///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_AutomaticReconciliations_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'automatic_reconciliations', 'main')
}

export const DatabaseRef_AutomaticReconciliation_Document = (clientKey: string, analysisKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'automatic_reconciliations', 'main', analysisKey)
}
