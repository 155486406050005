//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			component new message threads

		TODO:
			dark mode?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material/'
import { useEffect, useReducer, useState } from 'react'
import { rJSX_Avatar, TsInterface_ChatUser, TsInterface_ChatUsers, TsType_ChatThreadCreate } from 'rfbp_core/components/chat'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsType_FormOnChange,
  TsType_FormSubmission,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, getProp, objectToArray, searchAndSort } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  chatThreadCreate: TsType_ChatThreadCreate
  chatUsers: TsInterface_ChatUsers
}

///////////////////////////////
// Variables
///////////////////////////////

// Search Form
const formInputs: TsInterface_FormInputs = {
  search: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'search',
    label: rLIB('Search'),
    required: false,
  },
}

const formSettings: TsInterface_FormSettings = {
  highlight_missing: false,
  submit_button_alignment: 'right',
  submit_button_hide: true,
  submit_button_icon: null,
  submit_button_saving_icon: true,
  submit_button_text: <></>,
}

const searchTableSettings: TsInterface_TableSettings = {
  paginated: false,
  show_header: false,
  size: 'small',
  sortable: false,
}

const searchTableColumns: TsInterface_TableColumns = {
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <Typography className="tw-font-bold"></Typography>
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-text-right tw-bg-success_main'
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Button JSX
        let buttonJSX = <></>
        let userKey = getProp(rowData, 'key', '')
        if (
          userKey != null &&
          tableAdditionalData != null &&
          tableAdditionalData['threadUsers'] != null &&
          tableAdditionalData['threadUsers'][userKey] != null
        ) {
          buttonJSX = (
            <Button
              disabled={tableAdditionalData.creatingThread === true}
              color="warning"
              variant="outlined"
              className="tw-ml-2 tw-mr-4 tw-pb-0 tw-px-1 tw-inline-block tw-text-center"
              onClick={(event) => {
                tableAdditionalData.removeThreadUser(rowData)
              }}
            >
              <Icon icon="circle-minus" />
            </Button>
          )
        } else {
          buttonJSX = (
            <Button
              disabled={tableAdditionalData.creatingThread === true}
              color="primary"
              variant="outlined"
              className="tw-ml-2 tw-mr-4 tw-pb-0 tw-px-1 tw-inline-block tw-text-center"
              onClick={(event) => {
                tableAdditionalData.addThreadUser(rowData)
              }}
            >
              <Icon icon="circle-plus" />
            </Button>
          )
        }
        // Cell JSX
        let cellJSX = (
          <>
            {buttonJSX}
            {rowData.name}
          </>
        )
        return cellJSX
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ChatNewThread = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_chatThreadCreate: TsInterface_ComponentProps['chatThreadCreate'] = getProp(props, 'chatThreadCreate', (chatUsers: TsInterface_ChatUsers) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })
  let pr_chatUsers: TsInterface_ComponentProps['chatUsers'] = getProp(props, 'chatUsers', {})

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_creatingThread, us_setCreatingThread] = useState<boolean>(false)
  const [us_formData, us_setFormData] = useState<TsInterface_FormData>({})
  const [us_newThreadUsers, us_setNewThreadUsers] = useState<TsInterface_ChatUsers>({})
  const [us_searchTableData, us_setSearchTableData] = useState<TsInterface_TableData>([])
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    us_setFormData({})
  }, [us_setFormData])

  // Other Variables
  const formAdditionalData_NewThread: TsInterface_FormAdditionalData = {}

  const tableAdditionalData: TsInterface_TableAdditionalData = {
    creatingThread: us_creatingThread,
    threadUsers: us_newThreadUsers,
    addThreadUser: (user: TsInterface_ChatUser) => {
      us_setNewThreadUsers((prevState) => ({
        ...prevState,
        [user.key]: user,
      }))
    },
    removeThreadUser: (user: TsInterface_ChatUser) => {
      delete us_newThreadUsers[user.key]
      us_setNewThreadUsers(us_newThreadUsers)
      ur_forceRerender()
    },
  }

  // Functions
  const formOnChange: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    if (formData != null && formData.search != null && formData.search !== '') {
      let searchResults = searchAndSort(objectToArray(pr_chatUsers), 'name', formData.search as string)
      us_setSearchTableData(searchResults)
    } else {
      us_setSearchTableData([])
    }
  }

  const formSubmission: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_NewThreadChips = (): JSX.Element => {
    let chipsJSX = (
      <>
        {objectToArray(us_newThreadUsers)
          .sort(dynamicSort('name', 'asc'))
          .map((user: TsInterface_ChatUser, index: number) => (
            <Box
              component="span"
              className="tw-p-1"
              key={index}
            >
              <Chip
                label={user.name}
                onDelete={() => {
                  if (us_creatingThread !== true) {
                    delete us_newThreadUsers[user.key]
                    us_setNewThreadUsers(us_newThreadUsers)
                    ur_forceRerender()
                  }
                }}
              />
            </Box>
          ))}
      </>
    )
    return chipsJSX
  }

  const rJSX_NewThreadButton = (): JSX.Element => {
    // Icon JSX
    let iconJSX = <Icon icon="circle-plus" />
    if (us_creatingThread === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
        />
      )
    }
    // Button JSX
    let buttonJSX = (
      <Button
        color="primary"
        disabled={objectToArray(us_newThreadUsers).length === 0 || us_creatingThread === true}
        onClick={() => {
          us_setCreatingThread(true)
          pr_chatThreadCreate(us_newThreadUsers).finally(() => {
            us_setCreatingThread(false)
          })
        }}
        variant="contained"
        startIcon={iconJSX}
      >
        {rLIB('Create Thread')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Box
          component="div"
          className="tw-text-left tw-p-1"
        >
          <Box
            component="div"
            className="tw-inline-block"
          >
            <Box
              component="div"
              className="tw-flex"
            >
              {rJSX_Avatar({ '+': '+' }, 'lc_message_avatar_medium', 0, '')}
            </Box>
          </Box>
          <Box
            component="div"
            className="tw-inline-block tw-align-top tw-pb-2"
          >
            <Box>
              <Typography
                className="tw-inline-block"
                sx={{ paddingLeft: '0px', paddingTop: '4px' }}
                variant="h6"
              >
                {rLIB('New Message')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Form
          formAdditionalData={formAdditionalData_NewThread}
          formData={us_formData}
          formInputs={formInputs}
          formOnChange={formOnChange}
          formSettings={formSettings}
          formSubmission={formSubmission}
        />
        <Box className="tw-my-3">
          <Stack
            spacing={1}
            direction="row"
          >
            {rJSX_NewThreadButton()}
            {rJSX_NewThreadChips()}
          </Stack>
        </Box>
        <Divider className="tw-mt-3" />
        <TableBasic
          tableAdditionalData={tableAdditionalData}
          tableColumns={searchTableColumns}
          tableData={us_searchTableData}
          tableSettings={searchTableSettings}
        />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
