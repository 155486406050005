///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Stack, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { downloadExcelReport } from 'app/services/reports/report_helper_functions'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveDataBuckets_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_ActiveReportShortcuts_Query,
  DatabaseRef_ReportShortcuts_Collection,
  DatabaseRef_ReportShortcuts_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/report_shortcuts'
import {
  DatabaseRef_ActiveReportTemplates_Query,
  DatabaseRef_ReportTemplates_Collection,
  DatabaseRef_ReportTemplates_Components_Collection,
  DatabaseRef_ReportTemplates_Components_Document,
  DatabaseRef_ReportTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import { DatabaseRef_DataForSpecificDataBucket_DateRange_Query } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ReportsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_CSV: JSX.Element = 													<Trans>CSV</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_SHORTCUT: JSX.Element = <Trans>Are you sure that you want to delete this shortcut?</Trans>
const s_DATA_BUCKETS: JSX.Element = <Trans>Data Buckets</Trans>
const s_DATA_QUERY_END_DATE: JSX.Element = <Trans>Data Query End Date</Trans>
const s_DATA_QUERY_START_DATE: JSX.Element = <Trans>Data Query Start Date</Trans>
const s_DEFAULT_REPORT_START_TIME: JSX.Element = <Trans>Default Report Start Time</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_SHORTCUT: JSX.Element = <Trans>Delete Shortcut</Trans>
const s_DOWNLOAD_REPORT: JSX.Element = <Trans>Download Report</Trans>
const s_FAILED_TO_CREATE_REPORT_TEMPLATE: JSX.Element = <Trans>Failed to creare report template</Trans>
const s_FILE_TYPE: JSX.Element = <Trans>File Type</Trans>
const s_INVALID_FILE_TYPE: JSX.Element = <Trans>Invalid file type</Trans>
const s_LAST_DAY: JSX.Element = <Trans>Last Day</Trans>
const s_LAST_SEVEN_DAYS: JSX.Element = <Trans>Last Seven Days</Trans>
const s_MONTH_TO_DATE: JSX.Element = <Trans>Month to Date</Trans>
const s_NEW_REPORT_SHORTCUT: JSX.Element = <Trans>New Report Shortcut</Trans>
const s_NEW_REPORT_TEMPLATE: JSX.Element = <Trans>New Report Template</Trans>
const s_REPORT_SHORTCUT_NAME: JSX.Element = <Trans>Report Shortcut Name</Trans>
const s_REPORT_TEMPLATE: JSX.Element = <Trans>Report Template</Trans>
const s_REPORT_TEMPLATES: JSX.Element = <Trans>Report Template</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const se_REPORTS: string = 'Reports'
// { sort-end } - displayed text

// Table
const tableSettings_reportTemplates: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_ReportTemplates: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.ReportTemplatesViewPage.url(rowData.key as string))
        }
      },
    },
    copy: {
      icon: (
        <Icon
          type="solid"
          icon="copy"
        />
      ),
      label: <>{rLIB('Copy')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              DatabaseGetDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, rowData.key as string))
                .then((res_DGD) => {
                  DatabaseGetCollection(DatabaseRef_ReportTemplates_Components_Collection(res_GCK.clientKey, rowData.key as string))
                    .then((res_DGC) => {
                      let newReportKey = uuidv4()
                      let reportRoot = res_DGD.data
                      let reportComponents = res_DGC.data
                      reportRoot.key = newReportKey
                      reportRoot.name = reportRoot.name + '  (Copy)'
                      updateArray.push({
                        type: 'setMerge',
                        ref: DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, newReportKey),
                        data: reportRoot,
                      })
                      for (let loopComponentKey in reportComponents) {
                        let loopComponent = reportComponents[loopComponentKey]
                        loopComponent.key = loopComponentKey
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, newReportKey, loopComponentKey),
                          data: loopComponent,
                        })
                      }
                      DatabaseStagedBatchUpdate(updateArray)
                        .then((res_DSBU) => {
                          tableHooks.un_routerNavigation(ApplicationPages.ReportTemplatesViewPage.url(newReportKey))
                        })
                        .catch((rej_DSBU) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                        })
                    })
                    .catch((rej_GDC) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GDC.error })
                    })
                })
                .catch((rej_GDD) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GDD.error })
                })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'error',
              confirm_text: rLIB('Delete'),
              default_value: '',
              header: rLIB('Delete Report Template'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              input_label: rLIB('Type DELETE to confirm'),
              input_type: 'text',
              text: (
                <>
                  {rLIB('Are you sure that you want to delete')} {getProp(rowData, 'name', 'this report template')}?
                </>
              ),
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  if (promptValue === 'DELETE') {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      status: 'deleted',
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            console.log(res_DSMD)
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                    resolve({ close_dialog: true })
                  } else {
                    resolve({ close_dialog: false })
                  }
                })
              },
            },
          })
        }
      },
    },
  }),
  name: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-4'
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('File Type')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let iconJSX = <></>
        switch (rowData.file_type) {
          case 'excel':
            iconJSX = (
              <Box className="tw-inline-block">
                <Icon
                  icon="file-excel"
                  className="tw-mr-2"
                  sx={{ fontSize: '18px', color: themeVariables.success_main }}
                />
              </Box>
            )
            break
          case 'pdf':
            iconJSX = (
              <Box className="tw-inline-block">
                <Icon
                  icon="file-pdf"
                  className="tw-mr-2"
                  sx={{ fontSize: '18px', color: themeVariables.error_main }}
                />
              </Box>
            )
            break
          default:
            iconJSX = (
              <Box className="tw-inline-block">
                <Icon
                  icon="file"
                  className="tw-mr-2"
                  sx={{ fontSize: '18px', color: themeVariables.info_main }}
                />
              </Box>
            )
            break
        }
        let cellJSX = (
          <Box>
            {iconJSX}
            {rowData.name}
          </Box>
        )
        return cellJSX
      },
    },
  },
  // name: TableCellBasic('name', rLIB('Report Template Name'), 'name'),
  // file_type: TableCellBasic('file_type', s_FILE_TYPE, 'file_type'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeReportShortcuts, us_setActiveReportShortcuts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeReportTemplates, us_setActiveReportTemplates] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveReportShortcuts(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveReportShortcuts_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveReportTemplates(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    document.title = se_REPORTS
  }, [])

  // Functions
  const createReportTemplate = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Report Template Name'),
              required: true,
            },
            file_type: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'file_type',
              label: s_FILE_TYPE,
              options: [
                {
                  key: 'excel',
                  value: rLIB('Excel'),
                },
                {
                  key: 'pdf',
                  value: rLIB('PDF'),
                  disabled: true,
                },
              ],
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let reportKey = uuidv4()
              let updateObject = {
                associated_class_keys: {},
                file_type: formSubmittedData.file_type,
                key: reportKey,
                name: formSubmittedData.name,
                status: 'active',
                visible: false,
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  if (formSubmittedData.file_type === 'excel') {
                    let newTabKey = 'tab_' + new Date().getTime()
                    updateArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, reportKey),
                        data: updateObject,
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, reportKey, newTabKey),
                        data: {
                          component_type: 'excel_tab',
                          key: newTabKey,
                          name: 'Sheet 1',
                          order: new Date().getTime(),
                        },
                      },
                    ]
                  } else if (formSubmittedData.file_type === 'pdf') {
                    let pageKey = 'page_' + new Date().getTime()
                    updateArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, reportKey),
                        data: updateObject,
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, reportKey, pageKey),
                        data: {
                          component_type: 'pdf_page',
                          key: pageKey,
                          name: 'Page 1',
                          order: new Date().getTime(),
                          pdf_template: {
                            page_settings: {
                              flexDirection: 'column',
                              paddingTop: '0in',
                              paddingBottom: '0in',
                              paddingLeft: '0in',
                              paddingRight: '0in',
                            },
                            page_contents: {},
                          },
                        },
                      },
                    ]
                  }
                  if (updateArray.length > 0) {
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        formHooks.un_routerNavigation(ApplicationPages.ReportTemplatesViewPage.url(reportKey))
                        resolve(res_DBU)
                      })
                      .catch((rej_DBU) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                        reject(rej_DBU)
                      })
                  } else {
                    let error = {
                      message: s_FAILED_TO_CREATE_REPORT_TEMPLATE,
                      details: s_INVALID_FILE_TYPE,
                      code: 'ER-D-RTL-CRT-01',
                    }
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                    reject({ success: false, error: error })
                  }
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_NEW_REPORT_TEMPLATE} </>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const createReportShortcut = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let fullReportTemplates = res_DGC.data
            let reportTemplateOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopReportTemplateKey in res_DGC.data) {
              let loopReportTemplate = res_DGC.data[loopReportTemplateKey]
              reportTemplateOptions.push({
                key: loopReportTemplateKey,
                value: loopReportTemplate.name,
              })
            }
            DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey))
              .then((res_DGC) => {
                let dataBucketOptions: TsInterface_UnspecifiedObject[] = []
                for (let loopDataBucketKey in res_DGC.data) {
                  let loopDataBucket = res_DGC.data[loopDataBucketKey]
                  dataBucketOptions.push({
                    key: loopDataBucketKey,
                    value: loopDataBucket.name,
                  })
                }
                uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: {},
                      formInputs: {
                        name: {
                          data_type: 'string',
                          input_type: 'text_basic',
                          key: 'name',
                          label: s_REPORT_SHORTCUT_NAME,
                          required: true,
                        },
                        associated_data_bucket_keys: {
                          data_type: 'string',
                          input_type: 'multiple_select_dropdown',
                          key: 'associated_data_bucket_keys',
                          label: s_DATA_BUCKETS,
                          options: dataBucketOptions,
                          required: true,
                        },
                        associated_report_template_key: {
                          data_type: 'string',
                          input_type: 'multiple_choice_select',
                          key: 'associated_report_template_key',
                          label: s_REPORT_TEMPLATE,
                          options: reportTemplateOptions,
                          required: true,
                        },
                        default_report_query_time: {
                          data_type: 'string',
                          input_type: 'timestamp_time',
                          key: 'default_report_query_time',
                          label: s_DEFAULT_REPORT_START_TIME,
                          required: true,
                        },
                        default_report_date_range: {
                          data_type: 'string',
                          input_type: 'multiple_choice_radio',
                          key: 'default_report_date_range',
                          label: s_REPORT_TEMPLATE,
                          options: [
                            { key: 'last_day', value: s_LAST_DAY },
                            { key: 'last_week', value: s_LAST_SEVEN_DAYS },
                            { key: 'month_to_date', value: s_MONTH_TO_DATE },
                          ],
                          required: true,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject: TsInterface_UnspecifiedObject = {
                                status: 'active',
                                name: formSubmittedData.name,
                                associated_data_bucket_keys: formSubmittedData.associated_data_bucket_keys,
                                associated_report_template_key: formSubmittedData.associated_report_template_key,
                                associated_report_template_file_type: getProp(
                                  getProp(fullReportTemplates, formSubmittedData.associated_report_template_key, {}),
                                  'file_type',
                                  null,
                                ),
                                default_report_query_time: formSubmittedData.default_report_query_time,
                                default_report_date_range: formSubmittedData.default_report_date_range,
                              }
                              DatabaseAddDocument(DatabaseRef_ReportShortcuts_Collection(res_GCK.clientKey), updateObject, true)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: <>{s_NEW_REPORT_SHORTCUT}</>,
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="circle-plus"
                        />
                      ),
                    },
                  },
                })
              })
              .catch((rej_DGC) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
              })
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const updateReportShortcut = (reportShortcut: TsInterface_UnspecifiedObject) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let fullReportTemplates = res_DGC.data
            let reportTemplateOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopReportTemplateKey in res_DGC.data) {
              let loopReportTemplate = res_DGC.data[loopReportTemplateKey]
              reportTemplateOptions.push({
                key: loopReportTemplateKey,
                value: loopReportTemplate.name,
              })
            }
            DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey))
              .then((res_DGC) => {
                let dataBucketOptions: TsInterface_UnspecifiedObject[] = []
                for (let loopDataBucketKey in res_DGC.data) {
                  let loopDataBucket = res_DGC.data[loopDataBucketKey]
                  dataBucketOptions.push({
                    key: loopDataBucketKey,
                    value: loopDataBucket.name,
                  })
                }
                uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: reportShortcut,
                      formInputs: {
                        name: {
                          data_type: 'string',
                          input_type: 'text_basic',
                          key: 'name',
                          label: s_REPORT_SHORTCUT_NAME,
                          required: true,
                        },
                        associated_data_bucket_keys: {
                          data_type: 'string',
                          input_type: 'multiple_select_dropdown',
                          key: 'associated_data_bucket_keys',
                          label: s_DATA_BUCKETS,
                          options: dataBucketOptions,
                          required: true,
                        },
                        associated_report_template_key: {
                          data_type: 'string',
                          input_type: 'multiple_choice_select',
                          key: 'associated_report_template_key',
                          label: s_REPORT_TEMPLATE,
                          options: reportTemplateOptions,
                          required: true,
                        },
                        default_report_query_time: {
                          data_type: 'string',
                          input_type: 'timestamp_time',
                          key: 'default_report_query_time',
                          label: s_DEFAULT_REPORT_START_TIME,
                          required: true,
                        },
                        default_report_date_range: {
                          data_type: 'string',
                          input_type: 'multiple_choice_radio',
                          key: 'default_report_date_range',
                          label: s_REPORT_TEMPLATE,
                          options: [
                            { key: 'last_day', value: s_LAST_DAY },
                            { key: 'last_week', value: s_LAST_SEVEN_DAYS },
                            { key: 'month_to_date', value: s_MONTH_TO_DATE },
                          ],
                          required: true,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject: TsInterface_UnspecifiedObject = {
                                status: 'active',
                                name: formSubmittedData.name,
                                associated_data_bucket_keys: formSubmittedData.associated_data_bucket_keys,
                                associated_report_template_key: formSubmittedData.associated_report_template_key,
                                associated_report_template_file_type: getProp(
                                  getProp(fullReportTemplates, formSubmittedData.associated_report_template_key, {}),
                                  'file_type',
                                  null,
                                ),
                                default_report_query_time: formSubmittedData.default_report_query_time,
                                default_report_date_range: formSubmittedData.default_report_date_range,
                              }
                              DatabaseSetMergeDocument(DatabaseRef_ReportShortcuts_Document(res_GCK.clientKey, reportShortcut.key), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: <>{s_NEW_REPORT_SHORTCUT}</>,
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="circle-plus"
                        />
                      ),
                    },
                  },
                })
              })
              .catch((rej_DGC) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
              })
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const deleteReportShortcut = (reportShortcut: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        icon: <Icon icon="trash" />,
        header: s_DELETE_SHORTCUT,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_SHORTCUT,
        submit_text: s_DELETE,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            let updateObject: TsInterface_UnspecifiedObject = {
              status: 'deleted',
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_ReportShortcuts_Document(res_GCK.clientKey, reportShortcut.key), updateObject)
                  .then((res_DUD) => {
                    resolve(res_DUD)
                  })
                  .catch((rej_DUD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    reject(rej_DUD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const tableDatabaseEndpoint_DirectoryItems = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_ReportTemplates_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const parseTime = (timeString: string) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':').map(Number)
    // Check if hours and minutes are valid
    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      throw new Error('Invalid time format')
    }
    // Return an object with hours and minutes
    return {
      hours: hours,
      minutes: minutes,
    }
  }

  const downloadReport = (reportShortcut: TsInterface_UnspecifiedObject) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let formData: TsInterface_UnspecifiedObject = {}
        switch (reportShortcut.default_report_date_range) {
          case 'last_day':
            formData.start_date = new Date().getTime() - 1000 * 60 * 60 * 24
            formData.end_date = new Date().getTime()
            break
          case 'last_week':
            formData.start_date = new Date().getTime() - 1000 * 60 * 60 * 24 * 7
            formData.end_date = new Date().getTime()
            break
          case 'month_to_date':
            formData.start_date = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()
            formData.end_date = new Date().getTime()
            break
        }
        if (formData.start_date != null) {
          formData.start_date = new Date(formData.start_date)
          if (reportShortcut.default_report_query_time != null) {
            formData.start_date.setHours(
              parseTime(reportShortcut.default_report_query_time)['hours'],
              parseTime(reportShortcut.default_report_query_time)['minutes'],
              0,
              0,
            )
          } else {
            formData.start_date.setHours(0, 0, 0, 0)
          }
          formData.start_date = returnFormattedDate(formData.start_date, 'YYYY-MM-DDTHH:mm')
        }
        if (formData.end_date != null) {
          formData.end_date = new Date(formData.end_date)
          formData.end_date = returnFormattedDate(formData.end_date, 'YYYY-MM-DDTHH:mm')
        }
        // Open Date Selection Dialog
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: formData,
              formInputs: {
                start_date: {
                  data_type: 'number',
                  input_type: 'timestamp_datetime',
                  key: 'start_date',
                  label: s_DATA_QUERY_START_DATE,
                  required: true,
                },
                end_date: {
                  data_type: 'number',
                  input_type: 'timestamp_datetime',
                  key: 'end_date',
                  label: s_DATA_QUERY_END_DATE,
                  required: true,
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  let promiseArray: TsType_UnknownPromise[] = []
                  let allReportData: TsInterface_UnspecifiedObject = {}
                  let dataQueryStartDate = returnDateFromUnknownDateFormat(formSubmittedData.start_date)
                  let dataQueryEndDate = returnDateFromUnknownDateFormat(formSubmittedData.end_date)
                  // Loop through each filtered data bucket and query data
                  for (let loopDataBucketKey in reportShortcut['associated_data_bucket_keys']) {
                    if (reportShortcut['associated_data_bucket_keys'][loopDataBucketKey] === true) {
                      promiseArray.push(
                        DatabaseGetCollection(
                          DatabaseRef_DataForSpecificDataBucket_DateRange_Query(res_GCK.clientKey, loopDataBucketKey, dataQueryStartDate, dataQueryEndDate),
                        )
                          .then((res_DGC) => {
                            for (let loopItemKey in res_DGC.data) {
                              allReportData[loopItemKey] = res_DGC.data[loopItemKey]
                            }
                          })
                          .catch((rej_DGC) => {
                            console.error(rej_DGC)
                          }),
                      )
                    }
                  }
                  // After Data is Loaded
                  Promise.all(promiseArray).finally(() => {
                    // TODO - handle pdf and other file types
                    downloadExcelReport(
                      res_GCK.clientKey,
                      reportShortcut.associated_report_template_key,
                      allReportData,
                      getProp(reportShortcut, 'name', 'download'),
                      'download',
                      null,
                    )
                      .then((res_DR) => {
                        resolve(res_DR)
                      })
                      .catch((rej_DR) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DR.error })
                        reject(rej_DR)
                      })
                  })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{s_DOWNLOAD_REPORT}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="cloud-arrow-down"
                />
              ),
            },
          },
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const downloadManualReport = (reportShortcut: TsInterface_UnspecifiedObject) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Select Dates
        let formData: TsInterface_UnspecifiedObject = {}
        formData.start_date = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()
        formData.end_date = new Date().getTime()
        if (formData.start_date != null) {
          formData.start_date = new Date(formData.start_date)
          if (reportShortcut.default_report_query_time != null) {
            formData.start_date.setHours(
              parseTime(reportShortcut.default_report_query_time)['hours'],
              parseTime(reportShortcut.default_report_query_time)['minutes'],
              0,
              0,
            )
          } else {
            formData.start_date.setHours(0, 0, 0, 0)
          }
          formData.start_date = returnFormattedDate(formData.start_date, 'YYYY-MM-DDTHH:mm')
        }
        if (formData.end_date != null) {
          formData.end_date = new Date(formData.end_date)
          formData.end_date = returnFormattedDate(formData.end_date, 'YYYY-MM-DDTHH:mm')
        }

        DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let dataBucketOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopDataBucketKey in res_DGC.data) {
              let loopDataBucket = res_DGC.data[loopDataBucketKey]
              dataBucketOptions.push({
                key: loopDataBucketKey,
                value: loopDataBucket.name,
              })
            }
            // Open Dialog
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: formData,
                  formInputs: {
                    start_date: {
                      data_type: 'number',
                      input_type: 'timestamp_datetime',
                      key: 'start_date',
                      label: s_DATA_QUERY_START_DATE,
                      required: true,
                    },
                    end_date: {
                      data_type: 'number',
                      input_type: 'timestamp_datetime',
                      key: 'end_date',
                      label: s_DATA_QUERY_END_DATE,
                      required: true,
                    },
                    associated_data_bucket_keys: {
                      data_type: 'string',
                      input_type: 'multiple_select_dropdown',
                      key: 'associated_data_bucket_keys',
                      label: s_DATA_BUCKETS,
                      options: dataBucketOptions,
                      required: true,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let promiseArray: TsType_UnknownPromise[] = []
                      let allReportData: TsInterface_UnspecifiedObject = {}
                      let dataQueryStartDate = returnDateFromUnknownDateFormat(formSubmittedData.start_date)
                      let dataQueryEndDate = returnDateFromUnknownDateFormat(formSubmittedData.end_date)
                      // Loop through each filtered data bucket and query data
                      for (let loopDataBucketKey in formSubmittedData['associated_data_bucket_keys']) {
                        if (formSubmittedData['associated_data_bucket_keys'][loopDataBucketKey] === true) {
                          promiseArray.push(
                            DatabaseGetCollection(
                              DatabaseRef_DataForSpecificDataBucket_DateRange_Query(res_GCK.clientKey, loopDataBucketKey, dataQueryStartDate, dataQueryEndDate),
                            )
                              .then((res_DGC) => {
                                for (let loopItemKey in res_DGC.data) {
                                  allReportData[loopItemKey] = res_DGC.data[loopItemKey]
                                }
                              })
                              .catch((rej_DGC) => {
                                console.error(rej_DGC)
                              }),
                          )
                        }
                      }
                      // After Data is Loaded
                      Promise.all(promiseArray).finally(() => {
                        // TODO - handle pdf and other file types
                        downloadExcelReport(res_GCK.clientKey, reportShortcut.key, allReportData, getProp(reportShortcut, 'name', 'download'), 'download', null)
                          .then((res_DR) => {
                            resolve(res_DR)
                          })
                          .catch((rej_DR) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DR.error })
                            reject(rej_DR)
                          })
                      })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{s_DOWNLOAD_REPORT}</>,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="cloud-arrow-down"
                    />
                  ),
                },
              },
            })
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  // Call Functions

  // JSX Generation
  const rJSX_NewTemplateButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createReportTemplate()
        }}
        startIcon={<Icon icon="circle-plus" />}
        className="tw-mb-2"
      >
        {s_NEW_REPORT_TEMPLATE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewReportShortcut = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createReportShortcut()
        }}
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
      >
        {s_NEW_REPORT_SHORTCUT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DisabledReportShortcut = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          // createReportShortcut()
        }}
        disabled={true}
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
      >
        {s_NEW_REPORT_SHORTCUT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ShortcutLink = (shortcut: TsInterface_UnspecifiedObject): JSX.Element => {
    let shortcutLinkJSX = <></>
    let linkColor = themeVariables.info_main
    let linkColorDark = themeVariables.info_dark
    let linkIconKey = 'file'
    switch (shortcut.associated_report_template_file_type) {
      case 'excel':
        linkColor = themeVariables.success_main
        linkColorDark = themeVariables.success_dark
        linkIconKey = 'file-excel'
        break
      case 'pdf':
        linkColor = themeVariables.error_main
        linkColorDark = themeVariables.error_dark
        linkIconKey = 'file-pdf'
        break
      case 'csv':
        linkColor = themeVariables.info_main
        linkColorDark = themeVariables.info_dark
        linkIconKey = 'file-csv'
        break
    }
    shortcutLinkJSX = (
      <Box
        className="tw-inline-block tw-rounded-lg tw-cursor-pointer tw-mr-2 tw-w-full tw-m-1"
        sx={{
          border: '1px solid ' + themeVariables.gray_400,
        }}
        onClick={() => {
          // Download Report
          downloadReport(shortcut)
        }}
      >
        <Box
          sx={{
            // 'borderLeft': '12px solid ' + linkColor,
            'backgroundColor': themeVariables.gray_100,
            'height': '200px',
            'width': '100%',
            '&:hover': {
              backgroundColor: themeVariables.gray_300,
            },
            'position': 'relative',
          }}
          className="tw-inline-block tw-rounded-lg tw-p-2 tw-cursor-pointer"
        >
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Box></Box>
            <Box>
              <Box
                component={'span'}
                onClick={(event) => {
                  event.stopPropagation()
                  updateReportShortcut(shortcut)
                }}
              >
                <Icon
                  icon="pen-to-square"
                  className="tw-cursor-pointer tw-text-gray-500 tw-text-lg hover:tw-text-green-500 tw-mr-2"
                />
              </Box>
              <Box
                component={'span'}
                onClick={(event) => {
                  event.stopPropagation()
                  deleteReportShortcut(shortcut)
                }}
              >
                <Icon
                  icon="trash"
                  className="tw-cursor-pointer tw-text-gray-500 tw-text-lg hover:tw-text-red-500"
                />
              </Box>
            </Box>
          </Stack>
          <Box className="tw-text-center">
            <Icon
              icon={linkIconKey}
              sx={{ color: linkColor, fontSize: '96px' }}
            />
          </Box>
          <Box
            sx={{
              'color': themeVariables.white,
              'bottom': '0',
              'position': 'absolute',
              'backgroundColor': linkColor,
              'width': '100%',
              'height': '48px',
              'left': '0',
              'textAlign': 'center',
              'borderBottomLeftRadius': '8px',
              'borderBottomRightRadius': '8px',
              '&:hover': {
                backgroundColor: linkColorDark,
              },
            }}
          >
            <Typography className="tw-inline-block tw-ml-2 tw-align-top tw-font-bold">{shortcut.name}</Typography>
          </Box>
        </Box>
      </Box>
    )
    return shortcutLinkJSX
  }

  const rJSX_DownloadsTab = (): JSX.Element => {
    let tabJSX = (
      <Card className="tw-p-2">
        <Grid2
          columns={{
            xs: 1,
            sm: 2,
            md: 4,
            lg: 6,
            xl: 8,
          }}
          container
          spacing={2}
        >
          {objectToArray(us_activeReportShortcuts)
            .sort(dynamicSort('name', 'asc'))
            .map((shortcut, index) => (
              <Grid2
                key={index}
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              >
                {rJSX_ShortcutLink(shortcut)}
              </Grid2>
            ))}
        </Grid2>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ManualDownloadLink = (reportTemplate: TsInterface_UnspecifiedObject): JSX.Element => {
    let shortcutLinkJSX = <></>
    let linkColor = themeVariables.info_main
    let linkColorDark = themeVariables.info_dark
    let linkIconKey = 'file'
    switch (reportTemplate.file_type) {
      case 'excel':
        linkColor = themeVariables.success_main
        linkColorDark = themeVariables.success_dark
        linkIconKey = 'file-excel'
        break
      case 'pdf':
        linkColor = themeVariables.error_main
        linkColorDark = themeVariables.error_dark
        linkIconKey = 'file-pdf'
        break
      case 'csv':
        linkColor = themeVariables.info_main
        linkColorDark = themeVariables.info_dark
        linkIconKey = 'file-csv'
        break
    }
    shortcutLinkJSX = (
      <Box
        className="tw-inline-block tw-rounded-lg tw-cursor-pointer tw-mr-2 tw-w-full tw-m-1"
        sx={{
          border: '1px solid ' + themeVariables.gray_400,
        }}
        onClick={() => {
          // Download Report
          downloadManualReport(reportTemplate)
        }}
      >
        <Box
          sx={{
            // 'borderLeft': '12px solid ' + linkColor,
            'backgroundColor': themeVariables.gray_100,
            'height': '200px',
            'width': '100%',
            '&:hover': {
              backgroundColor: themeVariables.gray_300,
            },
            'position': 'relative',
          }}
          className="tw-inline-block tw-rounded-lg tw-p-2 tw-cursor-pointer"
        >
          <Box
            className="tw-text-center"
            sx={{ marginTop: '28px' }}
          >
            <Icon
              icon={linkIconKey}
              sx={{ color: linkColor, fontSize: '96px' }}
            />
          </Box>
          <Box
            sx={{
              'color': themeVariables.white,
              'bottom': '0',
              'position': 'absolute',
              'backgroundColor': linkColor,
              'width': '100%',
              'height': '48px',
              'left': '0',
              'textAlign': 'center',
              'borderBottomLeftRadius': '8px',
              'borderBottomRightRadius': '8px',
              '&:hover': {
                backgroundColor: linkColorDark,
              },
            }}
          >
            <Typography className="tw-inline-block tw-ml-2 tw-align-top tw-font-bold">{reportTemplate.name}</Typography>
          </Box>
        </Box>
      </Box>
    )
    return shortcutLinkJSX
  }

  const rJSX_ExcelDownloadsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-2">
        <Grid2
          columns={{
            xs: 1,
            sm: 2,
            md: 4,
            lg: 6,
            xl: 8,
          }}
          container
          spacing={2}
        >
          {objectToArray(us_activeReportTemplates)
            .sort(dynamicSort('name', 'asc'))
            .map((shortcut, index) => (
              <Grid2
                key={index}
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              >
                {rJSX_ManualDownloadLink(shortcut)}
              </Grid2>
            ))}
        </Grid2>
      </Card>
    )

    return tabJSX
  }

  const rJSX_ReportTemplatesTab = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_ReportTemplates}
            tableDatabaseEndpoint={tableDatabaseEndpoint_DirectoryItems}
            tableSettings={tableSettings_reportTemplates}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_REPORT_TEMPLATES}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabHeader: rLIB('Report Shortcuts'),
                  tabUrlKey: 'Shortcuts',
                  tabButtons: [{ fullJSX: rJSX_NewReportShortcut(), minJSX: rJSX_NewReportShortcut(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_DownloadsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Downloads'),
                  tabUrlKey: 'Downloads',
                  tabButtons: [{ fullJSX: rJSX_DisabledReportShortcut(), minJSX: rJSX_DisabledReportShortcut(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_ExcelDownloadsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Report Templates'),
                  tabUrlKey: 'Templates',
                  tabButtons: [{ fullJSX: rJSX_NewTemplateButton(), minJSX: rJSX_NewTemplateButton(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_ReportTemplatesTab()}</Box>,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.ReportsListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: se_REPORTS,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
