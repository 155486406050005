//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			External Requests contain all functions that interface with cloud function endpoints or external APIs

		TODO:
			[ ] Typescript - write better interfaces for cloud function errors and request params
			[ ] Typescript - 2 instances of any
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { getFunctions, httpsCallable } from 'firebase/functions'
import { waitForAuthenticationVerification } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

// Cloud Functions
const functions = getFunctions()

///////////////////////////////
// Functions
///////////////////////////////

const parseCloudFunctionError = (cloudFunctionError: any) => {
  if (typeof cloudFunctionError === 'string') {
    return { message: cloudFunctionError }
  } else if (typeof cloudFunctionError === 'object') {
    return cloudFunctionError
  } else if (cloudFunctionError != null && cloudFunctionError.message != null) {
    return JSON.parse(cloudFunctionError.message)
  } else {
    return { message: 'parseCloudFunctionError' }
  }
}

///////////////////////////////
// Exports
///////////////////////////////

export const cloudFunctionManageRequest = (functionName: string, requestParams: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    waitForAuthenticationVerification().finally(() => {
      const cloudFunction = httpsCallable(functions, functionName)
      cloudFunction(requestParams)
        .then((res_CF) => {
          resolve(res_CF.data)
        })
        .catch((rej_CF) => {
          reject(parseCloudFunctionError(rej_CF))
        })
    })
  })
}
