///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Reconciliations_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'reconciliations', 'main')
}

export const DatabaseRef_Reconciliation_Document = (clientKey: string, analysisKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'reconciliations', 'main', analysisKey)
}

export const DatabaseRef_Reconciliation_ComparisonFile_Collection = (clientKey: string, analysisKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'reconciliations', 'main', analysisKey, 'comparison_file')
}

export const DatabaseRef_Reconciliation_ComparisonFile_Document = (clientKey: string, analysisKey: string, rowKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'reconciliations', 'main', analysisKey, 'comparison_file', rowKey)
}

export const DatabaseRef_ActiveReconciliations_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'reconciliations', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}
