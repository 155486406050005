///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientUserRolesFormOptions } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClientWorkspaceInvites_Query } from 'rfbp_aux/services/database_endpoints/global_workspace_invites'
import { DatabaseRef_ClientWorkspaceRequests_Query } from 'rfbp_aux/services/database_endpoints/global_worspace_requests'
import { DatabaseRef_ClientAccessUsers_Query } from 'rfbp_aux/services/database_endpoints/users'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['UserManagementIndexPage']['key']

// Forms

// ClientUserRoles

// Table Data
const tableSettings_CurrentUsers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  collapsible_columns: false,
  no_data_message: rLIB('No data found') as JSX.Element,
  searchable: false,
  hide_pagination: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_CurrentUsers: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Name'), 'name'),
  user_role: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('User Role')
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.clientKey != null &&
          rowData != null &&
          rowData.client_access != null &&
          getProp(rowData.client_access, tableAdditionalData.clientKey, null) != null
        ) {
          let color: TsType_MuiComponentColors = 'primary'
          let icon = 'user'
          switch (getProp(rowData.client_access, tableAdditionalData.clientKey, '')) {
            case 'owner':
              color = 'warning'
              icon = 'crown'
              break
            case 'admin':
              color = 'error'
              icon = 'user-gear'
              break
          }
          cellJSX = (
            <Chip
              color={color}
              variant="filled"
              label={
                <Box>
                  <Icon
                    icon={icon}
                    className="tw-mr-2"
                  />
                  {capitalizeFirstLetter(getProp(rowData.client_access, tableAdditionalData.clientKey, ''))}
                </Box>
              }
            />
          )
        }
        return cellJSX
      },
    },
  },
  email: TableCellBasic('email', rLIB('Email'), 'email'),
  phone: TableCellBasic('phone', rLIB('Phone'), 'phone'),
  manage: TableCellManage({
    remove_user_from_workspace: {
      icon: (
        <Icon
          type="solid"
          icon="ban"
        />
      ),
      label: <>{rLIB('Remove User from Workspace')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          if (
            tableAdditionalData != null &&
            tableAdditionalData.clientKey != null &&
            rowData != null &&
            rowData.client_access != null &&
            getProp(rowData.client_access, tableAdditionalData.clientKey, null) != null
          ) {
            if (getProp(rowData.client_access, tableAdditionalData.clientKey, null) === 'owner') {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: rLIB('Failed to remove user from workspace'),
                  details: rLIB('Cannot remove owner accounts from workspace'),
                  code: 'ER-D-UM-RUFW-01',
                },
              })
            } else if (rowData.key === tableHooks.uc_RootData_GlobalUser.key) {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: rLIB('Failed to remove user from workspace'),
                  details: rLIB('You cannot remove yourself from the workspace'),
                  code: 'ER-D-UM-RUFW-02',
                },
              })
            } else {
              tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  header: rLIB('Remove User from Workspace'),
                  icon: (
                    <Icon
                      icon="ban"
                      type="solid"
                    />
                  ),
                  submit_text: rLIB('Remove User'),
                  text: rLIB('Are you sure that you want to remove this user from your workspace'),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          cloudFunctionManageRequest('manageUser', {
                            function: 'revokeWorkspaceAccess',
                            client_key: res_GCK.clientKey,
                            revoked_user_key: rowData.key,
                          })
                            .then((res_CFMUR: unknown) => {
                              resolve(res_CFMUR)
                            })
                            .catch((rej_CFMUR) => {
                              reject(rej_CFMUR)
                            })
                        })
                        .catch((rej_GCK) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
              })
            }
          } else {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                message: rLIB('Failed to remove user from workspace'),
                details: rLIB('Missing Required Parameters'),
                code: 'ER-D-UM-RUFW-03',
              },
            })
          }
        }
      },
    },
  }),
}

const tableSettings_Requests: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'associated_requestor_email',
  use_live_data: true,
  collapsible_columns: false,
  no_data_message: rLIB('No data found') as JSX.Element,
  searchable: false,
  hide_pagination: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

// // Select Role

const tableColumns_Requests: TsInterface_TableColumns = {
  associated_requestor_name: TableCellBasic('associated_requestor_name', rLIB('Requestor Name'), 'associated_requestor_name'),
  associated_requestor_email: TableCellBasic('associated_requestor_email', rLIB('Requestor Email'), 'associated_requestor_email'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Timestamp'), 'timestamp_created', 'D MMM YYYY h:mm a', true),
  manage: TableCellManage({
    grant_access_to_workspace: {
      icon: (
        <Icon
          type="solid"
          icon="badge-check"
        />
      ),
      label: <>{rLIB('Grant Workspace Access')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  user_role: {
                    key: 'user_role',
                    label: rLIB('User Role'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    options: ClientUserRolesFormOptions,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        cloudFunctionManageRequest('manageUser', {
                          function: 'grantWorkspaceAccess',
                          client_key: res_GCK.clientKey,
                          requestor_user_key: rowData.associated_requestor_key,
                          requestor_user_role: formSubmittedData.user_role,
                        })
                          .then((res_CFMUR: unknown) => {
                            resolve(res_CFMUR)
                          })
                          .catch((rej_CFMUR) => {
                            reject(rej_CFMUR)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Grant Workspace Access'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }
      },
    },
    deny_workspace_access_request: {
      icon: (
        <Icon
          type="solid"
          icon="ban"
        />
      ),
      label: <>{rLIB('Deny Workspace Access Request')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          // TODO
        }
      },
    },
  }),
}

const tableSettings_Invitations: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'associated_requestor_email',
  use_live_data: true,
  collapsible_columns: false,
  no_data_message: rLIB('No data found') as JSX.Element,
  searchable: false,
  hide_pagination: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_Invitations: TsInterface_TableColumns = {
  associated_invitee_email: TableCellBasic('associated_invitee_email', rLIB('Invitation Sent To'), 'associated_invitee_email'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Timestamp'), 'timestamp_created', 'D MMM YYYY h:mm a', true),
  manage: TableCellManage({
    revoke_workspace_invitation: {
      icon: (
        <Icon
          type="solid"
          icon="ban"
        />
      ),
      label: <>{rLIB('Revoke Invitation')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          cloudFunctionManageRequest('manageUser', {
            function: 'revokeWorkspaceInvite',
            invite_key: rowData.key,
          })
            .then((res_CFMUR: unknown) => {
              // resolve(res_CFMUR)
              tableHooks.uc_setUserInterface_AlertDialogDisplay({
                display: true,
                alert: {
                  color: 'info',
                  header: rLIB('Success'),
                  icon: <Icon icon="ban" />,
                  text: rLIB('Invitation Revoked Successfully'),
                },
              })
            })
            .catch((rej_CFMUR) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
            })
        }
      },
    },
  }),
}

///////////////////////////////
// Functions
///////////////////////////////

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientAccessUsers, us_setClientAccessUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientWorkspaceInvites, us_setClientWorkspaceInvites] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientWorkspaceRequests, us_setClientWorkspaceRequests] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('User Management', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientAccessUsers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClientAccessUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientWorkspaceInvites(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClientWorkspaceInvites_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientWorkspaceRequests(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClientWorkspaceRequests_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // Call Functions
  const inviteUserToWorkspace = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            header1: {
              data_type: 'string',
              key: 'header1',
              input_type: 'custom_form_input_jsx',
              label: <></>,
              required: false,
              renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                let inputJSX = (
                  <Box className="tw-text-left tw-mt-0">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700 }}
                      className="tw-mt-0"
                    >
                      {rLIB('Enter the email address of the person you want to invite to your workspace.')}
                    </Typography>
                  </Box>
                )
                return inputJSX
              },
            },
            email: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'email',
              label: <>{rLIB('Email')}</>,
              required: true,
            },
            invitee_user_role: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'invitee_user_role',
              label: <>{rLIB('User Role')}</>,
              options: ClientUserRolesFormOptions,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_theme: 'success',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  cloudFunctionManageRequest('manageUser', {
                    function: 'inviteUserToWorkspace',
                    // requestor_uid: uc_RootData_GlobalUser.key,
                    // owner_email: formSubmittedData.email,
                    client_key: res_GCK.clientKey,
                    owner_email: getProp(formHooks.uc_RootData_GlobalUser, 'email', null),
                    owner_name: getProp(formHooks.uc_RootData_GlobalUser, 'name', null),
                    invitee_email: formSubmittedData.email,
                    invitee_user_role: formSubmittedData.invitee_user_role,
                    // invitee_access_level: formSubmittedData.invitee_user_role,
                  })
                    .then((res_CFMUR: unknown) => {
                      resolve({ success: true, error: {} })
                      formHooks.uc_setUserInterface_AlertDialogDisplay({
                        display: true,
                        alert: {
                          color: 'success',
                          header: rLIB('Request sent successfully'),
                          icon: <Icon icon="paper-plane" />,
                          text: rLIB('We will let you know when we get a response'),
                        },
                      })
                    })
                    .catch((rej_CFMUR) => {
                      reject(rej_CFMUR)
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Invite a user to your workspace')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="user-plus"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_CurrentUsersTabContent = (): JSX.Element => {
    return (
      <Box>
        {/* <Json data={us_clientAccessUsers} /> */}
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={{
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_CurrentUsers}
            tableData={objectToArray(us_clientAccessUsers)}
            tableSettings={tableSettings_CurrentUsers}
          />
        </Card>
      </Box>
    )
  }

  const rJSX_RequestsToJoinWorkspaceTabContent = (): JSX.Element => {
    return (
      <Box>
        {/* <Json data={us_clientWorkspaceRequests} /> */}
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_Requests}
            tableData={objectToArray(us_clientWorkspaceRequests)}
            tableSettings={tableSettings_Requests}
          />
        </Card>
      </Box>
    )
  }

  const rJSX_InvitationsAwaitingResponseTabContent = (): JSX.Element => {
    return (
      <Box>
        {/* <Json data={us_clientWorkspaceInvites} /> */}
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_Invitations}
            tableData={objectToArray(us_clientWorkspaceInvites)}
            tableSettings={tableSettings_Invitations}
          />
        </Card>
      </Box>
    )
  }

  const rJSX_InvitationButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        className="tw-mb-2"
        color="success"
        startIcon={<Icon icon="user-plus" />}
        onClick={() => {
          inviteUserToWorkspace()
        }}
      >
        {rLIB('Invite User to Workspace')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_RequestHeader = (): JSX.Element => {
    let headerJSX = <>{rLIB('Requests to Join Workspace')}</>
    if (objectToArray(us_clientWorkspaceRequests).length > 0) {
      headerJSX = (
        <Box>
          <Icon
            icon="circle"
            sx={{ color: themeVariables.error_main }}
          />
          <Box className="tw-inline-block tw-ml-1">{rLIB('Requests to Join Workspace')}</Box>
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_InviteHeader = (): JSX.Element => {
    let headerJSX = <>{rLIB('Invitations Awaiting Response')}</>
    if (objectToArray(us_clientWorkspaceInvites).length > 0) {
      headerJSX = (
        <Box>
          <Icon
            icon="circle"
            sx={{ color: themeVariables.error_main }}
          />
          <Box className="tw-inline-block tw-ml-1">{rLIB('Requests to Join Workspace')}</Box>
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('User Management') as JSX.Element}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'current_users',
                  tabHeader: rLIB('Current Users'),
                  tabOnChange: () => {},
                  tabContent: rJSX_CurrentUsersTabContent(),
                  tabButtons: [{ fullJSX: rJSX_InvitationButton(), minJSX: rJSX_InvitationButton(), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'requests',
                  tabHeader: rJSX_RequestHeader(),
                  tabOnChange: () => {},
                  tabContent: rJSX_RequestsToJoinWorkspaceTabContent(),
                  tabButtons: [{ fullJSX: rJSX_InvitationButton(), minJSX: rJSX_InvitationButton(), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'invites',
                  tabHeader: rJSX_InviteHeader(),
                  tabOnChange: () => {},
                  tabContent: rJSX_InvitationsAwaitingResponseTabContent(),
                  tabButtons: [{ fullJSX: rJSX_InvitationButton(), minJSX: rJSX_InvitationButton(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.UserManagementIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('User Management', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
