///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveClasses_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveDataBuckets_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_ActiveAnalysisTemplates_Query,
  DatabaseRef_AnalysisTemplates_Collection,
  DatabaseRef_AnalysisTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/analysis_templates'
import { DatabaseRef_Analyses_Collection } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/analyses'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataAnalysisListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ANALYSES: JSX.Element = <Trans>Analyses</Trans>
const s_ANALYSIS_NAME: JSX.Element = <Trans>Analysis Name</Trans>
const s_ANALYSIS_TEMPLATE: JSX.Element = <Trans>Analysis Template</Trans>
const s_ANALYSIS_TEMPLATES: JSX.Element = <Trans>Analysis Templates</Trans>
const s_ANALYSIS_TEMPLATE_NAME: JSX.Element = <Trans>New Analysis Template</Trans>
const s_CREATE_NEW_ANALYSIS: JSX.Element = <Trans>Create New Analysis</Trans>
const s_CREATE_NEW_ANALYSIS_TEMPLATE: JSX.Element = <Trans>Create New Analysis Template</Trans>
const s_DATA_ANALYSIS: JSX.Element = <Trans>Data Analysis</Trans>
const s_DATA_FILTER_END_DATE: JSX.Element = <Trans>Data Filter End Date</Trans>
const s_DATA_FILTER_START_DATE: JSX.Element = <Trans>Data Filter Start Date</Trans>
const s_DATE_CREATED: JSX.Element = <Trans>Date Created</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_END_DATE: JSX.Element = <Trans>End Date</Trans>
const s_FORM: JSX.Element = <Trans>Form</Trans>
const s_FORM_DATA_THAT_THIS_ANALYSIS_TEMPLATE_WORKS_WITH: JSX.Element = <Trans>Form Data that this analysis template works with</Trans>
const s_NEW_ANALYSIS_TEMPLATE: JSX.Element = <Trans>New Analysis Template</Trans>
const s_NEW_DATA_ANALYSIS: JSX.Element = <Trans>New Data Analysis</Trans>
const s_QR_CODE: JSX.Element = <Trans>QR Code</Trans>
const s_QR_CODE_DATA_SET: JSX.Element = <Trans>QR Code Data Set</Trans>
const s_START_DATE: JSX.Element = <Trans>Start Date</Trans>
const s_TEMPLATE: JSX.Element = <Trans>Template</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const se_DATA_ANALYSIS: string = 'Data Analysis'
// { sort-end } - displayed text

// Tables
const tableSettings_ActiveAnalyses: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableSettings_ActiveTemplates: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

let tableColumns_ActiveTemplates: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.DataAnalysisTemplateViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{s_DELETE}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          // TODO
        }
      },
    },
  }),
  name: TableCellBasic('name', s_ANALYSIS_TEMPLATE_NAME, 'name'),
  associated_class_key: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_FORM
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_activeForms != null &&
          rowData != null &&
          rowData.associated_class_key != null &&
          tableAdditionalData.us_activeForms[rowData.associated_class_key as string] != null &&
          tableAdditionalData.us_activeForms[rowData.associated_class_key as string]['name'] != null
        ) {
          cellJSX = <Box>{tableAdditionalData.us_activeForms[rowData.associated_class_key as string]['name']}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

let tableColumns_ActiveAnalyses: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.DataAnalysisViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{s_DELETE}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          // Nothing
        }
      },
    },
  }),
  name: TableCellBasic('name', s_ANALYSIS_NAME, 'name'),
  associated_analysis_template_name: TableCellBasic('associated_analysis_template_name', s_TEMPLATE, 'associated_analysis_template_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', s_QR_CODE, 'associated_data_bucket_name'),
  filter_start_date: TableCellBasic('filter_start_date', s_START_DATE, 'filter_start_date'),
  filter_end_date: TableCellBasic('filter_end_date', s_END_DATE, 'filter_end_date'),
  timestamp_created: TableCellTimestamp('timestamp_created', s_DATE_CREATED, 'timestamp_created', ' D MMM YYYY - h:mm a', true),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeForms, us_setActiveForms] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_DATA_ANALYSIS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    ur_forceRerender()
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveForms(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const createTemplate = (): void => {
    let formsArray: TsInterface_UnspecifiedObject[] = []
    for (let loopFormKey in us_activeForms) {
      let loopForm = us_activeForms[loopFormKey]
      formsArray.push({
        key: loopFormKey,
        value: loopForm.name,
      })
    }
    // Open Form Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: <>{s_ANALYSIS_TEMPLATE_NAME}</>,
              required: true,
            },
            associated_class_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_class_key',
              label: s_FORM_DATA_THAT_THIS_ANALYSIS_TEMPLATE_WORKS_WITH,
              options: formsArray,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let itemKey = keyFromString(formSubmittedData.name)
                  let updateObject = {
                    name: formSubmittedData.name,
                    associated_class_key: formSubmittedData.associated_class_key,
                    // associated_class_name: formSubmittedData.associated_class_name,
                    timestamp_created: new Date(),
                    status: 'active',
                    key: itemKey,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_AnalysisTemplates_Document(res_GCK.clientKey, itemKey), updateObject)
                    .then((res_DSMD) => {
                      un_routerNavigation(ApplicationPages.DataAnalysisTemplateViewPage.url(itemKey))
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_CREATE_NEW_ANALYSIS_TEMPLATE}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const createAnalysis = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        let qrCodeOptions: TsInterface_UnspecifiedObject = {}
        let analysisTemplateOptions: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveAnalysisTemplates_Query(res_GCK.clientKey)).then((res_DGC) => {
            for (let loopTemplateKey in res_DGC.data) {
              let loopTemplate = res_DGC.data[loopTemplateKey]
              analysisTemplateOptions[loopTemplateKey] = {
                key: loopTemplateKey,
                value: loopTemplate.name,
              }
            }
          }),
        )
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey)).then((res_DGC) => {
            for (let loopQRCodeKey in res_DGC.data) {
              let loopQRCode = res_DGC.data[loopQRCodeKey]

              console.log(loopQRCode)

              qrCodeOptions[loopQRCodeKey] = {
                key: loopQRCodeKey,
                value: loopQRCode.name,
              }
            }
          }),
        )
        Promise.all(promiseArray).finally(() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{s_ANALYSIS_NAME}</>,
                    required: true,
                  },
                  associated_data_bucket_key: {
                    data_type: 'string',
                    input_type: 'multiple_choice_select',
                    key: 'associated_data_bucket_key',
                    label: s_QR_CODE_DATA_SET,
                    options: objectToArray(qrCodeOptions),
                    required: true,
                  },
                  filter_start_date: {
                    data_type: 'number',
                    input_type: 'timestamp_datetime',
                    key: 'filter_start_date',
                    label: <>{s_DATA_FILTER_START_DATE}</>,
                    required: true,
                  },
                  filter_end_date: {
                    data_type: 'number',
                    input_type: 'timestamp_datetime',
                    key: 'filter_end_date',
                    label: <>{s_DATA_FILTER_END_DATE}</>,
                    required: true,
                  },
                  associated_analysis_template_key: {
                    data_type: 'string',
                    input_type: 'multiple_choice_select',
                    key: 'associated_analysis_template_key',
                    label: s_ANALYSIS_TEMPLATE,
                    options: objectToArray(analysisTemplateOptions),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          associated_analysis_template_key: formSubmittedData.associated_analysis_template_key,
                          associated_analysis_template_name: getProp(
                            analysisTemplateOptions[formSubmittedData.associated_analysis_template_key],
                            'value',
                            null,
                          ),
                          associated_data_bucket_key: formSubmittedData.associated_data_bucket_key,
                          associated_data_bucket_name: getProp(qrCodeOptions[formSubmittedData.associated_data_bucket_key], 'value', null),
                          filter_end_date: formSubmittedData.filter_end_date,
                          filter_start_date: formSubmittedData.filter_start_date,
                          name: formSubmittedData.name,
                          status: 'active',
                          timestamp_created: new Date(),
                        }
                        DatabaseAddDocument(DatabaseRef_Analyses_Collection(res_GCK.clientKey), updateObject, true)
                          .then((res_DAD) => {
                            un_routerNavigation(ApplicationPages.DataAnalysisViewPage.url(res_DAD.key))
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                            reject(rej_DAD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{s_CREATE_NEW_ANALYSIS}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const tableDatabaseEndpoint_ActiveTemplates = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_AnalysisTemplates_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ActiveAnalyses = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Analyses_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // Call Functions

  // JSX Generation
  const rJSX_NewAnalysisButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createAnalysis()
        }}
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
      >
        {s_NEW_DATA_ANALYSIS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AnalysesTable = () => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{ us_activeForms: us_activeForms }}
            tableColumns={tableColumns_ActiveAnalyses}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ActiveAnalyses}
            tableSettings={tableSettings_ActiveAnalyses}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_NewTemplateButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createTemplate()
        }}
        startIcon={<Icon icon="circle-plus" />}
        className="tw-mb-2"
      >
        {s_NEW_ANALYSIS_TEMPLATE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_TemplatesTable = () => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{ us_activeForms: us_activeForms }}
            tableColumns={tableColumns_ActiveTemplates}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ActiveTemplates}
            tableSettings={tableSettings_ActiveTemplates}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_DATA_ANALYSIS}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabHeader: s_ANALYSES,
                  tabUrlKey: 'Analyses',
                  tabButtons: [{ fullJSX: rJSX_NewAnalysisButton(), minJSX: rJSX_NewAnalysisButton(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_AnalysesTable()}</Box>,
                },
                {
                  tabHeader: s_ANALYSIS_TEMPLATES,
                  tabUrlKey: 'Templates',
                  tabButtons: [{ fullJSX: rJSX_NewTemplateButton(), minJSX: rJSX_NewTemplateButton(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_TemplatesTable()}</Box>,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.DataAnalysisListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: se_DATA_ANALYSIS,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
