///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_CalculatedFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/data_management/calculated_fields'
import { DatabaseRef_Formulas_Collection } from 'rfbp_aux/services/database_endpoints/clients/data_management/formulas'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['FormulasIndexPage']['key']

// Table
const tableSettings_Formulas: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

let tableColumns_Formulas: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.FormulasViewPage.url(rowData.key as string))
        }
      },
    },
    // delete: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="trash"
    //     />
    //   ),
    //   label: rLIB('Delete'),
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData.key != null) {
    //       // TODO
    //     }
    //   },
    // },
  }),
  name: TableCellBasic('name', rLIB('Formula Name'), 'name'),
  description: TableCellBasic('description', rLIB('Description'), 'description'),
}

const tableSettings_CalculatedFields: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

let tableColumns_CalculatedFields: TsInterface_TableColumns = {
  // manage: TableCellManage({
  //   view: {
  //     icon: (
  //       <Icon
  //         type="solid"
  //         icon="magnifying-glass"
  //       />
  //     ),
  //     label: rLIB('View'),
  //     onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
  //       if (rowData.key != null) {
  //         tableHooks.un_routerNavigation(ApplicationPages.FormulasViewPage.url(rowData.key as string))
  //       }
  //     },
  //   },
  //   delete: {
  //     icon: (
  //       <Icon
  //         type="solid"
  //         icon="trash"
  //       />
  //     ),
  //     label: rLIB('Delete'),
  //     onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
  //       if (rowData.key != null) {
  //         // TODO
  //       }
  //     },
  //   },
  // }),
  name: TableCellBasic('name', rLIB('Calculated Field Name'), 'name'),
  // description: TableCellBasic('description', rLIB('Description'), 'description'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Formulas', false) as string
  }, [])

  // Functions
  const createNewFormula = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: {
                name: {
                  data_type: 'string',
                  input_type: 'text_basic',
                  key: 'name',
                  label: rLIB('Name'),
                  required: true,
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        name: formSubmittedData.name,
                        status: 'active',
                        timestamp_created: new Date(),
                        variables: {},
                        logic: {},
                      }
                      DatabaseAddDocument(DatabaseRef_Formulas_Collection(res_GCK.clientKey), updateObject, true)
                        .then((res_DAD) => {
                          un_routerNavigation(ApplicationPages.FormulasViewPage.url(res_DAD.key))
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: rLIB('New Formula'),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const createCalculatedFormula = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: {
                name: {
                  data_type: 'string',
                  input_type: 'text_basic',
                  key: 'name',
                  label: rLIB('Name'),
                  required: true,
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        name: formSubmittedData.name,
                        status: 'active',
                        timestamp_created: new Date(),
                      }
                      DatabaseAddDocument(DatabaseRef_CalculatedFields_Collection(res_GCK.clientKey), updateObject, true)
                        .then((res_DAD) => {
                          // un_routerNavigation(ApplicationPages.FormulasViewPage.url(res_DAD.key))
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: rLIB('New Calculated Field'),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const tableDatabaseEndpoint_Formulas = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Formulas_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_CalculatedFields = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_CalculatedFields_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewFormulaButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          createNewFormula()
        }}
        className="tw-mb-2"
      >
        {rLIB('New Formula')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewCalculatedFieldButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          createCalculatedFormula()
        }}
        className="tw-mb-2"
      >
        {rLIB('New Calculated Field')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_FormulasTable = () => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_Formulas}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Formulas}
            tableSettings={tableSettings_Formulas}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_CalculatedFieldsTable = () => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_CalculatedFields}
            tableDatabaseEndpoint={tableDatabaseEndpoint_CalculatedFields}
            tableSettings={tableSettings_CalculatedFields}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Formulas')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Formulas',
                  tabHeader: rLIB('Formulas'),
                  tabOnChange: () => {},
                  tabContent: rJSX_FormulasTable(),
                  tabButtons: [{ fullJSX: rJSX_NewFormulaButton(), minJSX: rJSX_NewFormulaButton(), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Calculated_Fields',
                  tabHeader: rLIB('Calculated Fields'),
                  tabOnChange: () => {},
                  tabContent: rJSX_CalculatedFieldsTable(),
                  tabButtons: [{ fullJSX: rJSX_NewCalculatedFieldButton(), minJSX: rJSX_NewCalculatedFieldButton(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.FormulasIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Formulas', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
