///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Data_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main')
}

export const DatabaseRef_Data_Document = (clientKey: string, dataKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main', dataKey)
}

export const DatabaseRef_Data_CalculationMetadata_Document = (clientKey: string, dataKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'calculation_metadata', dataKey)
}

export const DatabaseRef_ActiveData_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_ActiveData_SingleDataPoint_Query = (clientKey: string, dataBucketKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_data_bucket_key', comparator: '==', value: dataBucketKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main'), queryOperatorsArray, orderByArray, {}, 1)
}

export const DatabaseRef_DataForSpecificDataBucket_DateRange_Query = (
  clientKey: string,
  dataBucketKey: string,
  startDate: Date,
  endDate: Date,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_data_bucket_key', comparator: '==', value: dataBucketKey },
    { prop: 'timestamp_primary_query', comparator: '>=', value: startDate },
    { prop: 'timestamp_primary_query', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_primary_query', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_DataForSpecificClass_DateRange_Query = (clientKey: string, classKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_class_key', comparator: '==', value: classKey },
    { prop: 'timestamp_primary_query', comparator: '>=', value: startDate },
    { prop: 'timestamp_primary_query', comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_primary_query', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_DataLogs_Collection = (clientKey: string, dataKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main', dataKey, 'logs')
}

export const DatabaseRef_DataLogs_Document = (clientKey: string, dataKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main', dataKey, 'logs', logKey)
}

// Reconciliation
export const DatabaseRef_DataForSpecificReconciliation_DateRange_Query = (
  clientKey: string,
  reconciliationKey: string,
  startDate: Date,
  endDate: Date,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_reconciliation_keys.' + reconciliationKey, comparator: '>=', value: startDate },
    { prop: 'associated_reconciliation_keys.' + reconciliationKey, comparator: '<', value: endDate },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_reconciliation_keys.' + reconciliationKey, desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'data', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}
