///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_SearchIndexObject {
  key: string
  type: 'client' | 'global'
  indexKey: string
  primaryKey: 'id'
  indexedAttributes: {
    id: string
    [propKey: string]: string
  }
  searchableAttributes: string[]
  displayedAttributes: string[]
  filterableAttributes: string[]
  databaseDataEndpointOrderByProp: string
  databaseDataEndpoint: any
  statusToSetIfNull?: string
  inactiveBoolToStatus?: string
}

interface TsInterface_SearchIndexList {
  [indexKey: string]: TsInterface_SearchIndexObject
}

///////////////////////////////
// Variables
///////////////////////////////

export const searchConfig = {
  // host: "https://34.42.131.222",
  host: 'https://search.loadcall.com',
  apiKey: '4df1121bc4f504a7e87517ea89923c2ec61cbaaa0286ebcbd3b3e2cc77dc2455', // Search API Key
}

export const searchIndexList: TsInterface_SearchIndexList = {
  // TODO
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////
