///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { cloudFunctionManageRequest } from 'rfbp_aux/services/cloud_functions'
import {
  DatabaseRef_Classes_Collection,
  DatabaseRef_ClassFields_Document,
  DatabaseRef_Class_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ClassesListPage']['key']

// Table
const tableSettings_Classes: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_Classes: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.ClassesViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Form'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this Form?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_Class_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Form Name'), 'name'),
  // class_type: TableCellBasic( "class_type", rLIB("Type"), "class_type" ),
  class_type: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Type')
      },
      header_sort_by: 'class_type',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.class_type === 'persistent_data') {
          cellJSX = (
            <Chip
              color="info"
              variant="outlined"
              label={
                <Box>
                  <Icon
                    icon="database"
                    className="tw-mr-2"
                  />
                  {rLIB('Persistent Data (Directory)')}
                </Box>
              }
            />
          )
        } else if (rowData.class_type === 'transactional_data') {
          cellJSX = (
            <Chip
              color="primary"
              variant="outlined"
              label={
                <Box>
                  <Icon
                    icon="database"
                    className="tw-mr-2"
                  />
                  {rLIB('Transactional Data')}
                </Box>
              }
            />
          )
        } else {
          cellJSX = (
            <Chip
              color="warning"
              label={
                <Box>
                  <Icon
                    icon="database"
                    className="tw-mr-2"
                  />
                  {rowData.class_type}
                </Box>
              }
            />
          )
        }
        return cellJSX
      },
    },
  },
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Date Created'), 'timestamp_created', ' D MMM YYYY - h:mm a', true),
}

///////////////////////////////
// Functions
///////////////////////////////

export const removeSpecialCharacters = (str: string) => {
  // Regular expression to match characters that are not letters, numbers, spaces, or underscores
  let regex = /[^a-zA-Z0-9_\s]/g
  // Replace the matched characters with an empty string
  return str.replace(regex, '')
}

const createClass = (clientKey: string, name: string, classType: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && name != null && classType != null) {
      // Generate key
      let newClassKey: string = keyFromString(removeSpecialCharacters(name))
      // Make sure this class doesn't already exist
      let promiseArray: TsType_UnknownPromise[] = []
      let alreadyExists = false
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_Class_Document(clientKey, newClassKey))
          .then((res_DGD) => {
            if (res_DGD != null && res_DGD.data != null && res_DGD.data.key != null) {
              alreadyExists = true
            }
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      if (alreadyExists === false) {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          {
            type: 'setMerge',
            ref: DatabaseRef_Class_Document(clientKey, newClassKey),
            data: {
              class_type: classType,
              key: newClassKey,
              name: name,
              status: 'active',
              timestamp_created: new Date(),
            },
          },
          {
            type: 'setMerge',
            ref: DatabaseRef_ClassFields_Document(clientKey, newClassKey, 'name'),
            data: {
              filterable_in_search: true,
              form_type: 'short_answer',
              key: 'name',
              name: 'Name',
              order: new Date().getTime(),
              required: true,
              status: 'active',
            },
          },
        ]
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            // If persistent class type, create search index and name field
            if (classType === 'persistent_data') {
              cloudFunctionManageRequest('manageSearch', {
                function: 'createSearchIndex',
                client_key: clientKey,
                search_index_key: newClassKey,
                item_key: 'key',
              })
                .then((res_CFMUR: unknown) => {
                  console.log(res_CFMUR)
                  // resolve( { success: true, error: {} } )
                })
                .catch((rej_CFMUR) => {
                  console.error(rej_CFMUR)
                  // reject( rej_CFMUR )
                })
            }
            // Resolve before search index is created
            resolve({ success: true, key: newClassKey })
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Create Form'),
            details: rLIB('Form Already Exists'),
            code: 'ER-D-CL-CC-01',
          },
        })
      }
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to Create Form'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-D-CL-CC-02',
        },
      })
    }
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Forms', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const create = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: <>{rLIB('Name')}</>,
              required: true,
            },
            class_type: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'class_type',
              label: rLIB('Type'),
              options: [
                { key: 'persistent_data', value: rLIB('Persistent Data (Directory)') },
                { key: 'transactional_data', value: rLIB('Transactional Data') },
              ],
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  createClass(res_GCK.clientKey, formSubmittedData.name, formSubmittedData.class_type)
                    .then((res_CC: any) => {
                      if (res_CC.key != null) {
                        un_routerNavigation(ApplicationPages.ClassesViewPage.url(res_CC.key))
                      }
                      resolve(res_CC)
                    })
                    .catch((rej_CC) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CC.error })
                      reject(rej_CC)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create New Form')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // lass_type === 'persistent_data'
  // class_type === 'transactional_data'

  const tableDatabaseEndpoint_DirectoryForms = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'class_type', comparator: '==', value: 'persistent_data' },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Classes_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_TransactionalForms = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'class_type', comparator: '==', value: 'transactional_data' },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Classes_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // Call Functions

  // JSX Generation
  const rJSX_NewButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          create()
        }}
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Form')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DirectoryFormsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_Classes}
              tableDatabaseEndpoint={tableDatabaseEndpoint_DirectoryForms}
              tableSettings={tableSettings_Classes}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_TransactionalFormsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_Classes}
              tableDatabaseEndpoint={tableDatabaseEndpoint_TransactionalForms}
              tableSettings={tableSettings_Classes}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Forms')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Directory_Forms',
                  tabHeader: rLIB('Directory Forms'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DirectoryFormsTable(),
                  tabButtons: [{ fullJSX: rJSX_NewButton(), minJSX: rJSX_NewButton(), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Scheduled',
                  tabHeader: rLIB('Transactional Forms'),
                  tabOnChange: () => {},
                  tabContent: rJSX_TransactionalFormsTable(),
                  tabButtons: [{ fullJSX: rJSX_NewButton(), minJSX: rJSX_NewButton(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.ClassesListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Forms', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
