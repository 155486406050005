///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Avatar, Box, Button, Card, LinearProgress, Typography } from '@mui/material/'
import { updateUserClientKey } from 'app/models/users'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { returnAppVersionString } from 'rfbp_aux/config/app_version'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { stringAvatar, stringToColor } from 'rfbp_core/components/chat'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_GlobalUser } from 'rfbp_core/services/context'
import { dynamicSort, getProp, returnFormattedPhoneNumberString } from 'rfbp_core/services/helper_functions'
import { logOut } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_AuthorizedClient {
  [key: string]: string
}

interface TsInterface_AuthorizedClientsObject {
  [key: string]: boolean
}

interface TsInterface_AuthorizedClientArray extends Array<TsInterface_AuthorizedClient> {}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['UserSettingsPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_RootData_AuthenticatedUser } = 											useContext( Context_RootData_AuthenticatedUser )
  // const { uc_RootData_ClientPermissions } = 											useContext( Context_RootData_ClientPermissions )
  // const { uc_RootData_ClientUser } = 													useContext( Context_RootData_ClientUser )
  // const { uc_RootData_UserPermissions } = 											useContext( Context_RootData_UserPermissions )
  const [us_changingClientKey, us_setChangingClientKey] = useState(false)
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Settings', false) as string
  }, [])

  let displayUserName: string = getProp(uc_RootData_GlobalUser, 'name', null)
  let displayUserEmail: string = getProp(uc_RootData_GlobalUser, 'email', null)
  let displayUserPhone: string = getProp(uc_RootData_GlobalUser, 'phone', null)
  let authorizedClients: TsInterface_AuthorizedClientsObject = getProp(uc_RootData_GlobalUser, 'client_access', {})
  let authorizedClientArray: TsInterface_AuthorizedClientArray = []
  let appVersionNumber: string = returnAppVersionString()

  // Functions
  const signOut = (): void => {
    logOut()
      .then((res_LO) => {
        un_routerNavigation(ApplicationPages.UnauthenticatedLoginPage.url())
      })
      .catch((rej_LO) => {
        console.error(rej_LO)
      })
  }

  const generateAuthorizedClientList = (): void => {
    for (let clientKey in authorizedClients) {
      if (authorizedClients[clientKey] != null) {
        authorizedClientArray.push({ key: clientKey })
      }
    }
    authorizedClientArray.sort(dynamicSort('key', null))
  }

  const selectClientKey = (clientKey: string): void => {
    us_setChangingClientKey(true)
    updateUserClientKey(clientKey, uc_RootData_GlobalUser).finally(() => {
      us_setChangingClientKey(false)
    })
  }

  const rJSX_ClientToggleButton = (clientKey: string): JSX.Element => {
    let buttonJSX
    if (uc_RootData_ClientKey === clientKey) {
      buttonJSX = (
        <Button
          onClick={() => {}}
          className="tw-m-1"
          color="error"
          variant="contained"
          disabled={us_changingClientKey}
          startIcon={<Icon icon="square-check" />}
        >
          <Box
            component="div"
            sx={{ paddingLeft: '4px' }}
          >
            {clientKey}
          </Box>
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          onClick={() => {
            selectClientKey(clientKey)
          }}
          className="tw-m-1"
          variant="outlined"
          disabled={us_changingClientKey}
          startIcon={
            <Icon
              icon="square"
              type="light"
            />
          }
        >
          <Box
            component="div"
            sx={{ paddingLeft: '4px' }}
          >
            {clientKey}
          </Box>
        </Button>
      )
    }
    return buttonJSX
  }

  // Call Functions
  generateAuthorizedClientList()

  // JSX Generation

  const rJSX_ProgressBar = (): JSX.Element => {
    let progressJSX
    if (us_changingClientKey) {
      progressJSX = <LinearProgress color="error" />
    } else {
      progressJSX = <Box sx={{ height: '4px' }}></Box>
    }
    return progressJSX
  }

  const rJSX_ProfileTab = (): JSX.Element => {
    let profileTabJSX = (
      <Card variant="outlined">
        <Box className="tw-text-center">
          <Box className="tw-p-4 tw-text-left tw-inline-block">
            <Box className="tw-m-auto">
              <Avatar
                // eslint-disable-next-line react/no-children-prop
                children={stringAvatar(displayUserName)['children']}
                sx={{ width: 56, height: 56, margin: 'auto', bgcolor: stringToColor(displayUserName) }}
              />
            </Box>
            <Box className="tw-my-4">
              <Typography variant="subtitle1">
                <strong>{rLIB('Name')}:</strong> {displayUserName}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{rLIB('Email')}:</strong> {displayUserEmail}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{rLIB('Phone')}:</strong> {displayUserPhone != null ? returnFormattedPhoneNumberString(displayUserPhone.replace(/\D/g, '')) : ''}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{rLIB('App Version')}:</strong> {appVersionNumber}
              </Typography>
            </Box>
            <Button
              color="error"
              onClick={() => {
                signOut()
              }}
              variant="contained"
            >
              {rLIB('Log Out')}
            </Button>
          </Box>
        </Box>
      </Card>
    )
    return profileTabJSX
  }

  const rJSX_ClientToggleTab = (): JSX.Element => {
    let clientToggleTabJSX = (
      <Card>
        {rJSX_ProgressBar()}
        <Box className="tw-p-2">
          <Box
            component="div"
            className="tw-p-4"
          >
            {authorizedClientArray.map((client) => (
              <span key={client.key}>{rJSX_ClientToggleButton(client.key)}</span>
            ))}
          </Box>
        </Box>
      </Card>
    )
    return clientToggleTabJSX
  }

  // const rJSX_DiagnosticsTab = (): JSX.Element => {
  // let diagnosticsTabJSX =
  // <Card variant="outlined" className="tw-p-4">
  // 	<Typography variant="subtitle1">uc_RootData_AuthenticatedUser</Typography>
  // 	<Json alphabetized={ true } data={ uc_RootData_AuthenticatedUser }/>
  // 	<Typography variant="subtitle1">uc_RootData_ClientKey</Typography>
  // 	<Json alphabetized={ true } data={ uc_RootData_ClientKey as string }/>
  // 	<Typography variant="subtitle1">uc_RootData_GlobalUser</Typography>
  // 	<Json alphabetized={ true } data={ uc_RootData_GlobalUser }/>
  // 	<Typography variant="subtitle1">uc_RootData_ClientUser</Typography>
  // 	<Json alphabetized={ true } data={ uc_RootData_ClientUser }/>
  // 	<Typography variant="subtitle1">uc_RootData_ClientPermissions</Typography>
  // 	<Json alphabetized={ true } data={ uc_RootData_ClientPermissions }/>
  // 	<Typography variant="subtitle1">uc_RootData_UserPermissions</Typography>
  // 	<Json alphabetized={ true } data={ uc_RootData_UserPermissions }/>
  // </Card>
  // return diagnosticsTabJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let tabsJSX = (
      <TabsBasic
        tabs={[
          { tabHeader: rLIB('User'), tabContent: <Box>{rJSX_ProfileTab()}</Box> },
          { tabHeader: rLIB('Switch Workspace'), tabContent: <Box>{rJSX_ClientToggleTab()}</Box> },
          // { tabHeader: s_ROOT_DATA,			tabContent: <Box>{ rJSX_DiagnosticsTab() }</Box>		},
        ]}
        tabsSettings={{}}
      />
    )
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Profile')}
        pageKey={pageKey}
        content={<Box>{tabsJSX}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
