///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_RecurringEmails_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'recurring_emails', 'main')
}

export const DatabaseRef_RecurringEmail_Document = (clientKey: string, emailKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'recurring_emails', 'main', emailKey)
}

export const DatabaseRef_RecurringEmailLogs_Collection = (clientKey: string, emailKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'recurring_emails', 'main', emailKey, 'logs')
}

export const DatabaseRef_RecurringEmailLog_Document = (clientKey: string, emailKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'recurring_emails', 'main', emailKey, 'logs', logKey)
}
