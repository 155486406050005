///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:


			Clicking on add to queue updates global location
				clientKey,
				emailKey,
				timestamp
			Clicking on remove from queue removes global location


			recurring emails
				save date key as 0000-00-00
				TIMEZONES

				Query by last sent datae key < currentDate AND time < currrentTime

			Both need timezones figured out





			Tabs
				Recurring Emails
					(Add Button)
					(Active or Inactive Toggle)
					Table of Emails (paginated - Table Database Component Probably)
						(Delete Button)
						(Edit Button)
						(Logs Button)
						(Test Send Button)

				Scheduled Emails - One Time (Can send now or schedule for later)
					(Add Button)
					(Pending  or Sent Toggle)
					Table of Emails (paginated - Table Database Component Probably)
						(Delete Button)
						(Edit Button)
						(Logs Button)
						(Test Send Button)

				Report Downloads
					Class Type
					Date Range
					Template Selection


			Email Editor Component
				To
				CC
				BCC
				Subject
				Body
				Attachments (Report templates)
					Report
					Transactional Data Type???
						Filter By Data Bucket???

				Time to Send (Daily or Weekly, Time, Day of Week, Timezone etc.)

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, Tooltip } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_RecurringEmails_Collection } from 'rfbp_aux/services/database_endpoints/clients/data_management/recurring_emails'
import { DatabaseRef_ScheduledEmails_Collection } from 'rfbp_aux/services/database_endpoints/clients/data_management/scheduled_emails'
import { DatabaseRef_CronJobRecurringEmailQueue_Query } from 'rfbp_aux/services/database_endpoints/global_emails'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { RecurringEmailFormDialog } from './components/recurring_email_form_dialog'
import { isValidEmail, ScheduledEmailFormDialog } from './components/scheduled_email_form_dialog'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ScheduledEmailsListPage']['key']

// Table
const tableColumns_ScheduledEmails: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          openScheduledEmailDialog(rowData.key as string, tableHooks.uc_setUserInterface_CustomDialogDisplay)
        }
      },
    },
  }),
  TEMP_ICON: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-2'
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-2'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        switch (getProp(rowData, 'schedule_status', null)) {
          case 'not_in_queue':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Not in Queue')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="circle-pause"
                    sx={{ fontSize: '20px', color: themeVariables.warning_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'in_queue':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('In Queue')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="circle-play"
                    sx={{ fontSize: '20px', color: themeVariables.info_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'success':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email sent successfully')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="badge-check"
                    sx={{ fontSize: '20px', color: themeVariables.success_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'temporary_failure':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email failed to send')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="triangle-exclamation"
                    sx={{ fontSize: '20px', color: themeVariables.error_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'permanent_failure':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email failed to send')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="triangle-exclamation"
                    sx={{ fontSize: '20px', color: themeVariables.error_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          default:
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email not sent yet')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="clock"
                    sx={{ fontSize: '20px', color: themeVariables.gray_400 }}
                  />
                </Box>
              </Tooltip>
            )
            break
        }
        return (
          <Box
            className="tw-inline-block"
            sx={{ maxWidth: '20px' }}
          >
            {cellJSX}
          </Box>
        )
      },
    },
  },
  name: TableCellBasic('name', rLIB('Email'), 'name'),
  timestamp_email_scheduled: TableCellTimestamp('timestamp_email_scheduled', rLIB('Scheduled Send Time'), 'timestamp_email_scheduled', 'D MMM YY h:mm a', true),
  subject: TableCellBasic('subject', rLIB('Subject'), 'subject'),
  to_recipients: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Send To')
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            {getProp(rowData, 'to_recipients', [])
              .sort()
              .map((email: string, index: number) => (
                <Chip
                  key={index}
                  className="tw-mr-1"
                  color={isValidEmail(email) ? 'default' : 'error'}
                  label={<Box>{email}</Box>}
                />
              ))}
          </Box>
        )
        return cellJSX
      },
    },
  },
}

const tableSettings_ScheduledEmails: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_email_scheduled',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_RecurringEmails: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          openRecurringEmailDialog(rowData.key as string, tableHooks.uc_setUserInterface_CustomDialogDisplay)
        }
      },
    },
  }),
  TEMP_ICON: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-2'
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-2'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        switch (getProp(rowData, 'schedule_status', null)) {
          case 'not_in_queue':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Not in Queue')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="circle-pause"
                    sx={{ fontSize: '20px', color: themeVariables.warning_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'in_queue':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('In Queue')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="circle-play"
                    sx={{ fontSize: '20px', color: themeVariables.info_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'success':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email sent successfully')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="badge-check"
                    sx={{ fontSize: '20px', color: themeVariables.success_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'temporary_failure':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email failed to send')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="triangle-exclamation"
                    sx={{ fontSize: '20px', color: themeVariables.error_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'permanent_failure':
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email failed to send')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="triangle-exclamation"
                    sx={{ fontSize: '20px', color: themeVariables.error_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          default:
            cellJSX = (
              <Tooltip
                placement="right"
                title={rLIB('Email not sent yet')}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="clock"
                    sx={{ fontSize: '20px', color: themeVariables.gray_400 }}
                  />
                </Box>
              </Tooltip>
            )
            break
        }
        return (
          <Box
            className="tw-inline-block"
            sx={{ maxWidth: '20px' }}
          >
            {cellJSX}
          </Box>
        )
      },
    },
  },
  name: TableCellBasic('name', rLIB('Email'), 'name'),
  subject: TableCellBasic('subject', rLIB('Subject'), 'subject'),
  to_recipients: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Send To')
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            {getProp(rowData, 'to_recipients', [])
              .sort()
              .map((email: string, index: number) => (
                <Chip
                  key={index}
                  className="tw-mr-1"
                  color={isValidEmail(email) ? 'default' : 'error'}
                  label={<Box>{email}</Box>}
                />
              ))}
          </Box>
        )
        return cellJSX
      },
    },
  },
}

const tableSettings_RecurringEmails: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

///////////////////////////////
// Functions
///////////////////////////////

const openScheduledEmailDialog = (emailKey: string, uc_setUserInterface_CustomDialogDisplay: any) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: <ScheduledEmailFormDialog emailKey={emailKey} />,
      settings: {
        max_width: 'lg',
      },
    },
  })
}

const openRecurringEmailDialog = (emailKey: string, uc_setUserInterface_CustomDialogDisplay: any) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: <RecurringEmailFormDialog emailKey={emailKey} />,
      settings: {
        max_width: 'lg',
      },
    },
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Emails', false) as string
  }, [])

  useEffect(() => {
    // console.log("START")
    // cloudFunctionManageRequest(
    // 	"sendEmail",
    // 	{
    // 		function: "testServerDates",
    // 		date: "2024-03-01",
    // 		time: "07:00",
    // 		timezone: "et",
    // 	}
    // ).then(( res_CFMUR ) => {
    // 	console.log( res_CFMUR )
    // }).catch(( rej_CFMUR ) => {
    // 	console.error( rej_CFMUR )
    // })
    // console.log("START2")
    // cloudFunctionManageRequest(
    // 	"sendEmail",
    // 	{
    // 		function: "testServerDates2",
    // 		date: "day_before_email",
    // 		// date: "day_of_email",
    // 		time: "01:00",
    // 		timezone: "et",
    // 	}
    // ).then(( res_CFMUR ) => {
    // 	console.log( res_CFMUR )
    // }).catch(( rej_CFMUR ) => {
    // 	console.error( rej_CFMUR )
    // })
    // console.log("START3")
    // cloudFunctionManageRequest(
    // 	"sendEmail",
    // 	{
    // 		function: "testEmailDataQuery",
    // 		email_key: "MncXSEeJMEIBvwn3w95A",
    // 		client_key: "jmorrell1"
    // 	}
    // ).then(( res_CFMUR ) => {
    // 	console.log( res_CFMUR )
    // }).catch(( rej_CFMUR ) => {
    // 	console.error( rej_CFMUR )
    // })

    // TODO - fix

    let today = new Date()
    let oneDayAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
    DatabaseGetCollection(DatabaseRef_CronJobRecurringEmailQueue_Query('daily', oneDayAgo))
      .then((res_DGC) => {
        console.log(res_DGC)
      })
      .catch((rej_DGC) => {
        console.error(rej_DGC)
      })
  }, [])

  // Functions
  const tableDatabaseEndpoint_ScheduledEmails = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_email_scheduled', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_ScheduledEmails_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_RecurringEmails = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_RecurringEmails_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // Call Functions

  // JSX Generation
  const rJSX_NewScheduledEmailButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Email Name')}</>,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          name: formSubmittedData.name,
                          timestamp_created: new Date(),
                          status: 'active',
                          type: 'scheduled',
                        }
                        DatabaseAddDocument(DatabaseRef_ScheduledEmails_Collection(res_GCK.clientKey), updateObject, true)
                          .then((res_DAD) => {
                            openScheduledEmailDialog(res_DAD.key, uc_setUserInterface_CustomDialogDisplay)
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                            reject(rej_DAD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Scheduled Email')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New One Time Email')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewRecurringEmailButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Email Name')}</>,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          name: formSubmittedData.name,
                          timestamp_created: new Date(),
                          status: 'active',
                          type: 'scheduled',
                        }
                        DatabaseAddDocument(DatabaseRef_RecurringEmails_Collection(res_GCK.clientKey), updateObject, true)
                          .then((res_DAD) => {
                            openRecurringEmailDialog(res_DAD.key, uc_setUserInterface_CustomDialogDisplay)
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                            reject(rej_DAD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Recurring Email')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Recurring Email')}
      </Button>
    )
    return buttonJSX
  }

  // const rJSX_DownloadReportButton = (): JSX.Element => {
  // let buttonJSX =
  // <Button
  // 	variant="contained"
  // 	color="info"
  // 	className="tw-mr-2"
  // 	onClick={() => {

  // 		// TODO

  // 	}}
  // 	startIcon={ <Icon icon="cloud-arrow-down" /> }
  // >
  // 	{ s_DOWNLOAD_REPORT }
  // </Button>
  // return buttonJSX
  // }

  const rJSX_ScheduledEmailsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_ScheduledEmails}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ScheduledEmails}
            tableSettings={tableSettings_ScheduledEmails}
          />
        </Card>
      )
    }
    return tabContentJSX
  }

  const rJSX_RecurringEmailsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_RecurringEmails}
            tableDatabaseEndpoint={tableDatabaseEndpoint_RecurringEmails}
            tableSettings={tableSettings_RecurringEmails}
          />
        </Card>
      )
    }
    return tabContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Emails') as JSX.Element}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Recurring',
                  tabHeader: rLIB('Recurring Emails'),
                  tabOnChange: () => {},
                  tabContent: rJSX_RecurringEmailsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewRecurringEmailButton(), minJSX: rJSX_NewRecurringEmailButton(), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Scheduled',
                  tabHeader: rLIB('One Time Emails'),
                  tabOnChange: () => {},
                  tabContent: rJSX_ScheduledEmailsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewScheduledEmailButton(), minJSX: rJSX_NewScheduledEmailButton(), sizeCutoff: 0 }],
                },
                // {
                // tabUrlKey: "Downloads",
                // tabHeader: s_DOWNLOADS,
                // tabOnChange: () => {  },
                // tabContent: <></>,
                // tabButtons: [
                // 	{ fullJSX: rJSX_DownloadReportButton(), minJSX: rJSX_DownloadReportButton(), sizeCutoff: 0 },
                // ]
                // },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.ScheduledEmailsListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Emails', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
