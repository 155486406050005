///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Directory_Collection = (clientKey: string, directoryKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory_data', directoryKey, 'main')
}

export const DatabaseRef_Directory_Document = (clientKey: string, directoryKey: string, itemKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory_data', directoryKey, 'main', itemKey)
}

export const DatabaseRef_ActiveDirectory_Query = (clientKey: string, directoryKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory_data', directoryKey, 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_DirectoryNameSearch_Query = (clientKey: string, directoryKey: string, name: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'name', comparator: '==', value: name }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory_data', directoryKey, 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_DirectoryItemLogs_Collection = (clientKey: string, directoryKey: string, itemKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory_data', directoryKey, 'main', itemKey, 'logs')
}

export const DatabaseRef_DirectoryItemLogs_Document = (
  clientKey: string,
  directoryKey: string,
  itemKey: string,
  logKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory_data', directoryKey, 'main', itemKey, 'logs', logKey)
}
