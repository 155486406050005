///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from user interfaces

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { DataItemViewDialog } from 'app/pages/data_transactional/components/dialog_data_item_dialog'
import { DirectoryItemViewDialog } from 'app/pages/directory/components/dialog_directory_view'
import { calculateValuesForDataItemCalculatedFields } from 'app/pages/refine_formulas/services/calculation_functions'
import React from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_DirectoryItemLogs_Document, DatabaseRef_Directory_Document } from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import { DatabaseRef_DataLogs_Document, DatabaseRef_Data_Document } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import { StorageRef_UnauthenticatedQRCodeData_File } from 'rfbp_aux/services/storage_endpoints/data/qr_code_data'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { TsInterface_FormData, TsInterface_FormInputs } from 'rfbp_core/components/form'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormHooksObject,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsInterface_InputHooksObject,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, StorageDeleteFile, StorageUploadFile, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { dynamicSort, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
const s_INVALID_CLIENT_KEY: JSX.Element = <Trans>Invalid Client Key</Trans>
const s_MISSING_REQUIRED_DATA: JSX.Element = <Trans>Missing Required Data</Trans>
const s_FAILED_TO_VIEW: JSX.Element = <Trans>Failed to View</Trans>
const s_ITEM: JSX.Element = <Trans>Item</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const s_UPLOAD: JSX.Element = <Trans>Upload</Trans>
const s_SUBMITTED: JSX.Element = <Trans>Submitted</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_CONFIRMATION_NUMBER: JSX.Element = <Trans>Confirmation Number</Trans>
const s_FAILED_TO_UPLOAD_FILE: JSX.Element = <Trans>Failed to upload file</Trans>
const s_INVALID_DOCUMENT_SELECTION: JSX.Element = <Trans>Invalid document selection</Trans>
const s_DELETE_IMAGE: JSX.Element = <Trans>Delete Image</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_PRIMARY_DATE: JSX.Element = <Trans>Primary Date</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_IMAGE: JSX.Element = <Trans>Are you sure that you want to delete this image?</Trans>

// Gauge Options
const gaugeFeetOptions: TsInterface_UnspecifiedObject[] = [
  { key: 0, value: 0 },
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
  { key: 11, value: 11 },
  { key: 12, value: 12 },
  { key: 13, value: 13 },
  { key: 14, value: 14 },
  { key: 15, value: 15 },
  { key: 16, value: 16 },
  { key: 17, value: 17 },
  { key: 18, value: 18 },
  { key: 19, value: 19 },
  { key: 20, value: 20 },
  { key: 21, value: 21 },
  { key: 22, value: 22 },
  { key: 23, value: 23 },
  { key: 24, value: 24 },
  { key: 25, value: 25 },
  { key: 26, value: 26 },
  { key: 27, value: 27 },
  { key: 28, value: 28 },
  { key: 29, value: 29 },
  { key: 30, value: 30 },
  { key: 31, value: 31 },
  { key: 32, value: 32 },
  { key: 33, value: 33 },
  { key: 34, value: 34 },
  { key: 35, value: 35 },
  { key: 36, value: 36 },
  { key: 37, value: 37 },
  { key: 38, value: 38 },
  { key: 39, value: 39 },
  { key: 40, value: 40 },
  { key: 41, value: 41 },
  { key: 42, value: 42 },
  { key: 43, value: 43 },
  { key: 44, value: 44 },
  { key: 45, value: 45 },
  { key: 46, value: 46 },
  { key: 47, value: 47 },
  { key: 48, value: 48 },
  { key: 49, value: 49 },
  { key: 50, value: 50 },
]

const gaugeInchOptions: TsInterface_UnspecifiedObject[] = [
  { key: 0, value: 0 },
  { key: 0.25, value: 0.25 },
  { key: 0.5, value: 0.5 },
  { key: 0.75, value: 0.75 },
  { key: 1, value: 1 },
  { key: 1.25, value: 1.25 },
  { key: 1.5, value: 1.5 },
  { key: 1.75, value: 1.75 },
  { key: 2, value: 2 },
  { key: 2.25, value: 2.25 },
  { key: 2.5, value: 2.5 },
  { key: 2.75, value: 2.75 },
  { key: 3, value: 3 },
  { key: 3.25, value: 3.25 },
  { key: 3.5, value: 3.5 },
  { key: 3.75, value: 3.75 },
  { key: 4, value: 4 },
  { key: 4.25, value: 4.25 },
  { key: 4.5, value: 4.5 },
  { key: 4.75, value: 4.75 },
  { key: 5, value: 5 },
  { key: 5.25, value: 5.25 },
  { key: 5.5, value: 5.5 },
  { key: 5.75, value: 5.75 },
  { key: 6, value: 6 },
  { key: 6.25, value: 6.25 },
  { key: 6.5, value: 6.5 },
  { key: 6.75, value: 6.75 },
  { key: 7, value: 7 },
  { key: 7.25, value: 7.25 },
  { key: 7.5, value: 7.5 },
  { key: 7.75, value: 7.75 },
  { key: 8, value: 8 },
  { key: 8.25, value: 8.25 },
  { key: 8.5, value: 8.5 },
  { key: 8.75, value: 8.75 },
  { key: 9, value: 9 },
  { key: 9.25, value: 9.25 },
  { key: 9.5, value: 9.5 },
  { key: 9.75, value: 9.75 },
  { key: 10, value: 10 },
  { key: 10.25, value: 10.25 },
  { key: 10.5, value: 10.5 },
  { key: 10.75, value: 10.75 },
  { key: 11, value: 11 },
  { key: 11.25, value: 11.25 },
  { key: 11.5, value: 11.5 },
  { key: 11.75, value: 11.75 },
]

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

const rJSX_SearchResult = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
  additionalSearchData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box
      sx={{ marginLeft: '8px', marginRight: '8px', cursor: 'pointer' }}
      onClick={() => {
        inputHooks.us_setSearchInputValue(option.name)
        inputHooks.us_setOpen(false)
        inputHooks.optionSelectedCallback(option)
      }}
    >
      <Typography>{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
    </Box>
  )
  return searchResultJSX
}

// Form Services
export const returnStartingFormDataFromFormattedFormInputs = (formInputs: TsInterface_FormInputs): TsInterface_UnspecifiedObject => {
  let formData: TsInterface_UnspecifiedObject = {}
  for (let loopFormInputKey in formInputs) {
    let loopFormInput = formInputs[loopFormInputKey]
    if (loopFormInput != null && loopFormInput.key != null) {
      switch (loopFormInput.input_type) {
        case 'multiple_select_checklist':
          formData[loopFormInput.key] = {}
          if (loopFormInput.options != null) {
            for (let loopOptionIndex in loopFormInput.options) {
              let loopOption = loopFormInput.options[loopOptionIndex]
              if (loopOption != null && loopOption.key != null) {
                formData[loopFormInput.key][loopOption.key] = false
              }
            }
          }
          break
        case 'boolean_switch':
          formData[loopFormInput.key] = false
          break
      }
    }
  }
  return formData
}

export const returnCleanFormDataReadyForSubmission = (
  formData: TsInterface_UnspecifiedObject,
  formInputs: TsInterface_FormInputs,
): TsInterface_UnspecifiedObject => {
  for (let loopFormInputKey in formInputs) {
    let loopFormInput = formInputs[loopFormInputKey]
    // Handle Number Inputs
    if (
      loopFormInput != null &&
      loopFormInput.key != null &&
      loopFormInput.data_type === 'number' &&
      formData != null &&
      formData[loopFormInput.key] != null &&
      formData[loopFormInput.key] !== '' &&
      !isNaN(parseFloat(formData[loopFormInput.key]))
    ) {
      formData[loopFormInput.key] = parseFloat(formData[loopFormInput.key])
    }
  }
  return formData
}

export const returnFormInputsFromDatabaseDataFormat = (rawFormFieldData: TsInterface_UnspecifiedObject, clientKey: string | null): TsInterface_FormInputs => {
  let orderedRawFormFields: TsInterface_UnspecifiedObject[] = objectToArray(rawFormFieldData).sort(dynamicSort('order', 'asc'))
  let finalizedFormFields: TsInterface_FormInputs = {
    // display_form_data_json: {
    // 	data_type: 'string',
    // 	input_type: 'display_form_data_json',
    // 	key: '',
    // 	label: <></>
    // }
  }
  for (let loopFormFieldIndex in orderedRawFormFields) {
    let loopFormFieldData = orderedRawFormFields[loopFormFieldIndex]
    if (loopFormFieldData != null && loopFormFieldData.key != null && loopFormFieldData.name != null && loopFormFieldData.status === 'active') {
      let options: TsInterface_UnspecifiedObject[] = []
      let directoryOptions: TsInterface_UnspecifiedObject[] = []
      if (getProp(loopFormFieldData, 'options', null) != null) {
        let sortedRawOptions = objectToArray(loopFormFieldData['options']).sort(dynamicSort('order', 'asc'))
        for (let loopOptionIndex in sortedRawOptions) {
          let loopOptionData = sortedRawOptions[loopOptionIndex]
          if (loopOptionData != null && loopOptionData.value != null) {
            options.push({
              key: loopOptionData.value,
              value: loopOptionData.value,
            })
            directoryOptions.push({
              key: loopOptionData.key,
              value: loopOptionData.value,
            })
          }
        }
      }
      switch (loopFormFieldData.form_type) {
        case 'display':
          if (loopFormFieldData.display_text != null) {
            finalizedFormFields[loopFormFieldData.key] = {
              data_type: 'string',
              key: loopFormFieldData.key,
              input_type: 'custom_form_input_jsx',
              label: <></>,
              required: false,
              renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                let inputJSX = (
                  <Box className="tw-text-left tw-my-4">
                    <Typography variant="body1">{loopFormFieldData.display_text}</Typography>
                  </Box>
                )
                return inputJSX
              },
            }
          }
          break
        case 'short_answer':
          finalizedFormFields[loopFormFieldData.key] = {
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            input_type: 'text_basic',
            required: getProp(loopFormFieldData, 'required', false),
            data_type: 'string',
          }
          break
        case 'number':
          finalizedFormFields[loopFormFieldData.key] = {
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            input_type: 'text_number',
            required: getProp(loopFormFieldData, 'required', false),
            data_type: 'number',
          }
          if (getProp(loopFormFieldData, 'min', null) != null) {
            finalizedFormFields[loopFormFieldData.key]['min'] = getProp(loopFormFieldData, 'min', null)
          }
          if (getProp(loopFormFieldData, 'max', null) != null) {
            finalizedFormFields[loopFormFieldData.key]['max'] = getProp(loopFormFieldData, 'max', null)
          }
          break
        case 'paragraph':
          finalizedFormFields[loopFormFieldData.key] = {
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            input_type: 'text_multiline',
            required: getProp(loopFormFieldData, 'required', false),
            data_type: 'string',
          }
          break
        case 'multiple_choice':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'multiple_choice_radio',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
            options: options,
          }
          break
        case 'checkboxes':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'multiple_select_checklist',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
            options: options,
          }
          break
        case 'dropdown':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'multiple_choice_select',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
            options: options,
          }
          break
        case 'date':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'timestamp_date',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
          }
          break
        case 'time':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'timestamp_time',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
          }
          break
        case 'datetime':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'timestamp_datetime',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
          }
          break
        case 'toggle':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            input_type: 'boolean_switch',
            key: loopFormFieldData.key,
            label: loopFormFieldData.name,
            required: getProp(loopFormFieldData, 'required', false),
          }
          break
        case 'image_upload':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            key: loopFormFieldData.key,
            input_type: 'custom_form_input_jsx',
            label: <></>,
            required: getProp(loopFormFieldData, 'required', false),
            renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
              // Upload Functions
              const readAsDataURL = (file: any) => {
                return new Promise((resolve, reject) => {
                  const fr = new FileReader()
                  fr.onerror = reject
                  fr.onload = () => {
                    resolve(fr.result)
                  }
                  fr.readAsDataURL(file)
                })
              }
              const fileOnSelect = (
                event: React.ChangeEvent<HTMLInputElement>,
                additionalFileUploadParams: TsInterface_UnspecifiedObject,
              ): TsType_UnknownPromise => {
                return new Promise((resolve, reject) => {
                  if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
                    let promiseArray: TsType_UnknownPromise[] = []
                    let files = event.target.files
                    let readFiles: TsInterface_UnspecifiedObject = {}
                    let file = files[0]
                    if (file != null && typeof file === 'object') {
                      promiseArray.push(
                        readAsDataURL(file)
                          .then((res_RADU) => {
                            readFiles[0] = {
                              file_name: file.name,
                              file: file,
                              data_url: res_RADU,
                            }
                          })
                          .catch((rej_RADU) => {
                            // Nothing
                          }),
                      )
                    }
                    Promise.all(promiseArray).finally(() => {
                      // Need Client Key from page

                      // getClientKey( formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey ).then( ( res_GCK ) => {
                      if (clientKey != null) {
                        let imageKey = uuidv4()
                        StorageUploadFile(StorageRef_UnauthenticatedQRCodeData_File(clientKey, imageKey, readFiles[0].file_name), readFiles[0].file, {})
                          .then((res_SUF: any) => {
                            formInputChange(loopFormFieldData.key, 'Captured', true)
                            formInputChange(loopFormFieldData.key + '_key', imageKey, true)
                            formInputChange(loopFormFieldData.key + '_url', getProp(res_SUF, 'url', null), true)
                            formInputChange(loopFormFieldData.key + '_location', 'unauthenticated', true)
                            formInputChange(loopFormFieldData.key + '_name', getProp(file, 'name', null), true)
                            formInputChange(loopFormFieldData.key + '_type', getProp(file, 'type', null), true)
                            resolve(res_SUF)
                          })
                          .catch((rej_SUF) => {
                            if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                              setTimeout(() => {
                                // @ts-expect-error
                                formAdditionalData.us_setShowImage(true)
                                formHooks.ur_forceRerender()
                              }, 1)
                            }
                            console.error(rej_SUF)
                            reject(rej_SUF)
                          })
                      } else {
                        reject({
                          success: false,
                          error: {
                            message: s_FAILED_TO_UPLOAD_FILE,
                            details: s_INVALID_CLIENT_KEY,
                            code: 'ER-D-FS-FOS-01',
                          },
                        })
                      }
                      // }).catch( ( rej_GCK ) => {
                      // 	formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      // 	reject( rej_GCK )
                      // })
                    })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: s_FAILED_TO_UPLOAD_FILE,
                        details: s_INVALID_DOCUMENT_SELECTION,
                        code: 'ER-D-FS-FOS-02',
                      },
                    })
                  }
                })
              }
              // JSX
              let inputJSX = <></>
              if (formData[loopFormFieldData.key + '_url'] != null) {
                // Disabled Input and Preview
                inputJSX = (
                  <Box className="tw-my-4">
                    <Button
                      disabled={true}
                      color="info"
                      variant="outlined"
                      startIcon={<Icon icon="cloud-arrow-up" />}
                    >
                      <>
                        {s_UPLOAD} {loopFormFieldData.name}
                      </>
                    </Button>
                    <Box className="tw-mt-2">
                      <Box
                        className="tw-m-auto tw-inline-block tw-rounded-md tw-mr-2"
                        component="img"
                        sx={{ width: '75px' }}
                        // @ts-expect-error
                        src={formData[loopFormFieldData.key + '_url']}
                      />
                      <Typography
                        variant="body1"
                        className="tw-inline-block"
                      >
                        {formData[loopFormFieldData.key + '_name'] as string}
                      </Typography>
                      <Icon
                        icon="trash"
                        className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-red-600"
                        onClick={() => {
                          formHooks.uc_setUserInterface_ConfirmDialogDisplay({
                            display: true,
                            confirm: {
                              color: 'error',
                              header: s_DELETE_IMAGE,
                              icon: (
                                <Icon
                                  icon="trash"
                                  type="solid"
                                />
                              ),
                              submit_text: s_DELETE,
                              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_IMAGE,
                              submit_callback: () => {
                                return new Promise((resolve, reject) => {
                                  StorageDeleteFile(
                                    StorageRef_UnauthenticatedQRCodeData_File(
                                      formHooks.uc_RootData_ClientKey,
                                      formData[loopFormFieldData.key + '_key'] as string,
                                      formData[loopFormFieldData.key + '_name'] as string,
                                    ),
                                  )
                                    .then((res_SDF) => {
                                      formInputChange(loopFormFieldData.key, null, true)
                                      formInputChange(loopFormFieldData.key + '_key', null, true)
                                      formInputChange(loopFormFieldData.key + '_url', null, true)
                                      formInputChange(loopFormFieldData.key + '_location', null, true)
                                      formInputChange(loopFormFieldData.key + '_name', null, true)
                                      formInputChange(loopFormFieldData.key + '_type', null, true)
                                      formHooks.ur_forceRerender()
                                      resolve({ success: true })
                                    })
                                    .catch((rej_SDF) => {
                                      console.error(rej_SDF)
                                      formInputChange(loopFormFieldData.key, null, true)
                                      formInputChange(loopFormFieldData.key + '_key', null, true)
                                      formInputChange(loopFormFieldData.key + '_url', null, true)
                                      formInputChange(loopFormFieldData.key + '_location', null, true)
                                      formInputChange(loopFormFieldData.key + '_name', null, true)
                                      formInputChange(loopFormFieldData.key + '_type', null, true)
                                      formHooks.ur_forceRerender()
                                      reject({ success: false, error: rej_SDF })
                                    })
                                })
                              },
                            },
                          })
                        }}
                      />
                    </Box>
                  </Box>
                )
              } else {
                // Input
                inputJSX = (
                  <Box className="tw-my-4">
                    <FileUploadButton
                      multiple={false}
                      accept="image/*"
                      onChange={fileOnSelect}
                      button={{
                        text: (
                          <>
                            {s_UPLOAD} {loopFormFieldData.name}
                          </>
                        ),
                        icon: (
                          <Icon
                            icon="cloud-arrow-up"
                            className="tw-mr-2"
                          />
                        ),
                        color: 'info',
                        className: '',
                        variant: 'outlined',
                        disabled: false,
                      }}
                      additionalFileUploadParams={{}}
                    />
                  </Box>
                )
              }
              return inputJSX
            },
          }
          break
        case 'feet_inch_gauge':
          // TODO: Buggy - change to dropdowns - 0-40 for feet and 0.25 to 11.75 for inches
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            key: loopFormFieldData.key,
            input_type: 'custom_form_input_jsx',
            label: loopFormFieldData.name,
            required: loopFormFieldData.required,
            renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
              // Instantiate Variables
              let formInputKey = formInput.key

              // Parse Feet and Inches
              let totalInches = getProp(formData, formInputKey, 0)
              let feet = Math.floor(totalInches / 12)
              let inches = totalInches % 12

              const getFeetValue = (formData: TsInterface_FormData) => {
                let feetValue: string | number = ''
                let totalInches = getProp(formData, formInputKey, 0)
                let feet = Math.floor(totalInches / 12)
                let inches = totalInches % 12
                if (feet > 0 || inches > 0 || getProp(formData, 'MODIFIED_' + formInputKey, false)) {
                  feetValue = feet
                }
                return feetValue
              }

              const getInchesValue = (formData: TsInterface_FormData) => {
                let inchesValue: string | number = ''
                let totalInches = getProp(formData, formInputKey, 0)
                let feet = Math.floor(totalInches / 12)
                let inches = totalInches % 12
                if (feet > 0 || inches > 0 || getProp(formData, 'MODIFIED_' + formInputKey, false)) {
                  inchesValue = inches
                }
                return inchesValue
              }

              const returnDynamicAttributes = () => {
                let dynamicAttributes: TsInterface_UnspecifiedObject = {}
                if (formInput.required === true) {
                  dynamicAttributes.error = true
                }
                let totalInches = getProp(formData, formInputKey, 0)
                let feet = Math.floor(totalInches / 12)
                let inches = totalInches % 12
                if (feet > 0 || inches > 0 || getProp(formData, 'MODIFIED_' + formInputKey, false)) {
                  dynamicAttributes = {}
                }
                return dynamicAttributes
              }

              // JSX
              let inputJSX = (
                <Box className="tw-text-left tw-my-2">
                  <Stack
                    direction="row"
                    spacing={1}
                  >
                    <FormControl sx={{ width: '50%' }}>
                      <InputLabel
                        shrink={true}
                        id={formInputKey}
                      >
                        {formInput.label}
                      </InputLabel>
                      <Select
                        id={formInputKey}
                        label={formInput.label}
                        notched={true}
                        endAdornment={<Box sx={{ paddingRight: '24px' }}>{rLIB('ft')}</Box>}
                        autoWidth={true}
                        onChange={(event, value) => {
                          if (event != null && event.target != null && event.target.value != null) {
                            let newTotalInches = 0
                            if (!isNaN(parseFloat(event.target.value as string))) {
                              newTotalInches = parseFloat(event.target.value as string) * 12
                            }
                            formInputChange(formInputKey, newTotalInches + inches, true)
                            formInputChange('MODIFIED_' + formInputKey, true, true)
                          }
                        }}
                        value={getFeetValue(formData)}
                        required={formInput.required}
                        {...returnDynamicAttributes()}
                      >
                        {objectToArray(gaugeFeetOptions).map((option: TsInterface_UnspecifiedObject) => (
                          <MenuItem
                            key={option['key']}
                            value={option['key']}
                            disabled={option['disabled']}
                          >
                            {option['value']}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl sx={{ width: '50%' }}>
                      <Select
                        endAdornment={<Box sx={{ paddingRight: '24px' }}>{rLIB('in')}</Box>}
                        autoWidth={true}
                        onChange={(event, value) => {
                          if (event != null && event.target != null && event.target.value != null) {
                            let newTotalInches = 0
                            if (!isNaN(parseFloat(event.target.value as string))) {
                              newTotalInches = parseFloat(event.target.value as string)
                            }
                            formInputChange(formInputKey, feet * 12 + newTotalInches, true)
                            formInputChange('MODIFIED_' + formInputKey, true, true)
                          }
                        }}
                        value={getInchesValue(formData)}
                        required={formInput.required}
                        {...returnDynamicAttributes()}
                      >
                        {objectToArray(gaugeInchOptions).map((option: TsInterface_UnspecifiedObject) => (
                          <MenuItem
                            key={option['key']}
                            value={option['key']}
                            disabled={option['disabled']}
                          >
                            {option['value']}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Box>
              )
              return inputJSX
            },
          }
          break
        case 'directory_multiple_choice':
          finalizedFormFields[loopFormFieldData.key] = {
            data_type: 'string',
            key: loopFormFieldData.key,
            input_type: 'custom_form_input_jsx',
            label: loopFormFieldData.name,
            required: loopFormFieldData.required,
            options: directoryOptions,
            renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
              const returnDynamicAttributes = () => {
                let dynamicAttributes: TsInterface_UnspecifiedObject = {}
                if (formInput.required === true) {
                  if (formData[formInput.key] === '' || formData[formInput.key] == null) {
                    dynamicAttributes.error = true
                  }
                }
                return dynamicAttributes
              }
              // TODO: Reevaluate with longer lists
              let inputJSX = <></>
              if (objectToArray(getProp(formInput, 'options', {})).length > 0) {
                let options = objectToArray(getProp(formInput, 'options', {}))
                if (getProp(loopFormFieldData, 'include_other_option', null) === 'top_of_list') {
                  options.unshift({ key: 'other', value: 'Other' })
                } else if (getProp(loopFormFieldData, 'include_other_option', null) === 'bottom_of_list') {
                  options.push({ key: 'other', value: 'Other' })
                }

                inputJSX = (
                  <Box className="tw-text-left tw-mb-2 tw-mt-4">
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel
                        shrink={true}
                        id={formInput.key}
                      >
                        {formInput.label}
                      </InputLabel>
                      <Select
                        id={formInput.key}
                        label={formInput.label}
                        notched={true}
                        autoWidth={true}
                        onChange={(event, value) => {
                          if (event != null && event.target != null && event.target.value != null) {
                            let matchingOption = directoryOptions.find((option) => option.value === event.target.value)
                            formInputChange(formInput.key, event.target.value, true)
                            if (matchingOption != null && matchingOption.key != null) {
                              formInputChange(formInput.key + '_key', matchingOption.key, true)
                            }
                          }
                        }}
                        value={formData[formInput.key] || ''}
                        required={formInput.required}
                        {...returnDynamicAttributes()}
                      >
                        {options.map((option: TsInterface_UnspecifiedObject) => (
                          <MenuItem
                            key={option['key']}
                            value={option['value']}
                            disabled={option['disabled']}
                          >
                            {option['value']}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )
              } else if (clientKey != null && loopFormFieldData != null && loopFormFieldData.associated_directory_item_key != null) {
                inputJSX = (
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ marginBottom: '4px', opacity: '.5' }}
                    >
                      {loopFormFieldData.name}
                    </Typography>
                    <SearchInput
                      clientKey={clientKey}
                      searchIndexKey={loopFormFieldData.associated_directory_item_key}
                      searchFilters={[]}
                      // searchFilters={['status != deleted']} // TODO: Implement status as filterable field
                      searchResultRenderer={rJSX_SearchResult}
                      additionalSearchData={{}}
                      defaultSearchValue={(formData[formInput.key] as string) || ''}
                      optionSelectedCallback={(option: TsInterface_UnspecifiedObject) => {
                        formInputChange(formInput.key, option.name, true)
                        formInputChange(formInput.key + '_key', option.key, true)
                      }}
                      sx={{
                        width: '100%',
                        marginBottom: '8px',
                      }}
                    />
                  </Box>
                )
              }
              return inputJSX
            },
          }
          break
      }
    }
  }
  return finalizedFormFields
}

// Form to Table Services
const editFormField = (field: TsInterface_UnspecifiedObject, dataItem: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
  let itemKey = getProp(dataItem, 'key', '')
  let formInputs = returnFormInputsFromDatabaseDataFormat({ [field.key]: field }, tableHooks.uc_RootData_ClientKey)
  let originalValue = getProp(getProp(dataItem, 'data', {}), field.key, '')
  if (field.form_type === 'checkboxes') {
    originalValue = getProp(getProp(dataItem, 'data', {}), field.key, {})
  } else if (field.form_type === 'toggle') {
    if (originalValue === 'true') {
      originalValue = true
    } else {
      originalValue = false
    }
  }
  tableHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: { [field.key]: originalValue },
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                if (field.form_type === 'checkboxes') {
                  // String version of selected options
                  let originalSelectedOptions = []
                  if (dataItem['original_data'] != null && dataItem['original_data'][field.key] != null) {
                    for (let loopOptionKey in dataItem['original_data'][field.key]) {
                      if (dataItem['original_data'][field.key][loopOptionKey] === true || dataItem['original_data'][field.key][loopOptionKey] === 'true') {
                        originalSelectedOptions.push(loopOptionKey)
                      }
                    }
                  }
                  let selectedOptions = []
                  for (let loopOptionKey in formSubmittedData[field.key]) {
                    if (formSubmittedData[field.key][loopOptionKey] === true || formSubmittedData[field.key][loopOptionKey] === 'true') {
                      selectedOptions.push(loopOptionKey)
                    }
                  }
                  selectedOptions.sort()
                  updateArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Data_Document(res_GCK.clientKey, itemKey),
                      data: {
                        data: {
                          [field.key]: formSubmittedData[field.key],
                        },
                      },
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_DataLogs_Document(res_GCK.clientKey, itemKey, logKey),
                      data: {
                        key: logKey,
                        timestamp: new Date(),
                        text: getProp(field, 'name', ' Field') + ' updated from ' + originalSelectedOptions.join(', ') + ' to ' + selectedOptions.join(', '),
                        associated_user_name: getProp(tableHooks.uc_RootData_GlobalUser, 'name', ''),
                      },
                    },
                  ]
                } else {
                  updateArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Data_Document(res_GCK.clientKey, itemKey),
                      data: {
                        data: {
                          [field.key]: formSubmittedData[field.key].toString(),
                        },
                      },
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_DataLogs_Document(res_GCK.clientKey, itemKey, logKey),
                      data: {
                        key: logKey,
                        timestamp: new Date(),
                        text: getProp(field, 'name', ' Field') + ' updated from ' + originalValue + ' to ' + formSubmittedData[field.key],
                        associated_user_name: getProp(tableHooks.uc_RootData_GlobalUser, 'name', ''),
                      },
                    },
                  ]
                }

                // Batch Save
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    resolve(res_DBU)
                  })
                  .catch((rej_DBU) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                    reject(rej_DBU)
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit')} {getProp(field, 'name', rLIB('Property'))}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const returnDefaultFormTableColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (
          highlightedCellObject != null &&
          rowData != null &&
          rowData.data != null &&
          formField != null &&
          formField.key != null &&
          highlightedCellObject[rowData.key as string] != null &&
          highlightedCellObject[rowData.key as string][formField.key as string] === true
        ) {
          cellCSS = 'tw-bg-amber-400'
        }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        let altCellValueJSX = <></>
        let editIconJSX = <></>
        if (getProp(tableAdditionalData, 'editable', false) === true) {
          editIconJSX = (
            <Icon
              icon="edit"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
              onClick={() => {
                editFormField(formField, rowData, tableHooks)
              }}
            />
          )
        }
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          rowData.data != null &&
          getProp(rowData, 'data', {})[formField.key as string] != null
        ) {
          cellValue = getProp(rowData, 'data', {})[formField.key as string]
        }
        if (
          altDataCellObject != null &&
          rowData != null &&
          rowData.data != null &&
          formField != null &&
          formField.key != null &&
          altDataCellObject[rowData.key as string] != null &&
          altDataCellObject[rowData.key as string][formField.key as string] != null
        ) {
          if (
            altDataCellObject[rowData.key as string][formField.key as string] === '' ||
            altDataCellObject[rowData.key as string][formField.key as string] === null
          ) {
            altCellValueJSX = <Box className="tw-text-red-600 tw-italic">({s_MISSING})</Box>
          } else {
            altCellValueJSX = <Box className="tw-text-red-600">{altDataCellObject[rowData.key as string][formField.key as string]}</Box>
          }
          if (cellValue === '') {
            cellValue = <Box className="tw-italic">({s_MISSING})</Box>
          }
        }
        let cellJSX = (
          <Box>
            {cellValue}
            {altCellValueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

const returnMultipleSelectFormTableColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (
          highlightedCellObject != null &&
          rowData != null &&
          rowData.key != null &&
          formField != null &&
          formField.key != null &&
          highlightedCellObject[rowData.key as string] != null &&
          highlightedCellObject[rowData.key as string][formField.key as string] === true
        ) {
          cellCSS = 'tw-bg-amber-400'
        }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        let altCellValueJSX = <></>
        let editIconJSX = <></>
        if (getProp(tableAdditionalData, 'editable', false) === true) {
          editIconJSX = (
            <Icon
              icon="edit"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
              onClick={() => {
                editFormField(formField, rowData, tableHooks)
              }}
            />
          )
        }
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          rowData.data != null &&
          getProp(rowData, 'data', {})[formField.key as string] != null
        ) {
          let trueLoopOptions: string[] = []
          for (let loopOptionKey in getProp(rowData, 'data', {})[formField.key as string]) {
            let loopOption = getProp(rowData, 'data', {})[formField.key as string][loopOptionKey]
            if (loopOption === true || loopOption === 'true') {
              trueLoopOptions.push(loopOptionKey)
            }
          }
          cellValue = trueLoopOptions.join(', ')
        }
        if (
          altDataCellObject != null &&
          rowData != null &&
          rowData.data != null &&
          formField != null &&
          formField.key != null &&
          altDataCellObject[rowData.key as string] != null &&
          altDataCellObject[rowData.key as string][formField.key as string] != null
        ) {
          // TODO - check someday maybe
          let trueLoopOptions: string[] = []
          for (let loopOptionKey in altDataCellObject[rowData.key as string][formField.key as string]) {
            let loopOption = altDataCellObject[rowData.key as string][formField.key as string][loopOptionKey]
            if (loopOption === true || loopOption === 'true') {
              trueLoopOptions.push(loopOptionKey)
            }
          }
          cellValue = <Box className="tw-text-red-600">{trueLoopOptions.join(', ')}</Box>
        }
        let cellJSX = (
          <Box>
            {cellValue}
            {altCellValueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

const returnBooleanFormTableColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (
          highlightedCellObject != null &&
          rowData != null &&
          rowData.key != null &&
          formField != null &&
          formField.key != null &&
          highlightedCellObject[rowData.key as string] != null &&
          highlightedCellObject[rowData.key as string][formField.key as string] === true
        ) {
          cellCSS = 'tw-bg-amber-400'
        }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        let altCellValueJSX = <></>
        let editIconJSX = <></>
        if (getProp(tableAdditionalData, 'editable', false) === true) {
          editIconJSX = (
            <Icon
              icon="edit"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
              onClick={() => {
                editFormField(formField, rowData, tableHooks)
              }}
            />
          )
        }
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          rowData.data != null &&
          getProp(rowData, 'data', {})[formField.key as string] != null &&
          (getProp(rowData, 'data', {})[formField.key as string] === true || getProp(rowData, 'data', {})[formField.key as string] === 'true')
        ) {
          cellValue = 'true'
        } else {
          cellValue = 'false'
        }
        if (
          altDataCellObject != null &&
          rowData != null &&
          rowData.data != null &&
          formField != null &&
          formField.key != null &&
          altDataCellObject[rowData.key as string] != null &&
          altDataCellObject[rowData.key as string][formField.key as string] != null
        ) {
          if (
            altDataCellObject[rowData.key as string][formField.key as string] === '' ||
            altDataCellObject[rowData.key as string][formField.key as string] === null
          ) {
            altCellValueJSX = <Box className="tw-text-red-600 tw-italic">({s_MISSING})</Box>
          } else {
            if (
              altDataCellObject[rowData.key as string][formField.key as string] === true ||
              altDataCellObject[rowData.key as string][formField.key as string] === 'true'
            ) {
              altCellValueJSX = <Box className="tw-text-red-600">{'true'}</Box>
            } else {
              altCellValueJSX = <Box className="tw-text-red-600">{'false'}</Box>
            }
          }
          if (cellValue === '') {
            cellValue = <Box className="tw-italic">({s_MISSING})</Box>
          }
        }
        // Final JSX
        let cellJSX = (
          <Box>
            {cellValue}
            {altCellValueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

const returnImageFormTableColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (
          highlightedCellObject != null &&
          rowData != null &&
          rowData.data != null &&
          formField != null &&
          formField.key != null &&
          highlightedCellObject[rowData.key as string] != null &&
          highlightedCellObject[rowData.key as string][formField.key as string] === true
        ) {
          cellCSS = 'tw-bg-amber-400'
        }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          rowData.data != null &&
          getProp(rowData, 'data', {})[formField.key as string] != null
        ) {
          cellValue = getProp(rowData, 'data', {})[formField.key as string]
        }
        let cellJSX = (
          <Box
            className="tw-underline tw-cursor-pointer"
            sx={{ color: themeVariables.info_main }}
            onClick={() => {
              if (
                formField != null &&
                formField.key != null &&
                rowData != null &&
                rowData.data != null &&
                getProp(rowData.data, formField.key + '_url', null) != null
              ) {
                // Open Custom Dialog with Image
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <Box>
                        <Box
                          className="tw-m-auto tw-rounded-md"
                          component="img"
                          sx={{ width: '100%' }}
                          src={getProp(rowData.data, formField.key + '_url', null)}
                        />
                      </Box>
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              }
            }}
          >
            {cellValue}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

export const returnTableColumnsFromDatabaseFormDataFormat = (
  rawFormFieldData: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
  uc_setUserInterface_SnackbarDisplay: any,
  includeDataOriginationSource: boolean,
): TsInterface_TableColumns => {
  let orderedRawFormFields: TsInterface_UnspecifiedObject[] = objectToArray(rawFormFieldData).sort(dynamicSort('order', 'asc'))
  let formattedTableColumns: TsInterface_TableColumns = {
    MANAGE: TableCellManage({
      view: {
        icon: (
          <Icon
            type="solid"
            icon="magnifying-glass"
          />
        ),
        label: <>{s_VIEW}</>,
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData.key != null) {
            // Custom Component for View - Reuse with transactional data???
            if (rowData != null && rowData.key != null && rowData.key !== '') {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: <DataItemViewDialog itemKey={rowData.key as string} />,
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            } else {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: (
                    <>
                      {s_FAILED_TO_VIEW} {getProp(rowData, 'name', s_ITEM)}
                    </>
                  ),
                  details: s_MISSING_REQUIRED_DATA,
                  code: 'ER-D-FS-V-01',
                },
              })
            }
          }
        },
      },
      run_calculations: {
        icon: (
          <Icon
            type="solid"
            icon="calculator-simple"
          />
        ),
        label: rLIB('Rerun Calculations'),
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData.key != null) {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                calculateValuesForDataItemCalculatedFields(res_GCK.clientKey, rowData.key as string, null, null, null, null, null, 'save')
                  .then((res_DVFDICF) => {
                    // uc_setUserInterface_SnackbarDisplay({
                    //   display: true,
                    //   snackbar: {
                    //     message: rLIB('Calculations Recalculated'),
                    //     alertType: 'info',
                    //     verticalAlignment: 'top',
                    //     horizontalAlignment: 'rught',
                    //   },
                    // })
                  })
                  .catch((rej_DVFDICF) => {
                    console.error(rej_DVFDICF)
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        },
      },
    }),
    timestamp_submitted: TableCellTimestamp('timestamp_submitted', s_SUBMITTED, 'timestamp_submitted', 'D MMM YYYY h:mm a', true),
    timestamp_primary_query: TableCellTimestamp('timestamp_primary_query', s_PRIMARY_DATE, 'timestamp_primary_query', 'D MMM YYYY h:mm a', true),
    confirmation_number: TableCellBasic('confirmation_number', s_CONFIRMATION_NUMBER, 'confirmation_number'),
  }
  if (includeDataOriginationSource === true) {
    formattedTableColumns['associated_data_origination_type'] = {
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Data Source')
        },
        header_sort_by: 'associated_data_origination_type',
      },
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          switch (rowData.associated_data_origination_type) {
            case 'csv_import':
              cellJSX = (
                <Chip
                  variant="outlined"
                  color="success"
                  label={
                    <Box>
                      <Icon
                        icon="file-csv"
                        className="tw-mr-2"
                      />
                      {rLIB('CSV Import')}
                    </Box>
                  }
                />
              )
              break
            case 'email_file_attachment':
              cellJSX = (
                <Chip
                  variant="outlined"
                  color="info"
                  label={
                    <Box>
                      <Icon
                        icon="envelope"
                        className="tw-mr-2"
                      />
                      {rLIB('Emailed CSV')}
                    </Box>
                  }
                />
              )
              break
            case 'qr_code':
              cellJSX = (
                <Chip
                  variant="outlined"
                  color="error"
                  label={
                    <Box>
                      <Icon
                        icon="qrcode"
                        className="tw-mr-2"
                      />
                      {rLIB('QR Code')}
                    </Box>
                  }
                />
              )
              break
            default:
              cellJSX = (
                <Chip
                  variant="outlined"
                  color="primary"
                  label={
                    <Box>
                      <Icon
                        icon="file-import"
                        className="tw-mr-2"
                      />
                      {rowData.associated_data_origination_type}
                    </Box>
                  }
                />
              )
              break
          }

          return cellJSX
        },
      },
    }
  }
  for (let loopFormFieldIndex in orderedRawFormFields) {
    let loopFormFieldData = orderedRawFormFields[loopFormFieldIndex]
    if (loopFormFieldData != null && loopFormFieldData.key != null && loopFormFieldData.name != null && loopFormFieldData.status === 'active') {
      let options: TsInterface_UnspecifiedObject[] = []
      if (getProp(loopFormFieldData, 'options', null) != null) {
        let sortedRawOptions = objectToArray(loopFormFieldData['options']).sort(dynamicSort('order', 'asc'))
        for (let loopOptionIndex in sortedRawOptions) {
          let loopOptionData = sortedRawOptions[loopOptionIndex]
          if (loopOptionData != null && loopOptionData.value != null) {
            options.push({
              key: loopOptionData.value,
              value: loopOptionData.value,
            })
          }
        }
      }
      switch (loopFormFieldData.form_type) {
        case 'display':
          // Nothing
          break
        case 'short_answer':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'number':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'paragraph':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'multiple_choice':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'checkboxes':
          formattedTableColumns[loopFormFieldData.key] = returnMultipleSelectFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'dropdown':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'date':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'time':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'datetime':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'toggle':
          formattedTableColumns[loopFormFieldData.key] = returnBooleanFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'image_upload':
          formattedTableColumns[loopFormFieldData.key] = returnImageFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'feet_inch_gauge':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'directory_multiple_choice':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
      }
    }
  }

  return formattedTableColumns
}

const returnDefaultFormTableRootColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        // if(
        // 	highlightedCellObject != null &&
        // 	rowData != null &&
        // 	rowData.data != null &&
        // 	formField != null &&
        // 	formField.key != null &&
        // 	highlightedCellObject[ rowData.key as string ] != null &&
        // 	highlightedCellObject[ rowData.key as string ][ formField.key as string ] === true
        // ){
        // 	cellCSS = "tw-bg-amber-400"
        // }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        let altCellValueJSX = <></>
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          // rowData.data != null &&
          rowData[formField.key as string] != null
        ) {
          cellValue = <>{rowData[formField.key as string]}</>
        }
        if (
          altDataCellObject != null &&
          rowData != null &&
          // rowData.data != null &&
          formField != null &&
          formField.key != null &&
          altDataCellObject[rowData.key as string] != null &&
          altDataCellObject[rowData.key as string][formField.key as string] != null
        ) {
          if (
            altDataCellObject[rowData.key as string][formField.key as string] === '' ||
            altDataCellObject[rowData.key as string][formField.key as string] === null
          ) {
            altCellValueJSX = <Box className="tw-text-red-600 tw-italic">({s_MISSING})</Box>
          } else {
            altCellValueJSX = <Box className="tw-text-red-600">{altDataCellObject[rowData.key as string][formField.key as string]}</Box>
          }
          if (cellValue === '') {
            cellValue = <Box className="tw-italic">({s_MISSING})</Box>
          }
        }
        let cellJSX = (
          <Box>
            {cellValue}
            {altCellValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

const returnMultipleSelectFormTableRootColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        // if(
        // 	highlightedCellObject != null &&
        // 	rowData != null &&
        // 	rowData.key != null &&
        // 	formField != null &&
        // 	formField.key != null &&
        // 	highlightedCellObject[ rowData.key as string ] != null &&
        // 	highlightedCellObject[ rowData.key as string ][ formField.key as string ] === true
        // ){
        // 	cellCSS = "tw-bg-amber-400"
        // }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        let altCellValueJSX = <></>
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          // rowData.data != null &&
          rowData[formField.key as string] != null
        ) {
          let trueLoopOptions: string[] = []
          // @ts-expect-error
          for (let loopOptionKey in rowData[formField.key as string]) {
            // @ts-expect-error
            let loopOption = rowData[formField.key as string][loopOptionKey]
            if (loopOption === true || loopOption === 'true') {
              trueLoopOptions.push(loopOptionKey)
            }
          }
          cellValue = trueLoopOptions.join(', ')
        }
        if (
          altDataCellObject != null &&
          rowData != null &&
          // rowData.data != null &&
          formField != null &&
          formField.key != null &&
          altDataCellObject[rowData.key as string] != null &&
          altDataCellObject[rowData.key as string][formField.key as string] != null
        ) {
          // TODO - check someday maybe
          let trueLoopOptions: string[] = []
          for (let loopOptionKey in altDataCellObject[rowData.key as string][formField.key as string]) {
            let loopOption = altDataCellObject[rowData.key as string][formField.key as string][loopOptionKey]
            if (loopOption === true || loopOption === 'true') {
              trueLoopOptions.push(loopOptionKey)
            }
          }
          cellValue = <Box className="tw-text-red-600">{trueLoopOptions.join(', ')}</Box>
        }
        let cellJSX = (
          <Box>
            {cellValue}
            {altCellValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

const returnBooleanFormTableRootColumn = (
  formField: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumn => {
  return {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        // if(
        // 	highlightedCellObject != null &&
        // 	rowData != null &&
        // 	rowData.key != null &&
        // 	formField != null &&
        // 	formField.key != null &&
        // 	highlightedCellObject[ rowData.key as string ] != null &&
        // 	highlightedCellObject[ rowData.key as string ][ formField.key as string ] === true
        // ){
        // 	cellCSS = "tw-bg-amber-400"
        // }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellValue: string | JSX.Element = ''
        let altCellValueJSX = <></>
        if (
          formField != null &&
          formField.key != null &&
          rowData != null &&
          // rowData.data != null &&
          rowData[formField.key as string] != null &&
          (rowData[formField.key as string] === true || rowData[formField.key as string] === 'true')
        ) {
          cellValue = 'true'
        } else {
          cellValue = 'false'
        }
        if (
          altDataCellObject != null &&
          rowData != null &&
          // rowData.data != null &&
          formField != null &&
          formField.key != null &&
          altDataCellObject[rowData.key as string] != null &&
          altDataCellObject[rowData.key as string][formField.key as string] != null
        ) {
          if (
            altDataCellObject[rowData.key as string][formField.key as string] === '' ||
            altDataCellObject[rowData.key as string][formField.key as string] === null
          ) {
            altCellValueJSX = <Box className="tw-text-red-600 tw-italic">({s_MISSING})</Box>
          } else {
            if (
              altDataCellObject[rowData.key as string][formField.key as string] === true ||
              altDataCellObject[rowData.key as string][formField.key as string] === 'true'
            ) {
              altCellValueJSX = <Box className="tw-text-red-600">{'true'}</Box>
            } else {
              altCellValueJSX = <Box className="tw-text-red-600">{'false'}</Box>
            }
          }
          if (cellValue === '') {
            cellValue = <Box className="tw-italic">({s_MISSING})</Box>
          }
        }
        // Final JSX
        let cellJSX = (
          <Box>
            {cellValue}
            {altCellValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return formField.name
      },
      header_sort_by: null,
    },
  }
}

export const returnTableColumnsFromDatabaseFormDataForPersistentDirectoryItems = (
  rawFormFieldData: TsInterface_UnspecifiedObject,
  highlightedCellObject: TsInterface_UnspecifiedObject,
  altDataCellObject: TsInterface_UnspecifiedObject,
): TsInterface_TableColumns => {
  let orderedRawFormFields: TsInterface_UnspecifiedObject[] = objectToArray(rawFormFieldData).sort(dynamicSort('order', 'asc'))
  let formattedTableColumns: TsInterface_TableColumns = {
    MANAGE: TableCellManage({
      view: {
        icon: (
          <Icon
            type="solid"
            icon="magnifying-glass"
          />
        ),
        label: rLIB('View'),
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData.key != null) {
            // Custom Component for View - Reuse with transactional data???
            if (
              tableAdditionalData != null &&
              tableAdditionalData.associated_class_key != null &&
              tableAdditionalData.associated_class_key !== '' &&
              rowData != null &&
              rowData.key != null &&
              rowData.key !== ''
            ) {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <DirectoryItemViewDialog
                      classKey={tableAdditionalData.associated_class_key}
                      itemKey={rowData.key as string}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            } else {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: (
                    <>
                      {s_FAILED_TO_VIEW} {getProp(rowData, 'name', s_ITEM)}
                    </>
                  ),
                  details: s_MISSING_REQUIRED_DATA,
                  code: 'ER-D-FS-V-01',
                },
              })
            }
          }
        },
      },
      delete: {
        icon: (
          <Icon
            type="solid"
            icon="trash-xmark"
          />
        ),
        label: rLIB('Delete'),
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          if (rowData.key != null) {
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                icon: <Icon icon="trash-xmark" />,
                header: (
                  <>
                    {rLIB('Delete')} {getProp(rowData, 'name', rLIB('Item'))}
                  </>
                ),
                text: (
                  <>
                    {rLIB('Are you sure you want to delete')} {getProp(rowData, 'name', rLIB('this item'))}?
                  </>
                ),
                submit_text: s_DELETE,
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                        let classKey = tableAdditionalData.associated_class_key
                        let itemKey = rowData.key as string
                        let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                        updateArray = [
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_Directory_Document(res_GCK.clientKey, classKey, itemKey),
                            data: {
                              status: 'deleted',
                            },
                          },
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_DirectoryItemLogs_Document(res_GCK.clientKey, classKey, itemKey, logKey),
                            data: {
                              key: logKey,
                              timestamp: new Date(),
                              text: getProp(rowData, 'name', 'Item') + ' deleted',
                              associated_user_name: getProp(tableHooks.uc_RootData_GlobalUser, 'name', ''),
                            },
                          },
                        ]
                        DatabaseBatchUpdate(updateArray)
                          .then((res_DBU) => {
                            resolve(res_DBU)
                          })
                          .catch((rej_DBU) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                            reject(rej_DBU)
                          })
                      })
                      .catch((rej_GCK) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
            })
          }
        },
      },
    }),
  }
  for (let loopFormFieldIndex in orderedRawFormFields) {
    let loopFormFieldData = orderedRawFormFields[loopFormFieldIndex]
    if (loopFormFieldData != null && loopFormFieldData.key != null && loopFormFieldData.name != null && loopFormFieldData.status === 'active') {
      let options: TsInterface_UnspecifiedObject[] = []
      if (getProp(loopFormFieldData, 'options', null) != null) {
        let sortedRawOptions = objectToArray(loopFormFieldData['options']).sort(dynamicSort('order', 'asc'))
        for (let loopOptionIndex in sortedRawOptions) {
          let loopOptionData = sortedRawOptions[loopOptionIndex]
          if (loopOptionData != null && loopOptionData.value != null) {
            options.push({
              key: loopOptionData.value,
              value: loopOptionData.value,
            })
          }
        }
      }
      switch (loopFormFieldData.form_type) {
        case 'display':
          // Nothing
          break
        case 'short_answer':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'number':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'paragraph':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'multiple_choice':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'checkboxes':
          formattedTableColumns[loopFormFieldData.key] = returnMultipleSelectFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'dropdown':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'date':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'time':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'datetime':
          formattedTableColumns[loopFormFieldData.key] = returnDefaultFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
        case 'toggle':
          formattedTableColumns[loopFormFieldData.key] = returnBooleanFormTableRootColumn(loopFormFieldData, highlightedCellObject, altDataCellObject)
          break
      }
    }
  }
  return formattedTableColumns
}
