//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:
		Probably ,move out of boilerplate and make this whole file a custom param for chat header settings

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Card, Chip, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material/'
import { returnFormInputsFromDatabaseDataFormat } from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ClassFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_DirectoryItemLogs_Collection,
  DatabaseRef_DirectoryItemLogs_Document,
  DatabaseRef_Directory_Document,
} from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, generateRandomString, getProp, objectToArray, returnFormattedDate, returnTimezoneAbbreviation } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_DirectoryViewDialog {
  classKey: string
  itemKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const DirectoryItemViewDialog: React.FC<TsInterface_DirectoryViewDialog> = ({ classKey, itemKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_directoryItem, us_setDirectoryItem] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formFields, us_setFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_itemLogs, us_setItemLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_orderedFormFields, us_setOrderedFormFields] = useState<TsInterface_UnspecifiedObject[]>([])
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDirectoryItem(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Directory_Document(res_GCK.clientKey, classKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, classKey, itemKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFormFields(newData)
      ur_forceRerender()
    }
    if (classKey != null && classKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, classKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setFormFields({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, classKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setItemLogs(newData)
      ur_forceRerender()
    }
    if (classKey != null && classKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_DirectoryItemLogs_Collection(res_GCK.clientKey, classKey, itemKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setItemLogs({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, classKey, itemKey])

  useEffect(() => {
    let sortedFields: TsInterface_UnspecifiedObject[] = []
    for (let loopFieldKey in us_formFields) {
      let loopField = us_formFields[loopFieldKey]
      if (loopField != null && loopField.status === 'active') {
        sortedFields.push(loopField)
      }
    }
    sortedFields.sort(dynamicSort('order', 'asc'))
    us_setOrderedFormFields(sortedFields)
  }, [us_formFields])

  // Functions
  const editItemField = (field: TsInterface_UnspecifiedObject) => {
    let formInputs = returnFormInputsFromDatabaseDataFormat({ [field.key]: field }, uc_RootData_ClientKey)
    let originalValue = getProp(us_directoryItem, field.key, '')
    if (field.form_type === 'checkboxes') {
      originalValue = getProp(getProp(us_directoryItem, 'data', {}), field.key, {})
    } else if (field.form_type === 'toggle') {
      if (originalValue === 'true') {
        originalValue = true
      } else {
        originalValue = false
      }
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_directoryItem,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  if (field.form_type === 'checkboxes') {
                    // String version of selected options
                    let originalSelectedOptions = []
                    if (us_directoryItem['original_data'] != null && us_directoryItem['original_data'][field.key] != null) {
                      for (let loopOptionKey in us_directoryItem['original_data'][field.key]) {
                        if (
                          us_directoryItem['original_data'][field.key][loopOptionKey] === true ||
                          us_directoryItem['original_data'][field.key][loopOptionKey] === 'true'
                        ) {
                          originalSelectedOptions.push(loopOptionKey)
                        }
                      }
                    }
                    let selectedOptions = []
                    for (let loopOptionKey in formSubmittedData[field.key]) {
                      if (formSubmittedData[field.key][loopOptionKey] === true || formSubmittedData[field.key][loopOptionKey] === 'true') {
                        selectedOptions.push(loopOptionKey)
                      }
                    }
                    selectedOptions.sort()
                    updateArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Directory_Document(res_GCK.clientKey, classKey, itemKey),
                        data: formSubmittedData,
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_DirectoryItemLogs_Document(res_GCK.clientKey, classKey, itemKey, logKey),
                        data: {
                          key: logKey,
                          timestamp: new Date(),
                          text: getProp(field, 'name', ' Field') + ' updated from ' + originalValue + ' to ' + formSubmittedData[field.key],
                          associated_user_name: getProp(uc_RootData_GlobalUser, 'name', ''),
                        },
                      },
                    ]
                  } else {
                    updateArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Directory_Document(res_GCK.clientKey, classKey, itemKey),
                        data: formSubmittedData,
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_DirectoryItemLogs_Document(res_GCK.clientKey, classKey, itemKey, logKey),
                        data: {
                          key: logKey,
                          timestamp: new Date(),
                          text: getProp(field, 'name', ' Field') + ' updated from ' + originalValue + ' to ' + formSubmittedData[field.key],
                          associated_user_name: getProp(uc_RootData_GlobalUser, 'name', ''),
                        },
                      },
                    ]
                  }
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit')} {getProp(field, 'name', rLIB('Property'))}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_ItemFieldEditIcon = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (field.form_type !== 'display' && field.form_type !== 'image_upload') {
      iconJSX = (
        <Icon
          sx={{
            'cursor': 'pointer',
            'opacity': 0.3,
            '&:hover': {
              opacity: 1,
              color: themeVariables.success_main,
            },
          }}
          tooltip={rLIB('Edit')}
          tooltipPlacement="right"
          icon="pen-to-square"
          onClick={() => {
            editItemField(field)
          }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_ItemField = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let fieldJSX = <></>
    if (
      us_directoryItem != null &&
      field != null &&
      field.key != null &&
      field.name != null &&
      us_directoryItem[field.key] != null &&
      us_directoryItem[field.key] !== ''
    ) {
      if (field.form_type === 'checkboxes') {
        // Get Options
        let selectedOptions = []
        for (let loopOptionKey in us_directoryItem['data'][field.key]) {
          if (us_directoryItem['data'][field.key][loopOptionKey] === true || us_directoryItem['data'][field.key][loopOptionKey] === 'true') {
            selectedOptions.push(loopOptionKey)
          }
        }
        selectedOptions.sort()
        if (selectedOptions.length > 0) {
          fieldJSX = (
            <Box>
              <Typography variant="h6">
                <Box
                  component={'span'}
                  sx={{ fontWeight: 'bold', marginRight: '8px' }}
                >
                  {field.name}:
                </Box>
                <Box
                  component={'span'}
                  sx={{ color: themeVariables.info_main, marginRight: '8px' }}
                >
                  {selectedOptions.join(', ')}
                </Box>
                <Box component={'span'}>{rJSX_ItemFieldEditIcon(field)}</Box>
              </Typography>
            </Box>
          )
        } else {
          fieldJSX = (
            <Box>
              <Typography variant="h6">
                <Box
                  component={'span'}
                  sx={{ fontWeight: 'bold', marginRight: '8px' }}
                >
                  {field.name}:
                </Box>
                <Box
                  component={'span'}
                  sx={{ opacity: 0.3, marginRight: '8px', fontStyle: 'italic' }}
                >
                  {rLIB('Missing')}
                </Box>
                <Box component={'span'}>{rJSX_ItemFieldEditIcon(field)}</Box>
              </Typography>
            </Box>
          )
        }
      } else {
        fieldJSX = (
          <Box>
            <Typography variant="h6">
              <Box
                component={'span'}
                sx={{ fontWeight: 'bold', marginRight: '8px' }}
              >
                {field.name}:
              </Box>
              <Box
                component={'span'}
                sx={{ color: themeVariables.info_main, marginRight: '8px' }}
              >
                {us_directoryItem[field.key]}
              </Box>
              <Box component={'span'}>{rJSX_ItemFieldEditIcon(field)}</Box>
            </Typography>
          </Box>
        )
      }
    } else if (field != null && field.name != null) {
      fieldJSX = (
        <Box>
          <Typography variant="h6">
            <Box
              component={'span'}
              sx={{ fontWeight: 'bold', marginRight: '8px' }}
            >
              {field.name}:
            </Box>
            <Box
              component={'span'}
              sx={{ opacity: 0.3, marginRight: '8px', fontStyle: 'italic' }}
            >
              {rLIB('Missing')}
            </Box>
            <Box component={'span'}>{rJSX_ItemFieldEditIcon(field)}</Box>
          </Typography>
        </Box>
      )
    }
    return fieldJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card sx={{ padding: '8px' }}>
        {us_orderedFormFields.map((field: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>{rJSX_ItemField(field)}</Box>
        ))}
      </Card>
    )
    return tabJSX
  }

  const rJSX_LogsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card sx={{ padding: '8px' }}>
        {objectToArray(us_itemLogs)
          .sort(dynamicSort('timestamp', 'asc'))
          .map((log: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Typography
                variant="h6"
                sx={{ display: 'inline-block' }}
              >
                <Box
                  component={'span'}
                  sx={{ fontWeight: 'bold', marginRight: '8px' }}
                >
                  {returnFormattedDate(log.timestamp, 'YYYY-MM-DD HH:mm')} {returnTimezoneAbbreviation(log.timestamp)}
                </Box>
                <Box
                  component={'span'}
                  sx={{ marginRight: '8px', opacity: '0.4' }}
                >
                  {log.text}
                </Box>
              </Typography>
              <Chip
                variant="outlined"
                color="info"
                label={
                  <Box>
                    <Icon
                      icon="user"
                      sx={{ marginRight: '8px' }}
                    />
                    {log.associated_user_name}
                  </Box>
                }
              />
            </Box>
          ))}
        <Box sx={{ opacity: '0.2', textAlign: 'right', fontStyle: 'italic' }}>{us_directoryItem.key}</Box>
      </Card>
    )
    return tabJSX
  }

  // const rJSX_ManageTab = (): JSX.Element => {
  // let tabJSX = <></>
  // tabJSX =
  // <Box>
  // 	<Json data={ us_orderedFormFields } />
  // 	<Json data={ us_directoryItem } />
  // 	<Json data={ us_formFields } />
  // </Box>
  // return tabJSX
  // }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.info_main }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="inbox" />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{ flexGrow: 1, color: themeVariables.white }}
              >
                {getProp(us_directoryItem, 'name', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box sx={{ padding: '8px' }}>
              <TabsBasic
                tabs={[
                  {
                    tabHeader: rLIB('Details'),
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabHeader: rLIB('Logs'),
                    tabContent: rJSX_LogsTab(),
                  },
                  // {
                  // 	tabHeader: s_MANAGE,
                  // 	tabContent: rJSX_ManageTab()
                  // },
                ]}
                tabsSettings={{}}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
