///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ScheduledEmails_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'scheduled_emails', 'main')
}

export const DatabaseRef_ScheduledEmail_Document = (clientKey: string, emailKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'scheduled_emails', 'main', emailKey)
}

export const DatabaseRef_ScheduledEmailLogs_Collection = (clientKey: string, emailKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'scheduled_emails', 'main', emailKey, 'logs')
}

export const DatabaseRef_ScheduledEmailLog_Document = (clientKey: string, emailKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'scheduled_emails', 'main', emailKey, 'logs', logKey)
}
