///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Formulas_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'formulas', 'main')
}

export const DatabaseRef_Formula_Document = (clientKey: string, formulaKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'formulas', 'main', formulaKey)
}

export const DatabaseRef_ActiveFormulas_Query = (clientKey: string): Query<unknown> => {
  let orderByArray: TsInterface_OrderByArray = []
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject: TsInterface_QueryCursorsObject = {}
  let limit: number | null = null
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'data_management', 'formulas', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}
