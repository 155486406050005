//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:
		Probably ,move out of boilerplate and make this whole file a custom param for chat header settings

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Card, Chip, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material/'
import { calculateValuesForDataItemCalculatedFields } from 'app/pages/refine_formulas/services/calculation_functions'
import { returnFormInputsFromDatabaseDataFormat } from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ClassFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_DataBuckets_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_DataLogs_Collection,
  DatabaseRef_DataLogs_Document,
  DatabaseRef_Data_CalculationMetadata_Document,
  DatabaseRef_Data_Document,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, generateRandomString, getProp, objectToArray, returnFormattedDate, returnTimezoneAbbreviation } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_DataItemViewDialog {
  itemKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const compareArrays = (arr1: string[], arr2: string[]) => {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }
  // Sort arrays to ensure order doesn't affect comparison
  const sortedArr1 = arr1.slice().sort()
  const sortedArr2 = arr2.slice().sort()
  // Compare each element of the arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false
    }
  }
  // If all elements are identical, return true
  return true
}

///////////////////////////////
// Component
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const DataItemViewDialog: React.FC<TsInterface_DataItemViewDialog> = ({ itemKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_dataBucket, us_setDataBucket] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataCalculatedFieldMetadata, us_setDataCalculatedFieldMetadata] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataItem, us_setDataItem] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formFields, us_setFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_itemLogs, us_setItemLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_orderedFormFields, us_setOrderedFormFields] = useState<TsInterface_UnspecifiedObject[]>([])
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataItem(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Data_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, itemKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataCalculatedFieldMetadata(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Data_CalculationMetadata_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, itemKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataBucket(newData)
      ur_forceRerender()
    }
    if (us_dataItem != null && us_dataItem.associated_data_bucket_key) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_DataBuckets_Document(res_GCK.clientKey, us_dataItem.associated_data_bucket_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setDataBucket({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_dataItem])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFormFields(newData)
      ur_forceRerender()
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_dataBucket.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setFormFields({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setItemLogs(newData)
      ur_forceRerender()
    }
    if (itemKey != null && itemKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_DataLogs_Collection(res_GCK.clientKey, itemKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setItemLogs({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, itemKey])

  useEffect(() => {
    let sortedFields: TsInterface_UnspecifiedObject[] = []
    for (let loopFieldKey in us_formFields) {
      let loopField = us_formFields[loopFieldKey]
      if (loopField != null && loopField.status === 'active') {
        sortedFields.push(loopField)
      }
    }
    sortedFields.sort(dynamicSort('order', 'asc'))
    us_setOrderedFormFields(sortedFields)
  }, [us_formFields])

  // Functions
  const editItemField = (field: TsInterface_UnspecifiedObject) => {
    let formInputs = returnFormInputsFromDatabaseDataFormat({ [field.key]: field }, uc_RootData_ClientKey)
    let originalValue = getProp(getProp(us_dataItem, 'data', {}), field.key, '')
    if (field.form_type === 'checkboxes') {
      originalValue = getProp(getProp(us_dataItem, 'data', {}), field.key, {})
    } else if (field.form_type === 'toggle') {
      if (originalValue === 'true') {
        originalValue = true
      } else {
        originalValue = false
      }
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: { [field.key]: originalValue },
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  if (field.form_type === 'checkboxes') {
                    // String version of selected options
                    let originalSelectedOptions = []
                    if (us_dataItem['original_data'] != null && us_dataItem['original_data'][field.key] != null) {
                      for (let loopOptionKey in us_dataItem['original_data'][field.key]) {
                        if (
                          us_dataItem['original_data'][field.key][loopOptionKey] === true ||
                          us_dataItem['original_data'][field.key][loopOptionKey] === 'true'
                        ) {
                          originalSelectedOptions.push(loopOptionKey)
                        }
                      }
                    }
                    let selectedOptions = []
                    for (let loopOptionKey in formSubmittedData[field.key]) {
                      if (formSubmittedData[field.key][loopOptionKey] === true || formSubmittedData[field.key][loopOptionKey] === 'true') {
                        selectedOptions.push(loopOptionKey)
                      }
                    }
                    selectedOptions.sort()
                    updateArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Data_Document(res_GCK.clientKey, itemKey),
                        data: {
                          data: {
                            [field.key]: formSubmittedData[field.key],
                          },
                        },
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_DataLogs_Document(res_GCK.clientKey, itemKey, logKey),
                        data: {
                          key: logKey,
                          timestamp: new Date(),
                          text: getProp(field, 'name', ' Field') + ' updated from ' + originalSelectedOptions.join(', ') + ' to ' + selectedOptions.join(', '),
                          associated_user_name: getProp(uc_RootData_GlobalUser, 'name', ''),
                        },
                      },
                    ]
                  } else {
                    updateArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Data_Document(res_GCK.clientKey, itemKey),
                        data: {
                          data: {
                            [field.key]: formSubmittedData[field.key].toString(),
                          },
                        },
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_DataLogs_Document(res_GCK.clientKey, itemKey, logKey),
                        data: {
                          key: logKey,
                          timestamp: new Date(),
                          text: getProp(field, 'name', ' Field') + ' updated from ' + originalValue + ' to ' + formSubmittedData[field.key],
                          associated_user_name: getProp(uc_RootData_GlobalUser, 'name', ''),
                        },
                      },
                    ]
                  }

                  // Batch Save
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit')} {getProp(field, 'name', rLIB('Property'))}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_ItemFieldEditIcon = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (field.form_type !== 'display' && field.form_type !== 'image_upload') {
      iconJSX = (
        <Icon
          sx={{
            'cursor': 'pointer',
            'opacity': 0.3,
            '&:hover': {
              opacity: 1,
              color: themeVariables.success_main,
            },
          }}
          tooltip={rLIB('Edit')}
          tooltipPlacement="right"
          icon="pen-to-square"
          onClick={() => {
            editItemField(field)
          }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_ItemField = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let fieldJSX = <></>
    if (
      us_dataItem != null &&
      us_dataItem['data'] != null &&
      field != null &&
      field.key != null &&
      field.name != null &&
      us_dataItem['data'][field.key] != null &&
      us_dataItem['data'][field.key] !== ''
    ) {
      if (field.form_type === 'checkboxes') {
        // Get Options
        let selectedOptions = []
        for (let loopOptionKey in us_dataItem['data'][field.key]) {
          if (us_dataItem['data'][field.key][loopOptionKey] === true || us_dataItem['data'][field.key][loopOptionKey] === 'true') {
            selectedOptions.push(loopOptionKey)
          }
        }
        selectedOptions.sort()
        let originalSelectedOptions = []
        if (us_dataItem['original_data'] != null && us_dataItem['original_data'][field.key] != null) {
          for (let loopOptionKey in us_dataItem['original_data'][field.key]) {
            if (us_dataItem['original_data'][field.key][loopOptionKey] === true || us_dataItem['original_data'][field.key][loopOptionKey] === 'true') {
              originalSelectedOptions.push(loopOptionKey)
            }
          }
        }
        originalSelectedOptions.sort()
        let arraysMatch = compareArrays(selectedOptions, originalSelectedOptions)
        let originalDataIconJSX = <></>
        if (arraysMatch === false) {
          originalDataIconJSX = (
            <Icon
              sx={{
                'marginLeft': '8px',
                'cursor': 'pointer',
                'opacity': 0.3,
                '&:hover': {
                  opacity: 1,
                  color: themeVariables.warning_main,
                },
              }}
              tooltip={
                <>
                  {rLIB('Originally')}: {originalSelectedOptions.join(', ')}
                </>
              }
              tooltipPlacement="right"
              icon="info-circle"
            />
          )
        }
        if (selectedOptions.length > 0) {
          fieldJSX = (
            <Box>
              <Typography variant="h6">
                <Box
                  component={'span'}
                  sx={{ fontWeight: 'bold', marginRight: '8px' }}
                >
                  {field.name}:
                </Box>
                <Box
                  component={'span'}
                  sx={{ color: themeVariables.info_main, marginRight: '8px' }}
                >
                  {selectedOptions.join(', ')}
                </Box>
                <Box component={'span'}>
                  {rJSX_ItemFieldEditIcon(field)}
                  {originalDataIconJSX}
                </Box>
              </Typography>
            </Box>
          )
        } else {
          fieldJSX = (
            <Box>
              <Typography variant="h6">
                <Box
                  component={'span'}
                  sx={{ fontWeight: 'bold', marginRight: '8px' }}
                >
                  {field.name}:
                </Box>
                <Box
                  component={'span'}
                  sx={{ opacity: 0.3, marginRight: '8px', fontStyle: 'italic' }}
                >
                  {rLIB('Missing')}
                </Box>
                <Box component={'span'}>{rJSX_ItemFieldEditIcon(field)}</Box>
              </Typography>
            </Box>
          )
        }
      } else {
        let originalDataIconJSX = <></>
        if (
          us_dataItem != null &&
          us_dataItem['original_data'] != null &&
          us_dataItem['original_data'][field.key] != null &&
          us_dataItem['original_data'][field.key] !== '' &&
          // eslint-disable-next-line eqeqeq
          us_dataItem['original_data'][field.key] != us_dataItem['data'][field.key]
        ) {
          originalDataIconJSX = (
            <Icon
              sx={{
                'marginLeft': '8px',
                'cursor': 'pointer',
                'opacity': 0.3,
                '&:hover': {
                  opacity: 1,
                  color: themeVariables.warning_main,
                },
              }}
              tooltip={
                <>
                  {rLIB('Originally')}: {us_dataItem['original_data'][field.key]}
                </>
              }
              tooltipPlacement="right"
              icon="info-circle"
            />
          )
        }
        fieldJSX = (
          <Box>
            <Typography variant="h6">
              <Box
                component={'span'}
                sx={{ fontWeight: 'bold', marginRight: '8px' }}
              >
                {field.name}:
              </Box>
              <Box
                component={'span'}
                sx={{ color: themeVariables.info_main, marginRight: '8px' }}
              >
                {us_dataItem['data'][field.key]}
              </Box>
              <Box component={'span'}>
                {rJSX_ItemFieldEditIcon(field)}
                {originalDataIconJSX}
              </Box>
            </Typography>
          </Box>
        )
      }
    } else if (field != null && field.name != null) {
      fieldJSX = (
        <Box>
          <Typography variant="h6">
            <Box
              component={'span'}
              sx={{ fontWeight: 'bold', marginRight: '8px' }}
            >
              {field.name}:
            </Box>
            <Box
              component={'span'}
              sx={{ opacity: 0.3, marginRight: '8px', fontStyle: 'italic' }}
            >
              {rLIB('Missing')}
            </Box>
            <Box component={'span'}>{rJSX_ItemFieldEditIcon(field)}</Box>
          </Typography>
        </Box>
      )
    }
    return fieldJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card sx={{ padding: '16px' }}>
        {us_orderedFormFields.map((field: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>{rJSX_ItemField(field)}</Box>
        ))}
      </Card>
    )
    return tabJSX
  }

  const rJSX_CalculateButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        color="info"
        startIcon={<Icon icon="calculator-simple" />}
        size="small"
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              calculateValuesForDataItemCalculatedFields(res_GCK.clientKey, itemKey as string, null, null, null, null, null, 'save')
                .then((res_DVFDICF) => {
                  // uc_setUserInterface_SnackbarDisplay({
                  //   display: true,
                  //   snackbar: {
                  //     message: rLIB('Calculations Recalculated'),
                  //     alertType: 'info',
                  //     verticalAlignment: 'top',
                  //     horizontalAlignment: 'right',
                  //   },
                  // })
                })
                .catch((rej_DVFDICF) => {
                  console.error(rej_DVFDICF)
                })
            })
            .catch((rej_GCK) => {
              console.error(rej_GCK)
            })
        }}
      >
        {rLIB('Rerun Calculations')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_CalculatedFieldsTab = (): JSX.Element => {
    let tabJSX = (
      <Card sx={{ padding: '16px' }}>
        <Box sx={{ marginBottom: '8px' }}>
          {objectToArray(getProp(us_dataCalculatedFieldMetadata, 'calculated_data', {}))
            .sort(dynamicSort('name', 'asc'))
            .map((calculatedFieldMetadata: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Typography variant="h6">
                  <Box
                    component={'span'}
                    sx={{ fontWeight: 'bold', marginRight: '8px' }}
                  >
                    {calculatedFieldMetadata.name}:
                  </Box>
                  {getProp(getProp(calculatedFieldMetadata, 'result', {}), 'success', false) ? (
                    <Box
                      component={'span'}
                      sx={{ color: themeVariables.info_main, marginRight: '8px' }}
                    >
                      {getProp(getProp(calculatedFieldMetadata, 'result', {}), 'value', '')}
                    </Box>
                  ) : (
                    <Box
                      component={'span'}
                      sx={{ color: themeVariables.error_main, marginRight: '8px' }}
                    >
                      <Icon
                        icon="exclamation-triangle"
                        sx={{ color: themeVariables.error_main, marginRight: '8px' }}
                        tooltip={
                          <Box>
                            <Box>
                              {rLIB('Error')}: {getProp(getProp(calculatedFieldMetadata, 'result', {}), 'error_path', 'UNK')}
                            </Box>
                            <Box>{getProp(getProp(calculatedFieldMetadata, 'result', {}), 'error_type', '')}</Box>
                          </Box>
                        }
                      />
                      {rLIB('Failed to calculate')}
                    </Box>
                  )}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box sx={{ textAlign: 'right' }}>{rJSX_CalculateButton()}</Box>
      </Card>
    )

    return tabJSX
  }

  const rJSX_CreateUserChip = (): JSX.Element => {
    let userChipJSX = <></>
    if (us_dataItem != null && us_dataItem.metadata != null && us_dataItem.metadata['associated_user_name'] != null) {
      userChipJSX = (
        <Chip
          variant="outlined"
          color="info"
          label={
            <Box>
              <Icon
                icon="user"
                sx={{ marginRight: '8px' }}
              />
              {us_dataItem.metadata['associated_user_name']}
            </Box>
          }
        />
      )
    }
    return userChipJSX
  }

  const rJSX_CreationLog = (): JSX.Element => {
    let logJSX = <></>
    if (us_dataItem != null && us_dataItem.timestamp_submitted != null) {
      logJSX = (
        <Box>
          <Typography
            variant="h6"
            sx={{ display: 'inline-block' }}
          >
            <Box
              component={'span'}
              sx={{ fontWeight: 'bold', marginRight: '8px' }}
            >
              {returnFormattedDate(us_dataItem.timestamp_submitted, 'YYYY-MM-DD HH:mm')} {returnTimezoneAbbreviation(us_dataItem.timestamp_submitted)}
            </Box>
            <Box
              component={'span'}
              sx={{ marginRight: '8px', opacity: '0.4' }}
            >
              {rLIB('Created')}
            </Box>
          </Typography>
          {rJSX_CreateUserChip()}
          {/* <Chip
						variant="outlined"
						color="info"
						label={
							<Box>
								<Icon icon="user" sx={{ marginRight: "8px" }} />
								{ log.associated_user_name }
							</Box>
						}
					/> */}
        </Box>
      )
    }
    return logJSX
  }

  const rJSX_LogsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card sx={{ padding: '16px' }}>
        {rJSX_CreationLog()}
        {objectToArray(us_itemLogs)
          .sort(dynamicSort('timestamp', 'asc'))
          .map((log: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Typography
                variant="h6"
                sx={{ display: 'inline-block' }}
              >
                <Box
                  component={'span'}
                  sx={{ fontWeight: 'bold', marginRight: '8px' }}
                >
                  {returnFormattedDate(log.timestamp, 'YYYY-MM-DD HH:mm')} {returnTimezoneAbbreviation(log.timestamp)}
                </Box>
                <Box
                  component={'span'}
                  sx={{ marginRight: '8px', opacity: '0.4' }}
                >
                  {log.text}
                </Box>
              </Typography>
              <Chip
                variant="outlined"
                color="info"
                label={
                  <Box>
                    <Icon
                      icon="user"
                      sx={{ marginRight: '8px' }}
                    />
                    {log.associated_user_name}
                  </Box>
                }
              />
            </Box>
          ))}
        <Box sx={{ opacity: '0.2', textAlign: 'right', fontStyle: 'italic' }}>{us_dataItem.key}</Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ImagePreview = (photoUrl: string | null): JSX.Element => {
    let imageJSX = <></>
    if (photoUrl != null) {
      imageJSX = (
        <Box
          sx={{
            marginTop: '8px',
            textAlign: 'center',
            height: '400px',
            backgroundImage: "url('" + photoUrl + "')",
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '5px',
            border: '1px solid rgba(0,0,0,0.1)',
          }}
        />
      )
    } else {
      imageJSX = (
        <Box>
          <Typography
            variant="h6"
            sx={{ opacity: 0.3, fontStyle: 'italic', paddingLeft: '24px' }}
          >
            {rLIB('No image uploaded')}
          </Typography>
        </Box>
      )
    }
    return imageJSX
  }

  const rJSX_ImagesTab = (): JSX.Element => {
    let tabJSX = <></>
    let imageFields: TsInterface_UnspecifiedObject = {}
    for (let loopFieldKey in us_formFields) {
      let loopField = us_formFields[loopFieldKey]
      if (loopField != null && loopField.status === 'active' && loopField.form_type === 'image_upload') {
        imageFields[loopFieldKey] = loopField
      }
    }
    // JSX
    tabJSX = (
      <Card sx={{ padding: '16px' }}>
        {objectToArray(imageFields)
          .sort(dynamicSort('order', 'asc'))
          .map((image: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold', marginRight: '8px' }}
              >
                {image.name}
              </Typography>
              {rJSX_ImagePreview(getProp(getProp(us_dataItem, 'data', {}), image.key + '_url', null))}
            </Box>
          ))}
      </Card>
    )
    return tabJSX
  }

  // const rJSX_ManageTab = (): JSX.Element => {
  // let tabJSX = <></>
  // tabJSX =
  // <Box>
  // 	<Json data={ us_dataItem } />
  // </Box>
  // return tabJSX
  // }

  const rJSX_DialogHeader = (): JSX.Element => {
    let headerJSX = <></>
    let uniqueIdentifierFieldKey = null
    if (us_formFields != null) {
      for (let loopFieldKey in us_formFields) {
        let loopField = us_formFields[loopFieldKey]
        if (loopField != null && loopField.unique_identifier === true) {
          uniqueIdentifierFieldKey = loopField.key
        }
      }
    }
    if (uniqueIdentifierFieldKey != null && us_dataItem != null && us_dataItem['data'] != null && us_dataItem['data'][uniqueIdentifierFieldKey] != null) {
      headerJSX = us_dataItem['data'][uniqueIdentifierFieldKey]
    } else {
      headerJSX = rLIB('Data Item') as JSX.Element
    }
    return headerJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.info_main }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="memo-pad" />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{ flexGrow: 1, color: themeVariables.white }}
              >
                {rJSX_DialogHeader()}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box sx={{ paddingBottom: '16px', margin: '8px' }}>
              <TabsBasic
                tabs={[
                  {
                    tabHeader: rLIB('Data'),
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabHeader: rLIB('Calculated Fields'),
                    tabContent: rJSX_CalculatedFieldsTab(),
                  },
                  {
                    tabHeader: rLIB('Images'),
                    tabContent: rJSX_ImagesTab(),
                  },
                  {
                    tabHeader: rLIB('Logs'),
                    tabContent: rJSX_LogsTab(),
                  },
                  // {
                  // 	tabHeader: s_MANAGE,
                  // 	tabContent: rJSX_ManageTab()
                  // },
                ]}
                tabsSettings={{}}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
