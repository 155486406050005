///////////////////////////////
// Description
///////////////////////////////

/*
USAGE:
Data Services contains micro services for data requests that are best hosted on the server

*/

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

const correctedGravityLookupTable: TsInterface_UnspecifiedObject = {
  //  0 - 5
  '0': {
    '0': 2.3,
    '1': 2.3,
    '2': 2.2,
    '3': 2.2,
    '4': 2.1,
    '5': 2.1,
    '6': 2.1,
    '7': 2,
    '8': 2,
    '9': 1.9,
    '10': 1.9,
    '11': 1.9,
    '12': 1.8,
    '13': 1.8,
    '14': 1.7,
    '15': 1.7,
    '16': 1.7,
    '17': 1.6,
    '18': 1.6,
    '19': 1.6,
    '20': 1.5,
    '21': 1.5,
    '22': 1.4,
    '23': 1.4,
    '24': 1.4,
    '25': 1.3,
    '26': 1.3,
    '27': 1.2,
    '28': 1.2,
    '29': 1.2,
    '30': 1.1,
    '31': 1.1,
    '32': 1.1,
    '33': 1,
    '34': 1,
    '35': 0.9,
    '36': 0.9,
    '37': 0.9,
    '38': 0.8,
    '39': 0.8,
    '40': 0.7,
    '41': 0.7,
    '42': 0.7,
    '43': 0.6,
    '44': 0.6,
    '45': 0.6,
    '46': 0.5,
    '47': 0.5,
    '48': 0.4,
    '49': 0.4,
    '50': 0.4,
    '51': 0.3,
    '52': 0.3,
    '53': 0.3,
    '54': 0.2,
    '55': 0.2,
    '56': 0.1,
    '57': 0.1,
    '58': 0.1,
    '59': 0,
    '60': 0,
    '0.5': 2.3,
    '1.5': 2.2,
    '2.5': 2.2,
    '3.5': 2.2,
    '4.5': 2.1,
    '5.5': 2.1,
    '6.5': 2,
    '7.5': 2,
    '8.5': 2,
    '9.5': 1.9,
    '10.5': 1.9,
    '11.5': 1.8,
    '12.5': 1.8,
    '13.5': 1.8,
    '14.5': 1.7,
    '15.5': 1.7,
    '16.5': 1.7,
    '17.5': 1.6,
    '18.5': 1.6,
    '19.5': 1.5,
    '20.5': 1.5,
    '21.5': 1.5,
    '22.5': 1.4,
    '23.5': 1.4,
    '24.5': 1.3,
    '25.5': 1.3,
    '26.5': 1.3,
    '27.5': 1.2,
    '28.5': 1.2,
    '29.5': 1.2,
    '30.5': 1.1,
    '31.5': 1.1,
    '32.5': 1,
    '33.5': 1,
    '34.5': 1,
    '35.5': 0.9,
    '36.5': 0.9,
    '37.5': 0.8,
    '38.5': 0.8,
    '39.5': 0.8,
    '40.5': 0.7,
    '41.5': 0.7,
    '42.5': 0.6,
    '43.5': 0.6,
    '44.5': 0.6,
    '45.5': 0.5,
    '46.5': 0.5,
    '47.5': 0.5,
    '48.5': 0.4,
    '49.5': 0.4,
    '50.5': 0.4,
    '51.5': 0.3,
    '52.5': 0.3,
    '53.5': 0.2,
    '54.5': 0.2,
    '55.5': 0.2,
    '56.5': 0.1,
    '57.5': 0.1,
    '58.5': 0.1,
    '59.5': 0,
  },
  '0.5': {
    '0': 2.8,
    '1': 2.8,
    '2': 2.7,
    '3': 2.7,
    '4': 2.7,
    '5': 2.6,
    '6': 2.6,
    '7': 2.5,
    '8': 2.5,
    '9': 2.5,
    '10': 2.4,
    '11': 2.4,
    '12': 2.3,
    '13': 2.3,
    '14': 2.3,
    '15': 2.2,
    '16': 2.2,
    '17': 2.2,
    '18': 2.1,
    '19': 2.1,
    '20': 2,
    '21': 2,
    '22': 2,
    '23': 1.9,
    '24': 1.9,
    '25': 1.8,
    '26': 1.8,
    '27': 1.8,
    '28': 1.7,
    '29': 1.7,
    '30': 1.6,
    '31': 1.6,
    '32': 1.6,
    '33': 1.5,
    '34': 1.5,
    '35': 1.5,
    '36': 1.4,
    '37': 1.4,
    '38': 1.3,
    '39': 1.3,
    '40': 1.3,
    '41': 1.2,
    '42': 1.2,
    '43': 1.1,
    '44': 1.1,
    '45': 1.1,
    '46': 1,
    '47': 1,
    '48': 1,
    '49': 0.9,
    '50': 0.9,
    '51': 0.8,
    '52': 0.8,
    '53': 0.8,
    '54': 0.7,
    '55': 0.7,
    '56': 0.7,
    '57': 0.6,
    '58': 0.6,
    '59': 0.5,
    '60': 0.5,
    '61': 0.5,
    '62': 0.4,
    '63': 0.4,
    '64': 0.3,
    '65': 0.3,
    '66': 0.3,
    '67': 0.2,
    '68': 0.2,
    '69': 0.2,
    '70': 0.1,
    '71': 0.1,
    '72': 0.1,
    '73': 0,
    '0.5': 2.8,
    '1.5': 2.8,
    '2.5': 2.7,
    '3.5': 2.7,
    '4.5': 2.6,
    '5.5': 2.6,
    '6.5': 2.6,
    '7.5': 2.5,
    '8.5': 2.5,
    '9.5': 2.4,
    '10.5': 2.4,
    '11.5': 2.4,
    '12.5': 2.3,
    '13.5': 2.3,
    '14.5': 2.3,
    '15.5': 2.2,
    '16.5': 2.2,
    '17.5': 2.1,
    '18.5': 2.1,
    '19.5': 2.1,
    '20.5': 2,
    '21.5': 2,
    '22.5': 1.9,
    '23.5': 1.9,
    '24.5': 1.9,
    '25.5': 1.8,
    '26.5': 1.8,
    '27.5': 1.7,
    '28.5': 1.7,
    '29.5': 1.7,
    '30.5': 1.6,
    '31.5': 1.6,
    '32.5': 1.5,
    '33.5': 1.5,
    '34.5': 1.5,
    '35.5': 1.4,
    '36.5': 1.4,
    '37.5': 1.4,
    '38.5': 1.3,
    '39.5': 1.3,
    '40.5': 1.2,
    '41.5': 1.2,
    '42.5': 1.2,
    '43.5': 1.1,
    '44.5': 1.1,
    '45.5': 1,
    '46.5': 1,
    '47.5': 1,
    '48.5': 0.9,
    '49.5': 0.9,
    '50.5': 0.9,
    '51.5': 0.8,
    '52.5': 0.8,
    '53.5': 0.7,
    '54.5': 0.7,
    '55.5': 0.7,
    '56.5': 0.6,
    '57.5': 0.6,
    '58.5': 0.6,
    '59.5': 0.5,
    '60.5': 0.5,
    '61.5': 0.4,
    '62.5': 0.4,
    '63.5': 0.4,
    '64.5': 0.3,
    '65.5': 0.3,
    '66.5': 0.3,
    '67.5': 0.2,
    '68.5': 0.2,
    '69.5': 0.1,
    '70.5': 0.1,
    '71.5': 0.1,
    '72.5': 0,
  },
  '1': {
    '0': 3.4,
    '1': 3.3,
    '2': 3.3,
    '3': 3.2,
    '4': 3.2,
    '5': 3.2,
    '6': 3.1,
    '7': 3.1,
    '8': 3,
    '9': 3,
    '10': 3,
    '11': 2.9,
    '12': 2.9,
    '13': 2.8,
    '14': 2.8,
    '15': 2.8,
    '16': 2.7,
    '17': 2.7,
    '18': 2.6,
    '19': 2.6,
    '20': 2.6,
    '21': 2.5,
    '22': 2.5,
    '23': 2.4,
    '24': 2.4,
    '25': 2.4,
    '26': 2.3,
    '27': 2.3,
    '28': 2.2,
    '29': 2.2,
    '30': 2.2,
    '31': 2.1,
    '32': 2.1,
    '33': 2,
    '34': 2,
    '35': 2,
    '36': 1.9,
    '37': 1.9,
    '38': 1.8,
    '39': 1.8,
    '40': 1.8,
    '41': 1.7,
    '42': 1.7,
    '43': 1.7,
    '44': 1.6,
    '45': 1.6,
    '46': 1.5,
    '47': 1.5,
    '48': 1.5,
    '49': 1.4,
    '50': 1.4,
    '51': 1.3,
    '52': 1.3,
    '53': 1.3,
    '54': 1.2,
    '55': 1.2,
    '56': 1.2,
    '57': 1.1,
    '58': 1.1,
    '59': 1,
    '60': 1,
    '61': 1,
    '62': 0.9,
    '63': 0.9,
    '64': 0.8,
    '65': 0.8,
    '66': 0.8,
    '67': 0.7,
    '68': 0.7,
    '69': 0.7,
    '70': 0.6,
    '71': 0.6,
    '72': 0.5,
    '73': 0.5,
    '74': 0.5,
    '75': 0.4,
    '76': 0.4,
    '77': 0.4,
    '78': 0.3,
    '79': 0.3,
    '80': 0.2,
    '81': 0.2,
    '82': 0.2,
    '83': 0.1,
    '84': 0.1,
    '85': 0.1,
    '86': 0,
    '0.5': 3.3,
    '1.5': 3.3,
    '2.5': 3.3,
    '3.5': 3.2,
    '4.5': 3.2,
    '5.5': 3.1,
    '6.5': 3.1,
    '7.5': 3.1,
    '8.5': 3,
    '9.5': 3,
    '10.5': 2.9,
    '11.5': 2.9,
    '12.5': 2.9,
    '13.5': 2.8,
    '14.5': 2.8,
    '15.5': 2.7,
    '16.5': 2.7,
    '17.5': 2.7,
    '18.5': 2.6,
    '19.5': 2.6,
    '20.5': 2.5,
    '21.5': 2.5,
    '22.5': 2.5,
    '23.5': 2.4,
    '24.5': 2.4,
    '25.5': 2.3,
    '26.5': 2.3,
    '27.5': 2.3,
    '28.5': 2.2,
    '29.5': 2.2,
    '30.5': 2.1,
    '31.5': 2.1,
    '32.5': 2.1,
    '33.5': 2,
    '34.5': 2,
    '35.5': 1.9,
    '36.5': 1.9,
    '37.5': 1.9,
    '38.5': 1.8,
    '39.5': 1.8,
    '40.5': 1.7,
    '41.5': 1.7,
    '42.5': 1.7,
    '43.5': 1.6,
    '44.5': 1.6,
    '45.5': 1.6,
    '46.5': 1.5,
    '47.5': 1.5,
    '48.5': 1.4,
    '49.5': 1.4,
    '50.5': 1.4,
    '51.5': 1.3,
    '52.5': 1.3,
    '53.5': 1.2,
    '54.5': 1.2,
    '55.5': 1.2,
    '56.5': 1.1,
    '57.5': 1.1,
    '58.5': 1.1,
    '59.5': 1,
    '60.5': 1,
    '61.5': 0.9,
    '62.5': 0.9,
    '63.5': 0.9,
    '64.5': 0.8,
    '65.5': 0.8,
    '66.5': 0.8,
    '67.5': 0.7,
    '68.5': 0.7,
    '69.5': 0.6,
    '70.5': 0.6,
    '71.5': 0.6,
    '72.5': 0.5,
    '73.5': 0.5,
    '74.5': 0.5,
    '75.5': 0.4,
    '76.5': 0.4,
    '77.5': 0.3,
    '78.5': 0.3,
    '79.5': 0.3,
    '80.5': 0.2,
    '81.5': 0.2,
    '82.5': 0.2,
    '83.5': 0.1,
    '84.5': 0.1,
    '85.5': 0,
    '86.5': 0,
  },
  '1.5': {
    '0': 3.9,
    '1': 3.8,
    '2': 3.8,
    '3': 3.8,
    '4': 3.7,
    '5': 3.7,
    '6': 3.6,
    '7': 3.6,
    '8': 3.6,
    '9': 3.5,
    '10': 3.5,
    '11': 3.4,
    '12': 3.4,
    '13': 3.4,
    '14': 3.3,
    '15': 3.3,
    '16': 3.2,
    '17': 3.2,
    '18': 3.2,
    '19': 3.1,
    '20': 3.1,
    '21': 3,
    '22': 3,
    '23': 3,
    '24': 2.9,
    '25': 2.9,
    '26': 2.8,
    '27': 2.8,
    '28': 2.8,
    '29': 2.7,
    '30': 2.7,
    '31': 2.6,
    '32': 2.6,
    '33': 2.6,
    '34': 2.5,
    '35': 2.5,
    '36': 2.4,
    '37': 2.4,
    '38': 2.4,
    '39': 2.3,
    '40': 2.3,
    '41': 2.2,
    '42': 2.2,
    '43': 2.2,
    '44': 2.1,
    '45': 2.1,
    '46': 2,
    '47': 2,
    '48': 2,
    '49': 1.9,
    '50': 1.9,
    '51': 1.8,
    '52': 1.8,
    '53': 1.8,
    '54': 1.7,
    '55': 1.7,
    '56': 1.7,
    '57': 1.6,
    '58': 1.6,
    '59': 1.5,
    '60': 1.5,
    '61': 1.5,
    '62': 1.4,
    '63': 1.4,
    '64': 1.3,
    '65': 1.3,
    '66': 1.3,
    '67': 1.2,
    '68': 1.2,
    '69': 1.2,
    '70': 1.1,
    '71': 1.1,
    '72': 1,
    '73': 1,
    '74': 1,
    '75': 0.9,
    '76': 0.9,
    '77': 0.9,
    '78': 0.8,
    '79': 0.8,
    '80': 0.7,
    '81': 0.7,
    '82': 0.7,
    '83': 0.6,
    '84': 0.6,
    '85': 0.6,
    '86': 0.5,
    '87': 0.5,
    '88': 0.4,
    '89': 0.4,
    '90': 0.4,
    '91': 0.3,
    '92': 0.3,
    '93': 0.3,
    '94': 0.2,
    '95': 0.2,
    '96': 0.1,
    '97': 0.1,
    '98': 0.1,
    '99': 0,
    '0.5': 3.9,
    '1.5': 3.8,
    '2.5': 3.8,
    '3.5': 3.7,
    '4.5': 3.7,
    '5.5': 3.7,
    '6.5': 3.6,
    '7.5': 3.6,
    '8.5': 3.5,
    '9.5': 3.5,
    '10.5': 3.5,
    '11.5': 3.4,
    '12.5': 3.4,
    '13.5': 3.3,
    '14.5': 3.3,
    '15.5': 3.3,
    '16.5': 3.2,
    '17.5': 3.2,
    '18.5': 3.1,
    '19.5': 3.1,
    '20.5': 3.1,
    '21.5': 3,
    '22.5': 3,
    '23.5': 2.9,
    '24.5': 2.9,
    '25.5': 2.9,
    '26.5': 2.8,
    '27.5': 2.8,
    '28.5': 2.7,
    '29.5': 2.7,
    '30.5': 2.7,
    '31.5': 2.6,
    '32.5': 2.6,
    '33.5': 2.5,
    '34.5': 2.5,
    '35.5': 2.5,
    '36.5': 2.4,
    '37.5': 2.4,
    '38.5': 2.3,
    '39.5': 2.3,
    '40.5': 2.3,
    '41.5': 2.2,
    '42.5': 2.2,
    '43.5': 2.1,
    '44.5': 2.1,
    '45.5': 2.1,
    '46.5': 2,
    '47.5': 2,
    '48.5': 1.9,
    '49.5': 1.9,
    '50.5': 1.9,
    '51.5': 1.8,
    '52.5': 1.8,
    '53.5': 1.8,
    '54.5': 1.7,
    '55.5': 1.7,
    '56.5': 1.6,
    '57.5': 1.6,
    '58.5': 1.6,
    '59.5': 1.5,
    '60.5': 1.5,
    '61.5': 1.4,
    '62.5': 1.4,
    '63.5': 1.4,
    '64.5': 1.3,
    '65.5': 1.3,
    '66.5': 1.3,
    '67.5': 1.2,
    '68.5': 1.2,
    '69.5': 1.1,
    '70.5': 1.1,
    '71.5': 1.1,
    '72.5': 1,
    '73.5': 1,
    '74.5': 0.9,
    '75.5': 0.9,
    '76.5': 0.9,
    '77.5': 0.8,
    '78.5': 0.8,
    '79.5': 0.8,
    '80.5': 0.7,
    '81.5': 0.7,
    '82.5': 0.6,
    '83.5': 0.6,
    '84.5': 0.6,
    '85.5': 0.5,
    '86.5': 0.5,
    '87.5': 0.5,
    '88.5': 0.4,
    '89.5': 0.4,
    '90.5': 0.3,
    '91.5': 0.3,
    '92.5': 0.3,
    '93.5': 0.2,
    '94.5': 0.2,
    '95.5': 0.2,
    '96.5': 0.1,
    '97.5': 0.1,
    '98.5': 0,
    '99.5': 0,
  },
  '2': {
    '0': 4.4,
    '1': 4.4,
    '2': 4.3,
    '3': 4.3,
    '4': 4.2,
    '5': 4.2,
    '6': 4.2,
    '7': 4.1,
    '8': 4.1,
    '9': 4,
    '10': 4,
    '11': 4,
    '12': 3.9,
    '13': 3.9,
    '14': 3.8,
    '15': 3.8,
    '16': 3.8,
    '17': 3.7,
    '18': 3.7,
    '19': 3.6,
    '20': 3.6,
    '21': 3.5,
    '22': 3.5,
    '23': 3.5,
    '24': 3.4,
    '25': 3.4,
    '26': 3.3,
    '27': 3.3,
    '28': 3.3,
    '29': 3.2,
    '30': 3.2,
    '31': 3.1,
    '32': 3.1,
    '33': 3.1,
    '34': 3,
    '35': 3,
    '36': 2.9,
    '37': 2.9,
    '38': 2.9,
    '39': 2.8,
    '40': 2.8,
    '41': 2.7,
    '42': 2.7,
    '43': 2.7,
    '44': 2.6,
    '45': 2.6,
    '46': 2.5,
    '47': 2.5,
    '48': 2.5,
    '49': 2.4,
    '50': 2.4,
    '51': 2.4,
    '52': 2.3,
    '53': 2.3,
    '54': 2.2,
    '55': 2.2,
    '56': 2.2,
    '57': 2.1,
    '58': 2.1,
    '59': 2,
    '60': 2,
    '61': 2,
    '62': 1.9,
    '63': 1.9,
    '64': 1.8,
    '65': 1.8,
    '66': 1.8,
    '67': 1.7,
    '68': 1.7,
    '69': 1.7,
    '70': 1.6,
    '71': 1.6,
    '72': 1.5,
    '73': 1.5,
    '74': 1.5,
    '75': 1.4,
    '76': 1.4,
    '77': 1.3,
    '78': 1.3,
    '79': 1.3,
    '80': 1.2,
    '81': 1.2,
    '82': 1.2,
    '83': 1.1,
    '84': 1.1,
    '85': 1,
    '86': 1,
    '87': 1,
    '88': 0.9,
    '89': 0.9,
    '90': 0.8,
    '91': 0.8,
    '92': 0.8,
    '93': 0.7,
    '94': 0.7,
    '95': 0.7,
    '96': 0.6,
    '97': 0.6,
    '98': 0.5,
    '99': 0.5,
    '100': 0.5,
    '101': 0.4,
    '102': 0.4,
    '103': 0.4,
    '104': 0.3,
    '105': 0.3,
    '106': 0.3,
    '107': 0.2,
    '108': 0.2,
    '109': 0.1,
    '110': 0.1,
    '111': 0.1,
    '112': 0,
    '0.5': 4.4,
    '1.5': 4.3,
    '2.5': 4.3,
    '3.5': 4.3,
    '4.5': 4.2,
    '5.5': 4.2,
    '6.5': 4.1,
    '7.5': 4.1,
    '8.5': 4.1,
    '9.5': 4,
    '10.5': 4,
    '11.5': 3.9,
    '12.5': 3.9,
    '13.5': 3.9,
    '14.5': 3.8,
    '15.5': 3.8,
    '16.5': 3.7,
    '17.5': 3.7,
    '18.5': 3.7,
    '19.5': 3.6,
    '20.5': 3.6,
    '21.5': 3.5,
    '22.5': 3.5,
    '23.5': 3.4,
    '24.5': 3.4,
    '25.5': 3.4,
    '26.5': 3.3,
    '27.5': 3.3,
    '28.5': 3.2,
    '29.5': 3.2,
    '30.5': 3.2,
    '31.5': 3.1,
    '32.5': 3.1,
    '33.5': 3,
    '34.5': 3,
    '35.5': 3,
    '36.5': 2.9,
    '37.5': 2.9,
    '38.5': 2.8,
    '39.5': 2.8,
    '40.5': 2.8,
    '41.5': 2.7,
    '42.5': 2.7,
    '43.5': 2.6,
    '44.5': 2.6,
    '45.5': 2.6,
    '46.5': 2.5,
    '47.5': 2.5,
    '48.5': 2.5,
    '49.5': 2.4,
    '50.5': 2.4,
    '51.5': 2.3,
    '52.5': 2.3,
    '53.5': 2.3,
    '54.5': 2.2,
    '55.5': 2.2,
    '56.5': 2.1,
    '57.5': 2.1,
    '58.5': 2.1,
    '59.5': 2,
    '60.5': 2,
    '61.5': 1.9,
    '62.5': 1.9,
    '63.5': 1.9,
    '64.5': 1.8,
    '65.5': 1.8,
    '66.5': 1.7,
    '67.5': 1.7,
    '68.5': 1.7,
    '69.5': 1.6,
    '70.5': 1.6,
    '71.5': 1.6,
    '72.5': 1.5,
    '73.5': 1.5,
    '74.5': 1.4,
    '75.5': 1.4,
    '76.5': 1.4,
    '77.5': 1.3,
    '78.5': 1.3,
    '79.5': 1.2,
    '80.5': 1.2,
    '81.5': 1.2,
    '82.5': 1.1,
    '83.5': 1.1,
    '84.5': 1.1,
    '85.5': 1,
    '86.5': 1,
    '87.5': 0.9,
    '88.5': 0.9,
    '89.5': 0.9,
    '90.5': 0.8,
    '91.5': 0.8,
    '92.5': 0.8,
    '93.5': 0.7,
    '94.5': 0.7,
    '95.5': 0.6,
    '96.5': 0.6,
    '97.5': 0.6,
    '98.5': 0.5,
    '99.5': 0.5,
    '100.5': 0.5,
    '101.5': 0.4,
    '102.5': 0.4,
    '103.5': 0.3,
    '104.5': 0.3,
    '105.5': 0.3,
    '106.5': 0.2,
    '107.5': 0.2,
    '108.5': 0.2,
    '109.5': 0.1,
    '110.5': 0.1,
    '111.5': 0,
    '112.5': 0,
  },
  '2.5': {
    '0': 4.9,
    '1': 4.9,
    '2': 4.9,
    '3': 4.8,
    '4': 4.8,
    '5': 4.7,
    '6': 4.7,
    '7': 4.6,
    '8': 4.6,
    '9': 4.6,
    '10': 4.5,
    '11': 4.5,
    '12': 4.4,
    '13': 4.4,
    '14': 4.4,
    '15': 4.3,
    '16': 4.3,
    '17': 4.2,
    '18': 4.2,
    '19': 4.1,
    '20': 4.1,
    '21': 4.1,
    '22': 4,
    '23': 4,
    '24': 3.9,
    '25': 3.9,
    '26': 3.9,
    '27': 3.8,
    '28': 3.8,
    '29': 3.7,
    '30': 3.7,
    '31': 3.7,
    '32': 3.6,
    '33': 3.6,
    '34': 3.5,
    '35': 3.5,
    '36': 3.5,
    '37': 3.4,
    '38': 3.4,
    '39': 3.3,
    '40': 3.3,
    '41': 3.3,
    '42': 3.2,
    '43': 3.2,
    '44': 3.1,
    '45': 3.1,
    '46': 3.1,
    '47': 3,
    '48': 3,
    '49': 2.9,
    '50': 2.9,
    '51': 2.9,
    '52': 2.8,
    '53': 2.8,
    '54': 2.7,
    '55': 2.7,
    '56': 2.7,
    '57': 2.6,
    '58': 2.6,
    '59': 2.5,
    '60': 2.5,
    '61': 2.5,
    '62': 2.4,
    '63': 2.4,
    '64': 2.3,
    '65': 2.3,
    '66': 2.3,
    '67': 2.2,
    '68': 2.2,
    '69': 2.1,
    '70': 2.1,
    '71': 2.1,
    '72': 2,
    '73': 2,
    '74': 2,
    '75': 1.9,
    '76': 1.9,
    '77': 1.8,
    '78': 1.8,
    '79': 1.8,
    '80': 1.7,
    '81': 1.7,
    '82': 1.6,
    '83': 1.6,
    '84': 1.6,
    '85': 1.5,
    '86': 1.5,
    '87': 1.5,
    '88': 1.4,
    '89': 1.4,
    '90': 1.3,
    '91': 1.3,
    '92': 1.3,
    '93': 1.2,
    '94': 1.2,
    '95': 1.1,
    '96': 1.1,
    '97': 1.1,
    '98': 1,
    '99': 1,
    '100': 1,
    '101': 0.9,
    '102': 0.9,
    '103': 0.8,
    '104': 0.8,
    '105': 0.8,
    '106': 0.7,
    '107': 0.7,
    '108': 0.7,
    '109': 0.6,
    '110': 0.6,
    '111': 0.5,
    '112': 0.5,
    '113': 0.5,
    '114': 0.4,
    '115': 0.4,
    '116': 0.4,
    '117': 0.3,
    '118': 0.3,
    '119': 0.2,
    '120': 0.2,
    '121': 0.2,
    '122': 0.1,
    '123': 0.1,
    '124': 0.1,
    '125': 0,
    '0.5': 4.9,
    '1.5': 4.9,
    '2.5': 4.8,
    '3.5': 4.8,
    '4.5': 4.7,
    '5.5': 4.7,
    '6.5': 4.7,
    '7.5': 4.6,
    '8.5': 4.6,
    '9.5': 4.5,
    '10.5': 4.5,
    '11.5': 4.5,
    '12.5': 4.4,
    '13.5': 4.4,
    '14.5': 4.3,
    '15.5': 4.3,
    '16.5': 4.3,
    '17.5': 4.2,
    '18.5': 4.2,
    '19.5': 4.1,
    '20.5': 4.1,
    '21.5': 4,
    '22.5': 4,
    '23.5': 4,
    '24.5': 3.9,
    '25.5': 3.9,
    '26.5': 3.8,
    '27.5': 3.8,
    '28.5': 3.8,
    '29.5': 3.7,
    '30.5': 3.7,
    '31.5': 3.6,
    '32.5': 3.6,
    '33.5': 3.6,
    '34.5': 3.5,
    '35.5': 3.5,
    '36.5': 3.4,
    '37.5': 3.4,
    '38.5': 3.4,
    '39.5': 3.3,
    '40.5': 3.3,
    '41.5': 3.2,
    '42.5': 3.2,
    '43.5': 3.2,
    '44.5': 3.1,
    '45.5': 3.1,
    '46.5': 3,
    '47.5': 3,
    '48.5': 3,
    '49.5': 2.9,
    '50.5': 2.9,
    '51.5': 2.8,
    '52.5': 2.8,
    '53.5': 2.8,
    '54.5': 2.7,
    '55.5': 2.7,
    '56.5': 2.6,
    '57.5': 2.6,
    '58.5': 2.6,
    '59.5': 2.5,
    '60.5': 2.5,
    '61.5': 2.4,
    '62.5': 2.4,
    '63.5': 2.4,
    '64.5': 2.3,
    '65.5': 2.3,
    '66.5': 2.2,
    '67.5': 2.2,
    '68.5': 2.2,
    '69.5': 2.1,
    '70.5': 2.1,
    '71.5': 2.1,
    '72.5': 2,
    '73.5': 2,
    '74.5': 1.9,
    '75.5': 1.9,
    '76.5': 1.9,
    '77.5': 1.8,
    '78.5': 1.8,
    '79.5': 1.7,
    '80.5': 1.7,
    '81.5': 1.7,
    '82.5': 1.6,
    '83.5': 1.6,
    '84.5': 1.5,
    '85.5': 1.5,
    '86.5': 1.5,
    '87.5': 1.4,
    '88.5': 1.4,
    '89.5': 1.4,
    '90.5': 1.3,
    '91.5': 1.3,
    '92.5': 1.2,
    '93.5': 1.2,
    '94.5': 1.2,
    '95.5': 1.1,
    '96.5': 1.1,
    '97.5': 1.1,
    '98.5': 1,
    '99.5': 1,
    '100.5': 0.9,
    '101.5': 0.9,
    '102.5': 0.9,
    '103.5': 0.8,
    '104.5': 0.8,
    '105.5': 0.8,
    '106.5': 0.7,
    '107.5': 0.7,
    '108.5': 0.6,
    '109.5': 0.6,
    '110.5': 0.6,
    '111.5': 0.5,
    '112.5': 0.5,
    '113.5': 0.5,
    '114.5': 0.4,
    '115.5': 0.4,
    '116.5': 0.3,
    '117.5': 0.3,
    '118.5': 0.3,
    '119.5': 0.2,
    '120.5': 0.2,
    '121.5': 0.2,
    '122.5': 0.1,
    '123.5': 0.1,
    '124.5': 0,
    '125.5': 0,
  },
  '3': {
    '0': 5.5,
    '1': 5.4,
    '2': 5.4,
    '3': 5.3,
    '4': 5.3,
    '5': 5.3,
    '6': 5.2,
    '7': 5.2,
    '8': 5.1,
    '9': 5.1,
    '10': 5,
    '11': 5,
    '12': 5,
    '13': 4.9,
    '14': 4.9,
    '15': 4.8,
    '16': 4.8,
    '17': 4.8,
    '18': 4.7,
    '19': 4.7,
    '20': 4.6,
    '21': 4.6,
    '22': 4.5,
    '23': 4.5,
    '24': 4.5,
    '25': 4.4,
    '26': 4.4,
    '27': 4.3,
    '28': 4.3,
    '29': 4.3,
    '30': 4.2,
    '31': 4.2,
    '32': 4.1,
    '33': 4.1,
    '34': 4,
    '35': 4,
    '36': 4,
    '37': 3.9,
    '38': 3.9,
    '39': 3.8,
    '40': 3.8,
    '41': 3.8,
    '42': 3.7,
    '43': 3.7,
    '44': 3.6,
    '45': 3.6,
    '46': 3.6,
    '47': 3.5,
    '48': 3.5,
    '49': 3.4,
    '50': 3.4,
    '51': 3.4,
    '52': 3.3,
    '53': 3.3,
    '54': 3.2,
    '55': 3.2,
    '56': 3.2,
    '57': 3.1,
    '58': 3.1,
    '59': 3,
    '60': 3,
    '61': 3,
    '62': 2.9,
    '63': 2.9,
    '64': 2.8,
    '65': 2.8,
    '66': 2.8,
    '67': 2.7,
    '68': 2.7,
    '69': 2.6,
    '70': 2.6,
    '71': 2.6,
    '72': 2.5,
    '73': 2.5,
    '74': 2.4,
    '75': 2.4,
    '76': 2.4,
    '77': 2.3,
    '78': 2.3,
    '79': 2.3,
    '80': 2.2,
    '81': 2.2,
    '82': 2.1,
    '83': 2.1,
    '84': 2.1,
    '85': 2,
    '86': 2,
    '87': 1.9,
    '88': 1.9,
    '89': 1.9,
    '90': 1.8,
    '91': 1.8,
    '92': 1.7,
    '93': 1.7,
    '94': 1.7,
    '95': 1.6,
    '96': 1.6,
    '97': 1.6,
    '98': 1.5,
    '99': 1.5,
    '100': 1.4,
    '101': 1.4,
    '102': 1.4,
    '103': 1.3,
    '104': 1.3,
    '105': 1.3,
    '106': 1.2,
    '107': 1.2,
    '108': 1.1,
    '109': 1.1,
    '110': 1.1,
    '111': 1,
    '112': 1,
    '113': 0.9,
    '114': 0.9,
    '115': 0.9,
    '116': 0.8,
    '117': 0.8,
    '118': 0.8,
    '119': 0.7,
    '120': 0.7,
    '121': 0.6,
    '122': 0.6,
    '123': 0.6,
    '124': 0.5,
    '125': 0.5,
    '126': 0.5,
    '127': 0.4,
    '128': 0.4,
    '129': 0.3,
    '130': 0.3,
    '131': 0.3,
    '132': 0.2,
    '133': 0.2,
    '134': 0.2,
    '135': 0.1,
    '136': 0.1,
    '137': 0.1,
    '138': 0,
    '0.5': 5.4,
    '1.5': 5.4,
    '2.5': 5.4,
    '3.5': 5.3,
    '4.5': 5.3,
    '5.5': 5.2,
    '6.5': 5.2,
    '7.5': 5.1,
    '8.5': 5.1,
    '9.5': 5.1,
    '10.5': 5,
    '11.5': 5,
    '12.5': 4.9,
    '13.5': 4.9,
    '14.5': 4.9,
    '15.5': 4.8,
    '16.5': 4.8,
    '17.5': 4.7,
    '18.5': 4.7,
    '19.5': 4.6,
    '20.5': 4.6,
    '21.5': 4.6,
    '22.5': 4.5,
    '23.5': 4.5,
    '24.5': 4.4,
    '25.5': 4.4,
    '26.5': 4.4,
    '27.5': 4.3,
    '28.5': 4.3,
    '29.5': 4.2,
    '30.5': 4.2,
    '31.5': 4.2,
    '32.5': 4.1,
    '33.5': 4.1,
    '34.5': 4,
    '35.5': 4,
    '36.5': 3.9,
    '37.5': 3.9,
    '38.5': 3.9,
    '39.5': 3.8,
    '40.5': 3.8,
    '41.5': 3.7,
    '42.5': 3.7,
    '43.5': 3.7,
    '44.5': 3.6,
    '45.5': 3.6,
    '46.5': 3.5,
    '47.5': 3.5,
    '48.5': 3.5,
    '49.5': 3.4,
    '50.5': 3.4,
    '51.5': 3.3,
    '52.5': 3.3,
    '53.5': 3.3,
    '54.5': 3.2,
    '55.5': 3.2,
    '56.5': 3.1,
    '57.5': 3.1,
    '58.5': 3.1,
    '59.5': 3,
    '60.5': 3,
    '61.5': 2.9,
    '62.5': 2.9,
    '63.5': 2.9,
    '64.5': 2.8,
    '65.5': 2.8,
    '66.5': 2.7,
    '67.5': 2.7,
    '68.5': 2.7,
    '69.5': 2.6,
    '70.5': 2.6,
    '71.5': 2.5,
    '72.5': 2.5,
    '73.5': 2.5,
    '74.5': 2.4,
    '75.5': 2.4,
    '76.5': 2.3,
    '77.5': 2.3,
    '78.5': 2.3,
    '79.5': 2.2,
    '80.5': 2.2,
    '81.5': 2.2,
    '82.5': 2.1,
    '83.5': 2.1,
    '84.5': 2,
    '85.5': 2,
    '86.5': 2,
    '87.5': 1.9,
    '88.5': 1.9,
    '89.5': 1.8,
    '90.5': 1.8,
    '91.5': 1.8,
    '92.5': 1.7,
    '93.5': 1.7,
    '94.5': 1.7,
    '95.5': 1.6,
    '96.5': 1.6,
    '97.5': 1.5,
    '98.5': 1.5,
    '99.5': 1.5,
    '100.5': 1.4,
    '101.5': 1.4,
    '102.5': 1.3,
    '103.5': 1.3,
    '104.5': 1.3,
    '105.5': 1.2,
    '106.5': 1.2,
    '107.5': 1.2,
    '108.5': 1.1,
    '109.5': 1.1,
    '110.5': 1,
    '111.5': 1,
    '112.5': 1,
    '113.5': 0.9,
    '114.5': 0.9,
    '115.5': 0.9,
    '116.5': 0.8,
    '117.5': 0.8,
    '118.5': 0.7,
    '119.5': 0.7,
    '120.5': 0.7,
    '121.5': 0.6,
    '122.5': 0.6,
    '123.5': 0.6,
    '124.5': 0.5,
    '125.5': 0.5,
    '126.5': 0.4,
    '127.5': 0.4,
    '128.5': 0.4,
    '129.5': 0.3,
    '130.5': 0.3,
    '131.5': 0.3,
    '132.5': 0.2,
    '133.5': 0.2,
    '134.5': 0.1,
    '135.5': 0.1,
    '136.5': 0.1,
    '137.5': 0,
  },
  '3.5': {
    '0': 6,
    '1': 6,
    '2': 5.9,
    '3': 5.9,
    '4': 5.8,
    '5': 5.8,
    '6': 5.7,
    '7': 5.7,
    '8': 5.7,
    '9': 5.6,
    '10': 5.6,
    '11': 5.5,
    '12': 5.5,
    '13': 5.4,
    '14': 5.4,
    '15': 5.4,
    '16': 5.3,
    '17': 5.3,
    '18': 5.2,
    '19': 5.2,
    '20': 5.1,
    '21': 5.1,
    '22': 5.1,
    '23': 5,
    '24': 5,
    '25': 4.9,
    '26': 4.9,
    '27': 4.9,
    '28': 4.8,
    '29': 4.8,
    '30': 4.7,
    '31': 4.7,
    '32': 4.6,
    '33': 4.6,
    '34': 4.6,
    '35': 4.5,
    '36': 4.5,
    '37': 4.4,
    '38': 4.4,
    '39': 4.4,
    '40': 4.3,
    '41': 4.3,
    '42': 4.2,
    '43': 4.2,
    '44': 4.2,
    '45': 4.1,
    '46': 4.1,
    '47': 4,
    '48': 4,
    '49': 3.9,
    '50': 3.9,
    '51': 3.9,
    '52': 3.8,
    '53': 3.8,
    '54': 3.7,
    '55': 3.7,
    '56': 3.7,
    '57': 3.6,
    '58': 3.6,
    '59': 3.5,
    '60': 3.5,
    '61': 3.5,
    '62': 3.4,
    '63': 3.4,
    '64': 3.3,
    '65': 3.3,
    '66': 3.3,
    '67': 3.2,
    '68': 3.2,
    '69': 3.1,
    '70': 3.1,
    '71': 3.1,
    '72': 3,
    '73': 3,
    '74': 2.9,
    '75': 2.9,
    '76': 2.9,
    '77': 2.8,
    '78': 2.8,
    '79': 2.7,
    '80': 2.7,
    '81': 2.7,
    '82': 2.6,
    '83': 2.6,
    '84': 2.5,
    '85': 2.5,
    '86': 2.5,
    '87': 2.4,
    '88': 2.4,
    '89': 2.3,
    '90': 2.3,
    '91': 2.3,
    '92': 2.2,
    '93': 2.2,
    '94': 2.2,
    '95': 2.1,
    '96': 2.1,
    '97': 2,
    '98': 2,
    '99': 2,
    '100': 1.9,
    '101': 1.9,
    '102': 1.8,
    '103': 1.8,
    '104': 1.8,
    '105': 1.7,
    '106': 1.7,
    '107': 1.7,
    '108': 1.6,
    '109': 1.6,
    '110': 1.5,
    '111': 1.5,
    '112': 1.5,
    '113': 1.4,
    '114': 1.4,
    '115': 1.3,
    '116': 1.3,
    '117': 1.3,
    '118': 1.2,
    '119': 1.2,
    '120': 1.2,
    '121': 1.1,
    '122': 1.1,
    '123': 1,
    '124': 1,
    '125': 1,
    '126': 0.9,
    '127': 0.9,
    '128': 0.9,
    '129': 0.8,
    '130': 0.8,
    '131': 0.7,
    '132': 0.7,
    '133': 0.7,
    '134': 0.6,
    '135': 0.6,
    '136': 0.6,
    '137': 0.5,
    '138': 0.5,
    '139': 0.4,
    '140': 0.4,
    '141': 0.4,
    '142': 0.3,
    '143': 0.3,
    '144': 0.3,
    '145': 0.2,
    '146': 0.2,
    '147': 0.2,
    '148': 0.1,
    '149': 0.1,
    '150': 0,
    '0.5': 6,
    '1.5': 5.9,
    '2.5': 5.9,
    '3.5': 5.8,
    '4.5': 5.8,
    '5.5': 5.8,
    '6.5': 5.7,
    '7.5': 5.7,
    '8.5': 5.6,
    '9.5': 5.6,
    '10.5': 5.5,
    '11.5': 5.5,
    '12.5': 5.5,
    '13.5': 5.4,
    '14.5': 5.4,
    '15.5': 5.3,
    '16.5': 5.3,
    '17.5': 5.3,
    '18.5': 5.2,
    '19.5': 5.2,
    '20.5': 5.1,
    '21.5': 5.1,
    '22.5': 5,
    '23.5': 5,
    '24.5': 5,
    '25.5': 4.9,
    '26.5': 4.9,
    '27.5': 4.8,
    '28.5': 4.8,
    '29.5': 4.7,
    '30.5': 4.7,
    '31.5': 4.7,
    '32.5': 4.6,
    '33.5': 4.6,
    '34.5': 4.5,
    '35.5': 4.5,
    '36.5': 4.5,
    '37.5': 4.4,
    '38.5': 4.4,
    '39.5': 4.3,
    '40.5': 4.3,
    '41.5': 4.3,
    '42.5': 4.2,
    '43.5': 4.2,
    '44.5': 4.1,
    '45.5': 4.1,
    '46.5': 4,
    '47.5': 4,
    '48.5': 4,
    '49.5': 3.9,
    '50.5': 3.9,
    '51.5': 3.8,
    '52.5': 3.8,
    '53.5': 3.8,
    '54.5': 3.7,
    '55.5': 3.7,
    '56.5': 3.6,
    '57.5': 3.6,
    '58.5': 3.6,
    '59.5': 3.5,
    '60.5': 3.5,
    '61.5': 3.4,
    '62.5': 3.4,
    '63.5': 3.4,
    '64.5': 3.3,
    '65.5': 3.3,
    '66.5': 3.2,
    '67.5': 3.2,
    '68.5': 3.2,
    '69.5': 3.1,
    '70.5': 3.1,
    '71.5': 3,
    '72.5': 3,
    '73.5': 3,
    '74.5': 2.9,
    '75.5': 2.9,
    '76.5': 2.8,
    '77.5': 2.8,
    '78.5': 2.8,
    '79.5': 2.7,
    '80.5': 2.7,
    '81.5': 2.6,
    '82.5': 2.6,
    '83.5': 2.6,
    '84.5': 2.5,
    '85.5': 2.5,
    '86.5': 2.4,
    '87.5': 2.4,
    '88.5': 2.4,
    '89.5': 2.3,
    '90.5': 2.3,
    '91.5': 2.3,
    '92.5': 2.2,
    '93.5': 2.2,
    '94.5': 2.1,
    '95.5': 2.1,
    '96.5': 2.1,
    '97.5': 2,
    '98.5': 2,
    '99.5': 1.9,
    '100.5': 1.9,
    '101.5': 1.9,
    '102.5': 1.8,
    '103.5': 1.8,
    '104.5': 1.7,
    '105.5': 1.7,
    '106.5': 1.7,
    '107.5': 1.6,
    '108.5': 1.6,
    '109.5': 1.6,
    '110.5': 1.5,
    '111.5': 1.5,
    '112.5': 1.4,
    '113.5': 1.4,
    '114.5': 1.4,
    '115.5': 1.3,
    '116.5': 1.3,
    '117.5': 1.3,
    '118.5': 1.2,
    '119.5': 1.2,
    '120.5': 1.1,
    '121.5': 1.1,
    '122.5': 1.1,
    '123.5': 1,
    '124.5': 1,
    '125.5': 1,
    '126.5': 0.9,
    '127.5': 0.9,
    '128.5': 0.8,
    '129.5': 0.8,
    '130.5': 0.8,
    '131.5': 0.7,
    '132.5': 0.7,
    '133.5': 0.7,
    '134.5': 0.6,
    '135.5': 0.6,
    '136.5': 0.5,
    '137.5': 0.5,
    '138.5': 0.5,
    '139.5': 0.4,
    '140.5': 0.4,
    '141.5': 0.4,
    '142.5': 0.3,
    '143.5': 0.3,
    '144.5': 0.2,
    '145.5': 0.2,
    '146.5': 0.2,
    '147.5': 0.1,
    '148.5': 0.1,
    '149.5': 0.1,
  },
  '4': {
    '0': 6.5,
    '1': 6.5,
    '2': 6.4,
    '3': 6.4,
    '4': 6.4,
    '5': 6.3,
    '6': 6.3,
    '7': 6.2,
    '8': 6.2,
    '9': 6.1,
    '10': 6.1,
    '11': 6,
    '12': 6,
    '13': 6,
    '14': 5.9,
    '15': 5.9,
    '16': 5.8,
    '17': 5.8,
    '18': 5.7,
    '19': 5.7,
    '20': 5.7,
    '21': 5.6,
    '22': 5.6,
    '23': 5.5,
    '24': 5.5,
    '25': 5.5,
    '26': 5.4,
    '27': 5.4,
    '28': 5.3,
    '29': 5.3,
    '30': 5.2,
    '31': 5.2,
    '32': 5.2,
    '33': 5.1,
    '34': 5.1,
    '35': 5,
    '36': 5,
    '37': 4.9,
    '38': 4.9,
    '39': 4.9,
    '40': 4.8,
    '41': 4.8,
    '42': 4.7,
    '43': 4.7,
    '44': 4.7,
    '45': 4.6,
    '46': 4.6,
    '47': 4.5,
    '48': 4.5,
    '49': 4.4,
    '50': 4.4,
    '51': 4.4,
    '52': 4.3,
    '53': 4.3,
    '54': 4.2,
    '55': 4.2,
    '56': 4.2,
    '57': 4.1,
    '58': 4.1,
    '59': 4,
    '60': 4,
    '61': 4,
    '62': 3.9,
    '63': 3.9,
    '64': 3.8,
    '65': 3.8,
    '66': 3.8,
    '67': 3.7,
    '68': 3.7,
    '69': 3.6,
    '70': 3.6,
    '71': 3.6,
    '72': 3.5,
    '73': 3.5,
    '74': 3.4,
    '75': 3.4,
    '76': 3.4,
    '77': 3.3,
    '78': 3.3,
    '79': 3.2,
    '80': 3.2,
    '81': 3.2,
    '82': 3.1,
    '83': 3.1,
    '84': 3,
    '85': 3,
    '86': 3,
    '87': 2.9,
    '88': 2.9,
    '89': 2.8,
    '90': 2.8,
    '91': 2.8,
    '92': 2.7,
    '93': 2.7,
    '94': 2.6,
    '95': 2.6,
    '96': 2.6,
    '97': 2.5,
    '98': 2.5,
    '99': 2.4,
    '100': 2.4,
    '101': 2.4,
    '102': 2.3,
    '103': 2.3,
    '104': 2.2,
    '105': 2.2,
    '106': 2.2,
    '107': 2.1,
    '108': 2.1,
    '109': 2.1,
    '110': 2,
    '111': 2,
    '112': 1.9,
    '113': 1.9,
    '114': 1.9,
    '115': 1.8,
    '116': 1.8,
    '117': 1.7,
    '118': 1.7,
    '119': 1.7,
    '120': 1.6,
    '121': 1.6,
    '122': 1.6,
    '123': 1.5,
    '124': 1.5,
    '125': 1.4,
    '126': 1.4,
    '127': 1.4,
    '128': 1.3,
    '129': 1.3,
    '130': 1.3,
    '131': 1.2,
    '132': 1.2,
    '133': 1.1,
    '134': 1.1,
    '135': 1.1,
    '136': 1,
    '137': 1,
    '138': 1,
    '139': 0.9,
    '140': 0.9,
    '141': 0.8,
    '142': 0.8,
    '143': 0.8,
    '144': 0.7,
    '145': 0.7,
    '146': 0.7,
    '147': 0.6,
    '148': 0.6,
    '149': 0.5,
    '150': 0.5,
    '151': 0.5,
    '152': 0.4,
    '153': 0.4,
    '154': 0.4,
    '155': 0.3,
    '156': 0.3,
    '157': 0.2,
    '158': 0.2,
    '159': 0.2,
    '160': 0.1,
    '161': 0.1,
    '162': 0.1,
    '163': 0,
    '0.5': 6.5,
    '1.5': 6.5,
    '2.5': 6.4,
    '3.5': 6.4,
    '4.5': 6.3,
    '5.5': 6.3,
    '6.5': 6.2,
    '7.5': 6.2,
    '8.5': 6.2,
    '9.5': 6.1,
    '10.5': 6.1,
    '11.5': 6,
    '12.5': 6,
    '13.5': 5.9,
    '14.5': 5.9,
    '15.5': 5.9,
    '16.5': 5.8,
    '17.5': 5.8,
    '18.5': 5.7,
    '19.5': 5.7,
    '20.5': 5.6,
    '21.5': 5.6,
    '22.5': 5.6,
    '23.5': 5.5,
    '24.5': 5.5,
    '25.5': 5.4,
    '26.5': 5.4,
    '27.5': 5.3,
    '28.5': 5.3,
    '29.5': 5.3,
    '30.5': 5.2,
    '31.5': 5.2,
    '32.5': 5.1,
    '33.5': 5.1,
    '34.5': 5.1,
    '35.5': 5,
    '36.5': 5,
    '37.5': 4.9,
    '38.5': 4.9,
    '39.5': 4.8,
    '40.5': 4.8,
    '41.5': 4.8,
    '42.5': 4.7,
    '43.5': 4.7,
    '44.5': 4.6,
    '45.5': 4.6,
    '46.5': 4.6,
    '47.5': 4.5,
    '48.5': 4.5,
    '49.5': 4.4,
    '50.5': 4.4,
    '51.5': 4.3,
    '52.5': 4.3,
    '53.5': 4.3,
    '54.5': 4.2,
    '55.5': 4.2,
    '56.5': 4.1,
    '57.5': 4.1,
    '58.5': 4.1,
    '59.5': 4,
    '60.5': 4,
    '61.5': 3.9,
    '62.5': 3.9,
    '63.5': 3.9,
    '64.5': 3.8,
    '65.5': 3.8,
    '66.5': 3.7,
    '67.5': 3.7,
    '68.5': 3.7,
    '69.5': 3.6,
    '70.5': 3.6,
    '71.5': 3.5,
    '72.5': 3.5,
    '73.5': 3.5,
    '74.5': 3.4,
    '75.5': 3.4,
    '76.5': 3.3,
    '77.5': 3.3,
    '78.5': 3.3,
    '79.5': 3.2,
    '80.5': 3.2,
    '81.5': 3.1,
    '82.5': 3.1,
    '83.5': 3.1,
    '84.5': 3,
    '85.5': 3,
    '86.5': 2.9,
    '87.5': 2.9,
    '88.5': 2.9,
    '89.5': 2.8,
    '90.5': 2.8,
    '91.5': 2.7,
    '92.5': 2.7,
    '93.5': 2.7,
    '94.5': 2.6,
    '95.5': 2.6,
    '96.5': 2.5,
    '97.5': 2.5,
    '98.5': 2.5,
    '99.5': 2.4,
    '100.5': 2.4,
    '101.5': 2.3,
    '102.5': 2.3,
    '103.5': 2.3,
    '104.5': 2.2,
    '105.5': 2.2,
    '106.5': 2.2,
    '107.5': 2.1,
    '108.5': 2.1,
    '109.5': 2,
    '110.5': 2,
    '111.5': 2,
    '112.5': 1.9,
    '113.5': 1.9,
    '114.5': 1.8,
    '115.5': 1.8,
    '116.5': 1.8,
    '117.5': 1.7,
    '118.5': 1.7,
    '119.5': 1.7,
    '120.5': 1.6,
    '121.5': 1.6,
    '122.5': 1.5,
    '123.5': 1.5,
    '124.5': 1.5,
    '125.5': 1.4,
    '126.5': 1.4,
    '127.5': 1.3,
    '128.5': 1.3,
    '129.5': 1.3,
    '130.5': 1.2,
    '131.5': 1.2,
    '132.5': 1.2,
    '133.5': 1.1,
    '134.5': 1.1,
    '135.5': 1,
    '136.5': 1,
    '137.5': 1,
    '138.5': 0.9,
    '139.5': 0.9,
    '140.5': 0.9,
    '141.5': 0.8,
    '142.5': 0.8,
    '143.5': 0.7,
    '144.5': 0.7,
    '145.5': 0.7,
    '146.5': 0.6,
    '147.5': 0.6,
    '148.5': 0.6,
    '149.5': 0.5,
    '150.5': 0.5,
    '151.5': 0.4,
    '152.5': 0.4,
    '153.5': 0.4,
    '154.5': 0.3,
    '155.5': 0.3,
    '156.5': 0.3,
    '157.5': 0.2,
    '158.5': 0.2,
    '159.5': 0.2,
    '160.5': 0.1,
    '161.5': 0.1,
    '162.5': 0,
    '163.5': 0,
  },
  '4.5': {
    '0': 7.1,
    '1': 7,
    '2': 7,
    '3': 6.9,
    '4': 6.9,
    '5': 6.8,
    '6': 6.8,
    '7': 6.7,
    '8': 6.7,
    '9': 6.7,
    '10': 6.6,
    '11': 6.6,
    '12': 6.5,
    '13': 6.5,
    '14': 6.4,
    '15': 6.4,
    '16': 6.4,
    '17': 6.3,
    '18': 6.3,
    '19': 6.2,
    '20': 6.2,
    '21': 6.1,
    '22': 6.1,
    '23': 6.1,
    '24': 6,
    '25': 6,
    '26': 5.9,
    '27': 5.9,
    '28': 5.8,
    '29': 5.8,
    '30': 5.8,
    '31': 5.7,
    '32': 5.7,
    '33': 5.6,
    '34': 5.6,
    '35': 5.5,
    '36': 5.5,
    '37': 5.5,
    '38': 5.4,
    '39': 5.4,
    '40': 5.3,
    '41': 5.3,
    '42': 5.2,
    '43': 5.2,
    '44': 5.2,
    '45': 5.1,
    '46': 5.1,
    '47': 5,
    '48': 5,
    '49': 5,
    '50': 4.9,
    '51': 4.9,
    '52': 4.8,
    '53': 4.8,
    '54': 4.7,
    '55': 4.7,
    '56': 4.7,
    '57': 4.6,
    '58': 4.6,
    '59': 4.5,
    '60': 4.5,
    '61': 4.5,
    '62': 4.4,
    '63': 4.4,
    '64': 4.3,
    '65': 4.3,
    '66': 4.3,
    '67': 4.2,
    '68': 4.2,
    '69': 4.1,
    '70': 4.1,
    '71': 4.1,
    '72': 4,
    '73': 4,
    '74': 3.9,
    '75': 3.9,
    '76': 3.8,
    '77': 3.8,
    '78': 3.8,
    '79': 3.7,
    '80': 3.7,
    '81': 3.6,
    '82': 3.6,
    '83': 3.6,
    '84': 3.5,
    '85': 3.5,
    '86': 3.4,
    '87': 3.4,
    '88': 3.4,
    '89': 3.3,
    '90': 3.3,
    '91': 3.2,
    '92': 3.2,
    '93': 3.2,
    '94': 3.1,
    '95': 3.1,
    '96': 3,
    '97': 3,
    '98': 3,
    '99': 2.9,
    '100': 2.9,
    '101': 2.8,
    '102': 2.8,
    '103': 2.8,
    '104': 2.7,
    '105': 2.7,
    '106': 2.7,
    '107': 2.6,
    '108': 2.6,
    '109': 2.5,
    '110': 2.5,
    '111': 2.5,
    '112': 2.4,
    '113': 2.4,
    '114': 2.3,
    '115': 2.3,
    '116': 2.3,
    '117': 2.2,
    '118': 2.2,
    '119': 2.1,
    '120': 2.1,
    '121': 2.1,
    '122': 2,
    '123': 2,
    '124': 2,
    '125': 1.9,
    '126': 1.9,
    '127': 1.8,
    '128': 1.8,
    '129': 1.8,
    '130': 1.7,
    '131': 1.7,
    '132': 1.6,
    '133': 1.6,
    '134': 1.6,
    '135': 1.6,
    '136': 1.5,
    '137': 1.5,
    '138': 1.4,
    '139': 1.4,
    '140': 1.3,
    '141': 1.3,
    '142': 1.3,
    '143': 1.2,
    '144': 1.2,
    '145': 1.2,
    '146': 1.1,
    '147': 1.1,
    '148': 1,
    '149': 1,
    '150': 1,
    '151': 0.9,
    '152': 0.9,
    '153': 0.9,
    '154': 0.8,
    '155': 0.8,
    '156': 0.7,
    '157': 0.7,
    '158': 0.7,
    '159': 0.6,
    '160': 0.6,
    '161': 0.6,
    '162': 0.5,
    '163': 0.5,
    '164': 0.4,
    '165': 0.4,
    '166': 0.4,
    '167': 0.3,
    '168': 0.3,
    '169': 0.3,
    '170': 0.2,
    '171': 0.2,
    '172': 0.2,
    '173': 0.1,
    '174': 0.1,
    '175': 0,
    '176': 0,
    '0.5': 7,
    '1.5': 7,
    '2.5': 6.9,
    '3.5': 6.9,
    '4.5': 6.9,
    '5.5': 6.8,
    '6.5': 6.8,
    '7.5': 6.7,
    '8.5': 6.7,
    '9.5': 6.6,
    '10.5': 6.6,
    '11.5': 6.6,
    '12.5': 6.5,
    '13.5': 6.5,
    '14.5': 6.4,
    '15.5': 6.4,
    '16.5': 6.3,
    '17.5': 6.3,
    '18.5': 6.2,
    '19.5': 6.2,
    '20.5': 6.2,
    '21.5': 6.1,
    '22.5': 6.1,
    '23.5': 6,
    '24.5': 6,
    '25.5': 5.9,
    '26.5': 5.9,
    '27.5': 5.9,
    '28.5': 5.8,
    '29.5': 5.8,
    '30.5': 5.7,
    '31.5': 5.7,
    '32.5': 5.7,
    '33.5': 5.6,
    '34.5': 5.6,
    '35.5': 5.5,
    '36.5': 5.5,
    '37.5': 5.4,
    '38.5': 5.4,
    '39.5': 5.4,
    '40.5': 5.3,
    '41.5': 5.3,
    '42.5': 5.2,
    '43.5': 5.2,
    '44.5': 5.1,
    '45.5': 5.1,
    '46.5': 5.1,
    '47.5': 5,
    '48.5': 5,
    '49.5': 4.9,
    '50.5': 4.9,
    '51.5': 4.9,
    '52.5': 4.8,
    '53.5': 4.8,
    '54.5': 4.7,
    '55.5': 4.7,
    '56.5': 4.6,
    '57.5': 4.6,
    '58.5': 4.6,
    '59.5': 4.5,
    '60.5': 4.5,
    '61.5': 4.4,
    '62.5': 4.4,
    '63.5': 4.4,
    '64.5': 4.3,
    '65.5': 4.3,
    '66.5': 4.2,
    '67.5': 4.2,
    '68.5': 4.2,
    '69.5': 4.1,
    '70.5': 4.1,
    '71.5': 4,
    '72.5': 4,
    '73.5': 3.9,
    '74.5': 3.9,
    '75.5': 3.9,
    '76.5': 3.8,
    '77.5': 3.8,
    '78.5': 3.7,
    '79.5': 3.7,
    '80.5': 3.7,
    '81.5': 3.6,
    '82.5': 3.6,
    '83.5': 3.5,
    '84.5': 3.5,
    '85.5': 3.5,
    '86.5': 3.4,
    '87.5': 3.4,
    '88.5': 3.3,
    '89.5': 3.3,
    '90.5': 3.3,
    '91.5': 3.2,
    '92.5': 3.2,
    '93.5': 3.1,
    '94.5': 3.1,
    '95.5': 3.1,
    '96.5': 3,
    '97.5': 3,
    '98.5': 2.9,
    '99.5': 2.9,
    '100.5': 2.9,
    '101.5': 2.8,
    '102.5': 2.8,
    '103.5': 2.7,
    '104.5': 2.7,
    '105.5': 2.7,
    '106.5': 2.6,
    '107.5': 2.6,
    '108.5': 2.6,
    '109.5': 2.5,
    '110.5': 2.5,
    '111.5': 2.4,
    '112.5': 2.4,
    '113.5': 2.4,
    '114.5': 2.3,
    '115.5': 2.3,
    '116.5': 2.2,
    '117.5': 2.2,
    '118.5': 2.2,
    '119.5': 2.1,
    '120.5': 2.1,
    '121.5': 2,
    '122.5': 2,
    '123.5': 2,
    '124.5': 1.9,
    '125.5': 1.9,
    '126.5': 1.9,
    '127.5': 1.8,
    '128.5': 1.8,
    '129.5': 1.7,
    '130.5': 1.7,
    '131.5': 1.7,
    '132.5': 1.6,
    '133.5': 1.6,
    '134.5': 1.6,
    '135.5': 1.5,
    '136.5': 1.5,
    '137.5': 1.4,
    '138.5': 1.4,
    '139.5': 1.4,
    '140.5': 1.3,
    '141.5': 1.3,
    '142.5': 1.2,
    '143.5': 1.2,
    '144.5': 1.2,
    '145.5': 1.1,
    '146.5': 1.1,
    '147.5': 1.1,
    '148.5': 1,
    '149.5': 1,
    '150.5': 0.9,
    '151.5': 0.9,
    '152.5': 0.9,
    '153.5': 0.8,
    '154.5': 0.8,
    '155.5': 0.8,
    '156.5': 0.7,
    '157.5': 0.7,
    '158.5': 0.6,
    '159.5': 0.6,
    '160.5': 0.6,
    '161.5': 0.5,
    '162.5': 0.5,
    '163.5': 0.5,
    '164.5': 0.4,
    '165.5': 0.4,
    '166.5': 0.4,
    '167.5': 0.3,
    '168.5': 0.3,
    '169.5': 0.2,
    '170.5': 0.2,
    '171.5': 0.2,
    '172.5': 0.1,
    '173.5': 0.1,
    '174.5': 0.1,
    '175.5': 0,
  },
  '5': {
    '0': 7.6,
    '1': 7.5,
    '2': 7.5,
    '3': 7.4,
    '4': 7.4,
    '5': 7.4,
    '6': 7.3,
    '7': 7.3,
    '8': 7.2,
    '9': 7.2,
    '10': 7.1,
    '11': 7.1,
    '12': 7.1,
    '13': 7,
    '14': 7,
    '15': 6.9,
    '16': 6.9,
    '17': 6.8,
    '18': 6.8,
    '19': 6.7,
    '20': 6.7,
    '21': 6.7,
    '22': 6.6,
    '23': 6.6,
    '24': 6.5,
    '25': 6.5,
    '26': 6.4,
    '27': 6.4,
    '28': 6.4,
    '29': 6.3,
    '30': 6.3,
    '31': 6.2,
    '32': 6.2,
    '33': 6.1,
    '34': 6.1,
    '35': 6.1,
    '36': 6,
    '37': 6,
    '38': 5.9,
    '39': 5.9,
    '40': 5.8,
    '41': 5.8,
    '42': 5.8,
    '43': 5.7,
    '44': 5.7,
    '45': 5.6,
    '46': 5.6,
    '47': 5.5,
    '48': 5.5,
    '49': 5.5,
    '50': 5.4,
    '51': 5.4,
    '52': 5.3,
    '53': 5.3,
    '54': 5.3,
    '55': 5.2,
    '56': 5.2,
    '57': 5.1,
    '58': 5.1,
    '59': 5,
    '60': 5,
    '61': 5,
    '62': 4.9,
    '63': 4.9,
    '64': 4.8,
    '65': 4.8,
    '66': 4.8,
    '67': 4.7,
    '68': 4.7,
    '69': 4.6,
    '70': 4.6,
    '71': 4.5,
    '72': 4.5,
    '73': 4.5,
    '74': 4.4,
    '75': 4.4,
    '76': 4.3,
    '77': 4.3,
    '78': 4.3,
    '79': 4.2,
    '80': 4.2,
    '81': 4.1,
    '82': 4.1,
    '83': 4.1,
    '84': 4,
    '85': 4,
    '86': 3.9,
    '87': 3.9,
    '88': 3.9,
    '89': 3.8,
    '90': 3.8,
    '91': 3.7,
    '92': 3.7,
    '93': 3.6,
    '94': 3.6,
    '95': 3.6,
    '96': 3.5,
    '97': 3.5,
    '98': 3.4,
    '99': 3.4,
    '100': 3.4,
    '101': 3.3,
    '102': 3.3,
    '103': 3.2,
    '104': 3.2,
    '105': 3.2,
    '106': 3.1,
    '107': 3.1,
    '108': 3.1,
    '109': 3,
    '110': 3,
    '111': 2.9,
    '112': 2.9,
    '113': 2.9,
    '114': 2.8,
    '115': 2.8,
    '116': 2.7,
    '117': 2.7,
    '118': 2.7,
    '119': 2.6,
    '120': 2.6,
    '121': 2.5,
    '122': 2.5,
    '123': 2.5,
    '124': 2.4,
    '125': 2.4,
    '126': 2.3,
    '127': 2.3,
    '128': 2.3,
    '129': 2.2,
    '130': 2.2,
    '131': 2.2,
    '132': 2.1,
    '133': 2.1,
    '134': 2,
    '135': 2,
    '136': 2,
    '137': 1.9,
    '138': 1.9,
    '139': 1.8,
    '140': 1.8,
    '141': 1.8,
    '142': 1.7,
    '143': 1.7,
    '144': 1.7,
    '145': 1.6,
    '146': 1.6,
    '147': 1.5,
    '148': 1.5,
    '149': 1.5,
    '150': 1.4,
    '151': 1.4,
    '152': 1.3,
    '153': 1.3,
    '154': 1.3,
    '155': 1.2,
    '156': 1.2,
    '157': 1.2,
    '158': 1.1,
    '159': 1.1,
    '160': 1,
    '161': 1,
    '162': 1,
    '163': 0.9,
    '164': 0.9,
    '165': 0.9,
    '166': 0.8,
    '167': 0.8,
    '168': 0.8,
    '169': 0.7,
    '170': 0.7,
    '171': 0.6,
    '172': 0.6,
    '173': 0.6,
    '174': 0.5,
    '175': 0.5,
    '176': 0.5,
    '177': 0.4,
    '178': 0.4,
    '179': 0.3,
    '180': 0.3,
    '181': 0.3,
    '182': 0.2,
    '183': 0.2,
    '184': 0.2,
    '185': 0.1,
    '186': 0.1,
    '187': 0.1,
    '188': 0,
    '0.5': 7.6,
    '1.5': 7.5,
    '2.5': 7.5,
    '3.5': 7.4,
    '4.5': 7.4,
    '5.5': 7.3,
    '6.5': 7.3,
    '7.5': 7.3,
    '8.5': 7.2,
    '9.5': 7.2,
    '10.5': 7.1,
    '11.5': 7.1,
    '12.5': 7,
    '13.5': 7,
    '14.5': 6.9,
    '15.5': 6.9,
    '16.5': 6.9,
    '17.5': 6.8,
    '18.5': 6.8,
    '19.5': 6.7,
    '20.5': 6.7,
    '21.5': 6.6,
    '22.5': 6.6,
    '23.5': 6.6,
    '24.5': 6.5,
    '25.5': 6.5,
    '26.5': 6.4,
    '27.5': 6.4,
    '28.5': 6.3,
    '29.5': 6.3,
    '30.5': 6.2,
    '31.5': 6.2,
    '32.5': 6.2,
    '33.5': 6.1,
    '34.5': 6.1,
    '35.5': 6,
    '36.5': 6,
    '37.5': 5.9,
    '38.5': 5.9,
    '39.5': 5.9,
    '40.5': 5.8,
    '41.5': 5.8,
    '42.5': 5.7,
    '43.5': 5.7,
    '44.5': 5.7,
    '45.5': 5.6,
    '46.5': 5.6,
    '47.5': 5.5,
    '48.5': 5.5,
    '49.5': 5.4,
    '50.5': 5.4,
    '51.5': 5.4,
    '52.5': 5.3,
    '53.5': 5.3,
    '54.5': 5.2,
    '55.5': 5.2,
    '56.5': 5.1,
    '57.5': 5.1,
    '58.5': 5.1,
    '59.5': 5,
    '60.5': 5,
    '61.5': 4.9,
    '62.5': 4.9,
    '63.5': 4.9,
    '64.5': 4.8,
    '65.5': 4.8,
    '66.5': 4.7,
    '67.5': 4.7,
    '68.5': 4.6,
    '69.5': 4.6,
    '70.5': 4.6,
    '71.5': 4.5,
    '72.5': 4.5,
    '73.5': 4.4,
    '74.5': 4.4,
    '75.5': 4.4,
    '76.5': 4.3,
    '77.5': 4.3,
    '78.5': 4.2,
    '79.5': 4.2,
    '80.5': 4.2,
    '81.5': 4.1,
    '82.5': 4.1,
    '83.5': 4,
    '84.5': 4,
    '85.5': 4,
    '86.5': 3.9,
    '87.5': 3.9,
    '88.5': 3.8,
    '89.5': 3.8,
    '90.5': 3.7,
    '91.5': 3.7,
    '92.5': 3.7,
    '93.5': 3.6,
    '94.5': 3.6,
    '95.5': 3.5,
    '96.5': 3.5,
    '97.5': 3.5,
    '98.5': 3.4,
    '99.5': 3.4,
    '100.5': 3.3,
    '101.5': 3.3,
    '102.5': 3.3,
    '103.5': 3.2,
    '104.5': 3.2,
    '105.5': 3.2,
    '106.5': 3.1,
    '107.5': 3.1,
    '108.5': 3,
    '109.5': 3,
    '110.5': 3,
    '111.5': 2.9,
    '112.5': 2.9,
    '113.5': 2.8,
    '114.5': 2.8,
    '115.5': 2.8,
    '116.5': 2.7,
    '117.5': 2.7,
    '118.5': 2.6,
    '119.5': 2.6,
    '120.5': 2.6,
    '121.5': 2.5,
    '122.5': 2.5,
    '123.5': 2.4,
    '124.5': 2.4,
    '125.5': 2.4,
    '126.5': 2.3,
    '127.5': 2.3,
    '128.5': 2.2,
    '129.5': 2.2,
    '130.5': 2.2,
    '131.5': 2.1,
    '132.5': 2.1,
    '133.5': 2.1,
    '134.5': 2,
    '135.5': 2,
    '136.5': 1.9,
    '137.5': 1.9,
    '138.5': 1.9,
    '139.5': 1.8,
    '140.5': 1.8,
    '141.5': 1.7,
    '142.5': 1.7,
    '143.5': 1.7,
    '144.5': 1.6,
    '145.5': 1.6,
    '146.5': 1.6,
    '147.5': 1.5,
    '148.5': 1.5,
    '149.5': 1.4,
    '150.5': 1.4,
    '151.5': 1.4,
    '152.5': 1.3,
    '153.5': 1.3,
    '154.5': 1.3,
    '155.5': 1.2,
    '156.5': 1.2,
    '157.5': 1.1,
    '158.5': 1.1,
    '159.5': 1.1,
    '160.5': 1,
    '161.5': 1,
    '162.5': 1,
    '163.5': 0.9,
    '164.5': 0.9,
    '165.5': 0.8,
    '166.5': 0.8,
    '167.5': 0.8,
    '168.5': 0.7,
    '169.5': 0.7,
    '170.5': 0.7,
    '171.5': 0.6,
    '172.5': 0.6,
    '173.5': 0.5,
    '174.5': 0.5,
    '175.5': 0.5,
    '176.5': 0.4,
    '177.5': 0.4,
    '178.5': 0.4,
    '179.5': 0.3,
    '180.5': 0.3,
    '181.5': 0.3,
    '182.5': 0.2,
    '183.5': 0.2,
    '184.5': 0.1,
    '185.5': 0.1,
    '186.5': 0.1,
    '187.5': 0,
  },
  //  5 - 10
  '5.5': {
    '0': 8.1,
    '1': 8.1,
    '2': 8,
    '3': 8,
    '4': 7.9,
    '5': 7.9,
    '6': 7.8,
    '7': 7.8,
    '8': 7.8,
    '9': 7.7,
    '10': 7.7,
    '11': 7.6,
    '12': 7.6,
    '13': 7.5,
    '14': 7.5,
    '15': 7.4,
    '16': 7.4,
    '17': 7.4,
    '18': 7.3,
    '19': 7.3,
    '20': 7.2,
    '21': 7.2,
    '22': 7.1,
    '23': 7.1,
    '24': 7,
    '25': 7,
    '26': 7,
    '27': 6.9,
    '28': 6.9,
    '29': 6.8,
    '30': 6.8,
    '31': 6.7,
    '32': 6.7,
    '33': 6.7,
    '34': 6.6,
    '35': 6.6,
    '36': 6.5,
    '37': 6.5,
    '38': 6.4,
    '39': 6.4,
    '40': 6.4,
    '41': 6.3,
    '42': 6.3,
    '43': 6.2,
    '44': 6.2,
    '45': 6.1,
    '46': 6.1,
    '47': 6.1,
    '48': 6,
    '49': 6,
    '50': 5.9,
    '51': 5.9,
    '52': 5.8,
    '53': 5.8,
    '54': 5.8,
    '55': 5.7,
    '56': 5.7,
    '57': 5.6,
    '58': 5.6,
    '59': 5.5,
    '60': 5.5,
    '61': 5.5,
    '62': 5.4,
    '63': 5.4,
    '64': 5.3,
    '65': 5.3,
    '66': 5.2,
    '67': 5.2,
    '68': 5.2,
    '69': 5.1,
    '70': 5.1,
    '71': 5,
    '72': 5,
    '73': 5,
    '74': 4.9,
    '75': 4.9,
    '76': 4.8,
    '77': 4.8,
    '78': 4.7,
    '79': 4.7,
    '80': 4.7,
    '81': 4.6,
    '82': 4.6,
    '83': 4.5,
    '84': 4.5,
    '85': 4.5,
    '86': 4.4,
    '87': 4.4,
    '88': 4.3,
    '89': 4.3,
    '90': 4.3,
    '91': 4.2,
    '92': 4.2,
    '93': 4.1,
    '94': 4.1,
    '95': 4.1,
    '96': 4,
    '97': 4,
    '98': 3.9,
    '99': 3.9,
    '100': 3.9,
    '101': 3.8,
    '102': 3.8,
    '103': 3.7,
    '104': 3.7,
    '105': 3.6,
    '106': 3.6,
    '107': 3.6,
    '108': 3.5,
    '109': 3.5,
    '110': 3.4,
    '111': 3.4,
    '112': 3.4,
    '113': 3.3,
    '114': 3.3,
    '115': 3.2,
    '116': 3.2,
    '117': 3.2,
    '118': 3.1,
    '119': 3.1,
    '120': 3.1,
    '121': 3,
    '122': 3,
    '123': 2.9,
    '124': 2.9,
    '125': 2.9,
    '126': 2.8,
    '127': 2.8,
    '128': 2.7,
    '129': 2.7,
    '130': 2.7,
    '131': 2.6,
    '132': 2.6,
    '133': 2.5,
    '134': 2.5,
    '135': 2.5,
    '136': 2.4,
    '137': 2.4,
    '138': 2.3,
    '139': 2.3,
    '140': 2.3,
    '141': 2.2,
    '142': 2.2,
    '143': 2.2,
    '144': 2.1,
    '145': 2.1,
    '146': 2,
    '147': 2,
    '148': 2,
    '149': 1.9,
    '150': 1.9,
    '151': 1.8,
    '152': 1.8,
    '153': 1.8,
    '154': 1.7,
    '155': 1.7,
    '156': 1.7,
    '157': 1.6,
    '158': 1.6,
    '159': 1.5,
    '160': 1.5,
    '161': 1.5,
    '162': 1.4,
    '163': 1.4,
    '164': 1.4,
    '165': 1.3,
    '166': 1.3,
    '167': 1.2,
    '168': 1.2,
    '169': 1.2,
    '170': 1.1,
    '171': 1.1,
    '172': 1.1,
    '173': 1,
    '174': 1,
    '175': 0.9,
    '176': 0.9,
    '177': 0.9,
    '178': 0.8,
    '179': 0.8,
    '180': 0.8,
    '181': 0.7,
    '182': 0.7,
    '183': 0.6,
    '184': 0.6,
    '185': 0.6,
    '186': 0.5,
    '187': 0.5,
    '188': 0.5,
    '189': 0.4,
    '190': 0.4,
    '191': 0.4,
    '192': 0.3,
    '193': 0.3,
    '194': 0.2,
    '195': 0.2,
    '196': 0.2,
    '197': 0.1,
    '198': 0.1,
    '199': 0.1,
    '200': 0,
    '0.5': 8.1,
    '1.5': 8,
    '2.5': 8,
    '3.5': 8,
    '4.5': 7.9,
    '5.5': 7.9,
    '6.5': 7.8,
    '7.5': 7.8,
    '8.5': 7.7,
    '9.5': 7.7,
    '10.5': 7.6,
    '11.5': 7.6,
    '12.5': 7.6,
    '13.5': 7.5,
    '14.5': 7.5,
    '15.5': 7.4,
    '16.5': 7.4,
    '17.5': 7.3,
    '18.5': 7.3,
    '19.5': 7.2,
    '20.5': 7.2,
    '21.5': 7.2,
    '22.5': 7.1,
    '23.5': 7.1,
    '24.5': 7,
    '25.5': 7,
    '26.5': 6.9,
    '27.5': 6.9,
    '28.5': 6.8,
    '29.5': 6.8,
    '30.5': 6.8,
    '31.5': 6.7,
    '32.5': 6.7,
    '33.5': 6.6,
    '34.5': 6.6,
    '35.5': 6.5,
    '36.5': 6.5,
    '37.5': 6.5,
    '38.5': 6.4,
    '39.5': 6.4,
    '40.5': 6.3,
    '41.5': 6.3,
    '42.5': 6.2,
    '43.5': 6.2,
    '44.5': 6.2,
    '45.5': 6.1,
    '46.5': 6.1,
    '47.5': 6,
    '48.5': 6,
    '49.5': 5.9,
    '50.5': 5.9,
    '51.5': 5.9,
    '52.5': 5.8,
    '53.5': 5.8,
    '54.5': 5.7,
    '55.5': 5.7,
    '56.5': 5.6,
    '57.5': 5.6,
    '58.5': 5.6,
    '59.5': 5.5,
    '60.5': 5.5,
    '61.5': 5.4,
    '62.5': 5.4,
    '63.5': 5.4,
    '64.5': 5.3,
    '65.5': 5.3,
    '66.5': 5.2,
    '67.5': 5.2,
    '68.5': 5.1,
    '69.5': 5.1,
    '70.5': 5.1,
    '71.5': 5,
    '72.5': 5,
    '73.5': 4.9,
    '74.5': 4.9,
    '75.5': 4.9,
    '76.5': 4.8,
    '77.5': 4.8,
    '78.5': 4.7,
    '79.5': 4.7,
    '80.5': 4.6,
    '81.5': 4.6,
    '82.5': 4.6,
    '83.5': 4.5,
    '84.5': 4.5,
    '85.5': 4.4,
    '86.5': 4.4,
    '87.5': 4.4,
    '88.5': 4.3,
    '89.5': 4.3,
    '90.5': 4.2,
    '91.5': 4.2,
    '92.5': 4.2,
    '93.5': 4.1,
    '94.5': 4.1,
    '95.5': 4,
    '96.5': 4,
    '97.5': 4,
    '98.5': 3.9,
    '99.5': 3.9,
    '100.5': 3.8,
    '101.5': 3.8,
    '102.5': 3.7,
    '103.5': 3.7,
    '104.5': 3.7,
    '105.5': 3.6,
    '106.5': 3.6,
    '107.5': 3.5,
    '108.5': 3.5,
    '109.5': 3.5,
    '110.5': 3.4,
    '111.5': 3.4,
    '112.5': 3.3,
    '113.5': 3.3,
    '114.5': 3.3,
    '115.5': 3.2,
    '116.5': 3.2,
    '117.5': 3.2,
    '118.5': 3.1,
    '119.5': 3.1,
    '120.5': 3,
    '121.5': 3,
    '122.5': 3,
    '123.5': 2.9,
    '124.5': 2.9,
    '125.5': 2.8,
    '126.5': 2.8,
    '127.5': 2.8,
    '128.5': 2.7,
    '129.5': 2.7,
    '130.5': 2.6,
    '131.5': 2.6,
    '132.5': 2.6,
    '133.5': 2.5,
    '134.5': 2.5,
    '135.5': 2.4,
    '136.5': 2.4,
    '137.5': 2.4,
    '138.5': 2.3,
    '139.5': 2.3,
    '140.5': 2.2,
    '141.5': 2.2,
    '142.5': 2.2,
    '143.5': 2.1,
    '144.5': 2.1,
    '145.5': 2.1,
    '146.5': 2,
    '147.5': 2,
    '148.5': 1.9,
    '149.5': 1.9,
    '150.5': 1.9,
    '151.5': 1.8,
    '152.5': 1.8,
    '153.5': 1.8,
    '154.5': 1.7,
    '155.5': 1.7,
    '156.5': 1.6,
    '157.5': 1.6,
    '158.5': 1.6,
    '159.5': 1.5,
    '160.5': 1.5,
    '161.5': 1.4,
    '162.5': 1.4,
    '163.5': 1.4,
    '164.5': 1.3,
    '165.5': 1.3,
    '166.5': 1.3,
    '167.5': 1.2,
    '168.5': 1.2,
    '169.5': 1.1,
    '170.5': 1.1,
    '171.5': 1.1,
    '172.5': 1,
    '173.5': 1,
    '174.5': 1,
    '175.5': 0.9,
    '176.5': 0.9,
    '177.5': 0.8,
    '178.5': 0.8,
    '179.5': 0.8,
    '180.5': 0.7,
    '181.5': 0.7,
    '182.5': 0.7,
    '183.5': 0.6,
    '184.5': 0.6,
    '185.5': 0.6,
    '186.5': 0.5,
    '187.5': 0.5,
    '188.5': 0.4,
    '189.5': 0.4,
    '190.5': 0.4,
    '191.5': 0.3,
    '192.5': 0.3,
    '193.5': 0.3,
    '194.5': 0.2,
    '195.5': 0.2,
    '196.5': 0.2,
    '197.5': 0.1,
    '198.5': 0.1,
    '199.5': 0,
    '200.5': 0,
  },
  '6': {
    '0': 8.6,
    '1': 8.6,
    '2': 8.6,
    '3': 8.5,
    '4': 8.5,
    '5': 8.4,
    '6': 8.4,
    '7': 8.3,
    '8': 8.3,
    '9': 8.2,
    '10': 8.2,
    '11': 8.1,
    '12': 8.1,
    '13': 8.1,
    '14': 8,
    '15': 8,
    '16': 7.9,
    '17': 7.9,
    '18': 7.8,
    '19': 7.8,
    '20': 7.7,
    '21': 7.7,
    '22': 7.7,
    '23': 7.6,
    '24': 7.6,
    '25': 7.5,
    '26': 7.5,
    '27': 7.4,
    '28': 7.4,
    '29': 7.3,
    '30': 7.3,
    '31': 7.3,
    '32': 7.2,
    '33': 7.2,
    '34': 7.1,
    '35': 7.1,
    '36': 7,
    '37': 7,
    '38': 6.9,
    '39': 6.9,
    '40': 6.9,
    '41': 6.8,
    '42': 6.8,
    '43': 6.7,
    '44': 6.7,
    '45': 6.6,
    '46': 6.6,
    '47': 6.6,
    '48': 6.5,
    '49': 6.5,
    '50': 6.4,
    '51': 6.4,
    '52': 6.3,
    '53': 6.3,
    '54': 6.3,
    '55': 6.2,
    '56': 6.2,
    '57': 6.1,
    '58': 6.1,
    '59': 6,
    '60': 6,
    '61': 6,
    '62': 5.9,
    '63': 5.9,
    '64': 5.8,
    '65': 5.8,
    '66': 5.7,
    '67': 5.7,
    '68': 5.7,
    '69': 5.6,
    '70': 5.6,
    '71': 5.5,
    '72': 5.5,
    '73': 5.5,
    '74': 5.4,
    '75': 5.4,
    '76': 5.3,
    '77': 5.3,
    '78': 5.2,
    '79': 5.2,
    '80': 5.2,
    '81': 5.1,
    '82': 5.1,
    '83': 5,
    '84': 5,
    '85': 4.9,
    '86': 4.9,
    '87': 4.9,
    '88': 4.8,
    '89': 4.8,
    '90': 4.7,
    '91': 4.7,
    '92': 4.7,
    '93': 4.6,
    '94': 4.6,
    '95': 4.5,
    '96': 4.5,
    '97': 4.5,
    '98': 4.4,
    '99': 4.4,
    '100': 4.3,
    '101': 4.3,
    '102': 4.3,
    '103': 4.2,
    '104': 4.2,
    '105': 4.1,
    '106': 4.1,
    '107': 4,
    '108': 4,
    '109': 4,
    '110': 3.9,
    '111': 3.9,
    '112': 3.8,
    '113': 3.8,
    '114': 3.8,
    '115': 3.7,
    '116': 3.7,
    '117': 3.6,
    '118': 3.6,
    '119': 3.6,
    '120': 3.5,
    '121': 3.5,
    '122': 3.4,
    '123': 3.4,
    '124': 3.4,
    '125': 3.3,
    '126': 3.3,
    '127': 3.2,
    '128': 3.2,
    '129': 3.2,
    '130': 3.1,
    '131': 3.1,
    '132': 3.1,
    '133': 3,
    '134': 3,
    '135': 2.9,
    '136': 2.9,
    '137': 2.9,
    '138': 2.8,
    '139': 2.8,
    '140': 2.7,
    '141': 2.7,
    '142': 2.7,
    '143': 2.6,
    '144': 2.6,
    '145': 2.5,
    '146': 2.5,
    '147': 2.5,
    '148': 2.4,
    '149': 2.4,
    '150': 2.3,
    '151': 2.3,
    '152': 2.3,
    '153': 2.2,
    '154': 2.2,
    '155': 2.2,
    '156': 2.1,
    '157': 2.1,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 1.9,
    '162': 1.9,
    '163': 1.8,
    '164': 1.8,
    '165': 1.8,
    '166': 1.7,
    '167': 1.7,
    '168': 1.7,
    '169': 1.6,
    '170': 1.6,
    '171': 1.5,
    '172': 1.5,
    '173': 1.5,
    '174': 1.4,
    '175': 1.4,
    '176': 1.4,
    '177': 1.3,
    '178': 1.3,
    '179': 1.2,
    '180': 1.2,
    '181': 1.2,
    '182': 1.1,
    '183': 1.1,
    '184': 1.1,
    '185': 1,
    '186': 1,
    '187': 0.9,
    '188': 0.9,
    '189': 0.9,
    '190': 0.8,
    '191': 0.8,
    '192': 0.8,
    '193': 0.7,
    '194': 0.7,
    '195': 0.6,
    '196': 0.6,
    '197': 0.6,
    '198': 0.5,
    '199': 0.5,
    '200': 0.5,
    '201': 0.4,
    '202': 0.4,
    '203': 0.4,
    '204': 0.3,
    '205': 0.3,
    '206': 0.2,
    '207': 0.2,
    '208': 0.2,
    '209': 0.1,
    '210': 0.1,
    '211': 0.1,
    '212': 0,
    '213': 0,
    '0.5': 8.6,
    '1.5': 8.6,
    '2.5': 8.5,
    '3.5': 8.5,
    '4.5': 8.4,
    '5.5': 8.4,
    '6.5': 8.3,
    '7.5': 8.3,
    '8.5': 8.3,
    '9.5': 8.2,
    '10.5': 8.2,
    '11.5': 8.1,
    '12.5': 8.1,
    '13.5': 8,
    '14.5': 8,
    '15.5': 7.9,
    '16.5': 7.9,
    '17.5': 7.9,
    '18.5': 7.8,
    '19.5': 7.8,
    '20.5': 7.7,
    '21.5': 7.7,
    '22.5': 7.6,
    '23.5': 7.6,
    '24.5': 7.5,
    '25.5': 7.5,
    '26.5': 7.5,
    '27.5': 7.4,
    '28.5': 7.4,
    '29.5': 7.3,
    '30.5': 7.3,
    '31.5': 7.2,
    '32.5': 7.2,
    '33.5': 7.1,
    '34.5': 7.1,
    '35.5': 7.1,
    '36.5': 7,
    '37.5': 7,
    '38.5': 6.9,
    '39.5': 6.9,
    '40.5': 6.8,
    '41.5': 6.8,
    '42.5': 6.8,
    '43.5': 6.7,
    '44.5': 6.7,
    '45.5': 6.6,
    '46.5': 6.6,
    '47.5': 6.5,
    '48.5': 6.5,
    '49.5': 6.5,
    '50.5': 6.4,
    '51.5': 6.4,
    '52.5': 6.3,
    '53.5': 6.3,
    '54.5': 6.2,
    '55.5': 6.2,
    '56.5': 6.1,
    '57.5': 6.1,
    '58.5': 6.1,
    '59.5': 6,
    '60.5': 6,
    '61.5': 5.9,
    '62.5': 5.9,
    '63.5': 5.9,
    '64.5': 5.8,
    '65.5': 5.8,
    '66.5': 5.7,
    '67.5': 5.7,
    '68.5': 5.6,
    '69.5': 5.6,
    '70.5': 5.6,
    '71.5': 5.5,
    '72.5': 5.5,
    '73.5': 5.4,
    '74.5': 5.4,
    '75.5': 5.3,
    '76.5': 5.3,
    '77.5': 5.3,
    '78.5': 5.2,
    '79.5': 5.2,
    '80.5': 5.1,
    '81.5': 5.1,
    '82.5': 5.1,
    '83.5': 5,
    '84.5': 5,
    '85.5': 4.9,
    '86.5': 4.9,
    '87.5': 4.8,
    '88.5': 4.8,
    '89.5': 4.8,
    '90.5': 4.7,
    '91.5': 4.7,
    '92.5': 4.6,
    '93.5': 4.6,
    '94.5': 4.6,
    '95.5': 4.5,
    '96.5': 4.5,
    '97.5': 4.4,
    '98.5': 4.4,
    '99.5': 4.4,
    '100.5': 4.3,
    '101.5': 4.3,
    '102.5': 4.2,
    '103.5': 4.2,
    '104.5': 4.2,
    '105.5': 4.1,
    '106.5': 4.1,
    '107.5': 4,
    '108.5': 4,
    '109.5': 3.9,
    '110.5': 3.9,
    '111.5': 3.9,
    '112.5': 3.8,
    '113.5': 3.8,
    '114.5': 3.7,
    '115.5': 3.7,
    '116.5': 3.7,
    '117.5': 3.6,
    '118.5': 3.6,
    '119.5': 3.5,
    '120.5': 3.5,
    '121.5': 3.5,
    '122.5': 3.4,
    '123.5': 3.4,
    '124.5': 3.3,
    '125.5': 3.3,
    '126.5': 3.3,
    '127.5': 3.2,
    '128.5': 3.2,
    '129.5': 3.1,
    '130.5': 3.1,
    '131.5': 3.1,
    '132.5': 3,
    '133.5': 3,
    '134.5': 3,
    '135.5': 2.9,
    '136.5': 2.9,
    '137.5': 2.8,
    '138.5': 2.8,
    '139.5': 2.8,
    '140.5': 2.7,
    '141.5': 2.7,
    '142.5': 2.6,
    '143.5': 2.6,
    '144.5': 2.6,
    '145.5': 2.5,
    '146.5': 2.5,
    '147.5': 2.4,
    '148.5': 2.4,
    '149.5': 2.4,
    '150.5': 2.3,
    '151.5': 2.3,
    '152.5': 2.2,
    '153.5': 2.2,
    '154.5': 2.2,
    '155.5': 2.1,
    '156.5': 2.1,
    '157.5': 2.1,
    '158.5': 2,
    '159.5': 2,
    '160.5': 1.9,
    '161.5': 1.9,
    '162.5': 1.9,
    '163.5': 1.8,
    '164.5': 1.8,
    '165.5': 1.8,
    '166.5': 1.7,
    '167.5': 1.7,
    '168.5': 1.6,
    '169.5': 1.6,
    '170.5': 1.6,
    '171.5': 1.5,
    '172.5': 1.5,
    '173.5': 1.4,
    '174.5': 1.4,
    '175.5': 1.4,
    '176.5': 1.3,
    '177.5': 1.3,
    '178.5': 1.3,
    '179.5': 1.2,
    '180.5': 1.2,
    '181.5': 1.2,
    '182.5': 1.1,
    '183.5': 1.1,
    '184.5': 1,
    '185.5': 1,
    '186.5': 1,
    '187.5': 0.9,
    '188.5': 0.9,
    '189.5': 0.9,
    '190.5': 0.8,
    '191.5': 0.8,
    '192.5': 0.7,
    '193.5': 0.7,
    '194.5': 0.7,
    '195.5': 0.6,
    '196.5': 0.6,
    '197.5': 0.6,
    '198.5': 0.5,
    '199.5': 0.5,
    '200.5': 0.4,
    '201.5': 0.4,
    '202.5': 0.4,
    '203.5': 0.3,
    '204.5': 0.3,
    '205.5': 0.3,
    '206.5': 0.2,
    '207.5': 0.2,
    '208.5': 0.2,
    '209.5': 0.1,
    '210.5': 0.1,
    '211.5': 0.1,
    '212.5': 0,
  },
  '6.5': {
    '0': 9.2,
    '1': 9.1,
    '2': 9.1,
    '3': 9,
    '4': 9,
    '5': 8.9,
    '6': 8.9,
    '7': 8.9,
    '8': 8.8,
    '9': 8.8,
    '10': 8.7,
    '11': 8.7,
    '12': 8.6,
    '13': 8.6,
    '14': 8.5,
    '15': 8.5,
    '16': 8.4,
    '17': 8.4,
    '18': 8.4,
    '19': 8.3,
    '20': 8.3,
    '21': 8.2,
    '22': 8.2,
    '23': 8.1,
    '24': 8.1,
    '25': 8,
    '26': 8,
    '27': 7.9,
    '28': 7.9,
    '29': 7.9,
    '30': 7.8,
    '31': 7.8,
    '32': 7.7,
    '33': 7.7,
    '34': 7.6,
    '35': 7.6,
    '36': 7.5,
    '37': 7.5,
    '38': 7.5,
    '39': 7.4,
    '40': 7.4,
    '41': 7.3,
    '42': 7.3,
    '43': 7.2,
    '44': 7.2,
    '45': 7.2,
    '46': 7.1,
    '47': 7.1,
    '48': 7,
    '49': 7,
    '50': 6.9,
    '51': 6.9,
    '52': 6.8,
    '53': 6.8,
    '54': 6.8,
    '55': 6.7,
    '56': 6.7,
    '57': 6.6,
    '58': 6.6,
    '59': 6.5,
    '60': 6.5,
    '61': 6.5,
    '62': 6.4,
    '63': 6.4,
    '64': 6.3,
    '65': 6.3,
    '66': 6.2,
    '67': 6.2,
    '68': 6.2,
    '69': 6.1,
    '70': 6.1,
    '71': 6,
    '72': 6,
    '73': 5.9,
    '74': 5.9,
    '75': 5.9,
    '76': 5.8,
    '77': 5.8,
    '78': 5.7,
    '79': 5.7,
    '80': 5.6,
    '81': 5.6,
    '82': 5.6,
    '83': 5.5,
    '84': 5.5,
    '85': 5.4,
    '86': 5.4,
    '87': 5.4,
    '88': 5.3,
    '89': 5.3,
    '90': 5.2,
    '91': 5.2,
    '92': 5.1,
    '93': 5.1,
    '94': 5.1,
    '95': 5,
    '96': 5,
    '97': 4.9,
    '98': 4.9,
    '99': 4.9,
    '100': 4.8,
    '101': 4.8,
    '102': 4.7,
    '103': 4.7,
    '104': 4.6,
    '105': 4.6,
    '106': 4.6,
    '107': 4.5,
    '108': 4.5,
    '109': 4.4,
    '110': 4.4,
    '111': 4.4,
    '112': 4.3,
    '113': 4.3,
    '114': 4.2,
    '115': 4.2,
    '116': 4.2,
    '117': 4.1,
    '118': 4.1,
    '119': 4,
    '120': 4,
    '121': 4,
    '122': 3.9,
    '123': 3.9,
    '124': 3.8,
    '125': 3.8,
    '126': 3.8,
    '127': 3.7,
    '128': 3.7,
    '129': 3.6,
    '130': 3.6,
    '131': 3.6,
    '132': 3.5,
    '133': 3.5,
    '134': 3.4,
    '135': 3.4,
    '136': 3.4,
    '137': 3.3,
    '138': 3.3,
    '139': 3.2,
    '140': 3.2,
    '141': 3.2,
    '142': 3.1,
    '143': 3.1,
    '144': 3,
    '145': 3,
    '146': 3,
    '147': 2.9,
    '148': 2.9,
    '149': 2.8,
    '150': 2.8,
    '151': 2.8,
    '152': 2.7,
    '153': 2.7,
    '154': 2.7,
    '155': 2.6,
    '156': 2.6,
    '157': 2.5,
    '158': 2.5,
    '159': 2.5,
    '160': 2.4,
    '161': 2.4,
    '162': 2.3,
    '163': 2.3,
    '164': 2.3,
    '165': 2.2,
    '166': 2.2,
    '167': 2.1,
    '168': 2.1,
    '169': 2.1,
    '170': 2,
    '171': 2,
    '172': 2,
    '173': 1.9,
    '174': 1.9,
    '175': 1.8,
    '176': 1.8,
    '177': 1.8,
    '178': 1.7,
    '179': 1.7,
    '180': 1.7,
    '181': 1.6,
    '182': 1.6,
    '183': 1.5,
    '184': 1.5,
    '185': 1.5,
    '186': 1.4,
    '187': 1.4,
    '188': 1.4,
    '189': 1.3,
    '190': 1.3,
    '191': 1.2,
    '192': 1.2,
    '193': 1.2,
    '194': 1.1,
    '195': 1.1,
    '196': 1.1,
    '197': 1,
    '198': 1,
    '199': 0.9,
    '200': 0.9,
    '201': 0.9,
    '202': 0.8,
    '203': 0.8,
    '204': 0.8,
    '205': 0.7,
    '206': 0.7,
    '207': 0.6,
    '208': 0.6,
    '209': 0.6,
    '210': 0.5,
    '211': 0.5,
    '212': 0.5,
    '213': 0.4,
    '214': 0.4,
    '215': 0.4,
    '216': 0.3,
    '217': 0.3,
    '218': 0.2,
    '219': 0.2,
    '220': 0.2,
    '221': 0.1,
    '222': 0.1,
    '223': 0.1,
    '224': 0,
    '0.5': 9.2,
    '1.5': 9.1,
    '2.5': 9.1,
    '3.5': 9,
    '4.5': 9,
    '5.5': 8.9,
    '6.5': 8.9,
    '7.5': 8.8,
    '8.5': 8.8,
    '9.5': 8.7,
    '10.5': 8.7,
    '11.5': 8.6,
    '12.5': 8.6,
    '13.5': 8.6,
    '14.5': 8.5,
    '15.5': 8.5,
    '16.5': 8.4,
    '17.5': 8.4,
    '18.5': 8.3,
    '19.5': 8.3,
    '20.5': 8.2,
    '21.5': 8.2,
    '22.5': 8.2,
    '23.5': 8.1,
    '24.5': 8.1,
    '25.5': 8,
    '26.5': 8,
    '27.5': 7.9,
    '28.5': 7.9,
    '29.5': 7.8,
    '30.5': 7.8,
    '31.5': 7.7,
    '32.5': 7.7,
    '33.5': 7.7,
    '34.5': 7.6,
    '35.5': 7.6,
    '36.5': 7.5,
    '37.5': 7.5,
    '38.5': 7.4,
    '39.5': 7.4,
    '40.5': 7.3,
    '41.5': 7.3,
    '42.5': 7.3,
    '43.5': 7.2,
    '44.5': 7.2,
    '45.5': 7.1,
    '46.5': 7.1,
    '47.5': 7,
    '48.5': 7,
    '49.5': 7,
    '50.5': 6.9,
    '51.5': 6.9,
    '52.5': 6.8,
    '53.5': 6.8,
    '54.5': 6.7,
    '55.5': 6.7,
    '56.5': 6.7,
    '57.5': 6.6,
    '58.5': 6.6,
    '59.5': 6.5,
    '60.5': 6.5,
    '61.5': 6.4,
    '62.5': 6.4,
    '63.5': 6.4,
    '64.5': 6.3,
    '65.5': 6.3,
    '66.5': 6.2,
    '67.5': 6.2,
    '68.5': 6.1,
    '69.5': 6.1,
    '70.5': 6.1,
    '71.5': 6,
    '72.5': 6,
    '73.5': 5.9,
    '74.5': 5.9,
    '75.5': 5.8,
    '76.5': 5.8,
    '77.5': 5.8,
    '78.5': 5.7,
    '79.5': 5.7,
    '80.5': 5.6,
    '81.5': 5.6,
    '82.5': 5.5,
    '83.5': 5.5,
    '84.5': 5.5,
    '85.5': 5.4,
    '86.5': 5.4,
    '87.5': 5.3,
    '88.5': 5.3,
    '89.5': 5.2,
    '90.5': 5.2,
    '91.5': 5.2,
    '92.5': 5.1,
    '93.5': 5.1,
    '94.5': 5,
    '95.5': 5,
    '96.5': 5,
    '97.5': 4.9,
    '98.5': 4.9,
    '99.5': 4.8,
    '100.5': 4.8,
    '101.5': 4.8,
    '102.5': 4.7,
    '103.5': 4.7,
    '104.5': 4.6,
    '105.5': 4.6,
    '106.5': 4.5,
    '107.5': 4.5,
    '108.5': 4.5,
    '109.5': 4.4,
    '110.5': 4.4,
    '111.5': 4.3,
    '112.5': 4.3,
    '113.5': 4.3,
    '114.5': 4.2,
    '115.5': 4.2,
    '116.5': 4.1,
    '117.5': 4.1,
    '118.5': 4.1,
    '119.5': 4,
    '120.5': 4,
    '121.5': 3.9,
    '122.5': 3.9,
    '123.5': 3.9,
    '124.5': 3.8,
    '125.5': 3.8,
    '126.5': 3.7,
    '127.5': 3.7,
    '128.5': 3.7,
    '129.5': 3.6,
    '130.5': 3.6,
    '131.5': 3.5,
    '132.5': 3.5,
    '133.5': 3.5,
    '134.5': 3.4,
    '135.5': 3.4,
    '136.5': 3.3,
    '137.5': 3.3,
    '138.5': 3.3,
    '139.5': 3.2,
    '140.5': 3.2,
    '141.5': 3.1,
    '142.5': 3.1,
    '143.5': 3.1,
    '144.5': 3,
    '145.5': 3,
    '146.5': 2.9,
    '147.5': 2.9,
    '148.5': 2.9,
    '149.5': 2.8,
    '150.5': 2.8,
    '151.5': 2.7,
    '152.5': 2.7,
    '153.5': 2.7,
    '154.5': 2.6,
    '155.5': 2.6,
    '156.5': 2.6,
    '157.5': 2.5,
    '158.5': 2.5,
    '159.5': 2.4,
    '160.5': 2.4,
    '161.5': 2.4,
    '162.5': 2.3,
    '163.5': 2.3,
    '164.5': 2.2,
    '165.5': 2.2,
    '166.5': 2.2,
    '167.5': 2.1,
    '168.5': 2.1,
    '169.5': 2.1,
    '170.5': 2,
    '171.5': 2,
    '172.5': 1.9,
    '173.5': 1.9,
    '174.5': 1.9,
    '175.5': 1.8,
    '176.5': 1.8,
    '177.5': 1.7,
    '178.5': 1.7,
    '179.5': 1.7,
    '180.5': 1.6,
    '181.5': 1.6,
    '182.5': 1.6,
    '183.5': 1.5,
    '184.5': 1.5,
    '185.5': 1.4,
    '186.5': 1.4,
    '187.5': 1.4,
    '188.5': 1.3,
    '189.5': 1.3,
    '190.5': 1.3,
    '191.5': 1.2,
    '192.5': 1.2,
    '193.5': 1.1,
    '194.5': 1.1,
    '195.5': 1.1,
    '196.5': 1,
    '197.5': 1,
    '198.5': 1,
    '199.5': 0.9,
    '200.5': 0.9,
    '201.5': 0.8,
    '202.5': 0.8,
    '203.5': 0.8,
    '204.5': 0.7,
    '205.5': 0.7,
    '206.5': 0.7,
    '207.5': 0.6,
    '208.5': 0.6,
    '209.5': 0.6,
    '210.5': 0.5,
    '211.5': 0.5,
    '212.5': 0.4,
    '213.5': 0.4,
    '214.5': 0.4,
    '215.5': 0.3,
    '216.5': 0.3,
    '217.5': 0.3,
    '218.5': 0.2,
    '219.5': 0.2,
    '220.5': 0.2,
    '221.5': 0.1,
    '222.5': 0.1,
    '223.5': 0,
    '224.5': 0,
  },
  '7': {
    '0': 9.7,
    '1': 9.7,
    '2': 9.6,
    '3': 9.6,
    '4': 9.5,
    '5': 9.5,
    '6': 9.4,
    '7': 9.4,
    '8': 9.3,
    '9': 9.3,
    '10': 9.2,
    '11': 9.2,
    '12': 9.1,
    '13': 9.1,
    '14': 9.1,
    '15': 9,
    '16': 9,
    '17': 8.9,
    '18': 8.9,
    '19': 8.8,
    '20': 8.8,
    '21': 8.7,
    '22': 8.7,
    '23': 8.6,
    '24': 8.6,
    '25': 8.6,
    '26': 8.5,
    '27': 8.5,
    '28': 8.4,
    '29': 8.4,
    '30': 8.3,
    '31': 8.3,
    '32': 8.2,
    '33': 8.2,
    '34': 8.2,
    '35': 8.1,
    '36': 8.1,
    '37': 8,
    '38': 8,
    '39': 7.9,
    '40': 7.9,
    '41': 7.8,
    '42': 7.8,
    '43': 7.7,
    '44': 7.7,
    '45': 7.7,
    '46': 7.6,
    '47': 7.6,
    '48': 7.5,
    '49': 7.5,
    '50': 7.4,
    '51': 7.4,
    '52': 7.4,
    '53': 7.3,
    '54': 7.3,
    '55': 7.2,
    '56': 7.2,
    '57': 7.1,
    '58': 7.1,
    '59': 7,
    '60': 7,
    '61': 7,
    '62': 6.9,
    '63': 6.9,
    '64': 6.8,
    '65': 6.8,
    '66': 6.7,
    '67': 6.7,
    '68': 6.7,
    '69': 6.6,
    '70': 6.6,
    '71': 6.5,
    '72': 6.5,
    '73': 6.4,
    '74': 6.4,
    '75': 6.4,
    '76': 6.3,
    '77': 6.3,
    '78': 6.2,
    '79': 6.2,
    '80': 6.1,
    '81': 6.1,
    '82': 6.1,
    '83': 6,
    '84': 6,
    '85': 5.9,
    '86': 5.9,
    '87': 5.8,
    '88': 5.8,
    '89': 5.8,
    '90': 5.7,
    '91': 5.7,
    '92': 5.6,
    '93': 5.6,
    '94': 5.5,
    '95': 5.5,
    '96': 5.5,
    '97': 5.4,
    '98': 5.4,
    '99': 5.3,
    '100': 5.3,
    '101': 5.3,
    '102': 5.2,
    '103': 5.2,
    '104': 5.1,
    '105': 5.1,
    '106': 5,
    '107': 5,
    '108': 5,
    '109': 4.9,
    '110': 4.9,
    '111': 4.8,
    '112': 4.8,
    '113': 4.8,
    '114': 4.7,
    '115': 4.7,
    '116': 4.6,
    '117': 4.6,
    '118': 4.6,
    '119': 4.5,
    '120': 4.5,
    '121': 4.4,
    '122': 4.4,
    '123': 4.3,
    '124': 4.3,
    '125': 4.3,
    '126': 4.2,
    '127': 4.2,
    '128': 4.1,
    '129': 4.1,
    '130': 4.1,
    '131': 4,
    '132': 4,
    '133': 3.9,
    '134': 3.9,
    '135': 3.9,
    '136': 3.8,
    '137': 3.8,
    '138': 3.7,
    '139': 3.7,
    '140': 3.7,
    '141': 3.6,
    '142': 3.6,
    '143': 3.5,
    '144': 3.5,
    '145': 3.5,
    '146': 3.4,
    '147': 3.4,
    '148': 3.3,
    '149': 3.3,
    '150': 3.3,
    '151': 3.2,
    '152': 3.2,
    '153': 3.1,
    '154': 3.1,
    '155': 3.1,
    '156': 3,
    '157': 3,
    '158': 3,
    '159': 2.9,
    '160': 2.9,
    '161': 2.8,
    '162': 2.8,
    '163': 2.8,
    '164': 2.7,
    '165': 2.7,
    '166': 2.6,
    '167': 2.6,
    '168': 2.6,
    '169': 2.5,
    '170': 2.5,
    '171': 2.4,
    '172': 2.4,
    '173': 2.4,
    '174': 2.3,
    '175': 2.3,
    '176': 2.3,
    '177': 2.2,
    '178': 2.2,
    '179': 2.1,
    '180': 2.1,
    '181': 2.1,
    '182': 2,
    '183': 2,
    '184': 1.9,
    '185': 1.9,
    '186': 1.9,
    '187': 1.8,
    '188': 1.8,
    '189': 1.8,
    '190': 1.7,
    '191': 1.7,
    '192': 1.6,
    '193': 1.6,
    '194': 1.6,
    '195': 1.5,
    '196': 1.5,
    '197': 1.5,
    '198': 1.4,
    '199': 1.4,
    '200': 1.3,
    '201': 1.3,
    '202': 1.3,
    '203': 1.2,
    '204': 1.2,
    '205': 1.2,
    '206': 1.1,
    '207': 1.1,
    '208': 1,
    '209': 1,
    '210': 1,
    '211': 0.9,
    '212': 0.9,
    '213': 0.9,
    '214': 0.8,
    '215': 0.8,
    '216': 0.8,
    '217': 0.7,
    '218': 0.7,
    '219': 0.6,
    '220': 0.6,
    '221': 0.6,
    '222': 0.5,
    '223': 0.5,
    '224': 0.5,
    '225': 0.4,
    '0.5': 9.7,
    '1.5': 9.6,
    '2.5': 9.6,
    '3.5': 9.5,
    '4.5': 9.5,
    '5.5': 9.5,
    '6.5': 9.4,
    '7.5': 9.4,
    '8.5': 9.3,
    '9.5': 9.3,
    '10.5': 9.2,
    '11.5': 9.2,
    '12.5': 9.1,
    '13.5': 9.1,
    '14.5': 9,
    '15.5': 9,
    '16.5': 8.9,
    '17.5': 8.9,
    '18.5': 8.9,
    '19.5': 8.8,
    '20.5': 8.8,
    '21.5': 8.7,
    '22.5': 8.7,
    '23.5': 8.6,
    '24.5': 8.6,
    '25.5': 8.5,
    '26.5': 8.5,
    '27.5': 8.4,
    '28.5': 8.4,
    '29.5': 8.4,
    '30.5': 8.3,
    '31.5': 8.3,
    '32.5': 8.2,
    '33.5': 8.2,
    '34.5': 8.1,
    '35.5': 8.1,
    '36.5': 8,
    '37.5': 8,
    '38.5': 7.9,
    '39.5': 7.9,
    '40.5': 7.9,
    '41.5': 7.8,
    '42.5': 7.8,
    '43.5': 7.7,
    '44.5': 7.7,
    '45.5': 7.6,
    '46.5': 7.6,
    '47.5': 7.5,
    '48.5': 7.5,
    '49.5': 7.5,
    '50.5': 7.4,
    '51.5': 7.4,
    '52.5': 7.3,
    '53.5': 7.3,
    '54.5': 7.2,
    '55.5': 7.2,
    '56.5': 7.2,
    '57.5': 7.1,
    '58.5': 7.1,
    '59.5': 7,
    '60.5': 7,
    '61.5': 6.9,
    '62.5': 6.9,
    '63.5': 6.8,
    '64.5': 6.8,
    '65.5': 6.8,
    '66.5': 6.7,
    '67.5': 6.7,
    '68.5': 6.6,
    '69.5': 6.6,
    '70.5': 6.5,
    '71.5': 6.5,
    '72.5': 6.5,
    '73.5': 6.4,
    '74.5': 6.4,
    '75.5': 6.3,
    '76.5': 6.3,
    '77.5': 6.2,
    '78.5': 6.2,
    '79.5': 6.2,
    '80.5': 6.1,
    '81.5': 6.1,
    '82.5': 6,
    '83.5': 6,
    '84.5': 5.9,
    '85.5': 5.9,
    '86.5': 5.9,
    '87.5': 5.8,
    '88.5': 5.8,
    '89.5': 5.7,
    '90.5': 5.7,
    '91.5': 5.7,
    '92.5': 5.6,
    '93.5': 5.6,
    '94.5': 5.5,
    '95.5': 5.5,
    '96.5': 5.4,
    '97.5': 5.4,
    '98.5': 5.4,
    '99.5': 5.3,
    '100.5': 5.3,
    '101.5': 5.2,
    '102.5': 5.2,
    '103.5': 5.1,
    '104.5': 5.1,
    '105.5': 5.1,
    '106.5': 5,
    '107.5': 5,
    '108.5': 4.9,
    '109.5': 4.9,
    '110.5': 4.9,
    '111.5': 4.8,
    '112.5': 4.8,
    '113.5': 4.7,
    '114.5': 4.7,
    '115.5': 4.7,
    '116.5': 4.6,
    '117.5': 4.6,
    '118.5': 4.5,
    '119.5': 4.5,
    '120.5': 4.4,
    '121.5': 4.4,
    '122.5': 4.4,
    '123.5': 4.3,
    '124.5': 4.3,
    '125.5': 4.2,
    '126.5': 4.2,
    '127.5': 4.2,
    '128.5': 4.1,
    '129.5': 4.1,
    '130.5': 4,
    '131.5': 4,
    '132.5': 4,
    '133.5': 3.9,
    '134.5': 3.9,
    '135.5': 3.8,
    '136.5': 3.8,
    '137.5': 3.8,
    '138.5': 3.7,
    '139.5': 3.7,
    '140.5': 3.6,
    '141.5': 3.6,
    '142.5': 3.6,
    '143.5': 3.5,
    '144.5': 3.5,
    '145.5': 3.4,
    '146.5': 3.4,
    '147.5': 3.4,
    '148.5': 3.3,
    '149.5': 3.3,
    '150.5': 3.2,
    '151.5': 3.2,
    '152.5': 3.2,
    '153.5': 3.1,
    '154.5': 3.1,
    '155.5': 3.1,
    '156.5': 3,
    '157.5': 3,
    '158.5': 2.9,
    '159.5': 2.9,
    '160.5': 2.9,
    '161.5': 2.8,
    '162.5': 2.8,
    '163.5': 2.7,
    '164.5': 2.7,
    '165.5': 2.7,
    '166.5': 2.6,
    '167.5': 2.6,
    '168.5': 2.5,
    '169.5': 2.5,
    '170.5': 2.5,
    '171.5': 2.4,
    '172.5': 2.4,
    '173.5': 2.4,
    '174.5': 2.3,
    '175.5': 2.3,
    '176.5': 2.2,
    '177.5': 2.2,
    '178.5': 2.2,
    '179.5': 2.1,
    '180.5': 2.1,
    '181.5': 2,
    '182.5': 2,
    '183.5': 2,
    '184.5': 1.9,
    '185.5': 1.9,
    '186.5': 1.9,
    '187.5': 1.8,
    '188.5': 1.8,
    '189.5': 1.7,
    '190.5': 1.7,
    '191.5': 1.7,
    '192.5': 1.6,
    '193.5': 1.6,
    '194.5': 1.6,
    '195.5': 1.5,
    '196.5': 1.5,
    '197.5': 1.4,
    '198.5': 1.4,
    '199.5': 1.4,
    '200.5': 1.3,
    '201.5': 1.3,
    '202.5': 1.3,
    '203.5': 1.2,
    '204.5': 1.2,
    '205.5': 1.1,
    '206.5': 1.1,
    '207.5': 1.1,
    '208.5': 1,
    '209.5': 1,
    '210.5': 1,
    '211.5': 0.9,
    '212.5': 0.9,
    '213.5': 0.8,
    '214.5': 0.8,
    '215.5': 0.8,
    '216.5': 0.7,
    '217.5': 0.7,
    '218.5': 0.7,
    '219.5': 0.6,
    '220.5': 0.6,
    '221.5': 0.6,
    '222.5': 0.5,
    '223.5': 0.5,
    '224.5': 0.4,
  },
  '7.5': {
    '0': 10.2,
    '1': 10.2,
    '2': 10.1,
    '3': 10.1,
    '4': 10,
    '5': 10,
    '6': 10,
    '7': 9.9,
    '8': 9.9,
    '9': 9.8,
    '10': 9.8,
    '11': 9.7,
    '12': 9.7,
    '13': 9.6,
    '14': 9.6,
    '15': 9.5,
    '16': 9.5,
    '17': 9.4,
    '18': 9.4,
    '19': 9.3,
    '20': 9.3,
    '21': 9.3,
    '22': 9.2,
    '23': 9.2,
    '24': 9.1,
    '25': 9.1,
    '26': 9,
    '27': 9,
    '28': 8.9,
    '29': 8.9,
    '30': 8.8,
    '31': 8.8,
    '32': 8.8,
    '33': 8.7,
    '34': 8.7,
    '35': 8.6,
    '36': 8.6,
    '37': 8.5,
    '38': 8.5,
    '39': 8.4,
    '40': 8.4,
    '41': 8.3,
    '42': 8.3,
    '43': 8.3,
    '44': 8.2,
    '45': 8.2,
    '46': 8.1,
    '47': 8.1,
    '48': 8,
    '49': 8,
    '50': 7.9,
    '51': 7.9,
    '52': 7.9,
    '53': 7.8,
    '54': 7.8,
    '55': 7.7,
    '56': 7.7,
    '57': 7.6,
    '58': 7.6,
    '59': 7.5,
    '60': 7.5,
    '61': 7.5,
    '62': 7.4,
    '63': 7.4,
    '64': 7.3,
    '65': 7.3,
    '66': 7.2,
    '67': 7.2,
    '68': 7.1,
    '69': 7.1,
    '70': 7.1,
    '71': 7,
    '72': 7,
    '73': 6.9,
    '74': 6.9,
    '75': 6.8,
    '76': 6.8,
    '77': 6.8,
    '78': 6.7,
    '79': 6.7,
    '80': 6.6,
    '81': 6.6,
    '82': 6.5,
    '83': 6.5,
    '84': 6.5,
    '85': 6.4,
    '86': 6.4,
    '87': 6.3,
    '88': 6.3,
    '89': 6.2,
    '90': 6.2,
    '91': 6.2,
    '92': 6.1,
    '93': 6.1,
    '94': 6,
    '95': 6,
    '96': 5.9,
    '97': 5.9,
    '98': 5.9,
    '99': 5.8,
    '100': 5.8,
    '101': 5.7,
    '102': 5.7,
    '103': 5.6,
    '104': 5.6,
    '105': 5.6,
    '106': 5.5,
    '107': 5.5,
    '108': 5.4,
    '109': 5.4,
    '110': 5.4,
    '111': 5.3,
    '112': 5.3,
    '113': 5.2,
    '114': 5.2,
    '115': 5.1,
    '116': 5.1,
    '117': 5.1,
    '118': 5,
    '119': 5,
    '120': 4.9,
    '121': 4.9,
    '122': 4.9,
    '123': 4.8,
    '124': 4.8,
    '125': 4.7,
    '126': 4.7,
    '127': 4.7,
    '128': 4.6,
    '129': 4.6,
    '130': 4.5,
    '131': 4.5,
    '132': 4.5,
    '133': 4.4,
    '134': 4.4,
    '135': 4.3,
    '136': 4.3,
    '137': 4.2,
    '138': 4.2,
    '139': 4.2,
    '140': 4.1,
    '141': 4.1,
    '142': 4,
    '143': 4,
    '144': 4,
    '145': 3.9,
    '146': 3.9,
    '147': 3.8,
    '148': 3.8,
    '149': 3.8,
    '150': 3.7,
    '151': 3.7,
    '152': 3.6,
    '153': 3.6,
    '154': 3.6,
    '155': 3.5,
    '156': 3.5,
    '157': 3.4,
    '158': 3.4,
    '159': 3.4,
    '160': 3.3,
    '161': 3.3,
    '162': 3.3,
    '163': 3.2,
    '164': 3.2,
    '165': 3.1,
    '166': 3.1,
    '167': 3.1,
    '168': 3,
    '169': 3,
    '170': 2.9,
    '171': 2.9,
    '172': 2.9,
    '173': 2.8,
    '174': 2.8,
    '175': 2.7,
    '176': 2.7,
    '177': 2.7,
    '178': 2.6,
    '179': 2.6,
    '180': 2.5,
    '181': 2.5,
    '182': 2.5,
    '183': 2.4,
    '184': 2.4,
    '185': 2.4,
    '186': 2.3,
    '187': 2.3,
    '188': 2.2,
    '189': 2.2,
    '190': 2.2,
    '191': 2.1,
    '192': 2.1,
    '193': 2.1,
    '194': 2,
    '195': 2,
    '196': 1.9,
    '197': 1.9,
    '198': 1.9,
    '199': 1.8,
    '200': 1.8,
    '201': 1.7,
    '202': 1.7,
    '203': 1.7,
    '204': 1.6,
    '205': 1.6,
    '206': 1.6,
    '207': 1.5,
    '208': 1.5,
    '209': 1.4,
    '210': 1.4,
    '211': 1.4,
    '212': 1.3,
    '213': 1.3,
    '214': 1.3,
    '215': 1.2,
    '216': 1.2,
    '217': 1.1,
    '218': 1.1,
    '219': 1.1,
    '220': 1,
    '221': 1,
    '222': 1,
    '223': 0.9,
    '224': 0.9,
    '225': 0.9,
    '0.5': 10.2,
    '1.5': 10.2,
    '2.5': 10.1,
    '3.5': 10.1,
    '4.5': 10,
    '5.5': 10,
    '6.5': 9.9,
    '7.5': 9.9,
    '8.5': 9.8,
    '9.5': 9.8,
    '10.5': 9.7,
    '11.5': 9.7,
    '12.5': 9.7,
    '13.5': 9.6,
    '14.5': 9.6,
    '15.5': 9.5,
    '16.5': 9.5,
    '17.5': 9.4,
    '18.5': 9.4,
    '19.5': 9.3,
    '20.5': 9.3,
    '21.5': 9.2,
    '22.5': 9.2,
    '23.5': 9.1,
    '24.5': 9.1,
    '25.5': 9.1,
    '26.5': 9,
    '27.5': 9,
    '28.5': 8.9,
    '29.5': 8.9,
    '30.5': 8.8,
    '31.5': 8.8,
    '32.5': 8.7,
    '33.5': 8.7,
    '34.5': 8.6,
    '35.5': 8.6,
    '36.5': 8.5,
    '37.5': 8.5,
    '38.5': 8.5,
    '39.5': 8.4,
    '40.5': 8.4,
    '41.5': 8.3,
    '42.5': 8.3,
    '43.5': 8.2,
    '44.5': 8.2,
    '45.5': 8.1,
    '46.5': 8.1,
    '47.5': 8.1,
    '48.5': 8,
    '49.5': 8,
    '50.5': 7.9,
    '51.5': 7.9,
    '52.5': 7.8,
    '53.5': 7.8,
    '54.5': 7.7,
    '55.5': 7.7,
    '56.5': 7.7,
    '57.5': 7.6,
    '58.5': 7.6,
    '59.5': 7.5,
    '60.5': 7.5,
    '61.5': 7.4,
    '62.5': 7.4,
    '63.5': 7.3,
    '64.5': 7.3,
    '65.5': 7.3,
    '66.5': 7.2,
    '67.5': 7.2,
    '68.5': 7.1,
    '69.5': 7.1,
    '70.5': 7,
    '71.5': 7,
    '72.5': 7,
    '73.5': 6.9,
    '74.5': 6.9,
    '75.5': 6.8,
    '76.5': 6.8,
    '77.5': 6.7,
    '78.5': 6.7,
    '79.5': 6.7,
    '80.5': 6.6,
    '81.5': 6.6,
    '82.5': 6.5,
    '83.5': 6.5,
    '84.5': 6.4,
    '85.5': 6.4,
    '86.5': 6.3,
    '87.5': 6.3,
    '88.5': 6.3,
    '89.5': 6.2,
    '90.5': 6.2,
    '91.5': 6.1,
    '92.5': 6.1,
    '93.5': 6.1,
    '94.5': 6,
    '95.5': 6,
    '96.5': 5.9,
    '97.5': 5.9,
    '98.5': 5.8,
    '99.5': 5.8,
    '100.5': 5.8,
    '101.5': 5.7,
    '102.5': 5.7,
    '103.5': 5.6,
    '104.5': 5.6,
    '105.5': 5.5,
    '106.5': 5.5,
    '107.5': 5.5,
    '108.5': 5.4,
    '109.5': 5.4,
    '110.5': 5.3,
    '111.5': 5.3,
    '112.5': 5.3,
    '113.5': 5.2,
    '114.5': 5.2,
    '115.5': 5.1,
    '116.5': 5.1,
    '117.5': 5,
    '118.5': 5,
    '119.5': 5,
    '120.5': 4.9,
    '121.5': 4.9,
    '122.5': 4.8,
    '123.5': 4.8,
    '124.5': 4.8,
    '125.5': 4.7,
    '126.5': 4.7,
    '127.5': 4.6,
    '128.5': 4.6,
    '129.5': 4.6,
    '130.5': 4.5,
    '131.5': 4.5,
    '132.5': 4.4,
    '133.5': 4.4,
    '134.5': 4.4,
    '135.5': 4.3,
    '136.5': 4.3,
    '137.5': 4.2,
    '138.5': 4.2,
    '139.5': 4.1,
    '140.5': 4.1,
    '141.5': 4.1,
    '142.5': 4,
    '143.5': 4,
    '144.5': 3.9,
    '145.5': 3.9,
    '146.5': 3.9,
    '147.5': 3.8,
    '148.5': 3.8,
    '149.5': 3.7,
    '150.5': 3.7,
    '151.5': 3.7,
    '152.5': 3.6,
    '153.5': 3.6,
    '154.5': 3.5,
    '155.5': 3.5,
    '156.5': 3.5,
    '157.5': 3.4,
    '158.5': 3.4,
    '159.5': 3.3,
    '160.5': 3.3,
    '161.5': 3.3,
    '162.5': 3.2,
    '163.5': 3.2,
    '164.5': 3.2,
    '165.5': 3.1,
    '166.5': 3.1,
    '167.5': 3,
    '168.5': 3,
    '169.5': 3,
    '170.5': 2.9,
    '171.5': 2.9,
    '172.5': 2.8,
    '173.5': 2.8,
    '174.5': 2.8,
    '175.5': 2.7,
    '176.5': 2.7,
    '177.5': 2.6,
    '178.5': 2.6,
    '179.5': 2.6,
    '180.5': 2.5,
    '181.5': 2.5,
    '182.5': 2.5,
    '183.5': 2.4,
    '184.5': 2.4,
    '185.5': 2.3,
    '186.5': 2.3,
    '187.5': 2.3,
    '188.5': 2.2,
    '189.5': 2.2,
    '190.5': 2.1,
    '191.5': 2.1,
    '192.5': 2.1,
    '193.5': 2,
    '194.5': 2,
    '195.5': 2,
    '196.5': 1.9,
    '197.5': 1.9,
    '198.5': 1.8,
    '199.5': 1.8,
    '200.5': 1.8,
    '201.5': 1.7,
    '202.5': 1.7,
    '203.5': 1.7,
    '204.5': 1.6,
    '205.5': 1.6,
    '206.5': 1.5,
    '207.5': 1.5,
    '208.5': 1.5,
    '209.5': 1.4,
    '210.5': 1.4,
    '211.5': 1.4,
    '212.5': 1.3,
    '213.5': 1.3,
    '214.5': 1.2,
    '215.5': 1.2,
    '216.5': 1.2,
    '217.5': 1.1,
    '218.5': 1.1,
    '219.5': 1.1,
    '220.5': 1,
    '221.5': 1,
    '222.5': 0.9,
    '223.5': 0.9,
    '224.5': 0.9,
  },
  '8': {
    '0': 10.8,
    '1': 10.7,
    '2': 10.7,
    '3': 10.6,
    '4': 10.6,
    '5': 10.5,
    '6': 10.5,
    '7': 10.4,
    '8': 10.4,
    '9': 10.3,
    '10': 10.3,
    '11': 10.2,
    '12': 10.2,
    '13': 10.2,
    '14': 10.1,
    '15': 10.1,
    '16': 10,
    '17': 10,
    '18': 9.9,
    '19': 9.9,
    '20': 9.8,
    '21': 9.8,
    '22': 9.7,
    '23': 9.7,
    '24': 9.6,
    '25': 9.6,
    '26': 9.5,
    '27': 9.5,
    '28': 9.5,
    '29': 9.4,
    '30': 9.4,
    '31': 9.3,
    '32': 9.3,
    '33': 9.2,
    '34': 9.2,
    '35': 9.1,
    '36': 9.1,
    '37': 9,
    '38': 9,
    '39': 8.9,
    '40': 8.9,
    '41': 8.9,
    '42': 8.8,
    '43': 8.8,
    '44': 8.7,
    '45': 8.7,
    '46': 8.6,
    '47': 8.6,
    '48': 8.5,
    '49': 8.5,
    '50': 8.4,
    '51': 8.4,
    '52': 8.4,
    '53': 8.3,
    '54': 8.3,
    '55': 8.2,
    '56': 8.2,
    '57': 8.1,
    '58': 8.1,
    '59': 8,
    '60': 8,
    '61': 8,
    '62': 7.9,
    '63': 7.9,
    '64': 7.8,
    '65': 7.8,
    '66': 7.7,
    '67': 7.7,
    '68': 7.6,
    '69': 7.6,
    '70': 7.6,
    '71': 7.5,
    '72': 7.5,
    '73': 7.4,
    '74': 7.4,
    '75': 7.3,
    '76': 7.3,
    '77': 7.3,
    '78': 7.2,
    '79': 7.2,
    '80': 7.1,
    '81': 7.1,
    '82': 7,
    '83': 7,
    '84': 6.9,
    '85': 6.9,
    '86': 6.9,
    '87': 6.8,
    '88': 6.8,
    '89': 6.7,
    '90': 6.7,
    '91': 6.6,
    '92': 6.6,
    '93': 6.6,
    '94': 6.5,
    '95': 6.5,
    '96': 6.4,
    '97': 6.4,
    '98': 6.3,
    '99': 6.3,
    '100': 6.3,
    '101': 6.2,
    '102': 6.2,
    '103': 6.1,
    '104': 6.1,
    '105': 6,
    '106': 6,
    '107': 5.9,
    '108': 5.9,
    '109': 5.9,
    '110': 5.8,
    '111': 5.8,
    '112': 5.7,
    '113': 5.7,
    '114': 5.6,
    '115': 5.6,
    '116': 5.6,
    '117': 5.5,
    '118': 5.5,
    '119': 5.5,
    '120': 5.4,
    '121': 5.4,
    '122': 5.3,
    '123': 5.3,
    '124': 5.2,
    '125': 5.2,
    '126': 5.2,
    '127': 5.1,
    '128': 5.1,
    '129': 5,
    '130': 5,
    '131': 5,
    '132': 4.9,
    '133': 4.9,
    '134': 4.8,
    '135': 4.8,
    '136': 4.8,
    '137': 4.7,
    '138': 4.7,
    '139': 4.6,
    '140': 4.6,
    '141': 4.6,
    '142': 4.5,
    '143': 4.5,
    '144': 4.4,
    '145': 4.4,
    '146': 4.3,
    '147': 4.3,
    '148': 4.3,
    '149': 4.2,
    '150': 4.2,
    '151': 4.1,
    '152': 4.1,
    '153': 4.1,
    '154': 4,
    '155': 4,
    '156': 3.9,
    '157': 3.9,
    '158': 3.9,
    '159': 3.8,
    '160': 3.8,
    '161': 3.7,
    '162': 3.7,
    '163': 3.7,
    '164': 3.6,
    '165': 3.6,
    '166': 3.5,
    '167': 3.5,
    '168': 3.5,
    '169': 3.4,
    '170': 3.4,
    '171': 3.3,
    '172': 3.3,
    '173': 3.3,
    '174': 3.2,
    '175': 3.2,
    '176': 3.2,
    '177': 3.1,
    '178': 3.1,
    '179': 3,
    '180': 3,
    '181': 3,
    '182': 2.9,
    '183': 2.9,
    '184': 2.8,
    '185': 2.8,
    '186': 2.8,
    '187': 2.7,
    '188': 2.7,
    '189': 2.6,
    '190': 2.6,
    '191': 2.6,
    '192': 2.5,
    '193': 2.5,
    '194': 2.5,
    '195': 2.4,
    '196': 2.4,
    '197': 2.3,
    '198': 2.3,
    '199': 2.3,
    '200': 2.2,
    '201': 2.2,
    '202': 2.1,
    '203': 2.1,
    '204': 2.1,
    '205': 2,
    '206': 2,
    '207': 2,
    '208': 1.9,
    '209': 1.9,
    '210': 1.8,
    '211': 1.8,
    '212': 1.8,
    '213': 1.7,
    '214': 1.7,
    '215': 1.7,
    '216': 1.6,
    '217': 1.6,
    '218': 1.5,
    '219': 1.5,
    '220': 1.5,
    '221': 1.4,
    '222': 1.4,
    '223': 1.4,
    '224': 1.3,
    '225': 1.3,
    '0.5': 10.7,
    '1.5': 10.7,
    '2.5': 10.6,
    '3.5': 10.6,
    '4.5': 10.6,
    '5.5': 10.5,
    '6.5': 10.5,
    '7.5': 10.4,
    '8.5': 10.4,
    '9.5': 10.3,
    '10.5': 10.3,
    '11.5': 10.2,
    '12.5': 10.2,
    '13.5': 10.1,
    '14.5': 10.1,
    '15.5': 10,
    '16.5': 10,
    '17.5': 9.9,
    '18.5': 9.9,
    '19.5': 9.8,
    '20.5': 9.8,
    '21.5': 9.8,
    '22.5': 9.7,
    '23.5': 9.7,
    '24.5': 9.6,
    '25.5': 9.6,
    '26.5': 9.5,
    '27.5': 9.5,
    '28.5': 9.4,
    '29.5': 9.4,
    '30.5': 9.3,
    '31.5': 9.3,
    '32.5': 9.2,
    '33.5': 9.2,
    '34.5': 9.2,
    '35.5': 9.1,
    '36.5': 9.1,
    '37.5': 9,
    '38.5': 9,
    '39.5': 8.9,
    '40.5': 8.9,
    '41.5': 8.8,
    '42.5': 8.8,
    '43.5': 8.7,
    '44.5': 8.7,
    '45.5': 8.7,
    '46.5': 8.6,
    '47.5': 8.6,
    '48.5': 8.5,
    '49.5': 8.5,
    '50.5': 8.4,
    '51.5': 8.4,
    '52.5': 8.3,
    '53.5': 8.3,
    '54.5': 8.2,
    '55.5': 8.2,
    '56.5': 8.2,
    '57.5': 8.1,
    '58.5': 8.1,
    '59.5': 8,
    '60.5': 8,
    '61.5': 7.9,
    '62.5': 7.9,
    '63.5': 7.8,
    '64.5': 7.8,
    '65.5': 7.8,
    '66.5': 7.7,
    '67.5': 7.7,
    '68.5': 7.6,
    '69.5': 7.6,
    '70.5': 7.5,
    '71.5': 7.5,
    '72.5': 7.4,
    '73.5': 7.4,
    '74.5': 7.4,
    '75.5': 7.3,
    '76.5': 7.3,
    '77.5': 7.2,
    '78.5': 7.2,
    '79.5': 7.1,
    '80.5': 7.1,
    '81.5': 7.1,
    '82.5': 7,
    '83.5': 7,
    '84.5': 6.9,
    '85.5': 6.9,
    '86.5': 6.8,
    '87.5': 6.8,
    '88.5': 6.8,
    '89.5': 6.7,
    '90.5': 6.7,
    '91.5': 6.6,
    '92.5': 6.6,
    '93.5': 6.5,
    '94.5': 6.5,
    '95.5': 6.5,
    '96.5': 6.4,
    '97.5': 6.4,
    '98.5': 6.3,
    '99.5': 6.3,
    '100.5': 6.2,
    '101.5': 6.2,
    '102.5': 6.2,
    '103.5': 6.1,
    '104.5': 6.1,
    '105.5': 6,
    '106.5': 6,
    '107.5': 5.9,
    '108.5': 5.9,
    '109.5': 5.8,
    '110.5': 5.8,
    '111.5': 5.7,
    '112.5': 5.7,
    '113.5': 5.7,
    '114.5': 5.6,
    '115.5': 5.6,
    '116.5': 5.5,
    '117.5': 5.5,
    '118.5': 5.5,
    '119.5': 5.4,
    '120.5': 5.4,
    '121.5': 5.4,
    '122.5': 5.3,
    '123.5': 5.3,
    '124.5': 5.2,
    '125.5': 5.2,
    '126.5': 5.1,
    '127.5': 5.1,
    '128.5': 5.1,
    '129.5': 5,
    '130.5': 5,
    '131.5': 4.9,
    '132.5': 4.9,
    '133.5': 4.9,
    '134.5': 4.8,
    '135.5': 4.8,
    '136.5': 4.7,
    '137.5': 4.7,
    '138.5': 4.7,
    '139.5': 4.6,
    '140.5': 4.6,
    '141.5': 4.5,
    '142.5': 4.5,
    '143.5': 4.4,
    '144.5': 4.4,
    '145.5': 4.4,
    '146.5': 4.3,
    '147.5': 4.3,
    '148.5': 4.2,
    '149.5': 4.2,
    '150.5': 4.2,
    '151.5': 4.1,
    '152.5': 4.1,
    '153.5': 4,
    '154.5': 4,
    '155.5': 4,
    '156.5': 3.9,
    '157.5': 3.9,
    '158.5': 3.8,
    '159.5': 3.8,
    '160.5': 3.8,
    '161.5': 3.7,
    '162.5': 3.7,
    '163.5': 3.6,
    '164.5': 3.6,
    '165.5': 3.6,
    '166.5': 3.5,
    '167.5': 3.5,
    '168.5': 3.4,
    '169.5': 3.4,
    '170.5': 3.4,
    '171.5': 3.3,
    '172.5': 3.3,
    '173.5': 3.3,
    '174.5': 3.2,
    '175.5': 3.2,
    '176.5': 3.1,
    '177.5': 3.1,
    '178.5': 3.1,
    '179.5': 3,
    '180.5': 3,
    '181.5': 2.9,
    '182.5': 2.9,
    '183.5': 2.9,
    '184.5': 2.8,
    '185.5': 2.8,
    '186.5': 2.7,
    '187.5': 2.7,
    '188.5': 2.7,
    '189.5': 2.6,
    '190.5': 2.6,
    '191.5': 2.6,
    '192.5': 2.5,
    '193.5': 2.5,
    '194.5': 2.4,
    '195.5': 2.4,
    '196.5': 2.4,
    '197.5': 2.3,
    '198.5': 2.3,
    '199.5': 2.2,
    '200.5': 2.2,
    '201.5': 2.2,
    '202.5': 2.1,
    '203.5': 2.1,
    '204.5': 2.1,
    '205.5': 2,
    '206.5': 2,
    '207.5': 1.9,
    '208.5': 1.9,
    '209.5': 1.9,
    '210.5': 1.8,
    '211.5': 1.8,
    '212.5': 1.8,
    '213.5': 1.7,
    '214.5': 1.7,
    '215.5': 1.6,
    '216.5': 1.6,
    '217.5': 1.6,
    '218.5': 1.5,
    '219.5': 1.5,
    '220.5': 1.5,
    '221.5': 1.4,
    '222.5': 1.4,
    '223.5': 1.3,
    '224.5': 1.3,
  },
  '8.5': {
    '0': 11.3,
    '1': 11.2,
    '2': 11.2,
    '3': 11.2,
    '4': 11.1,
    '5': 11.1,
    '6': 11,
    '7': 11,
    '8': 10.9,
    '9': 10.9,
    '10': 10.8,
    '11': 10.8,
    '12': 10.7,
    '13': 10.7,
    '14': 10.6,
    '15': 10.6,
    '16': 10.5,
    '17': 10.5,
    '18': 10.4,
    '19': 10.4,
    '20': 10.3,
    '21': 10.3,
    '22': 10.3,
    '23': 10.2,
    '24': 10.2,
    '25': 10.1,
    '26': 10.1,
    '27': 10,
    '28': 10,
    '29': 9.9,
    '30': 9.9,
    '31': 9.8,
    '32': 9.8,
    '33': 9.7,
    '34': 9.7,
    '35': 9.6,
    '36': 9.6,
    '37': 9.5,
    '38': 9.5,
    '39': 9.5,
    '40': 9.4,
    '41': 9.4,
    '42': 9.3,
    '43': 9.3,
    '44': 9.2,
    '45': 9.2,
    '46': 9.1,
    '47': 9.1,
    '48': 9,
    '49': 9,
    '50': 9,
    '51': 8.9,
    '52': 8.9,
    '53': 8.8,
    '54': 8.8,
    '55': 8.7,
    '56': 8.7,
    '57': 8.6,
    '58': 8.6,
    '59': 8.5,
    '60': 8.5,
    '61': 8.5,
    '62': 8.4,
    '63': 8.4,
    '64': 8.3,
    '65': 8.3,
    '66': 8.2,
    '67': 8.2,
    '68': 8.1,
    '69': 8.1,
    '70': 8.1,
    '71': 8,
    '72': 8,
    '73': 7.9,
    '74': 7.9,
    '75': 7.8,
    '76': 7.8,
    '77': 7.7,
    '78': 7.7,
    '79': 7.7,
    '80': 7.6,
    '81': 7.6,
    '82': 7.5,
    '83': 7.5,
    '84': 7.4,
    '85': 7.4,
    '86': 7.3,
    '87': 7.3,
    '88': 7.3,
    '89': 7.2,
    '90': 7.2,
    '91': 7.1,
    '92': 7.1,
    '93': 7,
    '94': 7,
    '95': 7,
    '96': 6.9,
    '97': 6.9,
    '98': 6.8,
    '99': 6.8,
    '100': 6.7,
    '101': 6.7,
    '102': 6.7,
    '103': 6.6,
    '104': 6.6,
    '105': 6.5,
    '106': 6.5,
    '107': 6.4,
    '108': 6.4,
    '109': 6.4,
    '110': 6.3,
    '111': 6.3,
    '112': 6.2,
    '113': 6.2,
    '114': 6.1,
    '115': 6.1,
    '116': 6.1,
    '117': 6,
    '118': 6,
    '119': 5.9,
    '120': 5.9,
    '121': 5.8,
    '122': 5.8,
    '123': 5.8,
    '124': 5.7,
    '125': 5.7,
    '126': 5.6,
    '127': 5.6,
    '128': 5.6,
    '129': 5.5,
    '130': 5.5,
    '131': 5.4,
    '132': 5.4,
    '133': 5.3,
    '134': 5.3,
    '135': 5.3,
    '136': 5.2,
    '137': 5.2,
    '138': 5.1,
    '139': 5.1,
    '140': 5.1,
    '141': 5,
    '142': 5,
    '143': 4.9,
    '144': 4.9,
    '145': 4.8,
    '146': 4.8,
    '147': 4.8,
    '148': 4.7,
    '149': 4.7,
    '150': 4.6,
    '151': 4.6,
    '152': 4.6,
    '153': 4.5,
    '154': 4.5,
    '155': 4.4,
    '156': 4.4,
    '157': 4.4,
    '158': 4.3,
    '159': 4.3,
    '160': 4.2,
    '161': 4.2,
    '162': 4.2,
    '163': 4.1,
    '164': 4.1,
    '165': 4,
    '166': 4,
    '167': 4,
    '168': 3.9,
    '169': 3.9,
    '170': 3.8,
    '171': 3.8,
    '172': 3.8,
    '173': 3.7,
    '174': 3.7,
    '175': 3.6,
    '176': 3.6,
    '177': 3.6,
    '178': 3.5,
    '179': 3.5,
    '180': 3.4,
    '181': 3.4,
    '182': 3.4,
    '183': 3.3,
    '184': 3.3,
    '185': 3.2,
    '186': 3.2,
    '187': 3.2,
    '188': 3.1,
    '189': 3.1,
    '190': 3.1,
    '191': 3,
    '192': 3,
    '193': 2.9,
    '194': 2.9,
    '195': 2.9,
    '196': 2.8,
    '197': 2.8,
    '198': 2.7,
    '199': 2.7,
    '200': 2.7,
    '201': 2.6,
    '202': 2.6,
    '203': 2.5,
    '204': 2.5,
    '205': 2.5,
    '206': 2.4,
    '207': 2.4,
    '208': 2.4,
    '209': 2.3,
    '210': 2.3,
    '211': 2.2,
    '212': 2.2,
    '213': 2.2,
    '214': 2.1,
    '215': 2.1,
    '216': 2.1,
    '217': 2,
    '218': 2,
    '219': 1.9,
    '220': 1.9,
    '221': 1.9,
    '222': 1.8,
    '223': 1.8,
    '224': 1.7,
    '225': 1.7,
    '0.5': 11.3,
    '1.5': 11.2,
    '2.5': 11.2,
    '3.5': 11.1,
    '4.5': 11.1,
    '5.5': 11,
    '6.5': 11,
    '7.5': 10.9,
    '8.5': 10.9,
    '9.5': 10.8,
    '10.5': 10.8,
    '11.5': 10.7,
    '12.5': 10.7,
    '13.5': 10.7,
    '14.5': 10.6,
    '15.5': 10.6,
    '16.5': 10.5,
    '17.5': 10.5,
    '18.5': 10.4,
    '19.5': 10.4,
    '20.5': 10.3,
    '21.5': 10.3,
    '22.5': 10.2,
    '23.5': 10.2,
    '24.5': 10.1,
    '25.5': 10.1,
    '26.5': 10,
    '27.5': 10,
    '28.5': 9.9,
    '29.5': 9.9,
    '30.5': 9.9,
    '31.5': 9.8,
    '32.5': 9.8,
    '33.5': 9.7,
    '34.5': 9.7,
    '35.5': 9.6,
    '36.5': 9.6,
    '37.5': 9.5,
    '38.5': 9.5,
    '39.5': 9.4,
    '40.5': 9.4,
    '41.5': 9.3,
    '42.5': 9.3,
    '43.5': 9.3,
    '44.5': 9.2,
    '45.5': 9.2,
    '46.5': 9.1,
    '47.5': 9.1,
    '48.5': 9,
    '49.5': 9,
    '50.5': 8.9,
    '51.5': 8.9,
    '52.5': 8.8,
    '53.5': 8.8,
    '54.5': 8.7,
    '55.5': 8.7,
    '56.5': 8.7,
    '57.5': 8.6,
    '58.5': 8.6,
    '59.5': 8.5,
    '60.5': 8.5,
    '61.5': 8.4,
    '62.5': 8.4,
    '63.5': 8.3,
    '64.5': 8.3,
    '65.5': 8.3,
    '66.5': 8.2,
    '67.5': 8.2,
    '68.5': 8.1,
    '69.5': 8.1,
    '70.5': 8,
    '71.5': 8,
    '72.5': 7.9,
    '73.5': 7.9,
    '74.5': 7.9,
    '75.5': 7.8,
    '76.5': 7.8,
    '77.5': 7.7,
    '78.5': 7.7,
    '79.5': 7.6,
    '80.5': 7.6,
    '81.5': 7.5,
    '82.5': 7.5,
    '83.5': 7.5,
    '84.5': 7.4,
    '85.5': 7.4,
    '86.5': 7.3,
    '87.5': 7.3,
    '88.5': 7.2,
    '89.5': 7.2,
    '90.5': 7.1,
    '91.5': 7.1,
    '92.5': 7.1,
    '93.5': 7,
    '94.5': 7,
    '95.5': 6.9,
    '96.5': 6.9,
    '97.5': 6.8,
    '98.5': 6.8,
    '99.5': 6.8,
    '100.5': 6.7,
    '101.5': 6.7,
    '102.5': 6.6,
    '103.5': 6.6,
    '104.5': 6.5,
    '105.5': 6.5,
    '106.5': 6.5,
    '107.5': 6.4,
    '108.5': 6.4,
    '109.5': 6.3,
    '110.5': 6.3,
    '111.5': 6.2,
    '112.5': 6.2,
    '113.5': 6.2,
    '114.5': 6.1,
    '115.5': 6.1,
    '116.5': 6,
    '117.5': 6,
    '118.5': 5.9,
    '119.5': 5.9,
    '120.5': 5.9,
    '121.5': 5.8,
    '122.5': 5.8,
    '123.5': 5.7,
    '124.5': 5.7,
    '125.5': 5.7,
    '126.5': 5.6,
    '127.5': 5.6,
    '128.5': 5.5,
    '129.5': 5.5,
    '130.5': 5.4,
    '131.5': 5.4,
    '132.5': 5.4,
    '133.5': 5.3,
    '134.5': 5.3,
    '135.5': 5.2,
    '136.5': 5.2,
    '137.5': 5.2,
    '138.5': 5.1,
    '139.5': 5.1,
    '140.5': 5,
    '141.5': 5,
    '142.5': 5,
    '143.5': 4.9,
    '144.5': 4.9,
    '145.5': 4.8,
    '146.5': 4.8,
    '147.5': 4.7,
    '148.5': 4.7,
    '149.5': 4.7,
    '150.5': 4.6,
    '151.5': 4.6,
    '152.5': 4.5,
    '153.5': 4.5,
    '154.5': 4.5,
    '155.5': 4.4,
    '156.5': 4.4,
    '157.5': 4.3,
    '158.5': 4.3,
    '159.5': 4.3,
    '160.5': 4.2,
    '161.5': 4.2,
    '162.5': 4.1,
    '163.5': 4.1,
    '164.5': 4.1,
    '165.5': 4,
    '166.5': 4,
    '167.5': 3.9,
    '168.5': 3.9,
    '169.5': 3.9,
    '170.5': 3.8,
    '171.5': 3.8,
    '172.5': 3.7,
    '173.5': 3.7,
    '174.5': 3.7,
    '175.5': 3.6,
    '176.5': 3.6,
    '177.5': 3.5,
    '178.5': 3.5,
    '179.5': 3.5,
    '180.5': 3.4,
    '181.5': 3.4,
    '182.5': 3.3,
    '183.5': 3.3,
    '184.5': 3.3,
    '185.5': 3.2,
    '186.5': 3.2,
    '187.5': 3.1,
    '188.5': 3.1,
    '189.5': 3.1,
    '190.5': 3,
    '191.5': 3,
    '192.5': 3,
    '193.5': 2.9,
    '194.5': 2.9,
    '195.5': 2.8,
    '196.5': 2.8,
    '197.5': 2.8,
    '198.5': 2.7,
    '199.5': 2.7,
    '200.5': 2.6,
    '201.5': 2.6,
    '202.5': 2.6,
    '203.5': 2.5,
    '204.5': 2.5,
    '205.5': 2.5,
    '206.5': 2.4,
    '207.5': 2.4,
    '208.5': 2.3,
    '209.5': 2.3,
    '210.5': 2.3,
    '211.5': 2.2,
    '212.5': 2.2,
    '213.5': 2.1,
    '214.5': 2.1,
    '215.5': 2.1,
    '216.5': 2,
    '217.5': 2,
    '218.5': 2,
    '219.5': 1.9,
    '220.5': 1.9,
    '221.5': 1.8,
    '222.5': 1.8,
    '223.5': 1.8,
    '224.5': 1.7,
  },
  '9': {
    '0': 11.8,
    '1': 11.8,
    '2': 11.7,
    '3': 11.7,
    '4': 11.6,
    '5': 11.6,
    '6': 11.5,
    '7': 11.5,
    '8': 11.4,
    '9': 11.4,
    '10': 11.3,
    '11': 11.3,
    '12': 11.2,
    '13': 11.2,
    '14': 11.2,
    '15': 11.1,
    '16': 11.1,
    '17': 11,
    '18': 11,
    '19': 10.9,
    '20': 10.9,
    '21': 10.8,
    '22': 10.8,
    '23': 10.7,
    '24': 10.7,
    '25': 10.6,
    '26': 10.6,
    '27': 10.5,
    '28': 10.5,
    '29': 10.4,
    '30': 10.4,
    '31': 10.3,
    '32': 10.3,
    '33': 10.3,
    '34': 10.2,
    '35': 10.2,
    '36': 10.1,
    '37': 10.1,
    '38': 10,
    '39': 10,
    '40': 9.9,
    '41': 9.9,
    '42': 9.8,
    '43': 9.8,
    '44': 9.7,
    '45': 9.7,
    '46': 9.6,
    '47': 9.6,
    '48': 9.6,
    '49': 9.5,
    '50': 9.5,
    '51': 9.4,
    '52': 9.4,
    '53': 9.3,
    '54': 9.3,
    '55': 9.2,
    '56': 9.2,
    '57': 9.1,
    '58': 9.1,
    '59': 9,
    '60': 9,
    '61': 9,
    '62': 8.9,
    '63': 8.9,
    '64': 8.8,
    '65': 8.8,
    '66': 8.7,
    '67': 8.7,
    '68': 8.6,
    '69': 8.6,
    '70': 8.5,
    '71': 8.5,
    '72': 8.5,
    '73': 8.4,
    '74': 8.4,
    '75': 8.3,
    '76': 8.3,
    '77': 8.2,
    '78': 8.2,
    '79': 8.1,
    '80': 8.1,
    '81': 8.1,
    '82': 8,
    '83': 8,
    '84': 7.9,
    '85': 7.9,
    '86': 7.8,
    '87': 7.8,
    '88': 7.7,
    '89': 7.7,
    '90': 7.7,
    '91': 7.6,
    '92': 7.6,
    '93': 7.5,
    '94': 7.5,
    '95': 7.4,
    '96': 7.4,
    '97': 7.3,
    '98': 7.3,
    '99': 7.3,
    '100': 7.2,
    '101': 7.2,
    '102': 7.1,
    '103': 7.1,
    '104': 7,
    '105': 7,
    '106': 7,
    '107': 6.9,
    '108': 6.9,
    '109': 6.8,
    '110': 6.8,
    '111': 6.7,
    '112': 6.7,
    '113': 6.7,
    '114': 6.6,
    '115': 6.6,
    '116': 6.5,
    '117': 6.5,
    '118': 6.4,
    '119': 6.4,
    '120': 6.4,
    '121': 6.3,
    '122': 6.3,
    '123': 6.2,
    '124': 6.2,
    '125': 6.1,
    '126': 6.1,
    '127': 6.1,
    '128': 6,
    '129': 6,
    '130': 5.9,
    '131': 5.9,
    '132': 5.9,
    '133': 5.8,
    '134': 5.8,
    '135': 5.7,
    '136': 5.7,
    '137': 5.6,
    '138': 5.6,
    '139': 5.6,
    '140': 5.5,
    '141': 5.5,
    '142': 5.4,
    '143': 5.4,
    '144': 5.4,
    '145': 5.3,
    '146': 5.3,
    '147': 5.2,
    '148': 5.2,
    '149': 5.1,
    '150': 5.1,
    '151': 5.1,
    '152': 5,
    '153': 5,
    '154': 4.9,
    '155': 4.9,
    '156': 4.9,
    '157': 4.8,
    '158': 4.8,
    '159': 4.7,
    '160': 4.7,
    '161': 4.7,
    '162': 4.6,
    '163': 4.6,
    '164': 4.5,
    '165': 4.5,
    '166': 4.5,
    '167': 4.4,
    '168': 4.4,
    '169': 4.3,
    '170': 4.3,
    '171': 4.3,
    '172': 4.2,
    '173': 4.2,
    '174': 4.1,
    '175': 4.1,
    '176': 4.1,
    '177': 4,
    '178': 4,
    '179': 3.9,
    '180': 3.9,
    '181': 3.9,
    '182': 3.8,
    '183': 3.8,
    '184': 3.7,
    '185': 3.7,
    '186': 3.7,
    '187': 3.6,
    '188': 3.6,
    '189': 3.5,
    '190': 3.5,
    '191': 3.5,
    '192': 3.4,
    '193': 3.4,
    '194': 3.3,
    '195': 3.3,
    '196': 3.3,
    '197': 3.2,
    '198': 3.2,
    '199': 3.1,
    '200': 3.1,
    '201': 3.1,
    '202': 3,
    '203': 3,
    '204': 2.9,
    '205': 2.9,
    '206': 2.9,
    '207': 2.8,
    '208': 2.8,
    '209': 2.8,
    '210': 2.7,
    '211': 2.7,
    '212': 2.6,
    '213': 2.6,
    '214': 2.6,
    '215': 2.5,
    '216': 2.5,
    '217': 2.4,
    '218': 2.4,
    '219': 2.4,
    '220': 2.3,
    '221': 2.3,
    '222': 2.3,
    '223': 2.2,
    '224': 2.2,
    '225': 2.1,
    '0.5': 11.8,
    '1.5': 11.8,
    '2.5': 11.7,
    '3.5': 11.7,
    '4.5': 11.6,
    '5.5': 11.6,
    '6.5': 11.5,
    '7.5': 11.5,
    '8.5': 11.4,
    '9.5': 11.4,
    '10.5': 11.3,
    '11.5': 11.3,
    '12.5': 11.2,
    '13.5': 11.2,
    '14.5': 11.1,
    '15.5': 11.1,
    '16.5': 11,
    '17.5': 11,
    '18.5': 10.9,
    '19.5': 10.9,
    '20.5': 10.8,
    '21.5': 10.8,
    '22.5': 10.7,
    '23.5': 10.7,
    '24.5': 10.7,
    '25.5': 10.6,
    '26.5': 10.6,
    '27.5': 10.5,
    '28.5': 10.5,
    '29.5': 10.4,
    '30.5': 10.4,
    '31.5': 10.3,
    '32.5': 10.3,
    '33.5': 10.2,
    '34.5': 10.2,
    '35.5': 10.1,
    '36.5': 10.1,
    '37.5': 10,
    '38.5': 10,
    '39.5': 9.9,
    '40.5': 9.9,
    '41.5': 9.9,
    '42.5': 9.8,
    '43.5': 9.8,
    '44.5': 9.7,
    '45.5': 9.7,
    '46.5': 9.6,
    '47.5': 9.6,
    '48.5': 9.5,
    '49.5': 9.5,
    '50.5': 9.4,
    '51.5': 9.4,
    '52.5': 9.3,
    '53.5': 9.3,
    '54.5': 9.3,
    '55.5': 9.2,
    '56.5': 9.2,
    '57.5': 9.1,
    '58.5': 9.1,
    '59.5': 9,
    '60.5': 9,
    '61.5': 8.9,
    '62.5': 8.9,
    '63.5': 8.8,
    '64.5': 8.8,
    '65.5': 8.8,
    '66.5': 8.7,
    '67.5': 8.7,
    '68.5': 8.6,
    '69.5': 8.6,
    '70.5': 8.5,
    '71.5': 8.5,
    '72.5': 8.4,
    '73.5': 8.4,
    '74.5': 8.3,
    '75.5': 8.3,
    '76.5': 8.3,
    '77.5': 8.2,
    '78.5': 8.2,
    '79.5': 8.1,
    '80.5': 8.1,
    '81.5': 8,
    '82.5': 8,
    '83.5': 7.9,
    '84.5': 7.9,
    '85.5': 7.9,
    '86.5': 7.8,
    '87.5': 7.8,
    '88.5': 7.7,
    '89.5': 7.7,
    '90.5': 7.6,
    '91.5': 7.6,
    '92.5': 7.5,
    '93.5': 7.5,
    '94.5': 7.5,
    '95.5': 7.4,
    '96.5': 7.4,
    '97.5': 7.3,
    '98.5': 7.3,
    '99.5': 7.2,
    '100.5': 7.2,
    '101.5': 7.2,
    '102.5': 7.1,
    '103.5': 7.1,
    '104.5': 7,
    '105.5': 7,
    '106.5': 6.9,
    '107.5': 6.9,
    '108.5': 6.9,
    '109.5': 6.8,
    '110.5': 6.8,
    '111.5': 6.7,
    '112.5': 6.7,
    '113.5': 6.6,
    '114.5': 6.6,
    '115.5': 6.6,
    '116.5': 6.5,
    '117.5': 6.5,
    '118.5': 6.4,
    '119.5': 6.4,
    '120.5': 6.3,
    '121.5': 6.3,
    '122.5': 6.3,
    '123.5': 6.2,
    '124.5': 6.2,
    '125.5': 6.1,
    '126.5': 6.1,
    '127.5': 6,
    '128.5': 6,
    '129.5': 6,
    '130.5': 5.9,
    '131.5': 5.9,
    '132.5': 5.8,
    '133.5': 5.8,
    '134.5': 5.7,
    '135.5': 5.7,
    '136.5': 5.7,
    '137.5': 5.6,
    '138.5': 5.6,
    '139.5': 5.5,
    '140.5': 5.5,
    '141.5': 5.5,
    '142.5': 5.4,
    '143.5': 5.4,
    '144.5': 5.3,
    '145.5': 5.3,
    '146.5': 5.2,
    '147.5': 5.2,
    '148.5': 5.2,
    '149.5': 5.1,
    '150.5': 5.1,
    '151.5': 5,
    '152.5': 5,
    '153.5': 5,
    '154.5': 4.9,
    '155.5': 4.9,
    '156.5': 4.8,
    '157.5': 4.8,
    '158.5': 4.8,
    '159.5': 4.7,
    '160.5': 4.7,
    '161.5': 4.6,
    '162.5': 4.6,
    '163.5': 4.6,
    '164.5': 4.5,
    '165.5': 4.5,
    '166.5': 4.4,
    '167.5': 4.4,
    '168.5': 4.4,
    '169.5': 4.3,
    '170.5': 4.3,
    '171.5': 4.2,
    '172.5': 4.2,
    '173.5': 4.1,
    '174.5': 4.1,
    '175.5': 4.1,
    '176.5': 4,
    '177.5': 4,
    '178.5': 3.9,
    '179.5': 3.9,
    '180.5': 3.9,
    '181.5': 3.8,
    '182.5': 3.8,
    '183.5': 3.8,
    '184.5': 3.7,
    '185.5': 3.7,
    '186.5': 3.6,
    '187.5': 3.6,
    '188.5': 3.6,
    '189.5': 3.5,
    '190.5': 3.5,
    '191.5': 3.4,
    '192.5': 3.4,
    '193.5': 3.4,
    '194.5': 3.3,
    '195.5': 3.3,
    '196.5': 3.2,
    '197.5': 3.2,
    '198.5': 3.2,
    '199.5': 3.1,
    '200.5': 3.1,
    '201.5': 3,
    '202.5': 3,
    '203.5': 3,
    '204.5': 2.9,
    '205.5': 2.9,
    '206.5': 2.9,
    '207.5': 2.8,
    '208.5': 2.8,
    '209.5': 2.7,
    '210.5': 2.7,
    '211.5': 2.7,
    '212.5': 2.6,
    '213.5': 2.6,
    '214.5': 2.5,
    '215.5': 2.5,
    '216.5': 2.5,
    '217.5': 2.4,
    '218.5': 2.4,
    '219.5': 2.4,
    '220.5': 2.3,
    '221.5': 2.3,
    '222.5': 2.2,
    '223.5': 2.2,
    '224.5': 2.2,
  },
  '9.5': {
    '0': 12.4,
    '1': 12.3,
    '2': 12.3,
    '3': 12.2,
    '4': 12.2,
    '5': 12.1,
    '6': 12.1,
    '7': 12,
    '8': 12,
    '9': 11.9,
    '10': 11.9,
    '11': 11.8,
    '12': 11.8,
    '13': 11.7,
    '14': 11.7,
    '15': 11.6,
    '16': 11.6,
    '17': 11.5,
    '18': 11.5,
    '19': 11.4,
    '20': 11.4,
    '21': 11.3,
    '22': 11.3,
    '23': 11.2,
    '24': 11.2,
    '25': 11.1,
    '26': 11.1,
    '27': 11,
    '28': 11,
    '29': 11,
    '30': 10.9,
    '31': 10.9,
    '32': 10.8,
    '33': 10.8,
    '34': 10.7,
    '35': 10.7,
    '36': 10.6,
    '37': 10.6,
    '38': 10.5,
    '39': 10.5,
    '40': 10.4,
    '41': 10.4,
    '42': 10.3,
    '43': 10.3,
    '44': 10.2,
    '45': 10.2,
    '46': 10.2,
    '47': 10.1,
    '48': 10.1,
    '49': 10,
    '50': 10,
    '51': 9.9,
    '52': 9.9,
    '53': 9.8,
    '54': 9.8,
    '55': 9.7,
    '56': 9.7,
    '57': 9.6,
    '58': 9.6,
    '59': 9.5,
    '60': 9.5,
    '61': 9.5,
    '62': 9.4,
    '63': 9.4,
    '64': 9.3,
    '65': 9.3,
    '66': 9.2,
    '67': 9.2,
    '68': 9.1,
    '69': 9.1,
    '70': 9,
    '71': 9,
    '72': 9,
    '73': 8.9,
    '74': 8.9,
    '75': 8.8,
    '76': 8.8,
    '77': 8.7,
    '78': 8.7,
    '79': 8.6,
    '80': 8.6,
    '81': 8.5,
    '82': 8.5,
    '83': 8.5,
    '84': 8.4,
    '85': 8.4,
    '86': 8.3,
    '87': 8.3,
    '88': 8.2,
    '89': 8.2,
    '90': 8.1,
    '91': 8.1,
    '92': 8.1,
    '93': 8,
    '94': 8,
    '95': 7.9,
    '96': 7.9,
    '97': 7.8,
    '98': 7.8,
    '99': 7.7,
    '100': 7.7,
    '101': 7.7,
    '102': 7.6,
    '103': 7.6,
    '104': 7.5,
    '105': 7.5,
    '106': 7.4,
    '107': 7.4,
    '108': 7.3,
    '109': 7.3,
    '110': 7.3,
    '111': 7.2,
    '112': 7.2,
    '113': 7.1,
    '114': 7.1,
    '115': 7,
    '116': 7,
    '117': 7,
    '118': 6.9,
    '119': 6.9,
    '120': 6.8,
    '121': 6.8,
    '122': 6.7,
    '123': 6.7,
    '124': 6.7,
    '125': 6.6,
    '126': 6.6,
    '127': 6.5,
    '128': 6.5,
    '129': 6.4,
    '130': 6.4,
    '131': 6.4,
    '132': 6.3,
    '133': 6.3,
    '134': 6.2,
    '135': 6.2,
    '136': 6.1,
    '137': 6.1,
    '138': 6.1,
    '139': 6,
    '140': 6,
    '141': 5.9,
    '142': 5.9,
    '143': 5.9,
    '144': 5.8,
    '145': 5.8,
    '146': 5.7,
    '147': 5.7,
    '148': 5.6,
    '149': 5.6,
    '150': 5.6,
    '151': 5.5,
    '152': 5.5,
    '153': 5.4,
    '154': 5.4,
    '155': 5.4,
    '156': 5.3,
    '157': 5.3,
    '158': 5.2,
    '159': 5.2,
    '160': 5.1,
    '161': 5.1,
    '162': 5.1,
    '163': 5,
    '164': 5,
    '165': 4.9,
    '166': 4.9,
    '167': 4.9,
    '168': 4.8,
    '169': 4.8,
    '170': 4.7,
    '171': 4.7,
    '172': 4.7,
    '173': 4.6,
    '174': 4.6,
    '175': 4.5,
    '176': 4.5,
    '177': 4.5,
    '178': 4.4,
    '179': 4.4,
    '180': 4.3,
    '181': 4.3,
    '182': 4.3,
    '183': 4.2,
    '184': 4.2,
    '185': 4.1,
    '186': 4.1,
    '187': 4.1,
    '188': 4,
    '189': 4,
    '190': 3.9,
    '191': 3.9,
    '192': 3.9,
    '193': 3.8,
    '194': 3.8,
    '195': 3.7,
    '196': 3.7,
    '197': 3.7,
    '198': 3.6,
    '199': 3.6,
    '200': 3.5,
    '201': 3.5,
    '202': 3.5,
    '203': 3.4,
    '204': 3.4,
    '205': 3.3,
    '206': 3.3,
    '207': 3.3,
    '208': 3.2,
    '209': 3.2,
    '210': 3.1,
    '211': 3.1,
    '212': 3.1,
    '213': 3,
    '214': 3,
    '215': 3,
    '216': 2.9,
    '217': 2.9,
    '218': 2.8,
    '219': 2.8,
    '220': 2.8,
    '221': 2.7,
    '222': 2.7,
    '223': 2.6,
    '224': 2.6,
    '225': 2.6,
    '0.5': 12.3,
    '1.5': 12.3,
    '2.5': 12.2,
    '3.5': 12.2,
    '4.5': 12.1,
    '5.5': 12.1,
    '6.5': 12,
    '7.5': 12,
    '8.5': 11.9,
    '9.5': 11.9,
    '10.5': 11.8,
    '11.5': 11.8,
    '12.5': 11.7,
    '13.5': 11.7,
    '14.5': 11.7,
    '15.5': 11.6,
    '16.5': 11.6,
    '17.5': 11.5,
    '18.5': 11.5,
    '19.5': 11.4,
    '20.5': 11.4,
    '21.5': 11.3,
    '22.5': 11.3,
    '23.5': 11.2,
    '24.5': 11.2,
    '25.5': 11.1,
    '26.5': 11.1,
    '27.5': 11,
    '28.5': 11,
    '29.5': 10.9,
    '30.5': 10.9,
    '31.5': 10.8,
    '32.5': 10.8,
    '33.5': 10.7,
    '34.5': 10.7,
    '35.5': 10.6,
    '36.5': 10.6,
    '37.5': 10.5,
    '38.5': 10.5,
    '39.5': 10.5,
    '40.5': 10.4,
    '41.5': 10.4,
    '42.5': 10.3,
    '43.5': 10.3,
    '44.5': 10.2,
    '45.5': 10.2,
    '46.5': 10.1,
    '47.5': 10.1,
    '48.5': 10,
    '49.5': 10,
    '50.5': 9.9,
    '51.5': 9.9,
    '52.5': 9.8,
    '53.5': 9.8,
    '54.5': 9.8,
    '55.5': 9.7,
    '56.5': 9.7,
    '57.5': 9.6,
    '58.5': 9.6,
    '59.5': 9.5,
    '60.5': 9.5,
    '61.5': 9.4,
    '62.5': 9.4,
    '63.5': 9.3,
    '64.5': 9.3,
    '65.5': 9.2,
    '66.5': 9.2,
    '67.5': 9.2,
    '68.5': 9.1,
    '69.5': 9.1,
    '70.5': 9,
    '71.5': 9,
    '72.5': 8.9,
    '73.5': 8.9,
    '74.5': 8.9,
    '75.5': 8.8,
    '76.5': 8.7,
    '77.5': 8.7,
    '78.5': 8.7,
    '79.5': 8.6,
    '80.5': 8.6,
    '81.5': 8.5,
    '82.5': 8.5,
    '83.5': 8.4,
    '84.5': 8.4,
    '85.5': 8.3,
    '86.5': 8.3,
    '87.5': 8.3,
    '88.5': 8.2,
    '89.5': 8.2,
    '90.5': 8.1,
    '91.5': 8.1,
    '92.5': 8,
    '93.5': 8,
    '94.5': 7.9,
    '95.5': 7.9,
    '96.5': 7.9,
    '97.5': 7.8,
    '98.5': 7.8,
    '99.5': 7.7,
    '100.5': 7.7,
    '101.5': 7.6,
    '102.5': 7.6,
    '103.5': 7.5,
    '104.5': 7.5,
    '105.5': 7.5,
    '106.5': 7.4,
    '107.5': 7.4,
    '108.5': 7.3,
    '109.5': 7.3,
    '110.5': 7.2,
    '111.5': 7.2,
    '112.5': 7.2,
    '113.5': 7.1,
    '114.5': 7.1,
    '115.5': 7,
    '116.5': 7,
    '117.5': 6.9,
    '118.5': 6.9,
    '119.5': 6.9,
    '120.5': 6.8,
    '121.5': 6.8,
    '122.5': 6.7,
    '123.5': 6.7,
    '124.5': 6.6,
    '125.5': 6.6,
    '126.5': 6.6,
    '127.5': 6.5,
    '128.5': 6.5,
    '129.5': 6.4,
    '130.5': 6.4,
    '131.5': 6.3,
    '132.5': 6.3,
    '133.5': 6.3,
    '134.5': 6.2,
    '135.5': 6.2,
    '136.5': 6.1,
    '137.5': 6.1,
    '138.5': 6,
    '139.5': 6,
    '140.5': 6,
    '141.5': 5.9,
    '142.5': 5.9,
    '143.5': 5.8,
    '144.5': 5.8,
    '145.5': 5.8,
    '146.5': 5.7,
    '147.5': 5.7,
    '148.5': 5.6,
    '149.5': 5.6,
    '150.5': 5.5,
    '151.5': 5.5,
    '152.5': 5.5,
    '153.5': 5.4,
    '154.5': 5.4,
    '155.5': 5.3,
    '156.5': 5.3,
    '157.5': 5.3,
    '158.5': 5.2,
    '159.5': 5.2,
    '160.5': 5.1,
    '161.5': 5.1,
    '162.5': 5,
    '163.5': 5,
    '164.5': 5,
    '165.5': 4.9,
    '166.5': 4.9,
    '167.5': 4.8,
    '168.5': 4.8,
    '169.5': 4.8,
    '170.5': 4.7,
    '171.5': 4.7,
    '172.5': 4.6,
    '173.5': 4.6,
    '174.5': 4.6,
    '175.5': 4.5,
    '176.5': 4.5,
    '177.5': 4.4,
    '178.5': 4.4,
    '179.5': 4.4,
    '180.5': 4.3,
    '181.5': 4.3,
    '182.5': 4.2,
    '183.5': 4.2,
    '184.5': 4.2,
    '185.5': 4.1,
    '186.5': 4.1,
    '187.5': 4,
    '188.5': 4,
    '189.5': 4,
    '190.5': 3.9,
    '191.5': 3.9,
    '192.5': 3.8,
    '193.5': 3.8,
    '194.5': 3.8,
    '195.5': 3.7,
    '196.5': 3.7,
    '197.5': 3.6,
    '198.5': 3.6,
    '199.5': 3.6,
    '200.5': 3.5,
    '201.5': 3.5,
    '202.5': 3.4,
    '203.5': 3.4,
    '204.5': 3.4,
    '205.5': 3.3,
    '206.5': 3.3,
    '207.5': 3.2,
    '208.5': 3.2,
    '209.5': 3.2,
    '210.5': 3.1,
    '211.5': 3.1,
    '212.5': 3.1,
    '213.5': 3,
    '214.5': 3,
    '215.5': 2.9,
    '216.5': 2.9,
    '217.5': 2.9,
    '218.5': 2.8,
    '219.5': 2.8,
    '220.5': 2.7,
    '221.5': 2.7,
    '222.5': 2.7,
    '223.5': 2.6,
    '224.5': 2.6,
  },
  '10': {
    '0': 12.9,
    '1': 12.8,
    '2': 12.8,
    '3': 12.7,
    '4': 12.7,
    '5': 12.6,
    '6': 12.6,
    '7': 12.5,
    '8': 12.5,
    '9': 12.4,
    '10': 12.4,
    '11': 12.3,
    '12': 12.3,
    '13': 12.2,
    '14': 12.2,
    '15': 12.2,
    '16': 12.1,
    '17': 12.1,
    '18': 12,
    '19': 12,
    '20': 11.9,
    '21': 11.9,
    '22': 11.8,
    '23': 11.8,
    '24': 11.7,
    '25': 11.7,
    '26': 11.6,
    '27': 11.6,
    '28': 11.5,
    '29': 11.5,
    '30': 11.4,
    '31': 11.4,
    '32': 11.3,
    '33': 11.3,
    '34': 11.2,
    '35': 11.2,
    '36': 11.1,
    '37': 11.1,
    '38': 11,
    '39': 11,
    '40': 10.9,
    '41': 10.9,
    '42': 10.8,
    '43': 10.8,
    '44': 10.8,
    '45': 10.7,
    '46': 10.7,
    '47': 10.6,
    '48': 10.6,
    '49': 10.5,
    '50': 10.5,
    '51': 10.4,
    '52': 10.4,
    '53': 10.3,
    '54': 10.3,
    '55': 10.2,
    '56': 10.2,
    '57': 10.1,
    '58': 10.1,
    '59': 10,
    '60': 10,
    '61': 10,
    '62': 9.9,
    '63': 9.9,
    '64': 9.8,
    '65': 9.8,
    '66': 9.7,
    '67': 9.7,
    '68': 9.6,
    '69': 9.6,
    '70': 9.5,
    '71': 9.5,
    '72': 9.4,
    '73': 9.4,
    '74': 9.4,
    '75': 9.3,
    '76': 9.3,
    '77': 9.2,
    '78': 9.2,
    '79': 9.1,
    '80': 9.1,
    '81': 9,
    '82': 9,
    '83': 8.9,
    '84': 8.9,
    '85': 8.9,
    '86': 8.8,
    '87': 8.8,
    '88': 8.7,
    '89': 8.7,
    '90': 8.6,
    '91': 8.6,
    '92': 8.5,
    '93': 8.5,
    '94': 8.4,
    '95': 8.4,
    '96': 8.4,
    '97': 8.3,
    '98': 8.3,
    '99': 8.2,
    '100': 8.2,
    '101': 8.1,
    '102': 8.1,
    '103': 8,
    '104': 8,
    '105': 8,
    '106': 7.9,
    '107': 7.9,
    '108': 7.8,
    '109': 7.8,
    '110': 7.7,
    '111': 7.7,
    '112': 7.7,
    '113': 7.6,
    '114': 7.6,
    '115': 7.5,
    '116': 7.5,
    '117': 7.4,
    '118': 7.4,
    '119': 7.3,
    '120': 7.3,
    '121': 7.3,
    '122': 7.2,
    '123': 7.2,
    '124': 7.1,
    '125': 7.1,
    '126': 7,
    '127': 7,
    '128': 7,
    '129': 6.9,
    '130': 6.9,
    '131': 6.8,
    '132': 6.8,
    '133': 6.7,
    '134': 6.7,
    '135': 6.7,
    '136': 6.6,
    '137': 6.6,
    '138': 6.5,
    '139': 6.5,
    '140': 6.4,
    '141': 6.4,
    '142': 6.4,
    '143': 6.3,
    '144': 6.3,
    '145': 6.2,
    '146': 6.2,
    '147': 6.1,
    '148': 6.1,
    '149': 6.1,
    '150': 6,
    '151': 6,
    '152': 5.9,
    '153': 5.9,
    '154': 5.9,
    '155': 5.8,
    '156': 5.8,
    '157': 5.7,
    '158': 5.7,
    '159': 5.6,
    '160': 5.6,
    '161': 5.6,
    '162': 5.5,
    '163': 5.5,
    '164': 5.4,
    '165': 5.4,
    '166': 5.4,
    '167': 5.3,
    '168': 5.3,
    '169': 5.2,
    '170': 5.2,
    '171': 5.1,
    '172': 5.1,
    '173': 5.1,
    '174': 5,
    '175': 5,
    '176': 4.9,
    '177': 4.9,
    '178': 4.9,
    '179': 4.8,
    '180': 4.8,
    '181': 4.7,
    '182': 4.7,
    '183': 4.7,
    '184': 4.6,
    '185': 4.6,
    '186': 4.5,
    '187': 4.5,
    '188': 4.5,
    '189': 4.4,
    '190': 4.4,
    '191': 4.3,
    '192': 4.3,
    '193': 4.3,
    '194': 4.2,
    '195': 4.2,
    '196': 4.1,
    '197': 4.1,
    '198': 4.1,
    '199': 4,
    '200': 4,
    '201': 3.9,
    '202': 3.9,
    '203': 3.9,
    '204': 3.8,
    '205': 3.8,
    '206': 3.7,
    '207': 3.7,
    '208': 3.7,
    '209': 3.6,
    '210': 3.6,
    '211': 3.5,
    '212': 3.5,
    '213': 3.5,
    '214': 3.4,
    '215': 3.4,
    '216': 3.3,
    '217': 3.3,
    '218': 3.3,
    '219': 3.2,
    '220': 3.2,
    '221': 3.2,
    '222': 3.1,
    '223': 3.1,
    '224': 3,
    '225': 3,
    '0.5': 12.9,
    '1.5': 12.8,
    '2.5': 12.8,
    '3.5': 12.7,
    '4.5': 12.7,
    '5.5': 12.6,
    '6.5': 12.6,
    '7.5': 12.5,
    '8.5': 12.5,
    '9.5': 12.4,
    '10.5': 12.4,
    '11.5': 12.3,
    '12.5': 12.3,
    '13.5': 12.2,
    '14.5': 12.2,
    '15.5': 12.1,
    '16.5': 12.1,
    '17.5': 12,
    '18.5': 12,
    '19.5': 11.9,
    '20.5': 11.9,
    '21.5': 11.8,
    '22.5': 11.8,
    '23.5': 11.7,
    '24.5': 11.7,
    '25.5': 11.6,
    '26.5': 11.6,
    '27.5': 11.5,
    '28.5': 11.5,
    '29.5': 11.4,
    '30.5': 11.4,
    '31.5': 11.3,
    '32.5': 11.3,
    '33.5': 11.3,
    '34.5': 11.2,
    '35.5': 11.2,
    '36.5': 11.1,
    '37.5': 11.1,
    '38.5': 11,
    '39.5': 11,
    '40.5': 10.9,
    '41.5': 10.9,
    '42.5': 10.8,
    '43.5': 10.8,
    '44.5': 10.7,
    '45.5': 10.7,
    '46.5': 10.6,
    '47.5': 10.6,
    '48.5': 10.5,
    '49.5': 10.5,
    '50.5': 10.4,
    '51.5': 10.4,
    '52.5': 10.4,
    '53.5': 10.3,
    '54.5': 10.3,
    '55.5': 10.2,
    '56.5': 10.2,
    '57.5': 10.1,
    '58.5': 10.1,
    '59.5': 10,
    '60.5': 10,
    '61.5': 9.9,
    '62.5': 9.9,
    '63.5': 9.8,
    '64.5': 9.8,
    '65.5': 9.7,
    '66.5': 9.7,
    '67.5': 9.7,
    '68.5': 9.6,
    '69.5': 9.6,
    '70.5': 9.5,
    '71.5': 9.5,
    '72.5': 9.4,
    '73.5': 9.4,
    '74.5': 9.3,
    '75.5': 9.3,
    '76.5': 9.2,
    '77.5': 9.2,
    '78.5': 9.1,
    '79.5': 9.1,
    '80.5': 9.1,
    '81.5': 9,
    '82.5': 9,
    '83.5': 8.9,
    '84.5': 8.9,
    '85.5': 8.8,
    '86.5': 8.8,
    '87.5': 8.7,
    '88.5': 8.7,
    '89.5': 8.6,
    '90.5': 8.6,
    '91.5': 8.6,
    '92.5': 8.5,
    '93.5': 8.5,
    '94.5': 8.4,
    '95.5': 8.4,
    '96.5': 8.3,
    '97.5': 8.3,
    '98.5': 8.2,
    '99.5': 8.2,
    '100.5': 8.2,
    '101.5': 8.1,
    '102.5': 8.1,
    '103.5': 8,
    '104.5': 8,
    '105.5': 7.9,
    '106.5': 7.9,
    '107.5': 7.8,
    '108.5': 7.8,
    '109.5': 7.8,
    '110.5': 7.7,
    '111.5': 7.7,
    '112.5': 7.6,
    '113.5': 7.6,
    '114.5': 7.5,
    '115.5': 7.5,
    '116.5': 7.5,
    '117.5': 7.4,
    '118.5': 7.4,
    '119.5': 7.3,
    '120.5': 7.3,
    '121.5': 7.2,
    '122.5': 7.2,
    '123.5': 7.2,
    '124.5': 7.1,
    '125.5': 7.1,
    '126.5': 7,
    '127.5': 7,
    '128.5': 6.9,
    '129.5': 6.9,
    '130.5': 6.8,
    '131.5': 6.8,
    '132.5': 6.8,
    '133.5': 6.7,
    '134.5': 6.7,
    '135.5': 6.6,
    '136.5': 6.6,
    '137.5': 6.5,
    '138.5': 6.5,
    '139.5': 6.5,
    '140.5': 6.4,
    '141.5': 6.4,
    '142.5': 6.3,
    '143.5': 6.3,
    '144.5': 6.3,
    '145.5': 6.2,
    '146.5': 6.2,
    '147.5': 6.1,
    '148.5': 6.1,
    '149.5': 6,
    '150.5': 6,
    '151.5': 6,
    '152.5': 5.9,
    '153.5': 5.9,
    '154.5': 5.8,
    '155.5': 5.8,
    '156.5': 5.7,
    '157.5': 5.7,
    '158.5': 5.7,
    '159.5': 5.6,
    '160.5': 5.6,
    '161.5': 5.5,
    '162.5': 5.5,
    '163.5': 5.5,
    '164.5': 5.4,
    '165.5': 5.4,
    '166.5': 5.3,
    '167.5': 5.3,
    '168.5': 5.3,
    '169.5': 5.2,
    '170.5': 5.2,
    '171.5': 5.1,
    '172.5': 5.1,
    '173.5': 5,
    '174.5': 5,
    '175.5': 5,
    '176.5': 4.9,
    '177.5': 4.9,
    '178.5': 4.8,
    '179.5': 4.8,
    '180.5': 4.8,
    '181.5': 4.7,
    '182.5': 4.7,
    '183.5': 4.6,
    '184.5': 4.6,
    '185.5': 4.6,
    '186.5': 4.5,
    '187.5': 4.5,
    '188.5': 4.4,
    '189.5': 4.4,
    '190.5': 4.4,
    '191.5': 4.3,
    '192.5': 4.3,
    '193.5': 4.2,
    '194.5': 4.2,
    '195.5': 4.2,
    '196.5': 4.1,
    '197.5': 4.1,
    '198.5': 4,
    '199.5': 4,
    '200.5': 4,
    '201.5': 3.9,
    '202.5': 3.9,
    '203.5': 3.8,
    '204.5': 3.8,
    '205.5': 3.8,
    '206.5': 3.7,
    '207.5': 3.7,
    '208.5': 3.6,
    '209.5': 3.6,
    '210.5': 3.6,
    '211.5': 3.5,
    '212.5': 3.5,
    '213.5': 3.4,
    '214.5': 3.4,
    '215.5': 3.4,
    '216.5': 3.3,
    '217.5': 3.3,
    '218.5': 3.2,
    '219.5': 3.2,
    '220.5': 3.2,
    '221.5': 3.1,
    '222.5': 3.1,
    '223.5': 3.1,
    '224.5': 3,
  },
  // 10 - 15
  '10.5': {
    '0': 13.4,
    '1': 13.4,
    '2': 13.3,
    '3': 13.3,
    '4': 13.2,
    '5': 13.2,
    '6': 13.1,
    '7': 13.1,
    '8': 13,
    '9': 13,
    '10': 12.9,
    '11': 12.9,
    '12': 12.8,
    '13': 12.8,
    '14': 12.7,
    '15': 12.7,
    '16': 12.6,
    '17': 12.6,
    '18': 12.5,
    '19': 12.5,
    '20': 12.4,
    '21': 12.4,
    '22': 12.3,
    '23': 12.3,
    '24': 12.2,
    '25': 12.2,
    '26': 12.1,
    '27': 12.1,
    '28': 12,
    '29': 12,
    '30': 11.9,
    '31': 11.9,
    '32': 11.8,
    '33': 11.8,
    '34': 11.7,
    '35': 11.7,
    '36': 11.6,
    '37': 11.6,
    '38': 11.5,
    '39': 11.5,
    '40': 11.5,
    '41': 11.4,
    '42': 11.4,
    '43': 11.3,
    '44': 11.3,
    '45': 11.2,
    '46': 11.2,
    '47': 11.1,
    '48': 11.1,
    '49': 11,
    '50': 11,
    '51': 10.9,
    '52': 10.9,
    '53': 10.8,
    '54': 10.8,
    '55': 10.7,
    '56': 10.7,
    '57': 10.6,
    '58': 10.6,
    '59': 10.5,
    '60': 10.5,
    '61': 10.5,
    '62': 10.4,
    '63': 10.4,
    '64': 10.3,
    '65': 10.3,
    '66': 10.2,
    '67': 10.2,
    '68': 10.1,
    '69': 10.1,
    '70': 10,
    '71': 10,
    '72': 9.9,
    '73': 9.9,
    '74': 9.8,
    '75': 9.8,
    '76': 9.8,
    '77': 9.7,
    '78': 9.7,
    '79': 9.6,
    '80': 9.6,
    '81': 9.5,
    '82': 9.5,
    '83': 9.4,
    '84': 9.4,
    '85': 9.3,
    '86': 9.3,
    '87': 9.2,
    '88': 9.2,
    '89': 9.2,
    '90': 9.1,
    '91': 9.1,
    '92': 9,
    '93': 9,
    '94': 8.9,
    '95': 8.9,
    '96': 8.8,
    '97': 8.8,
    '98': 8.8,
    '99': 8.7,
    '100': 8.7,
    '101': 8.6,
    '102': 8.6,
    '103': 8.5,
    '104': 8.5,
    '105': 8.4,
    '106': 8.4,
    '107': 8.3,
    '108': 8.3,
    '109': 8.3,
    '110': 8.2,
    '111': 8.2,
    '112': 8.1,
    '113': 8.1,
    '114': 8,
    '115': 8,
    '116': 7.9,
    '117': 7.9,
    '118': 7.9,
    '119': 7.8,
    '120': 7.8,
    '121': 7.7,
    '122': 7.7,
    '123': 7.6,
    '124': 7.6,
    '125': 7.6,
    '126': 7.5,
    '127': 7.5,
    '128': 7.4,
    '129': 7.4,
    '130': 7.3,
    '131': 7.3,
    '132': 7.3,
    '133': 7.2,
    '134': 7.2,
    '135': 7.1,
    '136': 7.1,
    '137': 7,
    '138': 7,
    '139': 6.9,
    '140': 6.9,
    '141': 6.9,
    '142': 6.8,
    '143': 6.8,
    '144': 6.7,
    '145': 6.7,
    '146': 6.6,
    '147': 6.6,
    '148': 6.6,
    '149': 6.5,
    '150': 6.5,
    '151': 6.4,
    '152': 6.4,
    '153': 6.4,
    '154': 6.3,
    '155': 6.3,
    '156': 6.2,
    '157': 6.2,
    '158': 6.1,
    '159': 6.1,
    '160': 6.1,
    '161': 6,
    '162': 6,
    '163': 5.9,
    '164': 5.9,
    '165': 5.8,
    '166': 5.8,
    '167': 5.8,
    '168': 5.7,
    '169': 5.7,
    '170': 5.6,
    '171': 5.6,
    '172': 5.6,
    '173': 5.5,
    '174': 5.5,
    '175': 5.4,
    '176': 5.4,
    '177': 5.3,
    '178': 5.3,
    '179': 5.3,
    '180': 5.2,
    '181': 5.2,
    '182': 5.1,
    '183': 5.1,
    '184': 5.1,
    '185': 5,
    '186': 5,
    '187': 4.9,
    '188': 4.9,
    '189': 4.9,
    '190': 4.8,
    '191': 4.8,
    '192': 4.7,
    '193': 4.7,
    '194': 4.7,
    '195': 4.6,
    '0.5': 13.4,
    '1.5': 13.4,
    '2.5': 13.3,
    '3.5': 13.3,
    '4.5': 13.2,
    '5.5': 13.1,
    '6.5': 13.1,
    '7.5': 13,
    '8.5': 13,
    '9.5': 12.9,
    '10.5': 12.9,
    '11.5': 12.8,
    '12.5': 12.8,
    '13.5': 12.7,
    '14.5': 12.7,
    '15.5': 12.7,
    '16.5': 12.6,
    '17.5': 12.6,
    '18.5': 12.5,
    '19.5': 12.5,
    '20.5': 12.4,
    '21.5': 12.4,
    '22.5': 12.3,
    '23.5': 12.3,
    '24.5': 12.2,
    '25.5': 12.2,
    '26.5': 12.1,
    '27.5': 12.1,
    '28.5': 12,
    '29.5': 12,
    '30.5': 11.9,
    '31.5': 11.9,
    '32.5': 11.8,
    '33.5': 11.8,
    '34.5': 11.7,
    '35.5': 11.7,
    '36.5': 11.6,
    '37.5': 11.6,
    '38.5': 11.5,
    '39.5': 11.5,
    '40.5': 11.4,
    '41.5': 11.4,
    '42.5': 11.3,
    '43.5': 11.3,
    '44.5': 11.2,
    '45.5': 11.2,
    '46.5': 11.1,
    '47.5': 11.1,
    '48.5': 11,
    '49.5': 11,
    '50.5': 10.9,
    '51.5': 10.9,
    '52.5': 10.9,
    '53.5': 10.8,
    '54.5': 10.8,
    '55.5': 10.7,
    '56.5': 10.7,
    '57.5': 10.6,
    '58.5': 10.6,
    '59.5': 10.5,
    '60.5': 10.5,
    '61.5': 10.4,
    '62.5': 10.4,
    '63.5': 10.3,
    '64.5': 10.3,
    '65.5': 10.2,
    '66.5': 10.2,
    '67.5': 10.1,
    '68.5': 10.1,
    '69.5': 10.1,
    '70.5': 10,
    '71.5': 10,
    '72.5': 9.9,
    '73.5': 9.9,
    '74.5': 9.8,
    '75.5': 9.8,
    '76.5': 9.7,
    '77.5': 9.7,
    '78.5': 9.6,
    '79.5': 9.6,
    '80.5': 9.5,
    '81.5': 9.5,
    '82.5': 9.5,
    '83.5': 9.4,
    '84.5': 9.4,
    '85.5': 9.3,
    '86.5': 9.3,
    '87.5': 9.2,
    '88.5': 9.2,
    '89.5': 9.1,
    '90.5': 9.1,
    '91.5': 9,
    '92.5': 9,
    '93.5': 9,
    '94.5': 8.9,
    '95.5': 8.9,
    '96.5': 8.8,
    '97.5': 8.8,
    '98.5': 8.7,
    '99.5': 8.7,
    '100.5': 8.6,
    '101.5': 8.6,
    '102.5': 8.5,
    '103.5': 8.5,
    '104.5': 8.5,
    '105.5': 8.4,
    '106.5': 8.4,
    '107.5': 8.3,
    '108.5': 8.3,
    '109.5': 8.2,
    '110.5': 8.2,
    '111.5': 8.1,
    '112.5': 8.1,
    '113.5': 8.1,
    '114.5': 8,
    '115.5': 8,
    '116.5': 7.9,
    '117.5': 7.9,
    '118.5': 7.8,
    '119.5': 7.8,
    '120.5': 7.8,
    '121.5': 7.7,
    '122.5': 7.7,
    '123.5': 7.6,
    '124.5': 7.6,
    '125.5': 7.5,
    '126.5': 7.5,
    '127.5': 7.4,
    '128.5': 7.4,
    '129.5': 7.4,
    '130.5': 7.3,
    '131.5': 7.3,
    '132.5': 7.2,
    '133.5': 7.2,
    '134.5': 7.1,
    '135.5': 7.1,
    '136.5': 7.1,
    '137.5': 7,
    '138.5': 7,
    '139.5': 6.9,
    '140.5': 6.9,
    '141.5': 6.8,
    '142.5': 6.8,
    '143.5': 6.8,
    '144.5': 6.7,
    '145.5': 6.7,
    '146.5': 6.6,
    '147.5': 6.6,
    '148.5': 6.5,
    '149.5': 6.5,
    '150.5': 6.5,
    '151.5': 6.4,
    '152.5': 6.4,
    '153.5': 6.3,
    '154.5': 6.3,
    '155.5': 6.2,
    '156.5': 6.2,
    '157.5': 6.2,
    '158.5': 6.1,
    '159.5': 6.1,
    '160.5': 6,
    '161.5': 6,
    '162.5': 6,
    '163.5': 5.9,
    '164.5': 5.9,
    '165.5': 5.8,
    '166.5': 5.8,
    '167.5': 5.7,
    '168.5': 5.7,
    '169.5': 5.7,
    '170.5': 5.6,
    '171.5': 5.6,
    '172.5': 5.5,
    '173.5': 5.5,
    '174.5': 5.5,
    '175.5': 5.4,
    '176.5': 5.4,
    '177.5': 5.3,
    '178.5': 5.3,
    '179.5': 5.2,
    '180.5': 5.2,
    '181.5': 5.2,
    '182.5': 5.1,
    '183.5': 5.1,
    '184.5': 5,
    '185.5': 5,
    '186.5': 5,
    '187.5': 4.9,
    '188.5': 4.9,
    '189.5': 4.8,
    '190.5': 4.8,
    '191.5': 4.8,
    '192.5': 4.7,
    '193.5': 4.7,
    '194.5': 4.6,
  },
  '11': {
    '0': 14,
    '1': 13.9,
    '2': 13.9,
    '3': 13.8,
    '4': 13.8,
    '5': 13.7,
    '6': 13.7,
    '7': 13.6,
    '8': 13.6,
    '9': 13.5,
    '10': 13.5,
    '11': 13.4,
    '12': 13.4,
    '13': 13.3,
    '14': 13.2,
    '15': 13.2,
    '16': 13.1,
    '17': 13.1,
    '18': 13,
    '19': 13,
    '20': 12.9,
    '21': 12.9,
    '22': 12.8,
    '23': 12.8,
    '24': 12.7,
    '25': 12.7,
    '26': 12.7,
    '27': 12.6,
    '28': 12.6,
    '29': 12.5,
    '30': 12.5,
    '31': 12.4,
    '32': 12.4,
    '33': 12.3,
    '34': 12.3,
    '35': 12.2,
    '36': 12.2,
    '37': 12.1,
    '38': 12.1,
    '39': 12,
    '40': 12,
    '41': 11.9,
    '42': 11.9,
    '43': 11.8,
    '44': 11.8,
    '45': 11.7,
    '46': 11.7,
    '47': 11.6,
    '48': 11.6,
    '49': 11.5,
    '50': 11.5,
    '51': 11.4,
    '52': 11.4,
    '53': 11.3,
    '54': 11.3,
    '55': 11.2,
    '56': 11.2,
    '57': 11.1,
    '58': 11.1,
    '59': 11,
    '60': 11,
    '61': 11,
    '62': 10.9,
    '63': 10.9,
    '64': 10.8,
    '65': 10.8,
    '66': 10.7,
    '67': 10.7,
    '68': 10.6,
    '69': 10.6,
    '70': 10.5,
    '71': 10.5,
    '72': 10.4,
    '73': 10.4,
    '74': 10.3,
    '75': 10.3,
    '76': 10.2,
    '77': 10.2,
    '78': 10.2,
    '79': 10.1,
    '80': 10.1,
    '81': 10,
    '82': 10,
    '83': 9.9,
    '84': 9.9,
    '85': 9.8,
    '86': 9.8,
    '87': 9.7,
    '88': 9.7,
    '89': 9.6,
    '90': 9.6,
    '91': 9.6,
    '92': 9.5,
    '93': 9.5,
    '94': 9.4,
    '95': 9.4,
    '96': 9.3,
    '97': 9.3,
    '98': 9.2,
    '99': 9.2,
    '100': 9.1,
    '101': 9.1,
    '102': 9.1,
    '103': 9,
    '104': 9,
    '105': 8.9,
    '106': 8.9,
    '107': 8.8,
    '108': 8.8,
    '109': 8.7,
    '110': 8.7,
    '111': 8.6,
    '112': 8.6,
    '113': 8.6,
    '114': 8.5,
    '115': 8.5,
    '116': 8.4,
    '117': 8.4,
    '118': 8.3,
    '119': 8.3,
    '120': 8.2,
    '121': 8.2,
    '122': 8.2,
    '123': 8.1,
    '124': 8.1,
    '125': 8,
    '126': 8,
    '127': 7.9,
    '128': 7.9,
    '129': 7.8,
    '130': 7.8,
    '131': 7.8,
    '132': 7.7,
    '133': 7.7,
    '134': 7.6,
    '135': 7.6,
    '136': 7.5,
    '137': 7.5,
    '138': 7.5,
    '139': 7.4,
    '140': 7.4,
    '141': 7.3,
    '142': 7.3,
    '143': 7.2,
    '144': 7.2,
    '145': 7.2,
    '146': 7.1,
    '147': 7.1,
    '148': 7,
    '149': 7,
    '150': 6.9,
    '151': 6.9,
    '152': 6.9,
    '153': 6.8,
    '154': 6.8,
    '155': 6.7,
    '156': 6.7,
    '157': 6.6,
    '158': 6.6,
    '159': 6.6,
    '160': 6.5,
    '161': 6.5,
    '162': 6.4,
    '163': 6.4,
    '164': 6.3,
    '165': 6.3,
    '166': 6.3,
    '167': 6.2,
    '168': 6.2,
    '169': 6.1,
    '170': 6.1,
    '171': 6,
    '172': 6,
    '173': 6,
    '174': 5.9,
    '175': 5.9,
    '176': 5.8,
    '177': 5.8,
    '178': 5.8,
    '179': 5.7,
    '180': 5.7,
    '181': 5.6,
    '182': 5.6,
    '183': 5.5,
    '184': 5.5,
    '185': 5.5,
    '186': 5.4,
    '187': 5.4,
    '188': 5.3,
    '189': 5.3,
    '190': 5.3,
    '191': 5.2,
    '192': 5.2,
    '193': 5.1,
    '194': 5.1,
    '195': 5.1,
    '0.5': 13.9,
    '1.5': 13.9,
    '2.5': 13.8,
    '3.5': 13.8,
    '4.5': 13.7,
    '5.5': 13.7,
    '6.5': 13.6,
    '7.5': 13.6,
    '8.5': 13.5,
    '9.5': 13.5,
    '10.5': 13.4,
    '11.5': 13.4,
    '12.5': 13.3,
    '13.5': 13.3,
    '14.5': 13.2,
    '15.5': 13.2,
    '16.5': 13.1,
    '17.5': 13.1,
    '18.5': 13,
    '19.5': 13,
    '20.5': 12.9,
    '21.5': 12.9,
    '22.5': 12.8,
    '23.5': 12.8,
    '24.5': 12.7,
    '25.5': 12.7,
    '26.5': 12.6,
    '27.5': 12.6,
    '28.5': 12.5,
    '29.5': 12.5,
    '30.5': 12.4,
    '31.5': 12.4,
    '32.5': 12.3,
    '33.5': 12.3,
    '34.5': 12.2,
    '35.5': 12.2,
    '36.5': 12.1,
    '37.5': 12.1,
    '38.5': 12,
    '39.5': 12,
    '40.5': 11.9,
    '41.5': 11.9,
    '42.5': 11.8,
    '43.5': 11.8,
    '44.5': 11.7,
    '45.5': 11.7,
    '46.5': 11.6,
    '47.5': 11.6,
    '48.5': 11.5,
    '49.5': 11.5,
    '50.5': 11.5,
    '51.5': 11.4,
    '52.5': 11.4,
    '53.5': 11.3,
    '54.5': 11.3,
    '55.5': 11.2,
    '56.5': 11.2,
    '57.5': 11.1,
    '58.5': 11.1,
    '59.5': 11,
    '60.5': 11,
    '61.5': 10.9,
    '62.5': 10.9,
    '63.5': 10.8,
    '64.5': 10.8,
    '65.5': 10.7,
    '66.5': 10.7,
    '67.5': 10.6,
    '68.5': 10.6,
    '69.5': 10.6,
    '70.5': 10.5,
    '71.5': 10.5,
    '72.5': 10.4,
    '73.5': 10.4,
    '74.5': 10.3,
    '75.5': 10.3,
    '76.5': 10.2,
    '77.5': 10.2,
    '78.5': 10.1,
    '79.5': 10.1,
    '80.5': 10,
    '81.5': 10,
    '82.5': 9.9,
    '83.5': 9.9,
    '84.5': 9.9,
    '85.5': 9.8,
    '86.5': 9.8,
    '87.5': 9.7,
    '88.5': 9.7,
    '89.5': 9.6,
    '90.5': 9.6,
    '91.5': 9.5,
    '92.5': 9.5,
    '93.5': 9.4,
    '94.5': 9.4,
    '95.5': 9.3,
    '96.5': 9.3,
    '97.5': 9.3,
    '98.5': 9.2,
    '99.5': 9.2,
    '100.5': 9.1,
    '101.5': 9.1,
    '102.5': 9,
    '103.5': 9,
    '104.5': 8.9,
    '105.5': 8.9,
    '106.5': 8.8,
    '107.5': 8.8,
    '108.5': 8.8,
    '109.5': 8.7,
    '110.5': 8.7,
    '111.5': 8.6,
    '112.5': 8.6,
    '113.5': 8.5,
    '114.5': 8.5,
    '115.5': 8.4,
    '116.5': 8.4,
    '117.5': 8.4,
    '118.5': 8.3,
    '119.5': 8.3,
    '120.5': 8.2,
    '121.5': 8.2,
    '122.5': 8.1,
    '123.5': 8.1,
    '124.5': 8,
    '125.5': 8,
    '126.5': 8,
    '127.5': 7.9,
    '128.5': 7.9,
    '129.5': 7.8,
    '130.5': 7.8,
    '131.5': 7.7,
    '132.5': 7.7,
    '133.5': 7.6,
    '134.5': 7.6,
    '135.5': 7.6,
    '136.5': 7.5,
    '137.5': 7.5,
    '138.5': 7.5,
    '139.5': 7.4,
    '140.5': 7.4,
    '141.5': 7.3,
    '142.5': 7.3,
    '143.5': 7.2,
    '144.5': 7.2,
    '145.5': 7.1,
    '146.5': 7.1,
    '147.5': 7,
    '148.5': 7,
    '149.5': 7,
    '150.5': 6.9,
    '151.5': 6.9,
    '152.5': 6.8,
    '153.5': 6.8,
    '154.5': 6.7,
    '155.5': 6.7,
    '156.5': 6.7,
    '157.5': 6.6,
    '158.5': 6.6,
    '159.5': 6.5,
    '160.5': 6.5,
    '161.5': 6.4,
    '162.5': 6.4,
    '163.5': 6.4,
    '164.5': 6.3,
    '165.5': 6.3,
    '166.5': 6.2,
    '167.5': 6.2,
    '168.5': 6.2,
    '169.5': 6.1,
    '170.5': 6.1,
    '171.5': 6,
    '172.5': 6,
    '173.5': 5.9,
    '174.5': 5.9,
    '175.5': 5.9,
    '176.5': 5.8,
    '177.5': 5.8,
    '178.5': 5.7,
    '179.5': 5.7,
    '180.5': 5.7,
    '181.5': 5.6,
    '182.5': 5.6,
    '183.5': 5.5,
    '184.5': 5.5,
    '185.5': 5.4,
    '186.5': 5.4,
    '187.5': 5.4,
    '188.5': 5.3,
    '189.5': 5.3,
    '190.5': 5.2,
    '191.5': 5.2,
    '192.5': 5.2,
    '193.5': 5.1,
    '194.5': 5.1,
  },
  '11.5': {
    '0': 14.5,
    '1': 14.4,
    '2': 14.4,
    '3': 14.3,
    '4': 14.3,
    '5': 14.2,
    '6': 14.2,
    '7': 14.1,
    '8': 14.1,
    '9': 14,
    '10': 14,
    '11': 13.9,
    '12': 13.9,
    '13': 13.8,
    '14': 13.8,
    '15': 13.7,
    '16': 13.7,
    '17': 13.6,
    '18': 13.6,
    '19': 13.5,
    '20': 13.5,
    '21': 13.4,
    '22': 13.4,
    '23': 13.3,
    '24': 13.3,
    '25': 13.2,
    '26': 13.2,
    '27': 13.1,
    '28': 13.1,
    '29': 13,
    '30': 13,
    '31': 12.9,
    '32': 12.9,
    '33': 12.8,
    '34': 12.8,
    '35': 12.7,
    '36': 12.7,
    '37': 12.6,
    '38': 12.6,
    '39': 12.5,
    '40': 12.5,
    '41': 12.4,
    '42': 12.4,
    '43': 12.3,
    '44': 12.3,
    '45': 12.2,
    '46': 12.2,
    '47': 12.1,
    '48': 12.1,
    '49': 12,
    '50': 12,
    '51': 11.9,
    '52': 11.9,
    '53': 11.8,
    '54': 11.8,
    '55': 11.7,
    '56': 11.7,
    '57': 11.6,
    '58': 11.6,
    '59': 11.5,
    '60': 11.5,
    '61': 11.5,
    '62': 11.4,
    '63': 11.4,
    '64': 11.3,
    '65': 11.3,
    '66': 11.2,
    '67': 11.2,
    '68': 11.1,
    '69': 11.1,
    '70': 11,
    '71': 11,
    '72': 10.9,
    '73': 10.9,
    '74': 10.8,
    '75': 10.8,
    '76': 10.7,
    '77': 10.7,
    '78': 10.6,
    '79': 10.6,
    '80': 10.6,
    '81': 10.5,
    '82': 10.5,
    '83': 10.4,
    '84': 10.4,
    '85': 10.3,
    '86': 10.3,
    '87': 10.2,
    '88': 10.2,
    '89': 10.1,
    '90': 10.1,
    '91': 10,
    '92': 10,
    '93': 9.9,
    '94': 9.9,
    '95': 9.9,
    '96': 9.8,
    '97': 9.8,
    '98': 9.7,
    '99': 9.7,
    '100': 9.6,
    '101': 9.6,
    '102': 9.5,
    '103': 9.5,
    '104': 9.4,
    '105': 9.4,
    '106': 9.3,
    '107': 9.3,
    '108': 9.3,
    '109': 9.2,
    '110': 9.2,
    '111': 9.1,
    '112': 9.1,
    '113': 9,
    '114': 9,
    '115': 8.9,
    '116': 8.9,
    '117': 8.8,
    '118': 8.8,
    '119': 8.8,
    '120': 8.7,
    '121': 8.7,
    '122': 8.6,
    '123': 8.6,
    '124': 8.5,
    '125': 8.5,
    '126': 8.4,
    '127': 8.4,
    '128': 8.4,
    '129': 8.3,
    '130': 8.3,
    '131': 8.2,
    '132': 8.2,
    '133': 8.1,
    '134': 8.1,
    '135': 8,
    '136': 8,
    '137': 8,
    '138': 7.9,
    '139': 7.9,
    '140': 7.8,
    '141': 7.8,
    '142': 7.7,
    '143': 7.7,
    '144': 7.7,
    '145': 7.6,
    '146': 7.6,
    '147': 7.5,
    '148': 7.5,
    '149': 7.4,
    '150': 7.4,
    '151': 7.3,
    '152': 7.3,
    '153': 7.3,
    '154': 7.2,
    '155': 7.2,
    '156': 7.1,
    '157': 7.1,
    '158': 7,
    '159': 7,
    '160': 7,
    '161': 6.9,
    '162': 6.9,
    '163': 6.8,
    '164': 6.8,
    '165': 6.7,
    '166': 6.7,
    '167': 6.7,
    '168': 6.6,
    '169': 6.6,
    '170': 6.5,
    '171': 6.5,
    '172': 6.5,
    '173': 6.4,
    '174': 6.4,
    '175': 6.3,
    '176': 6.3,
    '177': 6.2,
    '178': 6.2,
    '179': 6.2,
    '180': 6.1,
    '181': 6.1,
    '182': 6,
    '183': 6,
    '184': 5.9,
    '185': 5.9,
    '186': 5.9,
    '187': 5.8,
    '188': 5.8,
    '189': 5.7,
    '190': 5.7,
    '191': 5.7,
    '192': 5.6,
    '193': 5.6,
    '194': 5.5,
    '195': 5.5,
    '0.5': 14.5,
    '1.5': 14.4,
    '2.5': 14.4,
    '3.5': 14.3,
    '4.5': 14.3,
    '5.5': 14.2,
    '6.5': 14.2,
    '7.5': 14.1,
    '8.5': 14.1,
    '9.5': 14,
    '10.5': 14,
    '11.5': 13.9,
    '12.5': 13.9,
    '13.5': 13.8,
    '14.5': 13.7,
    '15.5': 13.7,
    '16.5': 13.6,
    '17.5': 13.6,
    '18.5': 13.5,
    '19.5': 13.5,
    '20.5': 13.4,
    '21.5': 13.4,
    '22.5': 13.3,
    '23.5': 13.3,
    '24.5': 13.2,
    '25.5': 13.2,
    '26.5': 13.1,
    '27.5': 13.1,
    '28.5': 13,
    '29.5': 13,
    '30.5': 12.9,
    '31.5': 12.9,
    '32.5': 12.8,
    '33.5': 12.8,
    '34.5': 12.7,
    '35.5': 12.7,
    '36.5': 12.6,
    '37.5': 12.6,
    '38.5': 12.5,
    '39.5': 12.5,
    '40.5': 12.4,
    '41.5': 12.4,
    '42.5': 12.4,
    '43.5': 12.3,
    '44.5': 12.3,
    '45.5': 12.2,
    '46.5': 12.2,
    '47.5': 12.1,
    '48.5': 12.1,
    '49.5': 12,
    '50.5': 12,
    '51.5': 11.9,
    '52.5': 11.9,
    '53.5': 11.8,
    '54.5': 11.8,
    '55.5': 11.7,
    '56.5': 11.7,
    '57.5': 11.6,
    '58.5': 11.6,
    '59.5': 11.5,
    '60.5': 11.5,
    '61.5': 11.4,
    '62.5': 11.4,
    '63.5': 11.3,
    '64.5': 11.3,
    '65.5': 11.2,
    '66.5': 11.2,
    '67.5': 11.1,
    '68.5': 11.1,
    '69.5': 11,
    '70.5': 11,
    '71.5': 11,
    '72.5': 10.9,
    '73.5': 10.9,
    '74.5': 10.8,
    '75.5': 10.8,
    '76.5': 10.7,
    '77.5': 10.7,
    '78.5': 10.6,
    '79.5': 10.6,
    '80.5': 10.5,
    '81.5': 10.5,
    '82.5': 10.4,
    '83.5': 10.4,
    '84.5': 10.3,
    '85.5': 10.3,
    '86.5': 10.2,
    '87.5': 10.2,
    '88.5': 10.2,
    '89.5': 10.1,
    '90.5': 10.1,
    '91.5': 10,
    '92.5': 10,
    '93.5': 9.9,
    '94.5': 9.9,
    '95.5': 9.8,
    '96.5': 9.8,
    '97.5': 9.7,
    '98.5': 9.7,
    '99.5': 9.6,
    '100.5': 9.6,
    '101.5': 9.6,
    '102.5': 9.5,
    '103.5': 9.5,
    '104.5': 9.4,
    '105.5': 9.4,
    '106.5': 9.3,
    '107.5': 9.3,
    '108.5': 9.2,
    '109.5': 9.2,
    '110.5': 9.1,
    '111.5': 9.1,
    '112.5': 9.1,
    '113.5': 9,
    '114.5': 9,
    '115.5': 8.9,
    '116.5': 8.9,
    '117.5': 8.8,
    '118.5': 8.8,
    '119.5': 8.7,
    '120.5': 8.7,
    '121.5': 8.6,
    '122.5': 8.6,
    '123.5': 8.6,
    '124.5': 8.5,
    '125.5': 8.5,
    '126.5': 8.4,
    '127.5': 8.4,
    '128.5': 8.3,
    '129.5': 8.3,
    '130.5': 8.2,
    '131.5': 8.2,
    '132.5': 8.2,
    '133.5': 8.1,
    '134.5': 8.1,
    '135.5': 8,
    '136.5': 8,
    '137.5': 7.9,
    '138.5': 7.9,
    '139.5': 7.9,
    '140.5': 7.8,
    '141.5': 7.8,
    '142.5': 7.7,
    '143.5': 7.7,
    '144.5': 7.6,
    '145.5': 7.6,
    '146.5': 7.5,
    '147.5': 7.5,
    '148.5': 7.5,
    '149.5': 7.4,
    '150.5': 7.4,
    '151.5': 7.3,
    '152.5': 7.3,
    '153.5': 7.2,
    '154.5': 7.2,
    '155.5': 7.2,
    '156.5': 7.1,
    '157.5': 7.1,
    '158.5': 7,
    '159.5': 7,
    '160.5': 6.9,
    '161.5': 6.9,
    '162.5': 6.9,
    '163.5': 6.8,
    '164.5': 6.8,
    '165.5': 6.7,
    '166.5': 6.7,
    '167.5': 6.6,
    '168.5': 6.6,
    '169.5': 6.6,
    '170.5': 6.5,
    '171.5': 6.5,
    '172.5': 6.4,
    '173.5': 6.4,
    '174.5': 6.3,
    '175.5': 6.3,
    '176.5': 6.3,
    '177.5': 6.2,
    '178.5': 6.2,
    '179.5': 6.1,
    '180.5': 6.1,
    '181.5': 6.1,
    '182.5': 6,
    '183.5': 6,
    '184.5': 5.9,
    '185.5': 5.9,
    '186.5': 5.8,
    '187.5': 5.8,
    '188.5': 5.8,
    '189.5': 5.7,
    '190.5': 5.7,
    '191.5': 5.6,
    '192.5': 5.6,
    '193.5': 5.6,
    '194.5': 5.5,
  },
  '12': {
    '0': 15,
    '1': 15,
    '2': 14.9,
    '3': 14.9,
    '4': 14.8,
    '5': 14.8,
    '6': 14.7,
    '7': 14.7,
    '8': 14.6,
    '9': 14.6,
    '10': 14.5,
    '11': 14.5,
    '12': 14.4,
    '13': 14.3,
    '14': 14.3,
    '15': 14.2,
    '16': 14.2,
    '17': 14.1,
    '18': 14.1,
    '19': 14,
    '20': 14,
    '21': 13.9,
    '22': 13.9,
    '23': 13.8,
    '24': 13.8,
    '25': 13.7,
    '26': 13.7,
    '27': 13.6,
    '28': 13.6,
    '29': 13.5,
    '30': 13.5,
    '31': 13.4,
    '32': 13.4,
    '33': 13.3,
    '34': 13.3,
    '35': 13.2,
    '36': 13.2,
    '37': 13.1,
    '38': 13.1,
    '39': 13,
    '40': 13,
    '41': 12.9,
    '42': 12.9,
    '43': 12.8,
    '44': 12.8,
    '45': 12.7,
    '46': 12.7,
    '47': 12.6,
    '48': 12.6,
    '49': 12.5,
    '50': 12.5,
    '51': 12.4,
    '52': 12.4,
    '53': 12.3,
    '54': 12.3,
    '55': 12.2,
    '56': 12.2,
    '57': 12.1,
    '58': 12.1,
    '59': 12,
    '60': 12,
    '61': 12,
    '62': 11.9,
    '63': 11.9,
    '64': 11.8,
    '65': 11.8,
    '66': 11.7,
    '67': 11.7,
    '68': 11.6,
    '69': 11.6,
    '70': 11.5,
    '71': 11.5,
    '72': 11.4,
    '73': 11.4,
    '74': 11.3,
    '75': 11.3,
    '76': 11.2,
    '77': 11.2,
    '78': 11.1,
    '79': 11.1,
    '80': 11,
    '81': 11,
    '82': 10.9,
    '83': 10.9,
    '84': 10.9,
    '85': 10.8,
    '86': 10.8,
    '87': 10.7,
    '88': 10.7,
    '89': 10.6,
    '90': 10.6,
    '91': 10.5,
    '92': 10.5,
    '93': 10.4,
    '94': 10.4,
    '95': 10.3,
    '96': 10.3,
    '97': 10.2,
    '98': 10.2,
    '99': 10.1,
    '100': 10.1,
    '101': 10.1,
    '102': 10,
    '103': 10,
    '104': 9.9,
    '105': 9.9,
    '106': 9.8,
    '107': 9.8,
    '108': 9.7,
    '109': 9.7,
    '110': 9.6,
    '111': 9.6,
    '112': 9.5,
    '113': 9.5,
    '114': 9.5,
    '115': 9.4,
    '116': 9.4,
    '117': 9.3,
    '118': 9.3,
    '119': 9.2,
    '120': 9.2,
    '121': 9.1,
    '122': 9.1,
    '123': 9,
    '124': 9,
    '125': 9,
    '126': 8.9,
    '127': 8.9,
    '128': 8.8,
    '129': 8.8,
    '130': 8.7,
    '131': 8.7,
    '132': 8.6,
    '133': 8.6,
    '134': 8.6,
    '135': 8.5,
    '136': 8.5,
    '137': 8.4,
    '138': 8.4,
    '139': 8.3,
    '140': 8.3,
    '141': 8.2,
    '142': 8.2,
    '143': 8.2,
    '144': 8.1,
    '145': 8.1,
    '146': 8,
    '147': 8,
    '148': 7.9,
    '149': 7.9,
    '150': 7.8,
    '151': 7.8,
    '152': 7.8,
    '153': 7.7,
    '154': 7.7,
    '155': 7.6,
    '156': 7.6,
    '157': 7.5,
    '158': 7.5,
    '159': 7.5,
    '160': 7.4,
    '161': 7.4,
    '162': 7.3,
    '163': 7.3,
    '164': 7.2,
    '165': 7.2,
    '166': 7.2,
    '167': 7.1,
    '168': 7.1,
    '169': 7,
    '170': 7,
    '171': 6.9,
    '172': 6.9,
    '173': 6.9,
    '174': 6.8,
    '175': 6.8,
    '176': 6.7,
    '177': 6.7,
    '178': 6.6,
    '179': 6.6,
    '180': 6.6,
    '181': 6.5,
    '182': 6.5,
    '183': 6.4,
    '184': 6.4,
    '185': 6.4,
    '186': 6.3,
    '187': 6.3,
    '188': 6.2,
    '189': 6.2,
    '190': 6.1,
    '191': 6.1,
    '192': 6.1,
    '193': 6,
    '194': 6,
    '195': 5.9,
    '0.5': 15,
    '1.5': 14.9,
    '2.5': 14.9,
    '3.5': 14.8,
    '4.5': 14.8,
    '5.5': 14.7,
    '6.5': 14.7,
    '7.5': 14.6,
    '8.5': 14.6,
    '9.5': 14.5,
    '10.5': 14.5,
    '11.5': 14.4,
    '12.5': 14.4,
    '13.5': 14.3,
    '14.5': 14.3,
    '15.5': 14.2,
    '16.5': 14.2,
    '17.5': 14.1,
    '18.5': 14.1,
    '19.5': 14,
    '20.5': 14,
    '21.5': 13.9,
    '22.5': 13.9,
    '23.5': 13.8,
    '24.5': 13.8,
    '25.5': 13.7,
    '26.5': 13.7,
    '27.5': 13.6,
    '28.5': 13.6,
    '29.5': 13.5,
    '30.5': 13.5,
    '31.5': 13.4,
    '32.5': 13.4,
    '33.5': 13.3,
    '34.5': 13.3,
    '35.5': 13.2,
    '36.5': 13.2,
    '37.5': 13.1,
    '38.5': 13.1,
    '39.5': 13,
    '40.5': 13,
    '41.5': 12.9,
    '42.5': 12.9,
    '43.5': 12.8,
    '44.5': 12.8,
    '45.5': 12.7,
    '46.5': 12.7,
    '47.5': 12.6,
    '48.5': 12.6,
    '49.5': 12.5,
    '50.5': 12.5,
    '51.5': 12.4,
    '52.5': 12.4,
    '53.5': 12.3,
    '54.5': 12.3,
    '55.5': 12.2,
    '56.5': 12.2,
    '57.5': 12.1,
    '58.5': 12.1,
    '59.5': 12,
    '60.5': 12,
    '61.5': 11.9,
    '62.5': 11.9,
    '63.5': 11.8,
    '64.5': 11.8,
    '65.5': 11.7,
    '66.5': 11.7,
    '67.5': 11.6,
    '68.5': 11.6,
    '69.5': 11.5,
    '70.5': 11.5,
    '71.5': 11.4,
    '72.5': 11.4,
    '73.5': 11.3,
    '74.5': 11.3,
    '75.5': 11.3,
    '76.5': 11.2,
    '77.5': 11.2,
    '78.5': 11.1,
    '79.5': 11.1,
    '80.5': 11,
    '81.5': 11,
    '82.5': 10.9,
    '83.5': 10.9,
    '84.5': 10.8,
    '85.5': 10.8,
    '86.5': 10.7,
    '87.5': 10.7,
    '88.5': 10.6,
    '89.5': 10.6,
    '90.5': 10.5,
    '91.5': 10.5,
    '92.5': 10.5,
    '93.5': 10.4,
    '94.5': 10.4,
    '95.5': 10.3,
    '96.5': 10.3,
    '97.5': 10.2,
    '98.5': 10.2,
    '99.5': 10.1,
    '100.5': 10.1,
    '101.5': 10,
    '102.5': 10,
    '103.5': 9.9,
    '104.5': 9.9,
    '105.5': 9.8,
    '106.5': 9.8,
    '107.5': 9.8,
    '108.5': 9.7,
    '109.5': 9.7,
    '110.5': 9.6,
    '111.5': 9.6,
    '112.5': 9.5,
    '113.5': 9.5,
    '114.5': 9.4,
    '115.5': 9.4,
    '116.5': 9.3,
    '117.5': 9.3,
    '118.5': 9.3,
    '119.5': 9.2,
    '120.5': 9.2,
    '121.5': 9.1,
    '122.5': 9.1,
    '123.5': 9,
    '124.5': 9,
    '125.5': 8.9,
    '126.5': 8.9,
    '127.5': 8.8,
    '128.5': 8.8,
    '129.5': 8.8,
    '130.5': 8.7,
    '131.5': 8.7,
    '132.5': 8.6,
    '133.5': 8.6,
    '134.5': 8.5,
    '135.5': 8.5,
    '136.5': 8.4,
    '137.5': 8.4,
    '138.5': 8.4,
    '139.5': 8.3,
    '140.5': 8.3,
    '141.5': 8.2,
    '142.5': 8.2,
    '143.5': 8.1,
    '144.5': 8.1,
    '145.5': 8,
    '146.5': 8,
    '147.5': 8,
    '148.5': 7.9,
    '149.5': 7.9,
    '150.5': 7.8,
    '151.5': 7.8,
    '152.5': 7.7,
    '153.5': 7.7,
    '154.5': 7.7,
    '155.5': 7.6,
    '156.5': 7.6,
    '157.5': 7.5,
    '158.5': 7.5,
    '159.5': 7.4,
    '160.5': 7.4,
    '161.5': 7.4,
    '162.5': 7.3,
    '163.5': 7.3,
    '164.5': 7.2,
    '165.5': 7.2,
    '166.5': 7.1,
    '167.5': 7.1,
    '168.5': 7.1,
    '169.5': 7,
    '170.5': 7,
    '171.5': 6.9,
    '172.5': 6.9,
    '173.5': 6.8,
    '174.5': 6.8,
    '175.5': 6.8,
    '176.5': 6.7,
    '177.5': 6.7,
    '178.5': 6.6,
    '179.5': 6.6,
    '180.5': 6.5,
    '181.5': 6.5,
    '182.5': 6.5,
    '183.5': 6.4,
    '184.5': 6.4,
    '185.5': 6.3,
    '186.5': 6.3,
    '187.5': 6.2,
    '188.5': 6.2,
    '189.5': 6.2,
    '190.5': 6.1,
    '191.5': 6.1,
    '192.5': 6,
    '193.5': 6,
    '194.5': 6,
  },
  '12.5': {
    '0': 15.6,
    '1': 15.5,
    '2': 15.5,
    '3': 15.4,
    '4': 15.3,
    '5': 15.3,
    '6': 15.2,
    '7': 15.2,
    '8': 15.1,
    '9': 15.1,
    '10': 15,
    '11': 15,
    '12': 14.9,
    '13': 14.9,
    '14': 14.8,
    '15': 14.8,
    '16': 14.7,
    '17': 14.7,
    '18': 14.6,
    '19': 14.6,
    '20': 14.5,
    '21': 14.5,
    '22': 14.4,
    '23': 14.4,
    '24': 14.3,
    '25': 14.3,
    '26': 14.2,
    '27': 14.2,
    '28': 14.1,
    '29': 14.1,
    '30': 14,
    '31': 13.9,
    '32': 13.9,
    '33': 13.8,
    '34': 13.8,
    '35': 13.7,
    '36': 13.7,
    '37': 13.6,
    '38': 13.6,
    '39': 13.5,
    '40': 13.5,
    '41': 13.4,
    '42': 13.4,
    '43': 13.3,
    '44': 13.3,
    '45': 13.2,
    '46': 13.2,
    '47': 13.1,
    '48': 13.1,
    '49': 13,
    '50': 13,
    '51': 12.9,
    '52': 12.9,
    '53': 12.8,
    '54': 12.8,
    '55': 12.7,
    '56': 12.7,
    '57': 12.6,
    '58': 12.6,
    '59': 12.5,
    '60': 12.5,
    '61': 12.4,
    '62': 12.4,
    '63': 12.4,
    '64': 12.3,
    '65': 12.3,
    '66': 12.2,
    '67': 12.2,
    '68': 12.1,
    '69': 12.1,
    '70': 12,
    '71': 12,
    '72': 11.9,
    '73': 11.9,
    '74': 11.8,
    '75': 11.8,
    '76': 11.7,
    '77': 11.7,
    '78': 11.6,
    '79': 11.6,
    '80': 11.5,
    '81': 11.5,
    '82': 11.4,
    '83': 11.4,
    '84': 11.3,
    '85': 11.3,
    '86': 11.2,
    '87': 11.2,
    '88': 11.1,
    '89': 11.1,
    '90': 11.1,
    '91': 11,
    '92': 11,
    '93': 10.9,
    '94': 10.9,
    '95': 10.8,
    '96': 10.8,
    '97': 10.7,
    '98': 10.7,
    '99': 10.6,
    '100': 10.6,
    '101': 10.5,
    '102': 10.5,
    '103': 10.4,
    '104': 10.4,
    '105': 10.3,
    '106': 10.3,
    '107': 10.3,
    '108': 10.2,
    '109': 10.2,
    '110': 10.1,
    '111': 10.1,
    '112': 10,
    '113': 10,
    '114': 9.9,
    '115': 9.9,
    '116': 9.8,
    '117': 9.8,
    '118': 9.7,
    '119': 9.7,
    '120': 9.7,
    '121': 9.6,
    '122': 9.6,
    '123': 9.5,
    '124': 9.5,
    '125': 9.4,
    '126': 9.4,
    '127': 9.3,
    '128': 9.3,
    '129': 9.2,
    '130': 9.2,
    '131': 9.2,
    '132': 9.1,
    '133': 9.1,
    '134': 9,
    '135': 9,
    '136': 8.9,
    '137': 8.9,
    '138': 8.8,
    '139': 8.8,
    '140': 8.8,
    '141': 8.7,
    '142': 8.7,
    '143': 8.6,
    '144': 8.6,
    '145': 8.5,
    '146': 8.5,
    '147': 8.4,
    '148': 8.4,
    '149': 8.4,
    '150': 8.3,
    '151': 8.3,
    '152': 8.2,
    '153': 8.2,
    '154': 8.1,
    '155': 8.1,
    '156': 8,
    '157': 8,
    '158': 8,
    '159': 7.9,
    '160': 7.9,
    '161': 7.8,
    '162': 7.8,
    '163': 7.7,
    '164': 7.7,
    '165': 7.7,
    '166': 7.6,
    '167': 7.6,
    '168': 7.5,
    '169': 7.5,
    '170': 7.4,
    '171': 7.4,
    '172': 7.3,
    '173': 7.3,
    '174': 7.3,
    '175': 7.2,
    '176': 7.2,
    '177': 7.1,
    '178': 7.1,
    '179': 7,
    '180': 7,
    '181': 7,
    '182': 6.9,
    '183': 6.9,
    '184': 6.8,
    '185': 6.8,
    '186': 6.8,
    '187': 6.7,
    '188': 6.7,
    '189': 6.6,
    '190': 6.6,
    '191': 6.5,
    '192': 6.5,
    '193': 6.5,
    '194': 6.4,
    '195': 6.4,
    '0.5': 15.5,
    '1.5': 15.5,
    '2.5': 15.4,
    '3.5': 15.4,
    '4.5': 15.3,
    '5.5': 15.3,
    '6.5': 15.2,
    '7.5': 15.2,
    '8.5': 15.1,
    '9.5': 15.1,
    '10.5': 15,
    '11.5': 15,
    '12.5': 14.9,
    '13.5': 14.8,
    '14.5': 14.8,
    '15.5': 14.7,
    '16.5': 14.7,
    '17.5': 14.6,
    '18.5': 14.6,
    '19.5': 14.5,
    '20.5': 14.5,
    '21.5': 14.4,
    '22.5': 14.4,
    '23.5': 14.3,
    '24.5': 14.3,
    '25.5': 14.2,
    '26.5': 14.2,
    '27.5': 14.1,
    '28.5': 14.1,
    '29.5': 14,
    '30.5': 14,
    '31.5': 13.9,
    '32.5': 13.9,
    '33.5': 13.8,
    '34.5': 13.8,
    '35.5': 13.7,
    '36.5': 13.7,
    '37.5': 13.6,
    '38.5': 13.6,
    '39.5': 13.5,
    '40.5': 13.5,
    '41.5': 13.4,
    '42.5': 13.4,
    '43.5': 13.3,
    '44.5': 13.3,
    '45.5': 13.2,
    '46.5': 13.2,
    '47.5': 13.1,
    '48.5': 13.1,
    '49.5': 13,
    '50.5': 13,
    '51.5': 12.9,
    '52.5': 12.9,
    '53.5': 12.8,
    '54.5': 12.8,
    '55.5': 12.7,
    '56.5': 12.7,
    '57.5': 12.6,
    '58.5': 12.6,
    '59.5': 12.5,
    '60.5': 12.5,
    '61.5': 12.4,
    '62.5': 12.4,
    '63.5': 12.3,
    '64.5': 12.3,
    '65.5': 12.2,
    '66.5': 12.2,
    '67.5': 12.1,
    '68.5': 12.1,
    '69.5': 12,
    '70.5': 12,
    '71.5': 11.9,
    '72.5': 11.9,
    '73.5': 11.8,
    '74.5': 11.8,
    '75.5': 11.7,
    '76.5': 11.7,
    '77.5': 11.6,
    '78.5': 11.6,
    '79.5': 11.6,
    '80.5': 11.5,
    '81.5': 11.5,
    '82.5': 11.4,
    '83.5': 11.4,
    '84.5': 11.3,
    '85.5': 11.3,
    '86.5': 11.2,
    '87.5': 11.2,
    '88.5': 11.1,
    '89.5': 11.1,
    '90.5': 11,
    '91.5': 11,
    '92.5': 10.9,
    '93.5': 10.9,
    '94.5': 10.8,
    '95.5': 10.8,
    '96.5': 10.7,
    '97.5': 10.7,
    '98.5': 10.7,
    '99.5': 10.6,
    '100.5': 10.6,
    '101.5': 10.5,
    '102.5': 10.5,
    '103.5': 10.4,
    '104.5': 10.4,
    '105.5': 10.3,
    '106.5': 10.3,
    '107.5': 10.2,
    '108.5': 10.2,
    '109.5': 10.1,
    '110.5': 10.1,
    '111.5': 10,
    '112.5': 10,
    '113.5': 10,
    '114.5': 9.9,
    '115.5': 9.9,
    '116.5': 9.8,
    '117.5': 9.8,
    '118.5': 9.7,
    '119.5': 9.7,
    '120.5': 9.6,
    '121.5': 9.6,
    '122.5': 9.5,
    '123.5': 9.5,
    '124.5': 9.5,
    '125.5': 9.4,
    '126.5': 9.4,
    '127.5': 9.3,
    '128.5': 9.3,
    '129.5': 9.2,
    '130.5': 9.2,
    '131.5': 9.1,
    '132.5': 9.1,
    '133.5': 9,
    '134.5': 9,
    '135.5': 9,
    '136.5': 8.9,
    '137.5': 8.9,
    '138.5': 8.8,
    '139.5': 8.8,
    '140.5': 8.7,
    '141.5': 8.7,
    '142.5': 8.6,
    '143.5': 8.6,
    '144.5': 8.6,
    '145.5': 8.5,
    '146.5': 8.5,
    '147.5': 8.4,
    '148.5': 8.4,
    '149.5': 8.3,
    '150.5': 8.3,
    '151.5': 8.2,
    '152.5': 8.2,
    '153.5': 8.2,
    '154.5': 8.1,
    '155.5': 8.1,
    '156.5': 8,
    '157.5': 8,
    '158.5': 7.9,
    '159.5': 7.9,
    '160.5': 7.8,
    '161.5': 7.8,
    '162.5': 7.8,
    '163.5': 7.7,
    '164.5': 7.7,
    '165.5': 7.6,
    '166.5': 7.6,
    '167.5': 7.5,
    '168.5': 7.5,
    '169.5': 7.5,
    '170.5': 7.4,
    '171.5': 7.4,
    '172.5': 7.3,
    '173.5': 7.3,
    '174.5': 7.2,
    '175.5': 7.2,
    '176.5': 7.2,
    '177.5': 7.1,
    '178.5': 7.1,
    '179.5': 7,
    '180.5': 7,
    '181.5': 6.9,
    '182.5': 6.9,
    '183.5': 6.9,
    '184.5': 6.8,
    '185.5': 6.8,
    '186.5': 6.7,
    '187.5': 6.7,
    '188.5': 6.6,
    '189.5': 6.6,
    '190.5': 6.6,
    '191.5': 6.5,
    '192.5': 6.5,
    '193.5': 6.4,
    '194.5': 6.4,
  },
  '13': {
    '0': 16.1,
    '1': 16,
    '2': 16,
    '3': 15.9,
    '4': 15.9,
    '5': 15.8,
    '6': 15.8,
    '7': 15.7,
    '8': 15.7,
    '9': 15.6,
    '10': 15.6,
    '11': 15.5,
    '12': 15.5,
    '13': 15.4,
    '14': 15.3,
    '15': 15.3,
    '16': 15.2,
    '17': 15.2,
    '18': 15.1,
    '19': 15.1,
    '20': 15,
    '21': 15,
    '22': 14.9,
    '23': 14.9,
    '24': 14.8,
    '25': 14.8,
    '26': 14.7,
    '27': 14.7,
    '28': 14.6,
    '29': 14.6,
    '30': 14.5,
    '31': 14.5,
    '32': 14.4,
    '33': 14.4,
    '34': 14.3,
    '35': 14.3,
    '36': 14.2,
    '37': 14.2,
    '38': 14.1,
    '39': 14.1,
    '40': 14,
    '41': 14,
    '42': 13.9,
    '43': 13.9,
    '44': 13.8,
    '45': 13.8,
    '46': 13.7,
    '47': 13.7,
    '48': 13.6,
    '49': 13.5,
    '50': 13.5,
    '51': 13.4,
    '52': 13.4,
    '53': 13.3,
    '54': 13.3,
    '55': 13.3,
    '56': 13.2,
    '57': 13.1,
    '58': 13.1,
    '59': 13.1,
    '60': 13,
    '61': 12.9,
    '62': 12.9,
    '63': 12.9,
    '64': 12.8,
    '65': 12.8,
    '66': 12.7,
    '67': 12.7,
    '68': 12.6,
    '69': 12.6,
    '70': 12.5,
    '71': 12.5,
    '72': 12.4,
    '73': 12.4,
    '74': 12.3,
    '75': 12.3,
    '76': 12.2,
    '77': 12.2,
    '78': 12.1,
    '79': 12.1,
    '80': 12,
    '81': 12,
    '82': 11.9,
    '83': 11.9,
    '84': 11.8,
    '85': 11.8,
    '86': 11.7,
    '87': 11.7,
    '88': 11.6,
    '89': 11.6,
    '90': 11.5,
    '91': 11.5,
    '92': 11.4,
    '93': 11.4,
    '94': 11.3,
    '95': 11.3,
    '96': 11.2,
    '97': 11.2,
    '98': 11.1,
    '99': 11.1,
    '100': 11,
    '101': 11,
    '102': 10.9,
    '103': 10.9,
    '104': 10.9,
    '105': 10.8,
    '106': 10.8,
    '107': 10.7,
    '108': 10.7,
    '109': 10.6,
    '110': 10.6,
    '111': 10.5,
    '112': 10.5,
    '113': 10.5,
    '114': 10.4,
    '115': 10.4,
    '116': 10.3,
    '117': 10.3,
    '118': 10.2,
    '119': 10.2,
    '120': 10.1,
    '121': 10.1,
    '122': 10,
    '123': 10,
    '124': 9.9,
    '125': 9.9,
    '126': 9.9,
    '127': 9.8,
    '128': 9.8,
    '129': 9.7,
    '130': 9.7,
    '131': 9.6,
    '132': 9.6,
    '133': 9.5,
    '134': 9.5,
    '135': 9.4,
    '136': 9.4,
    '137': 9.3,
    '138': 9.3,
    '139': 9.3,
    '140': 9.2,
    '141': 9.2,
    '142': 9.1,
    '143': 9.1,
    '144': 9,
    '145': 9,
    '146': 8.9,
    '147': 8.9,
    '148': 8.9,
    '149': 8.8,
    '150': 8.8,
    '151': 8.7,
    '152': 8.7,
    '153': 8.6,
    '154': 8.6,
    '155': 8.5,
    '156': 8.5,
    '157': 8.5,
    '158': 8.4,
    '159': 8.4,
    '160': 8.3,
    '161': 8.3,
    '162': 8.2,
    '163': 8.2,
    '164': 8.1,
    '165': 8.1,
    '166': 8.1,
    '167': 8,
    '168': 8,
    '169': 7.9,
    '170': 7.9,
    '171': 7.8,
    '172': 7.8,
    '173': 7.8,
    '174': 7.7,
    '175': 7.7,
    '176': 7.6,
    '177': 7.6,
    '178': 7.5,
    '179': 7.5,
    '180': 7.5,
    '181': 7.4,
    '182': 7.4,
    '183': 7.3,
    '184': 7.3,
    '185': 7.2,
    '186': 7.2,
    '187': 7.1,
    '188': 7.1,
    '189': 7.1,
    '190': 7,
    '191': 7,
    '192': 6.9,
    '193': 6.9,
    '194': 6.9,
    '195': 6.8,
    '0.5': 16.1,
    '1.5': 16,
    '2.5': 16,
    '3.5': 15.9,
    '4.5': 15.9,
    '5.5': 15.8,
    '6.5': 15.7,
    '7.5': 15.7,
    '8.5': 15.6,
    '9.5': 15.6,
    '10.5': 15.5,
    '11.5': 15.5,
    '12.5': 15.4,
    '13.5': 15.4,
    '14.5': 15.3,
    '15.5': 15.3,
    '16.5': 15.2,
    '17.5': 15.2,
    '18.5': 15.1,
    '19.5': 15.1,
    '20.5': 15,
    '21.5': 15,
    '22.5': 14.9,
    '23.5': 14.9,
    '24.5': 14.8,
    '25.5': 14.8,
    '26.5': 14.7,
    '27.5': 14.6,
    '28.5': 14.6,
    '29.5': 14.5,
    '30.5': 14.5,
    '31.5': 14.4,
    '32.5': 14.4,
    '33.5': 14.3,
    '34.5': 14.3,
    '35.5': 14.2,
    '36.5': 14.2,
    '37.5': 14.1,
    '38.5': 14.1,
    '39.5': 14,
    '40.5': 14,
    '41.5': 13.9,
    '42.5': 13.9,
    '43.5': 13.8,
    '44.5': 13.8,
    '45.5': 13.7,
    '46.5': 13.7,
    '47.5': 13.6,
    '48.5': 13.6,
    '49.5': 13.5,
    '50.5': 13.5,
    '51.5': 13.4,
    '52.5': 13.4,
    '53.5': 13.3,
    '54.5': 13.3,
    '55.5': 13.2,
    '56.5': 13.2,
    '57.5': 13.1,
    '58.5': 13.1,
    '59.5': 13,
    '60.5': 13,
    '61.5': 12.9,
    '62.5': 12.9,
    '63.5': 12.8,
    '64.5': 12.8,
    '65.5': 12.7,
    '66.5': 12.7,
    '67.5': 12.6,
    '68.5': 12.6,
    '69.5': 12.5,
    '70.5': 12.5,
    '71.5': 12.4,
    '72.5': 12.4,
    '73.5': 12.3,
    '74.5': 12.3,
    '75.5': 12.2,
    '76.5': 12.2,
    '77.5': 12.1,
    '78.5': 12.1,
    '79.5': 12,
    '80.5': 12,
    '81.5': 11.9,
    '82.5': 11.9,
    '83.5': 11.8,
    '84.5': 11.8,
    '85.5': 11.8,
    '86.5': 11.7,
    '87.5': 11.7,
    '88.5': 11.6,
    '89.5': 11.6,
    '90.5': 11.5,
    '91.5': 11.4,
    '92.5': 11.4,
    '93.5': 11.3,
    '94.5': 11.3,
    '95.5': 11.3,
    '96.5': 11.2,
    '97.5': 11.2,
    '98.5': 11.1,
    '99.5': 11.1,
    '100.5': 11,
    '101.5': 11,
    '102.5': 10.9,
    '103.5': 10.9,
    '104.5': 10.8,
    '105.5': 10.8,
    '106.5': 10.8,
    '107.5': 10.7,
    '108.5': 10.7,
    '109.5': 10.6,
    '110.5': 10.6,
    '111.5': 10.5,
    '112.5': 10.5,
    '113.5': 10.4,
    '114.5': 10.4,
    '115.5': 10.3,
    '116.5': 10.3,
    '117.5': 10.2,
    '118.5': 10.2,
    '119.5': 10.1,
    '120.5': 10.1,
    '121.5': 10.1,
    '122.5': 10,
    '123.5': 10,
    '124.5': 9.9,
    '125.5': 9.9,
    '126.5': 9.8,
    '127.5': 9.8,
    '128.5': 9.7,
    '129.5': 9.7,
    '130.5': 9.6,
    '131.5': 9.6,
    '132.5': 9.6,
    '133.5': 9.5,
    '134.5': 9.5,
    '135.5': 9.4,
    '136.5': 9.4,
    '137.5': 9.3,
    '138.5': 9.3,
    '139.5': 9.2,
    '140.5': 9.2,
    '141.5': 9.1,
    '142.5': 9.1,
    '143.5': 9.1,
    '144.5': 9,
    '145.5': 9,
    '146.5': 8.9,
    '147.5': 8.9,
    '148.5': 8.8,
    '149.5': 8.8,
    '150.5': 8.7,
    '151.5': 8.7,
    '152.5': 8.7,
    '153.5': 8.6,
    '154.5': 8.6,
    '155.5': 8.5,
    '156.5': 8.5,
    '157.5': 8.4,
    '158.5': 8.4,
    '159.5': 8.3,
    '160.5': 8.3,
    '161.5': 8.3,
    '162.5': 8.2,
    '163.5': 8.2,
    '164.5': 8.1,
    '165.5': 8.1,
    '166.5': 8,
    '167.5': 8,
    '168.5': 7.9,
    '169.5': 7.9,
    '170.5': 7.9,
    '171.5': 7.8,
    '172.5': 7.8,
    '173.5': 7.7,
    '174.5': 7.7,
    '175.5': 7.6,
    '176.5': 7.6,
    '177.5': 7.6,
    '178.5': 7.5,
    '179.5': 7.5,
    '180.5': 7.4,
    '181.5': 7.4,
    '182.5': 7.3,
    '183.5': 7.3,
    '184.5': 7.3,
    '185.5': 7.2,
    '186.5': 7.2,
    '187.5': 7.1,
    '188.5': 7.1,
    '189.5': 7,
    '190.5': 7,
    '191.5': 7,
    '192.5': 6.9,
    '193.5': 6.9,
    '194.5': 6.8,
  },
  '13.5': {
    '0': 16.6,
    '1': 16.6,
    '2': 16.5,
    '3': 16.5,
    '4': 16.4,
    '5': 16.4,
    '6': 16.3,
    '7': 16.2,
    '8': 16.2,
    '9': 16.1,
    '10': 16.1,
    '11': 16,
    '12': 16,
    '13': 15.9,
    '14': 15.9,
    '15': 15.8,
    '16': 15.8,
    '17': 15.7,
    '18': 15.7,
    '19': 15.6,
    '20': 15.6,
    '21': 15.5,
    '22': 15.5,
    '23': 15.4,
    '24': 15.3,
    '25': 15.3,
    '26': 15.2,
    '27': 15.2,
    '28': 15.1,
    '29': 15.1,
    '30': 15,
    '31': 15,
    '32': 14.9,
    '33': 14.9,
    '34': 14.8,
    '35': 14.8,
    '36': 14.7,
    '37': 14.7,
    '38': 14.6,
    '39': 14.6,
    '40': 14.5,
    '41': 14.5,
    '42': 14.4,
    '43': 14.4,
    '44': 14.3,
    '45': 14.3,
    '46': 14.2,
    '47': 14.2,
    '48': 14.1,
    '49': 14.1,
    '50': 14,
    '51': 14,
    '52': 13.9,
    '53': 13.9,
    '54': 13.8,
    '55': 13.8,
    '56': 13.7,
    '57': 13.7,
    '58': 13.6,
    '59': 13.6,
    '60': 13.5,
    '61': 13.4,
    '62': 13.4,
    '63': 13.3,
    '64': 13.3,
    '65': 13.2,
    '66': 13.2,
    '67': 13.1,
    '68': 13.1,
    '69': 13.1,
    '70': 13,
    '71': 13,
    '72': 12.9,
    '73': 12.9,
    '74': 12.8,
    '75': 12.8,
    '76': 12.7,
    '77': 12.7,
    '78': 12.6,
    '79': 12.6,
    '80': 12.5,
    '81': 12.5,
    '82': 12.4,
    '83': 12.4,
    '84': 12.3,
    '85': 12.3,
    '86': 12.2,
    '87': 12.2,
    '88': 12.1,
    '89': 12.1,
    '90': 12,
    '91': 12,
    '92': 11.9,
    '93': 11.9,
    '94': 11.8,
    '95': 11.8,
    '96': 11.7,
    '97': 11.7,
    '98': 11.6,
    '99': 11.6,
    '100': 11.5,
    '101': 11.5,
    '102': 11.4,
    '103': 11.4,
    '104': 11.4,
    '105': 11.3,
    '106': 11.3,
    '107': 11.2,
    '108': 11.2,
    '109': 11.1,
    '110': 11.1,
    '111': 11,
    '112': 11,
    '113': 10.9,
    '114': 10.9,
    '115': 10.8,
    '116': 10.8,
    '117': 10.7,
    '118': 10.7,
    '119': 10.6,
    '120': 10.6,
    '121': 10.5,
    '122': 10.5,
    '123': 10.5,
    '124': 10.4,
    '125': 10.4,
    '126': 10.3,
    '127': 10.3,
    '128': 10.2,
    '129': 10.2,
    '130': 10.1,
    '131': 10.1,
    '132': 10,
    '133': 10,
    '134': 9.9,
    '135': 9.9,
    '136': 9.9,
    '137': 9.8,
    '138': 9.8,
    '139': 9.7,
    '140': 9.7,
    '141': 9.6,
    '142': 9.6,
    '143': 9.5,
    '144': 9.5,
    '145': 9.4,
    '146': 9.4,
    '147': 9.4,
    '148': 9.3,
    '149': 9.3,
    '150': 9.2,
    '151': 9.2,
    '152': 9.1,
    '153': 9.1,
    '154': 9,
    '155': 9,
    '156': 9,
    '157': 8.9,
    '158': 8.9,
    '159': 8.8,
    '160': 8.8,
    '161': 8.7,
    '162': 8.7,
    '163': 8.6,
    '164': 8.6,
    '165': 8.5,
    '166': 8.5,
    '167': 8.5,
    '168': 8.4,
    '169': 8.4,
    '170': 8.3,
    '171': 8.3,
    '172': 8.2,
    '173': 8.2,
    '174': 8.2,
    '175': 8.1,
    '176': 8.1,
    '177': 8,
    '178': 8,
    '179': 7.9,
    '180': 7.9,
    '181': 7.8,
    '182': 7.8,
    '183': 7.8,
    '184': 7.7,
    '185': 7.7,
    '186': 7.6,
    '187': 7.6,
    '188': 7.5,
    '189': 7.5,
    '190': 7.5,
    '191': 7.4,
    '192': 7.4,
    '193': 7.3,
    '194': 7.3,
    '195': 7.2,
    '0.5': 16.6,
    '1.5': 16.5,
    '2.5': 16.5,
    '3.5': 16.4,
    '4.5': 16.4,
    '5.5': 16.3,
    '6.5': 16.3,
    '7.5': 16.2,
    '8.5': 16.2,
    '9.5': 16.1,
    '10.5': 16.1,
    '11.5': 16,
    '12.5': 16,
    '13.5': 15.9,
    '14.5': 15.8,
    '15.5': 15.8,
    '16.5': 15.7,
    '17.5': 15.7,
    '18.5': 15.6,
    '19.5': 15.6,
    '20.5': 15.5,
    '21.5': 15.5,
    '22.5': 15.4,
    '23.5': 15.4,
    '24.5': 15.3,
    '25.5': 15.3,
    '26.5': 15.2,
    '27.5': 15.2,
    '28.5': 15.1,
    '29.5': 15.1,
    '30.5': 15,
    '31.5': 15,
    '32.5': 14.9,
    '33.5': 14.9,
    '34.5': 14.8,
    '35.5': 14.7,
    '36.5': 14.7,
    '37.5': 14.6,
    '38.5': 14.6,
    '39.5': 14.5,
    '40.5': 14.5,
    '41.5': 14.4,
    '42.5': 14.4,
    '43.5': 14.3,
    '44.5': 14.3,
    '45.5': 14.2,
    '46.5': 14.2,
    '47.5': 14.1,
    '48.5': 14.1,
    '49.5': 14,
    '50.5': 14,
    '51.5': 13.9,
    '52.5': 13.9,
    '53.5': 13.8,
    '54.5': 13.8,
    '55.5': 13.7,
    '56.5': 13.7,
    '57.5': 13.6,
    '58.5': 13.6,
    '59.5': 13.5,
    '60.5': 13.5,
    '61.5': 13.4,
    '62.5': 13.4,
    '63.5': 13.3,
    '64.5': 13.3,
    '65.5': 13.2,
    '66.5': 13.2,
    '67.5': 13.1,
    '68.5': 13.1,
    '69.5': 13,
    '70.5': 13,
    '71.5': 12.9,
    '72.5': 12.9,
    '73.5': 12.8,
    '74.5': 12.8,
    '75.5': 12.7,
    '76.5': 12.7,
    '77.5': 12.6,
    '78.5': 12.6,
    '79.5': 12.5,
    '80.5': 12.5,
    '81.5': 12.4,
    '82.5': 12.4,
    '83.5': 12.3,
    '84.5': 12.3,
    '85.5': 12.2,
    '86.5': 12.2,
    '87.5': 12.1,
    '88.5': 12.1,
    '89.5': 12,
    '90.5': 12,
    '91.5': 11.9,
    '92.5': 11.9,
    '93.5': 11.9,
    '94.5': 11.8,
    '95.5': 11.8,
    '96.5': 11.7,
    '97.5': 11.7,
    '98.5': 11.6,
    '99.5': 11.6,
    '100.5': 11.5,
    '101.5': 11.5,
    '102.5': 11.4,
    '103.5': 11.4,
    '104.5': 11.3,
    '105.5': 11.3,
    '106.5': 11.2,
    '107.5': 11.2,
    '108.5': 11.1,
    '109.5': 11.1,
    '110.5': 11,
    '111.5': 11,
    '112.5': 10.9,
    '113.5': 10.9,
    '114.5': 10.9,
    '115.5': 10.8,
    '116.5': 10.8,
    '117.5': 10.7,
    '118.5': 10.7,
    '119.5': 10.6,
    '120.5': 10.6,
    '121.5': 10.5,
    '122.5': 10.5,
    '123.5': 10.4,
    '124.5': 10.4,
    '125.5': 10.3,
    '126.5': 10.3,
    '127.5': 10.2,
    '128.5': 10.2,
    '129.5': 10.2,
    '130.5': 10.1,
    '131.5': 10.1,
    '132.5': 10,
    '133.5': 10,
    '134.5': 9.9,
    '135.5': 9.9,
    '136.5': 9.8,
    '137.5': 9.8,
    '138.5': 9.7,
    '139.5': 9.7,
    '140.5': 9.6,
    '141.5': 9.6,
    '142.5': 9.6,
    '143.5': 9.5,
    '144.5': 9.5,
    '145.5': 9.4,
    '146.5': 9.4,
    '147.5': 9.3,
    '148.5': 9.3,
    '149.5': 9.2,
    '150.5': 9.2,
    '151.5': 9.2,
    '152.5': 9.1,
    '153.5': 9.1,
    '154.5': 9,
    '155.5': 9,
    '156.5': 8.9,
    '157.5': 8.9,
    '158.5': 8.8,
    '159.5': 8.8,
    '160.5': 8.8,
    '161.5': 8.7,
    '162.5': 8.7,
    '163.5': 8.6,
    '164.5': 8.6,
    '165.5': 8.5,
    '166.5': 8.5,
    '167.5': 8.4,
    '168.5': 8.4,
    '169.5': 8.4,
    '170.5': 8.3,
    '171.5': 8.3,
    '172.5': 8.2,
    '173.5': 8.2,
    '174.5': 8.1,
    '175.5': 8.1,
    '176.5': 8,
    '177.5': 8,
    '178.5': 8,
    '179.5': 7.9,
    '180.5': 7.9,
    '181.5': 7.8,
    '182.5': 7.8,
    '183.5': 7.7,
    '184.5': 7.7,
    '185.5': 7.7,
    '186.5': 7.6,
    '187.5': 7.6,
    '188.5': 7.5,
    '189.5': 7.5,
    '190.5': 7.4,
    '191.5': 7.4,
    '192.5': 7.4,
    '193.5': 7.3,
    '194.5': 7.3,
  },
  '14': {
    '0': 17.2,
    '1': 17.1,
    '2': 17,
    '3': 17,
    '4': 16.9,
    '5': 16.9,
    '6': 16.8,
    '7': 16.8,
    '8': 16.7,
    '9': 16.7,
    '10': 16.6,
    '11': 16.6,
    '12': 16.5,
    '13': 16.5,
    '14': 16.4,
    '15': 16.3,
    '16': 16.3,
    '17': 16.2,
    '18': 16.2,
    '19': 16.1,
    '20': 16.1,
    '21': 16,
    '22': 16,
    '23': 15.9,
    '24': 15.9,
    '25': 15.8,
    '26': 15.8,
    '27': 15.7,
    '28': 15.7,
    '29': 15.6,
    '30': 15.5,
    '31': 15.5,
    '32': 15.4,
    '33': 15.4,
    '34': 15.3,
    '35': 15.3,
    '36': 15.2,
    '37': 15.2,
    '38': 15.1,
    '39': 15.1,
    '40': 15,
    '41': 15,
    '42': 14.9,
    '43': 14.9,
    '44': 14.8,
    '45': 14.8,
    '46': 14.7,
    '47': 14.7,
    '48': 14.6,
    '49': 14.6,
    '50': 14.5,
    '51': 14.5,
    '52': 14.4,
    '53': 14.4,
    '54': 14.3,
    '55': 14.3,
    '56': 14.2,
    '57': 14.2,
    '58': 14.1,
    '59': 14.1,
    '60': 14,
    '61': 13.9,
    '62': 13.9,
    '63': 13.8,
    '64': 13.8,
    '65': 13.7,
    '66': 13.7,
    '67': 13.6,
    '68': 13.6,
    '69': 13.5,
    '70': 13.5,
    '71': 13.4,
    '72': 13.4,
    '73': 13.3,
    '74': 13.3,
    '75': 13.2,
    '76': 13.2,
    '77': 13.1,
    '78': 13.1,
    '79': 13,
    '80': 13,
    '81': 12.9,
    '82': 12.9,
    '83': 12.8,
    '84': 12.8,
    '85': 12.8,
    '86': 12.7,
    '87': 12.7,
    '88': 12.6,
    '89': 12.6,
    '90': 12.5,
    '91': 12.5,
    '92': 12.4,
    '93': 12.4,
    '94': 12.3,
    '95': 12.3,
    '96': 12.2,
    '97': 12.2,
    '98': 12.1,
    '99': 12.1,
    '100': 12,
    '101': 12,
    '102': 11.9,
    '103': 11.9,
    '104': 11.8,
    '105': 11.8,
    '106': 11.7,
    '107': 11.7,
    '108': 11.6,
    '109': 11.6,
    '110': 11.5,
    '111': 11.5,
    '112': 11.4,
    '113': 11.4,
    '114': 11.4,
    '115': 11.3,
    '116': 11.3,
    '117': 11.2,
    '118': 11.2,
    '119': 11.1,
    '120': 11.1,
    '121': 11,
    '122': 11,
    '123': 10.9,
    '124': 10.9,
    '125': 10.8,
    '126': 10.8,
    '127': 10.7,
    '128': 10.7,
    '129': 10.6,
    '130': 10.6,
    '131': 10.6,
    '132': 10.5,
    '133': 10.5,
    '134': 10.4,
    '135': 10.4,
    '136': 10.3,
    '137': 10.3,
    '138': 10.2,
    '139': 10.2,
    '140': 10.1,
    '141': 10.1,
    '142': 10,
    '143': 10,
    '144': 10,
    '145': 9.9,
    '146': 9.9,
    '147': 9.8,
    '148': 9.8,
    '149': 9.7,
    '150': 9.7,
    '151': 9.6,
    '152': 9.6,
    '153': 9.5,
    '154': 9.5,
    '155': 9.4,
    '156': 9.4,
    '157': 9.4,
    '158': 9.3,
    '159': 9.3,
    '160': 9.2,
    '161': 9.2,
    '162': 9.1,
    '163': 9.1,
    '164': 9,
    '165': 9,
    '166': 9,
    '167': 8.9,
    '168': 8.9,
    '169': 8.8,
    '170': 8.8,
    '171': 8.7,
    '172': 8.7,
    '173': 8.6,
    '174': 8.6,
    '175': 8.6,
    '176': 8.5,
    '177': 8.5,
    '178': 8.4,
    '179': 8.4,
    '180': 8.3,
    '181': 8.3,
    '182': 8.2,
    '183': 8.2,
    '184': 8.2,
    '185': 8.1,
    '186': 8.1,
    '187': 8,
    '188': 8,
    '189': 7.9,
    '190': 7.9,
    '191': 7.9,
    '192': 7.8,
    '193': 7.8,
    '194': 7.7,
    '195': 7.7,
    '0.5': 17.1,
    '1.5': 17.1,
    '2.5': 17,
    '3.5': 17,
    '4.5': 16.9,
    '5.5': 16.9,
    '6.5': 16.8,
    '7.5': 16.7,
    '8.5': 16.7,
    '9.5': 16.6,
    '10.5': 16.6,
    '11.5': 16.5,
    '12.5': 16.5,
    '13.5': 16.4,
    '14.5': 16.4,
    '15.5': 16.3,
    '16.5': 16.3,
    '17.5': 16.2,
    '18.5': 16.2,
    '19.5': 16.1,
    '20.5': 16.1,
    '21.5': 16,
    '22.5': 15.9,
    '23.5': 15.9,
    '24.5': 15.8,
    '25.5': 15.8,
    '26.5': 15.7,
    '27.5': 15.7,
    '28.5': 15.6,
    '29.5': 15.6,
    '30.5': 15.5,
    '31.5': 15.5,
    '32.5': 15.4,
    '33.5': 15.4,
    '34.5': 15.3,
    '35.5': 15.3,
    '36.5': 15.2,
    '37.5': 15.2,
    '38.5': 15.1,
    '39.5': 15.1,
    '40.5': 15,
    '41.5': 14.9,
    '42.5': 14.9,
    '43.5': 14.8,
    '44.5': 14.8,
    '45.5': 14.7,
    '46.5': 14.7,
    '47.5': 14.6,
    '48.5': 14.6,
    '49.5': 14.5,
    '50.5': 14.5,
    '51.5': 14.4,
    '52.5': 14.4,
    '53.5': 14.3,
    '54.5': 14.3,
    '55.5': 14.2,
    '56.5': 14.2,
    '57.5': 14.1,
    '58.5': 14.1,
    '59.5': 14,
    '60.5': 14,
    '61.5': 13.9,
    '62.5': 13.9,
    '63.5': 13.8,
    '64.5': 13.8,
    '65.5': 13.7,
    '66.5': 13.7,
    '67.5': 13.6,
    '68.5': 13.6,
    '69.5': 13.5,
    '70.5': 13.5,
    '71.5': 13.4,
    '72.5': 13.4,
    '73.5': 13.3,
    '74.5': 13.3,
    '75.5': 13.2,
    '76.5': 13.2,
    '77.5': 13.1,
    '78.5': 13.1,
    '79.5': 13,
    '80.5': 13,
    '81.5': 12.9,
    '82.5': 12.9,
    '83.5': 12.8,
    '84.5': 12.8,
    '85.5': 12.7,
    '86.5': 12.7,
    '87.5': 12.6,
    '88.5': 12.6,
    '89.5': 12.5,
    '90.5': 12.5,
    '91.5': 12.4,
    '92.5': 12.4,
    '93.5': 12.3,
    '94.5': 12.3,
    '95.5': 12.2,
    '96.5': 12.2,
    '97.5': 12.1,
    '98.5': 12.1,
    '99.5': 12,
    '100.5': 12,
    '101.5': 11.9,
    '102.5': 11.9,
    '103.5': 11.9,
    '104.5': 11.8,
    '105.5': 11.8,
    '106.5': 11.7,
    '107.5': 11.7,
    '108.5': 11.6,
    '109.5': 11.6,
    '110.5': 11.5,
    '111.5': 11.5,
    '112.5': 11.4,
    '113.5': 11.4,
    '114.5': 11.3,
    '115.5': 11.3,
    '116.5': 11.2,
    '117.5': 11.2,
    '118.5': 11.1,
    '119.5': 11.1,
    '120.5': 11,
    '121.5': 11,
    '122.5': 10.9,
    '123.5': 10.9,
    '124.5': 10.9,
    '125.5': 10.8,
    '126.5': 10.8,
    '127.5': 10.7,
    '128.5': 10.7,
    '129.5': 10.6,
    '130.5': 10.6,
    '131.5': 10.5,
    '132.5': 10.5,
    '133.5': 10.4,
    '134.5': 10.4,
    '135.5': 10.3,
    '136.5': 10.3,
    '137.5': 10.2,
    '138.5': 10.2,
    '139.5': 10.2,
    '140.5': 10.1,
    '141.5': 10.1,
    '142.5': 10,
    '143.5': 10,
    '144.5': 9.9,
    '145.5': 9.9,
    '146.5': 9.8,
    '147.5': 9.8,
    '148.5': 9.7,
    '149.5': 9.7,
    '150.5': 9.7,
    '151.5': 9.6,
    '152.5': 9.6,
    '153.5': 9.5,
    '154.5': 9.5,
    '155.5': 9.4,
    '156.5': 9.4,
    '157.5': 9.3,
    '158.5': 9.3,
    '159.5': 9.2,
    '160.5': 9.2,
    '161.5': 9.2,
    '162.5': 9.1,
    '163.5': 9.1,
    '164.5': 9,
    '165.5': 9,
    '166.5': 8.9,
    '167.5': 8.9,
    '168.5': 8.8,
    '169.5': 8.8,
    '170.5': 8.8,
    '171.5': 8.7,
    '172.5': 8.7,
    '173.5': 8.6,
    '174.5': 8.6,
    '175.5': 8.5,
    '176.5': 8.5,
    '177.5': 8.4,
    '178.5': 8.4,
    '179.5': 8.4,
    '180.5': 8.3,
    '181.5': 8.3,
    '182.5': 8.2,
    '183.5': 8.2,
    '184.5': 8.1,
    '185.5': 8.1,
    '186.5': 8.1,
    '187.5': 8,
    '188.5': 8,
    '189.5': 7.9,
    '190.5': 7.9,
    '191.5': 7.8,
    '192.5': 7.8,
    '193.5': 7.7,
    '194.5': 7.7,
  },
  '14.5': {
    '0': 17.7,
    '1': 17.6,
    '2': 17.6,
    '3': 17.5,
    '4': 17.5,
    '5': 17.4,
    '6': 17.4,
    '7': 17.3,
    '8': 17.3,
    '9': 17.2,
    '10': 17.1,
    '11': 17.1,
    '12': 17,
    '13': 17,
    '14': 16.9,
    '15': 16.9,
    '16': 16.8,
    '17': 16.8,
    '18': 16.7,
    '19': 16.7,
    '20': 16.6,
    '21': 16.5,
    '22': 16.5,
    '23': 16.4,
    '24': 16.4,
    '25': 16.3,
    '26': 16.3,
    '27': 16.2,
    '28': 16.2,
    '29': 16.1,
    '30': 16.1,
    '31': 16,
    '32': 16,
    '33': 15.9,
    '34': 15.9,
    '35': 15.8,
    '36': 15.7,
    '37': 15.7,
    '38': 15.6,
    '39': 15.6,
    '40': 15.5,
    '41': 15.5,
    '42': 15.4,
    '43': 15.4,
    '44': 15.3,
    '45': 15.3,
    '46': 15.2,
    '47': 15.2,
    '48': 15.1,
    '49': 15.1,
    '50': 15,
    '51': 15,
    '52': 14.9,
    '53': 14.9,
    '54': 14.8,
    '55': 14.8,
    '56': 14.7,
    '57': 14.7,
    '58': 14.6,
    '59': 14.6,
    '60': 14.5,
    '61': 14.4,
    '62': 14.4,
    '63': 14.3,
    '64': 14.3,
    '65': 14.2,
    '66': 14.2,
    '67': 14.1,
    '68': 14.1,
    '69': 14,
    '70': 14,
    '71': 13.9,
    '72': 13.9,
    '73': 13.8,
    '74': 13.8,
    '75': 13.7,
    '76': 13.7,
    '77': 13.6,
    '78': 13.6,
    '79': 13.5,
    '80': 13.5,
    '81': 13.4,
    '82': 13.4,
    '83': 13.3,
    '84': 13.3,
    '85': 13.2,
    '86': 13.2,
    '87': 13.1,
    '88': 13.1,
    '89': 13,
    '90': 13,
    '91': 12.9,
    '92': 12.9,
    '93': 12.8,
    '94': 12.8,
    '95': 12.7,
    '96': 12.7,
    '97': 12.6,
    '98': 12.6,
    '99': 12.5,
    '100': 12.5,
    '101': 12.5,
    '102': 12.4,
    '103': 12.4,
    '104': 12.3,
    '105': 12.3,
    '106': 12.2,
    '107': 12.2,
    '108': 12.1,
    '109': 12.1,
    '110': 12,
    '111': 12,
    '112': 11.9,
    '113': 11.9,
    '114': 11.8,
    '115': 11.8,
    '116': 11.7,
    '117': 11.7,
    '118': 11.6,
    '119': 11.6,
    '120': 11.5,
    '121': 11.5,
    '122': 11.4,
    '123': 11.4,
    '124': 11.3,
    '125': 11.3,
    '126': 11.3,
    '127': 11.2,
    '128': 11.2,
    '129': 11.1,
    '130': 11.1,
    '131': 11,
    '132': 11,
    '133': 10.9,
    '134': 10.9,
    '135': 10.8,
    '136': 10.8,
    '137': 10.7,
    '138': 10.7,
    '139': 10.6,
    '140': 10.6,
    '141': 10.5,
    '142': 10.5,
    '143': 10.5,
    '144': 10.4,
    '145': 10.4,
    '146': 10.3,
    '147': 10.3,
    '148': 10.2,
    '149': 10.2,
    '150': 10.1,
    '151': 10.1,
    '152': 10,
    '153': 10,
    '154': 9.9,
    '155': 9.9,
    '156': 9.9,
    '157': 9.8,
    '158': 9.8,
    '159': 9.7,
    '160': 9.7,
    '161': 9.6,
    '162': 9.6,
    '163': 9.5,
    '164': 9.5,
    '165': 9.5,
    '166': 9.4,
    '167': 9.4,
    '168': 9.3,
    '169': 9.3,
    '170': 9.2,
    '171': 9.2,
    '172': 9.1,
    '173': 9.1,
    '174': 9,
    '175': 9,
    '176': 9,
    '177': 8.9,
    '178': 8.9,
    '179': 8.8,
    '180': 8.8,
    '181': 8.7,
    '182': 8.7,
    '183': 8.6,
    '184': 8.6,
    '185': 8.6,
    '186': 8.5,
    '187': 8.5,
    '188': 8.4,
    '189': 8.4,
    '190': 8.3,
    '191': 8.3,
    '192': 8.3,
    '193': 8.2,
    '194': 8.2,
    '195': 8.1,
    '0.5': 17.7,
    '1.5': 17.6,
    '2.5': 17.6,
    '3.5': 17.5,
    '4.5': 17.4,
    '5.5': 17.4,
    '6.5': 17.3,
    '7.5': 17.3,
    '8.5': 17.2,
    '9.5': 17.2,
    '10.5': 17.1,
    '11.5': 17.1,
    '12.5': 17,
    '13.5': 17,
    '14.5': 16.9,
    '15.5': 16.8,
    '16.5': 16.8,
    '17.5': 16.7,
    '18.5': 16.7,
    '19.5': 16.6,
    '20.5': 16.6,
    '21.5': 16.5,
    '22.5': 16.5,
    '23.5': 16.4,
    '24.5': 16.4,
    '25.5': 16.3,
    '26.5': 16.3,
    '27.5': 16.2,
    '28.5': 16.1,
    '29.5': 16.1,
    '30.5': 16,
    '31.5': 16,
    '32.5': 15.9,
    '33.5': 15.9,
    '34.5': 15.8,
    '35.5': 15.8,
    '36.5': 15.7,
    '37.5': 15.7,
    '38.5': 15.6,
    '39.5': 15.6,
    '40.5': 15.5,
    '41.5': 15.5,
    '42.5': 15.4,
    '43.5': 15.4,
    '44.5': 15.3,
    '45.5': 15.3,
    '46.5': 15.2,
    '47.5': 15.1,
    '48.5': 15.1,
    '49.5': 15,
    '50.5': 15,
    '51.5': 14.9,
    '52.5': 14.9,
    '53.5': 14.8,
    '54.5': 14.8,
    '55.5': 14.7,
    '56.5': 14.7,
    '57.5': 14.6,
    '58.5': 14.6,
    '59.5': 14.5,
    '60.5': 14.5,
    '61.5': 14.4,
    '62.5': 14.4,
    '63.5': 14.3,
    '64.5': 14.3,
    '65.5': 14.2,
    '66.5': 14.2,
    '67.5': 14.1,
    '68.5': 14.1,
    '69.5': 14,
    '70.5': 14,
    '71.5': 13.9,
    '72.5': 13.9,
    '73.5': 13.8,
    '74.5': 13.8,
    '75.5': 13.7,
    '76.5': 13.7,
    '77.5': 13.6,
    '78.5': 13.6,
    '79.5': 13.5,
    '80.5': 13.5,
    '81.5': 13.4,
    '82.5': 13.4,
    '83.5': 13.3,
    '84.5': 13.3,
    '85.5': 13.2,
    '86.5': 13.2,
    '87.5': 13.1,
    '88.5': 13.1,
    '89.5': 13,
    '90.5': 13,
    '91.5': 12.9,
    '92.5': 12.9,
    '93.5': 12.8,
    '94.5': 12.8,
    '95.5': 12.7,
    '96.5': 12.7,
    '97.5': 12.6,
    '98.5': 12.6,
    '99.5': 12.5,
    '100.5': 12.5,
    '101.5': 12.4,
    '102.5': 12.4,
    '103.5': 12.3,
    '104.5': 12.3,
    '105.5': 12.2,
    '106.5': 12.2,
    '107.5': 12.1,
    '108.5': 12.1,
    '109.5': 12,
    '110.5': 12,
    '111.5': 11.9,
    '112.5': 11.9,
    '113.5': 11.8,
    '114.5': 11.8,
    '115.5': 11.8,
    '116.5': 11.7,
    '117.5': 11.7,
    '118.5': 11.6,
    '119.5': 11.6,
    '120.5': 11.5,
    '121.5': 11.5,
    '122.5': 11.4,
    '123.5': 11.4,
    '124.5': 11.3,
    '125.5': 11.3,
    '126.5': 11.2,
    '127.5': 11.2,
    '128.5': 11.1,
    '129.5': 11.1,
    '130.5': 11,
    '131.5': 11,
    '132.5': 10.9,
    '133.5': 10.9,
    '134.5': 10.9,
    '135.5': 10.8,
    '136.5': 10.8,
    '137.5': 10.7,
    '138.5': 10.7,
    '139.5': 10.6,
    '140.5': 10.6,
    '141.5': 10.5,
    '142.5': 10.5,
    '143.5': 10.4,
    '144.5': 10.4,
    '145.5': 10.3,
    '146.5': 10.3,
    '147.5': 10.2,
    '148.5': 10.2,
    '149.5': 10.2,
    '150.5': 10.1,
    '151.5': 10.1,
    '152.5': 10,
    '153.5': 10,
    '154.5': 9.9,
    '155.5': 9.9,
    '156.5': 9.8,
    '157.5': 9.8,
    '158.5': 9.7,
    '159.5': 9.7,
    '160.5': 9.7,
    '161.5': 9.6,
    '162.5': 9.6,
    '163.5': 9.5,
    '164.5': 9.5,
    '165.5': 9.4,
    '166.5': 9.4,
    '167.5': 9.3,
    '168.5': 9.3,
    '169.5': 9.2,
    '170.5': 9.2,
    '171.5': 9.2,
    '172.5': 9.1,
    '173.5': 9.1,
    '174.5': 9,
    '175.5': 9,
    '176.5': 8.9,
    '177.5': 8.9,
    '178.5': 8.8,
    '179.5': 8.8,
    '180.5': 8.8,
    '181.5': 8.7,
    '182.5': 8.7,
    '183.5': 8.6,
    '184.5': 8.6,
    '185.5': 8.5,
    '186.5': 8.5,
    '187.5': 8.4,
    '188.5': 8.4,
    '189.5': 8.4,
    '190.5': 8.3,
    '191.5': 8.3,
    '192.5': 8.2,
    '193.5': 8.2,
    '194.5': 8.1,
  },
  '15': {
    '0': 18.2,
    '1': 18.2,
    '2': 18.1,
    '3': 18.1,
    '4': 18,
    '5': 17.9,
    '6': 17.9,
    '7': 17.8,
    '8': 17.8,
    '9': 17.7,
    '10': 17.7,
    '11': 17.6,
    '12': 17.6,
    '13': 17.5,
    '14': 17.5,
    '15': 17.4,
    '16': 17.3,
    '17': 17.3,
    '18': 17.2,
    '19': 17.2,
    '20': 17.1,
    '21': 17.1,
    '22': 17,
    '23': 17,
    '24': 16.9,
    '25': 16.9,
    '26': 16.8,
    '27': 16.7,
    '28': 16.7,
    '29': 16.6,
    '30': 16.6,
    '31': 16.5,
    '32': 16.5,
    '33': 16.4,
    '34': 16.4,
    '35': 16.3,
    '36': 16.3,
    '37': 16.2,
    '38': 16.2,
    '39': 16.1,
    '40': 16,
    '41': 16,
    '42': 15.9,
    '43': 15.9,
    '44': 15.8,
    '45': 15.8,
    '46': 15.7,
    '47': 15.7,
    '48': 15.6,
    '49': 15.6,
    '50': 15.5,
    '51': 15.5,
    '52': 15.4,
    '53': 15.4,
    '54': 15.3,
    '55': 15.3,
    '56': 15.2,
    '57': 15.2,
    '58': 15.1,
    '59': 15.1,
    '60': 15,
    '61': 14.9,
    '62': 14.9,
    '63': 14.8,
    '64': 14.8,
    '65': 14.7,
    '66': 14.7,
    '67': 14.6,
    '68': 14.6,
    '69': 14.5,
    '70': 14.5,
    '71': 14.4,
    '72': 14.4,
    '73': 14.3,
    '74': 14.3,
    '75': 14.2,
    '76': 14.2,
    '77': 14.1,
    '78': 14.1,
    '79': 14,
    '80': 14,
    '81': 13.9,
    '82': 13.9,
    '83': 13.8,
    '84': 13.8,
    '85': 13.7,
    '86': 13.7,
    '87': 13.6,
    '88': 13.6,
    '89': 13.5,
    '90': 13.5,
    '91': 13.4,
    '92': 13.4,
    '93': 13.3,
    '94': 13.3,
    '95': 13.2,
    '96': 13.2,
    '97': 13.1,
    '98': 13.1,
    '99': 13,
    '100': 13,
    '101': 12.9,
    '102': 12.9,
    '103': 12.8,
    '104': 12.8,
    '105': 12.7,
    '106': 12.7,
    '107': 12.6,
    '108': 12.6,
    '109': 12.5,
    '110': 12.5,
    '111': 12.4,
    '112': 12.4,
    '113': 12.3,
    '114': 12.3,
    '115': 12.2,
    '116': 12.2,
    '117': 12.1,
    '118': 12.1,
    '119': 12.1,
    '120': 12,
    '121': 12,
    '122': 11.9,
    '123': 11.9,
    '124': 11.8,
    '125': 11.8,
    '126': 11.7,
    '127': 11.7,
    '128': 11.6,
    '129': 11.6,
    '130': 11.5,
    '131': 11.5,
    '132': 11.4,
    '133': 11.4,
    '134': 11.3,
    '135': 11.3,
    '136': 11.2,
    '137': 11.2,
    '138': 11.1,
    '139': 11.1,
    '140': 11.1,
    '141': 11,
    '142': 11,
    '143': 10.9,
    '144': 10.9,
    '145': 10.8,
    '146': 10.8,
    '147': 10.7,
    '148': 10.7,
    '149': 10.6,
    '150': 10.6,
    '151': 10.5,
    '152': 10.5,
    '153': 10.4,
    '154': 10.4,
    '155': 10.4,
    '156': 10.3,
    '157': 10.3,
    '158': 10.2,
    '159': 10.2,
    '160': 10.1,
    '161': 10.1,
    '162': 10,
    '163': 10,
    '164': 9.9,
    '165': 9.9,
    '166': 9.9,
    '167': 9.8,
    '168': 9.8,
    '169': 9.7,
    '170': 9.7,
    '171': 9.6,
    '172': 9.6,
    '173': 9.5,
    '174': 9.5,
    '175': 9.4,
    '176': 9.4,
    '177': 9.4,
    '178': 9.3,
    '179': 9.3,
    '180': 9.2,
    '181': 9.2,
    '182': 9.1,
    '183': 9.1,
    '184': 9,
    '185': 9,
    '186': 9,
    '187': 8.9,
    '188': 8.9,
    '189': 8.8,
    '190': 8.8,
    '191': 8.7,
    '192': 8.7,
    '193': 8.6,
    '194': 8.6,
    '195': 8.6,
    '0.5': 18.2,
    '1.5': 18.1,
    '2.5': 18.1,
    '3.5': 18,
    '4.5': 18,
    '5.5': 17.9,
    '6.5': 17.9,
    '7.5': 17.8,
    '8.5': 17.8,
    '9.5': 17.7,
    '10.5': 17.6,
    '11.5': 17.6,
    '12.5': 17.5,
    '13.5': 17.5,
    '14.5': 17.4,
    '15.5': 17.4,
    '16.5': 17.3,
    '17.5': 17.3,
    '18.5': 17.2,
    '19.5': 17.2,
    '20.5': 17.1,
    '21.5': 17,
    '22.5': 17,
    '23.5': 16.9,
    '24.5': 16.9,
    '25.5': 16.8,
    '26.5': 16.8,
    '27.5': 16.7,
    '28.5': 16.7,
    '29.5': 16.6,
    '30.5': 16.6,
    '31.5': 16.5,
    '32.5': 16.4,
    '33.5': 16.4,
    '34.5': 16.3,
    '35.5': 16.3,
    '36.5': 16.2,
    '37.5': 16.2,
    '38.5': 16.1,
    '39.5': 16.1,
    '40.5': 16,
    '41.5': 16,
    '42.5': 15.9,
    '43.5': 15.9,
    '44.5': 15.8,
    '45.5': 15.8,
    '46.5': 15.7,
    '47.5': 15.7,
    '48.5': 15.6,
    '49.5': 15.5,
    '50.5': 15.5,
    '51.5': 15.4,
    '52.5': 15.4,
    '53.5': 15.3,
    '54.5': 15.3,
    '55.5': 15.2,
    '56.5': 15.2,
    '57.5': 15.1,
    '58.5': 15.1,
    '59.5': 15,
    '60.5': 15,
    '61.5': 14.9,
    '62.5': 14.9,
    '63.5': 14.8,
    '64.5': 14.8,
    '65.5': 14.7,
    '66.5': 14.7,
    '67.5': 14.6,
    '68.5': 14.6,
    '69.5': 14.5,
    '70.5': 14.5,
    '71.5': 14.4,
    '72.5': 14.4,
    '73.5': 14.3,
    '74.5': 14.3,
    '75.5': 14.2,
    '76.5': 14.2,
    '77.5': 14.1,
    '78.5': 14.1,
    '79.5': 14,
    '80.5': 14,
    '81.5': 13.9,
    '82.5': 13.9,
    '83.5': 13.8,
    '84.5': 13.7,
    '85.5': 13.7,
    '86.5': 13.6,
    '87.5': 13.6,
    '88.5': 13.6,
    '89.5': 13.5,
    '90.5': 13.4,
    '91.5': 13.4,
    '92.5': 13.3,
    '93.5': 13.3,
    '94.5': 13.2,
    '95.5': 13.2,
    '96.5': 13.2,
    '97.5': 13.1,
    '98.5': 13.1,
    '99.5': 13,
    '100.5': 13,
    '101.5': 12.9,
    '102.5': 12.9,
    '103.5': 12.8,
    '104.5': 12.8,
    '105.5': 12.7,
    '106.5': 12.7,
    '107.5': 12.6,
    '108.5': 12.6,
    '109.5': 12.5,
    '110.5': 12.5,
    '111.5': 12.4,
    '112.5': 12.4,
    '113.5': 12.3,
    '114.5': 12.3,
    '115.5': 12.2,
    '116.5': 12.2,
    '117.5': 12.1,
    '118.5': 12.1,
    '119.5': 12,
    '120.5': 12,
    '121.5': 11.9,
    '122.5': 11.9,
    '123.5': 11.8,
    '124.5': 11.8,
    '125.5': 11.7,
    '126.5': 11.7,
    '127.5': 11.6,
    '128.5': 11.6,
    '129.5': 11.5,
    '130.5': 11.5,
    '131.5': 11.5,
    '132.5': 11.4,
    '133.5': 11.4,
    '134.5': 11.3,
    '135.5': 11.3,
    '136.5': 11.2,
    '137.5': 11.2,
    '138.5': 11.1,
    '139.5': 11.1,
    '140.5': 11,
    '141.5': 11,
    '142.5': 10.9,
    '143.5': 10.9,
    '144.5': 10.8,
    '145.5': 10.8,
    '146.5': 10.7,
    '147.5': 10.7,
    '148.5': 10.7,
    '149.5': 10.6,
    '150.5': 10.6,
    '151.5': 10.5,
    '152.5': 10.5,
    '153.5': 10.4,
    '154.5': 10.4,
    '155.5': 10.3,
    '156.5': 10.3,
    '157.5': 10.2,
    '158.5': 10.2,
    '159.5': 10.1,
    '160.5': 10.1,
    '161.5': 10.1,
    '162.5': 10,
    '163.5': 10,
    '164.5': 9.9,
    '165.5': 9.9,
    '166.5': 9.8,
    '167.5': 9.8,
    '168.5': 9.7,
    '169.5': 9.7,
    '170.5': 9.6,
    '171.5': 9.6,
    '172.5': 9.6,
    '173.5': 9.5,
    '174.5': 9.5,
    '175.5': 9.4,
    '176.5': 9.4,
    '177.5': 9.3,
    '178.5': 9.3,
    '179.5': 9.2,
    '180.5': 9.2,
    '181.5': 9.2,
    '182.5': 9.1,
    '183.5': 9.1,
    '184.5': 9,
    '185.5': 9,
    '186.5': 8.9,
    '187.5': 8.9,
    '188.5': 8.8,
    '189.5': 8.8,
    '190.5': 8.8,
    '191.5': 8.7,
    '192.5': 8.7,
    '193.5': 8.6,
    '194.5': 8.6,
  },
  // 15 - 20
  '15.5': {
    '0': 18.8,
    '1': 18.7,
    '2': 18.6,
    '3': 18.6,
    '4': 18.5,
    '5': 18.5,
    '6': 18.4,
    '7': 18.4,
    '8': 18.3,
    '9': 18.3,
    '10': 18.2,
    '11': 18.1,
    '12': 18.1,
    '13': 18,
    '14': 18,
    '15': 17.9,
    '16': 17.9,
    '17': 17.8,
    '18': 17.8,
    '19': 17.7,
    '20': 17.6,
    '21': 17.6,
    '22': 17.5,
    '23': 17.5,
    '24': 17.4,
    '25': 17.4,
    '26': 17.3,
    '27': 17.3,
    '28': 17.2,
    '29': 17.2,
    '30': 17.1,
    '31': 17,
    '32': 17,
    '33': 16.9,
    '34': 16.9,
    '35': 16.8,
    '36': 16.8,
    '37': 16.7,
    '38': 16.7,
    '39': 16.6,
    '40': 16.6,
    '41': 16.5,
    '42': 16.5,
    '43': 16.4,
    '44': 16.3,
    '45': 16.3,
    '46': 16.2,
    '47': 16.2,
    '48': 16.1,
    '49': 16.1,
    '50': 16,
    '51': 16,
    '52': 15.9,
    '53': 15.9,
    '54': 15.8,
    '55': 15.8,
    '56': 15.7,
    '57': 15.7,
    '58': 15.6,
    '59': 15.6,
    '60': 15.5,
    '61': 15.4,
    '62': 15.4,
    '63': 15.3,
    '64': 15.3,
    '65': 15.2,
    '66': 15.2,
    '67': 15.1,
    '68': 15.1,
    '69': 15,
    '70': 15,
    '71': 14.9,
    '72': 14.9,
    '73': 14.8,
    '74': 14.8,
    '75': 14.7,
    '76': 14.7,
    '77': 14.6,
    '78': 14.6,
    '79': 14.5,
    '80': 14.5,
    '81': 14.4,
    '82': 14.4,
    '83': 14.3,
    '84': 14.3,
    '85': 14.2,
    '86': 14.2,
    '87': 14.1,
    '88': 14.1,
    '89': 14,
    '90': 14,
    '91': 13.9,
    '92': 13.9,
    '93': 13.8,
    '94': 13.8,
    '95': 13.7,
    '96': 13.7,
    '97': 13.6,
    '98': 13.6,
    '99': 13.5,
    '100': 13.5,
    '101': 13.4,
    '102': 13.4,
    '103': 13.3,
    '104': 13.3,
    '105': 13.2,
    '106': 13.2,
    '107': 13.1,
    '108': 13.1,
    '109': 13,
    '110': 13,
    '111': 12.9,
    '112': 12.9,
    '113': 12.8,
    '114': 12.8,
    '115': 12.7,
    '116': 12.7,
    '117': 12.6,
    '118': 12.6,
    '119': 12.5,
    '120': 12.5,
    '121': 12.4,
    '122': 12.4,
    '123': 12.3,
    '124': 12.3,
    '125': 12.2,
    '126': 12.2,
    '127': 12.1,
    '128': 12.1,
    '129': 12,
    '130': 12,
    '131': 11.9,
    '132': 11.9,
    '133': 11.8,
    '134': 11.8,
    '135': 11.8,
    '136': 11.7,
    '137': 11.7,
    '138': 11.6,
    '139': 11.6,
    '140': 11.5,
    '141': 11.5,
    '142': 11.4,
    '143': 11.4,
    '144': 11.3,
    '145': 11.3,
    '146': 11.2,
    '147': 11.2,
    '148': 11.1,
    '149': 11.1,
    '150': 11,
    '151': 11,
    '152': 10.9,
    '153': 10.9,
    '154': 10.9,
    '155': 10.8,
    '156': 10.8,
    '157': 10.7,
    '158': 10.7,
    '159': 10.6,
    '160': 10.6,
    '161': 10.5,
    '162': 10.5,
    '163': 10.4,
    '164': 10.4,
    '165': 10.3,
    '166': 10.3,
    '167': 10.3,
    '168': 10.2,
    '169': 10.2,
    '170': 10.1,
    '171': 10.1,
    '172': 10,
    '173': 10,
    '174': 9.9,
    '175': 9.9,
    '176': 9.8,
    '177': 9.8,
    '178': 9.8,
    '179': 9.7,
    '180': 9.7,
    '181': 9.6,
    '182': 9.6,
    '183': 9.5,
    '184': 9.5,
    '185': 9.4,
    '186': 9.4,
    '187': 9.3,
    '188': 9.3,
    '189': 9.3,
    '190': 9.2,
    '191': 9.2,
    '192': 9.1,
    '193': 9.1,
    '194': 9,
    '195': 9,
    '196': 8.9,
    '197': 8.9,
    '198': 8.9,
    '199': 8.8,
    '200': 8.8,
    '201': 8.7,
    '202': 8.7,
    '203': 8.6,
    '204': 8.6,
    '205': 8.5,
    '206': 8.5,
    '207': 8.5,
    '208': 8.4,
    '209': 8.4,
    '210': 8.3,
    '211': 8.3,
    '212': 8.2,
    '213': 8.2,
    '214': 8.2,
    '215': 8.1,
    '216': 8.1,
    '217': 8,
    '218': 8,
    '219': 7.9,
    '220': 7.9,
    '221': 7.9,
    '222': 7.8,
    '223': 7.8,
    '224': 7.7,
    '225': 7.7,
    '0.5': 18.7,
    '1.5': 18.7,
    '2.5': 18.6,
    '3.5': 18.6,
    '4.5': 18.5,
    '5.5': 18.5,
    '6.5': 18.4,
    '7.5': 18.3,
    '8.5': 18.3,
    '9.5': 18.2,
    '10.5': 18.2,
    '11.5': 18.1,
    '12.5': 18.1,
    '13.5': 18,
    '14.5': 18,
    '15.5': 17.9,
    '16.5': 17.8,
    '17.5': 17.8,
    '18.5': 17.7,
    '19.5': 17.7,
    '20.5': 17.6,
    '21.5': 17.6,
    '22.5': 17.5,
    '23.5': 17.5,
    '24.5': 17.4,
    '25.5': 17.3,
    '26.5': 17.3,
    '27.5': 17.2,
    '28.5': 17.2,
    '29.5': 17.1,
    '30.5': 17.1,
    '31.5': 17,
    '32.5': 17,
    '33.5': 16.9,
    '34.5': 16.9,
    '35.5': 16.8,
    '36.5': 16.7,
    '37.5': 16.7,
    '38.5': 16.6,
    '39.5': 16.6,
    '40.5': 16.5,
    '41.5': 16.5,
    '42.5': 16.4,
    '43.5': 16.4,
    '44.5': 16.3,
    '45.5': 16.3,
    '46.5': 16.2,
    '47.5': 16.2,
    '48.5': 16.1,
    '49.5': 16.1,
    '50.5': 16,
    '51.5': 15.9,
    '52.5': 15.9,
    '53.5': 15.8,
    '54.5': 15.8,
    '55.5': 15.7,
    '56.5': 15.7,
    '57.5': 15.6,
    '58.5': 15.6,
    '59.5': 15.5,
    '60.5': 15.5,
    '61.5': 15.4,
    '62.5': 15.4,
    '63.5': 15.3,
    '64.5': 15.3,
    '65.5': 15.2,
    '66.5': 15.2,
    '67.5': 15.1,
    '68.5': 15.1,
    '69.5': 15,
    '70.5': 15,
    '71.5': 14.9,
    '72.5': 14.9,
    '73.5': 14.8,
    '74.5': 14.7,
    '75.5': 14.7,
    '76.5': 14.6,
    '77.5': 14.6,
    '78.5': 14.5,
    '79.5': 14.5,
    '80.5': 14.4,
    '81.5': 14.4,
    '82.5': 14.3,
    '83.5': 14.3,
    '84.5': 14.2,
    '85.5': 14.2,
    '86.5': 14.1,
    '87.5': 14.1,
    '88.5': 14,
    '89.5': 14,
    '90.5': 13.9,
    '91.5': 13.9,
    '92.5': 13.8,
    '93.5': 13.8,
    '94.5': 13.7,
    '95.5': 13.7,
    '96.5': 13.6,
    '97.5': 13.6,
    '98.5': 13.5,
    '99.5': 13.5,
    '100.5': 13.4,
    '101.5': 13.4,
    '102.5': 13.3,
    '103.5': 13.3,
    '104.5': 13.2,
    '105.5': 13.2,
    '106.5': 13.1,
    '107.5': 13.1,
    '108.5': 13,
    '109.5': 13,
    '110.5': 12.9,
    '111.5': 12.9,
    '112.5': 12.8,
    '113.5': 12.8,
    '114.5': 12.7,
    '115.5': 12.7,
    '116.5': 12.6,
    '117.5': 12.6,
    '118.5': 12.5,
    '119.5': 12.5,
    '120.5': 12.5,
    '121.5': 12.4,
    '122.5': 12.4,
    '123.5': 12.3,
    '124.5': 12.3,
    '125.5': 12.2,
    '126.5': 12.2,
    '127.5': 12.1,
    '128.5': 12.1,
    '129.5': 12,
    '130.5': 12,
    '131.5': 11.9,
    '132.5': 11.9,
    '133.5': 11.8,
    '134.5': 11.8,
    '135.5': 11.7,
    '136.5': 11.7,
    '137.5': 11.6,
    '138.5': 11.6,
    '139.5': 11.5,
    '140.5': 11.5,
    '141.5': 11.4,
    '142.5': 11.4,
    '143.5': 11.3,
    '144.5': 11.3,
    '145.5': 11.3,
    '146.5': 11.2,
    '147.5': 11.2,
    '148.5': 11.1,
    '149.5': 11.1,
    '150.5': 11,
    '151.5': 11,
    '152.5': 10.9,
    '153.5': 10.9,
    '154.5': 10.8,
    '155.5': 10.8,
    '156.5': 10.7,
    '157.5': 10.7,
    '158.5': 10.6,
    '159.5': 10.6,
    '160.5': 10.6,
    '161.5': 10.5,
    '162.5': 10.5,
    '163.5': 10.4,
    '164.5': 10.4,
    '165.5': 10.3,
    '166.5': 10.3,
    '167.5': 10.2,
    '168.5': 10.2,
    '169.5': 10.1,
    '170.5': 10.1,
    '171.5': 10,
    '172.5': 10,
    '173.5': 10,
    '174.5': 9.9,
    '175.5': 9.9,
    '176.5': 9.8,
    '177.5': 9.8,
    '178.5': 9.7,
    '179.5': 9.7,
    '180.5': 9.6,
    '181.5': 9.6,
    '182.5': 9.5,
    '183.5': 9.5,
    '184.5': 9.5,
    '185.5': 9.4,
    '186.5': 9.4,
    '187.5': 9.3,
    '188.5': 9.3,
    '189.5': 9.2,
    '190.5': 9.2,
    '191.5': 9.1,
    '192.5': 9.1,
    '193.5': 9.1,
    '194.5': 9,
    '195.5': 9,
    '196.5': 8.9,
    '197.5': 8.9,
    '198.5': 8.8,
    '199.5': 8.8,
    '200.5': 8.7,
    '201.5': 8.7,
    '202.5': 8.7,
    '203.5': 8.6,
    '204.5': 8.6,
    '205.5': 8.5,
    '206.5': 8.5,
    '207.5': 8.4,
    '208.5': 8.4,
    '209.5': 8.4,
    '210.5': 8.3,
    '211.5': 8.3,
    '212.5': 8.2,
    '213.5': 8.2,
    '214.5': 8.1,
    '215.5': 8.1,
    '216.5': 8,
    '217.5': 8,
    '218.5': 8,
    '219.5': 7.9,
    '220.5': 7.9,
    '221.5': 7.8,
    '222.5': 7.8,
    '223.5': 7.7,
    '224.5': 7.7,
  },
  '16': {
    '0': 19.3,
    '1': 19.2,
    '2': 19.2,
    '3': 19.1,
    '4': 19.1,
    '5': 19,
    '6': 19,
    '7': 18.9,
    '8': 18.8,
    '9': 18.8,
    '10': 18.7,
    '11': 18.7,
    '12': 18.6,
    '13': 18.6,
    '14': 18.5,
    '15': 18.4,
    '16': 18.4,
    '17': 18.3,
    '18': 18.3,
    '19': 18.2,
    '20': 18.2,
    '21': 18.1,
    '22': 18.1,
    '23': 18,
    '24': 17.9,
    '25': 17.9,
    '26': 17.8,
    '27': 17.8,
    '28': 17.7,
    '29': 17.7,
    '30': 17.6,
    '31': 17.6,
    '32': 17.5,
    '33': 17.5,
    '34': 17.4,
    '35': 17.3,
    '36': 17.3,
    '37': 17.2,
    '38': 17.2,
    '39': 17.1,
    '40': 17.1,
    '41': 17,
    '42': 17,
    '43': 16.9,
    '44': 16.9,
    '45': 16.8,
    '46': 16.7,
    '47': 16.7,
    '48': 16.6,
    '49': 16.6,
    '50': 16.5,
    '51': 16.5,
    '52': 16.4,
    '53': 16.4,
    '54': 16.3,
    '55': 16.3,
    '56': 16.2,
    '57': 16.2,
    '58': 16.1,
    '59': 16.1,
    '60': 16,
    '61': 15.9,
    '62': 15.9,
    '63': 15.8,
    '64': 15.8,
    '65': 15.7,
    '66': 15.7,
    '67': 15.6,
    '68': 15.6,
    '69': 15.5,
    '70': 15.5,
    '71': 15.4,
    '72': 15.4,
    '73': 15.3,
    '74': 15.3,
    '75': 15.2,
    '76': 15.2,
    '77': 15.1,
    '78': 15.1,
    '79': 15,
    '80': 15,
    '81': 14.9,
    '82': 14.9,
    '83': 14.8,
    '84': 14.8,
    '85': 14.7,
    '86': 14.6,
    '87': 14.6,
    '88': 14.5,
    '89': 14.5,
    '90': 14.4,
    '91': 14.4,
    '92': 14.3,
    '93': 14.3,
    '94': 14.2,
    '95': 14.2,
    '96': 14.1,
    '97': 14.1,
    '98': 14,
    '99': 14,
    '100': 13.9,
    '101': 13.9,
    '102': 13.8,
    '103': 13.8,
    '104': 13.7,
    '105': 13.7,
    '106': 13.6,
    '107': 13.6,
    '108': 13.5,
    '109': 13.5,
    '110': 13.4,
    '111': 13.4,
    '112': 13.3,
    '113': 13.3,
    '114': 13.2,
    '115': 13.2,
    '116': 13.1,
    '117': 13.1,
    '118': 13,
    '119': 13,
    '120': 12.9,
    '121': 12.9,
    '122': 12.8,
    '123': 12.8,
    '124': 12.7,
    '125': 12.7,
    '126': 12.6,
    '127': 12.6,
    '128': 12.6,
    '129': 12.5,
    '130': 12.5,
    '131': 12.4,
    '132': 12.4,
    '133': 12.3,
    '134': 12.3,
    '135': 12.2,
    '136': 12.2,
    '137': 12.1,
    '138': 12.1,
    '139': 12,
    '140': 12,
    '141': 11.9,
    '142': 11.9,
    '143': 11.8,
    '144': 11.8,
    '145': 11.7,
    '146': 11.7,
    '147': 11.6,
    '148': 11.6,
    '149': 11.5,
    '150': 11.5,
    '151': 11.4,
    '152': 11.4,
    '153': 11.4,
    '154': 11.3,
    '155': 11.3,
    '156': 11.2,
    '157': 11.2,
    '158': 11.1,
    '159': 11.1,
    '160': 11,
    '161': 11,
    '162': 10.9,
    '163': 10.9,
    '164': 10.8,
    '165': 10.8,
    '166': 10.7,
    '167': 10.7,
    '168': 10.7,
    '169': 10.6,
    '170': 10.6,
    '171': 10.5,
    '172': 10.5,
    '173': 10.4,
    '174': 10.4,
    '175': 10.3,
    '176': 10.3,
    '177': 10.2,
    '178': 10.2,
    '179': 10.1,
    '180': 10.1,
    '181': 10.1,
    '182': 10,
    '183': 10,
    '184': 9.9,
    '185': 9.9,
    '186': 9.8,
    '187': 9.8,
    '188': 9.7,
    '189': 9.7,
    '190': 9.6,
    '191': 9.6,
    '192': 9.6,
    '193': 9.5,
    '194': 9.5,
    '195': 9.4,
    '196': 9.4,
    '197': 9.3,
    '198': 9.3,
    '199': 9.2,
    '200': 9.2,
    '201': 9.2,
    '202': 9.1,
    '203': 9.1,
    '204': 9,
    '205': 9,
    '206': 8.9,
    '207': 8.9,
    '208': 8.8,
    '209': 8.8,
    '210': 8.8,
    '211': 8.7,
    '212': 8.7,
    '213': 8.6,
    '214': 8.6,
    '215': 8.5,
    '216': 8.5,
    '217': 8.5,
    '218': 8.4,
    '219': 8.4,
    '220': 8.3,
    '221': 8.3,
    '222': 8.2,
    '223': 8.2,
    '224': 8.1,
    '225': 8.1,
    '0.5': 19.3,
    '1.5': 19.2,
    '2.5': 19.2,
    '3.5': 19.1,
    '4.5': 19,
    '5.5': 19,
    '6.5': 18.9,
    '7.5': 18.9,
    '8.5': 18.8,
    '9.5': 18.8,
    '10.5': 18.7,
    '11.5': 18.6,
    '12.5': 18.6,
    '13.5': 18.5,
    '14.5': 18.5,
    '15.5': 18.4,
    '16.5': 18.4,
    '17.5': 18.3,
    '18.5': 18.3,
    '19.5': 18.2,
    '20.5': 18.1,
    '21.5': 18.1,
    '22.5': 18,
    '23.5': 18,
    '24.5': 17.9,
    '25.5': 17.9,
    '26.5': 17.8,
    '27.5': 17.8,
    '28.5': 17.7,
    '29.5': 17.6,
    '30.5': 17.6,
    '31.5': 17.5,
    '32.5': 17.5,
    '33.5': 17.4,
    '34.5': 17.4,
    '35.5': 17.3,
    '36.5': 17.3,
    '37.5': 17.2,
    '38.5': 17.2,
    '39.5': 17.1,
    '40.5': 17,
    '41.5': 17,
    '42.5': 16.9,
    '43.5': 16.9,
    '44.5': 16.8,
    '45.5': 16.8,
    '46.5': 16.7,
    '47.5': 16.7,
    '48.5': 16.6,
    '49.5': 16.6,
    '50.5': 16.5,
    '51.5': 16.5,
    '52.5': 16.4,
    '53.5': 16.3,
    '54.5': 16.3,
    '55.5': 16.2,
    '56.5': 16.2,
    '57.5': 16.1,
    '58.5': 16.1,
    '59.5': 16,
    '60.5': 16,
    '61.5': 15.9,
    '62.5': 15.9,
    '63.5': 15.8,
    '64.5': 15.8,
    '65.5': 15.7,
    '66.5': 15.7,
    '67.5': 15.6,
    '68.5': 15.6,
    '69.5': 15.5,
    '70.5': 15.4,
    '71.5': 15.4,
    '72.5': 15.3,
    '73.5': 15.3,
    '74.5': 15.2,
    '75.5': 15.2,
    '76.5': 15.1,
    '77.5': 15.1,
    '78.5': 15,
    '79.5': 15,
    '80.5': 14.9,
    '81.5': 14.9,
    '82.5': 14.8,
    '83.5': 14.8,
    '84.5': 14.7,
    '85.5': 14.7,
    '86.5': 14.6,
    '87.5': 14.6,
    '88.5': 14.5,
    '89.5': 14.5,
    '90.5': 14.4,
    '91.5': 14.4,
    '92.5': 14.3,
    '93.5': 14.3,
    '94.5': 14.2,
    '95.5': 14.2,
    '96.5': 14.1,
    '97.5': 14.1,
    '98.5': 14,
    '99.5': 14,
    '100.5': 13.9,
    '101.5': 13.9,
    '102.5': 13.8,
    '103.5': 13.8,
    '104.5': 13.7,
    '105.5': 13.7,
    '106.5': 13.6,
    '107.5': 13.6,
    '108.5': 13.5,
    '109.5': 13.5,
    '110.5': 13.4,
    '111.5': 13.4,
    '112.5': 13.3,
    '113.5': 13.3,
    '114.5': 13.2,
    '115.5': 13.2,
    '116.5': 13.1,
    '117.5': 13.1,
    '118.5': 13,
    '119.5': 13,
    '120.5': 12.9,
    '121.5': 12.9,
    '122.5': 12.8,
    '123.5': 12.8,
    '124.5': 12.7,
    '125.5': 12.7,
    '126.5': 12.6,
    '127.5': 12.6,
    '128.5': 12.5,
    '129.5': 12.5,
    '130.5': 12.4,
    '131.5': 12.4,
    '132.5': 12.3,
    '133.5': 12.3,
    '134.5': 12.2,
    '135.5': 12.2,
    '136.5': 12.1,
    '137.5': 12.1,
    '138.5': 12,
    '139.5': 12,
    '140.5': 12,
    '141.5': 11.9,
    '142.5': 11.9,
    '143.5': 11.8,
    '144.5': 11.8,
    '145.5': 11.7,
    '146.5': 11.7,
    '147.5': 11.6,
    '148.5': 11.6,
    '149.5': 11.5,
    '150.5': 11.5,
    '151.5': 11.4,
    '152.5': 11.4,
    '153.5': 11.3,
    '154.5': 11.3,
    '155.5': 11.2,
    '156.5': 11.2,
    '157.5': 11.1,
    '158.5': 11.1,
    '159.5': 11.1,
    '160.5': 11,
    '161.5': 11,
    '162.5': 10.9,
    '163.5': 10.9,
    '164.5': 10.8,
    '165.5': 10.8,
    '166.5': 10.7,
    '167.5': 10.7,
    '168.5': 10.6,
    '169.5': 10.6,
    '170.5': 10.5,
    '171.5': 10.5,
    '172.5': 10.4,
    '173.5': 10.4,
    '174.5': 10.4,
    '175.5': 10.3,
    '176.5': 10.3,
    '177.5': 10.2,
    '178.5': 10.2,
    '179.5': 10.1,
    '180.5': 10.1,
    '181.5': 10,
    '182.5': 10,
    '183.5': 9.9,
    '184.5': 9.9,
    '185.5': 9.9,
    '186.5': 9.8,
    '187.5': 9.8,
    '188.5': 9.7,
    '189.5': 9.7,
    '190.5': 9.6,
    '191.5': 9.6,
    '192.5': 9.5,
    '193.5': 9.5,
    '194.5': 9.4,
    '195.5': 9.4,
    '196.5': 9.4,
    '197.5': 9.3,
    '198.5': 9.3,
    '199.5': 9.2,
    '200.5': 9.2,
    '201.5': 9.1,
    '202.5': 9.1,
    '203.5': 9,
    '204.5': 9,
    '205.5': 9,
    '206.5': 8.9,
    '207.5': 8.9,
    '208.5': 8.8,
    '209.5': 8.8,
    '210.5': 8.7,
    '211.5': 8.7,
    '212.5': 8.6,
    '213.5': 8.6,
    '214.5': 8.6,
    '215.5': 8.5,
    '216.5': 8.5,
    '217.5': 8.4,
    '218.5': 8.4,
    '219.5': 8.3,
    '220.5': 8.3,
    '221.5': 8.3,
    '222.5': 8.2,
    '223.5': 8.2,
    '224.5': 8.1,
  },
  '16.5': {
    '0': 19.8,
    '1': 19.8,
    '2': 19.7,
    '3': 19.7,
    '4': 19.6,
    '5': 19.5,
    '6': 19.5,
    '7': 19.4,
    '8': 19.4,
    '9': 19.3,
    '10': 19.3,
    '11': 19.2,
    '12': 19.1,
    '13': 19.1,
    '14': 19,
    '15': 19,
    '16': 18.9,
    '17': 18.9,
    '18': 18.8,
    '19': 18.7,
    '20': 18.7,
    '21': 18.6,
    '22': 18.6,
    '23': 18.5,
    '24': 18.5,
    '25': 18.4,
    '26': 18.4,
    '27': 18.3,
    '28': 18.2,
    '29': 18.2,
    '30': 18.1,
    '31': 18.1,
    '32': 18,
    '33': 18,
    '34': 17.9,
    '35': 17.9,
    '36': 17.8,
    '37': 17.7,
    '38': 17.7,
    '39': 17.6,
    '40': 17.6,
    '41': 17.5,
    '42': 17.5,
    '43': 17.4,
    '44': 17.4,
    '45': 17.3,
    '46': 17.3,
    '47': 17.2,
    '48': 17.1,
    '49': 17.1,
    '50': 17,
    '51': 17,
    '52': 16.9,
    '53': 16.9,
    '54': 16.8,
    '55': 16.8,
    '56': 16.7,
    '57': 16.7,
    '58': 16.6,
    '59': 16.6,
    '60': 16.5,
    '61': 16.4,
    '62': 16.4,
    '63': 16.3,
    '64': 16.3,
    '65': 16.2,
    '66': 16.2,
    '67': 16.1,
    '68': 16.1,
    '69': 16,
    '70': 16,
    '71': 15.9,
    '72': 15.9,
    '73': 15.8,
    '74': 15.8,
    '75': 15.7,
    '76': 15.7,
    '77': 15.6,
    '78': 15.5,
    '79': 15.5,
    '80': 15.4,
    '81': 15.4,
    '82': 15.3,
    '83': 15.3,
    '84': 15.2,
    '85': 15.2,
    '86': 15.1,
    '87': 15.1,
    '88': 15,
    '89': 15,
    '90': 14.9,
    '91': 14.9,
    '92': 14.8,
    '93': 14.8,
    '94': 14.7,
    '95': 14.7,
    '96': 14.6,
    '97': 14.6,
    '98': 14.5,
    '99': 14.5,
    '100': 14.4,
    '101': 14.4,
    '102': 14.3,
    '103': 14.3,
    '104': 14.2,
    '105': 14.2,
    '106': 14.1,
    '107': 14.1,
    '108': 14,
    '109': 14,
    '110': 13.9,
    '111': 13.9,
    '112': 13.8,
    '113': 13.8,
    '114': 13.7,
    '115': 13.7,
    '116': 13.6,
    '117': 13.6,
    '118': 13.5,
    '119': 13.5,
    '120': 13.4,
    '121': 13.4,
    '122': 13.3,
    '123': 13.3,
    '124': 13.2,
    '125': 13.2,
    '126': 13.1,
    '127': 13.1,
    '128': 13,
    '129': 13,
    '130': 12.9,
    '131': 12.9,
    '132': 12.8,
    '133': 12.8,
    '134': 12.7,
    '135': 12.7,
    '136': 12.6,
    '137': 12.6,
    '138': 12.5,
    '139': 12.5,
    '140': 12.4,
    '141': 12.4,
    '142': 12.3,
    '143': 12.3,
    '144': 12.2,
    '145': 12.2,
    '146': 12.1,
    '147': 12.1,
    '148': 12,
    '149': 12,
    '150': 12,
    '151': 11.9,
    '152': 11.9,
    '153': 11.8,
    '154': 11.8,
    '155': 11.7,
    '156': 11.7,
    '157': 11.6,
    '158': 11.6,
    '159': 11.5,
    '160': 11.5,
    '161': 11.4,
    '162': 11.4,
    '163': 11.3,
    '164': 11.3,
    '165': 11.2,
    '166': 11.2,
    '167': 11.1,
    '168': 11.1,
    '169': 11.1,
    '170': 11,
    '171': 11,
    '172': 10.9,
    '173': 10.9,
    '174': 10.8,
    '175': 10.8,
    '176': 10.7,
    '177': 10.7,
    '178': 10.6,
    '179': 10.6,
    '180': 10.5,
    '181': 10.5,
    '182': 10.5,
    '183': 10.4,
    '184': 10.4,
    '185': 10.3,
    '186': 10.3,
    '187': 10.2,
    '188': 10.2,
    '189': 10.1,
    '190': 10.1,
    '191': 10,
    '192': 10,
    '193': 10,
    '194': 9.9,
    '195': 9.9,
    '196': 9.8,
    '197': 9.8,
    '198': 9.7,
    '199': 9.7,
    '200': 9.6,
    '201': 9.6,
    '202': 9.5,
    '203': 9.5,
    '204': 9.5,
    '205': 9.4,
    '206': 9.4,
    '207': 9.3,
    '208': 9.3,
    '209': 9.2,
    '210': 9.2,
    '211': 9.1,
    '212': 9.1,
    '213': 9.1,
    '214': 9,
    '215': 9,
    '216': 8.9,
    '217': 8.9,
    '218': 8.8,
    '219': 8.8,
    '220': 8.7,
    '221': 8.7,
    '222': 8.7,
    '223': 8.6,
    '224': 8.6,
    '225': 8.5,
    '0.5': 19.8,
    '1.5': 19.7,
    '2.5': 19.7,
    '3.5': 19.6,
    '4.5': 19.6,
    '5.5': 19.5,
    '6.5': 19.5,
    '7.5': 19.4,
    '8.5': 19.3,
    '9.5': 19.3,
    '10.5': 19.2,
    '11.5': 19.2,
    '12.5': 19.1,
    '13.5': 19.1,
    '14.5': 19,
    '15.5': 18.9,
    '16.5': 18.9,
    '17.5': 18.8,
    '18.5': 18.8,
    '19.5': 18.7,
    '20.5': 18.7,
    '21.5': 18.6,
    '22.5': 18.6,
    '23.5': 18.5,
    '24.5': 18.4,
    '25.5': 18.4,
    '26.5': 18.3,
    '27.5': 18.3,
    '28.5': 18.2,
    '29.5': 18.2,
    '30.5': 18.1,
    '31.5': 18.1,
    '32.5': 18,
    '33.5': 17.9,
    '34.5': 17.9,
    '35.5': 17.8,
    '36.5': 17.8,
    '37.5': 17.7,
    '38.5': 17.7,
    '39.5': 17.6,
    '40.5': 17.6,
    '41.5': 17.5,
    '42.5': 17.4,
    '43.5': 17.4,
    '44.5': 17.3,
    '45.5': 17.3,
    '46.5': 17.2,
    '47.5': 17.2,
    '48.5': 17.1,
    '49.5': 17.1,
    '50.5': 17,
    '51.5': 17,
    '52.5': 16.9,
    '53.5': 16.8,
    '54.5': 16.8,
    '55.5': 16.7,
    '56.5': 16.7,
    '57.5': 16.6,
    '58.5': 16.6,
    '59.5': 16.5,
    '60.5': 16.5,
    '61.5': 16.4,
    '62.5': 16.4,
    '63.5': 16.3,
    '64.5': 16.3,
    '65.5': 16.2,
    '66.5': 16.2,
    '67.5': 16.1,
    '68.5': 16,
    '69.5': 16,
    '70.5': 15.9,
    '71.5': 15.9,
    '72.5': 15.8,
    '73.5': 15.8,
    '74.5': 15.7,
    '75.5': 15.7,
    '76.5': 15.6,
    '77.5': 15.6,
    '78.5': 15.5,
    '79.5': 15.5,
    '80.5': 15.4,
    '81.5': 15.4,
    '82.5': 15.3,
    '83.5': 15.3,
    '84.5': 15.2,
    '85.5': 15.2,
    '86.5': 15.1,
    '87.5': 15.1,
    '88.5': 15,
    '89.5': 15,
    '90.5': 14.9,
    '91.5': 14.8,
    '92.5': 14.8,
    '93.5': 14.7,
    '94.5': 14.7,
    '95.5': 14.6,
    '96.5': 14.6,
    '97.5': 14.5,
    '98.5': 14.5,
    '99.5': 14.4,
    '100.5': 14.4,
    '101.5': 14.3,
    '102.5': 14.3,
    '103.5': 14.2,
    '104.5': 14.2,
    '105.5': 14.1,
    '106.5': 14.1,
    '107.5': 14,
    '108.5': 14,
    '109.5': 13.9,
    '110.5': 13.9,
    '111.5': 13.8,
    '112.5': 13.8,
    '113.5': 13.7,
    '114.5': 13.7,
    '115.5': 13.6,
    '116.5': 13.6,
    '117.5': 13.5,
    '118.5': 13.5,
    '119.5': 13.4,
    '120.5': 13.4,
    '121.5': 13.3,
    '122.5': 13.3,
    '123.5': 13.2,
    '124.5': 13.2,
    '125.5': 13.1,
    '126.5': 13.1,
    '127.5': 13,
    '128.5': 13,
    '129.5': 12.9,
    '130.5': 12.9,
    '131.5': 12.8,
    '132.5': 12.8,
    '133.5': 12.7,
    '134.5': 12.7,
    '135.5': 12.7,
    '136.5': 12.6,
    '137.5': 12.6,
    '138.5': 12.5,
    '139.5': 12.5,
    '140.5': 12.4,
    '141.5': 12.4,
    '142.5': 12.3,
    '143.5': 12.3,
    '144.5': 12.2,
    '145.5': 12.2,
    '146.5': 12.1,
    '147.5': 12.1,
    '148.5': 12,
    '149.5': 12,
    '150.5': 11.9,
    '151.5': 11.9,
    '152.5': 11.8,
    '153.5': 11.8,
    '154.5': 11.7,
    '155.5': 11.7,
    '156.5': 11.6,
    '157.5': 11.6,
    '158.5': 11.5,
    '159.5': 11.5,
    '160.5': 11.5,
    '161.5': 11.4,
    '162.5': 11.4,
    '163.5': 11.3,
    '164.5': 11.3,
    '165.5': 11.2,
    '166.5': 11.2,
    '167.5': 11.1,
    '168.5': 11.1,
    '169.5': 11,
    '170.5': 11,
    '171.5': 10.9,
    '172.5': 10.9,
    '173.5': 10.8,
    '174.5': 10.8,
    '175.5': 10.8,
    '176.5': 10.7,
    '177.5': 10.7,
    '178.5': 10.6,
    '179.5': 10.6,
    '180.5': 10.5,
    '181.5': 10.5,
    '182.5': 10.4,
    '183.5': 10.4,
    '184.5': 10.3,
    '185.5': 10.3,
    '186.5': 10.2,
    '187.5': 10.2,
    '188.5': 10.2,
    '189.5': 10.1,
    '190.5': 10.1,
    '191.5': 10,
    '192.5': 10,
    '193.5': 9.9,
    '194.5': 9.9,
    '195.5': 9.8,
    '196.5': 9.8,
    '197.5': 9.7,
    '198.5': 9.7,
    '199.5': 9.7,
    '200.5': 9.6,
    '201.5': 9.6,
    '202.5': 9.5,
    '203.5': 9.5,
    '204.5': 9.4,
    '205.5': 9.4,
    '206.5': 9.3,
    '207.5': 9.3,
    '208.5': 9.3,
    '209.5': 9.2,
    '210.5': 9.2,
    '211.5': 9.1,
    '212.5': 9.1,
    '213.5': 9,
    '214.5': 9,
    '215.5': 8.9,
    '216.5': 8.9,
    '217.5': 8.9,
    '218.5': 8.8,
    '219.5': 8.8,
    '220.5': 8.7,
    '221.5': 8.7,
    '222.5': 8.6,
    '223.5': 8.6,
    '224.5': 8.5,
  },
  '17': {
    '0': 20.4,
    '1': 20.3,
    '2': 20.3,
    '3': 20.2,
    '4': 20.1,
    '5': 20.1,
    '6': 20,
    '7': 20,
    '8': 19.9,
    '9': 19.8,
    '10': 19.8,
    '11': 19.7,
    '12': 19.7,
    '13': 19.6,
    '14': 19.6,
    '15': 19.5,
    '16': 19.4,
    '17': 19.4,
    '18': 19.3,
    '19': 19.3,
    '20': 19.2,
    '21': 19.2,
    '22': 19.1,
    '23': 19,
    '24': 19,
    '25': 18.9,
    '26': 18.9,
    '27': 18.8,
    '28': 18.8,
    '29': 18.7,
    '30': 18.7,
    '31': 18.6,
    '32': 18.5,
    '33': 18.5,
    '34': 18.4,
    '35': 18.4,
    '36': 18.3,
    '37': 18.3,
    '38': 18.2,
    '39': 18.2,
    '40': 18.1,
    '41': 18,
    '42': 18,
    '43': 17.9,
    '44': 17.9,
    '45': 17.8,
    '46': 17.8,
    '47': 17.7,
    '48': 17.7,
    '49': 17.6,
    '50': 17.5,
    '51': 17.5,
    '52': 17.4,
    '53': 17.4,
    '54': 17.3,
    '55': 17.3,
    '56': 17.2,
    '57': 17.2,
    '58': 17.1,
    '59': 17.1,
    '60': 17,
    '61': 16.9,
    '62': 16.9,
    '63': 16.8,
    '64': 16.8,
    '65': 16.7,
    '66': 16.7,
    '67': 16.6,
    '68': 16.6,
    '69': 16.5,
    '70': 16.5,
    '71': 16.4,
    '72': 16.4,
    '73': 16.3,
    '74': 16.3,
    '75': 16.2,
    '76': 16.1,
    '77': 16.1,
    '78': 16,
    '79': 16,
    '80': 15.9,
    '81': 15.9,
    '82': 15.8,
    '83': 15.8,
    '84': 15.7,
    '85': 15.7,
    '86': 15.6,
    '87': 15.6,
    '88': 15.5,
    '89': 15.5,
    '90': 15.4,
    '91': 15.4,
    '92': 15.3,
    '93': 15.3,
    '94': 15.2,
    '95': 15.2,
    '96': 15.1,
    '97': 15,
    '98': 15,
    '99': 14.9,
    '100': 14.9,
    '101': 14.8,
    '102': 14.8,
    '103': 14.7,
    '104': 14.7,
    '105': 14.6,
    '106': 14.6,
    '107': 14.5,
    '108': 14.5,
    '109': 14.4,
    '110': 14.4,
    '111': 14.3,
    '112': 14.3,
    '113': 14.2,
    '114': 14.2,
    '115': 14.1,
    '116': 14.1,
    '117': 14,
    '118': 14,
    '119': 13.9,
    '120': 13.9,
    '121': 13.8,
    '122': 13.8,
    '123': 13.7,
    '124': 13.7,
    '125': 13.6,
    '126': 13.6,
    '127': 13.5,
    '128': 13.5,
    '129': 13.4,
    '130': 13.4,
    '131': 13.3,
    '132': 13.3,
    '133': 13.2,
    '134': 13.2,
    '135': 13.1,
    '136': 13.1,
    '137': 13,
    '138': 13,
    '139': 12.9,
    '140': 12.9,
    '141': 12.8,
    '142': 12.8,
    '143': 12.7,
    '144': 12.7,
    '145': 12.6,
    '146': 12.6,
    '147': 12.6,
    '148': 12.5,
    '149': 12.5,
    '150': 12.4,
    '151': 12.4,
    '152': 12.3,
    '153': 12.3,
    '154': 12.2,
    '155': 12.2,
    '156': 12.1,
    '157': 12.1,
    '158': 12,
    '159': 12,
    '160': 11.9,
    '161': 11.9,
    '162': 11.8,
    '163': 11.8,
    '164': 11.7,
    '165': 11.7,
    '166': 11.6,
    '167': 11.6,
    '168': 11.5,
    '169': 11.5,
    '170': 11.5,
    '171': 11.4,
    '172': 11.4,
    '173': 11.3,
    '174': 11.3,
    '175': 11.2,
    '176': 11.2,
    '177': 11.1,
    '178': 11.1,
    '179': 11,
    '180': 11,
    '181': 10.9,
    '182': 10.9,
    '183': 10.8,
    '184': 10.8,
    '185': 10.8,
    '186': 10.7,
    '187': 10.7,
    '188': 10.6,
    '189': 10.6,
    '190': 10.5,
    '191': 10.5,
    '192': 10.4,
    '193': 10.4,
    '194': 10.3,
    '195': 10.3,
    '196': 10.2,
    '197': 10.2,
    '198': 10.2,
    '199': 10.1,
    '200': 10.1,
    '201': 10,
    '202': 10,
    '203': 9.9,
    '204': 9.9,
    '205': 9.8,
    '206': 9.8,
    '207': 9.8,
    '208': 9.7,
    '209': 9.7,
    '210': 9.6,
    '211': 9.6,
    '212': 9.5,
    '213': 9.5,
    '214': 9.4,
    '215': 9.4,
    '216': 9.3,
    '217': 9.3,
    '218': 9.3,
    '219': 9.2,
    '220': 9.2,
    '221': 9.1,
    '222': 9.1,
    '223': 9,
    '224': 9,
    '225': 8.9,
    '0.5': 20.3,
    '1.5': 20.3,
    '2.5': 20.2,
    '3.5': 20.2,
    '4.5': 20.1,
    '5.5': 20,
    '6.5': 20,
    '7.5': 19.9,
    '8.5': 19.9,
    '9.5': 19.8,
    '10.5': 19.8,
    '11.5': 19.7,
    '12.5': 19.6,
    '13.5': 19.6,
    '14.5': 19.5,
    '15.5': 19.5,
    '16.5': 19.4,
    '17.5': 19.4,
    '18.5': 19.3,
    '19.5': 19.2,
    '20.5': 19.2,
    '21.5': 19.1,
    '22.5': 19.1,
    '23.5': 19,
    '24.5': 19,
    '25.5': 18.9,
    '26.5': 18.8,
    '27.5': 18.8,
    '28.5': 18.7,
    '29.5': 18.7,
    '30.5': 18.6,
    '31.5': 18.6,
    '32.5': 18.5,
    '33.5': 18.5,
    '34.5': 18.4,
    '35.5': 18.3,
    '36.5': 18.3,
    '37.5': 18.2,
    '38.5': 18.2,
    '39.5': 18.1,
    '40.5': 18.1,
    '41.5': 18,
    '42.5': 18,
    '43.5': 17.9,
    '44.5': 17.8,
    '45.5': 17.8,
    '46.5': 17.7,
    '47.5': 17.7,
    '48.5': 17.6,
    '49.5': 17.6,
    '50.5': 17.5,
    '51.5': 17.5,
    '52.5': 17.4,
    '53.5': 17.4,
    '54.5': 17.3,
    '55.5': 17.2,
    '56.5': 17.2,
    '57.5': 17.1,
    '58.5': 17.1,
    '59.5': 17,
    '60.5': 17,
    '61.5': 16.9,
    '62.5': 16.9,
    '63.5': 16.8,
    '64.5': 16.8,
    '65.5': 16.7,
    '66.5': 16.6,
    '67.5': 16.6,
    '68.5': 16.5,
    '69.5': 16.5,
    '70.5': 16.4,
    '71.5': 16.4,
    '72.5': 16.3,
    '73.5': 16.3,
    '74.5': 16.2,
    '75.5': 16.2,
    '76.5': 16.1,
    '77.5': 16.1,
    '78.5': 16,
    '79.5': 16,
    '80.5': 15.9,
    '81.5': 15.9,
    '82.5': 15.8,
    '83.5': 15.8,
    '84.5': 15.7,
    '85.5': 15.6,
    '86.5': 15.6,
    '87.5': 15.5,
    '88.5': 15.5,
    '89.5': 15.4,
    '90.5': 15.4,
    '91.5': 15.3,
    '92.5': 15.3,
    '93.5': 15.2,
    '94.5': 15.2,
    '95.5': 15.1,
    '96.5': 15.1,
    '97.5': 15,
    '98.5': 15,
    '99.5': 14.9,
    '100.5': 14.9,
    '101.5': 14.8,
    '102.5': 14.8,
    '103.5': 14.7,
    '104.5': 14.7,
    '105.5': 14.6,
    '106.5': 14.6,
    '107.5': 14.5,
    '108.5': 14.5,
    '109.5': 14.4,
    '110.5': 14.4,
    '111.5': 14.3,
    '112.5': 14.3,
    '113.5': 14.2,
    '114.5': 14.2,
    '115.5': 14.1,
    '116.5': 14.1,
    '117.5': 14,
    '118.5': 14,
    '119.5': 13.9,
    '120.5': 13.9,
    '121.5': 13.8,
    '122.5': 13.8,
    '123.5': 13.7,
    '124.5': 13.7,
    '125.5': 13.6,
    '126.5': 13.6,
    '127.5': 13.5,
    '128.5': 13.5,
    '129.5': 13.4,
    '130.5': 13.4,
    '131.5': 13.3,
    '132.5': 13.3,
    '133.5': 13.2,
    '134.5': 13.2,
    '135.5': 13.1,
    '136.5': 13.1,
    '137.5': 13,
    '138.5': 13,
    '139.5': 12.9,
    '140.5': 12.9,
    '141.5': 12.8,
    '142.5': 12.8,
    '143.5': 12.7,
    '144.5': 12.7,
    '145.5': 12.6,
    '146.5': 12.6,
    '147.5': 12.5,
    '148.5': 12.5,
    '149.5': 12.4,
    '150.5': 12.4,
    '151.5': 12.3,
    '152.5': 12.3,
    '153.5': 12.2,
    '154.5': 12.2,
    '155.5': 12.1,
    '156.5': 12.1,
    '157.5': 12,
    '158.5': 12,
    '159.5': 12,
    '160.5': 11.9,
    '161.5': 11.9,
    '162.5': 11.8,
    '163.5': 11.8,
    '164.5': 11.7,
    '165.5': 11.7,
    '166.5': 11.6,
    '167.5': 11.6,
    '168.5': 11.5,
    '169.5': 11.5,
    '170.5': 11.4,
    '171.5': 11.4,
    '172.5': 11.3,
    '173.5': 11.3,
    '174.5': 11.2,
    '175.5': 11.2,
    '176.5': 11.1,
    '177.5': 11.1,
    '178.5': 11.1,
    '179.5': 11,
    '180.5': 11,
    '181.5': 10.9,
    '182.5': 10.9,
    '183.5': 10.8,
    '184.5': 10.8,
    '185.5': 10.7,
    '186.5': 10.7,
    '187.5': 10.6,
    '188.5': 10.6,
    '189.5': 10.5,
    '190.5': 10.5,
    '191.5': 10.5,
    '192.5': 10.4,
    '193.5': 10.4,
    '194.5': 10.3,
    '195.5': 10.3,
    '196.5': 10.2,
    '197.5': 10.2,
    '198.5': 10.1,
    '199.5': 10.1,
    '200.5': 10,
    '201.5': 10,
    '202.5': 10,
    '203.5': 9.9,
    '204.5': 9.9,
    '205.5': 9.8,
    '206.5': 9.8,
    '207.5': 9.7,
    '208.5': 9.7,
    '209.5': 9.6,
    '210.5': 9.6,
    '211.5': 9.5,
    '212.5': 9.5,
    '213.5': 9.5,
    '214.5': 9.4,
    '215.5': 9.4,
    '216.5': 9.3,
    '217.5': 9.3,
    '218.5': 9.2,
    '219.5': 9.2,
    '220.5': 9.1,
    '221.5': 9.1,
    '222.5': 9.1,
    '223.5': 9,
    '224.5': 9,
  },
  '17.5': {
    '0': 20.9,
    '1': 20.8,
    '2': 20.8,
    '3': 20.7,
    '4': 20.7,
    '5': 20.6,
    '6': 20.6,
    '7': 20.5,
    '8': 20.4,
    '9': 20.4,
    '10': 20.3,
    '11': 20.3,
    '12': 20.2,
    '13': 20.1,
    '14': 20.1,
    '15': 20,
    '16': 20,
    '17': 19.9,
    '18': 19.9,
    '19': 19.8,
    '20': 19.7,
    '21': 19.7,
    '22': 19.6,
    '23': 19.6,
    '24': 19.5,
    '25': 19.5,
    '26': 19.4,
    '27': 19.3,
    '28': 19.3,
    '29': 19.2,
    '30': 19.2,
    '31': 19.1,
    '32': 19.1,
    '33': 19,
    '34': 18.9,
    '35': 18.9,
    '36': 18.8,
    '37': 18.8,
    '38': 18.7,
    '39': 18.7,
    '40': 18.6,
    '41': 18.5,
    '42': 18.5,
    '43': 18.4,
    '44': 18.4,
    '45': 18.3,
    '46': 18.3,
    '47': 18.2,
    '48': 18.2,
    '49': 18.1,
    '50': 18,
    '51': 18,
    '52': 17.9,
    '53': 17.9,
    '54': 17.8,
    '55': 17.8,
    '56': 17.7,
    '57': 17.7,
    '58': 17.6,
    '59': 17.6,
    '60': 17.5,
    '61': 17.4,
    '62': 17.4,
    '63': 17.3,
    '64': 17.3,
    '65': 17.2,
    '66': 17.2,
    '67': 17.1,
    '68': 17.1,
    '69': 17,
    '70': 17,
    '71': 16.9,
    '72': 16.9,
    '73': 16.8,
    '74': 16.7,
    '75': 16.7,
    '76': 16.6,
    '77': 16.6,
    '78': 16.5,
    '79': 16.5,
    '80': 16.4,
    '81': 16.4,
    '82': 16.3,
    '83': 16.3,
    '84': 16.2,
    '85': 16.2,
    '86': 16.1,
    '87': 16.1,
    '88': 16,
    '89': 15.9,
    '90': 15.9,
    '91': 15.8,
    '92': 15.8,
    '93': 15.7,
    '94': 15.7,
    '95': 15.6,
    '96': 15.6,
    '97': 15.5,
    '98': 15.5,
    '99': 15.4,
    '100': 15.4,
    '101': 15.3,
    '102': 15.3,
    '103': 15.2,
    '104': 15.2,
    '105': 15.1,
    '106': 15.1,
    '107': 15,
    '108': 15,
    '109': 14.9,
    '110': 14.9,
    '111': 14.8,
    '112': 14.8,
    '113': 14.7,
    '114': 14.7,
    '115': 14.6,
    '116': 14.6,
    '117': 14.5,
    '118': 14.4,
    '119': 14.4,
    '120': 14.3,
    '121': 14.3,
    '122': 14.2,
    '123': 14.2,
    '124': 14.1,
    '125': 14.1,
    '126': 14,
    '127': 14,
    '128': 13.9,
    '129': 13.9,
    '130': 13.8,
    '131': 13.8,
    '132': 13.7,
    '133': 13.7,
    '134': 13.6,
    '135': 13.6,
    '136': 13.5,
    '137': 13.5,
    '138': 13.4,
    '139': 13.4,
    '140': 13.4,
    '141': 13.3,
    '142': 13.3,
    '143': 13.2,
    '144': 13.2,
    '145': 13.1,
    '146': 13.1,
    '147': 13,
    '148': 13,
    '149': 12.9,
    '150': 12.9,
    '151': 12.8,
    '152': 12.8,
    '153': 12.7,
    '154': 12.7,
    '155': 12.6,
    '156': 12.6,
    '157': 12.5,
    '158': 12.5,
    '159': 12.4,
    '160': 12.4,
    '161': 12.3,
    '162': 12.3,
    '163': 12.2,
    '164': 12.2,
    '165': 12.1,
    '166': 12.1,
    '167': 12,
    '168': 12,
    '169': 11.9,
    '170': 11.9,
    '171': 11.8,
    '172': 11.8,
    '173': 11.8,
    '174': 11.7,
    '175': 11.7,
    '176': 11.6,
    '177': 11.6,
    '178': 11.5,
    '179': 11.5,
    '180': 11.4,
    '181': 11.4,
    '182': 11.3,
    '183': 11.3,
    '184': 11.2,
    '185': 11.2,
    '186': 11.1,
    '187': 11.1,
    '188': 11.1,
    '189': 11,
    '190': 11,
    '191': 10.9,
    '192': 10.9,
    '193': 10.8,
    '194': 10.8,
    '195': 10.7,
    '196': 10.7,
    '197': 10.6,
    '198': 10.6,
    '199': 10.5,
    '200': 10.5,
    '201': 10.5,
    '202': 10.4,
    '203': 10.4,
    '204': 10.3,
    '205': 10.3,
    '206': 10.2,
    '207': 10.2,
    '208': 10.1,
    '209': 10.1,
    '210': 10,
    '211': 10,
    '212': 10,
    '213': 9.9,
    '214': 9.9,
    '215': 9.8,
    '216': 9.8,
    '217': 9.7,
    '218': 9.7,
    '219': 9.6,
    '220': 9.6,
    '221': 9.5,
    '222': 9.5,
    '223': 9.5,
    '224': 9.4,
    '225': 9.4,
    '0.5': 20.9,
    '1.5': 20.8,
    '2.5': 20.8,
    '3.5': 20.7,
    '4.5': 20.6,
    '5.5': 20.6,
    '6.5': 20.5,
    '7.5': 20.5,
    '8.5': 20.4,
    '9.5': 20.3,
    '10.5': 20.3,
    '11.5': 20.2,
    '12.5': 20.2,
    '13.5': 20.1,
    '14.5': 20.1,
    '15.5': 20,
    '16.5': 19.9,
    '17.5': 19.9,
    '18.5': 19.8,
    '19.5': 19.8,
    '20.5': 19.7,
    '21.5': 19.7,
    '22.5': 19.6,
    '23.5': 19.5,
    '24.5': 19.5,
    '25.5': 19.4,
    '26.5': 19.4,
    '27.5': 19.3,
    '28.5': 19.3,
    '29.5': 19.2,
    '30.5': 19.1,
    '31.5': 19.1,
    '32.5': 19,
    '33.5': 19,
    '34.5': 18.9,
    '35.5': 18.9,
    '36.5': 18.8,
    '37.5': 18.7,
    '38.5': 18.7,
    '39.5': 18.6,
    '40.5': 18.6,
    '41.5': 18.5,
    '42.5': 18.5,
    '43.5': 18.4,
    '44.5': 18.4,
    '45.5': 18.3,
    '46.5': 18.2,
    '47.5': 18.2,
    '48.5': 18.1,
    '49.5': 18.1,
    '50.5': 18,
    '51.5': 18,
    '52.5': 17.9,
    '53.5': 17.9,
    '54.5': 17.8,
    '55.5': 17.7,
    '56.5': 17.7,
    '57.5': 17.6,
    '58.5': 17.6,
    '59.5': 17.5,
    '60.5': 17.5,
    '61.5': 17.4,
    '62.5': 17.4,
    '63.5': 17.3,
    '64.5': 17.3,
    '65.5': 17.2,
    '66.5': 17.1,
    '67.5': 17.1,
    '68.5': 17,
    '69.5': 17,
    '70.5': 16.9,
    '71.5': 16.9,
    '72.5': 16.8,
    '73.5': 16.8,
    '74.5': 16.7,
    '75.5': 16.7,
    '76.5': 16.6,
    '77.5': 16.6,
    '78.5': 16.5,
    '79.5': 16.4,
    '80.5': 16.4,
    '81.5': 16.3,
    '82.5': 16.3,
    '83.5': 16.2,
    '84.5': 16.2,
    '85.5': 16.1,
    '86.5': 16.1,
    '87.5': 16,
    '88.5': 16,
    '89.5': 15.9,
    '90.5': 15.9,
    '91.5': 15.8,
    '92.5': 15.8,
    '93.5': 15.7,
    '94.5': 15.7,
    '95.5': 15.6,
    '96.5': 15.6,
    '97.5': 15.5,
    '98.5': 15.5,
    '99.5': 15.4,
    '100.5': 15.3,
    '101.5': 15.3,
    '102.5': 15.2,
    '103.5': 15.2,
    '104.5': 15.1,
    '105.5': 15.1,
    '106.5': 15,
    '107.5': 15,
    '108.5': 14.9,
    '109.5': 14.9,
    '110.5': 14.8,
    '111.5': 14.8,
    '112.5': 14.7,
    '113.5': 14.7,
    '114.5': 14.6,
    '115.5': 14.6,
    '116.5': 14.5,
    '117.5': 14.5,
    '118.5': 14.4,
    '119.5': 14.4,
    '120.5': 14.3,
    '121.5': 14.3,
    '122.5': 14.2,
    '123.5': 14.2,
    '124.5': 14.1,
    '125.5': 14.1,
    '126.5': 14,
    '127.5': 14,
    '128.5': 13.9,
    '129.5': 13.9,
    '130.5': 13.8,
    '131.5': 13.8,
    '132.5': 13.7,
    '133.5': 13.7,
    '134.5': 13.6,
    '135.5': 13.6,
    '136.5': 13.5,
    '137.5': 13.5,
    '138.5': 13.4,
    '139.5': 13.4,
    '140.5': 13.3,
    '141.5': 13.3,
    '142.5': 13.2,
    '143.5': 13.2,
    '144.5': 13.1,
    '145.5': 13.1,
    '146.5': 13,
    '147.5': 13,
    '148.5': 12.9,
    '149.5': 12.9,
    '150.5': 12.8,
    '151.5': 12.8,
    '152.5': 12.7,
    '153.5': 12.7,
    '154.5': 12.6,
    '155.5': 12.6,
    '156.5': 12.5,
    '157.5': 12.5,
    '158.5': 12.4,
    '159.5': 12.4,
    '160.5': 12.4,
    '161.5': 12.3,
    '162.5': 12.3,
    '163.5': 12.2,
    '164.5': 12.2,
    '165.5': 12.1,
    '166.5': 12.1,
    '167.5': 12,
    '168.5': 12,
    '169.5': 11.9,
    '170.5': 11.9,
    '171.5': 11.8,
    '172.5': 11.8,
    '173.5': 11.7,
    '174.5': 11.7,
    '175.5': 11.6,
    '176.5': 11.6,
    '177.5': 11.5,
    '178.5': 11.5,
    '179.5': 11.4,
    '180.5': 11.4,
    '181.5': 11.4,
    '182.5': 11.3,
    '183.5': 11.3,
    '184.5': 11.2,
    '185.5': 11.2,
    '186.5': 11.1,
    '187.5': 11.1,
    '188.5': 11,
    '189.5': 11,
    '190.5': 10.9,
    '191.5': 10.9,
    '192.5': 10.8,
    '193.5': 10.8,
    '194.5': 10.8,
    '195.5': 10.7,
    '196.5': 10.7,
    '197.5': 10.6,
    '198.5': 10.6,
    '199.5': 10.5,
    '200.5': 10.5,
    '201.5': 10.4,
    '202.5': 10.4,
    '203.5': 10.3,
    '204.5': 10.3,
    '205.5': 10.2,
    '206.5': 10.2,
    '207.5': 10.2,
    '208.5': 10.1,
    '209.5': 10.1,
    '210.5': 10,
    '211.5': 10,
    '212.5': 9.9,
    '213.5': 9.9,
    '214.5': 9.8,
    '215.5': 9.8,
    '216.5': 9.7,
    '217.5': 9.7,
    '218.5': 9.7,
    '219.5': 9.6,
    '220.5': 9.6,
    '221.5': 9.5,
    '222.5': 9.5,
    '223.5': 9.4,
    '224.5': 9.4,
  },
  '18': {
    '0': 21.4,
    '1': 21.4,
    '2': 21.3,
    '3': 21.3,
    '4': 21.2,
    '5': 21.1,
    '6': 21.1,
    '7': 21,
    '8': 21,
    '9': 20.9,
    '10': 20.8,
    '11': 20.8,
    '12': 20.7,
    '13': 20.7,
    '14': 20.6,
    '15': 20.6,
    '16': 20.5,
    '17': 20.4,
    '18': 20.4,
    '19': 20.3,
    '20': 20.3,
    '21': 20.2,
    '22': 20.1,
    '23': 20.1,
    '24': 20,
    '25': 20,
    '26': 19.9,
    '27': 19.9,
    '28': 19.8,
    '29': 19.7,
    '30': 19.7,
    '31': 19.6,
    '32': 19.6,
    '33': 19.5,
    '34': 19.5,
    '35': 19.4,
    '36': 19.3,
    '37': 19.3,
    '38': 19.2,
    '39': 19.2,
    '40': 19.1,
    '41': 19.1,
    '42': 19,
    '43': 18.9,
    '44': 18.9,
    '45': 18.8,
    '46': 18.8,
    '47': 18.7,
    '48': 18.7,
    '49': 18.6,
    '50': 18.6,
    '51': 18.5,
    '52': 18.4,
    '53': 18.4,
    '54': 18.3,
    '55': 18.3,
    '56': 18.2,
    '57': 18.2,
    '58': 18.1,
    '59': 18.1,
    '60': 18,
    '61': 17.9,
    '62': 17.9,
    '63': 17.8,
    '64': 17.8,
    '65': 17.7,
    '66': 17.7,
    '67': 17.6,
    '68': 17.6,
    '69': 17.5,
    '70': 17.5,
    '71': 17.4,
    '72': 17.3,
    '73': 17.3,
    '74': 17.2,
    '75': 17.2,
    '76': 17.1,
    '77': 17.1,
    '78': 17,
    '79': 17,
    '80': 16.9,
    '81': 16.9,
    '82': 16.8,
    '83': 16.8,
    '84': 16.7,
    '85': 16.6,
    '86': 16.6,
    '87': 16.5,
    '88': 16.5,
    '89': 16.4,
    '90': 16.4,
    '91': 16.3,
    '92': 16.3,
    '93': 16.2,
    '94': 16.2,
    '95': 16.1,
    '96': 16.1,
    '97': 16,
    '98': 16,
    '99': 15.9,
    '100': 15.9,
    '101': 15.8,
    '102': 15.7,
    '103': 15.7,
    '104': 15.6,
    '105': 15.6,
    '106': 15.5,
    '107': 15.5,
    '108': 15.4,
    '109': 15.4,
    '110': 15.3,
    '111': 15.3,
    '112': 15.2,
    '113': 15.2,
    '114': 15.1,
    '115': 15.1,
    '116': 15,
    '117': 15,
    '118': 14.9,
    '119': 14.9,
    '120': 14.8,
    '121': 14.8,
    '122': 14.7,
    '123': 14.7,
    '124': 14.6,
    '125': 14.6,
    '126': 14.5,
    '127': 14.5,
    '128': 14.4,
    '129': 14.4,
    '130': 14.3,
    '131': 14.3,
    '132': 14.2,
    '133': 14.2,
    '134': 14.1,
    '135': 14.1,
    '136': 14,
    '137': 13.9,
    '138': 13.9,
    '139': 13.8,
    '140': 13.8,
    '141': 13.7,
    '142': 13.7,
    '143': 13.6,
    '144': 13.6,
    '145': 13.5,
    '146': 13.5,
    '147': 13.4,
    '148': 13.4,
    '149': 13.4,
    '150': 13.3,
    '151': 13.3,
    '152': 13.2,
    '153': 13.2,
    '154': 13.1,
    '155': 13.1,
    '156': 13,
    '157': 13,
    '158': 12.9,
    '159': 12.9,
    '160': 12.8,
    '161': 12.8,
    '162': 12.7,
    '163': 12.7,
    '164': 12.6,
    '165': 12.6,
    '166': 12.5,
    '167': 12.5,
    '168': 12.4,
    '169': 12.4,
    '170': 12.3,
    '171': 12.3,
    '172': 12.2,
    '173': 12.2,
    '174': 12.2,
    '175': 12.1,
    '176': 12.1,
    '177': 12,
    '178': 12,
    '179': 11.9,
    '180': 11.9,
    '181': 11.8,
    '182': 11.8,
    '183': 11.7,
    '184': 11.7,
    '185': 11.6,
    '186': 11.6,
    '187': 11.5,
    '188': 11.5,
    '189': 11.4,
    '190': 11.4,
    '191': 11.3,
    '192': 11.3,
    '193': 11.3,
    '194': 11.2,
    '195': 11.2,
    '196': 11.1,
    '197': 11.1,
    '198': 11,
    '199': 11,
    '200': 10.9,
    '201': 10.9,
    '202': 10.8,
    '203': 10.8,
    '204': 10.7,
    '205': 10.7,
    '206': 10.7,
    '207': 10.6,
    '208': 10.6,
    '209': 10.5,
    '210': 10.5,
    '211': 10.4,
    '212': 10.4,
    '213': 10.3,
    '214': 10.3,
    '215': 10.2,
    '216': 10.2,
    '217': 10.2,
    '218': 10.1,
    '219': 10.1,
    '220': 10,
    '221': 10,
    '222': 9.9,
    '223': 9.9,
    '224': 9.8,
    '225': 9.8,
    '0.5': 21.4,
    '1.5': 21.4,
    '2.5': 21.3,
    '3.5': 21.2,
    '4.5': 21.2,
    '5.5': 21.1,
    '6.5': 21.1,
    '7.5': 21,
    '8.5': 20.9,
    '9.5': 20.9,
    '10.5': 20.8,
    '11.5': 20.8,
    '12.5': 20.7,
    '13.5': 20.6,
    '14.5': 20.6,
    '15.5': 20.5,
    '16.5': 20.5,
    '17.5': 20.4,
    '18.5': 20.4,
    '19.5': 20.3,
    '20.5': 20.2,
    '21.5': 20.2,
    '22.5': 20.1,
    '23.5': 20.1,
    '24.5': 20,
    '25.5': 19.9,
    '26.5': 19.9,
    '27.5': 19.8,
    '28.5': 19.8,
    '29.5': 19.7,
    '30.5': 19.7,
    '31.5': 19.6,
    '32.5': 19.5,
    '33.5': 19.5,
    '34.5': 19.4,
    '35.5': 19.4,
    '36.5': 19.3,
    '37.5': 19.3,
    '38.5': 19.2,
    '39.5': 19.1,
    '40.5': 19.1,
    '41.5': 19,
    '42.5': 19,
    '43.5': 18.9,
    '44.5': 18.9,
    '45.5': 18.8,
    '46.5': 18.8,
    '47.5': 18.7,
    '48.5': 18.6,
    '49.5': 18.6,
    '50.5': 18.5,
    '51.5': 18.5,
    '52.5': 18.4,
    '53.5': 18.4,
    '54.5': 18.3,
    '55.5': 18.3,
    '56.5': 18.2,
    '57.5': 18.1,
    '58.5': 18.1,
    '59.5': 18,
    '60.5': 18,
    '61.5': 17.9,
    '62.5': 17.9,
    '63.5': 17.8,
    '64.5': 17.8,
    '65.5': 17.7,
    '66.5': 17.6,
    '67.5': 17.6,
    '68.5': 17.5,
    '69.5': 17.5,
    '70.5': 17.4,
    '71.5': 17.4,
    '72.5': 17.3,
    '73.5': 17.3,
    '74.5': 17.2,
    '75.5': 17.2,
    '76.5': 17.1,
    '77.5': 17,
    '78.5': 17,
    '79.5': 16.9,
    '80.5': 16.9,
    '81.5': 16.8,
    '82.5': 16.8,
    '83.5': 16.7,
    '84.5': 16.7,
    '85.5': 16.6,
    '86.5': 16.6,
    '87.5': 16.5,
    '88.5': 16.5,
    '89.5': 16.4,
    '90.5': 16.4,
    '91.5': 16.3,
    '92.5': 16.2,
    '93.5': 16.2,
    '94.5': 16.1,
    '95.5': 16.1,
    '96.5': 16,
    '97.5': 16,
    '98.5': 15.9,
    '99.5': 15.9,
    '100.5': 15.8,
    '101.5': 15.8,
    '102.5': 15.7,
    '103.5': 15.7,
    '104.5': 15.6,
    '105.5': 15.6,
    '106.5': 15.5,
    '107.5': 15.5,
    '108.5': 15.4,
    '109.5': 15.4,
    '110.5': 15.3,
    '111.5': 15.3,
    '112.5': 15.2,
    '113.5': 15.1,
    '114.5': 15.1,
    '115.5': 15,
    '116.5': 15,
    '117.5': 14.9,
    '118.5': 14.9,
    '119.5': 14.8,
    '120.5': 14.8,
    '121.5': 14.7,
    '122.5': 14.7,
    '123.5': 14.6,
    '124.5': 14.6,
    '125.5': 14.5,
    '126.5': 14.5,
    '127.5': 14.4,
    '128.5': 14.4,
    '129.5': 14.3,
    '130.5': 14.3,
    '131.5': 14.2,
    '132.5': 14.2,
    '133.5': 14.1,
    '134.5': 14.1,
    '135.5': 14,
    '136.5': 14,
    '137.5': 13.9,
    '138.5': 13.9,
    '139.5': 13.8,
    '140.5': 13.8,
    '141.5': 13.7,
    '142.5': 13.7,
    '143.5': 13.6,
    '144.5': 13.6,
    '145.5': 13.5,
    '146.5': 13.5,
    '147.5': 13.4,
    '148.5': 13.4,
    '149.5': 13.3,
    '150.5': 13.3,
    '151.5': 13.2,
    '152.5': 13.2,
    '153.5': 13.1,
    '154.5': 13.1,
    '155.5': 13,
    '156.5': 13,
    '157.5': 12.9,
    '158.5': 12.9,
    '159.5': 12.8,
    '160.5': 12.8,
    '161.5': 12.8,
    '162.5': 12.7,
    '163.5': 12.7,
    '164.5': 12.6,
    '165.5': 12.6,
    '166.5': 12.5,
    '167.5': 12.5,
    '168.5': 12.4,
    '169.5': 12.4,
    '170.5': 12.3,
    '171.5': 12.3,
    '172.5': 12.2,
    '173.5': 12.2,
    '174.5': 12.1,
    '175.5': 12.1,
    '176.5': 12,
    '177.5': 12,
    '178.5': 11.9,
    '179.5': 11.9,
    '180.5': 11.8,
    '181.5': 11.8,
    '182.5': 11.7,
    '183.5': 11.7,
    '184.5': 11.7,
    '185.5': 11.6,
    '186.5': 11.6,
    '187.5': 11.5,
    '188.5': 11.5,
    '189.5': 11.4,
    '190.5': 11.4,
    '191.5': 11.3,
    '192.5': 11.3,
    '193.5': 11.2,
    '194.5': 11.2,
    '195.5': 11.1,
    '196.5': 11.1,
    '197.5': 11,
    '198.5': 11,
    '199.5': 11,
    '200.5': 10.9,
    '201.5': 10.9,
    '202.5': 10.8,
    '203.5': 10.8,
    '204.5': 10.7,
    '205.5': 10.7,
    '206.5': 10.6,
    '207.5': 10.6,
    '208.5': 10.5,
    '209.5': 10.5,
    '210.5': 10.4,
    '211.5': 10.4,
    '212.5': 10.4,
    '213.5': 10.3,
    '214.5': 10.3,
    '215.5': 10.2,
    '216.5': 10.2,
    '217.5': 10.1,
    '218.5': 10.1,
    '219.5': 10,
    '220.5': 10,
    '221.5': 9.9,
    '222.5': 9.9,
    '223.5': 9.9,
    '224.5': 9.8,
  },
  '18.5': {
    '0': 22,
    '1': 21.9,
    '2': 21.9,
    '3': 21.8,
    '4': 21.7,
    '5': 21.7,
    '6': 21.6,
    '7': 21.6,
    '8': 21.5,
    '9': 21.4,
    '10': 21.4,
    '11': 21.3,
    '12': 21.3,
    '13': 21.2,
    '14': 21.1,
    '15': 21.1,
    '16': 21,
    '17': 21,
    '18': 20.9,
    '19': 20.8,
    '20': 20.8,
    '21': 20.7,
    '22': 20.7,
    '23': 20.6,
    '24': 20.6,
    '25': 20.5,
    '26': 20.4,
    '27': 20.4,
    '28': 20.3,
    '29': 20.3,
    '30': 20.2,
    '31': 20.1,
    '32': 20.1,
    '33': 20,
    '34': 20,
    '35': 19.9,
    '36': 19.9,
    '37': 19.8,
    '38': 19.7,
    '39': 19.7,
    '40': 19.6,
    '41': 19.6,
    '42': 19.5,
    '43': 19.5,
    '44': 19.4,
    '45': 19.3,
    '46': 19.3,
    '47': 19.2,
    '48': 19.2,
    '49': 19.1,
    '50': 19.1,
    '51': 19,
    '52': 18.9,
    '53': 18.9,
    '54': 18.8,
    '55': 18.8,
    '56': 18.7,
    '57': 18.7,
    '58': 18.6,
    '59': 18.6,
    '60': 18.5,
    '61': 18.4,
    '62': 18.4,
    '63': 18.3,
    '64': 18.3,
    '65': 18.2,
    '66': 18.2,
    '67': 18.1,
    '68': 18.1,
    '69': 18,
    '70': 17.9,
    '71': 17.9,
    '72': 17.8,
    '73': 17.8,
    '74': 17.7,
    '75': 17.7,
    '76': 17.6,
    '77': 17.6,
    '78': 17.5,
    '79': 17.5,
    '80': 17.4,
    '81': 17.3,
    '82': 17.3,
    '83': 17.2,
    '84': 17.2,
    '85': 17.1,
    '86': 17.1,
    '87': 17,
    '88': 17,
    '89': 16.9,
    '90': 16.9,
    '91': 16.8,
    '92': 16.8,
    '93': 16.7,
    '94': 16.6,
    '95': 16.6,
    '96': 16.5,
    '97': 16.5,
    '98': 16.4,
    '99': 16.4,
    '100': 16.3,
    '101': 16.3,
    '102': 16.2,
    '103': 16.2,
    '104': 16.1,
    '105': 16.1,
    '106': 16,
    '107': 16,
    '108': 15.9,
    '109': 15.9,
    '110': 15.8,
    '111': 15.8,
    '112': 15.7,
    '113': 15.6,
    '114': 15.6,
    '115': 15.5,
    '116': 15.5,
    '117': 15.4,
    '118': 15.4,
    '119': 15.3,
    '120': 15.3,
    '121': 15.2,
    '122': 15.2,
    '123': 15.1,
    '124': 15.1,
    '125': 15,
    '126': 15,
    '127': 14.9,
    '128': 14.9,
    '129': 14.8,
    '130': 14.8,
    '131': 14.7,
    '132': 14.7,
    '133': 14.6,
    '134': 14.6,
    '135': 14.5,
    '136': 14.5,
    '137': 14.4,
    '138': 14.4,
    '139': 14.3,
    '140': 14.3,
    '141': 14.2,
    '142': 14.2,
    '143': 14.1,
    '144': 14.1,
    '145': 14,
    '146': 14,
    '147': 13.9,
    '148': 13.9,
    '149': 13.8,
    '150': 13.8,
    '151': 13.7,
    '152': 13.7,
    '153': 13.6,
    '154': 13.6,
    '155': 13.5,
    '156': 13.5,
    '157': 13.4,
    '158': 13.4,
    '159': 13.3,
    '160': 13.3,
    '161': 13.2,
    '162': 13.2,
    '163': 13.1,
    '164': 13.1,
    '165': 13,
    '166': 13,
    '167': 12.9,
    '168': 12.9,
    '169': 12.8,
    '170': 12.8,
    '171': 12.7,
    '172': 12.7,
    '173': 12.6,
    '174': 12.6,
    '175': 12.5,
    '176': 12.5,
    '177': 12.4,
    '178': 12.4,
    '179': 12.4,
    '180': 12.3,
    '181': 12.3,
    '182': 12.2,
    '183': 12.2,
    '184': 12.1,
    '185': 12.1,
    '186': 12,
    '187': 12,
    '188': 11.9,
    '189': 11.9,
    '190': 11.8,
    '191': 11.8,
    '192': 11.7,
    '193': 11.7,
    '194': 11.6,
    '195': 11.6,
    '196': 11.5,
    '197': 11.5,
    '198': 11.5,
    '199': 11.4,
    '200': 11.4,
    '201': 11.3,
    '202': 11.3,
    '203': 11.2,
    '204': 11.2,
    '205': 11.1,
    '206': 11.1,
    '207': 11,
    '208': 11,
    '209': 10.9,
    '210': 10.9,
    '211': 10.9,
    '212': 10.8,
    '213': 10.8,
    '214': 10.7,
    '215': 10.7,
    '216': 10.6,
    '217': 10.6,
    '218': 10.5,
    '219': 10.5,
    '220': 10.4,
    '221': 10.4,
    '222': 10.3,
    '223': 10.3,
    '224': 10.3,
    '225': 10.2,
    '0.5': 21.9,
    '1.5': 21.9,
    '2.5': 21.8,
    '3.5': 21.8,
    '4.5': 21.7,
    '5.5': 21.6,
    '6.5': 21.6,
    '7.5': 21.5,
    '8.5': 21.5,
    '9.5': 21.4,
    '10.5': 21.3,
    '11.5': 21.3,
    '12.5': 21.2,
    '13.5': 21.2,
    '14.5': 21.1,
    '15.5': 21.1,
    '16.5': 21,
    '17.5': 20.9,
    '18.5': 20.9,
    '19.5': 20.8,
    '20.5': 20.8,
    '21.5': 20.7,
    '22.5': 20.6,
    '23.5': 20.6,
    '24.5': 20.5,
    '25.5': 20.5,
    '26.5': 20.4,
    '27.5': 20.3,
    '28.5': 20.3,
    '29.5': 20.2,
    '30.5': 20.2,
    '31.5': 20.1,
    '32.5': 20.1,
    '33.5': 20,
    '34.5': 19.9,
    '35.5': 19.9,
    '36.5': 19.8,
    '37.5': 19.8,
    '38.5': 19.7,
    '39.5': 19.7,
    '40.5': 19.6,
    '41.5': 19.5,
    '42.5': 19.5,
    '43.5': 19.4,
    '44.5': 19.4,
    '45.5': 19.3,
    '46.5': 19.3,
    '47.5': 19.2,
    '48.5': 19.1,
    '49.5': 19.1,
    '50.5': 19,
    '51.5': 19,
    '52.5': 18.9,
    '53.5': 18.9,
    '54.5': 18.8,
    '55.5': 18.8,
    '56.5': 18.7,
    '57.5': 18.6,
    '58.5': 18.6,
    '59.5': 18.5,
    '60.5': 18.5,
    '61.5': 18.4,
    '62.5': 18.4,
    '63.5': 18.3,
    '64.5': 18.2,
    '65.5': 18.2,
    '66.5': 18.1,
    '67.5': 18.1,
    '68.5': 18,
    '69.5': 18,
    '70.5': 17.9,
    '71.5': 17.9,
    '72.5': 17.8,
    '73.5': 17.8,
    '74.5': 17.7,
    '75.5': 17.6,
    '76.5': 17.6,
    '77.5': 17.5,
    '78.5': 17.5,
    '79.5': 17.4,
    '80.5': 17.4,
    '81.5': 17.3,
    '82.5': 17.3,
    '83.5': 17.2,
    '84.5': 17.2,
    '85.5': 17.1,
    '86.5': 17,
    '87.5': 17,
    '88.5': 16.9,
    '89.5': 16.9,
    '90.5': 16.8,
    '91.5': 16.8,
    '92.5': 16.7,
    '93.5': 16.7,
    '94.5': 16.6,
    '95.5': 16.6,
    '96.5': 16.5,
    '97.5': 16.5,
    '98.5': 16.4,
    '99.5': 16.4,
    '100.5': 16.3,
    '101.5': 16.3,
    '102.5': 16.2,
    '103.5': 16.1,
    '104.5': 16.1,
    '105.5': 16,
    '106.5': 16,
    '107.5': 15.9,
    '108.5': 15.9,
    '109.5': 15.8,
    '110.5': 15.8,
    '111.5': 15.7,
    '112.5': 15.7,
    '113.5': 15.6,
    '114.5': 15.6,
    '115.5': 15.5,
    '116.5': 15.5,
    '117.5': 15.4,
    '118.5': 15.4,
    '119.5': 15.3,
    '120.5': 15.3,
    '121.5': 15.2,
    '122.5': 15.2,
    '123.5': 15.1,
    '124.5': 15.1,
    '125.5': 15,
    '126.5': 15,
    '127.5': 14.9,
    '128.5': 14.8,
    '129.5': 14.8,
    '130.5': 14.7,
    '131.5': 14.7,
    '132.5': 14.6,
    '133.5': 14.6,
    '134.5': 14.5,
    '135.5': 14.5,
    '136.5': 14.4,
    '137.5': 14.4,
    '138.5': 14.3,
    '139.5': 14.3,
    '140.5': 14.2,
    '141.5': 14.2,
    '142.5': 14.1,
    '143.5': 14.1,
    '144.5': 14,
    '145.5': 14,
    '146.5': 13.9,
    '147.5': 13.9,
    '148.5': 13.8,
    '149.5': 13.8,
    '150.5': 13.7,
    '151.5': 13.7,
    '152.5': 13.6,
    '153.5': 13.6,
    '154.5': 13.5,
    '155.5': 13.5,
    '156.5': 13.4,
    '157.5': 13.4,
    '158.5': 13.3,
    '159.5': 13.3,
    '160.5': 13.3,
    '161.5': 13.2,
    '162.5': 13.2,
    '163.5': 13.1,
    '164.5': 13.1,
    '165.5': 13,
    '166.5': 13,
    '167.5': 12.9,
    '168.5': 12.9,
    '169.5': 12.8,
    '170.5': 12.8,
    '171.5': 12.7,
    '172.5': 12.7,
    '173.5': 12.6,
    '174.5': 12.6,
    '175.5': 12.5,
    '176.5': 12.5,
    '177.5': 12.4,
    '178.5': 12.4,
    '179.5': 12.3,
    '180.5': 12.3,
    '181.5': 12.2,
    '182.5': 12.2,
    '183.5': 12.1,
    '184.5': 12.1,
    '185.5': 12,
    '186.5': 12,
    '187.5': 11.9,
    '188.5': 11.9,
    '189.5': 11.9,
    '190.5': 11.8,
    '191.5': 11.8,
    '192.5': 11.7,
    '193.5': 11.7,
    '194.5': 11.6,
    '195.5': 11.6,
    '196.5': 11.5,
    '197.5': 11.5,
    '198.5': 11.4,
    '199.5': 11.4,
    '200.5': 11.3,
    '201.5': 11.3,
    '202.5': 11.2,
    '203.5': 11.2,
    '204.5': 11.2,
    '205.5': 11.1,
    '206.5': 11.1,
    '207.5': 11,
    '208.5': 11,
    '209.5': 10.9,
    '210.5': 10.9,
    '211.5': 10.8,
    '212.5': 10.8,
    '213.5': 10.7,
    '214.5': 10.7,
    '215.5': 10.6,
    '216.5': 10.6,
    '217.5': 10.6,
    '218.5': 10.5,
    '219.5': 10.5,
    '220.5': 10.4,
    '221.5': 10.4,
    '222.5': 10.3,
    '223.5': 10.3,
    '224.5': 10.2,
  },
  '19': {
    '0': 22.5,
    '1': 22.5,
    '2': 22.4,
    '3': 22.3,
    '4': 22.3,
    '5': 22.2,
    '6': 22.1,
    '7': 22.1,
    '8': 22,
    '9': 22,
    '10': 21.9,
    '11': 21.8,
    '12': 21.8,
    '13': 21.7,
    '14': 21.7,
    '15': 21.6,
    '16': 21.5,
    '17': 21.5,
    '18': 21.4,
    '19': 21.4,
    '20': 21.3,
    '21': 21.3,
    '22': 21.2,
    '23': 21.1,
    '24': 21.1,
    '25': 21,
    '26': 21,
    '27': 20.9,
    '28': 20.8,
    '29': 20.8,
    '30': 20.7,
    '31': 20.7,
    '32': 20.6,
    '33': 20.5,
    '34': 20.5,
    '35': 20.4,
    '36': 20.4,
    '37': 20.3,
    '38': 20.3,
    '39': 20.2,
    '40': 20.1,
    '41': 20.1,
    '42': 20,
    '43': 20,
    '44': 19.9,
    '45': 19.9,
    '46': 19.8,
    '47': 19.7,
    '48': 19.7,
    '49': 19.6,
    '50': 19.6,
    '51': 19.5,
    '52': 19.5,
    '53': 19.4,
    '54': 19.3,
    '55': 19.3,
    '56': 19.2,
    '57': 19.2,
    '58': 19.1,
    '59': 19.1,
    '60': 19,
    '61': 18.9,
    '62': 18.9,
    '63': 18.8,
    '64': 18.8,
    '65': 18.7,
    '66': 18.7,
    '67': 18.6,
    '68': 18.6,
    '69': 18.5,
    '70': 18.4,
    '71': 18.4,
    '72': 18.3,
    '73': 18.3,
    '74': 18.2,
    '75': 18.2,
    '76': 18.1,
    '77': 18.1,
    '78': 18,
    '79': 17.9,
    '80': 17.9,
    '81': 17.8,
    '82': 17.8,
    '83': 17.7,
    '84': 17.7,
    '85': 17.6,
    '86': 17.6,
    '87': 17.5,
    '88': 17.5,
    '89': 17.4,
    '90': 17.3,
    '91': 17.3,
    '92': 17.2,
    '93': 17.2,
    '94': 17.1,
    '95': 17.1,
    '96': 17,
    '97': 17,
    '98': 16.9,
    '99': 16.9,
    '100': 16.8,
    '101': 16.8,
    '102': 16.7,
    '103': 16.6,
    '104': 16.6,
    '105': 16.5,
    '106': 16.5,
    '107': 16.4,
    '108': 16.4,
    '109': 16.3,
    '110': 16.3,
    '111': 16.2,
    '112': 16.2,
    '113': 16.1,
    '114': 16.1,
    '115': 16,
    '116': 16,
    '117': 15.9,
    '118': 15.9,
    '119': 15.8,
    '120': 15.8,
    '121': 15.7,
    '122': 15.6,
    '123': 15.6,
    '124': 15.5,
    '125': 15.5,
    '126': 15.4,
    '127': 15.4,
    '128': 15.3,
    '129': 15.3,
    '130': 15.2,
    '131': 15.2,
    '132': 15.1,
    '133': 15.1,
    '134': 15,
    '135': 15,
    '136': 14.9,
    '137': 14.9,
    '138': 14.8,
    '139': 14.8,
    '140': 14.7,
    '141': 14.7,
    '142': 14.6,
    '143': 14.6,
    '144': 14.5,
    '145': 14.5,
    '146': 14.4,
    '147': 14.4,
    '148': 14.3,
    '149': 14.3,
    '150': 14.2,
    '151': 14.2,
    '152': 14.1,
    '153': 14.1,
    '154': 14,
    '155': 14,
    '156': 13.9,
    '157': 13.9,
    '158': 13.8,
    '159': 13.8,
    '160': 13.7,
    '161': 13.7,
    '162': 13.6,
    '163': 13.6,
    '164': 13.5,
    '165': 13.5,
    '166': 13.4,
    '167': 13.4,
    '168': 13.3,
    '169': 13.3,
    '170': 13.2,
    '171': 13.2,
    '172': 13.1,
    '173': 13.1,
    '174': 13,
    '175': 13,
    '176': 12.9,
    '177': 12.9,
    '178': 12.8,
    '179': 12.8,
    '180': 12.7,
    '181': 12.7,
    '182': 12.6,
    '183': 12.6,
    '184': 12.6,
    '185': 12.5,
    '186': 12.5,
    '187': 12.4,
    '188': 12.4,
    '189': 12.3,
    '190': 12.3,
    '191': 12.2,
    '192': 12.2,
    '193': 12.1,
    '194': 12.1,
    '195': 12,
    '196': 12,
    '197': 11.9,
    '198': 11.9,
    '199': 11.8,
    '200': 11.8,
    '201': 11.7,
    '202': 11.7,
    '203': 11.7,
    '204': 11.6,
    '205': 11.6,
    '206': 11.5,
    '207': 11.5,
    '208': 11.4,
    '209': 11.4,
    '210': 11.3,
    '211': 11.3,
    '212': 11.2,
    '213': 11.2,
    '214': 11.1,
    '215': 11.1,
    '216': 11,
    '217': 11,
    '218': 11,
    '219': 10.9,
    '220': 10.9,
    '221': 10.8,
    '222': 10.8,
    '223': 10.7,
    '224': 10.7,
    '225': 10.6,
    '0.5': 22.5,
    '1.5': 22.4,
    '2.5': 22.4,
    '3.5': 22.3,
    '4.5': 22.2,
    '5.5': 22.2,
    '6.5': 22.1,
    '7.5': 22.1,
    '8.5': 22,
    '9.5': 21.9,
    '10.5': 21.9,
    '11.5': 21.8,
    '12.5': 21.8,
    '13.5': 21.7,
    '14.5': 21.6,
    '15.5': 21.6,
    '16.5': 21.5,
    '17.5': 21.5,
    '18.5': 21.4,
    '19.5': 21.3,
    '20.5': 21.3,
    '21.5': 21.2,
    '22.5': 21.2,
    '23.5': 21.1,
    '24.5': 21,
    '25.5': 21,
    '26.5': 20.9,
    '27.5': 20.9,
    '28.5': 20.8,
    '29.5': 20.8,
    '30.5': 20.7,
    '31.5': 20.6,
    '32.5': 20.6,
    '33.5': 20.5,
    '34.5': 20.5,
    '35.5': 20.4,
    '36.5': 20.3,
    '37.5': 20.3,
    '38.5': 20.2,
    '39.5': 20.2,
    '40.5': 20.1,
    '41.5': 20.1,
    '42.5': 20,
    '43.5': 19.9,
    '44.5': 19.9,
    '45.5': 19.8,
    '46.5': 19.8,
    '47.5': 19.7,
    '48.5': 19.7,
    '49.5': 19.6,
    '50.5': 19.5,
    '51.5': 19.5,
    '52.5': 19.4,
    '53.5': 19.4,
    '54.5': 19.3,
    '55.5': 19.3,
    '56.5': 19.2,
    '57.5': 19.1,
    '58.5': 19.1,
    '59.5': 19,
    '60.5': 19,
    '61.5': 18.9,
    '62.5': 18.9,
    '63.5': 18.8,
    '64.5': 18.7,
    '65.5': 18.7,
    '66.5': 18.6,
    '67.5': 18.6,
    '68.5': 18.5,
    '69.5': 18.5,
    '70.5': 18.4,
    '71.5': 18.4,
    '72.5': 18.3,
    '73.5': 18.2,
    '74.5': 18.2,
    '75.5': 18.1,
    '76.5': 18.1,
    '77.5': 18,
    '78.5': 18,
    '79.5': 17.9,
    '80.5': 17.9,
    '81.5': 17.8,
    '82.5': 17.8,
    '83.5': 17.7,
    '84.5': 17.6,
    '85.5': 17.6,
    '86.5': 17.5,
    '87.5': 17.5,
    '88.5': 17.4,
    '89.5': 17.4,
    '90.5': 17.3,
    '91.5': 17.3,
    '92.5': 17.2,
    '93.5': 17.2,
    '94.5': 17.1,
    '95.5': 17,
    '96.5': 17,
    '97.5': 16.9,
    '98.5': 16.9,
    '99.5': 16.8,
    '100.5': 16.8,
    '101.5': 16.7,
    '102.5': 16.7,
    '103.5': 16.6,
    '104.5': 16.6,
    '105.5': 16.5,
    '106.5': 16.5,
    '107.5': 16.4,
    '108.5': 16.4,
    '109.5': 16.3,
    '110.5': 16.3,
    '111.5': 16.2,
    '112.5': 16.1,
    '113.5': 16.1,
    '114.5': 16,
    '115.5': 16,
    '116.5': 15.9,
    '117.5': 15.9,
    '118.5': 15.8,
    '119.5': 15.8,
    '120.5': 15.7,
    '121.5': 15.7,
    '122.5': 15.6,
    '123.5': 15.6,
    '124.5': 15.5,
    '125.5': 15.5,
    '126.5': 15.4,
    '127.5': 15.4,
    '128.5': 15.3,
    '129.5': 15.3,
    '130.5': 15.2,
    '131.5': 15.2,
    '132.5': 15.1,
    '133.5': 15.1,
    '134.5': 15,
    '135.5': 15,
    '136.5': 14.9,
    '137.5': 14.9,
    '138.5': 14.8,
    '139.5': 14.8,
    '140.5': 14.7,
    '141.5': 14.6,
    '142.5': 14.6,
    '143.5': 14.5,
    '144.5': 14.5,
    '145.5': 14.4,
    '146.5': 14.4,
    '147.5': 14.3,
    '148.5': 14.3,
    '149.5': 14.2,
    '150.5': 14.2,
    '151.5': 14.1,
    '152.5': 14.1,
    '153.5': 14,
    '154.5': 14,
    '155.5': 13.9,
    '156.5': 13.9,
    '157.5': 13.8,
    '158.5': 13.8,
    '159.5': 13.7,
    '160.5': 13.7,
    '161.5': 13.6,
    '162.5': 13.6,
    '163.5': 13.6,
    '164.5': 13.5,
    '165.5': 13.5,
    '166.5': 13.4,
    '167.5': 13.4,
    '168.5': 13.3,
    '169.5': 13.3,
    '170.5': 13.2,
    '171.5': 13.2,
    '172.5': 13.1,
    '173.5': 13.1,
    '174.5': 13,
    '175.5': 13,
    '176.5': 12.9,
    '177.5': 12.9,
    '178.5': 12.8,
    '179.5': 12.8,
    '180.5': 12.7,
    '181.5': 12.7,
    '182.5': 12.6,
    '183.5': 12.6,
    '184.5': 12.5,
    '185.5': 12.5,
    '186.5': 12.4,
    '187.5': 12.4,
    '188.5': 12.3,
    '189.5': 12.3,
    '190.5': 12.2,
    '191.5': 12.2,
    '192.5': 12.1,
    '193.5': 12.1,
    '194.5': 12.1,
    '195.5': 12,
    '196.5': 12,
    '197.5': 11.9,
    '198.5': 11.9,
    '199.5': 11.8,
    '200.5': 11.8,
    '201.5': 11.7,
    '202.5': 11.7,
    '203.5': 11.6,
    '204.5': 11.6,
    '205.5': 11.5,
    '206.5': 11.5,
    '207.5': 11.4,
    '208.5': 11.4,
    '209.5': 11.3,
    '210.5': 11.3,
    '211.5': 11.3,
    '212.5': 11.2,
    '213.5': 11.2,
    '214.5': 11.1,
    '215.5': 11.1,
    '216.5': 11,
    '217.5': 11,
    '218.5': 10.9,
    '219.5': 10.9,
    '220.5': 10.8,
    '221.5': 10.8,
    '222.5': 10.7,
    '223.5': 10.7,
    '224.5': 10.7,
  },
  '19.5': {
    '0': 23,
    '1': 23,
    '2': 22.9,
    '3': 22.9,
    '4': 22.8,
    '5': 22.7,
    '6': 22.7,
    '7': 22.6,
    '8': 22.6,
    '9': 22.5,
    '10': 22.4,
    '11': 22.4,
    '12': 22.3,
    '13': 22.3,
    '14': 22.2,
    '15': 22.1,
    '16': 22.1,
    '17': 22,
    '18': 22,
    '19': 21.9,
    '20': 21.8,
    '21': 21.8,
    '22': 21.7,
    '23': 21.7,
    '24': 21.6,
    '25': 21.5,
    '26': 21.5,
    '27': 21.4,
    '28': 21.4,
    '29': 21.3,
    '30': 21.2,
    '31': 21.2,
    '32': 21.1,
    '33': 21.1,
    '34': 21,
    '35': 20.9,
    '36': 20.9,
    '37': 20.8,
    '38': 20.8,
    '39': 20.7,
    '40': 20.7,
    '41': 20.6,
    '42': 20.5,
    '43': 20.5,
    '44': 20.4,
    '45': 20.4,
    '46': 20.3,
    '47': 20.2,
    '48': 20.2,
    '49': 20.1,
    '50': 20.1,
    '51': 20,
    '52': 20,
    '53': 19.9,
    '54': 19.8,
    '55': 19.8,
    '56': 19.7,
    '57': 19.7,
    '58': 19.6,
    '59': 19.6,
    '60': 19.5,
    '61': 19.4,
    '62': 19.4,
    '63': 19.3,
    '64': 19.3,
    '65': 19.2,
    '66': 19.2,
    '67': 19.1,
    '68': 19,
    '69': 19,
    '70': 18.9,
    '71': 18.9,
    '72': 18.8,
    '73': 18.8,
    '74': 18.7,
    '75': 18.7,
    '76': 18.6,
    '77': 18.5,
    '78': 18.5,
    '79': 18.4,
    '80': 18.4,
    '81': 18.3,
    '82': 18.3,
    '83': 18.2,
    '84': 18.2,
    '85': 18.1,
    '86': 18,
    '87': 18,
    '88': 17.9,
    '89': 17.9,
    '90': 17.8,
    '91': 17.8,
    '92': 17.7,
    '93': 17.7,
    '94': 17.6,
    '95': 17.6,
    '96': 17.5,
    '97': 17.4,
    '98': 17.4,
    '99': 17.3,
    '100': 17.3,
    '101': 17.2,
    '102': 17.2,
    '103': 17.1,
    '104': 17.1,
    '105': 17,
    '106': 17,
    '107': 16.9,
    '108': 16.9,
    '109': 16.8,
    '110': 16.7,
    '111': 16.7,
    '112': 16.6,
    '113': 16.6,
    '114': 16.5,
    '115': 16.5,
    '116': 16.4,
    '117': 16.4,
    '118': 16.3,
    '119': 16.3,
    '120': 16.2,
    '121': 16.2,
    '122': 16.1,
    '123': 16.1,
    '124': 16,
    '125': 16,
    '126': 15.9,
    '127': 15.9,
    '128': 15.8,
    '129': 15.8,
    '130': 15.7,
    '131': 15.6,
    '132': 15.6,
    '133': 15.5,
    '134': 15.5,
    '135': 15.4,
    '136': 15.4,
    '137': 15.3,
    '138': 15.3,
    '139': 15.2,
    '140': 15.2,
    '141': 15.1,
    '142': 15.1,
    '143': 15,
    '144': 15,
    '145': 14.9,
    '146': 14.9,
    '147': 14.8,
    '148': 14.8,
    '149': 14.7,
    '150': 14.7,
    '151': 14.6,
    '152': 14.6,
    '153': 14.5,
    '154': 14.5,
    '155': 14.4,
    '156': 14.4,
    '157': 14.3,
    '158': 14.3,
    '159': 14.2,
    '160': 14.2,
    '161': 14.1,
    '162': 14.1,
    '163': 14,
    '164': 14,
    '165': 13.9,
    '166': 13.9,
    '167': 13.8,
    '168': 13.8,
    '169': 13.7,
    '170': 13.7,
    '171': 13.6,
    '172': 13.6,
    '173': 13.5,
    '174': 13.5,
    '175': 13.4,
    '176': 13.4,
    '177': 13.3,
    '178': 13.3,
    '179': 13.2,
    '180': 13.2,
    '181': 13.1,
    '182': 13.1,
    '183': 13,
    '184': 13,
    '185': 12.9,
    '186': 12.9,
    '187': 12.8,
    '188': 12.8,
    '189': 12.7,
    '190': 12.7,
    '191': 12.7,
    '192': 12.6,
    '193': 12.6,
    '194': 12.5,
    '195': 12.5,
    '196': 12.4,
    '197': 12.4,
    '198': 12.3,
    '199': 12.3,
    '200': 12.2,
    '201': 12.2,
    '202': 12.1,
    '203': 12.1,
    '204': 12,
    '205': 12,
    '206': 11.9,
    '207': 11.9,
    '208': 11.8,
    '209': 11.8,
    '210': 11.7,
    '211': 11.7,
    '212': 11.7,
    '213': 11.6,
    '214': 11.6,
    '215': 11.5,
    '216': 11.5,
    '217': 11.4,
    '218': 11.4,
    '219': 11.3,
    '220': 11.3,
    '221': 11.2,
    '222': 11.2,
    '223': 11.1,
    '224': 11.1,
    '225': 11.1,
    '0.5': 23,
    '1.5': 23,
    '2.5': 22.9,
    '3.5': 22.8,
    '4.5': 22.8,
    '5.5': 22.7,
    '6.5': 22.7,
    '7.5': 22.6,
    '8.5': 22.5,
    '9.5': 22.5,
    '10.5': 22.4,
    '11.5': 22.3,
    '12.5': 22.3,
    '13.5': 22.2,
    '14.5': 22.2,
    '15.5': 22.1,
    '16.5': 22,
    '17.5': 22,
    '18.5': 21.9,
    '19.5': 21.9,
    '20.5': 21.8,
    '21.5': 21.7,
    '22.5': 21.7,
    '23.5': 21.6,
    '24.5': 21.6,
    '25.5': 21.5,
    '26.5': 21.4,
    '27.5': 21.4,
    '28.5': 21.3,
    '29.5': 21.3,
    '30.5': 21.2,
    '31.5': 21.2,
    '32.5': 21.1,
    '33.5': 21,
    '34.5': 21,
    '35.5': 20.9,
    '36.5': 20.9,
    '37.5': 20.8,
    '38.5': 20.7,
    '39.5': 20.7,
    '40.5': 20.6,
    '41.5': 20.6,
    '42.5': 20.5,
    '43.5': 20.4,
    '44.5': 20.4,
    '45.5': 20.3,
    '46.5': 20.3,
    '47.5': 20.2,
    '48.5': 20.2,
    '49.5': 20.1,
    '50.5': 20,
    '51.5': 20,
    '52.5': 19.9,
    '53.5': 19.9,
    '54.5': 19.8,
    '55.5': 19.8,
    '56.5': 19.7,
    '57.5': 19.6,
    '58.5': 19.6,
    '59.5': 19.5,
    '60.5': 19.5,
    '61.5': 19.4,
    '62.5': 19.4,
    '63.5': 19.3,
    '64.5': 19.2,
    '65.5': 19.2,
    '66.5': 19.1,
    '67.5': 19.1,
    '68.5': 19,
    '69.5': 19,
    '70.5': 18.9,
    '71.5': 18.9,
    '72.5': 18.8,
    '73.5': 18.7,
    '74.5': 18.7,
    '75.5': 18.6,
    '76.5': 18.6,
    '77.5': 18.5,
    '78.5': 18.5,
    '79.5': 18.4,
    '80.5': 18.4,
    '81.5': 18.3,
    '82.5': 18.2,
    '83.5': 18.2,
    '84.5': 18.1,
    '85.5': 18.1,
    '86.5': 18,
    '87.5': 18,
    '88.5': 17.9,
    '89.5': 17.9,
    '90.5': 17.8,
    '91.5': 17.7,
    '92.5': 17.7,
    '93.5': 17.6,
    '94.5': 17.6,
    '95.5': 17.5,
    '96.5': 17.5,
    '97.5': 17.4,
    '98.5': 17.4,
    '99.5': 17.3,
    '100.5': 17.3,
    '101.5': 17.2,
    '102.5': 17.1,
    '103.5': 17.1,
    '104.5': 17,
    '105.5': 17,
    '106.5': 16.9,
    '107.5': 16.9,
    '108.5': 16.8,
    '109.5': 16.8,
    '110.5': 16.7,
    '111.5': 16.7,
    '112.5': 16.6,
    '113.5': 16.6,
    '114.5': 16.5,
    '115.5': 16.5,
    '116.5': 16.4,
    '117.5': 16.4,
    '118.5': 16.3,
    '119.5': 16.2,
    '120.5': 16.2,
    '121.5': 16.1,
    '122.5': 16.1,
    '123.5': 16,
    '124.5': 16,
    '125.5': 15.9,
    '126.5': 15.9,
    '127.5': 15.8,
    '128.5': 15.8,
    '129.5': 15.7,
    '130.5': 15.7,
    '131.5': 15.6,
    '132.5': 15.6,
    '133.5': 15.5,
    '134.5': 15.5,
    '135.5': 15.4,
    '136.5': 15.4,
    '137.5': 15.3,
    '138.5': 15.3,
    '139.5': 15.2,
    '140.5': 15.2,
    '141.5': 15.1,
    '142.5': 15.1,
    '143.5': 15,
    '144.5': 15,
    '145.5': 14.9,
    '146.5': 14.9,
    '147.5': 14.8,
    '148.5': 14.7,
    '149.5': 14.7,
    '150.5': 14.6,
    '151.5': 14.6,
    '152.5': 14.5,
    '153.5': 14.5,
    '154.5': 14.4,
    '155.5': 14.4,
    '156.5': 14.3,
    '157.5': 14.3,
    '158.5': 14.2,
    '159.5': 14.2,
    '160.5': 14.1,
    '161.5': 14.1,
    '162.5': 14,
    '163.5': 14,
    '164.5': 13.9,
    '165.5': 13.9,
    '166.5': 13.8,
    '167.5': 13.8,
    '168.5': 13.7,
    '169.5': 13.7,
    '170.5': 13.7,
    '171.5': 13.6,
    '172.5': 13.6,
    '173.5': 13.5,
    '174.5': 13.5,
    '175.5': 13.4,
    '176.5': 13.4,
    '177.5': 13.3,
    '178.5': 13.3,
    '179.5': 13.2,
    '180.5': 13.2,
    '181.5': 13.1,
    '182.5': 13.1,
    '183.5': 13,
    '184.5': 13,
    '185.5': 12.9,
    '186.5': 12.9,
    '187.5': 12.8,
    '188.5': 12.8,
    '189.5': 12.7,
    '190.5': 12.7,
    '191.5': 12.6,
    '192.5': 12.6,
    '193.5': 12.5,
    '194.5': 12.5,
    '195.5': 12.4,
    '196.5': 12.4,
    '197.5': 12.3,
    '198.5': 12.3,
    '199.5': 12.2,
    '200.5': 12.2,
    '201.5': 12.2,
    '202.5': 12.1,
    '203.5': 12.1,
    '204.5': 12,
    '205.5': 12,
    '206.5': 11.9,
    '207.5': 11.9,
    '208.5': 11.8,
    '209.5': 11.8,
    '210.5': 11.7,
    '211.5': 11.7,
    '212.5': 11.6,
    '213.5': 11.6,
    '214.5': 11.5,
    '215.5': 11.5,
    '216.5': 11.4,
    '217.5': 11.4,
    '218.5': 11.4,
    '219.5': 11.3,
    '220.5': 11.3,
    '221.5': 11.2,
    '222.5': 11.2,
    '223.5': 11.1,
    '224.5': 11.1,
  },
  '20': {
    '0': 23.6,
    '1': 23.5,
    '2': 23.5,
    '3': 23.4,
    '4': 23.3,
    '5': 23.3,
    '6': 23.2,
    '7': 23.2,
    '8': 23.1,
    '9': 23,
    '10': 23,
    '11': 22.9,
    '12': 22.8,
    '13': 22.8,
    '14': 22.7,
    '15': 22.7,
    '16': 22.6,
    '17': 22.5,
    '18': 22.5,
    '19': 22.4,
    '20': 22.4,
    '21': 22.3,
    '22': 22.2,
    '23': 22.2,
    '24': 22.1,
    '25': 22.1,
    '26': 22,
    '27': 21.9,
    '28': 21.9,
    '29': 21.8,
    '30': 21.8,
    '31': 21.7,
    '32': 21.6,
    '33': 21.6,
    '34': 21.5,
    '35': 21.5,
    '36': 21.4,
    '37': 21.3,
    '38': 21.3,
    '39': 21.2,
    '40': 21.2,
    '41': 21.1,
    '42': 21,
    '43': 21,
    '44': 20.9,
    '45': 20.9,
    '46': 20.8,
    '47': 20.8,
    '48': 20.7,
    '49': 20.6,
    '50': 20.6,
    '51': 20.5,
    '52': 20.5,
    '53': 20.4,
    '54': 20.3,
    '55': 20.3,
    '56': 20.2,
    '57': 20.2,
    '58': 20.1,
    '59': 20.1,
    '60': 20,
    '61': 19.9,
    '62': 19.9,
    '63': 19.9,
    '64': 19.8,
    '65': 19.7,
    '66': 19.7,
    '67': 19.6,
    '68': 19.5,
    '69': 19.5,
    '70': 19.4,
    '71': 19.4,
    '72': 19.3,
    '73': 19.3,
    '74': 19.2,
    '75': 19.1,
    '76': 19.1,
    '77': 19,
    '78': 19,
    '79': 18.9,
    '80': 18.9,
    '81': 18.8,
    '82': 18.8,
    '83': 18.7,
    '84': 18.6,
    '85': 18.6,
    '86': 18.5,
    '87': 18.5,
    '88': 18.4,
    '89': 18.4,
    '90': 18.3,
    '91': 18.3,
    '92': 18.2,
    '93': 18.1,
    '94': 18.1,
    '95': 18,
    '96': 18,
    '97': 17.9,
    '98': 17.9,
    '99': 17.8,
    '100': 17.8,
    '101': 17.7,
    '102': 17.7,
    '103': 17.6,
    '104': 17.5,
    '105': 17.5,
    '106': 17.4,
    '107': 17.4,
    '108': 17.3,
    '109': 17.3,
    '110': 17.2,
    '111': 17.2,
    '112': 17.1,
    '113': 17.1,
    '114': 17,
    '115': 17,
    '116': 16.9,
    '117': 16.8,
    '118': 16.8,
    '119': 16.7,
    '120': 16.7,
    '121': 16.6,
    '122': 16.6,
    '123': 16.5,
    '124': 16.5,
    '125': 16.4,
    '126': 16.4,
    '127': 16.3,
    '128': 16.3,
    '129': 16.2,
    '130': 16.2,
    '131': 16.1,
    '132': 16.1,
    '133': 16,
    '134': 16,
    '135': 15.9,
    '136': 15.8,
    '137': 15.8,
    '138': 15.7,
    '139': 15.7,
    '140': 15.6,
    '141': 15.6,
    '142': 15.5,
    '143': 15.5,
    '144': 15.4,
    '145': 15.4,
    '146': 15.3,
    '147': 15.3,
    '148': 15.2,
    '149': 15.2,
    '150': 15.1,
    '151': 15.1,
    '152': 15,
    '153': 15,
    '154': 14.9,
    '155': 14.9,
    '156': 14.8,
    '157': 14.8,
    '158': 14.7,
    '159': 14.7,
    '160': 14.6,
    '161': 14.6,
    '162': 14.5,
    '163': 14.5,
    '164': 14.4,
    '165': 14.4,
    '166': 14.3,
    '167': 14.3,
    '168': 14.2,
    '169': 14.2,
    '170': 14.1,
    '171': 14.1,
    '172': 14,
    '173': 14,
    '174': 13.9,
    '175': 13.9,
    '176': 13.8,
    '177': 13.8,
    '178': 13.7,
    '179': 13.7,
    '180': 13.6,
    '181': 13.6,
    '182': 13.5,
    '183': 13.5,
    '184': 13.4,
    '185': 13.4,
    '186': 13.3,
    '187': 13.3,
    '188': 13.2,
    '189': 13.2,
    '190': 13.1,
    '191': 13.1,
    '192': 13,
    '193': 13,
    '194': 12.9,
    '195': 12.9,
    '196': 12.8,
    '197': 12.8,
    '198': 12.7,
    '199': 12.7,
    '200': 12.7,
    '201': 12.6,
    '202': 12.6,
    '203': 12.5,
    '204': 12.5,
    '205': 12.4,
    '206': 12.4,
    '207': 12.3,
    '208': 12.3,
    '209': 12.2,
    '210': 12.2,
    '0.5': 23.6,
    '1.5': 23.5,
    '2.5': 23.4,
    '3.5': 23.4,
    '4.5': 23.3,
    '5.5': 23.2,
    '6.5': 23.2,
    '7.5': 23.1,
    '8.5': 23.1,
    '9.5': 23,
    '10.5': 22.9,
    '11.5': 22.9,
    '12.5': 22.8,
    '13.5': 22.8,
    '14.5': 22.7,
    '15.5': 22.6,
    '16.5': 22.6,
    '17.5': 22.5,
    '18.5': 22.5,
    '19.5': 22.4,
    '20.5': 22.3,
    '21.5': 22.3,
    '22.5': 22.2,
    '23.5': 22.1,
    '24.5': 22.1,
    '25.5': 22,
    '26.5': 22,
    '27.5': 21.9,
    '28.5': 21.8,
    '29.5': 21.8,
    '30.5': 21.7,
    '31.5': 21.7,
    '32.5': 21.6,
    '33.5': 21.5,
    '34.5': 21.5,
    '35.5': 21.4,
    '36.5': 21.4,
    '37.5': 21.3,
    '38.5': 21.3,
    '39.5': 21.2,
    '40.5': 21.1,
    '41.5': 21.1,
    '42.5': 21,
    '43.5': 21,
    '44.5': 20.9,
    '45.5': 20.8,
    '46.5': 20.8,
    '47.5': 20.7,
    '48.5': 20.7,
    '49.5': 20.6,
    '50.5': 20.5,
    '51.5': 20.5,
    '52.5': 20.4,
    '53.5': 20.4,
    '54.5': 20.3,
    '55.5': 20.3,
    '56.5': 20.2,
    '57.5': 20.1,
    '58.5': 20.1,
    '59.5': 20,
    '60.5': 20,
    '61.5': 19.9,
    '62.5': 19.9,
    '63.5': 19.8,
    '64.5': 19.7,
    '65.5': 19.7,
    '66.5': 19.6,
    '67.5': 19.6,
    '68.5': 19.5,
    '69.5': 19.5,
    '70.5': 19.4,
    '71.5': 19.3,
    '72.5': 19.3,
    '73.5': 19.2,
    '74.5': 19.2,
    '75.5': 19.1,
    '76.5': 19.1,
    '77.5': 19,
    '78.5': 19,
    '79.5': 18.9,
    '80.5': 18.8,
    '81.5': 18.8,
    '82.5': 18.7,
    '83.5': 18.7,
    '84.5': 18.6,
    '85.5': 18.6,
    '86.5': 18.5,
    '87.5': 18.4,
    '88.5': 18.4,
    '89.5': 18.3,
    '90.5': 18.3,
    '91.5': 18.2,
    '92.5': 18.2,
    '93.5': 18.1,
    '94.5': 18.1,
    '95.5': 18,
    '96.5': 18,
    '97.5': 17.9,
    '98.5': 17.8,
    '99.5': 17.8,
    '100.5': 17.7,
    '101.5': 17.7,
    '102.5': 17.6,
    '103.5': 17.6,
    '104.5': 17.5,
    '105.5': 17.5,
    '106.5': 17.4,
    '107.5': 17.4,
    '108.5': 17.3,
    '109.5': 17.2,
    '110.5': 17.2,
    '111.5': 17.1,
    '112.5': 17.1,
    '113.5': 17,
    '114.5': 17,
    '115.5': 16.9,
    '116.5': 16.9,
    '117.5': 16.8,
    '118.5': 16.8,
    '119.5': 16.7,
    '120.5': 16.7,
    '121.5': 16.6,
    '122.5': 16.6,
    '123.5': 16.5,
    '124.5': 16.4,
    '125.5': 16.4,
    '126.5': 16.3,
    '127.5': 16.3,
    '128.5': 16.2,
    '129.5': 16.2,
    '130.5': 16.1,
    '131.5': 16.1,
    '132.5': 16,
    '133.5': 16,
    '134.5': 16,
    '135.5': 15.9,
    '136.5': 15.8,
    '137.5': 15.8,
    '138.5': 15.7,
    '139.5': 15.7,
    '140.5': 15.6,
    '141.5': 15.6,
    '142.5': 15.5,
    '143.5': 15.5,
    '144.5': 15.4,
    '145.5': 15.4,
    '146.5': 15.3,
    '147.5': 15.3,
    '148.5': 15.2,
    '149.5': 15.2,
    '150.5': 15.1,
    '151.5': 15,
    '152.5': 15,
    '153.5': 14.9,
    '154.5': 14.9,
    '155.5': 14.8,
    '156.5': 14.8,
    '157.5': 14.7,
    '158.5': 14.7,
    '159.5': 14.6,
    '160.5': 14.6,
    '161.5': 14.5,
    '162.5': 14.5,
    '163.5': 14.4,
    '164.5': 14.4,
    '165.5': 14.3,
    '166.5': 14.3,
    '167.5': 14.2,
    '168.5': 14.2,
    '169.5': 14.1,
    '170.5': 14.1,
    '171.5': 14,
    '172.5': 14,
    '173.5': 13.9,
    '174.5': 13.9,
    '175.5': 13.8,
    '176.5': 13.8,
    '177.5': 13.7,
    '178.5': 13.7,
    '179.5': 13.6,
    '180.5': 13.6,
    '181.5': 13.5,
    '182.5': 13.5,
    '183.5': 13.4,
    '184.5': 13.4,
    '185.5': 13.4,
    '186.5': 13.3,
    '187.5': 13.3,
    '188.5': 13.2,
    '189.5': 13.2,
    '190.5': 13.1,
    '191.5': 13.1,
    '192.5': 13,
    '193.5': 13,
    '194.5': 12.9,
    '195.5': 12.9,
    '196.5': 12.8,
    '197.5': 12.8,
    '198.5': 12.7,
    '199.5': 12.7,
    '200.5': 12.6,
    '201.5': 12.6,
    '202.5': 12.5,
    '203.5': 12.5,
    '204.5': 12.4,
    '205.5': 12.4,
    '206.5': 12.3,
    '207.5': 12.3,
    '208.5': 12.2,
    '209.5': 12.2,
  },
  // 20 - 25
  '20.5': {
    '0': 24.1,
    '1': 24.1,
    '2': 24,
    '3': 23.9,
    '4': 23.9,
    '5': 23.8,
    '6': 23.7,
    '7': 23.7,
    '8': 23.6,
    '9': 23.6,
    '10': 23.5,
    '11': 23.4,
    '12': 23.4,
    '13': 23.3,
    '14': 23.3,
    '15': 23.2,
    '16': 23.1,
    '17': 23.1,
    '18': 23,
    '19': 22.9,
    '20': 22.9,
    '21': 22.8,
    '22': 22.8,
    '23': 22.7,
    '24': 22.6,
    '25': 22.6,
    '26': 22.5,
    '27': 22.5,
    '28': 22.4,
    '29': 22.3,
    '30': 22.3,
    '31': 22.2,
    '32': 22.2,
    '33': 22.1,
    '34': 22,
    '35': 22,
    '36': 21.9,
    '37': 21.9,
    '38': 21.8,
    '39': 21.7,
    '40': 21.7,
    '41': 21.6,
    '42': 21.6,
    '43': 21.5,
    '44': 21.4,
    '45': 21.4,
    '46': 21.3,
    '47': 21.3,
    '48': 21.2,
    '49': 21.1,
    '50': 21.1,
    '51': 21,
    '52': 21,
    '53': 20.9,
    '54': 20.8,
    '55': 20.8,
    '56': 20.7,
    '57': 20.7,
    '58': 20.6,
    '59': 20.6,
    '60': 20.5,
    '61': 20.4,
    '62': 20.4,
    '63': 20.3,
    '64': 20.3,
    '65': 20.2,
    '66': 20.2,
    '67': 20.1,
    '68': 20,
    '69': 20,
    '70': 19.9,
    '71': 19.9,
    '72': 19.8,
    '73': 19.8,
    '74': 19.7,
    '75': 19.6,
    '76': 19.6,
    '77': 19.5,
    '78': 19.5,
    '79': 19.4,
    '80': 19.4,
    '81': 19.3,
    '82': 19.2,
    '83': 19.2,
    '84': 19.1,
    '85': 19.1,
    '86': 19,
    '87': 19,
    '88': 18.9,
    '89': 18.8,
    '90': 18.8,
    '91': 18.7,
    '92': 18.7,
    '93': 18.6,
    '94': 18.6,
    '95': 18.5,
    '96': 18.5,
    '97': 18.4,
    '98': 18.4,
    '99': 18.3,
    '100': 18.2,
    '101': 18.2,
    '102': 18.1,
    '103': 18,
    '104': 18,
    '105': 18,
    '106': 17.9,
    '107': 17.9,
    '108': 17.8,
    '109': 17.7,
    '110': 17.7,
    '111': 17.6,
    '112': 17.6,
    '113': 17.5,
    '114': 17.5,
    '115': 17.4,
    '116': 17.4,
    '117': 17.3,
    '118': 17.3,
    '119': 17.2,
    '120': 17.2,
    '121': 17.1,
    '122': 17.1,
    '123': 17,
    '124': 16.9,
    '125': 16.9,
    '126': 16.8,
    '127': 16.8,
    '128': 16.7,
    '129': 16.7,
    '130': 16.6,
    '131': 16.6,
    '132': 16.5,
    '133': 16.5,
    '134': 16.4,
    '135': 16.4,
    '136': 16.3,
    '137': 16.3,
    '138': 16.2,
    '139': 16.1,
    '140': 16.1,
    '141': 16,
    '142': 16,
    '143': 15.9,
    '144': 15.9,
    '145': 15.8,
    '146': 15.8,
    '147': 15.7,
    '148': 15.7,
    '149': 15.6,
    '150': 15.6,
    '151': 15.5,
    '152': 15.5,
    '153': 15.4,
    '154': 15.4,
    '155': 15.3,
    '156': 15.3,
    '157': 15.2,
    '158': 15.2,
    '159': 15.1,
    '160': 15.1,
    '161': 15,
    '162': 15,
    '163': 14.9,
    '164': 14.9,
    '165': 14.8,
    '166': 14.8,
    '167': 14.7,
    '168': 14.7,
    '169': 14.6,
    '170': 14.6,
    '171': 14.5,
    '172': 14.5,
    '173': 14.4,
    '174': 14.4,
    '175': 14.3,
    '176': 14.3,
    '177': 14.2,
    '178': 14.2,
    '179': 14.1,
    '180': 14.1,
    '181': 14,
    '182': 14,
    '183': 13.9,
    '184': 13.9,
    '185': 13.8,
    '186': 13.8,
    '187': 13.7,
    '188': 13.7,
    '189': 13.6,
    '190': 13.6,
    '191': 13.5,
    '192': 13.5,
    '193': 13.4,
    '194': 13.4,
    '195': 13.3,
    '196': 13.3,
    '197': 13.2,
    '198': 13.2,
    '199': 13.1,
    '200': 13.1,
    '201': 13,
    '202': 13,
    '203': 12.9,
    '204': 12.9,
    '205': 12.8,
    '206': 12.8,
    '207': 12.7,
    '208': 12.7,
    '209': 12.6,
    '210': 12.6,
    '0.5': 24.1,
    '1.5': 24,
    '2.5': 24,
    '3.5': 23.9,
    '4.5': 23.8,
    '5.5': 23.8,
    '6.5': 23.7,
    '7.5': 23.7,
    '8.5': 23.6,
    '9.5': 23.5,
    '10.5': 23.5,
    '11.5': 23.4,
    '12.5': 23.3,
    '13.5': 23.3,
    '14.5': 23.2,
    '15.5': 23.2,
    '16.5': 23.1,
    '17.5': 23,
    '18.5': 23,
    '19.5': 22.9,
    '20.5': 22.9,
    '21.5': 22.8,
    '22.5': 22.7,
    '23.5': 22.7,
    '24.5': 22.6,
    '25.5': 22.5,
    '26.5': 22.5,
    '27.5': 22.4,
    '28.5': 22.4,
    '29.5': 22.3,
    '30.5': 22.2,
    '31.5': 22.2,
    '32.5': 22.1,
    '33.5': 22.1,
    '34.5': 22,
    '35.5': 21.9,
    '36.5': 21.9,
    '37.5': 21.8,
    '38.5': 21.8,
    '39.5': 21.7,
    '40.5': 21.6,
    '41.5': 21.6,
    '42.5': 21.5,
    '43.5': 21.5,
    '44.5': 21.4,
    '45.5': 21.3,
    '46.5': 21.3,
    '47.5': 21.2,
    '48.5': 21.2,
    '49.5': 21.1,
    '50.5': 21.1,
    '51.5': 21,
    '52.5': 20.9,
    '53.5': 20.9,
    '54.5': 20.8,
    '55.5': 20.8,
    '56.5': 20.7,
    '57.5': 20.6,
    '58.5': 20.6,
    '59.5': 20.5,
    '60.5': 20.5,
    '61.5': 20.4,
    '62.5': 20.4,
    '63.5': 20.3,
    '64.5': 20.2,
    '65.5': 20.2,
    '66.5': 20.1,
    '67.5': 20.1,
    '68.5': 20,
    '69.5': 20,
    '70.5': 19.9,
    '71.5': 19.8,
    '72.5': 19.8,
    '73.5': 19.7,
    '74.5': 19.7,
    '75.5': 19.6,
    '76.5': 19.6,
    '77.5': 19.5,
    '78.5': 19.4,
    '79.5': 19.4,
    '80.5': 19.3,
    '81.5': 19.3,
    '82.5': 19.2,
    '83.5': 19.2,
    '84.5': 19.1,
    '85.5': 19.1,
    '86.5': 19,
    '87.5': 18.9,
    '88.5': 18.9,
    '89.5': 18.8,
    '90.5': 18.8,
    '91.5': 18.7,
    '92.5': 18.7,
    '93.5': 18.6,
    '94.5': 18.5,
    '95.5': 18.5,
    '96.5': 18.4,
    '97.5': 18.4,
    '98.5': 18.3,
    '99.5': 18.3,
    '100.5': 18.2,
    '101.5': 18.2,
    '102.5': 18.1,
    '103.5': 18,
    '104.5': 18,
    '105.5': 17.9,
    '106.5': 17.9,
    '107.5': 17.8,
    '108.5': 17.8,
    '109.5': 17.7,
    '110.5': 17.7,
    '111.5': 17.6,
    '112.5': 17.6,
    '113.5': 17.5,
    '114.5': 17.5,
    '115.5': 17.4,
    '116.5': 17.3,
    '117.5': 17.3,
    '118.5': 17.2,
    '119.5': 17.2,
    '120.5': 17.2,
    '121.5': 17.1,
    '122.5': 17,
    '123.5': 17,
    '124.5': 16.9,
    '125.5': 16.9,
    '126.5': 16.8,
    '127.5': 16.8,
    '128.5': 16.7,
    '129.5': 16.6,
    '130.5': 16.6,
    '131.5': 16.5,
    '132.5': 16.5,
    '133.5': 16.4,
    '134.5': 16.4,
    '135.5': 16.3,
    '136.5': 16.3,
    '137.5': 16.2,
    '138.5': 16.2,
    '139.5': 16.1,
    '140.5': 16.1,
    '141.5': 16,
    '142.5': 16,
    '143.5': 15.9,
    '144.5': 15.9,
    '145.5': 15.8,
    '146.5': 15.8,
    '147.5': 15.7,
    '148.5': 15.7,
    '149.5': 15.6,
    '150.5': 15.6,
    '151.5': 15.5,
    '152.5': 15.5,
    '153.5': 15.4,
    '154.5': 15.3,
    '155.5': 15.3,
    '156.5': 15.2,
    '157.5': 15.2,
    '158.5': 15.1,
    '159.5': 15.1,
    '160.5': 15,
    '161.5': 15,
    '162.5': 14.9,
    '163.5': 14.9,
    '164.5': 14.8,
    '165.5': 14.8,
    '166.5': 14.7,
    '167.5': 14.7,
    '168.5': 14.6,
    '169.5': 14.6,
    '170.5': 14.5,
    '171.5': 14.5,
    '172.5': 14.4,
    '173.5': 14.4,
    '174.5': 14.3,
    '175.5': 14.3,
    '176.5': 14.2,
    '177.5': 14.2,
    '178.5': 14.1,
    '179.5': 14.1,
    '180.5': 14,
    '181.5': 14,
    '182.5': 13.9,
    '183.5': 13.9,
    '184.5': 13.8,
    '185.5': 13.8,
    '186.5': 13.7,
    '187.5': 13.7,
    '188.5': 13.6,
    '189.5': 13.6,
    '190.5': 13.5,
    '191.5': 13.5,
    '192.5': 13.4,
    '193.5': 13.4,
    '194.5': 13.3,
    '195.5': 13.3,
    '196.5': 13.3,
    '197.5': 13.2,
    '198.5': 13.2,
    '199.5': 13.1,
    '200.5': 13.1,
    '201.5': 13,
    '202.5': 13,
    '203.5': 12.9,
    '204.5': 12.9,
    '205.5': 12.8,
    '206.5': 12.8,
    '207.5': 12.7,
    '208.5': 12.7,
    '209.5': 12.6,
  },
  '21': {
    '0': 24.7,
    '1': 24.6,
    '2': 24.5,
    '3': 24.5,
    '4': 24.4,
    '5': 24.3,
    '6': 24.3,
    '7': 24.2,
    '8': 24.2,
    '9': 24.1,
    '10': 24,
    '11': 24,
    '12': 23.9,
    '13': 23.8,
    '14': 23.8,
    '15': 23.7,
    '16': 23.7,
    '17': 23.6,
    '18': 23.5,
    '19': 23.5,
    '20': 23.4,
    '21': 23.3,
    '22': 23.3,
    '23': 23.2,
    '24': 23.2,
    '25': 23.1,
    '26': 23,
    '27': 23,
    '28': 22.9,
    '29': 22.9,
    '30': 22.8,
    '31': 22.7,
    '32': 22.7,
    '33': 22.6,
    '34': 22.6,
    '35': 22.6,
    '36': 22.4,
    '37': 22.4,
    '38': 22.3,
    '39': 22.2,
    '40': 22.2,
    '41': 22.1,
    '42': 22.1,
    '43': 22,
    '44': 21.9,
    '45': 21.9,
    '46': 21.8,
    '47': 21.8,
    '48': 21.7,
    '49': 21.6,
    '50': 21.6,
    '51': 21.5,
    '52': 21.5,
    '53': 21.4,
    '54': 21.4,
    '55': 21.3,
    '56': 21.2,
    '57': 21.2,
    '58': 21.1,
    '59': 21.1,
    '60': 21,
    '61': 20.9,
    '62': 20.9,
    '63': 20.8,
    '64': 20.8,
    '65': 20.7,
    '66': 20.7,
    '67': 20.6,
    '68': 20.5,
    '69': 20.5,
    '70': 20.4,
    '71': 20.4,
    '72': 20.3,
    '73': 20.2,
    '74': 20.2,
    '75': 20.1,
    '76': 20.1,
    '77': 20,
    '78': 20,
    '79': 19.9,
    '80': 19.8,
    '81': 19.8,
    '82': 19.7,
    '83': 19.7,
    '84': 19.6,
    '85': 19.6,
    '86': 19.5,
    '87': 19.4,
    '88': 19.4,
    '89': 19.3,
    '90': 19.3,
    '91': 19.2,
    '92': 19.2,
    '93': 19.1,
    '94': 19.1,
    '95': 19,
    '96': 18.9,
    '97': 18.9,
    '98': 18.8,
    '99': 18.8,
    '100': 18.7,
    '101': 18.7,
    '102': 18.6,
    '103': 18.6,
    '104': 18.5,
    '105': 18.4,
    '106': 18.4,
    '107': 18.3,
    '108': 18.3,
    '109': 18.2,
    '110': 18.2,
    '111': 18.1,
    '112': 18.1,
    '113': 18,
    '114': 17.9,
    '115': 17.9,
    '116': 17.8,
    '117': 17.8,
    '118': 17.7,
    '119': 17.7,
    '120': 17.6,
    '121': 17.6,
    '122': 17.5,
    '123': 17.5,
    '124': 17.4,
    '125': 17.4,
    '126': 17.3,
    '127': 17.2,
    '128': 17.2,
    '129': 17.1,
    '130': 17.1,
    '131': 17,
    '132': 17,
    '133': 16.9,
    '134': 16.9,
    '135': 16.8,
    '136': 16.8,
    '137': 16.7,
    '138': 16.7,
    '139': 16.6,
    '140': 16.6,
    '141': 16.5,
    '142': 16.4,
    '143': 16.4,
    '144': 16.3,
    '145': 16.3,
    '146': 16.2,
    '147': 16.2,
    '148': 16.1,
    '149': 16.1,
    '150': 16,
    '151': 16,
    '152': 15.9,
    '153': 15.9,
    '154': 15.8,
    '155': 15.8,
    '156': 15.7,
    '157': 15.7,
    '158': 15.6,
    '159': 15.6,
    '160': 15.5,
    '161': 15.5,
    '162': 15.4,
    '163': 15.4,
    '164': 15.3,
    '165': 15.3,
    '166': 15.2,
    '167': 15.2,
    '168': 15.1,
    '169': 15.1,
    '170': 15,
    '171': 15,
    '172': 14.9,
    '173': 14.9,
    '174': 14.8,
    '175': 14.8,
    '176': 14.7,
    '177': 14.6,
    '178': 14.6,
    '179': 14.5,
    '180': 14.5,
    '181': 14.5,
    '182': 14.4,
    '183': 14.3,
    '184': 14.3,
    '185': 14.3,
    '186': 14.2,
    '187': 14.2,
    '188': 14.1,
    '189': 14.1,
    '190': 14,
    '191': 14,
    '192': 13.9,
    '193': 13.9,
    '194': 13.8,
    '195': 13.8,
    '196': 13.7,
    '197': 13.7,
    '198': 13.6,
    '199': 13.6,
    '200': 13.5,
    '201': 13.5,
    '202': 13.4,
    '203': 13.4,
    '204': 13.3,
    '205': 13.3,
    '206': 13.2,
    '207': 13.2,
    '208': 13.1,
    '209': 13.1,
    '210': 13,
    '0.5': 24.6,
    '1.5': 24.6,
    '2.5': 24.5,
    '3.5': 24.4,
    '4.5': 24.4,
    '5.5': 24.3,
    '6.5': 24.2,
    '7.5': 24.2,
    '8.5': 24.1,
    '9.5': 24.1,
    '10.5': 24,
    '11.5': 23.9,
    '12.5': 23.9,
    '13.5': 23.8,
    '14.5': 23.7,
    '15.5': 23.7,
    '16.5': 23.6,
    '17.5': 23.6,
    '18.5': 23.5,
    '19.5': 23.4,
    '20.5': 23.4,
    '21.5': 23.3,
    '22.5': 23.3,
    '23.5': 23.2,
    '24.5': 23.1,
    '25.5': 23.1,
    '26.5': 23,
    '27.5': 22.9,
    '28.5': 22.9,
    '29.5': 22.8,
    '30.5': 22.8,
    '31.5': 22.7,
    '32.5': 22.6,
    '33.5': 22.6,
    '34.5': 22.6,
    '35.5': 22.5,
    '36.5': 22.4,
    '37.5': 22.3,
    '38.5': 22.3,
    '39.5': 22.2,
    '40.5': 22.2,
    '41.5': 22.1,
    '42.5': 22,
    '43.5': 22,
    '44.5': 21.9,
    '45.5': 21.9,
    '46.5': 21.8,
    '47.5': 21.7,
    '48.5': 21.7,
    '49.5': 21.6,
    '50.5': 21.6,
    '51.5': 21.5,
    '52.5': 21.4,
    '53.5': 21.4,
    '54.5': 21.3,
    '55.5': 21.3,
    '56.5': 21.2,
    '57.5': 21.1,
    '58.5': 21.1,
    '59.5': 21,
    '60.5': 21,
    '61.5': 20.9,
    '62.5': 20.9,
    '63.5': 20.8,
    '64.5': 20.7,
    '65.5': 20.7,
    '66.5': 20.6,
    '67.5': 20.6,
    '68.5': 20.5,
    '69.5': 20.4,
    '70.5': 20.4,
    '71.5': 20.3,
    '72.5': 20.3,
    '73.5': 20.2,
    '74.5': 20.2,
    '75.5': 20.1,
    '76.5': 20,
    '77.5': 20,
    '78.5': 19.9,
    '79.5': 19.9,
    '80.5': 19.8,
    '81.5': 19.8,
    '82.5': 19.7,
    '83.5': 19.6,
    '84.5': 19.6,
    '85.5': 19.5,
    '86.5': 19.5,
    '87.5': 19.4,
    '88.5': 19.4,
    '89.5': 19.3,
    '90.5': 19.3,
    '91.5': 19.2,
    '92.5': 19.1,
    '93.5': 19.1,
    '94.5': 19,
    '95.5': 19,
    '96.5': 18.9,
    '97.5': 18.9,
    '98.5': 18.8,
    '99.5': 18.7,
    '100.5': 18.7,
    '101.5': 18.6,
    '102.5': 18.6,
    '103.5': 18.5,
    '104.5': 18.5,
    '105.5': 18.4,
    '106.5': 18.4,
    '107.5': 18.3,
    '108.5': 18.2,
    '109.5': 18.2,
    '110.5': 18.1,
    '111.5': 18.1,
    '112.5': 18,
    '113.5': 18,
    '114.5': 17.9,
    '115.5': 17.9,
    '116.5': 17.8,
    '117.5': 17.8,
    '118.5': 17.7,
    '119.5': 17.7,
    '120.5': 17.6,
    '121.5': 17.5,
    '122.5': 17.5,
    '123.5': 17.4,
    '124.5': 17.4,
    '125.5': 17.3,
    '126.5': 17.3,
    '127.5': 17.2,
    '128.5': 17.2,
    '129.5': 17.1,
    '130.5': 17.1,
    '131.5': 17,
    '132.5': 17,
    '133.5': 16.9,
    '134.5': 16.8,
    '135.5': 16.8,
    '136.5': 16.7,
    '137.5': 16.7,
    '138.5': 16.6,
    '139.5': 16.6,
    '140.5': 16.5,
    '141.5': 16.5,
    '142.5': 16.4,
    '143.5': 16.4,
    '144.5': 16.3,
    '145.5': 16.3,
    '146.5': 16.2,
    '147.5': 16.2,
    '148.5': 16.1,
    '149.5': 16.1,
    '150.5': 16,
    '151.5': 16,
    '152.5': 15.9,
    '153.5': 15.8,
    '154.5': 15.8,
    '155.5': 15.7,
    '156.5': 15.7,
    '157.5': 15.6,
    '158.5': 15.6,
    '159.5': 15.5,
    '160.5': 15.5,
    '161.5': 15.4,
    '162.5': 15.4,
    '163.5': 15.3,
    '164.5': 15.3,
    '165.5': 15.2,
    '166.5': 15.2,
    '167.5': 15.1,
    '168.5': 15.1,
    '169.5': 15,
    '170.5': 15,
    '171.5': 14.9,
    '172.5': 14.9,
    '173.5': 14.8,
    '174.5': 14.8,
    '175.5': 14.7,
    '176.5': 14.7,
    '177.5': 14.6,
    '178.5': 14.6,
    '179.5': 14.5,
    '180.5': 14.5,
    '181.5': 14.4,
    '182.5': 14.4,
    '183.5': 14.3,
    '184.5': 14.3,
    '185.5': 14.2,
    '186.5': 14.2,
    '187.5': 14.1,
    '188.5': 14.1,
    '189.5': 14,
    '190.5': 14,
    '191.5': 13.9,
    '192.5': 13.9,
    '193.5': 13.8,
    '194.5': 13.8,
    '195.5': 13.7,
    '196.5': 13.7,
    '197.5': 13.6,
    '198.5': 13.6,
    '199.5': 13.5,
    '200.5': 13.5,
    '201.5': 13.4,
    '202.5': 13.4,
    '203.5': 13.3,
    '204.5': 13.3,
    '205.5': 13.2,
    '206.5': 13.2,
    '207.5': 13.1,
    '208.5': 13.1,
    '209.5': 13,
  },
  '21.5': {
    '0': 25.2,
    '1': 25.1,
    '2': 25.1,
    '3': 25,
    '4': 24.9,
    '5': 24.9,
    '6': 24.8,
    '7': 24.8,
    '8': 24.7,
    '9': 24.6,
    '10': 24.6,
    '11': 24.5,
    '12': 24.4,
    '13': 24.4,
    '14': 24.3,
    '15': 24.2,
    '16': 24.2,
    '17': 24.1,
    '18': 24.1,
    '19': 24,
    '20': 23.9,
    '21': 23.9,
    '22': 23.8,
    '23': 23.7,
    '24': 23.7,
    '25': 23.6,
    '26': 23.6,
    '27': 23.5,
    '28': 23.4,
    '29': 23.4,
    '30': 23.3,
    '31': 23.3,
    '32': 23.2,
    '33': 23.1,
    '34': 23.1,
    '35': 23,
    '36': 22.9,
    '37': 22.9,
    '38': 22.8,
    '39': 22.8,
    '40': 22.7,
    '41': 22.6,
    '42': 22.6,
    '43': 22.5,
    '44': 22.5,
    '45': 22.4,
    '46': 22.3,
    '47': 22.3,
    '48': 22.2,
    '49': 22.2,
    '50': 22.1,
    '51': 22,
    '52': 22,
    '53': 21.9,
    '54': 21.9,
    '55': 21.8,
    '56': 21.7,
    '57': 21.7,
    '58': 21.6,
    '59': 21.6,
    '60': 21.5,
    '61': 21.4,
    '62': 21.4,
    '63': 21.3,
    '64': 21.3,
    '65': 21.2,
    '66': 21.1,
    '67': 21.1,
    '68': 21,
    '69': 21,
    '70': 20.9,
    '71': 20.9,
    '72': 20.8,
    '73': 20.7,
    '74': 20.7,
    '75': 20.6,
    '76': 20.6,
    '77': 20.5,
    '78': 20.4,
    '79': 20.4,
    '80': 20.3,
    '81': 20.3,
    '82': 20.2,
    '83': 20.2,
    '84': 20.1,
    '85': 20,
    '86': 20,
    '87': 19.9,
    '88': 19.9,
    '89': 19.8,
    '90': 19.8,
    '91': 19.7,
    '92': 19.6,
    '93': 19.6,
    '94': 19.5,
    '95': 19.5,
    '96': 19.4,
    '97': 19.4,
    '98': 19.3,
    '99': 19.3,
    '100': 19.2,
    '101': 19.1,
    '102': 19.1,
    '103': 19,
    '104': 19,
    '105': 18.9,
    '106': 18.9,
    '107': 18.8,
    '108': 18.7,
    '109': 18.7,
    '110': 18.6,
    '111': 18.6,
    '112': 18.5,
    '113': 18.5,
    '114': 18.4,
    '115': 18.4,
    '116': 18.3,
    '117': 18.3,
    '118': 18.2,
    '119': 18.1,
    '120': 18.1,
    '121': 18,
    '122': 18,
    '123': 17.9,
    '124': 17.9,
    '125': 17.8,
    '126': 17.8,
    '127': 17.7,
    '128': 17.7,
    '129': 17.6,
    '130': 17.5,
    '131': 17.5,
    '132': 17.4,
    '133': 17.4,
    '134': 17.3,
    '135': 17.3,
    '136': 17.2,
    '137': 17.2,
    '138': 17.1,
    '139': 17.1,
    '140': 17,
    '141': 17,
    '142': 16.9,
    '143': 16.9,
    '144': 16.8,
    '145': 16.7,
    '146': 16.7,
    '147': 16.6,
    '148': 16.6,
    '149': 16.5,
    '150': 16.5,
    '151': 16.4,
    '152': 16.4,
    '153': 16.3,
    '154': 16.3,
    '155': 16.2,
    '156': 16.2,
    '157': 16.1,
    '158': 16.1,
    '159': 16,
    '160': 16,
    '161': 15.9,
    '162': 15.9,
    '163': 15.8,
    '164': 15.8,
    '165': 15.7,
    '166': 15.7,
    '167': 15.6,
    '168': 15.5,
    '169': 15.5,
    '170': 15.4,
    '171': 15.4,
    '172': 15.3,
    '173': 15.3,
    '174': 15.2,
    '175': 15.2,
    '176': 15.1,
    '177': 15.1,
    '178': 15,
    '179': 15,
    '180': 14.9,
    '181': 14.9,
    '182': 14.8,
    '183': 14.8,
    '184': 14.7,
    '185': 14.7,
    '186': 14.6,
    '187': 14.6,
    '188': 14.5,
    '189': 14.5,
    '190': 14.4,
    '191': 14.4,
    '192': 14.3,
    '193': 14.3,
    '194': 14.2,
    '195': 14.2,
    '196': 14.1,
    '197': 14.1,
    '198': 14,
    '199': 14,
    '200': 13.9,
    '201': 13.9,
    '202': 13.8,
    '203': 13.8,
    '204': 13.7,
    '205': 13.7,
    '206': 13.6,
    '207': 13.6,
    '208': 13.5,
    '209': 13.5,
    '210': 13.5,
    '0.5': 25.2,
    '1.5': 25.1,
    '2.5': 25,
    '3.5': 25,
    '4.5': 24.9,
    '5.5': 24.8,
    '6.5': 24.8,
    '7.5': 24.7,
    '8.5': 24.7,
    '9.5': 24.6,
    '10.5': 24.5,
    '11.5': 24.5,
    '12.5': 24.4,
    '13.5': 24.3,
    '14.5': 24.3,
    '15.5': 24.2,
    '16.5': 24.2,
    '17.5': 24.1,
    '18.5': 24,
    '19.5': 24,
    '20.5': 23.9,
    '21.5': 23.8,
    '22.5': 23.8,
    '23.5': 23.7,
    '24.5': 23.7,
    '25.5': 23.6,
    '26.5': 23.5,
    '27.5': 23.5,
    '28.5': 23.4,
    '29.5': 23.3,
    '30.5': 23.3,
    '31.5': 23.2,
    '32.5': 23.2,
    '33.5': 23.1,
    '34.5': 23,
    '35.5': 23,
    '36.5': 22.9,
    '37.5': 22.9,
    '38.5': 22.8,
    '39.5': 22.7,
    '40.5': 22.7,
    '41.5': 22.6,
    '42.5': 22.5,
    '43.5': 22.5,
    '44.5': 22.4,
    '45.5': 22.4,
    '46.5': 22.3,
    '47.5': 22.2,
    '48.5': 22.2,
    '49.5': 22.1,
    '50.5': 22.1,
    '51.5': 22,
    '52.5': 21.9,
    '53.5': 21.9,
    '54.5': 21.8,
    '55.5': 21.8,
    '56.5': 21.7,
    '57.5': 21.6,
    '58.5': 21.6,
    '59.5': 21.5,
    '60.5': 21.5,
    '61.5': 21.4,
    '62.5': 21.4,
    '63.5': 21.3,
    '64.5': 21.2,
    '65.5': 21.2,
    '66.5': 21.1,
    '67.5': 21.1,
    '68.5': 21,
    '69.5': 20.9,
    '70.5': 20.9,
    '71.5': 20.8,
    '72.5': 20.8,
    '73.5': 20.7,
    '74.5': 20.7,
    '75.5': 20.6,
    '76.5': 20.5,
    '77.5': 20.5,
    '78.5': 20.4,
    '79.5': 20.4,
    '80.5': 20.3,
    '81.5': 20.2,
    '82.5': 20.2,
    '83.5': 20.1,
    '84.5': 20.1,
    '85.5': 20,
    '86.5': 20,
    '87.5': 19.9,
    '88.5': 19.8,
    '89.5': 19.8,
    '90.5': 19.7,
    '91.5': 19.7,
    '92.5': 19.6,
    '93.5': 19.6,
    '94.5': 19.5,
    '95.5': 19.5,
    '96.5': 19.4,
    '97.5': 19.3,
    '98.5': 19.3,
    '99.5': 19.2,
    '100.5': 19.2,
    '101.5': 19.1,
    '102.5': 19.1,
    '103.5': 19,
    '104.5': 18.9,
    '105.5': 18.9,
    '106.5': 18.8,
    '107.5': 18.8,
    '108.5': 18.7,
    '109.5': 18.7,
    '110.5': 18.6,
    '111.5': 18.6,
    '112.5': 18.5,
    '113.5': 18.4,
    '114.5': 18.4,
    '115.5': 18.3,
    '116.5': 18.3,
    '117.5': 18.2,
    '118.5': 18.2,
    '119.5': 18.1,
    '120.5': 18.1,
    '121.5': 18,
    '122.5': 18,
    '123.5': 17.9,
    '124.5': 17.8,
    '125.5': 17.8,
    '126.5': 17.7,
    '127.5': 17.7,
    '128.5': 17.6,
    '129.5': 17.6,
    '130.5': 17.5,
    '131.5': 17.5,
    '132.5': 17.4,
    '133.5': 17.4,
    '134.5': 17.3,
    '135.5': 17.3,
    '136.5': 17.2,
    '137.5': 17.1,
    '138.5': 17.1,
    '139.5': 17,
    '140.5': 17,
    '141.5': 16.9,
    '142.5': 16.9,
    '143.5': 16.8,
    '144.5': 16.8,
    '145.5': 16.7,
    '146.5': 16.7,
    '147.5': 16.6,
    '148.5': 16.6,
    '149.5': 16.5,
    '150.5': 16.5,
    '151.5': 16.4,
    '152.5': 16.4,
    '153.5': 16.3,
    '154.5': 16.2,
    '155.5': 16.2,
    '156.5': 16.1,
    '157.5': 16.1,
    '158.5': 16,
    '159.5': 16,
    '160.5': 15.9,
    '161.5': 15.9,
    '162.5': 15.8,
    '163.5': 15.8,
    '164.5': 15.7,
    '165.5': 15.7,
    '166.5': 15.6,
    '167.5': 15.6,
    '168.5': 15.5,
    '169.5': 15.5,
    '170.5': 15.4,
    '171.5': 15.4,
    '172.5': 15.3,
    '173.5': 15.3,
    '174.5': 15.2,
    '175.5': 15.2,
    '176.5': 15.1,
    '177.5': 15.1,
    '178.5': 15,
    '179.5': 15,
    '180.5': 14.9,
    '181.5': 14.9,
    '182.5': 14.8,
    '183.5': 14.8,
    '184.5': 14.7,
    '185.5': 14.7,
    '186.5': 14.6,
    '187.5': 14.6,
    '188.5': 14.5,
    '189.5': 14.5,
    '190.5': 14.4,
    '191.5': 14.4,
    '192.5': 14.3,
    '193.5': 14.3,
    '194.5': 14.2,
    '195.5': 14.2,
    '196.5': 14.1,
    '197.5': 14.1,
    '198.5': 14,
    '199.5': 14,
    '200.5': 13.9,
    '201.5': 13.9,
    '202.5': 13.8,
    '203.5': 13.8,
    '204.5': 13.7,
    '205.5': 13.7,
    '206.5': 13.6,
    '207.5': 13.6,
    '208.5': 13.5,
    '209.5': 13.5,
  },
  '22': {
    '0': 25.7,
    '1': 25.7,
    '2': 25.6,
    '3': 25.5,
    '4': 25.5,
    '5': 25.4,
    '6': 25.4,
    '7': 25.3,
    '8': 25.2,
    '9': 25.2,
    '10': 25.1,
    '11': 25,
    '12': 25,
    '13': 24.9,
    '14': 24.8,
    '15': 24.8,
    '16': 24.7,
    '17': 24.6,
    '18': 24.6,
    '19': 24.5,
    '20': 24.5,
    '21': 24.4,
    '22': 24.3,
    '23': 24.3,
    '24': 24.2,
    '25': 24.1,
    '26': 24.1,
    '27': 24,
    '28': 24,
    '29': 23.9,
    '30': 23.8,
    '31': 23.8,
    '32': 23.7,
    '33': 23.6,
    '34': 23.6,
    '35': 23.5,
    '36': 23.5,
    '37': 23.4,
    '38': 23.3,
    '39': 23.3,
    '40': 23.2,
    '41': 23.2,
    '42': 23.1,
    '43': 23,
    '44': 23,
    '45': 22.9,
    '46': 22.8,
    '47': 22.8,
    '48': 22.7,
    '49': 22.7,
    '50': 22.6,
    '51': 22.5,
    '52': 22.5,
    '53': 22.4,
    '54': 22.4,
    '55': 22.3,
    '56': 22.2,
    '57': 22.2,
    '58': 22.1,
    '59': 22.1,
    '60': 22,
    '61': 21.9,
    '62': 21.9,
    '63': 21.8,
    '64': 21.8,
    '65': 21.7,
    '66': 21.6,
    '67': 21.6,
    '68': 21.5,
    '69': 21.5,
    '70': 21.4,
    '71': 21.3,
    '72': 21.3,
    '73': 21.2,
    '74': 21.2,
    '75': 21.1,
    '76': 21.1,
    '77': 21,
    '78': 20.9,
    '79': 20.9,
    '80': 20.8,
    '81': 20.8,
    '82': 20.7,
    '83': 20.6,
    '84': 20.6,
    '85': 20.5,
    '86': 20.5,
    '87': 20.4,
    '88': 20.4,
    '89': 20.3,
    '90': 20.2,
    '91': 20.2,
    '92': 20.1,
    '93': 20.1,
    '94': 20,
    '95': 20,
    '96': 19.9,
    '97': 19.8,
    '98': 19.8,
    '99': 19.7,
    '100': 19.7,
    '101': 19.6,
    '102': 19.6,
    '103': 19.5,
    '104': 19.4,
    '105': 19.4,
    '106': 19.3,
    '107': 19.3,
    '108': 19.2,
    '109': 19.2,
    '110': 19.1,
    '111': 19.1,
    '112': 19,
    '113': 18.9,
    '114': 18.9,
    '115': 18.8,
    '116': 18.8,
    '117': 18.7,
    '118': 18.7,
    '119': 18.6,
    '120': 18.6,
    '121': 18.5,
    '122': 18.4,
    '123': 18.4,
    '124': 18.3,
    '125': 18.3,
    '126': 18.2,
    '127': 18.2,
    '128': 18.1,
    '129': 18.1,
    '130': 18,
    '131': 18,
    '132': 17.9,
    '133': 17.8,
    '134': 17.8,
    '135': 17.7,
    '136': 17.7,
    '137': 17.6,
    '138': 17.6,
    '139': 17.5,
    '140': 17.5,
    '141': 17.4,
    '142': 17.4,
    '143': 17.3,
    '144': 17.3,
    '145': 17.2,
    '146': 17.1,
    '147': 17.1,
    '148': 17,
    '149': 17,
    '150': 16.9,
    '151': 16.9,
    '152': 16.8,
    '153': 16.8,
    '154': 16.7,
    '155': 16.7,
    '156': 16.6,
    '157': 16.6,
    '158': 16.5,
    '159': 16.5,
    '160': 16.4,
    '161': 16.4,
    '162': 16.3,
    '163': 16.3,
    '164': 16.2,
    '165': 16.1,
    '166': 16.1,
    '167': 16,
    '168': 16,
    '169': 15.9,
    '170': 15.9,
    '171': 15.8,
    '172': 15.8,
    '173': 15.7,
    '174': 15.7,
    '175': 15.6,
    '176': 15.6,
    '177': 15.5,
    '178': 15.5,
    '179': 15.4,
    '180': 15.4,
    '181': 15.3,
    '182': 15.3,
    '183': 15.2,
    '184': 15.2,
    '185': 15.1,
    '186': 15.1,
    '187': 15,
    '188': 15,
    '189': 14.9,
    '190': 14.9,
    '191': 14.8,
    '192': 14.8,
    '193': 14.7,
    '194': 14.7,
    '195': 14.6,
    '196': 14.6,
    '197': 14.5,
    '198': 14.5,
    '199': 14.4,
    '200': 14.4,
    '201': 14.3,
    '202': 14.3,
    '203': 14.2,
    '204': 14.2,
    '205': 14.1,
    '206': 14.1,
    '207': 14,
    '208': 14,
    '209': 13.9,
    '210': 13.9,
    '0.5': 25.7,
    '1.5': 25.6,
    '2.5': 25.6,
    '3.5': 25.5,
    '4.5': 25.4,
    '5.5': 25.4,
    '6.5': 25.3,
    '7.5': 25.3,
    '8.5': 25.2,
    '9.5': 25.1,
    '10.5': 25.1,
    '11.5': 25,
    '12.5': 24.9,
    '13.5': 24.9,
    '14.5': 24.8,
    '15.5': 24.7,
    '16.5': 24.7,
    '17.5': 24.6,
    '18.5': 24.6,
    '19.5': 24.5,
    '20.5': 24.4,
    '21.5': 24.4,
    '22.5': 24.3,
    '23.5': 24.2,
    '24.5': 24.2,
    '25.5': 24.1,
    '26.5': 24,
    '27.5': 24,
    '28.5': 23.9,
    '29.5': 23.9,
    '30.5': 23.8,
    '31.5': 23.7,
    '32.5': 23.7,
    '33.5': 23.6,
    '34.5': 23.6,
    '35.5': 23.5,
    '36.5': 23.4,
    '37.5': 23.4,
    '38.5': 23.3,
    '39.5': 23.2,
    '40.5': 23.2,
    '41.5': 23.1,
    '42.5': 23.1,
    '43.5': 23,
    '44.5': 22.9,
    '45.5': 22.9,
    '46.5': 22.8,
    '47.5': 22.8,
    '48.5': 22.7,
    '49.5': 22.6,
    '50.5': 22.6,
    '51.5': 22.5,
    '52.5': 22.5,
    '53.5': 22.4,
    '54.5': 22.3,
    '55.5': 22.3,
    '56.5': 22.2,
    '57.5': 22.2,
    '58.5': 22.1,
    '59.5': 22,
    '60.5': 22,
    '61.5': 21.9,
    '62.5': 21.9,
    '63.5': 21.8,
    '64.5': 21.7,
    '65.5': 21.7,
    '66.5': 21.6,
    '67.5': 21.6,
    '68.5': 21.5,
    '69.5': 21.4,
    '70.5': 21.4,
    '71.5': 21.3,
    '72.5': 21.3,
    '73.5': 21.2,
    '74.5': 21.1,
    '75.5': 21.1,
    '76.5': 21,
    '77.5': 21,
    '78.5': 20.9,
    '79.5': 20.8,
    '80.5': 20.8,
    '81.5': 20.7,
    '82.5': 20.7,
    '83.5': 20.6,
    '84.5': 20.6,
    '85.5': 20.5,
    '86.5': 20.4,
    '87.5': 20.4,
    '88.5': 20.3,
    '89.5': 20.3,
    '90.5': 20.2,
    '91.5': 20.2,
    '92.5': 20.1,
    '93.5': 20,
    '94.5': 20,
    '95.5': 19.9,
    '96.5': 19.9,
    '97.5': 19.8,
    '98.5': 19.8,
    '99.5': 19.7,
    '100.5': 19.6,
    '101.5': 19.6,
    '102.5': 19.5,
    '103.5': 19.5,
    '104.5': 19.4,
    '105.5': 19.4,
    '106.5': 19.3,
    '107.5': 19.3,
    '108.5': 19.2,
    '109.5': 19.1,
    '110.5': 19.1,
    '111.5': 19,
    '112.5': 19,
    '113.5': 18.9,
    '114.5': 18.9,
    '115.5': 18.8,
    '116.5': 18.7,
    '117.5': 18.7,
    '118.5': 18.6,
    '119.5': 18.6,
    '120.5': 18.5,
    '121.5': 18.5,
    '122.5': 18.4,
    '123.5': 18.4,
    '124.5': 18.3,
    '125.5': 18.3,
    '126.5': 18.2,
    '127.5': 18.1,
    '128.5': 18.1,
    '129.5': 18,
    '130.5': 18,
    '131.5': 17.9,
    '132.5': 17.9,
    '133.5': 17.8,
    '134.5': 17.8,
    '135.5': 17.7,
    '136.5': 17.7,
    '137.5': 17.6,
    '138.5': 17.5,
    '139.5': 17.5,
    '140.5': 17.4,
    '141.5': 17.4,
    '142.5': 17.3,
    '143.5': 17.3,
    '144.5': 17.2,
    '145.5': 17.2,
    '146.5': 17.1,
    '147.5': 17.1,
    '148.5': 17,
    '149.5': 17,
    '150.5': 16.9,
    '151.5': 16.9,
    '152.5': 16.8,
    '153.5': 16.7,
    '154.5': 16.7,
    '155.5': 16.6,
    '156.5': 16.6,
    '157.5': 16.5,
    '158.5': 16.5,
    '159.5': 16.4,
    '160.5': 16.4,
    '161.5': 16.3,
    '162.5': 16.3,
    '163.5': 16.2,
    '164.5': 16.2,
    '165.5': 16.1,
    '166.5': 16.1,
    '167.5': 16,
    '168.5': 16,
    '169.5': 15.9,
    '170.5': 15.9,
    '171.5': 15.8,
    '172.5': 15.8,
    '173.5': 15.7,
    '174.5': 15.7,
    '175.5': 15.6,
    '176.5': 15.6,
    '177.5': 15.5,
    '178.5': 15.5,
    '179.5': 15.4,
    '180.5': 15.3,
    '181.5': 15.3,
    '182.5': 15.2,
    '183.5': 15.2,
    '184.5': 15.1,
    '185.5': 15.1,
    '186.5': 15,
    '187.5': 15,
    '188.5': 14.9,
    '189.5': 14.9,
    '190.5': 14.8,
    '191.5': 14.8,
    '192.5': 14.7,
    '193.5': 14.7,
    '194.5': 14.6,
    '195.5': 14.6,
    '196.5': 14.5,
    '197.5': 14.5,
    '198.5': 14.4,
    '199.5': 14.4,
    '200.5': 14.3,
    '201.5': 14.3,
    '202.5': 14.2,
    '203.5': 14.2,
    '204.5': 14.1,
    '205.5': 14.1,
    '206.5': 14,
    '207.5': 14,
    '208.5': 13.9,
    '209.5': 13.9,
  },
  '22.5': {
    '0': 26.3,
    '1': 26.2,
    '2': 26.1,
    '3': 26.1,
    '4': 26,
    '5': 26,
    '6': 25.9,
    '7': 25.8,
    '8': 25.8,
    '9': 25.7,
    '10': 25.6,
    '11': 25.6,
    '12': 25.5,
    '13': 25.4,
    '14': 25.4,
    '15': 25.3,
    '16': 25.2,
    '17': 25.2,
    '18': 25.1,
    '19': 25,
    '20': 25,
    '21': 24.9,
    '22': 24.9,
    '23': 24.8,
    '24': 24.7,
    '25': 24.7,
    '26': 24.6,
    '27': 24.5,
    '28': 24.5,
    '29': 24.4,
    '30': 24.3,
    '31': 24.3,
    '32': 24.2,
    '33': 24.2,
    '34': 24.1,
    '35': 24,
    '36': 24,
    '37': 23.9,
    '38': 23.8,
    '39': 23.8,
    '40': 23.7,
    '41': 23.7,
    '42': 23.6,
    '43': 23.5,
    '44': 23.5,
    '45': 23.4,
    '46': 23.4,
    '47': 23.3,
    '48': 23.2,
    '49': 23.2,
    '50': 23.1,
    '51': 23,
    '52': 23,
    '53': 22.9,
    '54': 22.9,
    '55': 22.8,
    '56': 22.7,
    '57': 22.7,
    '58': 22.6,
    '59': 22.6,
    '60': 22.5,
    '61': 22.4,
    '62': 22.4,
    '63': 22.3,
    '64': 22.3,
    '65': 22.2,
    '66': 22.1,
    '67': 22.1,
    '68': 22,
    '69': 22,
    '70': 21.9,
    '71': 21.8,
    '72': 21.8,
    '73': 21.7,
    '74': 21.7,
    '75': 21.6,
    '76': 21.5,
    '77': 21.5,
    '78': 21.4,
    '79': 21.4,
    '80': 21.3,
    '81': 21.3,
    '82': 21.2,
    '83': 21.1,
    '84': 21.1,
    '85': 21,
    '86': 21,
    '87': 20.9,
    '88': 20.8,
    '89': 20.8,
    '90': 20.7,
    '91': 20.7,
    '92': 20.6,
    '93': 20.6,
    '94': 20.5,
    '95': 20.4,
    '96': 20.4,
    '97': 20.3,
    '98': 20.3,
    '99': 20.2,
    '100': 20.1,
    '101': 20.1,
    '102': 20,
    '103': 20,
    '104': 19.9,
    '105': 19.9,
    '106': 19.8,
    '107': 19.8,
    '108': 19.7,
    '109': 19.6,
    '110': 19.6,
    '111': 19.5,
    '112': 19.5,
    '113': 19.4,
    '114': 19.4,
    '115': 19.3,
    '116': 19.2,
    '117': 19.2,
    '118': 19.1,
    '119': 19.1,
    '120': 19,
    '121': 19,
    '122': 18.9,
    '123': 18.9,
    '124': 18.8,
    '125': 18.7,
    '126': 18.7,
    '127': 18.6,
    '128': 18.6,
    '129': 18.5,
    '130': 18.5,
    '131': 18.4,
    '132': 18.4,
    '133': 18.3,
    '134': 18.3,
    '135': 18.2,
    '136': 18.1,
    '137': 18.1,
    '138': 18,
    '139': 18,
    '140': 17.9,
    '141': 17.9,
    '142': 17.8,
    '143': 17.8,
    '144': 17.7,
    '145': 17.7,
    '146': 17.6,
    '147': 17.5,
    '148': 17.5,
    '149': 17.4,
    '150': 17.4,
    '151': 17.3,
    '152': 17.3,
    '153': 17.2,
    '154': 17.2,
    '155': 17.1,
    '156': 17.1,
    '157': 17,
    '158': 17,
    '159': 16.9,
    '160': 16.9,
    '161': 16.8,
    '162': 16.7,
    '163': 16.7,
    '164': 16.6,
    '165': 16.6,
    '166': 16.5,
    '167': 16.5,
    '168': 16.4,
    '169': 16.4,
    '170': 16.3,
    '171': 16.3,
    '172': 16.2,
    '173': 16.2,
    '174': 16.1,
    '175': 16.1,
    '176': 16,
    '177': 16,
    '178': 15.9,
    '179': 15.9,
    '180': 15.8,
    '181': 15.8,
    '182': 15.7,
    '183': 15.7,
    '184': 15.6,
    '185': 15.6,
    '186': 15.5,
    '187': 15.5,
    '188': 15.4,
    '189': 15.4,
    '190': 15.3,
    '191': 15.2,
    '192': 15.2,
    '193': 15.1,
    '194': 15.1,
    '195': 15,
    '196': 15,
    '197': 14.9,
    '198': 14.9,
    '199': 14.8,
    '200': 14.8,
    '201': 14.7,
    '202': 14.7,
    '203': 14.6,
    '204': 14.6,
    '205': 14.5,
    '206': 14.5,
    '207': 14.4,
    '208': 14.4,
    '209': 14.3,
    '210': 14.3,
    '0.5': 26.2,
    '1.5': 26.2,
    '2.5': 26.1,
    '3.5': 26.1,
    '4.5': 26,
    '5.5': 25.9,
    '6.5': 25.9,
    '7.5': 25.8,
    '8.5': 25.7,
    '9.5': 25.7,
    '10.5': 25.6,
    '11.5': 25.5,
    '12.5': 25.5,
    '13.5': 25.4,
    '14.5': 25.3,
    '15.5': 25.3,
    '16.5': 25.2,
    '17.5': 25.1,
    '18.5': 25.1,
    '19.5': 25,
    '20.5': 25,
    '21.5': 24.9,
    '22.5': 24.8,
    '23.5': 24.8,
    '24.5': 24.7,
    '25.5': 24.6,
    '26.5': 24.6,
    '27.5': 24.5,
    '28.5': 24.4,
    '29.5': 24.4,
    '30.5': 24.3,
    '31.5': 24.3,
    '32.5': 24.2,
    '33.5': 24.1,
    '34.5': 24.1,
    '35.5': 24,
    '36.5': 23.9,
    '37.5': 23.9,
    '38.5': 23.8,
    '39.5': 23.8,
    '40.5': 23.7,
    '41.5': 23.6,
    '42.5': 23.6,
    '43.5': 23.5,
    '44.5': 23.4,
    '45.5': 23.4,
    '46.5': 23.3,
    '47.5': 23.3,
    '48.5': 23.2,
    '49.5': 23.1,
    '50.5': 23.1,
    '51.5': 23,
    '52.5': 23,
    '53.5': 22.9,
    '54.5': 22.8,
    '55.5': 22.8,
    '56.5': 22.7,
    '57.5': 22.7,
    '58.5': 22.6,
    '59.5': 22.5,
    '60.5': 22.5,
    '61.5': 22.4,
    '62.5': 22.3,
    '63.5': 22.3,
    '64.5': 22.2,
    '65.5': 22.2,
    '66.5': 22.1,
    '67.5': 22.1,
    '68.5': 22,
    '69.5': 21.9,
    '70.5': 21.9,
    '71.5': 21.8,
    '72.5': 21.8,
    '73.5': 21.7,
    '74.5': 21.6,
    '75.5': 21.6,
    '76.5': 21.5,
    '77.5': 21.5,
    '78.5': 21.4,
    '79.5': 21.3,
    '80.5': 21.3,
    '81.5': 21.2,
    '82.5': 21.2,
    '83.5': 21.1,
    '84.5': 21,
    '85.5': 21,
    '86.5': 20.9,
    '87.5': 20.9,
    '88.5': 20.8,
    '89.5': 20.8,
    '90.5': 20.7,
    '91.5': 20.6,
    '92.5': 20.6,
    '93.5': 20.5,
    '94.5': 20.5,
    '95.5': 20.4,
    '96.5': 20.4,
    '97.5': 20.3,
    '98.5': 20.2,
    '99.5': 20.2,
    '100.5': 20.1,
    '101.5': 20.1,
    '102.5': 20,
    '103.5': 20,
    '104.5': 19.9,
    '105.5': 19.8,
    '106.5': 19.8,
    '107.5': 19.7,
    '108.5': 19.7,
    '109.5': 19.6,
    '110.5': 19.6,
    '111.5': 19.5,
    '112.5': 19.4,
    '113.5': 19.4,
    '114.5': 19.3,
    '115.5': 19.3,
    '116.5': 19.2,
    '117.5': 19.2,
    '118.5': 19.1,
    '119.5': 19,
    '120.5': 19,
    '121.5': 18.9,
    '122.5': 18.9,
    '123.5': 18.8,
    '124.5': 18.8,
    '125.5': 18.7,
    '126.5': 18.7,
    '127.5': 18.6,
    '128.5': 18.6,
    '129.5': 18.5,
    '130.5': 18.4,
    '131.5': 18.4,
    '132.5': 18.3,
    '133.5': 18.3,
    '134.5': 18.2,
    '135.5': 18.2,
    '136.5': 18.1,
    '137.5': 18.1,
    '138.5': 18,
    '139.5': 17.9,
    '140.5': 17.9,
    '141.5': 17.8,
    '142.5': 17.8,
    '143.5': 17.7,
    '144.5': 17.7,
    '145.5': 17.6,
    '146.5': 17.6,
    '147.5': 17.5,
    '148.5': 17.5,
    '149.5': 17.4,
    '150.5': 17.4,
    '151.5': 17.3,
    '152.5': 17.3,
    '153.5': 17.2,
    '154.5': 17.1,
    '155.5': 17.1,
    '156.5': 17,
    '157.5': 17,
    '158.5': 16.9,
    '159.5': 16.9,
    '160.5': 16.8,
    '161.5': 16.8,
    '162.5': 16.7,
    '163.5': 16.7,
    '164.5': 16.6,
    '165.5': 16.6,
    '166.5': 16.5,
    '167.5': 16.5,
    '168.5': 16.4,
    '169.5': 16.4,
    '170.5': 16.3,
    '171.5': 16.3,
    '172.5': 16.2,
    '173.5': 16.1,
    '174.5': 16.1,
    '175.5': 16,
    '176.5': 16,
    '177.5': 15.9,
    '178.5': 15.9,
    '179.5': 15.8,
    '180.5': 15.8,
    '181.5': 15.7,
    '182.5': 15.7,
    '183.5': 15.6,
    '184.5': 15.6,
    '185.5': 15.5,
    '186.5': 15.5,
    '187.5': 15.4,
    '188.5': 15.4,
    '189.5': 15.3,
    '190.5': 15.3,
    '191.5': 15.2,
    '192.5': 15.2,
    '193.5': 15.1,
    '194.5': 15.1,
    '195.5': 15,
    '196.5': 15,
    '197.5': 14.9,
    '198.5': 14.9,
    '199.5': 14.8,
    '200.5': 14.8,
    '201.5': 14.7,
    '202.5': 14.7,
    '203.5': 14.6,
    '204.5': 14.6,
    '205.5': 14.5,
    '206.5': 14.5,
    '207.5': 14.4,
    '208.5': 14.4,
    '209.5': 14.3,
  },
  '23': {
    '0': 26.8,
    '1': 26.8,
    '2': 26.7,
    '3': 26.6,
    '4': 26.6,
    '5': 26.5,
    '6': 26.4,
    '7': 26.4,
    '8': 26.3,
    '9': 26.2,
    '10': 26.2,
    '11': 26.1,
    '12': 26,
    '13': 26,
    '14': 25.9,
    '15': 25.8,
    '16': 25.8,
    '17': 25.7,
    '18': 25.6,
    '19': 25.6,
    '20': 25.5,
    '21': 25.4,
    '22': 25.4,
    '23': 25.3,
    '24': 25.3,
    '25': 25.2,
    '26': 25.1,
    '27': 25.1,
    '28': 25,
    '29': 24.9,
    '30': 24.9,
    '31': 24.8,
    '32': 24.7,
    '33': 24.7,
    '34': 24.6,
    '35': 24.6,
    '36': 24.5,
    '37': 24.4,
    '38': 24.4,
    '39': 24.3,
    '40': 24.2,
    '41': 24.2,
    '42': 24.1,
    '43': 24,
    '44': 24,
    '45': 23.9,
    '46': 23.9,
    '47': 23.8,
    '48': 23.7,
    '49': 23.7,
    '50': 23.6,
    '51': 23.6,
    '52': 23.5,
    '53': 23.4,
    '54': 23.4,
    '55': 23.3,
    '56': 23.2,
    '57': 23.2,
    '58': 23.1,
    '59': 23.1,
    '60': 23,
    '61': 22.9,
    '62': 22.9,
    '63': 22.8,
    '64': 22.8,
    '65': 22.7,
    '66': 22.6,
    '67': 22.6,
    '68': 22.5,
    '69': 22.5,
    '70': 22.4,
    '71': 22.3,
    '72': 22.3,
    '73': 22.2,
    '74': 22.2,
    '75': 22.1,
    '76': 22,
    '77': 22,
    '78': 21.9,
    '79': 21.9,
    '80': 21.8,
    '81': 21.7,
    '82': 21.7,
    '83': 21.6,
    '84': 21.6,
    '85': 21.5,
    '86': 21.4,
    '87': 21.4,
    '88': 21.3,
    '89': 21.3,
    '90': 21.2,
    '91': 21.2,
    '92': 21.2,
    '93': 21,
    '94': 21,
    '95': 20.9,
    '96': 20.9,
    '97': 20.8,
    '98': 20.7,
    '99': 20.7,
    '100': 20.6,
    '101': 20.6,
    '102': 20.5,
    '103': 20.5,
    '104': 20.4,
    '105': 20.3,
    '106': 20.3,
    '107': 20.2,
    '108': 20.2,
    '109': 20.1,
    '110': 20.1,
    '111': 20,
    '112': 19.9,
    '113': 19.9,
    '114': 19.8,
    '115': 19.8,
    '116': 19.7,
    '117': 19.7,
    '118': 19.6,
    '119': 19.5,
    '120': 19.5,
    '121': 19.4,
    '122': 19.4,
    '123': 19.3,
    '124': 19.3,
    '125': 19.2,
    '126': 19.2,
    '127': 19.1,
    '128': 19,
    '129': 19,
    '130': 18.9,
    '131': 18.9,
    '132': 18.8,
    '133': 18.8,
    '134': 18.7,
    '135': 18.7,
    '136': 18.6,
    '137': 18.5,
    '138': 18.5,
    '139': 18.4,
    '140': 18.4,
    '141': 18.4,
    '142': 18.3,
    '143': 18.2,
    '144': 18.2,
    '145': 18.1,
    '146': 18.1,
    '147': 18,
    '148': 17.9,
    '149': 17.9,
    '150': 17.8,
    '151': 17.8,
    '152': 17.7,
    '153': 17.7,
    '154': 17.6,
    '155': 17.6,
    '156': 17.5,
    '157': 17.5,
    '158': 17.4,
    '159': 17.4,
    '160': 17.3,
    '161': 17.2,
    '162': 17.2,
    '163': 17.1,
    '164': 17.1,
    '165': 17,
    '166': 17,
    '167': 16.9,
    '168': 16.9,
    '169': 16.8,
    '170': 16.8,
    '171': 16.7,
    '172': 16.7,
    '173': 16.6,
    '174': 16.6,
    '175': 16.5,
    '176': 16.5,
    '177': 16.4,
    '178': 16.4,
    '179': 16.3,
    '180': 16.2,
    '181': 16.2,
    '182': 16.1,
    '183': 16.1,
    '184': 16,
    '185': 16,
    '186': 15.9,
    '187': 15.9,
    '188': 15.8,
    '189': 15.8,
    '190': 15.7,
    '191': 15.7,
    '192': 15.6,
    '193': 15.6,
    '194': 15.5,
    '195': 15.5,
    '196': 15.4,
    '197': 15.4,
    '198': 15.3,
    '199': 15.3,
    '200': 15.2,
    '201': 15.2,
    '202': 15.1,
    '203': 15.1,
    '204': 15,
    '205': 15,
    '206': 14.9,
    '207': 14.9,
    '208': 14.8,
    '209': 14.8,
    '210': 14.7,
    '0.5': 26.8,
    '1.5': 26.7,
    '2.5': 26.7,
    '3.5': 26.6,
    '4.5': 26.5,
    '5.5': 26.5,
    '6.5': 26.4,
    '7.5': 26.3,
    '8.5': 26.3,
    '9.5': 26.2,
    '10.5': 26.1,
    '11.5': 26.1,
    '12.5': 26,
    '13.5': 25.9,
    '14.5': 25.9,
    '15.5': 25.8,
    '16.5': 25.7,
    '17.5': 25.7,
    '18.5': 25.6,
    '19.5': 25.5,
    '20.5': 25.5,
    '21.5': 25.4,
    '22.5': 25.3,
    '23.5': 25.3,
    '24.5': 25.2,
    '25.5': 25.2,
    '26.5': 25.1,
    '27.5': 25,
    '28.5': 25,
    '29.5': 24.9,
    '30.5': 24.8,
    '31.5': 24.8,
    '32.5': 24.7,
    '33.5': 24.6,
    '34.5': 24.6,
    '35.5': 24.5,
    '36.5': 24.5,
    '37.5': 24.4,
    '38.5': 24.3,
    '39.5': 24.3,
    '40.5': 24.2,
    '41.5': 24.1,
    '42.5': 24.1,
    '43.5': 24,
    '44.5': 24,
    '45.5': 23.9,
    '46.5': 23.8,
    '47.5': 23.8,
    '48.5': 23.7,
    '49.5': 23.6,
    '50.5': 23.6,
    '51.5': 23.5,
    '52.5': 23.5,
    '53.5': 23.4,
    '54.5': 23.3,
    '55.5': 23.3,
    '56.5': 23.2,
    '57.5': 23.2,
    '58.5': 23.1,
    '59.5': 23,
    '60.5': 23,
    '61.5': 22.9,
    '62.5': 22.8,
    '63.5': 22.8,
    '64.5': 22.7,
    '65.5': 22.7,
    '66.5': 22.6,
    '67.5': 22.5,
    '68.5': 22.5,
    '69.5': 22.4,
    '70.5': 22.4,
    '71.5': 22.3,
    '72.5': 22.2,
    '73.5': 22.2,
    '74.5': 22.1,
    '75.5': 22.1,
    '76.5': 22,
    '77.5': 21.9,
    '78.5': 21.9,
    '79.5': 21.8,
    '80.5': 21.8,
    '81.5': 21.7,
    '82.5': 21.6,
    '83.5': 21.6,
    '84.5': 21.5,
    '85.5': 21.5,
    '86.5': 21.4,
    '87.5': 21.4,
    '88.5': 21.3,
    '89.5': 21.2,
    '90.5': 21.2,
    '91.5': 21.2,
    '92.5': 21.1,
    '93.5': 21,
    '94.5': 20.9,
    '95.5': 20.9,
    '96.5': 20.8,
    '97.5': 20.8,
    '98.5': 20.7,
    '99.5': 20.7,
    '100.5': 20.6,
    '101.5': 20.5,
    '102.5': 20.5,
    '103.5': 20.4,
    '104.5': 20.4,
    '105.5': 20.3,
    '106.5': 20.3,
    '107.5': 20.2,
    '108.5': 20.1,
    '109.5': 20.1,
    '110.5': 20,
    '111.5': 20,
    '112.5': 19.9,
    '113.5': 19.9,
    '114.5': 19.8,
    '115.5': 19.7,
    '116.5': 19.7,
    '117.5': 19.6,
    '118.5': 19.6,
    '119.5': 19.5,
    '120.5': 19.5,
    '121.5': 19.4,
    '122.5': 19.3,
    '123.5': 19.3,
    '124.5': 19.2,
    '125.5': 19.2,
    '126.5': 19.1,
    '127.5': 19.1,
    '128.5': 19,
    '129.5': 19,
    '130.5': 18.9,
    '131.5': 18.8,
    '132.5': 18.8,
    '133.5': 18.7,
    '134.5': 18.7,
    '135.5': 18.6,
    '136.5': 18.6,
    '137.5': 18.5,
    '138.5': 18.5,
    '139.5': 18.4,
    '140.5': 18.4,
    '141.5': 18.3,
    '142.5': 18.2,
    '143.5': 18.2,
    '144.5': 18.1,
    '145.5': 18.1,
    '146.5': 18,
    '147.5': 18,
    '148.5': 17.9,
    '149.5': 17.9,
    '150.5': 17.8,
    '151.5': 17.8,
    '152.5': 17.7,
    '153.5': 17.6,
    '154.5': 17.6,
    '155.5': 17.5,
    '156.5': 17.5,
    '157.5': 17.4,
    '158.5': 17.4,
    '159.5': 17.3,
    '160.5': 17.3,
    '161.5': 17.2,
    '162.5': 17.2,
    '163.5': 17.1,
    '164.5': 17.1,
    '165.5': 17,
    '166.5': 17,
    '167.5': 16.9,
    '168.5': 16.9,
    '169.5': 16.8,
    '170.5': 16.7,
    '171.5': 16.7,
    '172.5': 16.6,
    '173.5': 16.6,
    '174.5': 16.5,
    '175.5': 16.5,
    '176.5': 16.4,
    '177.5': 16.4,
    '178.5': 16.3,
    '179.5': 16.3,
    '180.5': 16.2,
    '181.5': 16.2,
    '182.5': 16.1,
    '183.5': 16.1,
    '184.5': 16,
    '185.5': 16,
    '186.5': 15.9,
    '187.5': 15.9,
    '188.5': 15.8,
    '189.5': 15.8,
    '190.5': 15.7,
    '191.5': 15.7,
    '192.5': 15.6,
    '193.5': 15.6,
    '194.5': 15.5,
    '195.5': 15.5,
    '196.5': 15.4,
    '197.5': 15.3,
    '198.5': 15.3,
    '199.5': 15.2,
    '200.5': 15.2,
    '201.5': 15.1,
    '202.5': 15.1,
    '203.5': 15,
    '204.5': 15,
    '205.5': 14.9,
    '206.5': 14.9,
    '207.5': 14.8,
    '208.5': 14.8,
    '209.5': 14.7,
  },
  '23.5': {
    '0': 27.4,
    '1': 27.3,
    '2': 27.2,
    '3': 27.2,
    '4': 27.1,
    '5': 27,
    '6': 27,
    '7': 26.9,
    '8': 26.8,
    '9': 26.8,
    '10': 26.7,
    '11': 26.6,
    '12': 26.6,
    '13': 26.5,
    '14': 26.4,
    '15': 26.4,
    '16': 26.3,
    '17': 26.2,
    '18': 26.2,
    '19': 26.1,
    '20': 26,
    '21': 26,
    '22': 25.9,
    '23': 25.8,
    '24': 25.8,
    '25': 25.7,
    '26': 25.6,
    '27': 25.6,
    '28': 25.5,
    '29': 25.5,
    '30': 25.4,
    '31': 25.3,
    '32': 25.3,
    '33': 25.2,
    '34': 25.1,
    '35': 25.1,
    '36': 25,
    '37': 24.9,
    '38': 24.9,
    '39': 24.8,
    '40': 24.7,
    '41': 24.7,
    '42': 24.6,
    '43': 24.6,
    '44': 24.5,
    '45': 24.4,
    '46': 24.4,
    '47': 24.3,
    '48': 24.2,
    '49': 24.2,
    '50': 24.1,
    '51': 24.1,
    '52': 24,
    '53': 23.9,
    '54': 23.9,
    '55': 23.8,
    '56': 23.7,
    '57': 23.7,
    '58': 23.6,
    '59': 23.6,
    '60': 23.5,
    '61': 23.4,
    '62': 23.4,
    '63': 23.3,
    '64': 23.3,
    '65': 23.2,
    '66': 23.1,
    '67': 23.1,
    '68': 23,
    '69': 22.9,
    '70': 22.9,
    '71': 22.8,
    '72': 22.8,
    '73': 22.7,
    '74': 22.6,
    '75': 22.6,
    '76': 22.5,
    '77': 22.5,
    '78': 22.4,
    '79': 22.3,
    '80': 22.3,
    '81': 22.2,
    '82': 22.2,
    '83': 22.1,
    '84': 22,
    '85': 22,
    '86': 21.9,
    '87': 21.9,
    '88': 21.8,
    '89': 21.7,
    '90': 21.7,
    '91': 21.6,
    '92': 21.6,
    '93': 21.5,
    '94': 21.5,
    '95': 21.4,
    '96': 21.3,
    '97': 21.3,
    '98': 21.2,
    '99': 21.2,
    '100': 21.1,
    '101': 21,
    '102': 21,
    '103': 20.9,
    '104': 20.9,
    '105': 20.8,
    '106': 20.8,
    '107': 20.7,
    '108': 20.6,
    '109': 20.6,
    '110': 20.5,
    '111': 20.5,
    '112': 20.4,
    '113': 20.4,
    '114': 20.3,
    '115': 20.2,
    '116': 20.2,
    '117': 20.1,
    '118': 20.1,
    '119': 20,
    '120': 20,
    '121': 19.9,
    '122': 19.8,
    '123': 19.8,
    '124': 19.7,
    '125': 19.7,
    '126': 19.6,
    '127': 19.6,
    '128': 19.5,
    '129': 19.4,
    '130': 19.4,
    '131': 19.3,
    '132': 19.3,
    '133': 19.2,
    '134': 19.2,
    '135': 19.1,
    '136': 19.1,
    '137': 19,
    '138': 18.9,
    '139': 18.9,
    '140': 18.8,
    '141': 18.8,
    '142': 18.7,
    '143': 18.7,
    '144': 18.6,
    '145': 18.6,
    '146': 18.5,
    '147': 18.5,
    '148': 18.4,
    '149': 18.3,
    '150': 18.3,
    '151': 18.2,
    '152': 18.2,
    '153': 18.1,
    '154': 18.1,
    '155': 18,
    '156': 18,
    '157': 17.9,
    '158': 17.9,
    '159': 17.8,
    '160': 17.7,
    '161': 17.7,
    '162': 17.6,
    '163': 17.6,
    '164': 17.5,
    '165': 17.5,
    '166': 17.4,
    '167': 17.4,
    '168': 17.3,
    '169': 17.3,
    '170': 17.2,
    '171': 17.2,
    '172': 17.1,
    '173': 17.1,
    '174': 17,
    '175': 16.9,
    '176': 16.9,
    '177': 16.8,
    '178': 16.8,
    '179': 16.7,
    '180': 16.7,
    '181': 16.6,
    '182': 16.6,
    '183': 16.5,
    '184': 16.5,
    '185': 16.4,
    '186': 16.4,
    '187': 16.3,
    '188': 16.3,
    '189': 16.2,
    '190': 16.2,
    '191': 16.1,
    '192': 16.1,
    '193': 16,
    '194': 16,
    '195': 15.9,
    '196': 15.9,
    '197': 15.8,
    '198': 15.8,
    '199': 15.7,
    '200': 15.6,
    '201': 15.6,
    '202': 15.5,
    '203': 15.5,
    '204': 15.4,
    '205': 15.4,
    '206': 15.3,
    '207': 15.3,
    '208': 15.2,
    '209': 15.2,
    '210': 15.1,
    '0.5': 27.3,
    '1.5': 27.3,
    '2.5': 27.2,
    '3.5': 27.1,
    '4.5': 27.1,
    '5.5': 27,
    '6.5': 26.9,
    '7.5': 26.9,
    '8.5': 26.8,
    '9.5': 26.7,
    '10.5': 26.7,
    '11.5': 26.6,
    '12.5': 26.5,
    '13.5': 26.5,
    '14.5': 26.4,
    '15.5': 26.3,
    '16.5': 26.3,
    '17.5': 26.2,
    '18.5': 26.1,
    '19.5': 26.1,
    '20.5': 26,
    '21.5': 25.9,
    '22.5': 25.9,
    '23.5': 25.8,
    '24.5': 25.7,
    '25.5': 25.7,
    '26.5': 25.6,
    '27.5': 25.5,
    '28.5': 25.5,
    '29.5': 25.4,
    '30.5': 25.4,
    '31.5': 25.3,
    '32.5': 25.2,
    '33.5': 25.2,
    '34.5': 25.1,
    '35.5': 25,
    '36.5': 25,
    '37.5': 24.9,
    '38.5': 24.8,
    '39.5': 24.8,
    '40.5': 24.7,
    '41.5': 24.7,
    '42.5': 24.6,
    '43.5': 24.5,
    '44.5': 24.5,
    '45.5': 24.4,
    '46.5': 24.3,
    '47.5': 24.3,
    '48.5': 24.2,
    '49.5': 24.2,
    '50.5': 24.1,
    '51.5': 24,
    '52.5': 24,
    '53.5': 23.9,
    '54.5': 23.8,
    '55.5': 23.8,
    '56.5': 23.7,
    '57.5': 23.7,
    '58.5': 23.6,
    '59.5': 23.5,
    '60.5': 23.5,
    '61.5': 23.4,
    '62.5': 23.3,
    '63.5': 23.3,
    '64.5': 23.2,
    '65.5': 23.2,
    '66.5': 23.1,
    '67.5': 23,
    '68.5': 23,
    '69.5': 22.9,
    '70.5': 22.9,
    '71.5': 22.8,
    '72.5': 22.7,
    '73.5': 22.7,
    '74.5': 22.6,
    '75.5': 22.6,
    '76.5': 22.5,
    '77.5': 22.4,
    '78.5': 22.4,
    '79.5': 22.3,
    '80.5': 22.3,
    '81.5': 22.2,
    '82.5': 22.1,
    '83.5': 22.1,
    '84.5': 22,
    '85.5': 22,
    '86.5': 21.9,
    '87.5': 21.8,
    '88.5': 21.8,
    '89.5': 21.7,
    '90.5': 21.7,
    '91.5': 21.6,
    '92.5': 21.5,
    '93.5': 21.5,
    '94.5': 21.4,
    '95.5': 21.4,
    '96.5': 21.3,
    '97.5': 21.2,
    '98.5': 21.2,
    '99.5': 21.1,
    '100.5': 21.1,
    '101.5': 21,
    '102.5': 21,
    '103.5': 20.9,
    '104.5': 20.8,
    '105.5': 20.8,
    '106.5': 20.7,
    '107.5': 20.7,
    '108.5': 20.6,
    '109.5': 20.6,
    '110.5': 20.5,
    '111.5': 20.4,
    '112.5': 20.4,
    '113.5': 20.3,
    '114.5': 20.3,
    '115.5': 20.2,
    '116.5': 20.2,
    '117.5': 20.1,
    '118.5': 20,
    '119.5': 20,
    '120.5': 19.9,
    '121.5': 19.9,
    '122.5': 19.8,
    '123.5': 19.8,
    '124.5': 19.7,
    '125.5': 19.6,
    '126.5': 19.6,
    '127.5': 19.5,
    '128.5': 19.5,
    '129.5': 19.4,
    '130.5': 19.4,
    '131.5': 19.3,
    '132.5': 19.3,
    '133.5': 19.2,
    '134.5': 19.1,
    '135.5': 19.1,
    '136.5': 19,
    '137.5': 19,
    '138.5': 18.9,
    '139.5': 18.9,
    '140.5': 18.8,
    '141.5': 18.8,
    '142.5': 18.7,
    '143.5': 18.6,
    '144.5': 18.6,
    '145.5': 18.5,
    '146.5': 18.5,
    '147.5': 18.4,
    '148.5': 18.4,
    '149.5': 18.3,
    '150.5': 18.3,
    '151.5': 18.2,
    '152.5': 18.2,
    '153.5': 18.1,
    '154.5': 18,
    '155.5': 18,
    '156.5': 17.9,
    '157.5': 17.9,
    '158.5': 17.8,
    '159.5': 17.8,
    '160.5': 17.7,
    '161.5': 17.7,
    '162.5': 17.6,
    '163.5': 17.6,
    '164.5': 17.5,
    '165.5': 17.5,
    '166.5': 17.4,
    '167.5': 17.3,
    '168.5': 17.3,
    '169.5': 17.2,
    '170.5': 17.2,
    '171.5': 17.1,
    '172.5': 17.1,
    '173.5': 17,
    '174.5': 17,
    '175.5': 16.9,
    '176.5': 16.9,
    '177.5': 16.8,
    '178.5': 16.8,
    '179.5': 16.7,
    '180.5': 16.7,
    '181.5': 16.6,
    '182.5': 16.6,
    '183.5': 16.5,
    '184.5': 16.4,
    '185.5': 16.4,
    '186.5': 16.3,
    '187.5': 16.3,
    '188.5': 16.2,
    '189.5': 16.2,
    '190.5': 16.1,
    '191.5': 16.1,
    '192.5': 16,
    '193.5': 16,
    '194.5': 15.9,
    '195.5': 15.9,
    '196.5': 15.8,
    '197.5': 15.8,
    '198.5': 15.7,
    '199.5': 15.7,
    '200.5': 15.6,
    '201.5': 15.6,
    '202.5': 15.5,
    '203.5': 15.5,
    '204.5': 15.4,
    '205.5': 15.4,
    '206.5': 15.3,
    '207.5': 15.3,
    '208.5': 15.2,
    '209.5': 15.2,
  },
  '24': {
    '0': 27.9,
    '1': 27.8,
    '2': 27.8,
    '3': 27.7,
    '4': 27.6,
    '5': 27.6,
    '6': 27.5,
    '7': 27.4,
    '8': 27.4,
    '9': 27.3,
    '10': 27.2,
    '11': 27.2,
    '12': 27.1,
    '13': 27,
    '14': 27,
    '15': 26.9,
    '16': 26.8,
    '17': 26.8,
    '18': 26.7,
    '19': 26.6,
    '20': 26.6,
    '21': 26.5,
    '22': 26.4,
    '23': 26.4,
    '24': 26.3,
    '25': 26.2,
    '26': 26.2,
    '27': 26.1,
    '28': 26,
    '29': 26,
    '30': 25.9,
    '31': 25.8,
    '32': 25.8,
    '33': 25.7,
    '34': 25.6,
    '35': 25.6,
    '36': 25.5,
    '37': 25.5,
    '38': 25.4,
    '39': 25.3,
    '40': 25.3,
    '41': 25.2,
    '42': 25.1,
    '43': 25.1,
    '44': 25,
    '45': 24.9,
    '46': 24.9,
    '47': 24.8,
    '48': 24.8,
    '49': 24.7,
    '50': 24.6,
    '51': 24.6,
    '52': 24.5,
    '53': 24.4,
    '54': 24.4,
    '55': 24.3,
    '56': 24.2,
    '57': 24.2,
    '58': 24.1,
    '59': 24.1,
    '60': 24,
    '61': 23.9,
    '62': 23.9,
    '63': 23.8,
    '64': 23.8,
    '65': 23.7,
    '66': 23.6,
    '67': 23.6,
    '68': 23.5,
    '69': 23.4,
    '70': 23.4,
    '71': 23.3,
    '72': 23.3,
    '73': 23.2,
    '74': 23.1,
    '75': 23.1,
    '76': 23,
    '77': 23,
    '78': 22.9,
    '79': 22.8,
    '80': 22.8,
    '81': 22.7,
    '82': 22.7,
    '83': 22.6,
    '84': 22.5,
    '85': 22.5,
    '86': 22.4,
    '87': 22.4,
    '88': 22.3,
    '89': 22.2,
    '90': 22.2,
    '91': 22.1,
    '92': 22.1,
    '93': 22,
    '94': 21.9,
    '95': 21.9,
    '96': 21.8,
    '97': 21.8,
    '98': 21.7,
    '99': 21.6,
    '100': 21.6,
    '101': 21.5,
    '102': 21.5,
    '103': 21.4,
    '104': 21.3,
    '105': 21.3,
    '106': 21.2,
    '107': 21.2,
    '108': 21.1,
    '109': 21.1,
    '110': 21,
    '111': 20.9,
    '112': 20.9,
    '113': 20.8,
    '114': 20.8,
    '115': 20.7,
    '116': 20.6,
    '117': 20.6,
    '118': 20.5,
    '119': 20.5,
    '120': 20.4,
    '121': 20.4,
    '122': 20.3,
    '123': 20.2,
    '124': 20.2,
    '125': 20.1,
    '126': 20.1,
    '127': 20,
    '128': 20,
    '129': 19.9,
    '130': 19.9,
    '131': 19.8,
    '132': 19.7,
    '133': 19.7,
    '134': 19.6,
    '135': 19.6,
    '136': 19.5,
    '137': 19.5,
    '138': 19.4,
    '139': 19.3,
    '140': 19.3,
    '141': 19.2,
    '142': 19.2,
    '143': 19.1,
    '144': 19.1,
    '145': 19,
    '146': 19,
    '147': 18.9,
    '148': 18.8,
    '149': 18.8,
    '150': 18.7,
    '151': 18.7,
    '152': 18.6,
    '153': 18.6,
    '154': 18.5,
    '155': 18.5,
    '156': 18.4,
    '157': 18.4,
    '158': 18.3,
    '159': 18.2,
    '160': 18.2,
    '161': 18.1,
    '162': 18.1,
    '163': 18,
    '164': 18,
    '165': 17.9,
    '166': 17.9,
    '167': 17.8,
    '168': 17.8,
    '169': 17.7,
    '170': 17.7,
    '171': 17.6,
    '172': 17.5,
    '173': 17.5,
    '174': 17.4,
    '175': 17.4,
    '176': 17.3,
    '177': 17.3,
    '178': 17.2,
    '179': 17.2,
    '180': 17.1,
    '181': 17.1,
    '182': 17,
    '183': 17,
    '184': 16.9,
    '185': 16.9,
    '186': 16.8,
    '187': 16.8,
    '188': 16.7,
    '189': 16.6,
    '190': 16.6,
    '191': 16.5,
    '192': 16.5,
    '193': 16.4,
    '194': 16.4,
    '195': 16.3,
    '196': 16.3,
    '197': 16.2,
    '198': 16.2,
    '199': 16.1,
    '200': 16.1,
    '201': 16,
    '202': 16,
    '203': 15.9,
    '204': 15.9,
    '205': 15.8,
    '206': 15.8,
    '207': 15.7,
    '208': 15.7,
    '209': 15.6,
    '210': 15.6,
    '0.5': 27.9,
    '1.5': 27.8,
    '2.5': 27.7,
    '3.5': 27.7,
    '4.5': 27.6,
    '5.5': 27.5,
    '6.5': 27.5,
    '7.5': 27.4,
    '8.5': 27.3,
    '9.5': 27.3,
    '10.5': 27.2,
    '11.5': 27.1,
    '12.5': 27.1,
    '13.5': 27,
    '14.5': 26.9,
    '15.5': 26.9,
    '16.5': 26.8,
    '17.5': 26.7,
    '18.5': 26.7,
    '19.5': 26.6,
    '20.5': 26.5,
    '21.5': 26.5,
    '22.5': 26.4,
    '23.5': 26.3,
    '24.5': 26.3,
    '25.5': 26.2,
    '26.5': 26.1,
    '27.5': 26.1,
    '28.5': 26,
    '29.5': 25.9,
    '30.5': 25.9,
    '31.5': 25.8,
    '32.5': 25.7,
    '33.5': 25.7,
    '34.5': 25.6,
    '35.5': 25.5,
    '36.5': 25.5,
    '37.5': 25.4,
    '38.5': 25.4,
    '39.5': 25.3,
    '40.5': 25.2,
    '41.5': 25.2,
    '42.5': 25.1,
    '43.5': 25,
    '44.5': 25,
    '45.5': 24.9,
    '46.5': 24.8,
    '47.5': 24.8,
    '48.5': 24.7,
    '49.5': 24.7,
    '50.5': 24.6,
    '51.5': 24.5,
    '52.5': 24.5,
    '53.5': 24.4,
    '54.5': 24.3,
    '55.5': 24.3,
    '56.5': 24.2,
    '57.5': 24.2,
    '58.5': 24.1,
    '59.5': 24,
    '60.5': 24,
    '61.5': 23.9,
    '62.5': 23.8,
    '63.5': 23.8,
    '64.5': 23.7,
    '65.5': 23.7,
    '66.5': 23.6,
    '67.5': 23.5,
    '68.5': 23.5,
    '69.5': 23.4,
    '70.5': 23.4,
    '71.5': 23.3,
    '72.5': 23.2,
    '73.5': 23.2,
    '74.5': 23.1,
    '75.5': 23,
    '76.5': 23,
    '77.5': 22.9,
    '78.5': 22.9,
    '79.5': 22.8,
    '80.5': 22.7,
    '81.5': 22.7,
    '82.5': 22.6,
    '83.5': 22.6,
    '84.5': 22.5,
    '85.5': 22.4,
    '86.5': 22.4,
    '87.5': 22.3,
    '88.5': 22.3,
    '89.5': 22.2,
    '90.5': 22.1,
    '91.5': 22.1,
    '92.5': 22,
    '93.5': 22,
    '94.5': 21.9,
    '95.5': 21.8,
    '96.5': 21.8,
    '97.5': 21.7,
    '98.5': 21.7,
    '99.5': 21.6,
    '100.5': 21.6,
    '101.5': 21.5,
    '102.5': 21.4,
    '103.5': 21.4,
    '104.5': 21.3,
    '105.5': 21.3,
    '106.5': 21.2,
    '107.5': 21.1,
    '108.5': 21.1,
    '109.5': 21,
    '110.5': 21,
    '111.5': 20.9,
    '112.5': 20.9,
    '113.5': 20.8,
    '114.5': 20.7,
    '115.5': 20.7,
    '116.5': 20.6,
    '117.5': 20.6,
    '118.5': 20.5,
    '119.5': 20.4,
    '120.5': 20.4,
    '121.5': 20.3,
    '122.5': 20.3,
    '123.5': 20.2,
    '124.5': 20.2,
    '125.5': 20.1,
    '126.5': 20,
    '127.5': 20,
    '128.5': 19.9,
    '129.5': 19.9,
    '130.5': 19.8,
    '131.5': 19.8,
    '132.5': 19.7,
    '133.5': 19.7,
    '134.5': 19.6,
    '135.5': 19.5,
    '136.5': 19.5,
    '137.5': 19.4,
    '138.5': 19.4,
    '139.5': 19.3,
    '140.5': 19.3,
    '141.5': 19.2,
    '142.5': 19.2,
    '143.5': 19.1,
    '144.5': 19,
    '145.5': 19,
    '146.5': 18.9,
    '147.5': 18.9,
    '148.5': 18.8,
    '149.5': 18.8,
    '150.5': 18.7,
    '151.5': 18.7,
    '152.5': 18.6,
    '153.5': 18.5,
    '154.5': 18.5,
    '155.5': 18.4,
    '156.5': 18.4,
    '157.5': 18.3,
    '158.5': 18.3,
    '159.5': 18.2,
    '160.5': 18.2,
    '161.5': 18.1,
    '162.5': 18.1,
    '163.5': 18,
    '164.5': 17.9,
    '165.5': 17.9,
    '166.5': 17.8,
    '167.5': 17.8,
    '168.5': 17.7,
    '169.5': 17.7,
    '170.5': 17.6,
    '171.5': 17.6,
    '172.5': 17.5,
    '173.5': 17.5,
    '174.5': 17.4,
    '175.5': 17.4,
    '176.5': 17.3,
    '177.5': 17.3,
    '178.5': 17.2,
    '179.5': 17.1,
    '180.5': 17.1,
    '181.5': 17,
    '182.5': 17,
    '183.5': 16.9,
    '184.5': 16.9,
    '185.5': 16.8,
    '186.5': 16.8,
    '187.5': 16.7,
    '188.5': 16.7,
    '189.5': 16.6,
    '190.5': 16.6,
    '191.5': 16.5,
    '192.5': 16.5,
    '193.5': 16.4,
    '194.5': 16.4,
    '195.5': 16.3,
    '196.5': 16.3,
    '197.5': 16.2,
    '198.5': 16.2,
    '199.5': 16.1,
    '200.5': 16,
    '201.5': 16,
    '202.5': 15.9,
    '203.5': 15.9,
    '204.5': 15.8,
    '205.5': 15.8,
    '206.5': 15.7,
    '207.5': 15.7,
    '208.5': 15.6,
    '209.5': 15.6,
  },
  '24.5': {
    '0': 28.4,
    '1': 28.4,
    '2': 28.3,
    '3': 28.2,
    '4': 28.2,
    '5': 28.1,
    '6': 28,
    '7': 28,
    '8': 27.9,
    '9': 27.8,
    '10': 27.8,
    '11': 27.7,
    '12': 27.6,
    '13': 27.6,
    '14': 27.5,
    '15': 27.4,
    '16': 27.3,
    '17': 27.3,
    '18': 27.2,
    '19': 27.1,
    '20': 27.1,
    '21': 27,
    '22': 27,
    '23': 26.9,
    '24': 26.8,
    '25': 26.8,
    '26': 26.7,
    '27': 26.6,
    '28': 26.6,
    '29': 26.5,
    '30': 26.4,
    '31': 26.4,
    '32': 26.3,
    '33': 26.2,
    '34': 26.2,
    '35': 26.1,
    '36': 26,
    '37': 26,
    '38': 25.9,
    '39': 25.8,
    '40': 25.8,
    '41': 25.7,
    '42': 25.6,
    '43': 25.6,
    '44': 25.5,
    '45': 25.5,
    '46': 25.4,
    '47': 25.3,
    '48': 25.3,
    '49': 25.2,
    '50': 25.1,
    '51': 25.1,
    '52': 25,
    '53': 24.9,
    '54': 24.9,
    '55': 24.8,
    '56': 24.8,
    '57': 24.7,
    '58': 24.6,
    '59': 24.6,
    '60': 24.5,
    '61': 24.4,
    '62': 24.4,
    '63': 24.3,
    '64': 24.2,
    '65': 24.2,
    '66': 24.1,
    '67': 24.1,
    '68': 24,
    '69': 23.9,
    '70': 23.9,
    '71': 23.8,
    '72': 23.8,
    '73': 23.7,
    '74': 23.6,
    '75': 23.6,
    '76': 23.5,
    '77': 23.4,
    '78': 23.4,
    '79': 23.3,
    '80': 23.3,
    '81': 23.2,
    '82': 23.1,
    '83': 23.1,
    '84': 23,
    '85': 23,
    '86': 22.9,
    '87': 22.8,
    '88': 22.8,
    '89': 22.7,
    '90': 22.7,
    '91': 22.6,
    '92': 22.5,
    '93': 22.5,
    '94': 22.4,
    '95': 22.4,
    '96': 22.3,
    '97': 22.2,
    '98': 22.2,
    '99': 22.1,
    '100': 22.1,
    '101': 22,
    '102': 21.9,
    '103': 21.9,
    '104': 21.8,
    '105': 21.8,
    '106': 21.7,
    '107': 21.6,
    '108': 21.6,
    '109': 21.5,
    '110': 21.5,
    '111': 21.4,
    '112': 21.3,
    '113': 21.3,
    '114': 21.2,
    '115': 21.2,
    '116': 21.1,
    '117': 21.1,
    '118': 21,
    '119': 20.9,
    '120': 20.9,
    '121': 20.8,
    '122': 20.8,
    '123': 20.7,
    '124': 20.7,
    '125': 20.6,
    '126': 20.5,
    '127': 20.5,
    '128': 20.4,
    '129': 20.4,
    '130': 20.3,
    '131': 20.3,
    '132': 20.2,
    '133': 20.1,
    '134': 20.1,
    '135': 20,
    '136': 20,
    '137': 19.9,
    '138': 19.9,
    '139': 19.8,
    '140': 19.7,
    '141': 19.7,
    '142': 19.6,
    '143': 19.6,
    '144': 19.5,
    '145': 19.5,
    '146': 19.4,
    '147': 19.4,
    '148': 19.3,
    '149': 19.2,
    '150': 19.2,
    '151': 19.1,
    '152': 19.1,
    '153': 19,
    '154': 19,
    '155': 18.9,
    '156': 18.9,
    '157': 18.8,
    '158': 18.7,
    '159': 18.7,
    '160': 18.6,
    '161': 18.6,
    '162': 18.5,
    '163': 18.5,
    '164': 18.4,
    '165': 18.4,
    '166': 18.3,
    '167': 18.3,
    '168': 18.2,
    '169': 18.1,
    '170': 18.1,
    '171': 18,
    '172': 18,
    '173': 17.9,
    '174': 17.9,
    '175': 17.8,
    '176': 17.8,
    '177': 17.7,
    '178': 17.7,
    '179': 17.6,
    '180': 17.6,
    '181': 17.5,
    '182': 17.4,
    '183': 17.4,
    '184': 17.3,
    '185': 17.3,
    '186': 17.2,
    '187': 17.2,
    '188': 17.1,
    '189': 17.1,
    '190': 17,
    '191': 17,
    '192': 16.9,
    '193': 16.9,
    '194': 16.8,
    '195': 16.8,
    '196': 16.7,
    '197': 16.7,
    '198': 16.6,
    '199': 16.6,
    '200': 16.5,
    '201': 16.4,
    '202': 16.4,
    '203': 16.3,
    '204': 16.3,
    '205': 16.2,
    '206': 16.2,
    '207': 16.1,
    '208': 16.1,
    '209': 16,
    '210': 16,
    '0.5': 28.4,
    '1.5': 28.3,
    '2.5': 28.3,
    '3.5': 28.2,
    '4.5': 28.1,
    '5.5': 28.1,
    '6.5': 28,
    '7.5': 27.9,
    '8.5': 27.9,
    '9.5': 27.8,
    '10.5': 27.7,
    '11.5': 27.7,
    '12.5': 27.6,
    '13.5': 27.5,
    '14.5': 27.4,
    '15.5': 27.4,
    '16.5': 27.3,
    '17.5': 27.3,
    '18.5': 27.2,
    '19.5': 27.1,
    '20.5': 27.1,
    '21.5': 27,
    '22.5': 26.9,
    '23.5': 26.9,
    '24.5': 26.8,
    '25.5': 26.7,
    '26.5': 26.7,
    '27.5': 26.6,
    '28.5': 26.5,
    '29.5': 26.5,
    '30.5': 26.4,
    '31.5': 26.3,
    '32.5': 26.3,
    '33.5': 26.2,
    '34.5': 26.1,
    '35.5': 26.1,
    '36.5': 26,
    '37.5': 25.9,
    '38.5': 25.9,
    '39.5': 25.8,
    '40.5': 25.7,
    '41.5': 25.7,
    '42.5': 25.6,
    '43.5': 25.5,
    '44.5': 25.5,
    '45.5': 25.4,
    '46.5': 25.4,
    '47.5': 25.3,
    '48.5': 25.2,
    '49.5': 25.2,
    '50.5': 25.1,
    '51.5': 25,
    '52.5': 25,
    '53.5': 24.9,
    '54.5': 24.8,
    '55.5': 24.8,
    '56.5': 24.7,
    '57.5': 24.7,
    '58.5': 24.6,
    '59.5': 24.5,
    '60.5': 24.5,
    '61.5': 24.4,
    '62.5': 24.3,
    '63.5': 24.3,
    '64.5': 24.2,
    '65.5': 24.2,
    '66.5': 24.1,
    '67.5': 24,
    '68.5': 24,
    '69.5': 23.9,
    '70.5': 23.8,
    '71.5': 23.8,
    '72.5': 23.7,
    '73.5': 23.7,
    '74.5': 23.6,
    '75.5': 23.5,
    '76.5': 23.5,
    '77.5': 23.4,
    '78.5': 23.4,
    '79.5': 23.3,
    '80.5': 23.2,
    '81.5': 23.2,
    '82.5': 23.1,
    '83.5': 23,
    '84.5': 23,
    '85.5': 22.9,
    '86.5': 22.9,
    '87.5': 22.8,
    '88.5': 22.7,
    '89.5': 22.7,
    '90.5': 22.6,
    '91.5': 22.6,
    '92.5': 22.5,
    '93.5': 22.4,
    '94.5': 22.4,
    '95.5': 22.3,
    '96.5': 22.3,
    '97.5': 22.2,
    '98.5': 22.1,
    '99.5': 22.1,
    '100.5': 22,
    '101.5': 22,
    '102.5': 21.9,
    '103.5': 21.8,
    '104.5': 21.8,
    '105.5': 21.7,
    '106.5': 21.7,
    '107.5': 21.6,
    '108.5': 21.6,
    '109.5': 21.5,
    '110.5': 21.4,
    '111.5': 21.4,
    '112.5': 21.3,
    '113.5': 21.3,
    '114.5': 21.2,
    '115.5': 21.1,
    '116.5': 21.1,
    '117.5': 21,
    '118.5': 21,
    '119.5': 20.9,
    '120.5': 20.9,
    '121.5': 20.8,
    '122.5': 20.7,
    '123.5': 20.7,
    '124.5': 20.6,
    '125.5': 20.6,
    '126.5': 20.5,
    '127.5': 20.5,
    '128.5': 20.4,
    '129.5': 20.3,
    '130.5': 20.3,
    '131.5': 20.2,
    '132.5': 20.2,
    '133.5': 20.1,
    '134.5': 20.1,
    '135.5': 20,
    '136.5': 19.9,
    '137.5': 19.9,
    '138.5': 19.8,
    '139.5': 19.8,
    '140.5': 19.7,
    '141.5': 19.7,
    '142.5': 19.6,
    '143.5': 19.5,
    '144.5': 19.5,
    '145.5': 19.4,
    '146.5': 19.4,
    '147.5': 19.3,
    '148.5': 19.3,
    '149.5': 19.2,
    '150.5': 19.2,
    '151.5': 19.1,
    '152.5': 19,
    '153.5': 19,
    '154.5': 18.9,
    '155.5': 18.9,
    '156.5': 18.8,
    '157.5': 18.8,
    '158.5': 18.7,
    '159.5': 18.7,
    '160.5': 18.6,
    '161.5': 18.6,
    '162.5': 18.5,
    '163.5': 18.4,
    '164.5': 18.4,
    '165.5': 18.3,
    '166.5': 18.3,
    '167.5': 18.2,
    '168.5': 18.2,
    '169.5': 18.1,
    '170.5': 18.1,
    '171.5': 18,
    '172.5': 18,
    '173.5': 17.9,
    '174.5': 17.8,
    '175.5': 17.8,
    '176.5': 17.7,
    '177.5': 17.7,
    '178.5': 17.6,
    '179.5': 17.6,
    '180.5': 17.5,
    '181.5': 17.5,
    '182.5': 17.4,
    '183.5': 17.4,
    '184.5': 17.3,
    '185.5': 17.3,
    '186.5': 17.2,
    '187.5': 17.2,
    '188.5': 17.1,
    '189.5': 17.1,
    '190.5': 17,
    '191.5': 16.9,
    '192.5': 16.9,
    '193.5': 16.8,
    '194.5': 16.8,
    '195.5': 16.7,
    '196.5': 16.7,
    '197.5': 16.6,
    '198.5': 16.6,
    '199.5': 16.5,
    '200.5': 16.5,
    '201.5': 16.4,
    '202.5': 16.4,
    '203.5': 16.3,
    '204.5': 16.3,
    '205.5': 16.2,
    '206.5': 16.2,
    '207.5': 16.1,
    '208.5': 16.1,
    '209.5': 16,
  },
  '25': {
    '0': 29,
    '1': 28.9,
    '2': 28.8,
    '3': 28.8,
    '4': 28.7,
    '5': 28.6,
    '6': 28.6,
    '7': 28.5,
    '8': 28.4,
    '9': 28.4,
    '10': 28.3,
    '11': 28.2,
    '12': 28.2,
    '13': 28.1,
    '14': 28,
    '15': 27.9,
    '16': 27.9,
    '17': 27.8,
    '18': 27.7,
    '19': 27.7,
    '20': 27.6,
    '21': 27.5,
    '22': 27.5,
    '23': 27.4,
    '24': 27.3,
    '25': 27.3,
    '26': 27.2,
    '27': 27.1,
    '28': 27.1,
    '29': 27,
    '30': 26.9,
    '31': 26.9,
    '32': 26.8,
    '33': 26.7,
    '34': 26.7,
    '35': 26.6,
    '36': 26.5,
    '37': 26.5,
    '38': 26.4,
    '39': 26.4,
    '40': 26.3,
    '41': 26.2,
    '42': 26.2,
    '43': 26.1,
    '44': 26,
    '45': 26,
    '46': 25.9,
    '47': 25.8,
    '48': 25.8,
    '49': 25.7,
    '50': 25.6,
    '51': 25.6,
    '52': 25.5,
    '53': 25.4,
    '54': 25.4,
    '55': 25.3,
    '56': 25.3,
    '57': 25.2,
    '58': 25.1,
    '59': 25.1,
    '60': 25,
    '61': 24.9,
    '62': 24.9,
    '63': 24.8,
    '64': 24.7,
    '65': 24.7,
    '66': 24.6,
    '67': 24.6,
    '68': 24.5,
    '69': 24.4,
    '70': 24.4,
    '71': 24.3,
    '72': 24.2,
    '73': 24.2,
    '74': 24.1,
    '75': 24.1,
    '76': 24,
    '77': 23.9,
    '78': 23.9,
    '79': 23.8,
    '80': 23.7,
    '81': 23.7,
    '82': 23.6,
    '83': 23.6,
    '84': 23.5,
    '85': 23.4,
    '86': 23.4,
    '87': 23.3,
    '88': 23.3,
    '89': 23.2,
    '90': 23.1,
    '91': 23.1,
    '92': 23,
    '93': 23,
    '94': 22.9,
    '95': 22.8,
    '96': 22.8,
    '97': 22.7,
    '98': 22.7,
    '99': 22.6,
    '100': 22.5,
    '101': 22.5,
    '102': 22.4,
    '103': 22.4,
    '104': 22.3,
    '105': 22.2,
    '106': 22.2,
    '107': 22.1,
    '108': 22.1,
    '109': 22,
    '110': 21.9,
    '111': 21.9,
    '112': 21.8,
    '113': 21.8,
    '114': 21.7,
    '115': 21.6,
    '116': 21.6,
    '117': 21.5,
    '118': 21.5,
    '119': 21.4,
    '120': 21.3,
    '121': 21.3,
    '122': 21.2,
    '123': 21.2,
    '124': 21.1,
    '125': 21.1,
    '126': 21,
    '127': 20.9,
    '128': 20.9,
    '129': 20.8,
    '130': 20.8,
    '131': 20.7,
    '132': 20.7,
    '133': 20.6,
    '134': 20.5,
    '135': 20.5,
    '136': 20.4,
    '137': 20.4,
    '138': 20.3,
    '139': 20.3,
    '140': 20.2,
    '141': 20.1,
    '142': 20.1,
    '143': 20,
    '144': 20,
    '145': 19.9,
    '146': 19.9,
    '147': 19.8,
    '148': 19.7,
    '149': 19.7,
    '150': 19.6,
    '151': 19.6,
    '152': 19.5,
    '153': 19.4,
    '154': 19.4,
    '155': 19.3,
    '156': 19.3,
    '157': 19.2,
    '158': 19.2,
    '159': 19.1,
    '160': 19.1,
    '161': 19,
    '162': 19,
    '163': 18.9,
    '164': 18.9,
    '165': 18.8,
    '166': 18.8,
    '167': 18.7,
    '168': 18.6,
    '169': 18.6,
    '170': 18.5,
    '171': 18.5,
    '172': 18.4,
    '173': 18.4,
    '174': 18.3,
    '175': 18.3,
    '176': 18.2,
    '177': 18.2,
    '178': 18.1,
    '179': 18,
    '180': 18,
    '181': 17.9,
    '182': 17.9,
    '183': 17.8,
    '184': 17.8,
    '185': 17.7,
    '186': 17.7,
    '187': 17.6,
    '188': 17.6,
    '189': 17.5,
    '190': 17.5,
    '191': 17.4,
    '192': 17.3,
    '193': 17.3,
    '194': 17.2,
    '195': 17.2,
    '196': 17.1,
    '197': 17.1,
    '198': 17,
    '199': 17,
    '200': 16.9,
    '201': 16.9,
    '202': 16.8,
    '203': 16.8,
    '204': 16.7,
    '205': 16.7,
    '206': 16.6,
    '207': 16.6,
    '208': 16.5,
    '209': 16.5,
    '210': 16.4,
    '0.5': 28.9,
    '1.5': 28.9,
    '2.5': 28.8,
    '3.5': 28.7,
    '4.5': 28.7,
    '5.5': 28.6,
    '6.5': 28.5,
    '7.5': 28.5,
    '8.5': 28.4,
    '9.5': 28.3,
    '10.5': 28.3,
    '11.5': 28.2,
    '12.5': 28.1,
    '13.5': 28.1,
    '14.5': 28,
    '15.5': 27.9,
    '16.5': 27.8,
    '17.5': 27.8,
    '18.5': 27.7,
    '19.5': 27.6,
    '20.5': 27.6,
    '21.5': 27.5,
    '22.5': 27.4,
    '23.5': 27.4,
    '24.5': 27.3,
    '25.5': 27.2,
    '26.5': 27.2,
    '27.5': 27.1,
    '28.5': 27,
    '29.5': 27,
    '30.5': 26.9,
    '31.5': 26.8,
    '32.5': 26.8,
    '33.5': 26.7,
    '34.5': 26.6,
    '35.5': 26.6,
    '36.5': 26.5,
    '37.5': 26.4,
    '38.5': 26.4,
    '39.5': 26.3,
    '40.5': 26.3,
    '41.5': 26.2,
    '42.5': 26.1,
    '43.5': 26.1,
    '44.5': 26,
    '45.5': 25.9,
    '46.5': 25.9,
    '47.5': 25.8,
    '48.5': 25.7,
    '49.5': 25.7,
    '50.5': 25.6,
    '51.5': 25.5,
    '52.5': 25.5,
    '53.5': 25.4,
    '54.5': 25.4,
    '55.5': 25.3,
    '56.5': 25.2,
    '57.5': 25.2,
    '58.5': 25.1,
    '59.5': 25,
    '60.5': 25,
    '61.5': 24.9,
    '62.5': 24.8,
    '63.5': 24.8,
    '64.5': 24.7,
    '65.5': 24.7,
    '66.5': 24.6,
    '67.5': 24.5,
    '68.5': 24.5,
    '69.5': 24.4,
    '70.5': 24.3,
    '71.5': 24.3,
    '72.5': 24.2,
    '73.5': 24.2,
    '74.5': 24.1,
    '75.5': 24,
    '76.5': 24,
    '77.5': 23.9,
    '78.5': 23.8,
    '79.5': 23.8,
    '80.5': 23.7,
    '81.5': 23.7,
    '82.5': 23.6,
    '83.5': 23.5,
    '84.5': 23.5,
    '85.5': 23.4,
    '86.5': 23.4,
    '87.5': 23.3,
    '88.5': 23.2,
    '89.5': 23.2,
    '90.5': 23.1,
    '91.5': 23,
    '92.5': 23,
    '93.5': 22.9,
    '94.5': 22.9,
    '95.5': 22.8,
    '96.5': 22.7,
    '97.5': 22.7,
    '98.5': 22.6,
    '99.5': 22.6,
    '100.5': 22.5,
    '101.5': 22.4,
    '102.5': 22.4,
    '103.5': 22.3,
    '104.5': 22.3,
    '105.5': 22.2,
    '106.5': 22.1,
    '107.5': 22.1,
    '108.5': 22,
    '109.5': 22,
    '110.5': 21.9,
    '111.5': 21.8,
    '112.5': 21.8,
    '113.5': 21.7,
    '114.5': 21.7,
    '115.5': 21.6,
    '116.5': 21.6,
    '117.5': 21.5,
    '118.5': 21.4,
    '119.5': 21.4,
    '120.5': 21.3,
    '121.5': 21.3,
    '122.5': 21.2,
    '123.5': 21.1,
    '124.5': 21.1,
    '125.5': 21,
    '126.5': 21,
    '127.5': 20.9,
    '128.5': 20.9,
    '129.5': 20.8,
    '130.5': 20.7,
    '131.5': 20.7,
    '132.5': 20.6,
    '133.5': 20.6,
    '134.5': 20.5,
    '135.5': 20.5,
    '136.5': 20.4,
    '137.5': 20.3,
    '138.5': 20.3,
    '139.5': 20.2,
    '140.5': 20.2,
    '141.5': 20.1,
    '142.5': 20.1,
    '143.5': 20,
    '144.5': 19.9,
    '145.5': 19.9,
    '146.5': 19.8,
    '147.5': 19.8,
    '148.5': 19.7,
    '149.5': 19.7,
    '150.5': 19.6,
    '151.5': 19.5,
    '152.5': 19.5,
    '153.5': 19.4,
    '154.5': 19.4,
    '155.5': 19.3,
    '156.5': 19.2,
    '157.5': 19.2,
    '158.5': 19.1,
    '159.5': 19.1,
    '160.5': 19.1,
    '161.5': 19,
    '162.5': 18.9,
    '163.5': 18.9,
    '164.5': 18.8,
    '165.5': 18.8,
    '166.5': 18.7,
    '167.5': 18.7,
    '168.5': 18.6,
    '169.5': 18.6,
    '170.5': 18.5,
    '171.5': 18.5,
    '172.5': 18.4,
    '173.5': 18.3,
    '174.5': 18.3,
    '175.5': 18.2,
    '176.5': 18.2,
    '177.5': 18.1,
    '178.5': 18.1,
    '179.5': 18,
    '180.5': 18,
    '181.5': 17.9,
    '182.5': 17.9,
    '183.5': 17.8,
    '184.5': 17.7,
    '185.5': 17.7,
    '186.5': 17.6,
    '187.5': 17.6,
    '188.5': 17.5,
    '189.5': 17.5,
    '190.5': 17.4,
    '191.5': 17.4,
    '192.5': 17.3,
    '193.5': 17.3,
    '194.5': 17.2,
    '195.5': 17.2,
    '196.5': 17.1,
    '197.5': 17.1,
    '198.5': 17,
    '199.5': 17,
    '200.5': 16.9,
    '201.5': 16.9,
    '202.5': 16.8,
    '203.5': 16.7,
    '204.5': 16.7,
    '205.5': 16.6,
    '206.5': 16.6,
    '207.5': 16.5,
    '208.5': 16.5,
    '209.5': 16.4,
  },
  // 25 - 30
  '25.5': {
    '0': 29.5,
    '1': 29.4,
    '2': 29.4,
    '3': 29.3,
    '4': 29.2,
    '5': 29.2,
    '6': 29.1,
    '7': 29,
    '8': 29,
    '9': 28.9,
    '10': 28.8,
    '11': 28.8,
    '12': 28.7,
    '13': 28.6,
    '14': 28.5,
    '15': 28.5,
    '16': 28.4,
    '17': 28.3,
    '18': 28.3,
    '19': 28.2,
    '20': 28.1,
    '21': 28.1,
    '22': 28,
    '23': 27.9,
    '24': 27.9,
    '25': 27.8,
    '26': 27.7,
    '27': 27.7,
    '28': 27.6,
    '29': 27.5,
    '30': 27.5,
    '31': 27.4,
    '32': 27.3,
    '33': 27.3,
    '34': 27.2,
    '35': 27.1,
    '36': 27.1,
    '37': 27,
    '38': 26.9,
    '39': 26.9,
    '40': 26.8,
    '41': 26.7,
    '42': 26.7,
    '43': 26.6,
    '44': 26.5,
    '45': 26.5,
    '46': 26.4,
    '47': 26.3,
    '48': 26.3,
    '49': 26.2,
    '50': 26.1,
    '51': 26.1,
    '52': 26,
    '53': 26,
    '54': 25.9,
    '55': 25.8,
    '56': 25.8,
    '57': 25.7,
    '58': 25.6,
    '59': 25.6,
    '60': 25.5,
    '61': 25.4,
    '62': 25.4,
    '63': 25.3,
    '64': 25.2,
    '65': 25.2,
    '66': 25.1,
    '67': 25.1,
    '68': 25,
    '69': 24.9,
    '70': 24.9,
    '71': 24.8,
    '72': 24.7,
    '73': 24.7,
    '74': 24.6,
    '75': 24.5,
    '76': 24.5,
    '77': 24.4,
    '78': 24.4,
    '79': 24.3,
    '80': 24.2,
    '81': 24.2,
    '82': 24.1,
    '83': 24.1,
    '84': 24,
    '85': 23.9,
    '86': 23.9,
    '87': 23.8,
    '88': 23.7,
    '89': 23.7,
    '90': 23.6,
    '91': 23.6,
    '92': 23.5,
    '93': 23.4,
    '94': 23.4,
    '95': 23.3,
    '96': 23.3,
    '97': 23.2,
    '98': 23.1,
    '99': 23.1,
    '100': 23,
    '101': 22.9,
    '102': 22.9,
    '103': 22.8,
    '104': 22.8,
    '105': 22.7,
    '106': 22.6,
    '107': 22.6,
    '108': 22.5,
    '109': 22.5,
    '110': 22.4,
    '111': 22.3,
    '112': 22.3,
    '113': 22.2,
    '114': 22.2,
    '115': 22.1,
    '116': 22.1,
    '117': 22,
    '118': 21.9,
    '119': 21.9,
    '120': 21.8,
    '121': 21.8,
    '122': 21.7,
    '123': 21.6,
    '124': 21.6,
    '125': 21.5,
    '126': 21.5,
    '127': 21.4,
    '128': 21.3,
    '129': 21.3,
    '130': 21.2,
    '131': 21.2,
    '132': 21.1,
    '133': 21.1,
    '134': 21,
    '135': 20.9,
    '136': 20.9,
    '137': 20.8,
    '138': 20.8,
    '139': 20.7,
    '140': 20.7,
    '141': 20.6,
    '142': 20.5,
    '143': 20.5,
    '144': 20.4,
    '145': 20.4,
    '146': 20.3,
    '147': 20.3,
    '148': 20.2,
    '149': 20.1,
    '150': 20.1,
    '151': 20,
    '152': 20,
    '153': 19.9,
    '154': 19.9,
    '155': 19.8,
    '156': 19.7,
    '157': 19.7,
    '158': 19.6,
    '159': 19.6,
    '160': 19.5,
    '161': 19.5,
    '162': 19.4,
    '163': 19.4,
    '164': 19.3,
    '165': 19.2,
    '166': 19.2,
    '167': 19.1,
    '168': 19.1,
    '169': 19,
    '170': 19,
    '171': 18.9,
    '172': 18.9,
    '173': 18.8,
    '174': 18.8,
    '175': 18.7,
    '176': 18.6,
    '177': 18.6,
    '178': 18.5,
    '179': 18.5,
    '180': 18.4,
    '181': 18.4,
    '182': 18.3,
    '183': 18.3,
    '184': 18.2,
    '185': 18.2,
    '186': 18.1,
    '187': 18,
    '188': 18,
    '189': 17.9,
    '190': 17.9,
    '191': 17.8,
    '192': 17.8,
    '193': 17.7,
    '194': 17.7,
    '195': 17.6,
    '196': 17.6,
    '197': 17.5,
    '198': 17.5,
    '199': 17.4,
    '200': 17.4,
    '201': 17.3,
    '202': 17.2,
    '203': 17.2,
    '204': 17.1,
    '205': 17.1,
    '206': 17,
    '207': 17,
    '208': 16.9,
    '209': 16.9,
    '210': 16.8,
    '0.5': 29.5,
    '1.5': 29.4,
    '2.5': 29.3,
    '3.5': 29.3,
    '4.5': 29.2,
    '5.5': 29.1,
    '6.5': 29.1,
    '7.5': 29,
    '8.5': 28.9,
    '9.5': 28.9,
    '10.5': 28.8,
    '11.5': 28.7,
    '12.5': 28.7,
    '13.5': 28.6,
    '14.5': 28.5,
    '15.5': 28.4,
    '16.5': 28.4,
    '17.5': 28.3,
    '18.5': 28.2,
    '19.5': 28.2,
    '20.5': 28.1,
    '21.5': 28,
    '22.5': 28,
    '23.5': 27.9,
    '24.5': 27.8,
    '25.5': 27.8,
    '26.5': 27.7,
    '27.5': 27.6,
    '28.5': 27.6,
    '29.5': 27.5,
    '30.5': 27.4,
    '31.5': 27.4,
    '32.5': 27.3,
    '33.5': 27.2,
    '34.5': 27.2,
    '35.5': 27.1,
    '36.5': 27,
    '37.5': 27,
    '38.5': 26.9,
    '39.5': 26.8,
    '40.5': 26.8,
    '41.5': 26.7,
    '42.5': 26.6,
    '43.5': 26.6,
    '44.5': 26.5,
    '45.5': 26.4,
    '46.5': 26.4,
    '47.5': 26.3,
    '48.5': 26.2,
    '49.5': 26.2,
    '50.5': 26.1,
    '51.5': 26,
    '52.5': 26,
    '53.5': 25.9,
    '54.5': 25.9,
    '55.5': 25.8,
    '56.5': 25.7,
    '57.5': 25.7,
    '58.5': 25.6,
    '59.5': 25.5,
    '60.5': 25.5,
    '61.5': 25.4,
    '62.5': 25.3,
    '63.5': 25.3,
    '64.5': 25.2,
    '65.5': 25.1,
    '66.5': 25.1,
    '67.5': 25,
    '68.5': 25,
    '69.5': 24.9,
    '70.5': 24.8,
    '71.5': 24.8,
    '72.5': 24.7,
    '73.5': 24.6,
    '74.5': 24.6,
    '75.5': 24.5,
    '76.5': 24.5,
    '77.5': 24.4,
    '78.5': 24.3,
    '79.5': 24.3,
    '80.5': 24.2,
    '81.5': 24.1,
    '82.5': 24.1,
    '83.5': 24,
    '84.5': 24,
    '85.5': 23.9,
    '86.5': 23.8,
    '87.5': 23.8,
    '88.5': 23.7,
    '89.5': 23.6,
    '90.5': 23.6,
    '91.5': 23.5,
    '92.5': 23.5,
    '93.5': 23.4,
    '94.5': 23.3,
    '95.5': 23.3,
    '96.5': 23.2,
    '97.5': 23.2,
    '98.5': 23.1,
    '99.5': 23,
    '100.5': 23,
    '101.5': 22.9,
    '102.5': 22.9,
    '103.5': 22.8,
    '104.5': 22.7,
    '105.5': 22.7,
    '106.5': 22.6,
    '107.5': 22.6,
    '108.5': 22.5,
    '109.5': 22.4,
    '110.5': 22.4,
    '111.5': 22.3,
    '112.5': 22.3,
    '113.5': 22.2,
    '114.5': 22.1,
    '115.5': 22.1,
    '116.5': 22,
    '117.5': 22,
    '118.5': 21.9,
    '119.5': 21.8,
    '120.5': 21.8,
    '121.5': 21.7,
    '122.5': 21.7,
    '123.5': 21.6,
    '124.5': 21.6,
    '125.5': 21.5,
    '126.5': 21.4,
    '127.5': 21.4,
    '128.5': 21.3,
    '129.5': 21.3,
    '130.5': 21.2,
    '131.5': 21.1,
    '132.5': 21.1,
    '133.5': 21,
    '134.5': 21,
    '135.5': 20.9,
    '136.5': 20.9,
    '137.5': 20.8,
    '138.5': 20.7,
    '139.5': 20.7,
    '140.5': 20.6,
    '141.5': 20.6,
    '142.5': 20.5,
    '143.5': 20.5,
    '144.5': 20.4,
    '145.5': 20.3,
    '146.5': 20.3,
    '147.5': 20.2,
    '148.5': 20.2,
    '149.5': 20.1,
    '150.5': 20.1,
    '151.5': 20,
    '152.5': 19.9,
    '153.5': 19.9,
    '154.5': 19.8,
    '155.5': 19.8,
    '156.5': 19.7,
    '157.5': 19.7,
    '158.5': 19.6,
    '159.5': 19.6,
    '160.5': 19.5,
    '161.5': 19.4,
    '162.5': 19.4,
    '163.5': 19.3,
    '164.5': 19.3,
    '165.5': 19.2,
    '166.5': 19.2,
    '167.5': 19.1,
    '168.5': 19.1,
    '169.5': 19,
    '170.5': 18.9,
    '171.5': 18.9,
    '172.5': 18.8,
    '173.5': 18.8,
    '174.5': 18.7,
    '175.5': 18.7,
    '176.5': 18.6,
    '177.5': 18.6,
    '178.5': 18.5,
    '179.5': 18.5,
    '180.5': 18.4,
    '181.5': 18.3,
    '182.5': 18.3,
    '183.5': 18.2,
    '184.5': 18.2,
    '185.5': 18.1,
    '186.5': 18.1,
    '187.5': 18,
    '188.5': 18,
    '189.5': 17.9,
    '190.5': 17.9,
    '191.5': 17.8,
    '192.5': 17.8,
    '193.5': 17.7,
    '194.5': 17.6,
    '195.5': 17.6,
    '196.5': 17.5,
    '197.5': 17.5,
    '198.5': 17.4,
    '199.5': 17.4,
    '200.5': 17.3,
    '201.5': 17.3,
    '202.5': 17.2,
    '203.5': 17.2,
    '204.5': 17.1,
    '205.5': 17.1,
    '206.5': 17,
    '207.5': 17,
    '208.5': 16.9,
    '209.5': 16.9,
  },
  '26': {
    '0': 30.1,
    '1': 30,
    '2': 29.9,
    '3': 29.8,
    '4': 29.8,
    '5': 29.7,
    '6': 29.6,
    '7': 29.6,
    '8': 29.5,
    '9': 29.4,
    '10': 29.4,
    '11': 29.3,
    '12': 29.2,
    '13': 29.1,
    '14': 29.1,
    '15': 29,
    '16': 28.9,
    '17': 28.9,
    '18': 28.8,
    '19': 28.7,
    '20': 28.7,
    '21': 28.6,
    '22': 28.5,
    '23': 28.5,
    '24': 28.4,
    '25': 28.3,
    '26': 28.3,
    '27': 28.2,
    '28': 28.1,
    '29': 28,
    '30': 28,
    '31': 27.9,
    '32': 27.8,
    '33': 27.8,
    '34': 27.7,
    '35': 27.6,
    '36': 27.6,
    '37': 27.5,
    '38': 27.4,
    '39': 27.4,
    '40': 27.3,
    '41': 27.2,
    '42': 27.2,
    '43': 27.1,
    '44': 27,
    '45': 27,
    '46': 26.9,
    '47': 26.8,
    '48': 26.8,
    '49': 26.7,
    '50': 26.7,
    '51': 26.6,
    '52': 26.5,
    '53': 26.5,
    '54': 26.4,
    '55': 26.3,
    '56': 26.3,
    '57': 26.2,
    '58': 26.1,
    '59': 26.1,
    '60': 26,
    '61': 25.9,
    '62': 25.9,
    '63': 25.8,
    '64': 25.7,
    '65': 25.7,
    '66': 25.6,
    '67': 25.5,
    '68': 25.5,
    '69': 25.4,
    '70': 25.4,
    '71': 25.3,
    '72': 25.2,
    '73': 25.2,
    '74': 25.1,
    '75': 25,
    '76': 25,
    '77': 24.9,
    '78': 24.8,
    '79': 24.8,
    '80': 24.7,
    '81': 24.7,
    '82': 24.6,
    '83': 24.5,
    '84': 24.5,
    '85': 24.4,
    '86': 24.3,
    '87': 24.3,
    '88': 24.2,
    '89': 24.2,
    '90': 24.1,
    '91': 24,
    '92': 24,
    '93': 23.9,
    '94': 23.9,
    '95': 23.8,
    '96': 23.7,
    '97': 23.7,
    '98': 23.6,
    '99': 23.5,
    '100': 23.5,
    '101': 23.4,
    '102': 23.4,
    '103': 23.3,
    '104': 23.2,
    '105': 23.2,
    '106': 23.1,
    '107': 23.1,
    '108': 23,
    '109': 22.9,
    '110': 22.9,
    '111': 22.8,
    '112': 22.8,
    '113': 22.7,
    '114': 22.6,
    '115': 22.6,
    '116': 22.5,
    '117': 22.5,
    '118': 22.4,
    '119': 22.3,
    '120': 22.3,
    '121': 22.2,
    '122': 22.2,
    '123': 22.1,
    '124': 22,
    '125': 22,
    '126': 21.9,
    '127': 21.9,
    '128': 21.8,
    '129': 21.7,
    '130': 21.7,
    '131': 21.6,
    '132': 21.6,
    '133': 21.5,
    '134': 21.5,
    '135': 21.4,
    '136': 21.3,
    '137': 21.3,
    '138': 21.2,
    '139': 21.2,
    '140': 21.1,
    '141': 21.1,
    '142': 21,
    '143': 20.9,
    '144': 20.9,
    '145': 20.8,
    '146': 20.8,
    '147': 20.7,
    '148': 20.6,
    '149': 20.6,
    '150': 20.5,
    '151': 20.5,
    '152': 20.4,
    '153': 20.4,
    '154': 20.3,
    '155': 20.2,
    '156': 20.2,
    '157': 20.1,
    '158': 20.1,
    '159': 20,
    '160': 20,
    '161': 19.9,
    '162': 19.9,
    '163': 19.8,
    '164': 19.7,
    '165': 19.7,
    '166': 19.6,
    '167': 19.6,
    '168': 19.5,
    '169': 19.5,
    '170': 19.4,
    '171': 19.4,
    '172': 19.3,
    '173': 19.2,
    '174': 19.2,
    '175': 19.1,
    '176': 19.1,
    '177': 19,
    '178': 19,
    '179': 18.9,
    '180': 18.9,
    '181': 18.8,
    '182': 18.7,
    '183': 18.7,
    '184': 18.6,
    '185': 18.6,
    '186': 18.5,
    '187': 18.5,
    '188': 18.4,
    '189': 18.4,
    '190': 18.3,
    '191': 18.3,
    '192': 18.2,
    '193': 18.2,
    '194': 18.1,
    '195': 18,
    '196': 18,
    '197': 17.9,
    '198': 17.9,
    '199': 17.8,
    '200': 17.8,
    '201': 17.7,
    '202': 17.7,
    '203': 17.6,
    '204': 17.6,
    '205': 17.5,
    '206': 17.5,
    '207': 17.4,
    '208': 17.4,
    '209': 17.3,
    '210': 17.2,
    '0.5': 30,
    '1.5': 29.9,
    '2.5': 29.9,
    '3.5': 29.8,
    '4.5': 29.7,
    '5.5': 29.7,
    '6.5': 29.6,
    '7.5': 29.5,
    '8.5': 29.5,
    '9.5': 29.4,
    '10.5': 29.3,
    '11.5': 29.2,
    '12.5': 29.2,
    '13.5': 29.1,
    '14.5': 29,
    '15.5': 29,
    '16.5': 28.9,
    '17.5': 28.8,
    '18.5': 28.8,
    '19.5': 28.7,
    '20.5': 28.6,
    '21.5': 28.6,
    '22.5': 28.5,
    '23.5': 28.4,
    '24.5': 28.4,
    '25.5': 28.3,
    '26.5': 28.2,
    '27.5': 28.2,
    '28.5': 28.1,
    '29.5': 28,
    '30.5': 27.9,
    '31.5': 27.9,
    '32.5': 27.8,
    '33.5': 27.7,
    '34.5': 27.7,
    '35.5': 27.6,
    '36.5': 27.5,
    '37.5': 27.5,
    '38.5': 27.4,
    '39.5': 27.3,
    '40.5': 27.3,
    '41.5': 27.2,
    '42.5': 27.1,
    '43.5': 27.1,
    '44.5': 27,
    '45.5': 26.9,
    '46.5': 26.9,
    '47.5': 26.8,
    '48.5': 26.7,
    '49.5': 26.7,
    '50.5': 26.6,
    '51.5': 26.6,
    '52.5': 26.5,
    '53.5': 26.4,
    '54.5': 26.4,
    '55.5': 26.3,
    '56.5': 26.2,
    '57.5': 26.2,
    '58.5': 26.1,
    '59.5': 26,
    '60.5': 26,
    '61.5': 25.9,
    '62.5': 25.8,
    '63.5': 25.8,
    '64.5': 25.7,
    '65.5': 25.6,
    '66.5': 25.6,
    '67.5': 25.5,
    '68.5': 25.5,
    '69.5': 25.4,
    '70.5': 25.3,
    '71.5': 25.3,
    '72.5': 25.2,
    '73.5': 25.1,
    '74.5': 25.1,
    '75.5': 25,
    '76.5': 24.9,
    '77.5': 24.9,
    '78.5': 24.8,
    '79.5': 24.8,
    '80.5': 24.7,
    '81.5': 24.6,
    '82.5': 24.6,
    '83.5': 24.5,
    '84.5': 24.4,
    '85.5': 24.4,
    '86.5': 24.3,
    '87.5': 24.3,
    '88.5': 24.2,
    '89.5': 24.1,
    '90.5': 24.1,
    '91.5': 24,
    '92.5': 23.9,
    '93.5': 23.9,
    '94.5': 23.8,
    '95.5': 23.8,
    '96.5': 23.7,
    '97.5': 23.6,
    '98.5': 23.6,
    '99.5': 23.5,
    '100.5': 23.5,
    '101.5': 23.4,
    '102.5': 23.3,
    '103.5': 23.3,
    '104.5': 23.2,
    '105.5': 23.1,
    '106.5': 23.1,
    '107.5': 23,
    '108.5': 23,
    '109.5': 22.9,
    '110.5': 22.8,
    '111.5': 22.8,
    '112.5': 22.7,
    '113.5': 22.7,
    '114.5': 22.6,
    '115.5': 22.5,
    '116.5': 22.5,
    '117.5': 22.4,
    '118.5': 22.4,
    '119.5': 22.3,
    '120.5': 22.2,
    '121.5': 22.2,
    '122.5': 22.1,
    '123.5': 22.1,
    '124.5': 22,
    '125.5': 22,
    '126.5': 21.9,
    '127.5': 21.8,
    '128.5': 21.8,
    '129.5': 21.7,
    '130.5': 21.7,
    '131.5': 21.6,
    '132.5': 21.5,
    '133.5': 21.5,
    '134.5': 21.4,
    '135.5': 21.4,
    '136.5': 21.3,
    '137.5': 21.3,
    '138.5': 21.2,
    '139.5': 21.1,
    '140.5': 21.1,
    '141.5': 21,
    '142.5': 21,
    '143.5': 20.9,
    '144.5': 20.8,
    '145.5': 20.8,
    '146.5': 20.7,
    '147.5': 20.7,
    '148.5': 20.6,
    '149.5': 20.6,
    '150.5': 20.5,
    '151.5': 20.4,
    '152.5': 20.4,
    '153.5': 20.3,
    '154.5': 20.3,
    '155.5': 20.2,
    '156.5': 20.2,
    '157.5': 20.1,
    '158.5': 20.1,
    '159.5': 20,
    '160.5': 19.9,
    '161.5': 19.9,
    '162.5': 19.8,
    '163.5': 19.8,
    '164.5': 19.7,
    '165.5': 19.7,
    '166.5': 19.6,
    '167.5': 19.6,
    '168.5': 19.5,
    '169.5': 19.4,
    '170.5': 19.4,
    '171.5': 19.3,
    '172.5': 19.3,
    '173.5': 19.2,
    '174.5': 19.2,
    '175.5': 19.1,
    '176.5': 19,
    '177.5': 19,
    '178.5': 18.9,
    '179.5': 18.9,
    '180.5': 18.8,
    '181.5': 18.8,
    '182.5': 18.7,
    '183.5': 18.7,
    '184.5': 18.6,
    '185.5': 18.6,
    '186.5': 18.5,
    '187.5': 18.4,
    '188.5': 18.4,
    '189.5': 18.3,
    '190.5': 18.3,
    '191.5': 18.2,
    '192.5': 18.2,
    '193.5': 18.1,
    '194.5': 18.1,
    '195.5': 18,
    '196.5': 18,
    '197.5': 17.9,
    '198.5': 17.9,
    '199.5': 17.8,
    '200.5': 17.8,
    '201.5': 17.7,
    '202.5': 17.6,
    '203.5': 17.6,
    '204.5': 17.5,
    '205.5': 17.5,
    '206.5': 17.4,
    '207.5': 17.4,
    '208.5': 17.3,
    '209.5': 17.3,
  },
  '26.5': {
    '0': 30.6,
    '1': 30.5,
    '2': 30.5,
    '3': 30.4,
    '4': 30.3,
    '5': 30.2,
    '6': 30.2,
    '7': 30.1,
    '8': 30,
    '9': 30,
    '10': 29.9,
    '11': 29.8,
    '12': 29.7,
    '13': 29.7,
    '14': 29.6,
    '15': 29.5,
    '16': 29.5,
    '17': 29.4,
    '18': 29.3,
    '19': 29.3,
    '20': 29.2,
    '21': 29.1,
    '22': 29.1,
    '23': 29,
    '24': 28.9,
    '25': 28.8,
    '26': 28.8,
    '27': 28.7,
    '28': 28.6,
    '29': 28.6,
    '30': 28.5,
    '31': 28.4,
    '32': 28.4,
    '33': 28.3,
    '34': 28.2,
    '35': 28.2,
    '36': 28.1,
    '37': 28,
    '38': 28,
    '39': 27.9,
    '40': 27.8,
    '41': 27.8,
    '42': 27.7,
    '43': 27.6,
    '44': 27.6,
    '45': 27.5,
    '46': 27.4,
    '47': 27.4,
    '48': 27.3,
    '49': 27.2,
    '50': 27.2,
    '51': 27.1,
    '52': 27,
    '53': 27,
    '54': 26.9,
    '55': 26.8,
    '56': 26.8,
    '57': 26.7,
    '58': 26.6,
    '59': 26.6,
    '60': 26.5,
    '61': 26.4,
    '62': 26.4,
    '63': 26.3,
    '64': 26.2,
    '65': 26.2,
    '66': 26.1,
    '67': 26,
    '68': 26,
    '69': 25.9,
    '70': 25.9,
    '71': 25.8,
    '72': 25.7,
    '73': 25.7,
    '74': 25.6,
    '75': 25.5,
    '76': 25.5,
    '77': 25.4,
    '78': 25.3,
    '79': 25.3,
    '80': 25.2,
    '81': 25.1,
    '82': 25.1,
    '83': 25,
    '84': 25,
    '85': 24.9,
    '86': 24.8,
    '87': 24.8,
    '88': 24.7,
    '89': 24.6,
    '90': 24.6,
    '91': 24.5,
    '92': 24.5,
    '93': 24.4,
    '94': 24.3,
    '95': 24.3,
    '96': 24.2,
    '97': 24.1,
    '98': 24.1,
    '99': 24,
    '100': 24,
    '101': 23.9,
    '102': 23.8,
    '103': 23.8,
    '104': 23.7,
    '105': 23.7,
    '106': 23.6,
    '107': 23.5,
    '108': 23.5,
    '109': 23.4,
    '110': 23.4,
    '111': 23.3,
    '112': 23.2,
    '113': 23.2,
    '114': 23.1,
    '115': 23,
    '116': 23,
    '117': 22.9,
    '118': 22.9,
    '119': 22.8,
    '120': 22.7,
    '121': 22.7,
    '122': 22.6,
    '123': 22.6,
    '124': 22.5,
    '125': 22.4,
    '126': 22.4,
    '127': 22.3,
    '128': 22.3,
    '129': 22.2,
    '130': 22.2,
    '131': 22.1,
    '132': 22,
    '133': 22,
    '134': 21.9,
    '135': 21.9,
    '136': 21.8,
    '137': 21.7,
    '138': 21.7,
    '139': 21.6,
    '140': 21.6,
    '141': 21.5,
    '142': 21.4,
    '143': 21.4,
    '144': 21.3,
    '145': 21.3,
    '146': 21.2,
    '147': 21.2,
    '148': 21.1,
    '149': 21,
    '150': 21,
    '151': 20.9,
    '152': 20.9,
    '153': 20.8,
    '154': 20.8,
    '155': 20.7,
    '156': 20.6,
    '157': 20.6,
    '158': 20.5,
    '159': 20.5,
    '160': 20.4,
    '161': 20.4,
    '162': 20.3,
    '163': 20.2,
    '164': 20.2,
    '165': 20.1,
    '166': 20.1,
    '167': 20,
    '168': 20,
    '169': 19.9,
    '170': 19.8,
    '171': 19.8,
    '172': 19.7,
    '173': 19.7,
    '174': 19.6,
    '175': 19.6,
    '176': 19.5,
    '177': 19.5,
    '178': 19.4,
    '179': 19.4,
    '180': 19.3,
    '181': 19.2,
    '182': 19.2,
    '183': 19.1,
    '184': 19.1,
    '185': 19,
    '186': 19,
    '187': 18.9,
    '188': 18.9,
    '189': 18.8,
    '190': 18.7,
    '191': 18.7,
    '192': 18.6,
    '193': 18.6,
    '194': 18.5,
    '195': 18.5,
    '196': 18.4,
    '197': 18.4,
    '198': 18.3,
    '199': 18.3,
    '200': 18.2,
    '201': 18.1,
    '202': 18.1,
    '203': 18,
    '204': 18,
    '205': 17.9,
    '206': 17.9,
    '207': 17.8,
    '208': 17.8,
    '209': 17.7,
    '210': 17.7,
    '0.5': 30.6,
    '1.5': 30.5,
    '2.5': 30.4,
    '3.5': 30.3,
    '4.5': 30.3,
    '5.5': 30.2,
    '6.5': 30.1,
    '7.5': 30.1,
    '8.5': 30,
    '9.5': 29.9,
    '10.5': 29.9,
    '11.5': 29.8,
    '12.5': 29.7,
    '13.5': 29.6,
    '14.5': 29.6,
    '15.5': 29.5,
    '16.5': 29.4,
    '17.5': 29.4,
    '18.5': 29.3,
    '19.5': 29.2,
    '20.5': 29.2,
    '21.5': 29.1,
    '22.5': 29,
    '23.5': 28.9,
    '24.5': 28.9,
    '25.5': 28.8,
    '26.5': 28.7,
    '27.5': 28.7,
    '28.5': 28.6,
    '29.5': 28.5,
    '30.5': 28.5,
    '31.5': 28.4,
    '32.5': 28.3,
    '33.5': 28.3,
    '34.5': 28.2,
    '35.5': 28.1,
    '36.5': 28.1,
    '37.5': 28,
    '38.5': 27.9,
    '39.5': 27.9,
    '40.5': 27.8,
    '41.5': 27.7,
    '42.5': 27.7,
    '43.5': 27.6,
    '44.5': 27.5,
    '45.5': 27.5,
    '46.5': 27.4,
    '47.5': 27.3,
    '48.5': 27.3,
    '49.5': 27.2,
    '50.5': 27.1,
    '51.5': 27.1,
    '52.5': 27,
    '53.5': 26.9,
    '54.5': 26.9,
    '55.5': 26.8,
    '56.5': 26.7,
    '57.5': 26.7,
    '58.5': 26.6,
    '59.5': 26.5,
    '60.5': 26.5,
    '61.5': 26.4,
    '62.5': 26.3,
    '63.5': 26.3,
    '64.5': 26.2,
    '65.5': 26.1,
    '66.5': 26.1,
    '67.5': 26,
    '68.5': 26,
    '69.5': 25.9,
    '70.5': 25.8,
    '71.5': 25.8,
    '72.5': 25.7,
    '73.5': 25.6,
    '74.5': 25.6,
    '75.5': 25.5,
    '76.5': 25.4,
    '77.5': 25.4,
    '78.5': 25.3,
    '79.5': 25.2,
    '80.5': 25.2,
    '81.5': 25.1,
    '82.5': 25.1,
    '83.5': 25,
    '84.5': 24.9,
    '85.5': 24.9,
    '86.5': 24.8,
    '87.5': 24.7,
    '88.5': 24.7,
    '89.5': 24.6,
    '90.5': 24.6,
    '91.5': 24.5,
    '92.5': 24.4,
    '93.5': 24.4,
    '94.5': 24.3,
    '95.5': 24.2,
    '96.5': 24.2,
    '97.5': 24.1,
    '98.5': 24.1,
    '99.5': 24,
    '100.5': 23.9,
    '101.5': 23.9,
    '102.5': 23.8,
    '103.5': 23.7,
    '104.5': 23.7,
    '105.5': 23.6,
    '106.5': 23.6,
    '107.5': 23.5,
    '108.5': 23.4,
    '109.5': 23.4,
    '110.5': 23.3,
    '111.5': 23.3,
    '112.5': 23.2,
    '113.5': 23.1,
    '114.5': 23.1,
    '115.5': 23,
    '116.5': 23,
    '117.5': 22.9,
    '118.5': 22.8,
    '119.5': 22.8,
    '120.5': 22.7,
    '121.5': 22.7,
    '122.5': 22.6,
    '123.5': 22.5,
    '124.5': 22.5,
    '125.5': 22.4,
    '126.5': 22.4,
    '127.5': 22.3,
    '128.5': 22.2,
    '129.5': 22.2,
    '130.5': 22.1,
    '131.5': 22.1,
    '132.5': 22,
    '133.5': 21.9,
    '134.5': 21.9,
    '135.5': 21.8,
    '136.5': 21.8,
    '137.5': 21.7,
    '138.5': 21.6,
    '139.5': 21.6,
    '140.5': 21.5,
    '141.5': 21.5,
    '142.5': 21.4,
    '143.5': 21.4,
    '144.5': 21.3,
    '145.5': 21.2,
    '146.5': 21.2,
    '147.5': 21.1,
    '148.5': 21.1,
    '149.5': 21,
    '150.5': 21,
    '151.5': 20.9,
    '152.5': 20.8,
    '153.5': 20.8,
    '154.5': 20.7,
    '155.5': 20.7,
    '156.5': 20.6,
    '157.5': 20.6,
    '158.5': 20.5,
    '159.5': 20.4,
    '160.5': 20.4,
    '161.5': 20.3,
    '162.5': 20.3,
    '163.5': 20.2,
    '164.5': 20.2,
    '165.5': 20.1,
    '166.5': 20,
    '167.5': 20,
    '168.5': 19.9,
    '169.5': 19.9,
    '170.5': 19.8,
    '171.5': 19.8,
    '172.5': 19.7,
    '173.5': 19.7,
    '174.5': 19.6,
    '175.5': 19.5,
    '176.5': 19.5,
    '177.5': 19.4,
    '178.5': 19.4,
    '179.5': 19.3,
    '180.5': 19.3,
    '181.5': 19.2,
    '182.5': 19.2,
    '183.5': 19.1,
    '184.5': 19,
    '185.5': 19,
    '186.5': 18.9,
    '187.5': 18.9,
    '188.5': 18.8,
    '189.5': 18.8,
    '190.5': 18.7,
    '191.5': 18.7,
    '192.5': 18.6,
    '193.5': 18.6,
    '194.5': 18.5,
    '195.5': 18.4,
    '196.5': 18.4,
    '197.5': 18.3,
    '198.5': 18.3,
    '199.5': 18.2,
    '200.5': 18.2,
    '201.5': 18.1,
    '202.5': 18.1,
    '203.5': 18,
    '204.5': 18,
    '205.5': 17.9,
    '206.5': 17.9,
    '207.5': 17.8,
    '208.5': 17.8,
    '209.5': 17.7,
  },
  '27': {
    '0': 31.1,
    '1': 31.1,
    '2': 31,
    '3': 30.9,
    '4': 30.8,
    '5': 30.8,
    '6': 30.7,
    '7': 30.6,
    '8': 30.6,
    '9': 30.5,
    '10': 30.4,
    '11': 30.3,
    '12': 30.3,
    '13': 30.2,
    '14': 30.1,
    '15': 30.1,
    '16': 30,
    '17': 29.9,
    '18': 29.9,
    '19': 29.8,
    '20': 29.7,
    '21': 29.6,
    '22': 29.6,
    '23': 29.5,
    '24': 29.4,
    '25': 29.4,
    '26': 29.3,
    '27': 29.2,
    '28': 29.2,
    '29': 29.1,
    '30': 29,
    '31': 29,
    '32': 28.9,
    '33': 28.8,
    '34': 28.7,
    '35': 28.7,
    '36': 28.6,
    '37': 28.5,
    '38': 28.5,
    '39': 28.4,
    '40': 28.3,
    '41': 28.3,
    '42': 28.2,
    '43': 28.1,
    '44': 28.1,
    '45': 28,
    '46': 27.9,
    '47': 27.9,
    '48': 27.8,
    '49': 27.7,
    '50': 27.7,
    '51': 27.6,
    '52': 27.5,
    '53': 27.5,
    '54': 27.4,
    '55': 27.3,
    '56': 27.3,
    '57': 27.2,
    '58': 27.1,
    '59': 27.1,
    '60': 27,
    '61': 26.9,
    '62': 26.9,
    '63': 26.8,
    '64': 26.7,
    '65': 26.7,
    '66': 26.6,
    '67': 26.5,
    '68': 26.5,
    '69': 26.4,
    '70': 26.3,
    '71': 26.3,
    '72': 26.2,
    '73': 26.2,
    '74': 26.1,
    '75': 26,
    '76': 26,
    '77': 25.9,
    '78': 25.8,
    '79': 25.8,
    '80': 25.7,
    '81': 25.6,
    '82': 25.6,
    '83': 25.5,
    '84': 25.4,
    '85': 25.4,
    '86': 25.3,
    '87': 25.3,
    '88': 25.2,
    '89': 25.1,
    '90': 25.1,
    '91': 25,
    '92': 24.9,
    '93': 24.9,
    '94': 24.8,
    '95': 24.8,
    '96': 24.7,
    '97': 24.6,
    '98': 24.6,
    '99': 24.5,
    '100': 24.4,
    '101': 24.4,
    '102': 24.3,
    '103': 24.3,
    '104': 24.2,
    '105': 24.1,
    '106': 24.1,
    '107': 24,
    '108': 23.9,
    '109': 23.9,
    '110': 23.8,
    '111': 23.8,
    '112': 23.7,
    '113': 23.6,
    '114': 23.6,
    '115': 23.5,
    '116': 23.5,
    '117': 23.4,
    '118': 23.3,
    '119': 23.3,
    '120': 23.2,
    '121': 23.2,
    '122': 23.1,
    '123': 23,
    '124': 23,
    '125': 22.9,
    '126': 22.8,
    '127': 22.8,
    '128': 22.7,
    '129': 22.7,
    '130': 22.6,
    '131': 22.5,
    '132': 22.5,
    '133': 22.4,
    '134': 22.4,
    '135': 22.3,
    '136': 22.3,
    '137': 22.2,
    '138': 22.1,
    '139': 22.1,
    '140': 22,
    '141': 22,
    '142': 21.9,
    '143': 21.8,
    '144': 21.8,
    '145': 21.7,
    '146': 21.7,
    '147': 21.6,
    '148': 21.6,
    '149': 21.5,
    '150': 21.4,
    '151': 21.4,
    '152': 21.3,
    '153': 21.3,
    '154': 21.2,
    '155': 21.1,
    '156': 21.1,
    '157': 21,
    '158': 21,
    '159': 20.9,
    '160': 20.9,
    '161': 20.8,
    '162': 20.7,
    '163': 20.7,
    '164': 20.6,
    '165': 20.6,
    '166': 20.5,
    '167': 20.5,
    '168': 20.4,
    '169': 20.3,
    '170': 20.3,
    '171': 20.2,
    '172': 20.2,
    '173': 20.1,
    '174': 20.1,
    '175': 20,
    '176': 20,
    '177': 19.9,
    '178': 19.8,
    '179': 19.8,
    '180': 19.7,
    '181': 19.7,
    '182': 19.6,
    '183': 19.6,
    '184': 19.5,
    '185': 19.5,
    '186': 19.4,
    '187': 19.3,
    '188': 19.3,
    '189': 19.2,
    '190': 19.2,
    '191': 19.1,
    '192': 19.1,
    '193': 19,
    '194': 19,
    '195': 18.9,
    '196': 18.8,
    '197': 18.8,
    '198': 18.7,
    '199': 18.7,
    '200': 18.6,
    '201': 18.6,
    '202': 18.5,
    '203': 18.5,
    '204': 18.4,
    '205': 18.4,
    '206': 18.3,
    '207': 18.2,
    '208': 18.2,
    '209': 18.1,
    '210': 18.1,
    '0.5': 31.1,
    '1.5': 31,
    '2.5': 31,
    '3.5': 30.9,
    '4.5': 30.8,
    '5.5': 30.7,
    '6.5': 30.7,
    '7.5': 30.6,
    '8.5': 30.5,
    '9.5': 30.5,
    '10.5': 30.4,
    '11.5': 30.3,
    '12.5': 30.2,
    '13.5': 30.2,
    '14.5': 30.1,
    '15.5': 30,
    '16.5': 30,
    '17.5': 29.9,
    '18.5': 29.8,
    '19.5': 29.8,
    '20.5': 29.7,
    '21.5': 29.6,
    '22.5': 29.5,
    '23.5': 29.5,
    '24.5': 29.4,
    '25.5': 29.3,
    '26.5': 29.3,
    '27.5': 29.2,
    '28.5': 29.1,
    '29.5': 29.1,
    '30.5': 29,
    '31.5': 28.9,
    '32.5': 28.9,
    '33.5': 28.8,
    '34.5': 28.7,
    '35.5': 28.6,
    '36.5': 28.6,
    '37.5': 28.5,
    '38.5': 28.4,
    '39.5': 28.4,
    '40.5': 28.3,
    '41.5': 28.2,
    '42.5': 28.2,
    '43.5': 28.1,
    '44.5': 28,
    '45.5': 28,
    '46.5': 27.9,
    '47.5': 27.8,
    '48.5': 27.8,
    '49.5': 27.7,
    '50.5': 27.6,
    '51.5': 27.6,
    '52.5': 27.5,
    '53.5': 27.4,
    '54.5': 27.4,
    '55.5': 27.3,
    '56.5': 27.2,
    '57.5': 27.2,
    '58.5': 27.1,
    '59.5': 27,
    '60.5': 27,
    '61.5': 26.9,
    '62.5': 26.8,
    '63.5': 26.8,
    '64.5': 26.7,
    '65.5': 26.6,
    '66.5': 26.6,
    '67.5': 26.5,
    '68.5': 26.4,
    '69.5': 26.4,
    '70.5': 26.3,
    '71.5': 26.2,
    '72.5': 26.2,
    '73.5': 26.1,
    '74.5': 26.1,
    '75.5': 26,
    '76.5': 25.9,
    '77.5': 25.9,
    '78.5': 25.8,
    '79.5': 25.7,
    '80.5': 25.7,
    '81.5': 25.6,
    '82.5': 25.5,
    '83.5': 25.5,
    '84.5': 25.4,
    '85.5': 25.3,
    '86.5': 25.3,
    '87.5': 25.2,
    '88.5': 25.2,
    '89.5': 25.1,
    '90.5': 25,
    '91.5': 25,
    '92.5': 24.9,
    '93.5': 24.8,
    '94.5': 24.8,
    '95.5': 24.7,
    '96.5': 24.7,
    '97.5': 24.6,
    '98.5': 24.5,
    '99.5': 24.5,
    '100.5': 24.4,
    '101.5': 24.3,
    '102.5': 24.3,
    '103.5': 24.2,
    '104.5': 24.2,
    '105.5': 24.1,
    '106.5': 24,
    '107.5': 24,
    '108.5': 23.9,
    '109.5': 23.8,
    '110.5': 23.8,
    '111.5': 23.7,
    '112.5': 23.7,
    '113.5': 23.6,
    '114.5': 23.5,
    '115.5': 23.5,
    '116.5': 23.4,
    '117.5': 23.4,
    '118.5': 23.3,
    '119.5': 23.2,
    '120.5': 23.2,
    '121.5': 23.1,
    '122.5': 23.1,
    '123.5': 23,
    '124.5': 22.9,
    '125.5': 22.9,
    '126.5': 22.8,
    '127.5': 22.8,
    '128.5': 22.7,
    '129.5': 22.6,
    '130.5': 22.6,
    '131.5': 22.5,
    '132.5': 22.5,
    '133.5': 22.4,
    '134.5': 22.3,
    '135.5': 22.3,
    '136.5': 22.2,
    '137.5': 22.2,
    '138.5': 22.1,
    '139.5': 22,
    '140.5': 22,
    '141.5': 21.9,
    '142.5': 21.9,
    '143.5': 21.8,
    '144.5': 21.8,
    '145.5': 21.7,
    '146.5': 21.6,
    '147.5': 21.6,
    '148.5': 21.5,
    '149.5': 21.5,
    '150.5': 21.4,
    '151.5': 21.3,
    '152.5': 21.3,
    '153.5': 21.2,
    '154.5': 21.2,
    '155.5': 21.1,
    '156.5': 21.1,
    '157.5': 21,
    '158.5': 20.9,
    '159.5': 20.9,
    '160.5': 20.8,
    '161.5': 20.8,
    '162.5': 20.7,
    '163.5': 20.7,
    '164.5': 20.6,
    '165.5': 20.5,
    '166.5': 20.5,
    '167.5': 20.4,
    '168.5': 20.4,
    '169.5': 20.3,
    '170.5': 20.3,
    '171.5': 20.2,
    '172.5': 20.1,
    '173.5': 20.1,
    '174.5': 20,
    '175.5': 20,
    '176.5': 19.9,
    '177.5': 19.9,
    '178.5': 19.8,
    '179.5': 19.8,
    '180.5': 19.7,
    '181.5': 19.6,
    '182.5': 19.6,
    '183.5': 19.5,
    '184.5': 19.5,
    '185.5': 19.4,
    '186.5': 19.4,
    '187.5': 19.3,
    '188.5': 19.3,
    '189.5': 19.2,
    '190.5': 19.1,
    '191.5': 19.1,
    '192.5': 19,
    '193.5': 19,
    '194.5': 18.9,
    '195.5': 18.9,
    '196.5': 18.8,
    '197.5': 18.8,
    '198.5': 18.7,
    '199.5': 18.7,
    '200.5': 18.6,
    '201.5': 18.5,
    '202.5': 18.5,
    '203.5': 18.4,
    '204.5': 18.4,
    '205.5': 18.3,
    '206.5': 18.3,
    '207.5': 18.2,
    '208.5': 18.2,
    '209.5': 18.1,
  },
  '27.5': {
    '0': 31.7,
    '1': 31.6,
    '2': 31.5,
    '3': 31.5,
    '4': 31.4,
    '5': 31.3,
    '6': 31.2,
    '7': 31.2,
    '8': 31.1,
    '9': 31,
    '10': 31,
    '11': 30.9,
    '12': 30.8,
    '13': 30.7,
    '14': 30.7,
    '15': 30.6,
    '16': 30.5,
    '17': 30.5,
    '18': 30.4,
    '19': 30.3,
    '20': 30.2,
    '21': 30.2,
    '22': 30.1,
    '23': 30,
    '24': 30,
    '25': 29.9,
    '26': 29.8,
    '27': 29.8,
    '28': 29.7,
    '29': 29.6,
    '30': 29.5,
    '31': 29.5,
    '32': 29.4,
    '33': 29.3,
    '34': 29.3,
    '35': 29.2,
    '36': 29.1,
    '37': 29.1,
    '38': 29,
    '39': 28.9,
    '40': 28.9,
    '41': 28.8,
    '42': 28.7,
    '43': 28.6,
    '44': 28.6,
    '45': 28.5,
    '46': 28.4,
    '47': 28.4,
    '48': 28.3,
    '49': 28.2,
    '50': 28.2,
    '51': 28.1,
    '52': 28,
    '53': 28,
    '54': 27.9,
    '55': 27.8,
    '56': 27.8,
    '57': 27.7,
    '58': 27.6,
    '59': 27.6,
    '60': 27.5,
    '61': 27.4,
    '62': 27.4,
    '63': 27.3,
    '64': 27.2,
    '65': 27.2,
    '66': 27.1,
    '67': 27,
    '68': 27,
    '69': 26.9,
    '70': 26.8,
    '71': 26.8,
    '72': 26.7,
    '73': 26.6,
    '74': 26.6,
    '75': 26.5,
    '76': 26.4,
    '77': 26.4,
    '78': 26.3,
    '79': 26.3,
    '80': 26.2,
    '81': 26.1,
    '82': 26.1,
    '83': 26,
    '84': 25.9,
    '85': 25.9,
    '86': 25.8,
    '87': 25.7,
    '88': 25.7,
    '89': 25.6,
    '90': 25.5,
    '91': 25.5,
    '92': 25.4,
    '93': 25.4,
    '94': 25.3,
    '95': 25.2,
    '96': 25.2,
    '97': 25.1,
    '98': 25,
    '99': 25,
    '100': 24.9,
    '101': 24.8,
    '102': 24.8,
    '103': 24.7,
    '104': 24.7,
    '105': 24.6,
    '106': 24.5,
    '107': 24.5,
    '108': 24.4,
    '109': 24.4,
    '110': 24.3,
    '111': 24.2,
    '112': 24.2,
    '113': 24.1,
    '114': 24,
    '115': 24,
    '116': 23.9,
    '117': 23.9,
    '118': 23.8,
    '119': 23.7,
    '120': 23.7,
    '121': 23.6,
    '122': 23.6,
    '123': 23.5,
    '124': 23.4,
    '125': 23.4,
    '126': 23.3,
    '127': 23.2,
    '128': 23.2,
    '129': 23.1,
    '130': 23.1,
    '131': 23,
    '132': 22.9,
    '133': 22.9,
    '134': 22.8,
    '135': 22.8,
    '136': 22.7,
    '137': 22.6,
    '138': 22.6,
    '139': 22.5,
    '140': 22.5,
    '141': 22.4,
    '142': 22.4,
    '143': 22.3,
    '144': 22.2,
    '145': 22.2,
    '146': 22.1,
    '147': 22.1,
    '148': 22,
    '149': 21.9,
    '150': 21.9,
    '151': 21.8,
    '152': 21.8,
    '153': 21.7,
    '154': 21.6,
    '155': 21.6,
    '156': 21.5,
    '157': 21.5,
    '158': 21.4,
    '159': 21.4,
    '160': 21.3,
    '161': 21.2,
    '162': 21.2,
    '163': 21.1,
    '164': 21.1,
    '165': 21,
    '166': 21,
    '167': 20.9,
    '168': 20.8,
    '169': 20.8,
    '170': 20.7,
    '171': 20.7,
    '172': 20.6,
    '173': 20.6,
    '174': 20.5,
    '175': 20.4,
    '176': 20.4,
    '177': 20.3,
    '178': 20.3,
    '179': 20.2,
    '180': 20.2,
    '181': 20.1,
    '182': 20,
    '183': 20,
    '184': 19.9,
    '185': 19.9,
    '186': 19.8,
    '187': 19.8,
    '188': 19.7,
    '189': 19.7,
    '190': 19.6,
    '191': 19.5,
    '192': 19.5,
    '193': 19.4,
    '194': 19.4,
    '195': 19.3,
    '196': 19.3,
    '197': 19.2,
    '198': 19.2,
    '199': 19.1,
    '200': 19.1,
    '201': 19,
    '202': 18.9,
    '203': 18.9,
    '204': 18.8,
    '205': 18.8,
    '206': 18.7,
    '207': 18.7,
    '208': 18.6,
    '209': 18.6,
    '210': 18.5,
    '0.5': 31.6,
    '1.5': 31.6,
    '2.5': 31.5,
    '3.5': 31.4,
    '4.5': 31.3,
    '5.5': 31.3,
    '6.5': 31.2,
    '7.5': 31.1,
    '8.5': 31.1,
    '9.5': 31,
    '10.5': 30.9,
    '11.5': 30.8,
    '12.5': 30.8,
    '13.5': 30.7,
    '14.5': 30.6,
    '15.5': 30.6,
    '16.5': 30.5,
    '17.5': 30.4,
    '18.5': 30.3,
    '19.5': 30.3,
    '20.5': 30.2,
    '21.5': 30.1,
    '22.5': 30.1,
    '23.5': 30,
    '24.5': 29.9,
    '25.5': 29.9,
    '26.5': 29.8,
    '27.5': 29.7,
    '28.5': 29.6,
    '29.5': 29.6,
    '30.5': 29.5,
    '31.5': 29.4,
    '32.5': 29.4,
    '33.5': 29.3,
    '34.5': 29.2,
    '35.5': 29.2,
    '36.5': 29.1,
    '37.5': 29,
    '38.5': 29,
    '39.5': 28.9,
    '40.5': 28.8,
    '41.5': 28.7,
    '42.5': 28.7,
    '43.5': 28.6,
    '44.5': 28.5,
    '45.5': 28.5,
    '46.5': 28.4,
    '47.5': 28.3,
    '48.5': 28.3,
    '49.5': 28.2,
    '50.5': 28.1,
    '51.5': 28.1,
    '52.5': 28,
    '53.5': 27.9,
    '54.5': 27.9,
    '55.5': 27.8,
    '56.5': 27.7,
    '57.5': 27.7,
    '58.5': 27.6,
    '59.5': 27.5,
    '60.5': 27.5,
    '61.5': 27.4,
    '62.5': 27.3,
    '63.5': 27.3,
    '64.5': 27.2,
    '65.5': 27.1,
    '66.5': 27.1,
    '67.5': 27,
    '68.5': 26.9,
    '69.5': 26.9,
    '70.5': 26.8,
    '71.5': 26.7,
    '72.5': 26.7,
    '73.5': 26.6,
    '74.5': 26.5,
    '75.5': 26.5,
    '76.5': 26.4,
    '77.5': 26.3,
    '78.5': 26.3,
    '79.5': 26.2,
    '80.5': 26.2,
    '81.5': 26.1,
    '82.5': 26,
    '83.5': 26,
    '84.5': 25.9,
    '85.5': 25.8,
    '86.5': 25.8,
    '87.5': 25.7,
    '88.5': 25.6,
    '89.5': 25.6,
    '90.5': 25.5,
    '91.5': 25.4,
    '92.5': 25.4,
    '93.5': 25.3,
    '94.5': 25.3,
    '95.5': 25.2,
    '96.5': 25.1,
    '97.5': 25.1,
    '98.5': 25,
    '99.5': 24.9,
    '100.5': 24.9,
    '101.5': 24.8,
    '102.5': 24.8,
    '103.5': 24.7,
    '104.5': 24.6,
    '105.5': 24.6,
    '106.5': 24.5,
    '107.5': 24.4,
    '108.5': 24.4,
    '109.5': 24.3,
    '110.5': 24.3,
    '111.5': 24.2,
    '112.5': 24.1,
    '113.5': 24.1,
    '114.5': 24,
    '115.5': 24,
    '116.5': 23.9,
    '117.5': 23.8,
    '118.5': 23.8,
    '119.5': 23.7,
    '120.5': 23.6,
    '121.5': 23.6,
    '122.5': 23.5,
    '123.5': 23.5,
    '124.5': 23.4,
    '125.5': 23.3,
    '126.5': 23.3,
    '127.5': 23.2,
    '128.5': 23.2,
    '129.5': 23.1,
    '130.5': 23,
    '131.5': 23,
    '132.5': 22.9,
    '133.5': 22.9,
    '134.5': 22.8,
    '135.5': 22.7,
    '136.5': 22.7,
    '137.5': 22.6,
    '138.5': 22.6,
    '139.5': 22.5,
    '140.5': 22.4,
    '141.5': 22.4,
    '142.5': 22.3,
    '143.5': 22.3,
    '144.5': 22.2,
    '145.5': 22.1,
    '146.5': 22.1,
    '147.5': 22,
    '148.5': 22,
    '149.5': 21.9,
    '150.5': 21.9,
    '151.5': 21.8,
    '152.5': 21.7,
    '153.5': 21.7,
    '154.5': 21.6,
    '155.5': 21.6,
    '156.5': 21.5,
    '157.5': 21.4,
    '158.5': 21.4,
    '159.5': 21.3,
    '160.5': 21.3,
    '161.5': 21.2,
    '162.5': 21.2,
    '163.5': 21.1,
    '164.5': 21,
    '165.5': 21,
    '166.5': 20.9,
    '167.5': 20.9,
    '168.5': 20.8,
    '169.5': 20.8,
    '170.5': 20.7,
    '171.5': 20.6,
    '172.5': 20.6,
    '173.5': 20.5,
    '174.5': 20.5,
    '175.5': 20.4,
    '176.5': 20.4,
    '177.5': 20.3,
    '178.5': 20.2,
    '179.5': 20.2,
    '180.5': 20.1,
    '181.5': 20.1,
    '182.5': 20,
    '183.5': 20,
    '184.5': 19.9,
    '185.5': 19.9,
    '186.5': 19.8,
    '187.5': 19.7,
    '188.5': 19.7,
    '189.5': 19.6,
    '190.5': 19.6,
    '191.5': 19.5,
    '192.5': 19.5,
    '193.5': 19.4,
    '194.5': 19.4,
    '195.5': 19.3,
    '196.5': 19.2,
    '197.5': 19.2,
    '198.5': 19.1,
    '199.5': 19.1,
    '200.5': 19,
    '201.5': 19,
    '202.5': 18.9,
    '203.5': 18.9,
    '204.5': 18.8,
    '205.5': 18.8,
    '206.5': 18.7,
    '207.5': 18.6,
    '208.5': 18.6,
    '209.5': 18.5,
  },
  '28': {
    '0': 32.2,
    '1': 32.1,
    '2': 32.1,
    '3': 32,
    '4': 31.9,
    '5': 31.9,
    '6': 31.8,
    '7': 31.7,
    '8': 31.6,
    '9': 31.6,
    '10': 31.5,
    '11': 31.4,
    '12': 31.3,
    '13': 31.3,
    '14': 31.2,
    '15': 31.1,
    '16': 31.1,
    '17': 31,
    '18': 30.9,
    '19': 30.8,
    '20': 30.8,
    '21': 30.7,
    '22': 30.6,
    '23': 30.6,
    '24': 30.5,
    '25': 30.4,
    '26': 30.3,
    '27': 30.3,
    '28': 30.2,
    '29': 30.1,
    '30': 30.1,
    '31': 30,
    '32': 29.9,
    '33': 29.9,
    '34': 29.8,
    '35': 29.7,
    '36': 29.6,
    '37': 29.6,
    '38': 29.5,
    '39': 29.4,
    '40': 29.4,
    '41': 29.3,
    '42': 29.2,
    '43': 29.2,
    '44': 29.1,
    '45': 29,
    '46': 29,
    '47': 28.9,
    '48': 28.8,
    '49': 28.7,
    '50': 28.7,
    '51': 28.6,
    '52': 28.5,
    '53': 28.5,
    '54': 28.4,
    '55': 28.3,
    '56': 28.3,
    '57': 28.2,
    '58': 28.1,
    '59': 28.1,
    '60': 28,
    '61': 27.9,
    '62': 27.9,
    '63': 27.8,
    '64': 27.7,
    '65': 27.7,
    '66': 27.6,
    '67': 27.5,
    '68': 27.5,
    '69': 27.4,
    '70': 27.3,
    '71': 27.3,
    '72': 27.2,
    '73': 27.1,
    '74': 27.1,
    '75': 27,
    '76': 26.9,
    '77': 26.9,
    '78': 26.8,
    '79': 26.7,
    '80': 26.7,
    '81': 26.6,
    '82': 26.5,
    '83': 26.5,
    '84': 26.4,
    '85': 26.4,
    '86': 26.3,
    '87': 26.2,
    '88': 26.2,
    '89': 26.1,
    '90': 26,
    '91': 26,
    '92': 25.9,
    '93': 25.8,
    '94': 25.8,
    '95': 25.7,
    '96': 25.6,
    '97': 25.6,
    '98': 25.5,
    '99': 25.5,
    '100': 25.4,
    '101': 25.3,
    '102': 25.3,
    '103': 25.2,
    '104': 25.1,
    '105': 25.1,
    '106': 25,
    '107': 24.9,
    '108': 24.9,
    '109': 24.8,
    '110': 24.8,
    '111': 24.7,
    '112': 24.6,
    '113': 24.6,
    '114': 24.5,
    '115': 24.4,
    '116': 24.4,
    '117': 24.3,
    '118': 24.3,
    '119': 24.2,
    '120': 24.1,
    '121': 24.1,
    '122': 24,
    '123': 24,
    '124': 23.9,
    '125': 23.8,
    '126': 23.8,
    '127': 23.7,
    '128': 23.6,
    '129': 23.6,
    '130': 23.5,
    '131': 23.5,
    '132': 23.4,
    '133': 23.3,
    '134': 23.3,
    '135': 23.2,
    '136': 23.2,
    '137': 23.1,
    '138': 23,
    '139': 23,
    '140': 22.9,
    '141': 22.9,
    '142': 22.8,
    '143': 22.7,
    '144': 22.7,
    '145': 22.6,
    '146': 22.6,
    '147': 22.5,
    '148': 22.4,
    '149': 22.4,
    '150': 22.3,
    '151': 22.3,
    '152': 22.2,
    '153': 22.2,
    '154': 22.1,
    '155': 22,
    '156': 22,
    '157': 21.9,
    '158': 21.9,
    '159': 21.8,
    '160': 21.7,
    '161': 21.7,
    '162': 21.6,
    '163': 21.6,
    '164': 21.5,
    '165': 21.5,
    '166': 21.4,
    '167': 21.3,
    '168': 21.3,
    '169': 21.2,
    '170': 21.2,
    '171': 21.1,
    '172': 21,
    '173': 21,
    '174': 20.9,
    '175': 20.9,
    '176': 20.8,
    '177': 20.8,
    '178': 20.7,
    '179': 20.6,
    '180': 20.6,
    '181': 20.5,
    '182': 20.5,
    '183': 20.4,
    '184': 20.4,
    '185': 20.3,
    '186': 20.3,
    '187': 20.2,
    '188': 20.1,
    '189': 20.1,
    '190': 20,
    '191': 20,
    '192': 19.9,
    '193': 19.9,
    '194': 19.8,
    '195': 19.8,
    '196': 19.7,
    '197': 19.6,
    '198': 19.6,
    '199': 19.5,
    '200': 19.5,
    '201': 19.4,
    '202': 19.4,
    '203': 19.3,
    '204': 19.3,
    '205': 19.2,
    '206': 19.1,
    '207': 19.1,
    '208': 19,
    '209': 19,
    '210': 18.9,
    '0.5': 32.2,
    '1.5': 32.1,
    '2.5': 32,
    '3.5': 32,
    '4.5': 31.9,
    '5.5': 31.8,
    '6.5': 31.7,
    '7.5': 31.7,
    '8.5': 31.6,
    '9.5': 31.5,
    '10.5': 31.5,
    '11.5': 31.4,
    '12.5': 31.3,
    '13.5': 31.2,
    '14.5': 31.2,
    '15.5': 31.1,
    '16.5': 31,
    '17.5': 30.9,
    '18.5': 30.9,
    '19.5': 30.8,
    '20.5': 30.7,
    '21.5': 30.7,
    '22.5': 30.6,
    '23.5': 30.5,
    '24.5': 30.4,
    '25.5': 30.4,
    '26.5': 30.3,
    '27.5': 30.2,
    '28.5': 30.2,
    '29.5': 30.1,
    '30.5': 30,
    '31.5': 30,
    '32.5': 29.9,
    '33.5': 29.8,
    '34.5': 29.7,
    '35.5': 29.7,
    '36.5': 29.6,
    '37.5': 29.5,
    '38.5': 29.5,
    '39.5': 29.4,
    '40.5': 29.3,
    '41.5': 29.3,
    '42.5': 29.2,
    '43.5': 29.1,
    '44.5': 29.1,
    '45.5': 29,
    '46.5': 28.9,
    '47.5': 28.8,
    '48.5': 28.8,
    '49.5': 28.7,
    '50.5': 28.6,
    '51.5': 28.6,
    '52.5': 28.5,
    '53.5': 28.4,
    '54.5': 28.4,
    '55.5': 28.3,
    '56.5': 28.2,
    '57.5': 28.2,
    '58.5': 28.1,
    '59.5': 28,
    '60.5': 28,
    '61.5': 27.9,
    '62.5': 27.8,
    '63.5': 27.8,
    '64.5': 27.7,
    '65.5': 27.6,
    '66.5': 27.6,
    '67.5': 27.5,
    '68.5': 27.4,
    '69.5': 27.4,
    '70.5': 27.3,
    '71.5': 27.2,
    '72.5': 27.2,
    '73.5': 27.1,
    '74.5': 27,
    '75.5': 27,
    '76.5': 26.9,
    '77.5': 26.8,
    '78.5': 26.8,
    '79.5': 26.7,
    '80.5': 26.6,
    '81.5': 26.6,
    '82.5': 26.5,
    '83.5': 26.4,
    '84.5': 26.4,
    '85.5': 26.3,
    '86.5': 26.3,
    '87.5': 26.2,
    '88.5': 26.1,
    '89.5': 26.1,
    '90.5': 26,
    '91.5': 25.9,
    '92.5': 25.9,
    '93.5': 25.8,
    '94.5': 25.7,
    '95.5': 25.7,
    '96.5': 25.6,
    '97.5': 25.5,
    '98.5': 25.5,
    '99.5': 25.4,
    '100.5': 25.4,
    '101.5': 25.3,
    '102.5': 25.2,
    '103.5': 25.2,
    '104.5': 25.1,
    '105.5': 25,
    '106.5': 25,
    '107.5': 24.9,
    '108.5': 24.9,
    '109.5': 24.8,
    '110.5': 24.7,
    '111.5': 24.7,
    '112.5': 24.6,
    '113.5': 24.5,
    '114.5': 24.5,
    '115.5': 24.4,
    '116.5': 24.4,
    '117.5': 24.3,
    '118.5': 24.2,
    '119.5': 24.2,
    '120.5': 24.1,
    '121.5': 24,
    '122.5': 24,
    '123.5': 23.9,
    '124.5': 23.9,
    '125.5': 23.8,
    '126.5': 23.7,
    '127.5': 23.7,
    '128.5': 23.6,
    '129.5': 23.6,
    '130.5': 23.5,
    '131.5': 23.4,
    '132.5': 23.4,
    '133.5': 23.3,
    '134.5': 23.3,
    '135.5': 23.2,
    '136.5': 23.1,
    '137.5': 23.1,
    '138.5': 23,
    '139.5': 23,
    '140.5': 22.9,
    '141.5': 22.8,
    '142.5': 22.8,
    '143.5': 22.7,
    '144.5': 22.7,
    '145.5': 22.6,
    '146.5': 22.5,
    '147.5': 22.5,
    '148.5': 22.4,
    '149.5': 22.4,
    '150.5': 22.3,
    '151.5': 22.2,
    '152.5': 22.2,
    '153.5': 22.1,
    '154.5': 22.1,
    '155.5': 22,
    '156.5': 21.9,
    '157.5': 21.9,
    '158.5': 21.8,
    '159.5': 21.8,
    '160.5': 21.7,
    '161.5': 21.7,
    '162.5': 21.6,
    '163.5': 21.5,
    '164.5': 21.5,
    '165.5': 21.4,
    '166.5': 21.4,
    '167.5': 21.3,
    '168.5': 21.3,
    '169.5': 21.2,
    '170.5': 21.1,
    '171.5': 21.1,
    '172.5': 21,
    '173.5': 21,
    '174.5': 20.9,
    '175.5': 20.8,
    '176.5': 20.8,
    '177.5': 20.7,
    '178.5': 20.7,
    '179.5': 20.6,
    '180.5': 20.6,
    '181.5': 20.5,
    '182.5': 20.5,
    '183.5': 20.4,
    '184.5': 20.3,
    '185.5': 20.3,
    '186.5': 20.2,
    '187.5': 20.2,
    '188.5': 20.1,
    '189.5': 20.1,
    '190.5': 20,
    '191.5': 19.9,
    '192.5': 19.9,
    '193.5': 19.8,
    '194.5': 19.8,
    '195.5': 19.7,
    '196.5': 19.7,
    '197.5': 19.6,
    '198.5': 19.6,
    '199.5': 19.5,
    '200.5': 19.4,
    '201.5': 19.4,
    '202.5': 19.3,
    '203.5': 19.3,
    '204.5': 19.2,
    '205.5': 19.2,
    '206.5': 19.1,
    '207.5': 19.1,
    '208.5': 19,
    '209.5': 19,
  },
  '28.5': {
    '0': 32.8,
    '1': 32.7,
    '2': 32.6,
    '3': 32.5,
    '4': 32.5,
    '5': 32.4,
    '6': 32.3,
    '7': 32.2,
    '8': 32.2,
    '9': 32.1,
    '10': 32,
    '11': 32,
    '12': 31.9,
    '13': 31.8,
    '14': 31.7,
    '15': 31.7,
    '16': 31.6,
    '17': 31.5,
    '18': 31.4,
    '19': 31.4,
    '20': 31.3,
    '21': 31.2,
    '22': 31.2,
    '23': 31.1,
    '24': 31,
    '25': 30.9,
    '26': 30.9,
    '27': 30.8,
    '28': 30.7,
    '29': 30.7,
    '30': 30.6,
    '31': 30.5,
    '32': 30.4,
    '33': 30.4,
    '34': 30.3,
    '35': 30.2,
    '36': 30.2,
    '37': 30.1,
    '38': 30,
    '39': 29.9,
    '40': 29.9,
    '41': 29.8,
    '42': 29.7,
    '43': 29.7,
    '44': 29.6,
    '45': 29.5,
    '46': 29.5,
    '47': 29.4,
    '48': 29.3,
    '49': 29.3,
    '50': 29.2,
    '51': 29.1,
    '52': 29,
    '53': 29,
    '54': 28.9,
    '55': 28.8,
    '56': 28.8,
    '57': 28.7,
    '58': 28.6,
    '59': 28.6,
    '60': 28.5,
    '61': 28.4,
    '62': 28.4,
    '63': 28.3,
    '64': 28.2,
    '65': 28.2,
    '66': 28.1,
    '67': 28,
    '68': 28,
    '69': 27.9,
    '70': 27.8,
    '71': 27.8,
    '72': 27.7,
    '73': 27.6,
    '74': 27.6,
    '75': 27.5,
    '76': 27.4,
    '77': 27.4,
    '78': 27.3,
    '79': 27.2,
    '80': 27.2,
    '81': 27.1,
    '82': 27,
    '83': 27,
    '84': 26.9,
    '85': 26.8,
    '86': 26.8,
    '87': 26.7,
    '88': 26.6,
    '89': 26.6,
    '90': 26.5,
    '91': 26.4,
    '92': 26.4,
    '93': 26.3,
    '94': 26.2,
    '95': 26.2,
    '96': 26.1,
    '97': 26.1,
    '98': 26,
    '99': 25.9,
    '100': 25.9,
    '101': 25.8,
    '102': 25.7,
    '103': 25.7,
    '104': 25.6,
    '105': 25.5,
    '106': 25.5,
    '107': 25.4,
    '108': 25.4,
    '109': 25.3,
    '110': 25.2,
    '111': 25.2,
    '112': 25.1,
    '113': 25,
    '114': 25,
    '115': 24.9,
    '116': 24.9,
    '117': 24.8,
    '118': 24.7,
    '119': 24.7,
    '120': 24.6,
    '121': 24.5,
    '122': 24.5,
    '123': 24.4,
    '124': 24.4,
    '125': 24.3,
    '126': 24.2,
    '127': 24.2,
    '128': 24.1,
    '129': 24,
    '130': 24,
    '131': 23.9,
    '132': 23.9,
    '133': 23.8,
    '134': 23.7,
    '135': 23.7,
    '136': 23.6,
    '137': 23.6,
    '138': 23.5,
    '139': 23.4,
    '140': 23.4,
    '141': 23.3,
    '142': 23.3,
    '143': 23.2,
    '144': 23.1,
    '145': 23.1,
    '146': 23,
    '147': 23,
    '148': 22.9,
    '149': 22.8,
    '150': 22.8,
    '151': 22.7,
    '152': 22.7,
    '153': 22.6,
    '154': 22.5,
    '155': 22.5,
    '156': 22.4,
    '157': 22.4,
    '158': 22.3,
    '159': 22.2,
    '160': 22.2,
    '161': 22.1,
    '162': 22.1,
    '163': 22,
    '164': 21.9,
    '165': 21.9,
    '166': 21.8,
    '167': 21.8,
    '168': 21.7,
    '169': 21.7,
    '170': 21.6,
    '171': 21.5,
    '172': 21.5,
    '173': 21.4,
    '174': 21.4,
    '175': 21.3,
    '176': 21.3,
    '177': 21.2,
    '178': 21.1,
    '179': 21.1,
    '180': 21,
    '181': 21,
    '182': 20.9,
    '183': 20.9,
    '184': 20.8,
    '185': 20.7,
    '186': 20.7,
    '187': 20.6,
    '188': 20.6,
    '189': 20.5,
    '190': 20.5,
    '191': 20.4,
    '192': 20.3,
    '193': 20.3,
    '194': 20.2,
    '195': 20.2,
    '196': 20.1,
    '197': 20.1,
    '198': 20,
    '199': 20,
    '200': 19.9,
    '201': 19.8,
    '202': 19.8,
    '203': 19.7,
    '204': 19.7,
    '205': 19.6,
    '206': 19.6,
    '207': 19.5,
    '208': 19.5,
    '209': 19.4,
    '210': 19.3,
    '0.5': 32.7,
    '1.5': 32.6,
    '2.5': 32.6,
    '3.5': 32.5,
    '4.5': 32.4,
    '5.5': 32.4,
    '6.5': 32.3,
    '7.5': 32.2,
    '8.5': 32.1,
    '9.5': 32.1,
    '10.5': 32,
    '11.5': 31.9,
    '12.5': 31.8,
    '13.5': 31.8,
    '14.5': 31.7,
    '15.5': 31.6,
    '16.5': 31.6,
    '17.5': 31.5,
    '18.5': 31.4,
    '19.5': 31.3,
    '20.5': 31.3,
    '21.5': 31.2,
    '22.5': 31.1,
    '23.5': 31,
    '24.5': 31,
    '25.5': 30.9,
    '26.5': 30.8,
    '27.5': 30.8,
    '28.5': 30.7,
    '29.5': 30.6,
    '30.5': 30.5,
    '31.5': 30.5,
    '32.5': 30.4,
    '33.5': 30.3,
    '34.5': 30.3,
    '35.5': 30.2,
    '36.5': 30.1,
    '37.5': 30.1,
    '38.5': 30,
    '39.5': 29.9,
    '40.5': 29.8,
    '41.5': 29.8,
    '42.5': 29.7,
    '43.5': 29.6,
    '44.5': 29.6,
    '45.5': 29.5,
    '46.5': 29.4,
    '47.5': 29.4,
    '48.5': 29.3,
    '49.5': 29.2,
    '50.5': 29.1,
    '51.5': 29.1,
    '52.5': 29,
    '53.5': 28.9,
    '54.5': 28.9,
    '55.5': 28.8,
    '56.5': 28.7,
    '57.5': 28.7,
    '58.5': 28.6,
    '59.5': 28.5,
    '60.5': 28.5,
    '61.5': 28.4,
    '62.5': 28.3,
    '63.5': 28.3,
    '64.5': 28.2,
    '65.5': 28.1,
    '66.5': 28.1,
    '67.5': 28,
    '68.5': 27.9,
    '69.5': 27.9,
    '70.5': 27.8,
    '71.5': 27.7,
    '72.5': 27.7,
    '73.5': 27.6,
    '74.5': 27.5,
    '75.5': 27.5,
    '76.5': 27.4,
    '77.5': 27.3,
    '78.5': 27.3,
    '79.5': 27.2,
    '80.5': 27.1,
    '81.5': 27.1,
    '82.5': 27,
    '83.5': 26.9,
    '84.5': 26.9,
    '85.5': 26.8,
    '86.5': 26.7,
    '87.5': 26.7,
    '88.5': 26.6,
    '89.5': 26.5,
    '90.5': 26.5,
    '91.5': 26.4,
    '92.5': 26.3,
    '93.5': 26.3,
    '94.5': 26.2,
    '95.5': 26.2,
    '96.5': 26.1,
    '97.5': 26,
    '98.5': 26,
    '99.5': 25.9,
    '100.5': 25.8,
    '101.5': 25.8,
    '102.5': 25.7,
    '103.5': 25.6,
    '104.5': 25.6,
    '105.5': 25.5,
    '106.5': 25.5,
    '107.5': 25.4,
    '108.5': 25.3,
    '109.5': 25.3,
    '110.5': 25.2,
    '111.5': 25.1,
    '112.5': 25.1,
    '113.5': 25,
    '114.5': 24.9,
    '115.5': 24.9,
    '116.5': 24.8,
    '117.5': 24.8,
    '118.5': 24.7,
    '119.5': 24.6,
    '120.5': 24.6,
    '121.5': 24.5,
    '122.5': 24.4,
    '123.5': 24.4,
    '124.5': 24.3,
    '125.5': 24.3,
    '126.5': 24.2,
    '127.5': 24.1,
    '128.5': 24.1,
    '129.5': 24,
    '130.5': 24,
    '131.5': 23.9,
    '132.5': 23.8,
    '133.5': 23.8,
    '134.5': 23.7,
    '135.5': 23.6,
    '136.5': 23.6,
    '137.5': 23.5,
    '138.5': 23.5,
    '139.5': 23.4,
    '140.5': 23.3,
    '141.5': 23.3,
    '142.5': 23.2,
    '143.5': 23.2,
    '144.5': 23.1,
    '145.5': 23,
    '146.5': 23,
    '147.5': 22.9,
    '148.5': 22.9,
    '149.5': 22.8,
    '150.5': 22.7,
    '151.5': 22.7,
    '152.5': 22.6,
    '153.5': 22.6,
    '154.5': 22.5,
    '155.5': 22.5,
    '156.5': 22.4,
    '157.5': 22.3,
    '158.5': 22.3,
    '159.5': 22.2,
    '160.5': 22.2,
    '161.5': 22.1,
    '162.5': 22,
    '163.5': 22,
    '164.5': 21.9,
    '165.5': 21.9,
    '166.5': 21.8,
    '167.5': 21.7,
    '168.5': 21.7,
    '169.5': 21.6,
    '170.5': 21.6,
    '171.5': 21.5,
    '172.5': 21.5,
    '173.5': 21.4,
    '174.5': 21.3,
    '175.5': 21.3,
    '176.5': 21.2,
    '177.5': 21.2,
    '178.5': 21.1,
    '179.5': 21.1,
    '180.5': 21,
    '181.5': 20.9,
    '182.5': 20.9,
    '183.5': 20.8,
    '184.5': 20.8,
    '185.5': 20.7,
    '186.5': 20.7,
    '187.5': 20.6,
    '188.5': 20.5,
    '189.5': 20.5,
    '190.5': 20.4,
    '191.5': 20.4,
    '192.5': 20.3,
    '193.5': 20.3,
    '194.5': 20.2,
    '195.5': 20.1,
    '196.5': 20.1,
    '197.5': 20,
    '198.5': 20,
    '199.5': 19.9,
    '200.5': 19.9,
    '201.5': 19.8,
    '202.5': 19.8,
    '203.5': 19.7,
    '204.5': 19.6,
    '205.5': 19.6,
    '206.5': 19.5,
    '207.5': 19.5,
    '208.5': 19.4,
    '209.5': 19.4,
  },
  '29': {
    '0': 33.3,
    '1': 33.2,
    '2': 33.2,
    '3': 33.1,
    '4': 33,
    '5': 32.9,
    '6': 32.9,
    '7': 32.8,
    '8': 32.7,
    '9': 32.6,
    '10': 32.6,
    '11': 32.5,
    '12': 32.4,
    '13': 32.3,
    '14': 32.3,
    '15': 32.2,
    '16': 32.1,
    '17': 32,
    '18': 32,
    '19': 31.9,
    '20': 31.8,
    '21': 31.8,
    '22': 31.7,
    '23': 31.6,
    '24': 31.5,
    '25': 31.5,
    '26': 31.4,
    '27': 31.3,
    '28': 31.2,
    '29': 31.2,
    '30': 31.1,
    '31': 31,
    '32': 31,
    '33': 30.9,
    '34': 30.8,
    '35': 30.7,
    '36': 30.7,
    '37': 30.6,
    '38': 30.5,
    '39': 30.5,
    '40': 30.4,
    '41': 30.3,
    '42': 30.3,
    '43': 30.2,
    '44': 30.1,
    '45': 30,
    '46': 30,
    '47': 29.9,
    '48': 29.8,
    '49': 29.8,
    '50': 29.7,
    '51': 29.6,
    '52': 29.6,
    '53': 29.5,
    '54': 29.4,
    '55': 29.3,
    '56': 29.3,
    '57': 29.2,
    '58': 29.1,
    '59': 29.1,
    '60': 29,
    '61': 28.9,
    '62': 28.9,
    '63': 28.8,
    '64': 28.7,
    '65': 28.7,
    '66': 28.6,
    '67': 28.5,
    '68': 28.5,
    '69': 28.4,
    '70': 28.3,
    '71': 28.3,
    '72': 28.2,
    '73': 28.1,
    '74': 28.1,
    '75': 28,
    '76': 27.9,
    '77': 27.8,
    '78': 27.8,
    '79': 27.7,
    '80': 27.6,
    '81': 27.6,
    '82': 27.5,
    '83': 27.4,
    '84': 27.4,
    '85': 27.3,
    '86': 27.3,
    '87': 27.2,
    '88': 27.1,
    '89': 27.1,
    '90': 27,
    '91': 26.9,
    '92': 26.9,
    '93': 26.8,
    '94': 26.7,
    '95': 26.7,
    '96': 26.6,
    '97': 26.5,
    '98': 26.5,
    '99': 26.4,
    '100': 26.3,
    '101': 26.3,
    '102': 26.2,
    '103': 26.1,
    '104': 26.1,
    '105': 26,
    '106': 26,
    '107': 25.9,
    '108': 25.8,
    '109': 25.8,
    '110': 25.7,
    '111': 25.6,
    '112': 25.6,
    '113': 25.5,
    '114': 25.4,
    '115': 25.4,
    '116': 25.3,
    '117': 25.3,
    '118': 25.2,
    '119': 25.1,
    '120': 25.1,
    '121': 25,
    '122': 24.9,
    '123': 24.9,
    '124': 24.8,
    '125': 24.8,
    '126': 24.7,
    '127': 24.6,
    '128': 24.6,
    '129': 24.5,
    '130': 24.4,
    '131': 24.4,
    '132': 24.3,
    '133': 24.3,
    '134': 24.2,
    '135': 24.1,
    '136': 24.1,
    '137': 24,
    '138': 24,
    '139': 23.9,
    '140': 23.8,
    '141': 23.8,
    '142': 23.7,
    '143': 23.6,
    '144': 23.6,
    '145': 23.5,
    '146': 23.5,
    '147': 23.4,
    '148': 23.3,
    '149': 23.3,
    '150': 23.2,
    '151': 23.2,
    '152': 23.1,
    '153': 23,
    '154': 23,
    '155': 22.9,
    '156': 22.9,
    '157': 22.8,
    '158': 22.7,
    '159': 22.7,
    '160': 22.6,
    '161': 22.6,
    '162': 22.5,
    '163': 22.5,
    '164': 22.4,
    '165': 22.3,
    '166': 22.3,
    '167': 22.2,
    '168': 22.2,
    '169': 22.1,
    '170': 22,
    '171': 22,
    '172': 21.9,
    '173': 21.9,
    '174': 21.8,
    '175': 21.7,
    '176': 21.7,
    '177': 21.6,
    '178': 21.6,
    '179': 21.5,
    '180': 21.5,
    '181': 21.4,
    '182': 21.3,
    '183': 21.3,
    '184': 21.2,
    '185': 21.2,
    '186': 21.1,
    '187': 21.1,
    '188': 21,
    '189': 20.9,
    '190': 20.9,
    '191': 20.8,
    '192': 20.8,
    '193': 20.7,
    '194': 20.7,
    '195': 20.6,
    '196': 20.5,
    '197': 20.5,
    '198': 20.4,
    '199': 20.4,
    '200': 20.3,
    '201': 20.3,
    '202': 20.2,
    '203': 20.2,
    '204': 20.1,
    '205': 20,
    '206': 20,
    '207': 19.9,
    '208': 19.9,
    '209': 19.8,
    '210': 19.8,
    '0.5': 33.3,
    '1.5': 33.2,
    '2.5': 33.1,
    '3.5': 33,
    '4.5': 33,
    '5.5': 32.9,
    '6.5': 32.8,
    '7.5': 32.7,
    '8.5': 32.7,
    '9.5': 32.6,
    '10.5': 32.5,
    '11.5': 32.4,
    '12.5': 32.4,
    '13.5': 32.3,
    '14.5': 32.2,
    '15.5': 32.2,
    '16.5': 32.1,
    '17.5': 32,
    '18.5': 31.9,
    '19.5': 31.9,
    '20.5': 31.8,
    '21.5': 31.7,
    '22.5': 31.6,
    '23.5': 31.6,
    '24.5': 31.5,
    '25.5': 31.4,
    '26.5': 31.4,
    '27.5': 31.3,
    '28.5': 31.2,
    '29.5': 31.1,
    '30.5': 31.1,
    '31.5': 31,
    '32.5': 30.9,
    '33.5': 30.9,
    '34.5': 30.8,
    '35.5': 30.7,
    '36.5': 30.6,
    '37.5': 30.6,
    '38.5': 30.5,
    '39.5': 30.4,
    '40.5': 30.4,
    '41.5': 30.3,
    '42.5': 30.2,
    '43.5': 30.1,
    '44.5': 30.1,
    '45.5': 30,
    '46.5': 29.9,
    '47.5': 29.9,
    '48.5': 29.8,
    '49.5': 29.7,
    '50.5': 29.7,
    '51.5': 29.6,
    '52.5': 29.5,
    '53.5': 29.4,
    '54.5': 29.4,
    '55.5': 29.3,
    '56.5': 29.2,
    '57.5': 29.2,
    '58.5': 29.1,
    '59.5': 29,
    '60.5': 29,
    '61.5': 28.9,
    '62.5': 28.8,
    '63.5': 28.8,
    '64.5': 28.7,
    '65.5': 28.6,
    '66.5': 28.6,
    '67.5': 28.5,
    '68.5': 28.4,
    '69.5': 28.4,
    '70.5': 28.3,
    '71.5': 28.2,
    '72.5': 28.2,
    '73.5': 28.1,
    '74.5': 28,
    '75.5': 27.9,
    '76.5': 27.9,
    '77.5': 27.8,
    '78.5': 27.7,
    '79.5': 27.7,
    '80.5': 27.6,
    '81.5': 27.5,
    '82.5': 27.5,
    '83.5': 27.4,
    '84.5': 27.4,
    '85.5': 27.3,
    '86.5': 27.2,
    '87.5': 27.2,
    '88.5': 27.1,
    '89.5': 27,
    '90.5': 27,
    '91.5': 26.9,
    '92.5': 26.8,
    '93.5': 26.8,
    '94.5': 26.7,
    '95.5': 26.6,
    '96.5': 26.6,
    '97.5': 26.5,
    '98.5': 26.4,
    '99.5': 26.4,
    '100.5': 26.3,
    '101.5': 26.2,
    '102.5': 26.2,
    '103.5': 26.1,
    '104.5': 26,
    '105.5': 26,
    '106.5': 25.9,
    '107.5': 25.9,
    '108.5': 25.8,
    '109.5': 25.7,
    '110.5': 25.7,
    '111.5': 25.6,
    '112.5': 25.5,
    '113.5': 25.5,
    '114.5': 25.4,
    '115.5': 25.3,
    '116.5': 25.3,
    '117.5': 25.2,
    '118.5': 25.2,
    '119.5': 25.1,
    '120.5': 25,
    '121.5': 25,
    '122.5': 24.9,
    '123.5': 24.8,
    '124.5': 24.8,
    '125.5': 24.7,
    '126.5': 24.7,
    '127.5': 24.6,
    '128.5': 24.5,
    '129.5': 24.5,
    '130.5': 24.4,
    '131.5': 24.3,
    '132.5': 24.3,
    '133.5': 24.2,
    '134.5': 24.2,
    '135.5': 24.1,
    '136.5': 24,
    '137.5': 24,
    '138.5': 23.9,
    '139.5': 23.9,
    '140.5': 23.8,
    '141.5': 23.7,
    '142.5': 23.7,
    '143.5': 23.6,
    '144.5': 23.6,
    '145.5': 23.5,
    '146.5': 23.4,
    '147.5': 23.4,
    '148.5': 23.3,
    '149.5': 23.3,
    '150.5': 23.2,
    '151.5': 23.1,
    '152.5': 23.1,
    '153.5': 23,
    '154.5': 23,
    '155.5': 22.9,
    '156.5': 22.8,
    '157.5': 22.8,
    '158.5': 22.7,
    '159.5': 22.7,
    '160.5': 22.6,
    '161.5': 22.5,
    '162.5': 22.5,
    '163.5': 22.4,
    '164.5': 22.4,
    '165.5': 22.3,
    '166.5': 22.2,
    '167.5': 22.2,
    '168.5': 22.1,
    '169.5': 22.1,
    '170.5': 22,
    '171.5': 22,
    '172.5': 21.9,
    '173.5': 21.8,
    '174.5': 21.8,
    '175.5': 21.7,
    '176.5': 21.7,
    '177.5': 21.6,
    '178.5': 21.5,
    '179.5': 21.5,
    '180.5': 21.4,
    '181.5': 21.4,
    '182.5': 21.3,
    '183.5': 21.3,
    '184.5': 21.2,
    '185.5': 21.1,
    '186.5': 21.1,
    '187.5': 21,
    '188.5': 21,
    '189.5': 20.9,
    '190.5': 20.9,
    '191.5': 20.8,
    '192.5': 20.7,
    '193.5': 20.7,
    '194.5': 20.6,
    '195.5': 20.6,
    '196.5': 20.5,
    '197.5': 20.5,
    '198.5': 20.4,
    '199.5': 20.3,
    '200.5': 20.3,
    '201.5': 20.2,
    '202.5': 20.2,
    '203.5': 20.1,
    '204.5': 20.1,
    '205.5': 20,
    '206.5': 20,
    '207.5': 19.9,
    '208.5': 19.8,
    '209.5': 19.8,
  },
  '29.5': {
    '0': 33.8,
    '1': 33.8,
    '2': 33.7,
    '3': 33.6,
    '4': 33.5,
    '5': 33.5,
    '6': 33.4,
    '7': 33.3,
    '8': 33.2,
    '9': 33.2,
    '10': 33.1,
    '11': 33,
    '12': 32.9,
    '13': 32.9,
    '14': 32.8,
    '15': 32.7,
    '16': 32.6,
    '17': 32.6,
    '18': 32.5,
    '19': 32.4,
    '20': 32.4,
    '21': 32.3,
    '22': 32.2,
    '23': 32.1,
    '24': 32.1,
    '25': 32,
    '26': 31.9,
    '27': 31.8,
    '28': 31.8,
    '29': 31.7,
    '30': 31.6,
    '31': 31.6,
    '32': 31.5,
    '33': 31.4,
    '34': 31.3,
    '35': 31.3,
    '36': 31.2,
    '37': 31.1,
    '38': 31,
    '39': 31,
    '40': 30.9,
    '41': 30.8,
    '42': 30.8,
    '43': 30.7,
    '44': 30.6,
    '45': 30.5,
    '46': 30.5,
    '47': 30.4,
    '48': 30.3,
    '49': 30.3,
    '50': 30.2,
    '51': 30.1,
    '52': 30.1,
    '53': 30,
    '54': 29.9,
    '55': 29.8,
    '56': 29.8,
    '57': 29.7,
    '58': 29.6,
    '59': 29.6,
    '60': 29.5,
    '61': 29.4,
    '62': 29.4,
    '63': 29.3,
    '64': 29.2,
    '65': 29.2,
    '66': 29.1,
    '67': 29,
    '68': 29,
    '69': 28.9,
    '70': 28.8,
    '71': 28.7,
    '72': 28.7,
    '73': 28.6,
    '74': 28.5,
    '75': 28.5,
    '76': 28.4,
    '77': 28.3,
    '78': 28.3,
    '79': 28.2,
    '80': 28.1,
    '81': 28.1,
    '82': 28,
    '83': 27.9,
    '84': 27.9,
    '85': 27.8,
    '86': 27.7,
    '87': 27.7,
    '88': 27.6,
    '89': 27.5,
    '90': 27.5,
    '91': 27.4,
    '92': 27.3,
    '93': 27.3,
    '94': 27.2,
    '95': 27.1,
    '96': 27.1,
    '97': 27,
    '98': 26.9,
    '99': 26.9,
    '100': 26.8,
    '101': 26.7,
    '102': 26.7,
    '103': 26.6,
    '104': 26.6,
    '105': 26.5,
    '106': 26.4,
    '107': 26.4,
    '108': 26.3,
    '109': 26.2,
    '110': 26.2,
    '111': 26.1,
    '112': 26,
    '113': 26,
    '114': 25.9,
    '115': 25.8,
    '116': 25.8,
    '117': 25.7,
    '118': 25.7,
    '119': 25.6,
    '120': 25.5,
    '121': 25.5,
    '122': 25.4,
    '123': 25.3,
    '124': 25.3,
    '125': 25.2,
    '126': 25.2,
    '127': 25.1,
    '128': 25,
    '129': 25,
    '130': 24.9,
    '131': 24.8,
    '132': 24.8,
    '133': 24.7,
    '134': 24.7,
    '135': 24.6,
    '136': 24.5,
    '137': 24.5,
    '138': 24.4,
    '139': 24.3,
    '140': 24.3,
    '141': 24.2,
    '142': 24.2,
    '143': 24.1,
    '144': 24,
    '145': 24,
    '146': 23.9,
    '147': 23.9,
    '148': 23.8,
    '149': 23.7,
    '150': 23.7,
    '151': 23.6,
    '152': 23.5,
    '153': 23.5,
    '154': 23.4,
    '155': 23.4,
    '156': 23.3,
    '157': 23.2,
    '158': 23.2,
    '159': 23.1,
    '160': 23.1,
    '161': 23,
    '162': 22.9,
    '163': 22.9,
    '164': 22.8,
    '165': 22.8,
    '166': 22.7,
    '167': 22.7,
    '168': 22.6,
    '169': 22.5,
    '170': 22.5,
    '171': 22.4,
    '172': 22.4,
    '173': 22.3,
    '174': 22.2,
    '175': 22.2,
    '176': 22.1,
    '177': 22.1,
    '178': 22,
    '179': 21.9,
    '180': 21.9,
    '181': 21.8,
    '182': 21.8,
    '183': 21.7,
    '184': 21.7,
    '185': 21.6,
    '186': 21.5,
    '187': 21.5,
    '188': 21.4,
    '189': 21.4,
    '190': 21.3,
    '191': 21.3,
    '192': 21.2,
    '193': 21.1,
    '194': 21.1,
    '195': 21,
    '196': 21,
    '197': 20.9,
    '198': 20.9,
    '199': 20.8,
    '200': 20.7,
    '201': 20.7,
    '202': 20.6,
    '203': 20.6,
    '204': 20.5,
    '205': 20.5,
    '206': 20.4,
    '207': 20.4,
    '208': 20.3,
    '209': 20.3,
    '210': 20.2,
    '0.5': 33.8,
    '1.5': 33.7,
    '2.5': 33.7,
    '3.5': 33.6,
    '4.5': 33.5,
    '5.5': 33.4,
    '6.5': 33.4,
    '7.5': 33.3,
    '8.5': 33.2,
    '9.5': 33.1,
    '10.5': 33.1,
    '11.5': 33,
    '12.5': 32.9,
    '13.5': 32.8,
    '14.5': 32.8,
    '15.5': 32.7,
    '16.5': 32.6,
    '17.5': 32.5,
    '18.5': 32.5,
    '19.5': 32.4,
    '20.5': 32.3,
    '21.5': 32.2,
    '22.5': 32.2,
    '23.5': 32.1,
    '24.5': 32,
    '25.5': 32,
    '26.5': 31.9,
    '27.5': 31.8,
    '28.5': 31.7,
    '29.5': 31.7,
    '30.5': 31.6,
    '31.5': 31.5,
    '32.5': 31.4,
    '33.5': 31.4,
    '34.5': 31.3,
    '35.5': 31.2,
    '36.5': 31.2,
    '37.5': 31.1,
    '38.5': 31,
    '39.5': 30.9,
    '40.5': 30.9,
    '41.5': 30.8,
    '42.5': 30.7,
    '43.5': 30.7,
    '44.5': 30.6,
    '45.5': 30.5,
    '46.5': 30.4,
    '47.5': 30.4,
    '48.5': 30.3,
    '49.5': 30.2,
    '50.5': 30.2,
    '51.5': 30.1,
    '52.5': 30,
    '53.5': 30,
    '54.5': 29.9,
    '55.5': 29.8,
    '56.5': 29.7,
    '57.5': 29.7,
    '58.5': 29.6,
    '59.5': 29.5,
    '60.5': 29.5,
    '61.5': 29.4,
    '62.5': 29.3,
    '63.5': 29.3,
    '64.5': 29.2,
    '65.5': 29.1,
    '66.5': 29.1,
    '67.5': 29,
    '68.5': 28.9,
    '69.5': 28.8,
    '70.5': 28.8,
    '71.5': 28.7,
    '72.5': 28.6,
    '73.5': 28.6,
    '74.5': 28.5,
    '75.5': 28.4,
    '76.5': 28.4,
    '77.5': 28.3,
    '78.5': 28.2,
    '79.5': 28.2,
    '80.5': 28.1,
    '81.5': 28,
    '82.5': 28,
    '83.5': 27.9,
    '84.5': 27.8,
    '85.5': 27.8,
    '86.5': 27.7,
    '87.5': 27.6,
    '88.5': 27.6,
    '89.5': 27.5,
    '90.5': 27.4,
    '91.5': 27.4,
    '92.5': 27.3,
    '93.5': 27.2,
    '94.5': 27.2,
    '95.5': 27.1,
    '96.5': 27,
    '97.5': 27,
    '98.5': 26.9,
    '99.5': 26.8,
    '100.5': 26.8,
    '101.5': 26.7,
    '102.5': 26.7,
    '103.5': 26.6,
    '104.5': 26.5,
    '105.5': 26.5,
    '106.5': 26.4,
    '107.5': 26.3,
    '108.5': 26.3,
    '109.5': 26.2,
    '110.5': 26.1,
    '111.5': 26.1,
    '112.5': 26,
    '113.5': 25.9,
    '114.5': 25.9,
    '115.5': 25.8,
    '116.5': 25.8,
    '117.5': 25.7,
    '118.5': 25.6,
    '119.5': 25.6,
    '120.5': 25.5,
    '121.5': 25.4,
    '122.5': 25.4,
    '123.5': 25.3,
    '124.5': 25.2,
    '125.5': 25.2,
    '126.5': 25.1,
    '127.5': 25.1,
    '128.5': 25,
    '129.5': 24.9,
    '130.5': 24.9,
    '131.5': 24.8,
    '132.5': 24.7,
    '133.5': 24.7,
    '134.5': 24.6,
    '135.5': 24.6,
    '136.5': 24.5,
    '137.5': 24.4,
    '138.5': 24.4,
    '139.5': 24.3,
    '140.5': 24.2,
    '141.5': 24.2,
    '142.5': 24.1,
    '143.5': 24.1,
    '144.5': 24,
    '145.5': 23.9,
    '146.5': 23.9,
    '147.5': 23.8,
    '148.5': 23.8,
    '149.5': 23.7,
    '150.5': 23.6,
    '151.5': 23.6,
    '152.5': 23.5,
    '153.5': 23.5,
    '154.5': 23.4,
    '155.5': 23.3,
    '156.5': 23.3,
    '157.5': 23.2,
    '158.5': 23.2,
    '159.5': 23.1,
    '160.5': 23,
    '161.5': 23,
    '162.5': 22.9,
    '163.5': 22.9,
    '164.5': 22.8,
    '165.5': 22.7,
    '166.5': 22.7,
    '167.5': 22.6,
    '168.5': 22.6,
    '169.5': 22.5,
    '170.5': 22.4,
    '171.5': 22.4,
    '172.5': 22.3,
    '173.5': 22.3,
    '174.5': 22.2,
    '175.5': 22.2,
    '176.5': 22.1,
    '177.5': 22,
    '178.5': 22,
    '179.5': 21.9,
    '180.5': 21.9,
    '181.5': 21.8,
    '182.5': 21.7,
    '183.5': 21.7,
    '184.5': 21.6,
    '185.5': 21.6,
    '186.5': 21.5,
    '187.5': 21.5,
    '188.5': 21.4,
    '189.5': 21.3,
    '190.5': 21.3,
    '191.5': 21.2,
    '192.5': 21.2,
    '193.5': 21.1,
    '194.5': 21.1,
    '195.5': 21,
    '196.5': 20.9,
    '197.5': 20.9,
    '198.5': 20.8,
    '199.5': 20.8,
    '200.5': 20.7,
    '201.5': 20.7,
    '202.5': 20.6,
    '203.5': 20.6,
    '204.5': 20.5,
    '205.5': 20.5,
    '206.5': 20.4,
    '207.5': 20.3,
    '208.5': 20.3,
    '209.5': 20.2,
  },
  '30': {
    '0': 34.4,
    '1': 34.3,
    '2': 34.2,
    '3': 34.2,
    '4': 34.1,
    '5': 34,
    '6': 33.9,
    '7': 33.9,
    '8': 33.8,
    '9': 33.7,
    '10': 33.6,
    '11': 33.5,
    '12': 33.5,
    '13': 33.4,
    '14': 33.3,
    '15': 33.3,
    '16': 33.2,
    '17': 33.1,
    '18': 33,
    '19': 33,
    '20': 32.9,
    '21': 32.8,
    '22': 32.7,
    '23': 32.7,
    '24': 32.6,
    '25': 32.5,
    '26': 32.4,
    '27': 32.4,
    '28': 32.3,
    '29': 32.2,
    '30': 32.1,
    '31': 32.1,
    '32': 32,
    '33': 31.9,
    '34': 31.9,
    '35': 31.8,
    '36': 31.7,
    '37': 31.6,
    '38': 31.6,
    '39': 31.5,
    '40': 31.4,
    '41': 31.3,
    '42': 31.3,
    '43': 31.2,
    '44': 31.1,
    '45': 31.1,
    '46': 31,
    '47': 30.9,
    '48': 30.8,
    '49': 30.8,
    '50': 30.7,
    '51': 30.6,
    '52': 30.6,
    '53': 30.5,
    '54': 30.4,
    '55': 30.3,
    '56': 30.3,
    '57': 30.2,
    '58': 30.1,
    '59': 30.1,
    '60': 30,
    '61': 29.9,
    '62': 29.9,
    '63': 29.8,
    '64': 29.7,
    '65': 29.7,
    '66': 29.6,
    '67': 29.5,
    '68': 29.4,
    '69': 29.4,
    '70': 29.3,
    '71': 29.2,
    '72': 29.2,
    '73': 29.1,
    '74': 29,
    '75': 29,
    '76': 28.9,
    '77': 28.8,
    '78': 28.8,
    '79': 28.7,
    '80': 28.6,
    '81': 28.6,
    '82': 28.5,
    '83': 28.4,
    '84': 28.4,
    '85': 28.3,
    '86': 28.2,
    '87': 28.2,
    '88': 28.1,
    '89': 28,
    '90': 27.9,
    '91': 27.9,
    '92': 27.8,
    '93': 27.8,
    '94': 27.7,
    '95': 27.6,
    '96': 27.6,
    '97': 27.5,
    '98': 27.4,
    '99': 27.4,
    '100': 27.3,
    '101': 27.2,
    '102': 27.2,
    '103': 27.1,
    '104': 27,
    '105': 27,
    '106': 26.9,
    '107': 26.8,
    '108': 26.8,
    '109': 26.7,
    '110': 26.6,
    '111': 26.6,
    '112': 26.5,
    '113': 26.4,
    '114': 26.4,
    '115': 26.3,
    '116': 26.2,
    '117': 26.2,
    '118': 26.1,
    '119': 26.1,
    '120': 26,
    '121': 25.9,
    '122': 25.9,
    '123': 25.8,
    '124': 25.7,
    '125': 25.7,
    '126': 25.6,
    '127': 25.5,
    '128': 25.5,
    '129': 25.4,
    '130': 25.4,
    '131': 25.3,
    '132': 25.2,
    '133': 25.2,
    '134': 25.1,
    '135': 25,
    '136': 25,
    '137': 24.9,
    '138': 24.9,
    '139': 24.8,
    '140': 24.7,
    '141': 24.7,
    '142': 24.6,
    '143': 24.5,
    '144': 24.5,
    '145': 24.4,
    '146': 24.4,
    '147': 24.3,
    '148': 24.2,
    '149': 24.2,
    '150': 24.1,
    '151': 24.1,
    '152': 24,
    '153': 23.9,
    '154': 23.9,
    '155': 23.8,
    '156': 23.8,
    '157': 23.7,
    '158': 23.6,
    '159': 23.6,
    '160': 23.5,
    '161': 23.4,
    '162': 23.4,
    '163': 23.3,
    '164': 23.3,
    '165': 23.2,
    '166': 23.1,
    '167': 23.1,
    '168': 23,
    '169': 23,
    '170': 22.9,
    '171': 22.9,
    '172': 22.8,
    '173': 22.7,
    '174': 22.7,
    '175': 22.6,
    '176': 22.6,
    '177': 22.5,
    '178': 22.4,
    '179': 22.4,
    '180': 22.3,
    '181': 22.3,
    '182': 22.2,
    '183': 22.1,
    '184': 22.1,
    '185': 22,
    '186': 22,
    '187': 21.9,
    '188': 21.9,
    '189': 21.8,
    '190': 21.7,
    '191': 21.7,
    '192': 21.6,
    '193': 21.6,
    '194': 21.5,
    '195': 21.5,
    '196': 21.4,
    '197': 21.3,
    '198': 21.3,
    '199': 21.2,
    '200': 21.2,
    '201': 21.1,
    '202': 21.1,
    '203': 21,
    '204': 20.9,
    '205': 20.9,
    '206': 20.8,
    '207': 20.8,
    '208': 20.7,
    '209': 20.7,
    '210': 20.6,
    '0.5': 34.3,
    '1.5': 34.3,
    '2.5': 34.2,
    '3.5': 34.1,
    '4.5': 34,
    '5.5': 34,
    '6.5': 33.9,
    '7.5': 33.8,
    '8.5': 33.7,
    '9.5': 33.7,
    '10.5': 33.6,
    '11.5': 33.5,
    '12.5': 33.4,
    '13.5': 33.4,
    '14.5': 33.3,
    '15.5': 33.2,
    '16.5': 33.1,
    '17.5': 33.1,
    '18.5': 33,
    '19.5': 32.9,
    '20.5': 32.8,
    '21.5': 32.8,
    '22.5': 32.7,
    '23.5': 32.6,
    '24.5': 32.5,
    '25.5': 32.5,
    '26.5': 32.4,
    '27.5': 32.3,
    '28.5': 32.3,
    '29.5': 32.2,
    '30.5': 32.1,
    '31.5': 32,
    '32.5': 32,
    '33.5': 31.9,
    '34.5': 31.8,
    '35.5': 31.7,
    '36.5': 31.7,
    '37.5': 31.6,
    '38.5': 31.5,
    '39.5': 31.5,
    '40.5': 31.4,
    '41.5': 31.3,
    '42.5': 31.2,
    '43.5': 31.2,
    '44.5': 31.1,
    '45.5': 31,
    '46.5': 31,
    '47.5': 30.9,
    '48.5': 30.8,
    '49.5': 30.7,
    '50.5': 30.7,
    '51.5': 30.6,
    '52.5': 30.5,
    '53.5': 30.5,
    '54.5': 30.4,
    '55.5': 30.3,
    '56.5': 30.2,
    '57.5': 30.2,
    '58.5': 30.1,
    '59.5': 30,
    '60.5': 30,
    '61.5': 29.9,
    '62.5': 29.8,
    '63.5': 29.8,
    '64.5': 29.7,
    '65.5': 29.6,
    '66.5': 29.5,
    '67.5': 29.5,
    '68.5': 29.4,
    '69.5': 29.3,
    '70.5': 29.3,
    '71.5': 29.2,
    '72.5': 29.1,
    '73.5': 29.1,
    '74.5': 29,
    '75.5': 28.9,
    '76.5': 28.9,
    '77.5': 28.8,
    '78.5': 28.7,
    '79.5': 28.7,
    '80.5': 28.6,
    '81.5': 28.5,
    '82.5': 28.5,
    '83.5': 28.4,
    '84.5': 28.3,
    '85.5': 28.3,
    '86.5': 28.2,
    '87.5': 28.1,
    '88.5': 28.1,
    '89.5': 28,
    '90.5': 27.9,
    '91.5': 27.9,
    '92.5': 27.8,
    '93.5': 27.7,
    '94.5': 27.6,
    '95.5': 27.6,
    '96.5': 27.5,
    '97.5': 27.5,
    '98.5': 27.4,
    '99.5': 27.3,
    '100.5': 27.3,
    '101.5': 27.2,
    '102.5': 27.1,
    '103.5': 27.1,
    '104.5': 27,
    '105.5': 26.9,
    '106.5': 26.9,
    '107.5': 26.8,
    '108.5': 26.7,
    '109.5': 26.7,
    '110.5': 26.6,
    '111.5': 26.5,
    '112.5': 26.5,
    '113.5': 26.4,
    '114.5': 26.3,
    '115.5': 26.3,
    '116.5': 26.2,
    '117.5': 26.2,
    '118.5': 26.1,
    '119.5': 26,
    '120.5': 26,
    '121.5': 25.9,
    '122.5': 25.8,
    '123.5': 25.8,
    '124.5': 25.7,
    '125.5': 25.6,
    '126.5': 25.6,
    '127.5': 25.5,
    '128.5': 25.5,
    '129.5': 25.4,
    '130.5': 25.3,
    '131.5': 25.3,
    '132.5': 25.2,
    '133.5': 25.1,
    '134.5': 25.1,
    '135.5': 25,
    '136.5': 24.9,
    '137.5': 24.9,
    '138.5': 24.8,
    '139.5': 24.8,
    '140.5': 24.7,
    '141.5': 24.6,
    '142.5': 24.6,
    '143.5': 24.5,
    '144.5': 24.5,
    '145.5': 24.4,
    '146.5': 24.3,
    '147.5': 24.3,
    '148.5': 24.2,
    '149.5': 24.1,
    '150.5': 24.1,
    '151.5': 24,
    '152.5': 24,
    '153.5': 23.9,
    '154.5': 23.8,
    '155.5': 23.8,
    '156.5': 23.7,
    '157.5': 23.7,
    '158.5': 23.6,
    '159.5': 23.5,
    '160.5': 23.5,
    '161.5': 23.4,
    '162.5': 23.4,
    '163.5': 23.3,
    '164.5': 23.2,
    '165.5': 23.2,
    '166.5': 23.1,
    '167.5': 23.1,
    '168.5': 23,
    '169.5': 22.9,
    '170.5': 22.9,
    '171.5': 22.8,
    '172.5': 22.8,
    '173.5': 22.7,
    '174.5': 22.6,
    '175.5': 22.6,
    '176.5': 22.5,
    '177.5': 22.5,
    '178.5': 22.4,
    '179.5': 22.3,
    '180.5': 22.3,
    '181.5': 22.2,
    '182.5': 22.2,
    '183.5': 22.1,
    '184.5': 22.1,
    '185.5': 22,
    '186.5': 21.9,
    '187.5': 21.9,
    '188.5': 21.8,
    '189.5': 21.8,
    '190.5': 21.7,
    '191.5': 21.6,
    '192.5': 21.6,
    '193.5': 21.5,
    '194.5': 21.5,
    '195.5': 21.4,
    '196.5': 21.4,
    '197.5': 21.3,
    '198.5': 21.2,
    '199.5': 21.2,
    '200.5': 21.1,
    '201.5': 21.1,
    '202.5': 21,
    '203.5': 21,
    '204.5': 20.9,
    '205.5': 20.9,
    '206.5': 20.8,
    '207.5': 20.7,
    '208.5': 20.7,
    '209.5': 20.6,
  },
  // 30 - 35
  '30.5': {
    '0': 34.9,
    '1': 34.9,
    '2': 34.8,
    '3': 34.7,
    '4': 34.6,
    '5': 34.5,
    '6': 34.5,
    '7': 34.4,
    '8': 34.3,
    '9': 34.2,
    '10': 34.2,
    '11': 34.1,
    '12': 34,
    '13': 33.9,
    '14': 33.9,
    '15': 33.8,
    '16': 33.7,
    '17': 33.6,
    '18': 33.6,
    '19': 33.5,
    '20': 33.4,
    '21': 33.3,
    '22': 33.3,
    '23': 33.2,
    '24': 33.1,
    '25': 33,
    '26': 33,
    '27': 32.9,
    '28': 32.8,
    '29': 32.7,
    '30': 32.7,
    '31': 32.6,
    '32': 32.5,
    '33': 32.4,
    '34': 32.4,
    '35': 32.3,
    '36': 32.2,
    '37': 32.2,
    '38': 32.1,
    '39': 32,
    '40': 31.9,
    '41': 31.9,
    '42': 31.8,
    '43': 31.7,
    '44': 31.6,
    '45': 31.6,
    '46': 31.5,
    '47': 31.4,
    '48': 31.4,
    '49': 31.3,
    '50': 31.2,
    '51': 31.1,
    '52': 31.1,
    '53': 31,
    '54': 30.9,
    '55': 30.9,
    '56': 30.8,
    '57': 30.7,
    '58': 30.6,
    '59': 30.6,
    '60': 30.5,
    '61': 30.4,
    '62': 30.4,
    '63': 30.3,
    '64': 30.2,
    '65': 30.2,
    '66': 30.1,
    '67': 30,
    '68': 29.9,
    '69': 29.9,
    '70': 29.8,
    '71': 29.7,
    '72': 29.7,
    '73': 29.6,
    '74': 29.5,
    '75': 29.5,
    '76': 29.4,
    '77': 29.3,
    '78': 29.2,
    '79': 29.2,
    '80': 29.1,
    '81': 29,
    '82': 29,
    '83': 28.9,
    '84': 28.8,
    '85': 28.8,
    '86': 28.7,
    '87': 28.6,
    '88': 28.6,
    '89': 28.5,
    '90': 28.4,
    '91': 28.4,
    '92': 28.3,
    '93': 28.2,
    '94': 28.2,
    '95': 28.1,
    '96': 28,
    '97': 28,
    '98': 27.9,
    '99': 27.8,
    '100': 27.8,
    '101': 27.7,
    '102': 27.6,
    '103': 27.6,
    '104': 27.5,
    '105': 27.4,
    '106': 27.4,
    '107': 27.3,
    '108': 27.2,
    '109': 27.2,
    '110': 27.1,
    '111': 27,
    '112': 27,
    '113': 26.9,
    '114': 26.8,
    '115': 26.8,
    '116': 26.7,
    '117': 26.6,
    '118': 26.6,
    '119': 26.5,
    '120': 26.5,
    '121': 26.4,
    '122': 26.3,
    '123': 26.3,
    '124': 26.2,
    '125': 26.1,
    '126': 26.1,
    '127': 26,
    '128': 25.9,
    '129': 25.9,
    '130': 25.8,
    '131': 25.8,
    '132': 25.7,
    '133': 25.6,
    '134': 25.6,
    '135': 25.5,
    '136': 25.4,
    '137': 25.4,
    '138': 25.3,
    '139': 25.2,
    '140': 25.2,
    '141': 25.1,
    '142': 25.1,
    '143': 25,
    '144': 24.9,
    '145': 24.9,
    '146': 24.8,
    '147': 24.7,
    '148': 24.7,
    '149': 24.6,
    '150': 24.6,
    '151': 24.5,
    '152': 24.4,
    '153': 24.4,
    '154': 24.3,
    '155': 24.3,
    '156': 24.2,
    '157': 24.1,
    '158': 24.1,
    '159': 24,
    '160': 24,
    '161': 23.9,
    '162': 23.8,
    '163': 23.8,
    '164': 23.7,
    '165': 23.6,
    '166': 23.6,
    '167': 23.5,
    '168': 23.5,
    '169': 23.4,
    '170': 23.3,
    '171': 23.3,
    '172': 23.2,
    '173': 23.2,
    '174': 23.1,
    '175': 23,
    '176': 23,
    '177': 22.9,
    '178': 22.9,
    '179': 22.8,
    '180': 22.8,
    '181': 22.7,
    '182': 22.6,
    '183': 22.6,
    '184': 22.5,
    '185': 22.5,
    '186': 22.4,
    '187': 22.3,
    '188': 22.3,
    '189': 22.2,
    '190': 22.2,
    '191': 22.1,
    '192': 22,
    '193': 22,
    '194': 21.9,
    '195': 21.9,
    '0.5': 34.9,
    '1.5': 34.8,
    '2.5': 34.7,
    '3.5': 34.7,
    '4.5': 34.6,
    '5.5': 34.5,
    '6.5': 34.4,
    '7.5': 34.4,
    '8.5': 34.3,
    '9.5': 34.2,
    '10.5': 34.1,
    '11.5': 34,
    '12.5': 34,
    '13.5': 33.9,
    '14.5': 33.8,
    '15.5': 33.7,
    '16.5': 33.7,
    '17.5': 33.6,
    '18.5': 33.5,
    '19.5': 33.4,
    '20.5': 33.4,
    '21.5': 33.3,
    '22.5': 33.2,
    '23.5': 33.1,
    '24.5': 33.1,
    '25.5': 33,
    '26.5': 32.9,
    '27.5': 32.8,
    '28.5': 32.8,
    '29.5': 32.7,
    '30.5': 32.6,
    '31.5': 32.6,
    '32.5': 32.5,
    '33.5': 32.4,
    '34.5': 32.3,
    '35.5': 32.3,
    '36.5': 32.2,
    '37.5': 32.1,
    '38.5': 32,
    '39.5': 32,
    '40.5': 31.9,
    '41.5': 31.8,
    '42.5': 31.8,
    '43.5': 31.7,
    '44.5': 31.6,
    '45.5': 31.5,
    '46.5': 31.5,
    '47.5': 31.4,
    '48.5': 31.3,
    '49.5': 31.2,
    '50.5': 31.2,
    '51.5': 31.1,
    '52.5': 31,
    '53.5': 31,
    '54.5': 30.9,
    '55.5': 30.8,
    '56.5': 30.7,
    '57.5': 30.7,
    '58.5': 30.6,
    '59.5': 30.5,
    '60.5': 30.5,
    '61.5': 30.4,
    '62.5': 30.3,
    '63.5': 30.3,
    '64.5': 30.2,
    '65.5': 30.1,
    '66.5': 30,
    '67.5': 30,
    '68.5': 29.9,
    '69.5': 29.8,
    '70.5': 29.8,
    '71.5': 29.7,
    '72.5': 29.6,
    '73.5': 29.6,
    '74.5': 29.5,
    '75.5': 29.4,
    '76.5': 29.4,
    '77.5': 29.3,
    '78.5': 29.2,
    '79.5': 29.1,
    '80.5': 29.1,
    '81.5': 29,
    '82.5': 28.9,
    '83.5': 28.9,
    '84.5': 28.8,
    '85.5': 28.7,
    '86.5': 28.7,
    '87.5': 28.6,
    '88.5': 28.5,
    '89.5': 28.5,
    '90.5': 28.4,
    '91.5': 28.3,
    '92.5': 28.3,
    '93.5': 28.2,
    '94.5': 28.1,
    '95.5': 28.1,
    '96.5': 28,
    '97.5': 27.9,
    '98.5': 27.9,
    '99.5': 27.8,
    '100.5': 27.7,
    '101.5': 27.7,
    '102.5': 27.6,
    '103.5': 27.5,
    '104.5': 27.5,
    '105.5': 27.4,
    '106.5': 27.3,
    '107.5': 27.3,
    '108.5': 27.2,
    '109.5': 27.1,
    '110.5': 27.1,
    '111.5': 27,
    '112.5': 26.9,
    '113.5': 26.9,
    '114.5': 26.8,
    '115.5': 26.7,
    '116.5': 26.7,
    '117.5': 26.6,
    '118.5': 26.6,
    '119.5': 26.5,
    '120.5': 26.4,
    '121.5': 26.4,
    '122.5': 26.3,
    '123.5': 26.2,
    '124.5': 26.2,
    '125.5': 26.1,
    '126.5': 26,
    '127.5': 26,
    '128.5': 25.9,
    '129.5': 25.8,
    '130.5': 25.8,
    '131.5': 25.7,
    '132.5': 25.7,
    '133.5': 25.6,
    '134.5': 25.5,
    '135.5': 25.5,
    '136.5': 25.4,
    '137.5': 25.3,
    '138.5': 25.3,
    '139.5': 25.2,
    '140.5': 25.2,
    '141.5': 25.1,
    '142.5': 25,
    '143.5': 25,
    '144.5': 24.9,
    '145.5': 24.8,
    '146.5': 24.8,
    '147.5': 24.7,
    '148.5': 24.7,
    '149.5': 24.6,
    '150.5': 24.5,
    '151.5': 24.5,
    '152.5': 24.4,
    '153.5': 24.3,
    '154.5': 24.3,
    '155.5': 24.2,
    '156.5': 24.2,
    '157.5': 24.1,
    '158.5': 24,
    '159.5': 24,
    '160.5': 23.9,
    '161.5': 23.9,
    '162.5': 23.8,
    '163.5': 23.7,
    '164.5': 23.7,
    '165.5': 23.6,
    '166.5': 23.6,
    '167.5': 23.5,
    '168.5': 23.4,
    '169.5': 23.4,
    '170.5': 23.3,
    '171.5': 23.3,
    '172.5': 23.2,
    '173.5': 23.1,
    '174.5': 23.1,
    '175.5': 23,
    '176.5': 23,
    '177.5': 22.9,
    '178.5': 22.8,
    '179.5': 22.8,
    '180.5': 22.7,
    '181.5': 22.7,
    '182.5': 22.6,
    '183.5': 22.5,
    '184.5': 22.5,
    '185.5': 22.4,
    '186.5': 22.4,
    '187.5': 22.3,
    '188.5': 22.3,
    '189.5': 22.2,
    '190.5': 22.1,
    '191.5': 22.1,
    '192.5': 22,
    '193.5': 22,
    '194.5': 21.9,
  },
  '31': {
    '0': 35.5,
    '1': 35.4,
    '2': 35.3,
    '3': 35.2,
    '4': 35.2,
    '5': 35.1,
    '6': 35,
    '7': 34.9,
    '8': 34.9,
    '9': 34.8,
    '10': 34.7,
    '11': 34.6,
    '12': 34.5,
    '13': 34.5,
    '14': 34.4,
    '15': 34.3,
    '16': 34.2,
    '17': 34.2,
    '18': 34.1,
    '19': 34,
    '20': 33.9,
    '21': 33.9,
    '22': 33.8,
    '23': 33.7,
    '24': 33.6,
    '25': 33.6,
    '26': 33.5,
    '27': 33.4,
    '28': 33.3,
    '29': 33.3,
    '30': 33.2,
    '31': 33.1,
    '32': 33,
    '33': 33,
    '34': 32.9,
    '35': 32.8,
    '36': 32.7,
    '37': 32.7,
    '38': 32.6,
    '39': 32.5,
    '40': 32.4,
    '41': 32.4,
    '42': 32.3,
    '43': 32.2,
    '44': 32.2,
    '45': 32.1,
    '46': 32,
    '47': 31.9,
    '48': 31.9,
    '49': 31.8,
    '50': 31.7,
    '51': 31.6,
    '52': 31.6,
    '53': 31.5,
    '54': 31.4,
    '55': 31.4,
    '56': 31.3,
    '57': 31.2,
    '58': 31.1,
    '59': 31.1,
    '60': 31,
    '61': 30.9,
    '62': 30.9,
    '63': 30.8,
    '64': 30.7,
    '65': 30.6,
    '66': 30.6,
    '67': 30.5,
    '68': 30.4,
    '69': 30.4,
    '70': 30.3,
    '71': 30.2,
    '72': 30.2,
    '73': 30.1,
    '74': 30,
    '75': 29.9,
    '76': 29.9,
    '77': 29.8,
    '78': 29.7,
    '79': 29.7,
    '80': 29.6,
    '81': 29.5,
    '82': 29.5,
    '83': 29.4,
    '84': 29.3,
    '85': 29.3,
    '86': 29.2,
    '87': 29.1,
    '88': 29,
    '89': 29,
    '90': 28.9,
    '91': 28.8,
    '92': 28.8,
    '93': 28.7,
    '94': 28.6,
    '95': 28.6,
    '96': 28.5,
    '97': 28.4,
    '98': 28.4,
    '99': 28.3,
    '100': 28.2,
    '101': 28.2,
    '102': 28.1,
    '103': 28,
    '104': 28,
    '105': 27.9,
    '106': 27.8,
    '107': 27.8,
    '108': 27.7,
    '109': 27.6,
    '110': 27.6,
    '111': 27.5,
    '112': 27.4,
    '113': 27.4,
    '114': 27.3,
    '115': 27.2,
    '116': 27.2,
    '117': 27.1,
    '118': 27,
    '119': 27,
    '120': 26.9,
    '121': 26.9,
    '122': 26.8,
    '123': 26.7,
    '124': 26.7,
    '125': 26.6,
    '126': 26.5,
    '127': 26.5,
    '128': 26.4,
    '129': 26.3,
    '130': 26.3,
    '131': 26.2,
    '132': 26.1,
    '133': 26.1,
    '134': 26,
    '135': 26,
    '136': 25.9,
    '137': 25.8,
    '138': 25.8,
    '139': 25.7,
    '140': 25.6,
    '141': 25.6,
    '142': 25.5,
    '143': 25.4,
    '144': 25.4,
    '145': 25.3,
    '146': 25.3,
    '147': 25.2,
    '148': 25.1,
    '149': 25.1,
    '150': 25,
    '151': 24.9,
    '152': 24.9,
    '153': 24.8,
    '154': 24.8,
    '155': 24.7,
    '156': 24.6,
    '157': 24.6,
    '158': 24.5,
    '159': 24.5,
    '160': 24.4,
    '161': 24.3,
    '162': 24.3,
    '163': 24.2,
    '164': 24.1,
    '165': 24.1,
    '166': 24,
    '167': 24,
    '168': 23.9,
    '169': 23.8,
    '170': 23.8,
    '171': 23.7,
    '172': 23.7,
    '173': 23.6,
    '174': 23.5,
    '175': 23.5,
    '176': 23.4,
    '177': 23.4,
    '178': 23.3,
    '179': 23.2,
    '180': 23.2,
    '181': 23.1,
    '182': 23.1,
    '183': 23,
    '184': 22.9,
    '185': 22.9,
    '186': 22.8,
    '187': 22.8,
    '188': 22.7,
    '189': 22.6,
    '190': 22.6,
    '191': 22.5,
    '192': 22.5,
    '193': 22.4,
    '194': 22.4,
    '195': 22.3,
    '0.5': 35.4,
    '1.5': 35.3,
    '2.5': 35.3,
    '3.5': 35.2,
    '4.5': 35.1,
    '5.5': 35,
    '6.5': 35,
    '7.5': 34.9,
    '8.5': 34.8,
    '9.5': 34.7,
    '10.5': 34.7,
    '11.5': 34.6,
    '12.5': 34.5,
    '13.5': 34.4,
    '14.5': 34.4,
    '15.5': 34.3,
    '16.5': 34.2,
    '17.5': 34.1,
    '18.5': 34.1,
    '19.5': 34,
    '20.5': 33.9,
    '21.5': 33.8,
    '22.5': 33.7,
    '23.5': 33.7,
    '24.5': 33.6,
    '25.5': 33.5,
    '26.5': 33.4,
    '27.5': 33.4,
    '28.5': 33.3,
    '29.5': 33.2,
    '30.5': 33.1,
    '31.5': 33.1,
    '32.5': 33,
    '33.5': 32.9,
    '34.5': 32.9,
    '35.5': 32.8,
    '36.5': 32.7,
    '37.5': 32.6,
    '38.5': 32.6,
    '39.5': 32.5,
    '40.5': 32.4,
    '41.5': 32.3,
    '42.5': 32.3,
    '43.5': 32.2,
    '44.5': 32.1,
    '45.5': 32,
    '46.5': 32,
    '47.5': 31.9,
    '48.5': 31.8,
    '49.5': 31.8,
    '50.5': 31.7,
    '51.5': 31.6,
    '52.5': 31.5,
    '53.5': 31.5,
    '54.5': 31.4,
    '55.5': 31.3,
    '56.5': 31.2,
    '57.5': 31.2,
    '58.5': 31.1,
    '59.5': 31,
    '60.5': 31,
    '61.5': 30.9,
    '62.5': 30.8,
    '63.5': 30.8,
    '64.5': 30.7,
    '65.5': 30.6,
    '66.5': 30.5,
    '67.5': 30.5,
    '68.5': 30.4,
    '69.5': 30.3,
    '70.5': 30.3,
    '71.5': 30.2,
    '72.5': 30.1,
    '73.5': 30,
    '74.5': 30,
    '75.5': 29.9,
    '76.5': 29.8,
    '77.5': 29.8,
    '78.5': 29.7,
    '79.5': 29.6,
    '80.5': 29.6,
    '81.5': 29.5,
    '82.5': 29.4,
    '83.5': 29.4,
    '84.5': 29.3,
    '85.5': 29.2,
    '86.5': 29.2,
    '87.5': 29.1,
    '88.5': 29,
    '89.5': 28.9,
    '90.5': 28.9,
    '91.5': 28.8,
    '92.5': 28.7,
    '93.5': 28.7,
    '94.5': 28.6,
    '95.5': 28.5,
    '96.5': 28.5,
    '97.5': 28.4,
    '98.5': 28.3,
    '99.5': 28.3,
    '100.5': 28.2,
    '101.5': 28.1,
    '102.5': 28.1,
    '103.5': 28,
    '104.5': 27.9,
    '105.5': 27.9,
    '106.5': 27.8,
    '107.5': 27.7,
    '108.5': 27.7,
    '109.5': 27.6,
    '110.5': 27.5,
    '111.5': 27.5,
    '112.5': 27.4,
    '113.5': 27.3,
    '114.5': 27.3,
    '115.5': 27.2,
    '116.5': 27.1,
    '117.5': 27.1,
    '118.5': 27,
    '119.5': 27,
    '120.5': 26.9,
    '121.5': 26.8,
    '122.5': 26.8,
    '123.5': 26.7,
    '124.5': 26.6,
    '125.5': 26.6,
    '126.5': 26.5,
    '127.5': 26.4,
    '128.5': 26.4,
    '129.5': 26.3,
    '130.5': 26.2,
    '131.5': 26.2,
    '132.5': 26.1,
    '133.5': 26,
    '134.5': 26,
    '135.5': 25.9,
    '136.5': 25.9,
    '137.5': 25.8,
    '138.5': 25.7,
    '139.5': 25.7,
    '140.5': 25.6,
    '141.5': 25.5,
    '142.5': 25.5,
    '143.5': 25.4,
    '144.5': 25.4,
    '145.5': 25.3,
    '146.5': 25.2,
    '147.5': 25.2,
    '148.5': 25.1,
    '149.5': 25,
    '150.5': 25,
    '151.5': 24.9,
    '152.5': 24.9,
    '153.5': 24.8,
    '154.5': 24.7,
    '155.5': 24.7,
    '156.5': 24.6,
    '157.5': 24.5,
    '158.5': 24.5,
    '159.5': 24.4,
    '160.5': 24.4,
    '161.5': 24.3,
    '162.5': 24.2,
    '163.5': 24.2,
    '164.5': 24.1,
    '165.5': 24.1,
    '166.5': 24,
    '167.5': 23.9,
    '168.5': 23.9,
    '169.5': 23.8,
    '170.5': 23.8,
    '171.5': 23.7,
    '172.5': 23.6,
    '173.5': 23.6,
    '174.5': 23.5,
    '175.5': 23.5,
    '176.5': 23.4,
    '177.5': 23.3,
    '178.5': 23.3,
    '179.5': 23.2,
    '180.5': 23.2,
    '181.5': 23.1,
    '182.5': 23,
    '183.5': 23,
    '184.5': 22.9,
    '185.5': 22.9,
    '186.5': 22.8,
    '187.5': 22.7,
    '188.5': 22.7,
    '189.5': 22.6,
    '190.5': 22.6,
    '191.5': 22.5,
    '192.5': 22.4,
    '193.5': 22.4,
    '194.5': 22.3,
  },
  '31.5': {
    '0': 36,
    '1': 35.9,
    '2': 35.9,
    '3': 35.8,
    '4': 35.7,
    '5': 35.6,
    '6': 35.5,
    '7': 35.5,
    '8': 35.4,
    '9': 35.3,
    '10': 35.2,
    '11': 35.2,
    '12': 35.1,
    '13': 35,
    '14': 34.9,
    '15': 34.8,
    '16': 34.8,
    '17': 34.7,
    '18': 34.6,
    '19': 34.5,
    '20': 34.5,
    '21': 34.4,
    '22': 34.3,
    '23': 34.2,
    '24': 34.2,
    '25': 34.1,
    '26': 34,
    '27': 33.9,
    '28': 33.9,
    '29': 33.8,
    '30': 33.7,
    '31': 33.6,
    '32': 33.6,
    '33': 33.5,
    '34': 33.4,
    '35': 33.3,
    '36': 33.3,
    '37': 33.2,
    '38': 33.1,
    '39': 33,
    '40': 33,
    '41': 32.9,
    '42': 32.8,
    '43': 32.7,
    '44': 32.7,
    '45': 32.6,
    '46': 32.5,
    '47': 32.4,
    '48': 32.4,
    '49': 32.3,
    '50': 32.2,
    '51': 32.2,
    '52': 32.1,
    '53': 32,
    '54': 31.9,
    '55': 31.9,
    '56': 31.8,
    '57': 31.7,
    '58': 31.6,
    '59': 31.6,
    '60': 31.5,
    '61': 31.4,
    '62': 31.4,
    '63': 31.3,
    '64': 31.2,
    '65': 31.1,
    '66': 31.1,
    '67': 31,
    '68': 30.9,
    '69': 30.9,
    '70': 30.8,
    '71': 30.7,
    '72': 30.6,
    '73': 30.6,
    '74': 30.5,
    '75': 30.4,
    '76': 30.4,
    '77': 30.3,
    '78': 30.2,
    '79': 30.2,
    '80': 30.1,
    '81': 30,
    '82': 29.9,
    '83': 29.9,
    '84': 29.8,
    '85': 29.7,
    '86': 29.7,
    '87': 29.6,
    '88': 29.5,
    '89': 29.5,
    '90': 29.4,
    '91': 29.3,
    '92': 29.3,
    '93': 29.2,
    '94': 29.1,
    '95': 29.1,
    '96': 29,
    '97': 28.9,
    '98': 28.8,
    '99': 28.8,
    '100': 28.7,
    '101': 28.6,
    '102': 28.6,
    '103': 28.5,
    '104': 28.4,
    '105': 28.4,
    '106': 28.3,
    '107': 28.2,
    '108': 28.2,
    '109': 28.1,
    '110': 28,
    '111': 28,
    '112': 27.9,
    '113': 27.8,
    '114': 27.8,
    '115': 27.7,
    '116': 27.6,
    '117': 27.6,
    '118': 27.5,
    '119': 27.4,
    '120': 27.4,
    '121': 27.3,
    '122': 27.2,
    '123': 27.2,
    '124': 27.1,
    '125': 27.1,
    '126': 27,
    '127': 26.9,
    '128': 26.9,
    '129': 26.8,
    '130': 26.7,
    '131': 26.7,
    '132': 26.6,
    '133': 26.5,
    '134': 26.5,
    '135': 26.4,
    '136': 26.3,
    '137': 26.3,
    '138': 26.2,
    '139': 26.2,
    '140': 26.1,
    '141': 26,
    '142': 26,
    '143': 25.9,
    '144': 25.8,
    '145': 25.8,
    '146': 25.7,
    '147': 25.6,
    '148': 25.6,
    '149': 25.5,
    '150': 25.5,
    '151': 25.4,
    '152': 25.3,
    '153': 25.3,
    '154': 25.2,
    '155': 25.1,
    '156': 25.1,
    '157': 25,
    '158': 25,
    '159': 24.9,
    '160': 24.8,
    '161': 24.8,
    '162': 24.7,
    '163': 24.6,
    '164': 24.6,
    '165': 24.5,
    '166': 24.5,
    '167': 24.4,
    '168': 24.3,
    '169': 24.3,
    '170': 24.2,
    '171': 24.2,
    '172': 24.1,
    '173': 24,
    '174': 24,
    '175': 23.9,
    '176': 23.9,
    '177': 23.8,
    '178': 23.7,
    '179': 23.7,
    '180': 23.6,
    '181': 23.6,
    '182': 23.5,
    '183': 23.4,
    '184': 23.4,
    '185': 23.3,
    '186': 23.3,
    '187': 23.2,
    '188': 23.1,
    '189': 23.1,
    '190': 23,
    '191': 23,
    '192': 22.9,
    '193': 22.8,
    '194': 22.8,
    '195': 22.7,
    '0.5': 36,
    '1.5': 35.9,
    '2.5': 35.8,
    '3.5': 35.7,
    '4.5': 35.7,
    '5.5': 35.6,
    '6.5': 35.5,
    '7.5': 35.4,
    '8.5': 35.3,
    '9.5': 35.3,
    '10.5': 35.2,
    '11.5': 35.1,
    '12.5': 35,
    '13.5': 35,
    '14.5': 34.9,
    '15.5': 34.8,
    '16.5': 34.7,
    '17.5': 34.7,
    '18.5': 34.6,
    '19.5': 34.5,
    '20.5': 34.4,
    '21.5': 34.3,
    '22.5': 34.3,
    '23.5': 34.2,
    '24.5': 34.1,
    '25.5': 34,
    '26.5': 34,
    '27.5': 33.9,
    '28.5': 33.8,
    '29.5': 33.7,
    '30.5': 33.7,
    '31.5': 33.6,
    '32.5': 33.5,
    '33.5': 33.4,
    '34.5': 33.4,
    '35.5': 33.3,
    '36.5': 33.2,
    '37.5': 33.1,
    '38.5': 33.1,
    '39.5': 33,
    '40.5': 32.9,
    '41.5': 32.8,
    '42.5': 32.8,
    '43.5': 32.7,
    '44.5': 32.6,
    '45.5': 32.6,
    '46.5': 32.5,
    '47.5': 32.4,
    '48.5': 32.3,
    '49.5': 32.3,
    '50.5': 32.2,
    '51.5': 32.1,
    '52.5': 32,
    '53.5': 32,
    '54.5': 31.9,
    '55.5': 31.8,
    '56.5': 31.8,
    '57.5': 31.7,
    '58.5': 31.6,
    '59.5': 31.5,
    '60.5': 31.5,
    '61.5': 31.4,
    '62.5': 31.3,
    '63.5': 31.3,
    '64.5': 31.2,
    '65.5': 31.1,
    '66.5': 31,
    '67.5': 31,
    '68.5': 30.9,
    '69.5': 30.8,
    '70.5': 30.8,
    '71.5': 30.7,
    '72.5': 30.6,
    '73.5': 30.5,
    '74.5': 30.5,
    '75.5': 30.4,
    '76.5': 30.3,
    '77.5': 30.3,
    '78.5': 30.2,
    '79.5': 30.1,
    '80.5': 30.1,
    '81.5': 30,
    '82.5': 29.9,
    '83.5': 29.8,
    '84.5': 29.8,
    '85.5': 29.7,
    '86.5': 29.6,
    '87.5': 29.6,
    '88.5': 29.5,
    '89.5': 29.4,
    '90.5': 29.4,
    '91.5': 29.3,
    '92.5': 29.2,
    '93.5': 29.2,
    '94.5': 29.1,
    '95.5': 29,
    '96.5': 28.9,
    '97.5': 28.9,
    '98.5': 28.8,
    '99.5': 28.7,
    '100.5': 28.7,
    '101.5': 28.6,
    '102.5': 28.5,
    '103.5': 28.5,
    '104.5': 28.4,
    '105.5': 28.3,
    '106.5': 28.3,
    '107.5': 28.2,
    '108.5': 28.1,
    '109.5': 28.1,
    '110.5': 28,
    '111.5': 27.9,
    '112.5': 27.9,
    '113.5': 27.8,
    '114.5': 27.7,
    '115.5': 27.7,
    '116.5': 27.6,
    '117.5': 27.5,
    '118.5': 27.5,
    '119.5': 27.4,
    '120.5': 27.3,
    '121.5': 27.3,
    '122.5': 27.2,
    '123.5': 27.2,
    '124.5': 27.1,
    '125.5': 27,
    '126.5': 27,
    '127.5': 26.9,
    '128.5': 26.8,
    '129.5': 26.8,
    '130.5': 26.7,
    '131.5': 26.6,
    '132.5': 26.6,
    '133.5': 26.5,
    '134.5': 26.4,
    '135.5': 26.4,
    '136.5': 26.3,
    '137.5': 26.2,
    '138.5': 26.2,
    '139.5': 26.1,
    '140.5': 26.1,
    '141.5': 26,
    '142.5': 25.9,
    '143.5': 25.9,
    '144.5': 25.8,
    '145.5': 25.7,
    '146.5': 25.7,
    '147.5': 25.6,
    '148.5': 25.5,
    '149.5': 25.5,
    '150.5': 25.4,
    '151.5': 25.4,
    '152.5': 25.3,
    '153.5': 25.2,
    '154.5': 25.2,
    '155.5': 25.1,
    '156.5': 25.1,
    '157.5': 25,
    '158.5': 24.9,
    '159.5': 24.9,
    '160.5': 24.8,
    '161.5': 24.7,
    '162.5': 24.7,
    '163.5': 24.6,
    '164.5': 24.6,
    '165.5': 24.5,
    '166.5': 24.4,
    '167.5': 24.4,
    '168.5': 24.3,
    '169.5': 24.2,
    '170.5': 24.2,
    '171.5': 24.1,
    '172.5': 24.1,
    '173.5': 24,
    '174.5': 23.9,
    '175.5': 23.9,
    '176.5': 23.8,
    '177.5': 23.8,
    '178.5': 23.7,
    '179.5': 23.6,
    '180.5': 23.6,
    '181.5': 23.5,
    '182.5': 23.5,
    '183.5': 23.4,
    '184.5': 23.3,
    '185.5': 23.3,
    '186.5': 23.2,
    '187.5': 23.2,
    '188.5': 23.1,
    '189.5': 23,
    '190.5': 23,
    '191.5': 22.9,
    '192.5': 22.9,
    '193.5': 22.8,
    '194.5': 22.8,
  },
  '32': {
    '0': 36.6,
    '1': 36.5,
    '2': 36.4,
    '3': 36.3,
    '4': 36.2,
    '5': 36.2,
    '6': 36.1,
    '7': 36,
    '8': 35.9,
    '9': 35.8,
    '10': 35.8,
    '11': 35.7,
    '12': 35.6,
    '13': 35.5,
    '14': 35.5,
    '15': 35.4,
    '16': 35.3,
    '17': 35.2,
    '18': 35.1,
    '19': 35.1,
    '20': 35,
    '21': 34.9,
    '22': 34.8,
    '23': 34.8,
    '24': 34.7,
    '25': 34.6,
    '26': 34.5,
    '27': 34.5,
    '28': 34.4,
    '29': 34.3,
    '30': 34.2,
    '31': 34.1,
    '32': 34.1,
    '33': 34,
    '34': 33.9,
    '35': 33.8,
    '36': 33.8,
    '37': 33.7,
    '38': 33.6,
    '39': 33.5,
    '40': 33.5,
    '41': 33.4,
    '42': 33.3,
    '43': 33.2,
    '44': 33.2,
    '45': 33.1,
    '46': 33,
    '47': 33,
    '48': 32.9,
    '49': 32.8,
    '50': 32.7,
    '51': 32.7,
    '52': 32.6,
    '53': 32.5,
    '54': 32.4,
    '55': 32.4,
    '56': 32.3,
    '57': 32.2,
    '58': 32.1,
    '59': 32.1,
    '60': 32,
    '61': 31.9,
    '62': 31.9,
    '63': 31.8,
    '64': 31.7,
    '65': 31.6,
    '66': 31.6,
    '67': 31.5,
    '68': 31.4,
    '69': 31.4,
    '70': 31.3,
    '71': 31.2,
    '72': 31.1,
    '73': 31.1,
    '74': 31,
    '75': 30.9,
    '76': 30.9,
    '77': 30.8,
    '78': 30.7,
    '79': 30.6,
    '80': 30.6,
    '81': 30.5,
    '82': 30.4,
    '83': 30.4,
    '84': 30.3,
    '85': 30.2,
    '86': 30.2,
    '87': 30.1,
    '88': 30,
    '89': 29.9,
    '90': 29.9,
    '91': 29.8,
    '92': 29.7,
    '93': 29.7,
    '94': 29.6,
    '95': 29.5,
    '96': 29.5,
    '97': 29.4,
    '98': 29.3,
    '99': 29.3,
    '100': 29.2,
    '101': 29.1,
    '102': 29,
    '103': 29,
    '104': 28.9,
    '105': 28.8,
    '106': 28.8,
    '107': 28.7,
    '108': 28.6,
    '109': 28.6,
    '110': 28.5,
    '111': 28.4,
    '112': 28.4,
    '113': 28.3,
    '114': 28.2,
    '115': 28.2,
    '116': 28.1,
    '117': 28,
    '118': 28,
    '119': 27.9,
    '120': 27.8,
    '121': 27.8,
    '122': 27.7,
    '123': 27.6,
    '124': 27.6,
    '125': 27.5,
    '126': 27.4,
    '127': 27.4,
    '128': 27.3,
    '129': 27.3,
    '130': 27.2,
    '131': 27.1,
    '132': 27.1,
    '133': 27,
    '134': 26.9,
    '135': 26.9,
    '136': 26.8,
    '137': 26.7,
    '138': 26.7,
    '139': 26.6,
    '140': 26.5,
    '141': 26.5,
    '142': 26.4,
    '143': 26.3,
    '144': 26.3,
    '145': 26.2,
    '146': 26.2,
    '147': 26.1,
    '148': 26,
    '149': 26,
    '150': 25.9,
    '151': 25.8,
    '152': 25.8,
    '153': 25.7,
    '154': 25.6,
    '155': 25.6,
    '156': 25.5,
    '157': 25.5,
    '158': 25.4,
    '159': 25.3,
    '160': 25.3,
    '161': 25.2,
    '162': 25.1,
    '163': 25.1,
    '164': 25,
    '165': 25,
    '166': 24.9,
    '167': 24.8,
    '168': 24.8,
    '169': 24.7,
    '170': 24.7,
    '171': 24.6,
    '172': 24.5,
    '173': 24.5,
    '174': 24.4,
    '175': 24.3,
    '176': 24.3,
    '177': 24.2,
    '178': 24.2,
    '179': 24.1,
    '180': 24,
    '181': 24,
    '182': 23.9,
    '183': 23.9,
    '184': 23.8,
    '185': 23.7,
    '186': 23.7,
    '187': 23.6,
    '188': 23.6,
    '189': 23.5,
    '190': 23.4,
    '191': 23.4,
    '192': 23.3,
    '193': 23.3,
    '194': 23.2,
    '195': 23.1,
    '0.5': 36.5,
    '1.5': 36.4,
    '2.5': 36.4,
    '3.5': 36.3,
    '4.5': 36.2,
    '5.5': 36.1,
    '6.5': 36,
    '7.5': 36,
    '8.5': 35.9,
    '9.5': 35.8,
    '10.5': 35.7,
    '11.5': 35.7,
    '12.5': 35.6,
    '13.5': 35.5,
    '14.5': 35.4,
    '15.5': 35.3,
    '16.5': 35.3,
    '17.5': 35.2,
    '18.5': 35.1,
    '19.5': 35,
    '20.5': 35,
    '21.5': 34.9,
    '22.5': 34.8,
    '23.5': 34.7,
    '24.5': 34.6,
    '25.5': 34.6,
    '26.5': 34.5,
    '27.5': 34.4,
    '28.5': 34.3,
    '29.5': 34.3,
    '30.5': 34.2,
    '31.5': 34.1,
    '32.5': 34,
    '33.5': 34,
    '34.5': 33.9,
    '35.5': 33.8,
    '36.5': 33.7,
    '37.5': 33.7,
    '38.5': 33.6,
    '39.5': 33.5,
    '40.5': 33.4,
    '41.5': 33.4,
    '42.5': 33.3,
    '43.5': 33.2,
    '44.5': 33.1,
    '45.5': 33.1,
    '46.5': 33,
    '47.5': 32.9,
    '48.5': 32.8,
    '49.5': 32.8,
    '50.5': 32.7,
    '51.5': 32.6,
    '52.5': 32.5,
    '53.5': 32.5,
    '54.5': 32.4,
    '55.5': 32.3,
    '56.5': 32.3,
    '57.5': 32.2,
    '58.5': 32.1,
    '59.5': 32,
    '60.5': 32,
    '61.5': 31.9,
    '62.5': 31.8,
    '63.5': 31.7,
    '64.5': 31.7,
    '65.5': 31.6,
    '66.5': 31.5,
    '67.5': 31.5,
    '68.5': 31.4,
    '69.5': 31.3,
    '70.5': 31.2,
    '71.5': 31.2,
    '72.5': 31.1,
    '73.5': 31,
    '74.5': 31,
    '75.5': 30.9,
    '76.5': 30.8,
    '77.5': 30.7,
    '78.5': 30.7,
    '79.5': 30.6,
    '80.5': 30.5,
    '81.5': 30.5,
    '82.5': 30.4,
    '83.5': 30.3,
    '84.5': 30.3,
    '85.5': 30.2,
    '86.5': 30.1,
    '87.5': 30,
    '88.5': 30,
    '89.5': 29.9,
    '90.5': 29.8,
    '91.5': 29.8,
    '92.5': 29.7,
    '93.5': 29.6,
    '94.5': 29.6,
    '95.5': 29.5,
    '96.5': 29.4,
    '97.5': 29.4,
    '98.5': 29.3,
    '99.5': 29.2,
    '100.5': 29.2,
    '101.5': 29.1,
    '102.5': 29,
    '103.5': 28.9,
    '104.5': 28.9,
    '105.5': 28.8,
    '106.5': 28.7,
    '107.5': 28.7,
    '108.5': 28.6,
    '109.5': 28.5,
    '110.5': 28.5,
    '111.5': 28.4,
    '112.5': 28.3,
    '113.5': 28.3,
    '114.5': 28.2,
    '115.5': 28.1,
    '116.5': 28.1,
    '117.5': 28,
    '118.5': 27.9,
    '119.5': 27.9,
    '120.5': 27.8,
    '121.5': 27.7,
    '122.5': 27.7,
    '123.5': 27.6,
    '124.5': 27.5,
    '125.5': 27.5,
    '126.5': 27.4,
    '127.5': 27.3,
    '128.5': 27.3,
    '129.5': 27.2,
    '130.5': 27.2,
    '131.5': 27.1,
    '132.5': 27,
    '133.5': 27,
    '134.5': 26.9,
    '135.5': 26.8,
    '136.5': 26.8,
    '137.5': 26.7,
    '138.5': 26.6,
    '139.5': 26.6,
    '140.5': 26.5,
    '141.5': 26.4,
    '142.5': 26.4,
    '143.5': 26.3,
    '144.5': 26.2,
    '145.5': 26.2,
    '146.5': 26.1,
    '147.5': 26.1,
    '148.5': 26,
    '149.5': 25.9,
    '150.5': 25.9,
    '151.5': 25.8,
    '152.5': 25.7,
    '153.5': 25.7,
    '154.5': 25.6,
    '155.5': 25.6,
    '156.5': 25.5,
    '157.5': 25.4,
    '158.5': 25.4,
    '159.5': 25.3,
    '160.5': 25.2,
    '161.5': 25.2,
    '162.5': 25.1,
    '163.5': 25.1,
    '164.5': 25,
    '165.5': 24.9,
    '166.5': 24.9,
    '167.5': 24.8,
    '168.5': 24.7,
    '169.5': 24.7,
    '170.5': 24.6,
    '171.5': 24.6,
    '172.5': 24.5,
    '173.5': 24.4,
    '174.5': 24.4,
    '175.5': 24.3,
    '176.5': 24.3,
    '177.5': 24.2,
    '178.5': 24.1,
    '179.5': 24.1,
    '180.5': 24,
    '181.5': 24,
    '182.5': 23.9,
    '183.5': 23.8,
    '184.5': 23.8,
    '185.5': 23.7,
    '186.5': 23.6,
    '187.5': 23.6,
    '188.5': 23.5,
    '189.5': 23.5,
    '190.5': 23.4,
    '191.5': 23.4,
    '192.5': 23.3,
    '193.5': 23.2,
    '194.5': 23.2,
  },
  '32.5': {
    '0': 37.1,
    '1': 37,
    '2': 36.9,
    '3': 36.9,
    '4': 36.8,
    '5': 36.7,
    '6': 36.6,
    '7': 36.5,
    '8': 36.5,
    '9': 36.4,
    '10': 36.3,
    '11': 36.2,
    '12': 36.1,
    '13': 36.1,
    '14': 36,
    '15': 35.9,
    '16': 35.8,
    '17': 35.8,
    '18': 35.7,
    '19': 35.6,
    '20': 35.5,
    '21': 35.4,
    '22': 35.4,
    '23': 35.3,
    '24': 35.2,
    '25': 35.1,
    '26': 35.1,
    '27': 35,
    '28': 34.9,
    '29': 34.8,
    '30': 34.7,
    '31': 34.7,
    '32': 34.6,
    '33': 34.5,
    '34': 34.4,
    '35': 34.4,
    '36': 34.3,
    '37': 34.2,
    '38': 34.1,
    '39': 34.1,
    '40': 34,
    '41': 33.9,
    '42': 33.8,
    '43': 33.8,
    '44': 33.7,
    '45': 33.6,
    '46': 33.5,
    '47': 33.5,
    '48': 33.4,
    '49': 33.3,
    '50': 33.2,
    '51': 33.2,
    '52': 33.1,
    '53': 33,
    '54': 32.9,
    '55': 32.9,
    '56': 32.8,
    '57': 32.7,
    '58': 32.6,
    '59': 32.6,
    '60': 32.5,
    '61': 32.4,
    '62': 32.4,
    '63': 32.3,
    '64': 32.2,
    '65': 32.1,
    '66': 32.1,
    '67': 32,
    '68': 31.9,
    '69': 31.8,
    '70': 31.8,
    '71': 31.7,
    '72': 31.6,
    '73': 31.6,
    '74': 31.5,
    '75': 31.4,
    '76': 31.3,
    '77': 31.3,
    '78': 31.2,
    '79': 31.1,
    '80': 31.1,
    '81': 31,
    '82': 30.9,
    '83': 30.8,
    '84': 30.8,
    '85': 30.7,
    '86': 30.6,
    '87': 30.6,
    '88': 30.5,
    '89': 30.4,
    '90': 30.4,
    '91': 30.3,
    '92': 30.2,
    '93': 30.1,
    '94': 30.1,
    '95': 30,
    '96': 29.9,
    '97': 29.9,
    '98': 29.8,
    '99': 29.7,
    '100': 29.7,
    '101': 29.6,
    '102': 29.5,
    '103': 29.5,
    '104': 29.4,
    '105': 29.3,
    '106': 29.2,
    '107': 29.2,
    '108': 29.1,
    '109': 29,
    '110': 29,
    '111': 28.9,
    '112': 28.8,
    '113': 28.8,
    '114': 28.7,
    '115': 28.6,
    '116': 28.6,
    '117': 28.5,
    '118': 28.4,
    '119': 28.4,
    '120': 28.3,
    '121': 28.2,
    '122': 28.2,
    '123': 28.1,
    '124': 28,
    '125': 28,
    '126': 27.9,
    '127': 27.8,
    '128': 27.8,
    '129': 27.7,
    '130': 27.6,
    '131': 27.6,
    '132': 27.5,
    '133': 27.4,
    '134': 27.4,
    '135': 27.3,
    '136': 27.2,
    '137': 27.2,
    '138': 27.1,
    '139': 27.1,
    '140': 27,
    '141': 26.9,
    '142': 26.9,
    '143': 26.8,
    '144': 26.7,
    '145': 26.7,
    '146': 26.6,
    '147': 26.5,
    '148': 26.5,
    '149': 26.4,
    '150': 26.3,
    '151': 26.3,
    '152': 26.2,
    '153': 26.2,
    '154': 26.1,
    '155': 26,
    '156': 26,
    '157': 25.9,
    '158': 25.8,
    '159': 25.8,
    '160': 25.7,
    '161': 25.7,
    '162': 25.6,
    '163': 25.5,
    '164': 25.5,
    '165': 25.4,
    '166': 25.3,
    '167': 25.3,
    '168': 25.2,
    '169': 25.1,
    '170': 25.1,
    '171': 25,
    '172': 25,
    '173': 24.9,
    '174': 24.8,
    '175': 24.8,
    '176': 24.7,
    '177': 24.7,
    '178': 24.6,
    '179': 24.5,
    '180': 24.5,
    '181': 24.4,
    '182': 24.3,
    '183': 24.3,
    '184': 24.2,
    '185': 24.2,
    '186': 24.1,
    '187': 24,
    '188': 24,
    '189': 23.9,
    '190': 23.9,
    '191': 23.8,
    '192': 23.7,
    '193': 23.7,
    '194': 23.6,
    '195': 23.6,
    '0.5': 37.1,
    '1.5': 37,
    '2.5': 36.9,
    '3.5': 36.8,
    '4.5': 36.7,
    '5.5': 36.7,
    '6.5': 36.6,
    '7.5': 36.5,
    '8.5': 36.4,
    '9.5': 36.3,
    '10.5': 36.3,
    '11.5': 36.2,
    '12.5': 36.1,
    '13.5': 36,
    '14.5': 36,
    '15.5': 35.9,
    '16.5': 35.8,
    '17.5': 35.7,
    '18.5': 35.6,
    '19.5': 35.6,
    '20.5': 35.5,
    '21.5': 35.4,
    '22.5': 35.3,
    '23.5': 35.2,
    '24.5': 35.2,
    '25.5': 35.1,
    '26.5': 35,
    '27.5': 34.9,
    '28.5': 34.9,
    '29.5': 34.8,
    '30.5': 34.7,
    '31.5': 34.6,
    '32.5': 34.6,
    '33.5': 34.5,
    '34.5': 34.4,
    '35.5': 34.3,
    '36.5': 34.3,
    '37.5': 34.2,
    '38.5': 34.1,
    '39.5': 34,
    '40.5': 33.9,
    '41.5': 33.9,
    '42.5': 33.8,
    '43.5': 33.7,
    '44.5': 33.6,
    '45.5': 33.6,
    '46.5': 33.5,
    '47.5': 33.4,
    '48.5': 33.3,
    '49.5': 33.3,
    '50.5': 33.2,
    '51.5': 33.1,
    '52.5': 33.1,
    '53.5': 33,
    '54.5': 32.9,
    '55.5': 32.8,
    '56.5': 32.8,
    '57.5': 32.7,
    '58.5': 32.6,
    '59.5': 32.5,
    '60.5': 32.5,
    '61.5': 32.4,
    '62.5': 32.3,
    '63.5': 32.2,
    '64.5': 32.2,
    '65.5': 32.1,
    '66.5': 32,
    '67.5': 32,
    '68.5': 31.9,
    '69.5': 31.8,
    '70.5': 31.7,
    '71.5': 31.7,
    '72.5': 31.6,
    '73.5': 31.5,
    '74.5': 31.5,
    '75.5': 31.4,
    '76.5': 31.3,
    '77.5': 31.2,
    '78.5': 31.2,
    '79.5': 31.1,
    '80.5': 31,
    '81.5': 31,
    '82.5': 30.9,
    '83.5': 30.8,
    '84.5': 30.7,
    '85.5': 30.7,
    '86.5': 30.6,
    '87.5': 30.5,
    '88.5': 30.5,
    '89.5': 30.4,
    '90.5': 30.3,
    '91.5': 30.3,
    '92.5': 30.2,
    '93.5': 30.1,
    '94.5': 30,
    '95.5': 30,
    '96.5': 29.9,
    '97.5': 29.8,
    '98.5': 29.8,
    '99.5': 29.7,
    '100.5': 29.6,
    '101.5': 29.6,
    '102.5': 29.5,
    '103.5': 29.4,
    '104.5': 29.4,
    '105.5': 29.3,
    '106.5': 29.2,
    '107.5': 29.1,
    '108.5': 29.1,
    '109.5': 29,
    '110.5': 28.9,
    '111.5': 28.9,
    '112.5': 28.8,
    '113.5': 28.7,
    '114.5': 28.7,
    '115.5': 28.6,
    '116.5': 28.5,
    '117.5': 28.5,
    '118.5': 28.4,
    '119.5': 28.3,
    '120.5': 28.3,
    '121.5': 28.2,
    '122.5': 28.1,
    '123.5': 28.1,
    '124.5': 28,
    '125.5': 27.9,
    '126.5': 27.9,
    '127.5': 27.8,
    '128.5': 27.7,
    '129.5': 27.7,
    '130.5': 27.6,
    '131.5': 27.5,
    '132.5': 27.5,
    '133.5': 27.4,
    '134.5': 27.3,
    '135.5': 27.3,
    '136.5': 27.2,
    '137.5': 27.2,
    '138.5': 27.1,
    '139.5': 27,
    '140.5': 27,
    '141.5': 26.9,
    '142.5': 26.8,
    '143.5': 26.8,
    '144.5': 26.7,
    '145.5': 26.6,
    '146.5': 26.6,
    '147.5': 26.5,
    '148.5': 26.4,
    '149.5': 26.4,
    '150.5': 26.3,
    '151.5': 26.3,
    '152.5': 26.2,
    '153.5': 26.1,
    '154.5': 26.1,
    '155.5': 26,
    '156.5': 25.9,
    '157.5': 25.9,
    '158.5': 25.8,
    '159.5': 25.7,
    '160.5': 25.7,
    '161.5': 25.6,
    '162.5': 25.6,
    '163.5': 25.5,
    '164.5': 25.4,
    '165.5': 25.4,
    '166.5': 25.3,
    '167.5': 25.2,
    '168.5': 25.2,
    '169.5': 25.1,
    '170.5': 25.1,
    '171.5': 25,
    '172.5': 24.9,
    '173.5': 24.9,
    '174.5': 24.8,
    '175.5': 24.7,
    '176.5': 24.7,
    '177.5': 24.6,
    '178.5': 24.6,
    '179.5': 24.5,
    '180.5': 24.4,
    '181.5': 24.4,
    '182.5': 24.3,
    '183.5': 24.3,
    '184.5': 24.2,
    '185.5': 24.1,
    '186.5': 24.1,
    '187.5': 24,
    '188.5': 24,
    '189.5': 23.9,
    '190.5': 23.8,
    '191.5': 23.8,
    '192.5': 23.7,
    '193.5': 23.7,
    '194.5': 23.6,
  },
  '33': {
    '0': 37.6,
    '1': 37.6,
    '2': 37.5,
    '3': 37.4,
    '4': 37.3,
    '5': 37.2,
    '6': 37.2,
    '7': 37.1,
    '8': 37,
    '9': 36.9,
    '10': 36.8,
    '11': 36.8,
    '12': 36.7,
    '13': 36.6,
    '14': 36.5,
    '15': 36.4,
    '16': 36.4,
    '17': 36.3,
    '18': 36.2,
    '19': 36.1,
    '20': 36,
    '21': 36,
    '22': 35.9,
    '23': 35.8,
    '24': 35.7,
    '25': 35.7,
    '26': 35.6,
    '27': 35.5,
    '28': 35.4,
    '29': 35.3,
    '30': 35.3,
    '31': 35.2,
    '32': 35.1,
    '33': 35,
    '34': 35,
    '35': 34.9,
    '36': 34.8,
    '37': 34.7,
    '38': 34.7,
    '39': 34.6,
    '40': 34.5,
    '41': 34.4,
    '42': 34.3,
    '43': 34.3,
    '44': 34.2,
    '45': 34.1,
    '46': 34,
    '47': 34,
    '48': 33.9,
    '49': 33.8,
    '50': 33.7,
    '51': 33.7,
    '52': 33.6,
    '53': 33.5,
    '54': 33.4,
    '55': 33.4,
    '56': 33.3,
    '57': 33.2,
    '58': 33.1,
    '59': 33.1,
    '60': 33,
    '61': 32.9,
    '62': 32.9,
    '63': 32.8,
    '64': 32.7,
    '65': 32.6,
    '66': 32.6,
    '67': 32.5,
    '68': 32.4,
    '69': 32.3,
    '70': 32.3,
    '71': 32.2,
    '72': 32.1,
    '73': 32,
    '74': 32,
    '75': 31.9,
    '76': 31.8,
    '77': 31.8,
    '78': 31.7,
    '79': 31.6,
    '80': 31.5,
    '81': 31.5,
    '82': 31.4,
    '83': 31.3,
    '84': 31.3,
    '85': 31.2,
    '86': 31.1,
    '87': 31,
    '88': 31,
    '89': 30.9,
    '90': 30.8,
    '91': 30.8,
    '92': 30.7,
    '93': 30.6,
    '94': 30.6,
    '95': 30.5,
    '96': 30.4,
    '97': 30.3,
    '98': 30.3,
    '99': 30.2,
    '100': 30.1,
    '101': 30.1,
    '102': 30,
    '103': 29.9,
    '104': 29.9,
    '105': 29.8,
    '106': 29.7,
    '107': 29.7,
    '108': 29.6,
    '109': 29.5,
    '110': 29.4,
    '111': 29.4,
    '112': 29.3,
    '113': 29.2,
    '114': 29.2,
    '115': 29.1,
    '116': 29,
    '117': 29,
    '118': 28.9,
    '119': 28.8,
    '120': 28.8,
    '121': 28.7,
    '122': 28.6,
    '123': 28.6,
    '124': 28.5,
    '125': 28.4,
    '126': 28.4,
    '127': 28.3,
    '128': 28.2,
    '129': 28.2,
    '130': 28.1,
    '131': 28,
    '132': 28,
    '133': 27.9,
    '134': 27.8,
    '135': 27.8,
    '136': 27.7,
    '137': 27.6,
    '138': 27.6,
    '139': 27.5,
    '140': 27.4,
    '141': 27.4,
    '142': 27.3,
    '143': 27.3,
    '144': 27.2,
    '145': 27.1,
    '146': 27,
    '147': 27,
    '148': 26.9,
    '149': 26.9,
    '150': 26.8,
    '151': 26.7,
    '152': 26.7,
    '153': 26.6,
    '154': 26.5,
    '155': 26.5,
    '156': 26.4,
    '157': 26.3,
    '158': 26.3,
    '159': 26.2,
    '160': 26.2,
    '161': 26.1,
    '162': 26,
    '163': 26,
    '164': 25.9,
    '165': 25.8,
    '166': 25.8,
    '167': 25.7,
    '168': 25.6,
    '169': 25.6,
    '170': 25.5,
    '171': 25.5,
    '172': 25.4,
    '173': 25.3,
    '174': 25.3,
    '175': 25.2,
    '176': 25.1,
    '177': 25.1,
    '178': 25,
    '179': 25,
    '180': 24.9,
    '181': 24.8,
    '182': 24.8,
    '183': 24.7,
    '184': 24.7,
    '185': 24.6,
    '186': 24.5,
    '187': 24.5,
    '188': 24.4,
    '189': 24.4,
    '190': 24.3,
    '191': 24.2,
    '192': 24.2,
    '193': 24.1,
    '194': 24,
    '195': 24,
    '0.5': 37.6,
    '1.5': 37.5,
    '2.5': 37.4,
    '3.5': 37.4,
    '4.5': 37.3,
    '5.5': 37.2,
    '6.5': 37.1,
    '7.5': 37,
    '8.5': 37,
    '9.5': 36.9,
    '10.5': 36.8,
    '11.5': 36.7,
    '12.5': 36.6,
    '13.5': 36.6,
    '14.5': 36.5,
    '15.5': 36.4,
    '16.5': 36.3,
    '17.5': 36.2,
    '18.5': 36.2,
    '19.5': 36.1,
    '20.5': 36,
    '21.5': 35.9,
    '22.5': 35.9,
    '23.5': 35.8,
    '24.5': 35.7,
    '25.5': 35.6,
    '26.5': 35.5,
    '27.5': 35.5,
    '28.5': 35.4,
    '29.5': 35.3,
    '30.5': 35.2,
    '31.5': 35.2,
    '32.5': 35.1,
    '33.5': 35,
    '34.5': 34.9,
    '35.5': 34.8,
    '36.5': 34.8,
    '37.5': 34.7,
    '38.5': 34.6,
    '39.5': 34.5,
    '40.5': 34.5,
    '41.5': 34.4,
    '42.5': 34.3,
    '43.5': 34.2,
    '44.5': 34.2,
    '45.5': 34.1,
    '46.5': 34,
    '47.5': 33.9,
    '48.5': 33.9,
    '49.5': 33.8,
    '50.5': 33.7,
    '51.5': 33.6,
    '52.5': 33.6,
    '53.5': 33.5,
    '54.5': 33.4,
    '55.5': 33.3,
    '56.5': 33.3,
    '57.5': 33.2,
    '58.5': 33.1,
    '59.5': 33,
    '60.5': 33,
    '61.5': 32.9,
    '62.5': 32.8,
    '63.5': 32.7,
    '64.5': 32.7,
    '65.5': 32.6,
    '66.5': 32.5,
    '67.5': 32.4,
    '68.5': 32.4,
    '69.5': 32.3,
    '70.5': 32.2,
    '71.5': 32.2,
    '72.5': 32.1,
    '73.5': 32,
    '74.5': 31.9,
    '75.5': 31.9,
    '76.5': 31.8,
    '77.5': 31.7,
    '78.5': 31.7,
    '79.5': 31.6,
    '80.5': 31.5,
    '81.5': 31.4,
    '82.5': 31.4,
    '83.5': 31.3,
    '84.5': 31.2,
    '85.5': 31.2,
    '86.5': 31.1,
    '87.5': 31,
    '88.5': 30.9,
    '89.5': 30.9,
    '90.5': 30.8,
    '91.5': 30.7,
    '92.5': 30.7,
    '93.5': 30.6,
    '94.5': 30.5,
    '95.5': 30.4,
    '96.5': 30.4,
    '97.5': 30.3,
    '98.5': 30.2,
    '99.5': 30.2,
    '100.5': 30.1,
    '101.5': 30,
    '102.5': 30,
    '103.5': 29.9,
    '104.5': 29.8,
    '105.5': 29.8,
    '106.5': 29.7,
    '107.5': 29.6,
    '108.5': 29.5,
    '109.5': 29.5,
    '110.5': 29.4,
    '111.5': 29.3,
    '112.5': 29.3,
    '113.5': 29.2,
    '114.5': 29.1,
    '115.5': 29.1,
    '116.5': 29,
    '117.5': 28.9,
    '118.5': 28.9,
    '119.5': 28.8,
    '120.5': 28.7,
    '121.5': 28.7,
    '122.5': 28.6,
    '123.5': 28.5,
    '124.5': 28.5,
    '125.5': 28.4,
    '126.5': 28.3,
    '127.5': 28.3,
    '128.5': 28.2,
    '129.5': 28.1,
    '130.5': 28.1,
    '131.5': 28,
    '132.5': 27.9,
    '133.5': 27.9,
    '134.5': 27.8,
    '135.5': 27.7,
    '136.5': 27.7,
    '137.5': 27.6,
    '138.5': 27.5,
    '139.5': 27.5,
    '140.5': 27.4,
    '141.5': 27.3,
    '142.5': 27.3,
    '143.5': 27.2,
    '144.5': 27.1,
    '145.5': 27.1,
    '146.5': 27,
    '147.5': 27,
    '148.5': 26.9,
    '149.5': 26.8,
    '150.5': 26.8,
    '151.5': 26.7,
    '152.5': 26.6,
    '153.5': 26.6,
    '154.5': 26.5,
    '155.5': 26.4,
    '156.5': 26.4,
    '157.5': 26.3,
    '158.5': 26.2,
    '159.5': 26.2,
    '160.5': 26.1,
    '161.5': 26.1,
    '162.5': 26,
    '163.5': 25.9,
    '164.5': 25.9,
    '165.5': 25.8,
    '166.5': 25.7,
    '167.5': 25.7,
    '168.5': 25.6,
    '169.5': 25.6,
    '170.5': 25.5,
    '171.5': 25.4,
    '172.5': 25.4,
    '173.5': 25.3,
    '174.5': 25.2,
    '175.5': 25.2,
    '176.5': 25.1,
    '177.5': 25.1,
    '178.5': 25,
    '179.5': 24.9,
    '180.5': 24.9,
    '181.5': 24.8,
    '182.5': 24.7,
    '183.5': 24.7,
    '184.5': 24.6,
    '185.5': 24.6,
    '186.5': 24.5,
    '187.5': 24.4,
    '188.5': 24.4,
    '189.5': 24.3,
    '190.5': 24.3,
    '191.5': 24.2,
    '192.5': 24.1,
    '193.5': 24.1,
    '194.5': 24,
  },
  '33.5': {
    '0': 38.2,
    '1': 38.1,
    '2': 38,
    '3': 37.9,
    '4': 37.9,
    '5': 37.8,
    '6': 37.7,
    '7': 37.6,
    '8': 37.5,
    '9': 37.5,
    '10': 37.4,
    '11': 37.3,
    '12': 37.2,
    '13': 37.1,
    '14': 37.1,
    '15': 37,
    '16': 36.9,
    '17': 36.8,
    '18': 36.7,
    '19': 36.7,
    '20': 36.6,
    '21': 36.5,
    '22': 36.5,
    '23': 36.4,
    '24': 36.3,
    '25': 36.2,
    '26': 36.1,
    '27': 36,
    '28': 35.9,
    '29': 35.9,
    '30': 35.8,
    '31': 35.7,
    '32': 35.6,
    '33': 35.6,
    '34': 35.5,
    '35': 35.4,
    '36': 35.3,
    '37': 35.2,
    '38': 35.2,
    '39': 35.1,
    '40': 35,
    '41': 34.9,
    '42': 34.9,
    '43': 34.8,
    '44': 34.7,
    '45': 34.6,
    '46': 34.6,
    '47': 34.5,
    '48': 34.4,
    '49': 34.3,
    '50': 34.3,
    '51': 34.2,
    '52': 34.1,
    '53': 34,
    '54': 33.9,
    '55': 33.9,
    '56': 33.8,
    '57': 33.7,
    '58': 33.7,
    '59': 33.6,
    '60': 33.5,
    '61': 33.4,
    '62': 33.3,
    '63': 33.3,
    '64': 33.2,
    '65': 33.1,
    '66': 33.1,
    '67': 33,
    '68': 32.9,
    '69': 32.8,
    '70': 32.8,
    '71': 32.7,
    '72': 32.6,
    '73': 32.5,
    '74': 32.5,
    '75': 32.4,
    '76': 32.3,
    '77': 32.2,
    '78': 32.2,
    '79': 32.1,
    '80': 32,
    '81': 32,
    '82': 31.9,
    '83': 31.8,
    '84': 31.7,
    '85': 31.7,
    '86': 31.6,
    '87': 31.5,
    '88': 31.5,
    '89': 31.4,
    '90': 31.3,
    '91': 31.2,
    '92': 31.2,
    '93': 31.1,
    '94': 31,
    '95': 31,
    '96': 30.9,
    '97': 30.8,
    '98': 30.7,
    '99': 30.7,
    '100': 30.6,
    '101': 30.5,
    '102': 30.5,
    '103': 30.4,
    '104': 30.3,
    '105': 30.3,
    '106': 30.2,
    '107': 30.1,
    '108': 30.1,
    '109': 30,
    '110': 29.9,
    '111': 29.8,
    '112': 29.8,
    '113': 29.7,
    '114': 29.6,
    '115': 29.6,
    '116': 29.5,
    '117': 29.4,
    '118': 29.4,
    '119': 29.3,
    '120': 29.2,
    '121': 29.2,
    '122': 29.1,
    '123': 29,
    '124': 29,
    '125': 28.9,
    '126': 28.8,
    '127': 28.8,
    '128': 28.7,
    '129': 28.6,
    '130': 28.6,
    '131': 28.5,
    '132': 28.4,
    '133': 28.4,
    '134': 28.3,
    '135': 28.2,
    '136': 28.2,
    '137': 28.1,
    '138': 28,
    '139': 28,
    '140': 27.9,
    '141': 27.8,
    '142': 27.8,
    '143': 27.7,
    '144': 27.6,
    '145': 27.6,
    '146': 27.5,
    '147': 27.4,
    '148': 27.4,
    '149': 27.3,
    '150': 27.2,
    '151': 27.2,
    '152': 27.1,
    '153': 27,
    '154': 27,
    '155': 26.9,
    '156': 26.8,
    '157': 26.8,
    '158': 26.7,
    '159': 26.7,
    '160': 26.6,
    '161': 26.5,
    '162': 26.5,
    '163': 26.4,
    '164': 26.3,
    '165': 26.3,
    '166': 26.2,
    '167': 26.1,
    '168': 26.1,
    '169': 26,
    '170': 26,
    '171': 25.9,
    '172': 25.8,
    '173': 25.8,
    '174': 25.7,
    '175': 25.6,
    '176': 25.6,
    '177': 25.5,
    '178': 25.5,
    '179': 25.4,
    '180': 25.3,
    '181': 25.3,
    '182': 25.2,
    '183': 25.1,
    '184': 25.1,
    '185': 25,
    '186': 25,
    '187': 24.9,
    '188': 24.8,
    '189': 24.8,
    '190': 24.7,
    '191': 24.7,
    '192': 24.6,
    '193': 24.5,
    '194': 24.5,
    '195': 24.4,
    '0.5': 38.2,
    '1.5': 38.1,
    '2.5': 38,
    '3.5': 37.9,
    '4.5': 37.8,
    '5.5': 37.7,
    '6.5': 37.7,
    '7.5': 37.6,
    '8.5': 37.5,
    '9.5': 37.4,
    '10.5': 37.3,
    '11.5': 37.3,
    '12.5': 37.2,
    '13.5': 37.1,
    '14.5': 37,
    '15.5': 36.9,
    '16.5': 36.9,
    '17.5': 36.8,
    '18.5': 36.7,
    '19.5': 36.6,
    '20.5': 36.6,
    '21.5': 36.5,
    '22.5': 36.4,
    '23.5': 36.3,
    '24.5': 36.3,
    '25.5': 36.2,
    '26.5': 36.1,
    '27.5': 36,
    '28.5': 35.9,
    '29.5': 35.8,
    '30.5': 35.8,
    '31.5': 35.7,
    '32.5': 35.6,
    '33.5': 35.5,
    '34.5': 35.4,
    '35.5': 35.4,
    '36.5': 35.3,
    '37.5': 35.2,
    '38.5': 35.1,
    '39.5': 35.1,
    '40.5': 35,
    '41.5': 34.9,
    '42.5': 34.8,
    '43.5': 34.7,
    '44.5': 34.7,
    '45.5': 34.6,
    '46.5': 34.5,
    '47.5': 34.4,
    '48.5': 34.4,
    '49.5': 34.3,
    '50.5': 34.2,
    '51.5': 34.1,
    '52.5': 34.1,
    '53.5': 34,
    '54.5': 33.9,
    '55.5': 33.8,
    '56.5': 33.8,
    '57.5': 33.7,
    '58.5': 33.6,
    '59.5': 33.5,
    '60.5': 33.5,
    '61.5': 33.4,
    '62.5': 33.3,
    '63.5': 33.2,
    '64.5': 33.2,
    '65.5': 33.1,
    '66.5': 33,
    '67.5': 32.9,
    '68.5': 32.9,
    '69.5': 32.8,
    '70.5': 32.7,
    '71.5': 32.7,
    '72.5': 32.6,
    '73.5': 32.5,
    '74.5': 32.4,
    '75.5': 32.4,
    '76.5': 32.3,
    '77.5': 32.2,
    '78.5': 32.1,
    '79.5': 32.1,
    '80.5': 32,
    '81.5': 31.9,
    '82.5': 31.9,
    '83.5': 31.8,
    '84.5': 31.7,
    '85.5': 31.6,
    '86.5': 31.6,
    '87.5': 31.5,
    '88.5': 31.4,
    '89.5': 31.3,
    '90.5': 31.3,
    '91.5': 31.2,
    '92.5': 31.1,
    '93.5': 31.1,
    '94.5': 31,
    '95.5': 30.9,
    '96.5': 30.9,
    '97.5': 30.8,
    '98.5': 30.7,
    '99.5': 30.6,
    '100.5': 30.6,
    '101.5': 30.5,
    '102.5': 30.4,
    '103.5': 30.4,
    '104.5': 30.3,
    '105.5': 30.2,
    '106.5': 30.2,
    '107.5': 30.1,
    '108.5': 30,
    '109.5': 29.9,
    '110.5': 29.9,
    '111.5': 29.8,
    '112.5': 29.7,
    '113.5': 29.7,
    '114.5': 29.6,
    '115.5': 29.5,
    '116.5': 29.5,
    '117.5': 29.4,
    '118.5': 29.3,
    '119.5': 29.3,
    '120.5': 29.2,
    '121.5': 29.1,
    '122.5': 29.1,
    '123.5': 29,
    '124.5': 28.9,
    '125.5': 28.9,
    '126.5': 28.8,
    '127.5': 28.7,
    '128.5': 28.7,
    '129.5': 28.6,
    '130.5': 28.5,
    '131.5': 28.5,
    '132.5': 28.4,
    '133.5': 28.3,
    '134.5': 28.3,
    '135.5': 28.2,
    '136.5': 28.1,
    '137.5': 28.1,
    '138.5': 28,
    '139.5': 27.9,
    '140.5': 27.9,
    '141.5': 27.8,
    '142.5': 27.7,
    '143.5': 27.7,
    '144.5': 27.6,
    '145.5': 27.5,
    '146.5': 27.5,
    '147.5': 27.4,
    '148.5': 27.3,
    '149.5': 27.3,
    '150.5': 27.2,
    '151.5': 27.1,
    '152.5': 27.1,
    '153.5': 27,
    '154.5': 26.9,
    '155.5': 26.9,
    '156.5': 26.8,
    '157.5': 26.8,
    '158.5': 26.7,
    '159.5': 26.6,
    '160.5': 26.6,
    '161.5': 26.5,
    '162.5': 26.4,
    '163.5': 26.4,
    '164.5': 26.3,
    '165.5': 26.2,
    '166.5': 26.2,
    '167.5': 26.1,
    '168.5': 26.1,
    '169.5': 26,
    '170.5': 25.9,
    '171.5': 25.9,
    '172.5': 25.8,
    '173.5': 25.7,
    '174.5': 25.7,
    '175.5': 25.6,
    '176.5': 25.5,
    '177.5': 25.5,
    '178.5': 25.4,
    '179.5': 25.4,
    '180.5': 25.3,
    '181.5': 25.2,
    '182.5': 25.2,
    '183.5': 25.1,
    '184.5': 25.1,
    '185.5': 25,
    '186.5': 24.9,
    '187.5': 24.9,
    '188.5': 24.8,
    '189.5': 24.7,
    '190.5': 24.7,
    '191.5': 24.6,
    '192.5': 24.6,
    '193.5': 24.5,
    '194.5': 24.4,
  },
  '34': {
    '0': 38.7,
    '1': 38.7,
    '2': 38.6,
    '3': 38.5,
    '4': 38.4,
    '5': 38.3,
    '6': 38.2,
    '7': 38.2,
    '8': 38.1,
    '9': 38,
    '10': 37.9,
    '11': 37.8,
    '12': 37.8,
    '13': 37.7,
    '14': 37.6,
    '15': 37.5,
    '16': 37.4,
    '17': 37.3,
    '18': 37.3,
    '19': 37.2,
    '20': 37.1,
    '21': 37,
    '22': 36.9,
    '23': 36.9,
    '24': 36.8,
    '25': 36.7,
    '26': 36.6,
    '27': 36.5,
    '28': 36.5,
    '29': 36.4,
    '30': 36.3,
    '31': 36.2,
    '32': 36.2,
    '33': 36.1,
    '34': 36,
    '35': 35.9,
    '36': 35.8,
    '37': 35.8,
    '38': 35.7,
    '39': 35.6,
    '40': 35.5,
    '41': 35.5,
    '42': 35.4,
    '43': 35.3,
    '44': 35.2,
    '45': 35.1,
    '46': 35.1,
    '47': 35,
    '48': 34.9,
    '49': 34.8,
    '50': 34.8,
    '51': 34.7,
    '52': 34.6,
    '53': 34.5,
    '54': 34.5,
    '55': 34.4,
    '56': 34.3,
    '57': 34.2,
    '58': 34.2,
    '59': 34.1,
    '60': 34,
    '61': 33.9,
    '62': 33.8,
    '63': 33.8,
    '64': 33.7,
    '65': 33.6,
    '66': 33.6,
    '67': 33.5,
    '68': 33.4,
    '69': 33.3,
    '70': 33.3,
    '71': 33.2,
    '72': 33.1,
    '73': 33,
    '74': 33,
    '75': 32.9,
    '76': 32.8,
    '77': 32.7,
    '78': 32.7,
    '79': 32.6,
    '80': 32.5,
    '81': 32.4,
    '82': 32.4,
    '83': 32.3,
    '84': 32.2,
    '85': 32.2,
    '86': 32.1,
    '87': 32,
    '88': 31.9,
    '89': 31.9,
    '90': 31.8,
    '91': 31.7,
    '92': 31.7,
    '93': 31.6,
    '94': 31.5,
    '95': 31.4,
    '96': 31.4,
    '97': 31.3,
    '98': 31.2,
    '99': 31.2,
    '100': 31.1,
    '101': 31,
    '102': 30.9,
    '103': 30.9,
    '104': 30.8,
    '105': 30.7,
    '106': 30.7,
    '107': 30.6,
    '108': 30.5,
    '109': 30.5,
    '110': 30.4,
    '111': 30.3,
    '112': 30.2,
    '113': 30.2,
    '114': 30.1,
    '115': 30,
    '116': 30,
    '117': 29.9,
    '118': 29.8,
    '119': 29.8,
    '120': 29.7,
    '121': 29.6,
    '122': 29.6,
    '123': 29.5,
    '124': 29.4,
    '125': 29.3,
    '126': 29.3,
    '127': 29.2,
    '128': 29.1,
    '129': 29.1,
    '130': 29,
    '131': 28.9,
    '132': 28.9,
    '133': 28.8,
    '134': 28.7,
    '135': 28.7,
    '136': 28.6,
    '137': 28.5,
    '138': 28.5,
    '139': 28.4,
    '140': 28.3,
    '141': 28.3,
    '142': 28.2,
    '143': 28.1,
    '144': 28.1,
    '145': 28,
    '146': 27.9,
    '147': 27.9,
    '148': 27.8,
    '149': 27.7,
    '150': 27.7,
    '151': 27.6,
    '152': 27.5,
    '153': 27.5,
    '154': 27.4,
    '155': 27.4,
    '156': 27.3,
    '157': 27.2,
    '158': 27.2,
    '159': 27.1,
    '160': 27,
    '161': 27,
    '162': 26.9,
    '163': 26.8,
    '164': 26.8,
    '165': 26.7,
    '166': 26.6,
    '167': 26.6,
    '168': 26.5,
    '169': 26.5,
    '170': 26.4,
    '171': 26.3,
    '172': 26.3,
    '173': 26.2,
    '174': 26.1,
    '175': 26.1,
    '176': 26,
    '177': 25.9,
    '178': 25.9,
    '179': 25.8,
    '180': 25.8,
    '181': 25.7,
    '182': 25.6,
    '183': 25.6,
    '184': 25.5,
    '185': 25.4,
    '186': 25.4,
    '187': 25.3,
    '188': 25.3,
    '189': 25.2,
    '190': 25.1,
    '191': 25.1,
    '192': 25,
    '193': 25,
    '194': 24.9,
    '195': 24.8,
    '0.5': 38.7,
    '1.5': 38.6,
    '2.5': 38.5,
    '3.5': 38.4,
    '4.5': 38.4,
    '5.5': 38.3,
    '6.5': 38.2,
    '7.5': 38.1,
    '8.5': 38,
    '9.5': 38,
    '10.5': 37.9,
    '11.5': 37.8,
    '12.5': 37.7,
    '13.5': 37.6,
    '14.5': 37.6,
    '15.5': 37.5,
    '16.5': 37.4,
    '17.5': 37.3,
    '18.5': 37.2,
    '19.5': 37.1,
    '20.5': 37.1,
    '21.5': 37,
    '22.5': 36.9,
    '23.5': 36.8,
    '24.5': 36.7,
    '25.5': 36.7,
    '26.5': 36.6,
    '27.5': 36.5,
    '28.5': 36.4,
    '29.5': 36.4,
    '30.5': 36.3,
    '31.5': 36.2,
    '32.5': 36.1,
    '33.5': 36,
    '34.5': 36,
    '35.5': 35.9,
    '36.5': 35.8,
    '37.5': 35.7,
    '38.5': 35.6,
    '39.5': 35.6,
    '40.5': 35.5,
    '41.5': 35.4,
    '42.5': 35.3,
    '43.5': 35.3,
    '44.5': 35.2,
    '45.5': 35.1,
    '46.5': 35,
    '47.5': 34.9,
    '48.5': 34.9,
    '49.5': 34.8,
    '50.5': 34.7,
    '51.5': 34.6,
    '52.5': 34.6,
    '53.5': 34.5,
    '54.5': 34.4,
    '55.5': 34.3,
    '56.5': 34.3,
    '57.5': 34.2,
    '58.5': 34.1,
    '59.5': 34,
    '60.5': 34,
    '61.5': 33.9,
    '62.5': 33.8,
    '63.5': 33.7,
    '64.5': 33.7,
    '65.5': 33.6,
    '66.5': 33.5,
    '67.5': 33.4,
    '68.5': 33.4,
    '69.5': 33.3,
    '70.5': 33.2,
    '71.5': 33.1,
    '72.5': 33.1,
    '73.5': 33,
    '74.5': 32.9,
    '75.5': 32.8,
    '76.5': 32.8,
    '77.5': 32.7,
    '78.5': 32.6,
    '79.5': 32.6,
    '80.5': 32.5,
    '81.5': 32.4,
    '82.5': 32.3,
    '83.5': 32.3,
    '84.5': 32.2,
    '85.5': 32.1,
    '86.5': 32,
    '87.5': 32,
    '88.5': 31.9,
    '89.5': 31.8,
    '90.5': 31.8,
    '91.5': 31.7,
    '92.5': 31.6,
    '93.5': 31.5,
    '94.5': 31.5,
    '95.5': 31.4,
    '96.5': 31.3,
    '97.5': 31.3,
    '98.5': 31.2,
    '99.5': 31.1,
    '100.5': 31,
    '101.5': 31,
    '102.5': 30.9,
    '103.5': 30.8,
    '104.5': 30.8,
    '105.5': 30.7,
    '106.5': 30.6,
    '107.5': 30.6,
    '108.5': 30.5,
    '109.5': 30.4,
    '110.5': 30.3,
    '111.5': 30.3,
    '112.5': 30.2,
    '113.5': 30.1,
    '114.5': 30.1,
    '115.5': 30,
    '116.5': 29.9,
    '117.5': 29.9,
    '118.5': 29.8,
    '119.5': 29.7,
    '120.5': 29.7,
    '121.5': 29.6,
    '122.5': 29.5,
    '123.5': 29.4,
    '124.5': 29.4,
    '125.5': 29.3,
    '126.5': 29.2,
    '127.5': 29.2,
    '128.5': 29.1,
    '129.5': 29,
    '130.5': 29,
    '131.5': 28.9,
    '132.5': 28.8,
    '133.5': 28.8,
    '134.5': 28.7,
    '135.5': 28.6,
    '136.5': 28.6,
    '137.5': 28.5,
    '138.5': 28.4,
    '139.5': 28.4,
    '140.5': 28.3,
    '141.5': 28.2,
    '142.5': 28.2,
    '143.5': 28.1,
    '144.5': 28,
    '145.5': 28,
    '146.5': 27.9,
    '147.5': 27.8,
    '148.5': 27.8,
    '149.5': 27.7,
    '150.5': 27.6,
    '151.5': 27.6,
    '152.5': 27.5,
    '153.5': 27.5,
    '154.5': 27.4,
    '155.5': 27.3,
    '156.5': 27.3,
    '157.5': 27.2,
    '158.5': 27.1,
    '159.5': 27.1,
    '160.5': 27,
    '161.5': 26.9,
    '162.5': 26.9,
    '163.5': 26.8,
    '164.5': 26.7,
    '165.5': 26.7,
    '166.5': 26.6,
    '167.5': 26.5,
    '168.5': 26.5,
    '169.5': 26.4,
    '170.5': 26.4,
    '171.5': 26.3,
    '172.5': 26.2,
    '173.5': 26.2,
    '174.5': 26.1,
    '175.5': 26,
    '176.5': 26,
    '177.5': 25.9,
    '178.5': 25.9,
    '179.5': 25.8,
    '180.5': 25.7,
    '181.5': 25.7,
    '182.5': 25.6,
    '183.5': 25.5,
    '184.5': 25.5,
    '185.5': 25.4,
    '186.5': 25.4,
    '187.5': 25.3,
    '188.5': 25.2,
    '189.5': 25.2,
    '190.5': 25.1,
    '191.5': 25,
    '192.5': 25,
    '193.5': 24.9,
    '194.5': 24.9,
  },
  '34.5': {
    '0': 39.3,
    '1': 39.2,
    '2': 39.1,
    '3': 39,
    '4': 39,
    '5': 38.9,
    '6': 38.8,
    '7': 38.7,
    '8': 38.6,
    '9': 38.5,
    '10': 38.5,
    '11': 38.4,
    '12': 38.3,
    '13': 38.2,
    '14': 38.1,
    '15': 38,
    '16': 38,
    '17': 37.9,
    '18': 37.8,
    '19': 37.7,
    '20': 37.6,
    '21': 37.6,
    '22': 37.5,
    '23': 37.4,
    '24': 37.3,
    '25': 37.2,
    '26': 37.2,
    '27': 37.1,
    '28': 37,
    '29': 36.9,
    '30': 36.8,
    '31': 36.8,
    '32': 36.7,
    '33': 36.6,
    '34': 36.5,
    '35': 36.4,
    '36': 36.4,
    '37': 36.3,
    '38': 36.2,
    '39': 36.1,
    '40': 36,
    '41': 36,
    '42': 35.9,
    '43': 35.8,
    '44': 35.7,
    '45': 35.7,
    '46': 35.6,
    '47': 35.5,
    '48': 35.4,
    '49': 35.3,
    '50': 35.3,
    '51': 35.2,
    '52': 35.1,
    '53': 35,
    '54': 35,
    '55': 34.9,
    '56': 34.8,
    '57': 34.7,
    '58': 34.7,
    '59': 34.6,
    '60': 34.5,
    '61': 34.4,
    '62': 34.3,
    '63': 34.3,
    '64': 34.2,
    '65': 34.1,
    '66': 34,
    '67': 34,
    '68': 33.9,
    '69': 33.8,
    '70': 33.7,
    '71': 33.7,
    '72': 33.6,
    '73': 33.5,
    '74': 33.4,
    '75': 33.4,
    '76': 33.3,
    '77': 33.2,
    '78': 33.2,
    '79': 33.1,
    '80': 33,
    '81': 32.9,
    '82': 32.9,
    '83': 32.8,
    '84': 32.7,
    '85': 32.6,
    '86': 32.6,
    '87': 32.5,
    '88': 32.4,
    '89': 32.3,
    '90': 32.3,
    '91': 32.2,
    '92': 32.1,
    '93': 32.1,
    '94': 32,
    '95': 31.9,
    '96': 31.8,
    '97': 31.8,
    '98': 31.7,
    '99': 31.6,
    '100': 31.6,
    '101': 31.5,
    '102': 31.4,
    '103': 31.3,
    '104': 31.3,
    '105': 31.2,
    '106': 31.1,
    '107': 31.1,
    '108': 31,
    '109': 30.9,
    '110': 30.8,
    '111': 30.8,
    '112': 30.7,
    '113': 30.6,
    '114': 30.6,
    '115': 30.5,
    '116': 30.4,
    '117': 30.4,
    '118': 30.3,
    '119': 30.2,
    '120': 30.2,
    '121': 30.1,
    '122': 30,
    '123': 29.9,
    '124': 29.9,
    '125': 29.8,
    '126': 29.7,
    '127': 29.7,
    '128': 29.6,
    '129': 29.5,
    '130': 29.5,
    '131': 29.4,
    '132': 29.3,
    '133': 29.3,
    '134': 29.2,
    '135': 29.1,
    '136': 29.1,
    '137': 29,
    '138': 28.9,
    '139': 28.9,
    '140': 28.8,
    '141': 28.7,
    '142': 28.7,
    '143': 28.6,
    '144': 28.5,
    '145': 28.5,
    '146': 28.4,
    '147': 28.3,
    '148': 28.3,
    '149': 28.2,
    '150': 28.1,
    '151': 28.1,
    '152': 28,
    '153': 27.9,
    '154': 27.9,
    '155': 27.8,
    '156': 27.7,
    '157': 27.7,
    '158': 27.6,
    '159': 27.5,
    '160': 27.5,
    '161': 27.4,
    '162': 27.3,
    '163': 27.3,
    '164': 27.2,
    '165': 27.1,
    '166': 27.1,
    '167': 27,
    '168': 27,
    '169': 26.9,
    '170': 26.8,
    '171': 26.8,
    '172': 26.7,
    '173': 26.6,
    '174': 26.6,
    '175': 26.5,
    '176': 26.4,
    '177': 26.4,
    '178': 26.3,
    '179': 26.2,
    '180': 26.2,
    '181': 26.1,
    '182': 26.1,
    '183': 26,
    '184': 25.9,
    '185': 25.9,
    '186': 25.8,
    '187': 25.7,
    '188': 25.7,
    '189': 25.6,
    '190': 25.6,
    '191': 25.5,
    '192': 25.4,
    '193': 25.4,
    '194': 25.3,
    '195': 25.2,
    '0.5': 39.2,
    '1.5': 39.2,
    '2.5': 39.1,
    '3.5': 39,
    '4.5': 38.9,
    '5.5': 38.8,
    '6.5': 38.7,
    '7.5': 38.7,
    '8.5': 38.6,
    '9.5': 38.5,
    '10.5': 38.4,
    '11.5': 38.3,
    '12.5': 38.2,
    '13.5': 38.2,
    '14.5': 38.1,
    '15.5': 38,
    '16.5': 37.9,
    '17.5': 37.8,
    '18.5': 37.8,
    '19.5': 37.7,
    '20.5': 37.6,
    '21.5': 37.5,
    '22.5': 37.4,
    '23.5': 37.4,
    '24.5': 37.3,
    '25.5': 37.2,
    '26.5': 37.1,
    '27.5': 37,
    '28.5': 37,
    '29.5': 36.9,
    '30.5': 36.8,
    '31.5': 36.7,
    '32.5': 36.6,
    '33.5': 36.6,
    '34.5': 36.5,
    '35.5': 36.4,
    '36.5': 36.3,
    '37.5': 36.2,
    '38.5': 36.2,
    '39.5': 36.1,
    '40.5': 36,
    '41.5': 35.9,
    '42.5': 35.8,
    '43.5': 35.8,
    '44.5': 35.7,
    '45.5': 35.6,
    '46.5': 35.5,
    '47.5': 35.5,
    '48.5': 35.4,
    '49.5': 35.3,
    '50.5': 35.2,
    '51.5': 35.1,
    '52.5': 35.1,
    '53.5': 35,
    '54.5': 34.9,
    '55.5': 34.8,
    '56.5': 34.8,
    '57.5': 34.7,
    '58.5': 34.6,
    '59.5': 34.5,
    '60.5': 34.5,
    '61.5': 34.4,
    '62.5': 34.3,
    '63.5': 34.2,
    '64.5': 34.2,
    '65.5': 34.1,
    '66.5': 34,
    '67.5': 33.9,
    '68.5': 33.9,
    '69.5': 33.8,
    '70.5': 33.7,
    '71.5': 33.6,
    '72.5': 33.6,
    '73.5': 33.5,
    '74.5': 33.4,
    '75.5': 33.3,
    '76.5': 33.3,
    '77.5': 33.2,
    '78.5': 33.1,
    '79.5': 33,
    '80.5': 33,
    '81.5': 32.9,
    '82.5': 32.8,
    '83.5': 32.7,
    '84.5': 32.7,
    '85.5': 32.6,
    '86.5': 32.5,
    '87.5': 32.5,
    '88.5': 32.4,
    '89.5': 32.3,
    '90.5': 32.2,
    '91.5': 32.2,
    '92.5': 32.1,
    '93.5': 32,
    '94.5': 31.9,
    '95.5': 31.9,
    '96.5': 31.8,
    '97.5': 31.7,
    '98.5': 31.7,
    '99.5': 31.6,
    '100.5': 31.5,
    '101.5': 31.4,
    '102.5': 31.4,
    '103.5': 31.3,
    '104.5': 31.2,
    '105.5': 31.2,
    '106.5': 31.1,
    '107.5': 31,
    '108.5': 31,
    '109.5': 30.9,
    '110.5': 30.8,
    '111.5': 30.7,
    '112.5': 30.7,
    '113.5': 30.6,
    '114.5': 30.5,
    '115.5': 30.5,
    '116.5': 30.4,
    '117.5': 30.3,
    '118.5': 30.3,
    '119.5': 30.2,
    '120.5': 30.1,
    '121.5': 30,
    '122.5': 30,
    '123.5': 29.9,
    '124.5': 29.8,
    '125.5': 29.8,
    '126.5': 29.7,
    '127.5': 29.6,
    '128.5': 29.6,
    '129.5': 29.5,
    '130.5': 29.4,
    '131.5': 29.4,
    '132.5': 29.3,
    '133.5': 29.2,
    '134.5': 29.2,
    '135.5': 29.1,
    '136.5': 29,
    '137.5': 29,
    '138.5': 28.9,
    '139.5': 28.8,
    '140.5': 28.8,
    '141.5': 28.7,
    '142.5': 28.6,
    '143.5': 28.6,
    '144.5': 28.5,
    '145.5': 28.4,
    '146.5': 28.4,
    '147.5': 28.3,
    '148.5': 28.2,
    '149.5': 28.2,
    '150.5': 28.1,
    '151.5': 28,
    '152.5': 28,
    '153.5': 27.9,
    '154.5': 27.8,
    '155.5': 27.8,
    '156.5': 27.7,
    '157.5': 27.6,
    '158.5': 27.6,
    '159.5': 27.5,
    '160.5': 27.4,
    '161.5': 27.4,
    '162.5': 27.3,
    '163.5': 27.2,
    '164.5': 27.2,
    '165.5': 27.1,
    '166.5': 27,
    '167.5': 27,
    '168.5': 26.9,
    '169.5': 26.9,
    '170.5': 26.8,
    '171.5': 26.7,
    '172.5': 26.7,
    '173.5': 26.6,
    '174.5': 26.5,
    '175.5': 26.5,
    '176.5': 26.4,
    '177.5': 26.3,
    '178.5': 26.3,
    '179.5': 26.2,
    '180.5': 26.2,
    '181.5': 26.1,
    '182.5': 26,
    '183.5': 26,
    '184.5': 25.9,
    '185.5': 25.8,
    '186.5': 25.8,
    '187.5': 25.7,
    '188.5': 25.7,
    '189.5': 25.6,
    '190.5': 25.5,
    '191.5': 25.5,
    '192.5': 25.4,
    '193.5': 25.3,
    '194.5': 25.3,
  },
  '35': {
    '0': 39.8,
    '1': 39.7,
    '2': 39.7,
    '3': 39.6,
    '4': 39.5,
    '5': 39.4,
    '6': 39.3,
    '7': 39.2,
    '8': 39.2,
    '9': 39.1,
    '10': 39,
    '11': 38.9,
    '12': 38.8,
    '13': 38.7,
    '14': 38.7,
    '15': 38.6,
    '16': 38.5,
    '17': 38.4,
    '18': 38.3,
    '19': 38.2,
    '20': 38.2,
    '21': 38.1,
    '22': 38,
    '23': 37.9,
    '24': 37.8,
    '25': 37.8,
    '26': 37.7,
    '27': 37.6,
    '28': 37.5,
    '29': 37.4,
    '30': 37.4,
    '31': 37.3,
    '32': 37.2,
    '33': 37.1,
    '34': 37,
    '35': 37,
    '36': 36.9,
    '37': 36.8,
    '38': 36.7,
    '39': 36.6,
    '40': 36.6,
    '41': 36.5,
    '42': 36.4,
    '43': 36.3,
    '44': 36.2,
    '45': 36.2,
    '46': 36.1,
    '47': 36,
    '48': 35.9,
    '49': 35.8,
    '50': 35.8,
    '51': 35.7,
    '52': 35.6,
    '53': 35.5,
    '54': 35.5,
    '55': 35.4,
    '56': 35.3,
    '57': 35.2,
    '58': 35.2,
    '59': 35.1,
    '60': 35,
    '61': 34.9,
    '62': 34.8,
    '63': 34.8,
    '64': 34.7,
    '65': 34.6,
    '66': 34.5,
    '67': 34.5,
    '68': 34.4,
    '69': 34.3,
    '70': 34.2,
    '71': 34.2,
    '72': 34.1,
    '73': 34,
    '74': 33.9,
    '75': 33.9,
    '76': 33.8,
    '77': 33.7,
    '78': 33.6,
    '79': 33.6,
    '80': 33.5,
    '81': 33.4,
    '82': 33.3,
    '83': 33.3,
    '84': 33.2,
    '85': 33.1,
    '86': 33,
    '87': 33,
    '88': 32.9,
    '89': 32.8,
    '90': 32.8,
    '91': 32.7,
    '92': 32.6,
    '93': 32.5,
    '94': 32.5,
    '95': 32.4,
    '96': 32.3,
    '97': 32.2,
    '98': 32.2,
    '99': 32.1,
    '100': 32,
    '101': 32,
    '102': 31.9,
    '103': 31.8,
    '104': 31.7,
    '105': 31.7,
    '106': 31.6,
    '107': 31.5,
    '108': 31.5,
    '109': 31.4,
    '110': 31.3,
    '111': 31.2,
    '112': 31.2,
    '113': 31.1,
    '114': 31,
    '115': 31,
    '116': 30.9,
    '117': 30.8,
    '118': 30.8,
    '119': 30.7,
    '120': 30.6,
    '121': 30.5,
    '122': 30.5,
    '123': 30.4,
    '124': 30.3,
    '125': 30.3,
    '126': 30.2,
    '127': 30.1,
    '128': 30.1,
    '129': 30,
    '130': 29.9,
    '131': 29.9,
    '132': 29.8,
    '133': 29.7,
    '134': 29.6,
    '135': 29.6,
    '136': 29.5,
    '137': 29.4,
    '138': 29.4,
    '139': 29.3,
    '140': 29.2,
    '141': 29.2,
    '142': 29.1,
    '143': 29,
    '144': 29,
    '145': 28.9,
    '146': 28.8,
    '147': 28.8,
    '148': 28.7,
    '149': 28.6,
    '150': 28.6,
    '151': 28.5,
    '152': 28.4,
    '153': 28.4,
    '154': 28.3,
    '155': 28.2,
    '156': 28.2,
    '157': 28.1,
    '158': 28,
    '159': 28,
    '160': 27.9,
    '161': 27.8,
    '162': 27.8,
    '163': 27.7,
    '164': 27.6,
    '165': 27.6,
    '166': 27.5,
    '167': 27.4,
    '168': 27.4,
    '169': 27.3,
    '170': 27.3,
    '171': 27.2,
    '172': 27.1,
    '173': 27.1,
    '174': 27,
    '175': 26.9,
    '176': 26.9,
    '177': 26.8,
    '178': 26.7,
    '179': 26.7,
    '180': 26.6,
    '181': 26.6,
    '182': 26.5,
    '183': 26.4,
    '184': 26.4,
    '185': 26.3,
    '186': 26.2,
    '187': 26.2,
    '188': 26.1,
    '189': 26,
    '190': 26,
    '191': 25.9,
    '192': 25.9,
    '193': 25.8,
    '194': 25.7,
    '195': 25.7,
    '0.5': 39.8,
    '1.5': 39.7,
    '2.5': 39.6,
    '3.5': 39.5,
    '4.5': 39.4,
    '5.5': 39.4,
    '6.5': 39.3,
    '7.5': 39.2,
    '8.5': 39.1,
    '9.5': 39,
    '10.5': 39,
    '11.5': 38.9,
    '12.5': 38.8,
    '13.5': 38.7,
    '14.5': 38.6,
    '15.5': 38.5,
    '16.5': 38.5,
    '17.5': 38.4,
    '18.5': 38.3,
    '19.5': 38.2,
    '20.5': 38.1,
    '21.5': 38,
    '22.5': 38,
    '23.5': 37.9,
    '24.5': 37.8,
    '25.5': 37.7,
    '26.5': 37.6,
    '27.5': 37.6,
    '28.5': 37.5,
    '29.5': 37.4,
    '30.5': 37.3,
    '31.5': 37.2,
    '32.5': 37.2,
    '33.5': 37.1,
    '34.5': 37,
    '35.5': 36.9,
    '36.5': 36.8,
    '37.5': 36.8,
    '38.5': 36.7,
    '39.5': 36.6,
    '40.5': 36.5,
    '41.5': 36.4,
    '42.5': 36.4,
    '43.5': 36.3,
    '44.5': 36.2,
    '45.5': 36.1,
    '46.5': 36,
    '47.5': 36,
    '48.5': 35.9,
    '49.5': 35.8,
    '50.5': 35.7,
    '51.5': 35.7,
    '52.5': 35.6,
    '53.5': 35.5,
    '54.5': 35.4,
    '55.5': 35.3,
    '56.5': 35.3,
    '57.5': 35.2,
    '58.5': 35.1,
    '59.5': 35,
    '60.5': 35,
    '61.5': 34.9,
    '62.5': 34.8,
    '63.5': 34.7,
    '64.5': 34.7,
    '65.5': 34.6,
    '66.5': 34.5,
    '67.5': 34.4,
    '68.5': 34.4,
    '69.5': 34.3,
    '70.5': 34.2,
    '71.5': 34.1,
    '72.5': 34.1,
    '73.5': 34,
    '74.5': 33.9,
    '75.5': 33.8,
    '76.5': 33.8,
    '77.5': 33.7,
    '78.5': 33.6,
    '79.5': 33.5,
    '80.5': 33.5,
    '81.5': 33.4,
    '82.5': 33.3,
    '83.5': 33.2,
    '84.5': 33.2,
    '85.5': 33.1,
    '86.5': 33,
    '87.5': 32.9,
    '88.5': 32.9,
    '89.5': 32.8,
    '90.5': 32.7,
    '91.5': 32.6,
    '92.5': 32.6,
    '93.5': 32.5,
    '94.5': 32.4,
    '95.5': 32.4,
    '96.5': 32.3,
    '97.5': 32.2,
    '98.5': 32.1,
    '99.5': 32.1,
    '100.5': 32,
    '101.5': 31.9,
    '102.5': 31.8,
    '103.5': 31.8,
    '104.5': 31.7,
    '105.5': 31.6,
    '106.5': 31.6,
    '107.5': 31.5,
    '108.5': 31.4,
    '109.5': 31.3,
    '110.5': 31.3,
    '111.5': 31.2,
    '112.5': 31.1,
    '113.5': 31.1,
    '114.5': 31,
    '115.5': 30.9,
    '116.5': 30.9,
    '117.5': 30.8,
    '118.5': 30.7,
    '119.5': 30.6,
    '120.5': 30.6,
    '121.5': 30.5,
    '122.5': 30.4,
    '123.5': 30.4,
    '124.5': 30.3,
    '125.5': 30.2,
    '126.5': 30.2,
    '127.5': 30.1,
    '128.5': 30,
    '129.5': 30,
    '130.5': 29.9,
    '131.5': 29.8,
    '132.5': 29.7,
    '133.5': 29.7,
    '134.5': 29.6,
    '135.5': 29.5,
    '136.5': 29.5,
    '137.5': 29.4,
    '138.5': 29.3,
    '139.5': 29.3,
    '140.5': 29.2,
    '141.5': 29.1,
    '142.5': 29.1,
    '143.5': 29,
    '144.5': 28.9,
    '145.5': 28.9,
    '146.5': 28.8,
    '147.5': 28.7,
    '148.5': 28.7,
    '149.5': 28.6,
    '150.5': 28.5,
    '151.5': 28.5,
    '152.5': 28.4,
    '153.5': 28.3,
    '154.5': 28.3,
    '155.5': 28.2,
    '156.5': 28.1,
    '157.5': 28.1,
    '158.5': 28,
    '159.5': 27.9,
    '160.5': 27.9,
    '161.5': 27.8,
    '162.5': 27.7,
    '163.5': 27.7,
    '164.5': 27.6,
    '165.5': 27.5,
    '166.5': 27.5,
    '167.5': 27.4,
    '168.5': 27.4,
    '169.5': 27.3,
    '170.5': 27.2,
    '171.5': 27.2,
    '172.5': 27.1,
    '173.5': 27,
    '174.5': 27,
    '175.5': 26.9,
    '176.5': 26.8,
    '177.5': 26.8,
    '178.5': 26.7,
    '179.5': 26.6,
    '180.5': 26.6,
    '181.5': 26.5,
    '182.5': 26.5,
    '183.5': 26.4,
    '184.5': 26.3,
    '185.5': 26.3,
    '186.5': 26.2,
    '187.5': 26.1,
    '188.5': 26.1,
    '189.5': 26,
    '190.5': 26,
    '191.5': 25.9,
    '192.5': 25.8,
    '193.5': 25.8,
    '194.5': 25.7,
  },
  // 35 - 40
  '35.5': {
    '0': 40.4,
    '1': 40.3,
    '2': 40.2,
    '3': 40.1,
    '4': 40,
    '5': 40,
    '6': 39.9,
    '7': 39.8,
    '8': 39.7,
    '9': 39.6,
    '10': 39.5,
    '11': 39.4,
    '12': 39.4,
    '13': 39.3,
    '14': 39.2,
    '15': 39.1,
    '16': 39,
    '17': 38.9,
    '18': 38.9,
    '19': 38.8,
    '20': 38.7,
    '21': 38.6,
    '22': 38.5,
    '23': 38.5,
    '24': 38.4,
    '25': 38.3,
    '26': 38.2,
    '27': 38.1,
    '28': 38,
    '29': 38,
    '30': 37.9,
    '31': 37.8,
    '32': 37.7,
    '33': 37.6,
    '34': 37.6,
    '35': 37.5,
    '36': 37.4,
    '37': 37.3,
    '38': 37.2,
    '39': 37.2,
    '40': 37.1,
    '41': 37,
    '42': 36.9,
    '43': 36.8,
    '44': 36.8,
    '45': 36.7,
    '46': 36.6,
    '47': 36.5,
    '48': 36.4,
    '49': 36.4,
    '50': 36.3,
    '51': 36.2,
    '52': 36.1,
    '53': 36,
    '54': 36,
    '55': 35.9,
    '56': 35.8,
    '57': 35.7,
    '58': 35.7,
    '59': 35.6,
    '60': 35.5,
    '61': 35.4,
    '62': 35.3,
    '63': 35.3,
    '64': 35.2,
    '65': 35.1,
    '66': 35,
    '67': 35,
    '68': 34.9,
    '69': 34.8,
    '70': 34.7,
    '71': 34.7,
    '72': 34.6,
    '73': 34.5,
    '74': 34.4,
    '75': 34.4,
    '76': 34.3,
    '77': 34.2,
    '78': 34.1,
    '79': 34.1,
    '80': 34,
    '81': 33.9,
    '82': 33.8,
    '83': 33.8,
    '84': 33.7,
    '85': 33.6,
    '86': 33.5,
    '87': 33.5,
    '88': 33.4,
    '89': 33.3,
    '90': 33.2,
    '91': 33.2,
    '92': 33.1,
    '93': 33,
    '94': 32.9,
    '95': 32.9,
    '96': 32.8,
    '97': 32.7,
    '98': 32.6,
    '99': 32.6,
    '100': 32.5,
    '101': 32.4,
    '102': 32.4,
    '103': 32.3,
    '104': 32.2,
    '105': 32.1,
    '106': 32.1,
    '107': 32,
    '108': 31.9,
    '109': 31.9,
    '110': 31.8,
    '111': 31.7,
    '112': 31.6,
    '113': 31.6,
    '114': 31.5,
    '115': 31.4,
    '116': 31.4,
    '117': 31.3,
    '118': 31.2,
    '119': 31.1,
    '120': 31.1,
    '121': 31,
    '122': 30.9,
    '123': 30.9,
    '124': 30.8,
    '125': 30.7,
    '126': 30.7,
    '127': 30.6,
    '128': 30.5,
    '129': 30.4,
    '130': 30.4,
    '131': 30.3,
    '132': 30.2,
    '133': 30.2,
    '134': 30.1,
    '135': 30,
    '136': 30,
    '137': 29.9,
    '138': 29.8,
    '139': 29.8,
    '140': 29.7,
    '141': 29.6,
    '142': 29.6,
    '143': 29.5,
    '144': 29.4,
    '145': 29.3,
    '146': 29.3,
    '147': 29.2,
    '148': 29.1,
    '149': 29.1,
    '150': 29,
    '151': 28.9,
    '152': 28.9,
    '153': 28.8,
    '154': 28.7,
    '155': 28.7,
    '156': 28.6,
    '157': 28.5,
    '158': 28.5,
    '159': 28.4,
    '160': 28.3,
    '161': 28.3,
    '162': 28.2,
    '163': 28.1,
    '164': 28.1,
    '165': 28,
    '166': 27.9,
    '167': 27.9,
    '168': 27.8,
    '169': 27.8,
    '170': 27.7,
    '171': 27.6,
    '172': 27.6,
    '173': 27.5,
    '174': 27.4,
    '175': 27.4,
    '176': 27.3,
    '177': 27.2,
    '178': 27.2,
    '179': 27.1,
    '180': 27,
    '181': 27,
    '182': 26.9,
    '183': 26.8,
    '184': 26.8,
    '185': 26.7,
    '186': 26.7,
    '187': 26.6,
    '188': 26.5,
    '189': 26.5,
    '190': 26.4,
    '191': 26.3,
    '192': 26.3,
    '193': 26.2,
    '194': 26.1,
    '195': 26.1,
    '0.5': 40.3,
    '1.5': 40.2,
    '2.5': 40.2,
    '3.5': 40.1,
    '4.5': 40,
    '5.5': 39.9,
    '6.5': 39.8,
    '7.5': 39.7,
    '8.5': 39.7,
    '9.5': 39.6,
    '10.5': 39.5,
    '11.5': 39.4,
    '12.5': 39.3,
    '13.5': 39.2,
    '14.5': 39.2,
    '15.5': 39.1,
    '16.5': 39,
    '17.5': 38.9,
    '18.5': 38.8,
    '19.5': 38.7,
    '20.5': 38.7,
    '21.5': 38.6,
    '22.5': 38.5,
    '23.5': 38.4,
    '24.5': 38.3,
    '25.5': 38.2,
    '26.5': 38.2,
    '27.5': 38.1,
    '28.5': 38,
    '29.5': 37.9,
    '30.5': 37.8,
    '31.5': 37.8,
    '32.5': 37.7,
    '33.5': 37.6,
    '34.5': 37.5,
    '35.5': 37.4,
    '36.5': 37.4,
    '37.5': 37.3,
    '38.5': 37.2,
    '39.5': 37.1,
    '40.5': 37,
    '41.5': 37,
    '42.5': 36.9,
    '43.5': 36.8,
    '44.5': 36.7,
    '45.5': 36.6,
    '46.5': 36.6,
    '47.5': 36.5,
    '48.5': 36.4,
    '49.5': 36.3,
    '50.5': 36.2,
    '51.5': 36.2,
    '52.5': 36.1,
    '53.5': 36,
    '54.5': 35.9,
    '55.5': 35.8,
    '56.5': 35.8,
    '57.5': 35.7,
    '58.5': 35.6,
    '59.5': 35.5,
    '60.5': 35.5,
    '61.5': 35.4,
    '62.5': 35.3,
    '63.5': 35.2,
    '64.5': 35.2,
    '65.5': 35.1,
    '66.5': 35,
    '67.5': 34.9,
    '68.5': 34.8,
    '69.5': 34.8,
    '70.5': 34.7,
    '71.5': 34.6,
    '72.5': 34.5,
    '73.5': 34.5,
    '74.5': 34.4,
    '75.5': 34.3,
    '76.5': 34.2,
    '77.5': 34.2,
    '78.5': 34.1,
    '79.5': 34,
    '80.5': 33.9,
    '81.5': 33.9,
    '82.5': 33.8,
    '83.5': 33.7,
    '84.5': 33.6,
    '85.5': 33.6,
    '86.5': 33.5,
    '87.5': 33.4,
    '88.5': 33.3,
    '89.5': 33.3,
    '90.5': 33.2,
    '91.5': 33.1,
    '92.5': 33.1,
    '93.5': 33,
    '94.5': 32.9,
    '95.5': 32.8,
    '96.5': 32.8,
    '97.5': 32.7,
    '98.5': 32.6,
    '99.5': 32.5,
    '100.5': 32.5,
    '101.5': 32.4,
    '102.5': 32.3,
    '103.5': 32.2,
    '104.5': 32.2,
    '105.5': 32.1,
    '106.5': 32,
    '107.5': 32,
    '108.5': 31.9,
    '109.5': 31.8,
    '110.5': 31.7,
    '111.5': 31.7,
    '112.5': 31.6,
    '113.5': 31.5,
    '114.5': 31.5,
    '115.5': 31.4,
    '116.5': 31.3,
    '117.5': 31.2,
    '118.5': 31.2,
    '119.5': 31.1,
    '120.5': 31,
    '121.5': 31,
    '122.5': 30.9,
    '123.5': 30.8,
    '124.5': 30.8,
    '125.5': 30.7,
    '126.5': 30.6,
    '127.5': 30.5,
    '128.5': 30.5,
    '129.5': 30.4,
    '130.5': 30.3,
    '131.5': 30.3,
    '132.5': 30.2,
    '133.5': 30.1,
    '134.5': 30.1,
    '135.5': 30,
    '136.5': 29.9,
    '137.5': 29.9,
    '138.5': 29.8,
    '139.5': 29.7,
    '140.5': 29.7,
    '141.5': 29.6,
    '142.5': 29.5,
    '143.5': 29.4,
    '144.5': 29.4,
    '145.5': 29.3,
    '146.5': 29.2,
    '147.5': 29.2,
    '148.5': 29.1,
    '149.5': 29,
    '150.5': 29,
    '151.5': 28.9,
    '152.5': 28.8,
    '153.5': 28.8,
    '154.5': 28.7,
    '155.5': 28.6,
    '156.5': 28.6,
    '157.5': 28.5,
    '158.5': 28.4,
    '159.5': 28.4,
    '160.5': 28.3,
    '161.5': 28.2,
    '162.5': 28.2,
    '163.5': 28.1,
    '164.5': 28,
    '165.5': 28,
    '166.5': 27.9,
    '167.5': 27.9,
    '168.5': 27.8,
    '169.5': 27.7,
    '170.5': 27.7,
    '171.5': 27.6,
    '172.5': 27.5,
    '173.5': 27.5,
    '174.5': 27.4,
    '175.5': 27.3,
    '176.5': 27.3,
    '177.5': 27.2,
    '178.5': 27.1,
    '179.5': 27.1,
    '180.5': 27,
    '181.5': 26.9,
    '182.5': 26.9,
    '183.5': 26.8,
    '184.5': 26.8,
    '185.5': 26.7,
    '186.5': 26.6,
    '187.5': 26.6,
    '188.5': 26.5,
    '189.5': 26.4,
    '190.5': 26.4,
    '191.5': 26.3,
    '192.5': 26.2,
    '193.5': 26.2,
    '194.5': 26.1,
  },
  '36': {
    '0': 40.9,
    '1': 40.8,
    '2': 40.7,
    '3': 40.7,
    '4': 40.6,
    '5': 40.5,
    '6': 40.4,
    '7': 40.3,
    '8': 40.2,
    '9': 40.2,
    '10': 40.1,
    '11': 40,
    '12': 39.9,
    '13': 39.8,
    '14': 39.7,
    '15': 39.6,
    '16': 39.6,
    '17': 39.5,
    '18': 39.4,
    '19': 39.3,
    '20': 39.2,
    '21': 39.1,
    '22': 39.1,
    '23': 39,
    '24': 38.9,
    '25': 38.8,
    '26': 38.7,
    '27': 38.6,
    '28': 38.6,
    '29': 38.5,
    '30': 38.4,
    '31': 38.3,
    '32': 38.2,
    '33': 38.2,
    '34': 38.1,
    '35': 38,
    '36': 37.9,
    '37': 37.8,
    '38': 37.8,
    '39': 37.7,
    '40': 37.6,
    '41': 37.5,
    '42': 37.4,
    '43': 37.3,
    '44': 37.3,
    '45': 37.2,
    '46': 37.1,
    '47': 37,
    '48': 36.9,
    '49': 36.9,
    '50': 36.8,
    '51': 36.7,
    '52': 36.6,
    '53': 36.5,
    '54': 36.5,
    '55': 36.4,
    '56': 36.3,
    '57': 36.2,
    '58': 36.2,
    '59': 36.1,
    '60': 36,
    '61': 35.9,
    '62': 35.8,
    '63': 35.8,
    '64': 35.7,
    '65': 35.6,
    '66': 35.5,
    '67': 35.5,
    '68': 35.4,
    '69': 35.3,
    '70': 35.2,
    '71': 35.1,
    '72': 35.1,
    '73': 35,
    '74': 34.9,
    '75': 34.8,
    '76': 34.8,
    '77': 34.7,
    '78': 34.6,
    '79': 34.5,
    '80': 34.5,
    '81': 34.4,
    '82': 34.3,
    '83': 34.2,
    '84': 34.2,
    '85': 34.1,
    '86': 34,
    '87': 33.9,
    '88': 33.9,
    '89': 33.8,
    '90': 33.7,
    '91': 33.6,
    '92': 33.6,
    '93': 33.5,
    '94': 33.4,
    '95': 33.3,
    '96': 33.3,
    '97': 33.2,
    '98': 33.1,
    '99': 33,
    '100': 33,
    '101': 32.9,
    '102': 32.8,
    '103': 32.8,
    '104': 32.7,
    '105': 32.6,
    '106': 32.5,
    '107': 32.5,
    '108': 32.4,
    '109': 32.3,
    '110': 32.2,
    '111': 32.2,
    '112': 32.1,
    '113': 32,
    '114': 32,
    '115': 31.9,
    '116': 31.8,
    '117': 31.7,
    '118': 31.7,
    '119': 31.6,
    '120': 31.5,
    '121': 31.5,
    '122': 31.4,
    '123': 31.3,
    '124': 31.3,
    '125': 31.2,
    '126': 31.1,
    '127': 31,
    '128': 31,
    '129': 30.9,
    '130': 30.8,
    '131': 30.8,
    '132': 30.7,
    '133': 30.6,
    '134': 30.6,
    '135': 30.5,
    '136': 30.4,
    '137': 30.3,
    '138': 30.3,
    '139': 30.2,
    '140': 30.1,
    '141': 30.1,
    '142': 30,
    '143': 29.9,
    '144': 29.9,
    '145': 29.8,
    '146': 29.7,
    '147': 29.7,
    '148': 29.6,
    '149': 29.5,
    '150': 29.5,
    '151': 29.4,
    '152': 29.3,
    '153': 29.3,
    '154': 29.2,
    '155': 29.1,
    '156': 29,
    '157': 29,
    '158': 28.9,
    '159': 28.8,
    '160': 28.8,
    '161': 28.7,
    '162': 28.6,
    '163': 28.6,
    '164': 28.5,
    '165': 28.4,
    '166': 28.4,
    '167': 28.3,
    '168': 28.3,
    '169': 28.2,
    '170': 28.1,
    '171': 28.1,
    '172': 28,
    '173': 27.9,
    '174': 27.9,
    '175': 27.8,
    '176': 27.7,
    '177': 27.7,
    '178': 27.6,
    '179': 27.5,
    '180': 27.5,
    '181': 27.4,
    '182': 27.3,
    '183': 27.3,
    '184': 27.2,
    '185': 27.1,
    '186': 27.1,
    '187': 27,
    '188': 27,
    '189': 26.9,
    '190': 26.8,
    '191': 26.8,
    '192': 26.7,
    '193': 26.6,
    '194': 26.6,
    '195': 26.5,
    '0.5': 40.9,
    '1.5': 40.8,
    '2.5': 40.7,
    '3.5': 40.6,
    '4.5': 40.5,
    '5.5': 40.5,
    '6.5': 40.4,
    '7.5': 40.3,
    '8.5': 40.2,
    '9.5': 40.1,
    '10.5': 40,
    '11.5': 39.9,
    '12.5': 39.9,
    '13.5': 39.8,
    '14.5': 39.7,
    '15.5': 39.6,
    '16.5': 39.5,
    '17.5': 39.4,
    '18.5': 39.4,
    '19.5': 39.3,
    '20.5': 39.2,
    '21.5': 39.1,
    '22.5': 39,
    '23.5': 38.9,
    '24.5': 38.9,
    '25.5': 38.8,
    '26.5': 38.7,
    '27.5': 38.6,
    '28.5': 38.5,
    '29.5': 38.4,
    '30.5': 38.4,
    '31.5': 38.3,
    '32.5': 38.2,
    '33.5': 38.1,
    '34.5': 38,
    '35.5': 38,
    '36.5': 37.9,
    '37.5': 37.8,
    '38.5': 37.7,
    '39.5': 37.6,
    '40.5': 37.5,
    '41.5': 37.5,
    '42.5': 37.4,
    '43.5': 37.3,
    '44.5': 37.2,
    '45.5': 37.1,
    '46.5': 37.1,
    '47.5': 37,
    '48.5': 36.9,
    '49.5': 36.8,
    '50.5': 36.7,
    '51.5': 36.7,
    '52.5': 36.6,
    '53.5': 36.5,
    '54.5': 36.4,
    '55.5': 36.4,
    '56.5': 36.3,
    '57.5': 36.2,
    '58.5': 36.1,
    '59.5': 36,
    '60.5': 36,
    '61.5': 35.9,
    '62.5': 35.8,
    '63.5': 35.7,
    '64.5': 35.6,
    '65.5': 35.6,
    '66.5': 35.5,
    '67.5': 35.4,
    '68.5': 35.3,
    '69.5': 35.3,
    '70.5': 35.2,
    '71.5': 35.1,
    '72.5': 35,
    '73.5': 35,
    '74.5': 34.9,
    '75.5': 34.8,
    '76.5': 34.7,
    '77.5': 34.7,
    '78.5': 34.6,
    '79.5': 34.5,
    '80.5': 34.4,
    '81.5': 34.3,
    '82.5': 34.3,
    '83.5': 34.2,
    '84.5': 34.1,
    '85.5': 34.1,
    '86.5': 34,
    '87.5': 33.9,
    '88.5': 33.8,
    '89.5': 33.8,
    '90.5': 33.7,
    '91.5': 33.6,
    '92.5': 33.5,
    '93.5': 33.5,
    '94.5': 33.4,
    '95.5': 33.3,
    '96.5': 33.2,
    '97.5': 33.2,
    '98.5': 33.1,
    '99.5': 33,
    '100.5': 32.9,
    '101.5': 32.9,
    '102.5': 32.8,
    '103.5': 32.7,
    '104.5': 32.6,
    '105.5': 32.6,
    '106.5': 32.5,
    '107.5': 32.4,
    '108.5': 32.4,
    '109.5': 32.3,
    '110.5': 32.2,
    '111.5': 32.1,
    '112.5': 32.1,
    '113.5': 32,
    '114.5': 31.9,
    '115.5': 31.9,
    '116.5': 31.8,
    '117.5': 31.7,
    '118.5': 31.6,
    '119.5': 31.6,
    '120.5': 31.5,
    '121.5': 31.4,
    '122.5': 31.4,
    '123.5': 31.3,
    '124.5': 31.2,
    '125.5': 31.1,
    '126.5': 31.1,
    '127.5': 31,
    '128.5': 30.9,
    '129.5': 30.9,
    '130.5': 30.8,
    '131.5': 30.7,
    '132.5': 30.7,
    '133.5': 30.6,
    '134.5': 30.5,
    '135.5': 30.4,
    '136.5': 30.4,
    '137.5': 30.3,
    '138.5': 30.2,
    '139.5': 30.2,
    '140.5': 30.1,
    '141.5': 30,
    '142.5': 30,
    '143.5': 29.9,
    '144.5': 29.8,
    '145.5': 29.8,
    '146.5': 29.7,
    '147.5': 29.6,
    '148.5': 29.6,
    '149.5': 29.5,
    '150.5': 29.4,
    '151.5': 29.4,
    '152.5': 29.3,
    '153.5': 29.2,
    '154.5': 29.2,
    '155.5': 29.1,
    '156.5': 29,
    '157.5': 28.9,
    '158.5': 28.9,
    '159.5': 28.8,
    '160.5': 28.8,
    '161.5': 28.7,
    '162.5': 28.6,
    '163.5': 28.5,
    '164.5': 28.5,
    '165.5': 28.4,
    '166.5': 28.4,
    '167.5': 28.3,
    '168.5': 28.2,
    '169.5': 28.2,
    '170.5': 28.1,
    '171.5': 28,
    '172.5': 28,
    '173.5': 27.9,
    '174.5': 27.8,
    '175.5': 27.8,
    '176.5': 27.7,
    '177.5': 27.6,
    '178.5': 27.6,
    '179.5': 27.5,
    '180.5': 27.4,
    '181.5': 27.4,
    '182.5': 27.3,
    '183.5': 27.2,
    '184.5': 27.2,
    '185.5': 27.1,
    '186.5': 27.1,
    '187.5': 27,
    '188.5': 26.9,
    '189.5': 26.9,
    '190.5': 26.8,
    '191.5': 26.7,
    '192.5': 26.7,
    '193.5': 26.6,
    '194.5': 26.5,
  },
  '36.5': {
    '0': 41.5,
    '1': 41.4,
    '2': 41.3,
    '3': 41.2,
    '4': 41.1,
    '5': 41,
    '6': 40.9,
    '7': 40.9,
    '8': 40.8,
    '9': 40.7,
    '10': 40.6,
    '11': 40.5,
    '12': 40.4,
    '13': 40.3,
    '14': 40.3,
    '15': 40.2,
    '16': 40.1,
    '17': 40,
    '18': 39.9,
    '19': 39.8,
    '20': 39.8,
    '21': 39.7,
    '22': 39.6,
    '23': 39.5,
    '24': 39.4,
    '25': 39.3,
    '26': 39.3,
    '27': 39.2,
    '28': 39.1,
    '29': 39,
    '30': 38.9,
    '31': 38.8,
    '32': 38.8,
    '33': 38.7,
    '34': 38.6,
    '35': 38.5,
    '36': 38.4,
    '37': 38.3,
    '38': 38.3,
    '39': 38.2,
    '40': 38.1,
    '41': 38,
    '42': 37.9,
    '43': 37.9,
    '44': 37.8,
    '45': 37.7,
    '46': 37.6,
    '47': 37.5,
    '48': 37.5,
    '49': 37.4,
    '50': 37.3,
    '51': 37.2,
    '52': 37.1,
    '53': 37.1,
    '54': 37,
    '55': 36.9,
    '56': 36.8,
    '57': 36.7,
    '58': 36.7,
    '59': 36.6,
    '60': 36.5,
    '61': 36.4,
    '62': 36.3,
    '63': 36.2,
    '64': 36.1,
    '65': 36.1,
    '66': 36,
    '67': 35.9,
    '68': 35.8,
    '69': 35.8,
    '70': 35.7,
    '71': 35.6,
    '72': 35.5,
    '73': 35.4,
    '74': 35.4,
    '75': 35.3,
    '76': 35.3,
    '77': 35.2,
    '78': 35.1,
    '79': 35,
    '80': 35,
    '81': 34.9,
    '82': 34.8,
    '83': 34.7,
    '84': 34.6,
    '85': 34.6,
    '86': 34.5,
    '87': 34.4,
    '88': 34.3,
    '89': 34.3,
    '90': 34.2,
    '91': 34.1,
    '92': 34,
    '93': 34,
    '94': 33.9,
    '95': 33.8,
    '96': 33.7,
    '97': 33.7,
    '98': 33.6,
    '99': 33.5,
    '100': 33.4,
    '101': 33.4,
    '102': 33.3,
    '103': 33.2,
    '104': 33.2,
    '105': 33.1,
    '106': 33,
    '107': 32.9,
    '108': 32.9,
    '109': 32.8,
    '110': 32.7,
    '111': 32.6,
    '112': 32.6,
    '113': 32.5,
    '114': 32.4,
    '115': 32.4,
    '116': 32.3,
    '117': 32.2,
    '118': 32.1,
    '119': 32.1,
    '120': 32,
    '121': 31.9,
    '122': 31.9,
    '123': 31.8,
    '124': 31.7,
    '125': 31.6,
    '126': 31.6,
    '127': 31.5,
    '128': 31.4,
    '129': 31.4,
    '130': 31.3,
    '131': 31.2,
    '132': 31.1,
    '133': 31.1,
    '134': 31,
    '135': 30.9,
    '136': 30.9,
    '137': 30.8,
    '138': 30.7,
    '139': 30.7,
    '140': 30.6,
    '141': 30.5,
    '142': 30.4,
    '143': 30.4,
    '144': 30.3,
    '145': 30.2,
    '146': 30.2,
    '147': 30.1,
    '148': 30,
    '149': 30,
    '150': 29.9,
    '151': 29.8,
    '152': 29.8,
    '153': 29.7,
    '154': 29.6,
    '155': 29.6,
    '156': 29.5,
    '157': 29.4,
    '158': 29.4,
    '159': 29.3,
    '160': 29.2,
    '161': 29.2,
    '162': 29.1,
    '163': 29,
    '164': 29,
    '165': 28.9,
    '166': 28.8,
    '167': 28.8,
    '168': 28.7,
    '169': 28.6,
    '170': 28.6,
    '171': 28.5,
    '172': 28.4,
    '173': 28.4,
    '174': 28.3,
    '175': 28.2,
    '176': 28.2,
    '177': 28.1,
    '178': 28,
    '179': 28,
    '180': 27.9,
    '181': 27.8,
    '182': 27.8,
    '183': 27.7,
    '184': 27.6,
    '185': 27.6,
    '186': 27.5,
    '187': 27.4,
    '188': 27.4,
    '189': 27.3,
    '190': 27.2,
    '191': 27.2,
    '192': 27.1,
    '193': 27.1,
    '194': 27,
    '195': 26.9,
    '0.5': 41.4,
    '1.5': 41.3,
    '2.5': 41.3,
    '3.5': 41.2,
    '4.5': 41.1,
    '5.5': 41,
    '6.5': 40.9,
    '7.5': 40.8,
    '8.5': 40.7,
    '9.5': 40.6,
    '10.5': 40.6,
    '11.5': 40.5,
    '12.5': 40.4,
    '13.5': 40.3,
    '14.5': 40.2,
    '15.5': 40.1,
    '16.5': 40.1,
    '17.5': 40,
    '18.5': 39.9,
    '19.5': 39.8,
    '20.5': 39.7,
    '21.5': 39.6,
    '22.5': 39.5,
    '23.5': 39.5,
    '24.5': 39.4,
    '25.5': 39.3,
    '26.5': 39.2,
    '27.5': 39.1,
    '28.5': 39,
    '29.5': 39,
    '30.5': 38.9,
    '31.5': 38.8,
    '32.5': 38.7,
    '33.5': 38.6,
    '34.5': 38.6,
    '35.5': 38.5,
    '36.5': 38.4,
    '37.5': 38.3,
    '38.5': 38.2,
    '39.5': 38.1,
    '40.5': 38.1,
    '41.5': 38,
    '42.5': 37.9,
    '43.5': 37.8,
    '44.5': 37.7,
    '45.5': 37.7,
    '46.5': 37.6,
    '47.5': 37.5,
    '48.5': 37.4,
    '49.5': 37.3,
    '50.5': 37.3,
    '51.5': 37.2,
    '52.5': 37.1,
    '53.5': 37,
    '54.5': 36.9,
    '55.5': 36.9,
    '56.5': 36.8,
    '57.5': 36.7,
    '58.5': 36.6,
    '59.5': 36.5,
    '60.5': 36.5,
    '61.5': 36.3,
    '62.5': 36.3,
    '63.5': 36.2,
    '64.5': 36.1,
    '65.5': 36,
    '66.5': 36,
    '67.5': 35.9,
    '68.5': 35.8,
    '69.5': 35.7,
    '70.5': 35.6,
    '71.5': 35.6,
    '72.5': 35.5,
    '73.5': 35.4,
    '74.5': 35.3,
    '75.5': 35.3,
    '76.5': 35.2,
    '77.5': 35.1,
    '78.5': 35.1,
    '79.5': 35,
    '80.5': 34.9,
    '81.5': 34.8,
    '82.5': 34.8,
    '83.5': 34.7,
    '84.5': 34.6,
    '85.5': 34.5,
    '86.5': 34.5,
    '87.5': 34.4,
    '88.5': 34.3,
    '89.5': 34.2,
    '90.5': 34.2,
    '91.5': 34.1,
    '92.5': 34,
    '93.5': 33.9,
    '94.5': 33.9,
    '95.5': 33.8,
    '96.5': 33.7,
    '97.5': 33.6,
    '98.5': 33.6,
    '99.5': 33.5,
    '100.5': 33.4,
    '101.5': 33.3,
    '102.5': 33.3,
    '103.5': 33.2,
    '104.5': 33.1,
    '105.5': 33,
    '106.5': 33,
    '107.5': 32.9,
    '108.5': 32.8,
    '109.5': 32.8,
    '110.5': 32.7,
    '111.5': 32.6,
    '112.5': 32.5,
    '113.5': 32.5,
    '114.5': 32.4,
    '115.5': 32.3,
    '116.5': 32.2,
    '117.5': 32.2,
    '118.5': 32.1,
    '119.5': 32,
    '120.5': 32,
    '121.5': 31.9,
    '122.5': 31.8,
    '123.5': 31.7,
    '124.5': 31.7,
    '125.5': 31.6,
    '126.5': 31.5,
    '127.5': 31.5,
    '128.5': 31.4,
    '129.5': 31.3,
    '130.5': 31.2,
    '131.5': 31.2,
    '132.5': 31.1,
    '133.5': 31,
    '134.5': 31,
    '135.5': 30.9,
    '136.5': 30.8,
    '137.5': 30.8,
    '138.5': 30.7,
    '139.5': 30.6,
    '140.5': 30.6,
    '141.5': 30.5,
    '142.5': 30.4,
    '143.5': 30.3,
    '144.5': 30.3,
    '145.5': 30.2,
    '146.5': 30.1,
    '147.5': 30.1,
    '148.5': 30,
    '149.5': 29.9,
    '150.5': 29.9,
    '151.5': 29.8,
    '152.5': 29.7,
    '153.5': 29.7,
    '154.5': 29.6,
    '155.5': 29.5,
    '156.5': 29.5,
    '157.5': 29.4,
    '158.5': 29.3,
    '159.5': 29.3,
    '160.5': 29.2,
    '161.5': 29.1,
    '162.5': 29.1,
    '163.5': 29,
    '164.5': 28.9,
    '165.5': 28.9,
    '166.5': 28.8,
    '167.5': 28.7,
    '168.5': 28.7,
    '169.5': 28.6,
    '170.5': 28.5,
    '171.5': 28.5,
    '172.5': 28.4,
    '173.5': 28.3,
    '174.5': 28.3,
    '175.5': 28.2,
    '176.5': 28.1,
    '177.5': 28.1,
    '178.5': 28,
    '179.5': 27.9,
    '180.5': 27.9,
    '181.5': 27.8,
    '182.5': 27.7,
    '183.5': 27.7,
    '184.5': 27.6,
    '185.5': 27.5,
    '186.5': 27.5,
    '187.5': 27.4,
    '188.5': 27.3,
    '189.5': 27.3,
    '190.5': 27.2,
    '191.5': 27.1,
    '192.5': 27.1,
    '193.5': 27,
    '194.5': 27,
  },
  '37': {
    '0': 42,
    '1': 41.9,
    '2': 41.8,
    '3': 41.8,
    '4': 41.7,
    '5': 41.6,
    '6': 41.5,
    '7': 41.4,
    '8': 41.3,
    '9': 41.2,
    '10': 41.1,
    '11': 41.1,
    '12': 41,
    '13': 40.9,
    '14': 40.8,
    '15': 40.7,
    '16': 40.6,
    '17': 40.5,
    '18': 40.5,
    '19': 40.4,
    '20': 40.3,
    '21': 40.2,
    '22': 40.1,
    '23': 40,
    '24': 39.9,
    '25': 39.9,
    '26': 39.8,
    '27': 39.7,
    '28': 39.6,
    '29': 39.5,
    '30': 39.4,
    '31': 39.4,
    '32': 39.3,
    '33': 39.2,
    '34': 39.1,
    '35': 39,
    '36': 38.9,
    '37': 38.9,
    '38': 38.8,
    '39': 38.7,
    '40': 38.6,
    '41': 38.5,
    '42': 38.5,
    '43': 38.4,
    '44': 38.3,
    '45': 38.2,
    '46': 38.1,
    '47': 38,
    '48': 38,
    '49': 37.9,
    '50': 37.8,
    '51': 37.7,
    '52': 37.6,
    '53': 37.6,
    '54': 37.5,
    '55': 37.4,
    '56': 37.3,
    '57': 37.2,
    '58': 37.2,
    '59': 37.1,
    '60': 37,
    '61': 36.9,
    '62': 36.8,
    '63': 36.8,
    '64': 36.7,
    '65': 36.6,
    '66': 36.5,
    '67': 36.4,
    '68': 36.4,
    '69': 36.3,
    '70': 36.2,
    '71': 36.1,
    '72': 36.1,
    '73': 36,
    '74': 35.9,
    '75': 35.8,
    '76': 35.7,
    '77': 35.7,
    '78': 35.6,
    '79': 35.5,
    '80': 35.4,
    '81': 35.4,
    '82': 35.3,
    '83': 35.2,
    '84': 35.1,
    '85': 35.1,
    '86': 35,
    '87': 34.9,
    '88': 34.8,
    '89': 34.7,
    '90': 34.7,
    '91': 34.6,
    '92': 34.5,
    '93': 34.4,
    '94': 34.4,
    '95': 34.3,
    '96': 34.2,
    '97': 34.1,
    '98': 34.1,
    '99': 34,
    '100': 33.9,
    '101': 33.8,
    '102': 33.8,
    '103': 33.7,
    '104': 33.6,
    '105': 33.5,
    '106': 33.5,
    '107': 33.4,
    '108': 33.3,
    '109': 33.3,
    '110': 33.2,
    '111': 33.1,
    '112': 33,
    '113': 33,
    '114': 32.9,
    '115': 32.8,
    '116': 32.7,
    '117': 32.7,
    '118': 32.6,
    '119': 32.5,
    '120': 32.5,
    '121': 32.4,
    '122': 32.3,
    '123': 32.2,
    '124': 32.2,
    '125': 32.1,
    '126': 32,
    '127': 32,
    '128': 31.9,
    '129': 31.8,
    '130': 31.7,
    '131': 31.7,
    '132': 31.6,
    '133': 31.5,
    '134': 31.5,
    '135': 31.4,
    '136': 31.3,
    '137': 31.2,
    '138': 31.2,
    '139': 31.1,
    '140': 31,
    '141': 31,
    '142': 30.9,
    '143': 30.8,
    '144': 30.8,
    '145': 30.7,
    '146': 30.6,
    '147': 30.5,
    '148': 30.5,
    '149': 30.4,
    '150': 30.3,
    '151': 30.3,
    '152': 30.2,
    '153': 30.1,
    '154': 30.1,
    '155': 30,
    '156': 29.9,
    '157': 29.9,
    '158': 29.8,
    '159': 29.7,
    '160': 29.7,
    '161': 29.6,
    '162': 29.5,
    '163': 29.5,
    '164': 29.4,
    '165': 29.3,
    '166': 29.2,
    '167': 29.2,
    '168': 29.1,
    '169': 29.1,
    '170': 29,
    '171': 28.9,
    '172': 28.9,
    '173': 28.8,
    '174': 28.7,
    '175': 28.7,
    '176': 28.6,
    '177': 28.5,
    '178': 28.5,
    '179': 28.4,
    '180': 28.3,
    '181': 28.3,
    '182': 28.2,
    '183': 28.1,
    '184': 28.1,
    '185': 28,
    '186': 27.9,
    '187': 27.9,
    '188': 27.8,
    '189': 27.7,
    '190': 27.7,
    '191': 27.6,
    '192': 27.5,
    '193': 27.5,
    '194': 27.4,
    '195': 27.3,
    '0.5': 42,
    '1.5': 41.9,
    '2.5': 41.8,
    '3.5': 41.7,
    '4.5': 41.6,
    '5.5': 41.5,
    '6.5': 41.4,
    '7.5': 41.4,
    '8.5': 41.3,
    '9.5': 41.2,
    '10.5': 41.1,
    '11.5': 41,
    '12.5': 40.9,
    '13.5': 40.8,
    '14.5': 40.8,
    '15.5': 40.7,
    '16.5': 40.6,
    '17.5': 40.5,
    '18.5': 40.4,
    '19.5': 40.3,
    '20.5': 40.2,
    '21.5': 40.2,
    '22.5': 40.1,
    '23.5': 40,
    '24.5': 39.9,
    '25.5': 39.8,
    '26.5': 39.7,
    '27.5': 39.7,
    '28.5': 39.6,
    '29.5': 39.5,
    '30.5': 39.4,
    '31.5': 39.3,
    '32.5': 39.2,
    '33.5': 39.2,
    '34.5': 39.1,
    '35.5': 39,
    '36.5': 38.9,
    '37.5': 38.8,
    '38.5': 38.7,
    '39.5': 38.7,
    '40.5': 38.6,
    '41.5': 38.5,
    '42.5': 38.4,
    '43.5': 38.3,
    '44.5': 38.2,
    '45.5': 38.2,
    '46.5': 38.1,
    '47.5': 38,
    '48.5': 37.9,
    '49.5': 37.8,
    '50.5': 37.8,
    '51.5': 37.7,
    '52.5': 37.6,
    '53.5': 37.5,
    '54.5': 37.4,
    '55.5': 37.4,
    '56.5': 37.3,
    '57.5': 37.2,
    '58.5': 37.1,
    '59.5': 37,
    '60.5': 37,
    '61.5': 36.9,
    '62.5': 36.8,
    '63.5': 36.7,
    '64.5': 36.6,
    '65.5': 36.6,
    '66.5': 36.5,
    '67.5': 36.4,
    '68.5': 36.3,
    '69.5': 36.3,
    '70.5': 36.2,
    '71.5': 36.1,
    '72.5': 36,
    '73.5': 35.9,
    '74.5': 35.9,
    '75.5': 35.8,
    '76.5': 35.7,
    '77.5': 35.6,
    '78.5': 35.6,
    '79.5': 35.5,
    '80.5': 35.4,
    '81.5': 35.3,
    '82.5': 35.2,
    '83.5': 35.2,
    '84.5': 35.1,
    '85.5': 35,
    '86.5': 34.9,
    '87.5': 34.9,
    '88.5': 34.8,
    '89.5': 34.7,
    '90.5': 34.6,
    '91.5': 34.6,
    '92.5': 34.5,
    '93.5': 34.4,
    '94.5': 34.3,
    '95.5': 34.3,
    '96.5': 34.2,
    '97.5': 34.1,
    '98.5': 34,
    '99.5': 34,
    '100.5': 33.9,
    '101.5': 33.8,
    '102.5': 33.7,
    '103.5': 33.7,
    '104.5': 33.6,
    '105.5': 33.5,
    '106.5': 33.4,
    '107.5': 33.4,
    '108.5': 33.3,
    '109.5': 33.2,
    '110.5': 33.1,
    '111.5': 33.1,
    '112.5': 33,
    '113.5': 32.9,
    '114.5': 32.9,
    '115.5': 32.8,
    '116.5': 32.7,
    '117.5': 32.6,
    '118.5': 32.6,
    '119.5': 32.5,
    '120.5': 32.4,
    '121.5': 32.3,
    '122.5': 32.3,
    '123.5': 32.2,
    '124.5': 32.1,
    '125.5': 32.1,
    '126.5': 32,
    '127.5': 31.9,
    '128.5': 31.8,
    '129.5': 31.8,
    '130.5': 31.7,
    '131.5': 31.6,
    '132.5': 31.6,
    '133.5': 31.5,
    '134.5': 31.4,
    '135.5': 31.3,
    '136.5': 31.3,
    '137.5': 31.2,
    '138.5': 31.1,
    '139.5': 31.1,
    '140.5': 31,
    '141.5': 30.9,
    '142.5': 30.9,
    '143.5': 30.8,
    '144.5': 30.7,
    '145.5': 30.6,
    '146.5': 30.6,
    '147.5': 30.5,
    '148.5': 30.4,
    '149.5': 30.4,
    '150.5': 30.3,
    '151.5': 30.2,
    '152.5': 30.2,
    '153.5': 30.1,
    '154.5': 30,
    '155.5': 30,
    '156.5': 29.9,
    '157.5': 29.8,
    '158.5': 29.8,
    '159.5': 29.7,
    '160.5': 29.6,
    '161.5': 29.6,
    '162.5': 29.5,
    '163.5': 29.4,
    '164.5': 29.4,
    '165.5': 29.3,
    '166.5': 29.2,
    '167.5': 29.2,
    '168.5': 29.1,
    '169.5': 29,
    '170.5': 29,
    '171.5': 28.9,
    '172.5': 28.8,
    '173.5': 28.8,
    '174.5': 28.7,
    '175.5': 28.6,
    '176.5': 28.6,
    '177.5': 28.5,
    '178.5': 28.4,
    '179.5': 28.4,
    '180.5': 28.3,
    '181.5': 28.2,
    '182.5': 28.2,
    '183.5': 28.1,
    '184.5': 28,
    '185.5': 28,
    '186.5': 27.9,
    '187.5': 27.8,
    '188.5': 27.8,
    '189.5': 27.7,
    '190.5': 27.6,
    '191.5': 27.6,
    '192.5': 27.5,
    '193.5': 27.4,
    '194.5': 27.4,
  },
  '37.5': {
    '0': 42.6,
    '1': 42.5,
    '2': 42.4,
    '3': 42.3,
    '4': 42.2,
    '5': 42.1,
    '6': 42,
    '7': 41.9,
    '8': 41.9,
    '9': 41.8,
    '10': 41.7,
    '11': 41.6,
    '12': 41.5,
    '13': 41.4,
    '14': 41.3,
    '15': 41.2,
    '16': 41.2,
    '17': 41.1,
    '18': 41,
    '19': 40.9,
    '20': 40.8,
    '21': 40.7,
    '22': 40.6,
    '23': 40.6,
    '24': 40.5,
    '25': 40.4,
    '26': 40.3,
    '27': 40.2,
    '28': 40.1,
    '29': 40.1,
    '30': 40,
    '31': 39.9,
    '32': 39.8,
    '33': 39.7,
    '34': 39.6,
    '35': 39.5,
    '36': 39.5,
    '37': 39.4,
    '38': 39.3,
    '39': 39.2,
    '40': 39.1,
    '41': 39,
    '42': 39,
    '43': 38.9,
    '44': 38.8,
    '45': 38.7,
    '46': 38.6,
    '47': 38.6,
    '48': 38.5,
    '49': 38.4,
    '50': 38.3,
    '51': 38.2,
    '52': 38.1,
    '53': 38.1,
    '54': 38,
    '55': 37.9,
    '56': 37.8,
    '57': 37.7,
    '58': 37.7,
    '59': 37.6,
    '60': 37.5,
    '61': 37.4,
    '62': 37.3,
    '63': 37.3,
    '64': 37.2,
    '65': 37.1,
    '66': 37,
    '67': 36.9,
    '68': 36.9,
    '69': 36.8,
    '70': 36.7,
    '71': 36.6,
    '72': 36.5,
    '73': 36.5,
    '74': 36.4,
    '75': 36.3,
    '76': 36.2,
    '77': 36.2,
    '78': 36.1,
    '79': 36,
    '80': 35.9,
    '81': 35.8,
    '82': 35.8,
    '83': 35.7,
    '84': 35.6,
    '85': 35.5,
    '86': 35.5,
    '87': 35.4,
    '88': 35.3,
    '89': 35.2,
    '90': 35.2,
    '91': 35.1,
    '92': 35,
    '93': 34.9,
    '94': 34.8,
    '95': 34.8,
    '96': 34.7,
    '97': 34.6,
    '98': 34.5,
    '99': 34.5,
    '100': 34.4,
    '101': 34.3,
    '102': 34.2,
    '103': 34.2,
    '104': 34.1,
    '105': 34,
    '106': 33.9,
    '107': 33.9,
    '108': 33.8,
    '109': 33.7,
    '110': 33.6,
    '111': 33.6,
    '112': 33.5,
    '113': 33.4,
    '114': 33.4,
    '115': 33.3,
    '116': 33.2,
    '117': 33.1,
    '118': 33.1,
    '119': 33,
    '120': 32.9,
    '121': 32.8,
    '122': 32.8,
    '123': 32.7,
    '124': 32.6,
    '125': 32.6,
    '126': 32.5,
    '127': 32.4,
    '128': 32.3,
    '129': 32.3,
    '130': 32.2,
    '131': 32.1,
    '132': 32,
    '133': 32,
    '134': 31.9,
    '135': 31.8,
    '136': 31.8,
    '137': 31.7,
    '138': 31.6,
    '139': 31.6,
    '140': 31.5,
    '141': 31.4,
    '142': 31.3,
    '143': 31.3,
    '144': 31.2,
    '145': 31.1,
    '146': 31.1,
    '147': 31,
    '148': 30.9,
    '149': 30.9,
    '150': 30.8,
    '151': 30.7,
    '152': 30.6,
    '153': 30.6,
    '154': 30.5,
    '155': 30.4,
    '156': 30.4,
    '157': 30.3,
    '158': 30.2,
    '159': 30.2,
    '160': 30.1,
    '161': 30,
    '162': 30,
    '163': 29.9,
    '164': 29.8,
    '165': 29.8,
    '166': 29.7,
    '167': 29.6,
    '168': 29.5,
    '169': 29.5,
    '170': 29.4,
    '171': 29.3,
    '172': 29.3,
    '173': 29.2,
    '174': 29.1,
    '175': 29.1,
    '176': 29,
    '177': 28.9,
    '178': 28.9,
    '179': 28.8,
    '180': 28.7,
    '181': 28.7,
    '182': 28.6,
    '183': 28.5,
    '184': 28.5,
    '185': 28.4,
    '186': 28.4,
    '187': 28.3,
    '188': 28.2,
    '189': 28.2,
    '190': 28.1,
    '191': 28,
    '192': 28,
    '193': 27.9,
    '194': 27.8,
    '195': 27.8,
    '0.5': 42.5,
    '1.5': 42.4,
    '2.5': 42.3,
    '3.5': 42.3,
    '4.5': 42.2,
    '5.5': 42.1,
    '6.5': 42,
    '7.5': 41.9,
    '8.5': 41.8,
    '9.5': 41.7,
    '10.5': 41.6,
    '11.5': 41.6,
    '12.5': 41.5,
    '13.5': 41.4,
    '14.5': 41.3,
    '15.5': 41.2,
    '16.5': 41.1,
    '17.5': 41,
    '18.5': 40.9,
    '19.5': 40.9,
    '20.5': 40.8,
    '21.5': 40.7,
    '22.5': 40.6,
    '23.5': 40.5,
    '24.5': 40.4,
    '25.5': 40.3,
    '26.5': 40.3,
    '27.5': 40.2,
    '28.5': 40.1,
    '29.5': 40,
    '30.5': 39.9,
    '31.5': 39.8,
    '32.5': 39.8,
    '33.5': 39.7,
    '34.5': 39.6,
    '35.5': 39.5,
    '36.5': 39.4,
    '37.5': 39.3,
    '38.5': 39.3,
    '39.5': 39.2,
    '40.5': 39.1,
    '41.5': 39,
    '42.5': 38.9,
    '43.5': 38.8,
    '44.5': 38.8,
    '45.5': 38.7,
    '46.5': 38.6,
    '47.5': 38.5,
    '48.5': 38.4,
    '49.5': 38.4,
    '50.5': 38.3,
    '51.5': 38.2,
    '52.5': 38.1,
    '53.5': 38,
    '54.5': 37.9,
    '55.5': 37.9,
    '56.5': 37.8,
    '57.5': 37.7,
    '58.5': 37.6,
    '59.5': 37.5,
    '60.5': 37.5,
    '61.5': 37.4,
    '62.5': 37.3,
    '63.5': 37.2,
    '64.5': 37.1,
    '65.5': 37.1,
    '66.5': 37,
    '67.5': 36.9,
    '68.5': 36.8,
    '69.5': 36.7,
    '70.5': 36.7,
    '71.5': 36.6,
    '72.5': 36.5,
    '73.5': 36.4,
    '74.5': 36.4,
    '75.5': 36.3,
    '76.5': 36.2,
    '77.5': 36.1,
    '78.5': 36,
    '79.5': 36,
    '80.5': 35.9,
    '81.5': 35.8,
    '82.5': 35.7,
    '83.5': 35.6,
    '84.5': 35.6,
    '85.5': 35.5,
    '86.5': 35.4,
    '87.5': 35.3,
    '88.5': 35.3,
    '89.5': 35.2,
    '90.5': 35.1,
    '91.5': 35,
    '92.5': 35,
    '93.5': 34.9,
    '94.5': 34.8,
    '95.5': 34.7,
    '96.5': 34.7,
    '97.5': 34.6,
    '98.5': 34.5,
    '99.5': 34.4,
    '100.5': 34.4,
    '101.5': 34.3,
    '102.5': 34.2,
    '103.5': 34.1,
    '104.5': 34.1,
    '105.5': 34,
    '106.5': 33.9,
    '107.5': 33.8,
    '108.5': 33.8,
    '109.5': 33.7,
    '110.5': 33.6,
    '111.5': 33.5,
    '112.5': 33.5,
    '113.5': 33.4,
    '114.5': 33.3,
    '115.5': 33.2,
    '116.5': 33.2,
    '117.5': 33.1,
    '118.5': 33,
    '119.5': 33,
    '120.5': 32.9,
    '121.5': 32.8,
    '122.5': 32.7,
    '123.5': 32.7,
    '124.5': 32.6,
    '125.5': 32.5,
    '126.5': 32.4,
    '127.5': 32.4,
    '128.5': 32.3,
    '129.5': 32.2,
    '130.5': 32.2,
    '131.5': 32.1,
    '132.5': 32,
    '133.5': 31.9,
    '134.5': 31.9,
    '135.5': 31.8,
    '136.5': 31.7,
    '137.5': 31.7,
    '138.5': 31.6,
    '139.5': 31.5,
    '140.5': 31.4,
    '141.5': 31.4,
    '142.5': 31.3,
    '143.5': 31.2,
    '144.5': 31.2,
    '145.5': 31.1,
    '146.5': 31,
    '147.5': 31,
    '148.5': 30.9,
    '149.5': 30.8,
    '150.5': 30.7,
    '151.5': 30.7,
    '152.5': 30.6,
    '153.5': 30.5,
    '154.5': 30.5,
    '155.5': 30.4,
    '156.5': 30.3,
    '157.5': 30.3,
    '158.5': 30.2,
    '159.5': 30.1,
    '160.5': 30.1,
    '161.5': 30,
    '162.5': 29.9,
    '163.5': 29.9,
    '164.5': 29.8,
    '165.5': 29.7,
    '166.5': 29.7,
    '167.5': 29.6,
    '168.5': 29.5,
    '169.5': 29.4,
    '170.5': 29.4,
    '171.5': 29.3,
    '172.5': 29.2,
    '173.5': 29.2,
    '174.5': 29.1,
    '175.5': 29,
    '176.5': 29,
    '177.5': 28.9,
    '178.5': 28.8,
    '179.5': 28.8,
    '180.5': 28.7,
    '181.5': 28.6,
    '182.5': 28.6,
    '183.5': 28.5,
    '184.5': 28.4,
    '185.5': 28.4,
    '186.5': 28.3,
    '187.5': 28.3,
    '188.5': 28.2,
    '189.5': 28.1,
    '190.5': 28.1,
    '191.5': 28,
    '192.5': 27.9,
    '193.5': 27.9,
    '194.5': 27.8,
  },
  '38': {
    '0': 43.1,
    '1': 43,
    '2': 42.9,
    '3': 42.8,
    '4': 42.8,
    '5': 42.7,
    '6': 42.6,
    '7': 42.5,
    '8': 42.4,
    '9': 42.3,
    '10': 42.2,
    '11': 42.1,
    '12': 42,
    '13': 42,
    '14': 41.9,
    '15': 41.8,
    '16': 41.7,
    '17': 41.6,
    '18': 41.5,
    '19': 41.4,
    '20': 41.4,
    '21': 41.3,
    '22': 41.2,
    '23': 41.1,
    '24': 41,
    '25': 40.9,
    '26': 40.8,
    '27': 40.7,
    '28': 40.7,
    '29': 40.6,
    '30': 40.5,
    '31': 40.4,
    '32': 40.3,
    '33': 40.2,
    '34': 40.2,
    '35': 40.1,
    '36': 40,
    '37': 39.9,
    '38': 39.8,
    '39': 39.7,
    '40': 39.6,
    '41': 39.6,
    '42': 39.5,
    '43': 39.4,
    '44': 39.3,
    '45': 39.2,
    '46': 39.1,
    '47': 39.1,
    '48': 39,
    '49': 38.9,
    '50': 38.8,
    '51': 38.7,
    '52': 38.7,
    '53': 38.6,
    '54': 38.5,
    '55': 38.4,
    '56': 38.3,
    '57': 38.2,
    '58': 38.2,
    '59': 38.1,
    '60': 38,
    '61': 37.9,
    '62': 37.8,
    '63': 37.8,
    '64': 37.7,
    '65': 37.6,
    '66': 37.5,
    '67': 37.4,
    '68': 37.4,
    '69': 37.3,
    '70': 37.2,
    '71': 37.1,
    '72': 37,
    '73': 37,
    '74': 36.9,
    '75': 36.8,
    '76': 36.7,
    '77': 36.6,
    '78': 36.6,
    '79': 36.5,
    '80': 36.4,
    '81': 36.3,
    '82': 36.3,
    '83': 36.2,
    '84': 36.1,
    '85': 36,
    '86': 35.9,
    '87': 35.9,
    '88': 35.8,
    '89': 35.7,
    '90': 35.6,
    '91': 35.6,
    '92': 35.5,
    '93': 35.4,
    '94': 35.3,
    '95': 35.2,
    '96': 35.2,
    '97': 35.1,
    '98': 35,
    '99': 34.9,
    '100': 34.9,
    '101': 34.8,
    '102': 34.7,
    '103': 34.6,
    '104': 34.6,
    '105': 34.5,
    '106': 34.4,
    '107': 34.3,
    '108': 34.3,
    '109': 34.2,
    '110': 34.1,
    '111': 34,
    '112': 34,
    '113': 33.9,
    '114': 33.8,
    '115': 33.7,
    '116': 33.7,
    '117': 33.6,
    '118': 33.5,
    '119': 33.4,
    '120': 33.4,
    '121': 33.3,
    '122': 33.2,
    '123': 33.2,
    '124': 33.1,
    '125': 33,
    '126': 32.9,
    '127': 32.9,
    '128': 32.8,
    '129': 32.7,
    '130': 32.6,
    '131': 32.6,
    '132': 32.5,
    '133': 32.4,
    '134': 32.4,
    '135': 32.3,
    '136': 32.2,
    '137': 32.1,
    '138': 32.1,
    '139': 32,
    '140': 31.9,
    '141': 31.9,
    '142': 31.8,
    '143': 31.7,
    '144': 31.6,
    '145': 31.6,
    '146': 31.5,
    '147': 31.4,
    '148': 31.4,
    '149': 31.3,
    '150': 31.2,
    '151': 31.2,
    '152': 31.1,
    '153': 31,
    '154': 30.9,
    '155': 30.9,
    '156': 30.8,
    '157': 30.7,
    '158': 30.7,
    '159': 30.6,
    '160': 30.5,
    '161': 30.5,
    '162': 30.4,
    '163': 30.3,
    '164': 30.3,
    '165': 30.2,
    '166': 30.1,
    '167': 30,
    '168': 30,
    '169': 29.9,
    '170': 29.8,
    '171': 29.8,
    '172': 29.7,
    '173': 29.6,
    '174': 29.6,
    '175': 29.5,
    '176': 29.4,
    '177': 29.4,
    '178': 29.3,
    '179': 29.2,
    '180': 29.2,
    '181': 29.1,
    '182': 29,
    '183': 29,
    '184': 28.9,
    '185': 28.8,
    '186': 28.8,
    '187': 28.7,
    '188': 28.6,
    '189': 28.6,
    '190': 28.5,
    '191': 28.4,
    '192': 28.4,
    '193': 28.3,
    '194': 28.2,
    '195': 28.2,
    '0.5': 43.1,
    '1.5': 43,
    '2.5': 42.9,
    '3.5': 42.8,
    '4.5': 42.7,
    '5.5': 42.6,
    '6.5': 42.5,
    '7.5': 42.4,
    '8.5': 42.4,
    '9.5': 42.3,
    '10.5': 42.2,
    '11.5': 42.1,
    '12.5': 42,
    '13.5': 41.9,
    '14.5': 41.8,
    '15.5': 41.7,
    '16.5': 41.7,
    '17.5': 41.6,
    '18.5': 41.5,
    '19.5': 41.4,
    '20.5': 41.3,
    '21.5': 41.2,
    '22.5': 41.1,
    '23.5': 41,
    '24.5': 41,
    '25.5': 40.9,
    '26.5': 40.8,
    '27.5': 40.7,
    '28.5': 40.6,
    '29.5': 40.5,
    '30.5': 40.4,
    '31.5': 40.4,
    '32.5': 40.3,
    '33.5': 40.2,
    '34.5': 40.1,
    '35.5': 40,
    '36.5': 39.9,
    '37.5': 39.9,
    '38.5': 39.8,
    '39.5': 39.7,
    '40.5': 39.6,
    '41.5': 39.5,
    '42.5': 39.4,
    '43.5': 39.4,
    '44.5': 39.3,
    '45.5': 39.2,
    '46.5': 39.1,
    '47.5': 39,
    '48.5': 38.9,
    '49.5': 38.9,
    '50.5': 38.8,
    '51.5': 38.7,
    '52.5': 38.6,
    '53.5': 38.5,
    '54.5': 38.4,
    '55.5': 38.4,
    '56.5': 38.3,
    '57.5': 38.2,
    '58.5': 38.1,
    '59.5': 38,
    '60.5': 38,
    '61.5': 37.9,
    '62.5': 37.8,
    '63.5': 37.7,
    '64.5': 37.6,
    '65.5': 37.6,
    '66.5': 37.5,
    '67.5': 37.4,
    '68.5': 37.3,
    '69.5': 37.2,
    '70.5': 37.2,
    '71.5': 37.1,
    '72.5': 37,
    '73.5': 36.9,
    '74.5': 36.8,
    '75.5': 36.8,
    '76.5': 36.7,
    '77.5': 36.6,
    '78.5': 36.5,
    '79.5': 36.4,
    '80.5': 36.4,
    '81.5': 36.3,
    '82.5': 36.2,
    '83.5': 36.1,
    '84.5': 36.1,
    '85.5': 36,
    '86.5': 35.9,
    '87.5': 35.8,
    '88.5': 35.7,
    '89.5': 35.7,
    '90.5': 35.6,
    '91.5': 35.5,
    '92.5': 35.4,
    '93.5': 35.4,
    '94.5': 35.3,
    '95.5': 35.2,
    '96.5': 35.1,
    '97.5': 35.1,
    '98.5': 35,
    '99.5': 34.9,
    '100.5': 34.8,
    '101.5': 34.8,
    '102.5': 34.7,
    '103.5': 34.6,
    '104.5': 34.5,
    '105.5': 34.5,
    '106.5': 34.4,
    '107.5': 34.3,
    '108.5': 34.2,
    '109.5': 34.2,
    '110.5': 34.1,
    '111.5': 34,
    '112.5': 33.9,
    '113.5': 33.9,
    '114.5': 33.8,
    '115.5': 33.7,
    '116.5': 33.6,
    '117.5': 33.6,
    '118.5': 33.5,
    '119.5': 33.4,
    '120.5': 33.3,
    '121.5': 33.3,
    '122.5': 33.2,
    '123.5': 33.1,
    '124.5': 33,
    '125.5': 33,
    '126.5': 32.9,
    '127.5': 32.8,
    '128.5': 32.8,
    '129.5': 32.7,
    '130.5': 32.6,
    '131.5': 32.5,
    '132.5': 32.5,
    '133.5': 32.4,
    '134.5': 32.3,
    '135.5': 32.2,
    '136.5': 32.2,
    '137.5': 32.1,
    '138.5': 32,
    '139.5': 32,
    '140.5': 31.9,
    '141.5': 31.8,
    '142.5': 31.8,
    '143.5': 31.7,
    '144.5': 31.6,
    '145.5': 31.5,
    '146.5': 31.5,
    '147.5': 31.4,
    '148.5': 31.3,
    '149.5': 31.3,
    '150.5': 31.2,
    '151.5': 31.1,
    '152.5': 31.1,
    '153.5': 31,
    '154.5': 30.9,
    '155.5': 30.8,
    '156.5': 30.8,
    '157.5': 30.7,
    '158.5': 30.6,
    '159.5': 30.6,
    '160.5': 30.5,
    '161.5': 30.4,
    '162.5': 30.4,
    '163.5': 30.3,
    '164.5': 30.2,
    '165.5': 30.2,
    '166.5': 30.1,
    '167.5': 30,
    '168.5': 29.9,
    '169.5': 29.9,
    '170.5': 29.8,
    '171.5': 29.7,
    '172.5': 29.7,
    '173.5': 29.6,
    '174.5': 29.5,
    '175.5': 29.5,
    '176.5': 29.4,
    '177.5': 29.3,
    '178.5': 29.3,
    '179.5': 29.2,
    '180.5': 29.1,
    '181.5': 29.1,
    '182.5': 29,
    '183.5': 28.9,
    '184.5': 28.9,
    '185.5': 28.8,
    '186.5': 28.7,
    '187.5': 28.7,
    '188.5': 28.6,
    '189.5': 28.5,
    '190.5': 28.5,
    '191.5': 28.4,
    '192.5': 28.3,
    '193.5': 28.3,
    '194.5': 28.2,
  },
  '38.5': {
    '0': 43.7,
    '1': 43.6,
    '2': 43.5,
    '3': 43.4,
    '4': 43.3,
    '5': 43.2,
    '6': 43.1,
    '7': 43,
    '8': 42.9,
    '9': 42.8,
    '10': 42.8,
    '11': 42.7,
    '12': 42.6,
    '13': 42.5,
    '14': 42.4,
    '15': 42.3,
    '16': 42.2,
    '17': 42.1,
    '18': 42.1,
    '19': 42,
    '20': 41.9,
    '21': 41.8,
    '22': 41.7,
    '23': 41.6,
    '24': 41.5,
    '25': 41.4,
    '26': 41.4,
    '27': 41.3,
    '28': 41.2,
    '29': 41.1,
    '30': 41,
    '31': 40.9,
    '32': 40.8,
    '33': 40.8,
    '34': 40.7,
    '35': 40.6,
    '36': 40.5,
    '37': 40.4,
    '38': 40.3,
    '39': 40.2,
    '40': 40.2,
    '41': 40.1,
    '42': 40,
    '43': 39.9,
    '44': 39.8,
    '45': 39.7,
    '46': 39.7,
    '47': 39.6,
    '48': 39.5,
    '49': 39.4,
    '50': 39.3,
    '51': 39.2,
    '52': 39.2,
    '53': 39.1,
    '54': 39,
    '55': 38.9,
    '56': 38.8,
    '57': 38.7,
    '58': 38.7,
    '59': 38.6,
    '60': 38.5,
    '61': 38.4,
    '62': 38.3,
    '63': 38.3,
    '64': 38.2,
    '65': 38.1,
    '66': 38,
    '67': 37.9,
    '68': 37.8,
    '69': 37.7,
    '70': 37.6,
    '71': 37.6,
    '72': 37.5,
    '73': 37.4,
    '74': 37.3,
    '75': 37.3,
    '76': 37.2,
    '77': 37.1,
    '78': 37.1,
    '79': 37,
    '80': 36.9,
    '81': 36.8,
    '82': 36.7,
    '83': 36.7,
    '84': 36.6,
    '85': 36.5,
    '86': 36.4,
    '87': 36.3,
    '88': 36.3,
    '89': 36.2,
    '90': 36.1,
    '91': 36,
    '92': 36,
    '93': 35.9,
    '94': 35.8,
    '95': 35.7,
    '96': 35.6,
    '97': 35.6,
    '98': 35.5,
    '99': 35.4,
    '100': 35.3,
    '101': 35.3,
    '102': 35.2,
    '103': 35.1,
    '104': 35,
    '105': 35,
    '106': 34.9,
    '107': 34.8,
    '108': 34.7,
    '109': 34.7,
    '110': 34.6,
    '111': 34.5,
    '112': 34.4,
    '113': 34.4,
    '114': 34.3,
    '115': 34.2,
    '116': 34.1,
    '117': 34.1,
    '118': 34,
    '119': 33.9,
    '120': 33.8,
    '121': 33.8,
    '122': 33.7,
    '123': 33.6,
    '124': 33.5,
    '125': 33.5,
    '126': 33.4,
    '127': 33.3,
    '128': 33.2,
    '129': 33.2,
    '130': 33.1,
    '131': 33,
    '132': 33,
    '133': 32.9,
    '134': 32.8,
    '135': 32.7,
    '136': 32.7,
    '137': 32.6,
    '138': 32.5,
    '139': 32.4,
    '140': 32.4,
    '141': 32.3,
    '142': 32.2,
    '143': 32.2,
    '144': 32.1,
    '145': 32,
    '146': 31.9,
    '147': 31.9,
    '148': 31.8,
    '149': 31.7,
    '150': 31.7,
    '151': 31.6,
    '152': 31.5,
    '153': 31.5,
    '154': 31.4,
    '155': 31.3,
    '156': 31.2,
    '157': 31.2,
    '158': 31.1,
    '159': 31,
    '160': 31,
    '161': 30.9,
    '162': 30.8,
    '163': 30.8,
    '164': 30.7,
    '165': 30.6,
    '166': 30.5,
    '167': 30.5,
    '168': 30.4,
    '169': 30.3,
    '170': 30.3,
    '171': 30.2,
    '172': 30.1,
    '173': 30.1,
    '174': 30,
    '175': 29.9,
    '176': 29.9,
    '177': 29.8,
    '178': 29.7,
    '179': 29.7,
    '180': 29.6,
    '181': 29.5,
    '182': 29.5,
    '183': 29.4,
    '184': 29.3,
    '185': 29.3,
    '186': 29.2,
    '187': 29.1,
    '188': 29.1,
    '189': 29,
    '190': 28.9,
    '191': 28.9,
    '192': 28.8,
    '193': 28.7,
    '194': 28.7,
    '195': 28.6,
    '0.5': 43.6,
    '1.5': 43.5,
    '2.5': 43.4,
    '3.5': 43.3,
    '4.5': 43.3,
    '5.5': 43.2,
    '6.5': 43.1,
    '7.5': 43,
    '8.5': 42.9,
    '9.5': 42.8,
    '10.5': 42.7,
    '11.5': 42.6,
    '12.5': 42.5,
    '13.5': 42.5,
    '14.5': 42.4,
    '15.5': 42.3,
    '16.5': 42.2,
    '17.5': 42.1,
    '18.5': 42,
    '19.5': 41.9,
    '20.5': 41.8,
    '21.5': 41.7,
    '22.5': 41.7,
    '23.5': 41.6,
    '24.5': 41.5,
    '25.5': 41.4,
    '26.5': 41.3,
    '27.5': 41.2,
    '28.5': 41.1,
    '29.5': 41.1,
    '30.5': 41,
    '31.5': 40.9,
    '32.5': 40.8,
    '33.5': 40.7,
    '34.5': 40.6,
    '35.5': 40.5,
    '36.5': 40.5,
    '37.5': 40.4,
    '38.5': 40.3,
    '39.5': 40.2,
    '40.5': 40.1,
    '41.5': 40,
    '42.5': 40,
    '43.5': 39.9,
    '44.5': 39.8,
    '45.5': 39.7,
    '46.5': 39.6,
    '47.5': 39.5,
    '48.5': 39.4,
    '49.5': 39.4,
    '50.5': 39.3,
    '51.5': 39.2,
    '52.5': 39.1,
    '53.5': 39,
    '54.5': 39,
    '55.5': 38.9,
    '56.5': 38.8,
    '57.5': 38.7,
    '58.5': 38.6,
    '59.5': 38.5,
    '60.5': 38.5,
    '61.5': 38.4,
    '62.5': 38.3,
    '63.5': 38.2,
    '64.5': 38.1,
    '65.5': 38.1,
    '66.5': 38,
    '67.5': 37.9,
    '68.5': 37.8,
    '69.5': 37.7,
    '70.5': 37.6,
    '71.5': 37.5,
    '72.5': 37.4,
    '73.5': 37.4,
    '74.5': 37.3,
    '75.5': 37.3,
    '76.5': 37.2,
    '77.5': 37.1,
    '78.5': 37,
    '79.5': 36.9,
    '80.5': 36.9,
    '81.5': 36.8,
    '82.5': 36.7,
    '83.5': 36.6,
    '84.5': 36.5,
    '85.5': 36.5,
    '86.5': 36.4,
    '87.5': 36.3,
    '88.5': 36.2,
    '89.5': 36.1,
    '90.5': 36.1,
    '91.5': 36,
    '92.5': 35.9,
    '93.5': 35.8,
    '94.5': 35.8,
    '95.5': 35.7,
    '96.5': 35.6,
    '97.5': 35.5,
    '98.5': 35.5,
    '99.5': 35.4,
    '100.5': 35.3,
    '101.5': 35.2,
    '102.5': 35.1,
    '103.5': 35.1,
    '104.5': 35,
    '105.5': 34.9,
    '106.5': 34.8,
    '107.5': 34.8,
    '108.5': 34.7,
    '109.5': 34.6,
    '110.5': 34.5,
    '111.5': 34.5,
    '112.5': 34.4,
    '113.5': 34.3,
    '114.5': 34.2,
    '115.5': 34.2,
    '116.5': 34.1,
    '117.5': 34,
    '118.5': 33.9,
    '119.5': 33.9,
    '120.5': 33.8,
    '121.5': 33.7,
    '122.5': 33.6,
    '123.5': 33.6,
    '124.5': 33.5,
    '125.5': 33.4,
    '126.5': 33.4,
    '127.5': 33.3,
    '128.5': 33.2,
    '129.5': 33.1,
    '130.5': 33.1,
    '131.5': 33,
    '132.5': 32.9,
    '133.5': 32.8,
    '134.5': 32.8,
    '135.5': 32.7,
    '136.5': 32.6,
    '137.5': 32.6,
    '138.5': 32.5,
    '139.5': 32.4,
    '140.5': 32.3,
    '141.5': 32.3,
    '142.5': 32.2,
    '143.5': 32.1,
    '144.5': 32.1,
    '145.5': 32,
    '146.5': 31.9,
    '147.5': 31.8,
    '148.5': 31.8,
    '149.5': 31.7,
    '150.5': 31.6,
    '151.5': 31.6,
    '152.5': 31.5,
    '153.5': 31.4,
    '154.5': 31.3,
    '155.5': 31.3,
    '156.5': 31.2,
    '157.5': 31.1,
    '158.5': 31.1,
    '159.5': 31,
    '160.5': 30.9,
    '161.5': 30.9,
    '162.5': 30.8,
    '163.5': 30.7,
    '164.5': 30.7,
    '165.5': 30.6,
    '166.5': 30.5,
    '167.5': 30.4,
    '168.5': 30.4,
    '169.5': 30.3,
    '170.5': 30.2,
    '171.5': 30.2,
    '172.5': 30.1,
    '173.5': 30,
    '174.5': 30,
    '175.5': 29.9,
    '176.5': 29.8,
    '177.5': 29.8,
    '178.5': 29.7,
    '179.5': 29.6,
    '180.5': 29.6,
    '181.5': 29.5,
    '182.5': 29.4,
    '183.5': 29.4,
    '184.5': 29.3,
    '185.5': 29.2,
    '186.5': 29.2,
    '187.5': 29.1,
    '188.5': 29,
    '189.5': 29,
    '190.5': 28.9,
    '191.5': 28.8,
    '192.5': 28.8,
    '193.5': 28.7,
    '194.5': 28.6,
  },
  '39': {
    '0': 44.2,
    '1': 44.1,
    '2': 44,
    '3': 43.9,
    '4': 43.8,
    '5': 43.8,
    '6': 43.7,
    '7': 43.6,
    '8': 43.5,
    '9': 43.4,
    '10': 43.3,
    '11': 43.2,
    '12': 43.1,
    '13': 43,
    '14': 42.9,
    '15': 42.9,
    '16': 42.8,
    '17': 42.7,
    '18': 42.6,
    '19': 42.5,
    '20': 42.4,
    '21': 42.3,
    '22': 42.2,
    '23': 42.1,
    '24': 42.1,
    '25': 42,
    '26': 41.9,
    '27': 41.8,
    '28': 41.7,
    '29': 41.6,
    '30': 41.5,
    '31': 41.5,
    '32': 41.4,
    '33': 41.3,
    '34': 41.2,
    '35': 41.1,
    '36': 41,
    '37': 40.9,
    '38': 40.8,
    '39': 40.8,
    '40': 40.7,
    '41': 40.6,
    '42': 40.5,
    '43': 40.4,
    '44': 40.3,
    '45': 40.3,
    '46': 40.2,
    '47': 40.1,
    '48': 40,
    '49': 39.9,
    '50': 39.8,
    '51': 39.7,
    '52': 39.7,
    '53': 39.6,
    '54': 39.5,
    '55': 39.4,
    '56': 39.3,
    '57': 39.2,
    '58': 39.2,
    '59': 39.1,
    '60': 39,
    '61': 38.9,
    '62': 38.8,
    '63': 38.8,
    '64': 38.7,
    '65': 38.6,
    '66': 38.5,
    '67': 38.4,
    '68': 38.3,
    '69': 38.3,
    '70': 38.2,
    '71': 38.1,
    '72': 38,
    '73': 37.9,
    '74': 37.9,
    '75': 37.8,
    '76': 37.7,
    '77': 37.6,
    '78': 37.5,
    '79': 37.5,
    '80': 37.4,
    '81': 37.3,
    '82': 37.2,
    '83': 37.1,
    '84': 37.1,
    '85': 37,
    '86': 36.9,
    '87': 36.8,
    '88': 36.7,
    '89': 36.7,
    '90': 36.6,
    '91': 36.5,
    '92': 36.4,
    '93': 36.4,
    '94': 36.3,
    '95': 36.2,
    '96': 36.1,
    '97': 36,
    '98': 36,
    '99': 35.9,
    '100': 35.8,
    '101': 35.7,
    '102': 35.7,
    '103': 35.6,
    '104': 35.5,
    '105': 35.4,
    '106': 35.3,
    '107': 35.3,
    '108': 35.2,
    '109': 35.1,
    '110': 35,
    '111': 35,
    '112': 34.9,
    '113': 34.8,
    '114': 34.7,
    '115': 34.7,
    '116': 34.6,
    '117': 34.5,
    '118': 34.4,
    '119': 34.4,
    '120': 34.3,
    '121': 34.2,
    '122': 34.1,
    '123': 34.1,
    '124': 34,
    '125': 33.9,
    '126': 33.8,
    '127': 33.8,
    '128': 33.7,
    '129': 33.6,
    '130': 33.6,
    '131': 33.5,
    '132': 33.4,
    '133': 33.3,
    '134': 33.3,
    '135': 33.2,
    '136': 33.1,
    '137': 33,
    '138': 33,
    '139': 32.9,
    '140': 32.8,
    '141': 32.8,
    '142': 32.7,
    '143': 32.6,
    '144': 32.5,
    '145': 32.5,
    '146': 32.4,
    '147': 32.3,
    '148': 32.2,
    '149': 32.2,
    '150': 32.1,
    '151': 32,
    '152': 32,
    '153': 31.9,
    '154': 31.8,
    '155': 31.8,
    '156': 31.7,
    '157': 31.6,
    '158': 31.5,
    '159': 31.5,
    '160': 31.4,
    '161': 31.3,
    '162': 31.3,
    '163': 31.2,
    '164': 31.1,
    '165': 31.1,
    '166': 31,
    '167': 30.9,
    '168': 30.8,
    '169': 30.8,
    '170': 30.7,
    '171': 30.6,
    '172': 30.6,
    '173': 30.5,
    '174': 30.4,
    '175': 30.4,
    '176': 30.3,
    '177': 30.2,
    '178': 30.2,
    '179': 30.1,
    '180': 30,
    '181': 30,
    '182': 29.9,
    '183': 29.8,
    '184': 29.8,
    '185': 29.7,
    '186': 29.6,
    '187': 29.6,
    '188': 29.5,
    '189': 29.4,
    '190': 29.3,
    '191': 29.3,
    '192': 29.2,
    '193': 29.1,
    '194': 29.1,
    '195': 29,
    '0.5': 44.2,
    '1.5': 44.1,
    '2.5': 44,
    '3.5': 43.9,
    '4.5': 43.8,
    '5.5': 43.7,
    '6.5': 43.6,
    '7.5': 43.5,
    '8.5': 43.4,
    '9.5': 43.3,
    '10.5': 43.3,
    '11.5': 43.2,
    '12.5': 43.1,
    '13.5': 43,
    '14.5': 42.9,
    '15.5': 42.8,
    '16.5': 42.7,
    '17.5': 42.6,
    '18.5': 42.5,
    '19.5': 42.5,
    '20.5': 42.4,
    '21.5': 42.3,
    '22.5': 42.2,
    '23.5': 42.1,
    '24.5': 42,
    '25.5': 41.9,
    '26.5': 41.8,
    '27.5': 41.8,
    '28.5': 41.7,
    '29.5': 41.6,
    '30.5': 41.5,
    '31.5': 41.4,
    '32.5': 41.3,
    '33.5': 41.2,
    '34.5': 41.1,
    '35.5': 41.1,
    '36.5': 41,
    '37.5': 40.9,
    '38.5': 40.8,
    '39.5': 40.7,
    '40.5': 40.6,
    '41.5': 40.6,
    '42.5': 40.5,
    '43.5': 40.4,
    '44.5': 40.3,
    '45.5': 40.2,
    '46.5': 40.1,
    '47.5': 40,
    '48.5': 40,
    '49.5': 39.9,
    '50.5': 39.8,
    '51.5': 39.7,
    '52.5': 39.6,
    '53.5': 39.5,
    '54.5': 39.5,
    '55.5': 39.4,
    '56.5': 39.3,
    '57.5': 39.2,
    '58.5': 39.1,
    '59.5': 39,
    '60.5': 39,
    '61.5': 38.9,
    '62.5': 38.8,
    '63.5': 38.7,
    '64.5': 38.6,
    '65.5': 38.5,
    '66.5': 38.5,
    '67.5': 38.4,
    '68.5': 38.3,
    '69.5': 38.2,
    '70.5': 38.1,
    '71.5': 38.1,
    '72.5': 38,
    '73.5': 37.9,
    '74.5': 37.8,
    '75.5': 37.7,
    '76.5': 37.7,
    '77.5': 37.6,
    '78.5': 37.5,
    '79.5': 37.4,
    '80.5': 37.3,
    '81.5': 37.3,
    '82.5': 37.2,
    '83.5': 37.1,
    '84.5': 37,
    '85.5': 36.9,
    '86.5': 36.9,
    '87.5': 36.8,
    '88.5': 36.7,
    '89.5': 36.6,
    '90.5': 36.5,
    '91.5': 36.5,
    '92.5': 36.4,
    '93.5': 36.3,
    '94.5': 36.2,
    '95.5': 36.2,
    '96.5': 36.1,
    '97.5': 36,
    '98.5': 35.9,
    '99.5': 35.8,
    '100.5': 35.8,
    '101.5': 35.7,
    '102.5': 35.6,
    '103.5': 35.5,
    '104.5': 35.5,
    '105.5': 35.4,
    '106.5': 35.3,
    '107.5': 35.2,
    '108.5': 35.2,
    '109.5': 35.1,
    '110.5': 35,
    '111.5': 34.9,
    '112.5': 34.9,
    '113.5': 34.8,
    '114.5': 34.7,
    '115.5': 34.6,
    '116.5': 34.6,
    '117.5': 34.5,
    '118.5': 34.4,
    '119.5': 34.3,
    '120.5': 34.2,
    '121.5': 34.2,
    '122.5': 34.1,
    '123.5': 34,
    '124.5': 34,
    '125.5': 33.9,
    '126.5': 33.8,
    '127.5': 33.7,
    '128.5': 33.7,
    '129.5': 33.6,
    '130.5': 33.5,
    '131.5': 33.4,
    '132.5': 33.4,
    '133.5': 33.3,
    '134.5': 33.2,
    '135.5': 33.2,
    '136.5': 33.1,
    '137.5': 33,
    '138.5': 32.9,
    '139.5': 32.9,
    '140.5': 32.8,
    '141.5': 32.7,
    '142.5': 32.6,
    '143.5': 32.6,
    '144.5': 32.5,
    '145.5': 32.4,
    '146.5': 32.4,
    '147.5': 32.3,
    '148.5': 32.2,
    '149.5': 32.1,
    '150.5': 32.1,
    '151.5': 32,
    '152.5': 31.9,
    '153.5': 31.9,
    '154.5': 31.8,
    '155.5': 31.7,
    '156.5': 31.6,
    '157.5': 31.6,
    '158.5': 31.5,
    '159.5': 31.4,
    '160.5': 31.4,
    '161.5': 31.3,
    '162.5': 31.2,
    '163.5': 31.2,
    '164.5': 31.1,
    '165.5': 31,
    '166.5': 30.9,
    '167.5': 30.9,
    '168.5': 30.8,
    '169.5': 30.7,
    '170.5': 30.7,
    '171.5': 30.6,
    '172.5': 30.5,
    '173.5': 30.5,
    '174.5': 30.4,
    '175.5': 30.3,
    '176.5': 30.3,
    '177.5': 30.2,
    '178.5': 30.1,
    '179.5': 30.1,
    '180.5': 30,
    '181.5': 29.9,
    '182.5': 29.9,
    '183.5': 29.8,
    '184.5': 29.7,
    '185.5': 29.6,
    '186.5': 29.6,
    '187.5': 29.5,
    '188.5': 29.4,
    '189.5': 29.4,
    '190.5': 29.3,
    '191.5': 29.2,
    '192.5': 29.2,
    '193.5': 29.1,
    '194.5': 29,
  },
  '39.5': {
    '0': 44.7,
    '1': 44.7,
    '2': 44.6,
    '3': 44.5,
    '4': 44.4,
    '5': 44.3,
    '6': 44.2,
    '7': 44.1,
    '8': 44,
    '9': 43.9,
    '10': 43.8,
    '11': 43.7,
    '12': 43.7,
    '13': 43.6,
    '14': 43.5,
    '15': 43.4,
    '16': 43.3,
    '17': 43.2,
    '18': 43.1,
    '19': 43,
    '20': 42.9,
    '21': 42.9,
    '22': 42.8,
    '23': 42.7,
    '24': 42.6,
    '25': 42.5,
    '26': 42.4,
    '27': 42.3,
    '28': 42.2,
    '29': 42.1,
    '30': 42.1,
    '31': 42,
    '32': 41.9,
    '33': 41.8,
    '34': 41.7,
    '35': 41.6,
    '36': 41.5,
    '37': 41.4,
    '38': 41.4,
    '39': 41.3,
    '40': 41.2,
    '41': 41.1,
    '42': 41,
    '43': 40.9,
    '44': 40.8,
    '45': 40.8,
    '46': 40.7,
    '47': 40.6,
    '48': 40.5,
    '49': 40.4,
    '50': 40.3,
    '51': 40.3,
    '52': 40.2,
    '53': 40.1,
    '54': 40,
    '55': 39.9,
    '56': 39.8,
    '57': 39.8,
    '58': 39.7,
    '59': 39.6,
    '60': 39.5,
    '61': 39.4,
    '62': 39.3,
    '63': 39.2,
    '64': 39.2,
    '65': 39.1,
    '66': 39,
    '67': 38.9,
    '68': 38.8,
    '69': 38.8,
    '70': 38.7,
    '71': 38.6,
    '72': 38.5,
    '73': 38.4,
    '74': 38.3,
    '75': 38.3,
    '76': 38.2,
    '77': 38.1,
    '78': 38,
    '79': 37.9,
    '80': 37.9,
    '81': 37.8,
    '82': 37.7,
    '83': 37.6,
    '84': 37.5,
    '85': 37.5,
    '86': 37.4,
    '87': 37.3,
    '88': 37.2,
    '89': 37.1,
    '90': 37.1,
    '91': 37,
    '92': 36.9,
    '93': 36.8,
    '94': 36.8,
    '95': 36.7,
    '96': 36.6,
    '97': 36.5,
    '98': 36.4,
    '99': 36.4,
    '100': 36.3,
    '101': 36.2,
    '102': 36.1,
    '103': 36,
    '104': 36,
    '105': 35.9,
    '106': 35.8,
    '107': 35.7,
    '108': 35.7,
    '109': 35.6,
    '110': 35.5,
    '111': 35.4,
    '112': 35.4,
    '113': 35.3,
    '114': 35.2,
    '115': 35.1,
    '116': 35.1,
    '117': 35,
    '118': 34.9,
    '119': 34.8,
    '120': 34.8,
    '121': 34.7,
    '122': 34.6,
    '123': 34.5,
    '124': 34.5,
    '125': 34.4,
    '126': 34.3,
    '127': 34.2,
    '128': 34.2,
    '129': 34.1,
    '130': 34,
    '131': 33.9,
    '132': 33.9,
    '133': 33.8,
    '134': 33.7,
    '135': 33.6,
    '136': 33.6,
    '137': 33.5,
    '138': 33.4,
    '139': 33.3,
    '140': 33.3,
    '141': 33.2,
    '142': 33.1,
    '143': 33.1,
    '144': 33,
    '145': 32.9,
    '146': 32.8,
    '147': 32.8,
    '148': 32.7,
    '149': 32.6,
    '150': 32.5,
    '151': 32.5,
    '152': 32.4,
    '153': 32.3,
    '154': 32.3,
    '155': 32.2,
    '156': 32.1,
    '157': 32,
    '158': 32,
    '159': 31.9,
    '160': 31.8,
    '161': 31.8,
    '162': 31.7,
    '163': 31.6,
    '164': 31.6,
    '165': 31.5,
    '166': 31.4,
    '167': 31.3,
    '168': 31.3,
    '169': 31.2,
    '170': 31.1,
    '171': 31.1,
    '172': 31,
    '173': 30.9,
    '174': 30.9,
    '175': 30.8,
    '176': 30.7,
    '177': 30.7,
    '178': 30.6,
    '179': 30.5,
    '180': 30.4,
    '181': 30.4,
    '182': 30.3,
    '183': 30.2,
    '184': 30.2,
    '185': 30.1,
    '186': 30,
    '187': 30,
    '188': 29.9,
    '189': 29.8,
    '190': 29.8,
    '191': 29.7,
    '192': 29.6,
    '193': 29.6,
    '194': 29.5,
    '195': 29.4,
    '0.5': 44.7,
    '1.5': 44.6,
    '2.5': 44.5,
    '3.5': 44.4,
    '4.5': 44.3,
    '5.5': 44.2,
    '6.5': 44.2,
    '7.5': 44.1,
    '8.5': 44,
    '9.5': 43.9,
    '10.5': 43.8,
    '11.5': 43.7,
    '12.5': 43.6,
    '13.5': 43.5,
    '14.5': 43.4,
    '15.5': 43.3,
    '16.5': 43.3,
    '17.5': 43.2,
    '18.5': 43.1,
    '19.5': 43,
    '20.5': 42.9,
    '21.5': 42.8,
    '22.5': 42.7,
    '23.5': 42.6,
    '24.5': 42.5,
    '25.5': 42.5,
    '26.5': 42.4,
    '27.5': 42.3,
    '28.5': 42.2,
    '29.5': 42.1,
    '30.5': 42,
    '31.5': 41.9,
    '32.5': 41.8,
    '33.5': 41.8,
    '34.5': 41.7,
    '35.5': 41.6,
    '36.5': 41.5,
    '37.5': 41.4,
    '38.5': 41.3,
    '39.5': 41.2,
    '40.5': 41.1,
    '41.5': 41.1,
    '42.5': 41,
    '43.5': 40.9,
    '44.5': 40.8,
    '45.5': 40.7,
    '46.5': 40.6,
    '47.5': 40.6,
    '48.5': 40.5,
    '49.5': 40.4,
    '50.5': 40.3,
    '51.5': 40.2,
    '52.5': 40.1,
    '53.5': 40,
    '54.5': 40,
    '55.5': 39.9,
    '56.5': 39.8,
    '57.5': 39.7,
    '58.5': 39.6,
    '59.5': 39.5,
    '60.5': 39.5,
    '61.5': 39.4,
    '62.5': 39.3,
    '63.5': 39.2,
    '64.5': 39.1,
    '65.5': 39,
    '66.5': 39,
    '67.5': 38.9,
    '68.5': 38.8,
    '69.5': 38.7,
    '70.5': 38.6,
    '71.5': 38.6,
    '72.5': 38.5,
    '73.5': 38.4,
    '74.5': 38.3,
    '75.5': 38.2,
    '76.5': 38.1,
    '77.5': 38.1,
    '78.5': 38,
    '79.5': 37.9,
    '80.5': 37.8,
    '81.5': 37.7,
    '82.5': 37.7,
    '83.5': 37.6,
    '84.5': 37.5,
    '85.5': 37.4,
    '86.5': 37.3,
    '87.5': 37.3,
    '88.5': 37.2,
    '89.5': 37.1,
    '90.5': 37,
    '91.5': 36.9,
    '92.5': 36.9,
    '93.5': 36.8,
    '94.5': 36.7,
    '95.5': 36.6,
    '96.5': 36.6,
    '97.5': 36.5,
    '98.5': 36.4,
    '99.5': 36.3,
    '100.5': 36.2,
    '101.5': 36.2,
    '102.5': 36.1,
    '103.5': 36,
    '104.5': 35.9,
    '105.5': 35.9,
    '106.5': 35.8,
    '107.5': 35.7,
    '108.5': 35.6,
    '109.5': 35.6,
    '110.5': 35.5,
    '111.5': 35.4,
    '112.5': 35.3,
    '113.5': 35.2,
    '114.5': 35.2,
    '115.5': 35.1,
    '116.5': 35,
    '117.5': 34.9,
    '118.5': 34.9,
    '119.5': 34.8,
    '120.5': 34.7,
    '121.5': 34.6,
    '122.5': 34.6,
    '123.5': 34.5,
    '124.5': 34.4,
    '125.5': 34.3,
    '126.5': 34.3,
    '127.5': 34.2,
    '128.5': 34.1,
    '129.5': 34,
    '130.5': 34,
    '131.5': 33.9,
    '132.5': 33.8,
    '133.5': 33.7,
    '134.5': 33.7,
    '135.5': 33.6,
    '136.5': 33.5,
    '137.5': 33.5,
    '138.5': 33.4,
    '139.5': 33.3,
    '140.5': 33.2,
    '141.5': 33.2,
    '142.5': 33.1,
    '143.5': 33,
    '144.5': 32.9,
    '145.5': 32.9,
    '146.5': 32.8,
    '147.5': 32.7,
    '148.5': 32.7,
    '149.5': 32.6,
    '150.5': 32.5,
    '151.5': 32.4,
    '152.5': 32.4,
    '153.5': 32.3,
    '154.5': 32.2,
    '155.5': 32.2,
    '156.5': 32.1,
    '157.5': 32,
    '158.5': 31.9,
    '159.5': 31.9,
    '160.5': 31.8,
    '161.5': 31.7,
    '162.5': 31.7,
    '163.5': 31.6,
    '164.5': 31.5,
    '165.5': 31.4,
    '166.5': 31.4,
    '167.5': 31.3,
    '168.5': 31.2,
    '169.5': 31.2,
    '170.5': 31.1,
    '171.5': 31,
    '172.5': 31,
    '173.5': 30.9,
    '174.5': 30.8,
    '175.5': 30.8,
    '176.5': 30.7,
    '177.5': 30.6,
    '178.5': 30.5,
    '179.5': 30.5,
    '180.5': 30.4,
    '181.5': 30.3,
    '182.5': 30.3,
    '183.5': 30.2,
    '184.5': 30.1,
    '185.5': 30.1,
    '186.5': 30,
    '187.5': 29.9,
    '188.5': 29.9,
    '189.5': 29.8,
    '190.5': 29.7,
    '191.5': 29.7,
    '192.5': 29.6,
    '193.5': 29.5,
    '194.5': 29.5,
  },
  '40': {
    '0': 45.3,
    '1': 45.2,
    '2': 45.1,
    '3': 45,
    '4': 44.9,
    '5': 44.8,
    '6': 44.7,
    '7': 44.7,
    '8': 44.6,
    '9': 44.5,
    '10': 44.4,
    '11': 44.3,
    '12': 44.2,
    '13': 44.1,
    '14': 44,
    '15': 43.9,
    '16': 43.8,
    '17': 43.7,
    '18': 43.7,
    '19': 43.6,
    '20': 43.5,
    '21': 43.4,
    '22': 43.3,
    '23': 43.2,
    '24': 43.1,
    '25': 43,
    '26': 42.9,
    '27': 42.8,
    '28': 42.8,
    '29': 42.7,
    '30': 42.6,
    '31': 42.5,
    '32': 42.4,
    '33': 42.3,
    '34': 42.2,
    '35': 42.1,
    '36': 42.1,
    '37': 42,
    '38': 41.9,
    '39': 41.8,
    '40': 41.7,
    '41': 41.6,
    '42': 41.5,
    '43': 41.4,
    '44': 41.4,
    '45': 41.3,
    '46': 41.2,
    '47': 41.1,
    '48': 41,
    '49': 40.9,
    '50': 40.8,
    '51': 40.8,
    '52': 40.7,
    '53': 40.6,
    '54': 40.5,
    '55': 40.4,
    '56': 40.3,
    '57': 40.3,
    '58': 40.2,
    '59': 40.1,
    '60': 40,
    '61': 39.9,
    '62': 39.8,
    '63': 39.7,
    '64': 39.7,
    '65': 39.6,
    '66': 39.5,
    '67': 39.4,
    '68': 39.3,
    '69': 39.3,
    '70': 39.2,
    '71': 39.1,
    '72': 39,
    '73': 38.9,
    '74': 38.8,
    '75': 38.8,
    '76': 38.7,
    '77': 38.6,
    '78': 38.5,
    '79': 38.4,
    '80': 38.3,
    '81': 38.3,
    '82': 38.2,
    '83': 38.1,
    '84': 38,
    '85': 37.9,
    '86': 37.9,
    '87': 37.8,
    '88': 37.7,
    '89': 37.6,
    '90': 37.5,
    '91': 37.5,
    '92': 37.4,
    '93': 37.3,
    '94': 37.2,
    '95': 37.1,
    '96': 37.1,
    '97': 37,
    '98': 36.9,
    '99': 36.8,
    '100': 36.8,
    '101': 36.7,
    '102': 36.6,
    '103': 36.5,
    '104': 36.4,
    '105': 36.4,
    '106': 36.3,
    '107': 36.2,
    '108': 36.1,
    '109': 36.1,
    '110': 36,
    '111': 35.9,
    '112': 35.8,
    '113': 35.7,
    '114': 35.7,
    '115': 35.6,
    '116': 35.5,
    '117': 35.4,
    '118': 35.4,
    '119': 35.3,
    '120': 35.2,
    '121': 35.1,
    '122': 35.1,
    '123': 35,
    '124': 34.9,
    '125': 34.8,
    '126': 34.8,
    '127': 34.7,
    '128': 34.6,
    '129': 34.5,
    '130': 34.5,
    '131': 34.4,
    '132': 34.3,
    '133': 34.2,
    '134': 34.2,
    '135': 34.1,
    '136': 34,
    '137': 33.9,
    '138': 33.9,
    '139': 33.8,
    '140': 33.7,
    '141': 33.6,
    '142': 33.6,
    '143': 33.5,
    '144': 33.4,
    '145': 33.3,
    '146': 33.3,
    '147': 33.2,
    '148': 33.1,
    '149': 33.1,
    '150': 33,
    '151': 32.9,
    '152': 32.8,
    '153': 32.8,
    '154': 32.7,
    '155': 32.6,
    '156': 32.6,
    '157': 32.5,
    '158': 32.4,
    '159': 32.3,
    '160': 32.3,
    '161': 32.2,
    '162': 32.1,
    '163': 32.1,
    '164': 32,
    '165': 31.9,
    '166': 31.8,
    '167': 31.8,
    '168': 31.7,
    '169': 31.6,
    '170': 31.6,
    '171': 31.5,
    '172': 31.4,
    '173': 31.4,
    '174': 31.3,
    '175': 31.2,
    '176': 31.1,
    '177': 31.1,
    '178': 31,
    '179': 30.9,
    '180': 30.9,
    '181': 30.8,
    '182': 30.7,
    '183': 30.7,
    '184': 30.6,
    '185': 30.5,
    '186': 30.5,
    '187': 30.4,
    '188': 30.3,
    '189': 30.3,
    '190': 30.2,
    '191': 30.1,
    '192': 30,
    '193': 30,
    '194': 29.9,
    '195': 29.8,
    '0.5': 45.3,
    '1.5': 45.2,
    '2.5': 45.1,
    '3.5': 45,
    '4.5': 44.9,
    '5.5': 44.8,
    '6.5': 44.7,
    '7.5': 44.6,
    '8.5': 44.5,
    '9.5': 44.4,
    '10.5': 44.3,
    '11.5': 44.2,
    '12.5': 44.2,
    '13.5': 44.1,
    '14.5': 44,
    '15.5': 43.9,
    '16.5': 43.8,
    '17.5': 43.7,
    '18.5': 43.6,
    '19.5': 43.5,
    '20.5': 43.4,
    '21.5': 43.3,
    '22.5': 43.2,
    '23.5': 43.2,
    '24.5': 43.1,
    '25.5': 43,
    '26.5': 42.9,
    '27.5': 42.8,
    '28.5': 42.7,
    '29.5': 42.6,
    '30.5': 42.5,
    '31.5': 42.4,
    '32.5': 42.4,
    '33.5': 42.3,
    '34.5': 42.2,
    '35.5': 42.1,
    '36.5': 42,
    '37.5': 41.9,
    '38.5': 41.8,
    '39.5': 41.8,
    '40.5': 41.7,
    '41.5': 41.6,
    '42.5': 41.5,
    '43.5': 41.4,
    '44.5': 41.3,
    '45.5': 41.2,
    '46.5': 41.1,
    '47.5': 41.1,
    '48.5': 41,
    '49.5': 40.9,
    '50.5': 40.8,
    '51.5': 40.7,
    '52.5': 40.6,
    '53.5': 40.5,
    '54.5': 40.5,
    '55.5': 40.4,
    '56.5': 40.3,
    '57.5': 40.2,
    '58.5': 40.1,
    '59.5': 40,
    '60.5': 40,
    '61.5': 39.9,
    '62.5': 39.8,
    '63.5': 39.7,
    '64.5': 39.6,
    '65.5': 39.5,
    '66.5': 39.5,
    '67.5': 39.4,
    '68.5': 39.3,
    '69.5': 39.2,
    '70.5': 39.1,
    '71.5': 39,
    '72.5': 39,
    '73.5': 38.9,
    '74.5': 38.8,
    '75.5': 38.7,
    '76.5': 38.6,
    '77.5': 38.6,
    '78.5': 38.5,
    '79.5': 38.4,
    '80.5': 38.3,
    '81.5': 38.2,
    '82.5': 38.1,
    '83.5': 38.1,
    '84.5': 38,
    '85.5': 37.9,
    '86.5': 37.8,
    '87.5': 37.7,
    '88.5': 37.7,
    '89.5': 37.6,
    '90.5': 37.5,
    '91.5': 37.4,
    '92.5': 37.3,
    '93.5': 37.3,
    '94.5': 37.2,
    '95.5': 37.1,
    '96.5': 37,
    '97.5': 37,
    '98.5': 36.9,
    '99.5': 36.8,
    '100.5': 36.7,
    '101.5': 36.6,
    '102.5': 36.6,
    '103.5': 36.5,
    '104.5': 36.4,
    '105.5': 36.3,
    '106.5': 36.2,
    '107.5': 36.2,
    '108.5': 36.1,
    '109.5': 36,
    '110.5': 35.9,
    '111.5': 35.9,
    '112.5': 35.8,
    '113.5': 35.7,
    '114.5': 35.6,
    '115.5': 35.6,
    '116.5': 35.5,
    '117.5': 35.4,
    '118.5': 35.3,
    '119.5': 35.2,
    '120.5': 35.2,
    '121.5': 35.1,
    '122.5': 35,
    '123.5': 34.9,
    '124.5': 34.9,
    '125.5': 34.8,
    '126.5': 34.7,
    '127.5': 34.6,
    '128.5': 34.6,
    '129.5': 34.5,
    '130.5': 34.4,
    '131.5': 34.3,
    '132.5': 34.3,
    '133.5': 34.2,
    '134.5': 34.1,
    '135.5': 34,
    '136.5': 34,
    '137.5': 33.9,
    '138.5': 33.8,
    '139.5': 33.8,
    '140.5': 33.7,
    '141.5': 33.6,
    '142.5': 33.5,
    '143.5': 33.5,
    '144.5': 33.4,
    '145.5': 33.3,
    '146.5': 33.2,
    '147.5': 33.2,
    '148.5': 33.1,
    '149.5': 33,
    '150.5': 33,
    '151.5': 32.9,
    '152.5': 32.8,
    '153.5': 32.7,
    '154.5': 32.7,
    '155.5': 32.6,
    '156.5': 32.5,
    '157.5': 32.4,
    '158.5': 32.4,
    '159.5': 32.3,
    '160.5': 32.2,
    '161.5': 32.2,
    '162.5': 32.1,
    '163.5': 32,
    '164.5': 32,
    '165.5': 31.9,
    '166.5': 31.8,
    '167.5': 31.7,
    '168.5': 31.7,
    '169.5': 31.6,
    '170.5': 31.5,
    '171.5': 31.5,
    '172.5': 31.4,
    '173.5': 31.3,
    '174.5': 31.3,
    '175.5': 31.2,
    '176.5': 31.1,
    '177.5': 31,
    '178.5': 31,
    '179.5': 30.9,
    '180.5': 30.8,
    '181.5': 30.8,
    '182.5': 30.7,
    '183.5': 30.6,
    '184.5': 30.6,
    '185.5': 30.5,
    '186.5': 30.4,
    '187.5': 30.4,
    '188.5': 30.3,
    '189.5': 30.2,
    '190.5': 30.1,
    '191.5': 30.1,
    '192.5': 30,
    '193.5': 29.9,
    '194.5': 29.9,
  },
  // 40 - 45
  '40.5': {
    '0': 45.9,
    '1': 45.8,
    '2': 45.7,
    '3': 45.6,
    '4': 45.5,
    '5': 45.4,
    '6': 45.3,
    '7': 45.2,
    '8': 45.1,
    '9': 45,
    '10': 44.9,
    '11': 44.8,
    '12': 44.7,
    '13': 44.6,
    '14': 44.6,
    '15': 44.5,
    '16': 44.4,
    '17': 44.3,
    '18': 44.2,
    '19': 44.1,
    '20': 44,
    '21': 43.9,
    '22': 43.8,
    '23': 43.7,
    '24': 43.6,
    '25': 43.6,
    '26': 43.5,
    '27': 43.4,
    '28': 43.3,
    '29': 43.2,
    '30': 43.1,
    '31': 43,
    '32': 42.9,
    '33': 42.8,
    '34': 42.8,
    '35': 42.7,
    '36': 42.6,
    '37': 42.5,
    '38': 42.4,
    '39': 42.3,
    '40': 42.2,
    '41': 42.1,
    '42': 42,
    '43': 42,
    '44': 41.9,
    '45': 41.8,
    '46': 41.7,
    '47': 41.6,
    '48': 41.5,
    '49': 41.4,
    '50': 41.4,
    '51': 41.3,
    '52': 41.2,
    '53': 41.1,
    '54': 41,
    '55': 40.9,
    '56': 40.8,
    '57': 40.8,
    '58': 40.7,
    '59': 40.6,
    '60': 40.5,
    '61': 40.4,
    '62': 40.3,
    '63': 40.2,
    '64': 40.2,
    '65': 40.1,
    '66': 40,
    '67': 39.9,
    '68': 39.8,
    '69': 39.7,
    '70': 39.7,
    '71': 39.6,
    '72': 39.5,
    '73': 39.4,
    '74': 39.3,
    '75': 39.2,
    '76': 39.2,
    '77': 39.1,
    '78': 39,
    '79': 38.9,
    '80': 38.8,
    '81': 38.8,
    '82': 38.7,
    '83': 38.6,
    '84': 38.5,
    '85': 38.4,
    '86': 38.3,
    '87': 38.3,
    '88': 38.2,
    '89': 38.1,
    '90': 38,
    '91': 37.9,
    '92': 37.9,
    '93': 37.8,
    '94': 37.7,
    '95': 37.6,
    '96': 37.5,
    '97': 37.5,
    '98': 37.4,
    '99': 37.3,
    '100': 37.2,
    '101': 37.1,
    '102': 37.1,
    '103': 37,
    '104': 36.9,
    '105': 36.8,
    '106': 36.8,
    '107': 36.7,
    '108': 36.6,
    '109': 36.5,
    '110': 36.4,
    '111': 36.4,
    '112': 36.3,
    '113': 36.2,
    '114': 36.1,
    '115': 36.1,
    '116': 36,
    '117': 35.9,
    '118': 35.8,
    '119': 35.7,
    '120': 35.7,
    '121': 35.6,
    '122': 35.5,
    '123': 35.4,
    '124': 35.4,
    '125': 35.3,
    '126': 35.2,
    '127': 35.1,
    '128': 35.1,
    '129': 35,
    '130': 34.9,
    '131': 34.8,
    '132': 34.8,
    '133': 34.7,
    '134': 34.6,
    '135': 34.5,
    '136': 34.5,
    '137': 34.4,
    '138': 34.3,
    '139': 34.2,
    '140': 34.2,
    '141': 34.1,
    '142': 34,
    '143': 33.9,
    '144': 33.9,
    '145': 33.8,
    '146': 33.7,
    '147': 33.6,
    '148': 33.6,
    '149': 33.5,
    '150': 33.4,
    '151': 33.4,
    '152': 33.3,
    '153': 33.2,
    '154': 33.1,
    '155': 33.1,
    '156': 33,
    '157': 32.9,
    '158': 32.9,
    '159': 32.8,
    '160': 32.7,
    '161': 32.6,
    '162': 32.6,
    '163': 32.5,
    '164': 32.4,
    '165': 32.3,
    '166': 32.3,
    '167': 32.2,
    '168': 32.1,
    '169': 32.1,
    '170': 32,
    '171': 31.9,
    '172': 31.9,
    '173': 31.8,
    '174': 31.7,
    '175': 31.6,
    '176': 31.6,
    '177': 31.5,
    '178': 31.4,
    '179': 31.4,
    '180': 31.3,
    '181': 31.2,
    '182': 31.2,
    '183': 31.1,
    '184': 31,
    '185': 30.9,
    '186': 30.9,
    '187': 30.8,
    '188': 30.7,
    '189': 30.7,
    '190': 30.6,
    '191': 30.5,
    '192': 30.5,
    '193': 30.4,
    '194': 30.3,
    '195': 30.3,
    '0.5': 45.8,
    '1.5': 45.7,
    '2.5': 45.6,
    '3.5': 45.5,
    '4.5': 45.4,
    '5.5': 45.3,
    '6.5': 45.2,
    '7.5': 45.2,
    '8.5': 45.1,
    '9.5': 45,
    '10.5': 44.9,
    '11.5': 44.8,
    '12.5': 44.7,
    '13.5': 44.6,
    '14.5': 44.5,
    '15.5': 44.4,
    '16.5': 44.3,
    '17.5': 44.2,
    '18.5': 44.1,
    '19.5': 44.1,
    '20.5': 44,
    '21.5': 43.9,
    '22.5': 43.8,
    '23.5': 43.7,
    '24.5': 43.6,
    '25.5': 43.5,
    '26.5': 43.4,
    '27.5': 43.3,
    '28.5': 43.2,
    '29.5': 43.2,
    '30.5': 43.1,
    '31.5': 43,
    '32.5': 42.9,
    '33.5': 42.8,
    '34.5': 42.7,
    '35.5': 42.6,
    '36.5': 42.5,
    '37.5': 42.4,
    '38.5': 42.4,
    '39.5': 42.3,
    '40.5': 42.2,
    '41.5': 42.1,
    '42.5': 42,
    '43.5': 41.9,
    '44.5': 41.8,
    '45.5': 41.7,
    '46.5': 41.7,
    '47.5': 41.6,
    '48.5': 41.5,
    '49.5': 41.4,
    '50.5': 41.3,
    '51.5': 41.2,
    '52.5': 41.1,
    '53.5': 41.1,
    '54.5': 41,
    '55.5': 40.9,
    '56.5': 40.8,
    '57.5': 40.7,
    '58.5': 40.6,
    '59.5': 40.5,
    '60.5': 40.5,
    '61.5': 40.4,
    '62.5': 40.3,
    '63.5': 40.2,
    '64.5': 40.1,
    '65.5': 40,
    '66.5': 40,
    '67.5': 39.9,
    '68.5': 39.8,
    '69.5': 39.7,
    '70.5': 39.6,
    '71.5': 39.5,
    '72.5': 39.5,
    '73.5': 39.4,
    '74.5': 39.3,
    '75.5': 39.2,
    '76.5': 39.1,
    '77.5': 39,
    '78.5': 39,
    '79.5': 38.9,
    '80.5': 38.8,
    '81.5': 38.7,
    '82.5': 38.6,
    '83.5': 38.5,
    '84.5': 38.5,
    '85.5': 38.4,
    '86.5': 38.3,
    '87.5': 38.2,
    '88.5': 38.1,
    '89.5': 38.1,
    '90.5': 38,
    '91.5': 37.9,
    '92.5': 37.8,
    '93.5': 37.7,
    '94.5': 37.7,
    '95.5': 37.6,
    '96.5': 37.5,
    '97.5': 37.4,
    '98.5': 37.3,
    '99.5': 37.3,
    '100.5': 37.2,
    '101.5': 37.1,
    '102.5': 37,
    '103.5': 37,
    '104.5': 36.9,
    '105.5': 36.8,
    '106.5': 36.7,
    '107.5': 36.6,
    '108.5': 36.6,
    '109.5': 36.5,
    '110.5': 36.4,
    '111.5': 36.3,
    '112.5': 36.2,
    '113.5': 36.2,
    '114.5': 36.1,
    '115.5': 36,
    '116.5': 35.9,
    '117.5': 35.9,
    '118.5': 35.8,
    '119.5': 35.7,
    '120.5': 35.6,
    '121.5': 35.6,
    '122.5': 35.5,
    '123.5': 35.4,
    '124.5': 35.3,
    '125.5': 35.3,
    '126.5': 35.2,
    '127.5': 35.1,
    '128.5': 35,
    '129.5': 34.9,
    '130.5': 34.9,
    '131.5': 34.8,
    '132.5': 34.7,
    '133.5': 34.6,
    '134.5': 34.6,
    '135.5': 34.5,
    '136.5': 34.4,
    '137.5': 34.3,
    '138.5': 34.3,
    '139.5': 34.2,
    '140.5': 34.1,
    '141.5': 34.1,
    '142.5': 34,
    '143.5': 33.9,
    '144.5': 33.8,
    '145.5': 33.8,
    '146.5': 33.7,
    '147.5': 33.6,
    '148.5': 33.5,
    '149.5': 33.5,
    '150.5': 33.4,
    '151.5': 33.3,
    '152.5': 33.2,
    '153.5': 33.2,
    '154.5': 33.1,
    '155.5': 33,
    '156.5': 33,
    '157.5': 32.9,
    '158.5': 32.8,
    '159.5': 32.7,
    '160.5': 32.7,
    '161.5': 32.6,
    '162.5': 32.5,
    '163.5': 32.5,
    '164.5': 32.4,
    '165.5': 32.3,
    '166.5': 32.2,
    '167.5': 32.2,
    '168.5': 32.1,
    '169.5': 32,
    '170.5': 32,
    '171.5': 31.9,
    '172.5': 31.8,
    '173.5': 31.7,
    '174.5': 31.7,
    '175.5': 31.6,
    '176.5': 31.5,
    '177.5': 31.5,
    '178.5': 31.4,
    '179.5': 31.3,
    '180.5': 31.3,
    '181.5': 31.2,
    '182.5': 31.1,
    '183.5': 31.1,
    '184.5': 31,
    '185.5': 30.9,
    '186.5': 30.8,
    '187.5': 30.8,
    '188.5': 30.7,
    '189.5': 30.6,
    '190.5': 30.6,
    '191.5': 30.5,
    '192.5': 30.4,
    '193.5': 30.4,
    '194.5': 30.3,
  },
  '41': {
    '0': 46.4,
    '1': 46.3,
    '2': 46.2,
    '3': 46.1,
    '4': 46,
    '5': 45.9,
    '6': 45.8,
    '7': 45.7,
    '8': 45.6,
    '9': 45.6,
    '10': 45.5,
    '11': 45.4,
    '12': 45.3,
    '13': 45.2,
    '14': 45.1,
    '15': 45,
    '16': 44.9,
    '17': 44.8,
    '18': 44.7,
    '19': 44.6,
    '20': 44.5,
    '21': 44.4,
    '22': 44.4,
    '23': 44.3,
    '24': 44.2,
    '25': 44.1,
    '26': 44,
    '27': 43.9,
    '28': 43.8,
    '29': 43.7,
    '30': 43.6,
    '31': 43.5,
    '32': 43.5,
    '33': 43.4,
    '34': 43.3,
    '35': 43.2,
    '36': 43.1,
    '37': 43,
    '38': 42.9,
    '39': 42.8,
    '40': 42.7,
    '41': 42.6,
    '42': 42.6,
    '43': 42.5,
    '44': 42.4,
    '45': 42.3,
    '46': 42.2,
    '47': 42.1,
    '48': 42,
    '49': 42,
    '50': 41.9,
    '51': 41.8,
    '52': 41.7,
    '53': 41.6,
    '54': 41.5,
    '55': 41.4,
    '56': 41.3,
    '57': 41.3,
    '58': 41.2,
    '59': 41.1,
    '60': 41,
    '61': 40.9,
    '62': 40.8,
    '63': 40.7,
    '64': 40.7,
    '65': 40.6,
    '66': 40.5,
    '67': 40.4,
    '68': 40.3,
    '69': 40.2,
    '70': 40.2,
    '71': 40.1,
    '72': 40,
    '73': 39.9,
    '74': 39.8,
    '75': 39.7,
    '76': 39.7,
    '77': 39.6,
    '78': 39.5,
    '79': 39.4,
    '80': 39.3,
    '81': 39.2,
    '82': 39.2,
    '83': 39.1,
    '84': 39,
    '85': 38.9,
    '86': 38.8,
    '87': 38.7,
    '88': 38.7,
    '89': 38.6,
    '90': 38.5,
    '91': 38.4,
    '92': 38.3,
    '93': 38.3,
    '94': 38.2,
    '95': 38.1,
    '96': 38,
    '97': 37.9,
    '98': 37.9,
    '99': 37.8,
    '100': 37.7,
    '101': 37.6,
    '102': 37.5,
    '103': 37.5,
    '104': 37.4,
    '105': 37.3,
    '106': 37.2,
    '107': 37.1,
    '108': 37.1,
    '109': 37,
    '110': 36.9,
    '111': 36.8,
    '112': 36.7,
    '113': 36.7,
    '114': 36.6,
    '115': 36.5,
    '116': 36.4,
    '117': 36.4,
    '118': 36.3,
    '119': 36.2,
    '120': 36.1,
    '121': 36,
    '122': 36,
    '123': 35.9,
    '124': 35.8,
    '125': 35.7,
    '126': 35.7,
    '127': 35.6,
    '128': 35.5,
    '129': 35.4,
    '130': 35.4,
    '131': 35.3,
    '132': 35.2,
    '133': 35.1,
    '134': 35.1,
    '135': 35,
    '136': 34.9,
    '137': 34.8,
    '138': 34.8,
    '139': 34.7,
    '140': 34.6,
    '141': 34.5,
    '142': 34.5,
    '143': 34.4,
    '144': 34.3,
    '145': 34.2,
    '146': 34.2,
    '147': 34.1,
    '148': 34,
    '149': 33.9,
    '150': 33.9,
    '151': 33.8,
    '152': 33.7,
    '153': 33.7,
    '154': 33.6,
    '155': 33.5,
    '156': 33.4,
    '157': 33.4,
    '158': 33.3,
    '159': 33.2,
    '160': 33.1,
    '161': 33.1,
    '162': 33,
    '163': 32.9,
    '164': 32.8,
    '165': 32.8,
    '166': 32.7,
    '167': 32.6,
    '168': 32.6,
    '169': 32.5,
    '170': 32.4,
    '171': 32.4,
    '172': 32.3,
    '173': 32.2,
    '174': 32.1,
    '175': 32.1,
    '176': 32,
    '177': 31.9,
    '178': 31.9,
    '179': 31.8,
    '180': 31.7,
    '181': 31.6,
    '182': 31.6,
    '183': 31.5,
    '184': 31.4,
    '185': 31.4,
    '186': 31.3,
    '187': 31.2,
    '188': 31.2,
    '189': 31.1,
    '190': 31,
    '191': 31,
    '192': 30.9,
    '193': 30.8,
    '194': 30.7,
    '195': 30.7,
    '0.5': 46.4,
    '1.5': 46.3,
    '2.5': 46.2,
    '3.5': 46.1,
    '4.5': 46,
    '5.5': 45.9,
    '6.5': 45.8,
    '7.5': 45.7,
    '8.5': 45.6,
    '9.5': 45.5,
    '10.5': 45.4,
    '11.5': 45.3,
    '12.5': 45.2,
    '13.5': 45.1,
    '14.5': 45,
    '15.5': 44.9,
    '16.5': 44.9,
    '17.5': 44.8,
    '18.5': 44.7,
    '19.5': 44.6,
    '20.5': 44.5,
    '21.5': 44.4,
    '22.5': 44.3,
    '23.5': 44.2,
    '24.5': 44.1,
    '25.5': 44,
    '26.5': 43.9,
    '27.5': 43.9,
    '28.5': 43.8,
    '29.5': 43.7,
    '30.5': 43.6,
    '31.5': 43.5,
    '32.5': 43.4,
    '33.5': 43.3,
    '34.5': 43.2,
    '35.5': 43.1,
    '36.5': 43,
    '37.5': 43,
    '38.5': 42.9,
    '39.5': 42.8,
    '40.5': 42.7,
    '41.5': 42.6,
    '42.5': 42.5,
    '43.5': 42.4,
    '44.5': 42.3,
    '45.5': 42.3,
    '46.5': 42.2,
    '47.5': 42.1,
    '48.5': 42,
    '49.5': 41.9,
    '50.5': 41.8,
    '51.5': 41.7,
    '52.5': 41.6,
    '53.5': 41.6,
    '54.5': 41.5,
    '55.5': 41.4,
    '56.5': 41.3,
    '57.5': 41.2,
    '58.5': 41.1,
    '59.5': 41,
    '60.5': 41,
    '61.5': 40.9,
    '62.5': 40.8,
    '63.5': 40.7,
    '64.5': 40.6,
    '65.5': 40.5,
    '66.5': 40.4,
    '67.5': 40.4,
    '68.5': 40.3,
    '69.5': 40.2,
    '70.5': 40.1,
    '71.5': 40,
    '72.5': 39.9,
    '73.5': 39.9,
    '74.5': 39.8,
    '75.5': 39.7,
    '76.5': 39.6,
    '77.5': 39.5,
    '78.5': 39.4,
    '79.5': 39.4,
    '80.5': 39.3,
    '81.5': 39.2,
    '82.5': 39.1,
    '83.5': 39,
    '84.5': 39,
    '85.5': 38.9,
    '86.5': 38.8,
    '87.5': 38.7,
    '88.5': 38.6,
    '89.5': 38.5,
    '90.5': 38.5,
    '91.5': 38.4,
    '92.5': 38.3,
    '93.5': 38.2,
    '94.5': 38.1,
    '95.5': 38.1,
    '96.5': 38,
    '97.5': 37.9,
    '98.5': 37.8,
    '99.5': 37.7,
    '100.5': 37.7,
    '101.5': 37.6,
    '102.5': 37.5,
    '103.5': 37.4,
    '104.5': 37.3,
    '105.5': 37.3,
    '106.5': 37.2,
    '107.5': 37.1,
    '108.5': 37,
    '109.5': 36.9,
    '110.5': 36.9,
    '111.5': 36.8,
    '112.5': 36.7,
    '113.5': 36.6,
    '114.5': 36.6,
    '115.5': 36.5,
    '116.5': 36.4,
    '117.5': 36.3,
    '118.5': 36.2,
    '119.5': 36.2,
    '120.5': 36.1,
    '121.5': 36,
    '122.5': 35.9,
    '123.5': 35.9,
    '124.5': 35.8,
    '125.5': 35.7,
    '126.5': 35.6,
    '127.5': 35.6,
    '128.5': 35.5,
    '129.5': 35.4,
    '130.5': 35.3,
    '131.5': 35.2,
    '132.5': 35.2,
    '133.5': 35.1,
    '134.5': 35,
    '135.5': 34.9,
    '136.5': 34.9,
    '137.5': 34.8,
    '138.5': 34.7,
    '139.5': 34.6,
    '140.5': 34.6,
    '141.5': 34.5,
    '142.5': 34.4,
    '143.5': 34.3,
    '144.5': 34.3,
    '145.5': 34.2,
    '146.5': 34.1,
    '147.5': 34.1,
    '148.5': 34,
    '149.5': 33.9,
    '150.5': 33.8,
    '151.5': 33.8,
    '152.5': 33.7,
    '153.5': 33.6,
    '154.5': 33.5,
    '155.5': 33.5,
    '156.5': 33.4,
    '157.5': 33.3,
    '158.5': 33.2,
    '159.5': 33.2,
    '160.5': 33.1,
    '161.5': 33,
    '162.5': 33,
    '163.5': 32.9,
    '164.5': 32.8,
    '165.5': 32.7,
    '166.5': 32.7,
    '167.5': 32.6,
    '168.5': 32.5,
    '169.5': 32.5,
    '170.5': 32.4,
    '171.5': 32.3,
    '172.5': 32.2,
    '173.5': 32.2,
    '174.5': 32.1,
    '175.5': 32,
    '176.5': 32,
    '177.5': 31.9,
    '178.5': 31.8,
    '179.5': 31.8,
    '180.5': 31.7,
    '181.5': 31.6,
    '182.5': 31.5,
    '183.5': 31.5,
    '184.5': 31.4,
    '185.5': 31.3,
    '186.5': 31.3,
    '187.5': 31.2,
    '188.5': 31.1,
    '189.5': 31.1,
    '190.5': 31,
    '191.5': 30.9,
    '192.5': 30.8,
    '193.5': 30.8,
    '194.5': 30.7,
  },
  '41.5': {
    '0': 47,
    '1': 46.9,
    '2': 46.8,
    '3': 46.7,
    '4': 46.6,
    '5': 46.5,
    '6': 46.4,
    '7': 46.3,
    '8': 46.2,
    '9': 46.1,
    '10': 46,
    '11': 45.9,
    '12': 45.8,
    '13': 45.7,
    '14': 45.6,
    '15': 45.5,
    '16': 45.4,
    '17': 45.3,
    '18': 45.3,
    '19': 45.2,
    '20': 45.1,
    '21': 45,
    '22': 44.9,
    '23': 44.8,
    '24': 44.7,
    '25': 44.6,
    '26': 44.5,
    '27': 44.4,
    '28': 44.3,
    '29': 44.2,
    '30': 44.2,
    '31': 44.1,
    '32': 44,
    '33': 43.9,
    '34': 43.8,
    '35': 43.7,
    '36': 43.6,
    '37': 43.5,
    '38': 43.4,
    '39': 43.3,
    '40': 43.3,
    '41': 43.2,
    '42': 43.1,
    '43': 43,
    '44': 42.9,
    '45': 42.8,
    '46': 42.7,
    '47': 42.6,
    '48': 42.5,
    '49': 42.5,
    '50': 42.4,
    '51': 42.3,
    '52': 42.2,
    '53': 42.1,
    '54': 42,
    '55': 41.9,
    '56': 41.8,
    '57': 41.8,
    '58': 41.7,
    '59': 41.6,
    '60': 41.5,
    '61': 41.4,
    '62': 41.3,
    '63': 41.2,
    '64': 41.2,
    '65': 41.1,
    '66': 41,
    '67': 40.9,
    '68': 40.8,
    '69': 40.7,
    '70': 40.6,
    '71': 40.6,
    '72': 40.5,
    '73': 40.4,
    '74': 40.3,
    '75': 40.2,
    '76': 40.1,
    '77': 40.1,
    '78': 40,
    '79': 39.9,
    '80': 39.8,
    '81': 39.7,
    '82': 39.6,
    '83': 39.6,
    '84': 39.5,
    '85': 39.4,
    '86': 39.3,
    '87': 39.2,
    '88': 39.1,
    '89': 39.1,
    '90': 39,
    '91': 38.9,
    '92': 38.8,
    '93': 38.7,
    '94': 38.7,
    '95': 38.6,
    '96': 38.5,
    '97': 38.4,
    '98': 38.3,
    '99': 38.2,
    '100': 38.2,
    '101': 38.1,
    '102': 38,
    '103': 37.9,
    '104': 37.8,
    '105': 37.8,
    '106': 37.7,
    '107': 37.6,
    '108': 37.5,
    '109': 37.4,
    '110': 37.4,
    '111': 37.3,
    '112': 37.2,
    '113': 37.1,
    '114': 37.1,
    '115': 37,
    '116': 36.9,
    '117': 36.8,
    '118': 36.7,
    '119': 36.7,
    '120': 36.6,
    '121': 36.5,
    '122': 36.4,
    '123': 36.4,
    '124': 36.3,
    '125': 36.2,
    '126': 36.1,
    '127': 36,
    '128': 36,
    '129': 35.9,
    '130': 35.8,
    '131': 35.7,
    '132': 35.7,
    '133': 35.6,
    '134': 35.5,
    '135': 35.4,
    '136': 35.4,
    '137': 35.3,
    '138': 35.2,
    '139': 35.1,
    '140': 35.1,
    '141': 35,
    '142': 34.9,
    '143': 34.8,
    '144': 34.8,
    '145': 34.7,
    '146': 34.6,
    '147': 34.5,
    '148': 34.5,
    '149': 34.4,
    '150': 34.3,
    '151': 34.2,
    '152': 34.2,
    '153': 34.1,
    '154': 34,
    '155': 33.9,
    '156': 33.9,
    '157': 33.8,
    '158': 33.7,
    '159': 33.6,
    '160': 33.6,
    '161': 33.5,
    '162': 33.4,
    '163': 33.4,
    '164': 33.3,
    '165': 33.2,
    '166': 33.1,
    '167': 33.1,
    '168': 33,
    '169': 32.9,
    '170': 32.8,
    '171': 32.8,
    '172': 32.7,
    '173': 32.6,
    '174': 32.6,
    '175': 32.5,
    '176': 32.4,
    '177': 32.4,
    '178': 32.3,
    '179': 32.2,
    '180': 32.1,
    '181': 32.1,
    '182': 32,
    '183': 31.9,
    '184': 31.9,
    '185': 31.8,
    '186': 31.7,
    '187': 31.6,
    '188': 31.6,
    '189': 31.5,
    '190': 31.4,
    '191': 31.4,
    '192': 31.3,
    '193': 31.2,
    '194': 31.2,
    '195': 31.1,
    '0.5': 46.9,
    '1.5': 46.8,
    '2.5': 46.7,
    '3.5': 46.6,
    '4.5': 46.5,
    '5.5': 46.4,
    '6.5': 46.3,
    '7.5': 46.2,
    '8.5': 46.1,
    '9.5': 46.1,
    '10.5': 46,
    '11.5': 45.9,
    '12.5': 45.8,
    '13.5': 45.7,
    '14.5': 45.6,
    '15.5': 45.5,
    '16.5': 45.4,
    '17.5': 45.3,
    '18.5': 45.2,
    '19.5': 45.1,
    '20.5': 45,
    '21.5': 44.9,
    '22.5': 44.8,
    '23.5': 44.7,
    '24.5': 44.7,
    '25.5': 44.6,
    '26.5': 44.5,
    '27.5': 44.4,
    '28.5': 44.3,
    '29.5': 44.2,
    '30.5': 44.1,
    '31.5': 44,
    '32.5': 43.9,
    '33.5': 43.8,
    '34.5': 43.7,
    '35.5': 43.7,
    '36.5': 43.6,
    '37.5': 43.5,
    '38.5': 43.4,
    '39.5': 43.3,
    '40.5': 43.2,
    '41.5': 43.1,
    '42.5': 43,
    '43.5': 42.9,
    '44.5': 42.9,
    '45.5': 42.8,
    '46.5': 42.7,
    '47.5': 42.6,
    '48.5': 42.5,
    '49.5': 42.4,
    '50.5': 42.3,
    '51.5': 42.2,
    '52.5': 42.2,
    '53.5': 42.1,
    '54.5': 42,
    '55.5': 41.9,
    '56.5': 41.8,
    '57.5': 41.7,
    '58.5': 41.6,
    '59.5': 41.5,
    '60.5': 41.5,
    '61.5': 41.4,
    '62.5': 41.3,
    '63.5': 41.2,
    '64.5': 41.1,
    '65.5': 41,
    '66.5': 40.9,
    '67.5': 40.9,
    '68.5': 40.8,
    '69.5': 40.7,
    '70.5': 40.6,
    '71.5': 40.5,
    '72.5': 40.4,
    '73.5': 40.3,
    '74.5': 40.3,
    '75.5': 40.2,
    '76.5': 40.1,
    '77.5': 40,
    '78.5': 39.9,
    '79.5': 39.8,
    '80.5': 39.8,
    '81.5': 39.7,
    '82.5': 39.6,
    '83.5': 39.5,
    '84.5': 39.4,
    '85.5': 39.4,
    '86.5': 39.3,
    '87.5': 39.2,
    '88.5': 39.1,
    '89.5': 39,
    '90.5': 38.9,
    '91.5': 38.9,
    '92.5': 38.8,
    '93.5': 38.7,
    '94.5': 38.6,
    '95.5': 38.5,
    '96.5': 38.5,
    '97.5': 38.4,
    '98.5': 38.3,
    '99.5': 38.2,
    '100.5': 38.1,
    '101.5': 38,
    '102.5': 38,
    '103.5': 37.9,
    '104.5': 37.8,
    '105.5': 37.7,
    '106.5': 37.6,
    '107.5': 37.6,
    '108.5': 37.5,
    '109.5': 37.4,
    '110.5': 37.3,
    '111.5': 37.3,
    '112.5': 37.2,
    '113.5': 37.1,
    '114.5': 37,
    '115.5': 36.9,
    '116.5': 36.9,
    '117.5': 36.8,
    '118.5': 36.7,
    '119.5': 36.6,
    '120.5': 36.5,
    '121.5': 36.5,
    '122.5': 36.4,
    '123.5': 36.3,
    '124.5': 36.2,
    '125.5': 36.2,
    '126.5': 36.1,
    '127.5': 36,
    '128.5': 35.9,
    '129.5': 35.9,
    '130.5': 35.8,
    '131.5': 35.7,
    '132.5': 35.6,
    '133.5': 35.5,
    '134.5': 35.5,
    '135.5': 35.4,
    '136.5': 35.3,
    '137.5': 35.2,
    '138.5': 35.2,
    '139.5': 35.1,
    '140.5': 35,
    '141.5': 34.9,
    '142.5': 34.9,
    '143.5': 34.8,
    '144.5': 34.7,
    '145.5': 34.6,
    '146.5': 34.6,
    '147.5': 34.5,
    '148.5': 34.4,
    '149.5': 34.3,
    '150.5': 34.3,
    '151.5': 34.2,
    '152.5': 34.1,
    '153.5': 34.1,
    '154.5': 34,
    '155.5': 33.9,
    '156.5': 33.8,
    '157.5': 33.8,
    '158.5': 33.7,
    '159.5': 33.6,
    '160.5': 33.5,
    '161.5': 33.5,
    '162.5': 33.4,
    '163.5': 33.3,
    '164.5': 33.2,
    '165.5': 33.2,
    '166.5': 33.1,
    '167.5': 33,
    '168.5': 33,
    '169.5': 32.9,
    '170.5': 32.8,
    '171.5': 32.7,
    '172.5': 32.7,
    '173.5': 32.6,
    '174.5': 32.5,
    '175.5': 32.5,
    '176.5': 32.4,
    '177.5': 32.3,
    '178.5': 32.2,
    '179.5': 32.2,
    '180.5': 32.1,
    '181.5': 32,
    '182.5': 32,
    '183.5': 31.9,
    '184.5': 31.8,
    '185.5': 31.8,
    '186.5': 31.7,
    '187.5': 31.6,
    '188.5': 31.5,
    '189.5': 31.5,
    '190.5': 31.4,
    '191.5': 31.3,
    '192.5': 31.3,
    '193.5': 31.2,
    '194.5': 31.1,
  },
  '42': {
    '0': 47.5,
    '1': 47.4,
    '2': 47.3,
    '3': 47.2,
    '4': 47.1,
    '5': 47,
    '6': 46.9,
    '7': 46.8,
    '8': 46.7,
    '9': 46.6,
    '10': 46.5,
    '11': 46.4,
    '12': 46.4,
    '13': 46.3,
    '14': 46.2,
    '15': 46.1,
    '16': 46,
    '17': 45.9,
    '18': 45.8,
    '19': 45.7,
    '20': 45.6,
    '21': 45.5,
    '22': 45.4,
    '23': 45.3,
    '24': 45.2,
    '25': 45.1,
    '26': 45,
    '27': 45,
    '28': 44.9,
    '29': 44.8,
    '30': 44.7,
    '31': 44.6,
    '32': 44.5,
    '33': 44.4,
    '34': 44.3,
    '35': 44.2,
    '36': 44.1,
    '37': 44,
    '38': 43.9,
    '39': 43.9,
    '40': 43.8,
    '41': 43.7,
    '42': 43.6,
    '43': 43.5,
    '44': 43.4,
    '45': 43.3,
    '46': 43.2,
    '47': 43.1,
    '48': 43.1,
    '49': 43,
    '50': 42.9,
    '51': 42.8,
    '52': 42.7,
    '53': 42.6,
    '54': 42.5,
    '55': 42.4,
    '56': 42.3,
    '57': 42.3,
    '58': 42.2,
    '59': 42.1,
    '60': 42,
    '61': 41.9,
    '62': 41.8,
    '63': 41.7,
    '64': 41.7,
    '65': 41.6,
    '66': 41.5,
    '67': 41.4,
    '68': 41.3,
    '69': 41.2,
    '70': 41.1,
    '71': 41.1,
    '72': 41,
    '73': 40.9,
    '74': 40.8,
    '75': 40.7,
    '76': 40.6,
    '77': 40.5,
    '78': 40.5,
    '79': 40.4,
    '80': 40.3,
    '81': 40.2,
    '82': 40.1,
    '83': 40,
    '84': 40,
    '85': 39.9,
    '86': 39.8,
    '87': 39.7,
    '88': 39.6,
    '89': 39.5,
    '90': 39.5,
    '91': 39.4,
    '92': 39.3,
    '93': 39.2,
    '94': 39.1,
    '95': 39,
    '96': 39,
    '97': 38.9,
    '98': 38.8,
    '99': 38.7,
    '100': 38.6,
    '101': 38.6,
    '102': 38.5,
    '103': 38.4,
    '104': 38.3,
    '105': 38.2,
    '106': 38.2,
    '107': 38.1,
    '108': 38,
    '109': 37.9,
    '110': 37.8,
    '111': 37.8,
    '112': 37.7,
    '113': 37.6,
    '114': 37.5,
    '115': 37.4,
    '116': 37.4,
    '117': 37.3,
    '118': 37.2,
    '119': 37.1,
    '120': 37,
    '121': 37,
    '122': 36.9,
    '123': 36.8,
    '124': 36.7,
    '125': 36.7,
    '126': 36.6,
    '127': 36.5,
    '128': 36.4,
    '129': 36.3,
    '130': 36.3,
    '131': 36.2,
    '132': 36.1,
    '133': 36,
    '134': 36,
    '135': 35.9,
    '136': 35.8,
    '137': 35.7,
    '138': 35.7,
    '139': 35.6,
    '140': 35.5,
    '141': 35.4,
    '142': 35.3,
    '143': 35.3,
    '144': 35.2,
    '145': 35.1,
    '146': 35,
    '147': 35,
    '148': 34.9,
    '149': 34.8,
    '150': 34.7,
    '151': 34.7,
    '152': 34.6,
    '153': 34.5,
    '154': 34.4,
    '155': 34.4,
    '156': 34.3,
    '157': 34.2,
    '158': 34.2,
    '159': 34.1,
    '160': 34,
    '161': 33.9,
    '162': 33.9,
    '163': 33.8,
    '164': 33.7,
    '165': 33.6,
    '166': 33.6,
    '167': 33.5,
    '168': 33.4,
    '169': 33.3,
    '170': 33.3,
    '171': 33.2,
    '172': 33.1,
    '173': 33.1,
    '174': 33,
    '175': 32.9,
    '176': 32.8,
    '177': 32.8,
    '178': 32.7,
    '179': 32.6,
    '180': 32.6,
    '181': 32.5,
    '182': 32.4,
    '183': 32.3,
    '184': 32.3,
    '185': 32.2,
    '186': 32.1,
    '187': 32.1,
    '188': 32,
    '189': 31.9,
    '190': 31.9,
    '191': 31.8,
    '192': 31.7,
    '193': 31.6,
    '194': 31.6,
    '195': 31.5,
    '0.5': 47.5,
    '1.5': 47.4,
    '2.5': 47.3,
    '3.5': 47.2,
    '4.5': 47.1,
    '5.5': 47,
    '6.5': 46.9,
    '7.5': 46.8,
    '8.5': 46.7,
    '9.5': 46.6,
    '10.5': 46.5,
    '11.5': 46.4,
    '12.5': 46.3,
    '13.5': 46.2,
    '14.5': 46.1,
    '15.5': 46,
    '16.5': 45.9,
    '17.5': 45.8,
    '18.5': 45.7,
    '19.5': 45.6,
    '20.5': 45.6,
    '21.5': 45.5,
    '22.5': 45.4,
    '23.5': 45.3,
    '24.5': 45.2,
    '25.5': 45.1,
    '26.5': 45,
    '27.5': 44.9,
    '28.5': 44.8,
    '29.5': 44.7,
    '30.5': 44.6,
    '31.5': 44.5,
    '32.5': 44.4,
    '33.5': 44.4,
    '34.5': 44.3,
    '35.5': 44.2,
    '36.5': 44.1,
    '37.5': 44,
    '38.5': 43.9,
    '39.5': 43.8,
    '40.5': 43.7,
    '41.5': 43.6,
    '42.5': 43.5,
    '43.5': 43.5,
    '44.5': 43.4,
    '45.5': 43.3,
    '46.5': 43.2,
    '47.5': 43.1,
    '48.5': 43,
    '49.5': 42.9,
    '50.5': 42.8,
    '51.5': 42.7,
    '52.5': 42.7,
    '53.5': 42.6,
    '54.5': 42.5,
    '55.5': 42.4,
    '56.5': 42.3,
    '57.5': 42.2,
    '58.5': 42.1,
    '59.5': 42,
    '60.5': 42,
    '61.5': 41.9,
    '62.5': 41.8,
    '63.5': 41.7,
    '64.5': 41.6,
    '65.5': 41.5,
    '66.5': 41.4,
    '67.5': 41.4,
    '68.5': 41.3,
    '69.5': 41.2,
    '70.5': 41.1,
    '71.5': 41,
    '72.5': 40.9,
    '73.5': 40.8,
    '74.5': 40.8,
    '75.5': 40.7,
    '76.5': 40.6,
    '77.5': 40.5,
    '78.5': 40.4,
    '79.5': 40.3,
    '80.5': 40.2,
    '81.5': 40.2,
    '82.5': 40.1,
    '83.5': 40,
    '84.5': 39.9,
    '85.5': 39.8,
    '86.5': 39.7,
    '87.5': 39.7,
    '88.5': 39.6,
    '89.5': 39.5,
    '90.5': 39.4,
    '91.5': 39.3,
    '92.5': 39.3,
    '93.5': 39.2,
    '94.5': 39.1,
    '95.5': 39,
    '96.5': 38.9,
    '97.5': 38.8,
    '98.5': 38.8,
    '99.5': 38.7,
    '100.5': 38.6,
    '101.5': 38.5,
    '102.5': 38.4,
    '103.5': 38.4,
    '104.5': 38.3,
    '105.5': 38.2,
    '106.5': 38.1,
    '107.5': 38,
    '108.5': 38,
    '109.5': 37.9,
    '110.5': 37.8,
    '111.5': 37.7,
    '112.5': 37.6,
    '113.5': 37.6,
    '114.5': 37.5,
    '115.5': 37.4,
    '116.5': 37.3,
    '117.5': 37.2,
    '118.5': 37.2,
    '119.5': 37.1,
    '120.5': 37,
    '121.5': 36.9,
    '122.5': 36.8,
    '123.5': 36.8,
    '124.5': 36.7,
    '125.5': 36.6,
    '126.5': 36.5,
    '127.5': 36.5,
    '128.5': 36.4,
    '129.5': 36.3,
    '130.5': 36.2,
    '131.5': 36.1,
    '132.5': 36.1,
    '133.5': 36,
    '134.5': 35.9,
    '135.5': 35.8,
    '136.5': 35.8,
    '137.5': 35.7,
    '138.5': 35.6,
    '139.5': 35.5,
    '140.5': 35.5,
    '141.5': 35.4,
    '142.5': 35.3,
    '143.5': 35.2,
    '144.5': 35.2,
    '145.5': 35.1,
    '146.5': 35,
    '147.5': 34.9,
    '148.5': 34.9,
    '149.5': 34.8,
    '150.5': 34.7,
    '151.5': 34.6,
    '152.5': 34.6,
    '153.5': 34.5,
    '154.5': 34.4,
    '155.5': 34.3,
    '156.5': 34.3,
    '157.5': 34.2,
    '158.5': 34.1,
    '159.5': 34,
    '160.5': 34,
    '161.5': 33.9,
    '162.5': 33.8,
    '163.5': 33.8,
    '164.5': 33.7,
    '165.5': 33.6,
    '166.5': 33.5,
    '167.5': 33.5,
    '168.5': 33.4,
    '169.5': 33.3,
    '170.5': 33.2,
    '171.5': 33.2,
    '172.5': 33.1,
    '173.5': 33,
    '174.5': 33,
    '175.5': 32.9,
    '176.5': 32.8,
    '177.5': 32.7,
    '178.5': 32.7,
    '179.5': 32.6,
    '180.5': 32.5,
    '181.5': 32.5,
    '182.5': 32.4,
    '183.5': 32.3,
    '184.5': 32.2,
    '185.5': 32.2,
    '186.5': 32.1,
    '187.5': 32,
    '188.5': 32,
    '189.5': 31.9,
    '190.5': 31.8,
    '191.5': 31.8,
    '192.5': 31.7,
    '193.5': 31.6,
    '194.5': 31.5,
  },
  '42.5': {
    '0': 48.1,
    '1': 48,
    '2': 47.9,
    '3': 47.8,
    '4': 47.7,
    '5': 47.6,
    '6': 47.5,
    '7': 47.4,
    '8': 47.3,
    '9': 47.2,
    '10': 47.1,
    '11': 47,
    '12': 46.9,
    '13': 46.8,
    '14': 46.7,
    '15': 46.6,
    '16': 46.5,
    '17': 46.4,
    '18': 46.3,
    '19': 46.2,
    '20': 46.1,
    '21': 46,
    '22': 45.9,
    '23': 45.9,
    '24': 45.8,
    '25': 45.7,
    '26': 45.6,
    '27': 45.5,
    '28': 45.4,
    '29': 45.3,
    '30': 45.2,
    '31': 45.1,
    '32': 45,
    '33': 44.9,
    '34': 44.8,
    '35': 44.7,
    '36': 44.6,
    '37': 44.6,
    '38': 44.5,
    '39': 44.4,
    '40': 44.3,
    '41': 44.2,
    '42': 44.1,
    '43': 44,
    '44': 43.9,
    '45': 43.8,
    '46': 43.7,
    '47': 43.7,
    '48': 43.6,
    '49': 43.5,
    '50': 43.4,
    '51': 43.3,
    '52': 43.2,
    '53': 43.1,
    '54': 43,
    '55': 42.9,
    '56': 42.9,
    '57': 42.8,
    '58': 42.7,
    '59': 42.6,
    '60': 42.5,
    '61': 42.4,
    '62': 42.3,
    '63': 42.2,
    '64': 42.2,
    '65': 42.1,
    '66': 42,
    '67': 41.9,
    '68': 41.8,
    '69': 41.7,
    '70': 41.6,
    '71': 41.5,
    '72': 41.5,
    '73': 41.4,
    '74': 41.3,
    '75': 41.2,
    '76': 41.1,
    '77': 41,
    '78': 40.9,
    '79': 40.9,
    '80': 40.8,
    '81': 40.7,
    '82': 40.6,
    '83': 40.5,
    '84': 40.4,
    '85': 40.4,
    '86': 40.3,
    '87': 40.2,
    '88': 40.1,
    '89': 40,
    '90': 39.9,
    '91': 39.9,
    '92': 39.8,
    '93': 39.7,
    '94': 39.6,
    '95': 39.5,
    '96': 39.4,
    '97': 39.4,
    '98': 39.3,
    '99': 39.2,
    '100': 39.1,
    '101': 39,
    '102': 38.9,
    '103': 38.9,
    '104': 38.8,
    '105': 38.7,
    '106': 38.6,
    '107': 38.5,
    '108': 38.5,
    '109': 38.4,
    '110': 38.3,
    '111': 38.2,
    '112': 38.1,
    '113': 38.1,
    '114': 38,
    '115': 37.9,
    '116': 37.8,
    '117': 37.7,
    '118': 37.7,
    '119': 37.6,
    '120': 37.5,
    '121': 37.4,
    '122': 37.3,
    '123': 37.3,
    '124': 37.2,
    '125': 37.1,
    '126': 37,
    '127': 37,
    '128': 36.9,
    '129': 36.8,
    '130': 36.7,
    '131': 36.6,
    '132': 36.6,
    '133': 36.5,
    '134': 36.4,
    '135': 36.3,
    '136': 36.3,
    '137': 36.2,
    '138': 36.1,
    '139': 36,
    '140': 35.9,
    '141': 35.9,
    '142': 35.8,
    '143': 35.7,
    '144': 35.6,
    '145': 35.6,
    '146': 35.5,
    '147': 35.4,
    '148': 35.3,
    '149': 35.3,
    '150': 35.2,
    '151': 35.1,
    '152': 35,
    '153': 35,
    '154': 34.9,
    '155': 34.8,
    '156': 34.7,
    '157': 34.7,
    '158': 34.6,
    '159': 34.5,
    '160': 34.4,
    '161': 34.4,
    '162': 34.3,
    '163': 34.2,
    '164': 34.1,
    '165': 34.1,
    '166': 34,
    '167': 33.9,
    '168': 33.9,
    '169': 33.8,
    '170': 33.7,
    '171': 33.6,
    '172': 33.6,
    '173': 33.5,
    '174': 33.4,
    '175': 33.3,
    '176': 33.3,
    '177': 33.2,
    '178': 33.1,
    '179': 33.1,
    '180': 33,
    '181': 32.9,
    '182': 32.8,
    '183': 32.8,
    '184': 32.7,
    '185': 32.6,
    '186': 32.6,
    '187': 32.5,
    '188': 32.4,
    '189': 32.3,
    '190': 32.3,
    '191': 32.2,
    '192': 32.1,
    '193': 32.1,
    '194': 32,
    '195': 31.9,
    '0.5': 48,
    '1.5': 47.9,
    '2.5': 47.8,
    '3.5': 47.7,
    '4.5': 47.6,
    '5.5': 47.5,
    '6.5': 47.4,
    '7.5': 47.3,
    '8.5': 47.2,
    '9.5': 47.1,
    '10.5': 47,
    '11.5': 46.9,
    '12.5': 46.8,
    '13.5': 46.8,
    '14.5': 46.7,
    '15.5': 46.6,
    '16.5': 46.5,
    '17.5': 46.4,
    '18.5': 46.3,
    '19.5': 46.2,
    '20.5': 46.1,
    '21.5': 46,
    '22.5': 45.9,
    '23.5': 45.8,
    '24.5': 45.7,
    '25.5': 45.6,
    '26.5': 45.5,
    '27.5': 45.4,
    '28.5': 45.3,
    '29.5': 45.2,
    '30.5': 45.2,
    '31.5': 45.1,
    '32.5': 45,
    '33.5': 44.9,
    '34.5': 44.8,
    '35.5': 44.7,
    '36.5': 44.6,
    '37.5': 44.5,
    '38.5': 44.4,
    '39.5': 44.3,
    '40.5': 44.2,
    '41.5': 44.2,
    '42.5': 44.1,
    '43.5': 44,
    '44.5': 43.9,
    '45.5': 43.8,
    '46.5': 43.7,
    '47.5': 43.6,
    '48.5': 43.5,
    '49.5': 43.4,
    '50.5': 43.3,
    '51.5': 43.3,
    '52.5': 43.2,
    '53.5': 43.1,
    '54.5': 43,
    '55.5': 42.9,
    '56.5': 42.8,
    '57.5': 42.7,
    '58.5': 42.6,
    '59.5': 42.5,
    '60.5': 42.5,
    '61.5': 42.4,
    '62.5': 42.3,
    '63.5': 42.2,
    '64.5': 42.1,
    '65.5': 42,
    '66.5': 41.9,
    '67.5': 41.8,
    '68.5': 41.8,
    '69.5': 41.7,
    '70.5': 41.6,
    '71.5': 41.5,
    '72.5': 41.4,
    '73.5': 41.3,
    '74.5': 41.2,
    '75.5': 41.2,
    '76.5': 41.1,
    '77.5': 41,
    '78.5': 40.9,
    '79.5': 40.8,
    '80.5': 40.7,
    '81.5': 40.7,
    '82.5': 40.6,
    '83.5': 40.5,
    '84.5': 40.4,
    '85.5': 40.3,
    '86.5': 40.2,
    '87.5': 40.1,
    '88.5': 40.1,
    '89.5': 40,
    '90.5': 39.9,
    '91.5': 39.8,
    '92.5': 39.7,
    '93.5': 39.6,
    '94.5': 39.6,
    '95.5': 39.5,
    '96.5': 39.4,
    '97.5': 39.3,
    '98.5': 39.2,
    '99.5': 39.2,
    '100.5': 39.1,
    '101.5': 39,
    '102.5': 38.9,
    '103.5': 38.8,
    '104.5': 38.7,
    '105.5': 38.7,
    '106.5': 38.6,
    '107.5': 38.5,
    '108.5': 38.4,
    '109.5': 38.3,
    '110.5': 38.3,
    '111.5': 38.2,
    '112.5': 38.1,
    '113.5': 38,
    '114.5': 37.9,
    '115.5': 37.9,
    '116.5': 37.8,
    '117.5': 37.7,
    '118.5': 37.6,
    '119.5': 37.5,
    '120.5': 37.5,
    '121.5': 37.4,
    '122.5': 37.3,
    '123.5': 37.2,
    '124.5': 37.1,
    '125.5': 37.1,
    '126.5': 37,
    '127.5': 36.9,
    '128.5': 36.8,
    '129.5': 36.8,
    '130.5': 36.7,
    '131.5': 36.6,
    '132.5': 36.5,
    '133.5': 36.4,
    '134.5': 36.4,
    '135.5': 36.3,
    '136.5': 36.2,
    '137.5': 36.1,
    '138.5': 36.1,
    '139.5': 36,
    '140.5': 35.9,
    '141.5': 35.8,
    '142.5': 35.8,
    '143.5': 35.7,
    '144.5': 35.6,
    '145.5': 35.5,
    '146.5': 35.5,
    '147.5': 35.4,
    '148.5': 35.3,
    '149.5': 35.2,
    '150.5': 35.2,
    '151.5': 35.1,
    '152.5': 35,
    '153.5': 34.9,
    '154.5': 34.9,
    '155.5': 34.8,
    '156.5': 34.7,
    '157.5': 34.6,
    '158.5': 34.6,
    '159.5': 34.5,
    '160.5': 34.4,
    '161.5': 34.3,
    '162.5': 34.3,
    '163.5': 34.2,
    '164.5': 34.1,
    '165.5': 34,
    '166.5': 34,
    '167.5': 33.9,
    '168.5': 33.8,
    '169.5': 33.7,
    '170.5': 33.7,
    '171.5': 33.6,
    '172.5': 33.5,
    '173.5': 33.5,
    '174.5': 33.4,
    '175.5': 33.3,
    '176.5': 33.2,
    '177.5': 33.2,
    '178.5': 33.1,
    '179.5': 33,
    '180.5': 32.9,
    '181.5': 32.9,
    '182.5': 32.8,
    '183.5': 32.7,
    '184.5': 32.7,
    '185.5': 32.6,
    '186.5': 32.5,
    '187.5': 32.5,
    '188.5': 32.4,
    '189.5': 32.3,
    '190.5': 32.2,
    '191.5': 32.2,
    '192.5': 32.1,
    '193.5': 32,
    '194.5': 32,
  },
  '43': {
    '0': 48.6,
    '1': 48.5,
    '2': 48.4,
    '3': 48.3,
    '4': 48.2,
    '5': 48.1,
    '6': 48,
    '7': 47.9,
    '8': 47.8,
    '9': 47.7,
    '10': 47.6,
    '11': 47.5,
    '12': 47.4,
    '13': 47.3,
    '14': 47.2,
    '15': 47.1,
    '16': 47,
    '17': 47,
    '18': 46.9,
    '19': 46.8,
    '20': 46.7,
    '21': 46.6,
    '22': 46.5,
    '23': 46.4,
    '24': 46.3,
    '25': 46.2,
    '26': 46.1,
    '27': 46,
    '28': 45.9,
    '29': 45.8,
    '30': 45.7,
    '31': 45.6,
    '32': 45.5,
    '33': 45.4,
    '34': 45.4,
    '35': 45.3,
    '36': 45.2,
    '37': 45.1,
    '38': 45,
    '39': 44.9,
    '40': 44.8,
    '41': 44.7,
    '42': 44.6,
    '43': 44.5,
    '44': 44.4,
    '45': 44.3,
    '46': 44.3,
    '47': 44.2,
    '48': 44.1,
    '49': 44,
    '50': 43.9,
    '51': 43.8,
    '52': 43.7,
    '53': 43.6,
    '54': 43.5,
    '55': 43.4,
    '56': 43.4,
    '57': 43.3,
    '58': 43.2,
    '59': 43.1,
    '60': 43,
    '61': 42.9,
    '62': 42.8,
    '63': 42.7,
    '64': 42.6,
    '65': 42.6,
    '66': 42.5,
    '67': 42.4,
    '68': 42.3,
    '69': 42.2,
    '70': 42.1,
    '71': 42,
    '72': 41.9,
    '73': 41.9,
    '74': 41.8,
    '75': 41.7,
    '76': 41.6,
    '77': 41.5,
    '78': 41.4,
    '79': 41.3,
    '80': 41.3,
    '81': 41.2,
    '82': 41.1,
    '83': 41,
    '84': 40.9,
    '85': 40.8,
    '86': 40.7,
    '87': 40.7,
    '88': 40.6,
    '89': 40.5,
    '90': 40.4,
    '91': 40.3,
    '92': 40.2,
    '93': 40.2,
    '94': 40.1,
    '95': 40,
    '96': 39.9,
    '97': 39.8,
    '98': 39.7,
    '99': 39.7,
    '100': 39.6,
    '101': 39.5,
    '102': 39.4,
    '103': 39.3,
    '104': 39.3,
    '105': 39.2,
    '106': 39.1,
    '107': 39,
    '108': 38.9,
    '109': 38.8,
    '110': 38.8,
    '111': 38.7,
    '112': 38.6,
    '113': 38.5,
    '114': 38.4,
    '115': 38.4,
    '116': 38.3,
    '117': 38.2,
    '118': 38.1,
    '119': 38,
    '120': 38,
    '121': 37.9,
    '122': 37.8,
    '123': 37.7,
    '124': 37.6,
    '125': 37.6,
    '126': 37.5,
    '127': 37.4,
    '128': 37.3,
    '129': 37.2,
    '130': 37.2,
    '131': 37.1,
    '132': 37,
    '133': 36.9,
    '134': 36.9,
    '135': 36.8,
    '136': 36.7,
    '137': 36.6,
    '138': 36.5,
    '139': 36.5,
    '140': 36.4,
    '141': 36.3,
    '142': 36.2,
    '143': 36.2,
    '144': 36.1,
    '145': 36,
    '146': 35.9,
    '147': 35.9,
    '148': 35.8,
    '149': 35.7,
    '150': 35.6,
    '151': 35.5,
    '152': 35.5,
    '153': 35.4,
    '154': 35.3,
    '155': 35.2,
    '156': 35.2,
    '157': 35.1,
    '158': 35,
    '159': 34.9,
    '160': 34.9,
    '161': 34.8,
    '162': 34.7,
    '163': 34.6,
    '164': 34.6,
    '165': 34.5,
    '166': 34.4,
    '167': 34.4,
    '168': 34.3,
    '169': 34.2,
    '170': 34.1,
    '171': 34.1,
    '172': 34,
    '173': 33.9,
    '174': 33.8,
    '175': 33.8,
    '176': 33.7,
    '177': 33.6,
    '178': 33.5,
    '179': 33.5,
    '180': 33.4,
    '181': 33.3,
    '182': 33.3,
    '183': 33.2,
    '184': 33.1,
    '185': 33,
    '186': 33,
    '187': 32.9,
    '188': 32.8,
    '189': 32.8,
    '190': 32.7,
    '191': 32.6,
    '192': 32.5,
    '193': 32.5,
    '194': 32.4,
    '195': 32.3,
    '0.5': 48.6,
    '1.5': 48.5,
    '2.5': 48.4,
    '3.5': 48.3,
    '4.5': 48.2,
    '5.5': 48.1,
    '6.5': 48,
    '7.5': 47.9,
    '8.5': 47.8,
    '9.5': 47.7,
    '10.5': 47.6,
    '11.5': 47.5,
    '12.5': 47.4,
    '13.5': 47.3,
    '14.5': 47.2,
    '15.5': 47.1,
    '16.5': 47,
    '17.5': 46.9,
    '18.5': 46.8,
    '19.5': 46.7,
    '20.5': 46.6,
    '21.5': 46.5,
    '22.5': 46.4,
    '23.5': 46.3,
    '24.5': 46.2,
    '25.5': 46.1,
    '26.5': 46.1,
    '27.5': 46,
    '28.5': 45.9,
    '29.5': 45.8,
    '30.5': 45.7,
    '31.5': 45.6,
    '32.5': 45.5,
    '33.5': 45.4,
    '34.5': 45.3,
    '35.5': 45.2,
    '36.5': 45.1,
    '37.5': 45,
    '38.5': 44.9,
    '39.5': 44.8,
    '40.5': 44.8,
    '41.5': 44.7,
    '42.5': 44.6,
    '43.5': 44.5,
    '44.5': 44.4,
    '45.5': 44.3,
    '46.5': 44.2,
    '47.5': 44.1,
    '48.5': 44,
    '49.5': 43.9,
    '50.5': 43.8,
    '51.5': 43.8,
    '52.5': 43.7,
    '53.5': 43.6,
    '54.5': 43.5,
    '55.5': 43.4,
    '56.5': 43.3,
    '57.5': 43.2,
    '58.5': 43.1,
    '59.5': 43,
    '60.5': 43,
    '61.5': 42.9,
    '62.5': 42.8,
    '63.5': 42.7,
    '64.5': 42.6,
    '65.5': 42.5,
    '66.5': 42.4,
    '67.5': 42.3,
    '68.5': 42.3,
    '69.5': 42.2,
    '70.5': 42.1,
    '71.5': 42,
    '72.5': 41.9,
    '73.5': 41.8,
    '74.5': 41.7,
    '75.5': 41.6,
    '76.5': 41.6,
    '77.5': 41.5,
    '78.5': 41.4,
    '79.5': 41.3,
    '80.5': 41.2,
    '81.5': 41.1,
    '82.5': 41,
    '83.5': 41,
    '84.5': 40.9,
    '85.5': 40.8,
    '86.5': 40.7,
    '87.5': 40.6,
    '88.5': 40.5,
    '89.5': 40.5,
    '90.5': 40.4,
    '91.5': 40.3,
    '92.5': 40.2,
    '93.5': 40.1,
    '94.5': 40,
    '95.5': 40,
    '96.5': 39.9,
    '97.5': 39.8,
    '98.5': 39.7,
    '99.5': 39.6,
    '100.5': 39.5,
    '101.5': 39.5,
    '102.5': 39.4,
    '103.5': 39.3,
    '104.5': 39.2,
    '105.5': 39.1,
    '106.5': 39,
    '107.5': 39,
    '108.5': 38.9,
    '109.5': 38.8,
    '110.5': 38.7,
    '111.5': 38.6,
    '112.5': 38.6,
    '113.5': 38.5,
    '114.5': 38.4,
    '115.5': 38.3,
    '116.5': 38.2,
    '117.5': 38.2,
    '118.5': 38.1,
    '119.5': 38,
    '120.5': 37.9,
    '121.5': 37.8,
    '122.5': 37.8,
    '123.5': 37.7,
    '124.5': 37.6,
    '125.5': 37.5,
    '126.5': 37.4,
    '127.5': 37.4,
    '128.5': 37.3,
    '129.5': 37.2,
    '130.5': 37.1,
    '131.5': 37.1,
    '132.5': 37,
    '133.5': 36.9,
    '134.5': 36.8,
    '135.5': 36.7,
    '136.5': 36.7,
    '137.5': 36.6,
    '138.5': 36.5,
    '139.5': 36.4,
    '140.5': 36.4,
    '141.5': 36.3,
    '142.5': 36.2,
    '143.5': 36.1,
    '144.5': 36,
    '145.5': 36,
    '146.5': 35.9,
    '147.5': 35.8,
    '148.5': 35.7,
    '149.5': 35.7,
    '150.5': 35.6,
    '151.5': 35.5,
    '152.5': 35.4,
    '153.5': 35.4,
    '154.5': 35.3,
    '155.5': 35.2,
    '156.5': 35.1,
    '157.5': 35.1,
    '158.5': 35,
    '159.5': 34.9,
    '160.5': 34.8,
    '161.5': 34.8,
    '162.5': 34.7,
    '163.5': 34.6,
    '164.5': 34.5,
    '165.5': 34.5,
    '166.5': 34.4,
    '167.5': 34.3,
    '168.5': 34.2,
    '169.5': 34.2,
    '170.5': 34.1,
    '171.5': 34,
    '172.5': 33.9,
    '173.5': 33.9,
    '174.5': 33.8,
    '175.5': 33.7,
    '176.5': 33.7,
    '177.5': 33.6,
    '178.5': 33.5,
    '179.5': 33.4,
    '180.5': 33.4,
    '181.5': 33.3,
    '182.5': 33.2,
    '183.5': 33.2,
    '184.5': 33.1,
    '185.5': 33,
    '186.5': 32.9,
    '187.5': 32.9,
    '188.5': 32.8,
    '189.5': 32.7,
    '190.5': 32.7,
    '191.5': 32.6,
    '192.5': 32.5,
    '193.5': 32.4,
    '194.5': 32.4,
  },
  '43.5': {
    '0': 49.2,
    '1': 49.1,
    '2': 49,
    '3': 48.9,
    '4': 48.8,
    '5': 48.7,
    '6': 48.6,
    '7': 48.5,
    '8': 48.4,
    '9': 48.3,
    '10': 48.2,
    '11': 48.1,
    '12': 48,
    '13': 47.9,
    '14': 47.8,
    '15': 47.7,
    '16': 47.6,
    '17': 47.5,
    '18': 47.4,
    '19': 47.3,
    '20': 47.2,
    '21': 47.1,
    '22': 47,
    '23': 46.9,
    '24': 46.8,
    '25': 46.7,
    '26': 46.6,
    '27': 46.5,
    '28': 46.4,
    '29': 46.3,
    '30': 46.2,
    '31': 46.2,
    '32': 46.1,
    '33': 46,
    '34': 45.9,
    '35': 45.8,
    '36': 45.7,
    '37': 45.6,
    '38': 45.5,
    '39': 45.4,
    '40': 45.3,
    '41': 45.2,
    '42': 45.1,
    '43': 45,
    '44': 44.9,
    '45': 44.9,
    '46': 44.8,
    '47': 44.7,
    '48': 44.6,
    '49': 44.5,
    '50': 44.4,
    '51': 44.3,
    '52': 44.2,
    '53': 44.1,
    '54': 44,
    '55': 43.9,
    '56': 43.9,
    '57': 43.8,
    '58': 43.7,
    '59': 43.6,
    '60': 43.5,
    '61': 43.4,
    '62': 43.3,
    '63': 43.2,
    '64': 43.1,
    '65': 43.1,
    '66': 43,
    '67': 42.9,
    '68': 42.8,
    '69': 42.7,
    '70': 42.6,
    '71': 42.5,
    '72': 42.4,
    '73': 42.4,
    '74': 42.3,
    '75': 42.2,
    '76': 42.1,
    '77': 42,
    '78': 41.9,
    '79': 41.8,
    '80': 41.7,
    '81': 41.7,
    '82': 41.6,
    '83': 41.5,
    '84': 41.4,
    '85': 41.3,
    '86': 41.2,
    '87': 41.1,
    '88': 41.1,
    '89': 41,
    '90': 40.9,
    '91': 40.8,
    '92': 40.7,
    '93': 40.6,
    '94': 40.6,
    '95': 40.5,
    '96': 40.4,
    '97': 40.3,
    '98': 40.2,
    '99': 40.1,
    '100': 40.1,
    '101': 40,
    '102': 39.9,
    '103': 39.8,
    '104': 39.7,
    '105': 39.6,
    '106': 39.6,
    '107': 39.5,
    '108': 39.4,
    '109': 39.3,
    '110': 39.2,
    '111': 39.1,
    '112': 39.1,
    '113': 39,
    '114': 38.9,
    '115': 38.8,
    '116': 38.7,
    '117': 38.7,
    '118': 38.6,
    '119': 38.5,
    '120': 38.4,
    '121': 38.3,
    '122': 38.3,
    '123': 38.2,
    '124': 38.1,
    '125': 38,
    '126': 37.9,
    '127': 37.9,
    '128': 37.8,
    '129': 37.7,
    '130': 37.6,
    '131': 37.5,
    '132': 37.5,
    '133': 37.4,
    '134': 37.3,
    '135': 37.2,
    '136': 37.1,
    '137': 37.1,
    '138': 37,
    '139': 36.9,
    '140': 36.8,
    '141': 36.8,
    '142': 36.7,
    '143': 36.6,
    '144': 36.5,
    '145': 36.4,
    '146': 36.4,
    '147': 36.3,
    '148': 36.2,
    '149': 36.1,
    '150': 36.1,
    '151': 36,
    '152': 35.9,
    '153': 35.8,
    '154': 35.8,
    '155': 35.7,
    '156': 35.6,
    '157': 35.5,
    '158': 35.5,
    '159': 35.4,
    '160': 35.3,
    '161': 35.2,
    '162': 35.2,
    '163': 35.1,
    '164': 35,
    '165': 34.9,
    '166': 34.9,
    '167': 34.8,
    '168': 34.7,
    '169': 34.6,
    '170': 34.6,
    '171': 34.5,
    '172': 34.4,
    '173': 34.3,
    '174': 34.3,
    '175': 34.2,
    '176': 34.1,
    '177': 34,
    '178': 34,
    '179': 33.9,
    '180': 33.8,
    '181': 33.8,
    '182': 33.7,
    '183': 33.6,
    '184': 33.5,
    '185': 33.5,
    '186': 33.4,
    '187': 33.3,
    '188': 33.2,
    '189': 33.2,
    '190': 33.1,
    '191': 33,
    '192': 33,
    '193': 32.9,
    '194': 32.8,
    '195': 32.7,
    '0.5': 49.1,
    '1.5': 49,
    '2.5': 48.9,
    '3.5': 48.8,
    '4.5': 48.7,
    '5.5': 48.6,
    '6.5': 48.5,
    '7.5': 48.4,
    '8.5': 48.3,
    '9.5': 48.2,
    '10.5': 48.1,
    '11.5': 48,
    '12.5': 47.9,
    '13.5': 47.8,
    '14.5': 47.7,
    '15.5': 47.6,
    '16.5': 47.5,
    '17.5': 47.4,
    '18.5': 47.3,
    '19.5': 47.2,
    '20.5': 47.2,
    '21.5': 47.1,
    '22.5': 47,
    '23.5': 46.9,
    '24.5': 46.8,
    '25.5': 46.7,
    '26.5': 46.6,
    '27.5': 46.5,
    '28.5': 46.4,
    '29.5': 46.3,
    '30.5': 46.2,
    '31.5': 46.1,
    '32.5': 46,
    '33.5': 45.9,
    '34.5': 45.8,
    '35.5': 45.7,
    '36.5': 45.6,
    '37.5': 45.5,
    '38.5': 45.5,
    '39.5': 45.4,
    '40.5': 45.3,
    '41.5': 45.2,
    '42.5': 45.1,
    '43.5': 45,
    '44.5': 44.9,
    '45.5': 44.8,
    '46.5': 44.7,
    '47.5': 44.6,
    '48.5': 44.5,
    '49.5': 44.4,
    '50.5': 44.4,
    '51.5': 44.3,
    '52.5': 44.2,
    '53.5': 44.1,
    '54.5': 44,
    '55.5': 43.9,
    '56.5': 43.8,
    '57.5': 43.7,
    '58.5': 43.6,
    '59.5': 43.5,
    '60.5': 43.5,
    '61.5': 43.4,
    '62.5': 43.3,
    '63.5': 43.2,
    '64.5': 43.1,
    '65.5': 43,
    '66.5': 42.9,
    '67.5': 42.8,
    '68.5': 42.7,
    '69.5': 42.7,
    '70.5': 42.6,
    '71.5': 42.5,
    '72.5': 42.4,
    '73.5': 42.3,
    '74.5': 42.2,
    '75.5': 42.1,
    '76.5': 42,
    '77.5': 42,
    '78.5': 41.9,
    '79.5': 41.8,
    '80.5': 41.7,
    '81.5': 41.6,
    '82.5': 41.5,
    '83.5': 41.4,
    '84.5': 41.4,
    '85.5': 41.3,
    '86.5': 41.2,
    '87.5': 41.1,
    '88.5': 41,
    '89.5': 40.9,
    '90.5': 40.9,
    '91.5': 40.8,
    '92.5': 40.7,
    '93.5': 40.6,
    '94.5': 40.5,
    '95.5': 40.4,
    '96.5': 40.3,
    '97.5': 40.3,
    '98.5': 40.2,
    '99.5': 40.1,
    '100.5': 40,
    '101.5': 39.9,
    '102.5': 39.8,
    '103.5': 39.8,
    '104.5': 39.7,
    '105.5': 39.6,
    '106.5': 39.5,
    '107.5': 39.4,
    '108.5': 39.4,
    '109.5': 39.3,
    '110.5': 39.2,
    '111.5': 39.1,
    '112.5': 39,
    '113.5': 38.9,
    '114.5': 38.9,
    '115.5': 38.8,
    '116.5': 38.7,
    '117.5': 38.6,
    '118.5': 38.5,
    '119.5': 38.5,
    '120.5': 38.4,
    '121.5': 38.3,
    '122.5': 38.2,
    '123.5': 38.1,
    '124.5': 38.1,
    '125.5': 38,
    '126.5': 37.9,
    '127.5': 37.8,
    '128.5': 37.7,
    '129.5': 37.7,
    '130.5': 37.6,
    '131.5': 37.5,
    '132.5': 37.4,
    '133.5': 37.3,
    '134.5': 37.3,
    '135.5': 37.2,
    '136.5': 37.1,
    '137.5': 37,
    '138.5': 37,
    '139.5': 36.9,
    '140.5': 36.8,
    '141.5': 36.7,
    '142.5': 36.6,
    '143.5': 36.6,
    '144.5': 36.5,
    '145.5': 36.4,
    '146.5': 36.3,
    '147.5': 36.3,
    '148.5': 36.2,
    '149.5': 36.1,
    '150.5': 36,
    '151.5': 36,
    '152.5': 35.9,
    '153.5': 35.8,
    '154.5': 35.7,
    '155.5': 35.6,
    '156.5': 35.6,
    '157.5': 35.5,
    '158.5': 35.4,
    '159.5': 35.3,
    '160.5': 35.3,
    '161.5': 35.2,
    '162.5': 35.1,
    '163.5': 35,
    '164.5': 35,
    '165.5': 34.9,
    '166.5': 34.8,
    '167.5': 34.7,
    '168.5': 34.7,
    '169.5': 34.6,
    '170.5': 34.5,
    '171.5': 34.4,
    '172.5': 34.4,
    '173.5': 34.3,
    '174.5': 34.2,
    '175.5': 34.2,
    '176.5': 34.1,
    '177.5': 34,
    '178.5': 33.9,
    '179.5': 33.9,
    '180.5': 33.8,
    '181.5': 33.7,
    '182.5': 33.6,
    '183.5': 33.6,
    '184.5': 33.5,
    '185.5': 33.4,
    '186.5': 33.4,
    '187.5': 33.3,
    '188.5': 33.2,
    '189.5': 33.1,
    '190.5': 33.1,
    '191.5': 33,
    '192.5': 32.9,
    '193.5': 32.9,
    '194.5': 32.8,
  },
  '44': {
    '0': 49.7,
    '1': 49.6,
    '2': 49.5,
    '3': 49.4,
    '4': 49.3,
    '5': 49.2,
    '6': 49.1,
    '7': 49,
    '8': 48.9,
    '9': 48.8,
    '10': 48.7,
    '11': 48.6,
    '12': 48.5,
    '13': 48.4,
    '14': 48.3,
    '15': 48.2,
    '16': 48.1,
    '17': 48,
    '18': 47.9,
    '19': 47.8,
    '20': 47.7,
    '21': 47.6,
    '22': 47.5,
    '23': 47.4,
    '24': 47.3,
    '25': 47.3,
    '26': 47.2,
    '27': 47.1,
    '28': 47,
    '29': 46.9,
    '30': 46.8,
    '31': 46.7,
    '32': 46.6,
    '33': 46.5,
    '34': 46.4,
    '35': 46.3,
    '36': 46.2,
    '37': 46.1,
    '38': 46,
    '39': 45.9,
    '40': 45.8,
    '41': 45.7,
    '42': 45.6,
    '43': 45.6,
    '44': 45.5,
    '45': 45.4,
    '46': 45.3,
    '47': 45.2,
    '48': 45.1,
    '49': 45,
    '50': 44.9,
    '51': 44.8,
    '52': 44.7,
    '53': 44.6,
    '54': 44.5,
    '55': 44.5,
    '56': 44.4,
    '57': 44.3,
    '58': 44.2,
    '59': 44.1,
    '60': 44,
    '61': 43.9,
    '62': 43.8,
    '63': 43.7,
    '64': 43.6,
    '65': 43.6,
    '66': 43.5,
    '67': 43.4,
    '68': 43.3,
    '69': 43.2,
    '70': 43.1,
    '71': 43,
    '72': 42.9,
    '73': 42.8,
    '74': 42.8,
    '75': 42.7,
    '76': 42.6,
    '77': 42.5,
    '78': 42.4,
    '79': 42.3,
    '80': 42.2,
    '81': 42.1,
    '82': 42.1,
    '83': 42,
    '84': 41.9,
    '85': 41.8,
    '86': 41.7,
    '87': 41.6,
    '88': 41.5,
    '89': 41.5,
    '90': 41.4,
    '91': 41.3,
    '92': 41.2,
    '93': 41.1,
    '94': 41,
    '95': 40.9,
    '96': 40.9,
    '97': 40.8,
    '98': 40.7,
    '99': 40.6,
    '100': 40.5,
    '101': 40.4,
    '102': 40.4,
    '103': 40.3,
    '104': 40.2,
    '105': 40.1,
    '106': 40,
    '107': 39.9,
    '108': 39.9,
    '109': 39.8,
    '110': 39.7,
    '111': 39.6,
    '112': 39.5,
    '113': 39.4,
    '114': 39.4,
    '115': 39.3,
    '116': 39.2,
    '117': 39.1,
    '118': 39,
    '119': 39,
    '120': 38.9,
    '121': 38.8,
    '122': 38.7,
    '123': 38.6,
    '124': 38.6,
    '125': 38.5,
    '126': 38.4,
    '127': 38.3,
    '128': 38.2,
    '129': 38.1,
    '130': 38.1,
    '131': 38,
    '132': 37.9,
    '133': 37.8,
    '134': 37.8,
    '135': 37.7,
    '136': 37.6,
    '137': 37.5,
    '138': 37.4,
    '139': 37.4,
    '140': 37.3,
    '141': 37.2,
    '142': 37.1,
    '143': 37,
    '144': 37,
    '145': 36.9,
    '146': 36.8,
    '147': 36.7,
    '148': 36.7,
    '149': 36.6,
    '150': 36.5,
    '151': 36.4,
    '152': 36.3,
    '153': 36.3,
    '154': 36.2,
    '155': 36.1,
    '156': 36,
    '157': 36,
    '158': 35.9,
    '159': 35.8,
    '160': 35.7,
    '161': 35.7,
    '162': 35.6,
    '163': 35.5,
    '164': 35.4,
    '165': 35.4,
    '166': 35.3,
    '167': 35.2,
    '168': 35.1,
    '169': 35.1,
    '170': 35,
    '171': 34.9,
    '172': 34.8,
    '173': 34.8,
    '174': 34.7,
    '175': 34.6,
    '176': 34.5,
    '177': 34.5,
    '178': 34.4,
    '179': 34.3,
    '180': 34.2,
    '181': 34.2,
    '182': 34.1,
    '183': 34,
    '184': 34,
    '185': 33.9,
    '186': 33.8,
    '187': 33.7,
    '188': 33.7,
    '189': 33.6,
    '190': 33.5,
    '191': 33.4,
    '192': 33.4,
    '193': 33.3,
    '194': 33.2,
    '195': 33.2,
    '0.5': 49.7,
    '1.5': 49.6,
    '2.5': 49.5,
    '3.5': 49.4,
    '4.5': 49.3,
    '5.5': 49.2,
    '6.5': 49.1,
    '7.5': 49,
    '8.5': 48.9,
    '9.5': 48.8,
    '10.5': 48.7,
    '11.5': 48.6,
    '12.5': 48.5,
    '13.5': 48.4,
    '14.5': 48.3,
    '15.5': 48.2,
    '16.5': 48.1,
    '17.5': 48,
    '18.5': 47.9,
    '19.5': 47.8,
    '20.5': 47.7,
    '21.5': 47.6,
    '22.5': 47.5,
    '23.5': 47.4,
    '24.5': 47.3,
    '25.5': 47.2,
    '26.5': 47.1,
    '27.5': 47,
    '28.5': 46.9,
    '29.5': 46.8,
    '30.5': 46.7,
    '31.5': 46.6,
    '32.5': 46.5,
    '33.5': 46.4,
    '34.5': 46.3,
    '35.5': 46.3,
    '36.5': 46.2,
    '37.5': 46.1,
    '38.5': 46,
    '39.5': 45.9,
    '40.5': 45.8,
    '41.5': 45.7,
    '42.5': 45.6,
    '43.5': 45.5,
    '44.5': 45.4,
    '45.5': 45.3,
    '46.5': 45.2,
    '47.5': 45.1,
    '48.5': 45,
    '49.5': 45,
    '50.5': 44.9,
    '51.5': 44.8,
    '52.5': 44.7,
    '53.5': 44.6,
    '54.5': 44.5,
    '55.5': 44.4,
    '56.5': 44.3,
    '57.5': 44.2,
    '58.5': 44.1,
    '59.5': 44,
    '60.5': 44,
    '61.5': 43.9,
    '62.5': 43.8,
    '63.5': 43.7,
    '64.5': 43.6,
    '65.5': 43.5,
    '66.5': 43.4,
    '67.5': 43.3,
    '68.5': 43.2,
    '69.5': 43.2,
    '70.5': 43.1,
    '71.5': 43,
    '72.5': 42.9,
    '73.5': 42.8,
    '74.5': 42.7,
    '75.5': 42.6,
    '76.5': 42.5,
    '77.5': 42.4,
    '78.5': 42.4,
    '79.5': 42.3,
    '80.5': 42.2,
    '81.5': 42.1,
    '82.5': 42,
    '83.5': 41.9,
    '84.5': 41.8,
    '85.5': 41.8,
    '86.5': 41.7,
    '87.5': 41.6,
    '88.5': 41.5,
    '89.5': 41.4,
    '90.5': 41.3,
    '91.5': 41.2,
    '92.5': 41.2,
    '93.5': 41.1,
    '94.5': 41,
    '95.5': 40.9,
    '96.5': 40.8,
    '97.5': 40.7,
    '98.5': 40.6,
    '99.5': 40.6,
    '100.5': 40.5,
    '101.5': 40.4,
    '102.5': 40.3,
    '103.5': 40.2,
    '104.5': 40.1,
    '105.5': 40.1,
    '106.5': 40,
    '107.5': 39.9,
    '108.5': 39.8,
    '109.5': 39.7,
    '110.5': 39.7,
    '111.5': 39.6,
    '112.5': 39.5,
    '113.5': 39.4,
    '114.5': 39.3,
    '115.5': 39.2,
    '116.5': 39.2,
    '117.5': 39.1,
    '118.5': 39,
    '119.5': 38.9,
    '120.5': 38.8,
    '121.5': 38.8,
    '122.5': 38.7,
    '123.5': 38.6,
    '124.5': 38.5,
    '125.5': 38.4,
    '126.5': 38.4,
    '127.5': 38.3,
    '128.5': 38.2,
    '129.5': 38.1,
    '130.5': 38,
    '131.5': 37.9,
    '132.5': 37.9,
    '133.5': 37.8,
    '134.5': 37.7,
    '135.5': 37.6,
    '136.5': 37.6,
    '137.5': 37.5,
    '138.5': 37.4,
    '139.5': 37.3,
    '140.5': 37.2,
    '141.5': 37.2,
    '142.5': 37.1,
    '143.5': 37,
    '144.5': 36.9,
    '145.5': 36.9,
    '146.5': 36.8,
    '147.5': 36.7,
    '148.5': 36.6,
    '149.5': 36.5,
    '150.5': 36.5,
    '151.5': 36.4,
    '152.5': 36.3,
    '153.5': 36.2,
    '154.5': 36.2,
    '155.5': 36.1,
    '156.5': 36,
    '157.5': 35.9,
    '158.5': 35.8,
    '159.5': 35.8,
    '160.5': 35.7,
    '161.5': 35.6,
    '162.5': 35.5,
    '163.5': 35.5,
    '164.5': 35.4,
    '165.5': 35.3,
    '166.5': 35.2,
    '167.5': 35.2,
    '168.5': 35.1,
    '169.5': 35,
    '170.5': 34.9,
    '171.5': 34.9,
    '172.5': 34.8,
    '173.5': 34.7,
    '174.5': 34.7,
    '175.5': 34.6,
    '176.5': 34.5,
    '177.5': 34.4,
    '178.5': 34.4,
    '179.5': 34.3,
    '180.5': 34.2,
    '181.5': 34.1,
    '182.5': 34.1,
    '183.5': 34,
    '184.5': 33.9,
    '185.5': 33.8,
    '186.5': 33.8,
    '187.5': 33.7,
    '188.5': 33.6,
    '189.5': 33.6,
    '190.5': 33.5,
    '191.5': 33.4,
    '192.5': 33.3,
    '193.5': 33.3,
    '194.5': 33.2,
  },
  '44.5': {
    '0': 50.3,
    '1': 50.2,
    '2': 50.1,
    '3': 50,
    '4': 49.9,
    '5': 49.8,
    '6': 49.6,
    '7': 49.5,
    '8': 49.5,
    '9': 49.4,
    '10': 49.3,
    '11': 49.2,
    '12': 49.1,
    '13': 49,
    '14': 48.9,
    '15': 48.8,
    '16': 48.7,
    '17': 48.6,
    '18': 48.5,
    '19': 48.4,
    '20': 48.3,
    '21': 48.2,
    '22': 48.1,
    '23': 48,
    '24': 47.9,
    '25': 47.8,
    '26': 47.7,
    '27': 47.6,
    '28': 47.5,
    '29': 47.4,
    '30': 47.3,
    '31': 47.2,
    '32': 47.1,
    '33': 47,
    '34': 46.9,
    '35': 46.8,
    '36': 46.7,
    '37': 46.6,
    '38': 46.5,
    '39': 46.4,
    '40': 46.3,
    '41': 46.3,
    '42': 46.2,
    '43': 46.1,
    '44': 46,
    '45': 45.9,
    '46': 45.8,
    '47': 45.7,
    '48': 45.6,
    '49': 45.5,
    '50': 45.4,
    '51': 45.3,
    '52': 45.2,
    '53': 45.1,
    '54': 45,
    '55': 45,
    '56': 44.9,
    '57': 44.8,
    '58': 44.7,
    '59': 44.6,
    '60': 44.5,
    '61': 44.4,
    '62': 44.3,
    '63': 44.2,
    '64': 44.1,
    '65': 44,
    '66': 44,
    '67': 43.9,
    '68': 43.8,
    '69': 43.7,
    '70': 43.6,
    '71': 43.5,
    '72': 43.4,
    '73': 43.3,
    '74': 43.2,
    '75': 43.2,
    '76': 43.1,
    '77': 43,
    '78': 42.9,
    '79': 42.8,
    '80': 42.7,
    '81': 42.6,
    '82': 42.5,
    '83': 42.5,
    '84': 42.4,
    '85': 42.3,
    '86': 42.2,
    '87': 42.1,
    '88': 42,
    '89': 41.9,
    '90': 41.8,
    '91': 41.8,
    '92': 41.7,
    '93': 41.6,
    '94': 41.5,
    '95': 41.4,
    '96': 41.3,
    '97': 41.2,
    '98': 41.2,
    '99': 41.1,
    '100': 41,
    '101': 40.9,
    '102': 40.8,
    '103': 40.7,
    '104': 40.7,
    '105': 40.6,
    '106': 40.5,
    '107': 40.4,
    '108': 40.3,
    '109': 40.2,
    '110': 40.2,
    '111': 40.1,
    '112': 40,
    '113': 39.9,
    '114': 39.8,
    '115': 39.7,
    '116': 39.7,
    '117': 39.6,
    '118': 39.5,
    '119': 39.4,
    '120': 39.3,
    '121': 39.2,
    '122': 39.2,
    '123': 39.1,
    '124': 39,
    '125': 38.9,
    '126': 38.8,
    '127': 38.8,
    '128': 38.7,
    '129': 38.6,
    '130': 38.5,
    '131': 38.4,
    '132': 38.4,
    '133': 38.3,
    '134': 38.2,
    '135': 38.1,
    '136': 38,
    '137': 38,
    '138': 37.9,
    '139': 37.8,
    '140': 37.7,
    '141': 37.6,
    '142': 37.6,
    '143': 37.5,
    '144': 37.4,
    '145': 37.3,
    '146': 37.3,
    '147': 37.2,
    '148': 37.1,
    '149': 37,
    '150': 36.9,
    '151': 36.9,
    '152': 36.8,
    '153': 36.7,
    '154': 36.6,
    '155': 36.6,
    '156': 36.5,
    '157': 36.4,
    '158': 36.3,
    '159': 36.2,
    '160': 36.2,
    '161': 36.1,
    '162': 36,
    '163': 35.9,
    '164': 35.9,
    '165': 35.8,
    '166': 35.7,
    '167': 35.6,
    '168': 35.6,
    '169': 35.5,
    '170': 35.4,
    '171': 35.3,
    '172': 35.3,
    '173': 35.2,
    '174': 35.1,
    '175': 35,
    '176': 35,
    '177': 34.9,
    '178': 34.8,
    '179': 34.7,
    '180': 34.7,
    '181': 34.6,
    '182': 34.5,
    '183': 34.4,
    '184': 34.4,
    '185': 34.3,
    '186': 34.2,
    '187': 34.2,
    '188': 34.1,
    '189': 34,
    '190': 33.9,
    '191': 33.9,
    '192': 33.8,
    '193': 33.7,
    '194': 33.6,
    '195': 33.6,
    '0.5': 50.2,
    '1.5': 50.1,
    '2.5': 50,
    '3.5': 49.9,
    '4.5': 49.8,
    '5.5': 49.7,
    '6.5': 49.6,
    '7.5': 49.5,
    '8.5': 49.4,
    '9.5': 49.3,
    '10.5': 49.2,
    '11.5': 49.1,
    '12.5': 49,
    '13.5': 48.9,
    '14.5': 48.8,
    '15.5': 48.7,
    '16.5': 48.6,
    '17.5': 48.5,
    '18.5': 48.4,
    '19.5': 48.3,
    '20.5': 48.2,
    '21.5': 48.1,
    '22.5': 48,
    '23.5': 47.9,
    '24.5': 47.8,
    '25.5': 47.7,
    '26.5': 47.6,
    '27.5': 47.5,
    '28.5': 47.4,
    '29.5': 47.3,
    '30.5': 47.3,
    '31.5': 47.2,
    '32.5': 47.1,
    '33.5': 47,
    '34.5': 46.9,
    '35.5': 46.8,
    '36.5': 46.7,
    '37.5': 46.6,
    '38.5': 46.5,
    '39.5': 46.4,
    '40.5': 46.3,
    '41.5': 46.2,
    '42.5': 46.1,
    '43.5': 46,
    '44.5': 45.9,
    '45.5': 45.8,
    '46.5': 45.7,
    '47.5': 45.6,
    '48.5': 45.6,
    '49.5': 45.5,
    '50.5': 45.4,
    '51.5': 45.3,
    '52.5': 45.2,
    '53.5': 45.1,
    '54.5': 45,
    '55.5': 44.9,
    '56.5': 44.8,
    '57.5': 44.7,
    '58.5': 44.6,
    '59.5': 44.5,
    '60.5': 44.5,
    '61.5': 44.4,
    '62.5': 44.3,
    '63.5': 44.2,
    '64.5': 44.1,
    '65.5': 44,
    '66.5': 43.9,
    '67.5': 43.8,
    '68.5': 43.7,
    '69.5': 43.6,
    '70.5': 43.6,
    '71.5': 43.5,
    '72.5': 43.4,
    '73.5': 43.3,
    '74.5': 43.2,
    '75.5': 43.1,
    '76.5': 43,
    '77.5': 42.9,
    '78.5': 42.8,
    '79.5': 42.8,
    '80.5': 42.7,
    '81.5': 42.6,
    '82.5': 42.5,
    '83.5': 42.4,
    '84.5': 42.3,
    '85.5': 42.2,
    '86.5': 42.2,
    '87.5': 42.1,
    '88.5': 42,
    '89.5': 41.9,
    '90.5': 41.8,
    '91.5': 41.7,
    '92.5': 41.6,
    '93.5': 41.5,
    '94.5': 41.5,
    '95.5': 41.4,
    '96.5': 41.3,
    '97.5': 41.2,
    '98.5': 41.1,
    '99.5': 41,
    '100.5': 41,
    '101.5': 40.9,
    '102.5': 40.8,
    '103.5': 40.7,
    '104.5': 40.6,
    '105.5': 40.5,
    '106.5': 40.4,
    '107.5': 40.4,
    '108.5': 40.3,
    '109.5': 40.2,
    '110.5': 40.1,
    '111.5': 40,
    '112.5': 39.9,
    '113.5': 39.9,
    '114.5': 39.8,
    '115.5': 39.7,
    '116.5': 39.6,
    '117.5': 39.5,
    '118.5': 39.5,
    '119.5': 39.4,
    '120.5': 39.3,
    '121.5': 39.2,
    '122.5': 39.1,
    '123.5': 39,
    '124.5': 39,
    '125.5': 38.9,
    '126.5': 38.8,
    '127.5': 38.7,
    '128.5': 38.6,
    '129.5': 38.6,
    '130.5': 38.5,
    '131.5': 38.4,
    '132.5': 38.3,
    '133.5': 38.2,
    '134.5': 38.2,
    '135.5': 38.1,
    '136.5': 38,
    '137.5': 37.9,
    '138.5': 37.8,
    '139.5': 37.8,
    '140.5': 37.7,
    '141.5': 37.6,
    '142.5': 37.5,
    '143.5': 37.4,
    '144.5': 37.4,
    '145.5': 37.3,
    '146.5': 37.2,
    '147.5': 37.1,
    '148.5': 37.1,
    '149.5': 37,
    '150.5': 36.9,
    '151.5': 36.8,
    '152.5': 36.7,
    '153.5': 36.7,
    '154.5': 36.6,
    '155.5': 36.5,
    '156.5': 36.4,
    '157.5': 36.4,
    '158.5': 36.3,
    '159.5': 36.2,
    '160.5': 36.1,
    '161.5': 36.1,
    '162.5': 36,
    '163.5': 35.9,
    '164.5': 35.8,
    '165.5': 35.8,
    '166.5': 35.7,
    '167.5': 35.6,
    '168.5': 35.5,
    '169.5': 35.4,
    '170.5': 35.4,
    '171.5': 35.3,
    '172.5': 35.2,
    '173.5': 35.1,
    '174.5': 35.1,
    '175.5': 35,
    '176.5': 34.9,
    '177.5': 34.9,
    '178.5': 34.8,
    '179.5': 34.7,
    '180.5': 34.6,
    '181.5': 34.6,
    '182.5': 34.5,
    '183.5': 34.4,
    '184.5': 34.3,
    '185.5': 34.3,
    '186.5': 34.2,
    '187.5': 34.1,
    '188.5': 34,
    '189.5': 34,
    '190.5': 33.9,
    '191.5': 33.8,
    '192.5': 33.8,
    '193.5': 33.7,
    '194.5': 33.6,
  },
  '45': {
    '0': 50.8,
    '1': 50.7,
    '2': 50.6,
    '3': 50.5,
    '4': 50.4,
    '5': 50.3,
    '6': 50.2,
    '7': 50.1,
    '8': 50,
    '9': 49.9,
    '10': 49.8,
    '11': 49.7,
    '12': 49.6,
    '13': 49.5,
    '14': 49.4,
    '15': 49.3,
    '16': 49.2,
    '17': 49.1,
    '18': 49,
    '19': 48.9,
    '20': 48.8,
    '21': 48.7,
    '22': 48.6,
    '23': 48.5,
    '24': 48.4,
    '25': 48.3,
    '26': 48.2,
    '27': 48.1,
    '28': 48,
    '29': 47.9,
    '30': 47.8,
    '31': 47.7,
    '32': 47.6,
    '33': 47.5,
    '34': 47.4,
    '35': 47.3,
    '36': 47.2,
    '37': 47.2,
    '38': 47.1,
    '39': 47,
    '40': 46.9,
    '41': 46.8,
    '42': 46.7,
    '43': 46.6,
    '44': 46.5,
    '45': 46.4,
    '46': 46.3,
    '47': 46.2,
    '48': 46.1,
    '49': 46,
    '50': 45.9,
    '51': 45.8,
    '52': 45.7,
    '53': 45.6,
    '54': 45.6,
    '55': 45.5,
    '56': 45.4,
    '57': 45.3,
    '58': 45.2,
    '59': 45.1,
    '60': 45,
    '61': 44.9,
    '62': 44.8,
    '63': 44.7,
    '64': 44.6,
    '65': 44.5,
    '66': 44.5,
    '67': 44.4,
    '68': 44.3,
    '69': 44.2,
    '70': 44.1,
    '71': 44,
    '72': 43.9,
    '73': 43.8,
    '74': 43.8,
    '75': 43.6,
    '76': 43.6,
    '77': 43.5,
    '78': 43.4,
    '79': 43.3,
    '80': 43.2,
    '81': 43.1,
    '82': 43,
    '83': 42.9,
    '84': 42.8,
    '85': 42.8,
    '86': 42.7,
    '87': 42.6,
    '88': 42.5,
    '89': 42.4,
    '90': 42.3,
    '91': 42.2,
    '92': 42.2,
    '93': 42.1,
    '94': 42,
    '95': 41.9,
    '96': 41.8,
    '97': 41.7,
    '98': 41.6,
    '99': 41.5,
    '100': 41.5,
    '101': 41.4,
    '102': 41.3,
    '103': 41.2,
    '104': 41.1,
    '105': 41,
    '106': 41,
    '107': 40.9,
    '108': 40.8,
    '109': 40.7,
    '110': 40.6,
    '111': 40.5,
    '112': 40.5,
    '113': 40.4,
    '114': 40.3,
    '115': 40.2,
    '116': 40.1,
    '117': 40,
    '118': 40,
    '119': 39.9,
    '120': 39.8,
    '121': 39.7,
    '122': 39.6,
    '123': 39.5,
    '124': 39.5,
    '125': 39.4,
    '126': 39.3,
    '127': 39.2,
    '128': 39.1,
    '129': 39,
    '130': 39,
    '131': 38.9,
    '132': 38.8,
    '133': 38.7,
    '134': 38.6,
    '135': 38.6,
    '136': 38.5,
    '137': 38.4,
    '138': 38.3,
    '139': 38.2,
    '140': 38.2,
    '141': 38.1,
    '142': 38,
    '143': 37.9,
    '144': 37.8,
    '145': 37.8,
    '146': 37.7,
    '147': 37.6,
    '148': 37.5,
    '149': 37.5,
    '150': 37.4,
    '151': 37.3,
    '152': 37.2,
    '153': 37.1,
    '154': 37.1,
    '155': 37,
    '156': 36.9,
    '157': 36.8,
    '158': 36.8,
    '159': 36.7,
    '160': 36.6,
    '161': 36.5,
    '162': 36.4,
    '163': 36.4,
    '164': 36.3,
    '165': 36.2,
    '166': 36.1,
    '167': 36.1,
    '168': 36,
    '169': 35.9,
    '170': 35.8,
    '171': 35.8,
    '172': 35.7,
    '173': 35.6,
    '174': 35.5,
    '175': 35.5,
    '176': 35.4,
    '177': 35.3,
    '178': 35.2,
    '179': 35.2,
    '180': 35.1,
    '181': 35,
    '182': 34.9,
    '183': 34.9,
    '184': 34.8,
    '185': 34.7,
    '186': 34.6,
    '187': 34.6,
    '188': 34.5,
    '189': 34.4,
    '190': 34.3,
    '191': 34.3,
    '192': 34.2,
    '193': 34.1,
    '194': 34.1,
    '195': 34,
    '0.5': 50.8,
    '1.5': 50.7,
    '2.5': 50.6,
    '3.5': 50.4,
    '4.5': 50.3,
    '5.5': 50.2,
    '6.5': 50.1,
    '7.5': 50,
    '8.5': 49.9,
    '9.5': 49.8,
    '10.5': 49.7,
    '11.5': 49.6,
    '12.5': 49.5,
    '13.5': 49.4,
    '14.5': 49.3,
    '15.5': 49.2,
    '16.5': 49.1,
    '17.5': 49,
    '18.5': 48.9,
    '19.5': 48.8,
    '20.5': 48.7,
    '21.5': 48.7,
    '22.5': 48.6,
    '23.5': 48.5,
    '24.5': 48.4,
    '25.5': 48.3,
    '26.5': 48.2,
    '27.5': 48.1,
    '28.5': 48,
    '29.5': 47.9,
    '30.5': 47.8,
    '31.5': 47.7,
    '32.5': 47.6,
    '33.5': 47.5,
    '34.5': 47.4,
    '35.5': 47.3,
    '36.5': 47.2,
    '37.5': 47.1,
    '38.5': 47,
    '39.5': 46.9,
    '40.5': 46.8,
    '41.5': 46.7,
    '42.5': 46.6,
    '43.5': 46.5,
    '44.5': 46.4,
    '45.5': 46.3,
    '46.5': 46.3,
    '47.5': 46.2,
    '48.5': 46.1,
    '49.5': 46,
    '50.5': 45.9,
    '51.5': 45.8,
    '52.5': 45.7,
    '53.5': 45.6,
    '54.5': 45.5,
    '55.5': 45.4,
    '56.5': 45.3,
    '57.5': 45.2,
    '58.5': 45.1,
    '59.5': 45,
    '60.5': 45,
    '61.5': 44.9,
    '62.5': 44.8,
    '63.5': 44.7,
    '64.5': 44.6,
    '65.5': 44.5,
    '66.5': 44.4,
    '67.5': 44.3,
    '68.5': 44.2,
    '69.5': 44.1,
    '70.5': 44,
    '71.5': 44,
    '72.5': 43.9,
    '73.5': 43.8,
    '74.5': 43.7,
    '75.5': 43.6,
    '76.5': 43.5,
    '77.5': 43.4,
    '78.5': 43.3,
    '79.5': 43.2,
    '80.5': 43.2,
    '81.5': 43.1,
    '82.5': 43,
    '83.5': 42.9,
    '84.5': 42.8,
    '85.5': 42.7,
    '86.5': 42.6,
    '87.5': 42.5,
    '88.5': 42.5,
    '89.5': 42.4,
    '90.5': 42.3,
    '91.5': 42.2,
    '92.5': 42.1,
    '93.5': 42,
    '94.5': 41.9,
    '95.5': 41.8,
    '96.5': 41.8,
    '97.5': 41.7,
    '98.5': 41.6,
    '99.5': 41.5,
    '100.5': 41.4,
    '101.5': 41.3,
    '102.5': 41.2,
    '103.5': 41.2,
    '104.5': 41.1,
    '105.5': 41,
    '106.5': 40.9,
    '107.5': 40.8,
    '108.5': 40.7,
    '109.5': 40.7,
    '110.5': 40.6,
    '111.5': 40.5,
    '112.5': 40.4,
    '113.5': 40.3,
    '114.5': 40.2,
    '115.5': 40.2,
    '116.5': 40.1,
    '117.5': 40,
    '118.5': 39.9,
    '119.5': 39.8,
    '120.5': 39.7,
    '121.5': 39.7,
    '122.5': 39.6,
    '123.5': 39.5,
    '124.5': 39.4,
    '125.5': 39.3,
    '126.5': 39.3,
    '127.5': 39.2,
    '128.5': 39.1,
    '129.5': 39,
    '130.5': 38.9,
    '131.5': 38.8,
    '132.5': 38.8,
    '133.5': 38.7,
    '134.5': 38.6,
    '135.5': 38.5,
    '136.5': 38.4,
    '137.5': 38.4,
    '138.5': 38.3,
    '139.5': 38.2,
    '140.5': 38.1,
    '141.5': 38,
    '142.5': 38,
    '143.5': 37.9,
    '144.5': 37.8,
    '145.5': 37.7,
    '146.5': 37.7,
    '147.5': 37.6,
    '148.5': 37.5,
    '149.5': 37.4,
    '150.5': 37.3,
    '151.5': 37.3,
    '152.5': 37.2,
    '153.5': 37.1,
    '154.5': 37,
    '155.5': 36.9,
    '156.5': 36.9,
    '157.5': 36.8,
    '158.5': 36.7,
    '159.5': 36.6,
    '160.5': 36.6,
    '161.5': 36.5,
    '162.5': 36.4,
    '163.5': 36.3,
    '164.5': 36.3,
    '165.5': 36.2,
    '166.5': 36.1,
    '167.5': 36,
    '168.5': 36,
    '169.5': 35.9,
    '170.5': 35.8,
    '171.5': 35.7,
    '172.5': 35.6,
    '173.5': 35.6,
    '174.5': 35.5,
    '175.5': 35.4,
    '176.5': 35.3,
    '177.5': 35.3,
    '178.5': 35.2,
    '179.5': 35.1,
    '180.5': 35,
    '181.5': 35,
    '182.5': 34.9,
    '183.5': 34.8,
    '184.5': 34.8,
    '185.5': 34.7,
    '186.5': 34.6,
    '187.5': 34.5,
    '188.5': 34.5,
    '189.5': 34.4,
    '190.5': 34.3,
    '191.5': 34.2,
    '192.5': 34.2,
    '193.5': 34.1,
    '194.5': 34,
  },
  // 45 - 50
  '45.5': {
    '0': 51.4,
    '1': 51.3,
    '2': 51.2,
    '3': 51,
    '4': 50.9,
    '5': 50.8,
    '6': 50.7,
    '7': 50.6,
    '8': 50.5,
    '9': 50.4,
    '10': 50.3,
    '11': 50.2,
    '12': 50.1,
    '13': 50,
    '14': 49.9,
    '15': 49.8,
    '16': 49.7,
    '17': 49.6,
    '18': 49.5,
    '19': 49.4,
    '20': 49.3,
    '21': 49.2,
    '22': 49.1,
    '23': 49,
    '24': 48.9,
    '25': 48.8,
    '26': 48.7,
    '27': 48.6,
    '28': 48.5,
    '29': 48.4,
    '30': 48.3,
    '31': 48.3,
    '32': 48.2,
    '33': 48.1,
    '34': 48,
    '35': 47.9,
    '36': 47.8,
    '37': 47.7,
    '38': 47.6,
    '39': 47.5,
    '40': 47.4,
    '41': 47.3,
    '42': 47.2,
    '43': 47.1,
    '44': 47,
    '45': 46.9,
    '46': 46.8,
    '47': 46.7,
    '48': 46.6,
    '49': 46.5,
    '50': 46.4,
    '51': 46.3,
    '52': 46.2,
    '53': 46.2,
    '54': 46.1,
    '55': 46,
    '56': 45.9,
    '57': 45.8,
    '58': 45.7,
    '59': 45.6,
    '60': 45.5,
    '61': 45.4,
    '62': 45.3,
    '63': 45.2,
    '64': 45.1,
    '65': 45,
    '66': 44.9,
    '67': 44.9,
    '68': 44.8,
    '69': 44.7,
    '70': 44.6,
    '71': 44.5,
    '72': 44.4,
    '73': 44.3,
    '74': 44.2,
    '75': 44.1,
    '76': 44,
    '77': 44,
    '78': 43.9,
    '79': 43.8,
    '80': 43.7,
    '81': 43.6,
    '82': 43.5,
    '83': 43.4,
    '84': 43.3,
    '85': 43.2,
    '86': 43.2,
    '87': 43.1,
    '88': 43,
    '89': 42.9,
    '90': 42.8,
    '91': 42.7,
    '92': 42.6,
    '93': 42.5,
    '94': 42.5,
    '95': 42.4,
    '96': 42.3,
    '97': 42.2,
    '98': 42.1,
    '99': 42,
    '100': 41.9,
    '101': 41.8,
    '102': 41.8,
    '103': 41.7,
    '104': 41.6,
    '105': 41.5,
    '106': 41.4,
    '107': 41.3,
    '108': 41.2,
    '109': 41.2,
    '110': 41.1,
    '111': 41,
    '112': 40.9,
    '113': 40.8,
    '114': 40.7,
    '115': 40.7,
    '116': 40.6,
    '117': 40.5,
    '118': 40.4,
    '119': 40.3,
    '120': 40.2,
    '121': 40.2,
    '122': 40.1,
    '123': 40,
    '124': 39.9,
    '125': 39.8,
    '126': 39.7,
    '127': 39.7,
    '128': 39.6,
    '129': 39.5,
    '130': 39.4,
    '131': 39.3,
    '132': 39.3,
    '133': 39.2,
    '134': 39.1,
    '135': 39,
    '136': 38.9,
    '137': 38.9,
    '138': 38.8,
    '139': 38.7,
    '140': 38.6,
    '141': 38.5,
    '142': 38.4,
    '143': 38.4,
    '144': 38.3,
    '145': 38.2,
    '146': 38.1,
    '147': 38.1,
    '148': 38,
    '149': 37.9,
    '150': 37.8,
    '151': 37.7,
    '152': 37.7,
    '153': 37.6,
    '154': 37.5,
    '155': 37.4,
    '156': 37.3,
    '157': 37.3,
    '158': 37.2,
    '159': 37.1,
    '160': 37,
    '161': 37,
    '162': 36.9,
    '163': 36.8,
    '164': 36.7,
    '165': 36.6,
    '166': 36.6,
    '167': 36.5,
    '168': 36.4,
    '169': 36.3,
    '170': 36.3,
    '171': 36.2,
    '172': 36.1,
    '173': 36,
    '174': 36,
    '175': 35.9,
    '176': 35.8,
    '177': 35.7,
    '178': 35.7,
    '179': 35.6,
    '180': 35.5,
    '181': 35.4,
    '182': 35.4,
    '183': 35.3,
    '184': 35.2,
    '185': 35.1,
    '186': 35.1,
    '187': 35,
    '188': 34.9,
    '189': 34.8,
    '190': 34.8,
    '191': 34.7,
    '192': 34.6,
    '193': 34.5,
    '194': 34.5,
    '195': 34.4,
    '0.5': 51.3,
    '1.5': 51.2,
    '2.5': 51.1,
    '3.5': 51,
    '4.5': 50.9,
    '5.5': 50.8,
    '6.5': 50.7,
    '7.5': 50.6,
    '8.5': 50.5,
    '9.5': 50.4,
    '10.5': 50.3,
    '11.5': 50.2,
    '12.5': 50.1,
    '13.5': 50,
    '14.5': 49.9,
    '15.5': 49.8,
    '16.5': 49.7,
    '17.5': 49.6,
    '18.5': 49.5,
    '19.5': 49.4,
    '20.5': 49.3,
    '21.5': 49.2,
    '22.5': 49.1,
    '23.5': 49,
    '24.5': 48.9,
    '25.5': 48.8,
    '26.5': 48.7,
    '27.5': 48.6,
    '28.5': 48.5,
    '29.5': 48.4,
    '30.5': 48.3,
    '31.5': 48.2,
    '32.5': 48.1,
    '33.5': 48,
    '34.5': 47.9,
    '35.5': 47.8,
    '36.5': 47.7,
    '37.5': 47.6,
    '38.5': 47.5,
    '39.5': 47.4,
    '40.5': 47.3,
    '41.5': 47.2,
    '42.5': 47.1,
    '43.5': 47,
    '44.5': 47,
    '45.5': 46.9,
    '46.5': 46.8,
    '47.5': 46.7,
    '48.5': 46.6,
    '49.5': 46.5,
    '50.5': 46.4,
    '51.5': 46.3,
    '52.5': 46.2,
    '53.5': 46.1,
    '54.5': 46,
    '55.5': 45.9,
    '56.5': 45.8,
    '57.5': 45.7,
    '58.5': 45.6,
    '59.5': 45.5,
    '60.5': 45.5,
    '61.5': 45.4,
    '62.5': 45.3,
    '63.5': 45.2,
    '64.5': 45.1,
    '65.5': 45,
    '66.5': 44.9,
    '67.5': 44.8,
    '68.5': 44.7,
    '69.5': 44.6,
    '70.5': 44.5,
    '71.5': 44.4,
    '72.5': 44.4,
    '73.5': 44.3,
    '74.5': 44.2,
    '75.5': 44.1,
    '76.5': 44,
    '77.5': 43.9,
    '78.5': 43.8,
    '79.5': 43.7,
    '80.5': 43.6,
    '81.5': 43.6,
    '82.5': 43.5,
    '83.5': 43.4,
    '84.5': 43.3,
    '85.5': 43.2,
    '86.5': 43.1,
    '87.5': 43,
    '88.5': 42.9,
    '89.5': 42.8,
    '90.5': 42.8,
    '91.5': 42.7,
    '92.5': 42.6,
    '93.5': 42.5,
    '94.5': 42.4,
    '95.5': 42.3,
    '96.5': 42.2,
    '97.5': 42.1,
    '98.5': 42.1,
    '99.5': 42,
    '100.5': 41.9,
    '101.5': 41.8,
    '102.5': 41.7,
    '103.5': 41.6,
    '104.5': 41.5,
    '105.5': 41.5,
    '106.5': 41.4,
    '107.5': 41.3,
    '108.5': 41.2,
    '109.5': 41.1,
    '110.5': 41,
    '111.5': 41,
    '112.5': 40.9,
    '113.5': 40.8,
    '114.5': 40.7,
    '115.5': 40.6,
    '116.5': 40.5,
    '117.5': 40.5,
    '118.5': 40.4,
    '119.5': 40.3,
    '120.5': 40.2,
    '121.5': 40.1,
    '122.5': 40,
    '123.5': 40,
    '124.5': 39.9,
    '125.5': 39.8,
    '126.5': 39.7,
    '127.5': 39.6,
    '128.5': 39.5,
    '129.5': 39.5,
    '130.5': 39.4,
    '131.5': 39.3,
    '132.5': 39.2,
    '133.5': 39.1,
    '134.5': 39.1,
    '135.5': 39,
    '136.5': 38.9,
    '137.5': 38.8,
    '138.5': 38.7,
    '139.5': 38.6,
    '140.5': 38.6,
    '141.5': 38.5,
    '142.5': 38.4,
    '143.5': 38.3,
    '144.5': 38.3,
    '145.5': 38.2,
    '146.5': 38.1,
    '147.5': 38,
    '148.5': 37.9,
    '149.5': 37.9,
    '150.5': 37.8,
    '151.5': 37.7,
    '152.5': 37.6,
    '153.5': 37.5,
    '154.5': 37.5,
    '155.5': 37.4,
    '156.5': 37.3,
    '157.5': 37.2,
    '158.5': 37.1,
    '159.5': 37.1,
    '160.5': 37,
    '161.5': 36.9,
    '162.5': 36.8,
    '163.5': 36.8,
    '164.5': 36.7,
    '165.5': 36.6,
    '166.5': 36.5,
    '167.5': 36.5,
    '168.5': 36.4,
    '169.5': 36.3,
    '170.5': 36.2,
    '171.5': 36.1,
    '172.5': 36.1,
    '173.5': 36,
    '174.5': 35.9,
    '175.5': 35.8,
    '176.5': 35.8,
    '177.5': 35.7,
    '178.5': 35.6,
    '179.5': 35.5,
    '180.5': 35.4,
    '181.5': 35.4,
    '182.5': 35.3,
    '183.5': 35.2,
    '184.5': 35.2,
    '185.5': 35.1,
    '186.5': 35,
    '187.5': 34.9,
    '188.5': 34.9,
    '189.5': 34.8,
    '190.5': 34.7,
    '191.5': 34.7,
    '192.5': 34.6,
    '193.5': 34.5,
    '194.5': 34.4,
  },
  '46': {
    '0': 51.9,
    '1': 51.8,
    '2': 51.7,
    '3': 51.6,
    '4': 51.5,
    '5': 51.4,
    '6': 51.3,
    '7': 51.2,
    '8': 51.1,
    '9': 51,
    '10': 50.9,
    '11': 50.8,
    '12': 50.7,
    '13': 50.6,
    '14': 50.5,
    '15': 50.4,
    '16': 50.3,
    '17': 50.2,
    '18': 50.1,
    '19': 50,
    '20': 49.9,
    '21': 49.8,
    '22': 49.7,
    '23': 49.6,
    '24': 49.5,
    '25': 49.4,
    '26': 49.3,
    '27': 49.2,
    '28': 49.1,
    '29': 49,
    '30': 48.9,
    '31': 48.8,
    '32': 48.7,
    '33': 48.6,
    '34': 48.5,
    '35': 48.4,
    '36': 48.3,
    '37': 48.2,
    '38': 48.1,
    '39': 48,
    '40': 47.9,
    '41': 47.8,
    '42': 47.7,
    '43': 47.6,
    '44': 47.5,
    '45': 47.4,
    '46': 47.3,
    '47': 47.2,
    '48': 47.1,
    '49': 47,
    '50': 46.9,
    '51': 46.8,
    '52': 46.8,
    '53': 46.7,
    '54': 46.6,
    '55': 46.5,
    '56': 46.4,
    '57': 46.3,
    '58': 46.2,
    '59': 46.1,
    '60': 46,
    '61': 45.9,
    '62': 45.8,
    '63': 45.7,
    '64': 45.6,
    '65': 45.5,
    '66': 45.4,
    '67': 45.4,
    '68': 45.3,
    '69': 45.2,
    '70': 45.1,
    '71': 45,
    '72': 44.9,
    '73': 44.8,
    '74': 44.7,
    '75': 44.6,
    '76': 44.5,
    '77': 44.4,
    '78': 44.3,
    '79': 44.3,
    '80': 44.2,
    '81': 44.1,
    '82': 44,
    '83': 43.9,
    '84': 43.8,
    '85': 43.7,
    '86': 43.6,
    '87': 43.5,
    '88': 43.5,
    '89': 43.4,
    '90': 43.3,
    '91': 43.2,
    '92': 43.1,
    '93': 43,
    '94': 42.9,
    '95': 42.8,
    '96': 42.7,
    '97': 42.7,
    '98': 42.6,
    '99': 42.5,
    '100': 42.4,
    '101': 42.3,
    '102': 42.2,
    '103': 42.1,
    '104': 42.1,
    '105': 42,
    '106': 41.9,
    '107': 41.8,
    '108': 41.7,
    '109': 41.6,
    '110': 41.5,
    '111': 41.5,
    '112': 41.4,
    '113': 41.3,
    '114': 41.2,
    '115': 41.1,
    '116': 41,
    '117': 41,
    '118': 40.9,
    '119': 40.8,
    '120': 40.7,
    '121': 40.6,
    '122': 40.5,
    '123': 40.4,
    '124': 40.4,
    '125': 40.3,
    '126': 40.2,
    '127': 40.1,
    '128': 40,
    '129': 40,
    '130': 39.9,
    '131': 39.8,
    '132': 39.7,
    '133': 39.6,
    '134': 39.5,
    '135': 39.5,
    '136': 39.4,
    '137': 39.3,
    '138': 39.2,
    '139': 39.1,
    '140': 39.1,
    '141': 39,
    '142': 38.9,
    '143': 38.8,
    '144': 38.7,
    '145': 38.6,
    '146': 38.6,
    '147': 38.5,
    '148': 38.4,
    '149': 38.3,
    '150': 38.3,
    '151': 38.2,
    '152': 38.1,
    '153': 38,
    '154': 37.9,
    '155': 37.9,
    '156': 37.8,
    '157': 37.7,
    '158': 37.6,
    '159': 37.5,
    '160': 37.5,
    '161': 37.4,
    '162': 37.3,
    '163': 37.2,
    '164': 37.2,
    '165': 37.1,
    '166': 37,
    '167': 36.9,
    '168': 36.8,
    '169': 36.8,
    '170': 36.7,
    '171': 36.6,
    '172': 36.5,
    '173': 36.5,
    '174': 36.4,
    '175': 36.3,
    '176': 36.2,
    '177': 36.2,
    '178': 36.1,
    '179': 36,
    '180': 35.9,
    '181': 35.8,
    '182': 35.8,
    '183': 35.7,
    '184': 35.6,
    '185': 35.5,
    '186': 35.5,
    '187': 35.4,
    '188': 35.3,
    '189': 35.3,
    '190': 35.2,
    '191': 35.1,
    '192': 35,
    '193': 35,
    '194': 34.9,
    '195': 34.8,
    '0.5': 51.9,
    '1.5': 51.8,
    '2.5': 51.6,
    '3.5': 51.5,
    '4.5': 51.4,
    '5.5': 51.3,
    '6.5': 51.2,
    '7.5': 51.1,
    '8.5': 51,
    '9.5': 50.9,
    '10.5': 50.8,
    '11.5': 50.7,
    '12.5': 50.6,
    '13.5': 50.5,
    '14.5': 50.4,
    '15.5': 50.3,
    '16.5': 50.2,
    '17.5': 50.1,
    '18.5': 50,
    '19.5': 49.9,
    '20.5': 49.8,
    '21.5': 49.7,
    '22.5': 49.6,
    '23.5': 49.5,
    '24.5': 49.4,
    '25.5': 49.3,
    '26.5': 49.2,
    '27.5': 49.1,
    '28.5': 49,
    '29.5': 48.9,
    '30.5': 48.8,
    '31.5': 48.7,
    '32.5': 48.6,
    '33.5': 48.5,
    '34.5': 48.4,
    '35.5': 48.3,
    '36.5': 48.2,
    '37.5': 48.1,
    '38.5': 48,
    '39.5': 47.9,
    '40.5': 47.8,
    '41.5': 47.8,
    '42.5': 47.7,
    '43.5': 47.6,
    '44.5': 47.5,
    '45.5': 47.4,
    '46.5': 47.3,
    '47.5': 47.2,
    '48.5': 47.1,
    '49.5': 47,
    '50.5': 46.9,
    '51.5': 46.8,
    '52.5': 46.7,
    '53.5': 46.6,
    '54.5': 46.5,
    '55.5': 46.4,
    '56.5': 46.3,
    '57.5': 46.2,
    '58.5': 46.1,
    '59.5': 46,
    '60.5': 46,
    '61.5': 45.9,
    '62.5': 45.8,
    '63.5': 45.7,
    '64.5': 45.6,
    '65.5': 45.5,
    '66.5': 45.4,
    '67.5': 45.3,
    '68.5': 45.2,
    '69.5': 45.1,
    '70.5': 45,
    '71.5': 44.9,
    '72.5': 44.8,
    '73.5': 44.8,
    '74.5': 44.7,
    '75.5': 44.6,
    '76.5': 44.5,
    '77.5': 44.4,
    '78.5': 44.3,
    '79.5': 44.2,
    '80.5': 44.1,
    '81.5': 44,
    '82.5': 43.9,
    '83.5': 43.9,
    '84.5': 43.8,
    '85.5': 43.7,
    '86.5': 43.6,
    '87.5': 43.5,
    '88.5': 43.4,
    '89.5': 43.3,
    '90.5': 43.2,
    '91.5': 43.1,
    '92.5': 43.1,
    '93.5': 43,
    '94.5': 42.9,
    '95.5': 42.8,
    '96.5': 42.7,
    '97.5': 42.6,
    '98.5': 42.5,
    '99.5': 42.4,
    '100.5': 42.4,
    '101.5': 42.3,
    '102.5': 42.2,
    '103.5': 42.1,
    '104.5': 42,
    '105.5': 41.9,
    '106.5': 41.8,
    '107.5': 41.8,
    '108.5': 41.7,
    '109.5': 41.6,
    '110.5': 41.5,
    '111.5': 41.4,
    '112.5': 41.3,
    '113.5': 41.2,
    '114.5': 41.2,
    '115.5': 41.1,
    '116.5': 41,
    '117.5': 40.9,
    '118.5': 40.8,
    '119.5': 40.7,
    '120.5': 40.7,
    '121.5': 40.6,
    '122.5': 40.5,
    '123.5': 40.4,
    '124.5': 40.3,
    '125.5': 40.2,
    '126.5': 40.2,
    '127.5': 40.1,
    '128.5': 40,
    '129.5': 39.9,
    '130.5': 39.8,
    '131.5': 39.7,
    '132.5': 39.7,
    '133.5': 39.6,
    '134.5': 39.5,
    '135.5': 39.4,
    '136.5': 39.3,
    '137.5': 39.3,
    '138.5': 39.2,
    '139.5': 39.1,
    '140.5': 39,
    '141.5': 38.9,
    '142.5': 38.9,
    '143.5': 38.8,
    '144.5': 38.7,
    '145.5': 38.6,
    '146.5': 38.5,
    '147.5': 38.5,
    '148.5': 38.4,
    '149.5': 38.3,
    '150.5': 38.2,
    '151.5': 38.1,
    '152.5': 38.1,
    '153.5': 38,
    '154.5': 37.9,
    '155.5': 37.8,
    '156.5': 37.7,
    '157.5': 37.7,
    '158.5': 37.6,
    '159.5': 37.5,
    '160.5': 37.4,
    '161.5': 37.3,
    '162.5': 37.3,
    '163.5': 37.2,
    '164.5': 37.1,
    '165.5': 37,
    '166.5': 37,
    '167.5': 36.9,
    '168.5': 36.8,
    '169.5': 36.7,
    '170.5': 36.6,
    '171.5': 36.6,
    '172.5': 36.5,
    '173.5': 36.4,
    '174.5': 36.3,
    '175.5': 36.3,
    '176.5': 36.2,
    '177.5': 36.1,
    '178.5': 36,
    '179.5': 36,
    '180.5': 35.9,
    '181.5': 35.8,
    '182.5': 35.7,
    '183.5': 35.7,
    '184.5': 35.6,
    '185.5': 35.5,
    '186.5': 35.4,
    '187.5': 35.4,
    '188.5': 35.3,
    '189.5': 35.2,
    '190.5': 35.1,
    '191.5': 35.1,
    '192.5': 35,
    '193.5': 34.9,
    '194.5': 34.8,
  },
  '46.5': {
    '0': 52.5,
    '1': 52.4,
    '2': 52.3,
    '3': 52.1,
    '4': 52,
    '5': 51.9,
    '6': 51.8,
    '7': 51.7,
    '8': 51.6,
    '9': 51.5,
    '10': 51.4,
    '11': 51.3,
    '12': 51.2,
    '13': 51.1,
    '14': 51,
    '15': 50.9,
    '16': 50.8,
    '17': 50.7,
    '18': 50.6,
    '19': 50.5,
    '20': 50.4,
    '21': 50.3,
    '22': 50.2,
    '23': 50.1,
    '24': 50,
    '25': 49.9,
    '26': 49.8,
    '27': 49.7,
    '28': 49.6,
    '29': 49.5,
    '30': 49.4,
    '31': 49.3,
    '32': 49.2,
    '33': 49.1,
    '34': 49,
    '35': 48.9,
    '36': 48.8,
    '37': 48.7,
    '38': 48.6,
    '39': 48.5,
    '40': 48.4,
    '41': 48.3,
    '42': 48.2,
    '43': 48.1,
    '44': 48,
    '45': 47.9,
    '46': 47.8,
    '47': 47.7,
    '48': 47.6,
    '49': 47.5,
    '50': 47.4,
    '51': 47.4,
    '52': 47.3,
    '53': 47.2,
    '54': 47.1,
    '55': 47,
    '56': 46.9,
    '57': 46.8,
    '58': 46.7,
    '59': 46.6,
    '60': 46.5,
    '61': 46.4,
    '62': 46.3,
    '63': 46.2,
    '64': 46.1,
    '65': 46,
    '66': 45.9,
    '67': 45.8,
    '68': 45.8,
    '69': 45.7,
    '70': 45.6,
    '71': 45.5,
    '72': 45.4,
    '73': 45.3,
    '74': 45.2,
    '75': 45.1,
    '76': 45,
    '77': 44.9,
    '78': 44.8,
    '79': 44.7,
    '80': 44.7,
    '81': 44.6,
    '82': 44.5,
    '83': 44.4,
    '84': 44.3,
    '85': 44.2,
    '86': 44.1,
    '87': 44,
    '88': 43.9,
    '89': 43.8,
    '90': 43.8,
    '91': 43.7,
    '92': 43.6,
    '93': 43.5,
    '94': 43.4,
    '95': 43.3,
    '96': 43.2,
    '97': 43.1,
    '98': 43,
    '99': 43,
    '100': 42.9,
    '101': 42.8,
    '102': 42.7,
    '103': 42.6,
    '104': 42.5,
    '105': 42.4,
    '106': 42.4,
    '107': 42.3,
    '108': 42.2,
    '109': 42.1,
    '110': 42,
    '111': 41.9,
    '112': 41.8,
    '113': 41.8,
    '114': 41.7,
    '115': 41.6,
    '116': 41.5,
    '117': 41.4,
    '118': 41.3,
    '119': 41.2,
    '120': 41.2,
    '121': 41.1,
    '122': 41,
    '123': 40.9,
    '124': 40.8,
    '125': 40.7,
    '126': 40.7,
    '127': 40.6,
    '128': 40.5,
    '129': 40.4,
    '130': 40.3,
    '131': 40.2,
    '132': 40.2,
    '133': 40.1,
    '134': 40,
    '135': 39.9,
    '136': 39.8,
    '137': 39.7,
    '138': 39.7,
    '139': 39.6,
    '140': 39.5,
    '141': 39.4,
    '142': 39.3,
    '143': 39.3,
    '144': 39.2,
    '145': 39.1,
    '146': 39,
    '147': 38.9,
    '148': 38.8,
    '149': 38.8,
    '150': 38.7,
    '151': 38.6,
    '152': 38.5,
    '153': 38.4,
    '154': 38.4,
    '155': 38.3,
    '156': 38.2,
    '157': 38.1,
    '158': 38.1,
    '159': 38,
    '160': 37.9,
    '161': 37.8,
    '162': 37.7,
    '163': 37.7,
    '164': 37.6,
    '165': 37.5,
    '166': 37.4,
    '167': 37.3,
    '168': 37.3,
    '169': 37.2,
    '170': 37.1,
    '171': 37,
    '172': 37,
    '173': 36.9,
    '174': 36.8,
    '175': 36.7,
    '176': 36.6,
    '177': 36.6,
    '178': 36.5,
    '179': 36.4,
    '180': 36.3,
    '181': 36.3,
    '182': 36.2,
    '183': 36.1,
    '184': 36,
    '185': 36,
    '186': 35.9,
    '187': 35.8,
    '188': 35.7,
    '189': 35.7,
    '190': 35.6,
    '191': 35.5,
    '192': 35.4,
    '193': 35.4,
    '194': 35.3,
    '195': 35.2,
    '0.5': 52.4,
    '1.5': 52.3,
    '2.5': 52.2,
    '3.5': 52.1,
    '4.5': 52,
    '5.5': 51.9,
    '6.5': 51.8,
    '7.5': 51.7,
    '8.5': 51.6,
    '9.5': 51.5,
    '10.5': 51.4,
    '11.5': 51.3,
    '12.5': 51.2,
    '13.5': 51.1,
    '14.5': 51,
    '15.5': 50.9,
    '16.5': 50.8,
    '17.5': 50.7,
    '18.5': 50.6,
    '19.5': 50.5,
    '20.5': 50.4,
    '21.5': 50.3,
    '22.5': 50.2,
    '23.5': 50,
    '24.5': 49.9,
    '25.5': 49.8,
    '26.5': 49.7,
    '27.5': 49.6,
    '28.5': 49.5,
    '29.5': 49.4,
    '30.5': 49.3,
    '31.5': 49.2,
    '32.5': 49.1,
    '33.5': 49.1,
    '34.5': 49,
    '35.5': 48.9,
    '36.5': 48.8,
    '37.5': 48.7,
    '38.5': 48.6,
    '39.5': 48.5,
    '40.5': 48.4,
    '41.5': 48.3,
    '42.5': 48.2,
    '43.5': 48.1,
    '44.5': 48,
    '45.5': 47.9,
    '46.5': 47.8,
    '47.5': 47.7,
    '48.5': 47.6,
    '49.5': 47.5,
    '50.5': 47.4,
    '51.5': 47.3,
    '52.5': 47.2,
    '53.5': 47.1,
    '54.5': 47,
    '55.5': 46.9,
    '56.5': 46.8,
    '57.5': 46.7,
    '58.5': 46.6,
    '59.5': 46.5,
    '60.5': 46.5,
    '61.5': 46.4,
    '62.5': 46.3,
    '63.5': 46.2,
    '64.5': 46.1,
    '65.5': 46,
    '66.5': 45.9,
    '67.5': 45.8,
    '68.5': 45.7,
    '69.5': 45.6,
    '70.5': 45.5,
    '71.5': 45.4,
    '72.5': 45.3,
    '73.5': 45.2,
    '74.5': 45.2,
    '75.5': 45.1,
    '76.5': 45,
    '77.5': 44.9,
    '78.5': 44.8,
    '79.5': 44.7,
    '80.5': 44.6,
    '81.5': 44.5,
    '82.5': 44.4,
    '83.5': 44.3,
    '84.5': 44.2,
    '85.5': 44.2,
    '86.5': 44.1,
    '87.5': 44,
    '88.5': 43.9,
    '89.5': 43.8,
    '90.5': 43.7,
    '91.5': 43.6,
    '92.5': 43.5,
    '93.5': 43.4,
    '94.5': 43.4,
    '95.5': 43.3,
    '96.5': 43.2,
    '97.5': 43.1,
    '98.5': 43,
    '99.5': 42.9,
    '100.5': 42.8,
    '101.5': 42.7,
    '102.5': 42.7,
    '103.5': 42.6,
    '104.5': 42.5,
    '105.5': 42.4,
    '106.5': 42.3,
    '107.5': 42.2,
    '108.5': 42.1,
    '109.5': 42,
    '110.5': 42,
    '111.5': 41.9,
    '112.5': 41.8,
    '113.5': 41.7,
    '114.5': 41.6,
    '115.5': 41.5,
    '116.5': 41.5,
    '117.5': 41.4,
    '118.5': 41.3,
    '119.5': 41.2,
    '120.5': 41.1,
    '121.5': 41,
    '122.5': 40.9,
    '123.5': 40.9,
    '124.5': 40.8,
    '125.5': 40.7,
    '126.5': 40.6,
    '127.5': 40.5,
    '128.5': 40.4,
    '129.5': 40.4,
    '130.5': 40.3,
    '131.5': 40.2,
    '132.5': 40.1,
    '133.5': 40,
    '134.5': 39.9,
    '135.5': 39.9,
    '136.5': 39.8,
    '137.5': 39.7,
    '138.5': 39.6,
    '139.5': 39.5,
    '140.5': 39.5,
    '141.5': 39.4,
    '142.5': 39.3,
    '143.5': 39.2,
    '144.5': 39.1,
    '145.5': 39,
    '146.5': 39,
    '147.5': 38.9,
    '148.5': 38.8,
    '149.5': 38.7,
    '150.5': 38.6,
    '151.5': 38.6,
    '152.5': 38.5,
    '153.5': 38.4,
    '154.5': 38.3,
    '155.5': 38.2,
    '156.5': 38.2,
    '157.5': 38.1,
    '158.5': 38,
    '159.5': 37.9,
    '160.5': 37.9,
    '161.5': 37.8,
    '162.5': 37.7,
    '163.5': 37.6,
    '164.5': 37.5,
    '165.5': 37.5,
    '166.5': 37.4,
    '167.5': 37.3,
    '168.5': 37.2,
    '169.5': 37.2,
    '170.5': 37.1,
    '171.5': 37,
    '172.5': 36.9,
    '173.5': 36.8,
    '174.5': 36.8,
    '175.5': 36.7,
    '176.5': 36.6,
    '177.5': 36.5,
    '178.5': 36.5,
    '179.5': 36.4,
    '180.5': 36.3,
    '181.5': 36.2,
    '182.5': 36.2,
    '183.5': 36.1,
    '184.5': 36,
    '185.5': 35.9,
    '186.5': 35.8,
    '187.5': 35.8,
    '188.5': 35.7,
    '189.5': 35.6,
    '190.5': 35.6,
    '191.5': 35.5,
    '192.5': 35.4,
    '193.5': 35.3,
    '194.5': 35.3,
  },
  '47': {
    '0': 53,
    '1': 52.9,
    '2': 52.8,
    '3': 52.7,
    '4': 52.6,
    '5': 52.5,
    '6': 52.4,
    '7': 52.3,
    '8': 52.2,
    '9': 52.1,
    '10': 52,
    '11': 51.9,
    '12': 51.8,
    '13': 51.7,
    '14': 51.6,
    '15': 51.4,
    '16': 51.3,
    '17': 51.2,
    '18': 51.1,
    '19': 51,
    '20': 50.9,
    '21': 50.8,
    '22': 50.7,
    '23': 50.6,
    '24': 50.5,
    '25': 50.4,
    '26': 50.3,
    '27': 50.2,
    '28': 50.1,
    '29': 50,
    '30': 49.9,
    '31': 49.8,
    '32': 49.7,
    '33': 49.6,
    '34': 49.5,
    '35': 49.4,
    '36': 49.3,
    '37': 49.2,
    '38': 49.1,
    '39': 49,
    '40': 48.9,
    '41': 48.8,
    '42': 48.7,
    '43': 48.6,
    '44': 48.5,
    '45': 48.4,
    '46': 48.3,
    '47': 48.2,
    '48': 48.2,
    '49': 48.1,
    '50': 48,
    '51': 47.9,
    '52': 47.8,
    '53': 47.7,
    '54': 47.6,
    '55': 47.5,
    '56': 47.4,
    '57': 47.3,
    '58': 47.2,
    '59': 47.1,
    '60': 47,
    '61': 46.9,
    '62': 46.8,
    '63': 46.7,
    '64': 46.6,
    '65': 46.5,
    '66': 46.4,
    '67': 46.3,
    '68': 46.2,
    '69': 46.2,
    '70': 46,
    '71': 46,
    '72': 45.9,
    '73': 45.8,
    '74': 45.7,
    '75': 45.6,
    '76': 45.5,
    '77': 45.4,
    '78': 45.3,
    '79': 45.2,
    '80': 45.1,
    '81': 45,
    '82': 45,
    '83': 44.9,
    '84': 44.8,
    '85': 44.7,
    '86': 44.6,
    '87': 44.5,
    '88': 44.4,
    '89': 44.3,
    '90': 44.2,
    '91': 44.1,
    '92': 44.1,
    '93': 44,
    '94': 43.9,
    '95': 43.8,
    '96': 43.7,
    '97': 43.6,
    '98': 43.5,
    '99': 43.4,
    '100': 43.3,
    '101': 43.3,
    '102': 43.2,
    '103': 43.1,
    '104': 43,
    '105': 42.9,
    '106': 42.8,
    '107': 42.7,
    '108': 42.6,
    '109': 42.6,
    '110': 42.5,
    '111': 42.4,
    '112': 42.3,
    '113': 42.2,
    '114': 42.1,
    '115': 42,
    '116': 42,
    '117': 41.9,
    '118': 41.8,
    '119': 41.7,
    '120': 41.6,
    '121': 41.5,
    '122': 41.4,
    '123': 41.4,
    '124': 41.3,
    '125': 41.2,
    '126': 41.1,
    '127': 41,
    '128': 40.9,
    '129': 40.9,
    '130': 40.8,
    '131': 40.7,
    '132': 40.6,
    '133': 40.5,
    '134': 40.4,
    '135': 40.4,
    '136': 40.3,
    '137': 40.2,
    '138': 40.1,
    '139': 40,
    '140': 39.9,
    '141': 39.9,
    '142': 39.8,
    '143': 39.7,
    '144': 39.6,
    '145': 39.5,
    '146': 39.4,
    '147': 39.4,
    '148': 39.3,
    '149': 39.2,
    '150': 39.1,
    '151': 39,
    '152': 39,
    '153': 38.9,
    '154': 38.8,
    '155': 38.7,
    '156': 38.6,
    '157': 38.6,
    '158': 38.5,
    '159': 38.4,
    '160': 38.3,
    '161': 38.2,
    '162': 38.2,
    '163': 38.1,
    '164': 38,
    '165': 37.9,
    '166': 37.9,
    '167': 37.8,
    '168': 37.7,
    '169': 37.6,
    '170': 37.5,
    '171': 37.5,
    '172': 37.4,
    '173': 37.3,
    '174': 37.2,
    '175': 37.1,
    '176': 37.1,
    '177': 37,
    '178': 36.9,
    '179': 36.8,
    '180': 36.8,
    '181': 36.7,
    '182': 36.6,
    '183': 36.5,
    '184': 36.5,
    '185': 36.4,
    '186': 36.3,
    '187': 36.2,
    '188': 36.2,
    '189': 36.1,
    '190': 36,
    '191': 35.9,
    '192': 35.9,
    '193': 35.8,
    '194': 35.7,
    '195': 35.6,
    '0.5': 53,
    '1.5': 52.9,
    '2.5': 52.8,
    '3.5': 52.6,
    '4.5': 52.5,
    '5.5': 52.4,
    '6.5': 52.3,
    '7.5': 52.2,
    '8.5': 52.1,
    '9.5': 52,
    '10.5': 51.9,
    '11.5': 51.8,
    '12.5': 51.7,
    '13.5': 51.6,
    '14.5': 51.5,
    '15.5': 51.4,
    '16.5': 51.3,
    '17.5': 51.2,
    '18.5': 51.1,
    '19.5': 51,
    '20.5': 50.9,
    '21.5': 50.8,
    '22.5': 50.7,
    '23.5': 50.6,
    '24.5': 50.5,
    '25.5': 50.4,
    '26.5': 50.3,
    '27.5': 50.2,
    '28.5': 50.1,
    '29.5': 50,
    '30.5': 49.9,
    '31.5': 49.8,
    '32.5': 49.7,
    '33.5': 49.6,
    '34.5': 49.5,
    '35.5': 49.4,
    '36.5': 49.3,
    '37.5': 49.2,
    '38.5': 49.1,
    '39.5': 49,
    '40.5': 48.9,
    '41.5': 48.8,
    '42.5': 48.7,
    '43.5': 48.6,
    '44.5': 48.5,
    '45.5': 48.4,
    '46.5': 48.3,
    '47.5': 48.2,
    '48.5': 48.1,
    '49.5': 48,
    '50.5': 47.9,
    '51.5': 47.8,
    '52.5': 47.7,
    '53.5': 47.6,
    '54.5': 47.5,
    '55.5': 47.4,
    '56.5': 47.3,
    '57.5': 47.2,
    '58.5': 47.1,
    '59.5': 47,
    '60.5': 47,
    '61.5': 46.9,
    '62.5': 46.8,
    '63.5': 46.7,
    '64.5': 46.6,
    '65.5': 46.5,
    '66.5': 46.4,
    '67.5': 46.3,
    '68.5': 46.2,
    '69.5': 46.1,
    '70.5': 46,
    '71.5': 45.9,
    '72.5': 45.8,
    '73.5': 45.7,
    '74.5': 45.6,
    '75.5': 45.6,
    '76.5': 45.5,
    '77.5': 45.4,
    '78.5': 45.3,
    '79.5': 45.2,
    '80.5': 45.1,
    '81.5': 45,
    '82.5': 44.9,
    '83.5': 44.8,
    '84.5': 44.7,
    '85.5': 44.6,
    '86.5': 44.5,
    '87.5': 44.5,
    '88.5': 44.4,
    '89.5': 44.3,
    '90.5': 44.2,
    '91.5': 44.1,
    '92.5': 44,
    '93.5': 43.9,
    '94.5': 43.8,
    '95.5': 43.7,
    '96.5': 43.7,
    '97.5': 43.6,
    '98.5': 43.5,
    '99.5': 43.4,
    '100.5': 43.3,
    '101.5': 43.2,
    '102.5': 43.1,
    '103.5': 43,
    '104.5': 42.9,
    '105.5': 42.9,
    '106.5': 42.8,
    '107.5': 42.7,
    '108.5': 42.6,
    '109.5': 42.5,
    '110.5': 42.4,
    '111.5': 42.3,
    '112.5': 42.3,
    '113.5': 42.2,
    '114.5': 42.1,
    '115.5': 42,
    '116.5': 41.9,
    '117.5': 41.8,
    '118.5': 41.7,
    '119.5': 41.7,
    '120.5': 41.6,
    '121.5': 41.5,
    '122.5': 41.4,
    '123.5': 41.3,
    '124.5': 41.2,
    '125.5': 41.1,
    '126.5': 41.1,
    '127.5': 41,
    '128.5': 40.9,
    '129.5': 40.8,
    '130.5': 40.7,
    '131.5': 40.6,
    '132.5': 40.6,
    '133.5': 40.5,
    '134.5': 40.4,
    '135.5': 40.3,
    '136.5': 40.2,
    '137.5': 40.1,
    '138.5': 40.1,
    '139.5': 40,
    '140.5': 39.9,
    '141.5': 39.8,
    '142.5': 39.7,
    '143.5': 39.7,
    '144.5': 39.6,
    '145.5': 39.5,
    '146.5': 39.4,
    '147.5': 39.3,
    '148.5': 39.2,
    '149.5': 39.2,
    '150.5': 39.1,
    '151.5': 39,
    '152.5': 38.9,
    '153.5': 38.8,
    '154.5': 38.8,
    '155.5': 38.7,
    '156.5': 38.6,
    '157.5': 38.5,
    '158.5': 38.4,
    '159.5': 38.4,
    '160.5': 38.3,
    '161.5': 38.2,
    '162.5': 38.1,
    '163.5': 38,
    '164.5': 38,
    '165.5': 37.9,
    '166.5': 37.8,
    '167.5': 37.7,
    '168.5': 37.7,
    '169.5': 37.6,
    '170.5': 37.5,
    '171.5': 37.4,
    '172.5': 37.3,
    '173.5': 37.3,
    '174.5': 37.2,
    '175.5': 37.1,
    '176.5': 37,
    '177.5': 37,
    '178.5': 36.9,
    '179.5': 36.8,
    '180.5': 36.7,
    '181.5': 36.6,
    '182.5': 36.6,
    '183.5': 36.5,
    '184.5': 36.4,
    '185.5': 36.3,
    '186.5': 36.3,
    '187.5': 36.2,
    '188.5': 36.1,
    '189.5': 36,
    '190.5': 36,
    '191.5': 35.9,
    '192.5': 35.8,
    '193.5': 35.7,
    '194.5': 35.7,
  },
  '47.5': {
    '0': 53.6,
    '1': 53.5,
    '2': 53.4,
    '3': 53.3,
    '4': 53.1,
    '5': 53,
    '6': 52.9,
    '7': 52.8,
    '8': 52.7,
    '9': 52.6,
    '10': 52.5,
    '11': 52.4,
    '12': 52.3,
    '13': 52.2,
    '14': 52.1,
    '15': 52,
    '16': 51.9,
    '17': 51.8,
    '18': 51.7,
    '19': 51.6,
    '20': 51.5,
    '21': 51.4,
    '22': 51.3,
    '23': 51.2,
    '24': 51.1,
    '25': 51,
    '26': 50.9,
    '27': 50.8,
    '28': 50.7,
    '29': 50.6,
    '30': 50.4,
    '31': 50.3,
    '32': 50.2,
    '33': 50.1,
    '34': 50,
    '35': 49.9,
    '36': 49.8,
    '37': 49.7,
    '38': 49.6,
    '39': 49.5,
    '40': 49.4,
    '41': 49.3,
    '42': 49.3,
    '43': 49.2,
    '44': 49.1,
    '45': 49,
    '46': 48.9,
    '47': 48.8,
    '48': 48.7,
    '49': 48.6,
    '50': 48.5,
    '51': 48.4,
    '52': 48.3,
    '53': 48.2,
    '54': 48.1,
    '55': 48,
    '56': 47.9,
    '57': 47.8,
    '58': 47.7,
    '59': 47.6,
    '60': 47.5,
    '61': 47.4,
    '62': 47.3,
    '63': 47.2,
    '64': 47.1,
    '65': 47,
    '66': 46.9,
    '67': 46.8,
    '68': 46.7,
    '69': 46.6,
    '70': 46.6,
    '71': 46.5,
    '72': 46.4,
    '73': 46.3,
    '74': 46.2,
    '75': 46.1,
    '76': 46,
    '77': 45.9,
    '78': 45.8,
    '79': 45.7,
    '80': 45.6,
    '81': 45.5,
    '82': 45.4,
    '83': 45.3,
    '84': 45.3,
    '85': 45.2,
    '86': 45.1,
    '87': 45,
    '88': 44.9,
    '89': 44.8,
    '90': 44.7,
    '91': 44.6,
    '92': 44.5,
    '93': 44.4,
    '94': 44.3,
    '95': 44.3,
    '96': 44.2,
    '97': 44.1,
    '98': 44,
    '99': 43.9,
    '100': 43.8,
    '101': 43.7,
    '102': 43.6,
    '103': 43.5,
    '104': 43.5,
    '105': 43.4,
    '106': 43.3,
    '107': 43.2,
    '108': 43.1,
    '109': 43,
    '110': 42.9,
    '111': 42.8,
    '112': 42.8,
    '113': 42.7,
    '114': 42.6,
    '115': 42.5,
    '116': 42.4,
    '117': 42.3,
    '118': 42.2,
    '119': 42.2,
    '120': 42.1,
    '121': 42,
    '122': 41.9,
    '123': 41.8,
    '124': 41.7,
    '125': 41.6,
    '126': 41.6,
    '127': 41.5,
    '128': 41.4,
    '129': 41.3,
    '130': 41.2,
    '131': 41.1,
    '132': 41,
    '133': 41,
    '134': 40.9,
    '135': 40.8,
    '136': 40.7,
    '137': 40.6,
    '138': 40.5,
    '139': 40.5,
    '140': 40.4,
    '141': 40.3,
    '142': 40.2,
    '143': 40.1,
    '144': 40.1,
    '145': 40,
    '146': 39.9,
    '147': 39.8,
    '148': 39.7,
    '149': 39.6,
    '150': 39.6,
    '151': 39.5,
    '152': 39.4,
    '153': 39.3,
    '154': 39.2,
    '155': 39.2,
    '156': 39.1,
    '157': 39,
    '158': 38.9,
    '159': 38.8,
    '160': 38.8,
    '161': 38.7,
    '162': 38.6,
    '163': 38.5,
    '164': 38.4,
    '165': 38.4,
    '166': 38.3,
    '167': 38.2,
    '168': 38.1,
    '169': 38,
    '170': 38,
    '171': 37.9,
    '172': 37.8,
    '173': 37.7,
    '174': 37.6,
    '175': 37.6,
    '176': 37.5,
    '177': 37.4,
    '178': 37.3,
    '179': 37.3,
    '180': 37.2,
    '181': 37.1,
    '182': 37,
    '183': 37,
    '184': 36.9,
    '185': 36.8,
    '186': 36.7,
    '187': 36.6,
    '188': 36.6,
    '189': 36.5,
    '190': 36.4,
    '191': 36.3,
    '192': 36.3,
    '193': 36.2,
    '194': 36.1,
    '195': 36,
    '0.5': 53.5,
    '1.5': 53.4,
    '2.5': 53.3,
    '3.5': 53.2,
    '4.5': 53.1,
    '5.5': 53,
    '6.5': 52.9,
    '7.5': 52.8,
    '8.5': 52.7,
    '9.5': 52.6,
    '10.5': 52.5,
    '11.5': 52.4,
    '12.5': 52.2,
    '13.5': 52.1,
    '14.5': 52,
    '15.5': 51.9,
    '16.5': 51.8,
    '17.5': 51.7,
    '18.5': 51.6,
    '19.5': 51.5,
    '20.5': 51.4,
    '21.5': 51.3,
    '22.5': 51.2,
    '23.5': 51.1,
    '24.5': 51,
    '25.5': 50.9,
    '26.5': 50.8,
    '27.5': 50.7,
    '28.5': 50.6,
    '29.5': 50.5,
    '30.5': 50.4,
    '31.5': 50.3,
    '32.5': 50.2,
    '33.5': 50.1,
    '34.5': 50,
    '35.5': 49.9,
    '36.5': 49.8,
    '37.5': 49.7,
    '38.5': 49.6,
    '39.5': 49.5,
    '40.5': 49.4,
    '41.5': 49.3,
    '42.5': 49.2,
    '43.5': 49.1,
    '44.5': 49,
    '45.5': 48.9,
    '46.5': 48.8,
    '47.5': 48.7,
    '48.5': 48.6,
    '49.5': 48.5,
    '50.5': 48.4,
    '51.5': 48.3,
    '52.5': 48.2,
    '53.5': 48.1,
    '54.5': 48,
    '55.5': 47.9,
    '56.5': 47.8,
    '57.5': 47.7,
    '58.5': 47.6,
    '59.5': 47.5,
    '60.5': 47.5,
    '61.5': 47.4,
    '62.5': 47.3,
    '63.5': 47.2,
    '64.5': 47.1,
    '65.5': 47,
    '66.5': 46.9,
    '67.5': 46.8,
    '68.5': 46.7,
    '69.5': 46.6,
    '70.5': 46.5,
    '71.5': 46.4,
    '72.5': 46.3,
    '73.5': 46.2,
    '74.5': 46.1,
    '75.5': 46,
    '76.5': 45.9,
    '77.5': 45.9,
    '78.5': 45.8,
    '79.5': 45.7,
    '80.5': 45.6,
    '81.5': 45.5,
    '82.5': 45.4,
    '83.5': 45.3,
    '84.5': 45.2,
    '85.5': 45.1,
    '86.5': 45,
    '87.5': 44.9,
    '88.5': 44.8,
    '89.5': 44.8,
    '90.5': 44.7,
    '91.5': 44.6,
    '92.5': 44.5,
    '93.5': 44.4,
    '94.5': 44.3,
    '95.5': 44.2,
    '96.5': 44.1,
    '97.5': 44,
    '98.5': 43.9,
    '99.5': 43.9,
    '100.5': 43.8,
    '101.5': 43.7,
    '102.5': 43.6,
    '103.5': 43.5,
    '104.5': 43.4,
    '105.5': 43.3,
    '106.5': 43.2,
    '107.5': 43.2,
    '108.5': 43.1,
    '109.5': 43,
    '110.5': 42.9,
    '111.5': 42.8,
    '112.5': 42.7,
    '113.5': 42.6,
    '114.5': 42.5,
    '115.5': 42.5,
    '116.5': 42.4,
    '117.5': 42.3,
    '118.5': 42.2,
    '119.5': 42.1,
    '120.5': 42,
    '121.5': 41.9,
    '122.5': 41.9,
    '123.5': 41.8,
    '124.5': 41.7,
    '125.5': 41.6,
    '126.5': 41.5,
    '127.5': 41.4,
    '128.5': 41.3,
    '129.5': 41.3,
    '130.5': 41.2,
    '131.5': 41.1,
    '132.5': 41,
    '133.5': 40.9,
    '134.5': 40.8,
    '135.5': 40.8,
    '136.5': 40.7,
    '137.5': 40.6,
    '138.5': 40.5,
    '139.5': 40.4,
    '140.5': 40.3,
    '141.5': 40.3,
    '142.5': 40.2,
    '143.5': 40.1,
    '144.5': 40,
    '145.5': 39.9,
    '146.5': 39.8,
    '147.5': 39.8,
    '148.5': 39.7,
    '149.5': 39.6,
    '150.5': 39.5,
    '151.5': 39.4,
    '152.5': 39.4,
    '153.5': 39.3,
    '154.5': 39.2,
    '155.5': 39.1,
    '156.5': 39,
    '157.5': 39,
    '158.5': 38.9,
    '159.5': 38.8,
    '160.5': 38.7,
    '161.5': 38.6,
    '162.5': 38.6,
    '163.5': 38.5,
    '164.5': 38.4,
    '165.5': 38.3,
    '166.5': 38.2,
    '167.5': 38.2,
    '168.5': 38.1,
    '169.5': 38,
    '170.5': 37.9,
    '171.5': 37.8,
    '172.5': 37.8,
    '173.5': 37.7,
    '174.5': 37.6,
    '175.5': 37.5,
    '176.5': 37.5,
    '177.5': 37.4,
    '178.5': 37.3,
    '179.5': 37.2,
    '180.5': 37.1,
    '181.5': 37.1,
    '182.5': 37,
    '183.5': 36.9,
    '184.5': 36.8,
    '185.5': 36.8,
    '186.5': 36.7,
    '187.5': 36.6,
    '188.5': 36.5,
    '189.5': 36.5,
    '190.5': 36.4,
    '191.5': 36.3,
    '192.5': 36.2,
    '193.5': 36.1,
    '194.5': 36.1,
  },
  '48': {
    '0': 54.1,
    '1': 54,
    '2': 53.9,
    '3': 53.8,
    '4': 53.7,
    '5': 53.6,
    '6': 53.5,
    '7': 53.4,
    '8': 53.3,
    '9': 53.2,
    '10': 53.1,
    '11': 52.9,
    '12': 52.8,
    '13': 52.7,
    '14': 52.6,
    '15': 52.5,
    '16': 52.4,
    '17': 52.3,
    '18': 52.2,
    '19': 52.1,
    '20': 52,
    '21': 51.9,
    '22': 51.8,
    '23': 51.7,
    '24': 51.6,
    '25': 51.5,
    '26': 51.4,
    '27': 51.3,
    '28': 51.2,
    '29': 51.1,
    '30': 51,
    '31': 50.9,
    '32': 50.8,
    '33': 50.7,
    '34': 50.6,
    '35': 50.5,
    '36': 50.4,
    '37': 50.3,
    '38': 50.2,
    '39': 50.1,
    '40': 50,
    '41': 49.9,
    '42': 49.8,
    '43': 49.7,
    '44': 49.6,
    '45': 49.5,
    '46': 49.4,
    '47': 49.3,
    '48': 49.2,
    '49': 49.1,
    '50': 49,
    '51': 48.9,
    '52': 48.8,
    '53': 48.7,
    '54': 48.6,
    '55': 48.5,
    '56': 48.4,
    '57': 48.3,
    '58': 48.2,
    '59': 48.1,
    '60': 48,
    '61': 47.9,
    '62': 47.8,
    '63': 47.7,
    '64': 47.6,
    '65': 47.5,
    '66': 47.4,
    '67': 47.3,
    '68': 47.2,
    '69': 47.1,
    '70': 47,
    '71': 46.9,
    '72': 46.9,
    '73': 46.8,
    '74': 46.7,
    '75': 46.6,
    '76': 46.5,
    '77': 46.4,
    '78': 46.3,
    '79': 46.2,
    '80': 46.1,
    '81': 46,
    '82': 45.9,
    '83': 45.8,
    '84': 45.7,
    '85': 45.6,
    '86': 45.6,
    '87': 45.5,
    '88': 45.4,
    '89': 45.3,
    '90': 45.2,
    '91': 45.1,
    '92': 45,
    '93': 44.9,
    '94': 44.8,
    '95': 44.7,
    '96': 44.6,
    '97': 44.6,
    '98': 44.5,
    '99': 44.4,
    '100': 44.3,
    '101': 44.2,
    '102': 44.1,
    '103': 44,
    '104': 43.9,
    '105': 43.8,
    '106': 43.7,
    '107': 43.7,
    '108': 43.6,
    '109': 43.5,
    '110': 43.4,
    '111': 43.3,
    '112': 43.2,
    '113': 43.1,
    '114': 43,
    '115': 43,
    '116': 42.9,
    '117': 42.8,
    '118': 42.7,
    '119': 42.6,
    '120': 42.5,
    '121': 42.4,
    '122': 42.3,
    '123': 42.3,
    '124': 42.2,
    '125': 42.1,
    '126': 42,
    '127': 41.9,
    '128': 41.8,
    '129': 41.8,
    '130': 41.7,
    '131': 41.6,
    '132': 41.5,
    '133': 41.4,
    '134': 41.3,
    '135': 41.2,
    '136': 41.2,
    '137': 41.1,
    '138': 41,
    '139': 40.9,
    '140': 40.8,
    '141': 40.7,
    '142': 40.7,
    '143': 40.6,
    '144': 40.5,
    '145': 40.4,
    '146': 40.3,
    '147': 40.2,
    '148': 40.2,
    '149': 40.1,
    '150': 40,
    '151': 39.9,
    '152': 39.8,
    '153': 39.8,
    '154': 39.7,
    '155': 39.6,
    '156': 39.5,
    '157': 39.4,
    '158': 39.3,
    '159': 39.3,
    '160': 39.2,
    '161': 39.1,
    '162': 39,
    '163': 38.9,
    '164': 38.9,
    '165': 38.8,
    '166': 38.7,
    '167': 38.6,
    '168': 38.5,
    '169': 38.5,
    '170': 38.4,
    '171': 38.3,
    '172': 38.2,
    '173': 38.1,
    '174': 38.1,
    '175': 38,
    '176': 37.9,
    '177': 37.8,
    '178': 37.8,
    '179': 37.7,
    '180': 37.6,
    '181': 37.5,
    '182': 37.4,
    '183': 37.4,
    '184': 37.3,
    '185': 37.2,
    '186': 37.1,
    '187': 37.1,
    '188': 37,
    '189': 36.9,
    '190': 36.8,
    '191': 36.7,
    '192': 36.7,
    '193': 36.6,
    '194': 36.5,
    '195': 36.4,
    '0.5': 54.1,
    '1.5': 54,
    '2.5': 53.9,
    '3.5': 53.7,
    '4.5': 53.6,
    '5.5': 53.5,
    '6.5': 53.4,
    '7.5': 53.3,
    '8.5': 53.2,
    '9.5': 53.1,
    '10.5': 53,
    '11.5': 52.9,
    '12.5': 52.8,
    '13.5': 52.7,
    '14.5': 52.6,
    '15.5': 52.5,
    '16.5': 52.4,
    '17.5': 52.3,
    '18.5': 52.2,
    '19.5': 52.1,
    '20.5': 52,
    '21.5': 51.8,
    '22.5': 51.7,
    '23.5': 51.6,
    '24.5': 51.5,
    '25.5': 51.4,
    '26.5': 51.3,
    '27.5': 51.2,
    '28.5': 51.1,
    '29.5': 51,
    '30.5': 50.9,
    '31.5': 50.8,
    '32.5': 50.7,
    '33.5': 50.6,
    '34.5': 50.5,
    '35.5': 50.4,
    '36.5': 50.3,
    '37.5': 50.2,
    '38.5': 50.1,
    '39.5': 50,
    '40.5': 49.9,
    '41.5': 49.8,
    '42.5': 49.7,
    '43.5': 49.6,
    '44.5': 49.5,
    '45.5': 49.4,
    '46.5': 49.3,
    '47.5': 49.2,
    '48.5': 49.1,
    '49.5': 49,
    '50.5': 48.9,
    '51.5': 48.8,
    '52.5': 48.7,
    '53.5': 48.6,
    '54.5': 48.5,
    '55.5': 48.4,
    '56.5': 48.3,
    '57.5': 48.2,
    '58.5': 48.1,
    '59.5': 48,
    '60.5': 48,
    '61.5': 47.9,
    '62.5': 47.8,
    '63.5': 47.7,
    '64.5': 47.6,
    '65.5': 47.5,
    '66.5': 47.4,
    '67.5': 47.3,
    '68.5': 47.2,
    '69.5': 47.1,
    '70.5': 47,
    '71.5': 46.9,
    '72.5': 46.8,
    '73.5': 46.7,
    '74.5': 46.6,
    '75.5': 46.5,
    '76.5': 46.4,
    '77.5': 46.3,
    '78.5': 46.2,
    '79.5': 46.2,
    '80.5': 46.1,
    '81.5': 46,
    '82.5': 45.9,
    '83.5': 45.8,
    '84.5': 45.7,
    '85.5': 45.6,
    '86.5': 45.5,
    '87.5': 45.4,
    '88.5': 45.3,
    '89.5': 45.2,
    '90.5': 45.1,
    '91.5': 45,
    '92.5': 45,
    '93.5': 44.9,
    '94.5': 44.8,
    '95.5': 44.7,
    '96.5': 44.6,
    '97.5': 44.5,
    '98.5': 44.4,
    '99.5': 44.3,
    '100.5': 44.2,
    '101.5': 44.1,
    '102.5': 44.1,
    '103.5': 44,
    '104.5': 43.9,
    '105.5': 43.8,
    '106.5': 43.7,
    '107.5': 43.6,
    '108.5': 43.5,
    '109.5': 43.4,
    '110.5': 43.3,
    '111.5': 43.3,
    '112.5': 43.2,
    '113.5': 43.1,
    '114.5': 43,
    '115.5': 42.9,
    '116.5': 42.8,
    '117.5': 42.7,
    '118.5': 42.7,
    '119.5': 42.6,
    '120.5': 42.5,
    '121.5': 42.4,
    '122.5': 42.3,
    '123.5': 42.2,
    '124.5': 42.1,
    '125.5': 42,
    '126.5': 42,
    '127.5': 41.9,
    '128.5': 41.8,
    '129.5': 41.7,
    '130.5': 41.6,
    '131.5': 41.5,
    '132.5': 41.5,
    '133.5': 41.4,
    '134.5': 41.3,
    '135.5': 41.2,
    '136.5': 41.1,
    '137.5': 41,
    '138.5': 40.9,
    '139.5': 40.9,
    '140.5': 40.8,
    '141.5': 40.7,
    '142.5': 40.6,
    '143.5': 40.5,
    '144.5': 40.5,
    '145.5': 40.4,
    '146.5': 40.3,
    '147.5': 40.2,
    '148.5': 40.1,
    '149.5': 40,
    '150.5': 40,
    '151.5': 39.9,
    '152.5': 39.8,
    '153.5': 39.7,
    '154.5': 39.6,
    '155.5': 39.5,
    '156.5': 39.5,
    '157.5': 39.4,
    '158.5': 39.3,
    '159.5': 39.2,
    '160.5': 39.1,
    '161.5': 39.1,
    '162.5': 39,
    '163.5': 38.9,
    '164.5': 38.8,
    '165.5': 38.7,
    '166.5': 38.7,
    '167.5': 38.6,
    '168.5': 38.5,
    '169.5': 38.4,
    '170.5': 38.3,
    '171.5': 38.3,
    '172.5': 38.2,
    '173.5': 38.1,
    '174.5': 38,
    '175.5': 38,
    '176.5': 37.9,
    '177.5': 37.8,
    '178.5': 37.7,
    '179.5': 37.6,
    '180.5': 37.6,
    '181.5': 37.5,
    '182.5': 37.4,
    '183.5': 37.3,
    '184.5': 37.3,
    '185.5': 37.2,
    '186.5': 37.1,
    '187.5': 37,
    '188.5': 36.9,
    '189.5': 36.9,
    '190.5': 36.8,
    '191.5': 36.7,
    '192.5': 36.6,
    '193.5': 36.6,
    '194.5': 36.5,
  },
  '48.5': {
    '0': 54.7,
    '1': 54.6,
    '2': 54.5,
    '3': 54.4,
    '4': 54.2,
    '5': 54.1,
    '6': 54,
    '7': 53.9,
    '8': 53.8,
    '9': 53.7,
    '10': 53.6,
    '11': 53.5,
    '12': 53.4,
    '13': 53.3,
    '14': 53.2,
    '15': 53.1,
    '16': 53,
    '17': 52.9,
    '18': 52.8,
    '19': 52.6,
    '20': 52.5,
    '21': 52.4,
    '22': 52.3,
    '23': 52.2,
    '24': 52.1,
    '25': 52,
    '26': 51.9,
    '27': 51.8,
    '28': 51.7,
    '29': 51.6,
    '30': 51.5,
    '31': 51.4,
    '32': 51.3,
    '33': 51.2,
    '34': 51.1,
    '35': 51,
    '36': 50.9,
    '37': 50.8,
    '38': 50.7,
    '39': 50.6,
    '40': 50.5,
    '41': 50.4,
    '42': 50.3,
    '43': 50.2,
    '44': 50.1,
    '45': 50,
    '46': 49.9,
    '47': 49.8,
    '48': 49.7,
    '49': 49.6,
    '50': 49.5,
    '51': 49.4,
    '52': 49.3,
    '53': 49.2,
    '54': 49.1,
    '55': 49,
    '56': 48.9,
    '57': 48.8,
    '58': 48.7,
    '59': 48.6,
    '60': 48.5,
    '61': 48.4,
    '62': 48.3,
    '63': 48.2,
    '64': 48.1,
    '65': 48,
    '66': 47.9,
    '67': 47.8,
    '68': 47.7,
    '69': 47.6,
    '70': 47.5,
    '71': 47.4,
    '72': 47.3,
    '73': 47.3,
    '74': 47.2,
    '75': 47.1,
    '76': 47,
    '77': 46.9,
    '78': 46.8,
    '79': 46.7,
    '80': 46.6,
    '81': 46.5,
    '82': 46.4,
    '83': 46.3,
    '84': 46.2,
    '85': 46.1,
    '86': 46,
    '87': 45.9,
    '88': 45.8,
    '89': 45.8,
    '90': 45.7,
    '91': 45.6,
    '92': 45.5,
    '93': 45.4,
    '94': 45.3,
    '95': 45.2,
    '96': 45.1,
    '97': 45,
    '98': 44.9,
    '99': 44.8,
    '100': 44.8,
    '101': 44.7,
    '102': 44.6,
    '103': 44.5,
    '104': 44.4,
    '105': 44.3,
    '106': 44.2,
    '107': 44.1,
    '108': 44,
    '109': 43.9,
    '110': 43.9,
    '111': 43.8,
    '112': 43.7,
    '113': 43.6,
    '114': 43.5,
    '115': 43.4,
    '116': 43.3,
    '117': 43.2,
    '118': 43.2,
    '119': 43.1,
    '120': 43,
    '121': 42.9,
    '122': 42.8,
    '123': 42.7,
    '124': 42.6,
    '125': 42.5,
    '126': 42.5,
    '127': 42.4,
    '128': 42.3,
    '129': 42.2,
    '130': 42.1,
    '131': 42,
    '132': 41.9,
    '133': 41.9,
    '134': 41.8,
    '135': 41.7,
    '136': 41.6,
    '137': 41.5,
    '138': 41.4,
    '139': 41.4,
    '140': 41.3,
    '141': 41.2,
    '142': 41.1,
    '143': 41,
    '144': 40.9,
    '145': 40.8,
    '146': 40.8,
    '147': 40.7,
    '148': 40.6,
    '149': 40.5,
    '150': 40.4,
    '151': 40.3,
    '152': 40.3,
    '153': 40.2,
    '154': 40.1,
    '155': 40,
    '156': 39.9,
    '157': 39.9,
    '158': 39.8,
    '159': 39.7,
    '160': 39.6,
    '161': 39.5,
    '162': 39.4,
    '163': 39.4,
    '164': 39.3,
    '165': 39.2,
    '166': 39.1,
    '167': 39,
    '168': 39,
    '169': 38.9,
    '170': 38.8,
    '171': 38.7,
    '172': 38.6,
    '173': 38.6,
    '174': 38.5,
    '175': 38.4,
    '176': 38.3,
    '177': 38.3,
    '178': 38.2,
    '179': 38.1,
    '180': 38,
    '181': 37.9,
    '182': 37.9,
    '183': 37.8,
    '184': 37.7,
    '185': 37.6,
    '186': 37.5,
    '187': 37.5,
    '188': 37.4,
    '189': 37.3,
    '190': 37.2,
    '191': 37.2,
    '192': 37.1,
    '193': 37,
    '194': 36.9,
    '195': 36.9,
    '0.5': 54.6,
    '1.5': 54.5,
    '2.5': 54.4,
    '3.5': 54.3,
    '4.5': 54.2,
    '5.5': 54.1,
    '6.5': 54,
    '7.5': 53.9,
    '8.5': 53.8,
    '9.5': 53.7,
    '10.5': 53.5,
    '11.5': 53.4,
    '12.5': 53.3,
    '13.5': 53.2,
    '14.5': 53.1,
    '15.5': 53,
    '16.5': 52.9,
    '17.5': 52.8,
    '18.5': 52.7,
    '19.5': 52.6,
    '20.5': 52.5,
    '21.5': 52.4,
    '22.5': 52.3,
    '23.5': 52.2,
    '24.5': 52.1,
    '25.5': 52,
    '26.5': 51.9,
    '27.5': 51.8,
    '28.5': 51.7,
    '29.5': 51.6,
    '30.5': 51.5,
    '31.5': 51.3,
    '32.5': 51.2,
    '33.5': 51.1,
    '34.5': 51,
    '35.5': 50.9,
    '36.5': 50.8,
    '37.5': 50.7,
    '38.5': 50.6,
    '39.5': 50.5,
    '40.5': 50.4,
    '41.5': 50.3,
    '42.5': 50.2,
    '43.5': 50.1,
    '44.5': 50,
    '45.5': 49.9,
    '46.5': 49.8,
    '47.5': 49.7,
    '48.5': 49.6,
    '49.5': 49.5,
    '50.5': 49.4,
    '51.5': 49.3,
    '52.5': 49.2,
    '53.5': 49.1,
    '54.5': 49,
    '55.5': 48.9,
    '56.5': 48.8,
    '57.5': 48.7,
    '58.5': 48.6,
    '59.5': 48.5,
    '60.5': 48.5,
    '61.5': 48.4,
    '62.5': 48.3,
    '63.5': 48.2,
    '64.5': 48.1,
    '65.5': 48,
    '66.5': 47.9,
    '67.5': 47.8,
    '68.5': 47.7,
    '69.5': 47.6,
    '70.5': 47.5,
    '71.5': 47.4,
    '72.5': 47.3,
    '73.5': 47.2,
    '74.5': 47.1,
    '75.5': 47,
    '76.5': 46.9,
    '77.5': 46.8,
    '78.5': 46.7,
    '79.5': 46.6,
    '80.5': 46.5,
    '81.5': 46.5,
    '82.5': 46.4,
    '83.5': 46.3,
    '84.5': 46.2,
    '85.5': 46.1,
    '86.5': 46,
    '87.5': 45.9,
    '88.5': 45.8,
    '89.5': 45.7,
    '90.5': 45.6,
    '91.5': 45.5,
    '92.5': 45.4,
    '93.5': 45.3,
    '94.5': 45.3,
    '95.5': 45.2,
    '96.5': 45.1,
    '97.5': 45,
    '98.5': 44.9,
    '99.5': 44.8,
    '100.5': 44.7,
    '101.5': 44.6,
    '102.5': 44.5,
    '103.5': 44.4,
    '104.5': 44.3,
    '105.5': 44.3,
    '106.5': 44.2,
    '107.5': 44.1,
    '108.5': 44,
    '109.5': 43.9,
    '110.5': 43.8,
    '111.5': 43.7,
    '112.5': 43.6,
    '113.5': 43.5,
    '114.5': 43.5,
    '115.5': 43.4,
    '116.5': 43.3,
    '117.5': 43.2,
    '118.5': 43.1,
    '119.5': 43,
    '120.5': 42.9,
    '121.5': 42.8,
    '122.5': 42.8,
    '123.5': 42.7,
    '124.5': 42.6,
    '125.5': 42.5,
    '126.5': 42.4,
    '127.5': 42.3,
    '128.5': 42.2,
    '129.5': 42.2,
    '130.5': 42.1,
    '131.5': 42,
    '132.5': 41.9,
    '133.5': 41.8,
    '134.5': 41.7,
    '135.5': 41.6,
    '136.5': 41.6,
    '137.5': 41.5,
    '138.5': 41.4,
    '139.5': 41.3,
    '140.5': 41.2,
    '141.5': 41.1,
    '142.5': 41.1,
    '143.5': 41,
    '144.5': 40.9,
    '145.5': 40.8,
    '146.5': 40.7,
    '147.5': 40.6,
    '148.5': 40.6,
    '149.5': 40.5,
    '150.5': 40.4,
    '151.5': 40.3,
    '152.5': 40.2,
    '153.5': 40.1,
    '154.5': 40.1,
    '155.5': 40,
    '156.5': 39.9,
    '157.5': 39.8,
    '158.5': 39.7,
    '159.5': 39.7,
    '160.5': 39.6,
    '161.5': 39.5,
    '162.5': 39.4,
    '163.5': 39.3,
    '164.5': 39.2,
    '165.5': 39.2,
    '166.5': 39.1,
    '167.5': 39,
    '168.5': 38.9,
    '169.5': 38.8,
    '170.5': 38.8,
    '171.5': 38.7,
    '172.5': 38.6,
    '173.5': 38.5,
    '174.5': 38.5,
    '175.5': 38.4,
    '176.5': 38.3,
    '177.5': 38.2,
    '178.5': 38.1,
    '179.5': 38.1,
    '180.5': 38,
    '181.5': 37.9,
    '182.5': 37.8,
    '183.5': 37.7,
    '184.5': 37.7,
    '185.5': 37.6,
    '186.5': 37.5,
    '187.5': 37.4,
    '188.5': 37.4,
    '189.5': 37.3,
    '190.5': 37.2,
    '191.5': 37.1,
    '192.5': 37,
    '193.5': 37,
    '194.5': 36.9,
  },
  '49': {
    '0': 55.2,
    '1': 55.1,
    '2': 55,
    '3': 54.9,
    '4': 54.8,
    '5': 54.7,
    '6': 54.6,
    '7': 54.5,
    '8': 54.4,
    '9': 54.3,
    '10': 54.1,
    '11': 54,
    '12': 53.9,
    '13': 53.8,
    '14': 53.7,
    '15': 53.6,
    '16': 53.5,
    '17': 53.4,
    '18': 53.3,
    '19': 53.2,
    '20': 53.1,
    '21': 53,
    '22': 52.9,
    '23': 52.8,
    '24': 52.7,
    '25': 52.5,
    '26': 52.4,
    '27': 52.3,
    '28': 52.2,
    '29': 52.1,
    '30': 52,
    '31': 51.9,
    '32': 51.8,
    '33': 51.7,
    '34': 51.6,
    '35': 51.5,
    '36': 51.4,
    '37': 51.3,
    '38': 51.2,
    '39': 51.1,
    '40': 51,
    '41': 50.9,
    '42': 50.8,
    '43': 50.7,
    '44': 50.6,
    '45': 50.5,
    '46': 50.4,
    '47': 50.3,
    '48': 50.2,
    '49': 50.1,
    '50': 50,
    '51': 49.9,
    '52': 49.8,
    '53': 49.7,
    '54': 49.6,
    '55': 49.5,
    '56': 49.4,
    '57': 49.3,
    '58': 49.2,
    '59': 49.1,
    '60': 49,
    '61': 48.9,
    '62': 48.8,
    '63': 48.7,
    '64': 48.6,
    '65': 48.5,
    '66': 48.4,
    '67': 48.3,
    '68': 48.2,
    '69': 48.1,
    '70': 48,
    '71': 47.9,
    '72': 47.8,
    '73': 47.7,
    '74': 47.6,
    '75': 47.5,
    '76': 47.5,
    '77': 47.4,
    '78': 47.3,
    '79': 47.2,
    '80': 47.1,
    '81': 47,
    '82': 46.9,
    '83': 46.8,
    '84': 46.7,
    '85': 46.6,
    '86': 46.5,
    '87': 46.4,
    '88': 46.3,
    '89': 46.2,
    '90': 46.1,
    '91': 46,
    '92': 46,
    '93': 45.9,
    '94': 45.8,
    '95': 45.7,
    '96': 45.6,
    '97': 45.5,
    '98': 45.4,
    '99': 45.3,
    '100': 45.2,
    '101': 45.1,
    '102': 45,
    '103': 44.9,
    '104': 44.9,
    '105': 44.8,
    '106': 44.7,
    '107': 44.6,
    '108': 44.5,
    '109': 44.4,
    '110': 44.3,
    '111': 44.2,
    '112': 44.1,
    '113': 44.1,
    '114': 44,
    '115': 43.9,
    '116': 43.8,
    '117': 43.7,
    '118': 43.6,
    '119': 43.5,
    '120': 43.4,
    '121': 43.3,
    '122': 43.3,
    '123': 43.2,
    '124': 43.1,
    '125': 43,
    '126': 42.9,
    '127': 42.8,
    '128': 42.7,
    '129': 42.6,
    '130': 42.6,
    '131': 42.5,
    '132': 42.4,
    '133': 42.3,
    '134': 42.2,
    '135': 42.1,
    '136': 42,
    '137': 42,
    '138': 41.9,
    '139': 41.8,
    '140': 41.7,
    '141': 41.6,
    '142': 41.5,
    '143': 41.5,
    '144': 41.4,
    '145': 41.3,
    '146': 41.2,
    '147': 41.1,
    '148': 41,
    '149': 40.9,
    '150': 40.9,
    '151': 40.8,
    '152': 40.7,
    '153': 40.6,
    '154': 40.5,
    '155': 40.5,
    '156': 40.4,
    '157': 40.3,
    '158': 40.2,
    '159': 40.1,
    '160': 40,
    '161': 40,
    '162': 39.9,
    '163': 39.8,
    '164': 39.7,
    '165': 39.6,
    '166': 39.6,
    '167': 39.5,
    '168': 39.4,
    '169': 39.3,
    '170': 39.2,
    '171': 39.1,
    '172': 39.1,
    '173': 39,
    '174': 38.9,
    '175': 38.8,
    '176': 38.7,
    '177': 38.7,
    '178': 38.6,
    '179': 38.5,
    '180': 38.4,
    '181': 38.4,
    '182': 38.3,
    '183': 38.2,
    '184': 38.1,
    '185': 38,
    '186': 38,
    '187': 37.9,
    '188': 37.8,
    '189': 37.7,
    '190': 37.6,
    '191': 37.6,
    '192': 37.5,
    '193': 37.4,
    '194': 37.3,
    '195': 37.3,
    '0.5': 55.2,
    '1.5': 55.1,
    '2.5': 55,
    '3.5': 54.8,
    '4.5': 54.7,
    '5.5': 54.6,
    '6.5': 54.5,
    '7.5': 54.4,
    '8.5': 54.3,
    '9.5': 54.2,
    '10.5': 54.1,
    '11.5': 54,
    '12.5': 53.9,
    '13.5': 53.8,
    '14.5': 53.7,
    '15.5': 53.6,
    '16.5': 53.4,
    '17.5': 53.3,
    '18.5': 53.2,
    '19.5': 53.1,
    '20.5': 53,
    '21.5': 52.9,
    '22.5': 52.8,
    '23.5': 52.7,
    '24.5': 52.6,
    '25.5': 52.5,
    '26.5': 52.4,
    '27.5': 52.3,
    '28.5': 52.2,
    '29.5': 52.1,
    '30.5': 52,
    '31.5': 51.9,
    '32.5': 51.8,
    '33.5': 51.7,
    '34.5': 51.6,
    '35.5': 51.5,
    '36.5': 51.4,
    '37.5': 51.3,
    '38.5': 51.2,
    '39.5': 51.1,
    '40.5': 50.9,
    '41.5': 50.8,
    '42.5': 50.7,
    '43.5': 50.6,
    '44.5': 50.5,
    '45.5': 50.4,
    '46.5': 50.3,
    '47.5': 50.2,
    '48.5': 50.1,
    '49.5': 50,
    '50.5': 49.9,
    '51.5': 49.8,
    '52.5': 49.7,
    '53.5': 49.6,
    '54.5': 49.5,
    '55.5': 49.4,
    '56.5': 49.3,
    '57.5': 49.2,
    '58.5': 49.1,
    '59.5': 49,
    '60.5': 48.9,
    '61.5': 48.9,
    '62.5': 48.8,
    '63.5': 48.7,
    '64.5': 48.6,
    '65.5': 48.5,
    '66.5': 48.4,
    '67.5': 48.3,
    '68.5': 48.2,
    '69.5': 48.1,
    '70.5': 48,
    '71.5': 47.9,
    '72.5': 47.8,
    '73.5': 47.7,
    '74.5': 47.6,
    '75.5': 47.5,
    '76.5': 47.4,
    '77.5': 47.3,
    '78.5': 47.2,
    '79.5': 47.1,
    '80.5': 47,
    '81.5': 46.9,
    '82.5': 46.8,
    '83.5': 46.7,
    '84.5': 46.7,
    '85.5': 46.6,
    '86.5': 46.5,
    '87.5': 46.4,
    '88.5': 46.3,
    '89.5': 46.2,
    '90.5': 46.1,
    '91.5': 46,
    '92.5': 45.9,
    '93.5': 45.8,
    '94.5': 45.7,
    '95.5': 45.6,
    '96.5': 45.5,
    '97.5': 45.4,
    '98.5': 45.4,
    '99.5': 45.3,
    '100.5': 45.2,
    '101.5': 45.1,
    '102.5': 45,
    '103.5': 44.9,
    '104.5': 44.8,
    '105.5': 44.7,
    '106.5': 44.6,
    '107.5': 44.5,
    '108.5': 44.5,
    '109.5': 44.4,
    '110.5': 44.3,
    '111.5': 44.2,
    '112.5': 44.1,
    '113.5': 44,
    '114.5': 43.9,
    '115.5': 43.8,
    '116.5': 43.7,
    '117.5': 43.7,
    '118.5': 43.6,
    '119.5': 43.5,
    '120.5': 43.4,
    '121.5': 43.3,
    '122.5': 43.2,
    '123.5': 43.1,
    '124.5': 43,
    '125.5': 43,
    '126.5': 42.9,
    '127.5': 42.8,
    '128.5': 42.7,
    '129.5': 42.6,
    '130.5': 42.5,
    '131.5': 42.4,
    '132.5': 42.3,
    '133.5': 42.3,
    '134.5': 42.2,
    '135.5': 42.1,
    '136.5': 42,
    '137.5': 41.9,
    '138.5': 41.8,
    '139.5': 41.8,
    '140.5': 41.7,
    '141.5': 41.6,
    '142.5': 41.5,
    '143.5': 41.4,
    '144.5': 41.3,
    '145.5': 41.2,
    '146.5': 41.2,
    '147.5': 41.1,
    '148.5': 41,
    '149.5': 40.9,
    '150.5': 40.8,
    '151.5': 40.7,
    '152.5': 40.7,
    '153.5': 40.6,
    '154.5': 40.5,
    '155.5': 40.4,
    '156.5': 40.3,
    '157.5': 40.2,
    '158.5': 40.2,
    '159.5': 40.1,
    '160.5': 40,
    '161.5': 39.9,
    '162.5': 39.8,
    '163.5': 39.8,
    '164.5': 39.7,
    '165.5': 39.6,
    '166.5': 39.5,
    '167.5': 39.4,
    '168.5': 39.3,
    '169.5': 39.3,
    '170.5': 39.2,
    '171.5': 39.1,
    '172.5': 39,
    '173.5': 39,
    '174.5': 38.9,
    '175.5': 38.8,
    '176.5': 38.7,
    '177.5': 38.6,
    '178.5': 38.6,
    '179.5': 38.5,
    '180.5': 38.4,
    '181.5': 38.3,
    '182.5': 38.2,
    '183.5': 38.2,
    '184.5': 38.1,
    '185.5': 38,
    '186.5': 37.9,
    '187.5': 37.8,
    '188.5': 37.8,
    '189.5': 37.7,
    '190.5': 37.6,
    '191.5': 37.5,
    '192.5': 37.5,
    '193.5': 37.4,
    '194.5': 37.3,
  },
  '49.5': {
    '0': 55.8,
    '1': 55.7,
    '2': 55.6,
    '3': 55.5,
    '4': 55.3,
    '5': 55.2,
    '6': 55.1,
    '7': 55,
    '8': 54.9,
    '9': 54.8,
    '10': 54.7,
    '11': 54.6,
    '12': 54.5,
    '13': 54.4,
    '14': 54.3,
    '15': 54.1,
    '16': 54,
    '17': 53.9,
    '18': 53.8,
    '19': 53.7,
    '20': 53.6,
    '21': 53.5,
    '22': 53.4,
    '23': 53.3,
    '24': 53.2,
    '25': 53.1,
    '26': 53,
    '27': 52.9,
    '28': 52.8,
    '29': 52.7,
    '30': 52.6,
    '31': 52.4,
    '32': 52.3,
    '33': 52.2,
    '34': 52.1,
    '35': 52,
    '36': 51.9,
    '37': 51.8,
    '38': 51.7,
    '39': 51.6,
    '40': 51.5,
    '41': 51.4,
    '42': 51.3,
    '43': 51.2,
    '44': 51.1,
    '45': 51,
    '46': 50.9,
    '47': 50.8,
    '48': 50.7,
    '49': 50.6,
    '50': 50.5,
    '51': 50.4,
    '52': 50.3,
    '53': 50.2,
    '54': 50.1,
    '55': 50,
    '56': 49.9,
    '57': 49.8,
    '58': 49.7,
    '59': 49.6,
    '60': 49.5,
    '61': 49.4,
    '62': 49.3,
    '63': 49.2,
    '64': 49.1,
    '65': 49,
    '66': 48.9,
    '67': 48.8,
    '68': 48.7,
    '69': 48.6,
    '70': 48.5,
    '71': 48.4,
    '72': 48.3,
    '73': 48.2,
    '74': 48.1,
    '75': 48,
    '76': 47.9,
    '77': 47.8,
    '78': 47.7,
    '79': 47.7,
    '80': 47.6,
    '81': 47.5,
    '82': 47.4,
    '83': 47.3,
    '84': 47.2,
    '85': 47.1,
    '86': 47,
    '87': 46.9,
    '88': 46.8,
    '89': 46.7,
    '90': 46.6,
    '91': 46.5,
    '92': 46.4,
    '93': 46.3,
    '94': 46.2,
    '95': 46.2,
    '96': 46.1,
    '97': 46,
    '98': 45.9,
    '99': 45.8,
    '100': 45.7,
    '101': 45.6,
    '102': 45.5,
    '103': 45.4,
    '104': 45.3,
    '105': 45.2,
    '106': 45.1,
    '107': 45.1,
    '108': 45,
    '109': 44.9,
    '110': 44.8,
    '111': 44.7,
    '112': 44.6,
    '113': 44.5,
    '114': 44.4,
    '115': 44.3,
    '116': 44.2,
    '117': 44.2,
    '118': 44.1,
    '119': 44,
    '120': 43.9,
    '121': 43.8,
    '122': 43.7,
    '123': 43.6,
    '124': 43.5,
    '125': 43.4,
    '126': 43.4,
    '127': 43.3,
    '128': 43.2,
    '129': 43.1,
    '130': 43,
    '131': 42.9,
    '132': 42.9,
    '133': 42.7,
    '134': 42.7,
    '135': 42.6,
    '136': 42.5,
    '137': 42.4,
    '138': 42.3,
    '139': 42.2,
    '140': 42.1,
    '141': 42.1,
    '142': 42,
    '143': 41.9,
    '144': 41.8,
    '145': 41.7,
    '146': 41.6,
    '147': 41.6,
    '148': 41.5,
    '149': 41.4,
    '150': 41.3,
    '151': 41.2,
    '152': 41.1,
    '153': 41.1,
    '154': 41,
    '155': 40.9,
    '156': 40.8,
    '157': 40.7,
    '158': 40.6,
    '159': 40.6,
    '160': 40.5,
    '161': 40.4,
    '162': 40.3,
    '163': 40.2,
    '164': 40.1,
    '165': 40.1,
    '166': 40,
    '167': 39.9,
    '168': 39.8,
    '169': 39.7,
    '170': 39.7,
    '171': 39.6,
    '172': 39.5,
    '173': 39.4,
    '174': 39.3,
    '175': 39.2,
    '176': 39.2,
    '177': 39.1,
    '178': 39,
    '179': 38.9,
    '180': 38.8,
    '181': 38.8,
    '182': 38.7,
    '183': 38.6,
    '184': 38.5,
    '185': 38.5,
    '186': 38.4,
    '187': 38.3,
    '188': 38.2,
    '189': 38.1,
    '190': 38.1,
    '191': 38,
    '192': 37.9,
    '193': 37.8,
    '194': 37.7,
    '195': 37.7,
    '0.5': 55.7,
    '1.5': 55.6,
    '2.5': 55.5,
    '3.5': 55.4,
    '4.5': 55.3,
    '5.5': 55.2,
    '6.5': 55.1,
    '7.5': 55,
    '8.5': 54.9,
    '9.5': 54.7,
    '10.5': 54.6,
    '11.5': 54.5,
    '12.5': 54.4,
    '13.5': 54.3,
    '14.5': 54.2,
    '15.5': 54.1,
    '16.5': 54,
    '17.5': 53.9,
    '18.5': 53.8,
    '19.5': 53.7,
    '20.5': 53.6,
    '21.5': 53.4,
    '22.5': 53.3,
    '23.5': 53.2,
    '24.5': 53.1,
    '25.5': 53,
    '26.5': 52.9,
    '27.5': 52.8,
    '28.5': 52.7,
    '29.5': 52.6,
    '30.5': 52.5,
    '31.5': 52.4,
    '32.5': 52.3,
    '33.5': 52.2,
    '34.5': 52.1,
    '35.5': 52,
    '36.5': 51.9,
    '37.5': 51.8,
    '38.5': 51.7,
    '39.5': 51.6,
    '40.5': 51.5,
    '41.5': 51.4,
    '42.5': 51.3,
    '43.5': 51.2,
    '44.5': 51.1,
    '45.5': 51,
    '46.5': 50.9,
    '47.5': 50.8,
    '48.5': 50.6,
    '49.5': 50.5,
    '50.5': 50.4,
    '51.5': 50.3,
    '52.5': 50.2,
    '53.5': 50.1,
    '54.5': 50,
    '55.5': 49.9,
    '56.5': 49.8,
    '57.5': 49.7,
    '58.5': 49.6,
    '59.5': 49.5,
    '60.5': 49.4,
    '61.5': 49.3,
    '62.5': 49.3,
    '63.5': 49.2,
    '64.5': 49.1,
    '65.5': 49,
    '66.5': 48.9,
    '67.5': 48.8,
    '68.5': 48.7,
    '69.5': 48.6,
    '70.5': 48.5,
    '71.5': 48.4,
    '72.5': 48.3,
    '73.5': 48.2,
    '74.5': 48.1,
    '75.5': 48,
    '76.5': 47.9,
    '77.5': 47.8,
    '78.5': 47.7,
    '79.5': 47.6,
    '80.5': 47.5,
    '81.5': 47.4,
    '82.5': 47.3,
    '83.5': 47.2,
    '84.5': 47.1,
    '85.5': 47,
    '86.5': 46.9,
    '87.5': 46.8,
    '88.5': 46.8,
    '89.5': 46.7,
    '90.5': 46.6,
    '91.5': 46.5,
    '92.5': 46.4,
    '93.5': 46.3,
    '94.5': 46.2,
    '95.5': 46.1,
    '96.5': 46,
    '97.5': 45.9,
    '98.5': 45.8,
    '99.5': 45.7,
    '100.5': 45.6,
    '101.5': 45.5,
    '102.5': 45.5,
    '103.5': 45.4,
    '104.5': 45.3,
    '105.5': 45.2,
    '106.5': 45.1,
    '107.5': 45,
    '108.5': 44.9,
    '109.5': 44.8,
    '110.5': 44.7,
    '111.5': 44.6,
    '112.5': 44.6,
    '113.5': 44.5,
    '114.5': 44.4,
    '115.5': 44.3,
    '116.5': 44.2,
    '117.5': 44.1,
    '118.5': 44,
    '119.5': 43.9,
    '120.5': 43.8,
    '121.5': 43.8,
    '122.5': 43.7,
    '123.5': 43.6,
    '124.5': 43.5,
    '125.5': 43.4,
    '126.5': 43.3,
    '127.5': 43.2,
    '128.5': 43.1,
    '129.5': 43.1,
    '130.5': 43,
    '131.5': 42.9,
    '132.5': 42.8,
    '133.5': 42.7,
    '134.5': 42.6,
    '135.5': 42.5,
    '136.5': 42.4,
    '137.5': 42.4,
    '138.5': 42.3,
    '139.5': 42.2,
    '140.5': 42.1,
    '141.5': 42,
    '142.5': 41.9,
    '143.5': 41.9,
    '144.5': 41.8,
    '145.5': 41.7,
    '146.5': 41.6,
    '147.5': 41.5,
    '148.5': 41.4,
    '149.5': 41.3,
    '150.5': 41.3,
    '151.5': 41.2,
    '152.5': 41.1,
    '153.5': 41,
    '154.5': 40.9,
    '155.5': 40.8,
    '156.5': 40.8,
    '157.5': 40.7,
    '158.5': 40.6,
    '159.5': 40.5,
    '160.5': 40.4,
    '161.5': 40.3,
    '162.5': 40.3,
    '163.5': 40.2,
    '164.5': 40.1,
    '165.5': 40,
    '166.5': 39.9,
    '167.5': 39.9,
    '168.5': 39.8,
    '169.5': 39.7,
    '170.5': 39.6,
    '171.5': 39.5,
    '172.5': 39.4,
    '173.5': 39.4,
    '174.5': 39.3,
    '175.5': 39.2,
    '176.5': 39.1,
    '177.5': 39,
    '178.5': 39,
    '179.5': 38.9,
    '180.5': 38.8,
    '181.5': 38.7,
    '182.5': 38.7,
    '183.5': 38.6,
    '184.5': 38.5,
    '185.5': 38.4,
    '186.5': 38.3,
    '187.5': 38.3,
    '188.5': 38.2,
    '189.5': 38.1,
    '190.5': 38,
    '191.5': 37.9,
    '192.5': 37.9,
    '193.5': 37.8,
    '194.5': 37.7,
  },
  '50': {
    '0': 56.3,
    '1': 56.2,
    '2': 56.1,
    '3': 56,
    '4': 55.9,
    '5': 55.8,
    '6': 55.7,
    '7': 55.6,
    '8': 55.5,
    '9': 55.3,
    '10': 55.2,
    '11': 55.1,
    '12': 55,
    '13': 54.9,
    '14': 54.8,
    '15': 54.7,
    '16': 54.6,
    '17': 54.5,
    '18': 54.4,
    '19': 54.3,
    '20': 54.1,
    '21': 54,
    '22': 53.9,
    '23': 53.8,
    '24': 53.7,
    '25': 53.6,
    '26': 53.5,
    '27': 53.4,
    '28': 53.3,
    '29': 53.2,
    '30': 53.1,
    '31': 53,
    '32': 52.9,
    '33': 52.8,
    '34': 52.7,
    '35': 52.6,
    '36': 52.5,
    '37': 52.3,
    '38': 52.2,
    '39': 52.1,
    '40': 52,
    '41': 51.9,
    '42': 51.8,
    '43': 51.7,
    '44': 51.6,
    '45': 51.5,
    '46': 51.4,
    '47': 51.3,
    '48': 51.2,
    '49': 51.1,
    '50': 51,
    '51': 50.9,
    '52': 50.8,
    '53': 50.7,
    '54': 50.6,
    '55': 50.5,
    '56': 50.4,
    '57': 50.3,
    '58': 50.2,
    '59': 50.1,
    '60': 50,
    '61': 49.9,
    '62': 49.8,
    '63': 49.7,
    '64': 49.6,
    '65': 49.5,
    '66': 49.4,
    '67': 49.3,
    '68': 49.2,
    '69': 49.1,
    '70': 49,
    '71': 48.9,
    '72': 48.8,
    '73': 48.7,
    '74': 48.6,
    '75': 48.5,
    '76': 48.4,
    '77': 48.3,
    '78': 48.2,
    '79': 48.1,
    '80': 48,
    '81': 47.9,
    '82': 47.9,
    '83': 47.8,
    '84': 47.7,
    '85': 47.6,
    '86': 47.5,
    '87': 47.4,
    '88': 47.3,
    '89': 47.2,
    '90': 47.1,
    '91': 47,
    '92': 46.9,
    '93': 46.8,
    '94': 46.7,
    '95': 46.6,
    '96': 46.5,
    '97': 46.4,
    '98': 46.3,
    '99': 46.3,
    '100': 46.2,
    '101': 46.1,
    '102': 46,
    '103': 45.9,
    '104': 45.8,
    '105': 45.7,
    '106': 45.6,
    '107': 45.5,
    '108': 45.4,
    '109': 45.3,
    '110': 45.2,
    '111': 45.1,
    '112': 45.1,
    '113': 45,
    '114': 44.9,
    '115': 44.8,
    '116': 44.7,
    '117': 44.6,
    '118': 44.5,
    '119': 44.4,
    '120': 44.3,
    '121': 44.3,
    '122': 44.2,
    '123': 44.1,
    '124': 44,
    '125': 43.9,
    '126': 43.8,
    '127': 43.7,
    '128': 43.6,
    '129': 43.5,
    '130': 43.5,
    '131': 43.4,
    '132': 43.3,
    '133': 43.2,
    '134': 43.1,
    '135': 43,
    '136': 42.9,
    '137': 42.9,
    '138': 42.8,
    '139': 42.7,
    '140': 42.6,
    '141': 42.5,
    '142': 42.4,
    '143': 42.3,
    '144': 42.2,
    '145': 42.2,
    '146': 42.1,
    '147': 42,
    '148': 41.9,
    '149': 41.8,
    '150': 41.7,
    '151': 41.7,
    '152': 41.6,
    '153': 41.5,
    '154': 41.4,
    '155': 41.3,
    '156': 41.2,
    '157': 41.1,
    '158': 41.1,
    '159': 41,
    '160': 40.9,
    '161': 40.8,
    '162': 40.7,
    '163': 40.7,
    '164': 40.6,
    '165': 40.5,
    '166': 40.4,
    '167': 40.3,
    '168': 40.2,
    '169': 40.2,
    '170': 40.1,
    '171': 40,
    '172': 39.9,
    '173': 39.8,
    '174': 39.8,
    '175': 39.7,
    '176': 39.6,
    '177': 39.5,
    '178': 39.4,
    '179': 39.3,
    '180': 39.3,
    '181': 39.2,
    '182': 39.1,
    '183': 39,
    '184': 38.9,
    '185': 38.9,
    '186': 38.8,
    '187': 38.7,
    '188': 38.6,
    '189': 38.5,
    '190': 38.5,
    '191': 38.4,
    '192': 38.3,
    '193': 38.2,
    '194': 38.2,
    '195': 38.1,
    '0.5': 56.3,
    '1.5': 56.2,
    '2.5': 56.1,
    '3.5': 56,
    '4.5': 55.8,
    '5.5': 55.7,
    '6.5': 55.6,
    '7.5': 55.5,
    '8.5': 55.4,
    '9.5': 55.3,
    '10.5': 55.2,
    '11.5': 55.1,
    '12.5': 55,
    '13.5': 54.9,
    '14.5': 54.7,
    '15.5': 54.6,
    '16.5': 54.5,
    '17.5': 54.4,
    '18.5': 54.3,
    '19.5': 54.2,
    '20.5': 54.1,
    '21.5': 54,
    '22.5': 53.9,
    '23.5': 53.8,
    '24.5': 53.7,
    '25.5': 53.6,
    '26.5': 53.5,
    '27.5': 53.3,
    '28.5': 53.2,
    '29.5': 53.1,
    '30.5': 53,
    '31.5': 52.9,
    '32.5': 52.8,
    '33.5': 52.7,
    '34.5': 52.6,
    '35.5': 52.5,
    '36.5': 52.4,
    '37.5': 52.3,
    '38.5': 52.2,
    '39.5': 52.1,
    '40.5': 52,
    '41.5': 51.9,
    '42.5': 51.8,
    '43.5': 51.7,
    '44.5': 51.6,
    '45.5': 51.5,
    '46.5': 51.4,
    '47.5': 51.3,
    '48.5': 51.2,
    '49.5': 51.1,
    '50.5': 51,
    '51.5': 50.9,
    '52.5': 50.8,
    '53.5': 50.7,
    '54.5': 50.6,
    '55.5': 50.5,
    '56.5': 50.4,
    '57.5': 50.3,
    '58.5': 50.2,
    '59.5': 50,
    '60.5': 49.9,
    '61.5': 49.8,
    '62.5': 49.8,
    '63.5': 49.7,
    '64.5': 49.6,
    '65.5': 49.5,
    '66.5': 49.4,
    '67.5': 49.3,
    '68.5': 49.2,
    '69.5': 49.1,
    '70.5': 49,
    '71.5': 48.9,
    '72.5': 48.8,
    '73.5': 48.7,
    '74.5': 48.6,
    '75.5': 48.5,
    '76.5': 48.4,
    '77.5': 48.3,
    '78.5': 48.2,
    '79.5': 48.1,
    '80.5': 48,
    '81.5': 47.9,
    '82.5': 47.8,
    '83.5': 47.7,
    '84.5': 47.6,
    '85.5': 47.5,
    '86.5': 47.4,
    '87.5': 47.3,
    '88.5': 47.2,
    '89.5': 47.1,
    '90.5': 47,
    '91.5': 47,
    '92.5': 46.9,
    '93.5': 46.8,
    '94.5': 46.7,
    '95.5': 46.6,
    '96.5': 46.5,
    '97.5': 46.4,
    '98.5': 46.3,
    '99.5': 46.2,
    '100.5': 46.1,
    '101.5': 46,
    '102.5': 45.9,
    '103.5': 45.8,
    '104.5': 45.7,
    '105.5': 45.7,
    '106.5': 45.6,
    '107.5': 45.5,
    '108.5': 45.4,
    '109.5': 45.3,
    '110.5': 45.2,
    '111.5': 45.1,
    '112.5': 45,
    '113.5': 44.9,
    '114.5': 44.8,
    '115.5': 44.7,
    '116.5': 44.7,
    '117.5': 44.6,
    '118.5': 44.5,
    '119.5': 44.4,
    '120.5': 44.3,
    '121.5': 44.2,
    '122.5': 44.1,
    '123.5': 44,
    '124.5': 43.9,
    '125.5': 43.9,
    '126.5': 43.8,
    '127.5': 43.7,
    '128.5': 43.6,
    '129.5': 43.5,
    '130.5': 43.4,
    '131.5': 43.3,
    '132.5': 43.2,
    '133.5': 43.2,
    '134.5': 43.1,
    '135.5': 43,
    '136.5': 42.9,
    '137.5': 42.8,
    '138.5': 42.7,
    '139.5': 42.6,
    '140.5': 42.5,
    '141.5': 42.5,
    '142.5': 42.4,
    '143.5': 42.3,
    '144.5': 42.2,
    '145.5': 42.1,
    '146.5': 42,
    '147.5': 41.9,
    '148.5': 41.9,
    '149.5': 41.8,
    '150.5': 41.7,
    '151.5': 41.6,
    '152.5': 41.5,
    '153.5': 41.4,
    '154.5': 41.4,
    '155.5': 41.3,
    '156.5': 41.2,
    '157.5': 41.1,
    '158.5': 41,
    '159.5': 40.9,
    '160.5': 40.9,
    '161.5': 40.8,
    '162.5': 40.7,
    '163.5': 40.6,
    '164.5': 40.5,
    '165.5': 40.4,
    '166.5': 40.4,
    '167.5': 40.3,
    '168.5': 40.2,
    '169.5': 40.1,
    '170.5': 40,
    '171.5': 40,
    '172.5': 39.9,
    '173.5': 39.8,
    '174.5': 39.7,
    '175.5': 39.6,
    '176.5': 39.5,
    '177.5': 39.5,
    '178.5': 39.4,
    '179.5': 39.3,
    '180.5': 39.2,
    '181.5': 39.1,
    '182.5': 39.1,
    '183.5': 39,
    '184.5': 38.9,
    '185.5': 38.8,
    '186.5': 38.7,
    '187.5': 38.7,
    '188.5': 38.6,
    '189.5': 38.5,
    '190.5': 38.4,
    '191.5': 38.4,
    '192.5': 38.3,
    '193.5': 38.2,
    '194.5': 38.1,
  },
  // 50 - 55
  '50.5': {
    '0': 56.9,
    '1': 56.8,
    '2': 56.7,
    '3': 56.6,
    '4': 56.4,
    '5': 56.3,
    '6': 56.2,
    '7': 56.1,
    '8': 56,
    '9': 55.9,
    '10': 55.8,
    '11': 55.7,
    '12': 55.6,
    '13': 55.4,
    '14': 55.3,
    '15': 55.2,
    '16': 55.1,
    '17': 55,
    '18': 54.9,
    '19': 54.8,
    '20': 54.7,
    '21': 54.6,
    '22': 54.5,
    '23': 54.4,
    '24': 54.3,
    '25': 54.1,
    '26': 54,
    '27': 53.9,
    '28': 53.8,
    '29': 53.7,
    '30': 53.6,
    '31': 53.5,
    '32': 53.4,
    '33': 53.3,
    '34': 53.2,
    '35': 53.1,
    '36': 53,
    '37': 52.9,
    '38': 52.8,
    '39': 52.7,
    '40': 52.6,
    '41': 52.4,
    '42': 52.3,
    '43': 52.2,
    '44': 52.1,
    '45': 52,
    '46': 51.9,
    '47': 51.8,
    '48': 51.7,
    '49': 51.6,
    '50': 51.5,
    '51': 51.4,
    '52': 51.3,
    '53': 51.2,
    '54': 51.1,
    '55': 51,
    '56': 50.9,
    '57': 50.8,
    '58': 50.7,
    '59': 50.6,
    '60': 50.5,
    '61': 50.4,
    '62': 50.3,
    '63': 50.2,
    '64': 50.1,
    '65': 50,
    '66': 49.9,
    '67': 49.8,
    '68': 49.7,
    '69': 49.6,
    '70': 49.5,
    '71': 49.4,
    '72': 49.3,
    '73': 49.2,
    '74': 49.1,
    '75': 49,
    '76': 48.9,
    '77': 48.8,
    '78': 48.7,
    '79': 48.6,
    '80': 48.5,
    '81': 48.4,
    '82': 48.3,
    '83': 48.2,
    '84': 48.1,
    '85': 48,
    '86': 47.9,
    '87': 47.9,
    '88': 47.8,
    '89': 47.7,
    '90': 47.6,
    '91': 47.5,
    '92': 47.4,
    '93': 47.3,
    '94': 47.2,
    '95': 47.1,
    '96': 47,
    '97': 46.9,
    '98': 46.8,
    '99': 46.7,
    '100': 46.6,
    '101': 46.5,
    '102': 46.4,
    '103': 46.3,
    '104': 46.3,
    '105': 46.2,
    '106': 46.1,
    '107': 46,
    '108': 45.9,
    '109': 45.8,
    '110': 45.7,
    '111': 45.6,
    '112': 45.5,
    '113': 45.4,
    '114': 45.3,
    '115': 45.2,
    '116': 45.2,
    '117': 45.1,
    '118': 45,
    '119': 44.9,
    '120': 44.8,
    '121': 44.7,
    '122': 44.6,
    '123': 44.5,
    '124': 44.4,
    '125': 44.3,
    '126': 44.3,
    '127': 44.2,
    '128': 44.1,
    '129': 44,
    '130': 43.9,
    '131': 43.8,
    '132': 43.7,
    '133': 43.6,
    '134': 43.6,
    '135': 43.5,
    '136': 43.4,
    '137': 43.3,
    '138': 43.2,
    '139': 43.1,
    '140': 43,
    '141': 42.9,
    '142': 42.9,
    '143': 42.8,
    '144': 42.7,
    '145': 42.6,
    '146': 42.5,
    '147': 42.4,
    '148': 42.3,
    '149': 42.3,
    '150': 42.2,
    '151': 42.1,
    '152': 42,
    '153': 41.9,
    '154': 41.8,
    '155': 41.7,
    '156': 41.7,
    '157': 41.6,
    '158': 41.5,
    '159': 41.4,
    '160': 41.3,
    '161': 41.2,
    '162': 41.2,
    '163': 41.1,
    '164': 41,
    '165': 40.9,
    '166': 40.8,
    '167': 40.7,
    '168': 40.7,
    '169': 40.6,
    '170': 40.5,
    '171': 40.4,
    '172': 40.3,
    '173': 40.3,
    '174': 40.2,
    '175': 40.1,
    '176': 40,
    '177': 39.9,
    '178': 39.8,
    '179': 39.8,
    '180': 39.7,
    '181': 39.6,
    '182': 39.5,
    '183': 39.4,
    '184': 39.4,
    '185': 39.3,
    '186': 39.2,
    '187': 39.1,
    '188': 39,
    '189': 39,
    '190': 38.9,
    '191': 38.8,
    '192': 38.7,
    '193': 38.6,
    '194': 38.6,
    '195': 38.5,
    '0.5': 56.8,
    '1.5': 56.7,
    '2.5': 56.6,
    '3.5': 56.5,
    '4.5': 56.4,
    '5.5': 56.3,
    '6.5': 56.2,
    '7.5': 56.1,
    '8.5': 55.9,
    '9.5': 55.8,
    '10.5': 55.7,
    '11.5': 55.6,
    '12.5': 55.5,
    '13.5': 55.4,
    '14.5': 55.3,
    '15.5': 55.2,
    '16.5': 55.1,
    '17.5': 55,
    '18.5': 54.8,
    '19.5': 54.7,
    '20.5': 54.6,
    '21.5': 54.5,
    '22.5': 54.4,
    '23.5': 54.3,
    '24.5': 54.2,
    '25.5': 54.1,
    '26.5': 54,
    '27.5': 53.9,
    '28.5': 53.8,
    '29.5': 53.7,
    '30.5': 53.6,
    '31.5': 53.4,
    '32.5': 53.3,
    '33.5': 53.2,
    '34.5': 53.1,
    '35.5': 53,
    '36.5': 52.9,
    '37.5': 52.8,
    '38.5': 52.7,
    '39.5': 52.6,
    '40.5': 52.5,
    '41.5': 52.4,
    '42.5': 52.3,
    '43.5': 52.2,
    '44.5': 52.1,
    '45.5': 52,
    '46.5': 51.9,
    '47.5': 51.8,
    '48.5': 51.7,
    '49.5': 51.6,
    '50.5': 51.5,
    '51.5': 51.4,
    '52.5': 51.3,
    '53.5': 51.2,
    '54.5': 51.1,
    '55.5': 51,
    '56.5': 50.9,
    '57.5': 50.8,
    '58.5': 50.7,
    '59.5': 50.6,
    '60.5': 50.4,
    '61.5': 50.3,
    '62.5': 50.2,
    '63.5': 50.2,
    '64.5': 50,
    '65.5': 49.9,
    '66.5': 49.8,
    '67.5': 49.8,
    '68.5': 49.7,
    '69.5': 49.6,
    '70.5': 49.5,
    '71.5': 49.4,
    '72.5': 49.3,
    '73.5': 49.2,
    '74.5': 49.1,
    '75.5': 49,
    '76.5': 48.9,
    '77.5': 48.8,
    '78.5': 48.7,
    '79.5': 48.6,
    '80.5': 48.5,
    '81.5': 48.4,
    '82.5': 48.3,
    '83.5': 48.2,
    '84.5': 48.1,
    '85.5': 48,
    '86.5': 47.9,
    '87.5': 47.8,
    '88.5': 47.7,
    '89.5': 47.6,
    '90.5': 47.5,
    '91.5': 47.4,
    '92.5': 47.3,
    '93.5': 47.2,
    '94.5': 47.1,
    '95.5': 47,
    '96.5': 47,
    '97.5': 46.9,
    '98.5': 46.8,
    '99.5': 46.7,
    '100.5': 46.6,
    '101.5': 46.5,
    '102.5': 46.4,
    '103.5': 46.3,
    '104.5': 46.2,
    '105.5': 46.1,
    '106.5': 46,
    '107.5': 45.9,
    '108.5': 45.8,
    '109.5': 45.7,
    '110.5': 45.7,
    '111.5': 45.6,
    '112.5': 45.5,
    '113.5': 45.4,
    '114.5': 45.3,
    '115.5': 45.2,
    '116.5': 45.1,
    '117.5': 45,
    '118.5': 44.9,
    '119.5': 44.8,
    '120.5': 44.8,
    '121.5': 44.7,
    '122.5': 44.6,
    '123.5': 44.5,
    '124.5': 44.4,
    '125.5': 44.3,
    '126.5': 44.2,
    '127.5': 44.1,
    '128.5': 44,
    '129.5': 43.9,
    '130.5': 43.9,
    '131.5': 43.8,
    '132.5': 43.7,
    '133.5': 43.6,
    '134.5': 43.5,
    '135.5': 43.4,
    '136.5': 43.3,
    '137.5': 43.2,
    '138.5': 43.2,
    '139.5': 43.1,
    '140.5': 43,
    '141.5': 42.9,
    '142.5': 42.8,
    '143.5': 42.7,
    '144.5': 42.6,
    '145.5': 42.6,
    '146.5': 42.5,
    '147.5': 42.4,
    '148.5': 42.3,
    '149.5': 42.2,
    '150.5': 42.1,
    '151.5': 42,
    '152.5': 42,
    '153.5': 41.9,
    '154.5': 41.8,
    '155.5': 41.7,
    '156.5': 41.6,
    '157.5': 41.5,
    '158.5': 41.5,
    '159.5': 41.4,
    '160.5': 41.3,
    '161.5': 41.2,
    '162.5': 41.1,
    '163.5': 41,
    '164.5': 41,
    '165.5': 40.9,
    '166.5': 40.8,
    '167.5': 40.7,
    '168.5': 40.6,
    '169.5': 40.5,
    '170.5': 40.5,
    '171.5': 40.4,
    '172.5': 40.3,
    '173.5': 40.2,
    '174.5': 40.1,
    '175.5': 40,
    '176.5': 40,
    '177.5': 39.9,
    '178.5': 39.8,
    '179.5': 39.7,
    '180.5': 39.6,
    '181.5': 39.6,
    '182.5': 39.5,
    '183.5': 39.4,
    '184.5': 39.3,
    '185.5': 39.2,
    '186.5': 39.2,
    '187.5': 39.1,
    '188.5': 39,
    '189.5': 38.9,
    '190.5': 38.8,
    '191.5': 38.8,
    '192.5': 38.7,
    '193.5': 38.6,
    '194.5': 38.5,
  },
  '51': {
    '0': 57.5,
    '1': 57.3,
    '2': 57.2,
    '3': 57.1,
    '4': 57,
    '5': 56.9,
    '6': 56.8,
    '7': 56.7,
    '8': 56.5,
    '9': 56.4,
    '10': 56.3,
    '11': 56.2,
    '12': 56.1,
    '13': 56,
    '14': 55.9,
    '15': 55.8,
    '16': 55.7,
    '17': 55.5,
    '18': 55.4,
    '19': 55.3,
    '20': 55.2,
    '21': 55.1,
    '22': 55,
    '23': 54.9,
    '24': 54.8,
    '25': 54.7,
    '26': 54.6,
    '27': 54.5,
    '28': 54.3,
    '29': 54.2,
    '30': 54.1,
    '31': 54,
    '32': 53.9,
    '33': 53.8,
    '34': 53.7,
    '35': 53.6,
    '36': 53.5,
    '37': 53.4,
    '38': 53.3,
    '39': 53.2,
    '40': 53.1,
    '41': 53,
    '42': 52.9,
    '43': 52.8,
    '44': 52.6,
    '45': 52.5,
    '46': 52.4,
    '47': 52.3,
    '48': 52.2,
    '49': 52.1,
    '50': 52,
    '51': 51.9,
    '52': 51.8,
    '53': 51.7,
    '54': 51.6,
    '55': 51.5,
    '56': 51.4,
    '57': 51.3,
    '58': 51.2,
    '59': 51.1,
    '60': 51,
    '61': 50.9,
    '62': 50.8,
    '63': 50.7,
    '64': 50.6,
    '65': 50.5,
    '66': 50.4,
    '67': 50.3,
    '68': 50.2,
    '69': 50.1,
    '70': 50,
    '71': 49.9,
    '72': 49.8,
    '73': 49.7,
    '74': 49.6,
    '75': 49.5,
    '76': 49.4,
    '77': 49.3,
    '78': 49.2,
    '79': 49.1,
    '80': 49,
    '81': 48.9,
    '82': 48.8,
    '83': 48.7,
    '84': 48.6,
    '85': 48.5,
    '86': 48.4,
    '87': 48.3,
    '88': 48.2,
    '89': 48.1,
    '90': 48,
    '91': 47.9,
    '92': 47.9,
    '93': 47.8,
    '94': 47.7,
    '95': 47.6,
    '96': 47.5,
    '97': 47.4,
    '98': 47.3,
    '99': 47.2,
    '100': 47.1,
    '101': 47,
    '102': 46.9,
    '103': 46.8,
    '104': 46.7,
    '105': 46.6,
    '106': 46.5,
    '107': 46.4,
    '108': 46.3,
    '109': 46.3,
    '110': 46.2,
    '111': 46.1,
    '112': 46,
    '113': 45.9,
    '114': 45.8,
    '115': 45.7,
    '116': 45.6,
    '117': 45.5,
    '118': 45.4,
    '119': 45.3,
    '120': 45.2,
    '121': 45.2,
    '122': 45.1,
    '123': 45,
    '124': 44.9,
    '125': 44.8,
    '126': 44.7,
    '127': 44.6,
    '128': 44.5,
    '129': 44.4,
    '130': 44.4,
    '131': 44.3,
    '132': 44.2,
    '133': 44.1,
    '134': 44,
    '135': 43.9,
    '136': 43.8,
    '137': 43.7,
    '138': 43.6,
    '139': 43.6,
    '140': 43.5,
    '141': 43.4,
    '142': 43.3,
    '143': 43.2,
    '144': 43.1,
    '145': 43,
    '146': 42.9,
    '147': 42.9,
    '148': 42.8,
    '149': 42.7,
    '150': 42.6,
    '151': 42.5,
    '152': 42.4,
    '153': 42.3,
    '154': 42.3,
    '155': 42.2,
    '156': 42.1,
    '157': 42,
    '158': 41.9,
    '159': 41.8,
    '160': 41.8,
    '161': 41.7,
    '162': 41.6,
    '163': 41.5,
    '164': 41.4,
    '165': 41.3,
    '166': 41.2,
    '167': 41.2,
    '168': 41.1,
    '169': 41,
    '170': 40.9,
    '171': 40.8,
    '172': 40.8,
    '173': 40.7,
    '174': 40.6,
    '175': 40.5,
    '176': 40.4,
    '177': 40.3,
    '178': 40.3,
    '179': 40.2,
    '180': 40.1,
    '181': 40,
    '182': 39.9,
    '183': 39.9,
    '184': 39.8,
    '185': 39.7,
    '186': 39.6,
    '187': 39.5,
    '188': 39.4,
    '189': 39.4,
    '190': 39.3,
    '191': 39.2,
    '192': 39.1,
    '193': 39,
    '194': 39,
    '195': 38.9,
    '0.5': 57.4,
    '1.5': 57.3,
    '2.5': 57.2,
    '3.5': 57.1,
    '4.5': 56.9,
    '5.5': 56.8,
    '6.5': 56.7,
    '7.5': 56.6,
    '8.5': 56.5,
    '9.5': 56.4,
    '10.5': 56.3,
    '11.5': 56.2,
    '12.5': 56,
    '13.5': 55.9,
    '14.5': 55.8,
    '15.5': 55.7,
    '16.5': 55.6,
    '17.5': 55.5,
    '18.5': 55.4,
    '19.5': 55.3,
    '20.5': 55.2,
    '21.5': 55.1,
    '22.5': 54.9,
    '23.5': 54.8,
    '24.5': 54.7,
    '25.5': 54.6,
    '26.5': 54.5,
    '27.5': 54.4,
    '28.5': 54.3,
    '29.5': 54.2,
    '30.5': 54.1,
    '31.5': 54,
    '32.5': 53.9,
    '33.5': 53.8,
    '34.5': 53.7,
    '35.5': 53.5,
    '36.5': 53.4,
    '37.5': 53.3,
    '38.5': 53.2,
    '39.5': 53.1,
    '40.5': 53,
    '41.5': 52.9,
    '42.5': 52.8,
    '43.5': 52.7,
    '44.5': 52.6,
    '45.5': 52.5,
    '46.5': 52.4,
    '47.5': 52.3,
    '48.5': 52.2,
    '49.5': 52.1,
    '50.5': 52,
    '51.5': 51.9,
    '52.5': 51.8,
    '53.5': 51.7,
    '54.5': 51.6,
    '55.5': 51.5,
    '56.5': 51.4,
    '57.5': 51.3,
    '58.5': 51.2,
    '59.5': 51.1,
    '60.5': 50.9,
    '61.5': 50.8,
    '62.5': 50.7,
    '63.5': 50.6,
    '64.5': 50.5,
    '65.5': 50.4,
    '66.5': 50.3,
    '67.5': 50.2,
    '68.5': 50.1,
    '69.5': 50,
    '70.5': 49.9,
    '71.5': 49.8,
    '72.5': 49.7,
    '73.5': 49.6,
    '74.5': 49.5,
    '75.5': 49.5,
    '76.5': 49.4,
    '77.5': 49.3,
    '78.5': 49.2,
    '79.5': 49.1,
    '80.5': 49,
    '81.5': 48.9,
    '82.5': 48.8,
    '83.5': 48.7,
    '84.5': 48.6,
    '85.5': 48.5,
    '86.5': 48.4,
    '87.5': 48.3,
    '88.5': 48.2,
    '89.5': 48.1,
    '90.5': 48,
    '91.5': 47.9,
    '92.5': 47.8,
    '93.5': 47.7,
    '94.5': 47.6,
    '95.5': 47.5,
    '96.5': 47.4,
    '97.5': 47.3,
    '98.5': 47.2,
    '99.5': 47.1,
    '100.5': 47,
    '101.5': 47,
    '102.5': 46.9,
    '103.5': 46.8,
    '104.5': 46.7,
    '105.5': 46.6,
    '106.5': 46.5,
    '107.5': 46.4,
    '108.5': 46.3,
    '109.5': 46.2,
    '110.5': 46.1,
    '111.5': 46,
    '112.5': 45.9,
    '113.5': 45.8,
    '114.5': 45.7,
    '115.5': 45.7,
    '116.5': 45.6,
    '117.5': 45.5,
    '118.5': 45.4,
    '119.5': 45.3,
    '120.5': 45.2,
    '121.5': 45.1,
    '122.5': 45,
    '123.5': 44.9,
    '124.5': 44.8,
    '125.5': 44.8,
    '126.5': 44.7,
    '127.5': 44.6,
    '128.5': 44.5,
    '129.5': 44.4,
    '130.5': 44.3,
    '131.5': 44.2,
    '132.5': 44.1,
    '133.5': 44,
    '134.5': 44,
    '135.5': 43.9,
    '136.5': 43.8,
    '137.5': 43.7,
    '138.5': 43.6,
    '139.5': 43.5,
    '140.5': 43.4,
    '141.5': 43.3,
    '142.5': 43.3,
    '143.5': 43.2,
    '144.5': 43.1,
    '145.5': 43,
    '146.5': 42.9,
    '147.5': 42.8,
    '148.5': 42.7,
    '149.5': 42.6,
    '150.5': 42.6,
    '151.5': 42.5,
    '152.5': 42.4,
    '153.5': 42.3,
    '154.5': 42.2,
    '155.5': 42.1,
    '156.5': 42,
    '157.5': 42,
    '158.5': 41.9,
    '159.5': 41.8,
    '160.5': 41.7,
    '161.5': 41.6,
    '162.5': 41.5,
    '163.5': 41.5,
    '164.5': 41.4,
    '165.5': 41.3,
    '166.5': 41.2,
    '167.5': 41.1,
    '168.5': 41,
    '169.5': 41,
    '170.5': 40.9,
    '171.5': 40.8,
    '172.5': 40.7,
    '173.5': 40.6,
    '174.5': 40.5,
    '175.5': 40.5,
    '176.5': 40.4,
    '177.5': 40.3,
    '178.5': 40.2,
    '179.5': 40.1,
    '180.5': 40.1,
    '181.5': 40,
    '182.5': 39.9,
    '183.5': 39.8,
    '184.5': 39.7,
    '185.5': 39.7,
    '186.5': 39.6,
    '187.5': 39.5,
    '188.5': 39.4,
    '189.5': 39.3,
    '190.5': 39.2,
    '191.5': 39.2,
    '192.5': 39.1,
    '193.5': 39,
    '194.5': 38.9,
  },
  '51.5': {
    '0': 58,
    '1': 57.9,
    '2': 57.8,
    '3': 57.7,
    '4': 57.6,
    '5': 57.4,
    '6': 57.3,
    '7': 57.2,
    '8': 57.1,
    '9': 57,
    '10': 56.9,
    '11': 56.8,
    '12': 56.6,
    '13': 56.5,
    '14': 56.4,
    '15': 56.3,
    '16': 56.2,
    '17': 56.1,
    '18': 56,
    '19': 55.9,
    '20': 55.8,
    '21': 55.6,
    '22': 55.5,
    '23': 55.4,
    '24': 55.3,
    '25': 55.2,
    '26': 55.1,
    '27': 55,
    '28': 54.9,
    '29': 54.8,
    '30': 54.7,
    '31': 54.6,
    '32': 54.4,
    '33': 54.3,
    '34': 54.2,
    '35': 54.1,
    '36': 54,
    '37': 53.9,
    '38': 53.8,
    '39': 53.7,
    '40': 53.6,
    '41': 53.5,
    '42': 53.4,
    '43': 53.3,
    '44': 53.2,
    '45': 53.1,
    '46': 53,
    '47': 52.8,
    '48': 52.7,
    '49': 52.6,
    '50': 52.5,
    '51': 52.4,
    '52': 52.3,
    '53': 52.2,
    '54': 52.1,
    '55': 52,
    '56': 51.9,
    '57': 51.8,
    '58': 51.7,
    '59': 51.6,
    '60': 51.5,
    '61': 51.4,
    '62': 51.3,
    '63': 51.2,
    '64': 51.1,
    '65': 51,
    '66': 50.9,
    '67': 50.8,
    '68': 50.7,
    '69': 50.6,
    '70': 50.5,
    '71': 50.4,
    '72': 50.3,
    '73': 50.2,
    '74': 50.1,
    '75': 50,
    '76': 49.9,
    '77': 49.8,
    '78': 49.7,
    '79': 49.6,
    '80': 49.5,
    '81': 49.4,
    '82': 49.3,
    '83': 49.2,
    '84': 49.1,
    '85': 49,
    '86': 48.9,
    '87': 48.8,
    '88': 48.7,
    '89': 48.6,
    '90': 48.5,
    '91': 48.4,
    '92': 48.3,
    '93': 48.2,
    '94': 48.1,
    '95': 48,
    '96': 47.9,
    '97': 47.8,
    '98': 47.8,
    '99': 47.7,
    '100': 47.6,
    '101': 47.5,
    '102': 47.4,
    '103': 47.3,
    '104': 47.2,
    '105': 47.1,
    '106': 47,
    '107': 46.9,
    '108': 46.8,
    '109': 46.7,
    '110': 46.6,
    '111': 46.5,
    '112': 46.4,
    '113': 46.3,
    '114': 46.3,
    '115': 46.2,
    '116': 46.1,
    '117': 46,
    '118': 45.9,
    '119': 45.8,
    '120': 45.7,
    '121': 45.6,
    '122': 45.5,
    '123': 45.4,
    '124': 45.3,
    '125': 45.2,
    '126': 45.2,
    '127': 45.1,
    '128': 45,
    '129': 44.9,
    '130': 44.8,
    '131': 44.7,
    '132': 44.6,
    '133': 44.5,
    '134': 44.4,
    '135': 44.4,
    '136': 44.3,
    '137': 44.2,
    '138': 44.1,
    '139': 44,
    '140': 43.9,
    '141': 43.8,
    '142': 43.7,
    '143': 43.6,
    '144': 43.6,
    '145': 43.5,
    '146': 43.4,
    '147': 43.3,
    '148': 43.2,
    '149': 43.1,
    '150': 43,
    '151': 43,
    '152': 42.9,
    '153': 42.8,
    '154': 42.7,
    '155': 42.6,
    '156': 42.5,
    '157': 42.4,
    '158': 42.3,
    '159': 42.3,
    '160': 42.2,
    '161': 42.1,
    '162': 42,
    '163': 41.9,
    '164': 41.8,
    '165': 41.8,
    '166': 41.7,
    '167': 41.6,
    '168': 41.5,
    '169': 41.4,
    '170': 41.3,
    '171': 41.3,
    '172': 41.2,
    '173': 41.1,
    '174': 41,
    '175': 40.9,
    '176': 40.8,
    '177': 40.8,
    '178': 40.7,
    '179': 40.6,
    '180': 40.5,
    '181': 40.4,
    '182': 40.3,
    '183': 40.3,
    '184': 40.2,
    '185': 40.1,
    '186': 40,
    '187': 39.9,
    '188': 39.9,
    '189': 39.8,
    '190': 39.7,
    '191': 39.6,
    '192': 39.5,
    '193': 39.5,
    '194': 39.4,
    '195': 39.3,
    '0.5': 58,
    '1.5': 57.8,
    '2.5': 57.7,
    '3.5': 57.6,
    '4.5': 57.5,
    '5.5': 57.4,
    '6.5': 57.3,
    '7.5': 57.2,
    '8.5': 57,
    '9.5': 56.9,
    '10.5': 56.8,
    '11.5': 56.7,
    '12.5': 56.6,
    '13.5': 56.5,
    '14.5': 56.4,
    '15.5': 56.3,
    '16.5': 56.1,
    '17.5': 56,
    '18.5': 55.9,
    '19.5': 55.8,
    '20.5': 55.7,
    '21.5': 55.6,
    '22.5': 55.5,
    '23.5': 55.4,
    '24.5': 55.3,
    '25.5': 55.2,
    '26.5': 55,
    '27.5': 54.9,
    '28.5': 54.8,
    '29.5': 54.7,
    '30.5': 54.6,
    '31.5': 54.5,
    '32.5': 54.4,
    '33.5': 54.3,
    '34.5': 54.2,
    '35.5': 54.1,
    '36.5': 54,
    '37.5': 53.9,
    '38.5': 53.7,
    '39.5': 53.6,
    '40.5': 53.5,
    '41.5': 53.4,
    '42.5': 53.3,
    '43.5': 53.2,
    '44.5': 53.1,
    '45.5': 53,
    '46.5': 52.9,
    '47.5': 52.8,
    '48.5': 52.7,
    '49.5': 52.6,
    '50.5': 52.5,
    '51.5': 52.4,
    '52.5': 52.3,
    '53.5': 52.2,
    '54.5': 52.1,
    '55.5': 52,
    '56.5': 51.9,
    '57.5': 51.8,
    '58.5': 51.7,
    '59.5': 51.6,
    '60.5': 51.4,
    '61.5': 51.3,
    '62.5': 51.2,
    '63.5': 51.1,
    '64.5': 51,
    '65.5': 50.9,
    '66.5': 50.8,
    '67.5': 50.7,
    '68.5': 50.6,
    '69.5': 50.5,
    '70.5': 50.4,
    '71.5': 50.3,
    '72.5': 50.2,
    '73.5': 50.1,
    '74.5': 50,
    '75.5': 49.9,
    '76.5': 49.8,
    '77.5': 49.7,
    '78.5': 49.6,
    '79.5': 49.5,
    '80.5': 49.4,
    '81.5': 49.3,
    '82.5': 49.2,
    '83.5': 49.1,
    '84.5': 49.1,
    '85.5': 49,
    '86.5': 48.9,
    '87.5': 48.8,
    '88.5': 48.7,
    '89.5': 48.6,
    '90.5': 48.5,
    '91.5': 48.4,
    '92.5': 48.3,
    '93.5': 48.1,
    '94.5': 48,
    '95.5': 47.9,
    '96.5': 47.8,
    '97.5': 47.8,
    '98.5': 47.7,
    '99.5': 47.6,
    '100.5': 47.5,
    '101.5': 47.4,
    '102.5': 47.3,
    '103.5': 47.2,
    '104.5': 47.1,
    '105.5': 47,
    '106.5': 46.9,
    '107.5': 46.9,
    '108.5': 46.8,
    '109.5': 46.7,
    '110.5': 46.6,
    '111.5': 46.5,
    '112.5': 46.4,
    '113.5': 46.3,
    '114.5': 46.2,
    '115.5': 46.1,
    '116.5': 46,
    '117.5': 45.9,
    '118.5': 45.8,
    '119.5': 45.7,
    '120.5': 45.7,
    '121.5': 45.6,
    '122.5': 45.5,
    '123.5': 45.4,
    '124.5': 45.3,
    '125.5': 45.2,
    '126.5': 45.1,
    '127.5': 45,
    '128.5': 44.9,
    '129.5': 44.8,
    '130.5': 44.8,
    '131.5': 44.7,
    '132.5': 44.6,
    '133.5': 44.5,
    '134.5': 44.4,
    '135.5': 44.3,
    '136.5': 44.2,
    '137.5': 44.1,
    '138.5': 44,
    '139.5': 44,
    '140.5': 43.9,
    '141.5': 43.8,
    '142.5': 43.7,
    '143.5': 43.6,
    '144.5': 43.5,
    '145.5': 43.4,
    '146.5': 43.3,
    '147.5': 43.3,
    '148.5': 43.2,
    '149.5': 43.1,
    '150.5': 43,
    '151.5': 42.9,
    '152.5': 42.8,
    '153.5': 42.7,
    '154.5': 42.7,
    '155.5': 42.6,
    '156.5': 42.5,
    '157.5': 42.4,
    '158.5': 42.3,
    '159.5': 42.2,
    '160.5': 42.1,
    '161.5': 42.1,
    '162.5': 42,
    '163.5': 41.9,
    '164.5': 41.8,
    '165.5': 41.7,
    '166.5': 41.6,
    '167.5': 41.5,
    '168.5': 41.5,
    '169.5': 41.4,
    '170.5': 41.3,
    '171.5': 41.2,
    '172.5': 41.1,
    '173.5': 41,
    '174.5': 41,
    '175.5': 40.9,
    '176.5': 40.8,
    '177.5': 40.7,
    '178.5': 40.6,
    '179.5': 40.6,
    '180.5': 40.5,
    '181.5': 40.4,
    '182.5': 40.3,
    '183.5': 40.2,
    '184.5': 40.1,
    '185.5': 40.1,
    '186.5': 40,
    '187.5': 39.9,
    '188.5': 39.8,
    '189.5': 39.7,
    '190.5': 39.7,
    '191.5': 39.6,
    '192.5': 39.5,
    '193.5': 39.4,
    '194.5': 39.3,
  },
  '52': {
    '0': 58.6,
    '1': 58.5,
    '2': 58.3,
    '3': 58.2,
    '4': 58.1,
    '5': 58,
    '6': 57.9,
    '7': 57.8,
    '8': 57.6,
    '9': 57.5,
    '10': 57.4,
    '11': 57.3,
    '12': 57.2,
    '13': 57.1,
    '14': 57,
    '15': 56.9,
    '16': 56.7,
    '17': 56.6,
    '18': 56.5,
    '19': 56.4,
    '20': 56.3,
    '21': 56.2,
    '22': 56.1,
    '23': 56,
    '24': 55.8,
    '25': 55.7,
    '26': 55.6,
    '27': 55.5,
    '28': 55.4,
    '29': 55.3,
    '30': 55.2,
    '31': 55.1,
    '32': 55,
    '33': 54.9,
    '34': 54.8,
    '35': 54.6,
    '36': 54.5,
    '37': 54.4,
    '38': 54.3,
    '39': 54.2,
    '40': 54.1,
    '41': 54,
    '42': 53.9,
    '43': 53.8,
    '44': 53.7,
    '45': 53.6,
    '46': 53.5,
    '47': 53.4,
    '48': 53.3,
    '49': 53.1,
    '50': 53,
    '51': 52.9,
    '52': 52.8,
    '53': 52.7,
    '54': 52.6,
    '55': 52.5,
    '56': 52.4,
    '57': 52.3,
    '58': 52.2,
    '59': 52.1,
    '60': 52,
    '61': 51.9,
    '62': 51.8,
    '63': 51.7,
    '64': 51.6,
    '65': 51.5,
    '66': 51.4,
    '67': 51.3,
    '68': 51.2,
    '69': 51.1,
    '70': 51,
    '71': 50.9,
    '72': 50.8,
    '73': 50.7,
    '74': 50.6,
    '75': 50.5,
    '76': 50.4,
    '77': 50.3,
    '78': 50.2,
    '79': 50.1,
    '80': 50,
    '81': 49.9,
    '82': 49.8,
    '83': 49.7,
    '84': 49.6,
    '85': 49.5,
    '86': 49.4,
    '87': 49.3,
    '88': 49.2,
    '89': 49.1,
    '90': 49,
    '91': 48.9,
    '92': 48.8,
    '93': 48.7,
    '94': 48.6,
    '95': 48.5,
    '96': 48.4,
    '97': 48.3,
    '98': 48.2,
    '99': 48.1,
    '100': 48,
    '101': 47.9,
    '102': 47.8,
    '103': 47.7,
    '104': 47.6,
    '105': 47.6,
    '106': 47.4,
    '107': 47.4,
    '108': 47.3,
    '109': 47.2,
    '110': 47.1,
    '111': 47,
    '112': 46.9,
    '113': 46.8,
    '114': 46.7,
    '115': 46.6,
    '116': 46.5,
    '117': 46.4,
    '118': 46.3,
    '119': 46.2,
    '120': 46.2,
    '121': 46.1,
    '122': 46,
    '123': 45.9,
    '124': 45.8,
    '125': 45.7,
    '126': 45.6,
    '127': 45.5,
    '128': 45.4,
    '129': 45.3,
    '130': 45.2,
    '131': 45.2,
    '132': 45.1,
    '133': 45,
    '134': 44.9,
    '135': 44.8,
    '136': 44.7,
    '137': 44.6,
    '138': 44.5,
    '139': 44.4,
    '140': 44.3,
    '141': 44.3,
    '142': 44.2,
    '143': 44.1,
    '144': 44,
    '145': 43.9,
    '146': 43.8,
    '147': 43.7,
    '148': 43.6,
    '149': 43.6,
    '150': 43.5,
    '151': 43.4,
    '152': 43.3,
    '153': 43.2,
    '154': 43.1,
    '155': 43,
    '156': 42.9,
    '157': 42.9,
    '158': 42.8,
    '159': 42.7,
    '160': 42.6,
    '161': 42.5,
    '162': 42.4,
    '163': 42.3,
    '164': 42.3,
    '165': 42.2,
    '166': 42.1,
    '167': 42,
    '168': 41.9,
    '169': 41.8,
    '170': 41.8,
    '171': 41.7,
    '172': 41.6,
    '173': 41.5,
    '174': 41.4,
    '175': 41.3,
    '176': 41.3,
    '177': 41.2,
    '178': 41.1,
    '179': 41,
    '180': 40.9,
    '181': 40.8,
    '182': 40.8,
    '183': 40.7,
    '184': 40.6,
    '185': 40.5,
    '186': 40.4,
    '187': 40.3,
    '188': 40.3,
    '189': 40.2,
    '190': 40.1,
    '191': 40,
    '192': 39.9,
    '193': 39.9,
    '194': 39.8,
    '195': 39.7,
    '0.5': 58.5,
    '1.5': 58.4,
    '2.5': 58.3,
    '3.5': 58.2,
    '4.5': 58,
    '5.5': 57.9,
    '6.5': 57.8,
    '7.5': 57.7,
    '8.5': 57.6,
    '9.5': 57.5,
    '10.5': 57.4,
    '11.5': 57.2,
    '12.5': 57.1,
    '13.5': 57,
    '14.5': 56.9,
    '15.5': 56.8,
    '16.5': 56.7,
    '17.5': 56.6,
    '18.5': 56.5,
    '19.5': 56.3,
    '20.5': 56.2,
    '21.5': 56.1,
    '22.5': 56,
    '23.5': 55.9,
    '24.5': 55.8,
    '25.5': 55.7,
    '26.5': 55.6,
    '27.5': 55.5,
    '28.5': 55.3,
    '29.5': 55.2,
    '30.5': 55.1,
    '31.5': 55,
    '32.5': 54.9,
    '33.5': 54.8,
    '34.5': 54.7,
    '35.5': 54.6,
    '36.5': 54.5,
    '37.5': 54.4,
    '38.5': 54.3,
    '39.5': 54.2,
    '40.5': 54,
    '41.5': 53.9,
    '42.5': 53.8,
    '43.5': 53.7,
    '44.5': 53.6,
    '45.5': 53.5,
    '46.5': 53.4,
    '47.5': 53.3,
    '48.5': 53.2,
    '49.5': 53.1,
    '50.5': 53,
    '51.5': 52.9,
    '52.5': 52.8,
    '53.5': 52.7,
    '54.5': 52.6,
    '55.5': 52.5,
    '56.5': 52.4,
    '57.5': 52.3,
    '58.5': 52.2,
    '59.5': 52.1,
    '60.5': 51.9,
    '61.5': 51.8,
    '62.5': 51.7,
    '63.5': 51.6,
    '64.5': 51.5,
    '65.5': 51.4,
    '66.5': 51.3,
    '67.5': 51.2,
    '68.5': 51.1,
    '69.5': 51,
    '70.5': 50.9,
    '71.5': 50.8,
    '72.5': 50.7,
    '73.5': 50.6,
    '74.5': 50.5,
    '75.5': 50.4,
    '76.5': 50.3,
    '77.5': 50.2,
    '78.5': 50.1,
    '79.5': 50,
    '80.5': 49.9,
    '81.5': 49.8,
    '82.5': 49.7,
    '83.5': 49.6,
    '84.5': 49.5,
    '85.5': 49.4,
    '86.5': 49.3,
    '87.5': 49.2,
    '88.5': 49.1,
    '89.5': 49,
    '90.5': 48.9,
    '91.5': 48.8,
    '92.5': 48.7,
    '93.5': 48.7,
    '94.5': 48.6,
    '95.5': 48.5,
    '96.5': 48.4,
    '97.5': 48.3,
    '98.5': 48.2,
    '99.5': 48.1,
    '100.5': 48,
    '101.5': 47.9,
    '102.5': 47.8,
    '103.5': 47.7,
    '104.5': 47.6,
    '105.5': 47.5,
    '106.5': 47.4,
    '107.5': 47.3,
    '108.5': 47.2,
    '109.5': 47.1,
    '110.5': 47,
    '111.5': 46.9,
    '112.5': 46.8,
    '113.5': 46.8,
    '114.5': 46.7,
    '115.5': 46.6,
    '116.5': 46.5,
    '117.5': 46.4,
    '118.5': 46.3,
    '119.5': 46.2,
    '120.5': 46.1,
    '121.5': 46,
    '122.5': 45.9,
    '123.5': 45.8,
    '124.5': 45.7,
    '125.5': 45.7,
    '126.5': 45.6,
    '127.5': 45.5,
    '128.5': 45.4,
    '129.5': 45.3,
    '130.5': 45.2,
    '131.5': 45.1,
    '132.5': 45,
    '133.5': 44.9,
    '134.5': 44.8,
    '135.5': 44.7,
    '136.5': 44.7,
    '137.5': 44.6,
    '138.5': 44.5,
    '139.5': 44.4,
    '140.5': 44.3,
    '141.5': 44.2,
    '142.5': 44.1,
    '143.5': 44,
    '144.5': 44,
    '145.5': 43.9,
    '146.5': 43.8,
    '147.5': 43.7,
    '148.5': 43.6,
    '149.5': 43.5,
    '150.5': 43.4,
    '151.5': 43.3,
    '152.5': 43.3,
    '153.5': 43.2,
    '154.5': 43.1,
    '155.5': 43,
    '156.5': 42.9,
    '157.5': 42.8,
    '158.5': 42.7,
    '159.5': 42.6,
    '160.5': 42.6,
    '161.5': 42.5,
    '162.5': 42.4,
    '163.5': 42.3,
    '164.5': 42.2,
    '165.5': 42.1,
    '166.5': 42.1,
    '167.5': 42,
    '168.5': 41.9,
    '169.5': 41.8,
    '170.5': 41.7,
    '171.5': 41.6,
    '172.5': 41.5,
    '173.5': 41.5,
    '174.5': 41.4,
    '175.5': 41.3,
    '176.5': 41.2,
    '177.5': 41.1,
    '178.5': 41.1,
    '179.5': 41,
    '180.5': 40.9,
    '181.5': 40.8,
    '182.5': 40.7,
    '183.5': 40.6,
    '184.5': 40.6,
    '185.5': 40.5,
    '186.5': 40.4,
    '187.5': 40.3,
    '188.5': 40.2,
    '189.5': 40.1,
    '190.5': 40.1,
    '191.5': 40,
    '192.5': 39.9,
    '193.5': 39.8,
    '194.5': 39.7,
  },
  '52.5': {
    '0': 59.1,
    '1': 59,
    '2': 58.9,
    '3': 58.8,
    '4': 58.7,
    '5': 58.5,
    '6': 58.4,
    '7': 58.3,
    '8': 58.2,
    '9': 58.1,
    '10': 58,
    '11': 57.8,
    '12': 57.7,
    '13': 57.6,
    '14': 57.5,
    '15': 57.4,
    '16': 57.3,
    '17': 57.2,
    '18': 57.1,
    '19': 56.9,
    '20': 56.8,
    '21': 56.7,
    '22': 56.6,
    '23': 56.5,
    '24': 56.4,
    '25': 56.3,
    '26': 56.2,
    '27': 56,
    '28': 55.9,
    '29': 55.8,
    '30': 55.7,
    '31': 55.6,
    '32': 55.5,
    '33': 55.4,
    '34': 55.3,
    '35': 55.2,
    '36': 55.1,
    '37': 54.9,
    '38': 54.8,
    '39': 54.7,
    '40': 54.6,
    '41': 54.5,
    '42': 54.4,
    '43': 54.3,
    '44': 54.2,
    '45': 54.1,
    '46': 54,
    '47': 53.9,
    '48': 53.8,
    '49': 53.7,
    '50': 53.6,
    '51': 53.4,
    '52': 53.3,
    '53': 53.2,
    '54': 53.1,
    '55': 53,
    '56': 52.9,
    '57': 52.8,
    '58': 52.7,
    '59': 52.6,
    '60': 52.5,
    '61': 52.4,
    '62': 52.3,
    '63': 52.2,
    '64': 52.1,
    '65': 52,
    '66': 51.9,
    '67': 51.8,
    '68': 51.7,
    '69': 51.6,
    '70': 51.5,
    '71': 51.4,
    '72': 51.3,
    '73': 51.2,
    '74': 51.1,
    '75': 51,
    '76': 50.9,
    '77': 50.8,
    '78': 50.7,
    '79': 50.6,
    '80': 50.5,
    '81': 50.4,
    '82': 50.3,
    '83': 50.2,
    '84': 50.1,
    '85': 50,
    '86': 49.9,
    '87': 49.8,
    '88': 49.7,
    '89': 49.6,
    '90': 49.5,
    '91': 49.4,
    '92': 49.3,
    '93': 49.2,
    '94': 49.1,
    '95': 49,
    '96': 48.9,
    '97': 48.8,
    '98': 48.7,
    '99': 48.6,
    '100': 48.5,
    '101': 48.4,
    '102': 48.3,
    '103': 48.2,
    '104': 48.1,
    '105': 48,
    '106': 47.9,
    '107': 47.8,
    '108': 47.7,
    '109': 47.6,
    '110': 47.5,
    '111': 47.5,
    '112': 47.4,
    '113': 47.3,
    '114': 47.2,
    '115': 47.1,
    '116': 47,
    '117': 46.9,
    '118': 46.8,
    '119': 46.7,
    '120': 46.6,
    '121': 46.5,
    '122': 46.4,
    '123': 46.3,
    '124': 46.2,
    '125': 46.1,
    '126': 46.1,
    '127': 46,
    '128': 45.9,
    '129': 45.8,
    '130': 45.7,
    '131': 45.6,
    '132': 45.5,
    '133': 45.4,
    '134': 45.3,
    '135': 45.2,
    '136': 45.1,
    '137': 45.1,
    '138': 45,
    '139': 44.9,
    '140': 44.8,
    '141': 44.7,
    '142': 44.6,
    '143': 44.5,
    '144': 44.4,
    '145': 44.3,
    '146': 44.3,
    '147': 44.2,
    '148': 44.1,
    '149': 44,
    '150': 43.9,
    '151': 43.8,
    '152': 43.7,
    '153': 43.6,
    '154': 43.6,
    '155': 43.5,
    '156': 43.4,
    '157': 43.3,
    '158': 43.2,
    '159': 43.1,
    '160': 43,
    '161': 42.9,
    '162': 42.9,
    '163': 42.8,
    '164': 42.7,
    '165': 42.6,
    '166': 42.5,
    '167': 42.4,
    '168': 42.4,
    '169': 42.3,
    '170': 42.2,
    '171': 42.1,
    '172': 42,
    '173': 41.9,
    '174': 41.8,
    '175': 41.8,
    '176': 41.7,
    '177': 41.6,
    '178': 41.5,
    '179': 41.4,
    '180': 41.3,
    '181': 41.3,
    '182': 41.2,
    '183': 41.1,
    '184': 41,
    '185': 40.9,
    '186': 40.8,
    '187': 40.8,
    '188': 40.7,
    '189': 40.6,
    '190': 40.5,
    '191': 40.4,
    '192': 40.4,
    '193': 40.3,
    '194': 40.2,
    '195': 40.1,
    '0.5': 59.1,
    '1.5': 58.9,
    '2.5': 58.8,
    '3.5': 58.7,
    '4.5': 58.6,
    '5.5': 58.5,
    '6.5': 58.4,
    '7.5': 58.3,
    '8.5': 58.1,
    '9.5': 58,
    '10.5': 57.9,
    '11.5': 57.8,
    '12.5': 57.7,
    '13.5': 57.6,
    '14.5': 57.4,
    '15.5': 57.3,
    '16.5': 57.2,
    '17.5': 57.1,
    '18.5': 57,
    '19.5': 56.9,
    '20.5': 56.8,
    '21.5': 56.7,
    '22.5': 56.5,
    '23.5': 56.4,
    '24.5': 56.3,
    '25.5': 56.2,
    '26.5': 56.1,
    '27.5': 56,
    '28.5': 55.9,
    '29.5': 55.8,
    '30.5': 55.7,
    '31.5': 55.5,
    '32.5': 55.4,
    '33.5': 55.3,
    '34.5': 55.2,
    '35.5': 55.1,
    '36.5': 55,
    '37.5': 54.9,
    '38.5': 54.8,
    '39.5': 54.7,
    '40.5': 54.6,
    '41.5': 54.5,
    '42.5': 54.4,
    '43.5': 54.2,
    '44.5': 54.1,
    '45.5': 54,
    '46.5': 53.9,
    '47.5': 53.8,
    '48.5': 53.7,
    '49.5': 53.6,
    '50.5': 53.5,
    '51.5': 53.4,
    '52.5': 53.3,
    '53.5': 53.2,
    '54.5': 53.1,
    '55.5': 53,
    '56.5': 52.9,
    '57.5': 52.8,
    '58.5': 52.7,
    '59.5': 52.6,
    '60.5': 52.4,
    '61.5': 52.3,
    '62.5': 52.2,
    '63.5': 52.1,
    '64.5': 52,
    '65.5': 51.9,
    '66.5': 51.8,
    '67.5': 51.7,
    '68.5': 51.6,
    '69.5': 51.5,
    '70.5': 51.4,
    '71.5': 51.3,
    '72.5': 51.2,
    '73.5': 51.1,
    '74.5': 51,
    '75.5': 50.9,
    '76.5': 50.8,
    '77.5': 50.7,
    '78.5': 50.6,
    '79.5': 50.5,
    '80.5': 50.4,
    '81.5': 50.3,
    '82.5': 50.2,
    '83.5': 50.1,
    '84.5': 50,
    '85.5': 49.9,
    '86.5': 49.8,
    '87.5': 49.7,
    '88.5': 49.6,
    '89.5': 49.5,
    '90.5': 49.4,
    '91.5': 49.3,
    '92.5': 49.2,
    '93.5': 49.1,
    '94.5': 49,
    '95.5': 48.9,
    '96.5': 48.8,
    '97.5': 48.7,
    '98.5': 48.6,
    '99.5': 48.5,
    '100.5': 48.5,
    '101.5': 48.4,
    '102.5': 48.3,
    '103.5': 48.2,
    '104.5': 48.1,
    '105.5': 48,
    '106.5': 47.9,
    '107.5': 47.8,
    '108.5': 47.7,
    '109.5': 47.6,
    '110.5': 47.5,
    '111.5': 47.4,
    '112.5': 47.3,
    '113.5': 47.2,
    '114.5': 47.1,
    '115.5': 47,
    '116.5': 46.9,
    '117.5': 46.8,
    '118.5': 46.7,
    '119.5': 46.7,
    '120.5': 46.6,
    '121.5': 46.5,
    '122.5': 46.4,
    '123.5': 46.3,
    '124.5': 46.2,
    '125.5': 46.1,
    '126.5': 46,
    '127.5': 45.9,
    '128.5': 45.8,
    '129.5': 45.7,
    '130.5': 45.6,
    '131.5': 45.6,
    '132.5': 45.5,
    '133.5': 45.4,
    '134.5': 45.3,
    '135.5': 45.2,
    '136.5': 45.1,
    '137.5': 45,
    '138.5': 44.9,
    '139.5': 44.8,
    '140.5': 44.7,
    '141.5': 44.7,
    '142.5': 44.6,
    '143.5': 44.5,
    '144.5': 44.4,
    '145.5': 44.3,
    '146.5': 44.2,
    '147.5': 44.1,
    '148.5': 44,
    '149.5': 43.9,
    '150.5': 43.9,
    '151.5': 43.8,
    '152.5': 43.7,
    '153.5': 43.6,
    '154.5': 43.5,
    '155.5': 43.4,
    '156.5': 43.3,
    '157.5': 43.2,
    '158.5': 43.2,
    '159.5': 43.1,
    '160.5': 43,
    '161.5': 42.9,
    '162.5': 42.8,
    '163.5': 42.7,
    '164.5': 42.6,
    '165.5': 42.6,
    '166.5': 42.5,
    '167.5': 42.4,
    '168.5': 42.3,
    '169.5': 42.2,
    '170.5': 42.1,
    '171.5': 42.1,
    '172.5': 42,
    '173.5': 41.9,
    '174.5': 41.8,
    '175.5': 41.7,
    '176.5': 41.6,
    '177.5': 41.5,
    '178.5': 41.5,
    '179.5': 41.4,
    '180.5': 41.3,
    '181.5': 41.2,
    '182.5': 41.1,
    '183.5': 41.1,
    '184.5': 41,
    '185.5': 40.9,
    '186.5': 40.8,
    '187.5': 40.7,
    '188.5': 40.6,
    '189.5': 40.6,
    '190.5': 40.5,
    '191.5': 40.4,
    '192.5': 40.3,
    '193.5': 40.2,
    '194.5': 40.1,
  },
  '53': {
    '0': 59.7,
    '1': 59.6,
    '2': 59.4,
    '3': 59.3,
    '4': 59.2,
    '5': 59.1,
    '6': 59,
    '7': 58.9,
    '8': 58.7,
    '9': 58.6,
    '10': 58.5,
    '11': 58.4,
    '12': 58.3,
    '13': 58.2,
    '14': 58,
    '15': 57.9,
    '16': 57.8,
    '17': 57.7,
    '18': 57.6,
    '19': 57.5,
    '20': 57.4,
    '21': 57.3,
    '22': 57.1,
    '23': 57,
    '24': 56.9,
    '25': 56.8,
    '26': 56.7,
    '27': 56.6,
    '28': 56.4,
    '29': 56.4,
    '30': 56.2,
    '31': 56.1,
    '32': 56,
    '33': 55.9,
    '34': 55.8,
    '35': 55.7,
    '36': 55.6,
    '37': 55.5,
    '38': 55.4,
    '39': 55.2,
    '40': 55.1,
    '41': 55,
    '42': 54.9,
    '43': 54.8,
    '44': 54.7,
    '45': 54.6,
    '46': 54.5,
    '47': 54.4,
    '48': 54.3,
    '49': 54.2,
    '50': 54.1,
    '51': 54,
    '52': 53.8,
    '53': 53.7,
    '54': 53.6,
    '55': 53.5,
    '56': 53.4,
    '57': 53.3,
    '58': 53.2,
    '59': 53.1,
    '60': 53,
    '61': 52.9,
    '62': 52.8,
    '63': 52.7,
    '64': 52.6,
    '65': 52.5,
    '66': 52.4,
    '67': 52.3,
    '68': 52.2,
    '69': 52.1,
    '70': 52,
    '71': 51.9,
    '72': 51.8,
    '73': 51.7,
    '74': 51.6,
    '75': 51.5,
    '76': 51.3,
    '77': 51.2,
    '78': 51.1,
    '79': 51,
    '80': 50.9,
    '81': 50.8,
    '82': 50.7,
    '83': 50.6,
    '84': 50.5,
    '85': 50.4,
    '86': 50.3,
    '87': 50.2,
    '88': 50.1,
    '89': 50,
    '90': 49.9,
    '91': 49.8,
    '92': 49.7,
    '93': 49.6,
    '94': 49.5,
    '95': 49.5,
    '96': 49.4,
    '97': 49.3,
    '98': 49.2,
    '99': 49.1,
    '100': 49,
    '101': 48.9,
    '102': 48.8,
    '103': 48.7,
    '104': 48.6,
    '105': 48.5,
    '106': 48.4,
    '107': 48.3,
    '108': 48.2,
    '109': 48.1,
    '110': 48,
    '111': 47.9,
    '112': 47.8,
    '113': 47.7,
    '114': 47.6,
    '115': 47.5,
    '116': 47.4,
    '117': 47.3,
    '118': 47.2,
    '119': 47.2,
    '120': 47.1,
    '121': 47,
    '122': 46.9,
    '123': 46.8,
    '124': 46.7,
    '125': 46.6,
    '126': 46.5,
    '127': 46.4,
    '128': 46.3,
    '129': 46.2,
    '130': 46.1,
    '131': 46,
    '132': 46,
    '133': 45.9,
    '134': 45.8,
    '135': 45.7,
    '136': 45.6,
    '137': 45.5,
    '138': 45.4,
    '139': 45.3,
    '140': 45.2,
    '141': 45.1,
    '142': 45,
    '143': 45,
    '144': 44.9,
    '145': 44.8,
    '146': 44.7,
    '147': 44.6,
    '148': 44.5,
    '149': 44.4,
    '150': 44.3,
    '151': 44.2,
    '152': 44.2,
    '153': 44.1,
    '154': 44,
    '155': 43.9,
    '156': 43.8,
    '157': 43.7,
    '158': 43.6,
    '159': 43.5,
    '160': 43.5,
    '161': 43.4,
    '162': 43.3,
    '163': 43.2,
    '164': 43.1,
    '165': 43,
    '166': 42.9,
    '167': 42.9,
    '168': 42.8,
    '169': 42.7,
    '170': 42.6,
    '171': 42.5,
    '172': 42.4,
    '173': 42.3,
    '174': 42.3,
    '175': 42.2,
    '176': 42.1,
    '177': 42,
    '178': 41.9,
    '179': 41.8,
    '180': 41.8,
    '181': 41.7,
    '182': 41.6,
    '183': 41.5,
    '184': 41.4,
    '185': 41.3,
    '186': 41.3,
    '187': 41.2,
    '188': 41.1,
    '189': 41,
    '190': 40.9,
    '191': 40.8,
    '192': 40.8,
    '193': 40.7,
    '194': 40.6,
    '195': 40.5,
    '0.5': 59.6,
    '1.5': 59.5,
    '2.5': 59.4,
    '3.5': 59.3,
    '4.5': 59.2,
    '5.5': 59,
    '6.5': 58.9,
    '7.5': 58.8,
    '8.5': 58.7,
    '9.5': 58.6,
    '10.5': 58.5,
    '11.5': 58.3,
    '12.5': 58.2,
    '13.5': 58.1,
    '14.5': 58,
    '15.5': 57.9,
    '16.5': 57.8,
    '17.5': 57.6,
    '18.5': 57.5,
    '19.5': 57.4,
    '20.5': 57.3,
    '21.5': 57.2,
    '22.5': 57.1,
    '23.5': 57,
    '24.5': 56.9,
    '25.5': 56.7,
    '26.5': 56.6,
    '27.5': 56.5,
    '28.5': 56.4,
    '29.5': 56.3,
    '30.5': 56.2,
    '31.5': 56.1,
    '32.5': 56,
    '33.5': 55.9,
    '34.5': 55.7,
    '35.5': 55.6,
    '36.5': 55.5,
    '37.5': 55.4,
    '38.5': 55.3,
    '39.5': 55.2,
    '40.5': 55.1,
    '41.5': 55,
    '42.5': 54.9,
    '43.5': 54.8,
    '44.5': 54.6,
    '45.5': 54.5,
    '46.5': 54.4,
    '47.5': 54.3,
    '48.5': 54.2,
    '49.5': 54.1,
    '50.5': 54,
    '51.5': 53.9,
    '52.5': 53.8,
    '53.5': 53.7,
    '54.5': 53.6,
    '55.5': 53.5,
    '56.5': 53.4,
    '57.5': 53.3,
    '58.5': 53.2,
    '59.5': 53.1,
    '60.5': 52.9,
    '61.5': 52.8,
    '62.5': 52.7,
    '63.5': 52.6,
    '64.5': 52.5,
    '65.5': 52.4,
    '66.5': 52.3,
    '67.5': 52.2,
    '68.5': 52.1,
    '69.5': 52,
    '70.5': 51.9,
    '71.5': 51.8,
    '72.5': 51.7,
    '73.5': 51.6,
    '74.5': 51.5,
    '75.5': 51.4,
    '76.5': 51.3,
    '77.5': 51.2,
    '78.5': 51.1,
    '79.5': 51,
    '80.5': 50.9,
    '81.5': 50.8,
    '82.5': 50.7,
    '83.5': 50.6,
    '84.5': 50.5,
    '85.5': 50.4,
    '86.5': 50.3,
    '87.5': 50.2,
    '88.5': 50.1,
    '89.5': 50,
    '90.5': 49.9,
    '91.5': 49.8,
    '92.5': 49.7,
    '93.5': 49.6,
    '94.5': 49.5,
    '95.5': 49.4,
    '96.5': 49.3,
    '97.5': 49.2,
    '98.5': 49.1,
    '99.5': 49,
    '100.5': 48.9,
    '101.5': 48.8,
    '102.5': 48.7,
    '103.5': 48.6,
    '104.5': 48.5,
    '105.5': 48.4,
    '106.5': 48.3,
    '107.5': 48.2,
    '108.5': 48.1,
    '109.5': 48.1,
    '110.5': 48,
    '111.5': 47.9,
    '112.5': 47.8,
    '113.5': 47.7,
    '114.5': 47.6,
    '115.5': 47.5,
    '116.5': 47.4,
    '117.5': 47.3,
    '118.5': 47.2,
    '119.5': 47.1,
    '120.5': 47,
    '121.5': 46.9,
    '122.5': 46.8,
    '123.5': 46.7,
    '124.5': 46.6,
    '125.5': 46.5,
    '126.5': 46.5,
    '127.5': 46.4,
    '128.5': 46.3,
    '129.5': 46.2,
    '130.5': 46.1,
    '131.5': 46,
    '132.5': 45.9,
    '133.5': 45.8,
    '134.5': 45.7,
    '135.5': 45.6,
    '136.5': 45.5,
    '137.5': 45.5,
    '138.5': 45.4,
    '139.5': 45.3,
    '140.5': 45.2,
    '141.5': 45.1,
    '142.5': 45,
    '143.5': 44.9,
    '144.5': 44.8,
    '145.5': 44.7,
    '146.5': 44.6,
    '147.5': 44.6,
    '148.5': 44.5,
    '149.5': 44.4,
    '150.5': 44.3,
    '151.5': 44.2,
    '152.5': 44.1,
    '153.5': 44,
    '154.5': 43.9,
    '155.5': 43.9,
    '156.5': 43.8,
    '157.5': 43.7,
    '158.5': 43.6,
    '159.5': 43.5,
    '160.5': 43.4,
    '161.5': 43.3,
    '162.5': 43.2,
    '163.5': 43.2,
    '164.5': 43.1,
    '165.5': 43,
    '166.5': 42.9,
    '167.5': 42.8,
    '168.5': 42.7,
    '169.5': 42.6,
    '170.5': 42.6,
    '171.5': 42.5,
    '172.5': 42.4,
    '173.5': 42.3,
    '174.5': 42.2,
    '175.5': 42.1,
    '176.5': 42.1,
    '177.5': 42,
    '178.5': 41.9,
    '179.5': 41.8,
    '180.5': 41.7,
    '181.5': 41.6,
    '182.5': 41.5,
    '183.5': 41.5,
    '184.5': 41.4,
    '185.5': 41.3,
    '186.5': 41.2,
    '187.5': 41.1,
    '188.5': 41.1,
    '189.5': 41,
    '190.5': 40.9,
    '191.5': 40.8,
    '192.5': 40.7,
    '193.5': 40.6,
    '194.5': 40.6,
  },
  '53.5': {
    '0': 60.2,
    '1': 60.1,
    '2': 60,
    '3': 59.9,
    '4': 59.8,
    '5': 59.6,
    '6': 59.5,
    '7': 59.4,
    '8': 59.3,
    '9': 59.2,
    '10': 59.1,
    '11': 58.9,
    '12': 58.8,
    '13': 58.7,
    '14': 58.6,
    '15': 58.5,
    '16': 58.4,
    '17': 58.2,
    '18': 58.1,
    '19': 58,
    '20': 57.9,
    '21': 57.8,
    '22': 57.7,
    '23': 57.6,
    '24': 57.4,
    '25': 57.3,
    '26': 57.2,
    '27': 57.1,
    '28': 57,
    '29': 56.9,
    '30': 56.8,
    '31': 56.7,
    '32': 56.6,
    '33': 56.5,
    '34': 56.3,
    '35': 56.2,
    '36': 56.1,
    '37': 56,
    '38': 55.9,
    '39': 55.8,
    '40': 55.7,
    '41': 55.5,
    '42': 55.4,
    '43': 55.3,
    '44': 55.2,
    '45': 55.1,
    '46': 55,
    '47': 54.9,
    '48': 54.8,
    '49': 54.7,
    '50': 54.6,
    '51': 54.5,
    '52': 54.4,
    '53': 54.2,
    '54': 54.1,
    '55': 54,
    '56': 53.9,
    '57': 53.8,
    '58': 53.7,
    '59': 53.6,
    '60': 53.5,
    '61': 53.4,
    '62': 53.3,
    '63': 53.2,
    '64': 53.1,
    '65': 53,
    '66': 52.9,
    '67': 52.8,
    '68': 52.7,
    '69': 52.6,
    '70': 52.5,
    '71': 52.3,
    '72': 52.2,
    '73': 52.1,
    '74': 52,
    '75': 51.9,
    '76': 51.8,
    '77': 51.7,
    '78': 51.6,
    '79': 51.5,
    '80': 51.4,
    '81': 51.3,
    '82': 51.2,
    '83': 51.1,
    '84': 51,
    '85': 50.9,
    '86': 50.8,
    '87': 50.7,
    '88': 50.6,
    '89': 50.5,
    '90': 50.4,
    '91': 50.3,
    '92': 50.2,
    '93': 50.1,
    '94': 50,
    '95': 49.9,
    '96': 49.8,
    '97': 49.7,
    '98': 49.6,
    '99': 49.5,
    '100': 49.4,
    '101': 49.3,
    '102': 49.2,
    '103': 49.1,
    '104': 49,
    '105': 48.9,
    '106': 48.9,
    '107': 48.8,
    '108': 48.7,
    '109': 48.6,
    '110': 48.5,
    '111': 48.4,
    '112': 48.3,
    '113': 48.2,
    '114': 48.1,
    '115': 48,
    '116': 47.9,
    '117': 47.8,
    '118': 47.7,
    '119': 47.6,
    '120': 47.5,
    '121': 47.4,
    '122': 47.3,
    '123': 47.2,
    '124': 47.1,
    '125': 47,
    '126': 47,
    '127': 46.9,
    '128': 46.8,
    '129': 46.7,
    '130': 46.6,
    '131': 46.5,
    '132': 46.4,
    '133': 46.3,
    '134': 46.2,
    '135': 46.1,
    '136': 46,
    '137': 45.9,
    '138': 45.8,
    '139': 45.8,
    '140': 45.7,
    '141': 45.6,
    '142': 45.5,
    '143': 45.4,
    '144': 45.3,
    '145': 45.2,
    '146': 45.1,
    '147': 45,
    '148': 44.9,
    '149': 44.9,
    '150': 44.8,
    '151': 44.7,
    '152': 44.6,
    '153': 44.5,
    '154': 44.4,
    '155': 44.3,
    '156': 44.2,
    '157': 44.1,
    '158': 44.1,
    '159': 44,
    '160': 43.9,
    '161': 43.8,
    '162': 43.7,
    '163': 43.6,
    '164': 43.5,
    '165': 43.5,
    '166': 43.4,
    '167': 43.3,
    '168': 43.2,
    '169': 43.1,
    '170': 43,
    '171': 42.9,
    '172': 42.8,
    '173': 42.8,
    '174': 42.7,
    '175': 42.6,
    '176': 42.5,
    '177': 42.4,
    '178': 42.3,
    '179': 42.3,
    '180': 42.2,
    '181': 42.1,
    '182': 42,
    '183': 41.9,
    '184': 41.8,
    '185': 41.7,
    '186': 41.7,
    '187': 41.6,
    '188': 41.5,
    '189': 41.4,
    '190': 41.3,
    '191': 41.2,
    '192': 41.2,
    '193': 41.1,
    '194': 41,
    '195': 40.9,
    '0.5': 60.2,
    '1.5': 60.1,
    '2.5': 59.9,
    '3.5': 59.8,
    '4.5': 59.7,
    '5.5': 59.6,
    '6.5': 59.5,
    '7.5': 59.3,
    '8.5': 59.2,
    '9.5': 59.1,
    '10.5': 59,
    '11.5': 58.9,
    '12.5': 58.8,
    '13.5': 58.7,
    '14.5': 58.5,
    '15.5': 58.4,
    '16.5': 58.3,
    '17.5': 58.2,
    '18.5': 58.1,
    '19.5': 58,
    '20.5': 57.8,
    '21.5': 57.7,
    '22.5': 57.6,
    '23.5': 57.5,
    '24.5': 57.4,
    '25.5': 57.3,
    '26.5': 57.2,
    '27.5': 57,
    '28.5': 56.9,
    '29.5': 56.8,
    '30.5': 56.7,
    '31.5': 56.6,
    '32.5': 56.5,
    '33.5': 56.4,
    '34.5': 56.3,
    '35.5': 56.2,
    '36.5': 56.1,
    '37.5': 56,
    '38.5': 55.8,
    '39.5': 55.7,
    '40.5': 55.6,
    '41.5': 55.5,
    '42.5': 55.4,
    '43.5': 55.3,
    '44.5': 55.2,
    '45.5': 55.1,
    '46.5': 54.9,
    '47.5': 54.8,
    '48.5': 54.7,
    '49.5': 54.6,
    '50.5': 54.5,
    '51.5': 54.4,
    '52.5': 54.3,
    '53.5': 54.2,
    '54.5': 54.1,
    '55.5': 54,
    '56.5': 53.9,
    '57.5': 53.8,
    '58.5': 53.7,
    '59.5': 53.6,
    '60.5': 53.4,
    '61.5': 53.3,
    '62.5': 53.2,
    '63.5': 53.1,
    '64.5': 53,
    '65.5': 52.9,
    '66.5': 52.8,
    '67.5': 52.7,
    '68.5': 52.6,
    '69.5': 52.5,
    '70.5': 52.4,
    '71.5': 52.3,
    '72.5': 52.2,
    '73.5': 52.1,
    '74.5': 52,
    '75.5': 51.9,
    '76.5': 51.8,
    '77.5': 51.7,
    '78.5': 51.6,
    '79.5': 51.5,
    '80.5': 51.4,
    '81.5': 51.3,
    '82.5': 51.2,
    '83.5': 51.1,
    '84.5': 51,
    '85.5': 50.9,
    '86.5': 50.8,
    '87.5': 50.7,
    '88.5': 50.6,
    '89.5': 50.5,
    '90.5': 50.4,
    '91.5': 50.3,
    '92.5': 50.2,
    '93.5': 50.1,
    '94.5': 50,
    '95.5': 49.9,
    '96.5': 49.8,
    '97.5': 49.7,
    '98.5': 49.6,
    '99.5': 49.5,
    '100.5': 49.4,
    '101.5': 49.3,
    '102.5': 49.2,
    '103.5': 49.1,
    '104.5': 49,
    '105.5': 48.9,
    '106.5': 48.8,
    '107.5': 48.7,
    '108.5': 48.6,
    '109.5': 48.5,
    '110.5': 48.4,
    '111.5': 48.3,
    '112.5': 48.2,
    '113.5': 48.1,
    '114.5': 48,
    '115.5': 47.9,
    '116.5': 47.8,
    '117.5': 47.8,
    '118.5': 47.7,
    '119.5': 47.6,
    '120.5': 47.5,
    '121.5': 47.4,
    '122.5': 47.3,
    '123.5': 47.2,
    '124.5': 47.1,
    '125.5': 47,
    '126.5': 46.9,
    '127.5': 46.8,
    '128.5': 46.7,
    '129.5': 46.6,
    '130.5': 46.5,
    '131.5': 46.4,
    '132.5': 46.3,
    '133.5': 46.3,
    '134.5': 46.2,
    '135.5': 46.1,
    '136.5': 46,
    '137.5': 45.9,
    '138.5': 45.8,
    '139.5': 45.7,
    '140.5': 45.6,
    '141.5': 45.5,
    '142.5': 45.4,
    '143.5': 45.4,
    '144.5': 45.3,
    '145.5': 45.2,
    '146.5': 45.1,
    '147.5': 45,
    '148.5': 44.9,
    '149.5': 44.8,
    '150.5': 44.7,
    '151.5': 44.6,
    '152.5': 44.5,
    '153.5': 44.5,
    '154.5': 44.4,
    '155.5': 44.3,
    '156.5': 44.2,
    '157.5': 44.1,
    '158.5': 44,
    '159.5': 43.9,
    '160.5': 43.8,
    '161.5': 43.8,
    '162.5': 43.7,
    '163.5': 43.6,
    '164.5': 43.5,
    '165.5': 43.4,
    '166.5': 43.3,
    '167.5': 43.2,
    '168.5': 43.1,
    '169.5': 43.1,
    '170.5': 43,
    '171.5': 42.9,
    '172.5': 42.8,
    '173.5': 42.7,
    '174.5': 42.6,
    '175.5': 42.5,
    '176.5': 42.5,
    '177.5': 42.4,
    '178.5': 42.3,
    '179.5': 42.2,
    '180.5': 42.1,
    '181.5': 42,
    '182.5': 42,
    '183.5': 41.9,
    '184.5': 41.8,
    '185.5': 41.7,
    '186.5': 41.6,
    '187.5': 41.5,
    '188.5': 41.5,
    '189.5': 41.4,
    '190.5': 41.3,
    '191.5': 41.2,
    '192.5': 41.1,
    '193.5': 41,
    '194.5': 41,
  },
  '54': {
    '0': 60.8,
    '1': 60.7,
    '2': 60.6,
    '3': 60.4,
    '4': 60.3,
    '5': 60.2,
    '6': 60.1,
    '7': 60,
    '8': 59.8,
    '9': 59.7,
    '10': 59.6,
    '11': 59.5,
    '12': 59.4,
    '13': 59.3,
    '14': 59.1,
    '15': 59,
    '16': 58.9,
    '17': 58.8,
    '18': 58.7,
    '19': 58.6,
    '20': 58.4,
    '21': 58.3,
    '22': 58.2,
    '23': 58.1,
    '24': 58,
    '25': 57.9,
    '26': 57.7,
    '27': 57.6,
    '28': 57.5,
    '29': 57.4,
    '30': 57.3,
    '31': 57.2,
    '32': 57.1,
    '33': 57,
    '34': 56.8,
    '35': 56.7,
    '36': 56.6,
    '37': 56.5,
    '38': 56.4,
    '39': 56.3,
    '40': 56.2,
    '41': 56.1,
    '42': 56,
    '43': 55.8,
    '44': 55.7,
    '45': 55.6,
    '46': 55.5,
    '47': 55.4,
    '48': 55.3,
    '49': 55.2,
    '50': 55.1,
    '51': 55,
    '52': 54.9,
    '53': 54.8,
    '54': 54.6,
    '55': 54.5,
    '56': 54.4,
    '57': 54.3,
    '58': 54.2,
    '59': 54.1,
    '60': 54,
    '61': 53.9,
    '62': 53.8,
    '63': 53.7,
    '64': 53.6,
    '65': 53.5,
    '66': 53.4,
    '67': 53.3,
    '68': 53.2,
    '69': 53.1,
    '70': 52.9,
    '71': 52.8,
    '72': 52.7,
    '73': 52.6,
    '74': 52.5,
    '75': 52.4,
    '76': 52.3,
    '77': 52.2,
    '78': 52.1,
    '79': 52,
    '80': 51.9,
    '81': 51.8,
    '82': 51.7,
    '83': 51.6,
    '84': 51.5,
    '85': 51.4,
    '86': 51.3,
    '87': 51.2,
    '88': 51.1,
    '89': 51,
    '90': 50.9,
    '91': 50.8,
    '92': 50.7,
    '93': 50.6,
    '94': 50.5,
    '95': 50.4,
    '96': 50.3,
    '97': 50.2,
    '98': 50.1,
    '99': 50,
    '100': 49.9,
    '101': 49.8,
    '102': 49.7,
    '103': 49.6,
    '104': 49.5,
    '105': 49.4,
    '106': 49.3,
    '107': 49.2,
    '108': 49.1,
    '109': 49,
    '110': 48.9,
    '111': 48.8,
    '112': 48.7,
    '113': 48.6,
    '114': 48.5,
    '115': 48.4,
    '116': 48.3,
    '117': 48.3,
    '118': 48.2,
    '119': 48.1,
    '120': 48,
    '121': 47.9,
    '122': 47.8,
    '123': 47.7,
    '124': 47.6,
    '125': 47.5,
    '126': 47.4,
    '127': 47.3,
    '128': 47.2,
    '129': 47.1,
    '130': 47,
    '131': 46.9,
    '132': 46.8,
    '133': 46.7,
    '134': 46.7,
    '135': 46.6,
    '136': 46.5,
    '137': 46.4,
    '138': 46.3,
    '139': 46.2,
    '140': 46.1,
    '141': 46,
    '142': 45.9,
    '143': 45.8,
    '144': 45.7,
    '145': 45.6,
    '146': 45.6,
    '147': 45.5,
    '148': 45.4,
    '149': 45.3,
    '150': 45.2,
    '151': 45.1,
    '152': 45,
    '153': 44.9,
    '154': 44.8,
    '155': 44.8,
    '156': 44.7,
    '157': 44.6,
    '158': 44.5,
    '159': 44.4,
    '160': 44.3,
    '161': 44.2,
    '162': 44.1,
    '163': 44,
    '164': 44,
    '165': 43.9,
    '166': 43.8,
    '167': 43.7,
    '168': 43.6,
    '169': 43.5,
    '170': 43.4,
    '171': 43.4,
    '172': 43.3,
    '173': 43.2,
    '174': 43.1,
    '175': 43,
    '176': 42.9,
    '177': 42.8,
    '178': 42.7,
    '179': 42.7,
    '180': 42.6,
    '181': 42.5,
    '182': 42.4,
    '183': 42.3,
    '184': 42.2,
    '185': 42.2,
    '186': 42.1,
    '187': 42,
    '188': 41.9,
    '189': 41.8,
    '190': 41.7,
    '191': 41.7,
    '192': 41.6,
    '193': 41.5,
    '194': 41.4,
    '195': 41.3,
    '0.5': 60.7,
    '1.5': 60.6,
    '2.5': 60.5,
    '3.5': 60.4,
    '4.5': 60.3,
    '5.5': 60.1,
    '6.5': 60,
    '7.5': 59.9,
    '8.5': 59.8,
    '9.5': 59.7,
    '10.5': 59.5,
    '11.5': 59.4,
    '12.5': 59.3,
    '13.5': 59.2,
    '14.5': 59.1,
    '15.5': 59,
    '16.5': 58.8,
    '17.5': 58.7,
    '18.5': 58.6,
    '19.5': 58.5,
    '20.5': 58.4,
    '21.5': 58.3,
    '22.5': 58.2,
    '23.5': 58,
    '24.5': 57.9,
    '25.5': 57.8,
    '26.5': 57.7,
    '27.5': 57.6,
    '28.5': 57.5,
    '29.5': 57.4,
    '30.5': 57.2,
    '31.5': 57.1,
    '32.5': 57,
    '33.5': 56.9,
    '34.5': 56.8,
    '35.5': 56.7,
    '36.5': 56.6,
    '37.5': 56.5,
    '38.5': 56.3,
    '39.5': 56.2,
    '40.5': 56.1,
    '41.5': 56,
    '42.5': 55.9,
    '43.5': 55.8,
    '44.5': 55.7,
    '45.5': 55.6,
    '46.5': 55.5,
    '47.5': 55.3,
    '48.5': 55.2,
    '49.5': 55.1,
    '50.5': 55,
    '51.5': 54.9,
    '52.5': 54.8,
    '53.5': 54.7,
    '54.5': 54.6,
    '55.5': 54.5,
    '56.5': 54.4,
    '57.5': 54.3,
    '58.5': 54.2,
    '59.5': 54.1,
    '60.5': 53.9,
    '61.5': 53.8,
    '62.5': 53.7,
    '63.5': 53.6,
    '64.5': 53.5,
    '65.5': 53.4,
    '66.5': 53.3,
    '67.5': 53.2,
    '68.5': 53.1,
    '69.5': 53,
    '70.5': 52.9,
    '71.5': 52.8,
    '72.5': 52.7,
    '73.5': 52.6,
    '74.5': 52.5,
    '75.5': 52.4,
    '76.5': 52.3,
    '77.5': 52.2,
    '78.5': 52.1,
    '79.5': 52,
    '80.5': 51.9,
    '81.5': 51.8,
    '82.5': 51.7,
    '83.5': 51.6,
    '84.5': 51.5,
    '85.5': 51.3,
    '86.5': 51.2,
    '87.5': 51.1,
    '88.5': 51,
    '89.5': 50.9,
    '90.5': 50.8,
    '91.5': 50.7,
    '92.5': 50.6,
    '93.5': 50.5,
    '94.5': 50.4,
    '95.5': 50.3,
    '96.5': 50.2,
    '97.5': 50.1,
    '98.5': 50,
    '99.5': 49.9,
    '100.5': 49.8,
    '101.5': 49.8,
    '102.5': 49.7,
    '103.5': 49.6,
    '104.5': 49.5,
    '105.5': 49.4,
    '106.5': 49.3,
    '107.5': 49.2,
    '108.5': 49.1,
    '109.5': 49,
    '110.5': 48.9,
    '111.5': 48.8,
    '112.5': 48.7,
    '113.5': 48.6,
    '114.5': 48.5,
    '115.5': 48.4,
    '116.5': 48.3,
    '117.5': 48.2,
    '118.5': 48.1,
    '119.5': 48,
    '120.5': 47.9,
    '121.5': 47.8,
    '122.5': 47.7,
    '123.5': 47.6,
    '124.5': 47.5,
    '125.5': 47.4,
    '126.5': 47.4,
    '127.5': 47.3,
    '128.5': 47.2,
    '129.5': 47.1,
    '130.5': 47,
    '131.5': 46.9,
    '132.5': 46.8,
    '133.5': 46.7,
    '134.5': 46.6,
    '135.5': 46.5,
    '136.5': 46.4,
    '137.5': 46.3,
    '138.5': 46.2,
    '139.5': 46.1,
    '140.5': 46.1,
    '141.5': 46,
    '142.5': 45.9,
    '143.5': 45.8,
    '144.5': 45.7,
    '145.5': 45.6,
    '146.5': 45.5,
    '147.5': 45.4,
    '148.5': 45.3,
    '149.5': 45.2,
    '150.5': 45.2,
    '151.5': 45.1,
    '152.5': 45,
    '153.5': 44.9,
    '154.5': 44.8,
    '155.5': 44.7,
    '156.5': 44.6,
    '157.5': 44.5,
    '158.5': 44.4,
    '159.5': 44.4,
    '160.5': 44.3,
    '161.5': 44.2,
    '162.5': 44.1,
    '163.5': 44,
    '164.5': 43.9,
    '165.5': 43.8,
    '166.5': 43.7,
    '167.5': 43.7,
    '168.5': 43.6,
    '169.5': 43.5,
    '170.5': 43.4,
    '171.5': 43.3,
    '172.5': 43.2,
    '173.5': 43.1,
    '174.5': 43.1,
    '175.5': 43,
    '176.5': 42.9,
    '177.5': 42.8,
    '178.5': 42.7,
    '179.5': 42.6,
    '180.5': 42.5,
    '181.5': 42.5,
    '182.5': 42.4,
    '183.5': 42.3,
    '184.5': 42.2,
    '185.5': 42.1,
    '186.5': 42,
    '187.5': 41.9,
    '188.5': 41.9,
    '189.5': 41.8,
    '190.5': 41.7,
    '191.5': 41.6,
    '192.5': 41.5,
    '193.5': 41.4,
    '194.5': 41.4,
  },
  '54.5': {
    '0': 61.4,
    '1': 61.2,
    '2': 61.1,
    '3': 61,
    '4': 60.9,
    '5': 60.8,
    '6': 60.6,
    '7': 60.5,
    '8': 60.4,
    '9': 60.3,
    '10': 60.2,
    '11': 60,
    '12': 59.9,
    '13': 59.8,
    '14': 59.7,
    '15': 59.6,
    '16': 59.4,
    '17': 59.3,
    '18': 59.2,
    '19': 59.1,
    '20': 59,
    '21': 58.9,
    '22': 58.7,
    '23': 58.6,
    '24': 58.5,
    '25': 58.4,
    '26': 58.3,
    '27': 58.2,
    '28': 58.1,
    '29': 57.9,
    '30': 57.8,
    '31': 57.7,
    '32': 57.6,
    '33': 57.5,
    '34': 57.4,
    '35': 57.3,
    '36': 57.1,
    '37': 57,
    '38': 56.9,
    '39': 56.8,
    '40': 56.7,
    '41': 56.6,
    '42': 56.5,
    '43': 56.4,
    '44': 56.2,
    '45': 56.1,
    '46': 56,
    '47': 55.9,
    '48': 55.8,
    '49': 55.7,
    '50': 55.6,
    '51': 55.5,
    '52': 55.4,
    '53': 55.3,
    '54': 55.1,
    '55': 55,
    '56': 54.9,
    '57': 54.8,
    '58': 54.7,
    '59': 54.6,
    '60': 54.5,
    '61': 54.4,
    '62': 54.3,
    '63': 54.2,
    '64': 54.1,
    '65': 54,
    '66': 53.9,
    '67': 53.8,
    '68': 53.6,
    '69': 53.5,
    '70': 53.4,
    '71': 53.3,
    '72': 53.2,
    '73': 53.1,
    '74': 53,
    '75': 52.9,
    '76': 52.8,
    '77': 52.7,
    '78': 52.6,
    '79': 52.5,
    '80': 52.4,
    '81': 52.3,
    '82': 52.2,
    '83': 52.1,
    '84': 52,
    '85': 51.9,
    '86': 51.8,
    '87': 51.7,
    '88': 51.6,
    '89': 51.5,
    '90': 51.4,
    '91': 51.3,
    '92': 51.2,
    '93': 51.1,
    '94': 51,
    '95': 50.9,
    '96': 50.8,
    '97': 50.7,
    '98': 50.6,
    '99': 50.5,
    '100': 50.4,
    '101': 50.3,
    '102': 50.2,
    '103': 50.1,
    '104': 50,
    '105': 49.9,
    '106': 49.8,
    '107': 49.7,
    '108': 49.6,
    '109': 49.5,
    '110': 49.4,
    '111': 49.3,
    '112': 49.2,
    '113': 49.1,
    '114': 49,
    '115': 48.9,
    '116': 48.8,
    '117': 48.7,
    '118': 48.6,
    '119': 48.5,
    '120': 48.4,
    '121': 48.3,
    '122': 48.2,
    '123': 48.1,
    '124': 48,
    '125': 47.9,
    '126': 47.8,
    '127': 47.8,
    '128': 47.7,
    '129': 47.6,
    '130': 47.5,
    '131': 47.4,
    '132': 47.3,
    '133': 47.2,
    '134': 47.1,
    '135': 47,
    '136': 46.9,
    '137': 46.8,
    '138': 46.7,
    '139': 46.6,
    '140': 46.5,
    '141': 46.5,
    '142': 46.4,
    '143': 46.3,
    '144': 46.2,
    '145': 46.1,
    '146': 46,
    '147': 45.9,
    '148': 45.8,
    '149': 45.7,
    '150': 45.6,
    '151': 45.5,
    '152': 45.5,
    '153': 45.4,
    '154': 45.3,
    '155': 45.2,
    '156': 45.1,
    '157': 45,
    '158': 44.9,
    '159': 44.8,
    '160': 44.7,
    '161': 44.6,
    '162': 44.6,
    '163': 44.5,
    '164': 44.4,
    '165': 44.3,
    '166': 44.2,
    '167': 44.1,
    '168': 44,
    '169': 43.9,
    '170': 43.9,
    '171': 43.8,
    '172': 43.7,
    '173': 43.6,
    '174': 43.5,
    '175': 43.4,
    '176': 43.3,
    '177': 43.2,
    '178': 43.2,
    '179': 43.1,
    '180': 43,
    '181': 42.9,
    '182': 42.8,
    '183': 42.7,
    '184': 42.7,
    '185': 42.6,
    '186': 42.5,
    '187': 42.4,
    '188': 42.3,
    '189': 42.2,
    '190': 42.1,
    '191': 42.1,
    '192': 42,
    '193': 41.9,
    '194': 41.8,
    '195': 41.7,
    '0.5': 61.3,
    '1.5': 61.2,
    '2.5': 61.1,
    '3.5': 60.9,
    '4.5': 60.8,
    '5.5': 60.7,
    '6.5': 60.6,
    '7.5': 60.5,
    '8.5': 60.3,
    '9.5': 60.2,
    '10.5': 60.1,
    '11.5': 60,
    '12.5': 59.9,
    '13.5': 59.7,
    '14.5': 59.6,
    '15.5': 59.5,
    '16.5': 59.4,
    '17.5': 59.3,
    '18.5': 59.1,
    '19.5': 59,
    '20.5': 58.9,
    '21.5': 58.8,
    '22.5': 58.7,
    '23.5': 58.6,
    '24.5': 58.5,
    '25.5': 58.3,
    '26.5': 58.2,
    '27.5': 58.1,
    '28.5': 58,
    '29.5': 57.9,
    '30.5': 57.8,
    '31.5': 57.7,
    '32.5': 57.5,
    '33.5': 57.4,
    '34.5': 57.3,
    '35.5': 57.2,
    '36.5': 57.1,
    '37.5': 57,
    '38.5': 56.9,
    '39.5': 56.7,
    '40.5': 56.6,
    '41.5': 56.5,
    '42.5': 56.4,
    '43.5': 56.3,
    '44.5': 56.2,
    '45.5': 56.1,
    '46.5': 56,
    '47.5': 55.9,
    '48.5': 55.7,
    '49.5': 55.6,
    '50.5': 55.5,
    '51.5': 55.4,
    '52.5': 55.3,
    '53.5': 55.2,
    '54.5': 55.1,
    '55.5': 55,
    '56.5': 54.9,
    '57.5': 54.8,
    '58.5': 54.7,
    '59.5': 54.6,
    '60.5': 54.4,
    '61.5': 54.3,
    '62.5': 54.2,
    '63.5': 54.1,
    '64.5': 54,
    '65.5': 53.9,
    '66.5': 53.8,
    '67.5': 53.7,
    '68.5': 53.6,
    '69.5': 53.5,
    '70.5': 53.4,
    '71.5': 53.3,
    '72.5': 53.2,
    '73.5': 53.1,
    '74.5': 53,
    '75.5': 52.9,
    '76.5': 52.8,
    '77.5': 52.7,
    '78.5': 52.5,
    '79.5': 52.4,
    '80.5': 52.3,
    '81.5': 52.2,
    '82.5': 52.1,
    '83.5': 52,
    '84.5': 51.9,
    '85.5': 51.8,
    '86.5': 51.7,
    '87.5': 51.6,
    '88.5': 51.5,
    '89.5': 51.4,
    '90.5': 51.3,
    '91.5': 51.2,
    '92.5': 51.1,
    '93.5': 51,
    '94.5': 50.9,
    '95.5': 50.8,
    '96.5': 50.7,
    '97.5': 50.6,
    '98.5': 50.5,
    '99.5': 50.4,
    '100.5': 50.3,
    '101.5': 50.2,
    '102.5': 50.1,
    '103.5': 50,
    '104.5': 49.9,
    '105.5': 49.8,
    '106.5': 49.7,
    '107.5': 49.6,
    '108.5': 49.5,
    '109.5': 49.4,
    '110.5': 49.3,
    '111.5': 49.2,
    '112.5': 49.1,
    '113.5': 49,
    '114.5': 48.9,
    '115.5': 48.9,
    '116.5': 48.8,
    '117.5': 48.7,
    '118.5': 48.6,
    '119.5': 48.5,
    '120.5': 48.4,
    '121.5': 48.3,
    '122.5': 48.2,
    '123.5': 48.1,
    '124.5': 48,
    '125.5': 47.9,
    '126.5': 47.8,
    '127.5': 47.7,
    '128.5': 47.6,
    '129.5': 47.5,
    '130.5': 47.4,
    '131.5': 47.3,
    '132.5': 47.2,
    '133.5': 47.1,
    '134.5': 47.1,
    '135.5': 47,
    '136.5': 46.9,
    '137.5': 46.8,
    '138.5': 46.7,
    '139.5': 46.6,
    '140.5': 46.5,
    '141.5': 46.4,
    '142.5': 46.3,
    '143.5': 46.2,
    '144.5': 46.1,
    '145.5': 46,
    '146.5': 45.9,
    '147.5': 45.9,
    '148.5': 45.8,
    '149.5': 45.7,
    '150.5': 45.6,
    '151.5': 45.5,
    '152.5': 45.4,
    '153.5': 45.3,
    '154.5': 45.2,
    '155.5': 45.1,
    '156.5': 45,
    '157.5': 45,
    '158.5': 44.9,
    '159.5': 44.8,
    '160.5': 44.7,
    '161.5': 44.6,
    '162.5': 44.5,
    '163.5': 44.4,
    '164.5': 44.3,
    '165.5': 44.3,
    '166.5': 44.2,
    '167.5': 44.1,
    '168.5': 44,
    '169.5': 43.9,
    '170.5': 43.8,
    '171.5': 43.7,
    '172.5': 43.6,
    '173.5': 43.6,
    '174.5': 43.5,
    '175.5': 43.4,
    '176.5': 43.3,
    '177.5': 43.2,
    '178.5': 43.1,
    '179.5': 43,
    '180.5': 43,
    '181.5': 42.9,
    '182.5': 42.8,
    '183.5': 42.7,
    '184.5': 42.6,
    '185.5': 42.5,
    '186.5': 42.4,
    '187.5': 42.4,
    '188.5': 42.3,
    '189.5': 42.2,
    '190.5': 42.1,
    '191.5': 42,
    '192.5': 41.9,
    '193.5': 41.9,
    '194.5': 41.8,
  },
  '55': {
    '0': 61.9,
    '1': 61.8,
    '2': 61.7,
    '3': 61.5,
    '4': 61.4,
    '5': 61.3,
    '6': 61.2,
    '7': 61.1,
    '8': 60.9,
    '9': 60.8,
    '10': 60.7,
    '11': 60.6,
    '12': 60.5,
    '13': 60.3,
    '14': 60.2,
    '15': 60.1,
    '16': 60,
    '17': 59.9,
    '18': 59.7,
    '19': 59.6,
    '20': 59.5,
    '21': 59.4,
    '22': 59.3,
    '23': 59.2,
    '24': 59,
    '25': 58.9,
    '26': 58.8,
    '27': 58.7,
    '28': 58.6,
    '29': 58.5,
    '30': 58.3,
    '31': 58.2,
    '32': 58.1,
    '33': 58,
    '34': 57.9,
    '35': 57.8,
    '36': 57.7,
    '37': 57.6,
    '38': 57.4,
    '39': 57.3,
    '40': 57.2,
    '41': 57.1,
    '42': 57,
    '43': 56.9,
    '44': 56.8,
    '45': 56.7,
    '46': 56.5,
    '47': 56.4,
    '48': 56.3,
    '49': 56.2,
    '50': 56.1,
    '51': 56,
    '52': 55.9,
    '53': 55.8,
    '54': 55.7,
    '55': 55.5,
    '56': 55.4,
    '57': 55.3,
    '58': 55.2,
    '59': 55.1,
    '60': 55,
    '61': 54.9,
    '62': 54.8,
    '63': 54.7,
    '64': 54.6,
    '65': 54.5,
    '66': 54.4,
    '67': 54.2,
    '68': 54.1,
    '69': 54,
    '70': 53.9,
    '71': 53.8,
    '72': 53.7,
    '73': 53.6,
    '74': 53.5,
    '75': 53.4,
    '76': 53.3,
    '77': 53.2,
    '78': 53.1,
    '79': 53,
    '80': 52.9,
    '81': 52.8,
    '82': 52.7,
    '83': 52.6,
    '84': 52.5,
    '85': 52.4,
    '86': 52.3,
    '87': 52.2,
    '88': 52,
    '89': 51.9,
    '90': 51.8,
    '91': 51.7,
    '92': 51.6,
    '93': 51.5,
    '94': 51.4,
    '95': 51.3,
    '96': 51.2,
    '97': 51.1,
    '98': 51,
    '99': 50.9,
    '100': 50.8,
    '101': 50.7,
    '102': 50.6,
    '103': 50.5,
    '104': 50.4,
    '105': 50.3,
    '106': 50.2,
    '107': 50.1,
    '108': 50,
    '109': 49.9,
    '110': 49.8,
    '111': 49.7,
    '112': 49.6,
    '113': 49.5,
    '114': 49.5,
    '115': 49.4,
    '116': 49.3,
    '117': 49.2,
    '118': 49.1,
    '119': 49,
    '120': 48.9,
    '121': 48.8,
    '122': 48.7,
    '123': 48.6,
    '124': 48.5,
    '125': 48.4,
    '126': 48.3,
    '127': 48.2,
    '128': 48.1,
    '129': 48,
    '130': 47.9,
    '131': 47.8,
    '132': 47.7,
    '133': 47.6,
    '134': 47.5,
    '135': 47.4,
    '136': 47.4,
    '137': 47.3,
    '138': 47.2,
    '139': 47.1,
    '140': 47,
    '141': 46.9,
    '142': 46.8,
    '143': 46.7,
    '144': 46.6,
    '145': 46.5,
    '146': 46.4,
    '147': 46.3,
    '148': 46.2,
    '149': 46.2,
    '150': 46.1,
    '151': 46,
    '152': 45.9,
    '153': 45.8,
    '154': 45.7,
    '155': 45.6,
    '156': 45.5,
    '157': 45.4,
    '158': 45.3,
    '159': 45.2,
    '160': 45.2,
    '161': 45.1,
    '162': 45,
    '163': 44.9,
    '164': 44.8,
    '165': 44.7,
    '166': 44.6,
    '167': 44.5,
    '168': 44.5,
    '169': 44.4,
    '170': 44.3,
    '171': 44.2,
    '172': 44.1,
    '173': 44,
    '174': 43.9,
    '175': 43.8,
    '176': 43.8,
    '177': 43.7,
    '178': 43.6,
    '179': 43.5,
    '180': 43.4,
    '181': 43.3,
    '182': 43.2,
    '183': 43.1,
    '184': 43.1,
    '185': 43,
    '186': 42.9,
    '187': 42.8,
    '188': 42.7,
    '189': 42.6,
    '190': 42.6,
    '191': 42.5,
    '192': 42.4,
    '193': 42.3,
    '194': 42.2,
    '195': 42.1,
    '0.5': 61.9,
    '1.5': 61.7,
    '2.5': 61.6,
    '3.5': 61.5,
    '4.5': 61.4,
    '5.5': 61.2,
    '6.5': 61.1,
    '7.5': 61,
    '8.5': 60.9,
    '9.5': 60.8,
    '10.5': 60.6,
    '11.5': 60.5,
    '12.5': 60.4,
    '13.5': 60.3,
    '14.5': 60.2,
    '15.5': 60,
    '16.5': 59.9,
    '17.5': 59.8,
    '18.5': 59.7,
    '19.5': 59.6,
    '20.5': 59.5,
    '21.5': 59.3,
    '22.5': 59.2,
    '23.5': 59.1,
    '24.5': 59,
    '25.5': 58.9,
    '26.5': 58.8,
    '27.5': 58.6,
    '28.5': 58.5,
    '29.5': 58.4,
    '30.5': 58.3,
    '31.5': 58.2,
    '32.5': 58.1,
    '33.5': 58,
    '34.5': 57.8,
    '35.5': 57.7,
    '36.5': 57.6,
    '37.5': 57.5,
    '38.5': 57.4,
    '39.5': 57.3,
    '40.5': 57.2,
    '41.5': 57,
    '42.5': 56.9,
    '43.5': 56.8,
    '44.5': 56.7,
    '45.5': 56.6,
    '46.5': 56.5,
    '47.5': 56.4,
    '48.5': 56.3,
    '49.5': 56.2,
    '50.5': 56,
    '51.5': 55.9,
    '52.5': 55.8,
    '53.5': 55.7,
    '54.5': 55.6,
    '55.5': 55.5,
    '56.5': 55.4,
    '57.5': 55.3,
    '58.5': 55.2,
    '59.5': 55.1,
    '60.5': 54.9,
    '61.5': 54.8,
    '62.5': 54.7,
    '63.5': 54.6,
    '64.5': 54.5,
    '65.5': 54.4,
    '66.5': 54.3,
    '67.5': 54.2,
    '68.5': 54.1,
    '69.5': 54,
    '70.5': 53.9,
    '71.5': 53.8,
    '72.5': 53.7,
    '73.5': 53.6,
    '74.5': 53.5,
    '75.5': 53.3,
    '76.5': 53.2,
    '77.5': 53.1,
    '78.5': 53,
    '79.5': 52.9,
    '80.5': 52.8,
    '81.5': 52.7,
    '82.5': 52.6,
    '83.5': 52.5,
    '84.5': 52.4,
    '85.5': 52.3,
    '86.5': 52.2,
    '87.5': 52.1,
    '88.5': 52,
    '89.5': 51.9,
    '90.5': 51.8,
    '91.5': 51.7,
    '92.5': 51.6,
    '93.5': 51.5,
    '94.5': 51.4,
    '95.5': 51.3,
    '96.5': 51.2,
    '97.5': 51.1,
    '98.5': 51,
    '99.5': 50.9,
    '100.5': 50.8,
    '101.5': 50.7,
    '102.5': 50.6,
    '103.5': 50.5,
    '104.5': 50.4,
    '105.5': 50.3,
    '106.5': 50.2,
    '107.5': 50.1,
    '108.5': 50,
    '109.5': 49.9,
    '110.5': 49.8,
    '111.5': 49.7,
    '112.5': 49.6,
    '113.5': 49.5,
    '114.5': 49.4,
    '115.5': 49.3,
    '116.5': 49.2,
    '117.5': 49.1,
    '118.5': 49,
    '119.5': 48.9,
    '120.5': 48.8,
    '121.5': 48.7,
    '122.5': 48.6,
    '123.5': 48.5,
    '124.5': 48.4,
    '125.5': 48.3,
    '126.5': 48.2,
    '127.5': 48.2,
    '128.5': 48.1,
    '129.5': 48,
    '130.5': 47.9,
    '131.5': 47.8,
    '132.5': 47.7,
    '133.5': 47.6,
    '134.5': 47.5,
    '135.5': 47.4,
    '136.5': 47.3,
    '137.5': 47.2,
    '138.5': 47.1,
    '139.5': 47,
    '140.5': 46.9,
    '141.5': 46.8,
    '142.5': 46.8,
    '143.5': 46.7,
    '144.5': 46.6,
    '145.5': 46.5,
    '146.5': 46.4,
    '147.5': 46.3,
    '148.5': 46.2,
    '149.5': 46.1,
    '150.5': 46,
    '151.5': 45.9,
    '152.5': 45.8,
    '153.5': 45.7,
    '154.5': 45.7,
    '155.5': 45.6,
    '156.5': 45.5,
    '157.5': 45.4,
    '158.5': 45.3,
    '159.5': 45.2,
    '160.5': 45.1,
    '161.5': 45,
    '162.5': 44.9,
    '163.5': 44.8,
    '164.5': 44.8,
    '165.5': 44.7,
    '166.5': 44.6,
    '167.5': 44.5,
    '168.5': 44.4,
    '169.5': 44.3,
    '170.5': 44.2,
    '171.5': 44.1,
    '172.5': 44.1,
    '173.5': 44,
    '174.5': 43.9,
    '175.5': 43.8,
    '176.5': 43.7,
    '177.5': 43.6,
    '178.5': 43.5,
    '179.5': 43.4,
    '180.5': 43.4,
    '181.5': 43.3,
    '182.5': 43.2,
    '183.5': 43.1,
    '184.5': 43,
    '185.5': 42.9,
    '186.5': 42.9,
    '187.5': 42.8,
    '188.5': 42.7,
    '189.5': 42.6,
    '190.5': 42.5,
    '191.5': 42.4,
    '192.5': 42.3,
    '193.5': 42.3,
    '194.5': 42.2,
  },
  // 55 - 60
  '55.5': {
    '0': 62.5,
    '1': 62.3,
    '2': 62.2,
    '3': 62.1,
    '4': 62,
    '5': 61.9,
    '6': 61.7,
    '7': 61.6,
    '8': 61.5,
    '9': 61.4,
    '10': 61.2,
    '11': 61.1,
    '12': 61,
    '13': 60.9,
    '14': 60.8,
    '15': 60.6,
    '16': 60.5,
    '17': 60.4,
    '18': 60.3,
    '19': 60.2,
    '20': 60.1,
    '21': 59.9,
    '22': 59.8,
    '23': 59.7,
    '24': 59.6,
    '25': 59.5,
    '26': 59.3,
    '27': 59.2,
    '28': 59.1,
    '29': 59,
    '30': 58.9,
    '31': 58.8,
    '32': 58.6,
    '33': 58.5,
    '34': 58.4,
    '35': 58.3,
    '36': 58.2,
    '37': 58.1,
    '38': 58,
    '39': 57.8,
    '40': 57.7,
    '41': 57.6,
    '42': 57.5,
    '43': 57.4,
    '44': 57.3,
    '45': 57.2,
    '46': 57.1,
    '47': 56.9,
    '48': 56.8,
    '49': 56.7,
    '50': 56.6,
    '51': 56.5,
    '52': 56.4,
    '53': 56.3,
    '54': 56.2,
    '55': 56,
    '56': 55.9,
    '57': 55.8,
    '58': 55.7,
    '59': 55.6,
    '60': 55.5,
    '61': 55.4,
    '62': 55.3,
    '63': 55.2,
    '64': 55.1,
    '65': 55,
    '66': 54.8,
    '67': 54.7,
    '68': 54.6,
    '69': 54.5,
    '70': 54.4,
    '71': 54.3,
    '72': 54.2,
    '73': 54.1,
    '74': 54,
    '75': 53.9,
    '76': 53.8,
    '77': 53.7,
    '78': 53.6,
    '79': 53.5,
    '80': 53.4,
    '81': 53.3,
    '82': 53.1,
    '83': 53,
    '84': 52.9,
    '85': 52.8,
    '86': 52.7,
    '87': 52.6,
    '88': 52.5,
    '89': 52.4,
    '90': 52.3,
    '91': 52.2,
    '92': 52.1,
    '93': 52,
    '94': 51.9,
    '95': 51.8,
    '96': 51.7,
    '97': 51.6,
    '98': 51.5,
    '99': 51.4,
    '100': 51.3,
    '101': 51.2,
    '102': 51.1,
    '103': 51,
    '104': 50.9,
    '105': 50.8,
    '106': 50.7,
    '107': 50.6,
    '108': 50.5,
    '109': 50.4,
    '110': 50.3,
    '111': 50.2,
    '112': 50.1,
    '113': 50,
    '114': 49.9,
    '115': 49.8,
    '116': 49.7,
    '117': 49.6,
    '118': 49.5,
    '119': 49.4,
    '120': 49.3,
    '121': 49.2,
    '122': 49.1,
    '123': 49,
    '124': 48.9,
    '125': 48.8,
    '126': 48.7,
    '127': 48.6,
    '128': 48.6,
    '129': 48.5,
    '130': 48.4,
    '131': 48.3,
    '132': 48.2,
    '133': 48.1,
    '134': 48,
    '135': 47.9,
    '136': 47.8,
    '137': 47.7,
    '138': 47.6,
    '139': 47.5,
    '140': 47.4,
    '141': 47.3,
    '142': 47.2,
    '143': 47.1,
    '144': 47,
    '145': 47,
    '146': 46.9,
    '147': 46.8,
    '148': 46.7,
    '149': 46.6,
    '150': 46.5,
    '151': 46.4,
    '152': 46.3,
    '153': 46.2,
    '154': 46.1,
    '155': 46,
    '156': 45.9,
    '157': 45.9,
    '158': 45.8,
    '159': 45.7,
    '160': 45.6,
    '161': 45.5,
    '162': 45.4,
    '163': 45.3,
    '164': 45.2,
    '165': 45.1,
    '166': 45,
    '167': 45,
    '168': 44.9,
    '169': 44.8,
    '170': 44.7,
    '171': 44.6,
    '172': 44.5,
    '173': 44.4,
    '174': 44.3,
    '175': 44.3,
    '176': 44.2,
    '177': 44.1,
    '178': 44,
    '179': 43.9,
    '180': 43.8,
    '181': 43.7,
    '182': 43.6,
    '183': 43.6,
    '184': 43.5,
    '185': 43.4,
    '186': 43.3,
    '187': 43.2,
    '188': 43.1,
    '189': 43,
    '190': 43,
    '191': 42.9,
    '192': 42.8,
    '193': 42.7,
    '194': 42.6,
    '195': 42.5,
    '0.5': 62.4,
    '1.5': 62.3,
    '2.5': 62.2,
    '3.5': 62,
    '4.5': 61.9,
    '5.5': 61.8,
    '6.5': 61.7,
    '7.5': 61.6,
    '8.5': 61.4,
    '9.5': 61.3,
    '10.5': 61.2,
    '11.5': 61.1,
    '12.5': 60.9,
    '13.5': 60.8,
    '14.5': 60.7,
    '15.5': 60.6,
    '16.5': 60.5,
    '17.5': 60.3,
    '18.5': 60.2,
    '19.5': 60.1,
    '20.5': 60,
    '21.5': 59.9,
    '22.5': 59.8,
    '23.5': 59.6,
    '24.5': 59.5,
    '25.5': 59.4,
    '26.5': 59.3,
    '27.5': 59.2,
    '28.5': 59.1,
    '29.5': 58.9,
    '30.5': 58.8,
    '31.5': 58.7,
    '32.5': 58.6,
    '33.5': 58.5,
    '34.5': 58.4,
    '35.5': 58.2,
    '36.5': 58.1,
    '37.5': 58,
    '38.5': 57.9,
    '39.5': 57.8,
    '40.5': 57.7,
    '41.5': 57.6,
    '42.5': 57.4,
    '43.5': 57.3,
    '44.5': 57.2,
    '45.5': 57.1,
    '46.5': 57,
    '47.5': 56.9,
    '48.5': 56.8,
    '49.5': 56.7,
    '50.5': 56.5,
    '51.5': 56.4,
    '52.5': 56.3,
    '53.5': 56.2,
    '54.5': 56.1,
    '55.5': 56,
    '56.5': 55.9,
    '57.5': 55.8,
    '58.5': 55.7,
    '59.5': 55.6,
    '60.5': 55.4,
    '61.5': 55.3,
    '62.5': 55.2,
    '63.5': 55.1,
    '64.5': 55,
    '65.5': 54.9,
    '66.5': 54.8,
    '67.5': 54.7,
    '68.5': 54.6,
    '69.5': 54.5,
    '70.5': 54.4,
    '71.5': 54.3,
    '72.5': 54.2,
    '73.5': 54,
    '74.5': 53.9,
    '75.5': 53.8,
    '76.5': 53.7,
    '77.5': 53.6,
    '78.5': 53.5,
    '79.5': 53.4,
    '80.5': 53.3,
    '81.5': 53.2,
    '82.5': 53.1,
    '83.5': 53,
    '84.5': 52.9,
    '85.5': 52.8,
    '86.5': 52.7,
    '87.5': 52.6,
    '88.5': 52.5,
    '89.5': 52.4,
    '90.5': 52.3,
    '91.5': 52.2,
    '92.5': 52.1,
    '93.5': 52,
    '94.5': 51.9,
    '95.5': 51.8,
    '96.5': 51.7,
    '97.5': 51.6,
    '98.5': 51.5,
    '99.5': 51.4,
    '100.5': 51.2,
    '101.5': 51.2,
    '102.5': 51,
    '103.5': 50.9,
    '104.5': 50.8,
    '105.5': 50.7,
    '106.5': 50.6,
    '107.5': 50.6,
    '108.5': 50.4,
    '109.5': 50.4,
    '110.5': 50.3,
    '111.5': 50.2,
    '112.5': 50.1,
    '113.5': 50,
    '114.5': 49.9,
    '115.5': 49.8,
    '116.5': 49.7,
    '117.5': 49.6,
    '118.5': 49.5,
    '119.5': 49.4,
    '120.5': 49.3,
    '121.5': 49.2,
    '122.5': 49.1,
    '123.5': 49,
    '124.5': 48.9,
    '125.5': 48.8,
    '126.5': 48.7,
    '127.5': 48.6,
    '128.5': 48.5,
    '129.5': 48.4,
    '130.5': 48.3,
    '131.5': 48.2,
    '132.5': 48.1,
    '133.5': 48,
    '134.5': 47.9,
    '135.5': 47.8,
    '136.5': 47.7,
    '137.5': 47.7,
    '138.5': 47.6,
    '139.5': 47.5,
    '140.5': 47.4,
    '141.5': 47.3,
    '142.5': 47.2,
    '143.5': 47.1,
    '144.5': 47,
    '145.5': 46.9,
    '146.5': 46.8,
    '147.5': 46.7,
    '148.5': 46.6,
    '149.5': 46.5,
    '150.5': 46.4,
    '151.5': 46.4,
    '152.5': 46.3,
    '153.5': 46.2,
    '154.5': 46.1,
    '155.5': 46,
    '156.5': 45.9,
    '157.5': 45.8,
    '158.5': 45.7,
    '159.5': 45.6,
    '160.5': 45.5,
    '161.5': 45.4,
    '162.5': 45.4,
    '163.5': 45.3,
    '164.5': 45.2,
    '165.5': 45.1,
    '166.5': 45,
    '167.5': 44.9,
    '168.5': 44.8,
    '169.5': 44.7,
    '170.5': 44.6,
    '171.5': 44.6,
    '172.5': 44.5,
    '173.5': 44.4,
    '174.5': 44.3,
    '175.5': 44.2,
    '176.5': 44.1,
    '177.5': 44,
    '178.5': 43.9,
    '179.5': 43.9,
    '180.5': 43.8,
    '181.5': 43.7,
    '182.5': 43.6,
    '183.5': 43.5,
    '184.5': 43.4,
    '185.5': 43.3,
    '186.5': 43.3,
    '187.5': 43.2,
    '188.5': 43.1,
    '189.5': 43,
    '190.5': 42.9,
    '191.5': 42.8,
    '192.5': 42.7,
    '193.5': 42.7,
    '194.5': 42.6,
  },
  '56': {
    '0': 63,
    '1': 62.9,
    '2': 62.8,
    '3': 62.7,
    '4': 62.5,
    '5': 62.4,
    '6': 62.3,
    '7': 62.2,
    '8': 62,
    '9': 61.9,
    '10': 61.8,
    '11': 61.7,
    '12': 61.6,
    '13': 61.4,
    '14': 61.3,
    '15': 61.2,
    '16': 61.1,
    '17': 60.9,
    '18': 60.8,
    '19': 60.7,
    '20': 60.6,
    '21': 60.5,
    '22': 60.4,
    '23': 60.2,
    '24': 60.1,
    '25': 60,
    '26': 59.9,
    '27': 59.8,
    '28': 59.6,
    '29': 59.5,
    '30': 59.4,
    '31': 59.3,
    '32': 59.2,
    '33': 59.1,
    '34': 58.9,
    '35': 58.8,
    '36': 58.7,
    '37': 58.6,
    '38': 58.5,
    '39': 58.4,
    '40': 58.2,
    '41': 58.1,
    '42': 58,
    '43': 57.9,
    '44': 57.8,
    '45': 57.7,
    '46': 57.6,
    '47': 57.5,
    '48': 57.3,
    '49': 57.2,
    '50': 57.1,
    '51': 57,
    '52': 56.9,
    '53': 56.8,
    '54': 56.7,
    '55': 56.6,
    '56': 56.4,
    '57': 56.3,
    '58': 56.2,
    '59': 56.1,
    '60': 56,
    '61': 55.9,
    '62': 55.8,
    '63': 55.7,
    '64': 55.6,
    '65': 55.5,
    '66': 55.3,
    '67': 55.2,
    '68': 55.1,
    '69': 55,
    '70': 54.9,
    '71': 54.8,
    '72': 54.7,
    '73': 54.6,
    '74': 54.5,
    '75': 54.4,
    '76': 54.3,
    '77': 54.2,
    '78': 54.1,
    '79': 53.9,
    '80': 53.8,
    '81': 53.7,
    '82': 53.6,
    '83': 53.5,
    '84': 53.4,
    '85': 53.3,
    '86': 53.2,
    '87': 53.1,
    '88': 53,
    '89': 52.9,
    '90': 52.8,
    '91': 52.7,
    '92': 52.6,
    '93': 52.5,
    '94': 52.4,
    '95': 52.3,
    '96': 52.2,
    '97': 52.1,
    '98': 52,
    '99': 51.9,
    '100': 51.8,
    '101': 51.7,
    '102': 51.6,
    '103': 51.5,
    '104': 51.4,
    '105': 51.3,
    '106': 51.2,
    '107': 51.1,
    '108': 51,
    '109': 50.9,
    '110': 50.8,
    '111': 50.7,
    '112': 50.6,
    '113': 50.5,
    '114': 50.4,
    '115': 50.3,
    '116': 50.2,
    '117': 50.1,
    '118': 50,
    '119': 49.9,
    '120': 49.8,
    '121': 49.7,
    '122': 49.6,
    '123': 49.5,
    '124': 49.4,
    '125': 49.3,
    '126': 49.2,
    '127': 49.1,
    '128': 49,
    '129': 48.9,
    '130': 48.8,
    '131': 48.7,
    '132': 48.6,
    '133': 48.5,
    '134': 48.4,
    '135': 48.3,
    '136': 48.2,
    '137': 48.1,
    '138': 48,
    '139': 48,
    '140': 47.9,
    '141': 47.8,
    '142': 47.7,
    '143': 47.6,
    '144': 47.5,
    '145': 47.4,
    '146': 47.3,
    '147': 47.2,
    '148': 47.1,
    '149': 47,
    '150': 46.9,
    '151': 46.8,
    '152': 46.7,
    '153': 46.6,
    '154': 46.6,
    '155': 46.5,
    '156': 46.4,
    '157': 46.3,
    '158': 46.2,
    '159': 46.1,
    '160': 46,
    '161': 45.9,
    '162': 45.8,
    '163': 45.7,
    '164': 45.6,
    '165': 45.6,
    '166': 45.5,
    '167': 45.4,
    '168': 45.3,
    '169': 45.2,
    '170': 45.1,
    '171': 45,
    '172': 44.9,
    '173': 44.8,
    '174': 44.8,
    '175': 44.7,
    '176': 44.6,
    '177': 44.5,
    '178': 44.4,
    '179': 44.3,
    '180': 44.2,
    '181': 44.1,
    '182': 44.1,
    '183': 44,
    '184': 43.9,
    '185': 43.8,
    '186': 43.7,
    '187': 43.6,
    '188': 43.5,
    '189': 43.4,
    '190': 43.4,
    '191': 43.3,
    '192': 43.2,
    '193': 43.1,
    '194': 43,
    '195': 42.9,
    '0.5': 63,
    '1.5': 62.8,
    '2.5': 62.7,
    '3.5': 62.6,
    '4.5': 62.5,
    '5.5': 62.3,
    '6.5': 62.2,
    '7.5': 62.1,
    '8.5': 62,
    '9.5': 61.9,
    '10.5': 61.7,
    '11.5': 61.6,
    '12.5': 61.5,
    '13.5': 61.4,
    '14.5': 61.3,
    '15.5': 61.1,
    '16.5': 61,
    '17.5': 60.9,
    '18.5': 60.8,
    '19.5': 60.7,
    '20.5': 60.5,
    '21.5': 60.4,
    '22.5': 60.3,
    '23.5': 60.2,
    '24.5': 60.1,
    '25.5': 59.9,
    '26.5': 59.8,
    '27.5': 59.7,
    '28.5': 59.6,
    '29.5': 59.5,
    '30.5': 59.3,
    '31.5': 59.2,
    '32.5': 59.1,
    '33.5': 59,
    '34.5': 58.9,
    '35.5': 58.8,
    '36.5': 58.7,
    '37.5': 58.5,
    '38.5': 58.4,
    '39.5': 58.3,
    '40.5': 58.2,
    '41.5': 58.1,
    '42.5': 58,
    '43.5': 57.8,
    '44.5': 57.7,
    '45.5': 57.6,
    '46.5': 57.5,
    '47.5': 57.4,
    '48.5': 57.3,
    '49.5': 57.2,
    '50.5': 57.1,
    '51.5': 56.9,
    '52.5': 56.8,
    '53.5': 56.7,
    '54.5': 56.6,
    '55.5': 56.5,
    '56.5': 56.4,
    '57.5': 56.3,
    '58.5': 56.2,
    '59.5': 56.1,
    '60.5': 55.9,
    '61.5': 55.8,
    '62.5': 55.7,
    '63.5': 55.6,
    '64.5': 55.5,
    '65.5': 55.4,
    '66.5': 55.3,
    '67.5': 55.2,
    '68.5': 55.1,
    '69.5': 55,
    '70.5': 54.9,
    '71.5': 54.7,
    '72.5': 54.6,
    '73.5': 54.5,
    '74.5': 54.4,
    '75.5': 54.3,
    '76.5': 54.2,
    '77.5': 54.1,
    '78.5': 54,
    '79.5': 53.9,
    '80.5': 53.8,
    '81.5': 53.7,
    '82.5': 53.6,
    '83.5': 53.5,
    '84.5': 53.4,
    '85.5': 53.3,
    '86.5': 53.2,
    '87.5': 53.1,
    '88.5': 52.9,
    '89.5': 52.8,
    '90.5': 52.7,
    '91.5': 52.6,
    '92.5': 52.5,
    '93.5': 52.4,
    '94.5': 52.3,
    '95.5': 52.2,
    '96.5': 52.1,
    '97.5': 52,
    '98.5': 51.9,
    '99.5': 51.8,
    '100.5': 51.7,
    '101.5': 51.6,
    '102.5': 51.5,
    '103.5': 51.4,
    '104.5': 51.3,
    '105.5': 51.2,
    '106.5': 51.1,
    '107.5': 51,
    '108.5': 50.9,
    '109.5': 50.8,
    '110.5': 50.7,
    '111.5': 50.6,
    '112.5': 50.5,
    '113.5': 50.4,
    '114.5': 50.3,
    '115.5': 50.2,
    '116.5': 50.1,
    '117.5': 50,
    '118.5': 49.9,
    '119.5': 49.8,
    '120.5': 49.7,
    '121.5': 49.6,
    '122.5': 49.5,
    '123.5': 49.4,
    '124.5': 49.3,
    '125.5': 49.2,
    '126.5': 49.1,
    '127.5': 49,
    '128.5': 48.9,
    '129.5': 48.9,
    '130.5': 48.8,
    '131.5': 48.7,
    '132.5': 48.6,
    '133.5': 48.5,
    '134.5': 48.4,
    '135.5': 48.3,
    '136.5': 48.2,
    '137.5': 48.1,
    '138.5': 48,
    '139.5': 47.9,
    '140.5': 47.8,
    '141.5': 47.7,
    '142.5': 47.6,
    '143.5': 47.5,
    '144.5': 47.4,
    '145.5': 47.3,
    '146.5': 47.2,
    '147.5': 47.2,
    '148.5': 47.1,
    '149.5': 47,
    '150.5': 46.9,
    '151.5': 46.8,
    '152.5': 46.7,
    '153.5': 46.6,
    '154.5': 46.5,
    '155.5': 46.4,
    '156.5': 46.3,
    '157.5': 46.2,
    '158.5': 46.1,
    '159.5': 46.1,
    '160.5': 46,
    '161.5': 45.9,
    '162.5': 45.8,
    '163.5': 45.7,
    '164.5': 45.6,
    '165.5': 45.5,
    '166.5': 45.4,
    '167.5': 45.3,
    '168.5': 45.2,
    '169.5': 45.2,
    '170.5': 45.1,
    '171.5': 45,
    '172.5': 44.9,
    '173.5': 44.8,
    '174.5': 44.7,
    '175.5': 44.6,
    '176.5': 44.5,
    '177.5': 44.4,
    '178.5': 44.4,
    '179.5': 44.3,
    '180.5': 44.2,
    '181.5': 44.1,
    '182.5': 44,
    '183.5': 43.9,
    '184.5': 43.8,
    '185.5': 43.8,
    '186.5': 43.7,
    '187.5': 43.6,
    '188.5': 43.5,
    '189.5': 43.4,
    '190.5': 43.3,
    '191.5': 43.2,
    '192.5': 43.2,
    '193.5': 43.1,
    '194.5': 43,
  },
  '56.5': {
    '0': 63.6,
    '1': 63.5,
    '2': 63.3,
    '3': 63.2,
    '4': 63.1,
    '5': 63,
    '6': 62.8,
    '7': 62.7,
    '8': 62.6,
    '9': 62.5,
    '10': 62.3,
    '11': 62.2,
    '12': 62.1,
    '13': 62,
    '14': 61.9,
    '15': 61.7,
    '16': 61.6,
    '17': 61.5,
    '18': 61.4,
    '19': 61.2,
    '20': 61.1,
    '21': 61,
    '22': 60.9,
    '23': 60.8,
    '24': 60.6,
    '25': 60.5,
    '26': 60.4,
    '27': 60.3,
    '28': 60.2,
    '29': 60.1,
    '30': 59.9,
    '31': 59.8,
    '32': 59.7,
    '33': 59.6,
    '34': 59.5,
    '35': 59.3,
    '36': 59.2,
    '37': 59.1,
    '38': 59,
    '39': 58.9,
    '40': 58.8,
    '41': 58.7,
    '42': 58.5,
    '43': 58.4,
    '44': 58.3,
    '45': 58.2,
    '46': 58.1,
    '47': 58,
    '48': 57.8,
    '49': 57.7,
    '50': 57.6,
    '51': 57.5,
    '52': 57.4,
    '53': 57.3,
    '54': 57.2,
    '55': 57.1,
    '56': 56.9,
    '57': 56.8,
    '58': 56.7,
    '59': 56.6,
    '60': 56.5,
    '61': 56.4,
    '62': 56.3,
    '63': 56.2,
    '64': 56.1,
    '65': 55.9,
    '66': 55.8,
    '67': 55.7,
    '68': 55.6,
    '69': 55.5,
    '70': 55.4,
    '71': 55.3,
    '72': 55.2,
    '73': 55.1,
    '74': 55,
    '75': 54.9,
    '76': 54.8,
    '77': 54.6,
    '78': 54.5,
    '79': 54.4,
    '80': 54.3,
    '81': 54.2,
    '82': 54.1,
    '83': 54,
    '84': 53.9,
    '85': 53.8,
    '86': 53.7,
    '87': 53.6,
    '88': 53.5,
    '89': 53.4,
    '90': 53.3,
    '91': 53.2,
    '92': 53.1,
    '93': 53,
    '94': 52.9,
    '95': 52.7,
    '96': 52.6,
    '97': 52.5,
    '98': 52.4,
    '99': 52.3,
    '100': 52.2,
    '101': 52.1,
    '102': 52,
    '103': 51.9,
    '104': 51.8,
    '105': 51.7,
    '106': 51.6,
    '107': 51.5,
    '108': 51.4,
    '109': 51.3,
    '110': 51.2,
    '111': 51.1,
    '112': 51,
    '113': 50.9,
    '114': 50.8,
    '115': 50.7,
    '116': 50.6,
    '117': 50.5,
    '118': 50.4,
    '119': 50.3,
    '120': 50.2,
    '121': 50.1,
    '122': 50,
    '123': 49.9,
    '124': 49.8,
    '125': 49.7,
    '126': 49.6,
    '127': 49.5,
    '128': 49.4,
    '129': 49.3,
    '130': 49.3,
    '131': 49.2,
    '132': 49.1,
    '133': 49,
    '134': 48.9,
    '135': 48.8,
    '136': 48.7,
    '137': 48.6,
    '138': 48.5,
    '139': 48.4,
    '140': 48.3,
    '141': 48.2,
    '142': 48.1,
    '143': 48,
    '144': 47.9,
    '145': 47.8,
    '146': 47.7,
    '147': 47.6,
    '148': 47.5,
    '149': 47.4,
    '150': 47.4,
    '151': 47.3,
    '152': 47.2,
    '153': 47.1,
    '154': 47,
    '155': 46.9,
    '156': 46.8,
    '157': 46.7,
    '158': 46.6,
    '159': 46.5,
    '160': 46.4,
    '161': 46.3,
    '162': 46.2,
    '163': 46.2,
    '164': 46.1,
    '165': 46,
    '166': 45.9,
    '167': 45.8,
    '168': 45.7,
    '169': 45.6,
    '170': 45.5,
    '171': 45.4,
    '172': 45.3,
    '173': 45.3,
    '174': 45.2,
    '175': 45.1,
    '176': 45,
    '177': 44.9,
    '178': 44.8,
    '179': 44.7,
    '180': 44.6,
    '181': 44.6,
    '182': 44.5,
    '183': 44.4,
    '184': 44.3,
    '185': 44.2,
    '186': 44.1,
    '187': 44,
    '188': 43.9,
    '189': 43.9,
    '190': 43.8,
    '191': 43.7,
    '192': 43.6,
    '193': 43.5,
    '194': 43.4,
    '195': 43.3,
    '0.5': 63.5,
    '1.5': 63.4,
    '2.5': 63.3,
    '3.5': 63.1,
    '4.5': 63,
    '5.5': 62.9,
    '6.5': 62.8,
    '7.5': 62.7,
    '8.5': 62.5,
    '9.5': 62.4,
    '10.5': 62.3,
    '11.5': 62.2,
    '12.5': 62,
    '13.5': 61.9,
    '14.5': 61.8,
    '15.5': 61.7,
    '16.5': 61.5,
    '17.5': 61.4,
    '18.5': 61.3,
    '19.5': 61.2,
    '20.5': 61.1,
    '21.5': 60.9,
    '22.5': 60.8,
    '23.5': 60.7,
    '24.5': 60.6,
    '25.5': 60.5,
    '26.5': 60.3,
    '27.5': 60.2,
    '28.5': 60.1,
    '29.5': 60,
    '30.5': 59.9,
    '31.5': 59.8,
    '32.5': 59.6,
    '33.5': 59.5,
    '34.5': 59.4,
    '35.5': 59.3,
    '36.5': 59.2,
    '37.5': 59.1,
    '38.5': 58.9,
    '39.5': 58.8,
    '40.5': 58.7,
    '41.5': 58.6,
    '42.5': 58.5,
    '43.5': 58.4,
    '44.5': 58.2,
    '45.5': 58.1,
    '46.5': 58,
    '47.5': 57.9,
    '48.5': 57.8,
    '49.5': 57.7,
    '50.5': 57.6,
    '51.5': 57.5,
    '52.5': 57.3,
    '53.5': 57.2,
    '54.5': 57.1,
    '55.5': 57,
    '56.5': 56.9,
    '57.5': 56.8,
    '58.5': 56.7,
    '59.5': 56.6,
    '60.5': 56.4,
    '61.5': 56.3,
    '62.5': 56.2,
    '63.5': 56.1,
    '64.5': 56,
    '65.5': 55.9,
    '66.5': 55.8,
    '67.5': 55.7,
    '68.5': 55.6,
    '69.5': 55.5,
    '70.5': 55.3,
    '71.5': 55.2,
    '72.5': 55.1,
    '73.5': 55,
    '74.5': 54.9,
    '75.5': 54.8,
    '76.5': 54.7,
    '77.5': 54.6,
    '78.5': 54.5,
    '79.5': 54.4,
    '80.5': 54.3,
    '81.5': 54.2,
    '82.5': 54.1,
    '83.5': 54,
    '84.5': 53.8,
    '85.5': 53.7,
    '86.5': 53.6,
    '87.5': 53.5,
    '88.5': 53.4,
    '89.5': 53.3,
    '90.5': 53.2,
    '91.5': 53.1,
    '92.5': 53,
    '93.5': 52.9,
    '94.5': 52.8,
    '95.5': 52.7,
    '96.5': 52.6,
    '97.5': 52.5,
    '98.5': 52.4,
    '99.5': 52.3,
    '100.5': 52.2,
    '101.5': 52.1,
    '102.5': 52,
    '103.5': 51.9,
    '104.5': 51.8,
    '105.5': 51.7,
    '106.5': 51.6,
    '107.5': 51.5,
    '108.5': 51.4,
    '109.5': 51.3,
    '110.5': 51.2,
    '111.5': 51.1,
    '112.5': 51,
    '113.5': 50.9,
    '114.5': 50.8,
    '115.5': 50.7,
    '116.5': 50.6,
    '117.5': 50.5,
    '118.5': 50.4,
    '119.5': 50.3,
    '120.5': 50.2,
    '121.5': 50.1,
    '122.5': 50,
    '123.5': 49.9,
    '124.5': 49.8,
    '125.5': 49.7,
    '126.5': 49.6,
    '127.5': 49.5,
    '128.5': 49.4,
    '129.5': 49.3,
    '130.5': 49.2,
    '131.5': 49.1,
    '132.5': 49,
    '133.5': 48.9,
    '134.5': 48.8,
    '135.5': 48.7,
    '136.5': 48.6,
    '137.5': 48.5,
    '138.5': 48.4,
    '139.5': 48.3,
    '140.5': 48.2,
    '141.5': 48.1,
    '142.5': 48.1,
    '143.5': 48,
    '144.5': 47.9,
    '145.5': 47.8,
    '146.5': 47.7,
    '147.5': 47.6,
    '148.5': 47.5,
    '149.5': 47.4,
    '150.5': 47.3,
    '151.5': 47.2,
    '152.5': 47.1,
    '153.5': 47,
    '154.5': 46.9,
    '155.5': 46.8,
    '156.5': 46.8,
    '157.5': 46.7,
    '158.5': 46.6,
    '159.5': 46.5,
    '160.5': 46.4,
    '161.5': 46.3,
    '162.5': 46.2,
    '163.5': 46.1,
    '164.5': 46,
    '165.5': 45.9,
    '166.5': 45.8,
    '167.5': 45.8,
    '168.5': 45.7,
    '169.5': 45.6,
    '170.5': 45.5,
    '171.5': 45.4,
    '172.5': 45.3,
    '173.5': 45.2,
    '174.5': 45.1,
    '175.5': 45,
    '176.5': 44.9,
    '177.5': 44.9,
    '178.5': 44.8,
    '179.5': 44.7,
    '180.5': 44.6,
    '181.5': 44.5,
    '182.5': 44.4,
    '183.5': 44.3,
    '184.5': 44.2,
    '185.5': 44.2,
    '186.5': 44.1,
    '187.5': 44,
    '188.5': 43.9,
    '189.5': 43.8,
    '190.5': 43.7,
    '191.5': 43.6,
    '192.5': 43.6,
    '193.5': 43.5,
    '194.5': 43.4,
  },
  '57': {
    '0': 64.1,
    '1': 64,
    '2': 63.9,
    '3': 63.8,
    '4': 63.6,
    '5': 63.5,
    '6': 63.4,
    '7': 63.3,
    '8': 63.1,
    '9': 63,
    '10': 62.9,
    '11': 62.8,
    '12': 62.6,
    '13': 62.5,
    '14': 62.4,
    '15': 62.3,
    '16': 62.2,
    '17': 62,
    '18': 61.9,
    '19': 61.8,
    '20': 61.7,
    '21': 61.5,
    '22': 61.4,
    '23': 61.3,
    '24': 61.2,
    '25': 61.1,
    '26': 60.9,
    '27': 60.8,
    '28': 60.7,
    '29': 60.6,
    '30': 60.5,
    '31': 60.3,
    '32': 60.2,
    '33': 60.1,
    '34': 60,
    '35': 59.9,
    '36': 59.8,
    '37': 59.6,
    '38': 59.5,
    '39': 59.4,
    '40': 59.3,
    '41': 59.2,
    '42': 59.1,
    '43': 58.9,
    '44': 58.8,
    '45': 58.7,
    '46': 58.6,
    '47': 58.5,
    '48': 58.4,
    '49': 58.2,
    '50': 58.1,
    '51': 58,
    '52': 57.9,
    '53': 57.8,
    '54': 57.7,
    '55': 57.6,
    '56': 57.4,
    '57': 57.3,
    '58': 57.2,
    '59': 57.1,
    '60': 57,
    '61': 56.9,
    '62': 56.8,
    '63': 56.7,
    '64': 56.6,
    '65': 56.4,
    '66': 56.3,
    '67': 56.2,
    '68': 56.1,
    '69': 56,
    '70': 55.9,
    '71': 55.8,
    '72': 55.7,
    '73': 55.6,
    '74': 55.5,
    '75': 55.3,
    '76': 55.2,
    '77': 55.1,
    '78': 55,
    '79': 54.9,
    '80': 54.8,
    '81': 54.7,
    '82': 54.6,
    '83': 54.5,
    '84': 54.4,
    '85': 54.3,
    '86': 54.2,
    '87': 54.1,
    '88': 54,
    '89': 53.8,
    '90': 53.7,
    '91': 53.6,
    '92': 53.5,
    '93': 53.4,
    '94': 53.3,
    '95': 53.2,
    '96': 53.1,
    '97': 53,
    '98': 52.9,
    '99': 52.8,
    '100': 52.7,
    '101': 52.6,
    '102': 52.5,
    '103': 52.4,
    '104': 52.3,
    '105': 52.2,
    '106': 52.1,
    '107': 52,
    '108': 51.9,
    '109': 51.8,
    '110': 51.7,
    '111': 51.6,
    '112': 51.5,
    '113': 51.4,
    '114': 51.3,
    '115': 51.2,
    '116': 51.1,
    '117': 51,
    '118': 50.9,
    '119': 50.8,
    '120': 50.7,
    '121': 50.6,
    '122': 50.5,
    '123': 50.4,
    '124': 50.3,
    '125': 50.2,
    '126': 50.1,
    '127': 50,
    '128': 49.9,
    '129': 49.8,
    '130': 49.7,
    '131': 49.6,
    '132': 49.5,
    '133': 49.4,
    '134': 49.3,
    '135': 49.2,
    '136': 49.1,
    '137': 49,
    '138': 48.9,
    '139': 48.8,
    '140': 48.7,
    '141': 48.6,
    '142': 48.5,
    '143': 48.4,
    '144': 48.3,
    '145': 48.3,
    '146': 48.2,
    '147': 48.1,
    '148': 48,
    '149': 47.9,
    '150': 47.8,
    '151': 47.7,
    '152': 47.6,
    '153': 47.5,
    '154': 47.4,
    '155': 47.3,
    '156': 47.2,
    '157': 47.1,
    '158': 47,
    '159': 46.9,
    '160': 46.9,
    '161': 46.8,
    '162': 46.7,
    '163': 46.6,
    '164': 46.5,
    '165': 46.4,
    '166': 46.3,
    '167': 46.2,
    '168': 46.1,
    '169': 46,
    '170': 45.9,
    '171': 45.9,
    '172': 45.8,
    '173': 45.7,
    '174': 45.6,
    '175': 45.5,
    '176': 45.4,
    '177': 45.3,
    '178': 45.2,
    '179': 45.1,
    '180': 45.1,
    '181': 45,
    '182': 44.9,
    '183': 44.8,
    '184': 44.7,
    '185': 44.6,
    '186': 44.5,
    '187': 44.4,
    '188': 44.3,
    '189': 44.3,
    '190': 44.2,
    '191': 44.1,
    '192': 44,
    '193': 43.9,
    '194': 43.8,
    '195': 43.7,
    '0.5': 64.1,
    '1.5': 64,
    '2.5': 63.8,
    '3.5': 63.7,
    '4.5': 63.6,
    '5.5': 63.5,
    '6.5': 63.3,
    '7.5': 63.2,
    '8.5': 63.1,
    '9.5': 63,
    '10.5': 62.8,
    '11.5': 62.7,
    '12.5': 62.6,
    '13.5': 62.5,
    '14.5': 62.3,
    '15.5': 62.2,
    '16.5': 62.1,
    '17.5': 62,
    '18.5': 61.9,
    '19.5': 61.7,
    '20.5': 61.6,
    '21.5': 61.5,
    '22.5': 61.4,
    '23.5': 61.2,
    '24.5': 61.1,
    '25.5': 61,
    '26.5': 60.9,
    '27.5': 60.8,
    '28.5': 60.6,
    '29.5': 60.5,
    '30.5': 60.4,
    '31.5': 60.3,
    '32.5': 60.2,
    '33.5': 60,
    '34.5': 59.9,
    '35.5': 59.8,
    '36.5': 59.7,
    '37.5': 59.6,
    '38.5': 59.5,
    '39.5': 59.3,
    '40.5': 59.2,
    '41.5': 59.1,
    '42.5': 59,
    '43.5': 58.9,
    '44.5': 58.8,
    '45.5': 58.6,
    '46.5': 58.5,
    '47.5': 58.4,
    '48.5': 58.3,
    '49.5': 58.2,
    '50.5': 58.1,
    '51.5': 58,
    '52.5': 57.8,
    '53.5': 57.7,
    '54.5': 57.6,
    '55.5': 57.5,
    '56.5': 57.4,
    '57.5': 57.3,
    '58.5': 57.2,
    '59.5': 57.1,
    '60.5': 56.9,
    '61.5': 56.8,
    '62.5': 56.7,
    '63.5': 56.6,
    '64.5': 56.5,
    '65.5': 56.4,
    '66.5': 56.3,
    '67.5': 56.2,
    '68.5': 56.1,
    '69.5': 55.9,
    '70.5': 55.8,
    '71.5': 55.7,
    '72.5': 55.6,
    '73.5': 55.5,
    '74.5': 55.4,
    '75.5': 55.3,
    '76.5': 55.2,
    '77.5': 55.1,
    '78.5': 55,
    '79.5': 54.9,
    '80.5': 54.8,
    '81.5': 54.6,
    '82.5': 54.5,
    '83.5': 54.4,
    '84.5': 54.3,
    '85.5': 54.2,
    '86.5': 54.1,
    '87.5': 54,
    '88.5': 53.9,
    '89.5': 53.8,
    '90.5': 53.7,
    '91.5': 53.6,
    '92.5': 53.5,
    '93.5': 53.4,
    '94.5': 53.3,
    '95.5': 53.2,
    '96.5': 53.1,
    '97.5': 53,
    '98.5': 52.9,
    '99.5': 52.8,
    '100.5': 52.6,
    '101.5': 52.5,
    '102.5': 52.4,
    '103.5': 52.3,
    '104.5': 52.2,
    '105.5': 52.1,
    '106.5': 52,
    '107.5': 51.9,
    '108.5': 51.8,
    '109.5': 51.7,
    '110.5': 51.6,
    '111.5': 51.5,
    '112.5': 51.4,
    '113.5': 51.3,
    '114.5': 51.2,
    '115.5': 51.1,
    '116.5': 51,
    '117.5': 50.9,
    '118.5': 50.8,
    '119.5': 50.7,
    '120.5': 50.6,
    '121.5': 50.5,
    '122.5': 50.4,
    '123.5': 50.3,
    '124.5': 50.2,
    '125.5': 50.1,
    '126.5': 50,
    '127.5': 49.9,
    '128.5': 49.8,
    '129.5': 49.7,
    '130.5': 49.6,
    '131.5': 49.5,
    '132.5': 49.5,
    '133.5': 49.4,
    '134.5': 49.3,
    '135.5': 49.2,
    '136.5': 49.1,
    '137.5': 49,
    '138.5': 48.9,
    '139.5': 48.8,
    '140.5': 48.7,
    '141.5': 48.6,
    '142.5': 48.5,
    '143.5': 48.4,
    '144.5': 48.3,
    '145.5': 48.2,
    '146.5': 48.1,
    '147.5': 48,
    '148.5': 47.9,
    '149.5': 47.8,
    '150.5': 47.7,
    '151.5': 47.6,
    '152.5': 47.6,
    '153.5': 47.5,
    '154.5': 47.4,
    '155.5': 47.3,
    '156.5': 47.2,
    '157.5': 47.1,
    '158.5': 47,
    '159.5': 46.9,
    '160.5': 46.8,
    '161.5': 46.7,
    '162.5': 46.6,
    '163.5': 46.5,
    '164.5': 46.4,
    '165.5': 46.4,
    '166.5': 46.3,
    '167.5': 46.2,
    '168.5': 46.1,
    '169.5': 46,
    '170.5': 45.9,
    '171.5': 45.8,
    '172.5': 45.7,
    '173.5': 45.6,
    '174.5': 45.5,
    '175.5': 45.5,
    '176.5': 45.4,
    '177.5': 45.3,
    '178.5': 45.2,
    '179.5': 45.1,
    '180.5': 45,
    '181.5': 44.9,
    '182.5': 44.8,
    '183.5': 44.7,
    '184.5': 44.7,
    '185.5': 44.6,
    '186.5': 44.5,
    '187.5': 44.4,
    '188.5': 44.3,
    '189.5': 44.2,
    '190.5': 44.1,
    '191.5': 44,
    '192.5': 44,
    '193.5': 43.9,
    '194.5': 43.8,
  },
  '57.5': {
    '0': 64.7,
    '1': 64.6,
    '2': 64.5,
    '3': 64.3,
    '4': 64.2,
    '5': 64.1,
    '6': 63.9,
    '7': 63.8,
    '8': 63.7,
    '9': 63.6,
    '10': 63.4,
    '11': 63.3,
    '12': 63.2,
    '13': 63.1,
    '14': 62.9,
    '15': 62.8,
    '16': 62.7,
    '17': 62.6,
    '18': 62.4,
    '19': 62.3,
    '20': 62.2,
    '21': 62.1,
    '22': 62,
    '23': 61.8,
    '24': 61.7,
    '25': 61.6,
    '26': 61.5,
    '27': 61.4,
    '28': 61.2,
    '29': 61.1,
    '30': 61,
    '31': 60.9,
    '32': 60.8,
    '33': 60.6,
    '34': 60.5,
    '35': 60.4,
    '36': 60.3,
    '37': 60.2,
    '38': 60,
    '39': 59.9,
    '40': 59.8,
    '41': 59.7,
    '42': 59.6,
    '43': 59.5,
    '44': 59.3,
    '45': 59.2,
    '46': 59.1,
    '47': 59,
    '48': 58.9,
    '49': 58.8,
    '50': 58.6,
    '51': 58.5,
    '52': 58.4,
    '53': 58.3,
    '54': 58.2,
    '55': 58.1,
    '56': 58,
    '57': 57.8,
    '58': 57.7,
    '59': 57.6,
    '60': 57.5,
    '61': 57.4,
    '62': 57.3,
    '63': 57.2,
    '64': 57.1,
    '65': 56.9,
    '66': 56.8,
    '67': 56.7,
    '68': 56.6,
    '69': 56.5,
    '70': 56.4,
    '71': 56.3,
    '72': 56.2,
    '73': 56.1,
    '74': 55.9,
    '75': 55.8,
    '76': 55.7,
    '77': 55.6,
    '78': 55.5,
    '79': 55.4,
    '80': 55.3,
    '81': 55.2,
    '82': 55.1,
    '83': 55,
    '84': 54.9,
    '85': 54.8,
    '86': 54.6,
    '87': 54.5,
    '88': 54.4,
    '89': 54.3,
    '90': 54.2,
    '91': 54.1,
    '92': 54,
    '93': 53.9,
    '94': 53.8,
    '95': 53.7,
    '96': 53.6,
    '97': 53.5,
    '98': 53.4,
    '99': 53.3,
    '100': 53.2,
    '101': 53.1,
    '102': 53,
    '103': 52.9,
    '104': 52.8,
    '105': 52.6,
    '106': 52.5,
    '107': 52.4,
    '108': 52.3,
    '109': 52.2,
    '110': 52.1,
    '111': 52,
    '112': 51.9,
    '113': 51.8,
    '114': 51.7,
    '115': 51.6,
    '116': 51.5,
    '117': 51.4,
    '118': 51.3,
    '119': 51.2,
    '120': 51.1,
    '121': 51,
    '122': 50.9,
    '123': 50.8,
    '124': 50.7,
    '125': 50.6,
    '126': 50.5,
    '127': 50.4,
    '128': 50.3,
    '129': 50.2,
    '130': 50.1,
    '131': 50,
    '132': 49.9,
    '133': 49.8,
    '134': 49.7,
    '135': 49.6,
    '136': 49.5,
    '137': 49.5,
    '138': 49.4,
    '139': 49.3,
    '140': 49.2,
    '141': 49.1,
    '142': 49,
    '143': 48.9,
    '144': 48.8,
    '145': 48.7,
    '146': 48.6,
    '147': 48.5,
    '148': 48.4,
    '149': 48.3,
    '150': 48.2,
    '151': 48.1,
    '152': 48,
    '153': 47.9,
    '154': 47.8,
    '155': 47.7,
    '156': 47.6,
    '157': 47.6,
    '158': 47.5,
    '159': 47.4,
    '160': 47.3,
    '161': 47.2,
    '162': 47.1,
    '163': 47,
    '164': 46.9,
    '165': 46.8,
    '166': 46.7,
    '167': 46.6,
    '168': 46.5,
    '169': 46.5,
    '170': 46.4,
    '171': 46.3,
    '172': 46.2,
    '173': 46.1,
    '174': 46,
    '175': 45.9,
    '176': 45.8,
    '177': 45.7,
    '178': 45.6,
    '179': 45.5,
    '180': 45.5,
    '181': 45.4,
    '182': 45.3,
    '183': 45.2,
    '184': 45.1,
    '185': 45,
    '186': 44.9,
    '187': 44.8,
    '188': 44.8,
    '189': 44.7,
    '190': 44.6,
    '191': 44.5,
    '192': 44.4,
    '193': 44.3,
    '194': 44.2,
    '195': 44.1,
    '0.5': 64.6,
    '1.5': 64.5,
    '2.5': 64.4,
    '3.5': 64.3,
    '4.5': 64.1,
    '5.5': 64,
    '6.5': 63.9,
    '7.5': 63.8,
    '8.5': 63.6,
    '9.5': 63.5,
    '10.5': 63.4,
    '11.5': 63.3,
    '12.5': 63.1,
    '13.5': 63,
    '14.5': 62.9,
    '15.5': 62.8,
    '16.5': 62.6,
    '17.5': 62.5,
    '18.5': 62.4,
    '19.5': 62.3,
    '20.5': 62.1,
    '21.5': 62,
    '22.5': 61.9,
    '23.5': 61.8,
    '24.5': 61.7,
    '25.5': 61.5,
    '26.5': 61.4,
    '27.5': 61.3,
    '28.5': 61.2,
    '29.5': 61,
    '30.5': 60.9,
    '31.5': 60.8,
    '32.5': 60.7,
    '33.5': 60.6,
    '34.5': 60.5,
    '35.5': 60.3,
    '36.5': 60.2,
    '37.5': 60.1,
    '38.5': 60,
    '39.5': 59.9,
    '40.5': 59.7,
    '41.5': 59.6,
    '42.5': 59.5,
    '43.5': 59.4,
    '44.5': 59.3,
    '45.5': 59.2,
    '46.5': 59,
    '47.5': 58.9,
    '48.5': 58.8,
    '49.5': 58.7,
    '50.5': 58.6,
    '51.5': 58.5,
    '52.5': 58.4,
    '53.5': 58.2,
    '54.5': 58.1,
    '55.5': 58,
    '56.5': 57.9,
    '57.5': 57.8,
    '58.5': 57.7,
    '59.5': 57.6,
    '60.5': 57.4,
    '61.5': 57.3,
    '62.5': 57.2,
    '63.5': 57.1,
    '64.5': 57,
    '65.5': 56.9,
    '66.5': 56.8,
    '67.5': 56.7,
    '68.5': 56.5,
    '69.5': 56.4,
    '70.5': 56.3,
    '71.5': 56.2,
    '72.5': 56.1,
    '73.5': 56,
    '74.5': 55.9,
    '75.5': 55.8,
    '76.5': 55.7,
    '77.5': 55.6,
    '78.5': 55.5,
    '79.5': 55.3,
    '80.5': 55.2,
    '81.5': 55.1,
    '82.5': 55,
    '83.5': 54.9,
    '84.5': 54.8,
    '85.5': 54.7,
    '86.5': 54.6,
    '87.5': 54.5,
    '88.5': 54.4,
    '89.5': 54.3,
    '90.5': 54.2,
    '91.5': 54.1,
    '92.5': 54,
    '93.5': 53.8,
    '94.5': 53.7,
    '95.5': 53.6,
    '96.5': 53.5,
    '97.5': 53.4,
    '98.5': 53.3,
    '99.5': 53.2,
    '100.5': 53.1,
    '101.5': 53,
    '102.5': 52.9,
    '103.5': 52.8,
    '104.5': 52.7,
    '105.5': 52.6,
    '106.5': 52.5,
    '107.5': 52.4,
    '108.5': 52.3,
    '109.5': 52.2,
    '110.5': 52.1,
    '111.5': 52,
    '112.5': 51.9,
    '113.5': 51.8,
    '114.5': 51.7,
    '115.5': 51.6,
    '116.5': 51.5,
    '117.5': 51.4,
    '118.5': 51.3,
    '119.5': 51.2,
    '120.5': 51.1,
    '121.5': 51,
    '122.5': 50.9,
    '123.5': 50.8,
    '124.5': 50.7,
    '125.5': 50.6,
    '126.5': 50.5,
    '127.5': 50.4,
    '128.5': 50.3,
    '129.5': 50.2,
    '130.5': 50.1,
    '131.5': 50,
    '132.5': 49.9,
    '133.5': 49.8,
    '134.5': 49.7,
    '135.5': 49.6,
    '136.5': 49.5,
    '137.5': 49.4,
    '138.5': 49.3,
    '139.5': 49.2,
    '140.5': 49.1,
    '141.5': 49,
    '142.5': 48.9,
    '143.5': 48.8,
    '144.5': 48.7,
    '145.5': 48.6,
    '146.5': 48.5,
    '147.5': 48.4,
    '148.5': 48.4,
    '149.5': 48.3,
    '150.5': 48.2,
    '151.5': 48.1,
    '152.5': 48,
    '153.5': 47.9,
    '154.5': 47.8,
    '155.5': 47.7,
    '156.5': 47.6,
    '157.5': 47.5,
    '158.5': 47.4,
    '159.5': 47.3,
    '160.5': 47.2,
    '161.5': 47.1,
    '162.5': 47,
    '163.5': 47,
    '164.5': 46.9,
    '165.5': 46.8,
    '166.5': 46.7,
    '167.5': 46.6,
    '168.5': 46.5,
    '169.5': 46.4,
    '170.5': 46.3,
    '171.5': 46.2,
    '172.5': 46.1,
    '173.5': 46,
    '174.5': 46,
    '175.5': 45.9,
    '176.5': 45.8,
    '177.5': 45.7,
    '178.5': 45.6,
    '179.5': 45.5,
    '180.5': 45.4,
    '181.5': 45.3,
    '182.5': 45.2,
    '183.5': 45.1,
    '184.5': 45.1,
    '185.5': 45,
    '186.5': 44.9,
    '187.5': 44.8,
    '188.5': 44.7,
    '189.5': 44.6,
    '190.5': 44.5,
    '191.5': 44.4,
    '192.5': 44.4,
    '193.5': 44.3,
    '194.5': 44.2,
  },
  '58': {
    '0': 65.3,
    '1': 65.1,
    '2': 65,
    '3': 64.9,
    '4': 64.8,
    '5': 64.6,
    '6': 64.5,
    '7': 64.4,
    '8': 64.2,
    '9': 64.1,
    '10': 64,
    '11': 63.9,
    '12': 63.7,
    '13': 63.6,
    '14': 63.5,
    '15': 63.4,
    '16': 63.2,
    '17': 63.1,
    '18': 63,
    '19': 62.9,
    '20': 62.7,
    '21': 62.6,
    '22': 62.5,
    '23': 62.4,
    '24': 62.3,
    '25': 62.1,
    '26': 62,
    '27': 61.9,
    '28': 61.8,
    '29': 61.6,
    '30': 61.5,
    '31': 61.4,
    '32': 61.3,
    '33': 61.2,
    '34': 61,
    '35': 60.9,
    '36': 60.8,
    '37': 60.7,
    '38': 60.6,
    '39': 60.4,
    '40': 60.3,
    '41': 60.2,
    '42': 60.1,
    '43': 60,
    '44': 59.9,
    '45': 59.7,
    '46': 59.6,
    '47': 59.5,
    '48': 59.4,
    '49': 59.3,
    '50': 59.2,
    '51': 59,
    '52': 58.9,
    '53': 58.8,
    '54': 58.7,
    '55': 58.6,
    '56': 58.5,
    '57': 58.3,
    '58': 58.2,
    '59': 58.1,
    '60': 58,
    '61': 57.9,
    '62': 57.8,
    '63': 57.7,
    '64': 57.5,
    '65': 57.4,
    '66': 57.3,
    '67': 57.2,
    '68': 57.1,
    '69': 57,
    '70': 56.9,
    '71': 56.8,
    '72': 56.7,
    '73': 56.5,
    '74': 56.4,
    '75': 56.3,
    '76': 56.2,
    '77': 56.1,
    '78': 56,
    '79': 55.9,
    '80': 55.8,
    '81': 55.7,
    '82': 55.6,
    '83': 55.4,
    '84': 55.3,
    '85': 55.2,
    '86': 55.1,
    '87': 55,
    '88': 54.9,
    '89': 54.8,
    '90': 54.7,
    '91': 54.6,
    '92': 54.5,
    '93': 54.4,
    '94': 54.3,
    '95': 54.2,
    '96': 54.1,
    '97': 53.9,
    '98': 53.8,
    '99': 53.7,
    '100': 53.6,
    '101': 53.5,
    '102': 53.4,
    '103': 53.3,
    '104': 53.2,
    '105': 53.1,
    '106': 53,
    '107': 52.9,
    '108': 52.8,
    '109': 52.7,
    '110': 52.6,
    '111': 52.5,
    '112': 52.4,
    '113': 52.3,
    '114': 52.2,
    '115': 52.1,
    '116': 52,
    '117': 51.9,
    '118': 51.8,
    '119': 51.7,
    '120': 51.6,
    '121': 51.5,
    '122': 51.4,
    '123': 51.3,
    '124': 51.2,
    '125': 51.1,
    '126': 51,
    '127': 50.9,
    '128': 50.8,
    '129': 50.7,
    '130': 50.6,
    '131': 50.5,
    '132': 50.4,
    '133': 50.3,
    '134': 50.2,
    '135': 50.1,
    '136': 50,
    '137': 49.9,
    '138': 49.8,
    '139': 49.7,
    '140': 49.6,
    '141': 49.5,
    '142': 49.4,
    '143': 49.3,
    '144': 49.2,
    '145': 49.1,
    '146': 49,
    '147': 48.9,
    '148': 48.8,
    '149': 48.7,
    '150': 48.6,
    '151': 48.5,
    '152': 48.5,
    '153': 48.4,
    '154': 48.3,
    '155': 48.2,
    '156': 48.1,
    '157': 48,
    '158': 47.9,
    '159': 47.8,
    '160': 47.7,
    '161': 47.6,
    '162': 47.5,
    '163': 47.4,
    '164': 47.3,
    '165': 47.2,
    '166': 47.1,
    '167': 47.1,
    '168': 47,
    '169': 46.9,
    '170': 46.8,
    '171': 46.7,
    '172': 46.6,
    '173': 46.5,
    '174': 46.4,
    '175': 46.3,
    '176': 46.2,
    '177': 46.1,
    '178': 46.1,
    '179': 46,
    '180': 45.9,
    '181': 45.8,
    '182': 45.7,
    '183': 45.6,
    '184': 45.5,
    '185': 45.4,
    '186': 45.3,
    '187': 45.2,
    '188': 45.2,
    '189': 45.1,
    '190': 45,
    '191': 44.9,
    '192': 44.8,
    '193': 44.7,
    '194': 44.6,
    '195': 44.5,
    '0.5': 65.2,
    '1.5': 65.1,
    '2.5': 65,
    '3.5': 64.8,
    '4.5': 64.7,
    '5.5': 64.6,
    '6.5': 64.4,
    '7.5': 64.3,
    '8.5': 64.2,
    '9.5': 64.1,
    '10.5': 63.9,
    '11.5': 63.8,
    '12.5': 63.7,
    '13.5': 63.6,
    '14.5': 63.4,
    '15.5': 63.3,
    '16.5': 63.2,
    '17.5': 63.1,
    '18.5': 62.9,
    '19.5': 62.8,
    '20.5': 62.7,
    '21.5': 62.6,
    '22.5': 62.4,
    '23.5': 62.3,
    '24.5': 62.2,
    '25.5': 62.1,
    '26.5': 61.9,
    '27.5': 61.8,
    '28.5': 61.7,
    '29.5': 61.6,
    '30.5': 61.5,
    '31.5': 61.3,
    '32.5': 61.2,
    '33.5': 61.1,
    '34.5': 61,
    '35.5': 60.9,
    '36.5': 60.7,
    '37.5': 60.6,
    '38.5': 60.5,
    '39.5': 60.4,
    '40.5': 60.3,
    '41.5': 60.1,
    '42.5': 60,
    '43.5': 59.9,
    '44.5': 59.8,
    '45.5': 59.7,
    '46.5': 59.6,
    '47.5': 59.4,
    '48.5': 59.3,
    '49.5': 59.2,
    '50.5': 59.1,
    '51.5': 59,
    '52.5': 58.9,
    '53.5': 58.7,
    '54.5': 58.6,
    '55.5': 58.5,
    '56.5': 58.4,
    '57.5': 58.3,
    '58.5': 58.2,
    '59.5': 58.1,
    '60.5': 57.9,
    '61.5': 57.8,
    '62.5': 57.7,
    '63.5': 57.6,
    '64.5': 57.5,
    '65.5': 57.4,
    '66.5': 57.3,
    '67.5': 57.2,
    '68.5': 57,
    '69.5': 56.9,
    '70.5': 56.8,
    '71.5': 56.7,
    '72.5': 56.6,
    '73.5': 56.5,
    '74.5': 56.4,
    '75.5': 56.3,
    '76.5': 56.2,
    '77.5': 56,
    '78.5': 55.9,
    '79.5': 55.8,
    '80.5': 55.7,
    '81.5': 55.6,
    '82.5': 55.5,
    '83.5': 55.4,
    '84.5': 55.3,
    '85.5': 55.2,
    '86.5': 55.1,
    '87.5': 55,
    '88.5': 54.9,
    '89.5': 54.7,
    '90.5': 54.6,
    '91.5': 54.5,
    '92.5': 54.4,
    '93.5': 54.3,
    '94.5': 54.2,
    '95.5': 54.1,
    '96.5': 54,
    '97.5': 53.9,
    '98.5': 53.8,
    '99.5': 53.7,
    '100.5': 53.6,
    '101.5': 53.5,
    '102.5': 53.4,
    '103.5': 53.3,
    '104.5': 53.2,
    '105.5': 53.1,
    '106.5': 53,
    '107.5': 52.9,
    '108.5': 52.7,
    '109.5': 52.6,
    '110.5': 52.5,
    '111.5': 52.4,
    '112.5': 52.3,
    '113.5': 52.2,
    '114.5': 52.1,
    '115.5': 52,
    '116.5': 51.9,
    '117.5': 51.8,
    '118.5': 51.7,
    '119.5': 51.6,
    '120.5': 51.5,
    '121.5': 51.4,
    '122.5': 51.3,
    '123.5': 51.2,
    '124.5': 51.1,
    '125.5': 51,
    '126.5': 50.9,
    '127.5': 50.8,
    '128.5': 50.7,
    '129.5': 50.6,
    '130.5': 50.5,
    '131.5': 50.4,
    '132.5': 50.3,
    '133.5': 50.2,
    '134.5': 50.1,
    '135.5': 50,
    '136.5': 49.9,
    '137.5': 49.8,
    '138.5': 49.7,
    '139.5': 49.7,
    '140.5': 49.6,
    '141.5': 49.5,
    '142.5': 49.4,
    '143.5': 49.3,
    '144.5': 49.2,
    '145.5': 49.1,
    '146.5': 49,
    '147.5': 48.9,
    '148.5': 48.8,
    '149.5': 48.7,
    '150.5': 48.6,
    '151.5': 48.5,
    '152.5': 48.4,
    '153.5': 48.3,
    '154.5': 48.2,
    '155.5': 48.1,
    '156.5': 48,
    '157.5': 47.9,
    '158.5': 47.8,
    '159.5': 47.7,
    '160.5': 47.7,
    '161.5': 47.6,
    '162.5': 47.5,
    '163.5': 47.4,
    '164.5': 47.3,
    '165.5': 47.2,
    '166.5': 47.1,
    '167.5': 47,
    '168.5': 46.9,
    '169.5': 46.8,
    '170.5': 46.7,
    '171.5': 46.6,
    '172.5': 46.5,
    '173.5': 46.4,
    '174.5': 46.4,
    '175.5': 46.3,
    '176.5': 46.2,
    '177.5': 46.1,
    '178.5': 46,
    '179.5': 45.9,
    '180.5': 45.8,
    '181.5': 45.7,
    '182.5': 45.6,
    '183.5': 45.6,
    '184.5': 45.5,
    '185.5': 45.4,
    '186.5': 45.3,
    '187.5': 45.2,
    '188.5': 45.1,
    '189.5': 45,
    '190.5': 44.9,
    '191.5': 44.8,
    '192.5': 44.8,
    '193.5': 44.7,
    '194.5': 44.6,
  },
  '58.5': {
    '0': 65.8,
    '1': 65.7,
    '2': 65.6,
    '3': 65.4,
    '4': 65.3,
    '5': 65.2,
    '6': 65.1,
    '7': 64.9,
    '8': 64.8,
    '9': 64.7,
    '10': 64.5,
    '11': 64.4,
    '12': 64.3,
    '13': 64.2,
    '14': 64,
    '15': 63.9,
    '16': 63.8,
    '17': 63.7,
    '18': 63.5,
    '19': 63.4,
    '20': 63.3,
    '21': 63.2,
    '22': 63,
    '23': 62.9,
    '24': 62.8,
    '25': 62.7,
    '26': 62.5,
    '27': 62.4,
    '28': 62.3,
    '29': 62.2,
    '30': 62.1,
    '31': 61.9,
    '32': 61.8,
    '33': 61.7,
    '34': 61.6,
    '35': 61.4,
    '36': 61.3,
    '37': 61.2,
    '38': 61.1,
    '39': 61,
    '40': 60.8,
    '41': 60.7,
    '42': 60.6,
    '43': 60.5,
    '44': 60.4,
    '45': 60.2,
    '46': 60.1,
    '47': 60,
    '48': 59.9,
    '49': 59.8,
    '50': 59.7,
    '51': 59.5,
    '52': 59.4,
    '53': 59.3,
    '54': 59.2,
    '55': 59.1,
    '56': 59,
    '57': 58.8,
    '58': 58.7,
    '59': 58.6,
    '60': 58.5,
    '61': 58.4,
    '62': 58.3,
    '63': 58.2,
    '64': 58,
    '65': 57.9,
    '66': 57.8,
    '67': 57.7,
    '68': 57.6,
    '69': 57.5,
    '70': 57.4,
    '71': 57.3,
    '72': 57.1,
    '73': 57,
    '74': 56.9,
    '75': 56.8,
    '76': 56.7,
    '77': 56.6,
    '78': 56.5,
    '79': 56.4,
    '80': 56.3,
    '81': 56.1,
    '82': 56,
    '83': 55.9,
    '84': 55.8,
    '85': 55.7,
    '86': 55.6,
    '87': 55.5,
    '88': 55.4,
    '89': 55.3,
    '90': 55.2,
    '91': 55.1,
    '92': 54.9,
    '93': 54.8,
    '94': 54.7,
    '95': 54.6,
    '96': 54.5,
    '97': 54.4,
    '98': 54.3,
    '99': 54.2,
    '100': 54.1,
    '101': 54,
    '102': 53.9,
    '103': 53.8,
    '104': 53.7,
    '105': 53.6,
    '106': 53.5,
    '107': 53.4,
    '108': 53.3,
    '109': 53.2,
    '110': 53.1,
    '111': 52.9,
    '112': 52.8,
    '113': 52.7,
    '114': 52.6,
    '115': 52.5,
    '116': 52.4,
    '117': 52.3,
    '118': 52.2,
    '119': 52.1,
    '120': 52,
    '121': 51.9,
    '122': 51.8,
    '123': 51.7,
    '124': 51.6,
    '125': 51.5,
    '126': 51.4,
    '127': 51.3,
    '128': 51.2,
    '129': 51.1,
    '130': 51,
    '131': 50.9,
    '132': 50.8,
    '133': 50.7,
    '134': 50.6,
    '135': 50.5,
    '136': 50.4,
    '137': 50.3,
    '138': 50.2,
    '139': 50.1,
    '140': 50,
    '141': 49.9,
    '142': 49.8,
    '143': 49.7,
    '144': 49.6,
    '145': 49.6,
    '146': 49.5,
    '147': 49.4,
    '148': 49.3,
    '149': 49.2,
    '150': 49.1,
    '151': 49,
    '152': 48.9,
    '153': 48.8,
    '154': 48.7,
    '155': 48.6,
    '156': 48.5,
    '157': 48.4,
    '158': 48.3,
    '159': 48.2,
    '160': 48.1,
    '161': 48,
    '162': 47.9,
    '163': 47.8,
    '164': 47.8,
    '165': 47.7,
    '166': 47.6,
    '167': 47.5,
    '168': 47.4,
    '169': 47.3,
    '170': 47.2,
    '171': 47.1,
    '172': 47,
    '173': 46.9,
    '174': 46.8,
    '175': 46.7,
    '176': 46.6,
    '177': 46.6,
    '178': 46.5,
    '179': 46.4,
    '180': 46.3,
    '181': 46.2,
    '182': 46.1,
    '183': 46,
    '184': 45.9,
    '185': 45.8,
    '186': 45.7,
    '187': 45.7,
    '188': 45.6,
    '189': 45.5,
    '190': 45.4,
    '191': 45.3,
    '192': 45.2,
    '193': 45.1,
    '194': 45,
    '195': 44.9,
    '0.5': 65.8,
    '1.5': 65.6,
    '2.5': 65.5,
    '3.5': 65.4,
    '4.5': 65.3,
    '5.5': 65.1,
    '6.5': 65,
    '7.5': 64.9,
    '8.5': 64.7,
    '9.5': 64.6,
    '10.5': 64.5,
    '11.5': 64.4,
    '12.5': 64.2,
    '13.5': 64.1,
    '14.5': 64,
    '15.5': 63.8,
    '16.5': 63.7,
    '17.5': 63.6,
    '18.5': 63.5,
    '19.5': 63.3,
    '20.5': 63.2,
    '21.5': 63.1,
    '22.5': 63,
    '23.5': 62.8,
    '24.5': 62.7,
    '25.5': 62.6,
    '26.5': 62.5,
    '27.5': 62.4,
    '28.5': 62.2,
    '29.5': 62.1,
    '30.5': 62,
    '31.5': 61.9,
    '32.5': 61.7,
    '33.5': 61.6,
    '34.5': 61.5,
    '35.5': 61.4,
    '36.5': 61.3,
    '37.5': 61.1,
    '38.5': 61,
    '39.5': 60.9,
    '40.5': 60.8,
    '41.5': 60.7,
    '42.5': 60.5,
    '43.5': 60.4,
    '44.5': 60.3,
    '45.5': 60.2,
    '46.5': 60.1,
    '47.5': 60,
    '48.5': 59.8,
    '49.5': 59.7,
    '50.5': 59.6,
    '51.5': 59.5,
    '52.5': 59.4,
    '53.5': 59.3,
    '54.5': 59.1,
    '55.5': 59,
    '56.5': 58.9,
    '57.5': 58.8,
    '58.5': 58.7,
    '59.5': 58.6,
    '60.5': 58.4,
    '61.5': 58.3,
    '62.5': 58.2,
    '63.5': 58.1,
    '64.5': 58,
    '65.5': 57.9,
    '66.5': 57.8,
    '67.5': 57.6,
    '68.5': 57.5,
    '69.5': 57.4,
    '70.5': 57.3,
    '71.5': 57.2,
    '72.5': 57.1,
    '73.5': 57,
    '74.5': 56.9,
    '75.5': 56.8,
    '76.5': 56.6,
    '77.5': 56.5,
    '78.5': 56.4,
    '79.5': 56.3,
    '80.5': 56.2,
    '81.5': 56.1,
    '82.5': 56,
    '83.5': 55.9,
    '84.5': 55.8,
    '85.5': 55.7,
    '86.5': 55.5,
    '87.5': 55.4,
    '88.5': 55.3,
    '89.5': 55.2,
    '90.5': 55.1,
    '91.5': 55,
    '92.5': 54.9,
    '93.5': 54.8,
    '94.5': 54.7,
    '95.5': 54.6,
    '96.5': 54.5,
    '97.5': 54.4,
    '98.5': 54.3,
    '99.5': 54.2,
    '100.5': 54,
    '101.5': 53.9,
    '102.5': 53.8,
    '103.5': 53.7,
    '104.5': 53.6,
    '105.5': 53.5,
    '106.5': 53.4,
    '107.5': 53.3,
    '108.5': 53.2,
    '109.5': 53.1,
    '110.5': 53,
    '111.5': 52.9,
    '112.5': 52.8,
    '113.5': 52.7,
    '114.5': 52.6,
    '115.5': 52.5,
    '116.5': 52.4,
    '117.5': 52.3,
    '118.5': 52.2,
    '119.5': 52.1,
    '120.5': 52,
    '121.5': 51.9,
    '122.5': 51.8,
    '123.5': 51.7,
    '124.5': 51.6,
    '125.5': 51.5,
    '126.5': 51.4,
    '127.5': 51.3,
    '128.5': 51.2,
    '129.5': 51.1,
    '130.5': 51,
    '131.5': 50.9,
    '132.5': 50.8,
    '133.5': 50.7,
    '134.5': 50.6,
    '135.5': 50.5,
    '136.5': 50.4,
    '137.5': 50.3,
    '138.5': 50.2,
    '139.5': 50.1,
    '140.5': 50,
    '141.5': 49.9,
    '142.5': 49.8,
    '143.5': 49.7,
    '144.5': 49.6,
    '145.5': 49.5,
    '146.5': 49.4,
    '147.5': 49.3,
    '148.5': 49.2,
    '149.5': 49.1,
    '150.5': 49,
    '151.5': 48.9,
    '152.5': 48.8,
    '153.5': 48.7,
    '154.5': 48.6,
    '155.5': 48.5,
    '156.5': 48.5,
    '157.5': 48.4,
    '158.5': 48.3,
    '159.5': 48.2,
    '160.5': 48.1,
    '161.5': 48,
    '162.5': 47.9,
    '163.5': 47.8,
    '164.5': 47.7,
    '165.5': 47.6,
    '166.5': 47.5,
    '167.5': 47.4,
    '168.5': 47.3,
    '169.5': 47.2,
    '170.5': 47.1,
    '171.5': 47.1,
    '172.5': 47,
    '173.5': 46.9,
    '174.5': 46.8,
    '175.5': 46.7,
    '176.5': 46.6,
    '177.5': 46.5,
    '178.5': 46.4,
    '179.5': 46.3,
    '180.5': 46.2,
    '181.5': 46.1,
    '182.5': 46.1,
    '183.5': 46,
    '184.5': 45.9,
    '185.5': 45.8,
    '186.5': 45.7,
    '187.5': 45.6,
    '188.5': 45.5,
    '189.5': 45.4,
    '190.5': 45.3,
    '191.5': 45.3,
    '192.5': 45.2,
    '193.5': 45.1,
    '194.5': 45,
  },
  '59': {
    '0': 66.4,
    '1': 66.3,
    '2': 66.1,
    '3': 66,
    '4': 65.9,
    '5': 65.7,
    '6': 65.6,
    '7': 65.5,
    '8': 65.4,
    '9': 65.2,
    '10': 65.1,
    '11': 65,
    '12': 64.8,
    '13': 64.7,
    '14': 64.6,
    '15': 64.5,
    '16': 64.3,
    '17': 64.2,
    '18': 64,
    '19': 63.9,
    '20': 63.8,
    '21': 63.7,
    '22': 63.6,
    '23': 63.4,
    '24': 63.3,
    '25': 63.2,
    '26': 63.1,
    '27': 62.9,
    '28': 62.8,
    '29': 62.7,
    '30': 62.6,
    '31': 62.5,
    '32': 62.3,
    '33': 62.2,
    '34': 62.1,
    '35': 62,
    '36': 61.8,
    '37': 61.7,
    '38': 61.6,
    '39': 61.5,
    '40': 61.4,
    '41': 61.2,
    '42': 61.1,
    '43': 61,
    '44': 60.9,
    '45': 60.8,
    '46': 60.6,
    '47': 60.5,
    '48': 60.4,
    '49': 60.3,
    '50': 60.2,
    '51': 60,
    '52': 59.9,
    '53': 59.8,
    '54': 59.7,
    '55': 59.6,
    '56': 59.5,
    '57': 59.3,
    '58': 59.2,
    '59': 59.1,
    '60': 59,
    '61': 58.9,
    '62': 58.8,
    '63': 58.7,
    '64': 58.5,
    '65': 58.4,
    '66': 58.3,
    '67': 58.2,
    '68': 58.1,
    '69': 58,
    '70': 57.9,
    '71': 57.7,
    '72': 57.6,
    '73': 57.5,
    '74': 57.4,
    '75': 57.3,
    '76': 57.2,
    '77': 57.1,
    '78': 57,
    '79': 56.8,
    '80': 56.7,
    '81': 56.6,
    '82': 56.5,
    '83': 56.4,
    '84': 56.3,
    '85': 56.2,
    '86': 56.1,
    '87': 56,
    '88': 55.9,
    '89': 55.7,
    '90': 55.6,
    '91': 55.5,
    '92': 55.4,
    '93': 55.3,
    '94': 55.2,
    '95': 55.1,
    '96': 55,
    '97': 54.9,
    '98': 54.8,
    '99': 54.7,
    '100': 54.6,
    '101': 54.5,
    '102': 54.4,
    '103': 54.2,
    '104': 54.1,
    '105': 54,
    '106': 53.9,
    '107': 53.8,
    '108': 53.7,
    '109': 53.6,
    '110': 53.5,
    '111': 53.4,
    '112': 53.3,
    '113': 53.2,
    '114': 53.1,
    '115': 53,
    '116': 52.9,
    '117': 52.8,
    '118': 52.7,
    '119': 52.6,
    '120': 52.5,
    '121': 52.4,
    '122': 52.3,
    '123': 52.2,
    '124': 52.1,
    '125': 52,
    '126': 51.9,
    '127': 51.8,
    '128': 51.7,
    '129': 51.6,
    '130': 51.5,
    '131': 51.4,
    '132': 51.3,
    '133': 51.2,
    '134': 51.1,
    '135': 51,
    '136': 50.9,
    '137': 50.8,
    '138': 50.7,
    '139': 50.6,
    '140': 50.5,
    '141': 50.4,
    '142': 50.3,
    '143': 50.2,
    '144': 50.1,
    '145': 50,
    '146': 49.9,
    '147': 49.8,
    '148': 49.7,
    '149': 49.6,
    '150': 49.5,
    '151': 49.4,
    '152': 49.3,
    '153': 49.2,
    '154': 49.1,
    '155': 49,
    '156': 48.9,
    '157': 48.8,
    '158': 48.7,
    '159': 48.6,
    '160': 48.5,
    '161': 48.4,
    '162': 48.4,
    '163': 48.3,
    '164': 48.2,
    '165': 48.1,
    '166': 48,
    '167': 47.9,
    '168': 47.8,
    '169': 47.7,
    '170': 47.6,
    '171': 47.5,
    '172': 47.4,
    '173': 47.3,
    '174': 47.2,
    '175': 47.1,
    '176': 47.1,
    '177': 47,
    '178': 46.9,
    '179': 46.8,
    '180': 46.7,
    '181': 46.6,
    '182': 46.5,
    '183': 46.4,
    '184': 46.3,
    '185': 46.2,
    '186': 46.1,
    '187': 46.1,
    '188': 46,
    '189': 45.9,
    '190': 45.8,
    '191': 45.7,
    '192': 45.6,
    '193': 45.5,
    '194': 45.4,
    '195': 45.3,
    '0.5': 66.3,
    '1.5': 66.2,
    '2.5': 66.1,
    '3.5': 65.9,
    '4.5': 65.8,
    '5.5': 65.7,
    '6.5': 65.5,
    '7.5': 65.4,
    '8.5': 65.3,
    '9.5': 65.2,
    '10.5': 65,
    '11.5': 64.9,
    '12.5': 64.8,
    '13.5': 64.6,
    '14.5': 64.5,
    '15.5': 64.4,
    '16.5': 64.3,
    '17.5': 64.1,
    '18.5': 64,
    '19.5': 63.9,
    '20.5': 63.8,
    '21.5': 63.6,
    '22.5': 63.5,
    '23.5': 63.4,
    '24.5': 63.3,
    '25.5': 63.1,
    '26.5': 63,
    '27.5': 62.9,
    '28.5': 62.8,
    '29.5': 62.6,
    '30.5': 62.5,
    '31.5': 62.4,
    '32.5': 62.3,
    '33.5': 62.1,
    '34.5': 62,
    '35.5': 61.9,
    '36.5': 61.8,
    '37.5': 61.7,
    '38.5': 61.5,
    '39.5': 61.4,
    '40.5': 61.3,
    '41.5': 61.2,
    '42.5': 61.1,
    '43.5': 60.9,
    '44.5': 60.8,
    '45.5': 60.7,
    '46.5': 60.6,
    '47.5': 60.5,
    '48.5': 60.3,
    '49.5': 60.2,
    '50.5': 60.1,
    '51.5': 60,
    '52.5': 59.9,
    '53.5': 59.8,
    '54.5': 59.6,
    '55.5': 59.5,
    '56.5': 59.4,
    '57.5': 59.3,
    '58.5': 59.2,
    '59.5': 59.1,
    '60.5': 58.9,
    '61.5': 58.8,
    '62.5': 58.7,
    '63.5': 58.6,
    '64.5': 58.5,
    '65.5': 58.4,
    '66.5': 58.3,
    '67.5': 58.1,
    '68.5': 58,
    '69.5': 57.9,
    '70.5': 57.8,
    '71.5': 57.7,
    '72.5': 57.6,
    '73.5': 57.5,
    '74.5': 57.3,
    '75.5': 57.2,
    '76.5': 57.1,
    '77.5': 57,
    '78.5': 56.9,
    '79.5': 56.8,
    '80.5': 56.7,
    '81.5': 56.6,
    '82.5': 56.5,
    '83.5': 56.3,
    '84.5': 56.2,
    '85.5': 56.1,
    '86.5': 56,
    '87.5': 55.9,
    '88.5': 55.8,
    '89.5': 55.7,
    '90.5': 55.6,
    '91.5': 55.5,
    '92.5': 55.4,
    '93.5': 55.3,
    '94.5': 55.2,
    '95.5': 55,
    '96.5': 54.9,
    '97.5': 54.8,
    '98.5': 54.7,
    '99.5': 54.6,
    '100.5': 54.5,
    '101.5': 54.5,
    '102.5': 54.3,
    '103.5': 54.2,
    '104.5': 54.1,
    '105.5': 54,
    '106.5': 53.9,
    '107.5': 53.8,
    '108.5': 53.7,
    '109.5': 53.6,
    '110.5': 53.5,
    '111.5': 53.4,
    '112.5': 53.3,
    '113.5': 53.1,
    '114.5': 53,
    '115.5': 52.9,
    '116.5': 52.8,
    '117.5': 52.7,
    '118.5': 52.6,
    '119.5': 52.5,
    '120.5': 52.4,
    '121.5': 52.3,
    '122.5': 52.2,
    '123.5': 52.1,
    '124.5': 52,
    '125.5': 51.9,
    '126.5': 51.8,
    '127.5': 51.7,
    '128.5': 51.6,
    '129.5': 51.5,
    '130.5': 51.4,
    '131.5': 51.3,
    '132.5': 51.2,
    '133.5': 51.1,
    '134.5': 51,
    '135.5': 50.9,
    '136.5': 50.8,
    '137.5': 50.7,
    '138.5': 50.6,
    '139.5': 50.5,
    '140.5': 50.4,
    '141.5': 50.3,
    '142.5': 50.2,
    '143.5': 50.1,
    '144.5': 50,
    '145.5': 49.9,
    '146.5': 49.8,
    '147.5': 49.7,
    '148.5': 49.6,
    '149.5': 49.5,
    '150.5': 49.5,
    '151.5': 49.4,
    '152.5': 49.3,
    '153.5': 49.2,
    '154.5': 49.1,
    '155.5': 49,
    '156.5': 48.9,
    '157.5': 48.8,
    '158.5': 48.7,
    '159.5': 48.6,
    '160.5': 48.5,
    '161.5': 48.4,
    '162.5': 48.3,
    '163.5': 48.2,
    '164.5': 48.1,
    '165.5': 48,
    '166.5': 47.9,
    '167.5': 47.8,
    '168.5': 47.7,
    '169.5': 47.7,
    '170.5': 47.6,
    '171.5': 47.5,
    '172.5': 47.4,
    '173.5': 47.3,
    '174.5': 47.2,
    '175.5': 47.1,
    '176.5': 47,
    '177.5': 46.9,
    '178.5': 46.8,
    '179.5': 46.7,
    '180.5': 46.6,
    '181.5': 46.6,
    '182.5': 46.5,
    '183.5': 46.4,
    '184.5': 46.3,
    '185.5': 46.2,
    '186.5': 46.1,
    '187.5': 46,
    '188.5': 45.9,
    '189.5': 45.8,
    '190.5': 45.7,
    '191.5': 45.7,
    '192.5': 45.6,
    '193.5': 45.5,
    '194.5': 45.4,
  },
  '59.5': {
    '0': 67,
    '1': 66.8,
    '2': 66.7,
    '3': 66.6,
    '4': 66.4,
    '5': 66.3,
    '6': 66.2,
    '7': 66,
    '8': 65.9,
    '9': 65.8,
    '10': 65.6,
    '11': 65.5,
    '12': 65.4,
    '13': 65.3,
    '14': 65.1,
    '15': 65,
    '16': 64.9,
    '17': 64.7,
    '18': 64.6,
    '19': 64.5,
    '20': 64.4,
    '21': 64.2,
    '22': 64.1,
    '23': 64,
    '24': 63.9,
    '25': 63.7,
    '26': 63.6,
    '27': 63.5,
    '28': 63.4,
    '29': 63.2,
    '30': 63.1,
    '31': 63,
    '32': 62.9,
    '33': 62.7,
    '34': 62.6,
    '35': 62.5,
    '36': 62.4,
    '37': 62.2,
    '38': 62.1,
    '39': 62,
    '40': 61.9,
    '41': 61.8,
    '42': 61.6,
    '43': 61.5,
    '44': 61.4,
    '45': 61.3,
    '46': 61.2,
    '47': 61,
    '48': 60.9,
    '49': 60.8,
    '50': 60.7,
    '51': 60.6,
    '52': 60.4,
    '53': 60.3,
    '54': 60.2,
    '55': 60.1,
    '56': 60,
    '57': 59.8,
    '58': 59.7,
    '59': 59.6,
    '60': 59.5,
    '61': 59.4,
    '62': 59.3,
    '63': 59.2,
    '64': 59,
    '65': 58.9,
    '66': 58.8,
    '67': 58.7,
    '68': 58.6,
    '69': 58.5,
    '70': 58.3,
    '71': 58.2,
    '72': 58.1,
    '73': 58,
    '74': 57.9,
    '75': 57.8,
    '76': 57.7,
    '77': 57.6,
    '78': 57.4,
    '79': 57.3,
    '80': 57.2,
    '81': 57.1,
    '82': 57,
    '83': 56.9,
    '84': 56.8,
    '85': 56.7,
    '86': 56.6,
    '87': 56.4,
    '88': 56.3,
    '89': 56.2,
    '90': 56.1,
    '91': 56,
    '92': 55.9,
    '93': 55.8,
    '94': 55.7,
    '95': 55.6,
    '96': 55.5,
    '97': 55.4,
    '98': 55.2,
    '99': 55.1,
    '100': 55,
    '101': 54.9,
    '102': 54.8,
    '103': 54.7,
    '104': 54.6,
    '105': 54.5,
    '106': 54.4,
    '107': 54.3,
    '108': 54.2,
    '109': 54.1,
    '110': 54,
    '111': 53.9,
    '112': 53.8,
    '113': 53.7,
    '114': 53.5,
    '115': 53.4,
    '116': 53.3,
    '117': 53.2,
    '118': 53.1,
    '119': 53,
    '120': 52.9,
    '121': 52.8,
    '122': 52.7,
    '123': 52.6,
    '124': 52.5,
    '125': 52.4,
    '126': 52.3,
    '127': 52.2,
    '128': 52.1,
    '129': 52,
    '130': 51.9,
    '131': 51.8,
    '132': 51.7,
    '133': 51.6,
    '134': 51.5,
    '135': 51.4,
    '136': 51.3,
    '137': 51.2,
    '138': 51.1,
    '139': 51,
    '140': 50.9,
    '141': 50.8,
    '142': 50.7,
    '143': 50.6,
    '144': 50.5,
    '145': 50.4,
    '146': 50.3,
    '147': 50.2,
    '148': 50.1,
    '149': 50,
    '150': 49.9,
    '151': 49.8,
    '152': 49.7,
    '153': 49.6,
    '154': 49.5,
    '155': 49.4,
    '156': 49.3,
    '157': 49.3,
    '158': 49.2,
    '159': 49.1,
    '160': 49,
    '161': 48.9,
    '162': 48.8,
    '163': 48.7,
    '164': 48.6,
    '165': 48.5,
    '166': 48.4,
    '167': 48.3,
    '168': 48.2,
    '169': 48.1,
    '170': 48,
    '171': 47.9,
    '172': 47.8,
    '173': 47.7,
    '174': 47.7,
    '175': 47.6,
    '176': 47.5,
    '177': 47.4,
    '178': 47.3,
    '179': 47.2,
    '180': 47.1,
    '181': 47,
    '182': 46.9,
    '183': 46.8,
    '184': 46.7,
    '185': 46.6,
    '186': 46.6,
    '187': 46.5,
    '188': 46.4,
    '189': 46.3,
    '190': 46.2,
    '191': 46.1,
    '192': 46,
    '193': 45.9,
    '194': 45.8,
    '195': 45.7,
    '0.5': 66.9,
    '1.5': 66.8,
    '2.5': 66.6,
    '3.5': 66.5,
    '4.5': 66.4,
    '5.5': 66.2,
    '6.5': 66.1,
    '7.5': 66,
    '8.5': 65.8,
    '9.5': 65.7,
    '10.5': 65.6,
    '11.5': 65.4,
    '12.5': 65.3,
    '13.5': 65.2,
    '14.5': 65.1,
    '15.5': 64.9,
    '16.5': 64.8,
    '17.5': 64.7,
    '18.5': 64.6,
    '19.5': 64.4,
    '20.5': 64.3,
    '21.5': 64.2,
    '22.5': 64,
    '23.5': 63.9,
    '24.5': 63.8,
    '25.5': 63.7,
    '26.5': 63.5,
    '27.5': 63.4,
    '28.5': 63.3,
    '29.5': 63.2,
    '30.5': 63,
    '31.5': 62.9,
    '32.5': 62.8,
    '33.5': 62.7,
    '34.5': 62.6,
    '35.5': 62.4,
    '36.5': 62.3,
    '37.5': 62.2,
    '38.5': 62.1,
    '39.5': 61.9,
    '40.5': 61.8,
    '41.5': 61.7,
    '42.5': 61.6,
    '43.5': 61.5,
    '44.5': 61.3,
    '45.5': 61.2,
    '46.5': 61.1,
    '47.5': 61,
    '48.5': 60.9,
    '49.5': 60.7,
    '50.5': 60.6,
    '51.5': 60.5,
    '52.5': 60.4,
    '53.5': 60.3,
    '54.5': 60.1,
    '55.5': 60,
    '56.5': 59.9,
    '57.5': 59.8,
    '58.5': 59.7,
    '59.5': 59.6,
    '60.5': 59.4,
    '61.5': 59.3,
    '62.5': 59.2,
    '63.5': 59.1,
    '64.5': 59,
    '65.5': 58.9,
    '66.5': 58.7,
    '67.5': 58.6,
    '68.5': 58.5,
    '69.5': 58.4,
    '70.5': 58.3,
    '71.5': 58.2,
    '72.5': 58.1,
    '73.5': 57.9,
    '74.5': 57.8,
    '75.5': 57.7,
    '76.5': 57.6,
    '77.5': 57.5,
    '78.5': 57.4,
    '79.5': 57.3,
    '80.5': 57.2,
    '81.5': 57.1,
    '82.5': 56.9,
    '83.5': 56.8,
    '84.5': 56.7,
    '85.5': 56.6,
    '86.5': 56.5,
    '87.5': 56.4,
    '88.5': 56.3,
    '89.5': 56.2,
    '90.5': 56.1,
    '91.5': 55.9,
    '92.5': 55.8,
    '93.5': 55.7,
    '94.5': 55.6,
    '95.5': 55.5,
    '96.5': 55.4,
    '97.5': 55.3,
    '98.5': 55.2,
    '99.5': 55.1,
    '100.5': 55,
    '101.5': 54.9,
    '102.5': 54.8,
    '103.5': 54.7,
    '104.5': 54.5,
    '105.5': 54.4,
    '106.5': 54.3,
    '107.5': 54.2,
    '108.5': 54.1,
    '109.5': 54,
    '110.5': 53.9,
    '111.5': 53.8,
    '112.5': 53.7,
    '113.5': 53.6,
    '114.5': 53.5,
    '115.5': 53.4,
    '116.5': 53.3,
    '117.5': 53.2,
    '118.5': 53.1,
    '119.5': 53,
    '120.5': 52.9,
    '121.5': 52.8,
    '122.5': 52.7,
    '123.5': 52.6,
    '124.5': 52.5,
    '125.5': 52.4,
    '126.5': 52.3,
    '127.5': 52.2,
    '128.5': 52.1,
    '129.5': 52,
    '130.5': 51.9,
    '131.5': 51.8,
    '132.5': 51.7,
    '133.5': 51.6,
    '134.5': 51.5,
    '135.5': 51.4,
    '136.5': 51.3,
    '137.5': 51.2,
    '138.5': 51.1,
    '139.5': 51,
    '140.5': 50.9,
    '141.5': 50.8,
    '142.5': 50.7,
    '143.5': 50.6,
    '144.5': 50.5,
    '145.5': 50.4,
    '146.5': 50.3,
    '147.5': 50.2,
    '148.5': 50.1,
    '149.5': 50,
    '150.5': 49.9,
    '151.5': 49.8,
    '152.5': 49.7,
    '153.5': 49.6,
    '154.5': 49.5,
    '155.5': 49.4,
    '156.5': 49.3,
    '157.5': 49.2,
    '158.5': 49.1,
    '159.5': 49,
    '160.5': 48.9,
    '161.5': 48.8,
    '162.5': 48.7,
    '163.5': 48.6,
    '164.5': 48.5,
    '165.5': 48.4,
    '166.5': 48.3,
    '167.5': 48.3,
    '168.5': 48.2,
    '169.5': 48.1,
    '170.5': 48,
    '171.5': 47.9,
    '172.5': 47.8,
    '173.5': 47.7,
    '174.5': 47.6,
    '175.5': 47.5,
    '176.5': 47.4,
    '177.5': 47.3,
    '178.5': 47.2,
    '179.5': 47.1,
    '180.5': 47.1,
    '181.5': 47,
    '182.5': 46.9,
    '183.5': 46.8,
    '184.5': 46.7,
    '185.5': 46.6,
    '186.5': 46.5,
    '187.5': 46.4,
    '188.5': 46.3,
    '189.5': 46.2,
    '190.5': 46.1,
    '191.5': 46.1,
    '192.5': 46,
    '193.5': 45.9,
    '194.5': 45.8,
  },
  '60': {
    '0': 67.5,
    '1': 67.4,
    '2': 67.3,
    '3': 67.1,
    '4': 67,
    '5': 66.9,
    '6': 66.7,
    '7': 66.6,
    '8': 66.4,
    '9': 66.3,
    '10': 66.2,
    '11': 66.1,
    '12': 65.9,
    '13': 65.8,
    '14': 65.7,
    '15': 65.5,
    '16': 65.4,
    '17': 65.3,
    '18': 65.2,
    '19': 65,
    '20': 64.9,
    '21': 64.8,
    '22': 64.6,
    '23': 64.5,
    '24': 64.4,
    '25': 64.3,
    '26': 64.1,
    '27': 64,
    '28': 63.9,
    '29': 63.8,
    '30': 63.6,
    '31': 63.5,
    '32': 63.4,
    '33': 63.3,
    '34': 63.1,
    '35': 63,
    '36': 62.9,
    '37': 62.8,
    '38': 62.6,
    '39': 62.5,
    '40': 62.4,
    '41': 62.3,
    '42': 62.2,
    '43': 62,
    '44': 61.9,
    '45': 61.8,
    '46': 61.7,
    '47': 61.5,
    '48': 61.4,
    '49': 61.3,
    '50': 61.2,
    '51': 61.1,
    '52': 60.9,
    '53': 60.8,
    '54': 60.7,
    '55': 60.6,
    '56': 60.5,
    '57': 60.4,
    '58': 60.2,
    '59': 60.1,
    '60': 60,
    '61': 59.9,
    '62': 59.8,
    '63': 59.7,
    '64': 59.5,
    '65': 59.4,
    '66': 59.3,
    '67': 59.2,
    '68': 59.1,
    '69': 59,
    '70': 58.8,
    '71': 58.7,
    '72': 58.6,
    '73': 58.5,
    '74': 58.4,
    '75': 58.3,
    '76': 58.2,
    '77': 58,
    '78': 57.9,
    '79': 57.8,
    '80': 57.7,
    '81': 57.6,
    '82': 57.5,
    '83': 57.4,
    '84': 57.3,
    '85': 57.1,
    '86': 57,
    '87': 56.9,
    '88': 56.8,
    '89': 56.7,
    '90': 56.6,
    '91': 56.5,
    '92': 56.4,
    '93': 56.3,
    '94': 56.1,
    '95': 56,
    '96': 55.9,
    '97': 55.8,
    '98': 55.7,
    '99': 55.6,
    '100': 55.5,
    '101': 55.4,
    '102': 55.3,
    '103': 55.2,
    '104': 55.1,
    '105': 55,
    '106': 54.9,
    '107': 54.7,
    '108': 54.6,
    '109': 54.5,
    '110': 54.4,
    '111': 54.3,
    '112': 54.2,
    '113': 54.1,
    '114': 54,
    '115': 53.9,
    '116': 53.8,
    '117': 53.7,
    '118': 53.6,
    '119': 53.5,
    '120': 53.4,
    '121': 53.3,
    '122': 53.2,
    '123': 53.1,
    '124': 53,
    '125': 52.9,
    '126': 52.8,
    '127': 52.7,
    '128': 52.6,
    '129': 52.5,
    '130': 52.4,
    '131': 52.2,
    '132': 52.1,
    '133': 52,
    '134': 51.9,
    '135': 51.8,
    '136': 51.7,
    '137': 51.6,
    '138': 51.5,
    '139': 51.4,
    '140': 51.3,
    '141': 51.2,
    '142': 51.1,
    '143': 51,
    '144': 50.9,
    '145': 50.8,
    '146': 50.7,
    '147': 50.7,
    '148': 50.6,
    '149': 50.5,
    '150': 50.4,
    '151': 50.3,
    '152': 50.2,
    '153': 50.1,
    '154': 50,
    '155': 49.9,
    '156': 49.8,
    '157': 49.7,
    '158': 49.6,
    '159': 49.5,
    '160': 49.4,
    '161': 49.3,
    '162': 49.2,
    '163': 49.1,
    '164': 49,
    '165': 48.9,
    '166': 48.8,
    '167': 48.7,
    '168': 48.6,
    '169': 48.5,
    '170': 48.4,
    '171': 48.3,
    '172': 48.2,
    '173': 48.2,
    '174': 48.1,
    '175': 48,
    '176': 47.9,
    '177': 47.8,
    '178': 47.7,
    '179': 47.6,
    '180': 47.5,
    '181': 47.4,
    '182': 47.3,
    '183': 47.2,
    '184': 47.1,
    '185': 47,
    '186': 47,
    '187': 46.9,
    '188': 46.8,
    '189': 46.7,
    '190': 46.6,
    '191': 46.5,
    '192': 46.4,
    '193': 46.3,
    '194': 46.2,
    '195': 46.1,
    '0.5': 67.5,
    '1.5': 67.3,
    '2.5': 67.2,
    '3.5': 67.1,
    '4.5': 66.9,
    '5.5': 66.8,
    '6.5': 66.7,
    '7.5': 66.5,
    '8.5': 66.4,
    '9.5': 66.3,
    '10.5': 66.1,
    '11.5': 66,
    '12.5': 65.9,
    '13.5': 65.7,
    '14.5': 65.6,
    '15.5': 65.5,
    '16.5': 65.4,
    '17.5': 65.2,
    '18.5': 65.1,
    '19.5': 65,
    '20.5': 64.8,
    '21.5': 64.7,
    '22.5': 64.6,
    '23.5': 64.5,
    '24.5': 64.3,
    '25.5': 64.2,
    '26.5': 64.1,
    '27.5': 64,
    '28.5': 63.8,
    '29.5': 63.7,
    '30.5': 63.6,
    '31.5': 63.5,
    '32.5': 63.3,
    '33.5': 63.2,
    '34.5': 63.1,
    '35.5': 63,
    '36.5': 62.8,
    '37.5': 62.7,
    '38.5': 62.6,
    '39.5': 62.5,
    '40.5': 62.3,
    '41.5': 62.2,
    '42.5': 62.1,
    '43.5': 62,
    '44.5': 61.9,
    '45.5': 61.7,
    '46.5': 61.6,
    '47.5': 61.5,
    '48.5': 61.4,
    '49.5': 61.2,
    '50.5': 61.1,
    '51.5': 61,
    '52.5': 60.9,
    '53.5': 60.8,
    '54.5': 60.7,
    '55.5': 60.5,
    '56.5': 60.4,
    '57.5': 60.3,
    '58.5': 60.2,
    '59.5': 60.1,
    '60.5': 59.9,
    '61.5': 59.8,
    '62.5': 59.7,
    '63.5': 59.6,
    '64.5': 59.5,
    '65.5': 59.4,
    '66.5': 59.2,
    '67.5': 59.1,
    '68.5': 59,
    '69.5': 58.9,
    '70.5': 58.8,
    '71.5': 58.7,
    '72.5': 58.6,
    '73.5': 58.4,
    '74.5': 58.3,
    '75.5': 58.2,
    '76.5': 58.1,
    '77.5': 58,
    '78.5': 57.9,
    '79.5': 57.8,
    '80.5': 57.6,
    '81.5': 57.5,
    '82.5': 57.4,
    '83.5': 57.3,
    '84.5': 57.2,
    '85.5': 57.1,
    '86.5': 57,
    '87.5': 56.9,
    '88.5': 56.8,
    '89.5': 56.6,
    '90.5': 56.5,
    '91.5': 56.4,
    '92.5': 56.3,
    '93.5': 56.2,
    '94.5': 56.1,
    '95.5': 56,
    '96.5': 55.9,
    '97.5': 55.8,
    '98.5': 55.7,
    '99.5': 55.5,
    '100.5': 55.4,
    '101.5': 55.3,
    '102.5': 55.2,
    '103.5': 55.1,
    '104.5': 55,
    '105.5': 54.9,
    '106.5': 54.8,
    '107.5': 54.7,
    '108.5': 54.6,
    '109.5': 54.5,
    '110.5': 54.4,
    '111.5': 54.3,
    '112.5': 54.2,
    '113.5': 54.1,
    '114.5': 54,
    '115.5': 53.8,
    '116.5': 53.7,
    '117.5': 53.6,
    '118.5': 53.5,
    '119.5': 53.4,
    '120.5': 53.3,
    '121.5': 53.2,
    '122.5': 53.1,
    '123.5': 53,
    '124.5': 52.9,
    '125.5': 52.8,
    '126.5': 52.7,
    '127.5': 52.6,
    '128.5': 52.5,
    '129.5': 52.4,
    '130.5': 52.3,
    '131.5': 52.2,
    '132.5': 52.1,
    '133.5': 52,
    '134.5': 51.9,
    '135.5': 51.8,
    '136.5': 51.7,
    '137.5': 51.6,
    '138.5': 51.5,
    '139.5': 51.4,
    '140.5': 51.3,
    '141.5': 51.2,
    '142.5': 51.1,
    '143.5': 51,
    '144.5': 50.9,
    '145.5': 50.8,
    '146.5': 50.7,
    '147.5': 50.6,
    '148.5': 50.5,
    '149.5': 50.4,
    '150.5': 50.3,
    '151.5': 50.2,
    '152.5': 50.1,
    '153.5': 50,
    '154.5': 49.9,
    '155.5': 49.8,
    '156.5': 49.7,
    '157.5': 49.6,
    '158.5': 49.5,
    '159.5': 49.4,
    '160.5': 49.3,
    '161.5': 49.2,
    '162.5': 49.1,
    '163.5': 49.1,
    '164.5': 49,
    '165.5': 48.9,
    '166.5': 48.8,
    '167.5': 48.7,
    '168.5': 48.6,
    '169.5': 48.5,
    '170.5': 48.4,
    '171.5': 48.3,
    '172.5': 48.2,
    '173.5': 48.1,
    '174.5': 48,
    '175.5': 47.9,
    '176.5': 47.8,
    '177.5': 47.7,
    '178.5': 47.6,
    '179.5': 47.6,
    '180.5': 47.5,
    '181.5': 47.4,
    '182.5': 47.3,
    '183.5': 47.2,
    '184.5': 47.1,
    '185.5': 47,
    '186.5': 46.9,
    '187.5': 46.8,
    '188.5': 46.7,
    '189.5': 46.6,
    '190.5': 46.5,
    '191.5': 46.5,
    '192.5': 46.4,
    '193.5': 46.3,
    '194.5': 46.2,
  },
  // 60 - 65
  '60.5': {
    '0': 68.1,
    '1': 67.9,
    '2': 67.8,
    '3': 67.7,
    '4': 67.5,
    '5': 67.4,
    '6': 67.3,
    '7': 67.1,
    '8': 67,
    '9': 66.9,
    '10': 66.7,
    '11': 66.6,
    '12': 66.5,
    '13': 66.4,
    '14': 66.2,
    '15': 66.1,
    '16': 66,
    '17': 65.8,
    '18': 65.7,
    '19': 65.6,
    '20': 65.4,
    '21': 65.3,
    '22': 65.2,
    '23': 65.1,
    '24': 64.9,
    '25': 64.8,
    '26': 64.7,
    '27': 64.5,
    '28': 64.4,
    '29': 64.3,
    '30': 64.2,
    '31': 64,
    '32': 63.9,
    '33': 63.8,
    '34': 63.7,
    '35': 63.5,
    '36': 63.4,
    '37': 63.3,
    '38': 63.2,
    '39': 63,
    '40': 62.9,
    '41': 62.8,
    '42': 62.7,
    '43': 62.5,
    '44': 62.4,
    '45': 62.3,
    '46': 62.2,
    '47': 62.1,
    '48': 61.9,
    '49': 61.8,
    '50': 61.7,
    '51': 61.6,
    '52': 61.5,
    '53': 61.3,
    '54': 61.2,
    '55': 61.1,
    '56': 61,
    '57': 60.9,
    '58': 60.7,
    '59': 60.6,
    '60': 60.5,
    '61': 60.4,
    '62': 60.3,
    '63': 60.1,
    '64': 60,
    '65': 59.9,
    '66': 59.8,
    '67': 59.7,
    '68': 59.6,
    '69': 59.4,
    '70': 59.3,
    '71': 59.2,
    '72': 59.1,
    '73': 59,
    '74': 58.9,
    '75': 58.8,
    '76': 58.6,
    '77': 58.5,
    '78': 58.4,
    '79': 58.3,
    '80': 58.2,
    '81': 58.1,
    '82': 58,
    '83': 57.8,
    '84': 57.7,
    '85': 57.6,
    '86': 57.5,
    '87': 57.4,
    '88': 57.3,
    '89': 57.2,
    '90': 57.1,
    '91': 57,
    '92': 56.8,
    '93': 56.7,
    '94': 56.6,
    '95': 56.5,
    '96': 56.4,
    '97': 56.3,
    '98': 56.2,
    '99': 56.1,
    '100': 56,
    '101': 55.9,
    '102': 55.7,
    '103': 55.6,
    '104': 55.5,
    '105': 55.4,
    '106': 55.3,
    '107': 55.2,
    '108': 55.1,
    '109': 55,
    '110': 54.9,
    '111': 54.8,
    '112': 54.7,
    '113': 54.6,
    '114': 54.5,
    '115': 54.4,
    '116': 54.2,
    '117': 54.1,
    '118': 54,
    '119': 53.9,
    '120': 53.8,
    '121': 53.7,
    '122': 53.6,
    '123': 53.5,
    '124': 53.4,
    '125': 53.3,
    '126': 53.2,
    '127': 53.1,
    '128': 53,
    '129': 52.9,
    '130': 52.8,
    '131': 52.7,
    '132': 52.6,
    '133': 52.5,
    '134': 52.4,
    '135': 52.3,
    '136': 52.2,
    '137': 52.1,
    '138': 52,
    '139': 51.9,
    '140': 51.8,
    '141': 51.7,
    '142': 51.6,
    '143': 51.5,
    '144': 51.4,
    '145': 51.3,
    '146': 51.2,
    '147': 51.1,
    '148': 51,
    '149': 50.9,
    '150': 50.8,
    '151': 50.7,
    '152': 50.6,
    '153': 50.5,
    '154': 50.4,
    '155': 50.3,
    '156': 50.2,
    '157': 50.1,
    '158': 50,
    '159': 49.9,
    '160': 49.8,
    '161': 49.7,
    '162': 49.6,
    '163': 49.5,
    '164': 49.4,
    '165': 49.3,
    '166': 49.2,
    '167': 49.1,
    '168': 49,
    '169': 48.9,
    '170': 48.9,
    '171': 48.8,
    '172': 48.7,
    '173': 48.6,
    '174': 48.5,
    '175': 48.4,
    '176': 48.3,
    '177': 48.2,
    '178': 48.1,
    '179': 48,
    '180': 47.9,
    '181': 47.8,
    '182': 47.7,
    '183': 47.6,
    '184': 47.5,
    '185': 47.5,
    '186': 47.4,
    '187': 47.3,
    '188': 47.2,
    '189': 47.1,
    '190': 47,
    '191': 46.9,
    '192': 46.8,
    '193': 46.7,
    '194': 46.6,
    '195': 46.5,
    '0.5': 68,
    '1.5': 67.9,
    '2.5': 67.7,
    '3.5': 67.6,
    '4.5': 67.5,
    '5.5': 67.3,
    '6.5': 67.2,
    '7.5': 67.1,
    '8.5': 66.9,
    '9.5': 66.8,
    '10.5': 66.7,
    '11.5': 66.6,
    '12.5': 66.4,
    '13.5': 66.3,
    '14.5': 66.2,
    '15.5': 66,
    '16.5': 65.9,
    '17.5': 65.8,
    '18.5': 65.6,
    '19.5': 65.5,
    '20.5': 65.4,
    '21.5': 65.2,
    '22.5': 65.1,
    '23.5': 65,
    '24.5': 64.9,
    '25.5': 64.7,
    '26.5': 64.6,
    '27.5': 64.5,
    '28.5': 64.4,
    '29.5': 64.2,
    '30.5': 64.1,
    '31.5': 64,
    '32.5': 63.9,
    '33.5': 63.7,
    '34.5': 63.6,
    '35.5': 63.5,
    '36.5': 63.4,
    '37.5': 63.2,
    '38.5': 63.1,
    '39.5': 63,
    '40.5': 62.9,
    '41.5': 62.7,
    '42.5': 62.6,
    '43.5': 62.5,
    '44.5': 62.4,
    '45.5': 62.2,
    '46.5': 62.1,
    '47.5': 62,
    '48.5': 61.9,
    '49.5': 61.8,
    '50.5': 61.6,
    '51.5': 61.5,
    '52.5': 61.4,
    '53.5': 61.3,
    '54.5': 61.2,
    '55.5': 61,
    '56.5': 60.9,
    '57.5': 60.8,
    '58.5': 60.7,
    '59.5': 60.6,
    '60.5': 60.4,
    '61.5': 60.3,
    '62.5': 60.2,
    '63.5': 60.1,
    '64.5': 60,
    '65.5': 59.9,
    '66.5': 59.7,
    '67.5': 59.6,
    '68.5': 59.5,
    '69.5': 59.4,
    '70.5': 59.3,
    '71.5': 59.2,
    '72.5': 59,
    '73.5': 58.9,
    '74.5': 58.8,
    '75.5': 58.7,
    '76.5': 58.6,
    '77.5': 58.5,
    '78.5': 58.4,
    '79.5': 58.2,
    '80.5': 58.1,
    '81.5': 58,
    '82.5': 57.9,
    '83.5': 57.8,
    '84.5': 57.7,
    '85.5': 57.6,
    '86.5': 57.4,
    '87.5': 57.3,
    '88.5': 57.2,
    '89.5': 57.1,
    '90.5': 57,
    '91.5': 56.9,
    '92.5': 56.8,
    '93.5': 56.7,
    '94.5': 56.6,
    '95.5': 56.5,
    '96.5': 56.3,
    '97.5': 56.2,
    '98.5': 56.1,
    '99.5': 56,
    '100.5': 55.9,
    '101.5': 55.8,
    '102.5': 55.7,
    '103.5': 55.6,
    '104.5': 55.5,
    '105.5': 55.4,
    '106.5': 55.3,
    '107.5': 55.2,
    '108.5': 55,
    '109.5': 54.9,
    '110.5': 54.8,
    '111.5': 54.7,
    '112.5': 54.6,
    '113.5': 54.5,
    '114.5': 54.4,
    '115.5': 54.3,
    '116.5': 54.2,
    '117.5': 54.1,
    '118.5': 54,
    '119.5': 53.9,
    '120.5': 53.8,
    '121.5': 53.7,
    '122.5': 53.6,
    '123.5': 53.5,
    '124.5': 53.4,
    '125.5': 53.3,
    '126.5': 53.2,
    '127.5': 53.1,
    '128.5': 52.9,
    '129.5': 52.8,
    '130.5': 52.7,
    '131.5': 52.6,
    '132.5': 52.5,
    '133.5': 52.4,
    '134.5': 52.3,
    '135.5': 52.2,
    '136.5': 52.1,
    '137.5': 52,
    '138.5': 51.9,
    '139.5': 51.8,
    '140.5': 51.7,
    '141.5': 51.6,
    '142.5': 51.5,
    '143.5': 51.4,
    '144.5': 51.3,
    '145.5': 51.2,
    '146.5': 51.1,
    '147.5': 51,
    '148.5': 50.9,
    '149.5': 50.8,
    '150.5': 50.7,
    '151.5': 50.6,
    '152.5': 50.5,
    '153.5': 50.4,
    '154.5': 50.3,
    '155.5': 50.2,
    '156.5': 50.1,
    '157.5': 50.1,
    '158.5': 50,
    '159.5': 49.9,
    '160.5': 49.8,
    '161.5': 49.7,
    '162.5': 49.6,
    '163.5': 49.5,
    '164.5': 49.4,
    '165.5': 49.3,
    '166.5': 49.2,
    '167.5': 49.1,
    '168.5': 49,
    '169.5': 48.9,
    '170.5': 48.8,
    '171.5': 48.7,
    '172.5': 48.6,
    '173.5': 48.5,
    '174.5': 48.4,
    '175.5': 48.3,
    '176.5': 48.2,
    '177.5': 48.1,
    '178.5': 48.1,
    '179.5': 48,
    '180.5': 47.9,
    '181.5': 47.8,
    '182.5': 47.7,
    '183.5': 47.6,
    '184.5': 47.5,
    '185.5': 47.4,
    '186.5': 47.3,
    '187.5': 47.2,
    '188.5': 47.1,
    '189.5': 47,
    '190.5': 47,
    '191.5': 46.9,
    '192.5': 46.8,
    '193.5': 46.7,
    '194.5': 46.6,
  },
  '61': {
    '0': 68.6,
    '1': 68.5,
    '2': 68.4,
    '3': 68.2,
    '4': 68.1,
    '5': 68,
    '6': 67.8,
    '7': 67.7,
    '8': 67.6,
    '9': 67.4,
    '10': 67.3,
    '11': 67.2,
    '12': 67,
    '13': 66.9,
    '14': 66.8,
    '15': 66.6,
    '16': 66.5,
    '17': 66.4,
    '18': 66.2,
    '19': 66.1,
    '20': 66,
    '21': 65.9,
    '22': 65.7,
    '23': 65.6,
    '24': 65.5,
    '25': 65.3,
    '26': 65.2,
    '27': 65.1,
    '28': 65,
    '29': 64.8,
    '30': 64.7,
    '31': 64.6,
    '32': 64.4,
    '33': 64.3,
    '34': 64.2,
    '35': 64.1,
    '36': 63.9,
    '37': 63.8,
    '38': 63.7,
    '39': 63.6,
    '40': 63.4,
    '41': 63.3,
    '42': 63.2,
    '43': 63.1,
    '44': 62.9,
    '45': 62.8,
    '46': 62.7,
    '47': 62.6,
    '48': 62.4,
    '49': 62.3,
    '50': 62.2,
    '51': 62.1,
    '52': 62,
    '53': 61.8,
    '54': 61.7,
    '55': 61.6,
    '56': 61.5,
    '57': 61.4,
    '58': 61.2,
    '59': 61.1,
    '60': 61,
    '61': 60.9,
    '62': 60.8,
    '63': 60.6,
    '64': 60.5,
    '65': 60.4,
    '66': 60.3,
    '67': 60.2,
    '68': 60.1,
    '69': 59.9,
    '70': 59.8,
    '71': 59.7,
    '72': 59.6,
    '73': 59.5,
    '74': 59.4,
    '75': 59.2,
    '76': 59.1,
    '77': 59,
    '78': 58.9,
    '79': 58.8,
    '80': 58.7,
    '81': 58.5,
    '82': 58.4,
    '83': 58.3,
    '84': 58.2,
    '85': 58.1,
    '86': 58,
    '87': 57.9,
    '88': 57.8,
    '89': 57.6,
    '90': 57.5,
    '91': 57.4,
    '92': 57.3,
    '93': 57.2,
    '94': 57.1,
    '95': 57,
    '96': 56.9,
    '97': 56.8,
    '98': 56.6,
    '99': 56.5,
    '100': 56.4,
    '101': 56.3,
    '102': 56.2,
    '103': 56.1,
    '104': 56,
    '105': 55.9,
    '106': 55.8,
    '107': 55.7,
    '108': 55.6,
    '109': 55.4,
    '110': 55.3,
    '111': 55.2,
    '112': 55.1,
    '113': 55,
    '114': 54.9,
    '115': 54.8,
    '116': 54.7,
    '117': 54.6,
    '118': 54.5,
    '119': 54.4,
    '120': 54.3,
    '121': 54.2,
    '122': 54.1,
    '123': 54,
    '124': 53.9,
    '125': 53.8,
    '126': 53.6,
    '127': 53.5,
    '128': 53.4,
    '129': 53.3,
    '130': 53.2,
    '131': 53.1,
    '132': 53,
    '133': 52.9,
    '134': 52.8,
    '135': 52.7,
    '136': 52.6,
    '137': 52.5,
    '138': 52.4,
    '139': 52.3,
    '140': 52.2,
    '141': 52.1,
    '142': 52,
    '143': 51.9,
    '144': 51.8,
    '145': 51.7,
    '146': 51.6,
    '147': 51.5,
    '148': 51.4,
    '149': 51.3,
    '150': 51.2,
    '151': 51.1,
    '152': 51,
    '153': 50.9,
    '154': 50.8,
    '155': 50.7,
    '156': 50.6,
    '157': 50.5,
    '158': 50.4,
    '159': 50.3,
    '160': 50.2,
    '161': 50.1,
    '162': 50,
    '163': 49.9,
    '164': 49.8,
    '165': 49.7,
    '166': 49.6,
    '167': 49.6,
    '168': 49.5,
    '169': 49.4,
    '170': 49.3,
    '171': 49.2,
    '172': 49.1,
    '173': 49,
    '174': 48.9,
    '175': 48.8,
    '176': 48.7,
    '177': 48.6,
    '178': 48.5,
    '179': 48.4,
    '180': 48.3,
    '181': 48.2,
    '182': 48.1,
    '183': 48,
    '184': 47.9,
    '185': 47.9,
    '186': 47.8,
    '187': 47.7,
    '188': 47.6,
    '189': 47.5,
    '190': 47.4,
    '191': 47.3,
    '192': 47.2,
    '193': 47.1,
    '194': 47,
    '195': 46.9,
    '0.5': 68.6,
    '1.5': 68.4,
    '2.5': 68.3,
    '3.5': 68.2,
    '4.5': 68,
    '5.5': 67.9,
    '6.5': 67.8,
    '7.5': 67.6,
    '8.5': 67.5,
    '9.5': 67.4,
    '10.5': 67.2,
    '11.5': 67.1,
    '12.5': 67,
    '13.5': 66.8,
    '14.5': 66.7,
    '15.5': 66.6,
    '16.5': 66.4,
    '17.5': 66.3,
    '18.5': 66.2,
    '19.5': 66,
    '20.5': 65.9,
    '21.5': 65.8,
    '22.5': 65.7,
    '23.5': 65.5,
    '24.5': 65.4,
    '25.5': 65.3,
    '26.5': 65.1,
    '27.5': 65,
    '28.5': 64.9,
    '29.5': 64.8,
    '30.5': 64.6,
    '31.5': 64.5,
    '32.5': 64.4,
    '33.5': 64.3,
    '34.5': 64.1,
    '35.5': 64,
    '36.5': 63.9,
    '37.5': 63.7,
    '38.5': 63.6,
    '39.5': 63.5,
    '40.5': 63.4,
    '41.5': 63.3,
    '42.5': 63.1,
    '43.5': 63,
    '44.5': 62.9,
    '45.5': 62.8,
    '46.5': 62.6,
    '47.5': 62.5,
    '48.5': 62.4,
    '49.5': 62.3,
    '50.5': 62.1,
    '51.5': 62,
    '52.5': 61.9,
    '53.5': 61.8,
    '54.5': 61.7,
    '55.5': 61.5,
    '56.5': 61.4,
    '57.5': 61.3,
    '58.5': 61.2,
    '59.5': 61.1,
    '60.5': 60.9,
    '61.5': 60.8,
    '62.5': 60.7,
    '63.5': 60.6,
    '64.5': 60.5,
    '65.5': 60.3,
    '66.5': 60.2,
    '67.5': 60.1,
    '68.5': 60,
    '69.5': 59.9,
    '70.5': 59.8,
    '71.5': 59.6,
    '72.5': 59.5,
    '73.5': 59.4,
    '74.5': 59.3,
    '75.5': 59.2,
    '76.5': 59.1,
    '77.5': 59,
    '78.5': 58.8,
    '79.5': 58.7,
    '80.5': 58.6,
    '81.5': 58.5,
    '82.5': 58.4,
    '83.5': 58.3,
    '84.5': 58.2,
    '85.5': 58,
    '86.5': 57.9,
    '87.5': 57.8,
    '88.5': 57.7,
    '89.5': 57.6,
    '90.5': 57.5,
    '91.5': 57.4,
    '92.5': 57.3,
    '93.5': 57.1,
    '94.5': 57,
    '95.5': 56.9,
    '96.5': 56.8,
    '97.5': 56.7,
    '98.5': 56.6,
    '99.5': 56.5,
    '100.5': 56.4,
    '101.5': 56.3,
    '102.5': 56.2,
    '103.5': 56,
    '104.5': 55.9,
    '105.5': 55.8,
    '106.5': 55.7,
    '107.5': 55.6,
    '108.5': 55.5,
    '109.5': 55.4,
    '110.5': 55.3,
    '111.5': 55.2,
    '112.5': 55.1,
    '113.5': 55,
    '114.5': 54.9,
    '115.5': 54.8,
    '116.5': 54.6,
    '117.5': 54.5,
    '118.5': 54.4,
    '119.5': 54.3,
    '120.5': 54.2,
    '121.5': 54.1,
    '122.5': 54,
    '123.5': 53.9,
    '124.5': 53.8,
    '125.5': 53.7,
    '126.5': 53.6,
    '127.5': 53.5,
    '128.5': 53.4,
    '129.5': 53.3,
    '130.5': 53.2,
    '131.5': 53.1,
    '132.5': 53,
    '133.5': 52.9,
    '134.5': 52.8,
    '135.5': 52.7,
    '136.5': 52.6,
    '137.5': 52.5,
    '138.5': 52.4,
    '139.5': 52.3,
    '140.5': 52.2,
    '141.5': 52.1,
    '142.5': 52,
    '143.5': 51.9,
    '144.5': 51.8,
    '145.5': 51.7,
    '146.5': 51.6,
    '147.5': 51.5,
    '148.5': 51.4,
    '149.5': 51.3,
    '150.5': 51.2,
    '151.5': 51.1,
    '152.5': 51,
    '153.5': 50.9,
    '154.5': 50.8,
    '155.5': 50.7,
    '156.5': 50.6,
    '157.5': 50.5,
    '158.5': 50.4,
    '159.5': 50.3,
    '160.5': 50.2,
    '161.5': 50.1,
    '162.5': 50,
    '163.5': 49.9,
    '164.5': 49.8,
    '165.5': 49.7,
    '166.5': 49.6,
    '167.5': 49.5,
    '168.5': 49.4,
    '169.5': 49.3,
    '170.5': 49.2,
    '171.5': 49.1,
    '172.5': 49,
    '173.5': 48.9,
    '174.5': 48.8,
    '175.5': 48.7,
    '176.5': 48.7,
    '177.5': 48.6,
    '178.5': 48.5,
    '179.5': 48.4,
    '180.5': 48.3,
    '181.5': 48.2,
    '182.5': 48.1,
    '183.5': 48,
    '184.5': 47.9,
    '185.5': 47.8,
    '186.5': 47.7,
    '187.5': 47.6,
    '188.5': 47.5,
    '189.5': 47.4,
    '190.5': 47.3,
    '191.5': 47.3,
    '192.5': 47.2,
    '193.5': 47.1,
    '194.5': 47,
  },
  '61.5': {
    '0': 69.2,
    '1': 69.1,
    '2': 68.9,
    '3': 68.8,
    '4': 68.7,
    '5': 68.5,
    '6': 68.4,
    '7': 68.3,
    '8': 68.1,
    '9': 68,
    '10': 67.8,
    '11': 67.7,
    '12': 67.6,
    '13': 67.4,
    '14': 67.3,
    '15': 67.2,
    '16': 67.1,
    '17': 66.9,
    '18': 66.8,
    '19': 66.7,
    '20': 66.5,
    '21': 66.4,
    '22': 66.3,
    '23': 66.1,
    '24': 66,
    '25': 65.9,
    '26': 65.7,
    '27': 65.6,
    '28': 65.5,
    '29': 65.4,
    '30': 65.2,
    '31': 65.1,
    '32': 65,
    '33': 64.8,
    '34': 64.7,
    '35': 64.6,
    '36': 64.5,
    '37': 64.3,
    '38': 64.2,
    '39': 64.1,
    '40': 64,
    '41': 63.8,
    '42': 63.7,
    '43': 63.6,
    '44': 63.5,
    '45': 63.3,
    '46': 63.2,
    '47': 63.1,
    '48': 63,
    '49': 62.8,
    '50': 62.7,
    '51': 62.6,
    '52': 62.5,
    '53': 62.3,
    '54': 62.2,
    '55': 62.1,
    '56': 62,
    '57': 61.9,
    '58': 61.7,
    '59': 61.6,
    '60': 61.5,
    '61': 61.4,
    '62': 61.3,
    '63': 61.1,
    '64': 61,
    '65': 60.9,
    '66': 60.8,
    '67': 60.7,
    '68': 60.5,
    '69': 60.4,
    '70': 60.3,
    '71': 60.2,
    '72': 60.1,
    '73': 60,
    '74': 59.8,
    '75': 59.7,
    '76': 59.6,
    '77': 59.5,
    '78': 59.4,
    '79': 59.3,
    '80': 59.1,
    '81': 59,
    '82': 58.9,
    '83': 58.8,
    '84': 58.7,
    '85': 58.6,
    '86': 58.5,
    '87': 58.3,
    '88': 58.2,
    '89': 58.1,
    '90': 58,
    '91': 57.9,
    '92': 57.8,
    '93': 57.7,
    '94': 57.6,
    '95': 57.4,
    '96': 57.3,
    '97': 57.2,
    '98': 57.1,
    '99': 57,
    '100': 56.9,
    '101': 56.8,
    '102': 56.7,
    '103': 56.6,
    '104': 56.4,
    '105': 56.3,
    '106': 56.2,
    '107': 56.1,
    '108': 56,
    '109': 55.9,
    '110': 55.8,
    '111': 55.7,
    '112': 55.6,
    '113': 55.5,
    '114': 55.4,
    '115': 55.3,
    '116': 55.2,
    '117': 55,
    '118': 54.9,
    '119': 54.8,
    '120': 54.7,
    '121': 54.6,
    '122': 54.5,
    '123': 54.4,
    '124': 54.3,
    '125': 54.2,
    '126': 54.1,
    '127': 54,
    '128': 53.9,
    '129': 53.8,
    '130': 53.7,
    '131': 53.6,
    '132': 53.5,
    '133': 53.4,
    '134': 53.3,
    '135': 53.2,
    '136': 53.1,
    '137': 53,
    '138': 52.9,
    '139': 52.7,
    '140': 52.6,
    '141': 52.5,
    '142': 52.4,
    '143': 52.3,
    '144': 52.2,
    '145': 52.1,
    '146': 52,
    '147': 51.9,
    '148': 51.8,
    '149': 51.7,
    '150': 51.6,
    '151': 51.5,
    '152': 51.4,
    '153': 51.3,
    '154': 51.2,
    '155': 51.1,
    '156': 51,
    '157': 50.9,
    '158': 50.8,
    '159': 50.7,
    '160': 50.6,
    '161': 50.6,
    '162': 50.5,
    '163': 50.4,
    '164': 50.3,
    '165': 50.2,
    '166': 50.1,
    '167': 50,
    '168': 49.9,
    '169': 49.8,
    '170': 49.7,
    '171': 49.6,
    '172': 49.5,
    '173': 49.4,
    '174': 49.3,
    '175': 49.2,
    '176': 49.1,
    '177': 49,
    '178': 48.9,
    '179': 48.8,
    '180': 48.7,
    '181': 48.6,
    '182': 48.5,
    '183': 48.4,
    '184': 48.4,
    '185': 48.3,
    '186': 48.2,
    '187': 48.1,
    '188': 48,
    '189': 47.9,
    '190': 47.8,
    '191': 47.7,
    '192': 47.6,
    '193': 47.5,
    '194': 47.4,
    '195': 47.3,
    '0.5': 69.1,
    '1.5': 69,
    '2.5': 68.9,
    '3.5': 68.7,
    '4.5': 68.6,
    '5.5': 68.5,
    '6.5': 68.3,
    '7.5': 68.2,
    '8.5': 68.1,
    '9.5': 67.9,
    '10.5': 67.8,
    '11.5': 67.6,
    '12.5': 67.5,
    '13.5': 67.4,
    '14.5': 67.2,
    '15.5': 67.1,
    '16.5': 67,
    '17.5': 66.9,
    '18.5': 66.7,
    '19.5': 66.6,
    '20.5': 66.5,
    '21.5': 66.3,
    '22.5': 66.2,
    '23.5': 66.1,
    '24.5': 65.9,
    '25.5': 65.8,
    '26.5': 65.7,
    '27.5': 65.5,
    '28.5': 65.4,
    '29.5': 65.3,
    '30.5': 65.2,
    '31.5': 65,
    '32.5': 64.9,
    '33.5': 64.8,
    '34.5': 64.7,
    '35.5': 64.5,
    '36.5': 64.4,
    '37.5': 64.3,
    '38.5': 64.1,
    '39.5': 64,
    '40.5': 63.9,
    '41.5': 63.8,
    '42.5': 63.6,
    '43.5': 63.5,
    '44.5': 63.4,
    '45.5': 63.3,
    '46.5': 63.1,
    '47.5': 63,
    '48.5': 62.9,
    '49.5': 62.8,
    '50.5': 62.7,
    '51.5': 62.5,
    '52.5': 62.4,
    '53.5': 62.3,
    '54.5': 62.2,
    '55.5': 62,
    '56.5': 61.9,
    '57.5': 61.8,
    '58.5': 61.7,
    '59.5': 61.6,
    '60.5': 61.4,
    '61.5': 61.3,
    '62.5': 61.2,
    '63.5': 61.1,
    '64.5': 61,
    '65.5': 60.8,
    '66.5': 60.7,
    '67.5': 60.6,
    '68.5': 60.5,
    '69.5': 60.4,
    '70.5': 60.3,
    '71.5': 60.1,
    '72.5': 60,
    '73.5': 59.9,
    '74.5': 59.8,
    '75.5': 59.7,
    '76.5': 59.6,
    '77.5': 59.4,
    '78.5': 59.3,
    '79.5': 59.2,
    '80.5': 59.1,
    '81.5': 59,
    '82.5': 58.9,
    '83.5': 58.7,
    '84.5': 58.6,
    '85.5': 58.5,
    '86.5': 58.4,
    '87.5': 58.3,
    '88.5': 58.2,
    '89.5': 58.1,
    '90.5': 57.9,
    '91.5': 57.8,
    '92.5': 57.7,
    '93.5': 57.6,
    '94.5': 57.5,
    '95.5': 57.4,
    '96.5': 57.3,
    '97.5': 57.2,
    '98.5': 57.1,
    '99.5': 56.9,
    '100.5': 56.8,
    '101.5': 56.7,
    '102.5': 56.6,
    '103.5': 56.5,
    '104.5': 56.4,
    '105.5': 56.3,
    '106.5': 56.2,
    '107.5': 56.1,
    '108.5': 56,
    '109.5': 55.8,
    '110.5': 55.7,
    '111.5': 55.6,
    '112.5': 55.5,
    '113.5': 55.4,
    '114.5': 55.3,
    '115.5': 55.2,
    '116.5': 55.1,
    '117.5': 55,
    '118.5': 54.9,
    '119.5': 54.8,
    '120.5': 54.7,
    '121.5': 54.6,
    '122.5': 54.5,
    '123.5': 54.4,
    '124.5': 54.2,
    '125.5': 54.1,
    '126.5': 54,
    '127.5': 53.9,
    '128.5': 53.8,
    '129.5': 53.7,
    '130.5': 53.6,
    '131.5': 53.5,
    '132.5': 53.4,
    '133.5': 53.3,
    '134.5': 53.2,
    '135.5': 53.1,
    '136.5': 53,
    '137.5': 52.9,
    '138.5': 52.8,
    '139.5': 52.7,
    '140.5': 52.6,
    '141.5': 52.5,
    '142.5': 52.4,
    '143.5': 52.3,
    '144.5': 52.2,
    '145.5': 52.1,
    '146.5': 52,
    '147.5': 51.9,
    '148.5': 51.8,
    '149.5': 51.7,
    '150.5': 51.6,
    '151.5': 51.5,
    '152.5': 51.4,
    '153.5': 51.3,
    '154.5': 51.2,
    '155.5': 51.1,
    '156.5': 51,
    '157.5': 50.9,
    '158.5': 50.8,
    '159.5': 50.7,
    '160.5': 50.6,
    '161.5': 50.5,
    '162.5': 50.4,
    '163.5': 50.3,
    '164.5': 50.2,
    '165.5': 50.1,
    '166.5': 50,
    '167.5': 49.9,
    '168.5': 49.8,
    '169.5': 49.7,
    '170.5': 49.6,
    '171.5': 49.5,
    '172.5': 49.4,
    '173.5': 49.3,
    '174.5': 49.2,
    '175.5': 49.2,
    '176.5': 49.1,
    '177.5': 49,
    '178.5': 48.9,
    '179.5': 48.8,
    '180.5': 48.7,
    '181.5': 48.6,
    '182.5': 48.5,
    '183.5': 48.4,
    '184.5': 48.3,
    '185.5': 48.2,
    '186.5': 48.1,
    '187.5': 48,
    '188.5': 47.9,
    '189.5': 47.8,
    '190.5': 47.8,
    '191.5': 47.7,
    '192.5': 47.6,
    '193.5': 47.5,
    '194.5': 47.4,
  },
  '62': {
    '0': 69.8,
    '1': 69.6,
    '2': 69.5,
    '3': 69.4,
    '4': 69.2,
    '5': 69.1,
    '6': 68.9,
    '7': 68.8,
    '8': 68.7,
    '9': 68.5,
    '10': 68.4,
    '11': 68.3,
    '12': 68.1,
    '13': 68,
    '14': 67.9,
    '15': 67.7,
    '16': 67.6,
    '17': 67.5,
    '18': 67.3,
    '19': 67.2,
    '20': 67.1,
    '21': 66.9,
    '22': 66.8,
    '23': 66.7,
    '24': 66.5,
    '25': 66.4,
    '26': 66.3,
    '27': 66.1,
    '28': 66,
    '29': 65.9,
    '30': 65.8,
    '31': 65.6,
    '32': 65.5,
    '33': 65.4,
    '34': 65.2,
    '35': 65.1,
    '36': 65,
    '37': 64.9,
    '38': 64.7,
    '39': 64.6,
    '40': 64.5,
    '41': 64.3,
    '42': 64.2,
    '43': 64.1,
    '44': 64,
    '45': 63.8,
    '46': 63.7,
    '47': 63.6,
    '48': 63.5,
    '49': 63.4,
    '50': 63.2,
    '51': 63.1,
    '52': 63,
    '53': 62.9,
    '54': 62.7,
    '55': 62.6,
    '56': 62.5,
    '57': 62.4,
    '58': 62.2,
    '59': 62.1,
    '60': 62,
    '61': 61.9,
    '62': 61.8,
    '63': 61.6,
    '64': 61.5,
    '65': 61.4,
    '66': 61.3,
    '67': 61.2,
    '68': 61,
    '69': 60.9,
    '70': 60.8,
    '71': 60.7,
    '72': 60.6,
    '73': 60.4,
    '74': 60.3,
    '75': 60.2,
    '76': 60.1,
    '77': 60,
    '78': 59.9,
    '79': 59.7,
    '80': 59.6,
    '81': 59.5,
    '82': 59.4,
    '83': 59.3,
    '84': 59.2,
    '85': 59.1,
    '86': 58.9,
    '87': 58.8,
    '88': 58.7,
    '89': 58.6,
    '90': 58.5,
    '91': 58.4,
    '92': 58.3,
    '93': 58.1,
    '94': 58,
    '95': 57.9,
    '96': 57.8,
    '97': 57.7,
    '98': 57.6,
    '99': 57.5,
    '100': 57.4,
    '101': 57.2,
    '102': 57.1,
    '103': 57,
    '104': 56.9,
    '105': 56.8,
    '106': 56.7,
    '107': 56.6,
    '108': 56.5,
    '109': 56.4,
    '110': 56.3,
    '111': 56.1,
    '112': 56,
    '113': 55.9,
    '114': 55.8,
    '115': 55.7,
    '116': 55.6,
    '117': 55.5,
    '118': 55.4,
    '119': 55.3,
    '120': 55.2,
    '121': 55.1,
    '122': 55,
    '123': 54.9,
    '124': 54.8,
    '125': 54.6,
    '126': 54.5,
    '127': 54.4,
    '128': 54.3,
    '129': 54.2,
    '130': 54.1,
    '131': 54,
    '132': 53.9,
    '133': 53.8,
    '134': 53.7,
    '135': 53.6,
    '136': 53.5,
    '137': 53.4,
    '138': 53.3,
    '139': 53.2,
    '140': 53.1,
    '141': 53,
    '142': 52.9,
    '143': 52.8,
    '144': 52.7,
    '145': 52.6,
    '146': 52.5,
    '147': 52.4,
    '148': 52.3,
    '149': 52.2,
    '150': 52.1,
    '151': 52,
    '152': 51.9,
    '153': 51.8,
    '154': 51.7,
    '155': 51.6,
    '156': 51.5,
    '157': 51.4,
    '158': 51.3,
    '159': 51.2,
    '160': 51.1,
    '161': 51,
    '162': 50.9,
    '163': 50.8,
    '164': 50.7,
    '165': 50.6,
    '166': 50.5,
    '167': 50.4,
    '168': 50.3,
    '169': 50.2,
    '170': 50.1,
    '171': 50,
    '172': 49.9,
    '173': 49.8,
    '174': 49.7,
    '175': 49.6,
    '176': 49.5,
    '177': 49.4,
    '178': 49.3,
    '179': 49.2,
    '180': 49.1,
    '181': 49,
    '182': 48.9,
    '183': 48.9,
    '184': 48.8,
    '185': 48.7,
    '186': 48.6,
    '187': 48.5,
    '188': 48.4,
    '189': 48.3,
    '190': 48.2,
    '191': 48.1,
    '192': 48,
    '193': 47.9,
    '194': 47.8,
    '195': 47.7,
    '0.5': 69.7,
    '1.5': 69.6,
    '2.5': 69.4,
    '3.5': 69.3,
    '4.5': 69.2,
    '5.5': 69,
    '6.5': 68.9,
    '7.5': 68.7,
    '8.5': 68.6,
    '9.5': 68.5,
    '10.5': 68.3,
    '11.5': 68.2,
    '12.5': 68.1,
    '13.5': 67.9,
    '14.5': 67.8,
    '15.5': 67.7,
    '16.5': 67.5,
    '17.5': 67.4,
    '18.5': 67.3,
    '19.5': 67.1,
    '20.5': 67,
    '21.5': 66.9,
    '22.5': 66.7,
    '23.5': 66.6,
    '24.5': 66.5,
    '25.5': 66.3,
    '26.5': 66.2,
    '27.5': 66.1,
    '28.5': 66,
    '29.5': 65.8,
    '30.5': 65.7,
    '31.5': 65.6,
    '32.5': 65.4,
    '33.5': 65.3,
    '34.5': 65.2,
    '35.5': 65,
    '36.5': 64.9,
    '37.5': 64.8,
    '38.5': 64.7,
    '39.5': 64.5,
    '40.5': 64.4,
    '41.5': 64.3,
    '42.5': 64.2,
    '43.5': 64,
    '44.5': 63.9,
    '45.5': 63.8,
    '46.5': 63.7,
    '47.5': 63.5,
    '48.5': 63.4,
    '49.5': 63.3,
    '50.5': 63.2,
    '51.5': 63,
    '52.5': 62.9,
    '53.5': 62.8,
    '54.5': 62.7,
    '55.5': 62.6,
    '56.5': 62.4,
    '57.5': 62.3,
    '58.5': 62.2,
    '59.5': 62.1,
    '60.5': 61.9,
    '61.5': 61.8,
    '62.5': 61.7,
    '63.5': 61.6,
    '64.5': 61.5,
    '65.5': 61.3,
    '66.5': 61.2,
    '67.5': 61.1,
    '68.5': 61,
    '69.5': 60.9,
    '70.5': 60.7,
    '71.5': 60.6,
    '72.5': 60.5,
    '73.5': 60.4,
    '74.5': 60.3,
    '75.5': 60.2,
    '76.5': 60,
    '77.5': 59.9,
    '78.5': 59.8,
    '79.5': 59.7,
    '80.5': 59.6,
    '81.5': 59.5,
    '82.5': 59.3,
    '83.5': 59.2,
    '84.5': 59.1,
    '85.5': 59,
    '86.5': 58.9,
    '87.5': 58.8,
    '88.5': 58.7,
    '89.5': 58.5,
    '90.5': 58.4,
    '91.5': 58.3,
    '92.5': 58.2,
    '93.5': 58.1,
    '94.5': 58,
    '95.5': 57.9,
    '96.5': 57.7,
    '97.5': 57.6,
    '98.5': 57.5,
    '99.5': 57.4,
    '100.5': 57.3,
    '101.5': 57.2,
    '102.5': 57.1,
    '103.5': 57,
    '104.5': 56.9,
    '105.5': 56.7,
    '106.5': 56.6,
    '107.5': 56.5,
    '108.5': 56.4,
    '109.5': 56.3,
    '110.5': 56.2,
    '111.5': 56.1,
    '112.5': 56,
    '113.5': 55.9,
    '114.5': 55.8,
    '115.5': 55.7,
    '116.5': 55.5,
    '117.5': 55.4,
    '118.5': 55.3,
    '119.5': 55.2,
    '120.5': 55.1,
    '121.5': 55,
    '122.5': 54.9,
    '123.5': 54.8,
    '124.5': 54.7,
    '125.5': 54.6,
    '126.5': 54.5,
    '127.5': 54.4,
    '128.5': 54.3,
    '129.5': 54.2,
    '130.5': 54.1,
    '131.5': 54,
    '132.5': 53.9,
    '133.5': 53.8,
    '134.5': 53.6,
    '135.5': 53.5,
    '136.5': 53.4,
    '137.5': 53.3,
    '138.5': 53.2,
    '139.5': 53.1,
    '140.5': 53,
    '141.5': 52.9,
    '142.5': 52.8,
    '143.5': 52.7,
    '144.5': 52.6,
    '145.5': 52.5,
    '146.5': 52.4,
    '147.5': 52.3,
    '148.5': 52.2,
    '149.5': 52.1,
    '150.5': 52,
    '151.5': 51.9,
    '152.5': 51.8,
    '153.5': 51.7,
    '154.5': 51.6,
    '155.5': 51.5,
    '156.5': 51.4,
    '157.5': 51.3,
    '158.5': 51.2,
    '159.5': 51.1,
    '160.5': 51,
    '161.5': 50.9,
    '162.5': 50.8,
    '163.5': 50.7,
    '164.5': 50.6,
    '165.5': 50.5,
    '166.5': 50.4,
    '167.5': 50.3,
    '168.5': 50.2,
    '169.5': 50.1,
    '170.5': 50,
    '171.5': 50,
    '172.5': 49.9,
    '173.5': 49.8,
    '174.5': 49.7,
    '175.5': 49.6,
    '176.5': 49.5,
    '177.5': 49.4,
    '178.5': 49.3,
    '179.5': 49.2,
    '180.5': 49.1,
    '181.5': 49,
    '182.5': 48.9,
    '183.5': 48.8,
    '184.5': 48.7,
    '185.5': 48.6,
    '186.5': 48.5,
    '187.5': 48.4,
    '188.5': 48.3,
    '189.5': 48.2,
    '190.5': 48.2,
    '191.5': 48.1,
    '192.5': 48,
    '193.5': 47.9,
    '194.5': 47.8,
  },
  '62.5': {
    '0': 70.3,
    '1': 70.2,
    '2': 70.1,
    '3': 69.9,
    '4': 69.8,
    '5': 69.6,
    '6': 69.5,
    '7': 69.4,
    '8': 69.2,
    '9': 69.1,
    '10': 69,
    '11': 68.8,
    '12': 68.7,
    '13': 68.5,
    '14': 68.4,
    '15': 68.3,
    '16': 68.1,
    '17': 68,
    '18': 67.9,
    '19': 67.7,
    '20': 67.6,
    '21': 67.5,
    '22': 67.3,
    '23': 67.2,
    '24': 67.1,
    '25': 66.9,
    '26': 66.8,
    '27': 66.7,
    '28': 66.6,
    '29': 66.4,
    '30': 66.3,
    '31': 66.2,
    '32': 66,
    '33': 65.9,
    '34': 65.8,
    '35': 65.6,
    '36': 65.5,
    '37': 65.4,
    '38': 65.3,
    '39': 65.1,
    '40': 65,
    '41': 64.9,
    '42': 64.7,
    '43': 64.6,
    '44': 64.5,
    '45': 64.4,
    '46': 64.2,
    '47': 64.1,
    '48': 64,
    '49': 63.9,
    '50': 63.7,
    '51': 63.6,
    '52': 63.5,
    '53': 63.4,
    '54': 63.2,
    '55': 63.1,
    '56': 63,
    '57': 62.9,
    '58': 62.7,
    '59': 62.6,
    '60': 62.5,
    '61': 62.4,
    '62': 62.3,
    '63': 62.1,
    '64': 62,
    '65': 61.9,
    '66': 61.8,
    '67': 61.7,
    '68': 61.5,
    '69': 61.4,
    '70': 61.3,
    '71': 61.2,
    '72': 61.1,
    '73': 60.9,
    '74': 60.8,
    '75': 60.7,
    '76': 60.6,
    '77': 60.5,
    '78': 60.3,
    '79': 60.2,
    '80': 60.1,
    '81': 60,
    '82': 59.9,
    '83': 59.8,
    '84': 59.6,
    '85': 59.5,
    '86': 59.4,
    '87': 59.3,
    '88': 59.2,
    '89': 59.1,
    '90': 59,
    '91': 58.8,
    '92': 58.7,
    '93': 58.6,
    '94': 58.5,
    '95': 58.4,
    '96': 58.3,
    '97': 58.2,
    '98': 58,
    '99': 57.9,
    '100': 57.8,
    '101': 57.7,
    '102': 57.6,
    '103': 57.5,
    '104': 57.4,
    '105': 57.3,
    '106': 57.2,
    '107': 57,
    '108': 56.9,
    '109': 56.8,
    '110': 56.7,
    '111': 56.6,
    '112': 56.5,
    '113': 56.4,
    '114': 56.3,
    '115': 56.2,
    '116': 56.1,
    '117': 55.9,
    '118': 55.8,
    '119': 55.7,
    '120': 55.6,
    '121': 55.5,
    '122': 55.4,
    '123': 55.3,
    '124': 55.2,
    '125': 55.1,
    '126': 55,
    '127': 54.9,
    '128': 54.8,
    '129': 54.7,
    '130': 54.6,
    '131': 54.5,
    '132': 54.3,
    '133': 54.2,
    '134': 54.1,
    '135': 54,
    '136': 53.9,
    '137': 53.8,
    '138': 53.7,
    '139': 53.6,
    '140': 53.5,
    '141': 53.4,
    '142': 53.3,
    '143': 53.2,
    '144': 53.1,
    '145': 53,
    '146': 52.9,
    '147': 52.8,
    '148': 52.7,
    '149': 52.6,
    '150': 52.5,
    '151': 52.4,
    '152': 52.3,
    '153': 52.2,
    '154': 52.1,
    '155': 52,
    '156': 51.9,
    '157': 51.8,
    '158': 51.7,
    '159': 51.6,
    '160': 51.5,
    '161': 51.4,
    '162': 51.3,
    '163': 51.2,
    '164': 51.1,
    '165': 51,
    '166': 50.9,
    '167': 50.8,
    '168': 50.7,
    '169': 50.6,
    '170': 50.5,
    '171': 50.4,
    '172': 50.3,
    '173': 50.2,
    '174': 50.1,
    '175': 50,
    '176': 49.9,
    '177': 49.8,
    '178': 49.7,
    '179': 49.6,
    '180': 49.5,
    '181': 49.4,
    '182': 49.4,
    '183': 49.3,
    '184': 49.2,
    '185': 49.1,
    '186': 49,
    '187': 48.9,
    '188': 48.8,
    '189': 48.7,
    '190': 48.6,
    '191': 48.5,
    '192': 48.4,
    '193': 48.3,
    '194': 48.2,
    '195': 48.1,
    '0.5': 70.3,
    '1.5': 70.1,
    '2.5': 70,
    '3.5': 69.8,
    '4.5': 69.7,
    '5.5': 69.6,
    '6.5': 69.4,
    '7.5': 69.3,
    '8.5': 69.2,
    '9.5': 69,
    '10.5': 68.9,
    '11.5': 68.8,
    '12.5': 68.6,
    '13.5': 68.5,
    '14.5': 68.3,
    '15.5': 68.2,
    '16.5': 68.1,
    '17.5': 67.9,
    '18.5': 67.8,
    '19.5': 67.7,
    '20.5': 67.5,
    '21.5': 67.4,
    '22.5': 67.3,
    '23.5': 67.1,
    '24.5': 67,
    '25.5': 66.9,
    '26.5': 66.7,
    '27.5': 66.6,
    '28.5': 66.5,
    '29.5': 66.4,
    '30.5': 66.2,
    '31.5': 66.1,
    '32.5': 66,
    '33.5': 65.8,
    '34.5': 65.7,
    '35.5': 65.6,
    '36.5': 65.4,
    '37.5': 65.3,
    '38.5': 65.2,
    '39.5': 65.1,
    '40.5': 64.9,
    '41.5': 64.8,
    '42.5': 64.7,
    '43.5': 64.6,
    '44.5': 64.4,
    '45.5': 64.3,
    '46.5': 64.2,
    '47.5': 64,
    '48.5': 63.9,
    '49.5': 63.8,
    '50.5': 63.7,
    '51.5': 63.5,
    '52.5': 63.4,
    '53.5': 63.3,
    '54.5': 63.2,
    '55.5': 63.1,
    '56.5': 62.9,
    '57.5': 62.8,
    '58.5': 62.7,
    '59.5': 62.6,
    '60.5': 62.4,
    '61.5': 62.3,
    '62.5': 62.2,
    '63.5': 62.1,
    '64.5': 62,
    '65.5': 61.8,
    '66.5': 61.7,
    '67.5': 61.6,
    '68.5': 61.5,
    '69.5': 61.4,
    '70.5': 61.2,
    '71.5': 61.1,
    '72.5': 61,
    '73.5': 60.9,
    '74.5': 60.8,
    '75.5': 60.6,
    '76.5': 60.5,
    '77.5': 60.4,
    '78.5': 60.3,
    '79.5': 60.2,
    '80.5': 60.1,
    '81.5': 59.9,
    '82.5': 59.8,
    '83.5': 59.7,
    '84.5': 59.6,
    '85.5': 59.5,
    '86.5': 59.4,
    '87.5': 59.2,
    '88.5': 59.1,
    '89.5': 59,
    '90.5': 58.9,
    '91.5': 58.8,
    '92.5': 58.7,
    '93.5': 58.6,
    '94.5': 58.4,
    '95.5': 58.3,
    '96.5': 58.2,
    '97.5': 58.1,
    '98.5': 58,
    '99.5': 57.9,
    '100.5': 57.8,
    '101.5': 57.6,
    '102.5': 57.5,
    '103.5': 57.4,
    '104.5': 57.3,
    '105.5': 57.2,
    '106.5': 57.1,
    '107.5': 57,
    '108.5': 56.9,
    '109.5': 56.8,
    '110.5': 56.7,
    '111.5': 56.5,
    '112.5': 56.4,
    '113.5': 56.3,
    '114.5': 56.2,
    '115.5': 56.1,
    '116.5': 56,
    '117.5': 55.9,
    '118.5': 55.8,
    '119.5': 55.7,
    '120.5': 55.6,
    '121.5': 55.5,
    '122.5': 55.4,
    '123.5': 55.2,
    '124.5': 55.1,
    '125.5': 55,
    '126.5': 54.9,
    '127.5': 54.8,
    '128.5': 54.7,
    '129.5': 54.6,
    '130.5': 54.5,
    '131.5': 54.4,
    '132.5': 54.3,
    '133.5': 54.2,
    '134.5': 54.1,
    '135.5': 54,
    '136.5': 53.9,
    '137.5': 53.8,
    '138.5': 53.7,
    '139.5': 53.6,
    '140.5': 53.5,
    '141.5': 53.4,
    '142.5': 53.3,
    '143.5': 53.2,
    '144.5': 53.1,
    '145.5': 52.9,
    '146.5': 52.8,
    '147.5': 52.7,
    '148.5': 52.6,
    '149.5': 52.5,
    '150.5': 52.4,
    '151.5': 52.3,
    '152.5': 52.2,
    '153.5': 52.1,
    '154.5': 52,
    '155.5': 51.9,
    '156.5': 51.8,
    '157.5': 51.7,
    '158.5': 51.6,
    '159.5': 51.5,
    '160.5': 51.4,
    '161.5': 51.3,
    '162.5': 51.2,
    '163.5': 51.1,
    '164.5': 51,
    '165.5': 50.9,
    '166.5': 50.8,
    '167.5': 50.7,
    '168.5': 50.7,
    '169.5': 50.6,
    '170.5': 50.5,
    '171.5': 50.4,
    '172.5': 50.3,
    '173.5': 50.2,
    '174.5': 50.1,
    '175.5': 50,
    '176.5': 49.9,
    '177.5': 49.8,
    '178.5': 49.7,
    '179.5': 49.6,
    '180.5': 49.5,
    '181.5': 49.4,
    '182.5': 49.3,
    '183.5': 49.2,
    '184.5': 49.1,
    '185.5': 49,
    '186.5': 48.9,
    '187.5': 48.8,
    '188.5': 48.7,
    '189.5': 48.6,
    '190.5': 48.6,
    '191.5': 48.5,
    '192.5': 48.4,
    '193.5': 48.3,
    '194.5': 48.2,
  },
  '63': {
    '0': 70.9,
    '1': 70.8,
    '2': 70.6,
    '3': 70.5,
    '4': 70.3,
    '5': 70.2,
    '6': 70.1,
    '7': 69.9,
    '8': 69.8,
    '9': 69.6,
    '10': 69.5,
    '11': 69.4,
    '12': 69.2,
    '13': 69.1,
    '14': 69,
    '15': 68.8,
    '16': 68.7,
    '17': 68.6,
    '18': 68.4,
    '19': 68.3,
    '20': 68.1,
    '21': 68,
    '22': 67.9,
    '23': 67.7,
    '24': 67.6,
    '25': 67.5,
    '26': 67.3,
    '27': 67.2,
    '28': 67.1,
    '29': 67,
    '30': 66.8,
    '31': 66.7,
    '32': 66.6,
    '33': 66.4,
    '34': 66.3,
    '35': 66.2,
    '36': 66,
    '37': 65.9,
    '38': 65.8,
    '39': 65.6,
    '40': 65.5,
    '41': 65.4,
    '42': 65.3,
    '43': 65.1,
    '44': 65,
    '45': 64.9,
    '46': 64.7,
    '47': 64.6,
    '48': 64.5,
    '49': 64.4,
    '50': 64.2,
    '51': 64.1,
    '52': 64,
    '53': 63.9,
    '54': 63.7,
    '55': 63.6,
    '56': 63.5,
    '57': 63.4,
    '58': 63.2,
    '59': 63.1,
    '60': 63,
    '61': 62.9,
    '62': 62.8,
    '63': 62.6,
    '64': 62.5,
    '65': 62.4,
    '66': 62.3,
    '67': 62.1,
    '68': 62,
    '69': 61.9,
    '70': 61.8,
    '71': 61.7,
    '72': 61.5,
    '73': 61.4,
    '74': 61.3,
    '75': 61.2,
    '76': 61.1,
    '77': 60.9,
    '78': 60.8,
    '79': 60.7,
    '80': 60.6,
    '81': 60.5,
    '82': 60.4,
    '83': 60.2,
    '84': 60.1,
    '85': 60,
    '86': 59.9,
    '87': 59.8,
    '88': 59.7,
    '89': 59.5,
    '90': 59.4,
    '91': 59.3,
    '92': 59.2,
    '93': 59.1,
    '94': 59,
    '95': 58.8,
    '96': 58.7,
    '97': 58.6,
    '98': 58.5,
    '99': 58.4,
    '100': 58.3,
    '101': 58.2,
    '102': 58.1,
    '103': 57.9,
    '104': 57.8,
    '105': 57.7,
    '106': 57.6,
    '107': 57.5,
    '108': 57.4,
    '109': 57.3,
    '110': 57.2,
    '111': 57.1,
    '112': 56.9,
    '113': 56.8,
    '114': 56.7,
    '115': 56.6,
    '116': 56.5,
    '117': 56.4,
    '118': 56.3,
    '119': 56.2,
    '120': 56.1,
    '121': 56,
    '122': 55.9,
    '123': 55.7,
    '124': 55.6,
    '125': 55.5,
    '126': 55.4,
    '127': 55.3,
    '128': 55.2,
    '129': 55.1,
    '130': 55,
    '131': 54.9,
    '132': 54.8,
    '133': 54.7,
    '134': 54.6,
    '135': 54.5,
    '136': 54.4,
    '137': 54.3,
    '138': 54.2,
    '139': 54.1,
    '140': 53.9,
    '141': 53.8,
    '142': 53.7,
    '143': 53.6,
    '144': 53.5,
    '145': 53.4,
    '146': 53.3,
    '147': 53.2,
    '148': 53.1,
    '149': 53,
    '150': 52.9,
    '151': 52.8,
    '152': 52.7,
    '153': 52.6,
    '154': 52.5,
    '155': 52.4,
    '156': 52.3,
    '157': 52.2,
    '158': 52.1,
    '159': 52,
    '160': 51.9,
    '161': 51.8,
    '162': 51.7,
    '163': 51.6,
    '164': 51.5,
    '165': 51.4,
    '166': 51.3,
    '167': 51.2,
    '168': 51.1,
    '169': 51,
    '170': 50.9,
    '171': 50.8,
    '172': 50.7,
    '173': 50.6,
    '174': 50.5,
    '175': 50.4,
    '176': 50.3,
    '177': 50.2,
    '178': 50.1,
    '179': 50,
    '180': 49.9,
    '181': 49.9,
    '182': 49.8,
    '183': 49.7,
    '184': 49.6,
    '185': 49.5,
    '186': 49.4,
    '187': 49.3,
    '188': 49.2,
    '189': 49.1,
    '190': 49,
    '191': 48.9,
    '192': 48.8,
    '193': 48.7,
    '194': 48.6,
    '195': 48.5,
    '0.5': 70.8,
    '1.5': 70.7,
    '2.5': 70.5,
    '3.5': 70.4,
    '4.5': 70.3,
    '5.5': 70.1,
    '6.5': 70,
    '7.5': 69.9,
    '8.5': 69.7,
    '9.5': 69.6,
    '10.5': 69.4,
    '11.5': 69.3,
    '12.5': 69.2,
    '13.5': 69,
    '14.5': 68.9,
    '15.5': 68.8,
    '16.5': 68.6,
    '17.5': 68.5,
    '18.5': 68.3,
    '19.5': 68.2,
    '20.5': 68.1,
    '21.5': 67.9,
    '22.5': 67.8,
    '23.5': 67.7,
    '24.5': 67.5,
    '25.5': 67.4,
    '26.5': 67.3,
    '27.5': 67.1,
    '28.5': 67,
    '29.5': 66.9,
    '30.5': 66.8,
    '31.5': 66.6,
    '32.5': 66.5,
    '33.5': 66.4,
    '34.5': 66.2,
    '35.5': 66.1,
    '36.5': 66,
    '37.5': 65.8,
    '38.5': 65.7,
    '39.5': 65.6,
    '40.5': 65.5,
    '41.5': 65.3,
    '42.5': 65.2,
    '43.5': 65.1,
    '44.5': 64.9,
    '45.5': 64.8,
    '46.5': 64.7,
    '47.5': 64.6,
    '48.5': 64.4,
    '49.5': 64.3,
    '50.5': 64.2,
    '51.5': 64.1,
    '52.5': 63.9,
    '53.5': 63.8,
    '54.5': 63.7,
    '55.5': 63.6,
    '56.5': 63.4,
    '57.5': 63.3,
    '58.5': 63.2,
    '59.5': 63.1,
    '60.5': 62.9,
    '61.5': 62.8,
    '62.5': 62.7,
    '63.5': 62.6,
    '64.5': 62.4,
    '65.5': 62.3,
    '66.5': 62.2,
    '67.5': 62.1,
    '68.5': 62,
    '69.5': 61.8,
    '70.5': 61.7,
    '71.5': 61.6,
    '72.5': 61.5,
    '73.5': 61.4,
    '74.5': 61.2,
    '75.5': 61.1,
    '76.5': 61,
    '77.5': 60.9,
    '78.5': 60.8,
    '79.5': 60.6,
    '80.5': 60.5,
    '81.5': 60.4,
    '82.5': 60.3,
    '83.5': 60.2,
    '84.5': 60.1,
    '85.5': 59.9,
    '86.5': 59.8,
    '87.5': 59.7,
    '88.5': 59.6,
    '89.5': 59.5,
    '90.5': 59.4,
    '91.5': 59.3,
    '92.5': 59.1,
    '93.5': 59,
    '94.5': 58.9,
    '95.5': 58.8,
    '96.5': 58.7,
    '97.5': 58.6,
    '98.5': 58.4,
    '99.5': 58.3,
    '100.5': 58.2,
    '101.5': 58.1,
    '102.5': 58,
    '103.5': 57.9,
    '104.5': 57.8,
    '105.5': 57.7,
    '106.5': 57.6,
    '107.5': 57.4,
    '108.5': 57.3,
    '109.5': 57.2,
    '110.5': 57.1,
    '111.5': 57,
    '112.5': 56.9,
    '113.5': 56.8,
    '114.5': 56.7,
    '115.5': 56.6,
    '116.5': 56.4,
    '117.5': 56.3,
    '118.5': 56.2,
    '119.5': 56.1,
    '120.5': 56,
    '121.5': 55.9,
    '122.5': 55.8,
    '123.5': 55.7,
    '124.5': 55.6,
    '125.5': 55.5,
    '126.5': 55.4,
    '127.5': 55.3,
    '128.5': 55.2,
    '129.5': 55.1,
    '130.5': 54.9,
    '131.5': 54.8,
    '132.5': 54.7,
    '133.5': 54.6,
    '134.5': 54.5,
    '135.5': 54.4,
    '136.5': 54.3,
    '137.5': 54.2,
    '138.5': 54.1,
    '139.5': 54,
    '140.5': 53.9,
    '141.5': 53.8,
    '142.5': 53.7,
    '143.5': 53.6,
    '144.5': 53.5,
    '145.5': 53.4,
    '146.5': 53.3,
    '147.5': 53.2,
    '148.5': 53.1,
    '149.5': 53,
    '150.5': 52.9,
    '151.5': 52.8,
    '152.5': 52.7,
    '153.5': 52.6,
    '154.5': 52.5,
    '155.5': 52.4,
    '156.5': 52.3,
    '157.5': 52.2,
    '158.5': 52.1,
    '159.5': 52,
    '160.5': 51.9,
    '161.5': 51.8,
    '162.5': 51.7,
    '163.5': 51.6,
    '164.5': 51.5,
    '165.5': 51.4,
    '166.5': 51.3,
    '167.5': 51.2,
    '168.5': 51.1,
    '169.5': 51,
    '170.5': 50.9,
    '171.5': 50.8,
    '172.5': 50.7,
    '173.5': 50.6,
    '174.5': 50.5,
    '175.5': 50.4,
    '176.5': 50.3,
    '177.5': 50.2,
    '178.5': 50.1,
    '179.5': 50,
    '180.5': 49.9,
    '181.5': 49.8,
    '182.5': 49.7,
    '183.5': 49.6,
    '184.5': 49.5,
    '185.5': 49.4,
    '186.5': 49.3,
    '187.5': 49.2,
    '188.5': 49.1,
    '189.5': 49,
    '190.5': 49,
    '191.5': 48.9,
    '192.5': 48.8,
    '193.5': 48.7,
    '194.5': 48.6,
  },
  '63.5': {
    '0': 71.5,
    '1': 71.3,
    '2': 71.2,
    '3': 71,
    '4': 70.9,
    '5': 70.8,
    '6': 70.6,
    '7': 70.5,
    '8': 70.3,
    '9': 70.2,
    '10': 70.1,
    '11': 69.9,
    '12': 69.8,
    '13': 69.6,
    '14': 69.5,
    '15': 69.4,
    '16': 69.2,
    '17': 69.1,
    '18': 69,
    '19': 68.8,
    '20': 68.7,
    '21': 68.6,
    '22': 68.4,
    '23': 68.3,
    '24': 68.2,
    '25': 68,
    '26': 67.9,
    '27': 67.7,
    '28': 67.6,
    '29': 67.5,
    '30': 67.3,
    '31': 67.2,
    '32': 67.1,
    '33': 67,
    '34': 66.8,
    '35': 66.7,
    '36': 66.6,
    '37': 66.4,
    '38': 66.3,
    '39': 66.2,
    '40': 66,
    '41': 65.9,
    '42': 65.8,
    '43': 65.6,
    '44': 65.5,
    '45': 65.4,
    '46': 65.3,
    '47': 65.1,
    '48': 65,
    '49': 64.9,
    '50': 64.8,
    '51': 64.6,
    '52': 64.5,
    '53': 64.4,
    '54': 64.2,
    '55': 64.1,
    '56': 64,
    '57': 63.9,
    '58': 63.7,
    '59': 63.6,
    '60': 63.5,
    '61': 63.4,
    '62': 63.3,
    '63': 63.1,
    '64': 63,
    '65': 62.9,
    '66': 62.8,
    '67': 62.6,
    '68': 62.5,
    '69': 62.4,
    '70': 62.3,
    '71': 62.2,
    '72': 62,
    '73': 61.9,
    '74': 61.8,
    '75': 61.7,
    '76': 61.5,
    '77': 61.4,
    '78': 61.3,
    '79': 61.2,
    '80': 61.1,
    '81': 61,
    '82': 60.8,
    '83': 60.7,
    '84': 60.6,
    '85': 60.5,
    '86': 60.4,
    '87': 60.2,
    '88': 60.1,
    '89': 60,
    '90': 59.9,
    '91': 59.8,
    '92': 59.7,
    '93': 59.6,
    '94': 59.4,
    '95': 59.3,
    '96': 59.2,
    '97': 59.1,
    '98': 59,
    '99': 58.9,
    '100': 58.7,
    '101': 58.6,
    '102': 58.5,
    '103': 58.4,
    '104': 58.3,
    '105': 58.2,
    '106': 58.1,
    '107': 58,
    '108': 57.8,
    '109': 57.7,
    '110': 57.6,
    '111': 57.5,
    '112': 57.4,
    '113': 57.3,
    '114': 57.2,
    '115': 57.1,
    '116': 57,
    '117': 56.8,
    '118': 56.7,
    '119': 56.6,
    '120': 56.5,
    '121': 56.4,
    '122': 56.3,
    '123': 56.2,
    '124': 56.1,
    '125': 56,
    '126': 55.9,
    '127': 55.8,
    '128': 55.7,
    '129': 55.5,
    '130': 55.4,
    '131': 55.3,
    '132': 55.2,
    '133': 55.1,
    '134': 55,
    '135': 54.9,
    '136': 54.8,
    '137': 54.7,
    '138': 54.6,
    '139': 54.5,
    '140': 54.4,
    '141': 54.3,
    '142': 54.2,
    '143': 54.1,
    '144': 54,
    '145': 53.9,
    '146': 53.8,
    '147': 53.7,
    '148': 53.6,
    '149': 53.4,
    '150': 53.3,
    '151': 53.2,
    '152': 53.1,
    '153': 53,
    '154': 52.9,
    '155': 52.8,
    '156': 52.7,
    '157': 52.6,
    '158': 52.5,
    '159': 52.4,
    '160': 52.3,
    '161': 52.2,
    '162': 52.1,
    '163': 52,
    '164': 51.9,
    '165': 51.8,
    '166': 51.7,
    '167': 51.6,
    '168': 51.5,
    '169': 51.4,
    '170': 51.3,
    '171': 51.2,
    '172': 51.1,
    '173': 51,
    '174': 50.9,
    '175': 50.8,
    '176': 50.7,
    '177': 50.6,
    '178': 50.6,
    '179': 50.5,
    '180': 50.4,
    '181': 50.3,
    '182': 50.2,
    '183': 50.1,
    '184': 50,
    '185': 49.9,
    '186': 49.8,
    '187': 49.7,
    '188': 49.6,
    '189': 49.5,
    '190': 49.4,
    '191': 49.3,
    '192': 49.2,
    '193': 49.1,
    '194': 49,
    '195': 48.9,
    '0.5': 71.4,
    '1.5': 71.3,
    '2.5': 71.1,
    '3.5': 71,
    '4.5': 70.8,
    '5.5': 70.7,
    '6.5': 70.5,
    '7.5': 70.4,
    '8.5': 70.3,
    '9.5': 70.1,
    '10.5': 70,
    '11.5': 69.9,
    '12.5': 69.7,
    '13.5': 69.6,
    '14.5': 69.4,
    '15.5': 69.3,
    '16.5': 69.2,
    '17.5': 69,
    '18.5': 68.9,
    '19.5': 68.8,
    '20.5': 68.6,
    '21.5': 68.5,
    '22.5': 68.4,
    '23.5': 68.2,
    '24.5': 68.1,
    '25.5': 68,
    '26.5': 67.8,
    '27.5': 67.7,
    '28.5': 67.5,
    '29.5': 67.4,
    '30.5': 67.3,
    '31.5': 67.2,
    '32.5': 67,
    '33.5': 66.9,
    '34.5': 66.8,
    '35.5': 66.6,
    '36.5': 66.5,
    '37.5': 66.4,
    '38.5': 66.2,
    '39.5': 66.1,
    '40.5': 66,
    '41.5': 65.8,
    '42.5': 65.7,
    '43.5': 65.6,
    '44.5': 65.5,
    '45.5': 65.3,
    '46.5': 65.2,
    '47.5': 65.1,
    '48.5': 64.9,
    '49.5': 64.8,
    '50.5': 64.7,
    '51.5': 64.6,
    '52.5': 64.4,
    '53.5': 64.3,
    '54.5': 64.2,
    '55.5': 64.1,
    '56.5': 63.9,
    '57.5': 63.8,
    '58.5': 63.7,
    '59.5': 63.6,
    '60.5': 63.4,
    '61.5': 63.3,
    '62.5': 63.2,
    '63.5': 63.1,
    '64.5': 62.9,
    '65.5': 62.8,
    '66.5': 62.7,
    '67.5': 62.6,
    '68.5': 62.5,
    '69.5': 62.3,
    '70.5': 62.2,
    '71.5': 62.1,
    '72.5': 62,
    '73.5': 61.9,
    '74.5': 61.7,
    '75.5': 61.6,
    '76.5': 61.5,
    '77.5': 61.4,
    '78.5': 61.3,
    '79.5': 61.1,
    '80.5': 61,
    '81.5': 60.9,
    '82.5': 60.8,
    '83.5': 60.7,
    '84.5': 60.5,
    '85.5': 60.4,
    '86.5': 60.3,
    '87.5': 60.2,
    '88.5': 60.1,
    '89.5': 60,
    '90.5': 59.8,
    '91.5': 59.7,
    '92.5': 59.6,
    '93.5': 59.5,
    '94.5': 59.4,
    '95.5': 59.3,
    '96.5': 59.1,
    '97.5': 59,
    '98.5': 58.9,
    '99.5': 58.8,
    '100.5': 58.7,
    '101.5': 58.6,
    '102.5': 58.5,
    '103.5': 58.3,
    '104.5': 58.2,
    '105.5': 58.1,
    '106.5': 58,
    '107.5': 57.9,
    '108.5': 57.8,
    '109.5': 57.7,
    '110.5': 57.6,
    '111.5': 57.5,
    '112.5': 57.3,
    '113.5': 57.2,
    '114.5': 57.1,
    '115.5': 57,
    '116.5': 56.9,
    '117.5': 56.8,
    '118.5': 56.7,
    '119.5': 56.6,
    '120.5': 56.5,
    '121.5': 56.4,
    '122.5': 56.2,
    '123.5': 56.1,
    '124.5': 56,
    '125.5': 55.9,
    '126.5': 55.8,
    '127.5': 55.7,
    '128.5': 55.6,
    '129.5': 55.5,
    '130.5': 55.4,
    '131.5': 55.3,
    '132.5': 55.2,
    '133.5': 55.1,
    '134.5': 55,
    '135.5': 54.9,
    '136.5': 54.7,
    '137.5': 54.6,
    '138.5': 54.5,
    '139.5': 54.4,
    '140.5': 54.3,
    '141.5': 54.2,
    '142.5': 54.1,
    '143.5': 54,
    '144.5': 53.9,
    '145.5': 53.8,
    '146.5': 53.7,
    '147.5': 53.6,
    '148.5': 53.5,
    '149.5': 53.4,
    '150.5': 53.3,
    '151.5': 53.2,
    '152.5': 53.1,
    '153.5': 53,
    '154.5': 52.9,
    '155.5': 52.8,
    '156.5': 52.7,
    '157.5': 52.6,
    '158.5': 52.5,
    '159.5': 52.4,
    '160.5': 52.3,
    '161.5': 52.2,
    '162.5': 52.1,
    '163.5': 52,
    '164.5': 51.9,
    '165.5': 51.8,
    '166.5': 51.7,
    '167.5': 51.6,
    '168.5': 51.5,
    '169.5': 51.4,
    '170.5': 51.3,
    '171.5': 51.2,
    '172.5': 51.1,
    '173.5': 51,
    '174.5': 50.9,
    '175.5': 50.8,
    '176.5': 50.7,
    '177.5': 50.6,
    '178.5': 50.5,
    '179.5': 50.4,
    '180.5': 50.3,
    '181.5': 50.2,
    '182.5': 50.1,
    '183.5': 50,
    '184.5': 49.9,
    '185.5': 49.8,
    '186.5': 49.7,
    '187.5': 49.6,
    '188.5': 49.5,
    '189.5': 49.4,
    '190.5': 49.4,
    '191.5': 49.3,
    '192.5': 49.2,
    '193.5': 49.1,
    '194.5': 49,
  },
  '64': {
    '0': 72,
    '1': 71.9,
    '2': 71.7,
    '3': 71.6,
    '4': 71.5,
    '5': 71.3,
    '6': 71.2,
    '7': 71,
    '8': 70.9,
    '9': 70.8,
    '10': 70.6,
    '11': 70.5,
    '12': 70.3,
    '13': 70.2,
    '14': 70.1,
    '15': 69.9,
    '16': 69.8,
    '17': 69.6,
    '18': 69.5,
    '19': 69.4,
    '20': 69.2,
    '21': 69.1,
    '22': 69,
    '23': 68.8,
    '24': 68.7,
    '25': 68.6,
    '26': 68.4,
    '27': 68.3,
    '28': 68.1,
    '29': 68,
    '30': 67.9,
    '31': 67.7,
    '32': 67.6,
    '33': 67.5,
    '34': 67.3,
    '35': 67.2,
    '36': 67.1,
    '37': 67,
    '38': 66.8,
    '39': 66.7,
    '40': 66.6,
    '41': 66.4,
    '42': 66.3,
    '43': 66.2,
    '44': 66,
    '45': 65.9,
    '46': 65.8,
    '47': 65.6,
    '48': 65.5,
    '49': 65.4,
    '50': 65.3,
    '51': 65.1,
    '52': 65,
    '53': 64.9,
    '54': 64.8,
    '55': 64.6,
    '56': 64.5,
    '57': 64.4,
    '58': 64.2,
    '59': 64.1,
    '60': 64,
    '61': 63.9,
    '62': 63.8,
    '63': 63.6,
    '64': 63.5,
    '65': 63.4,
    '66': 63.3,
    '67': 63.1,
    '68': 63,
    '69': 62.9,
    '70': 62.8,
    '71': 62.6,
    '72': 62.5,
    '73': 62.4,
    '74': 62.3,
    '75': 62.2,
    '76': 62,
    '77': 61.9,
    '78': 61.8,
    '79': 61.7,
    '80': 61.6,
    '81': 61.4,
    '82': 61.3,
    '83': 61.2,
    '84': 61.1,
    '85': 61,
    '86': 60.8,
    '87': 60.7,
    '88': 60.6,
    '89': 60.5,
    '90': 60.4,
    '91': 60.3,
    '92': 60.1,
    '93': 60,
    '94': 59.9,
    '95': 59.8,
    '96': 59.7,
    '97': 59.6,
    '98': 59.4,
    '99': 59.3,
    '100': 59.2,
    '101': 59.1,
    '102': 59,
    '103': 58.9,
    '104': 58.8,
    '105': 58.6,
    '106': 58.5,
    '107': 58.4,
    '108': 58.3,
    '109': 58.2,
    '110': 58.1,
    '111': 58,
    '112': 57.9,
    '113': 57.7,
    '114': 57.6,
    '115': 57.5,
    '116': 57.4,
    '117': 57.3,
    '118': 57.2,
    '119': 57.1,
    '120': 57,
    '121': 56.9,
    '122': 56.7,
    '123': 56.6,
    '124': 56.5,
    '125': 56.4,
    '126': 56.3,
    '127': 56.2,
    '128': 56.1,
    '129': 56,
    '130': 55.9,
    '131': 55.8,
    '132': 55.7,
    '133': 55.6,
    '134': 55.4,
    '135': 55.3,
    '136': 55.2,
    '137': 55.1,
    '138': 55,
    '139': 54.9,
    '140': 54.8,
    '141': 54.7,
    '142': 54.6,
    '143': 54.5,
    '144': 54.4,
    '145': 54.3,
    '146': 54.2,
    '147': 54.1,
    '148': 54,
    '149': 53.9,
    '150': 53.8,
    '151': 53.7,
    '152': 53.6,
    '153': 53.5,
    '154': 53.4,
    '155': 53.3,
    '156': 53.2,
    '157': 53.1,
    '158': 52.9,
    '159': 52.8,
    '160': 52.7,
    '161': 52.6,
    '162': 52.5,
    '163': 52.4,
    '164': 52.3,
    '165': 52.2,
    '166': 52.1,
    '167': 52,
    '168': 51.9,
    '169': 51.8,
    '170': 51.7,
    '171': 51.6,
    '172': 51.5,
    '173': 51.4,
    '174': 51.3,
    '175': 51.2,
    '176': 51.2,
    '177': 51.1,
    '178': 51,
    '179': 50.9,
    '180': 50.8,
    '181': 50.7,
    '182': 50.6,
    '183': 50.5,
    '184': 50.4,
    '185': 50.3,
    '186': 50.2,
    '187': 50.1,
    '188': 50,
    '189': 49.9,
    '190': 49.8,
    '191': 49.7,
    '192': 49.6,
    '193': 49.5,
    '194': 49.4,
    '195': 49.3,
    '0.5': 72,
    '1.5': 71.8,
    '2.5': 71.7,
    '3.5': 71.5,
    '4.5': 71.4,
    '5.5': 71.2,
    '6.5': 71.1,
    '7.5': 71,
    '8.5': 70.8,
    '9.5': 70.7,
    '10.5': 70.5,
    '11.5': 70.4,
    '12.5': 70.3,
    '13.5': 70.1,
    '14.5': 70,
    '15.5': 69.9,
    '16.5': 69.7,
    '17.5': 69.6,
    '18.5': 69.4,
    '19.5': 69.3,
    '20.5': 69.2,
    '21.5': 69,
    '22.5': 68.9,
    '23.5': 68.8,
    '24.5': 68.6,
    '25.5': 68.5,
    '26.5': 68.4,
    '27.5': 68.2,
    '28.5': 68.1,
    '29.5': 67.9,
    '30.5': 67.8,
    '31.5': 67.7,
    '32.5': 67.5,
    '33.5': 67.4,
    '34.5': 67.3,
    '35.5': 67.1,
    '36.5': 67,
    '37.5': 66.9,
    '38.5': 66.8,
    '39.5': 66.6,
    '40.5': 66.5,
    '41.5': 66.4,
    '42.5': 66.2,
    '43.5': 66.1,
    '44.5': 66,
    '45.5': 65.8,
    '46.5': 65.7,
    '47.5': 65.6,
    '48.5': 65.5,
    '49.5': 65.3,
    '50.5': 65.2,
    '51.5': 65.1,
    '52.5': 64.9,
    '53.5': 64.8,
    '54.5': 64.7,
    '55.5': 64.6,
    '56.5': 64.4,
    '57.5': 64.3,
    '58.5': 64.2,
    '59.5': 64.1,
    '60.5': 63.9,
    '61.5': 63.8,
    '62.5': 63.7,
    '63.5': 63.6,
    '64.5': 63.4,
    '65.5': 63.3,
    '66.5': 63.2,
    '67.5': 63.1,
    '68.5': 62.9,
    '69.5': 62.8,
    '70.5': 62.7,
    '71.5': 62.6,
    '72.5': 62.5,
    '73.5': 62.3,
    '74.5': 62.2,
    '75.5': 62.1,
    '76.5': 62,
    '77.5': 61.9,
    '78.5': 61.7,
    '79.5': 61.6,
    '80.5': 61.5,
    '81.5': 61.4,
    '82.5': 61.3,
    '83.5': 61.1,
    '84.5': 61,
    '85.5': 60.9,
    '86.5': 60.8,
    '87.5': 60.7,
    '88.5': 60.5,
    '89.5': 60.4,
    '90.5': 60.3,
    '91.5': 60.2,
    '92.5': 60.1,
    '93.5': 60,
    '94.5': 59.8,
    '95.5': 59.7,
    '96.5': 59.6,
    '97.5': 59.5,
    '98.5': 59.4,
    '99.5': 59.3,
    '100.5': 59.2,
    '101.5': 59,
    '102.5': 58.9,
    '103.5': 58.8,
    '104.5': 58.7,
    '105.5': 58.6,
    '106.5': 58.5,
    '107.5': 58.4,
    '108.5': 58.2,
    '109.5': 58.1,
    '110.5': 58,
    '111.5': 57.9,
    '112.5': 57.8,
    '113.5': 57.7,
    '114.5': 57.6,
    '115.5': 57.5,
    '116.5': 57.4,
    '117.5': 57.2,
    '118.5': 57.1,
    '119.5': 57,
    '120.5': 56.9,
    '121.5': 56.8,
    '122.5': 56.7,
    '123.5': 56.6,
    '124.5': 56.5,
    '125.5': 56.4,
    '126.5': 56.3,
    '127.5': 56.1,
    '128.5': 56,
    '129.5': 55.9,
    '130.5': 55.8,
    '131.5': 55.7,
    '132.5': 55.6,
    '133.5': 55.5,
    '134.5': 55.4,
    '135.5': 55.3,
    '136.5': 55.2,
    '137.5': 55.1,
    '138.5': 55,
    '139.5': 54.9,
    '140.5': 54.8,
    '141.5': 54.7,
    '142.5': 54.5,
    '143.5': 54.4,
    '144.5': 54.3,
    '145.5': 54.2,
    '146.5': 54.1,
    '147.5': 54,
    '148.5': 53.9,
    '149.5': 53.8,
    '150.5': 53.7,
    '151.5': 53.6,
    '152.5': 53.5,
    '153.5': 53.4,
    '154.5': 53.3,
    '155.5': 53.2,
    '156.5': 53.1,
    '157.5': 53,
    '158.5': 52.9,
    '159.5': 52.8,
    '160.5': 52.7,
    '161.5': 52.6,
    '162.5': 52.5,
    '163.5': 52.4,
    '164.5': 52.3,
    '165.5': 52.2,
    '166.5': 52.1,
    '167.5': 52,
    '168.5': 51.9,
    '169.5': 51.8,
    '170.5': 51.7,
    '171.5': 51.6,
    '172.5': 51.5,
    '173.5': 51.4,
    '174.5': 51.3,
    '175.5': 51.2,
    '176.5': 51.1,
    '177.5': 51,
    '178.5': 50.9,
    '179.5': 50.8,
    '180.5': 50.7,
    '181.5': 50.6,
    '182.5': 50.5,
    '183.5': 50.4,
    '184.5': 50.3,
    '185.5': 50.2,
    '186.5': 50.1,
    '187.5': 50,
    '188.5': 49.9,
    '189.5': 49.8,
    '190.5': 49.7,
    '191.5': 49.7,
    '192.5': 49.6,
    '193.5': 49.5,
    '194.5': 49.4,
  },
  '64.5': {
    '0': 72.6,
    '1': 72.4,
    '2': 72.3,
    '3': 72.2,
    '4': 72,
    '5': 71.9,
    '6': 71.7,
    '7': 71.6,
    '8': 71.4,
    '9': 71.3,
    '10': 71.2,
    '11': 71,
    '12': 70.9,
    '13': 70.7,
    '14': 70.6,
    '15': 70.5,
    '16': 70.3,
    '17': 70.2,
    '18': 70,
    '19': 69.9,
    '20': 69.8,
    '21': 69.6,
    '22': 69.5,
    '23': 69.4,
    '24': 69.2,
    '25': 69.1,
    '26': 68.9,
    '27': 68.8,
    '28': 68.7,
    '29': 68.5,
    '30': 68.4,
    '31': 68.3,
    '32': 68.1,
    '33': 68,
    '34': 67.9,
    '35': 67.7,
    '36': 67.6,
    '37': 67.5,
    '38': 67.3,
    '39': 67.2,
    '40': 67.1,
    '41': 66.9,
    '42': 66.8,
    '43': 66.7,
    '44': 66.6,
    '45': 66.4,
    '46': 66.3,
    '47': 66.2,
    '48': 66,
    '49': 65.9,
    '50': 65.8,
    '51': 65.6,
    '52': 65.5,
    '53': 65.4,
    '54': 65.3,
    '55': 65.1,
    '56': 65,
    '57': 64.9,
    '58': 64.8,
    '59': 64.6,
    '60': 64.5,
    '61': 64.4,
    '62': 64.2,
    '63': 64.1,
    '64': 64,
    '65': 63.9,
    '66': 63.7,
    '67': 63.6,
    '68': 63.5,
    '69': 63.4,
    '70': 63.3,
    '71': 63.1,
    '72': 63,
    '73': 62.9,
    '74': 62.8,
    '75': 62.6,
    '76': 62.5,
    '77': 62.4,
    '78': 62.3,
    '79': 62.2,
    '80': 62,
    '81': 61.9,
    '82': 61.8,
    '83': 61.7,
    '84': 61.6,
    '85': 61.4,
    '86': 61.3,
    '87': 61.2,
    '88': 61.1,
    '89': 61,
    '90': 60.8,
    '91': 60.7,
    '92': 60.6,
    '93': 60.5,
    '94': 60.4,
    '95': 60.3,
    '96': 60.1,
    '97': 60,
    '98': 59.9,
    '99': 59.8,
    '100': 59.7,
    '101': 59.6,
    '102': 59.4,
    '103': 59.3,
    '104': 59.2,
    '105': 59.1,
    '106': 59,
    '107': 58.9,
    '108': 58.8,
    '109': 58.6,
    '110': 58.5,
    '111': 58.4,
    '112': 58.3,
    '113': 58.2,
    '114': 58.1,
    '115': 58,
    '116': 57.9,
    '117': 57.7,
    '118': 57.6,
    '119': 57.5,
    '120': 57.4,
    '121': 57.3,
    '122': 57.2,
    '123': 57.1,
    '124': 57,
    '125': 56.9,
    '126': 56.8,
    '127': 56.6,
    '128': 56.5,
    '129': 56.4,
    '130': 56.3,
    '131': 56.2,
    '132': 56.1,
    '133': 56,
    '134': 55.9,
    '135': 55.8,
    '136': 55.7,
    '137': 55.6,
    '138': 55.5,
    '139': 55.4,
    '140': 55.2,
    '141': 55.1,
    '142': 55,
    '143': 54.9,
    '144': 54.8,
    '145': 54.7,
    '146': 54.6,
    '147': 54.5,
    '148': 54.4,
    '149': 54.3,
    '150': 54.2,
    '151': 54.1,
    '152': 54,
    '153': 53.9,
    '154': 53.8,
    '155': 53.7,
    '156': 53.6,
    '157': 53.5,
    '158': 53.4,
    '159': 53.3,
    '160': 53.2,
    '161': 53.1,
    '162': 53,
    '163': 52.9,
    '164': 52.8,
    '165': 52.7,
    '166': 52.6,
    '167': 52.5,
    '168': 52.4,
    '169': 52.3,
    '170': 52.2,
    '171': 52.1,
    '172': 52,
    '173': 51.9,
    '174': 51.8,
    '175': 51.7,
    '176': 51.6,
    '177': 51.5,
    '178': 51.4,
    '179': 51.3,
    '180': 51.2,
    '181': 51.1,
    '182': 51,
    '183': 50.9,
    '184': 50.8,
    '185': 50.7,
    '186': 50.6,
    '187': 50.5,
    '188': 50.4,
    '189': 50.3,
    '190': 50.2,
    '191': 50.1,
    '192': 50,
    '193': 49.9,
    '194': 49.8,
    '195': 49.7,
    '0.5': 72.5,
    '1.5': 72.4,
    '2.5': 72.2,
    '3.5': 72.1,
    '4.5': 71.9,
    '5.5': 71.8,
    '6.5': 71.7,
    '7.5': 71.5,
    '8.5': 71.4,
    '9.5': 71.2,
    '10.5': 71.1,
    '11.5': 71,
    '12.5': 70.8,
    '13.5': 70.7,
    '14.5': 70.5,
    '15.5': 70.4,
    '16.5': 70.3,
    '17.5': 70.1,
    '18.5': 70,
    '19.5': 69.8,
    '20.5': 69.7,
    '21.5': 69.6,
    '22.5': 69.4,
    '23.5': 69.3,
    '24.5': 69.2,
    '25.5': 69,
    '26.5': 68.9,
    '27.5': 68.7,
    '28.5': 68.6,
    '29.5': 68.5,
    '30.5': 68.3,
    '31.5': 68.2,
    '32.5': 68.1,
    '33.5': 67.9,
    '34.5': 67.8,
    '35.5': 67.7,
    '36.5': 67.5,
    '37.5': 67.4,
    '38.5': 67.3,
    '39.5': 67.1,
    '40.5': 67,
    '41.5': 66.9,
    '42.5': 66.7,
    '43.5': 66.6,
    '44.5': 66.5,
    '45.5': 66.4,
    '46.5': 66.2,
    '47.5': 66.1,
    '48.5': 66,
    '49.5': 65.8,
    '50.5': 65.7,
    '51.5': 65.6,
    '52.5': 65.5,
    '53.5': 65.3,
    '54.5': 65.2,
    '55.5': 65.1,
    '56.5': 64.9,
    '57.5': 64.8,
    '58.5': 64.7,
    '59.5': 64.6,
    '60.5': 64.4,
    '61.5': 64.3,
    '62.5': 64.2,
    '63.5': 64.1,
    '64.5': 63.9,
    '65.5': 63.8,
    '66.5': 63.7,
    '67.5': 63.6,
    '68.5': 63.4,
    '69.5': 63.3,
    '70.5': 63.2,
    '71.5': 63.1,
    '72.5': 62.9,
    '73.5': 62.8,
    '74.5': 62.7,
    '75.5': 62.6,
    '76.5': 62.5,
    '77.5': 62.3,
    '78.5': 62.2,
    '79.5': 62.1,
    '80.5': 62,
    '81.5': 61.9,
    '82.5': 61.7,
    '83.5': 61.6,
    '84.5': 61.5,
    '85.5': 61.4,
    '86.5': 61.3,
    '87.5': 61.1,
    '88.5': 61,
    '89.5': 60.9,
    '90.5': 60.8,
    '91.5': 60.7,
    '92.5': 60.5,
    '93.5': 60.4,
    '94.5': 60.3,
    '95.5': 60.2,
    '96.5': 60.1,
    '97.5': 60,
    '98.5': 59.8,
    '99.5': 59.7,
    '100.5': 59.6,
    '101.5': 59.5,
    '102.5': 59.4,
    '103.5': 59.3,
    '104.5': 59.2,
    '105.5': 59,
    '106.5': 58.9,
    '107.5': 58.8,
    '108.5': 58.7,
    '109.5': 58.6,
    '110.5': 58.5,
    '111.5': 58.4,
    '112.5': 58.2,
    '113.5': 58.1,
    '114.5': 58,
    '115.5': 57.9,
    '116.5': 57.8,
    '117.5': 57.7,
    '118.5': 57.6,
    '119.5': 57.5,
    '120.5': 57.4,
    '121.5': 57.2,
    '122.5': 57.1,
    '123.5': 57,
    '124.5': 56.9,
    '125.5': 56.8,
    '126.5': 56.7,
    '127.5': 56.6,
    '128.5': 56.5,
    '129.5': 56.4,
    '130.5': 56.3,
    '131.5': 56.2,
    '132.5': 56,
    '133.5': 55.9,
    '134.5': 55.8,
    '135.5': 55.7,
    '136.5': 55.6,
    '137.5': 55.5,
    '138.5': 55.4,
    '139.5': 55.3,
    '140.5': 55.2,
    '141.5': 55.1,
    '142.5': 55,
    '143.5': 54.9,
    '144.5': 54.8,
    '145.5': 54.7,
    '146.5': 54.6,
    '147.5': 54.5,
    '148.5': 54.3,
    '149.5': 54.2,
    '150.5': 54.1,
    '151.5': 54,
    '152.5': 53.9,
    '153.5': 53.8,
    '154.5': 53.7,
    '155.5': 53.6,
    '156.5': 53.5,
    '157.5': 53.4,
    '158.5': 53.3,
    '159.5': 53.2,
    '160.5': 53.1,
    '161.5': 53,
    '162.5': 52.9,
    '163.5': 52.8,
    '164.5': 52.7,
    '165.5': 52.6,
    '166.5': 52.5,
    '167.5': 52.4,
    '168.5': 52.3,
    '169.5': 52.2,
    '170.5': 52.1,
    '171.5': 52,
    '172.5': 51.9,
    '173.5': 51.8,
    '174.5': 51.7,
    '175.5': 51.6,
    '176.5': 51.5,
    '177.5': 51.4,
    '178.5': 51.3,
    '179.5': 51.2,
    '180.5': 51.1,
    '181.5': 51,
    '182.5': 50.9,
    '183.5': 50.8,
    '184.5': 50.7,
    '185.5': 50.6,
    '186.5': 50.5,
    '187.5': 50.4,
    '188.5': 50.3,
    '189.5': 50.2,
    '190.5': 50.1,
    '191.5': 50.1,
    '192.5': 50,
    '193.5': 49.9,
    '194.5': 49.8,
  },
  '65': {
    '0': 73.2,
    '1': 73,
    '2': 72.9,
    '3': 72.7,
    '4': 72.6,
    '5': 72.4,
    '6': 72.3,
    '7': 72.2,
    '8': 72,
    '9': 71.9,
    '10': 71.7,
    '11': 71.6,
    '12': 71.4,
    '13': 71.3,
    '14': 71.2,
    '15': 71,
    '16': 70.9,
    '17': 70.7,
    '18': 70.6,
    '19': 70.5,
    '20': 70.3,
    '21': 70.2,
    '22': 70,
    '23': 69.9,
    '24': 69.8,
    '25': 69.6,
    '26': 69.5,
    '27': 69.3,
    '28': 69.2,
    '29': 69.1,
    '30': 68.9,
    '31': 68.8,
    '32': 68.7,
    '33': 68.5,
    '34': 68.4,
    '35': 68.3,
    '36': 68.1,
    '37': 68,
    '38': 67.9,
    '39': 67.7,
    '40': 67.6,
    '41': 67.5,
    '42': 67.3,
    '43': 67.2,
    '44': 67.1,
    '45': 66.9,
    '46': 66.8,
    '47': 66.7,
    '48': 66.5,
    '49': 66.4,
    '50': 66.3,
    '51': 66.2,
    '52': 66,
    '53': 65.9,
    '54': 65.8,
    '55': 65.6,
    '56': 65.5,
    '57': 65.4,
    '58': 65.3,
    '59': 65.1,
    '60': 65,
    '61': 64.9,
    '62': 64.7,
    '63': 64.6,
    '64': 64.5,
    '65': 64.4,
    '66': 64.2,
    '67': 64.1,
    '68': 64,
    '69': 63.9,
    '70': 63.7,
    '71': 63.6,
    '72': 63.5,
    '73': 63.4,
    '74': 63.2,
    '75': 63.1,
    '76': 63,
    '77': 62.9,
    '78': 62.8,
    '79': 62.6,
    '80': 62.5,
    '81': 62.4,
    '82': 62.3,
    '83': 62.2,
    '84': 62,
    '85': 61.9,
    '86': 61.8,
    '87': 61.7,
    '88': 61.6,
    '89': 61.4,
    '90': 61.3,
    '91': 61.2,
    '92': 61.1,
    '93': 61,
    '94': 60.8,
    '95': 60.7,
    '96': 60.6,
    '97': 60.5,
    '98': 60.4,
    '99': 60.3,
    '100': 60.1,
    '101': 60,
    '102': 59.9,
    '103': 59.8,
    '104': 59.7,
    '105': 59.6,
    '106': 59.4,
    '107': 59.3,
    '108': 59.2,
    '109': 59.1,
    '110': 59,
    '111': 58.9,
    '112': 58.8,
    '113': 58.6,
    '114': 58.5,
    '115': 58.4,
    '116': 58.3,
    '117': 58.2,
    '118': 58.1,
    '119': 58,
    '120': 57.9,
    '121': 57.7,
    '122': 57.6,
    '123': 57.5,
    '124': 57.4,
    '125': 57.3,
    '126': 57.2,
    '127': 57.1,
    '128': 57,
    '129': 56.9,
    '130': 56.8,
    '131': 56.6,
    '132': 56.5,
    '133': 56.4,
    '134': 56.3,
    '135': 56.2,
    '136': 56.1,
    '137': 56,
    '138': 55.9,
    '139': 55.8,
    '140': 55.7,
    '141': 55.6,
    '142': 55.5,
    '143': 55.4,
    '144': 55.2,
    '145': 55.1,
    '146': 55,
    '147': 54.9,
    '148': 54.8,
    '149': 54.7,
    '150': 54.6,
    '151': 54.5,
    '152': 54.4,
    '153': 54.3,
    '154': 54.2,
    '155': 54.1,
    '156': 54,
    '157': 53.9,
    '158': 53.8,
    '159': 53.7,
    '160': 53.6,
    '161': 53.5,
    '162': 53.4,
    '163': 53.3,
    '164': 53.2,
    '165': 53.1,
    '166': 53,
    '167': 52.9,
    '168': 52.8,
    '169': 52.7,
    '170': 52.6,
    '171': 52.5,
    '172': 52.4,
    '173': 52.3,
    '174': 52.2,
    '175': 52.1,
    '176': 52,
    '177': 51.9,
    '178': 51.8,
    '179': 51.7,
    '180': 51.6,
    '181': 51.5,
    '182': 51.4,
    '183': 51.3,
    '184': 51.2,
    '185': 51.1,
    '186': 51,
    '187': 50.9,
    '188': 50.8,
    '189': 50.7,
    '190': 50.6,
    '191': 50.5,
    '192': 50.4,
    '193': 50.3,
    '194': 50.2,
    '195': 50.1,
    '0.5': 73.1,
    '1.5': 72.9,
    '2.5': 72.8,
    '3.5': 72.7,
    '4.5': 72.5,
    '5.5': 72.4,
    '6.5': 72.2,
    '7.5': 72.1,
    '8.5': 71.9,
    '9.5': 71.8,
    '10.5': 71.7,
    '11.5': 71.5,
    '12.5': 71.4,
    '13.5': 71.2,
    '14.5': 71.1,
    '15.5': 70.9,
    '16.5': 70.8,
    '17.5': 70.7,
    '18.5': 70.5,
    '19.5': 70.4,
    '20.5': 70.2,
    '21.5': 70.1,
    '22.5': 70,
    '23.5': 69.8,
    '24.5': 69.7,
    '25.5': 69.6,
    '26.5': 69.4,
    '27.5': 69.3,
    '28.5': 69.1,
    '29.5': 69,
    '30.5': 68.9,
    '31.5': 68.7,
    '32.5': 68.6,
    '33.5': 68.5,
    '34.5': 68.3,
    '35.5': 68.2,
    '36.5': 68.1,
    '37.5': 67.9,
    '38.5': 67.8,
    '39.5': 67.7,
    '40.5': 67.5,
    '41.5': 67.4,
    '42.5': 67.3,
    '43.5': 67.1,
    '44.5': 67,
    '45.5': 66.9,
    '46.5': 66.7,
    '47.5': 66.6,
    '48.5': 66.5,
    '49.5': 66.3,
    '50.5': 66.2,
    '51.5': 66.1,
    '52.5': 66,
    '53.5': 65.8,
    '54.5': 65.7,
    '55.5': 65.6,
    '56.5': 65.4,
    '57.5': 65.3,
    '58.5': 65.2,
    '59.5': 65.1,
    '60.5': 64.9,
    '61.5': 64.8,
    '62.5': 64.7,
    '63.5': 64.6,
    '64.5': 64.4,
    '65.5': 64.3,
    '66.5': 64.2,
    '67.5': 64.1,
    '68.5': 63.9,
    '69.5': 63.8,
    '70.5': 63.7,
    '71.5': 63.6,
    '72.5': 63.4,
    '73.5': 63.3,
    '74.5': 63.2,
    '75.5': 63.1,
    '76.5': 62.9,
    '77.5': 62.8,
    '78.5': 62.7,
    '79.5': 62.6,
    '80.5': 62.5,
    '81.5': 62.3,
    '82.5': 62.2,
    '83.5': 62.1,
    '84.5': 62,
    '85.5': 61.9,
    '86.5': 61.7,
    '87.5': 61.6,
    '88.5': 61.5,
    '89.5': 61.4,
    '90.5': 61.3,
    '91.5': 61.1,
    '92.5': 61,
    '93.5': 60.9,
    '94.5': 60.8,
    '95.5': 60.7,
    '96.5': 60.5,
    '97.5': 60.4,
    '98.5': 60.3,
    '99.5': 60.2,
    '100.5': 60.1,
    '101.5': 60,
    '102.5': 59.8,
    '103.5': 59.7,
    '104.5': 59.6,
    '105.5': 59.5,
    '106.5': 59.4,
    '107.5': 59.3,
    '108.5': 59.2,
    '109.5': 59,
    '110.5': 58.9,
    '111.5': 58.8,
    '112.5': 58.7,
    '113.5': 58.6,
    '114.5': 58.5,
    '115.5': 58.4,
    '116.5': 58.3,
    '117.5': 58.1,
    '118.5': 58,
    '119.5': 57.9,
    '120.5': 57.8,
    '121.5': 57.7,
    '122.5': 57.6,
    '123.5': 57.5,
    '124.5': 57.4,
    '125.5': 57.2,
    '126.5': 57.1,
    '127.5': 57,
    '128.5': 56.9,
    '129.5': 56.8,
    '130.5': 56.7,
    '131.5': 56.6,
    '132.5': 56.5,
    '133.5': 56.4,
    '134.5': 56.3,
    '135.5': 56.2,
    '136.5': 56.1,
    '137.5': 55.9,
    '138.5': 55.8,
    '139.5': 55.7,
    '140.5': 55.6,
    '141.5': 55.5,
    '142.5': 55.4,
    '143.5': 55.3,
    '144.5': 55.2,
    '145.5': 55.1,
    '146.5': 55,
    '147.5': 54.9,
    '148.5': 54.8,
    '149.5': 54.7,
    '150.5': 54.6,
    '151.5': 54.5,
    '152.5': 54.4,
    '153.5': 54.3,
    '154.5': 54.2,
    '155.5': 54,
    '156.5': 53.9,
    '157.5': 53.8,
    '158.5': 53.7,
    '159.5': 53.6,
    '160.5': 53.5,
    '161.5': 53.4,
    '162.5': 53.3,
    '163.5': 53.2,
    '164.5': 53.1,
    '165.5': 53,
    '166.5': 52.9,
    '167.5': 52.8,
    '168.5': 52.7,
    '169.5': 52.6,
    '170.5': 52.5,
    '171.5': 52.4,
    '172.5': 52.3,
    '173.5': 52.2,
    '174.5': 52.1,
    '175.5': 52,
    '176.5': 51.9,
    '177.5': 51.8,
    '178.5': 51.7,
    '179.5': 51.6,
    '180.5': 51.5,
    '181.5': 51.4,
    '182.5': 51.3,
    '183.5': 51.2,
    '184.5': 51.1,
    '185.5': 51,
    '186.5': 50.9,
    '187.5': 50.8,
    '188.5': 50.7,
    '189.5': 50.6,
    '190.5': 50.5,
    '191.5': 50.4,
    '192.5': 50.4,
    '193.5': 50.3,
    '194.5': 50.2,
  },
  // 65 - 70
  '65.5': {
    '0': 73.7,
    '1': 73.6,
    '2': 73.4,
    '3': 73.3,
    '4': 73.1,
    '5': 73,
    '6': 72.9,
    '7': 72.7,
    '8': 72.6,
    '9': 72.4,
    '10': 72.3,
    '11': 72.1,
    '12': 72,
    '13': 71.8,
    '14': 71.7,
    '15': 71.6,
    '16': 71.4,
    '17': 71.3,
    '18': 71.1,
    '19': 71,
    '20': 70.9,
    '21': 70.7,
    '22': 70.6,
    '23': 70.4,
    '24': 70.3,
    '25': 70.2,
    '26': 70,
    '27': 69.9,
    '28': 69.7,
    '29': 69.6,
    '30': 69.5,
    '31': 69.3,
    '32': 69.2,
    '33': 69.1,
    '34': 68.9,
    '35': 68.8,
    '36': 68.7,
    '37': 68.5,
    '38': 68.4,
    '39': 68.3,
    '40': 68.1,
    '41': 68,
    '42': 67.9,
    '43': 67.7,
    '44': 67.6,
    '45': 67.5,
    '46': 67.3,
    '47': 67.2,
    '48': 67.1,
    '49': 66.9,
    '50': 66.8,
    '51': 66.7,
    '52': 66.5,
    '53': 66.4,
    '54': 66.3,
    '55': 66.1,
    '56': 66,
    '57': 65.9,
    '58': 65.8,
    '59': 65.6,
    '60': 65.5,
    '61': 65.4,
    '62': 65.2,
    '63': 65.1,
    '64': 65,
    '65': 64.9,
    '66': 64.7,
    '67': 64.6,
    '68': 64.5,
    '69': 64.4,
    '70': 64.2,
    '71': 64.1,
    '72': 64,
    '73': 63.9,
    '74': 63.7,
    '75': 63.6,
    '76': 63.5,
    '77': 63.4,
    '78': 63.2,
    '79': 63.1,
    '80': 63,
    '81': 62.9,
    '82': 62.8,
    '83': 62.6,
    '84': 62.5,
    '85': 62.4,
    '86': 62.3,
    '87': 62.1,
    '88': 62,
    '89': 61.9,
    '90': 61.8,
    '91': 61.7,
    '92': 61.5,
    '93': 61.4,
    '94': 61.3,
    '95': 61.2,
    '96': 61.1,
    '97': 61,
    '98': 60.8,
    '99': 60.7,
    '100': 60.6,
    '101': 60.5,
    '102': 60.4,
    '103': 60.3,
    '104': 60.1,
    '105': 60,
    '106': 59.9,
    '107': 59.8,
    '108': 59.7,
    '109': 59.6,
    '110': 59.4,
    '111': 59.3,
    '112': 59.2,
    '113': 59.1,
    '114': 59,
    '115': 58.9,
    '116': 58.8,
    '117': 58.6,
    '118': 58.5,
    '119': 58.4,
    '120': 58.3,
    '121': 58.2,
    '122': 58.1,
    '123': 58,
    '124': 57.9,
    '125': 57.7,
    '126': 57.6,
    '127': 57.5,
    '128': 57.4,
    '129': 57.3,
    '130': 57.2,
    '131': 57.1,
    '132': 57,
    '133': 56.9,
    '134': 56.8,
    '135': 56.7,
    '136': 56.5,
    '137': 56.4,
    '138': 56.3,
    '139': 56.2,
    '140': 56.1,
    '141': 56,
    '142': 55.9,
    '143': 55.8,
    '144': 55.7,
    '145': 55.6,
    '146': 55.5,
    '147': 55.4,
    '148': 55.3,
    '149': 55.1,
    '150': 55,
    '151': 54.9,
    '152': 54.8,
    '153': 54.7,
    '154': 54.6,
    '155': 54.5,
    '156': 54.4,
    '157': 54.3,
    '158': 54.2,
    '159': 54.1,
    '160': 54,
    '161': 53.9,
    '162': 53.8,
    '163': 53.7,
    '164': 53.6,
    '165': 53.5,
    '166': 53.4,
    '167': 53.3,
    '168': 53.2,
    '169': 53.1,
    '170': 53,
    '171': 52.9,
    '172': 52.8,
    '173': 52.7,
    '174': 52.6,
    '175': 52.5,
    '176': 52.4,
    '177': 52.3,
    '178': 52.2,
    '179': 52.1,
    '180': 52,
    '181': 51.9,
    '182': 51.8,
    '183': 51.7,
    '184': 51.6,
    '185': 51.5,
    '186': 51.4,
    '187': 51.3,
    '188': 51.2,
    '189': 51.1,
    '190': 51,
    '191': 50.9,
    '192': 50.8,
    '193': 50.7,
    '194': 50.6,
    '195': 50.5,
    '0.5': 73.7,
    '1.5': 73.5,
    '2.5': 73.4,
    '3.5': 73.2,
    '4.5': 73.1,
    '5.5': 72.9,
    '6.5': 72.8,
    '7.5': 72.6,
    '8.5': 72.5,
    '9.5': 72.3,
    '10.5': 72.2,
    '11.5': 72.1,
    '12.5': 71.9,
    '13.5': 71.8,
    '14.5': 71.6,
    '15.5': 71.5,
    '16.5': 71.4,
    '17.5': 71.2,
    '18.5': 71.1,
    '19.5': 70.9,
    '20.5': 70.8,
    '21.5': 70.6,
    '22.5': 70.5,
    '23.5': 70.4,
    '24.5': 70.2,
    '25.5': 70.1,
    '26.5': 70,
    '27.5': 69.8,
    '28.5': 69.7,
    '29.5': 69.5,
    '30.5': 69.4,
    '31.5': 69.3,
    '32.5': 69.1,
    '33.5': 69,
    '34.5': 68.9,
    '35.5': 68.7,
    '36.5': 68.6,
    '37.5': 68.5,
    '38.5': 68.3,
    '39.5': 68.2,
    '40.5': 68.1,
    '41.5': 67.9,
    '42.5': 67.8,
    '43.5': 67.7,
    '44.5': 67.5,
    '45.5': 67.4,
    '46.5': 67.3,
    '47.5': 67.1,
    '48.5': 67,
    '49.5': 66.9,
    '50.5': 66.7,
    '51.5': 66.6,
    '52.5': 66.5,
    '53.5': 66.3,
    '54.5': 66.2,
    '55.5': 66.1,
    '56.5': 66,
    '57.5': 65.8,
    '58.5': 65.7,
    '59.5': 65.6,
    '60.5': 65.4,
    '61.5': 65.3,
    '62.5': 65.2,
    '63.5': 65.1,
    '64.5': 64.9,
    '65.5': 64.8,
    '66.5': 64.7,
    '67.5': 64.6,
    '68.5': 64.4,
    '69.5': 64.3,
    '70.5': 64.2,
    '71.5': 64,
    '72.5': 63.9,
    '73.5': 63.8,
    '74.5': 63.7,
    '75.5': 63.5,
    '76.5': 63.4,
    '77.5': 63.3,
    '78.5': 63.2,
    '79.5': 63.1,
    '80.5': 62.9,
    '81.5': 62.8,
    '82.5': 62.7,
    '83.5': 62.6,
    '84.5': 62.4,
    '85.5': 62.3,
    '86.5': 62.2,
    '87.5': 62.1,
    '88.5': 62,
    '89.5': 61.8,
    '90.5': 61.7,
    '91.5': 61.6,
    '92.5': 61.5,
    '93.5': 61.4,
    '94.5': 61.2,
    '95.5': 61.1,
    '96.5': 61,
    '97.5': 60.9,
    '98.5': 60.8,
    '99.5': 60.7,
    '100.5': 60.5,
    '101.5': 60.4,
    '102.5': 60.3,
    '103.5': 60.2,
    '104.5': 60.1,
    '105.5': 60,
    '106.5': 59.8,
    '107.5': 59.7,
    '108.5': 59.6,
    '109.5': 59.5,
    '110.5': 59.4,
    '111.5': 59.3,
    '112.5': 59.2,
    '113.5': 59,
    '114.5': 58.9,
    '115.5': 58.8,
    '116.5': 58.7,
    '117.5': 58.6,
    '118.5': 58.5,
    '119.5': 58.4,
    '120.5': 58.3,
    '121.5': 58.1,
    '122.5': 58,
    '123.5': 57.9,
    '124.5': 57.8,
    '125.5': 57.7,
    '126.5': 57.6,
    '127.5': 57.5,
    '128.5': 57.4,
    '129.5': 57.3,
    '130.5': 57.1,
    '131.5': 57,
    '132.5': 56.9,
    '133.5': 56.8,
    '134.5': 56.7,
    '135.5': 56.6,
    '136.5': 56.5,
    '137.5': 56.4,
    '138.5': 56.3,
    '139.5': 56.2,
    '140.5': 56.1,
    '141.5': 55.9,
    '142.5': 55.8,
    '143.5': 55.7,
    '144.5': 55.6,
    '145.5': 55.5,
    '146.5': 55.4,
    '147.5': 55.3,
    '148.5': 55.2,
    '149.5': 55.1,
    '150.5': 55,
    '151.5': 54.9,
    '152.5': 54.8,
    '153.5': 54.7,
    '154.5': 54.6,
    '155.5': 54.5,
    '156.5': 54.4,
    '157.5': 54.3,
    '158.5': 54.2,
    '159.5': 54.1,
    '160.5': 53.9,
    '161.5': 53.8,
    '162.5': 53.7,
    '163.5': 53.6,
    '164.5': 53.5,
    '165.5': 53.4,
    '166.5': 53.3,
    '167.5': 53.2,
    '168.5': 53.1,
    '169.5': 53,
    '170.5': 52.9,
    '171.5': 52.8,
    '172.5': 52.7,
    '173.5': 52.6,
    '174.5': 52.5,
    '175.5': 52.4,
    '176.5': 52.3,
    '177.5': 52.2,
    '178.5': 52.1,
    '179.5': 52,
    '180.5': 51.9,
    '181.5': 51.8,
    '182.5': 51.7,
    '183.5': 51.6,
    '184.5': 51.5,
    '185.5': 51.4,
    '186.5': 51.3,
    '187.5': 51.2,
    '188.5': 51.1,
    '189.5': 51,
    '190.5': 50.9,
    '191.5': 50.8,
    '192.5': 50.7,
    '193.5': 50.7,
    '194.5': 50.6,
  },
  '66': {
    '0': 74.3,
    '1': 74.1,
    '2': 74,
    '3': 73.8,
    '4': 73.7,
    '5': 73.6,
    '6': 73.4,
    '7': 73.3,
    '8': 73.1,
    '9': 73,
    '10': 72.8,
    '11': 72.7,
    '12': 72.5,
    '13': 72.4,
    '14': 72.3,
    '15': 72.1,
    '16': 72,
    '17': 71.8,
    '18': 71.7,
    '19': 71.5,
    '20': 71.4,
    '21': 71.3,
    '22': 71.1,
    '23': 71,
    '24': 70.8,
    '25': 70.7,
    '26': 70.6,
    '27': 70.4,
    '28': 70.3,
    '29': 70.1,
    '30': 70,
    '31': 69.9,
    '32': 69.7,
    '33': 69.6,
    '34': 69.5,
    '35': 69.3,
    '36': 69.2,
    '37': 69,
    '38': 68.9,
    '39': 68.8,
    '40': 68.6,
    '41': 68.5,
    '42': 68.4,
    '43': 68.2,
    '44': 68.1,
    '45': 68,
    '46': 67.8,
    '47': 67.7,
    '48': 67.6,
    '49': 67.4,
    '50': 67.3,
    '51': 67.2,
    '52': 67,
    '53': 66.9,
    '54': 66.8,
    '55': 66.6,
    '56': 66.5,
    '57': 66.4,
    '58': 66.3,
    '59': 66.1,
    '60': 66,
    '61': 65.9,
    '62': 65.7,
    '63': 65.6,
    '64': 65.5,
    '65': 65.4,
    '66': 65.2,
    '67': 65.1,
    '68': 65,
    '69': 64.8,
    '70': 64.7,
    '71': 64.6,
    '72': 64.5,
    '73': 64.3,
    '74': 64.2,
    '75': 64.1,
    '76': 64,
    '77': 63.8,
    '78': 63.7,
    '79': 63.6,
    '80': 63.5,
    '81': 63.4,
    '82': 63.2,
    '83': 63.1,
    '84': 63,
    '85': 62.9,
    '86': 62.7,
    '87': 62.6,
    '88': 62.5,
    '89': 62.4,
    '90': 62.3,
    '91': 62.1,
    '92': 62,
    '93': 61.9,
    '94': 61.8,
    '95': 61.7,
    '96': 61.5,
    '97': 61.4,
    '98': 61.3,
    '99': 61.2,
    '100': 61.1,
    '101': 60.9,
    '102': 60.8,
    '103': 60.7,
    '104': 60.6,
    '105': 60.5,
    '106': 60.4,
    '107': 60.2,
    '108': 60.1,
    '109': 60,
    '110': 59.9,
    '111': 59.8,
    '112': 59.7,
    '113': 59.6,
    '114': 59.4,
    '115': 59.3,
    '116': 59.2,
    '117': 59.1,
    '118': 59,
    '119': 58.9,
    '120': 58.8,
    '121': 58.6,
    '122': 58.5,
    '123': 58.4,
    '124': 58.3,
    '125': 58.2,
    '126': 58.1,
    '127': 58,
    '128': 57.9,
    '129': 57.7,
    '130': 57.6,
    '131': 57.5,
    '132': 57.4,
    '133': 57.3,
    '134': 57.2,
    '135': 57.1,
    '136': 57,
    '137': 56.9,
    '138': 56.8,
    '139': 56.6,
    '140': 56.5,
    '141': 56.4,
    '142': 56.3,
    '143': 56.2,
    '144': 56.1,
    '145': 56,
    '146': 55.9,
    '147': 55.8,
    '148': 55.7,
    '149': 55.6,
    '150': 55.5,
    '151': 55.4,
    '152': 55.3,
    '153': 55.1,
    '154': 55,
    '155': 54.9,
    '156': 54.8,
    '157': 54.7,
    '158': 54.6,
    '159': 54.5,
    '160': 54.4,
    '161': 54.3,
    '162': 54.2,
    '163': 54.1,
    '164': 54,
    '165': 53.9,
    '166': 53.8,
    '167': 53.7,
    '168': 53.6,
    '169': 53.5,
    '170': 53.4,
    '171': 53.3,
    '172': 53.2,
    '173': 53.1,
    '174': 53,
    '175': 52.9,
    '176': 52.8,
    '177': 52.7,
    '178': 52.6,
    '179': 52.5,
    '180': 52.4,
    '181': 52.3,
    '182': 52.2,
    '183': 52.1,
    '184': 52,
    '185': 51.9,
    '186': 51.8,
    '187': 51.7,
    '188': 51.6,
    '189': 51.5,
    '190': 51.4,
    '191': 51.3,
    '192': 51.2,
    '193': 51.1,
    '194': 51,
    '195': 50.9,
    '0.5': 74.2,
    '1.5': 74.1,
    '2.5': 73.9,
    '3.5': 73.8,
    '4.5': 73.6,
    '5.5': 73.5,
    '6.5': 73.3,
    '7.5': 73.2,
    '8.5': 73,
    '9.5': 72.9,
    '10.5': 72.8,
    '11.5': 72.6,
    '12.5': 72.5,
    '13.5': 72.3,
    '14.5': 72.2,
    '15.5': 72,
    '16.5': 71.9,
    '17.5': 71.8,
    '18.5': 71.6,
    '19.5': 71.5,
    '20.5': 71.3,
    '21.5': 71.2,
    '22.5': 71,
    '23.5': 70.9,
    '24.5': 70.8,
    '25.5': 70.6,
    '26.5': 70.5,
    '27.5': 70.4,
    '28.5': 70.2,
    '29.5': 70.1,
    '30.5': 69.9,
    '31.5': 69.8,
    '32.5': 69.7,
    '33.5': 69.5,
    '34.5': 69.4,
    '35.5': 69.3,
    '36.5': 69.1,
    '37.5': 69,
    '38.5': 68.8,
    '39.5': 68.7,
    '40.5': 68.6,
    '41.5': 68.4,
    '42.5': 68.3,
    '43.5': 68.2,
    '44.5': 68,
    '45.5': 67.9,
    '46.5': 67.8,
    '47.5': 67.6,
    '48.5': 67.5,
    '49.5': 67.4,
    '50.5': 67.2,
    '51.5': 67.1,
    '52.5': 67,
    '53.5': 66.8,
    '54.5': 66.7,
    '55.5': 66.6,
    '56.5': 66.5,
    '57.5': 66.3,
    '58.5': 66.2,
    '59.5': 66.1,
    '60.5': 65.9,
    '61.5': 65.8,
    '62.5': 65.7,
    '63.5': 65.5,
    '64.5': 65.4,
    '65.5': 65.3,
    '66.5': 65.2,
    '67.5': 65,
    '68.5': 64.9,
    '69.5': 64.8,
    '70.5': 64.7,
    '71.5': 64.5,
    '72.5': 64.4,
    '73.5': 64.3,
    '74.5': 64.2,
    '75.5': 64,
    '76.5': 63.9,
    '77.5': 63.8,
    '78.5': 63.7,
    '79.5': 63.5,
    '80.5': 63.4,
    '81.5': 63.3,
    '82.5': 63.2,
    '83.5': 63,
    '84.5': 62.9,
    '85.5': 62.8,
    '86.5': 62.7,
    '87.5': 62.6,
    '88.5': 62.4,
    '89.5': 62.3,
    '90.5': 62.2,
    '91.5': 62.1,
    '92.5': 62,
    '93.5': 61.8,
    '94.5': 61.7,
    '95.5': 61.6,
    '96.5': 61.5,
    '97.5': 61.4,
    '98.5': 61.2,
    '99.5': 61.1,
    '100.5': 61,
    '101.5': 60.9,
    '102.5': 60.8,
    '103.5': 60.7,
    '104.5': 60.5,
    '105.5': 60.4,
    '106.5': 60.3,
    '107.5': 60.2,
    '108.5': 60.1,
    '109.5': 60,
    '110.5': 59.8,
    '111.5': 59.7,
    '112.5': 59.6,
    '113.5': 59.5,
    '114.5': 59.4,
    '115.5': 59.3,
    '116.5': 59.2,
    '117.5': 59,
    '118.5': 58.9,
    '119.5': 58.8,
    '120.5': 58.7,
    '121.5': 58.6,
    '122.5': 58.5,
    '123.5': 58.4,
    '124.5': 58.2,
    '125.5': 58.1,
    '126.5': 58,
    '127.5': 57.9,
    '128.5': 57.8,
    '129.5': 57.7,
    '130.5': 57.6,
    '131.5': 57.5,
    '132.5': 57.4,
    '133.5': 57.2,
    '134.5': 57.1,
    '135.5': 57,
    '136.5': 56.9,
    '137.5': 56.8,
    '138.5': 56.7,
    '139.5': 56.6,
    '140.5': 56.5,
    '141.5': 56.4,
    '142.5': 56.3,
    '143.5': 56.2,
    '144.5': 56.1,
    '145.5': 55.9,
    '146.5': 55.8,
    '147.5': 55.7,
    '148.5': 55.6,
    '149.5': 55.5,
    '150.5': 55.4,
    '151.5': 55.3,
    '152.5': 55.2,
    '153.5': 55.1,
    '154.5': 55,
    '155.5': 54.9,
    '156.5': 54.8,
    '157.5': 54.7,
    '158.5': 54.6,
    '159.5': 54.5,
    '160.5': 54.4,
    '161.5': 54.3,
    '162.5': 54.2,
    '163.5': 54.1,
    '164.5': 53.9,
    '165.5': 53.8,
    '166.5': 53.7,
    '167.5': 53.6,
    '168.5': 53.5,
    '169.5': 53.4,
    '170.5': 53.3,
    '171.5': 53.2,
    '172.5': 53.1,
    '173.5': 53,
    '174.5': 52.9,
    '175.5': 52.8,
    '176.5': 52.7,
    '177.5': 52.6,
    '178.5': 52.5,
    '179.5': 52.4,
    '180.5': 52.3,
    '181.5': 52.2,
    '182.5': 52.1,
    '183.5': 52,
    '184.5': 51.9,
    '185.5': 51.8,
    '186.5': 51.7,
    '187.5': 51.6,
    '188.5': 51.5,
    '189.5': 51.4,
    '190.5': 51.3,
    '191.5': 51.2,
    '192.5': 51.1,
    '193.5': 51,
    '194.5': 50.9,
  },
  '66.5': {
    '0': 74.9,
    '1': 74.7,
    '2': 74.6,
    '3': 74.4,
    '4': 74.3,
    '5': 74.1,
    '6': 74,
    '7': 73.8,
    '8': 73.7,
    '9': 73.5,
    '10': 73.4,
    '11': 73.2,
    '12': 73.1,
    '13': 72.9,
    '14': 72.8,
    '15': 72.7,
    '16': 72.5,
    '17': 72.4,
    '18': 72.2,
    '19': 72.1,
    '20': 71.9,
    '21': 71.8,
    '22': 71.7,
    '23': 71.5,
    '24': 71.4,
    '25': 71.2,
    '26': 71.1,
    '27': 71,
    '28': 70.8,
    '29': 70.7,
    '30': 70.5,
    '31': 70.4,
    '32': 70.3,
    '33': 70.1,
    '34': 70,
    '35': 69.8,
    '36': 69.7,
    '37': 69.6,
    '38': 69.4,
    '39': 69.3,
    '40': 69.2,
    '41': 69,
    '42': 68.9,
    '43': 68.8,
    '44': 68.6,
    '45': 68.5,
    '46': 68.3,
    '47': 68.2,
    '48': 68.1,
    '49': 67.9,
    '50': 67.8,
    '51': 67.7,
    '52': 67.6,
    '53': 67.4,
    '54': 67.3,
    '55': 67.2,
    '56': 67,
    '57': 66.9,
    '58': 66.8,
    '59': 66.6,
    '60': 66.5,
    '61': 66.4,
    '62': 66.2,
    '63': 66.1,
    '64': 66,
    '65': 65.9,
    '66': 65.7,
    '67': 65.6,
    '68': 65.5,
    '69': 65.3,
    '70': 65.2,
    '71': 65.1,
    '72': 65,
    '73': 64.8,
    '74': 64.7,
    '75': 64.6,
    '76': 64.5,
    '77': 64.3,
    '78': 64.2,
    '79': 64.1,
    '80': 64,
    '81': 63.8,
    '82': 63.7,
    '83': 63.6,
    '84': 63.5,
    '85': 63.3,
    '86': 63.2,
    '87': 63.1,
    '88': 63,
    '89': 62.9,
    '90': 62.7,
    '91': 62.6,
    '92': 62.5,
    '93': 62.4,
    '94': 62.2,
    '95': 62.1,
    '96': 62,
    '97': 61.9,
    '98': 61.8,
    '99': 61.6,
    '100': 61.5,
    '101': 61.4,
    '102': 61.3,
    '103': 61.2,
    '104': 61.1,
    '105': 60.9,
    '106': 60.8,
    '107': 60.7,
    '108': 60.6,
    '109': 60.5,
    '110': 60.4,
    '111': 60.2,
    '112': 60.1,
    '113': 60,
    '114': 59.9,
    '115': 59.8,
    '116': 59.7,
    '117': 59.5,
    '118': 59.4,
    '119': 59.3,
    '120': 59.2,
    '121': 59.1,
    '122': 59,
    '123': 58.9,
    '124': 58.7,
    '125': 58.6,
    '126': 58.5,
    '127': 58.4,
    '128': 58.3,
    '129': 58.2,
    '130': 58.1,
    '131': 58,
    '132': 57.9,
    '133': 57.7,
    '134': 57.6,
    '135': 57.5,
    '136': 57.4,
    '137': 57.3,
    '138': 57.2,
    '139': 57.1,
    '140': 57,
    '141': 56.9,
    '142': 56.8,
    '143': 56.6,
    '144': 56.5,
    '145': 56.4,
    '146': 56.3,
    '147': 56.2,
    '148': 56.1,
    '149': 56,
    '150': 55.9,
    '151': 55.8,
    '152': 55.7,
    '153': 55.6,
    '154': 55.5,
    '155': 55.4,
    '156': 55.3,
    '157': 55.1,
    '158': 55,
    '159': 54.9,
    '160': 54.8,
    '161': 54.7,
    '162': 54.6,
    '163': 54.5,
    '164': 54.4,
    '165': 54.3,
    '166': 54.2,
    '167': 54.1,
    '168': 54,
    '169': 53.9,
    '170': 53.8,
    '171': 53.7,
    '172': 53.6,
    '173': 53.5,
    '174': 53.4,
    '175': 53.3,
    '176': 53.2,
    '177': 53.1,
    '178': 53,
    '179': 52.9,
    '180': 52.8,
    '181': 52.7,
    '182': 52.6,
    '183': 52.5,
    '184': 52.4,
    '185': 52.3,
    '186': 52.2,
    '187': 52.1,
    '188': 52,
    '189': 51.9,
    '190': 51.8,
    '191': 51.7,
    '192': 51.6,
    '193': 51.5,
    '194': 51.4,
    '195': 51.3,
    '0.5': 74.8,
    '1.5': 74.6,
    '2.5': 74.5,
    '3.5': 74.3,
    '4.5': 74.2,
    '5.5': 74,
    '6.5': 73.9,
    '7.5': 73.7,
    '8.5': 73.6,
    '9.5': 73.5,
    '10.5': 73.3,
    '11.5': 73.2,
    '12.5': 73,
    '13.5': 72.9,
    '14.5': 72.7,
    '15.5': 72.6,
    '16.5': 72.4,
    '17.5': 72.3,
    '18.5': 72.2,
    '19.5': 72,
    '20.5': 71.9,
    '21.5': 71.7,
    '22.5': 71.6,
    '23.5': 71.4,
    '24.5': 71.3,
    '25.5': 71.2,
    '26.5': 71,
    '27.5': 70.9,
    '28.5': 70.7,
    '29.5': 70.6,
    '30.5': 70.5,
    '31.5': 70.3,
    '32.5': 70.2,
    '33.5': 70,
    '34.5': 69.9,
    '35.5': 69.8,
    '36.5': 69.6,
    '37.5': 69.5,
    '38.5': 69.4,
    '39.5': 69.2,
    '40.5': 69.1,
    '41.5': 69,
    '42.5': 68.8,
    '43.5': 68.7,
    '44.5': 68.6,
    '45.5': 68.4,
    '46.5': 68.3,
    '47.5': 68.2,
    '48.5': 68,
    '49.5': 67.9,
    '50.5': 67.7,
    '51.5': 67.6,
    '52.5': 67.5,
    '53.5': 67.3,
    '54.5': 67.2,
    '55.5': 67.1,
    '56.5': 67,
    '57.5': 66.8,
    '58.5': 66.7,
    '59.5': 66.6,
    '60.5': 66.4,
    '61.5': 66.3,
    '62.5': 66.2,
    '63.5': 66,
    '64.5': 65.9,
    '65.5': 65.8,
    '66.5': 65.7,
    '67.5': 65.5,
    '68.5': 65.4,
    '69.5': 65.3,
    '70.5': 65.2,
    '71.5': 65,
    '72.5': 64.9,
    '73.5': 64.8,
    '74.5': 64.6,
    '75.5': 64.5,
    '76.5': 64.4,
    '77.5': 64.3,
    '78.5': 64.1,
    '79.5': 64,
    '80.5': 63.9,
    '81.5': 63.8,
    '82.5': 63.6,
    '83.5': 63.5,
    '84.5': 63.4,
    '85.5': 63.3,
    '86.5': 63.2,
    '87.5': 63,
    '88.5': 62.9,
    '89.5': 62.8,
    '90.5': 62.7,
    '91.5': 62.5,
    '92.5': 62.4,
    '93.5': 62.3,
    '94.5': 62.2,
    '95.5': 62.1,
    '96.5': 61.9,
    '97.5': 61.8,
    '98.5': 61.7,
    '99.5': 61.6,
    '100.5': 61.5,
    '101.5': 61.4,
    '102.5': 61.2,
    '103.5': 61.1,
    '104.5': 61,
    '105.5': 60.9,
    '106.5': 60.8,
    '107.5': 60.6,
    '108.5': 60.5,
    '109.5': 60.4,
    '110.5': 60.3,
    '111.5': 60.2,
    '112.5': 60.1,
    '113.5': 59.9,
    '114.5': 59.8,
    '115.5': 59.7,
    '116.5': 59.6,
    '117.5': 59.5,
    '118.5': 59.4,
    '119.5': 59.3,
    '120.5': 59.1,
    '121.5': 59,
    '122.5': 58.9,
    '123.5': 58.8,
    '124.5': 58.7,
    '125.5': 58.6,
    '126.5': 58.5,
    '127.5': 58.4,
    '128.5': 58.2,
    '129.5': 58.1,
    '130.5': 58,
    '131.5': 57.9,
    '132.5': 57.8,
    '133.5': 57.7,
    '134.5': 57.6,
    '135.5': 57.5,
    '136.5': 57.4,
    '137.5': 57.2,
    '138.5': 57.1,
    '139.5': 57,
    '140.5': 56.9,
    '141.5': 56.8,
    '142.5': 56.7,
    '143.5': 56.6,
    '144.5': 56.5,
    '145.5': 56.4,
    '146.5': 56.3,
    '147.5': 56.2,
    '148.5': 56.1,
    '149.5': 55.9,
    '150.5': 55.8,
    '151.5': 55.7,
    '152.5': 55.6,
    '153.5': 55.5,
    '154.5': 55.4,
    '155.5': 55.3,
    '156.5': 55.2,
    '157.5': 55.1,
    '158.5': 55,
    '159.5': 54.9,
    '160.5': 54.8,
    '161.5': 54.7,
    '162.5': 54.6,
    '163.5': 54.5,
    '164.5': 54.4,
    '165.5': 54.3,
    '166.5': 54.2,
    '167.5': 54.1,
    '168.5': 53.9,
    '169.5': 53.8,
    '170.5': 53.7,
    '171.5': 53.6,
    '172.5': 53.5,
    '173.5': 53.4,
    '174.5': 53.3,
    '175.5': 53.2,
    '176.5': 53.1,
    '177.5': 53,
    '178.5': 52.9,
    '179.5': 52.8,
    '180.5': 52.7,
    '181.5': 52.6,
    '182.5': 52.5,
    '183.5': 52.4,
    '184.5': 52.3,
    '185.5': 52.2,
    '186.5': 52.1,
    '187.5': 52,
    '188.5': 51.9,
    '189.5': 51.8,
    '190.5': 51.7,
    '191.5': 51.6,
    '192.5': 51.5,
    '193.5': 51.4,
    '194.5': 51.3,
  },
  '67': {
    '0': 75.4,
    '1': 75.3,
    '2': 75.1,
    '3': 75,
    '4': 74.8,
    '5': 74.7,
    '6': 74.5,
    '7': 74.4,
    '8': 74.2,
    '9': 74.1,
    '10': 73.9,
    '11': 73.8,
    '12': 73.6,
    '13': 73.5,
    '14': 73.4,
    '15': 73.2,
    '16': 73.1,
    '17': 72.9,
    '18': 72.8,
    '19': 72.6,
    '20': 72.5,
    '21': 72.3,
    '22': 72.2,
    '23': 72.1,
    '24': 71.9,
    '25': 71.8,
    '26': 71.6,
    '27': 71.5,
    '28': 71.3,
    '29': 71.2,
    '30': 71.1,
    '31': 70.9,
    '32': 70.8,
    '33': 70.6,
    '34': 70.5,
    '35': 70.4,
    '36': 70.2,
    '37': 70.1,
    '38': 70,
    '39': 69.8,
    '40': 69.7,
    '41': 69.5,
    '42': 69.4,
    '43': 69.3,
    '44': 69.1,
    '45': 69,
    '46': 68.9,
    '47': 68.7,
    '48': 68.6,
    '49': 68.5,
    '50': 68.3,
    '51': 68.2,
    '52': 68.1,
    '53': 67.9,
    '54': 67.8,
    '55': 67.7,
    '56': 67.5,
    '57': 67.4,
    '58': 67.3,
    '59': 67.1,
    '60': 67,
    '61': 66.9,
    '62': 66.7,
    '63': 66.6,
    '64': 66.5,
    '65': 66.4,
    '66': 66.2,
    '67': 66.1,
    '68': 66,
    '69': 65.8,
    '70': 65.7,
    '71': 65.6,
    '72': 65.4,
    '73': 65.3,
    '74': 65.2,
    '75': 65.1,
    '76': 64.9,
    '77': 64.8,
    '78': 64.7,
    '79': 64.6,
    '80': 64.4,
    '81': 64.3,
    '82': 64.2,
    '83': 64.1,
    '84': 63.9,
    '85': 63.8,
    '86': 63.7,
    '87': 63.6,
    '88': 63.4,
    '89': 63.3,
    '90': 63.2,
    '91': 63.1,
    '92': 63,
    '93': 62.8,
    '94': 62.7,
    '95': 62.6,
    '96': 62.5,
    '97': 62.3,
    '98': 62.2,
    '99': 62.1,
    '100': 62,
    '101': 61.9,
    '102': 61.8,
    '103': 61.6,
    '104': 61.5,
    '105': 61.4,
    '106': 61.3,
    '107': 61.2,
    '108': 61,
    '109': 60.9,
    '110': 60.8,
    '111': 60.7,
    '112': 60.6,
    '113': 60.5,
    '114': 60.3,
    '115': 60.2,
    '116': 60.1,
    '117': 60,
    '118': 59.9,
    '119': 59.8,
    '120': 59.6,
    '121': 59.5,
    '122': 59.4,
    '123': 59.3,
    '124': 59.2,
    '125': 59.1,
    '126': 59,
    '127': 58.9,
    '128': 58.7,
    '129': 58.6,
    '130': 58.5,
    '131': 58.4,
    '132': 58.3,
    '133': 58.2,
    '134': 58.1,
    '135': 58,
    '136': 57.8,
    '137': 57.7,
    '138': 57.6,
    '139': 57.5,
    '140': 57.4,
    '141': 57.3,
    '142': 57.2,
    '143': 57.1,
    '144': 57,
    '145': 56.9,
    '146': 56.7,
    '147': 56.6,
    '148': 56.5,
    '149': 56.4,
    '150': 56.3,
    '151': 56.2,
    '152': 56.1,
    '153': 56,
    '154': 55.9,
    '155': 55.8,
    '156': 55.7,
    '157': 55.6,
    '158': 55.5,
    '159': 55.4,
    '160': 55.2,
    '161': 55.1,
    '162': 55,
    '163': 54.9,
    '164': 54.8,
    '165': 54.7,
    '166': 54.6,
    '167': 54.5,
    '168': 54.4,
    '169': 54.3,
    '170': 54.2,
    '171': 54.1,
    '172': 54,
    '173': 53.9,
    '174': 53.8,
    '175': 53.7,
    '176': 53.6,
    '177': 53.5,
    '178': 53.4,
    '179': 53.3,
    '180': 53.2,
    '181': 53.1,
    '182': 53,
    '183': 52.9,
    '184': 52.8,
    '185': 52.7,
    '186': 52.6,
    '187': 52.5,
    '188': 52.4,
    '189': 52.3,
    '190': 52.2,
    '191': 52.1,
    '192': 52,
    '193': 51.9,
    '194': 51.8,
    '195': 51.7,
    '0.5': 75.3,
    '1.5': 75.2,
    '2.5': 75,
    '3.5': 74.9,
    '4.5': 74.8,
    '5.5': 74.6,
    '6.5': 74.5,
    '7.5': 74.3,
    '8.5': 74.2,
    '9.5': 74,
    '10.5': 73.9,
    '11.5': 73.7,
    '12.5': 73.6,
    '13.5': 73.4,
    '14.5': 73.3,
    '15.5': 73.1,
    '16.5': 73,
    '17.5': 72.8,
    '18.5': 72.7,
    '19.5': 72.6,
    '20.5': 72.4,
    '21.5': 72.3,
    '22.5': 72.1,
    '23.5': 72,
    '24.5': 71.8,
    '25.5': 71.7,
    '26.5': 71.6,
    '27.5': 71.4,
    '28.5': 71.3,
    '29.5': 71.1,
    '30.5': 71,
    '31.5': 70.9,
    '32.5': 70.7,
    '33.5': 70.6,
    '34.5': 70.4,
    '35.5': 70.3,
    '36.5': 70.2,
    '37.5': 70,
    '38.5': 69.9,
    '39.5': 69.8,
    '40.5': 69.6,
    '41.5': 69.5,
    '42.5': 69.3,
    '43.5': 69.2,
    '44.5': 69.1,
    '45.5': 68.9,
    '46.5': 68.8,
    '47.5': 68.7,
    '48.5': 68.5,
    '49.5': 68.4,
    '50.5': 68.3,
    '51.5': 68.1,
    '52.5': 68,
    '53.5': 67.9,
    '54.5': 67.7,
    '55.5': 67.6,
    '56.5': 67.5,
    '57.5': 67.3,
    '58.5': 67.2,
    '59.5': 67.1,
    '60.5': 66.9,
    '61.5': 66.8,
    '62.5': 66.7,
    '63.5': 66.5,
    '64.5': 66.4,
    '65.5': 66.3,
    '66.5': 66.2,
    '67.5': 66,
    '68.5': 65.9,
    '69.5': 65.8,
    '70.5': 65.6,
    '71.5': 65.5,
    '72.5': 65.4,
    '73.5': 65.3,
    '74.5': 65.1,
    '75.5': 65,
    '76.5': 64.9,
    '77.5': 64.8,
    '78.5': 64.6,
    '79.5': 64.5,
    '80.5': 64.4,
    '81.5': 64.3,
    '82.5': 64.1,
    '83.5': 64,
    '84.5': 63.9,
    '85.5': 63.8,
    '86.5': 63.6,
    '87.5': 63.5,
    '88.5': 63.4,
    '89.5': 63.3,
    '90.5': 63.1,
    '91.5': 63,
    '92.5': 62.9,
    '93.5': 62.8,
    '94.5': 62.7,
    '95.5': 62.5,
    '96.5': 62.4,
    '97.5': 62.3,
    '98.5': 62.2,
    '99.5': 62.1,
    '100.5': 61.9,
    '101.5': 61.8,
    '102.5': 61.7,
    '103.5': 61.6,
    '104.5': 61.5,
    '105.5': 61.3,
    '106.5': 61.2,
    '107.5': 61.1,
    '108.5': 61,
    '109.5': 60.9,
    '110.5': 60.7,
    '111.5': 60.6,
    '112.5': 60.5,
    '113.5': 60.4,
    '114.5': 60.3,
    '115.5': 60.2,
    '116.5': 60.1,
    '117.5': 59.9,
    '118.5': 59.8,
    '119.5': 59.7,
    '120.5': 59.6,
    '121.5': 59.5,
    '122.5': 59.4,
    '123.5': 59.2,
    '124.5': 59.1,
    '125.5': 59,
    '126.5': 58.9,
    '127.5': 58.8,
    '128.5': 58.7,
    '129.5': 58.6,
    '130.5': 58.5,
    '131.5': 58.3,
    '132.5': 58.2,
    '133.5': 58.1,
    '134.5': 58,
    '135.5': 57.9,
    '136.5': 57.8,
    '137.5': 57.7,
    '138.5': 57.6,
    '139.5': 57.5,
    '140.5': 57.3,
    '141.5': 57.2,
    '142.5': 57.1,
    '143.5': 57,
    '144.5': 56.9,
    '145.5': 56.8,
    '146.5': 56.7,
    '147.5': 56.6,
    '148.5': 56.5,
    '149.5': 56.4,
    '150.5': 56.3,
    '151.5': 56.2,
    '152.5': 56,
    '153.5': 55.9,
    '154.5': 55.8,
    '155.5': 55.7,
    '156.5': 55.6,
    '157.5': 55.5,
    '158.5': 55.4,
    '159.5': 55.3,
    '160.5': 55.2,
    '161.5': 55.1,
    '162.5': 55,
    '163.5': 54.9,
    '164.5': 54.8,
    '165.5': 54.7,
    '166.5': 54.6,
    '167.5': 54.5,
    '168.5': 54.4,
    '169.5': 54.3,
    '170.5': 54.2,
    '171.5': 54.1,
    '172.5': 53.9,
    '173.5': 53.8,
    '174.5': 53.7,
    '175.5': 53.6,
    '176.5': 53.5,
    '177.5': 53.4,
    '178.5': 53.3,
    '179.5': 53.2,
    '180.5': 53.1,
    '181.5': 53,
    '182.5': 52.9,
    '183.5': 52.8,
    '184.5': 52.7,
    '185.5': 52.6,
    '186.5': 52.5,
    '187.5': 52.4,
    '188.5': 52.3,
    '189.5': 52.2,
    '190.5': 52.1,
    '191.5': 52,
    '192.5': 51.9,
    '193.5': 51.8,
    '194.5': 51.7,
  },
  '67.5': {
    '0': 76,
    '1': 75.8,
    '2': 75.7,
    '3': 75.5,
    '4': 75.4,
    '5': 75.2,
    '6': 75.1,
    '7': 74.9,
    '8': 74.8,
    '9': 74.6,
    '10': 74.5,
    '11': 74.3,
    '12': 74.2,
    '13': 74.1,
    '14': 73.9,
    '15': 73.8,
    '16': 73.6,
    '17': 73.5,
    '18': 73.3,
    '19': 73.2,
    '20': 73,
    '21': 72.9,
    '22': 72.7,
    '23': 72.6,
    '24': 72.5,
    '25': 72.3,
    '26': 72.2,
    '27': 72,
    '28': 71.9,
    '29': 71.7,
    '30': 71.6,
    '31': 71.5,
    '32': 71.3,
    '33': 71.2,
    '34': 71,
    '35': 70.9,
    '36': 70.8,
    '37': 70.6,
    '38': 70.5,
    '39': 70.3,
    '40': 70.2,
    '41': 70.1,
    '42': 69.9,
    '43': 69.8,
    '44': 69.7,
    '45': 69.5,
    '46': 69.4,
    '47': 69.2,
    '48': 69.1,
    '49': 69,
    '50': 68.8,
    '51': 68.7,
    '52': 68.6,
    '53': 68.4,
    '54': 68.3,
    '55': 68.2,
    '56': 68,
    '57': 67.9,
    '58': 67.8,
    '59': 67.6,
    '60': 67.5,
    '61': 67.4,
    '62': 67.2,
    '63': 67.1,
    '64': 67,
    '65': 66.8,
    '66': 66.7,
    '67': 66.6,
    '68': 66.5,
    '69': 66.3,
    '70': 66.2,
    '71': 66.1,
    '72': 65.9,
    '73': 65.8,
    '74': 65.7,
    '75': 65.6,
    '76': 65.4,
    '77': 65.3,
    '78': 65.2,
    '79': 65,
    '80': 64.9,
    '81': 64.8,
    '82': 64.7,
    '83': 64.5,
    '84': 64.4,
    '85': 64.3,
    '86': 64.2,
    '87': 64,
    '88': 63.9,
    '89': 63.8,
    '90': 63.7,
    '91': 63.5,
    '92': 63.4,
    '93': 63.3,
    '94': 63.2,
    '95': 63.1,
    '96': 62.9,
    '97': 62.8,
    '98': 62.7,
    '99': 62.6,
    '100': 62.5,
    '101': 62.3,
    '102': 62.2,
    '103': 62.1,
    '104': 62,
    '105': 61.9,
    '106': 61.7,
    '107': 61.6,
    '108': 61.5,
    '109': 61.4,
    '110': 61.3,
    '111': 61.1,
    '112': 61,
    '113': 60.9,
    '114': 60.8,
    '115': 60.7,
    '116': 60.6,
    '117': 60.4,
    '118': 60.3,
    '119': 60.2,
    '120': 60.1,
    '121': 60,
    '122': 59.9,
    '123': 59.7,
    '124': 59.6,
    '125': 59.5,
    '126': 59.4,
    '127': 59.3,
    '128': 59.2,
    '129': 59.1,
    '130': 59,
    '131': 58.8,
    '132': 58.7,
    '133': 58.6,
    '134': 58.5,
    '135': 58.4,
    '136': 58.3,
    '137': 58.2,
    '138': 58.1,
    '139': 57.9,
    '140': 57.8,
    '141': 57.7,
    '142': 57.6,
    '143': 57.5,
    '144': 57.4,
    '145': 57.3,
    '146': 57.2,
    '147': 57.1,
    '148': 57,
    '149': 56.8,
    '150': 56.7,
    '151': 56.6,
    '152': 56.5,
    '153': 56.4,
    '154': 56.3,
    '155': 56.2,
    '156': 56.1,
    '157': 56,
    '158': 55.9,
    '159': 55.8,
    '160': 55.7,
    '161': 55.6,
    '162': 55.5,
    '163': 55.3,
    '164': 55.2,
    '165': 55.1,
    '166': 55,
    '167': 54.9,
    '168': 54.8,
    '169': 54.7,
    '170': 54.6,
    '171': 54.5,
    '172': 54.4,
    '173': 54.3,
    '174': 54.2,
    '175': 54.1,
    '176': 54,
    '177': 53.9,
    '178': 53.8,
    '179': 53.7,
    '180': 53.6,
    '181': 53.5,
    '182': 53.4,
    '183': 53.3,
    '184': 53.2,
    '185': 53.1,
    '186': 53,
    '187': 52.9,
    '188': 52.8,
    '189': 52.7,
    '190': 52.6,
    '191': 52.5,
    '192': 52.4,
    '193': 52.3,
    '194': 52.2,
    '195': 52.1,
    '0.5': 75.9,
    '1.5': 75.8,
    '2.5': 75.6,
    '3.5': 75.5,
    '4.5': 75.3,
    '5.5': 75.2,
    '6.5': 75,
    '7.5': 74.9,
    '8.5': 74.7,
    '9.5': 74.6,
    '10.5': 74.4,
    '11.5': 74.3,
    '12.5': 74.1,
    '13.5': 74,
    '14.5': 73.8,
    '15.5': 73.7,
    '16.5': 73.5,
    '17.5': 73.4,
    '18.5': 73.2,
    '19.5': 73.1,
    '20.5': 73,
    '21.5': 72.8,
    '22.5': 72.7,
    '23.5': 72.5,
    '24.5': 72.4,
    '25.5': 72.2,
    '26.5': 72.1,
    '27.5': 72,
    '28.5': 71.8,
    '29.5': 71.7,
    '30.5': 71.5,
    '31.5': 71.4,
    '32.5': 71.2,
    '33.5': 71.1,
    '34.5': 71,
    '35.5': 70.8,
    '36.5': 70.7,
    '37.5': 70.5,
    '38.5': 70.4,
    '39.5': 70.3,
    '40.5': 70.1,
    '41.5': 70,
    '42.5': 69.9,
    '43.5': 69.7,
    '44.5': 69.6,
    '45.5': 69.4,
    '46.5': 69.3,
    '47.5': 69.2,
    '48.5': 69,
    '49.5': 68.9,
    '50.5': 68.8,
    '51.5': 68.6,
    '52.5': 68.5,
    '53.5': 68.4,
    '54.5': 68.2,
    '55.5': 68.1,
    '56.5': 68,
    '57.5': 67.8,
    '58.5': 67.7,
    '59.5': 67.6,
    '60.5': 67.4,
    '61.5': 67.3,
    '62.5': 67.2,
    '63.5': 67,
    '64.5': 66.9,
    '65.5': 66.8,
    '66.5': 66.6,
    '67.5': 66.5,
    '68.5': 66.4,
    '69.5': 66.3,
    '70.5': 66.1,
    '71.5': 66,
    '72.5': 65.9,
    '73.5': 65.7,
    '74.5': 65.6,
    '75.5': 65.5,
    '76.5': 65.4,
    '77.5': 65.2,
    '78.5': 65.1,
    '79.5': 65,
    '80.5': 64.9,
    '81.5': 64.7,
    '82.5': 64.6,
    '83.5': 64.5,
    '84.5': 64.4,
    '85.5': 64.2,
    '86.5': 64.1,
    '87.5': 64,
    '88.5': 63.9,
    '89.5': 63.7,
    '90.5': 63.6,
    '91.5': 63.5,
    '92.5': 63.4,
    '93.5': 63.2,
    '94.5': 63.1,
    '95.5': 63,
    '96.5': 62.9,
    '97.5': 62.8,
    '98.5': 62.6,
    '99.5': 62.5,
    '100.5': 62.4,
    '101.5': 62.3,
    '102.5': 62.2,
    '103.5': 62,
    '104.5': 61.9,
    '105.5': 61.8,
    '106.5': 61.7,
    '107.5': 61.6,
    '108.5': 61.4,
    '109.5': 61.3,
    '110.5': 61.2,
    '111.5': 61.1,
    '112.5': 61,
    '113.5': 60.9,
    '114.5': 60.7,
    '115.5': 60.6,
    '116.5': 60.5,
    '117.5': 60.4,
    '118.5': 60.3,
    '119.5': 60.2,
    '120.5': 60,
    '121.5': 59.9,
    '122.5': 59.8,
    '123.5': 59.7,
    '124.5': 59.6,
    '125.5': 59.5,
    '126.5': 59.3,
    '127.5': 59.2,
    '128.5': 59.1,
    '129.5': 59,
    '130.5': 58.9,
    '131.5': 58.8,
    '132.5': 58.7,
    '133.5': 58.6,
    '134.5': 58.4,
    '135.5': 58.3,
    '136.5': 58.2,
    '137.5': 58.1,
    '138.5': 58,
    '139.5': 57.9,
    '140.5': 57.8,
    '141.5': 57.7,
    '142.5': 57.6,
    '143.5': 57.4,
    '144.5': 57.3,
    '145.5': 57.2,
    '146.5': 57.1,
    '147.5': 57,
    '148.5': 56.9,
    '149.5': 56.8,
    '150.5': 56.7,
    '151.5': 56.6,
    '152.5': 56.5,
    '153.5': 56.4,
    '154.5': 56.3,
    '155.5': 56.1,
    '156.5': 56,
    '157.5': 55.9,
    '158.5': 55.8,
    '159.5': 55.7,
    '160.5': 55.6,
    '161.5': 55.5,
    '162.5': 55.4,
    '163.5': 55.3,
    '164.5': 55.2,
    '165.5': 55.1,
    '166.5': 55,
    '167.5': 54.9,
    '168.5': 54.8,
    '169.5': 54.7,
    '170.5': 54.6,
    '171.5': 54.5,
    '172.5': 54.4,
    '173.5': 54.3,
    '174.5': 54.1,
    '175.5': 54,
    '176.5': 53.9,
    '177.5': 53.8,
    '178.5': 53.7,
    '179.5': 53.6,
    '180.5': 53.5,
    '181.5': 53.4,
    '182.5': 53.3,
    '183.5': 53.2,
    '184.5': 53.1,
    '185.5': 53,
    '186.5': 52.9,
    '187.5': 52.8,
    '188.5': 52.7,
    '189.5': 52.6,
    '190.5': 52.5,
    '191.5': 52.4,
    '192.5': 52.3,
    '193.5': 52.2,
    '194.5': 52.1,
  },
  '68': {
    '0': 76.6,
    '1': 76.4,
    '2': 76.3,
    '3': 76.1,
    '4': 76,
    '5': 75.8,
    '6': 75.7,
    '7': 75.5,
    '8': 75.3,
    '9': 75.2,
    '10': 75.1,
    '11': 74.9,
    '12': 74.8,
    '13': 74.6,
    '14': 74.5,
    '15': 74.3,
    '16': 74.2,
    '17': 74,
    '18': 73.9,
    '19': 73.7,
    '20': 73.6,
    '21': 73.4,
    '22': 73.3,
    '23': 73.1,
    '24': 73,
    '25': 72.8,
    '26': 72.7,
    '27': 72.6,
    '28': 72.4,
    '29': 72.3,
    '30': 72.1,
    '31': 72,
    '32': 71.8,
    '33': 71.7,
    '34': 71.6,
    '35': 71.4,
    '36': 71.3,
    '37': 71.1,
    '38': 71,
    '39': 70.9,
    '40': 70.7,
    '41': 70.6,
    '42': 70.4,
    '43': 70.3,
    '44': 70.2,
    '45': 70,
    '46': 69.9,
    '47': 69.8,
    '48': 69.6,
    '49': 69.5,
    '50': 69.3,
    '51': 69.2,
    '52': 69.1,
    '53': 68.9,
    '54': 68.8,
    '55': 68.7,
    '56': 68.5,
    '57': 68.4,
    '58': 68.3,
    '59': 68.1,
    '60': 68,
    '61': 67.9,
    '62': 67.7,
    '63': 67.6,
    '64': 67.5,
    '65': 67.3,
    '66': 67.2,
    '67': 67.1,
    '68': 66.9,
    '69': 66.8,
    '70': 66.7,
    '71': 66.6,
    '72': 66.4,
    '73': 66.3,
    '74': 66.2,
    '75': 66,
    '76': 65.9,
    '77': 65.8,
    '78': 65.7,
    '79': 65.5,
    '80': 65.4,
    '81': 65.3,
    '82': 65.1,
    '83': 65,
    '84': 64.9,
    '85': 64.8,
    '86': 64.6,
    '87': 64.5,
    '88': 64.4,
    '89': 64.3,
    '90': 64.1,
    '91': 64,
    '92': 63.9,
    '93': 63.8,
    '94': 63.6,
    '95': 63.5,
    '96': 63.4,
    '97': 63.3,
    '98': 63.2,
    '99': 63,
    '100': 62.9,
    '101': 62.8,
    '102': 62.7,
    '103': 62.6,
    '104': 62.4,
    '105': 62.3,
    '106': 62.2,
    '107': 62.1,
    '108': 62,
    '109': 61.8,
    '110': 61.7,
    '111': 61.6,
    '112': 61.5,
    '113': 61.4,
    '114': 61.2,
    '115': 61.1,
    '116': 61,
    '117': 60.9,
    '118': 60.8,
    '119': 60.7,
    '120': 60.5,
    '121': 60.4,
    '122': 60.3,
    '123': 60.2,
    '124': 60.1,
    '125': 60,
    '126': 59.8,
    '127': 59.7,
    '128': 59.6,
    '129': 59.5,
    '130': 59.4,
    '131': 59.3,
    '132': 59.2,
    '133': 59,
    '134': 58.9,
    '135': 58.8,
    '136': 58.7,
    '137': 58.6,
    '138': 58.5,
    '139': 58.4,
    '140': 58.3,
    '141': 58.2,
    '142': 58,
    '143': 57.9,
    '144': 57.8,
    '145': 57.7,
    '146': 57.6,
    '147': 57.5,
    '148': 57.4,
    '149': 57.3,
    '150': 57.2,
    '151': 57.1,
    '152': 56.9,
    '153': 56.8,
    '154': 56.7,
    '155': 56.6,
    '156': 56.5,
    '157': 56.4,
    '158': 56.3,
    '159': 56.2,
    '160': 56.1,
    '161': 56,
    '162': 55.9,
    '163': 55.8,
    '164': 55.7,
    '165': 55.6,
    '166': 55.4,
    '167': 55.3,
    '168': 55.2,
    '169': 55.1,
    '170': 55,
    '171': 54.9,
    '172': 54.8,
    '173': 54.7,
    '174': 54.6,
    '175': 54.5,
    '176': 54.4,
    '177': 54.3,
    '178': 54.2,
    '179': 54.1,
    '180': 54,
    '181': 53.9,
    '182': 53.8,
    '183': 53.7,
    '184': 53.6,
    '185': 53.5,
    '186': 53.4,
    '187': 53.3,
    '188': 53.2,
    '189': 53.1,
    '190': 53,
    '191': 52.9,
    '192': 52.8,
    '193': 52.7,
    '194': 52.6,
    '195': 52.5,
    '0.5': 76.5,
    '1.5': 76.3,
    '2.5': 76.2,
    '3.5': 76,
    '4.5': 75.9,
    '5.5': 75.7,
    '6.5': 75.6,
    '7.5': 75.4,
    '8.5': 75.3,
    '9.5': 75.1,
    '10.5': 75,
    '11.5': 74.8,
    '12.5': 74.7,
    '13.5': 74.5,
    '14.5': 74.4,
    '15.5': 74.2,
    '16.5': 74.1,
    '17.5': 73.9,
    '18.5': 73.8,
    '19.5': 73.6,
    '20.5': 73.5,
    '21.5': 73.4,
    '22.5': 73.2,
    '23.5': 73.1,
    '24.5': 72.9,
    '25.5': 72.8,
    '26.5': 72.6,
    '27.5': 72.5,
    '28.5': 72.3,
    '29.5': 72.2,
    '30.5': 72.1,
    '31.5': 71.9,
    '32.5': 71.8,
    '33.5': 71.6,
    '34.5': 71.5,
    '35.5': 71.4,
    '36.5': 71.2,
    '37.5': 71.1,
    '38.5': 70.9,
    '39.5': 70.8,
    '40.5': 70.7,
    '41.5': 70.5,
    '42.5': 70.4,
    '43.5': 70.2,
    '44.5': 70.1,
    '45.5': 70,
    '46.5': 69.8,
    '47.5': 69.7,
    '48.5': 69.6,
    '49.5': 69.4,
    '50.5': 69.3,
    '51.5': 69.1,
    '52.5': 69,
    '53.5': 68.9,
    '54.5': 68.7,
    '55.5': 68.6,
    '56.5': 68.5,
    '57.5': 68.3,
    '58.5': 68.2,
    '59.5': 68.1,
    '60.5': 67.9,
    '61.5': 67.8,
    '62.5': 67.7,
    '63.5': 67.5,
    '64.5': 67.4,
    '65.5': 67.3,
    '66.5': 67.1,
    '67.5': 67,
    '68.5': 66.9,
    '69.5': 66.8,
    '70.5': 66.6,
    '71.5': 66.5,
    '72.5': 66.4,
    '73.5': 66.2,
    '74.5': 66.1,
    '75.5': 66,
    '76.5': 65.8,
    '77.5': 65.7,
    '78.5': 65.6,
    '79.5': 65.5,
    '80.5': 65.3,
    '81.5': 65.2,
    '82.5': 65.1,
    '83.5': 65,
    '84.5': 64.8,
    '85.5': 64.7,
    '86.5': 64.6,
    '87.5': 64.5,
    '88.5': 64.3,
    '89.5': 64.2,
    '90.5': 64.1,
    '91.5': 64,
    '92.5': 63.8,
    '93.5': 63.7,
    '94.5': 63.6,
    '95.5': 63.5,
    '96.5': 63.3,
    '97.5': 63.2,
    '98.5': 63.1,
    '99.5': 63,
    '100.5': 62.9,
    '101.5': 62.7,
    '102.5': 62.6,
    '103.5': 62.5,
    '104.5': 62.4,
    '105.5': 62.3,
    '106.5': 62.1,
    '107.5': 62,
    '108.5': 61.9,
    '109.5': 61.8,
    '110.5': 61.7,
    '111.5': 61.5,
    '112.5': 61.4,
    '113.5': 61.3,
    '114.5': 61.2,
    '115.5': 61.1,
    '116.5': 60.9,
    '117.5': 60.8,
    '118.5': 60.7,
    '119.5': 60.6,
    '120.5': 60.5,
    '121.5': 60.4,
    '122.5': 60.2,
    '123.5': 60.1,
    '124.5': 60,
    '125.5': 59.9,
    '126.5': 59.8,
    '127.5': 59.7,
    '128.5': 59.6,
    '129.5': 59.4,
    '130.5': 59.3,
    '131.5': 59.2,
    '132.5': 59.1,
    '133.5': 59,
    '134.5': 58.9,
    '135.5': 58.8,
    '136.5': 58.7,
    '137.5': 58.5,
    '138.5': 58.4,
    '139.5': 58.3,
    '140.5': 58.2,
    '141.5': 58.1,
    '142.5': 58,
    '143.5': 57.9,
    '144.5': 57.8,
    '145.5': 57.7,
    '146.5': 57.5,
    '147.5': 57.4,
    '148.5': 57.3,
    '149.5': 57.2,
    '150.5': 57.1,
    '151.5': 57,
    '152.5': 56.9,
    '153.5': 56.8,
    '154.5': 56.7,
    '155.5': 56.6,
    '156.5': 56.5,
    '157.5': 56.3,
    '158.5': 56.2,
    '159.5': 56.1,
    '160.5': 56,
    '161.5': 55.9,
    '162.5': 55.8,
    '163.5': 55.7,
    '164.5': 55.6,
    '165.5': 55.5,
    '166.5': 55.4,
    '167.5': 55.3,
    '168.5': 55.2,
    '169.5': 55.1,
    '170.5': 55,
    '171.5': 54.9,
    '172.5': 54.8,
    '173.5': 54.7,
    '174.5': 54.6,
    '175.5': 54.4,
    '176.5': 54.3,
    '177.5': 54.2,
    '178.5': 54.1,
    '179.5': 54,
    '180.5': 53.9,
    '181.5': 53.8,
    '182.5': 53.7,
    '183.5': 53.6,
    '184.5': 53.5,
    '185.5': 53.4,
    '186.5': 53.3,
    '187.5': 53.2,
    '188.5': 53.1,
    '189.5': 53,
    '190.5': 52.9,
    '191.5': 52.8,
    '192.5': 52.7,
    '193.5': 52.6,
    '194.5': 52.5,
  },
  '68.5': {
    '0': 77.1,
    '1': 77,
    '2': 76.8,
    '3': 76.7,
    '4': 76.5,
    '5': 76.4,
    '6': 76.2,
    '7': 76.1,
    '8': 75.9,
    '9': 75.8,
    '10': 75.6,
    '11': 75.5,
    '12': 75.3,
    '13': 75.2,
    '14': 75,
    '15': 74.9,
    '16': 74.7,
    '17': 74.6,
    '18': 74.4,
    '19': 74.3,
    '20': 74.1,
    '21': 74,
    '22': 73.8,
    '23': 73.7,
    '24': 73.5,
    '25': 73.4,
    '26': 73.2,
    '27': 73.1,
    '28': 73,
    '29': 72.8,
    '30': 72.7,
    '31': 72.5,
    '32': 72.4,
    '33': 72.2,
    '34': 72.1,
    '35': 71.9,
    '36': 71.8,
    '37': 71.7,
    '38': 71.5,
    '39': 71.4,
    '40': 71.2,
    '41': 71.1,
    '42': 71,
    '43': 70.8,
    '44': 70.7,
    '45': 70.5,
    '46': 70.4,
    '47': 70.3,
    '48': 70.1,
    '49': 70,
    '50': 69.9,
    '51': 69.7,
    '52': 69.6,
    '53': 69.4,
    '54': 69.3,
    '55': 69.2,
    '56': 69,
    '57': 68.9,
    '58': 68.8,
    '59': 68.6,
    '60': 68.5,
    '61': 68.4,
    '62': 68.2,
    '63': 68.1,
    '64': 68,
    '65': 67.8,
    '66': 67.7,
    '67': 67.6,
    '68': 67.4,
    '69': 67.3,
    '70': 67.2,
    '71': 67,
    '72': 66.9,
    '73': 66.8,
    '74': 66.7,
    '75': 66.5,
    '76': 66.4,
    '77': 66.3,
    '78': 66.1,
    '79': 66,
    '80': 65.9,
    '81': 65.8,
    '82': 65.6,
    '83': 65.5,
    '84': 65.4,
    '85': 65.2,
    '86': 65.1,
    '87': 65,
    '88': 64.9,
    '89': 64.7,
    '90': 64.6,
    '91': 64.5,
    '92': 64.4,
    '93': 64.2,
    '94': 64.1,
    '95': 64,
    '96': 63.9,
    '97': 63.7,
    '98': 63.6,
    '99': 63.5,
    '100': 63.4,
    '101': 63.3,
    '102': 63.1,
    '103': 63,
    '104': 62.9,
    '105': 62.8,
    '106': 62.6,
    '107': 62.5,
    '108': 62.4,
    '109': 62.3,
    '110': 62.2,
    '111': 62,
    '112': 61.9,
    '113': 61.8,
    '114': 61.7,
    '115': 61.6,
    '116': 61.5,
    '117': 61.3,
    '118': 61.2,
    '119': 61.1,
    '120': 61,
    '121': 60.9,
    '122': 60.8,
    '123': 60.6,
    '124': 60.5,
    '125': 60.4,
    '126': 60.3,
    '127': 60.2,
    '128': 60.1,
    '129': 59.9,
    '130': 59.8,
    '131': 59.7,
    '132': 59.6,
    '133': 59.5,
    '134': 59.4,
    '135': 59.3,
    '136': 59.1,
    '137': 59,
    '138': 58.9,
    '139': 58.8,
    '140': 58.7,
    '141': 58.6,
    '142': 58.5,
    '143': 58.4,
    '144': 58.2,
    '145': 58.1,
    '146': 58,
    '147': 57.9,
    '148': 57.8,
    '149': 57.7,
    '150': 57.6,
    '151': 57.5,
    '152': 57.4,
    '153': 57.3,
    '154': 57.1,
    '155': 57,
    '156': 56.9,
    '157': 56.8,
    '158': 56.7,
    '159': 56.6,
    '160': 56.5,
    '161': 56.4,
    '162': 56.3,
    '163': 56.2,
    '164': 56.1,
    '165': 56,
    '166': 55.9,
    '167': 55.7,
    '168': 55.6,
    '169': 55.5,
    '170': 55.4,
    '171': 55.3,
    '172': 55.2,
    '173': 55.1,
    '174': 55,
    '175': 54.9,
    '176': 54.8,
    '177': 54.7,
    '178': 54.6,
    '179': 54.5,
    '180': 54.4,
    '181': 54.3,
    '182': 54.2,
    '183': 54.1,
    '184': 54,
    '185': 53.9,
    '186': 53.8,
    '187': 53.7,
    '188': 53.6,
    '189': 53.5,
    '190': 53.4,
    '191': 53.3,
    '192': 53.2,
    '193': 53.1,
    '194': 53,
    '195': 52.9,
    '0.5': 77.1,
    '1.5': 76.9,
    '2.5': 76.7,
    '3.5': 76.6,
    '4.5': 76.4,
    '5.5': 76.3,
    '6.5': 76.1,
    '7.5': 76,
    '8.5': 75.8,
    '9.5': 75.7,
    '10.5': 75.5,
    '11.5': 75.4,
    '12.5': 75.2,
    '13.5': 75.1,
    '14.5': 74.9,
    '15.5': 74.8,
    '16.5': 74.6,
    '17.5': 74.5,
    '18.5': 74.3,
    '19.5': 74.2,
    '20.5': 74,
    '21.5': 73.9,
    '22.5': 73.8,
    '23.5': 73.6,
    '24.5': 73.5,
    '25.5': 73.3,
    '26.5': 73.2,
    '27.5': 73,
    '28.5': 72.9,
    '29.5': 72.7,
    '30.5': 72.6,
    '31.5': 72.5,
    '32.5': 72.3,
    '33.5': 72.2,
    '34.5': 72,
    '35.5': 71.9,
    '36.5': 71.7,
    '37.5': 71.6,
    '38.5': 71.5,
    '39.5': 71.3,
    '40.5': 71.2,
    '41.5': 71,
    '42.5': 70.9,
    '43.5': 70.8,
    '44.5': 70.6,
    '45.5': 70.5,
    '46.5': 70.3,
    '47.5': 70.2,
    '48.5': 70.1,
    '49.5': 69.9,
    '50.5': 69.8,
    '51.5': 69.7,
    '52.5': 69.5,
    '53.5': 69.4,
    '54.5': 69.2,
    '55.5': 69.1,
    '56.5': 69,
    '57.5': 68.8,
    '58.5': 68.7,
    '59.5': 68.6,
    '60.5': 68.4,
    '61.5': 68.3,
    '62.5': 68.2,
    '63.5': 68,
    '64.5': 67.9,
    '65.5': 67.8,
    '66.5': 67.6,
    '67.5': 67.5,
    '68.5': 67.4,
    '69.5': 67.2,
    '70.5': 67.1,
    '71.5': 67,
    '72.5': 66.8,
    '73.5': 66.7,
    '74.5': 66.6,
    '75.5': 66.5,
    '76.5': 66.3,
    '77.5': 66.2,
    '78.5': 66.1,
    '79.5': 65.9,
    '80.5': 65.8,
    '81.5': 65.7,
    '82.5': 65.6,
    '83.5': 65.4,
    '84.5': 65.3,
    '85.5': 65.2,
    '86.5': 65.1,
    '87.5': 64.9,
    '88.5': 64.8,
    '89.5': 64.7,
    '90.5': 64.6,
    '91.5': 64.4,
    '92.5': 64.3,
    '93.5': 64.2,
    '94.5': 64.1,
    '95.5': 63.9,
    '96.5': 63.8,
    '97.5': 63.7,
    '98.5': 63.6,
    '99.5': 63.4,
    '100.5': 63.3,
    '101.5': 63.2,
    '102.5': 63.1,
    '103.5': 63,
    '104.5': 62.8,
    '105.5': 62.7,
    '106.5': 62.6,
    '107.5': 62.5,
    '108.5': 62.3,
    '109.5': 62.2,
    '110.5': 62.1,
    '111.5': 62,
    '112.5': 61.9,
    '113.5': 61.7,
    '114.5': 61.6,
    '115.5': 61.5,
    '116.5': 61.4,
    '117.5': 61.3,
    '118.5': 61.2,
    '119.5': 61,
    '120.5': 60.9,
    '121.5': 60.8,
    '122.5': 60.7,
    '123.5': 60.6,
    '124.5': 60.5,
    '125.5': 60.3,
    '126.5': 60.2,
    '127.5': 60.1,
    '128.5': 60,
    '129.5': 59.9,
    '130.5': 59.8,
    '131.5': 59.7,
    '132.5': 59.5,
    '133.5': 59.4,
    '134.5': 59.3,
    '135.5': 59.2,
    '136.5': 59.1,
    '137.5': 59,
    '138.5': 58.9,
    '139.5': 58.7,
    '140.5': 58.6,
    '141.5': 58.5,
    '142.5': 58.4,
    '143.5': 58.3,
    '144.5': 58.2,
    '145.5': 58.1,
    '146.5': 58,
    '147.5': 57.9,
    '148.5': 57.7,
    '149.5': 57.6,
    '150.5': 57.5,
    '151.5': 57.4,
    '152.5': 57.3,
    '153.5': 57.2,
    '154.5': 57.1,
    '155.5': 57,
    '156.5': 56.9,
    '157.5': 56.8,
    '158.5': 56.7,
    '159.5': 56.5,
    '160.5': 56.4,
    '161.5': 56.3,
    '162.5': 56.2,
    '163.5': 56.1,
    '164.5': 56,
    '165.5': 55.9,
    '166.5': 55.8,
    '167.5': 55.7,
    '168.5': 55.6,
    '169.5': 55.5,
    '170.5': 55.4,
    '171.5': 55.3,
    '172.5': 55.2,
    '173.5': 55.1,
    '174.5': 55,
    '175.5': 54.9,
    '176.5': 54.8,
    '177.5': 54.6,
    '178.5': 54.5,
    '179.5': 54.4,
    '180.5': 54.3,
    '181.5': 54.2,
    '182.5': 54.1,
    '183.5': 54,
    '184.5': 53.9,
    '185.5': 53.8,
    '186.5': 53.7,
    '187.5': 53.6,
    '188.5': 53.5,
    '189.5': 53.4,
    '190.5': 53.3,
    '191.5': 53.2,
    '192.5': 53.1,
    '193.5': 53,
    '194.5': 52.9,
  },
  '69': {
    '0': 77.7,
    '1': 77.5,
    '2': 77.4,
    '3': 77.2,
    '4': 77.1,
    '5': 76.9,
    '6': 76.8,
    '7': 76.6,
    '8': 76.5,
    '9': 76.3,
    '10': 76.2,
    '11': 76,
    '12': 75.9,
    '13': 75.7,
    '14': 75.6,
    '15': 75.4,
    '16': 75.3,
    '17': 75.1,
    '18': 75,
    '19': 74.8,
    '20': 74.7,
    '21': 74.5,
    '22': 74.4,
    '23': 74.2,
    '24': 74.1,
    '25': 73.9,
    '26': 73.8,
    '27': 73.6,
    '28': 73.5,
    '29': 73.3,
    '30': 73.2,
    '31': 73,
    '32': 72.9,
    '33': 72.8,
    '34': 72.6,
    '35': 72.5,
    '36': 72.3,
    '37': 72.2,
    '38': 72,
    '39': 71.9,
    '40': 71.8,
    '41': 71.6,
    '42': 71.5,
    '43': 71.3,
    '44': 71.2,
    '45': 71.1,
    '46': 70.9,
    '47': 70.8,
    '48': 70.6,
    '49': 70.5,
    '50': 70.4,
    '51': 70.2,
    '52': 70.1,
    '53': 70,
    '54': 69.8,
    '55': 69.7,
    '56': 69.5,
    '57': 69.4,
    '58': 69.3,
    '59': 69.1,
    '60': 69,
    '61': 68.9,
    '62': 68.7,
    '63': 68.6,
    '64': 68.5,
    '65': 68.3,
    '66': 68.2,
    '67': 68.1,
    '68': 67.9,
    '69': 67.8,
    '70': 67.7,
    '71': 67.5,
    '72': 67.4,
    '73': 67.3,
    '74': 67.1,
    '75': 67,
    '76': 66.9,
    '77': 66.7,
    '78': 66.6,
    '79': 66.5,
    '80': 66.4,
    '81': 66.2,
    '82': 66.1,
    '83': 66,
    '84': 65.8,
    '85': 65.7,
    '86': 65.6,
    '87': 65.5,
    '88': 65.3,
    '89': 65.2,
    '90': 65.1,
    '91': 65,
    '92': 64.8,
    '93': 64.7,
    '94': 64.6,
    '95': 64.5,
    '96': 64.3,
    '97': 64.2,
    '98': 64.1,
    '99': 64,
    '100': 63.8,
    '101': 63.7,
    '102': 63.6,
    '103': 63.5,
    '104': 63.3,
    '105': 63.2,
    '106': 63.1,
    '107': 63,
    '108': 62.9,
    '109': 62.7,
    '110': 62.6,
    '111': 62.5,
    '112': 62.4,
    '113': 62.3,
    '114': 62.1,
    '115': 62,
    '116': 61.9,
    '117': 61.8,
    '118': 61.7,
    '119': 61.5,
    '120': 61.4,
    '121': 61.3,
    '122': 61.2,
    '123': 61.1,
    '124': 61,
    '125': 60.8,
    '126': 60.7,
    '127': 60.6,
    '128': 60.5,
    '129': 60.4,
    '130': 60.3,
    '131': 60.1,
    '132': 60,
    '133': 59.9,
    '134': 59.8,
    '135': 59.7,
    '136': 59.6,
    '137': 59.5,
    '138': 59.3,
    '139': 59.2,
    '140': 59.1,
    '141': 59,
    '142': 58.9,
    '143': 58.8,
    '144': 58.7,
    '145': 58.6,
    '146': 58.4,
    '147': 58.3,
    '148': 58.2,
    '149': 58.1,
    '150': 58,
    '151': 57.9,
    '152': 57.8,
    '153': 57.7,
    '154': 57.6,
    '155': 57.5,
    '156': 57.3,
    '157': 57.2,
    '158': 57.1,
    '159': 57,
    '160': 56.9,
    '161': 56.8,
    '162': 56.7,
    '163': 56.6,
    '164': 56.5,
    '165': 56.4,
    '166': 56.3,
    '167': 56.2,
    '168': 56.1,
    '169': 55.9,
    '170': 55.8,
    '171': 55.7,
    '172': 55.6,
    '173': 55.5,
    '174': 55.4,
    '175': 55.3,
    '176': 55.2,
    '177': 55.1,
    '178': 55,
    '179': 54.9,
    '180': 54.8,
    '181': 54.7,
    '182': 54.6,
    '183': 54.5,
    '184': 54.4,
    '185': 54.3,
    '186': 54.2,
    '187': 54.1,
    '188': 54,
    '189': 53.9,
    '190': 53.8,
    '191': 53.7,
    '192': 53.6,
    '193': 53.5,
    '194': 53.4,
    '195': 53.3,
    '0.5': 77.6,
    '1.5': 77.5,
    '2.5': 77.3,
    '3.5': 77.2,
    '4.5': 77,
    '5.5': 76.8,
    '6.5': 76.7,
    '7.5': 76.5,
    '8.5': 76.4,
    '9.5': 76.2,
    '10.5': 76.1,
    '11.5': 75.9,
    '12.5': 75.8,
    '13.5': 75.6,
    '14.5': 75.5,
    '15.5': 75.3,
    '16.5': 75.2,
    '17.5': 75,
    '18.5': 74.9,
    '19.5': 74.7,
    '20.5': 74.6,
    '21.5': 74.4,
    '22.5': 74.3,
    '23.5': 74.1,
    '24.5': 74,
    '25.5': 73.8,
    '26.5': 73.7,
    '27.5': 73.6,
    '28.5': 73.4,
    '29.5': 73.3,
    '30.5': 73.1,
    '31.5': 73,
    '32.5': 72.8,
    '33.5': 72.7,
    '34.5': 72.5,
    '35.5': 72.4,
    '36.5': 72.3,
    '37.5': 72.1,
    '38.5': 72,
    '39.5': 71.8,
    '40.5': 71.7,
    '41.5': 71.6,
    '42.5': 71.4,
    '43.5': 71.3,
    '44.5': 71.1,
    '45.5': 71,
    '46.5': 70.9,
    '47.5': 70.7,
    '48.5': 70.6,
    '49.5': 70.4,
    '50.5': 70.3,
    '51.5': 70.2,
    '52.5': 70,
    '53.5': 69.9,
    '54.5': 69.7,
    '55.5': 69.6,
    '56.5': 69.5,
    '57.5': 69.3,
    '58.5': 69.2,
    '59.5': 69.1,
    '60.5': 68.9,
    '61.5': 68.8,
    '62.5': 68.7,
    '63.5': 68.5,
    '64.5': 68.4,
    '65.5': 68.3,
    '66.5': 68.1,
    '67.5': 68,
    '68.5': 67.9,
    '69.5': 67.7,
    '70.5': 67.6,
    '71.5': 67.5,
    '72.5': 67.3,
    '73.5': 67.2,
    '74.5': 67.1,
    '75.5': 66.9,
    '76.5': 66.8,
    '77.5': 66.7,
    '78.5': 66.6,
    '79.5': 66.4,
    '80.5': 66.3,
    '81.5': 66.2,
    '82.5': 66,
    '83.5': 65.9,
    '84.5': 65.8,
    '85.5': 65.7,
    '86.5': 65.5,
    '87.5': 65.4,
    '88.5': 65.3,
    '89.5': 65.1,
    '90.5': 65,
    '91.5': 64.9,
    '92.5': 64.8,
    '93.5': 64.6,
    '94.5': 64.5,
    '95.5': 64.4,
    '96.5': 64.3,
    '97.5': 64.1,
    '98.5': 64,
    '99.5': 63.9,
    '100.5': 63.8,
    '101.5': 63.7,
    '102.5': 63.5,
    '103.5': 63.4,
    '104.5': 63.3,
    '105.5': 63.2,
    '106.5': 63,
    '107.5': 62.9,
    '108.5': 62.8,
    '109.5': 62.7,
    '110.5': 62.6,
    '111.5': 62.4,
    '112.5': 62.3,
    '113.5': 62.2,
    '114.5': 62.1,
    '115.5': 62,
    '116.5': 61.8,
    '117.5': 61.7,
    '118.5': 61.6,
    '119.5': 61.5,
    '120.5': 61.4,
    '121.5': 61.3,
    '122.5': 61.1,
    '123.5': 61,
    '124.5': 60.9,
    '125.5': 60.8,
    '126.5': 60.7,
    '127.5': 60.6,
    '128.5': 60.4,
    '129.5': 60.3,
    '130.5': 60.2,
    '131.5': 60.1,
    '132.5': 60,
    '133.5': 59.9,
    '134.5': 59.7,
    '135.5': 59.6,
    '136.5': 59.5,
    '137.5': 59.4,
    '138.5': 59.3,
    '139.5': 59.2,
    '140.5': 59.1,
    '141.5': 59,
    '142.5': 58.8,
    '143.5': 58.7,
    '144.5': 58.6,
    '145.5': 58.5,
    '146.5': 58.4,
    '147.5': 58.3,
    '148.5': 58.2,
    '149.5': 58.1,
    '150.5': 57.9,
    '151.5': 57.8,
    '152.5': 57.7,
    '153.5': 57.6,
    '154.5': 57.5,
    '155.5': 57.4,
    '156.5': 57.3,
    '157.5': 57.2,
    '158.5': 57.1,
    '159.5': 57,
    '160.5': 56.9,
    '161.5': 56.7,
    '162.5': 56.6,
    '163.5': 56.5,
    '164.5': 56.4,
    '165.5': 56.3,
    '166.5': 56.2,
    '167.5': 56.1,
    '168.5': 56,
    '169.5': 55.9,
    '170.5': 55.8,
    '171.5': 55.7,
    '172.5': 55.6,
    '173.5': 55.5,
    '174.5': 55.4,
    '175.5': 55.3,
    '176.5': 55.2,
    '177.5': 55.1,
    '178.5': 54.9,
    '179.5': 54.8,
    '180.5': 54.7,
    '181.5': 54.6,
    '182.5': 54.5,
    '183.5': 54.4,
    '184.5': 54.3,
    '185.5': 54.2,
    '186.5': 54.1,
    '187.5': 54,
    '188.5': 53.9,
    '189.5': 53.8,
    '190.5': 53.7,
    '191.5': 53.6,
    '192.5': 53.5,
    '193.5': 53.4,
    '194.5': 53.3,
  },
  '69.5': {
    '0': 78.3,
    '1': 78.1,
    '2': 78,
    '3': 77.8,
    '4': 77.6,
    '5': 77.5,
    '6': 77.3,
    '7': 77.2,
    '8': 77,
    '9': 76.9,
    '10': 76.7,
    '11': 76.6,
    '12': 76.4,
    '13': 76.3,
    '14': 76.1,
    '15': 76,
    '16': 75.8,
    '17': 75.7,
    '18': 75.5,
    '19': 75.4,
    '20': 75.2,
    '21': 75.1,
    '22': 74.9,
    '23': 74.8,
    '24': 74.6,
    '25': 74.5,
    '26': 74.3,
    '27': 74.2,
    '28': 74,
    '29': 73.9,
    '30': 73.7,
    '31': 73.6,
    '32': 73.4,
    '33': 73.3,
    '34': 73.1,
    '35': 73,
    '36': 72.9,
    '37': 72.7,
    '38': 72.6,
    '39': 72.4,
    '40': 72.3,
    '41': 72.1,
    '42': 72,
    '43': 71.9,
    '44': 71.7,
    '45': 71.6,
    '46': 71.4,
    '47': 71.3,
    '48': 71.2,
    '49': 71,
    '50': 70.9,
    '51': 70.7,
    '52': 70.6,
    '53': 70.5,
    '54': 70.3,
    '55': 70.2,
    '56': 70,
    '57': 69.9,
    '58': 69.8,
    '59': 69.6,
    '60': 69.5,
    '61': 69.4,
    '62': 69.2,
    '63': 69.1,
    '64': 69,
    '65': 68.8,
    '66': 68.7,
    '67': 68.6,
    '68': 68.4,
    '69': 68.3,
    '70': 68.2,
    '71': 68,
    '72': 67.9,
    '73': 67.8,
    '74': 67.6,
    '75': 67.5,
    '76': 67.4,
    '77': 67.2,
    '78': 67.1,
    '79': 67,
    '80': 66.8,
    '81': 66.7,
    '82': 66.6,
    '83': 66.5,
    '84': 66.3,
    '85': 66.2,
    '86': 66.1,
    '87': 65.9,
    '88': 65.8,
    '89': 65.7,
    '90': 65.6,
    '91': 65.4,
    '92': 65.3,
    '93': 65.2,
    '94': 65.1,
    '95': 64.9,
    '96': 64.8,
    '97': 64.7,
    '98': 64.6,
    '99': 64.4,
    '100': 64.3,
    '101': 64.2,
    '102': 64.1,
    '103': 63.9,
    '104': 63.8,
    '105': 63.7,
    '106': 63.6,
    '107': 63.4,
    '108': 63.3,
    '109': 63.2,
    '110': 63.1,
    '111': 63,
    '112': 62.8,
    '113': 62.7,
    '114': 62.6,
    '115': 62.5,
    '116': 62.4,
    '117': 62.2,
    '118': 62.1,
    '119': 62,
    '120': 61.9,
    '121': 61.8,
    '122': 61.6,
    '123': 61.5,
    '124': 61.4,
    '125': 61.3,
    '126': 61.2,
    '127': 61,
    '128': 60.9,
    '129': 60.8,
    '130': 60.7,
    '131': 60.6,
    '132': 60.5,
    '133': 60.4,
    '134': 60.2,
    '135': 60.1,
    '136': 60,
    '137': 59.9,
    '138': 59.8,
    '139': 59.7,
    '140': 59.6,
    '141': 59.4,
    '142': 59.3,
    '143': 59.2,
    '144': 59.1,
    '145': 59,
    '146': 58.9,
    '147': 58.8,
    '148': 58.6,
    '149': 58.5,
    '150': 58.4,
    '151': 58.3,
    '152': 58.2,
    '153': 58.1,
    '154': 58,
    '155': 57.9,
    '156': 57.8,
    '157': 57.7,
    '158': 57.5,
    '159': 57.4,
    '160': 57.3,
    '161': 57.2,
    '162': 57.1,
    '163': 57,
    '164': 56.9,
    '165': 56.8,
    '166': 56.7,
    '167': 56.6,
    '168': 56.5,
    '169': 56.4,
    '170': 56.2,
    '171': 56.1,
    '172': 56,
    '173': 55.9,
    '174': 55.8,
    '175': 55.7,
    '176': 55.6,
    '177': 55.5,
    '178': 55.4,
    '179': 55.3,
    '180': 55.2,
    '181': 55.1,
    '182': 55,
    '183': 54.9,
    '184': 54.8,
    '185': 54.7,
    '186': 54.6,
    '187': 54.5,
    '188': 54.4,
    '189': 54.3,
    '190': 54.2,
    '191': 54.1,
    '192': 54,
    '193': 53.9,
    '194': 53.7,
    '195': 53.7,
    '0.5': 78.2,
    '1.5': 78,
    '2.5': 77.9,
    '3.5': 77.7,
    '4.5': 77.6,
    '5.5': 77.4,
    '6.5': 77.3,
    '7.5': 77.1,
    '8.5': 76.9,
    '9.5': 76.8,
    '10.5': 76.6,
    '11.5': 76.5,
    '12.5': 76.3,
    '13.5': 76.2,
    '14.5': 76,
    '15.5': 75.9,
    '16.5': 75.7,
    '17.5': 75.6,
    '18.5': 75.4,
    '19.5': 75.3,
    '20.5': 75.1,
    '21.5': 75,
    '22.5': 74.8,
    '23.5': 74.7,
    '24.5': 74.5,
    '25.5': 74.4,
    '26.5': 74.2,
    '27.5': 74.1,
    '28.5': 73.9,
    '29.5': 73.8,
    '30.5': 73.7,
    '31.5': 73.5,
    '32.5': 73.4,
    '33.5': 73.2,
    '34.5': 73.1,
    '35.5': 72.9,
    '36.5': 72.8,
    '37.5': 72.6,
    '38.5': 72.5,
    '39.5': 72.4,
    '40.5': 72.2,
    '41.5': 72.1,
    '42.5': 71.9,
    '43.5': 71.8,
    '44.5': 71.6,
    '45.5': 71.5,
    '46.5': 71.4,
    '47.5': 71.2,
    '48.5': 71.1,
    '49.5': 70.9,
    '50.5': 70.8,
    '51.5': 70.7,
    '52.5': 70.5,
    '53.5': 70.4,
    '54.5': 70.3,
    '55.5': 70.1,
    '56.5': 70,
    '57.5': 69.8,
    '58.5': 69.7,
    '59.5': 69.6,
    '60.5': 69.4,
    '61.5': 69.3,
    '62.5': 69.2,
    '63.5': 69,
    '64.5': 68.9,
    '65.5': 68.8,
    '66.5': 68.6,
    '67.5': 68.5,
    '68.5': 68.4,
    '69.5': 68.2,
    '70.5': 68.1,
    '71.5': 68,
    '72.5': 67.8,
    '73.5': 67.7,
    '74.5': 67.6,
    '75.5': 67.4,
    '76.5': 67.3,
    '77.5': 67.2,
    '78.5': 67,
    '79.5': 66.9,
    '80.5': 66.8,
    '81.5': 66.6,
    '82.5': 66.5,
    '83.5': 66.4,
    '84.5': 66.3,
    '85.5': 66.1,
    '86.5': 66,
    '87.5': 65.9,
    '88.5': 65.7,
    '89.5': 65.6,
    '90.5': 65.5,
    '91.5': 65.4,
    '92.5': 65.2,
    '93.5': 65.1,
    '94.5': 65,
    '95.5': 64.9,
    '96.5': 64.7,
    '97.5': 64.6,
    '98.5': 64.5,
    '99.5': 64.4,
    '100.5': 64.2,
    '101.5': 64.1,
    '102.5': 64,
    '103.5': 63.9,
    '104.5': 63.7,
    '105.5': 63.6,
    '106.5': 63.5,
    '107.5': 63.4,
    '108.5': 63.3,
    '109.5': 63.1,
    '110.5': 63,
    '111.5': 62.9,
    '112.5': 62.8,
    '113.5': 62.7,
    '114.5': 62.5,
    '115.5': 62.4,
    '116.5': 62.3,
    '117.5': 62.2,
    '118.5': 62.1,
    '119.5': 61.9,
    '120.5': 61.8,
    '121.5': 61.7,
    '122.5': 61.6,
    '123.5': 61.5,
    '124.5': 61.3,
    '125.5': 61.2,
    '126.5': 61.1,
    '127.5': 61,
    '128.5': 60.9,
    '129.5': 60.8,
    '130.5': 60.6,
    '131.5': 60.5,
    '132.5': 60.4,
    '133.5': 60.3,
    '134.5': 60.2,
    '135.5': 60.1,
    '136.5': 60,
    '137.5': 59.8,
    '138.5': 59.7,
    '139.5': 59.6,
    '140.5': 59.5,
    '141.5': 59.4,
    '142.5': 59.3,
    '143.5': 59.2,
    '144.5': 59,
    '145.5': 58.9,
    '146.5': 58.8,
    '147.5': 58.7,
    '148.5': 58.6,
    '149.5': 58.5,
    '150.5': 58.4,
    '151.5': 58.3,
    '152.5': 58.1,
    '153.5': 58,
    '154.5': 57.9,
    '155.5': 57.8,
    '156.5': 57.7,
    '157.5': 57.6,
    '158.5': 57.5,
    '159.5': 57.4,
    '160.5': 57.3,
    '161.5': 57.2,
    '162.5': 57.1,
    '163.5': 56.9,
    '164.5': 56.8,
    '165.5': 56.7,
    '166.5': 56.6,
    '167.5': 56.5,
    '168.5': 56.4,
    '169.5': 56.3,
    '170.5': 56.2,
    '171.5': 56.1,
    '172.5': 56,
    '173.5': 55.9,
    '174.5': 55.8,
    '175.5': 55.7,
    '176.5': 55.6,
    '177.5': 55.5,
    '178.5': 55.3,
    '179.5': 55.2,
    '180.5': 55.1,
    '181.5': 55,
    '182.5': 54.9,
    '183.5': 54.8,
    '184.5': 54.7,
    '185.5': 54.6,
    '186.5': 54.5,
    '187.5': 54.4,
    '188.5': 54.3,
    '189.5': 54.2,
    '190.5': 54.1,
    '191.5': 54,
    '192.5': 53.9,
    '193.5': 53.8,
    '194.5': 53.7,
  },
  '70': {
    '0': 78.8,
    '1': 78.7,
    '2': 78.5,
    '3': 78.4,
    '4': 78.2,
    '5': 78,
    '6': 77.9,
    '7': 77.7,
    '8': 77.6,
    '9': 77.4,
    '10': 77.3,
    '11': 77.1,
    '12': 77,
    '13': 76.8,
    '14': 76.7,
    '15': 76.5,
    '16': 76.4,
    '17': 76.2,
    '18': 76.1,
    '19': 75.9,
    '20': 75.8,
    '21': 75.6,
    '22': 75.5,
    '23': 75.3,
    '24': 75.1,
    '25': 75,
    '26': 74.9,
    '27': 74.7,
    '28': 74.6,
    '29': 74.4,
    '30': 74.3,
    '31': 74.1,
    '32': 74,
    '33': 73.8,
    '34': 73.7,
    '35': 73.5,
    '36': 73.4,
    '37': 73.2,
    '38': 73.1,
    '39': 72.9,
    '40': 72.8,
    '41': 72.7,
    '42': 72.5,
    '43': 72.4,
    '44': 72.2,
    '45': 72.1,
    '46': 72,
    '47': 71.8,
    '48': 71.7,
    '49': 71.5,
    '50': 71.4,
    '51': 71.2,
    '52': 71.1,
    '53': 71,
    '54': 70.8,
    '55': 70.7,
    '56': 70.5,
    '57': 70.4,
    '58': 70.3,
    '59': 70.1,
    '60': 70,
    '61': 69.9,
    '62': 69.7,
    '63': 69.6,
    '64': 69.5,
    '65': 69.3,
    '66': 69.2,
    '67': 69,
    '68': 68.9,
    '69': 68.8,
    '70': 68.6,
    '71': 68.5,
    '72': 68.4,
    '73': 68.2,
    '74': 68.1,
    '75': 68,
    '76': 67.8,
    '77': 67.7,
    '78': 67.6,
    '79': 67.5,
    '80': 67.3,
    '81': 67.2,
    '82': 67.1,
    '83': 66.9,
    '84': 66.8,
    '85': 66.7,
    '86': 66.5,
    '87': 66.4,
    '88': 66.3,
    '89': 66.2,
    '90': 66,
    '91': 65.9,
    '92': 65.8,
    '93': 65.6,
    '94': 65.5,
    '95': 65.4,
    '96': 65.3,
    '97': 65.1,
    '98': 65,
    '99': 64.9,
    '100': 64.8,
    '101': 64.6,
    '102': 64.5,
    '103': 64.4,
    '104': 64.3,
    '105': 64.1,
    '106': 64,
    '107': 63.9,
    '108': 63.8,
    '109': 63.6,
    '110': 63.5,
    '111': 63.4,
    '112': 63.3,
    '113': 63.2,
    '114': 63,
    '115': 62.9,
    '116': 62.8,
    '117': 62.7,
    '118': 62.6,
    '119': 62.4,
    '120': 62.3,
    '121': 62.2,
    '122': 62.1,
    '123': 62,
    '124': 61.8,
    '125': 61.7,
    '126': 61.6,
    '127': 61.5,
    '128': 61.4,
    '129': 61.3,
    '130': 61.1,
    '131': 61,
    '132': 60.9,
    '133': 60.8,
    '134': 60.7,
    '135': 60.6,
    '136': 60.4,
    '137': 60.3,
    '138': 60.2,
    '139': 60.1,
    '140': 60,
    '141': 59.9,
    '142': 59.7,
    '143': 59.6,
    '144': 59.5,
    '145': 59.4,
    '146': 59.3,
    '147': 59.2,
    '148': 59.1,
    '149': 59,
    '150': 58.8,
    '151': 58.7,
    '152': 58.6,
    '153': 58.5,
    '154': 58.4,
    '155': 58.3,
    '156': 58.2,
    '157': 58.1,
    '158': 58,
    '159': 57.8,
    '160': 57.7,
    '161': 57.6,
    '162': 57.5,
    '163': 57.4,
    '164': 57.3,
    '165': 57.2,
    '166': 57.1,
    '167': 57,
    '168': 56.9,
    '169': 56.8,
    '170': 56.7,
    '171': 56.5,
    '172': 56.4,
    '173': 56.3,
    '174': 56.2,
    '175': 56.1,
    '176': 56,
    '177': 55.9,
    '178': 55.8,
    '179': 55.7,
    '180': 55.6,
    '181': 55.5,
    '182': 55.4,
    '183': 55.3,
    '184': 55.2,
    '185': 55.1,
    '186': 55,
    '187': 54.9,
    '188': 54.8,
    '189': 54.7,
    '190': 54.6,
    '191': 54.4,
    '192': 54.3,
    '193': 54.2,
    '194': 54.1,
    '195': 54,
    '0.5': 78.8,
    '1.5': 78.6,
    '2.5': 78.4,
    '3.5': 78.3,
    '4.5': 78.1,
    '5.5': 78,
    '6.5': 77.8,
    '7.5': 77.7,
    '8.5': 77.5,
    '9.5': 77.4,
    '10.5': 77.2,
    '11.5': 77,
    '12.5': 76.9,
    '13.5': 76.7,
    '14.5': 76.6,
    '15.5': 76.4,
    '16.5': 76.3,
    '17.5': 76.1,
    '18.5': 76,
    '19.5': 75.8,
    '20.5': 75.7,
    '21.5': 75.5,
    '22.5': 75.4,
    '23.5': 75.2,
    '24.5': 75.1,
    '25.5': 74.9,
    '26.5': 74.8,
    '27.5': 74.6,
    '28.5': 74.5,
    '29.5': 74.3,
    '30.5': 74.2,
    '31.5': 74,
    '32.5': 73.9,
    '33.5': 73.8,
    '34.5': 73.6,
    '35.5': 73.5,
    '36.5': 73.3,
    '37.5': 73.2,
    '38.5': 73,
    '39.5': 72.9,
    '40.5': 72.7,
    '41.5': 72.6,
    '42.5': 72.4,
    '43.5': 72.3,
    '44.5': 72.2,
    '45.5': 72,
    '46.5': 71.9,
    '47.5': 71.7,
    '48.5': 71.6,
    '49.5': 71.5,
    '50.5': 71.3,
    '51.5': 71.2,
    '52.5': 71,
    '53.5': 70.9,
    '54.5': 70.8,
    '55.5': 70.6,
    '56.5': 70.5,
    '57.5': 70.3,
    '58.5': 70.2,
    '59.5': 70.1,
    '60.5': 69.9,
    '61.5': 69.8,
    '62.5': 69.7,
    '63.5': 69.5,
    '64.5': 69.4,
    '65.5': 69.3,
    '66.5': 69.1,
    '67.5': 69,
    '68.5': 68.8,
    '69.5': 68.7,
    '70.5': 68.6,
    '71.5': 68.4,
    '72.5': 68.3,
    '73.5': 68.2,
    '74.5': 68,
    '75.5': 67.9,
    '76.5': 67.8,
    '77.5': 67.6,
    '78.5': 67.5,
    '79.5': 67.4,
    '80.5': 67.3,
    '81.5': 67.1,
    '82.5': 67,
    '83.5': 66.9,
    '84.5': 66.7,
    '85.5': 66.6,
    '86.5': 66.5,
    '87.5': 66.3,
    '88.5': 66.2,
    '89.5': 66.1,
    '90.5': 66,
    '91.5': 65.8,
    '92.5': 65.7,
    '93.5': 65.6,
    '94.5': 65.5,
    '95.5': 65.3,
    '96.5': 65.2,
    '97.5': 65.1,
    '98.5': 65,
    '99.5': 64.8,
    '100.5': 64.7,
    '101.5': 64.6,
    '102.5': 64.5,
    '103.5': 64.3,
    '104.5': 64.2,
    '105.5': 64.1,
    '106.5': 64,
    '107.5': 63.8,
    '108.5': 63.7,
    '109.5': 63.6,
    '110.5': 63.5,
    '111.5': 63.3,
    '112.5': 63.2,
    '113.5': 63.1,
    '114.5': 63,
    '115.5': 62.9,
    '116.5': 62.7,
    '117.5': 62.6,
    '118.5': 62.5,
    '119.5': 62.4,
    '120.5': 62.3,
    '121.5': 62.1,
    '122.5': 62,
    '123.5': 61.9,
    '124.5': 61.8,
    '125.5': 61.7,
    '126.5': 61.5,
    '127.5': 61.4,
    '128.5': 61.3,
    '129.5': 61.2,
    '130.5': 61.1,
    '131.5': 61,
    '132.5': 60.8,
    '133.5': 60.7,
    '134.5': 60.6,
    '135.5': 60.5,
    '136.5': 60.4,
    '137.5': 60.3,
    '138.5': 60.2,
    '139.5': 60,
    '140.5': 59.9,
    '141.5': 59.8,
    '142.5': 59.7,
    '143.5': 59.6,
    '144.5': 59.5,
    '145.5': 59.4,
    '146.5': 59.2,
    '147.5': 59.1,
    '148.5': 59,
    '149.5': 58.9,
    '150.5': 58.8,
    '151.5': 58.7,
    '152.5': 58.6,
    '153.5': 58.5,
    '154.5': 58.3,
    '155.5': 58.2,
    '156.5': 58.1,
    '157.5': 58,
    '158.5': 57.9,
    '159.5': 57.8,
    '160.5': 57.7,
    '161.5': 57.6,
    '162.5': 57.5,
    '163.5': 57.4,
    '164.5': 57.2,
    '165.5': 57.1,
    '166.5': 57,
    '167.5': 56.9,
    '168.5': 56.8,
    '169.5': 56.7,
    '170.5': 56.6,
    '171.5': 56.5,
    '172.5': 56.4,
    '173.5': 56.3,
    '174.5': 56.2,
    '175.5': 56.1,
    '176.5': 56,
    '177.5': 55.9,
    '178.5': 55.7,
    '179.5': 55.6,
    '180.5': 55.5,
    '181.5': 55.4,
    '182.5': 55.3,
    '183.5': 55.2,
    '184.5': 55.1,
    '185.5': 55,
    '186.5': 54.9,
    '187.5': 54.8,
    '188.5': 54.7,
    '189.5': 54.6,
    '190.5': 54.5,
    '191.5': 54.4,
    '192.5': 54.3,
    '193.5': 54.2,
    '194.5': 54.1,
  },
  // 70 - 75
  '70.5': {
    '0': 79.4,
    '1': 79.2,
    '2': 79.1,
    '3': 78.9,
    '4': 78.8,
    '5': 78.6,
    '6': 78.5,
    '7': 78.3,
    '8': 78.1,
    '9': 78,
    '10': 77.8,
    '11': 77.7,
    '12': 77.5,
    '13': 77.4,
    '14': 77.2,
    '15': 77.1,
    '16': 76.9,
    '17': 76.8,
    '18': 76.6,
    '19': 76.4,
    '20': 76.3,
    '21': 76.1,
    '22': 76,
    '23': 75.8,
    '24': 75.7,
    '25': 75.5,
    '26': 75.4,
    '27': 75.2,
    '28': 75.1,
    '29': 74.9,
    '30': 74.8,
    '31': 74.6,
    '32': 74.5,
    '33': 74.4,
    '34': 74.2,
    '35': 74.1,
    '36': 73.9,
    '37': 73.8,
    '38': 73.6,
    '39': 73.5,
    '40': 73.3,
    '41': 73.2,
    '42': 73,
    '43': 72.9,
    '44': 72.8,
    '45': 72.6,
    '46': 72.5,
    '47': 72.3,
    '48': 72.2,
    '49': 72,
    '50': 71.9,
    '51': 71.8,
    '52': 71.6,
    '53': 71.5,
    '54': 71.3,
    '55': 71.2,
    '56': 71.1,
    '57': 70.9,
    '58': 70.8,
    '59': 70.6,
    '60': 70.5,
    '61': 70.4,
    '62': 70.2,
    '63': 70.1,
    '64': 70,
    '65': 69.8,
    '66': 69.7,
    '67': 69.5,
    '68': 69.4,
    '69': 69.3,
    '70': 69.1,
    '71': 69,
    '72': 68.9,
    '73': 68.7,
    '74': 68.6,
    '75': 68.5,
    '76': 68.3,
    '77': 68.2,
    '78': 68.1,
    '79': 67.9,
    '80': 67.8,
    '81': 67.7,
    '82': 67.5,
    '83': 67.4,
    '84': 67.3,
    '85': 67.1,
    '86': 67,
    '87': 66.9,
    '88': 66.8,
    '89': 66.6,
    '90': 66.5,
    '91': 66.4,
    '92': 66.2,
    '93': 66.1,
    '94': 66,
    '95': 65.9,
    '96': 65.7,
    '97': 65.6,
    '98': 65.5,
    '99': 65.4,
    '100': 65.2,
    '101': 65.1,
    '102': 65,
    '103': 64.8,
    '104': 64.7,
    '105': 64.6,
    '106': 64.5,
    '107': 64.4,
    '108': 64.2,
    '109': 64.1,
    '110': 64,
    '111': 63.9,
    '112': 63.7,
    '113': 63.6,
    '114': 63.5,
    '115': 63.4,
    '116': 63.2,
    '117': 63.1,
    '118': 63,
    '119': 62.9,
    '120': 62.8,
    '121': 62.6,
    '122': 62.5,
    '123': 62.4,
    '124': 62.3,
    '125': 62.2,
    '126': 62,
    '127': 61.9,
    '128': 61.8,
    '129': 61.7,
    '130': 61.6,
    '131': 61.5,
    '132': 61.3,
    '133': 61.2,
    '134': 61.1,
    '135': 61,
    '136': 60.9,
    '137': 60.8,
    '138': 60.6,
    '139': 60.5,
    '140': 60.4,
    '141': 60.3,
    '142': 60.2,
    '143': 60.1,
    '144': 59.9,
    '145': 59.8,
    '146': 59.7,
    '147': 59.6,
    '148': 59.5,
    '149': 59.4,
    '150': 59.3,
    '151': 59.2,
    '152': 59,
    '153': 58.9,
    '154': 58.8,
    '155': 58.7,
    '156': 58.6,
    '157': 58.5,
    '158': 58.4,
    '159': 58.3,
    '160': 58.2,
    '161': 58,
    '162': 57.9,
    '163': 57.8,
    '164': 57.7,
    '165': 57.6,
    '166': 57.5,
    '167': 57.4,
    '168': 57.3,
    '169': 57.2,
    '170': 57.1,
    '171': 57,
    '172': 56.8,
    '173': 56.7,
    '174': 56.6,
    '175': 56.5,
    '176': 56.4,
    '177': 56.3,
    '178': 56.2,
    '179': 56.1,
    '180': 56,
    '181': 55.9,
    '182': 55.8,
    '183': 55.7,
    '184': 55.6,
    '185': 55.5,
    '186': 55.4,
    '187': 55.3,
    '188': 55.2,
    '189': 55.1,
    '190': 54.9,
    '191': 54.8,
    '192': 54.7,
    '193': 54.6,
    '194': 54.5,
    '195': 54.4,
    '0.5': 79.3,
    '1.5': 79.2,
    '2.5': 79,
    '3.5': 78.9,
    '4.5': 78.7,
    '5.5': 78.5,
    '6.5': 78.4,
    '7.5': 78.2,
    '8.5': 78.1,
    '9.5': 77.9,
    '10.5': 77.8,
    '11.5': 77.6,
    '12.5': 77.4,
    '13.5': 77.3,
    '14.5': 77.1,
    '15.5': 77,
    '16.5': 76.8,
    '17.5': 76.7,
    '18.5': 76.5,
    '19.5': 76.4,
    '20.5': 76.2,
    '21.5': 76.1,
    '22.5': 75.9,
    '23.5': 75.8,
    '24.5': 75.6,
    '25.5': 75.5,
    '26.5': 75.3,
    '27.5': 75.2,
    '28.5': 75,
    '29.5': 74.9,
    '30.5': 74.7,
    '31.5': 74.6,
    '32.5': 74.4,
    '33.5': 74.3,
    '34.5': 74.1,
    '35.5': 74,
    '36.5': 73.8,
    '37.5': 73.7,
    '38.5': 73.5,
    '39.5': 73.4,
    '40.5': 73.3,
    '41.5': 73.1,
    '42.5': 73,
    '43.5': 72.8,
    '44.5': 72.7,
    '45.5': 72.5,
    '46.5': 72.4,
    '47.5': 72.3,
    '48.5': 72.1,
    '49.5': 72,
    '50.5': 71.8,
    '51.5': 71.7,
    '52.5': 71.5,
    '53.5': 71.4,
    '54.5': 71.3,
    '55.5': 71.1,
    '56.5': 71,
    '57.5': 70.8,
    '58.5': 70.7,
    '59.5': 70.6,
    '60.5': 70.4,
    '61.5': 70.3,
    '62.5': 70.2,
    '63.5': 70,
    '64.5': 69.9,
    '65.5': 69.7,
    '66.5': 69.6,
    '67.5': 69.5,
    '68.5': 69.3,
    '69.5': 69.2,
    '70.5': 69.1,
    '71.5': 68.9,
    '72.5': 68.8,
    '73.5': 68.7,
    '74.5': 68.5,
    '75.5': 68.4,
    '76.5': 68.3,
    '77.5': 68.1,
    '78.5': 68,
    '79.5': 67.9,
    '80.5': 67.7,
    '81.5': 67.6,
    '82.5': 67.5,
    '83.5': 67.3,
    '84.5': 67.2,
    '85.5': 67.1,
    '86.5': 67,
    '87.5': 66.8,
    '88.5': 66.7,
    '89.5': 66.6,
    '90.5': 66.4,
    '91.5': 66.3,
    '92.5': 66.2,
    '93.5': 66,
    '94.5': 65.9,
    '95.5': 65.8,
    '96.5': 65.7,
    '97.5': 65.5,
    '98.5': 65.4,
    '99.5': 65.3,
    '100.5': 65.2,
    '101.5': 65,
    '102.5': 64.9,
    '103.5': 64.8,
    '104.5': 64.7,
    '105.5': 64.5,
    '106.5': 64.4,
    '107.5': 64.3,
    '108.5': 64.2,
    '109.5': 64,
    '110.5': 63.9,
    '111.5': 63.8,
    '112.5': 63.7,
    '113.5': 63.6,
    '114.5': 63.4,
    '115.5': 63.3,
    '116.5': 63.2,
    '117.5': 63.1,
    '118.5': 62.9,
    '119.5': 62.8,
    '120.5': 62.7,
    '121.5': 62.6,
    '122.5': 62.5,
    '123.5': 62.3,
    '124.5': 62.2,
    '125.5': 62.1,
    '126.5': 62,
    '127.5': 61.9,
    '128.5': 61.7,
    '129.5': 61.6,
    '130.5': 61.5,
    '131.5': 61.4,
    '132.5': 61.3,
    '133.5': 61.2,
    '134.5': 61,
    '135.5': 60.9,
    '136.5': 60.8,
    '137.5': 60.7,
    '138.5': 60.6,
    '139.5': 60.5,
    '140.5': 60.4,
    '141.5': 60.2,
    '142.5': 60.1,
    '143.5': 60,
    '144.5': 59.9,
    '145.5': 59.8,
    '146.5': 59.7,
    '147.5': 59.6,
    '148.5': 59.4,
    '149.5': 59.3,
    '150.5': 59.2,
    '151.5': 59.1,
    '152.5': 59,
    '153.5': 58.9,
    '154.5': 58.8,
    '155.5': 58.7,
    '156.5': 58.5,
    '157.5': 58.4,
    '158.5': 58.3,
    '159.5': 58.2,
    '160.5': 58.1,
    '161.5': 58,
    '162.5': 57.9,
    '163.5': 57.8,
    '164.5': 57.7,
    '165.5': 57.5,
    '166.5': 57.4,
    '167.5': 57.3,
    '168.5': 57.2,
    '169.5': 57.1,
    '170.5': 57,
    '171.5': 56.9,
    '172.5': 56.8,
    '173.5': 56.7,
    '174.5': 56.6,
    '175.5': 56.5,
    '176.5': 56.4,
    '177.5': 56.3,
    '178.5': 56.2,
    '179.5': 56,
    '180.5': 55.9,
    '181.5': 55.8,
    '182.5': 55.7,
    '183.5': 55.6,
    '184.5': 55.5,
    '185.5': 55.4,
    '186.5': 55.3,
    '187.5': 55.2,
    '188.5': 55.1,
    '189.5': 55,
    '190.5': 54.9,
    '191.5': 54.8,
    '192.5': 54.7,
    '193.5': 54.6,
    '194.5': 54.5,
  },
  '71': {
    '0': 80,
    '1': 79.8,
    '2': 79.7,
    '3': 79.5,
    '4': 79.3,
    '5': 79.2,
    '6': 79,
    '7': 78.9,
    '8': 78.7,
    '9': 78.5,
    '10': 78.4,
    '11': 78.2,
    '12': 78.1,
    '13': 77.9,
    '14': 77.8,
    '15': 77.6,
    '16': 77.5,
    '17': 77.3,
    '18': 77.1,
    '19': 77,
    '20': 76.8,
    '21': 76.7,
    '22': 76.5,
    '23': 76.4,
    '24': 76.2,
    '25': 76.1,
    '26': 75.9,
    '27': 75.8,
    '28': 75.6,
    '29': 75.5,
    '30': 75.3,
    '31': 75.2,
    '32': 75,
    '33': 74.9,
    '34': 74.7,
    '35': 74.6,
    '36': 74.4,
    '37': 74.3,
    '38': 74.1,
    '39': 74,
    '40': 73.8,
    '41': 73.7,
    '42': 73.6,
    '43': 73.4,
    '44': 73.3,
    '45': 73.1,
    '46': 73,
    '47': 72.8,
    '48': 72.7,
    '49': 72.5,
    '50': 72.4,
    '51': 72.3,
    '52': 72.1,
    '53': 72,
    '54': 71.8,
    '55': 71.7,
    '56': 71.6,
    '57': 71.4,
    '58': 71.3,
    '59': 71.1,
    '60': 71,
    '61': 70.9,
    '62': 70.7,
    '63': 70.6,
    '64': 70.4,
    '65': 70.3,
    '66': 70.2,
    '67': 70,
    '68': 69.9,
    '69': 69.8,
    '70': 69.6,
    '71': 69.5,
    '72': 69.4,
    '73': 69.2,
    '74': 69.1,
    '75': 68.9,
    '76': 68.8,
    '77': 68.7,
    '78': 68.5,
    '79': 68.4,
    '80': 68.3,
    '81': 68.1,
    '82': 68,
    '83': 67.9,
    '84': 67.8,
    '85': 67.6,
    '86': 67.5,
    '87': 67.4,
    '88': 67.2,
    '89': 67.1,
    '90': 67,
    '91': 66.8,
    '92': 66.7,
    '93': 66.6,
    '94': 66.5,
    '95': 66.3,
    '96': 66.2,
    '97': 66.1,
    '98': 65.9,
    '99': 65.8,
    '100': 65.7,
    '101': 65.6,
    '102': 65.4,
    '103': 65.3,
    '104': 65.2,
    '105': 65.1,
    '106': 64.9,
    '107': 64.8,
    '108': 64.7,
    '109': 64.6,
    '110': 64.4,
    '111': 64.3,
    '112': 64.2,
    '113': 64.1,
    '114': 63.9,
    '115': 63.8,
    '116': 63.7,
    '117': 63.6,
    '118': 63.4,
    '119': 63.3,
    '120': 63.2,
    '121': 63.1,
    '122': 63,
    '123': 62.8,
    '124': 62.7,
    '125': 62.6,
    '126': 62.5,
    '127': 62.4,
    '128': 62.2,
    '129': 62.1,
    '130': 62,
    '131': 61.9,
    '132': 61.8,
    '133': 61.7,
    '134': 61.5,
    '135': 61.4,
    '136': 61.3,
    '137': 61.2,
    '138': 61.1,
    '139': 60.9,
    '140': 60.8,
    '141': 60.7,
    '142': 60.6,
    '143': 60.5,
    '144': 60.4,
    '145': 60.3,
    '146': 60.1,
    '147': 60,
    '148': 59.9,
    '149': 59.8,
    '150': 59.7,
    '151': 59.6,
    '152': 59.5,
    '153': 59.3,
    '154': 59.2,
    '155': 59.1,
    '156': 59,
    '157': 58.9,
    '158': 58.8,
    '159': 58.7,
    '160': 58.6,
    '161': 58.5,
    '162': 58.3,
    '163': 58.2,
    '164': 58.1,
    '165': 58,
    '166': 57.9,
    '167': 57.8,
    '168': 57.7,
    '169': 57.6,
    '170': 57.5,
    '171': 57.4,
    '172': 57.2,
    '173': 57.1,
    '174': 57,
    '175': 56.9,
    '176': 56.8,
    '177': 56.7,
    '178': 56.6,
    '179': 56.5,
    '180': 56.4,
    '181': 56.3,
    '182': 56.2,
    '183': 56.1,
    '184': 56,
    '185': 55.9,
    '186': 55.8,
    '187': 55.7,
    '188': 55.5,
    '189': 55.4,
    '190': 55.3,
    '191': 55.2,
    '192': 55.1,
    '193': 55,
    '194': 54.9,
    '195': 54.8,
    '0.5': 79.9,
    '1.5': 79.7,
    '2.5': 79.6,
    '3.5': 79.4,
    '4.5': 79.3,
    '5.5': 79.1,
    '6.5': 78.9,
    '7.5': 78.8,
    '8.5': 78.6,
    '9.5': 78.5,
    '10.5': 78.3,
    '11.5': 78.2,
    '12.5': 78,
    '13.5': 77.8,
    '14.5': 77.7,
    '15.5': 77.5,
    '16.5': 77.4,
    '17.5': 77.2,
    '18.5': 77.1,
    '19.5': 76.9,
    '20.5': 76.8,
    '21.5': 76.6,
    '22.5': 76.5,
    '23.5': 76.3,
    '24.5': 76.2,
    '25.5': 76,
    '26.5': 75.9,
    '27.5': 75.7,
    '28.5': 75.5,
    '29.5': 75.4,
    '30.5': 75.3,
    '31.5': 75.1,
    '32.5': 75,
    '33.5': 74.8,
    '34.5': 74.7,
    '35.5': 74.5,
    '36.5': 74.4,
    '37.5': 74.2,
    '38.5': 74.1,
    '39.5': 73.9,
    '40.5': 73.8,
    '41.5': 73.6,
    '42.5': 73.5,
    '43.5': 73.3,
    '44.5': 73.2,
    '45.5': 73,
    '46.5': 72.9,
    '47.5': 72.8,
    '48.5': 72.6,
    '49.5': 72.5,
    '50.5': 72.3,
    '51.5': 72.2,
    '52.5': 72.1,
    '53.5': 71.9,
    '54.5': 71.8,
    '55.5': 71.6,
    '56.5': 71.5,
    '57.5': 71.3,
    '58.5': 71.2,
    '59.5': 71.1,
    '60.5': 70.9,
    '61.5': 70.8,
    '62.5': 70.7,
    '63.5': 70.5,
    '64.5': 70.4,
    '65.5': 70.2,
    '66.5': 70.1,
    '67.5': 70,
    '68.5': 69.8,
    '69.5': 69.7,
    '70.5': 69.6,
    '71.5': 69.4,
    '72.5': 69.3,
    '73.5': 69.2,
    '74.5': 69,
    '75.5': 68.9,
    '76.5': 68.7,
    '77.5': 68.6,
    '78.5': 68.5,
    '79.5': 68.3,
    '80.5': 68.2,
    '81.5': 68.1,
    '82.5': 67.9,
    '83.5': 67.8,
    '84.5': 67.7,
    '85.5': 67.6,
    '86.5': 67.4,
    '87.5': 67.3,
    '88.5': 67.2,
    '89.5': 67,
    '90.5': 66.9,
    '91.5': 66.8,
    '92.5': 66.6,
    '93.5': 66.5,
    '94.5': 66.4,
    '95.5': 66.3,
    '96.5': 66.1,
    '97.5': 66,
    '98.5': 65.9,
    '99.5': 65.7,
    '100.5': 65.6,
    '101.5': 65.5,
    '102.5': 65.4,
    '103.5': 65.2,
    '104.5': 65.1,
    '105.5': 65,
    '106.5': 64.9,
    '107.5': 64.7,
    '108.5': 64.6,
    '109.5': 64.5,
    '110.5': 64.4,
    '111.5': 64.2,
    '112.5': 64.1,
    '113.5': 64,
    '114.5': 63.9,
    '115.5': 63.8,
    '116.5': 63.6,
    '117.5': 63.5,
    '118.5': 63.4,
    '119.5': 63.3,
    '120.5': 63.1,
    '121.5': 63,
    '122.5': 62.9,
    '123.5': 62.8,
    '124.5': 62.7,
    '125.5': 62.5,
    '126.5': 62.4,
    '127.5': 62.3,
    '128.5': 62.2,
    '129.5': 62.1,
    '130.5': 61.9,
    '131.5': 61.8,
    '132.5': 61.7,
    '133.5': 61.6,
    '134.5': 61.5,
    '135.5': 61.4,
    '136.5': 61.2,
    '137.5': 61.1,
    '138.5': 61,
    '139.5': 60.9,
    '140.5': 60.8,
    '141.5': 60.7,
    '142.5': 60.5,
    '143.5': 60.4,
    '144.5': 60.3,
    '145.5': 60.2,
    '146.5': 60.1,
    '147.5': 60,
    '148.5': 59.9,
    '149.5': 59.7,
    '150.5': 59.6,
    '151.5': 59.5,
    '152.5': 59.4,
    '153.5': 59.3,
    '154.5': 59.2,
    '155.5': 59.1,
    '156.5': 59,
    '157.5': 58.8,
    '158.5': 58.7,
    '159.5': 58.6,
    '160.5': 58.5,
    '161.5': 58.4,
    '162.5': 58.3,
    '163.5': 58.2,
    '164.5': 58.1,
    '165.5': 58,
    '166.5': 57.8,
    '167.5': 57.7,
    '168.5': 57.6,
    '169.5': 57.5,
    '170.5': 57.4,
    '171.5': 57.3,
    '172.5': 57.2,
    '173.5': 57.1,
    '174.5': 57,
    '175.5': 56.9,
    '176.5': 56.8,
    '177.5': 56.7,
    '178.5': 56.6,
    '179.5': 56.4,
    '180.5': 56.3,
    '181.5': 56.2,
    '182.5': 56.1,
    '183.5': 56,
    '184.5': 55.9,
    '185.5': 55.8,
    '186.5': 55.7,
    '187.5': 55.6,
    '188.5': 55.5,
    '189.5': 55.4,
    '190.5': 55.3,
    '191.5': 55.2,
    '192.5': 55.1,
    '193.5': 55,
    '194.5': 54.9,
  },
  '71.5': {
    '0': 80.5,
    '1': 80.4,
    '2': 80.2,
    '3': 80.1,
    '4': 79.9,
    '5': 79.7,
    '6': 79.6,
    '7': 79.4,
    '8': 79.3,
    '9': 79.1,
    '10': 78.9,
    '11': 78.8,
    '12': 78.6,
    '13': 78.5,
    '14': 78.3,
    '15': 78.2,
    '16': 78,
    '17': 77.9,
    '18': 77.7,
    '19': 77.5,
    '20': 77.4,
    '21': 77.2,
    '22': 77.1,
    '23': 76.9,
    '24': 76.8,
    '25': 76.6,
    '26': 76.5,
    '27': 76.3,
    '28': 76.2,
    '29': 76,
    '30': 75.9,
    '31': 75.7,
    '32': 75.6,
    '33': 75.4,
    '34': 75.3,
    '35': 75.1,
    '36': 75,
    '37': 74.8,
    '38': 74.7,
    '39': 74.5,
    '40': 74.4,
    '41': 74.2,
    '42': 74.1,
    '43': 73.9,
    '44': 73.8,
    '45': 73.6,
    '46': 73.5,
    '47': 73.3,
    '48': 73.2,
    '49': 73.1,
    '50': 72.9,
    '51': 72.8,
    '52': 72.6,
    '53': 72.5,
    '54': 72.3,
    '55': 72.2,
    '56': 72.1,
    '57': 71.9,
    '58': 71.8,
    '59': 71.6,
    '60': 71.5,
    '61': 71.4,
    '62': 71.2,
    '63': 71.1,
    '64': 70.9,
    '65': 70.8,
    '66': 70.7,
    '67': 70.5,
    '68': 70.4,
    '69': 70.3,
    '70': 70.1,
    '71': 70,
    '72': 69.8,
    '73': 69.7,
    '74': 69.6,
    '75': 69.4,
    '76': 69.3,
    '77': 69.2,
    '78': 69,
    '79': 68.9,
    '80': 68.8,
    '81': 68.6,
    '82': 68.5,
    '83': 68.4,
    '84': 68.2,
    '85': 68.1,
    '86': 68,
    '87': 67.8,
    '88': 67.7,
    '89': 67.6,
    '90': 67.4,
    '91': 67.3,
    '92': 67.2,
    '93': 67,
    '94': 66.9,
    '95': 66.8,
    '96': 66.7,
    '97': 66.5,
    '98': 66.4,
    '99': 66.3,
    '100': 66.1,
    '101': 66,
    '102': 65.9,
    '103': 65.8,
    '104': 65.6,
    '105': 65.5,
    '106': 65.4,
    '107': 65.3,
    '108': 65.1,
    '109': 65,
    '110': 64.9,
    '111': 64.8,
    '112': 64.6,
    '113': 64.5,
    '114': 64.4,
    '115': 64.3,
    '116': 64.1,
    '117': 64,
    '118': 63.9,
    '119': 63.8,
    '120': 63.7,
    '121': 63.5,
    '122': 63.4,
    '123': 63.3,
    '124': 63.2,
    '125': 63,
    '126': 62.9,
    '127': 62.8,
    '128': 62.7,
    '129': 62.6,
    '130': 62.4,
    '131': 62.3,
    '132': 62.2,
    '133': 62.1,
    '134': 62,
    '135': 61.9,
    '136': 61.7,
    '137': 61.6,
    '138': 61.5,
    '139': 61.4,
    '140': 61.3,
    '141': 61.1,
    '142': 61,
    '143': 60.9,
    '144': 60.8,
    '145': 60.7,
    '146': 60.6,
    '147': 60.5,
    '148': 60.3,
    '149': 60.2,
    '150': 60.1,
    '151': 60,
    '152': 59.9,
    '153': 59.8,
    '154': 59.7,
    '155': 59.5,
    '156': 59.4,
    '157': 59.3,
    '158': 59.2,
    '159': 59.1,
    '160': 59,
    '161': 58.9,
    '162': 58.8,
    '163': 58.6,
    '164': 58.5,
    '165': 58.4,
    '166': 58.3,
    '167': 58.2,
    '168': 58.1,
    '169': 58,
    '170': 57.9,
    '171': 57.8,
    '172': 57.7,
    '173': 57.5,
    '174': 57.4,
    '175': 57.3,
    '176': 57.2,
    '177': 57.1,
    '178': 57,
    '179': 56.9,
    '180': 56.8,
    '181': 56.7,
    '182': 56.6,
    '183': 56.5,
    '184': 56.4,
    '185': 56.3,
    '186': 56.2,
    '187': 56,
    '188': 55.9,
    '189': 55.8,
    '190': 55.7,
    '191': 55.6,
    '192': 55.5,
    '193': 55.4,
    '194': 55.3,
    '195': 55.2,
    '0.5': 80.5,
    '1.5': 80.3,
    '2.5': 80.1,
    '3.5': 80,
    '4.5': 79.8,
    '5.5': 79.7,
    '6.5': 79.5,
    '7.5': 79.3,
    '8.5': 79.2,
    '9.5': 79,
    '10.5': 78.9,
    '11.5': 78.7,
    '12.5': 78.6,
    '13.5': 78.4,
    '14.5': 78.2,
    '15.5': 78.1,
    '16.5': 77.9,
    '17.5': 77.8,
    '18.5': 77.6,
    '19.5': 77.5,
    '20.5': 77.3,
    '21.5': 77.2,
    '22.5': 77,
    '23.5': 76.8,
    '24.5': 76.7,
    '25.5': 76.5,
    '26.5': 76.4,
    '27.5': 76.2,
    '28.5': 76.1,
    '29.5': 75.9,
    '30.5': 75.8,
    '31.5': 75.6,
    '32.5': 75.5,
    '33.5': 75.3,
    '34.5': 75.2,
    '35.5': 75,
    '36.5': 74.9,
    '37.5': 74.7,
    '38.5': 74.6,
    '39.5': 74.4,
    '40.5': 74.3,
    '41.5': 74.2,
    '42.5': 74,
    '43.5': 73.9,
    '44.5': 73.7,
    '45.5': 73.6,
    '46.5': 73.4,
    '47.5': 73.3,
    '48.5': 73.1,
    '49.5': 73,
    '50.5': 72.8,
    '51.5': 72.7,
    '52.5': 72.6,
    '53.5': 72.4,
    '54.5': 72.3,
    '55.5': 72.1,
    '56.5': 72,
    '57.5': 71.9,
    '58.5': 71.7,
    '59.5': 71.6,
    '60.5': 71.4,
    '61.5': 71.3,
    '62.5': 71.1,
    '63.5': 71,
    '64.5': 70.9,
    '65.5': 70.7,
    '66.5': 70.6,
    '67.5': 70.5,
    '68.5': 70.3,
    '69.5': 70.2,
    '70.5': 70,
    '71.5': 69.9,
    '72.5': 69.8,
    '73.5': 69.6,
    '74.5': 69.5,
    '75.5': 69.4,
    '76.5': 69.2,
    '77.5': 69.1,
    '78.5': 69,
    '79.5': 68.8,
    '80.5': 68.7,
    '81.5': 68.6,
    '82.5': 68.4,
    '83.5': 68.3,
    '84.5': 68.2,
    '85.5': 68,
    '86.5': 67.9,
    '87.5': 67.8,
    '88.5': 67.6,
    '89.5': 67.5,
    '90.5': 67.4,
    '91.5': 67.2,
    '92.5': 67.1,
    '93.5': 67,
    '94.5': 66.9,
    '95.5': 66.7,
    '96.5': 66.6,
    '97.5': 66.5,
    '98.5': 66.3,
    '99.5': 66.2,
    '100.5': 66.1,
    '101.5': 66,
    '102.5': 65.8,
    '103.5': 65.7,
    '104.5': 65.6,
    '105.5': 65.4,
    '106.5': 65.3,
    '107.5': 65.2,
    '108.5': 65.1,
    '109.5': 64.9,
    '110.5': 64.8,
    '111.5': 64.7,
    '112.5': 64.6,
    '113.5': 64.4,
    '114.5': 64.3,
    '115.5': 64.2,
    '116.5': 64.1,
    '117.5': 64,
    '118.5': 63.8,
    '119.5': 63.7,
    '120.5': 63.6,
    '121.5': 63.5,
    '122.5': 63.3,
    '123.5': 63.2,
    '124.5': 63.1,
    '125.5': 63,
    '126.5': 62.9,
    '127.5': 62.7,
    '128.5': 62.6,
    '129.5': 62.5,
    '130.5': 62.4,
    '131.5': 62.3,
    '132.5': 62.1,
    '133.5': 62,
    '134.5': 61.9,
    '135.5': 61.8,
    '136.5': 61.7,
    '137.5': 61.6,
    '138.5': 61.4,
    '139.5': 61.3,
    '140.5': 61.2,
    '141.5': 61.1,
    '142.5': 61,
    '143.5': 60.9,
    '144.5': 60.7,
    '145.5': 60.6,
    '146.5': 60.5,
    '147.5': 60.4,
    '148.5': 60.3,
    '149.5': 60.2,
    '150.5': 60.1,
    '151.5': 59.9,
    '152.5': 59.8,
    '153.5': 59.7,
    '154.5': 59.6,
    '155.5': 59.5,
    '156.5': 59.4,
    '157.5': 59.3,
    '158.5': 59.1,
    '159.5': 59,
    '160.5': 58.9,
    '161.5': 58.8,
    '162.5': 58.7,
    '163.5': 58.6,
    '164.5': 58.5,
    '165.5': 58.4,
    '166.5': 58.3,
    '167.5': 58.1,
    '168.5': 58,
    '169.5': 57.9,
    '170.5': 57.8,
    '171.5': 57.7,
    '172.5': 57.6,
    '173.5': 57.5,
    '174.5': 57.4,
    '175.5': 57.3,
    '176.5': 57.2,
    '177.5': 57.1,
    '178.5': 57,
    '179.5': 56.8,
    '180.5': 56.7,
    '181.5': 56.6,
    '182.5': 56.5,
    '183.5': 56.4,
    '184.5': 56.3,
    '185.5': 56.2,
    '186.5': 56.1,
    '187.5': 56,
    '188.5': 55.9,
    '189.5': 55.8,
    '190.5': 55.7,
    '191.5': 55.6,
    '192.5': 55.5,
    '193.5': 55.4,
    '194.5': 55.3,
  },
  '72': {
    '0': 81.1,
    '1': 81,
    '2': 80.8,
    '3': 80.6,
    '4': 80.5,
    '5': 80.3,
    '6': 80.1,
    '7': 80,
    '8': 79.8,
    '9': 79.7,
    '10': 79.5,
    '11': 79.4,
    '12': 79.2,
    '13': 79,
    '14': 78.9,
    '15': 78.7,
    '16': 78.6,
    '17': 78.4,
    '18': 78.2,
    '19': 78.1,
    '20': 77.9,
    '21': 77.8,
    '22': 77.6,
    '23': 77.5,
    '24': 77.3,
    '25': 77.2,
    '26': 77,
    '27': 76.9,
    '28': 76.7,
    '29': 76.5,
    '30': 76.4,
    '31': 76.2,
    '32': 76.1,
    '33': 75.9,
    '34': 75.8,
    '35': 75.6,
    '36': 75.5,
    '37': 75.3,
    '38': 75.2,
    '39': 75,
    '40': 74.9,
    '41': 74.7,
    '42': 74.6,
    '43': 74.5,
    '44': 74.3,
    '45': 74.2,
    '46': 74,
    '47': 73.9,
    '48': 73.7,
    '49': 73.6,
    '50': 73.4,
    '51': 73.3,
    '52': 73.1,
    '53': 73,
    '54': 72.9,
    '55': 72.7,
    '56': 72.6,
    '57': 72.4,
    '58': 72.3,
    '59': 72.1,
    '60': 72,
    '61': 71.9,
    '62': 71.7,
    '63': 71.6,
    '64': 71.4,
    '65': 71.3,
    '66': 71.2,
    '67': 71,
    '68': 70.9,
    '69': 70.7,
    '70': 70.6,
    '71': 70.5,
    '72': 70.3,
    '73': 70.2,
    '74': 70.1,
    '75': 69.9,
    '76': 69.8,
    '77': 69.6,
    '78': 69.5,
    '79': 69.4,
    '80': 69.2,
    '81': 69.1,
    '82': 69,
    '83': 68.8,
    '84': 68.7,
    '85': 68.6,
    '86': 68.4,
    '87': 68.3,
    '88': 68.2,
    '89': 68,
    '90': 67.9,
    '91': 67.8,
    '92': 67.6,
    '93': 67.5,
    '94': 67.4,
    '95': 67.3,
    '96': 67.1,
    '97': 67,
    '98': 66.9,
    '99': 66.7,
    '100': 66.6,
    '101': 66.5,
    '102': 66.4,
    '103': 66.2,
    '104': 66.1,
    '105': 66,
    '106': 65.8,
    '107': 65.7,
    '108': 65.6,
    '109': 65.5,
    '110': 65.3,
    '111': 65.2,
    '112': 65.1,
    '113': 65,
    '114': 64.8,
    '115': 64.7,
    '116': 64.6,
    '117': 64.5,
    '118': 64.3,
    '119': 64.2,
    '120': 64.1,
    '121': 64,
    '122': 63.9,
    '123': 63.7,
    '124': 63.6,
    '125': 63.5,
    '126': 63.4,
    '127': 63.2,
    '128': 63.1,
    '129': 63,
    '130': 62.9,
    '131': 62.8,
    '132': 62.6,
    '133': 62.5,
    '134': 62.4,
    '135': 62.3,
    '136': 62.2,
    '137': 62,
    '138': 61.9,
    '139': 61.8,
    '140': 61.7,
    '141': 61.6,
    '142': 61.5,
    '143': 61.3,
    '144': 61.2,
    '145': 61.1,
    '146': 61,
    '147': 60.9,
    '148': 60.8,
    '149': 60.6,
    '150': 60.5,
    '151': 60.4,
    '152': 60.3,
    '153': 60.2,
    '154': 60.1,
    '155': 60,
    '156': 59.8,
    '157': 59.7,
    '158': 59.6,
    '159': 59.5,
    '160': 59.4,
    '161': 59.3,
    '162': 59.2,
    '163': 59.1,
    '164': 58.9,
    '165': 58.8,
    '166': 58.7,
    '167': 58.6,
    '168': 58.5,
    '169': 58.4,
    '170': 58.3,
    '171': 58.2,
    '172': 58.1,
    '173': 58,
    '174': 57.8,
    '175': 57.7,
    '176': 57.6,
    '177': 57.5,
    '178': 57.4,
    '179': 57.3,
    '180': 57.2,
    '181': 57.1,
    '182': 57,
    '183': 56.9,
    '184': 56.8,
    '185': 56.7,
    '186': 56.5,
    '187': 56.4,
    '188': 56.3,
    '189': 56.2,
    '190': 56.1,
    '191': 56,
    '192': 55.9,
    '193': 55.8,
    '194': 55.7,
    '195': 55.6,
    '0.5': 81,
    '1.5': 80.9,
    '2.5': 80.7,
    '3.5': 80.6,
    '4.5': 80.4,
    '5.5': 80.2,
    '6.5': 80.1,
    '7.5': 79.9,
    '8.5': 79.7,
    '9.5': 79.6,
    '10.5': 79.4,
    '11.5': 79.3,
    '12.5': 79.1,
    '13.5': 79,
    '14.5': 78.8,
    '15.5': 78.6,
    '16.5': 78.5,
    '17.5': 78.3,
    '18.5': 78.2,
    '19.5': 78,
    '20.5': 77.9,
    '21.5': 77.7,
    '22.5': 77.5,
    '23.5': 77.4,
    '24.5': 77.2,
    '25.5': 77.1,
    '26.5': 76.9,
    '27.5': 76.8,
    '28.5': 76.6,
    '29.5': 76.5,
    '30.5': 76.3,
    '31.5': 76.2,
    '32.5': 76,
    '33.5': 75.9,
    '34.5': 75.7,
    '35.5': 75.6,
    '36.5': 75.4,
    '37.5': 75.3,
    '38.5': 75.1,
    '39.5': 75,
    '40.5': 74.8,
    '41.5': 74.7,
    '42.5': 74.5,
    '43.5': 74.4,
    '44.5': 74.2,
    '45.5': 74.1,
    '46.5': 73.9,
    '47.5': 73.8,
    '48.5': 73.6,
    '49.5': 73.5,
    '50.5': 73.4,
    '51.5': 73.2,
    '52.5': 73.1,
    '53.5': 72.9,
    '54.5': 72.8,
    '55.5': 72.6,
    '56.5': 72.5,
    '57.5': 72.4,
    '58.5': 72.2,
    '59.5': 72.1,
    '60.5': 71.9,
    '61.5': 71.8,
    '62.5': 71.6,
    '63.5': 71.5,
    '64.5': 71.4,
    '65.5': 71.2,
    '66.5': 71.1,
    '67.5': 71,
    '68.5': 70.8,
    '69.5': 70.7,
    '70.5': 70.5,
    '71.5': 70.4,
    '72.5': 70.3,
    '73.5': 70.1,
    '74.5': 70,
    '75.5': 69.9,
    '76.5': 69.7,
    '77.5': 69.6,
    '78.5': 69.4,
    '79.5': 69.3,
    '80.5': 69.2,
    '81.5': 69,
    '82.5': 68.9,
    '83.5': 68.8,
    '84.5': 68.6,
    '85.5': 68.5,
    '86.5': 68.4,
    '87.5': 68.2,
    '88.5': 68.1,
    '89.5': 68,
    '90.5': 67.8,
    '91.5': 67.7,
    '92.5': 67.6,
    '93.5': 67.5,
    '94.5': 67.3,
    '95.5': 67.2,
    '96.5': 67.1,
    '97.5': 66.9,
    '98.5': 66.8,
    '99.5': 66.7,
    '100.5': 66.5,
    '101.5': 66.4,
    '102.5': 66.3,
    '103.5': 66.2,
    '104.5': 66,
    '105.5': 65.9,
    '106.5': 65.8,
    '107.5': 65.7,
    '108.5': 65.5,
    '109.5': 65.4,
    '110.5': 65.3,
    '111.5': 65.2,
    '112.5': 65,
    '113.5': 64.9,
    '114.5': 64.8,
    '115.5': 64.7,
    '116.5': 64.5,
    '117.5': 64.4,
    '118.5': 64.3,
    '119.5': 64.2,
    '120.5': 64,
    '121.5': 63.9,
    '122.5': 63.8,
    '123.5': 63.7,
    '124.5': 63.5,
    '125.5': 63.4,
    '126.5': 63.3,
    '127.5': 63.2,
    '128.5': 63.1,
    '129.5': 62.9,
    '130.5': 62.8,
    '131.5': 62.7,
    '132.5': 62.6,
    '133.5': 62.5,
    '134.5': 62.3,
    '135.5': 62.2,
    '136.5': 62.1,
    '137.5': 62,
    '138.5': 61.9,
    '139.5': 61.7,
    '140.5': 61.6,
    '141.5': 61.5,
    '142.5': 61.4,
    '143.5': 61.3,
    '144.5': 61.2,
    '145.5': 61,
    '146.5': 60.9,
    '147.5': 60.8,
    '148.5': 60.7,
    '149.5': 60.6,
    '150.5': 60.5,
    '151.5': 60.4,
    '152.5': 60.2,
    '153.5': 60.1,
    '154.5': 60,
    '155.5': 59.9,
    '156.5': 59.8,
    '157.5': 59.7,
    '158.5': 59.6,
    '159.5': 59.4,
    '160.5': 59.3,
    '161.5': 59.2,
    '162.5': 59.1,
    '163.5': 59,
    '164.5': 58.9,
    '165.5': 58.8,
    '166.5': 58.7,
    '167.5': 58.6,
    '168.5': 58.4,
    '169.5': 58.3,
    '170.5': 58.2,
    '171.5': 58.1,
    '172.5': 58,
    '173.5': 57.9,
    '174.5': 57.8,
    '175.5': 57.7,
    '176.5': 57.6,
    '177.5': 57.5,
    '178.5': 57.4,
    '179.5': 57.2,
    '180.5': 57.1,
    '181.5': 57,
    '182.5': 56.9,
    '183.5': 56.8,
    '184.5': 56.7,
    '185.5': 56.6,
    '186.5': 56.5,
    '187.5': 56.4,
    '188.5': 56.3,
    '189.5': 56.2,
    '190.5': 56.1,
    '191.5': 56,
    '192.5': 55.9,
    '193.5': 55.8,
    '194.5': 55.7,
  },
  '72.5': {
    '0': 81.7,
    '1': 81.5,
    '2': 81.4,
    '3': 81.2,
    '4': 81,
    '5': 80.9,
    '6': 80.7,
    '7': 80.5,
    '8': 80.4,
    '9': 80.2,
    '10': 80.1,
    '11': 79.9,
    '12': 79.7,
    '13': 79.6,
    '14': 79.4,
    '15': 79.3,
    '16': 79.1,
    '17': 79,
    '18': 78.8,
    '19': 78.6,
    '20': 78.5,
    '21': 78.3,
    '22': 78.2,
    '23': 78,
    '24': 77.9,
    '25': 77.7,
    '26': 77.5,
    '27': 77.4,
    '28': 77.2,
    '29': 77.1,
    '30': 76.9,
    '31': 76.8,
    '32': 76.6,
    '33': 76.5,
    '34': 76.3,
    '35': 76.2,
    '36': 76,
    '37': 75.9,
    '38': 75.7,
    '39': 75.6,
    '40': 75.4,
    '41': 75.3,
    '42': 75.1,
    '43': 75,
    '44': 74.8,
    '45': 74.7,
    '46': 74.5,
    '47': 74.4,
    '48': 74.2,
    '49': 74.1,
    '50': 73.9,
    '51': 73.8,
    '52': 73.6,
    '53': 73.5,
    '54': 73.4,
    '55': 73.2,
    '56': 73.1,
    '57': 72.9,
    '58': 72.8,
    '59': 72.6,
    '60': 72.5,
    '61': 72.4,
    '62': 72.2,
    '63': 72.1,
    '64': 71.9,
    '65': 71.8,
    '66': 71.7,
    '67': 71.5,
    '68': 71.4,
    '69': 71.2,
    '70': 71.1,
    '71': 71,
    '72': 70.8,
    '73': 70.7,
    '74': 70.5,
    '75': 70.4,
    '76': 70.3,
    '77': 70.1,
    '78': 70,
    '79': 69.9,
    '80': 69.7,
    '81': 69.6,
    '82': 69.5,
    '83': 69.3,
    '84': 69.2,
    '85': 69,
    '86': 68.9,
    '87': 68.8,
    '88': 68.6,
    '89': 68.5,
    '90': 68.4,
    '91': 68.2,
    '92': 68.1,
    '93': 68,
    '94': 67.9,
    '95': 67.7,
    '96': 67.6,
    '97': 67.5,
    '98': 67.3,
    '99': 67.2,
    '100': 67.1,
    '101': 66.9,
    '102': 66.8,
    '103': 66.7,
    '104': 66.6,
    '105': 66.4,
    '106': 66.3,
    '107': 66.2,
    '108': 66,
    '109': 65.9,
    '110': 65.8,
    '111': 65.7,
    '112': 65.5,
    '113': 65.4,
    '114': 65.3,
    '115': 65.2,
    '116': 65,
    '117': 64.9,
    '118': 64.8,
    '119': 64.7,
    '120': 64.5,
    '121': 64.4,
    '122': 64.3,
    '123': 64.2,
    '124': 64,
    '125': 63.9,
    '126': 63.8,
    '127': 63.7,
    '128': 63.6,
    '129': 63.4,
    '130': 63.3,
    '131': 63.2,
    '132': 63.1,
    '133': 63,
    '134': 62.8,
    '135': 62.7,
    '136': 62.6,
    '137': 62.5,
    '138': 62.4,
    '139': 62.2,
    '140': 62.1,
    '141': 62,
    '142': 61.9,
    '143': 61.8,
    '144': 61.6,
    '145': 61.5,
    '146': 61.4,
    '147': 61.3,
    '148': 61.2,
    '149': 61.1,
    '150': 60.9,
    '151': 60.8,
    '152': 60.7,
    '153': 60.6,
    '154': 60.5,
    '155': 60.4,
    '156': 60.3,
    '157': 60.1,
    '158': 60,
    '159': 59.9,
    '160': 59.8,
    '161': 59.7,
    '162': 59.6,
    '163': 59.5,
    '164': 59.4,
    '165': 59.2,
    '166': 59.1,
    '167': 59,
    '168': 58.9,
    '169': 58.8,
    '170': 58.7,
    '171': 58.6,
    '172': 58.5,
    '173': 58.4,
    '174': 58.2,
    '175': 58.1,
    '176': 58,
    '177': 57.9,
    '178': 57.8,
    '179': 57.7,
    '180': 57.6,
    '181': 57.5,
    '182': 57.4,
    '183': 57.3,
    '184': 57.2,
    '185': 57.1,
    '186': 56.9,
    '187': 56.8,
    '188': 56.7,
    '189': 56.6,
    '190': 56.5,
    '191': 56.4,
    '192': 56.3,
    '193': 56.2,
    '194': 56.1,
    '195': 56,
    '0.5': 81.6,
    '1.5': 81.4,
    '2.5': 81.3,
    '3.5': 81.1,
    '4.5': 81,
    '5.5': 80.8,
    '6.5': 80.6,
    '7.5': 80.5,
    '8.5': 80.3,
    '9.5': 80.1,
    '10.5': 80,
    '11.5': 79.8,
    '12.5': 79.7,
    '13.5': 79.5,
    '14.5': 79.3,
    '15.5': 79.2,
    '16.5': 79,
    '17.5': 78.9,
    '18.5': 78.7,
    '19.5': 78.6,
    '20.5': 78.4,
    '21.5': 78.2,
    '22.5': 78.1,
    '23.5': 77.9,
    '24.5': 77.8,
    '25.5': 77.6,
    '26.5': 77.5,
    '27.5': 77.3,
    '28.5': 77.2,
    '29.5': 77,
    '30.5': 76.9,
    '31.5': 76.7,
    '32.5': 76.5,
    '33.5': 76.4,
    '34.5': 76.2,
    '35.5': 76.1,
    '36.5': 75.9,
    '37.5': 75.8,
    '38.5': 75.6,
    '39.5': 75.5,
    '40.5': 75.3,
    '41.5': 75.2,
    '42.5': 75,
    '43.5': 74.9,
    '44.5': 74.7,
    '45.5': 74.6,
    '46.5': 74.5,
    '47.5': 74.3,
    '48.5': 74.2,
    '49.5': 74,
    '50.5': 73.9,
    '51.5': 73.7,
    '52.5': 73.6,
    '53.5': 73.4,
    '54.5': 73.3,
    '55.5': 73.1,
    '56.5': 73,
    '57.5': 72.9,
    '58.5': 72.7,
    '59.5': 72.6,
    '60.5': 72.4,
    '61.5': 72.3,
    '62.5': 72.1,
    '63.5': 72,
    '64.5': 71.9,
    '65.5': 71.7,
    '66.5': 71.6,
    '67.5': 71.4,
    '68.5': 71.3,
    '69.5': 71.2,
    '70.5': 71,
    '71.5': 70.9,
    '72.5': 70.7,
    '73.5': 70.6,
    '74.5': 70.5,
    '75.5': 70.3,
    '76.5': 70.2,
    '77.5': 70.1,
    '78.5': 69.9,
    '79.5': 69.8,
    '80.5': 69.7,
    '81.5': 69.5,
    '82.5': 69.4,
    '83.5': 69.2,
    '84.5': 69.1,
    '85.5': 69,
    '86.5': 68.8,
    '87.5': 68.7,
    '88.5': 68.6,
    '89.5': 68.4,
    '90.5': 68.3,
    '91.5': 68.2,
    '92.5': 68,
    '93.5': 67.9,
    '94.5': 67.8,
    '95.5': 67.7,
    '96.5': 67.5,
    '97.5': 67.4,
    '98.5': 67.3,
    '99.5': 67.1,
    '100.5': 67,
    '101.5': 66.9,
    '102.5': 66.7,
    '103.5': 66.6,
    '104.5': 66.5,
    '105.5': 66.4,
    '106.5': 66.2,
    '107.5': 66.1,
    '108.5': 66,
    '109.5': 65.9,
    '110.5': 65.7,
    '111.5': 65.6,
    '112.5': 65.5,
    '113.5': 65.4,
    '114.5': 65.2,
    '115.5': 65.1,
    '116.5': 65,
    '117.5': 64.8,
    '118.5': 64.7,
    '119.5': 64.6,
    '120.5': 64.5,
    '121.5': 64.4,
    '122.5': 64.2,
    '123.5': 64.1,
    '124.5': 64,
    '125.5': 63.9,
    '126.5': 63.7,
    '127.5': 63.6,
    '128.5': 63.5,
    '129.5': 63.4,
    '130.5': 63.3,
    '131.5': 63.1,
    '132.5': 63,
    '133.5': 62.9,
    '134.5': 62.8,
    '135.5': 62.7,
    '136.5': 62.5,
    '137.5': 62.4,
    '138.5': 62.3,
    '139.5': 62.2,
    '140.5': 62.1,
    '141.5': 61.9,
    '142.5': 61.8,
    '143.5': 61.7,
    '144.5': 61.6,
    '145.5': 61.5,
    '146.5': 61.4,
    '147.5': 61.2,
    '148.5': 61.1,
    '149.5': 61,
    '150.5': 60.9,
    '151.5': 60.8,
    '152.5': 60.7,
    '153.5': 60.5,
    '154.5': 60.4,
    '155.5': 60.3,
    '156.5': 60.2,
    '157.5': 60.1,
    '158.5': 60,
    '159.5': 59.9,
    '160.5': 59.7,
    '161.5': 59.6,
    '162.5': 59.5,
    '163.5': 59.4,
    '164.5': 59.3,
    '165.5': 59.2,
    '166.5': 59.1,
    '167.5': 59,
    '168.5': 58.9,
    '169.5': 58.7,
    '170.5': 58.6,
    '171.5': 58.5,
    '172.5': 58.4,
    '173.5': 58.3,
    '174.5': 58.2,
    '175.5': 58.1,
    '176.5': 58,
    '177.5': 57.9,
    '178.5': 57.8,
    '179.5': 57.6,
    '180.5': 57.5,
    '181.5': 57.4,
    '182.5': 57.3,
    '183.5': 57.2,
    '184.5': 57.1,
    '185.5': 57,
    '186.5': 56.9,
    '187.5': 56.8,
    '188.5': 56.7,
    '189.5': 56.6,
    '190.5': 56.5,
    '191.5': 56.4,
    '192.5': 56.3,
    '193.5': 56.1,
    '194.5': 56,
  },
  '73': {
    '0': 82.3,
    '1': 82.1,
    '2': 81.9,
    '3': 81.8,
    '4': 81.6,
    '5': 81.4,
    '6': 81.3,
    '7': 81.1,
    '8': 80.9,
    '9': 80.8,
    '10': 80.6,
    '11': 80.5,
    '12': 80.3,
    '13': 80.1,
    '14': 80,
    '15': 79.8,
    '16': 79.7,
    '17': 79.5,
    '18': 79.3,
    '19': 79.2,
    '20': 79,
    '21': 78.9,
    '22': 78.7,
    '23': 78.5,
    '24': 78.4,
    '25': 78.2,
    '26': 78.1,
    '27': 77.9,
    '28': 77.8,
    '29': 77.6,
    '30': 77.5,
    '31': 77.3,
    '32': 77.2,
    '33': 77,
    '34': 76.8,
    '35': 76.7,
    '36': 76.5,
    '37': 76.4,
    '38': 76.2,
    '39': 76.1,
    '40': 75.9,
    '41': 75.8,
    '42': 75.6,
    '43': 75.5,
    '44': 75.3,
    '45': 75.2,
    '46': 75,
    '47': 74.9,
    '48': 74.7,
    '49': 74.6,
    '50': 74.5,
    '51': 74.3,
    '52': 74.2,
    '53': 74,
    '54': 73.9,
    '55': 73.7,
    '56': 73.6,
    '57': 73.4,
    '58': 73.3,
    '59': 73.1,
    '60': 73,
    '61': 72.9,
    '62': 72.7,
    '63': 72.6,
    '64': 72.4,
    '65': 72.3,
    '66': 72.1,
    '67': 72,
    '68': 71.9,
    '69': 71.7,
    '70': 71.6,
    '71': 71.4,
    '72': 71.3,
    '73': 71.2,
    '74': 71,
    '75': 70.9,
    '76': 70.7,
    '77': 70.6,
    '78': 70.5,
    '79': 70.3,
    '80': 70.2,
    '81': 70.1,
    '82': 69.9,
    '83': 69.8,
    '84': 69.7,
    '85': 69.5,
    '86': 69.4,
    '87': 69.3,
    '88': 69.1,
    '89': 69,
    '90': 68.8,
    '91': 68.7,
    '92': 68.6,
    '93': 68.4,
    '94': 68.3,
    '95': 68.2,
    '96': 68.1,
    '97': 67.9,
    '98': 67.8,
    '99': 67.7,
    '100': 67.5,
    '101': 67.4,
    '102': 67.3,
    '103': 67.1,
    '104': 67,
    '105': 66.9,
    '106': 66.8,
    '107': 66.6,
    '108': 66.5,
    '109': 66.4,
    '110': 66.2,
    '111': 66.1,
    '112': 66,
    '113': 65.9,
    '114': 65.7,
    '115': 65.6,
    '116': 65.5,
    '117': 65.4,
    '118': 65.2,
    '119': 65.1,
    '120': 65,
    '121': 64.9,
    '122': 64.7,
    '123': 64.6,
    '124': 64.5,
    '125': 64.4,
    '126': 64.2,
    '127': 64.1,
    '128': 64,
    '129': 63.9,
    '130': 63.8,
    '131': 63.6,
    '132': 63.5,
    '133': 63.4,
    '134': 63.3,
    '135': 63.1,
    '136': 63,
    '137': 62.9,
    '138': 62.8,
    '139': 62.7,
    '140': 62.5,
    '141': 62.4,
    '142': 62.3,
    '143': 62.2,
    '144': 62.1,
    '145': 62,
    '146': 61.8,
    '147': 61.7,
    '148': 61.6,
    '149': 61.5,
    '150': 61.4,
    '151': 61.3,
    '152': 61.1,
    '153': 61,
    '154': 60.9,
    '155': 60.8,
    '156': 60.7,
    '157': 60.6,
    '158': 60.4,
    '159': 60.3,
    '160': 60.2,
    '161': 60.1,
    '162': 60,
    '163': 59.9,
    '164': 59.8,
    '165': 59.6,
    '166': 59.5,
    '167': 59.4,
    '168': 59.3,
    '169': 59.2,
    '170': 59.1,
    '171': 59,
    '172': 58.9,
    '173': 58.8,
    '174': 58.6,
    '175': 58.5,
    '176': 58.4,
    '177': 58.3,
    '178': 58.2,
    '179': 58.1,
    '180': 58,
    '181': 57.9,
    '182': 57.8,
    '183': 57.7,
    '184': 57.5,
    '185': 57.4,
    '186': 57.3,
    '187': 57.2,
    '188': 57.1,
    '189': 57,
    '190': 56.9,
    '191': 56.8,
    '192': 56.7,
    '193': 56.6,
    '194': 56.5,
    '195': 56.4,
    '0.5': 82.2,
    '1.5': 82,
    '2.5': 81.8,
    '3.5': 81.7,
    '4.5': 81.5,
    '5.5': 81.4,
    '6.5': 81.2,
    '7.5': 81,
    '8.5': 80.9,
    '9.5': 80.7,
    '10.5': 80.5,
    '11.5': 80.4,
    '12.5': 80.2,
    '13.5': 80.1,
    '14.5': 79.9,
    '15.5': 79.7,
    '16.5': 79.6,
    '17.5': 79.4,
    '18.5': 79.3,
    '19.5': 79.1,
    '20.5': 78.9,
    '21.5': 78.8,
    '22.5': 78.6,
    '23.5': 78.5,
    '24.5': 78.3,
    '25.5': 78.2,
    '26.5': 78,
    '27.5': 77.8,
    '28.5': 77.7,
    '29.5': 77.5,
    '30.5': 77.4,
    '31.5': 77.2,
    '32.5': 77.1,
    '33.5': 76.9,
    '34.5': 76.8,
    '35.5': 76.6,
    '36.5': 76.5,
    '37.5': 76.3,
    '38.5': 76.2,
    '39.5': 76,
    '40.5': 75.9,
    '41.5': 75.7,
    '42.5': 75.6,
    '43.5': 75.4,
    '44.5': 75.3,
    '45.5': 75.1,
    '46.5': 75,
    '47.5': 74.8,
    '48.5': 74.7,
    '49.5': 74.5,
    '50.5': 74.4,
    '51.5': 74.2,
    '52.5': 74.1,
    '53.5': 73.9,
    '54.5': 73.8,
    '55.5': 73.6,
    '56.5': 73.5,
    '57.5': 73.4,
    '58.5': 73.2,
    '59.5': 73.1,
    '60.5': 72.9,
    '61.5': 72.8,
    '62.5': 72.6,
    '63.5': 72.5,
    '64.5': 72.4,
    '65.5': 72.2,
    '66.5': 72.1,
    '67.5': 71.9,
    '68.5': 71.8,
    '69.5': 71.7,
    '70.5': 71.5,
    '71.5': 71.4,
    '72.5': 71.2,
    '73.5': 71.1,
    '74.5': 71,
    '75.5': 70.8,
    '76.5': 70.7,
    '77.5': 70.5,
    '78.5': 70.4,
    '79.5': 70.3,
    '80.5': 70.1,
    '81.5': 70,
    '82.5': 69.9,
    '83.5': 69.7,
    '84.5': 69.6,
    '85.5': 69.5,
    '86.5': 69.3,
    '87.5': 69.2,
    '88.5': 69.1,
    '89.5': 68.9,
    '90.5': 68.8,
    '91.5': 68.6,
    '92.5': 68.5,
    '93.5': 68.4,
    '94.5': 68.3,
    '95.5': 68.1,
    '96.5': 68,
    '97.5': 67.9,
    '98.5': 67.7,
    '99.5': 67.6,
    '100.5': 67.5,
    '101.5': 67.3,
    '102.5': 67.2,
    '103.5': 67.1,
    '104.5': 66.9,
    '105.5': 66.8,
    '106.5': 66.7,
    '107.5': 66.6,
    '108.5': 66.4,
    '109.5': 66.3,
    '110.5': 66.2,
    '111.5': 66.1,
    '112.5': 65.9,
    '113.5': 65.8,
    '114.5': 65.7,
    '115.5': 65.5,
    '116.5': 65.4,
    '117.5': 65.3,
    '118.5': 65.2,
    '119.5': 65,
    '120.5': 64.9,
    '121.5': 64.8,
    '122.5': 64.7,
    '123.5': 64.5,
    '124.5': 64.4,
    '125.5': 64.3,
    '126.5': 64.2,
    '127.5': 64.1,
    '128.5': 63.9,
    '129.5': 63.8,
    '130.5': 63.7,
    '131.5': 63.6,
    '132.5': 63.4,
    '133.5': 63.3,
    '134.5': 63.2,
    '135.5': 63.1,
    '136.5': 63,
    '137.5': 62.8,
    '138.5': 62.7,
    '139.5': 62.6,
    '140.5': 62.5,
    '141.5': 62.4,
    '142.5': 62.3,
    '143.5': 62.1,
    '144.5': 62,
    '145.5': 61.9,
    '146.5': 61.8,
    '147.5': 61.7,
    '148.5': 61.5,
    '149.5': 61.4,
    '150.5': 61.3,
    '151.5': 61.2,
    '152.5': 61.1,
    '153.5': 61,
    '154.5': 60.8,
    '155.5': 60.7,
    '156.5': 60.6,
    '157.5': 60.5,
    '158.5': 60.4,
    '159.5': 60.3,
    '160.5': 60.2,
    '161.5': 60,
    '162.5': 59.9,
    '163.5': 59.8,
    '164.5': 59.7,
    '165.5': 59.6,
    '166.5': 59.5,
    '167.5': 59.4,
    '168.5': 59.3,
    '169.5': 59.1,
    '170.5': 59,
    '171.5': 58.9,
    '172.5': 58.8,
    '173.5': 58.7,
    '174.5': 58.6,
    '175.5': 58.5,
    '176.5': 58.4,
    '177.5': 58.3,
    '178.5': 58.2,
    '179.5': 58,
    '180.5': 57.9,
    '181.5': 57.8,
    '182.5': 57.7,
    '183.5': 57.6,
    '184.5': 57.5,
    '185.5': 57.4,
    '186.5': 57.3,
    '187.5': 57.2,
    '188.5': 57.1,
    '189.5': 57,
    '190.5': 56.9,
    '191.5': 56.7,
    '192.5': 56.6,
    '193.5': 56.5,
    '194.5': 56.4,
  },
  '73.5': {
    '0': 82.8,
    '1': 82.7,
    '2': 82.5,
    '3': 82.3,
    '4': 82.2,
    '5': 82,
    '6': 81.8,
    '7': 81.7,
    '8': 81.5,
    '9': 81.3,
    '10': 81.2,
    '11': 81,
    '12': 80.9,
    '13': 80.7,
    '14': 80.5,
    '15': 80.4,
    '16': 80.2,
    '17': 80.1,
    '18': 79.9,
    '19': 79.7,
    '20': 79.6,
    '21': 79.4,
    '22': 79.3,
    '23': 79.1,
    '24': 78.9,
    '25': 78.8,
    '26': 78.6,
    '27': 78.5,
    '28': 78.3,
    '29': 78.2,
    '30': 78,
    '31': 77.8,
    '32': 77.7,
    '33': 77.5,
    '34': 77.4,
    '35': 77.2,
    '36': 77.1,
    '37': 76.9,
    '38': 76.8,
    '39': 76.6,
    '40': 76.5,
    '41': 76.3,
    '42': 76.2,
    '43': 76,
    '44': 75.9,
    '45': 75.7,
    '46': 75.6,
    '47': 75.4,
    '48': 75.3,
    '49': 75.1,
    '50': 75,
    '51': 74.8,
    '52': 74.7,
    '53': 74.5,
    '54': 74.4,
    '55': 74.2,
    '56': 74.1,
    '57': 73.9,
    '58': 73.8,
    '59': 73.6,
    '60': 73.5,
    '61': 73.4,
    '62': 73.2,
    '63': 73.1,
    '64': 72.9,
    '65': 72.8,
    '66': 72.6,
    '67': 72.5,
    '68': 72.4,
    '69': 72.2,
    '70': 72.1,
    '71': 71.9,
    '72': 71.8,
    '73': 71.7,
    '74': 71.5,
    '75': 71.4,
    '76': 71.2,
    '77': 71.1,
    '78': 71,
    '79': 70.8,
    '80': 70.7,
    '81': 70.5,
    '82': 70.4,
    '83': 70.3,
    '84': 70.1,
    '85': 70,
    '86': 69.9,
    '87': 69.7,
    '88': 69.6,
    '89': 69.5,
    '90': 69.3,
    '91': 69.2,
    '92': 69.1,
    '93': 68.9,
    '94': 68.8,
    '95': 68.7,
    '96': 68.5,
    '97': 68.4,
    '98': 68.3,
    '99': 68.1,
    '100': 68,
    '101': 67.9,
    '102': 67.7,
    '103': 67.6,
    '104': 67.5,
    '105': 67.3,
    '106': 67.2,
    '107': 67.1,
    '108': 67,
    '109': 66.8,
    '110': 66.7,
    '111': 66.6,
    '112': 66.4,
    '113': 66.3,
    '114': 66.2,
    '115': 66.1,
    '116': 65.9,
    '117': 65.8,
    '118': 65.7,
    '119': 65.5,
    '120': 65.4,
    '121': 65.3,
    '122': 65.2,
    '123': 65,
    '124': 64.9,
    '125': 64.8,
    '126': 64.7,
    '127': 64.6,
    '128': 64.4,
    '129': 64.3,
    '130': 64.2,
    '131': 64.1,
    '132': 63.9,
    '133': 63.8,
    '134': 63.7,
    '135': 63.6,
    '136': 63.5,
    '137': 63.3,
    '138': 63.2,
    '139': 63.1,
    '140': 63,
    '141': 62.9,
    '142': 62.7,
    '143': 62.6,
    '144': 62.5,
    '145': 62.4,
    '146': 62.3,
    '147': 62.1,
    '148': 62,
    '149': 61.9,
    '150': 61.8,
    '151': 61.7,
    '152': 61.6,
    '153': 61.4,
    '154': 61.3,
    '155': 61.2,
    '156': 61.1,
    '157': 61,
    '158': 60.9,
    '159': 60.7,
    '160': 60.6,
    '161': 60.5,
    '162': 60.4,
    '163': 60.3,
    '164': 60.2,
    '165': 60.1,
    '166': 59.9,
    '167': 59.8,
    '168': 59.7,
    '169': 59.6,
    '170': 59.5,
    '171': 59.4,
    '172': 59.3,
    '173': 59.2,
    '174': 59,
    '175': 58.9,
    '176': 58.8,
    '177': 58.7,
    '178': 58.6,
    '179': 58.5,
    '180': 58.4,
    '181': 58.3,
    '182': 58.2,
    '183': 58.1,
    '184': 57.9,
    '185': 57.8,
    '186': 57.7,
    '187': 57.6,
    '188': 57.5,
    '189': 57.4,
    '190': 57.3,
    '191': 57.2,
    '192': 57.1,
    '193': 57,
    '194': 56.9,
    '195': 56.8,
    '0.5': 82.8,
    '1.5': 82.6,
    '2.5': 82.4,
    '3.5': 82.3,
    '4.5': 82.1,
    '5.5': 81.9,
    '6.5': 81.8,
    '7.5': 81.6,
    '8.5': 81.4,
    '9.5': 81.3,
    '10.5': 81.1,
    '11.5': 80.9,
    '12.5': 80.8,
    '13.5': 80.6,
    '14.5': 80.5,
    '15.5': 80.3,
    '16.5': 80.1,
    '17.5': 80,
    '18.5': 79.8,
    '19.5': 79.6,
    '20.5': 79.5,
    '21.5': 79.3,
    '22.5': 79.2,
    '23.5': 79,
    '24.5': 78.9,
    '25.5': 78.7,
    '26.5': 78.5,
    '27.5': 78.4,
    '28.5': 78.2,
    '29.5': 78.1,
    '30.5': 77.9,
    '31.5': 77.8,
    '32.5': 77.6,
    '33.5': 77.5,
    '34.5': 77.3,
    '35.5': 77.1,
    '36.5': 77,
    '37.5': 76.8,
    '38.5': 76.7,
    '39.5': 76.5,
    '40.5': 76.4,
    '41.5': 76.2,
    '42.5': 76.1,
    '43.5': 75.9,
    '44.5': 75.8,
    '45.5': 75.6,
    '46.5': 75.5,
    '47.5': 75.3,
    '48.5': 75.2,
    '49.5': 75,
    '50.5': 74.9,
    '51.5': 74.7,
    '52.5': 74.6,
    '53.5': 74.4,
    '54.5': 74.3,
    '55.5': 74.2,
    '56.5': 74,
    '57.5': 73.9,
    '58.5': 73.7,
    '59.5': 73.6,
    '60.5': 73.4,
    '61.5': 73.3,
    '62.5': 73.1,
    '63.5': 73,
    '64.5': 72.9,
    '65.5': 72.7,
    '66.5': 72.6,
    '67.5': 72.4,
    '68.5': 72.3,
    '69.5': 72.1,
    '70.5': 72,
    '71.5': 71.9,
    '72.5': 71.7,
    '73.5': 71.6,
    '74.5': 71.4,
    '75.5': 71.3,
    '76.5': 71.2,
    '77.5': 71,
    '78.5': 70.9,
    '79.5': 70.7,
    '80.5': 70.6,
    '81.5': 70.5,
    '82.5': 70.3,
    '83.5': 70.2,
    '84.5': 70.1,
    '85.5': 69.9,
    '86.5': 69.8,
    '87.5': 69.7,
    '88.5': 69.5,
    '89.5': 69.4,
    '90.5': 69.3,
    '91.5': 69.1,
    '92.5': 69,
    '93.5': 68.9,
    '94.5': 68.7,
    '95.5': 68.6,
    '96.5': 68.5,
    '97.5': 68.3,
    '98.5': 68.2,
    '99.5': 68.1,
    '100.5': 67.9,
    '101.5': 67.8,
    '102.5': 67.7,
    '103.5': 67.5,
    '104.5': 67.4,
    '105.5': 67.3,
    '106.5': 67.1,
    '107.5': 67,
    '108.5': 66.9,
    '109.5': 66.8,
    '110.5': 66.6,
    '111.5': 66.5,
    '112.5': 66.4,
    '113.5': 66.2,
    '114.5': 66.1,
    '115.5': 66,
    '116.5': 65.9,
    '117.5': 65.7,
    '118.5': 65.6,
    '119.5': 65.5,
    '120.5': 65.4,
    '121.5': 65.2,
    '122.5': 65.1,
    '123.5': 65,
    '124.5': 64.9,
    '125.5': 64.7,
    '126.5': 64.6,
    '127.5': 64.5,
    '128.5': 64.4,
    '129.5': 64.2,
    '130.5': 64.1,
    '131.5': 64,
    '132.5': 63.9,
    '133.5': 63.8,
    '134.5': 63.6,
    '135.5': 63.5,
    '136.5': 63.4,
    '137.5': 63.3,
    '138.5': 63.2,
    '139.5': 63,
    '140.5': 62.9,
    '141.5': 62.8,
    '142.5': 62.7,
    '143.5': 62.6,
    '144.5': 62.4,
    '145.5': 62.3,
    '146.5': 62.2,
    '147.5': 62.1,
    '148.5': 62,
    '149.5': 61.8,
    '150.5': 61.7,
    '151.5': 61.6,
    '152.5': 61.5,
    '153.5': 61.4,
    '154.5': 61.3,
    '155.5': 61.1,
    '156.5': 61,
    '157.5': 60.9,
    '158.5': 60.8,
    '159.5': 60.7,
    '160.5': 60.6,
    '161.5': 60.5,
    '162.5': 60.3,
    '163.5': 60.2,
    '164.5': 60.1,
    '165.5': 60,
    '166.5': 59.9,
    '167.5': 59.8,
    '168.5': 59.7,
    '169.5': 59.6,
    '170.5': 59.4,
    '171.5': 59.3,
    '172.5': 59.2,
    '173.5': 59.1,
    '174.5': 59,
    '175.5': 58.9,
    '176.5': 58.8,
    '177.5': 58.7,
    '178.5': 58.5,
    '179.5': 58.4,
    '180.5': 58.3,
    '181.5': 58.2,
    '182.5': 58.1,
    '183.5': 58,
    '184.5': 57.9,
    '185.5': 57.8,
    '186.5': 57.7,
    '187.5': 57.6,
    '188.5': 57.5,
    '189.5': 57.4,
    '190.5': 57.2,
    '191.5': 57.1,
    '192.5': 57,
    '193.5': 56.9,
    '194.5': 56.8,
  },
  '74': {
    '0': 83.4,
    '1': 83.2,
    '2': 83.1,
    '3': 82.9,
    '4': 82.7,
    '5': 82.6,
    '6': 82.4,
    '7': 82.2,
    '8': 82.1,
    '9': 81.9,
    '10': 81.7,
    '11': 81.6,
    '12': 81.4,
    '13': 81.3,
    '14': 81.1,
    '15': 80.9,
    '16': 80.8,
    '17': 80.6,
    '18': 80.4,
    '19': 80.3,
    '20': 80.1,
    '21': 80,
    '22': 79.8,
    '23': 79.6,
    '24': 79.5,
    '25': 79.3,
    '26': 79.2,
    '27': 79,
    '28': 78.8,
    '29': 78.7,
    '30': 78.5,
    '31': 78.4,
    '32': 78.2,
    '33': 78.1,
    '34': 77.9,
    '35': 77.8,
    '36': 77.6,
    '37': 77.4,
    '38': 77.3,
    '39': 77.1,
    '40': 77,
    '41': 76.8,
    '42': 76.7,
    '43': 76.5,
    '44': 76.4,
    '45': 76.2,
    '46': 76.1,
    '47': 75.9,
    '48': 75.8,
    '49': 75.6,
    '50': 75.5,
    '51': 75.3,
    '52': 75.2,
    '53': 75,
    '54': 74.9,
    '55': 74.7,
    '56': 74.6,
    '57': 74.4,
    '58': 74.3,
    '59': 74.1,
    '60': 74,
    '61': 73.9,
    '62': 73.7,
    '63': 73.6,
    '64': 73.4,
    '65': 73.3,
    '66': 73.1,
    '67': 73,
    '68': 72.8,
    '69': 72.7,
    '70': 72.6,
    '71': 72.4,
    '72': 72.3,
    '73': 72.1,
    '74': 72,
    '75': 71.9,
    '76': 71.7,
    '77': 71.6,
    '78': 71.4,
    '79': 71.3,
    '80': 71.2,
    '81': 71,
    '82': 70.9,
    '83': 70.7,
    '84': 70.6,
    '85': 70.5,
    '86': 70.3,
    '87': 70.2,
    '88': 70.1,
    '89': 69.9,
    '90': 69.8,
    '91': 69.7,
    '92': 69.5,
    '93': 69.4,
    '94': 69.3,
    '95': 69.1,
    '96': 69,
    '97': 68.8,
    '98': 68.7,
    '99': 68.6,
    '100': 68.5,
    '101': 68.3,
    '102': 68.2,
    '103': 68.1,
    '104': 67.9,
    '105': 67.8,
    '106': 67.7,
    '107': 67.5,
    '108': 67.4,
    '109': 67.3,
    '110': 67.1,
    '111': 67,
    '112': 66.9,
    '113': 66.8,
    '114': 66.6,
    '115': 66.5,
    '116': 66.4,
    '117': 66.2,
    '118': 66.1,
    '119': 66,
    '120': 65.9,
    '121': 65.7,
    '122': 65.6,
    '123': 65.5,
    '124': 65.4,
    '125': 65.2,
    '126': 65.1,
    '127': 65,
    '128': 64.9,
    '129': 64.7,
    '130': 64.6,
    '131': 64.5,
    '132': 64.4,
    '133': 64.3,
    '134': 64.1,
    '135': 64,
    '136': 63.9,
    '137': 63.8,
    '138': 63.6,
    '139': 63.5,
    '140': 63.4,
    '141': 63.3,
    '142': 63.2,
    '143': 63,
    '144': 62.9,
    '145': 62.8,
    '146': 62.7,
    '147': 62.6,
    '148': 62.4,
    '149': 62.3,
    '150': 62.2,
    '151': 62.1,
    '152': 62,
    '153': 61.9,
    '154': 61.7,
    '155': 61.6,
    '156': 61.5,
    '157': 61.4,
    '158': 61.3,
    '159': 61.2,
    '160': 61,
    '161': 60.9,
    '162': 60.8,
    '163': 60.7,
    '164': 60.6,
    '165': 60.5,
    '166': 60.4,
    '167': 60.2,
    '168': 60.1,
    '169': 60,
    '170': 59.9,
    '171': 59.8,
    '172': 59.7,
    '173': 59.6,
    '174': 59.4,
    '175': 59.3,
    '176': 59.2,
    '177': 59.1,
    '178': 59,
    '179': 58.9,
    '180': 58.8,
    '181': 58.7,
    '182': 58.6,
    '183': 58.5,
    '184': 58.3,
    '185': 58.2,
    '186': 58.1,
    '187': 58,
    '188': 57.9,
    '189': 57.8,
    '190': 57.7,
    '191': 57.6,
    '192': 57.5,
    '193': 57.4,
    '194': 57.3,
    '195': 57.1,
    '0.5': 83.3,
    '1.5': 83.2,
    '2.5': 83,
    '3.5': 82.8,
    '4.5': 82.7,
    '5.5': 82.5,
    '6.5': 82.3,
    '7.5': 82.2,
    '8.5': 82,
    '9.5': 81.8,
    '10.5': 81.7,
    '11.5': 81.5,
    '12.5': 81.3,
    '13.5': 81.2,
    '14.5': 81,
    '15.5': 80.8,
    '16.5': 80.7,
    '17.5': 80.5,
    '18.5': 80.4,
    '19.5': 80.2,
    '20.5': 80,
    '21.5': 79.9,
    '22.5': 79.7,
    '23.5': 79.6,
    '24.5': 79.4,
    '25.5': 79.2,
    '26.5': 79.1,
    '27.5': 78.9,
    '28.5': 78.8,
    '29.5': 78.6,
    '30.5': 78.5,
    '31.5': 78.3,
    '32.5': 78.1,
    '33.5': 78,
    '34.5': 77.8,
    '35.5': 77.7,
    '36.5': 77.5,
    '37.5': 77.4,
    '38.5': 77.2,
    '39.5': 77.1,
    '40.5': 76.9,
    '41.5': 76.8,
    '42.5': 76.6,
    '43.5': 76.5,
    '44.5': 76.3,
    '45.5': 76.1,
    '46.5': 76,
    '47.5': 75.8,
    '48.5': 75.7,
    '49.5': 75.5,
    '50.5': 75.4,
    '51.5': 75.3,
    '52.5': 75.1,
    '53.5': 75,
    '54.5': 74.8,
    '55.5': 74.7,
    '56.5': 74.5,
    '57.5': 74.4,
    '58.5': 74.2,
    '59.5': 74.1,
    '60.5': 73.9,
    '61.5': 73.8,
    '62.5': 73.6,
    '63.5': 73.5,
    '64.5': 73.3,
    '65.5': 73.2,
    '66.5': 73.1,
    '67.5': 72.9,
    '68.5': 72.8,
    '69.5': 72.6,
    '70.5': 72.5,
    '71.5': 72.4,
    '72.5': 72.2,
    '73.5': 72.1,
    '74.5': 71.9,
    '75.5': 71.8,
    '76.5': 71.6,
    '77.5': 71.5,
    '78.5': 71.4,
    '79.5': 71.2,
    '80.5': 71.1,
    '81.5': 71,
    '82.5': 70.8,
    '83.5': 70.7,
    '84.5': 70.5,
    '85.5': 70.4,
    '86.5': 70.3,
    '87.5': 70.1,
    '88.5': 70,
    '89.5': 69.9,
    '90.5': 69.7,
    '91.5': 69.6,
    '92.5': 69.5,
    '93.5': 69.3,
    '94.5': 69.2,
    '95.5': 69.1,
    '96.5': 68.9,
    '97.5': 68.8,
    '98.5': 68.7,
    '99.5': 68.5,
    '100.5': 68.4,
    '101.5': 68.3,
    '102.5': 68.1,
    '103.5': 68,
    '104.5': 67.9,
    '105.5': 67.7,
    '106.5': 67.6,
    '107.5': 67.5,
    '108.5': 67.3,
    '109.5': 67.2,
    '110.5': 67.1,
    '111.5': 67,
    '112.5': 66.8,
    '113.5': 66.7,
    '114.5': 66.6,
    '115.5': 66.4,
    '116.5': 66.3,
    '117.5': 66.2,
    '118.5': 66.1,
    '119.5': 65.9,
    '120.5': 65.8,
    '121.5': 65.7,
    '122.5': 65.6,
    '123.5': 65.4,
    '124.5': 65.3,
    '125.5': 65.2,
    '126.5': 65.1,
    '127.5': 64.9,
    '128.5': 64.8,
    '129.5': 64.7,
    '130.5': 64.6,
    '131.5': 64.4,
    '132.5': 64.3,
    '133.5': 64.2,
    '134.5': 64.1,
    '135.5': 63.9,
    '136.5': 63.8,
    '137.5': 63.7,
    '138.5': 63.6,
    '139.5': 63.5,
    '140.5': 63.3,
    '141.5': 63.2,
    '142.5': 63.1,
    '143.5': 63,
    '144.5': 62.9,
    '145.5': 62.7,
    '146.5': 62.6,
    '147.5': 62.5,
    '148.5': 62.4,
    '149.5': 62.3,
    '150.5': 62.1,
    '151.5': 62,
    '152.5': 61.9,
    '153.5': 61.8,
    '154.5': 61.7,
    '155.5': 61.6,
    '156.5': 61.4,
    '157.5': 61.3,
    '158.5': 61.2,
    '159.5': 61.1,
    '160.5': 61,
    '161.5': 60.9,
    '162.5': 60.8,
    '163.5': 60.6,
    '164.5': 60.5,
    '165.5': 60.4,
    '166.5': 60.3,
    '167.5': 60.2,
    '168.5': 60.1,
    '169.5': 60,
    '170.5': 59.8,
    '171.5': 59.7,
    '172.5': 59.6,
    '173.5': 59.5,
    '174.5': 59.4,
    '175.5': 59.3,
    '176.5': 59.2,
    '177.5': 59.1,
    '178.5': 58.9,
    '179.5': 58.8,
    '180.5': 58.7,
    '181.5': 58.6,
    '182.5': 58.5,
    '183.5': 58.4,
    '184.5': 58.3,
    '185.5': 58.2,
    '186.5': 58.1,
    '187.5': 58,
    '188.5': 57.9,
    '189.5': 57.7,
    '190.5': 57.6,
    '191.5': 57.5,
    '192.5': 57.4,
    '193.5': 57.3,
    '194.5': 57.2,
  },
  '74.5': {
    '0': 84,
    '1': 83.8,
    '2': 83.6,
    '3': 83.5,
    '4': 83.3,
    '5': 83.1,
    '6': 83,
    '7': 82.8,
    '8': 82.6,
    '9': 82.5,
    '10': 82.3,
    '11': 82.1,
    '12': 82,
    '13': 81.8,
    '14': 81.6,
    '15': 81.5,
    '16': 81.3,
    '17': 81.2,
    '18': 81,
    '19': 80.8,
    '20': 80.7,
    '21': 80.5,
    '22': 80.3,
    '23': 80.2,
    '24': 80,
    '25': 79.9,
    '26': 79.7,
    '27': 79.5,
    '28': 79.4,
    '29': 79.2,
    '30': 79.1,
    '31': 78.9,
    '32': 78.8,
    '33': 78.6,
    '34': 78.4,
    '35': 78.3,
    '36': 78.1,
    '37': 78,
    '38': 77.8,
    '39': 77.7,
    '40': 77.5,
    '41': 77.4,
    '42': 77.2,
    '43': 77,
    '44': 76.9,
    '45': 76.7,
    '46': 76.6,
    '47': 76.4,
    '48': 76.3,
    '49': 76.1,
    '50': 76,
    '51': 75.8,
    '52': 75.7,
    '53': 75.5,
    '54': 75.4,
    '55': 75.2,
    '56': 75.1,
    '57': 74.9,
    '58': 74.8,
    '59': 74.6,
    '60': 74.5,
    '61': 74.4,
    '62': 74.2,
    '63': 74.1,
    '64': 73.9,
    '65': 73.8,
    '66': 73.6,
    '67': 73.5,
    '68': 73.3,
    '69': 73.2,
    '70': 73.1,
    '71': 72.9,
    '72': 72.8,
    '73': 72.6,
    '74': 72.5,
    '75': 72.3,
    '76': 72.2,
    '77': 72.1,
    '78': 71.9,
    '79': 71.8,
    '80': 71.6,
    '81': 71.5,
    '82': 71.4,
    '83': 71.2,
    '84': 71.1,
    '85': 70.9,
    '86': 70.8,
    '87': 70.7,
    '88': 70.5,
    '89': 70.4,
    '90': 70.3,
    '91': 70.1,
    '92': 70,
    '93': 69.9,
    '94': 69.7,
    '95': 69.6,
    '96': 69.4,
    '97': 69.3,
    '98': 69.2,
    '99': 69,
    '100': 68.9,
    '101': 68.8,
    '102': 68.6,
    '103': 68.5,
    '104': 68.4,
    '105': 68.2,
    '106': 68.1,
    '107': 68,
    '108': 67.9,
    '109': 67.7,
    '110': 67.6,
    '111': 67.5,
    '112': 67.3,
    '113': 67.2,
    '114': 67.1,
    '115': 67,
    '116': 66.8,
    '117': 66.7,
    '118': 66.6,
    '119': 66.4,
    '120': 66.3,
    '121': 66.2,
    '122': 66.1,
    '123': 65.9,
    '124': 65.8,
    '125': 65.7,
    '126': 65.6,
    '127': 65.4,
    '128': 65.3,
    '129': 65.2,
    '130': 65.1,
    '131': 64.9,
    '132': 64.8,
    '133': 64.7,
    '134': 64.6,
    '135': 64.4,
    '136': 64.3,
    '137': 64.2,
    '138': 64.1,
    '139': 63.9,
    '140': 63.8,
    '141': 63.7,
    '142': 63.6,
    '143': 63.5,
    '144': 63.3,
    '145': 63.2,
    '146': 63.1,
    '147': 63,
    '148': 62.9,
    '149': 62.7,
    '150': 62.6,
    '151': 62.5,
    '152': 62.4,
    '153': 62.3,
    '154': 62.2,
    '155': 62,
    '156': 61.9,
    '157': 61.8,
    '158': 61.7,
    '159': 61.6,
    '160': 61.5,
    '161': 61.3,
    '162': 61.2,
    '163': 61.1,
    '164': 61,
    '165': 60.9,
    '166': 60.8,
    '167': 60.6,
    '168': 60.5,
    '169': 60.4,
    '170': 60.3,
    '171': 60.2,
    '172': 60.1,
    '173': 60,
    '174': 59.8,
    '175': 59.7,
    '176': 59.6,
    '177': 59.5,
    '178': 59.4,
    '179': 59.3,
    '180': 59.2,
    '181': 59.1,
    '182': 59,
    '183': 58.8,
    '184': 58.7,
    '185': 58.6,
    '186': 58.5,
    '187': 58.4,
    '188': 58.3,
    '189': 58.2,
    '190': 58.1,
    '191': 58,
    '192': 57.9,
    '193': 57.8,
    '194': 57.6,
    '195': 57.5,
    '0.5': 83.9,
    '1.5': 83.7,
    '2.5': 83.6,
    '3.5': 83.4,
    '4.5': 83.2,
    '5.5': 83.1,
    '6.5': 82.9,
    '7.5': 82.7,
    '8.5': 82.6,
    '9.5': 82.4,
    '10.5': 82.2,
    '11.5': 82.1,
    '12.5': 81.9,
    '13.5': 81.7,
    '14.5': 81.6,
    '15.5': 81.4,
    '16.5': 81.2,
    '17.5': 81.1,
    '18.5': 80.9,
    '19.5': 80.7,
    '20.5': 80.6,
    '21.5': 80.4,
    '22.5': 80.3,
    '23.5': 80.1,
    '24.5': 79.9,
    '25.5': 79.8,
    '26.5': 79.6,
    '27.5': 79.5,
    '28.5': 79.3,
    '29.5': 79.1,
    '30.5': 79,
    '31.5': 78.8,
    '32.5': 78.7,
    '33.5': 78.5,
    '34.5': 78.4,
    '35.5': 78.2,
    '36.5': 78,
    '37.5': 77.9,
    '38.5': 77.7,
    '39.5': 77.6,
    '40.5': 77.4,
    '41.5': 77.3,
    '42.5': 77.1,
    '43.5': 77,
    '44.5': 76.8,
    '45.5': 76.7,
    '46.5': 76.5,
    '47.5': 76.4,
    '48.5': 76.2,
    '49.5': 76.1,
    '50.5': 75.9,
    '51.5': 75.8,
    '52.5': 75.6,
    '53.5': 75.5,
    '54.5': 75.3,
    '55.5': 75.2,
    '56.5': 75,
    '57.5': 74.9,
    '58.5': 74.7,
    '59.5': 74.6,
    '60.5': 74.4,
    '61.5': 74.3,
    '62.5': 74.1,
    '63.5': 74,
    '64.5': 73.8,
    '65.5': 73.7,
    '66.5': 73.6,
    '67.5': 73.4,
    '68.5': 73.3,
    '69.5': 73.1,
    '70.5': 73,
    '71.5': 72.8,
    '72.5': 72.7,
    '73.5': 72.6,
    '74.5': 72.4,
    '75.5': 72.3,
    '76.5': 72.1,
    '77.5': 72,
    '78.5': 71.8,
    '79.5': 71.7,
    '80.5': 71.6,
    '81.5': 71.4,
    '82.5': 71.3,
    '83.5': 71.2,
    '84.5': 71,
    '85.5': 70.9,
    '86.5': 70.7,
    '87.5': 70.6,
    '88.5': 70.5,
    '89.5': 70.3,
    '90.5': 70.2,
    '91.5': 70.1,
    '92.5': 69.9,
    '93.5': 69.8,
    '94.5': 69.7,
    '95.5': 69.5,
    '96.5': 69.4,
    '97.5': 69.2,
    '98.5': 69.1,
    '99.5': 69,
    '100.5': 68.8,
    '101.5': 68.7,
    '102.5': 68.6,
    '103.5': 68.4,
    '104.5': 68.3,
    '105.5': 68.2,
    '106.5': 68.1,
    '107.5': 67.9,
    '108.5': 67.8,
    '109.5': 67.7,
    '110.5': 67.5,
    '111.5': 67.4,
    '112.5': 67.3,
    '113.5': 67.1,
    '114.5': 67,
    '115.5': 66.9,
    '116.5': 66.8,
    '117.5': 66.6,
    '118.5': 66.5,
    '119.5': 66.4,
    '120.5': 66.2,
    '121.5': 66.1,
    '122.5': 66,
    '123.5': 65.9,
    '124.5': 65.7,
    '125.5': 65.6,
    '126.5': 65.5,
    '127.5': 65.4,
    '128.5': 65.2,
    '129.5': 65.1,
    '130.5': 65,
    '131.5': 64.9,
    '132.5': 64.7,
    '133.5': 64.6,
    '134.5': 64.5,
    '135.5': 64.4,
    '136.5': 64.3,
    '137.5': 64.1,
    '138.5': 64,
    '139.5': 63.9,
    '140.5': 63.8,
    '141.5': 63.6,
    '142.5': 63.5,
    '143.5': 63.4,
    '144.5': 63.3,
    '145.5': 63.2,
    '146.5': 63,
    '147.5': 62.9,
    '148.5': 62.8,
    '149.5': 62.7,
    '150.5': 62.6,
    '151.5': 62.4,
    '152.5': 62.3,
    '153.5': 62.2,
    '154.5': 62.1,
    '155.5': 62,
    '156.5': 61.9,
    '157.5': 61.7,
    '158.5': 61.6,
    '159.5': 61.5,
    '160.5': 61.4,
    '161.5': 61.3,
    '162.5': 61.2,
    '163.5': 61,
    '164.5': 60.9,
    '165.5': 60.8,
    '166.5': 60.7,
    '167.5': 60.6,
    '168.5': 60.5,
    '169.5': 60.4,
    '170.5': 60.2,
    '171.5': 60.1,
    '172.5': 60,
    '173.5': 59.9,
    '174.5': 59.8,
    '175.5': 59.7,
    '176.5': 59.6,
    '177.5': 59.5,
    '178.5': 59.3,
    '179.5': 59.2,
    '180.5': 59.1,
    '181.5': 59,
    '182.5': 58.9,
    '183.5': 58.8,
    '184.5': 58.7,
    '185.5': 58.6,
    '186.5': 58.5,
    '187.5': 58.4,
    '188.5': 58.2,
    '189.5': 58.1,
    '190.5': 58,
    '191.5': 57.9,
    '192.5': 57.8,
    '193.5': 57.7,
    '194.5': 57.6,
  },
  '75': {
    '0': 84.6,
    '1': 84.4,
    '2': 84.2,
    '3': 84,
    '4': 83.9,
    '5': 83.7,
    '6': 83.5,
    '7': 83.4,
    '8': 83.2,
    '9': 83,
    '10': 82.9,
    '11': 82.7,
    '12': 82.5,
    '13': 82.4,
    '14': 82.2,
    '15': 82,
    '16': 81.9,
    '17': 81.7,
    '18': 81.5,
    '19': 81.4,
    '20': 81.2,
    '21': 81,
    '22': 80.9,
    '23': 80.7,
    '24': 80.6,
    '25': 80.4,
    '26': 80.2,
    '27': 80.1,
    '28': 79.9,
    '29': 79.8,
    '30': 79.6,
    '31': 79.4,
    '32': 79.3,
    '33': 79.1,
    '34': 79,
    '35': 78.8,
    '36': 78.7,
    '37': 78.5,
    '38': 78.3,
    '39': 78.2,
    '40': 78,
    '41': 77.9,
    '42': 77.7,
    '43': 77.6,
    '44': 77.4,
    '45': 77.3,
    '46': 77.1,
    '47': 77,
    '48': 76.8,
    '49': 76.6,
    '50': 76.5,
    '51': 76.3,
    '52': 76.2,
    '53': 76,
    '54': 75.9,
    '55': 75.7,
    '56': 75.6,
    '57': 75.4,
    '58': 75.3,
    '59': 75.1,
    '60': 75,
    '61': 74.9,
    '62': 74.7,
    '63': 74.6,
    '64': 74.4,
    '65': 74.3,
    '66': 74.1,
    '67': 74,
    '68': 73.8,
    '69': 73.7,
    '70': 73.5,
    '71': 73.4,
    '72': 73.3,
    '73': 73.1,
    '74': 73,
    '75': 72.8,
    '76': 72.7,
    '77': 72.5,
    '78': 72.4,
    '79': 72.3,
    '80': 72.1,
    '81': 72,
    '82': 71.8,
    '83': 71.7,
    '84': 71.6,
    '85': 71.4,
    '86': 71.3,
    '87': 71.1,
    '88': 71,
    '89': 70.9,
    '90': 70.7,
    '91': 70.6,
    '92': 70.5,
    '93': 70.3,
    '94': 70.2,
    '95': 70,
    '96': 69.9,
    '97': 69.8,
    '98': 69.6,
    '99': 69.5,
    '100': 69.4,
    '101': 69.2,
    '102': 69.1,
    '103': 69,
    '104': 68.8,
    '105': 68.7,
    '106': 68.6,
    '107': 68.4,
    '108': 68.3,
    '109': 68.2,
    '110': 68,
    '111': 67.9,
    '112': 67.8,
    '113': 67.7,
    '114': 67.5,
    '115': 67.4,
    '116': 67.3,
    '117': 67.1,
    '118': 67,
    '119': 66.9,
    '120': 66.8,
    '121': 66.6,
    '122': 66.5,
    '123': 66.4,
    '124': 66.2,
    '125': 66.1,
    '126': 66,
    '127': 65.9,
    '128': 65.7,
    '129': 65.6,
    '130': 65.5,
    '131': 65.4,
    '132': 65.2,
    '133': 65.1,
    '134': 65,
    '135': 64.9,
    '136': 64.7,
    '137': 64.6,
    '138': 64.5,
    '139': 64.4,
    '140': 64.3,
    '141': 64.1,
    '142': 64,
    '143': 63.9,
    '144': 63.8,
    '145': 63.6,
    '146': 63.5,
    '147': 63.4,
    '148': 63.3,
    '149': 63.2,
    '150': 63,
    '151': 62.9,
    '152': 62.8,
    '153': 62.7,
    '154': 62.6,
    '155': 62.4,
    '156': 62.3,
    '157': 62.2,
    '158': 62.1,
    '159': 62,
    '160': 61.9,
    '161': 61.7,
    '162': 61.6,
    '163': 61.5,
    '164': 61.4,
    '165': 61.3,
    '166': 61.2,
    '167': 61,
    '168': 60.9,
    '169': 60.8,
    '170': 60.7,
    '171': 60.6,
    '172': 60.5,
    '173': 60.4,
    '174': 60.2,
    '175': 60.1,
    '176': 60,
    '177': 59.9,
    '178': 59.8,
    '179': 59.7,
    '180': 59.6,
    '181': 59.5,
    '182': 59.4,
    '183': 59.2,
    '184': 59.1,
    '185': 59,
    '186': 58.9,
    '187': 58.8,
    '188': 58.7,
    '189': 58.6,
    '190': 58.5,
    '191': 58.4,
    '192': 58.3,
    '193': 58.1,
    '194': 58,
    '195': 57.9,
    '0.5': 84.5,
    '1.5': 84.3,
    '2.5': 84.1,
    '3.5': 84,
    '4.5': 83.8,
    '5.5': 83.6,
    '6.5': 83.4,
    '7.5': 83.3,
    '8.5': 83.1,
    '9.5': 82.9,
    '10.5': 82.8,
    '11.5': 82.6,
    '12.5': 82.4,
    '13.5': 82.3,
    '14.5': 82.1,
    '15.5': 81.9,
    '16.5': 81.8,
    '17.5': 81.6,
    '18.5': 81.5,
    '19.5': 81.3,
    '20.5': 81.1,
    '21.5': 81,
    '22.5': 80.8,
    '23.5': 80.6,
    '24.5': 80.5,
    '25.5': 80.3,
    '26.5': 80.2,
    '27.5': 80,
    '28.5': 79.8,
    '29.5': 79.7,
    '30.5': 79.5,
    '31.5': 79.4,
    '32.5': 79.2,
    '33.5': 79,
    '34.5': 78.9,
    '35.5': 78.7,
    '36.5': 78.6,
    '37.5': 78.4,
    '38.5': 78.3,
    '39.5': 78.1,
    '40.5': 78,
    '41.5': 77.8,
    '42.5': 77.6,
    '43.5': 77.5,
    '44.5': 77.3,
    '45.5': 77.2,
    '46.5': 77,
    '47.5': 76.9,
    '48.5': 76.7,
    '49.5': 76.6,
    '50.5': 76.4,
    '51.5': 76.3,
    '52.5': 76.1,
    '53.5': 76,
    '54.5': 75.8,
    '55.5': 75.7,
    '56.5': 75.5,
    '57.5': 75.4,
    '58.5': 75.2,
    '59.5': 75.1,
    '60.5': 74.9,
    '61.5': 74.8,
    '62.5': 74.6,
    '63.5': 74.5,
    '64.5': 74.3,
    '65.5': 74.2,
    '66.5': 74,
    '67.5': 73.9,
    '68.5': 73.8,
    '69.5': 73.6,
    '70.5': 73.5,
    '71.5': 73.3,
    '72.5': 73.2,
    '73.5': 73,
    '74.5': 72.9,
    '75.5': 72.8,
    '76.5': 72.6,
    '77.5': 72.5,
    '78.5': 72.3,
    '79.5': 72.2,
    '80.5': 72,
    '81.5': 71.9,
    '82.5': 71.8,
    '83.5': 71.6,
    '84.5': 71.5,
    '85.5': 71.4,
    '86.5': 71.2,
    '87.5': 71.1,
    '88.5': 70.9,
    '89.5': 70.8,
    '90.5': 70.7,
    '91.5': 70.5,
    '92.5': 70.4,
    '93.5': 70.3,
    '94.5': 70.1,
    '95.5': 70,
    '96.5': 69.8,
    '97.5': 69.7,
    '98.5': 69.6,
    '99.5': 69.4,
    '100.5': 69.3,
    '101.5': 69.2,
    '102.5': 69,
    '103.5': 68.9,
    '104.5': 68.8,
    '105.5': 68.6,
    '106.5': 68.5,
    '107.5': 68.4,
    '108.5': 68.2,
    '109.5': 68.1,
    '110.5': 68,
    '111.5': 67.9,
    '112.5': 67.7,
    '113.5': 67.6,
    '114.5': 67.5,
    '115.5': 67.3,
    '116.5': 67.2,
    '117.5': 67.1,
    '118.5': 66.9,
    '119.5': 66.8,
    '120.5': 66.7,
    '121.5': 66.6,
    '122.5': 66.4,
    '123.5': 66.3,
    '124.5': 66.2,
    '125.5': 66.1,
    '126.5': 65.9,
    '127.5': 65.8,
    '128.5': 65.7,
    '129.5': 65.6,
    '130.5': 65.4,
    '131.5': 65.3,
    '132.5': 65.2,
    '133.5': 65.1,
    '134.5': 64.9,
    '135.5': 64.8,
    '136.5': 64.7,
    '137.5': 64.6,
    '138.5': 64.4,
    '139.5': 64.3,
    '140.5': 64.2,
    '141.5': 64.1,
    '142.5': 63.9,
    '143.5': 63.8,
    '144.5': 63.7,
    '145.5': 63.6,
    '146.5': 63.5,
    '147.5': 63.3,
    '148.5': 63.2,
    '149.5': 63.1,
    '150.5': 63,
    '151.5': 62.9,
    '152.5': 62.7,
    '153.5': 62.6,
    '154.5': 62.5,
    '155.5': 62.4,
    '156.5': 62.3,
    '157.5': 62.2,
    '158.5': 62,
    '159.5': 61.9,
    '160.5': 61.8,
    '161.5': 61.7,
    '162.5': 61.6,
    '163.5': 61.5,
    '164.5': 61.3,
    '165.5': 61.2,
    '166.5': 61.1,
    '167.5': 61,
    '168.5': 60.9,
    '169.5': 60.8,
    '170.5': 60.6,
    '171.5': 60.5,
    '172.5': 60.4,
    '173.5': 60.3,
    '174.5': 60.2,
    '175.5': 60.1,
    '176.5': 60,
    '177.5': 59.9,
    '178.5': 59.7,
    '179.5': 59.6,
    '180.5': 59.5,
    '181.5': 59.4,
    '182.5': 59.3,
    '183.5': 59.2,
    '184.5': 59.1,
    '185.5': 59,
    '186.5': 58.9,
    '187.5': 58.7,
    '188.5': 58.6,
    '189.5': 58.5,
    '190.5': 58.4,
    '191.5': 58.3,
    '192.5': 58.2,
    '193.5': 58.1,
    '194.5': 58,
  },
  // 75 - 80
  '75.5': {
    '0': 85.1,
    '1': 85,
    '2': 84.8,
    '3': 84.6,
    '4': 84.4,
    '5': 84.3,
    '6': 84.1,
    '7': 83.9,
    '8': 83.8,
    '9': 83.6,
    '10': 83.4,
    '11': 83.3,
    '12': 83.1,
    '13': 82.9,
    '14': 82.8,
    '15': 82.6,
    '16': 82.4,
    '17': 82.3,
    '18': 82.1,
    '19': 81.9,
    '20': 81.8,
    '21': 81.6,
    '22': 81.4,
    '23': 81.3,
    '24': 81.1,
    '25': 80.9,
    '26': 80.8,
    '27': 80.6,
    '28': 80.5,
    '29': 80.3,
    '30': 80.1,
    '31': 80,
    '32': 79.8,
    '33': 79.7,
    '34': 79.5,
    '35': 79.3,
    '36': 79.2,
    '37': 79,
    '38': 78.9,
    '39': 78.7,
    '40': 78.6,
    '41': 78.4,
    '42': 78.2,
    '43': 78.1,
    '44': 77.9,
    '45': 77.8,
    '46': 77.6,
    '47': 77.5,
    '48': 77.3,
    '49': 77.2,
    '50': 77,
    '51': 76.9,
    '52': 76.7,
    '53': 76.6,
    '54': 76.4,
    '55': 76.2,
    '56': 76.1,
    '57': 75.9,
    '58': 75.8,
    '59': 75.6,
    '60': 75.5,
    '61': 75.4,
    '62': 75.2,
    '63': 75.1,
    '64': 74.9,
    '65': 74.8,
    '66': 74.6,
    '67': 74.5,
    '68': 74.3,
    '69': 74.2,
    '70': 74,
    '71': 73.9,
    '72': 73.7,
    '73': 73.6,
    '74': 73.5,
    '75': 73.3,
    '76': 73.2,
    '77': 73,
    '78': 72.9,
    '79': 72.7,
    '80': 72.6,
    '81': 72.5,
    '82': 72.3,
    '83': 72.2,
    '84': 72,
    '85': 71.9,
    '86': 71.8,
    '87': 71.6,
    '88': 71.5,
    '89': 71.3,
    '90': 71.2,
    '91': 71.1,
    '92': 70.9,
    '93': 70.8,
    '94': 70.6,
    '95': 70.5,
    '96': 70.4,
    '97': 70.2,
    '98': 70.1,
    '99': 70,
    '100': 69.8,
    '101': 69.7,
    '102': 69.6,
    '103': 69.4,
    '104': 69.3,
    '105': 69.2,
    '106': 69,
    '107': 68.9,
    '108': 68.8,
    '109': 68.6,
    '110': 68.5,
    '111': 68.4,
    '112': 68.2,
    '113': 68.1,
    '114': 68,
    '115': 67.8,
    '116': 67.7,
    '117': 67.6,
    '118': 67.5,
    '119': 67.3,
    '120': 67.2,
    '121': 67.1,
    '122': 66.9,
    '123': 66.8,
    '124': 66.7,
    '125': 66.6,
    '126': 66.4,
    '127': 66.3,
    '128': 66.2,
    '129': 66,
    '130': 65.9,
    '131': 65.8,
    '132': 65.7,
    '133': 65.5,
    '134': 65.4,
    '135': 65.3,
    '136': 65.2,
    '137': 65,
    '138': 64.9,
    '139': 64.8,
    '140': 64.7,
    '141': 64.6,
    '142': 64.4,
    '143': 64.3,
    '144': 64.2,
    '145': 64.1,
    '146': 63.9,
    '147': 63.8,
    '148': 63.7,
    '149': 63.6,
    '150': 63.5,
    '151': 63.3,
    '152': 63.2,
    '153': 63.1,
    '154': 63,
    '155': 62.9,
    '156': 62.7,
    '157': 62.6,
    '158': 62.5,
    '159': 62.4,
    '160': 62.3,
    '161': 62.2,
    '162': 62,
    '163': 61.9,
    '164': 61.8,
    '165': 61.7,
    '166': 61.6,
    '167': 61.5,
    '168': 61.3,
    '169': 61.2,
    '170': 61.1,
    '171': 61,
    '172': 60.9,
    '173': 60.8,
    '174': 60.6,
    '175': 60.5,
    '176': 60.4,
    '177': 60.3,
    '178': 60.2,
    '179': 60.1,
    '180': 60,
    '181': 59.9,
    '182': 59.7,
    '183': 59.6,
    '184': 59.5,
    '185': 59.4,
    '186': 59.3,
    '187': 59.2,
    '188': 59.1,
    '189': 59,
    '190': 58.9,
    '191': 58.7,
    '192': 58.6,
    '193': 58.5,
    '194': 58.4,
    '195': 58.3,
    '0.5': 85,
    '1.5': 84.9,
    '2.5': 84.7,
    '3.5': 84.5,
    '4.5': 84.4,
    '5.5': 84.2,
    '6.5': 84,
    '7.5': 83.8,
    '8.5': 83.7,
    '9.5': 83.5,
    '10.5': 83.3,
    '11.5': 83.2,
    '12.5': 83,
    '13.5': 82.8,
    '14.5': 82.7,
    '15.5': 82.5,
    '16.5': 82.3,
    '17.5': 82.2,
    '18.5': 82,
    '19.5': 81.8,
    '20.5': 81.7,
    '21.5': 81.5,
    '22.5': 81.3,
    '23.5': 81.2,
    '24.5': 81,
    '25.5': 80.9,
    '26.5': 80.7,
    '27.5': 80.5,
    '28.5': 80.4,
    '29.5': 80.2,
    '30.5': 80.1,
    '31.5': 79.9,
    '32.5': 79.7,
    '33.5': 79.6,
    '34.5': 79.4,
    '35.5': 79.3,
    '36.5': 79.1,
    '37.5': 78.9,
    '38.5': 78.8,
    '39.5': 78.6,
    '40.5': 78.5,
    '41.5': 78.3,
    '42.5': 78.2,
    '43.5': 78,
    '44.5': 77.9,
    '45.5': 77.7,
    '46.5': 77.5,
    '47.5': 77.4,
    '48.5': 77.2,
    '49.5': 77.1,
    '50.5': 76.9,
    '51.5': 76.8,
    '52.5': 76.6,
    '53.5': 76.5,
    '54.5': 76.3,
    '55.5': 76.2,
    '56.5': 76,
    '57.5': 75.9,
    '58.5': 75.7,
    '59.5': 75.6,
    '60.5': 75.4,
    '61.5': 75.3,
    '62.5': 75.1,
    '63.5': 75,
    '64.5': 74.8,
    '65.5': 74.7,
    '66.5': 74.5,
    '67.5': 74.4,
    '68.5': 74.2,
    '69.5': 74.1,
    '70.5': 74,
    '71.5': 73.8,
    '72.5': 73.7,
    '73.5': 73.5,
    '74.5': 73.4,
    '75.5': 73.2,
    '76.5': 73.1,
    '77.5': 73,
    '78.5': 72.8,
    '79.5': 72.7,
    '80.5': 72.5,
    '81.5': 72.4,
    '82.5': 72.2,
    '83.5': 72.1,
    '84.5': 72,
    '85.5': 71.8,
    '86.5': 71.7,
    '87.5': 71.5,
    '88.5': 71.4,
    '89.5': 71.3,
    '90.5': 71.1,
    '91.5': 71,
    '92.5': 70.9,
    '93.5': 70.7,
    '94.5': 70.6,
    '95.5': 70.4,
    '96.5': 70.3,
    '97.5': 70.2,
    '98.5': 70,
    '99.5': 69.9,
    '100.5': 69.8,
    '101.5': 69.6,
    '102.5': 69.5,
    '103.5': 69.4,
    '104.5': 69.2,
    '105.5': 69.1,
    '106.5': 69,
    '107.5': 68.8,
    '108.5': 68.7,
    '109.5': 68.6,
    '110.5': 68.4,
    '111.5': 68.3,
    '112.5': 68.2,
    '113.5': 68,
    '114.5': 67.9,
    '115.5': 67.8,
    '116.5': 67.6,
    '117.5': 67.5,
    '118.5': 67.4,
    '119.5': 67.3,
    '120.5': 67.1,
    '121.5': 67,
    '122.5': 66.9,
    '123.5': 66.7,
    '124.5': 66.6,
    '125.5': 66.5,
    '126.5': 66.4,
    '127.5': 66.2,
    '128.5': 66.1,
    '129.5': 66,
    '130.5': 65.9,
    '131.5': 65.7,
    '132.5': 65.6,
    '133.5': 65.5,
    '134.5': 65.4,
    '135.5': 65.2,
    '136.5': 65.1,
    '137.5': 65,
    '138.5': 64.9,
    '139.5': 64.7,
    '140.5': 64.6,
    '141.5': 64.5,
    '142.5': 64.4,
    '143.5': 64.2,
    '144.5': 64.1,
    '145.5': 64,
    '146.5': 63.9,
    '147.5': 63.8,
    '148.5': 63.6,
    '149.5': 63.5,
    '150.5': 63.4,
    '151.5': 63.3,
    '152.5': 63.2,
    '153.5': 63,
    '154.5': 62.9,
    '155.5': 62.8,
    '156.5': 62.7,
    '157.5': 62.6,
    '158.5': 62.4,
    '159.5': 62.3,
    '160.5': 62.2,
    '161.5': 62.1,
    '162.5': 62,
    '163.5': 61.9,
    '164.5': 61.7,
    '165.5': 61.6,
    '166.5': 61.5,
    '167.5': 61.4,
    '168.5': 61.3,
    '169.5': 61.2,
    '170.5': 61.1,
    '171.5': 60.9,
    '172.5': 60.8,
    '173.5': 60.7,
    '174.5': 60.6,
    '175.5': 60.5,
    '176.5': 60.4,
    '177.5': 60.3,
    '178.5': 60.1,
    '179.5': 60,
    '180.5': 59.9,
    '181.5': 59.8,
    '182.5': 59.7,
    '183.5': 59.6,
    '184.5': 59.5,
    '185.5': 59.4,
    '186.5': 59.2,
    '187.5': 59.1,
    '188.5': 59,
    '189.5': 58.9,
    '190.5': 58.8,
    '191.5': 58.7,
    '192.5': 58.6,
    '193.5': 58.5,
    '194.5': 58.4,
  },
  '76': {
    '0': 85.7,
    '1': 85.5,
    '2': 85.4,
    '3': 85.2,
    '4': 85,
    '5': 84.8,
    '6': 84.7,
    '7': 84.5,
    '8': 84.3,
    '9': 84.2,
    '10': 84,
    '11': 83.8,
    '12': 83.6,
    '13': 83.5,
    '14': 83.3,
    '15': 83.1,
    '16': 83,
    '17': 82.8,
    '18': 82.6,
    '19': 82.5,
    '20': 82.3,
    '21': 82.1,
    '22': 82,
    '23': 81.8,
    '24': 81.6,
    '25': 81.5,
    '26': 81.3,
    '27': 81.2,
    '28': 81,
    '29': 80.8,
    '30': 80.7,
    '31': 80.5,
    '32': 80.3,
    '33': 80.2,
    '34': 80,
    '35': 79.9,
    '36': 79.7,
    '37': 79.5,
    '38': 79.4,
    '39': 79.2,
    '40': 79.1,
    '41': 78.9,
    '42': 78.8,
    '43': 78.6,
    '44': 78.4,
    '45': 78.3,
    '46': 78.1,
    '47': 78,
    '48': 77.8,
    '49': 77.7,
    '50': 77.5,
    '51': 77.4,
    '52': 77.2,
    '53': 77.1,
    '54': 76.9,
    '55': 76.8,
    '56': 76.6,
    '57': 76.4,
    '58': 76.3,
    '59': 76.2,
    '60': 76,
    '61': 75.9,
    '62': 75.7,
    '63': 75.6,
    '64': 75.4,
    '65': 75.3,
    '66': 75.1,
    '67': 75,
    '68': 74.8,
    '69': 74.7,
    '70': 74.5,
    '71': 74.4,
    '72': 74.2,
    '73': 74.1,
    '74': 73.9,
    '75': 73.8,
    '76': 73.6,
    '77': 73.5,
    '78': 73.4,
    '79': 73.2,
    '80': 73.1,
    '81': 72.9,
    '82': 72.8,
    '83': 72.7,
    '84': 72.5,
    '85': 72.4,
    '86': 72.2,
    '87': 72.1,
    '88': 71.9,
    '89': 71.8,
    '90': 71.7,
    '91': 71.5,
    '92': 71.4,
    '93': 71.3,
    '94': 71.1,
    '95': 71,
    '96': 70.8,
    '97': 70.7,
    '98': 70.6,
    '99': 70.4,
    '100': 70.3,
    '101': 70.2,
    '102': 70,
    '103': 69.9,
    '104': 69.8,
    '105': 69.6,
    '106': 69.5,
    '107': 69.3,
    '108': 69.2,
    '109': 69.1,
    '110': 69,
    '111': 68.8,
    '112': 68.7,
    '113': 68.6,
    '114': 68.4,
    '115': 68.3,
    '116': 68.2,
    '117': 68,
    '118': 67.9,
    '119': 67.8,
    '120': 67.6,
    '121': 67.5,
    '122': 67.4,
    '123': 67.3,
    '124': 67.1,
    '125': 67,
    '126': 66.9,
    '127': 66.7,
    '128': 66.6,
    '129': 66.5,
    '130': 66.4,
    '131': 66.2,
    '132': 66.1,
    '133': 66,
    '134': 65.9,
    '135': 65.7,
    '136': 65.6,
    '137': 65.5,
    '138': 65.4,
    '139': 65.2,
    '140': 65.1,
    '141': 65,
    '142': 64.9,
    '143': 64.7,
    '144': 64.6,
    '145': 64.5,
    '146': 64.4,
    '147': 64.2,
    '148': 64.1,
    '149': 64,
    '150': 63.9,
    '151': 63.8,
    '152': 63.6,
    '153': 63.5,
    '154': 63.4,
    '155': 63.3,
    '156': 63.2,
    '157': 63,
    '158': 62.9,
    '159': 62.8,
    '160': 62.7,
    '161': 62.6,
    '162': 62.4,
    '163': 62.3,
    '164': 62.2,
    '165': 62.1,
    '166': 62,
    '167': 61.9,
    '168': 61.7,
    '169': 61.6,
    '170': 61.5,
    '171': 61.4,
    '172': 61.3,
    '173': 61.2,
    '174': 61.1,
    '175': 60.9,
    '176': 60.8,
    '177': 60.7,
    '178': 60.6,
    '179': 60.5,
    '180': 60.4,
    '181': 60.3,
    '182': 60.1,
    '183': 60,
    '184': 59.9,
    '185': 59.8,
    '186': 59.7,
    '187': 59.6,
    '188': 59.5,
    '189': 59.4,
    '190': 59.2,
    '191': 59.1,
    '192': 59,
    '193': 58.9,
    '194': 58.8,
    '195': 58.7,
    '0.5': 85.6,
    '1.5': 85.4,
    '2.5': 85.3,
    '3.5': 85.1,
    '4.5': 84.9,
    '5.5': 84.8,
    '6.5': 84.6,
    '7.5': 84.4,
    '8.5': 84.2,
    '9.5': 84.1,
    '10.5': 83.9,
    '11.5': 83.7,
    '12.5': 83.6,
    '13.5': 83.4,
    '14.5': 83.2,
    '15.5': 83.1,
    '16.5': 82.9,
    '17.5': 82.7,
    '18.5': 82.6,
    '19.5': 82.4,
    '20.5': 82.2,
    '21.5': 82.1,
    '22.5': 81.9,
    '23.5': 81.7,
    '24.5': 81.6,
    '25.5': 81.4,
    '26.5': 81.2,
    '27.5': 81.1,
    '28.5': 80.9,
    '29.5': 80.7,
    '30.5': 80.6,
    '31.5': 80.4,
    '32.5': 80.3,
    '33.5': 80.1,
    '34.5': 79.9,
    '35.5': 79.8,
    '36.5': 79.6,
    '37.5': 79.5,
    '38.5': 79.3,
    '39.5': 79.2,
    '40.5': 79,
    '41.5': 78.8,
    '42.5': 78.7,
    '43.5': 78.5,
    '44.5': 78.4,
    '45.5': 78.2,
    '46.5': 78.1,
    '47.5': 77.9,
    '48.5': 77.7,
    '49.5': 77.6,
    '50.5': 77.4,
    '51.5': 77.3,
    '52.5': 77.1,
    '53.5': 77,
    '54.5': 76.8,
    '55.5': 76.7,
    '56.5': 76.5,
    '57.5': 76.4,
    '58.5': 76.2,
    '59.5': 76.1,
    '60.5': 75.9,
    '61.5': 75.8,
    '62.5': 75.6,
    '63.5': 75.5,
    '64.5': 75.3,
    '65.5': 75.2,
    '66.5': 75,
    '67.5': 74.9,
    '68.5': 74.7,
    '69.5': 74.6,
    '70.5': 74.4,
    '71.5': 74.3,
    '72.5': 74.2,
    '73.5': 74,
    '74.5': 73.9,
    '75.5': 73.7,
    '76.5': 73.6,
    '77.5': 73.4,
    '78.5': 73.3,
    '79.5': 73.1,
    '80.5': 73,
    '81.5': 72.9,
    '82.5': 72.7,
    '83.5': 72.6,
    '84.5': 72.4,
    '85.5': 72.3,
    '86.5': 72.2,
    '87.5': 72,
    '88.5': 71.9,
    '89.5': 71.7,
    '90.5': 71.6,
    '91.5': 71.5,
    '92.5': 71.3,
    '93.5': 71.2,
    '94.5': 71,
    '95.5': 70.9,
    '96.5': 70.8,
    '97.5': 70.6,
    '98.5': 70.5,
    '99.5': 70.4,
    '100.5': 70.2,
    '101.5': 70.1,
    '102.5': 70,
    '103.5': 69.8,
    '104.5': 69.7,
    '105.5': 69.5,
    '106.5': 69.4,
    '107.5': 69.3,
    '108.5': 69.1,
    '109.5': 69,
    '110.5': 68.9,
    '111.5': 68.7,
    '112.5': 68.6,
    '113.5': 68.5,
    '114.5': 68.4,
    '115.5': 68.2,
    '116.5': 68.1,
    '117.5': 68,
    '118.5': 67.8,
    '119.5': 67.7,
    '120.5': 67.6,
    '121.5': 67.4,
    '122.5': 67.3,
    '123.5': 67.2,
    '124.5': 67.1,
    '125.5': 66.9,
    '126.5': 66.8,
    '127.5': 66.7,
    '128.5': 66.5,
    '129.5': 66.4,
    '130.5': 66.3,
    '131.5': 66.2,
    '132.5': 66,
    '133.5': 65.9,
    '134.5': 65.8,
    '135.5': 65.7,
    '136.5': 65.5,
    '137.5': 65.4,
    '138.5': 65.3,
    '139.5': 65.2,
    '140.5': 65,
    '141.5': 64.9,
    '142.5': 64.8,
    '143.5': 64.7,
    '144.5': 64.6,
    '145.5': 64.4,
    '146.5': 64.3,
    '147.5': 64.2,
    '148.5': 64.1,
    '149.5': 63.9,
    '150.5': 63.8,
    '151.5': 63.7,
    '152.5': 63.6,
    '153.5': 63.5,
    '154.5': 63.3,
    '155.5': 63.2,
    '156.5': 63.1,
    '157.5': 63,
    '158.5': 62.9,
    '159.5': 62.7,
    '160.5': 62.6,
    '161.5': 62.5,
    '162.5': 62.4,
    '163.5': 62.3,
    '164.5': 62.2,
    '165.5': 62,
    '166.5': 61.9,
    '167.5': 61.8,
    '168.5': 61.7,
    '169.5': 61.6,
    '170.5': 61.5,
    '171.5': 61.3,
    '172.5': 61.2,
    '173.5': 61.1,
    '174.5': 61,
    '175.5': 60.9,
    '176.5': 60.8,
    '177.5': 60.7,
    '178.5': 60.5,
    '179.5': 60.4,
    '180.5': 60.3,
    '181.5': 60.2,
    '182.5': 60.1,
    '183.5': 60,
    '184.5': 59.9,
    '185.5': 59.7,
    '186.5': 59.6,
    '187.5': 59.5,
    '188.5': 59.4,
    '189.5': 59.3,
    '190.5': 59.2,
    '191.5': 59.1,
    '192.5': 59,
    '193.5': 58.9,
    '194.5': 58.8,
  },
  '76.5': {
    '0': 86.3,
    '1': 86.1,
    '2': 85.9,
    '3': 85.8,
    '4': 85.6,
    '5': 85.4,
    '6': 85.2,
    '7': 85.1,
    '8': 84.9,
    '9': 84.7,
    '10': 84.5,
    '11': 84.4,
    '12': 84.2,
    '13': 84,
    '14': 83.9,
    '15': 83.7,
    '16': 83.5,
    '17': 83.4,
    '18': 83.2,
    '19': 83,
    '20': 82.9,
    '21': 82.7,
    '22': 82.5,
    '23': 82.4,
    '24': 82.2,
    '25': 82,
    '26': 81.9,
    '27': 81.7,
    '28': 81.5,
    '29': 81.4,
    '30': 81.2,
    '31': 81,
    '32': 80.9,
    '33': 80.7,
    '34': 80.6,
    '35': 80.4,
    '36': 80.2,
    '37': 80.1,
    '38': 79.9,
    '39': 79.8,
    '40': 79.6,
    '41': 79.4,
    '42': 79.3,
    '43': 79.1,
    '44': 79,
    '45': 78.8,
    '46': 78.7,
    '47': 78.5,
    '48': 78.3,
    '49': 78.2,
    '50': 78,
    '51': 77.9,
    '52': 77.7,
    '53': 77.6,
    '54': 77.4,
    '55': 77.3,
    '56': 77.1,
    '57': 77,
    '58': 76.8,
    '59': 76.6,
    '60': 76.5,
    '61': 76.3,
    '62': 76.2,
    '63': 76,
    '64': 75.9,
    '65': 75.7,
    '66': 75.6,
    '67': 75.5,
    '68': 75.3,
    '69': 75.2,
    '70': 75,
    '71': 74.9,
    '72': 74.7,
    '73': 74.6,
    '74': 74.4,
    '75': 74.3,
    '76': 74.1,
    '77': 74,
    '78': 73.8,
    '79': 73.7,
    '80': 73.6,
    '81': 73.4,
    '82': 73.3,
    '83': 73.1,
    '84': 73,
    '85': 72.8,
    '86': 72.7,
    '87': 72.6,
    '88': 72.4,
    '89': 72.3,
    '90': 72.1,
    '91': 72,
    '92': 71.9,
    '93': 71.7,
    '94': 71.6,
    '95': 71.4,
    '96': 71.3,
    '97': 71.2,
    '98': 71,
    '99': 70.9,
    '100': 70.7,
    '101': 70.6,
    '102': 70.5,
    '103': 70.3,
    '104': 70.2,
    '105': 70.1,
    '106': 69.9,
    '107': 69.8,
    '108': 69.7,
    '109': 69.5,
    '110': 69.4,
    '111': 69.3,
    '112': 69.1,
    '113': 69,
    '114': 68.9,
    '115': 68.7,
    '116': 68.6,
    '117': 68.5,
    '118': 68.3,
    '119': 68.2,
    '120': 68.1,
    '121': 67.9,
    '122': 67.8,
    '123': 67.7,
    '124': 67.6,
    '125': 67.4,
    '126': 67.3,
    '127': 67.2,
    '128': 67,
    '129': 66.9,
    '130': 66.8,
    '131': 66.7,
    '132': 66.5,
    '133': 66.4,
    '134': 66.3,
    '135': 66.2,
    '136': 66,
    '137': 65.9,
    '138': 65.8,
    '139': 65.6,
    '140': 65.5,
    '141': 65.4,
    '142': 65.3,
    '143': 65.2,
    '144': 65,
    '145': 64.9,
    '146': 64.8,
    '147': 64.7,
    '148': 64.5,
    '149': 64.4,
    '150': 64.3,
    '151': 64.2,
    '152': 64,
    '153': 63.9,
    '154': 63.8,
    '155': 63.7,
    '156': 63.6,
    '157': 63.4,
    '158': 63.3,
    '159': 63.2,
    '160': 63.1,
    '161': 63,
    '162': 62.9,
    '163': 62.7,
    '164': 62.6,
    '165': 62.5,
    '166': 62.4,
    '167': 62.3,
    '168': 62.1,
    '169': 62,
    '170': 61.9,
    '171': 61.8,
    '172': 61.7,
    '173': 61.6,
    '174': 61.4,
    '175': 61.3,
    '176': 61.2,
    '177': 61.1,
    '178': 61,
    '179': 60.9,
    '180': 60.8,
    '181': 60.6,
    '182': 60.5,
    '183': 60.4,
    '184': 60.3,
    '185': 60.2,
    '186': 60.1,
    '187': 60,
    '188': 59.9,
    '189': 59.7,
    '190': 59.6,
    '191': 59.5,
    '192': 59.4,
    '193': 59.3,
    '194': 59.2,
    '195': 59.1,
    '0.5': 86.2,
    '1.5': 86,
    '2.5': 85.8,
    '3.5': 85.7,
    '4.5': 85.5,
    '5.5': 85.3,
    '6.5': 85.1,
    '7.5': 85,
    '8.5': 84.8,
    '9.5': 84.6,
    '10.5': 84.5,
    '11.5': 84.3,
    '12.5': 84.1,
    '13.5': 83.9,
    '14.5': 83.8,
    '15.5': 83.6,
    '16.5': 83.4,
    '17.5': 83.3,
    '18.5': 83.1,
    '19.5': 82.9,
    '20.5': 82.8,
    '21.5': 82.6,
    '22.5': 82.4,
    '23.5': 82.3,
    '24.5': 82.1,
    '25.5': 81.9,
    '26.5': 81.8,
    '27.5': 81.6,
    '28.5': 81.4,
    '29.5': 81.3,
    '30.5': 81.1,
    '31.5': 81,
    '32.5': 80.8,
    '33.5': 80.6,
    '34.5': 80.5,
    '35.5': 80.3,
    '36.5': 80.2,
    '37.5': 80,
    '38.5': 79.8,
    '39.5': 79.7,
    '40.5': 79.5,
    '41.5': 79.4,
    '42.5': 79.2,
    '43.5': 79,
    '44.5': 78.9,
    '45.5': 78.7,
    '46.5': 78.6,
    '47.5': 78.4,
    '48.5': 78.3,
    '49.5': 78.1,
    '50.5': 78,
    '51.5': 77.8,
    '52.5': 77.6,
    '53.5': 77.5,
    '54.5': 77.3,
    '55.5': 77.2,
    '56.5': 77,
    '57.5': 76.9,
    '58.5': 76.7,
    '59.5': 76.6,
    '60.5': 76.4,
    '61.5': 76.3,
    '62.5': 76.1,
    '63.5': 76,
    '64.5': 75.8,
    '65.5': 75.7,
    '66.5': 75.5,
    '67.5': 75.4,
    '68.5': 75.2,
    '69.5': 75.1,
    '70.5': 74.9,
    '71.5': 74.8,
    '72.5': 74.6,
    '73.5': 74.5,
    '74.5': 74.4,
    '75.5': 74.2,
    '76.5': 74.1,
    '77.5': 73.9,
    '78.5': 73.8,
    '79.5': 73.6,
    '80.5': 73.5,
    '81.5': 73.3,
    '82.5': 73.2,
    '83.5': 73.1,
    '84.5': 72.9,
    '85.5': 72.8,
    '86.5': 72.6,
    '87.5': 72.5,
    '88.5': 72.3,
    '89.5': 72.2,
    '90.5': 72.1,
    '91.5': 71.9,
    '92.5': 71.8,
    '93.5': 71.6,
    '94.5': 71.5,
    '95.5': 71.4,
    '96.5': 71.2,
    '97.5': 71.1,
    '98.5': 71,
    '99.5': 70.8,
    '100.5': 70.7,
    '101.5': 70.5,
    '102.5': 70.4,
    '103.5': 70.3,
    '104.5': 70.1,
    '105.5': 70,
    '106.5': 69.9,
    '107.5': 69.7,
    '108.5': 69.6,
    '109.5': 69.5,
    '110.5': 69.3,
    '111.5': 69.2,
    '112.5': 69.1,
    '113.5': 68.9,
    '114.5': 68.8,
    '115.5': 68.7,
    '116.5': 68.5,
    '117.5': 68.4,
    '118.5': 68.3,
    '119.5': 68.1,
    '120.5': 68,
    '121.5': 67.9,
    '122.5': 67.8,
    '123.5': 67.6,
    '124.5': 67.5,
    '125.5': 67.4,
    '126.5': 67.2,
    '127.5': 67.1,
    '128.5': 67,
    '129.5': 66.9,
    '130.5': 66.7,
    '131.5': 66.6,
    '132.5': 66.5,
    '133.5': 66.3,
    '134.5': 66.2,
    '135.5': 66.1,
    '136.5': 66,
    '137.5': 65.8,
    '138.5': 65.7,
    '139.5': 65.6,
    '140.5': 65.5,
    '141.5': 65.3,
    '142.5': 65.2,
    '143.5': 65.1,
    '144.5': 65,
    '145.5': 64.8,
    '146.5': 64.7,
    '147.5': 64.6,
    '148.5': 64.5,
    '149.5': 64.4,
    '150.5': 64.2,
    '151.5': 64.1,
    '152.5': 64,
    '153.5': 63.9,
    '154.5': 63.7,
    '155.5': 63.6,
    '156.5': 63.5,
    '157.5': 63.4,
    '158.5': 63.3,
    '159.5': 63.1,
    '160.5': 63,
    '161.5': 62.9,
    '162.5': 62.8,
    '163.5': 62.7,
    '164.5': 62.6,
    '165.5': 62.4,
    '166.5': 62.3,
    '167.5': 62.2,
    '168.5': 62.1,
    '169.5': 62,
    '170.5': 61.9,
    '171.5': 61.7,
    '172.5': 61.6,
    '173.5': 61.5,
    '174.5': 61.4,
    '175.5': 61.3,
    '176.5': 61.2,
    '177.5': 61,
    '178.5': 60.9,
    '179.5': 60.8,
    '180.5': 60.7,
    '181.5': 60.6,
    '182.5': 60.5,
    '183.5': 60.4,
    '184.5': 60.3,
    '185.5': 60.1,
    '186.5': 60,
    '187.5': 59.9,
    '188.5': 59.8,
    '189.5': 59.7,
    '190.5': 59.6,
    '191.5': 59.5,
    '192.5': 59.4,
    '193.5': 59.2,
    '194.5': 59.1,
  },
  '77': {
    '0': 86.9,
    '1': 86.7,
    '2': 86.5,
    '3': 86.3,
    '4': 86.1,
    '5': 86,
    '6': 85.8,
    '7': 85.6,
    '8': 85.4,
    '9': 85.3,
    '10': 85.1,
    '11': 84.9,
    '12': 84.8,
    '13': 84.6,
    '14': 84.4,
    '15': 84.2,
    '16': 84.1,
    '17': 83.9,
    '18': 83.7,
    '19': 83.6,
    '20': 83.4,
    '21': 83.2,
    '22': 83.1,
    '23': 82.9,
    '24': 82.7,
    '25': 82.6,
    '26': 82.4,
    '27': 82.2,
    '28': 82.1,
    '29': 81.9,
    '30': 81.7,
    '31': 81.6,
    '32': 81.4,
    '33': 81.2,
    '34': 81.1,
    '35': 80.9,
    '36': 80.8,
    '37': 80.6,
    '38': 80.4,
    '39': 80.3,
    '40': 80.1,
    '41': 80,
    '42': 79.8,
    '43': 79.6,
    '44': 79.5,
    '45': 79.3,
    '46': 79.2,
    '47': 79,
    '48': 78.9,
    '49': 78.7,
    '50': 78.5,
    '51': 78.4,
    '52': 78.2,
    '53': 78.1,
    '54': 77.9,
    '55': 77.8,
    '56': 77.6,
    '57': 77.5,
    '58': 77.3,
    '59': 77.1,
    '60': 77,
    '61': 76.8,
    '62': 76.7,
    '63': 76.5,
    '64': 76.4,
    '65': 76.2,
    '66': 76.1,
    '67': 75.9,
    '68': 75.8,
    '69': 75.6,
    '70': 75.5,
    '71': 75.4,
    '72': 75.2,
    '73': 75.1,
    '74': 74.9,
    '75': 74.8,
    '76': 74.6,
    '77': 74.5,
    '78': 74.3,
    '79': 74.2,
    '80': 74,
    '81': 73.9,
    '82': 73.7,
    '83': 73.6,
    '84': 73.5,
    '85': 73.3,
    '86': 73.2,
    '87': 73,
    '88': 72.9,
    '89': 72.7,
    '90': 72.6,
    '91': 72.5,
    '92': 72.3,
    '93': 72.2,
    '94': 72,
    '95': 71.9,
    '96': 71.8,
    '97': 71.6,
    '98': 71.5,
    '99': 71.3,
    '100': 71.2,
    '101': 71.1,
    '102': 70.9,
    '103': 70.8,
    '104': 70.7,
    '105': 70.5,
    '106': 70.4,
    '107': 70.3,
    '108': 70.1,
    '109': 70,
    '110': 69.8,
    '111': 69.7,
    '112': 69.6,
    '113': 69.4,
    '114': 69.3,
    '115': 69.2,
    '116': 69,
    '117': 68.9,
    '118': 68.8,
    '119': 68.7,
    '120': 68.5,
    '121': 68.4,
    '122': 68.3,
    '123': 68.1,
    '124': 68,
    '125': 67.9,
    '126': 67.7,
    '127': 67.6,
    '128': 67.5,
    '129': 67.3,
    '130': 67.2,
    '131': 67.1,
    '132': 67,
    '133': 66.8,
    '134': 66.7,
    '135': 66.6,
    '136': 66.5,
    '137': 66.3,
    '138': 66.2,
    '139': 66.1,
    '140': 65.9,
    '141': 65.8,
    '142': 65.7,
    '143': 65.6,
    '144': 65.5,
    '145': 65.3,
    '146': 65.2,
    '147': 65.1,
    '148': 65,
    '149': 64.8,
    '150': 64.7,
    '151': 64.6,
    '152': 64.5,
    '153': 64.3,
    '154': 64.2,
    '155': 64.1,
    '156': 64,
    '157': 63.9,
    '158': 63.7,
    '159': 63.6,
    '160': 63.5,
    '161': 63.4,
    '162': 63.3,
    '163': 63.1,
    '164': 63,
    '165': 62.9,
    '166': 62.8,
    '167': 62.7,
    '168': 62.5,
    '169': 62.4,
    '170': 62.3,
    '171': 62.2,
    '172': 62.1,
    '173': 62,
    '174': 61.8,
    '175': 61.7,
    '176': 61.6,
    '177': 61.5,
    '178': 61.4,
    '179': 61.3,
    '180': 61.2,
    '181': 61,
    '182': 60.9,
    '183': 60.8,
    '184': 60.7,
    '185': 60.6,
    '186': 60.5,
    '187': 60.4,
    '188': 60.2,
    '189': 60.1,
    '190': 60,
    '191': 59.9,
    '192': 59.8,
    '193': 59.7,
    '194': 59.6,
    '195': 59.5,
    '0.5': 86.8,
    '1.5': 86.6,
    '2.5': 86.4,
    '3.5': 86.2,
    '4.5': 86.1,
    '5.5': 85.9,
    '6.5': 85.7,
    '7.5': 85.5,
    '8.5': 85.4,
    '9.5': 85.2,
    '10.5': 85,
    '11.5': 84.8,
    '12.5': 84.7,
    '13.5': 84.5,
    '14.5': 84.3,
    '15.5': 84.2,
    '16.5': 84,
    '17.5': 83.8,
    '18.5': 83.7,
    '19.5': 83.5,
    '20.5': 83.3,
    '21.5': 83.1,
    '22.5': 83,
    '23.5': 82.8,
    '24.5': 82.6,
    '25.5': 82.5,
    '26.5': 82.3,
    '27.5': 82.1,
    '28.5': 82,
    '29.5': 81.8,
    '30.5': 81.7,
    '31.5': 81.5,
    '32.5': 81.3,
    '33.5': 81.2,
    '34.5': 81,
    '35.5': 80.8,
    '36.5': 80.7,
    '37.5': 80.5,
    '38.5': 80.4,
    '39.5': 80.2,
    '40.5': 80,
    '41.5': 79.9,
    '42.5': 79.7,
    '43.5': 79.6,
    '44.5': 79.4,
    '45.5': 79.2,
    '46.5': 79.1,
    '47.5': 78.9,
    '48.5': 78.8,
    '49.5': 78.6,
    '50.5': 78.5,
    '51.5': 78.3,
    '52.5': 78.2,
    '53.5': 78,
    '54.5': 77.8,
    '55.5': 77.7,
    '56.5': 77.5,
    '57.5': 77.4,
    '58.5': 77.2,
    '59.5': 77.1,
    '60.5': 76.9,
    '61.5': 76.8,
    '62.5': 76.6,
    '63.5': 76.5,
    '64.5': 76.3,
    '65.5': 76.2,
    '66.5': 76,
    '67.5': 75.9,
    '68.5': 75.7,
    '69.5': 75.6,
    '70.5': 75.4,
    '71.5': 75.3,
    '72.5': 75.1,
    '73.5': 75,
    '74.5': 74.8,
    '75.5': 74.7,
    '76.5': 74.5,
    '77.5': 74.4,
    '78.5': 74.2,
    '79.5': 74.1,
    '80.5': 74,
    '81.5': 73.8,
    '82.5': 73.7,
    '83.5': 73.5,
    '84.5': 73.4,
    '85.5': 73.2,
    '86.5': 73.1,
    '87.5': 73,
    '88.5': 72.8,
    '89.5': 72.7,
    '90.5': 72.5,
    '91.5': 72.4,
    '92.5': 72.3,
    '93.5': 72.1,
    '94.5': 72,
    '95.5': 71.8,
    '96.5': 71.7,
    '97.5': 71.6,
    '98.5': 71.4,
    '99.5': 71.3,
    '100.5': 71.1,
    '101.5': 71,
    '102.5': 70.9,
    '103.5': 70.7,
    '104.5': 70.6,
    '105.5': 70.5,
    '106.5': 70.3,
    '107.5': 70.2,
    '108.5': 70,
    '109.5': 69.9,
    '110.5': 69.8,
    '111.5': 69.6,
    '112.5': 69.5,
    '113.5': 69.4,
    '114.5': 69.2,
    '115.5': 69.1,
    '116.5': 69,
    '117.5': 68.8,
    '118.5': 68.7,
    '119.5': 68.6,
    '120.5': 68.5,
    '121.5': 68.3,
    '122.5': 68.2,
    '123.5': 68.1,
    '124.5': 67.9,
    '125.5': 67.8,
    '126.5': 67.7,
    '127.5': 67.5,
    '128.5': 67.4,
    '129.5': 67.3,
    '130.5': 67.2,
    '131.5': 67,
    '132.5': 66.9,
    '133.5': 66.8,
    '134.5': 66.6,
    '135.5': 66.5,
    '136.5': 66.4,
    '137.5': 66.3,
    '138.5': 66.1,
    '139.5': 66,
    '140.5': 65.9,
    '141.5': 65.8,
    '142.5': 65.6,
    '143.5': 65.5,
    '144.5': 65.4,
    '145.5': 65.3,
    '146.5': 65.1,
    '147.5': 65,
    '148.5': 64.9,
    '149.5': 64.8,
    '150.5': 64.6,
    '151.5': 64.5,
    '152.5': 64.4,
    '153.5': 64.3,
    '154.5': 64.2,
    '155.5': 64,
    '156.5': 63.9,
    '157.5': 63.8,
    '158.5': 63.7,
    '159.5': 63.6,
    '160.5': 63.4,
    '161.5': 63.3,
    '162.5': 63.2,
    '163.5': 63.1,
    '164.5': 63,
    '165.5': 62.8,
    '166.5': 62.7,
    '167.5': 62.6,
    '168.5': 62.5,
    '169.5': 62.4,
    '170.5': 62.3,
    '171.5': 62.1,
    '172.5': 62,
    '173.5': 61.9,
    '174.5': 61.8,
    '175.5': 61.7,
    '176.5': 61.6,
    '177.5': 61.4,
    '178.5': 61.3,
    '179.5': 61.2,
    '180.5': 61.1,
    '181.5': 61,
    '182.5': 60.9,
    '183.5': 60.8,
    '184.5': 60.6,
    '185.5': 60.5,
    '186.5': 60.4,
    '187.5': 60.3,
    '188.5': 60.2,
    '189.5': 60.1,
    '190.5': 60,
    '191.5': 59.9,
    '192.5': 59.7,
    '193.5': 59.6,
    '194.5': 59.5,
  },
  '77.5': {
    '0': 87.4,
    '1': 87.2,
    '2': 87.1,
    '3': 86.9,
    '4': 86.7,
    '5': 86.5,
    '6': 86.4,
    '7': 86.2,
    '8': 86,
    '9': 85.8,
    '10': 85.7,
    '11': 85.5,
    '12': 85.3,
    '13': 85.1,
    '14': 85,
    '15': 84.8,
    '16': 84.6,
    '17': 84.5,
    '18': 84.3,
    '19': 84.1,
    '20': 83.9,
    '21': 83.8,
    '22': 83.6,
    '23': 83.4,
    '24': 83.3,
    '25': 83.1,
    '26': 82.9,
    '27': 82.8,
    '28': 82.6,
    '29': 82.4,
    '30': 82.3,
    '31': 82.1,
    '32': 81.9,
    '33': 81.8,
    '34': 81.6,
    '35': 81.5,
    '36': 81.3,
    '37': 81.1,
    '38': 81,
    '39': 80.8,
    '40': 80.6,
    '41': 80.5,
    '42': 80.3,
    '43': 80.2,
    '44': 80,
    '45': 79.8,
    '46': 79.7,
    '47': 79.5,
    '48': 79.4,
    '49': 79.2,
    '50': 79.1,
    '51': 78.9,
    '52': 78.7,
    '53': 78.6,
    '54': 78.4,
    '55': 78.3,
    '56': 78.1,
    '57': 78,
    '58': 77.8,
    '59': 77.7,
    '60': 77.5,
    '61': 77.4,
    '62': 77.2,
    '63': 77,
    '64': 76.9,
    '65': 76.7,
    '66': 76.6,
    '67': 76.4,
    '68': 76.3,
    '69': 76.1,
    '70': 76,
    '71': 75.8,
    '72': 75.7,
    '73': 75.5,
    '74': 75.4,
    '75': 75.2,
    '76': 75.1,
    '77': 75,
    '78': 74.8,
    '79': 74.7,
    '80': 74.5,
    '81': 74.4,
    '82': 74.2,
    '83': 74.1,
    '84': 73.9,
    '85': 73.8,
    '86': 73.6,
    '87': 73.5,
    '88': 73.4,
    '89': 73.2,
    '90': 73.1,
    '91': 72.9,
    '92': 72.8,
    '93': 72.6,
    '94': 72.5,
    '95': 72.4,
    '96': 72.2,
    '97': 72.1,
    '98': 71.9,
    '99': 71.8,
    '100': 71.7,
    '101': 71.5,
    '102': 71.4,
    '103': 71.3,
    '104': 71.1,
    '105': 71,
    '106': 70.8,
    '107': 70.7,
    '108': 70.6,
    '109': 70.4,
    '110': 70.3,
    '111': 70.2,
    '112': 70,
    '113': 69.9,
    '114': 69.8,
    '115': 69.6,
    '116': 69.5,
    '117': 69.4,
    '118': 69.2,
    '119': 69.1,
    '120': 69,
    '121': 68.8,
    '122': 68.7,
    '123': 68.6,
    '124': 68.4,
    '125': 68.3,
    '126': 68.2,
    '127': 68,
    '128': 67.9,
    '129': 67.8,
    '130': 67.7,
    '131': 67.5,
    '132': 67.4,
    '133': 67.3,
    '134': 67.1,
    '135': 67,
    '136': 66.9,
    '137': 66.8,
    '138': 66.6,
    '139': 66.5,
    '140': 66.4,
    '141': 66.3,
    '142': 66.1,
    '143': 66,
    '144': 65.9,
    '145': 65.7,
    '146': 65.6,
    '147': 65.5,
    '148': 65.4,
    '149': 65.3,
    '150': 65.1,
    '151': 65,
    '152': 64.9,
    '153': 64.8,
    '154': 64.6,
    '155': 64.5,
    '156': 64.4,
    '157': 64.3,
    '158': 64.2,
    '159': 64,
    '160': 63.9,
    '161': 63.8,
    '162': 63.7,
    '163': 63.5,
    '164': 63.4,
    '165': 63.3,
    '166': 63.2,
    '167': 63.1,
    '168': 63,
    '169': 62.8,
    '170': 62.7,
    '171': 62.6,
    '172': 62.5,
    '173': 62.4,
    '174': 62.2,
    '175': 62.1,
    '176': 62,
    '177': 61.9,
    '178': 61.8,
    '179': 61.7,
    '180': 61.6,
    '181': 61.4,
    '182': 61.3,
    '183': 61.2,
    '184': 61.1,
    '185': 61,
    '186': 60.9,
    '187': 60.8,
    '188': 60.6,
    '189': 60.5,
    '190': 60.4,
    '191': 60.3,
    '192': 60.2,
    '193': 60.1,
    '194': 60,
    '195': 59.9,
    '0.5': 87.3,
    '1.5': 87.2,
    '2.5': 87,
    '3.5': 86.8,
    '4.5': 86.6,
    '5.5': 86.5,
    '6.5': 86.3,
    '7.5': 86.1,
    '8.5': 85.9,
    '9.5': 85.8,
    '10.5': 85.6,
    '11.5': 85.4,
    '12.5': 85.2,
    '13.5': 85.1,
    '14.5': 84.9,
    '15.5': 84.7,
    '16.5': 84.5,
    '17.5': 84.4,
    '18.5': 84.2,
    '19.5': 84,
    '20.5': 83.9,
    '21.5': 83.7,
    '22.5': 83.5,
    '23.5': 83.4,
    '24.5': 83.2,
    '25.5': 83,
    '26.5': 82.9,
    '27.5': 82.7,
    '28.5': 82.5,
    '29.5': 82.4,
    '30.5': 82.2,
    '31.5': 82,
    '32.5': 81.9,
    '33.5': 81.7,
    '34.5': 81.5,
    '35.5': 81.4,
    '36.5': 81.2,
    '37.5': 81,
    '38.5': 80.9,
    '39.5': 80.7,
    '40.5': 80.6,
    '41.5': 80.4,
    '42.5': 80.2,
    '43.5': 80.1,
    '44.5': 79.9,
    '45.5': 79.8,
    '46.5': 79.6,
    '47.5': 79.4,
    '48.5': 79.3,
    '49.5': 79.1,
    '50.5': 79,
    '51.5': 78.8,
    '52.5': 78.7,
    '53.5': 78.5,
    '54.5': 78.3,
    '55.5': 78.2,
    '56.5': 78,
    '57.5': 77.9,
    '58.5': 77.7,
    '59.5': 77.6,
    '60.5': 77.4,
    '61.5': 77.3,
    '62.5': 77.1,
    '63.5': 77,
    '64.5': 76.8,
    '65.5': 76.7,
    '66.5': 76.5,
    '67.5': 76.4,
    '68.5': 76.2,
    '69.5': 76.1,
    '70.5': 75.9,
    '71.5': 75.8,
    '72.5': 75.6,
    '73.5': 75.5,
    '74.5': 75.3,
    '75.5': 75.2,
    '76.5': 75,
    '77.5': 74.9,
    '78.5': 74.7,
    '79.5': 74.6,
    '80.5': 74.4,
    '81.5': 74.3,
    '82.5': 74.2,
    '83.5': 74,
    '84.5': 73.9,
    '85.5': 73.7,
    '86.5': 73.6,
    '87.5': 73.4,
    '88.5': 73.3,
    '89.5': 73.1,
    '90.5': 73,
    '91.5': 72.9,
    '92.5': 72.7,
    '93.5': 72.6,
    '94.5': 72.4,
    '95.5': 72.3,
    '96.5': 72.2,
    '97.5': 72,
    '98.5': 71.9,
    '99.5': 71.7,
    '100.5': 71.6,
    '101.5': 71.5,
    '102.5': 71.3,
    '103.5': 71.2,
    '104.5': 71,
    '105.5': 70.9,
    '106.5': 70.8,
    '107.5': 70.6,
    '108.5': 70.5,
    '109.5': 70.4,
    '110.5': 70.2,
    '111.5': 70.1,
    '112.5': 70,
    '113.5': 69.8,
    '114.5': 69.7,
    '115.5': 69.6,
    '116.5': 69.4,
    '117.5': 69.3,
    '118.5': 69.2,
    '119.5': 69,
    '120.5': 68.9,
    '121.5': 68.8,
    '122.5': 68.6,
    '123.5': 68.5,
    '124.5': 68.4,
    '125.5': 68.2,
    '126.5': 68.1,
    '127.5': 68,
    '128.5': 67.8,
    '129.5': 67.7,
    '130.5': 67.6,
    '131.5': 67.5,
    '132.5': 67.3,
    '133.5': 67.2,
    '134.5': 67.1,
    '135.5': 67,
    '136.5': 66.8,
    '137.5': 66.7,
    '138.5': 66.6,
    '139.5': 66.4,
    '140.5': 66.3,
    '141.5': 66.2,
    '142.5': 66.1,
    '143.5': 65.9,
    '144.5': 65.8,
    '145.5': 65.7,
    '146.5': 65.6,
    '147.5': 65.4,
    '148.5': 65.3,
    '149.5': 65.2,
    '150.5': 65.1,
    '151.5': 64.9,
    '152.5': 64.8,
    '153.5': 64.7,
    '154.5': 64.6,
    '155.5': 64.5,
    '156.5': 64.3,
    '157.5': 64.2,
    '158.5': 64.1,
    '159.5': 64,
    '160.5': 63.8,
    '161.5': 63.7,
    '162.5': 63.6,
    '163.5': 63.5,
    '164.5': 63.4,
    '165.5': 63.3,
    '166.5': 63.1,
    '167.5': 63,
    '168.5': 62.9,
    '169.5': 62.8,
    '170.5': 62.7,
    '171.5': 62.5,
    '172.5': 62.4,
    '173.5': 62.3,
    '174.5': 62.2,
    '175.5': 62.1,
    '176.5': 62,
    '177.5': 61.8,
    '178.5': 61.7,
    '179.5': 61.6,
    '180.5': 61.5,
    '181.5': 61.4,
    '182.5': 61.3,
    '183.5': 61.2,
    '184.5': 61,
    '185.5': 60.9,
    '186.5': 60.8,
    '187.5': 60.7,
    '188.5': 60.6,
    '189.5': 60.5,
    '190.5': 60.4,
    '191.5': 60.2,
    '192.5': 60.1,
    '193.5': 60,
    '194.5': 59.9,
  },
  '78': {
    '0': 88,
    '1': 87.8,
    '2': 87.6,
    '3': 87.5,
    '4': 87.3,
    '5': 87.1,
    '6': 86.9,
    '7': 86.8,
    '8': 86.6,
    '9': 86.4,
    '10': 86.2,
    '11': 86.1,
    '12': 85.9,
    '13': 85.7,
    '14': 85.5,
    '15': 85.4,
    '16': 85.2,
    '17': 85,
    '18': 84.8,
    '19': 84.7,
    '20': 84.5,
    '21': 84.3,
    '22': 84.2,
    '23': 84,
    '24': 83.8,
    '25': 83.6,
    '26': 83.5,
    '27': 83.3,
    '28': 83.1,
    '29': 83,
    '30': 82.8,
    '31': 82.6,
    '32': 82.5,
    '33': 82.3,
    '34': 82.1,
    '35': 82,
    '36': 81.8,
    '37': 81.7,
    '38': 81.5,
    '39': 81.3,
    '40': 81.2,
    '41': 81,
    '42': 80.8,
    '43': 80.7,
    '44': 80.5,
    '45': 80.4,
    '46': 80.2,
    '47': 80,
    '48': 79.9,
    '49': 79.7,
    '50': 79.6,
    '51': 79.4,
    '52': 79.2,
    '53': 79.1,
    '54': 78.9,
    '55': 78.8,
    '56': 78.6,
    '57': 78.5,
    '58': 78.3,
    '59': 78.2,
    '60': 78,
    '61': 77.8,
    '62': 77.7,
    '63': 77.5,
    '64': 77.4,
    '65': 77.2,
    '66': 77.1,
    '67': 76.9,
    '68': 76.8,
    '69': 76.6,
    '70': 76.5,
    '71': 76.3,
    '72': 76.2,
    '73': 76,
    '74': 75.9,
    '75': 75.7,
    '76': 75.6,
    '77': 75.4,
    '78': 75.3,
    '79': 75.1,
    '80': 75,
    '81': 74.8,
    '82': 74.7,
    '83': 74.6,
    '84': 74.4,
    '85': 74.3,
    '86': 74.1,
    '87': 74,
    '88': 73.8,
    '89': 73.7,
    '90': 73.5,
    '91': 73.4,
    '92': 73.3,
    '93': 73.1,
    '94': 73,
    '95': 72.8,
    '96': 72.7,
    '97': 72.5,
    '98': 72.4,
    '99': 72.3,
    '100': 72.1,
    '101': 72,
    '102': 71.8,
    '103': 71.7,
    '104': 71.6,
    '105': 71.4,
    '106': 71.3,
    '107': 71.2,
    '108': 71,
    '109': 70.9,
    '110': 70.7,
    '111': 70.6,
    '112': 70.5,
    '113': 70.3,
    '114': 70.2,
    '115': 70.1,
    '116': 69.9,
    '117': 69.8,
    '118': 69.7,
    '119': 69.5,
    '120': 69.4,
    '121': 69.3,
    '122': 69.1,
    '123': 69,
    '124': 68.9,
    '125': 68.7,
    '126': 68.6,
    '127': 68.5,
    '128': 68.3,
    '129': 68.2,
    '130': 68.1,
    '131': 68,
    '132': 67.8,
    '133': 67.7,
    '134': 67.6,
    '135': 67.4,
    '136': 67.3,
    '137': 67.2,
    '138': 67.1,
    '139': 66.9,
    '140': 66.8,
    '141': 66.7,
    '142': 66.5,
    '143': 66.4,
    '144': 66.3,
    '145': 66.2,
    '146': 66,
    '147': 65.9,
    '148': 65.8,
    '149': 65.7,
    '150': 65.5,
    '151': 65.4,
    '152': 65.3,
    '153': 65.2,
    '154': 65,
    '155': 64.9,
    '156': 64.8,
    '157': 64.7,
    '158': 64.6,
    '159': 64.4,
    '160': 64.3,
    '161': 64.2,
    '162': 64.1,
    '163': 64,
    '164': 63.8,
    '165': 63.7,
    '166': 63.6,
    '167': 63.5,
    '168': 63.4,
    '169': 63.2,
    '170': 63.1,
    '171': 63,
    '172': 62.9,
    '173': 62.8,
    '174': 62.6,
    '175': 62.5,
    '176': 62.4,
    '177': 62.3,
    '178': 62.2,
    '179': 62.1,
    '180': 61.9,
    '181': 61.8,
    '182': 61.7,
    '183': 61.6,
    '184': 61.5,
    '185': 61.4,
    '186': 61.3,
    '187': 61.1,
    '188': 61,
    '189': 60.9,
    '190': 60.8,
    '191': 60.7,
    '192': 60.6,
    '193': 60.5,
    '194': 60.3,
    '195': 60.2,
    '0.5': 87.9,
    '1.5': 87.7,
    '2.5': 87.6,
    '3.5': 87.4,
    '4.5': 87.2,
    '5.5': 87,
    '6.5': 86.8,
    '7.5': 86.7,
    '8.5': 86.5,
    '9.5': 86.3,
    '10.5': 86.1,
    '11.5': 86,
    '12.5': 85.8,
    '13.5': 85.6,
    '14.5': 85.4,
    '15.5': 85.3,
    '16.5': 85.1,
    '17.5': 84.9,
    '18.5': 84.8,
    '19.5': 84.6,
    '20.5': 84.4,
    '21.5': 84.2,
    '22.5': 84.1,
    '23.5': 83.9,
    '24.5': 83.7,
    '25.5': 83.6,
    '26.5': 83.4,
    '27.5': 83.2,
    '28.5': 83.1,
    '29.5': 82.9,
    '30.5': 82.7,
    '31.5': 82.6,
    '32.5': 82.4,
    '33.5': 82.2,
    '34.5': 82.1,
    '35.5': 81.9,
    '36.5': 81.7,
    '37.5': 81.6,
    '38.5': 81.4,
    '39.5': 81.2,
    '40.5': 81.1,
    '41.5': 80.9,
    '42.5': 80.8,
    '43.5': 80.6,
    '44.5': 80.4,
    '45.5': 80.3,
    '46.5': 80.1,
    '47.5': 80,
    '48.5': 79.8,
    '49.5': 79.6,
    '50.5': 79.5,
    '51.5': 79.3,
    '52.5': 79.2,
    '53.5': 79,
    '54.5': 78.9,
    '55.5': 78.7,
    '56.5': 78.5,
    '57.5': 78.4,
    '58.5': 78.2,
    '59.5': 78.1,
    '60.5': 77.9,
    '61.5': 77.8,
    '62.5': 77.6,
    '63.5': 77.5,
    '64.5': 77.3,
    '65.5': 77.2,
    '66.5': 77,
    '67.5': 76.9,
    '68.5': 76.7,
    '69.5': 76.6,
    '70.5': 76.4,
    '71.5': 76.3,
    '72.5': 76.1,
    '73.5': 76,
    '74.5': 75.8,
    '75.5': 75.7,
    '76.5': 75.5,
    '77.5': 75.4,
    '78.5': 75.2,
    '79.5': 75.1,
    '80.5': 74.9,
    '81.5': 74.8,
    '82.5': 74.6,
    '83.5': 74.5,
    '84.5': 74.3,
    '85.5': 74.2,
    '86.5': 74,
    '87.5': 73.9,
    '88.5': 73.8,
    '89.5': 73.6,
    '90.5': 73.5,
    '91.5': 73.3,
    '92.5': 73.2,
    '93.5': 73,
    '94.5': 72.9,
    '95.5': 72.8,
    '96.5': 72.6,
    '97.5': 72.5,
    '98.5': 72.3,
    '99.5': 72.2,
    '100.5': 72.1,
    '101.5': 71.9,
    '102.5': 71.8,
    '103.5': 71.6,
    '104.5': 71.5,
    '105.5': 71.4,
    '106.5': 71.2,
    '107.5': 71.1,
    '108.5': 71,
    '109.5': 70.8,
    '110.5': 70.7,
    '111.5': 70.5,
    '112.5': 70.4,
    '113.5': 70.3,
    '114.5': 70.1,
    '115.5': 70,
    '116.5': 69.9,
    '117.5': 69.7,
    '118.5': 69.6,
    '119.5': 69.5,
    '120.5': 69.3,
    '121.5': 69.2,
    '122.5': 69.1,
    '123.5': 68.9,
    '124.5': 68.8,
    '125.5': 68.7,
    '126.5': 68.5,
    '127.5': 68.4,
    '128.5': 68.3,
    '129.5': 68.2,
    '130.5': 68,
    '131.5': 67.9,
    '132.5': 67.8,
    '133.5': 67.6,
    '134.5': 67.5,
    '135.5': 67.4,
    '136.5': 67.2,
    '137.5': 67.1,
    '138.5': 67,
    '139.5': 66.9,
    '140.5': 66.7,
    '141.5': 66.6,
    '142.5': 66.5,
    '143.5': 66.4,
    '144.5': 66.2,
    '145.5': 66.1,
    '146.5': 66,
    '147.5': 65.9,
    '148.5': 65.7,
    '149.5': 65.6,
    '150.5': 65.5,
    '151.5': 65.4,
    '152.5': 65.2,
    '153.5': 65.1,
    '154.5': 65,
    '155.5': 64.9,
    '156.5': 64.7,
    '157.5': 64.6,
    '158.5': 64.5,
    '159.5': 64.4,
    '160.5': 64.3,
    '161.5': 64.1,
    '162.5': 64,
    '163.5': 63.9,
    '164.5': 63.8,
    '165.5': 63.7,
    '166.5': 63.5,
    '167.5': 63.4,
    '168.5': 63.3,
    '169.5': 63.2,
    '170.5': 63.1,
    '171.5': 62.9,
    '172.5': 62.8,
    '173.5': 62.7,
    '174.5': 62.6,
    '175.5': 62.5,
    '176.5': 62.4,
    '177.5': 62.2,
    '178.5': 62.1,
    '179.5': 62,
    '180.5': 61.9,
    '181.5': 61.8,
    '182.5': 61.7,
    '183.5': 61.5,
    '184.5': 61.4,
    '185.5': 61.3,
    '186.5': 61.2,
    '187.5': 61.1,
    '188.5': 61,
    '189.5': 60.9,
    '190.5': 60.7,
    '191.5': 60.6,
    '192.5': 60.5,
    '193.5': 60.4,
    '194.5': 60.3,
  },
  '78.5': {
    '0': 88.6,
    '1': 88.4,
    '2': 88.2,
    '3': 88,
    '4': 87.9,
    '5': 87.7,
    '6': 87.5,
    '7': 87.3,
    '8': 87.1,
    '9': 87,
    '10': 86.8,
    '11': 86.6,
    '12': 86.4,
    '13': 86.3,
    '14': 86.1,
    '15': 85.9,
    '16': 85.7,
    '17': 85.6,
    '18': 85.4,
    '19': 85.2,
    '20': 85,
    '21': 84.9,
    '22': 84.7,
    '23': 84.5,
    '24': 84.4,
    '25': 84.2,
    '26': 84,
    '27': 83.9,
    '28': 83.7,
    '29': 83.5,
    '30': 83.3,
    '31': 83.2,
    '32': 83,
    '33': 82.8,
    '34': 82.7,
    '35': 82.5,
    '36': 82.3,
    '37': 82.2,
    '38': 82,
    '39': 81.9,
    '40': 81.7,
    '41': 81.5,
    '42': 81.4,
    '43': 81.2,
    '44': 81,
    '45': 80.9,
    '46': 80.7,
    '47': 80.6,
    '48': 80.4,
    '49': 80.2,
    '50': 80.1,
    '51': 79.9,
    '52': 79.8,
    '53': 79.6,
    '54': 79.4,
    '55': 79.3,
    '56': 79.1,
    '57': 79,
    '58': 78.8,
    '59': 78.7,
    '60': 78.5,
    '61': 78.3,
    '62': 78.2,
    '63': 78,
    '64': 77.9,
    '65': 77.7,
    '66': 77.6,
    '67': 77.4,
    '68': 77.3,
    '69': 77.1,
    '70': 77,
    '71': 76.8,
    '72': 76.7,
    '73': 76.5,
    '74': 76.4,
    '75': 76.2,
    '76': 76.1,
    '77': 75.9,
    '78': 75.8,
    '79': 75.6,
    '80': 75.5,
    '81': 75.3,
    '82': 75.2,
    '83': 75,
    '84': 74.9,
    '85': 74.7,
    '86': 74.6,
    '87': 74.4,
    '88': 74.3,
    '89': 74.2,
    '90': 74,
    '91': 73.9,
    '92': 73.7,
    '93': 73.6,
    '94': 73.4,
    '95': 73.3,
    '96': 73.2,
    '97': 73,
    '98': 72.9,
    '99': 72.7,
    '100': 72.6,
    '101': 72.4,
    '102': 72.3,
    '103': 72.2,
    '104': 72,
    '105': 71.9,
    '106': 71.7,
    '107': 71.6,
    '108': 71.5,
    '109': 71.3,
    '110': 71.2,
    '111': 71.1,
    '112': 70.9,
    '113': 70.8,
    '114': 70.7,
    '115': 70.5,
    '116': 70.4,
    '117': 70.2,
    '118': 70.1,
    '119': 70,
    '120': 69.8,
    '121': 69.7,
    '122': 69.6,
    '123': 69.4,
    '124': 69.3,
    '125': 69.2,
    '126': 69,
    '127': 68.9,
    '128': 68.8,
    '129': 68.7,
    '130': 68.5,
    '131': 68.4,
    '132': 68.3,
    '133': 68.1,
    '134': 68,
    '135': 67.9,
    '136': 67.7,
    '137': 67.6,
    '138': 67.5,
    '139': 67.4,
    '140': 67.2,
    '141': 67.1,
    '142': 67,
    '143': 66.8,
    '144': 66.7,
    '145': 66.6,
    '146': 66.5,
    '147': 66.3,
    '148': 66.2,
    '149': 66.1,
    '150': 66,
    '151': 65.8,
    '152': 65.7,
    '153': 65.6,
    '154': 65.5,
    '155': 65.3,
    '156': 65.2,
    '157': 65.1,
    '158': 65,
    '159': 64.8,
    '160': 64.7,
    '161': 64.6,
    '162': 64.5,
    '163': 64.4,
    '164': 64.2,
    '165': 64.1,
    '166': 64,
    '167': 63.9,
    '168': 63.8,
    '169': 63.6,
    '170': 63.5,
    '171': 63.4,
    '172': 63.3,
    '173': 63.2,
    '174': 63,
    '175': 62.9,
    '176': 62.8,
    '177': 62.7,
    '178': 62.6,
    '179': 62.5,
    '180': 62.3,
    '181': 62.2,
    '182': 62.1,
    '183': 62,
    '184': 61.9,
    '185': 61.8,
    '186': 61.6,
    '187': 61.5,
    '188': 61.4,
    '189': 61.3,
    '190': 61.2,
    '191': 61.1,
    '192': 61,
    '193': 60.8,
    '194': 60.7,
    '195': 60.6,
    '0.5': 88.5,
    '1.5': 88.3,
    '2.5': 88.1,
    '3.5': 88,
    '4.5': 87.8,
    '5.5': 87.6,
    '6.5': 87.4,
    '7.5': 87.2,
    '8.5': 87.1,
    '9.5': 86.9,
    '10.5': 86.7,
    '11.5': 86.5,
    '12.5': 86.4,
    '13.5': 86.2,
    '14.5': 86,
    '15.5': 85.8,
    '16.5': 85.7,
    '17.5': 85.5,
    '18.5': 85.3,
    '19.5': 85.1,
    '20.5': 85,
    '21.5': 84.8,
    '22.5': 84.6,
    '23.5': 84.4,
    '24.5': 84.3,
    '25.5': 84.1,
    '26.5': 83.9,
    '27.5': 83.8,
    '28.5': 83.6,
    '29.5': 83.4,
    '30.5': 83.3,
    '31.5': 83.1,
    '32.5': 82.9,
    '33.5': 82.8,
    '34.5': 82.6,
    '35.5': 82.4,
    '36.5': 82.3,
    '37.5': 82.1,
    '38.5': 81.9,
    '39.5': 81.8,
    '40.5': 81.6,
    '41.5': 81.4,
    '42.5': 81.3,
    '43.5': 81.1,
    '44.5': 81,
    '45.5': 80.8,
    '46.5': 80.6,
    '47.5': 80.5,
    '48.5': 80.3,
    '49.5': 80.2,
    '50.5': 80,
    '51.5': 79.8,
    '52.5': 79.7,
    '53.5': 79.5,
    '54.5': 79.4,
    '55.5': 79.2,
    '56.5': 79,
    '57.5': 78.9,
    '58.5': 78.7,
    '59.5': 78.6,
    '60.5': 78.4,
    '61.5': 78.3,
    '62.5': 78.1,
    '63.5': 78,
    '64.5': 77.8,
    '65.5': 77.7,
    '66.5': 77.5,
    '67.5': 77.3,
    '68.5': 77.2,
    '69.5': 77,
    '70.5': 76.9,
    '71.5': 76.7,
    '72.5': 76.6,
    '73.5': 76.4,
    '74.5': 76.3,
    '75.5': 76.1,
    '76.5': 76,
    '77.5': 75.8,
    '78.5': 75.7,
    '79.5': 75.5,
    '80.5': 75.4,
    '81.5': 75.2,
    '82.5': 75.1,
    '83.5': 75,
    '84.5': 74.8,
    '85.5': 74.7,
    '86.5': 74.5,
    '87.5': 74.4,
    '88.5': 74.2,
    '89.5': 74.1,
    '90.5': 73.9,
    '91.5': 73.8,
    '92.5': 73.6,
    '93.5': 73.5,
    '94.5': 73.4,
    '95.5': 73.2,
    '96.5': 73.1,
    '97.5': 72.9,
    '98.5': 72.8,
    '99.5': 72.7,
    '100.5': 72.5,
    '101.5': 72.4,
    '102.5': 72.2,
    '103.5': 72.1,
    '104.5': 72,
    '105.5': 71.8,
    '106.5': 71.7,
    '107.5': 71.5,
    '108.5': 71.4,
    '109.5': 71.3,
    '110.5': 71.1,
    '111.5': 71,
    '112.5': 70.9,
    '113.5': 70.7,
    '114.5': 70.6,
    '115.5': 70.4,
    '116.5': 70.3,
    '117.5': 70.2,
    '118.5': 70,
    '119.5': 69.9,
    '120.5': 69.8,
    '121.5': 69.6,
    '122.5': 69.5,
    '123.5': 69.4,
    '124.5': 69.2,
    '125.5': 69.1,
    '126.5': 69,
    '127.5': 68.8,
    '128.5': 68.7,
    '129.5': 68.6,
    '130.5': 68.5,
    '131.5': 68.3,
    '132.5': 68.2,
    '133.5': 68.1,
    '134.5': 67.9,
    '135.5': 67.8,
    '136.5': 67.7,
    '137.5': 67.5,
    '138.5': 67.4,
    '139.5': 67.3,
    '140.5': 67.2,
    '141.5': 67,
    '142.5': 66.9,
    '143.5': 66.8,
    '144.5': 66.7,
    '145.5': 66.5,
    '146.5': 66.4,
    '147.5': 66.3,
    '148.5': 66.1,
    '149.5': 66,
    '150.5': 65.9,
    '151.5': 65.8,
    '152.5': 65.6,
    '153.5': 65.5,
    '154.5': 65.4,
    '155.5': 65.3,
    '156.5': 65.2,
    '157.5': 65,
    '158.5': 64.9,
    '159.5': 64.8,
    '160.5': 64.7,
    '161.5': 64.5,
    '162.5': 64.4,
    '163.5': 64.3,
    '164.5': 64.2,
    '165.5': 64.1,
    '166.5': 63.9,
    '167.5': 63.8,
    '168.5': 63.7,
    '169.5': 63.6,
    '170.5': 63.5,
    '171.5': 63.3,
    '172.5': 63.2,
    '173.5': 63.1,
    '174.5': 63,
    '175.5': 62.9,
    '176.5': 62.8,
    '177.5': 62.6,
    '178.5': 62.5,
    '179.5': 62.4,
    '180.5': 62.3,
    '181.5': 62.2,
    '182.5': 62.1,
    '183.5': 61.9,
    '184.5': 61.8,
    '185.5': 61.7,
    '186.5': 61.6,
    '187.5': 61.5,
    '188.5': 61.4,
    '189.5': 61.2,
    '190.5': 61.1,
    '191.5': 61,
    '192.5': 60.9,
    '193.5': 60.8,
    '194.5': 60.7,
  },
  '79': {
    '0': 89.2,
    '1': 89,
    '2': 88.8,
    '3': 88.6,
    '4': 88.4,
    '5': 88.3,
    '6': 88.1,
    '7': 87.9,
    '8': 87.7,
    '9': 87.5,
    '10': 87.4,
    '11': 87.2,
    '12': 87,
    '13': 86.8,
    '14': 86.6,
    '15': 86.5,
    '16': 86.3,
    '17': 86.1,
    '18': 85.9,
    '19': 85.8,
    '20': 85.6,
    '21': 85.4,
    '22': 85.3,
    '23': 85.1,
    '24': 84.9,
    '25': 84.7,
    '26': 84.6,
    '27': 84.4,
    '28': 84.2,
    '29': 84.1,
    '30': 83.9,
    '31': 83.7,
    '32': 83.5,
    '33': 83.4,
    '34': 83.2,
    '35': 83,
    '36': 82.9,
    '37': 82.7,
    '38': 82.5,
    '39': 82.4,
    '40': 82.2,
    '41': 82.1,
    '42': 81.9,
    '43': 81.7,
    '44': 81.6,
    '45': 81.4,
    '46': 81.2,
    '47': 81.1,
    '48': 80.9,
    '49': 80.7,
    '50': 80.6,
    '51': 80.4,
    '52': 80.3,
    '53': 80.1,
    '54': 79.9,
    '55': 79.8,
    '56': 79.6,
    '57': 79.5,
    '58': 79.3,
    '59': 79.2,
    '60': 79,
    '61': 78.8,
    '62': 78.7,
    '63': 78.5,
    '64': 78.4,
    '65': 78.2,
    '66': 78.1,
    '67': 77.9,
    '68': 77.8,
    '69': 77.6,
    '70': 77.5,
    '71': 77.3,
    '72': 77.1,
    '73': 77,
    '74': 76.8,
    '75': 76.7,
    '76': 76.5,
    '77': 76.4,
    '78': 76.2,
    '79': 76.1,
    '80': 75.9,
    '81': 75.8,
    '82': 75.7,
    '83': 75.5,
    '84': 75.4,
    '85': 75.2,
    '86': 75.1,
    '87': 74.9,
    '88': 74.8,
    '89': 74.6,
    '90': 74.5,
    '91': 74.3,
    '92': 74.2,
    '93': 74,
    '94': 73.9,
    '95': 73.8,
    '96': 73.6,
    '97': 73.5,
    '98': 73.3,
    '99': 73.2,
    '100': 73,
    '101': 72.9,
    '102': 72.8,
    '103': 72.6,
    '104': 72.5,
    '105': 72.3,
    '106': 72.2,
    '107': 72.1,
    '108': 71.9,
    '109': 71.8,
    '110': 71.6,
    '111': 71.5,
    '112': 71.4,
    '113': 71.2,
    '114': 71.1,
    '115': 71,
    '116': 70.8,
    '117': 70.7,
    '118': 70.6,
    '119': 70.4,
    '120': 70.3,
    '121': 70.1,
    '122': 70,
    '123': 69.9,
    '124': 69.7,
    '125': 69.6,
    '126': 69.5,
    '127': 69.3,
    '128': 69.2,
    '129': 69.1,
    '130': 69,
    '131': 68.8,
    '132': 68.7,
    '133': 68.6,
    '134': 68.4,
    '135': 68.3,
    '136': 68.2,
    '137': 68,
    '138': 67.9,
    '139': 67.8,
    '140': 67.6,
    '141': 67.5,
    '142': 67.4,
    '143': 67.3,
    '144': 67.1,
    '145': 67,
    '146': 66.9,
    '147': 66.8,
    '148': 66.6,
    '149': 66.5,
    '150': 66.4,
    '151': 66.3,
    '152': 66.1,
    '153': 66,
    '154': 65.9,
    '155': 65.8,
    '156': 65.6,
    '157': 65.5,
    '158': 65.4,
    '159': 65.3,
    '160': 65.1,
    '161': 65,
    '162': 64.9,
    '163': 64.8,
    '164': 64.6,
    '165': 64.5,
    '166': 64.4,
    '167': 64.3,
    '168': 64.2,
    '169': 64,
    '170': 63.9,
    '171': 63.8,
    '172': 63.7,
    '173': 63.6,
    '174': 63.4,
    '175': 63.3,
    '176': 63.2,
    '177': 63.1,
    '178': 63,
    '179': 62.9,
    '180': 62.7,
    '181': 62.6,
    '182': 62.5,
    '183': 62.4,
    '184': 62.3,
    '185': 62.2,
    '186': 62,
    '187': 61.9,
    '188': 61.8,
    '189': 61.7,
    '190': 61.6,
    '191': 61.5,
    '192': 61.3,
    '193': 61.2,
    '194': 61.1,
    '195': 61,
    '0.5': 89.1,
    '1.5': 88.9,
    '2.5': 88.7,
    '3.5': 88.5,
    '4.5': 88.3,
    '5.5': 88.2,
    '6.5': 88,
    '7.5': 87.8,
    '8.5': 87.6,
    '9.5': 87.4,
    '10.5': 87.3,
    '11.5': 87.1,
    '12.5': 86.9,
    '13.5': 86.7,
    '14.5': 86.6,
    '15.5': 86.4,
    '16.5': 86.2,
    '17.5': 86,
    '18.5': 85.9,
    '19.5': 85.7,
    '20.5': 85.5,
    '21.5': 85.3,
    '22.5': 85.2,
    '23.5': 85,
    '24.5': 84.8,
    '25.5': 84.6,
    '26.5': 84.5,
    '27.5': 84.3,
    '28.5': 84.1,
    '29.5': 84,
    '30.5': 83.8,
    '31.5': 83.6,
    '32.5': 83.5,
    '33.5': 83.3,
    '34.5': 83.1,
    '35.5': 83,
    '36.5': 82.8,
    '37.5': 82.6,
    '38.5': 82.5,
    '39.5': 82.3,
    '40.5': 82.1,
    '41.5': 82,
    '42.5': 81.8,
    '43.5': 81.6,
    '44.5': 81.5,
    '45.5': 81.3,
    '46.5': 81.1,
    '47.5': 81,
    '48.5': 80.8,
    '49.5': 80.7,
    '50.5': 80.5,
    '51.5': 80.3,
    '52.5': 80.2,
    '53.5': 80,
    '54.5': 79.9,
    '55.5': 79.7,
    '56.5': 79.6,
    '57.5': 79.4,
    '58.5': 79.2,
    '59.5': 79.1,
    '60.5': 78.9,
    '61.5': 78.8,
    '62.5': 78.6,
    '63.5': 78.5,
    '64.5': 78.3,
    '65.5': 78.1,
    '66.5': 78,
    '67.5': 77.8,
    '68.5': 77.7,
    '69.5': 77.5,
    '70.5': 77.4,
    '71.5': 77.2,
    '72.5': 77.1,
    '73.5': 76.9,
    '74.5': 76.8,
    '75.5': 76.6,
    '76.5': 76.5,
    '77.5': 76.3,
    '78.5': 76.2,
    '79.5': 76,
    '80.5': 75.9,
    '81.5': 75.7,
    '82.5': 75.6,
    '83.5': 75.4,
    '84.5': 75.3,
    '85.5': 75.1,
    '86.5': 75,
    '87.5': 74.8,
    '88.5': 74.7,
    '89.5': 74.6,
    '90.5': 74.4,
    '91.5': 74.3,
    '92.5': 74.1,
    '93.5': 74,
    '94.5': 73.8,
    '95.5': 73.7,
    '96.5': 73.5,
    '97.5': 73.4,
    '98.5': 73.3,
    '99.5': 73.1,
    '100.5': 73,
    '101.5': 72.8,
    '102.5': 72.7,
    '103.5': 72.6,
    '104.5': 72.4,
    '105.5': 72.3,
    '106.5': 72.1,
    '107.5': 72,
    '108.5': 71.9,
    '109.5': 71.7,
    '110.5': 71.6,
    '111.5': 71.4,
    '112.5': 71.3,
    '113.5': 71.2,
    '114.5': 71,
    '115.5': 70.9,
    '116.5': 70.8,
    '117.5': 70.6,
    '118.5': 70.5,
    '119.5': 70.4,
    '120.5': 70.2,
    '121.5': 70.1,
    '122.5': 69.9,
    '123.5': 69.8,
    '124.5': 69.7,
    '125.5': 69.5,
    '126.5': 69.4,
    '127.5': 69.3,
    '128.5': 69.1,
    '129.5': 69,
    '130.5': 68.9,
    '131.5': 68.8,
    '132.5': 68.6,
    '133.5': 68.5,
    '134.5': 68.4,
    '135.5': 68.2,
    '136.5': 68.1,
    '137.5': 68,
    '138.5': 67.8,
    '139.5': 67.7,
    '140.5': 67.6,
    '141.5': 67.5,
    '142.5': 67.3,
    '143.5': 67.2,
    '144.5': 67.1,
    '145.5': 66.9,
    '146.5': 66.8,
    '147.5': 66.7,
    '148.5': 66.6,
    '149.5': 66.4,
    '150.5': 66.3,
    '151.5': 66.2,
    '152.5': 66.1,
    '153.5': 65.9,
    '154.5': 65.8,
    '155.5': 65.7,
    '156.5': 65.6,
    '157.5': 65.4,
    '158.5': 65.3,
    '159.5': 65.2,
    '160.5': 65.1,
    '161.5': 64.9,
    '162.5': 64.8,
    '163.5': 64.7,
    '164.5': 64.6,
    '165.5': 64.5,
    '166.5': 64.3,
    '167.5': 64.2,
    '168.5': 64.1,
    '169.5': 64,
    '170.5': 63.9,
    '171.5': 63.7,
    '172.5': 63.6,
    '173.5': 63.5,
    '174.5': 63.4,
    '175.5': 63.3,
    '176.5': 63.1,
    '177.5': 63,
    '178.5': 62.9,
    '179.5': 62.8,
    '180.5': 62.7,
    '181.5': 62.6,
    '182.5': 62.4,
    '183.5': 62.3,
    '184.5': 62.2,
    '185.5': 62.1,
    '186.5': 62,
    '187.5': 61.9,
    '188.5': 61.7,
    '189.5': 61.6,
    '190.5': 61.5,
    '191.5': 61.4,
    '192.5': 61.3,
    '193.5': 61.2,
    '194.5': 61.1,
  },
  '79.5': {
    '0': 89.7,
    '1': 89.6,
    '2': 89.4,
    '3': 89.2,
    '4': 89,
    '5': 88.8,
    '6': 88.6,
    '7': 88.5,
    '8': 88.3,
    '9': 88.1,
    '10': 87.9,
    '11': 87.7,
    '12': 87.6,
    '13': 87.4,
    '14': 87.2,
    '15': 87,
    '16': 86.8,
    '17': 86.7,
    '18': 86.5,
    '19': 86.3,
    '20': 86.1,
    '21': 86,
    '22': 85.8,
    '23': 85.6,
    '24': 85.4,
    '25': 85.3,
    '26': 85.1,
    '27': 84.9,
    '28': 84.8,
    '29': 84.6,
    '30': 84.4,
    '31': 84.3,
    '32': 84.1,
    '33': 83.9,
    '34': 83.7,
    '35': 83.6,
    '36': 83.4,
    '37': 83.2,
    '38': 83.1,
    '39': 82.9,
    '40': 82.7,
    '41': 82.6,
    '42': 82.4,
    '43': 82.2,
    '44': 82.1,
    '45': 81.9,
    '46': 81.7,
    '47': 81.6,
    '48': 81.4,
    '49': 81.3,
    '50': 81.1,
    '51': 80.9,
    '52': 80.8,
    '53': 80.6,
    '54': 80.5,
    '55': 80.3,
    '56': 80.1,
    '57': 80,
    '58': 79.8,
    '59': 79.7,
    '60': 79.5,
    '61': 79.3,
    '62': 79.2,
    '63': 79,
    '64': 78.9,
    '65': 78.7,
    '66': 78.6,
    '67': 78.4,
    '68': 78.3,
    '69': 78.1,
    '70': 77.9,
    '71': 77.8,
    '72': 77.6,
    '73': 77.5,
    '74': 77.3,
    '75': 77.2,
    '76': 77,
    '77': 76.9,
    '78': 76.7,
    '79': 76.6,
    '80': 76.4,
    '81': 76.3,
    '82': 76.1,
    '83': 76,
    '84': 75.8,
    '85': 75.7,
    '86': 75.5,
    '87': 75.4,
    '88': 75.2,
    '89': 75.1,
    '90': 74.9,
    '91': 74.8,
    '92': 74.7,
    '93': 74.5,
    '94': 74.4,
    '95': 74.2,
    '96': 74.1,
    '97': 73.9,
    '98': 73.8,
    '99': 73.6,
    '100': 73.5,
    '101': 73.4,
    '102': 73.2,
    '103': 73.1,
    '104': 72.9,
    '105': 72.8,
    '106': 72.7,
    '107': 72.5,
    '108': 72.4,
    '109': 72.2,
    '110': 72.1,
    '111': 72,
    '112': 71.8,
    '113': 71.7,
    '114': 71.5,
    '115': 71.4,
    '116': 71.3,
    '117': 71.1,
    '118': 71,
    '119': 70.9,
    '120': 70.7,
    '121': 70.6,
    '122': 70.5,
    '123': 70.3,
    '124': 70.2,
    '125': 70,
    '126': 69.9,
    '127': 69.8,
    '128': 69.6,
    '129': 69.5,
    '130': 69.4,
    '131': 69.3,
    '132': 69.1,
    '133': 69,
    '134': 68.9,
    '135': 68.7,
    '136': 68.6,
    '137': 68.5,
    '138': 68.3,
    '139': 68.2,
    '140': 68.1,
    '141': 67.9,
    '142': 67.8,
    '143': 67.7,
    '144': 67.6,
    '145': 67.4,
    '146': 67.3,
    '147': 67.2,
    '148': 67,
    '149': 66.9,
    '150': 66.8,
    '151': 66.7,
    '152': 66.5,
    '153': 66.4,
    '154': 66.3,
    '155': 66.2,
    '156': 66,
    '157': 65.9,
    '158': 65.8,
    '159': 65.7,
    '160': 65.5,
    '161': 65.4,
    '162': 65.3,
    '163': 65.2,
    '164': 65,
    '165': 64.9,
    '166': 64.8,
    '167': 64.7,
    '168': 64.6,
    '169': 64.4,
    '170': 64.3,
    '171': 64.2,
    '172': 64.1,
    '173': 64,
    '174': 63.8,
    '175': 63.7,
    '176': 63.6,
    '177': 63.5,
    '178': 63.4,
    '179': 63.2,
    '180': 63.1,
    '181': 63,
    '182': 62.9,
    '183': 62.8,
    '184': 62.7,
    '185': 62.5,
    '186': 62.4,
    '187': 62.3,
    '188': 62.2,
    '189': 62.1,
    '190': 62,
    '191': 61.8,
    '192': 61.7,
    '193': 61.6,
    '194': 61.5,
    '195': 61.4,
    '0.5': 89.6,
    '1.5': 89.5,
    '2.5': 89.3,
    '3.5': 89.1,
    '4.5': 88.9,
    '5.5': 88.7,
    '6.5': 88.5,
    '7.5': 88.4,
    '8.5': 88.2,
    '9.5': 88,
    '10.5': 87.8,
    '11.5': 87.6,
    '12.5': 87.5,
    '13.5': 87.3,
    '14.5': 87.1,
    '15.5': 86.9,
    '16.5': 86.8,
    '17.5': 86.6,
    '18.5': 86.4,
    '19.5': 86.2,
    '20.5': 86.1,
    '21.5': 85.9,
    '22.5': 85.7,
    '23.5': 85.5,
    '24.5': 85.4,
    '25.5': 85.2,
    '26.5': 85,
    '27.5': 84.8,
    '28.5': 84.7,
    '29.5': 84.5,
    '30.5': 84.3,
    '31.5': 84.2,
    '32.5': 84,
    '33.5': 83.8,
    '34.5': 83.7,
    '35.5': 83.5,
    '36.5': 83.3,
    '37.5': 83.2,
    '38.5': 83,
    '39.5': 82.8,
    '40.5': 82.7,
    '41.5': 82.5,
    '42.5': 82.3,
    '43.5': 82.2,
    '44.5': 82,
    '45.5': 81.8,
    '46.5': 81.7,
    '47.5': 81.5,
    '48.5': 81.3,
    '49.5': 81.2,
    '50.5': 81,
    '51.5': 80.9,
    '52.5': 80.7,
    '53.5': 80.5,
    '54.5': 80.4,
    '55.5': 80.2,
    '56.5': 80.1,
    '57.5': 79.9,
    '58.5': 79.7,
    '59.5': 79.6,
    '60.5': 79.4,
    '61.5': 79.3,
    '62.5': 79.1,
    '63.5': 79,
    '64.5': 78.8,
    '65.5': 78.6,
    '66.5': 78.5,
    '67.5': 78.3,
    '68.5': 78.2,
    '69.5': 78,
    '70.5': 77.9,
    '71.5': 77.7,
    '72.5': 77.6,
    '73.5': 77.4,
    '74.5': 77.3,
    '75.5': 77.1,
    '76.5': 77,
    '77.5': 76.8,
    '78.5': 76.7,
    '79.5': 76.5,
    '80.5': 76.4,
    '81.5': 76.2,
    '82.5': 76.1,
    '83.5': 75.9,
    '84.5': 75.8,
    '85.5': 75.6,
    '86.5': 75.5,
    '87.5': 75.3,
    '88.5': 75.2,
    '89.5': 75,
    '90.5': 74.9,
    '91.5': 74.7,
    '92.5': 74.6,
    '93.5': 74.4,
    '94.5': 74.3,
    '95.5': 74.2,
    '96.5': 74,
    '97.5': 73.9,
    '98.5': 73.7,
    '99.5': 73.6,
    '100.5': 73.4,
    '101.5': 73.3,
    '102.5': 73.1,
    '103.5': 73,
    '104.5': 72.9,
    '105.5': 72.7,
    '106.5': 72.6,
    '107.5': 72.4,
    '108.5': 72.3,
    '109.5': 72.2,
    '110.5': 72,
    '111.5': 71.9,
    '112.5': 71.7,
    '113.5': 71.6,
    '114.5': 71.5,
    '115.5': 71.3,
    '116.5': 71.2,
    '117.5': 71.1,
    '118.5': 70.9,
    '119.5': 70.8,
    '120.5': 70.7,
    '121.5': 70.5,
    '122.5': 70.4,
    '123.5': 70.3,
    '124.5': 70.1,
    '125.5': 70,
    '126.5': 69.8,
    '127.5': 69.7,
    '128.5': 69.6,
    '129.5': 69.4,
    '130.5': 69.3,
    '131.5': 69.2,
    '132.5': 69.1,
    '133.5': 68.9,
    '134.5': 68.8,
    '135.5': 68.7,
    '136.5': 68.5,
    '137.5': 68.4,
    '138.5': 68.3,
    '139.5': 68.1,
    '140.5': 68,
    '141.5': 67.9,
    '142.5': 67.7,
    '143.5': 67.6,
    '144.5': 67.5,
    '145.5': 67.4,
    '146.5': 67.2,
    '147.5': 67.1,
    '148.5': 67,
    '149.5': 66.9,
    '150.5': 66.7,
    '151.5': 66.6,
    '152.5': 66.5,
    '153.5': 66.4,
    '154.5': 66.2,
    '155.5': 66.1,
    '156.5': 66,
    '157.5': 65.9,
    '158.5': 65.7,
    '159.5': 65.6,
    '160.5': 65.5,
    '161.5': 65.4,
    '162.5': 65.2,
    '163.5': 65.1,
    '164.5': 65,
    '165.5': 64.9,
    '166.5': 64.7,
    '167.5': 64.6,
    '168.5': 64.5,
    '169.5': 64.4,
    '170.5': 64.3,
    '171.5': 64.1,
    '172.5': 64,
    '173.5': 63.9,
    '174.5': 63.8,
    '175.5': 63.7,
    '176.5': 63.5,
    '177.5': 63.4,
    '178.5': 63.3,
    '179.5': 63.2,
    '180.5': 63.1,
    '181.5': 62.9,
    '182.5': 62.8,
    '183.5': 62.7,
    '184.5': 62.6,
    '185.5': 62.5,
    '186.5': 62.4,
    '187.5': 62.3,
    '188.5': 62.1,
    '189.5': 62,
    '190.5': 61.9,
    '191.5': 61.8,
    '192.5': 61.7,
    '193.5': 61.6,
    '194.5': 61.4,
  },
  '80': {
    '0': 90.3,
    '1': 90.1,
    '2': 89.9,
    '3': 89.8,
    '4': 89.6,
    '5': 89.4,
    '6': 89.2,
    '7': 89,
    '8': 88.8,
    '9': 88.7,
    '10': 88.5,
    '11': 88.3,
    '12': 88.1,
    '13': 87.9,
    '14': 87.8,
    '15': 87.6,
    '16': 87.4,
    '17': 87.2,
    '18': 87,
    '19': 86.9,
    '20': 86.7,
    '21': 86.5,
    '22': 86.3,
    '23': 86.2,
    '24': 86,
    '25': 85.8,
    '26': 85.6,
    '27': 85.5,
    '28': 85.3,
    '29': 85.1,
    '30': 85,
    '31': 84.8,
    '32': 84.6,
    '33': 84.4,
    '34': 84.3,
    '35': 84.1,
    '36': 83.9,
    '37': 83.8,
    '38': 83.6,
    '39': 83.4,
    '40': 83.3,
    '41': 83.1,
    '42': 82.9,
    '43': 82.8,
    '44': 82.6,
    '45': 82.4,
    '46': 82.3,
    '47': 82.1,
    '48': 81.9,
    '49': 81.8,
    '50': 81.6,
    '51': 81.4,
    '52': 81.3,
    '53': 81.1,
    '54': 81,
    '55': 80.8,
    '56': 80.6,
    '57': 80.5,
    '58': 80.3,
    '59': 80.2,
    '60': 80,
    '61': 79.8,
    '62': 79.7,
    '63': 79.5,
    '64': 79.4,
    '65': 79.2,
    '66': 79.1,
    '67': 78.9,
    '68': 78.7,
    '69': 78.6,
    '70': 78.4,
    '71': 78.3,
    '72': 78.1,
    '73': 78,
    '74': 77.8,
    '75': 77.7,
    '76': 77.5,
    '77': 77.4,
    '78': 77.2,
    '79': 77.1,
    '80': 76.9,
    '81': 76.8,
    '82': 76.6,
    '83': 76.5,
    '84': 76.3,
    '85': 76.2,
    '86': 76,
    '87': 75.9,
    '88': 75.7,
    '89': 75.6,
    '90': 75.4,
    '91': 75.3,
    '92': 75.1,
    '93': 75,
    '94': 74.8,
    '95': 74.7,
    '96': 74.5,
    '97': 74.4,
    '98': 74.2,
    '99': 74.1,
    '100': 74,
    '101': 73.8,
    '102': 73.7,
    '103': 73.5,
    '104': 73.4,
    '105': 73.2,
    '106': 73.1,
    '107': 73,
    '108': 72.8,
    '109': 72.7,
    '110': 72.5,
    '111': 72.4,
    '112': 72.3,
    '113': 72.1,
    '114': 72,
    '115': 71.8,
    '116': 71.7,
    '117': 71.6,
    '118': 71.4,
    '119': 71.3,
    '120': 71.2,
    '121': 71,
    '122': 70.9,
    '123': 70.8,
    '124': 70.6,
    '125': 70.5,
    '126': 70.3,
    '127': 70.2,
    '128': 70.1,
    '129': 69.9,
    '130': 69.8,
    '131': 69.7,
    '132': 69.5,
    '133': 69.4,
    '134': 69.3,
    '135': 69.1,
    '136': 69,
    '137': 68.9,
    '138': 68.8,
    '139': 68.6,
    '140': 68.5,
    '141': 68.4,
    '142': 68.2,
    '143': 68.1,
    '144': 68,
    '145': 67.8,
    '146': 67.7,
    '147': 67.6,
    '148': 67.5,
    '149': 67.3,
    '150': 67.2,
    '151': 67.1,
    '152': 67,
    '153': 66.8,
    '154': 66.7,
    '155': 66.6,
    '156': 66.4,
    '157': 66.3,
    '158': 66.2,
    '159': 66.1,
    '160': 65.9,
    '161': 65.8,
    '162': 65.7,
    '163': 65.6,
    '164': 65.5,
    '165': 65.3,
    '166': 65.2,
    '167': 65.1,
    '168': 65,
    '169': 64.8,
    '170': 64.7,
    '171': 64.6,
    '172': 64.5,
    '173': 64.4,
    '174': 64.2,
    '175': 64.1,
    '176': 64,
    '177': 63.9,
    '178': 63.8,
    '179': 63.6,
    '180': 63.5,
    '181': 63.4,
    '182': 63.3,
    '183': 63.2,
    '184': 63,
    '185': 62.9,
    '186': 62.8,
    '187': 62.7,
    '188': 62.6,
    '189': 62.5,
    '190': 62.3,
    '191': 62.2,
    '192': 62.1,
    '193': 62,
    '194': 61.9,
    '195': 61.8,
    '0.5': 90.2,
    '1.5': 90,
    '2.5': 89.8,
    '3.5': 89.7,
    '4.5': 89.5,
    '5.5': 89.3,
    '6.5': 89.1,
    '7.5': 88.9,
    '8.5': 88.8,
    '9.5': 88.6,
    '10.5': 88.4,
    '11.5': 88.2,
    '12.5': 88,
    '13.5': 87.9,
    '14.5': 87.7,
    '15.5': 87.5,
    '16.5': 87.3,
    '17.5': 87.1,
    '18.5': 87,
    '19.5': 86.8,
    '20.5': 86.6,
    '21.5': 86.4,
    '22.5': 86.3,
    '23.5': 86.1,
    '24.5': 85.9,
    '25.5': 85.7,
    '26.5': 85.6,
    '27.5': 85.4,
    '28.5': 85.2,
    '29.5': 85,
    '30.5': 84.9,
    '31.5': 84.7,
    '32.5': 84.5,
    '33.5': 84.4,
    '34.5': 84.2,
    '35.5': 84,
    '36.5': 83.8,
    '37.5': 83.7,
    '38.5': 83.5,
    '39.5': 83.3,
    '40.5': 83.2,
    '41.5': 83,
    '42.5': 82.8,
    '43.5': 82.7,
    '44.5': 82.5,
    '45.5': 82.3,
    '46.5': 82.2,
    '47.5': 82,
    '48.5': 81.9,
    '49.5': 81.7,
    '50.5': 81.5,
    '51.5': 81.4,
    '52.5': 81.2,
    '53.5': 81,
    '54.5': 80.9,
    '55.5': 80.7,
    '56.5': 80.6,
    '57.5': 80.4,
    '58.5': 80.2,
    '59.5': 80.1,
    '60.5': 79.9,
    '61.5': 79.8,
    '62.5': 79.6,
    '63.5': 79.4,
    '64.5': 79.3,
    '65.5': 79.1,
    '66.5': 79,
    '67.5': 78.8,
    '68.5': 78.7,
    '69.5': 78.5,
    '70.5': 78.4,
    '71.5': 78.2,
    '72.5': 78,
    '73.5': 77.9,
    '74.5': 77.7,
    '75.5': 77.6,
    '76.5': 77.4,
    '77.5': 77.3,
    '78.5': 77.1,
    '79.5': 77,
    '80.5': 76.8,
    '81.5': 76.7,
    '82.5': 76.5,
    '83.5': 76.4,
    '84.5': 76.2,
    '85.5': 76.1,
    '86.5': 75.9,
    '87.5': 75.8,
    '88.5': 75.6,
    '89.5': 75.5,
    '90.5': 75.3,
    '91.5': 75.2,
    '92.5': 75,
    '93.5': 74.9,
    '94.5': 74.8,
    '95.5': 74.6,
    '96.5': 74.5,
    '97.5': 74.3,
    '98.5': 74.2,
    '99.5': 74,
    '100.5': 73.9,
    '101.5': 73.7,
    '102.5': 73.6,
    '103.5': 73.5,
    '104.5': 73.3,
    '105.5': 73.2,
    '106.5': 73,
    '107.5': 72.9,
    '108.5': 72.8,
    '109.5': 72.6,
    '110.5': 72.5,
    '111.5': 72.3,
    '112.5': 72.2,
    '113.5': 72.1,
    '114.5': 71.9,
    '115.5': 71.8,
    '116.5': 71.6,
    '117.5': 71.5,
    '118.5': 71.4,
    '119.5': 71.2,
    '120.5': 71.1,
    '121.5': 71,
    '122.5': 70.8,
    '123.5': 70.7,
    '124.5': 70.5,
    '125.5': 70.4,
    '126.5': 70.3,
    '127.5': 70.1,
    '128.5': 70,
    '129.5': 69.9,
    '130.5': 69.7,
    '131.5': 69.6,
    '132.5': 69.5,
    '133.5': 69.3,
    '134.5': 69.2,
    '135.5': 69.1,
    '136.5': 69,
    '137.5': 68.8,
    '138.5': 68.7,
    '139.5': 68.6,
    '140.5': 68.4,
    '141.5': 68.3,
    '142.5': 68.2,
    '143.5': 68,
    '144.5': 67.9,
    '145.5': 67.8,
    '146.5': 67.7,
    '147.5': 67.5,
    '148.5': 67.4,
    '149.5': 67.3,
    '150.5': 67.1,
    '151.5': 67,
    '152.5': 66.9,
    '153.5': 66.8,
    '154.5': 66.6,
    '155.5': 66.5,
    '156.5': 66.4,
    '157.5': 66.3,
    '158.5': 66.1,
    '159.5': 66,
    '160.5': 65.9,
    '161.5': 65.8,
    '162.5': 65.6,
    '163.5': 65.5,
    '164.5': 65.4,
    '165.5': 65.3,
    '166.5': 65.1,
    '167.5': 65,
    '168.5': 64.9,
    '169.5': 64.8,
    '170.5': 64.7,
    '171.5': 64.5,
    '172.5': 64.4,
    '173.5': 64.3,
    '174.5': 64.2,
    '175.5': 64.1,
    '176.5': 63.9,
    '177.5': 63.8,
    '178.5': 63.7,
    '179.5': 63.6,
    '180.5': 63.5,
    '181.5': 63.3,
    '182.5': 63.2,
    '183.5': 63.1,
    '184.5': 63,
    '185.5': 62.9,
    '186.5': 62.8,
    '187.5': 62.6,
    '188.5': 62.5,
    '189.5': 62.4,
    '190.5': 62.3,
    '191.5': 62.2,
    '192.5': 62.1,
    '193.5': 61.9,
    '194.5': 61.8,
  },
  // 80 - 85
  '80.5': {
    '0': 90.9,
    '1': 90.7,
    '2': 90.5,
    '3': 90.3,
    '4': 90.2,
    '5': 90,
    '6': 89.8,
    '7': 89.6,
    '8': 89.4,
    '9': 89.2,
    '10': 89,
    '11': 88.9,
    '12': 88.7,
    '13': 88.5,
    '14': 88.3,
    '15': 88.1,
    '16': 88,
    '17': 87.8,
    '18': 87.6,
    '19': 87.4,
    '20': 87.2,
    '21': 87.1,
    '22': 86.9,
    '23': 86.7,
    '24': 86.5,
    '25': 86.4,
    '26': 86.2,
    '27': 86,
    '28': 85.8,
    '29': 85.7,
    '30': 85.5,
    '31': 85.3,
    '32': 85.1,
    '33': 85,
    '34': 84.8,
    '35': 84.6,
    '36': 84.5,
    '37': 84.3,
    '38': 84.1,
    '39': 84,
    '40': 83.8,
    '41': 83.6,
    '42': 83.5,
    '43': 83.3,
    '44': 83.1,
    '45': 82.9,
    '46': 82.8,
    '47': 82.6,
    '48': 82.5,
    '49': 82.3,
    '50': 82.1,
    '51': 82,
    '52': 81.8,
    '53': 81.6,
    '54': 81.5,
    '55': 81.3,
    '56': 81.1,
    '57': 81,
    '58': 80.8,
    '59': 80.7,
    '60': 80.5,
    '61': 80.3,
    '62': 80.2,
    '63': 80,
    '64': 79.9,
    '65': 79.7,
    '66': 79.5,
    '67': 79.4,
    '68': 79.2,
    '69': 79.1,
    '70': 78.9,
    '71': 78.8,
    '72': 78.6,
    '73': 78.5,
    '74': 78.3,
    '75': 78.1,
    '76': 78,
    '77': 77.8,
    '78': 77.7,
    '79': 77.5,
    '80': 77.4,
    '81': 77.2,
    '82': 77.1,
    '83': 76.9,
    '84': 76.8,
    '85': 76.6,
    '86': 76.5,
    '87': 76.3,
    '88': 76.2,
    '89': 76,
    '90': 75.9,
    '91': 75.7,
    '92': 75.6,
    '93': 75.4,
    '94': 75.3,
    '95': 75.1,
    '96': 75,
    '97': 74.9,
    '98': 74.7,
    '99': 74.6,
    '100': 74.4,
    '101': 74.3,
    '102': 74.1,
    '103': 74,
    '104': 73.8,
    '105': 73.7,
    '106': 73.6,
    '107': 73.4,
    '108': 73.3,
    '109': 73.1,
    '110': 73,
    '111': 72.9,
    '112': 72.7,
    '113': 72.6,
    '114': 72.4,
    '115': 72.3,
    '116': 72.2,
    '117': 72,
    '118': 71.9,
    '119': 71.7,
    '120': 71.6,
    '121': 71.5,
    '122': 71.3,
    '123': 71.2,
    '124': 71.1,
    '125': 70.9,
    '126': 70.8,
    '127': 70.6,
    '128': 70.5,
    '129': 70.4,
    '130': 70.2,
    '131': 70.1,
    '132': 70,
    '133': 69.8,
    '134': 69.7,
    '135': 69.6,
    '136': 69.4,
    '137': 69.3,
    '138': 69.2,
    '139': 69,
    '140': 68.9,
    '141': 68.8,
    '142': 68.7,
    '143': 68.5,
    '144': 68.4,
    '145': 68.3,
    '146': 68.1,
    '147': 68,
    '148': 67.9,
    '149': 67.7,
    '150': 67.6,
    '151': 67.5,
    '152': 67.4,
    '153': 67.2,
    '154': 67.1,
    '155': 67,
    '156': 66.9,
    '157': 66.7,
    '158': 66.6,
    '159': 66.5,
    '160': 66.4,
    '161': 66.2,
    '162': 66.1,
    '163': 66,
    '164': 65.9,
    '165': 65.7,
    '166': 65.6,
    '167': 65.5,
    '168': 65.4,
    '169': 65.2,
    '170': 65.1,
    '171': 65,
    '172': 64.9,
    '173': 64.8,
    '174': 64.6,
    '175': 64.5,
    '176': 64.4,
    '177': 64.3,
    '178': 64.1,
    '179': 64,
    '180': 63.9,
    '181': 63.8,
    '182': 63.7,
    '183': 63.6,
    '184': 63.4,
    '185': 63.3,
    '186': 63.2,
    '187': 63.1,
    '188': 63,
    '189': 62.8,
    '190': 62.7,
    '191': 62.6,
    '192': 62.5,
    '193': 62.4,
    '194': 62.3,
    '195': 62.1,
    '0.5': 90.8,
    '1.5': 90.6,
    '2.5': 90.4,
    '3.5': 90.2,
    '4.5': 90.1,
    '5.5': 89.9,
    '6.5': 89.7,
    '7.5': 89.5,
    '8.5': 89.3,
    '9.5': 89.1,
    '10.5': 89,
    '11.5': 88.8,
    '12.5': 88.6,
    '13.5': 88.4,
    '14.5': 88.2,
    '15.5': 88.1,
    '16.5': 87.9,
    '17.5': 87.7,
    '18.5': 87.5,
    '19.5': 87.3,
    '20.5': 87.2,
    '21.5': 87,
    '22.5': 86.8,
    '23.5': 86.6,
    '24.5': 86.5,
    '25.5': 86.3,
    '26.5': 86.1,
    '27.5': 85.9,
    '28.5': 85.8,
    '29.5': 85.6,
    '30.5': 85.4,
    '31.5': 85.2,
    '32.5': 85.1,
    '33.5': 84.9,
    '34.5': 84.7,
    '35.5': 84.5,
    '36.5': 84.4,
    '37.5': 84.2,
    '38.5': 84,
    '39.5': 83.9,
    '40.5': 83.7,
    '41.5': 83.5,
    '42.5': 83.4,
    '43.5': 83.2,
    '44.5': 83,
    '45.5': 82.9,
    '46.5': 82.7,
    '47.5': 82.5,
    '48.5': 82.4,
    '49.5': 82.2,
    '50.5': 82,
    '51.5': 81.9,
    '52.5': 81.7,
    '53.5': 81.5,
    '54.5': 81.4,
    '55.5': 81.2,
    '56.5': 81.1,
    '57.5': 80.9,
    '58.5': 80.7,
    '59.5': 80.6,
    '60.5': 80.4,
    '61.5': 80.3,
    '62.5': 80.1,
    '63.5': 79.9,
    '64.5': 79.8,
    '65.5': 79.6,
    '66.5': 79.5,
    '67.5': 79.3,
    '68.5': 79.2,
    '69.5': 79,
    '70.5': 78.8,
    '71.5': 78.7,
    '72.5': 78.5,
    '73.5': 78.4,
    '74.5': 78.2,
    '75.5': 78.1,
    '76.5': 77.9,
    '77.5': 77.8,
    '78.5': 77.6,
    '79.5': 77.5,
    '80.5': 77.3,
    '81.5': 77.2,
    '82.5': 77,
    '83.5': 76.9,
    '84.5': 76.7,
    '85.5': 76.6,
    '86.5': 76.4,
    '87.5': 76.3,
    '88.5': 76.1,
    '89.5': 76,
    '90.5': 75.8,
    '91.5': 75.7,
    '92.5': 75.5,
    '93.5': 75.4,
    '94.5': 75.2,
    '95.5': 75.1,
    '96.5': 74.9,
    '97.5': 74.8,
    '98.5': 74.6,
    '99.5': 74.5,
    '100.5': 74.3,
    '101.5': 74.2,
    '102.5': 74.1,
    '103.5': 73.9,
    '104.5': 73.8,
    '105.5': 73.6,
    '106.5': 73.5,
    '107.5': 73.3,
    '108.5': 73.2,
    '109.5': 73.1,
    '110.5': 72.9,
    '111.5': 72.8,
    '112.5': 72.6,
    '113.5': 72.5,
    '114.5': 72.4,
    '115.5': 72.2,
    '116.5': 72.1,
    '117.5': 71.9,
    '118.5': 71.8,
    '119.5': 71.7,
    '120.5': 71.5,
    '121.5': 71.4,
    '122.5': 71.3,
    '123.5': 71.1,
    '124.5': 71,
    '125.5': 70.9,
    '126.5': 70.7,
    '127.5': 70.6,
    '128.5': 70.4,
    '129.5': 70.3,
    '130.5': 70.2,
    '131.5': 70,
    '132.5': 69.9,
    '133.5': 69.8,
    '134.5': 69.6,
    '135.5': 69.5,
    '136.5': 69.4,
    '137.5': 69.2,
    '138.5': 69.1,
    '139.5': 69,
    '140.5': 68.8,
    '141.5': 68.7,
    '142.5': 68.6,
    '143.5': 68.5,
    '144.5': 68.3,
    '145.5': 68.2,
    '146.5': 68.1,
    '147.5': 67.9,
    '148.5': 67.8,
    '149.5': 67.7,
    '150.5': 67.6,
    '151.5': 67.4,
    '152.5': 67.3,
    '153.5': 67.2,
    '154.5': 67,
    '155.5': 66.9,
    '156.5': 66.8,
    '157.5': 66.7,
    '158.5': 66.5,
    '159.5': 66.4,
    '160.5': 66.3,
    '161.5': 66.2,
    '162.5': 66,
    '163.5': 65.9,
    '164.5': 65.8,
    '165.5': 65.7,
    '166.5': 65.5,
    '167.5': 65.4,
    '168.5': 65.3,
    '169.5': 65.2,
    '170.5': 65.1,
    '171.5': 64.9,
    '172.5': 64.8,
    '173.5': 64.7,
    '174.5': 64.6,
    '175.5': 64.5,
    '176.5': 64.3,
    '177.5': 64.2,
    '178.5': 64.1,
    '179.5': 64,
    '180.5': 63.9,
    '181.5': 63.7,
    '182.5': 63.6,
    '183.5': 63.5,
    '184.5': 63.4,
    '185.5': 63.3,
    '186.5': 63.1,
    '187.5': 63,
    '188.5': 62.9,
    '189.5': 62.8,
    '190.5': 62.7,
    '191.5': 62.6,
    '192.5': 62.4,
    '193.5': 62.3,
    '194.5': 62.2,
  },
  '81': {
    '0': 91.5,
    '1': 91.3,
    '2': 91.1,
    '3': 90.9,
    '4': 90.7,
    '5': 90.5,
    '6': 90.3,
    '7': 90.2,
    '8': 90,
    '9': 89.8,
    '10': 89.6,
    '11': 89.4,
    '12': 89.2,
    '13': 89.1,
    '14': 88.9,
    '15': 88.7,
    '16': 88.5,
    '17': 88.3,
    '18': 88.2,
    '19': 88,
    '20': 87.8,
    '21': 87.6,
    '22': 87.4,
    '23': 87.3,
    '24': 87.1,
    '25': 86.9,
    '26': 86.7,
    '27': 86.6,
    '28': 86.4,
    '29': 86.2,
    '30': 86,
    '31': 85.9,
    '32': 85.7,
    '33': 85.5,
    '34': 85.3,
    '35': 85.2,
    '36': 85,
    '37': 84.8,
    '38': 84.6,
    '39': 84.5,
    '40': 84.3,
    '41': 84.1,
    '42': 84,
    '43': 83.8,
    '44': 83.6,
    '45': 83.5,
    '46': 83.3,
    '47': 83.1,
    '48': 83,
    '49': 82.8,
    '50': 82.6,
    '51': 82.5,
    '52': 82.3,
    '53': 82.1,
    '54': 82,
    '55': 81.8,
    '56': 81.7,
    '57': 81.5,
    '58': 81.3,
    '59': 81.2,
    '60': 81,
    '61': 80.8,
    '62': 80.7,
    '63': 80.5,
    '64': 80.4,
    '65': 80.2,
    '66': 80,
    '67': 79.9,
    '68': 79.7,
    '69': 79.6,
    '70': 79.4,
    '71': 79.3,
    '72': 79.1,
    '73': 78.9,
    '74': 78.8,
    '75': 78.6,
    '76': 78.5,
    '77': 78.3,
    '78': 78.2,
    '79': 78,
    '80': 77.9,
    '81': 77.7,
    '82': 77.6,
    '83': 77.4,
    '84': 77.3,
    '85': 77.1,
    '86': 76.9,
    '87': 76.8,
    '88': 76.6,
    '89': 76.5,
    '90': 76.4,
    '91': 76.2,
    '92': 76.1,
    '93': 75.9,
    '94': 75.8,
    '95': 75.6,
    '96': 75.5,
    '97': 75.3,
    '98': 75.2,
    '99': 75,
    '100': 74.9,
    '101': 74.7,
    '102': 74.6,
    '103': 74.4,
    '104': 74.3,
    '105': 74.2,
    '106': 74,
    '107': 73.9,
    '108': 73.7,
    '109': 73.6,
    '110': 73.4,
    '111': 73.3,
    '112': 73.2,
    '113': 73,
    '114': 72.9,
    '115': 72.7,
    '116': 72.6,
    '117': 72.5,
    '118': 72.3,
    '119': 72.2,
    '120': 72,
    '121': 71.9,
    '122': 71.8,
    '123': 71.6,
    '124': 71.5,
    '125': 71.4,
    '126': 71.2,
    '127': 71.1,
    '128': 70.9,
    '129': 70.8,
    '130': 70.7,
    '131': 70.5,
    '132': 70.4,
    '133': 70.3,
    '134': 70.1,
    '135': 70,
    '136': 69.9,
    '137': 69.7,
    '138': 69.6,
    '139': 69.5,
    '140': 69.3,
    '141': 69.2,
    '142': 69.1,
    '143': 68.9,
    '144': 68.8,
    '145': 68.7,
    '146': 68.6,
    '147': 68.4,
    '148': 68.3,
    '149': 68.2,
    '150': 68,
    '151': 67.9,
    '152': 67.8,
    '153': 67.7,
    '154': 67.5,
    '155': 67.4,
    '156': 67.3,
    '157': 67.1,
    '158': 67,
    '159': 66.9,
    '160': 66.8,
    '161': 66.6,
    '162': 66.5,
    '163': 66.4,
    '164': 66.3,
    '165': 66.1,
    '166': 66,
    '167': 65.9,
    '168': 65.8,
    '169': 65.6,
    '170': 65.5,
    '171': 65.4,
    '172': 65.3,
    '173': 65.2,
    '174': 65,
    '175': 64.9,
    '176': 64.8,
    '177': 64.7,
    '178': 64.5,
    '179': 64.4,
    '180': 64.3,
    '181': 64.2,
    '182': 64.1,
    '183': 63.9,
    '184': 63.8,
    '185': 63.7,
    '186': 63.6,
    '187': 63.5,
    '188': 63.4,
    '189': 63.2,
    '190': 63.1,
    '191': 63,
    '192': 62.9,
    '193': 62.8,
    '194': 62.6,
    '195': 62.5,
    '0.5': 91.4,
    '1.5': 91.2,
    '2.5': 91,
    '3.5': 90.8,
    '4.5': 90.6,
    '5.5': 90.4,
    '6.5': 90.3,
    '7.5': 90.1,
    '8.5': 89.9,
    '9.5': 89.7,
    '10.5': 89.5,
    '11.5': 89.3,
    '12.5': 89.2,
    '13.5': 89,
    '14.5': 88.8,
    '15.5': 88.6,
    '16.5': 88.4,
    '17.5': 88.2,
    '18.5': 88.1,
    '19.5': 87.9,
    '20.5': 87.7,
    '21.5': 87.5,
    '22.5': 87.4,
    '23.5': 87.2,
    '24.5': 87,
    '25.5': 86.8,
    '26.5': 86.6,
    '27.5': 86.5,
    '28.5': 86.3,
    '29.5': 86.1,
    '30.5': 85.9,
    '31.5': 85.8,
    '32.5': 85.6,
    '33.5': 85.4,
    '34.5': 85.3,
    '35.5': 85.1,
    '36.5': 84.9,
    '37.5': 84.7,
    '38.5': 84.6,
    '39.5': 84.4,
    '40.5': 84.2,
    '41.5': 84.1,
    '42.5': 83.9,
    '43.5': 83.7,
    '44.5': 83.5,
    '45.5': 83.4,
    '46.5': 83.2,
    '47.5': 83,
    '48.5': 82.9,
    '49.5': 82.7,
    '50.5': 82.6,
    '51.5': 82.4,
    '52.5': 82.2,
    '53.5': 82.1,
    '54.5': 81.9,
    '55.5': 81.7,
    '56.5': 81.6,
    '57.5': 81.4,
    '58.5': 81.2,
    '59.5': 81.1,
    '60.5': 80.9,
    '61.5': 80.8,
    '62.5': 80.6,
    '63.5': 80.4,
    '64.5': 80.3,
    '65.5': 80.1,
    '66.5': 80,
    '67.5': 79.8,
    '68.5': 79.6,
    '69.5': 79.5,
    '70.5': 79.3,
    '71.5': 79.2,
    '72.5': 79,
    '73.5': 78.9,
    '74.5': 78.7,
    '75.5': 78.6,
    '76.5': 78.4,
    '77.5': 78.2,
    '78.5': 78.1,
    '79.5': 77.9,
    '80.5': 77.8,
    '81.5': 77.6,
    '82.5': 77.5,
    '83.5': 77.3,
    '84.5': 77.2,
    '85.5': 77,
    '86.5': 76.9,
    '87.5': 76.7,
    '88.5': 76.6,
    '89.5': 76.4,
    '90.5': 76.3,
    '91.5': 76.1,
    '92.5': 76,
    '93.5': 75.8,
    '94.5': 75.7,
    '95.5': 75.5,
    '96.5': 75.4,
    '97.5': 75.2,
    '98.5': 75.1,
    '99.5': 74.9,
    '100.5': 74.8,
    '101.5': 74.7,
    '102.5': 74.5,
    '103.5': 74.4,
    '104.5': 74.2,
    '105.5': 74.1,
    '106.5': 73.9,
    '107.5': 73.8,
    '108.5': 73.7,
    '109.5': 73.5,
    '110.5': 73.4,
    '111.5': 73.2,
    '112.5': 73.1,
    '113.5': 72.9,
    '114.5': 72.8,
    '115.5': 72.7,
    '116.5': 72.5,
    '117.5': 72.4,
    '118.5': 72.2,
    '119.5': 72.1,
    '120.5': 72,
    '121.5': 71.8,
    '122.5': 71.7,
    '123.5': 71.6,
    '124.5': 71.4,
    '125.5': 71.3,
    '126.5': 71.1,
    '127.5': 71,
    '128.5': 70.9,
    '129.5': 70.7,
    '130.5': 70.6,
    '131.5': 70.5,
    '132.5': 70.3,
    '133.5': 70.2,
    '134.5': 70.1,
    '135.5': 69.9,
    '136.5': 69.8,
    '137.5': 69.7,
    '138.5': 69.5,
    '139.5': 69.4,
    '140.5': 69.3,
    '141.5': 69.1,
    '142.5': 69,
    '143.5': 68.9,
    '144.5': 68.7,
    '145.5': 68.6,
    '146.5': 68.5,
    '147.5': 68.4,
    '148.5': 68.2,
    '149.5': 68.1,
    '150.5': 68,
    '151.5': 67.8,
    '152.5': 67.7,
    '153.5': 67.6,
    '154.5': 67.5,
    '155.5': 67.3,
    '156.5': 67.2,
    '157.5': 67.1,
    '158.5': 67,
    '159.5': 66.8,
    '160.5': 66.7,
    '161.5': 66.6,
    '162.5': 66.4,
    '163.5': 66.3,
    '164.5': 66.2,
    '165.5': 66.1,
    '166.5': 65.9,
    '167.5': 65.8,
    '168.5': 65.7,
    '169.5': 65.6,
    '170.5': 65.5,
    '171.5': 65.3,
    '172.5': 65.2,
    '173.5': 65.1,
    '174.5': 65,
    '175.5': 64.8,
    '176.5': 64.7,
    '177.5': 64.6,
    '178.5': 64.5,
    '179.5': 64.4,
    '180.5': 64.2,
    '181.5': 64.1,
    '182.5': 64,
    '183.5': 63.9,
    '184.5': 63.8,
    '185.5': 63.6,
    '186.5': 63.5,
    '187.5': 63.4,
    '188.5': 63.3,
    '189.5': 63.2,
    '190.5': 63.1,
    '191.5': 62.9,
    '192.5': 62.8,
    '193.5': 62.7,
    '194.5': 62.6,
  },
  '81.5': {
    '0': 92.1,
    '1': 91.9,
    '2': 91.7,
    '3': 91.5,
    '4': 91.3,
    '5': 91.1,
    '6': 90.9,
    '7': 90.7,
    '8': 90.5,
    '9': 90.4,
    '10': 90.2,
    '11': 90,
    '12': 89.8,
    '13': 89.6,
    '14': 89.4,
    '15': 89.3,
    '16': 89.1,
    '17': 88.9,
    '18': 88.7,
    '19': 88.5,
    '20': 88.3,
    '21': 88.2,
    '22': 88,
    '23': 87.8,
    '24': 87.6,
    '25': 87.4,
    '26': 87.3,
    '27': 87.1,
    '28': 86.9,
    '29': 86.7,
    '30': 86.6,
    '31': 86.4,
    '32': 86.2,
    '33': 86,
    '34': 85.9,
    '35': 85.7,
    '36': 85.5,
    '37': 85.3,
    '38': 85.2,
    '39': 85,
    '40': 84.8,
    '41': 84.7,
    '42': 84.5,
    '43': 84.3,
    '44': 84.2,
    '45': 84,
    '46': 83.8,
    '47': 83.6,
    '48': 83.5,
    '49': 83.3,
    '50': 83.1,
    '51': 83,
    '52': 82.8,
    '53': 82.6,
    '54': 82.5,
    '55': 82.3,
    '56': 82.2,
    '57': 82,
    '58': 81.8,
    '59': 81.7,
    '60': 81.5,
    '61': 81.3,
    '62': 81.2,
    '63': 81,
    '64': 80.9,
    '65': 80.7,
    '66': 80.5,
    '67': 80.4,
    '68': 80.2,
    '69': 80.1,
    '70': 79.9,
    '71': 79.7,
    '72': 79.6,
    '73': 79.4,
    '74': 79.3,
    '75': 79.1,
    '76': 79,
    '77': 78.8,
    '78': 78.6,
    '79': 78.5,
    '80': 78.3,
    '81': 78.2,
    '82': 78,
    '83': 77.9,
    '84': 77.7,
    '85': 77.6,
    '86': 77.4,
    '87': 77.3,
    '88': 77.1,
    '89': 77,
    '90': 76.8,
    '91': 76.7,
    '92': 76.5,
    '93': 76.4,
    '94': 76.2,
    '95': 76.1,
    '96': 75.9,
    '97': 75.8,
    '98': 75.6,
    '99': 75.5,
    '100': 75.3,
    '101': 75.2,
    '102': 75,
    '103': 74.9,
    '104': 74.8,
    '105': 74.6,
    '106': 74.5,
    '107': 74.3,
    '108': 74.2,
    '109': 74,
    '110': 73.9,
    '111': 73.7,
    '112': 73.6,
    '113': 73.5,
    '114': 73.3,
    '115': 73.2,
    '116': 73,
    '117': 72.9,
    '118': 72.8,
    '119': 72.6,
    '120': 72.5,
    '121': 72.3,
    '122': 72.2,
    '123': 72.1,
    '124': 71.9,
    '125': 71.8,
    '126': 71.7,
    '127': 71.5,
    '128': 71.4,
    '129': 71.2,
    '130': 71.1,
    '131': 71,
    '132': 70.8,
    '133': 70.7,
    '134': 70.6,
    '135': 70.4,
    '136': 70.3,
    '137': 70.2,
    '138': 70,
    '139': 69.9,
    '140': 69.8,
    '141': 69.6,
    '142': 69.5,
    '143': 69.4,
    '144': 69.2,
    '145': 69.1,
    '146': 69,
    '147': 68.8,
    '148': 68.7,
    '149': 68.6,
    '150': 68.4,
    '151': 68.3,
    '152': 68.2,
    '153': 68.1,
    '154': 67.9,
    '155': 67.8,
    '156': 67.7,
    '157': 67.5,
    '158': 67.4,
    '159': 67.3,
    '160': 67.2,
    '161': 67,
    '162': 66.9,
    '163': 66.8,
    '164': 66.7,
    '165': 66.5,
    '166': 66.4,
    '167': 66.3,
    '168': 66.2,
    '169': 66,
    '170': 65.9,
    '171': 65.8,
    '172': 65.7,
    '173': 65.5,
    '174': 65.4,
    '175': 65.3,
    '176': 65.2,
    '177': 65.1,
    '178': 64.9,
    '179': 64.8,
    '180': 64.7,
    '181': 64.6,
    '182': 64.5,
    '183': 64.3,
    '184': 64.2,
    '185': 64.1,
    '186': 64,
    '187': 63.9,
    '188': 63.7,
    '189': 63.6,
    '190': 63.5,
    '191': 63.4,
    '192': 63.3,
    '193': 63.1,
    '194': 63,
    '195': 62.9,
    '0.5': 92,
    '1.5': 91.8,
    '2.5': 91.6,
    '3.5': 91.4,
    '4.5': 91.2,
    '5.5': 91,
    '6.5': 90.8,
    '7.5': 90.6,
    '8.5': 90.5,
    '9.5': 90.3,
    '10.5': 90.1,
    '11.5': 89.9,
    '12.5': 89.7,
    '13.5': 89.5,
    '14.5': 89.3,
    '15.5': 89.2,
    '16.5': 89,
    '17.5': 88.8,
    '18.5': 88.6,
    '19.5': 88.4,
    '20.5': 88.3,
    '21.5': 88.1,
    '22.5': 87.9,
    '23.5': 87.7,
    '24.5': 87.5,
    '25.5': 87.4,
    '26.5': 87.2,
    '27.5': 87,
    '28.5': 86.8,
    '29.5': 86.7,
    '30.5': 86.5,
    '31.5': 86.3,
    '32.5': 86.1,
    '33.5': 86,
    '34.5': 85.8,
    '35.5': 85.6,
    '36.5': 85.4,
    '37.5': 85.3,
    '38.5': 85.1,
    '39.5': 84.9,
    '40.5': 84.7,
    '41.5': 84.6,
    '42.5': 84.4,
    '43.5': 84.2,
    '44.5': 84.1,
    '45.5': 83.9,
    '46.5': 83.7,
    '47.5': 83.6,
    '48.5': 83.4,
    '49.5': 83.2,
    '50.5': 83.1,
    '51.5': 82.9,
    '52.5': 82.7,
    '53.5': 82.6,
    '54.5': 82.4,
    '55.5': 82.2,
    '56.5': 82.1,
    '57.5': 81.9,
    '58.5': 81.7,
    '59.5': 81.6,
    '60.5': 81.4,
    '61.5': 81.3,
    '62.5': 81.1,
    '63.5': 80.9,
    '64.5': 80.8,
    '65.5': 80.6,
    '66.5': 80.5,
    '67.5': 80.3,
    '68.5': 80.1,
    '69.5': 80,
    '70.5': 79.8,
    '71.5': 79.7,
    '72.5': 79.5,
    '73.5': 79.4,
    '74.5': 79.2,
    '75.5': 79,
    '76.5': 78.9,
    '77.5': 78.7,
    '78.5': 78.6,
    '79.5': 78.4,
    '80.5': 78.3,
    '81.5': 78.1,
    '82.5': 78,
    '83.5': 77.8,
    '84.5': 77.6,
    '85.5': 77.5,
    '86.5': 77.3,
    '87.5': 77.2,
    '88.5': 77,
    '89.5': 76.9,
    '90.5': 76.7,
    '91.5': 76.6,
    '92.5': 76.4,
    '93.5': 76.3,
    '94.5': 76.1,
    '95.5': 76,
    '96.5': 75.9,
    '97.5': 75.7,
    '98.5': 75.6,
    '99.5': 75.4,
    '100.5': 75.3,
    '101.5': 75.1,
    '102.5': 75,
    '103.5': 74.8,
    '104.5': 74.7,
    '105.5': 74.5,
    '106.5': 74.4,
    '107.5': 74.2,
    '108.5': 74.1,
    '109.5': 74,
    '110.5': 73.8,
    '111.5': 73.7,
    '112.5': 73.5,
    '113.5': 73.4,
    '114.5': 73.2,
    '115.5': 73.1,
    '116.5': 73,
    '117.5': 72.8,
    '118.5': 72.7,
    '119.5': 72.6,
    '120.5': 72.4,
    '121.5': 72.3,
    '122.5': 72.1,
    '123.5': 72,
    '124.5': 71.9,
    '125.5': 71.7,
    '126.5': 71.6,
    '127.5': 71.4,
    '128.5': 71.3,
    '129.5': 71.2,
    '130.5': 71,
    '131.5': 70.9,
    '132.5': 70.8,
    '133.5': 70.6,
    '134.5': 70.5,
    '135.5': 70.4,
    '136.5': 70.2,
    '137.5': 70.1,
    '138.5': 70,
    '139.5': 69.8,
    '140.5': 69.7,
    '141.5': 69.6,
    '142.5': 69.4,
    '143.5': 69.3,
    '144.5': 69.2,
    '145.5': 69,
    '146.5': 68.9,
    '147.5': 68.8,
    '148.5': 68.6,
    '149.5': 68.5,
    '150.5': 68.4,
    '151.5': 68.3,
    '152.5': 68.1,
    '153.5': 68,
    '154.5': 67.9,
    '155.5': 67.7,
    '156.5': 67.6,
    '157.5': 67.5,
    '158.5': 67.4,
    '159.5': 67.2,
    '160.5': 67.1,
    '161.5': 67,
    '162.5': 66.9,
    '163.5': 66.7,
    '164.5': 66.6,
    '165.5': 66.5,
    '166.5': 66.3,
    '167.5': 66.2,
    '168.5': 66.1,
    '169.5': 66,
    '170.5': 65.9,
    '171.5': 65.7,
    '172.5': 65.6,
    '173.5': 65.5,
    '174.5': 65.4,
    '175.5': 65.2,
    '176.5': 65.1,
    '177.5': 65,
    '178.5': 64.9,
    '179.5': 64.8,
    '180.5': 64.6,
    '181.5': 64.5,
    '182.5': 64.4,
    '183.5': 64.3,
    '184.5': 64.2,
    '185.5': 64,
    '186.5': 63.9,
    '187.5': 63.8,
    '188.5': 63.7,
    '189.5': 63.6,
    '190.5': 63.4,
    '191.5': 63.3,
    '192.5': 63.2,
    '193.5': 63.1,
    '194.5': 63,
  },
  '82': {
    '0': 92.6,
    '1': 92.4,
    '2': 92.2,
    '3': 92.1,
    '4': 91.9,
    '5': 91.7,
    '6': 91.5,
    '7': 91.3,
    '8': 91.1,
    '9': 90.9,
    '10': 90.7,
    '11': 90.6,
    '12': 90.4,
    '13': 90.2,
    '14': 90,
    '15': 89.8,
    '16': 89.6,
    '17': 89.4,
    '18': 89.3,
    '19': 89.1,
    '20': 88.9,
    '21': 88.7,
    '22': 88.5,
    '23': 88.4,
    '24': 88.2,
    '25': 88,
    '26': 87.8,
    '27': 87.6,
    '28': 87.5,
    '29': 87.3,
    '30': 87.1,
    '31': 86.9,
    '32': 86.7,
    '33': 86.6,
    '34': 86.4,
    '35': 86.2,
    '36': 86,
    '37': 85.9,
    '38': 85.7,
    '39': 85.5,
    '40': 85.4,
    '41': 85.2,
    '42': 85,
    '43': 84.8,
    '44': 84.7,
    '45': 84.5,
    '46': 84.3,
    '47': 84.2,
    '48': 84,
    '49': 83.8,
    '50': 83.7,
    '51': 83.5,
    '52': 83.3,
    '53': 83.2,
    '54': 83,
    '55': 82.8,
    '56': 82.7,
    '57': 82.5,
    '58': 82.3,
    '59': 82.2,
    '60': 82,
    '61': 81.8,
    '62': 81.7,
    '63': 81.5,
    '64': 81.3,
    '65': 81.2,
    '66': 81,
    '67': 80.9,
    '68': 80.7,
    '69': 80.5,
    '70': 80.4,
    '71': 80.2,
    '72': 80.1,
    '73': 79.9,
    '74': 79.8,
    '75': 79.6,
    '76': 79.4,
    '77': 79.3,
    '78': 79.1,
    '79': 79,
    '80': 78.8,
    '81': 78.7,
    '82': 78.5,
    '83': 78.4,
    '84': 78.2,
    '85': 78,
    '86': 77.9,
    '87': 77.7,
    '88': 77.6,
    '89': 77.4,
    '90': 77.3,
    '91': 77.1,
    '92': 77,
    '93': 76.8,
    '94': 76.7,
    '95': 76.5,
    '96': 76.4,
    '97': 76.2,
    '98': 76.1,
    '99': 75.9,
    '100': 75.8,
    '101': 75.6,
    '102': 75.5,
    '103': 75.4,
    '104': 75.2,
    '105': 75.1,
    '106': 74.9,
    '107': 74.8,
    '108': 74.6,
    '109': 74.5,
    '110': 74.3,
    '111': 74.2,
    '112': 74,
    '113': 73.9,
    '114': 73.8,
    '115': 73.6,
    '116': 73.5,
    '117': 73.3,
    '118': 73.2,
    '119': 73.1,
    '120': 72.9,
    '121': 72.8,
    '122': 72.6,
    '123': 72.5,
    '124': 72.4,
    '125': 72.2,
    '126': 72.1,
    '127': 71.9,
    '128': 71.8,
    '129': 71.7,
    '130': 71.5,
    '131': 71.4,
    '132': 71.3,
    '133': 71.1,
    '134': 71,
    '135': 70.9,
    '136': 70.7,
    '137': 70.6,
    '138': 70.4,
    '139': 70.3,
    '140': 70.2,
    '141': 70,
    '142': 69.9,
    '143': 69.8,
    '144': 69.6,
    '145': 69.5,
    '146': 69.4,
    '147': 69.3,
    '148': 69.1,
    '149': 69,
    '150': 68.9,
    '151': 68.7,
    '152': 68.6,
    '153': 68.5,
    '154': 68.3,
    '155': 68.2,
    '156': 68.1,
    '157': 68,
    '158': 67.8,
    '159': 67.7,
    '160': 67.6,
    '161': 67.4,
    '162': 67.3,
    '163': 67.2,
    '164': 67.1,
    '165': 66.9,
    '166': 66.8,
    '167': 66.7,
    '168': 66.6,
    '169': 66.4,
    '170': 66.3,
    '171': 66.2,
    '172': 66.1,
    '173': 65.9,
    '174': 65.8,
    '175': 65.7,
    '176': 65.6,
    '177': 65.5,
    '178': 65.3,
    '179': 65.2,
    '180': 65.1,
    '181': 65,
    '182': 64.8,
    '183': 64.7,
    '184': 64.6,
    '185': 64.5,
    '186': 64.4,
    '187': 64.2,
    '188': 64.1,
    '189': 64,
    '190': 63.9,
    '191': 63.8,
    '192': 63.6,
    '193': 63.5,
    '194': 63.4,
    '195': 63.3,
    '0.5': 92.5,
    '1.5': 92.3,
    '2.5': 92.2,
    '3.5': 92,
    '4.5': 91.8,
    '5.5': 91.6,
    '6.5': 91.4,
    '7.5': 91.2,
    '8.5': 91,
    '9.5': 90.8,
    '10.5': 90.6,
    '11.5': 90.5,
    '12.5': 90.3,
    '13.5': 90.1,
    '14.5': 89.9,
    '15.5': 89.7,
    '16.5': 89.5,
    '17.5': 89.4,
    '18.5': 89.2,
    '19.5': 89,
    '20.5': 88.8,
    '21.5': 88.6,
    '22.5': 88.4,
    '23.5': 88.3,
    '24.5': 88.1,
    '25.5': 87.9,
    '26.5': 87.7,
    '27.5': 87.5,
    '28.5': 87.4,
    '29.5': 87.2,
    '30.5': 87,
    '31.5': 86.8,
    '32.5': 86.7,
    '33.5': 86.5,
    '34.5': 86.3,
    '35.5': 86.1,
    '36.5': 86,
    '37.5': 85.8,
    '38.5': 85.6,
    '39.5': 85.4,
    '40.5': 85.3,
    '41.5': 85.1,
    '42.5': 84.9,
    '43.5': 84.8,
    '44.5': 84.6,
    '45.5': 84.4,
    '46.5': 84.2,
    '47.5': 84.1,
    '48.5': 83.9,
    '49.5': 83.7,
    '50.5': 83.6,
    '51.5': 83.4,
    '52.5': 83.2,
    '53.5': 83.1,
    '54.5': 82.9,
    '55.5': 82.7,
    '56.5': 82.6,
    '57.5': 82.4,
    '58.5': 82.2,
    '59.5': 82.1,
    '60.5': 81.9,
    '61.5': 81.8,
    '62.5': 81.6,
    '63.5': 81.4,
    '64.5': 81.3,
    '65.5': 81.1,
    '66.5': 80.9,
    '67.5': 80.8,
    '68.5': 80.6,
    '69.5': 80.5,
    '70.5': 80.3,
    '71.5': 80.1,
    '72.5': 80,
    '73.5': 79.8,
    '74.5': 79.7,
    '75.5': 79.5,
    '76.5': 79.4,
    '77.5': 79.2,
    '78.5': 79,
    '79.5': 78.9,
    '80.5': 78.7,
    '81.5': 78.6,
    '82.5': 78.4,
    '83.5': 78.3,
    '84.5': 78.1,
    '85.5': 78,
    '86.5': 77.8,
    '87.5': 77.7,
    '88.5': 77.5,
    '89.5': 77.4,
    '90.5': 77.2,
    '91.5': 77.1,
    '92.5': 76.9,
    '93.5': 76.8,
    '94.5': 76.6,
    '95.5': 76.5,
    '96.5': 76.3,
    '97.5': 76.2,
    '98.5': 76,
    '99.5': 75.9,
    '100.5': 75.7,
    '101.5': 75.6,
    '102.5': 75.4,
    '103.5': 75.3,
    '104.5': 75.1,
    '105.5': 75,
    '106.5': 74.8,
    '107.5': 74.7,
    '108.5': 74.6,
    '109.5': 74.4,
    '110.5': 74.3,
    '111.5': 74.1,
    '112.5': 74,
    '113.5': 73.8,
    '114.5': 73.7,
    '115.5': 73.6,
    '116.5': 73.4,
    '117.5': 73.3,
    '118.5': 73.1,
    '119.5': 73,
    '120.5': 72.8,
    '121.5': 72.7,
    '122.5': 72.6,
    '123.5': 72.4,
    '124.5': 72.3,
    '125.5': 72.2,
    '126.5': 72,
    '127.5': 71.9,
    '128.5': 71.7,
    '129.5': 71.6,
    '130.5': 71.5,
    '131.5': 71.3,
    '132.5': 71.2,
    '133.5': 71.1,
    '134.5': 70.9,
    '135.5': 70.8,
    '136.5': 70.7,
    '137.5': 70.5,
    '138.5': 70.4,
    '139.5': 70.2,
    '140.5': 70.1,
    '141.5': 70,
    '142.5': 69.9,
    '143.5': 69.7,
    '144.5': 69.6,
    '145.5': 69.4,
    '146.5': 69.3,
    '147.5': 69.2,
    '148.5': 69.1,
    '149.5': 68.9,
    '150.5': 68.8,
    '151.5': 68.7,
    '152.5': 68.5,
    '153.5': 68.4,
    '154.5': 68.3,
    '155.5': 68.2,
    '156.5': 68,
    '157.5': 67.9,
    '158.5': 67.8,
    '159.5': 67.6,
    '160.5': 67.5,
    '161.5': 67.4,
    '162.5': 67.3,
    '163.5': 67.1,
    '164.5': 67,
    '165.5': 66.9,
    '166.5': 66.8,
    '167.5': 66.6,
    '168.5': 66.5,
    '169.5': 66.4,
    '170.5': 66.3,
    '171.5': 66.1,
    '172.5': 66,
    '173.5': 65.9,
    '174.5': 65.8,
    '175.5': 65.6,
    '176.5': 65.5,
    '177.5': 65.4,
    '178.5': 65.3,
    '179.5': 65.1,
    '180.5': 65,
    '181.5': 64.9,
    '182.5': 64.8,
    '183.5': 64.7,
    '184.5': 64.5,
    '185.5': 64.4,
    '186.5': 64.3,
    '187.5': 64.2,
    '188.5': 64.1,
    '189.5': 63.9,
    '190.5': 63.8,
    '191.5': 63.7,
    '192.5': 63.6,
    '193.5': 63.5,
    '194.5': 63.4,
  },
  '82.5': {
    '0': 93.2,
    '1': 93,
    '2': 92.8,
    '3': 92.6,
    '4': 92.4,
    '5': 92.2,
    '6': 92.1,
    '7': 91.9,
    '8': 91.7,
    '9': 91.5,
    '10': 91.3,
    '11': 91.1,
    '12': 90.9,
    '13': 90.7,
    '14': 90.6,
    '15': 90.4,
    '16': 90.2,
    '17': 90,
    '18': 89.8,
    '19': 89.6,
    '20': 89.4,
    '21': 89.3,
    '22': 89.1,
    '23': 88.9,
    '24': 88.7,
    '25': 88.5,
    '26': 88.4,
    '27': 88.2,
    '28': 88,
    '29': 87.8,
    '30': 87.6,
    '31': 87.5,
    '32': 87.3,
    '33': 87.1,
    '34': 86.9,
    '35': 86.8,
    '36': 86.6,
    '37': 86.4,
    '38': 86.2,
    '39': 86.1,
    '40': 85.9,
    '41': 85.7,
    '42': 85.5,
    '43': 85.4,
    '44': 85.2,
    '45': 85,
    '46': 84.8,
    '47': 84.7,
    '48': 84.5,
    '49': 84.3,
    '50': 84.2,
    '51': 84,
    '52': 83.8,
    '53': 83.7,
    '54': 83.5,
    '55': 83.3,
    '56': 83.2,
    '57': 83,
    '58': 82.8,
    '59': 82.7,
    '60': 82.5,
    '61': 82.3,
    '62': 82.2,
    '63': 82,
    '64': 81.8,
    '65': 81.7,
    '66': 81.5,
    '67': 81.4,
    '68': 81.2,
    '69': 81,
    '70': 80.9,
    '71': 80.7,
    '72': 80.6,
    '73': 80.4,
    '74': 80.2,
    '75': 80.1,
    '76': 79.9,
    '77': 79.8,
    '78': 79.6,
    '79': 79.5,
    '80': 79.3,
    '81': 79.1,
    '82': 79,
    '83': 78.8,
    '84': 78.7,
    '85': 78.5,
    '86': 78.4,
    '87': 78.2,
    '88': 78.1,
    '89': 77.9,
    '90': 77.8,
    '91': 77.6,
    '92': 77.4,
    '93': 77.3,
    '94': 77.1,
    '95': 77,
    '96': 76.8,
    '97': 76.7,
    '98': 76.5,
    '99': 76.4,
    '100': 76.2,
    '101': 76.1,
    '102': 76,
    '103': 75.8,
    '104': 75.7,
    '105': 75.5,
    '106': 75.4,
    '107': 75.2,
    '108': 75.1,
    '109': 74.9,
    '110': 74.8,
    '111': 74.6,
    '112': 74.5,
    '113': 74.4,
    '114': 74.2,
    '115': 74.1,
    '116': 73.9,
    '117': 73.8,
    '118': 73.6,
    '119': 73.5,
    '120': 73.4,
    '121': 73.2,
    '122': 73.1,
    '123': 72.9,
    '124': 72.8,
    '125': 72.7,
    '126': 72.5,
    '127': 72.4,
    '128': 72.2,
    '129': 72.1,
    '130': 72,
    '131': 71.8,
    '132': 71.7,
    '133': 71.6,
    '134': 71.4,
    '135': 71.3,
    '136': 71.1,
    '137': 71,
    '138': 70.9,
    '139': 70.7,
    '140': 70.6,
    '141': 70.5,
    '142': 70.3,
    '143': 70.2,
    '144': 70.1,
    '145': 69.9,
    '146': 69.8,
    '147': 69.7,
    '148': 69.5,
    '149': 69.4,
    '150': 69.3,
    '151': 69.1,
    '152': 69,
    '153': 68.9,
    '154': 68.8,
    '155': 68.6,
    '156': 68.5,
    '157': 68.4,
    '158': 68.2,
    '159': 68.1,
    '160': 68,
    '161': 67.8,
    '162': 67.7,
    '163': 67.6,
    '164': 67.5,
    '165': 67.3,
    '166': 67.2,
    '167': 67.1,
    '168': 67,
    '169': 66.8,
    '170': 66.7,
    '171': 66.6,
    '172': 66.5,
    '173': 66.3,
    '174': 66.2,
    '175': 66.1,
    '176': 66,
    '177': 65.8,
    '178': 65.7,
    '179': 65.6,
    '180': 65.5,
    '181': 65.4,
    '182': 65.2,
    '183': 65.1,
    '184': 65,
    '185': 64.9,
    '186': 64.7,
    '187': 64.6,
    '188': 64.5,
    '189': 64.4,
    '190': 64.3,
    '191': 64.1,
    '192': 64,
    '193': 63.9,
    '194': 63.8,
    '195': 63.7,
    '0.5': 93.1,
    '1.5': 92.9,
    '2.5': 92.7,
    '3.5': 92.5,
    '4.5': 92.3,
    '5.5': 92.2,
    '6.5': 92,
    '7.5': 91.8,
    '8.5': 91.6,
    '9.5': 91.4,
    '10.5': 91.2,
    '11.5': 91,
    '12.5': 90.8,
    '13.5': 90.6,
    '14.5': 90.5,
    '15.5': 90.3,
    '16.5': 90.1,
    '17.5': 89.9,
    '18.5': 89.7,
    '19.5': 89.5,
    '20.5': 89.4,
    '21.5': 89.2,
    '22.5': 89,
    '23.5': 88.8,
    '24.5': 88.6,
    '25.5': 88.4,
    '26.5': 88.3,
    '27.5': 88.1,
    '28.5': 87.9,
    '29.5': 87.7,
    '30.5': 87.5,
    '31.5': 87.4,
    '32.5': 87.2,
    '33.5': 87,
    '34.5': 86.8,
    '35.5': 86.7,
    '36.5': 86.5,
    '37.5': 86.3,
    '38.5': 86.1,
    '39.5': 86,
    '40.5': 85.8,
    '41.5': 85.6,
    '42.5': 85.4,
    '43.5': 85.3,
    '44.5': 85.1,
    '45.5': 84.9,
    '46.5': 84.8,
    '47.5': 84.6,
    '48.5': 84.4,
    '49.5': 84.3,
    '50.5': 84.1,
    '51.5': 83.9,
    '52.5': 83.7,
    '53.5': 83.6,
    '54.5': 83.4,
    '55.5': 83.2,
    '56.5': 83.1,
    '57.5': 82.9,
    '58.5': 82.8,
    '59.5': 82.6,
    '60.5': 82.4,
    '61.5': 82.3,
    '62.5': 82.1,
    '63.5': 81.9,
    '64.5': 81.8,
    '65.5': 81.6,
    '66.5': 81.4,
    '67.5': 81.3,
    '68.5': 81.1,
    '69.5': 81,
    '70.5': 80.8,
    '71.5': 80.6,
    '72.5': 80.5,
    '73.5': 80.3,
    '74.5': 80.2,
    '75.5': 80,
    '76.5': 79.8,
    '77.5': 79.7,
    '78.5': 79.5,
    '79.5': 79.4,
    '80.5': 79.2,
    '81.5': 79.1,
    '82.5': 78.9,
    '83.5': 78.8,
    '84.5': 78.6,
    '85.5': 78.4,
    '86.5': 78.3,
    '87.5': 78.1,
    '88.5': 78,
    '89.5': 77.8,
    '90.5': 77.7,
    '91.5': 77.5,
    '92.5': 77.4,
    '93.5': 77.2,
    '94.5': 77.1,
    '95.5': 76.9,
    '96.5': 76.8,
    '97.5': 76.6,
    '98.5': 76.5,
    '99.5': 76.3,
    '100.5': 76.2,
    '101.5': 76,
    '102.5': 75.9,
    '103.5': 75.7,
    '104.5': 75.6,
    '105.5': 75.4,
    '106.5': 75.3,
    '107.5': 75.1,
    '108.5': 75,
    '109.5': 74.9,
    '110.5': 74.7,
    '111.5': 74.6,
    '112.5': 74.4,
    '113.5': 74.3,
    '114.5': 74.1,
    '115.5': 74,
    '116.5': 73.9,
    '117.5': 73.7,
    '118.5': 73.6,
    '119.5': 73.4,
    '120.5': 73.3,
    '121.5': 73.1,
    '122.5': 73,
    '123.5': 72.9,
    '124.5': 72.7,
    '125.5': 72.6,
    '126.5': 72.4,
    '127.5': 72.3,
    '128.5': 72.2,
    '129.5': 72,
    '130.5': 71.9,
    '131.5': 71.8,
    '132.5': 71.6,
    '133.5': 71.5,
    '134.5': 71.3,
    '135.5': 71.2,
    '136.5': 71.1,
    '137.5': 70.9,
    '138.5': 70.8,
    '139.5': 70.7,
    '140.5': 70.5,
    '141.5': 70.4,
    '142.5': 70.3,
    '143.5': 70.1,
    '144.5': 70,
    '145.5': 69.9,
    '146.5': 69.7,
    '147.5': 69.6,
    '148.5': 69.5,
    '149.5': 69.3,
    '150.5': 69.2,
    '151.5': 69.1,
    '152.5': 68.9,
    '153.5': 68.8,
    '154.5': 68.7,
    '155.5': 68.6,
    '156.5': 68.4,
    '157.5': 68.3,
    '158.5': 68.2,
    '159.5': 68,
    '160.5': 67.9,
    '161.5': 67.8,
    '162.5': 67.7,
    '163.5': 67.5,
    '164.5': 67.4,
    '165.5': 67.3,
    '166.5': 67.2,
    '167.5': 67,
    '168.5': 66.9,
    '169.5': 66.8,
    '170.5': 66.6,
    '171.5': 66.5,
    '172.5': 66.4,
    '173.5': 66.3,
    '174.5': 66.2,
    '175.5': 66,
    '176.5': 65.9,
    '177.5': 65.8,
    '178.5': 65.7,
    '179.5': 65.5,
    '180.5': 65.4,
    '181.5': 65.3,
    '182.5': 65.2,
    '183.5': 65,
    '184.5': 64.9,
    '185.5': 64.8,
    '186.5': 64.7,
    '187.5': 64.6,
    '188.5': 64.4,
    '189.5': 64.3,
    '190.5': 64.2,
    '191.5': 64.1,
    '192.5': 64,
    '193.5': 63.8,
    '194.5': 63.7,
  },
  '83': {
    '0': 93.8,
    '1': 93.6,
    '2': 93.4,
    '3': 93.2,
    '4': 93,
    '5': 92.8,
    '6': 92.6,
    '7': 92.4,
    '8': 92.2,
    '9': 92.1,
    '10': 91.9,
    '11': 91.7,
    '12': 91.5,
    '13': 91.3,
    '14': 91.1,
    '15': 90.9,
    '16': 90.7,
    '17': 90.6,
    '18': 90.4,
    '19': 90.2,
    '20': 90,
    '21': 89.8,
    '22': 89.6,
    '23': 89.4,
    '24': 89.3,
    '25': 89.1,
    '26': 88.9,
    '27': 88.7,
    '28': 88.5,
    '29': 88.4,
    '30': 88.2,
    '31': 88,
    '32': 87.8,
    '33': 87.6,
    '34': 87.5,
    '35': 87.3,
    '36': 87.1,
    '37': 86.9,
    '38': 86.8,
    '39': 86.6,
    '40': 86.4,
    '41': 86.2,
    '42': 86.1,
    '43': 85.9,
    '44': 85.7,
    '45': 85.5,
    '46': 85.4,
    '47': 85.2,
    '48': 85,
    '49': 84.9,
    '50': 84.7,
    '51': 84.5,
    '52': 84.3,
    '53': 84.2,
    '54': 84,
    '55': 83.8,
    '56': 83.7,
    '57': 83.5,
    '58': 83.3,
    '59': 83.2,
    '60': 83,
    '61': 82.8,
    '62': 82.7,
    '63': 82.5,
    '64': 82.3,
    '65': 82.2,
    '66': 82,
    '67': 81.9,
    '68': 81.7,
    '69': 81.5,
    '70': 81.4,
    '71': 81.2,
    '72': 81,
    '73': 80.9,
    '74': 80.7,
    '75': 80.6,
    '76': 80.4,
    '77': 80.2,
    '78': 80.1,
    '79': 79.9,
    '80': 79.8,
    '81': 79.6,
    '82': 79.5,
    '83': 79.3,
    '84': 79.1,
    '85': 79,
    '86': 78.8,
    '87': 78.7,
    '88': 78.5,
    '89': 78.4,
    '90': 78.2,
    '91': 78.1,
    '92': 77.9,
    '93': 77.8,
    '94': 77.6,
    '95': 77.5,
    '96': 77.3,
    '97': 77.2,
    '98': 77,
    '99': 76.9,
    '100': 76.7,
    '101': 76.6,
    '102': 76.4,
    '103': 76.3,
    '104': 76.1,
    '105': 76,
    '106': 75.8,
    '107': 75.7,
    '108': 75.5,
    '109': 75.4,
    '110': 75.2,
    '111': 75.1,
    '112': 74.9,
    '113': 74.8,
    '114': 74.7,
    '115': 74.5,
    '116': 74.4,
    '117': 74.2,
    '118': 74.1,
    '119': 73.9,
    '120': 73.8,
    '121': 73.7,
    '122': 73.5,
    '123': 73.4,
    '124': 73.2,
    '125': 73.1,
    '126': 72.9,
    '127': 72.8,
    '128': 72.7,
    '129': 72.5,
    '130': 72.4,
    '131': 72.3,
    '132': 72.1,
    '133': 72,
    '134': 71.8,
    '135': 71.7,
    '136': 71.6,
    '137': 71.4,
    '138': 71.3,
    '139': 71.2,
    '140': 71,
    '141': 70.9,
    '142': 70.8,
    '143': 70.6,
    '144': 70.5,
    '145': 70.4,
    '146': 70.2,
    '147': 70.1,
    '148': 70,
    '149': 69.8,
    '150': 69.7,
    '151': 69.6,
    '152': 69.4,
    '153': 69.3,
    '154': 69.2,
    '155': 69,
    '156': 68.9,
    '157': 68.8,
    '158': 68.6,
    '159': 68.5,
    '160': 68.4,
    '161': 68.3,
    '162': 68.1,
    '163': 68,
    '164': 67.9,
    '165': 67.7,
    '166': 67.6,
    '167': 67.5,
    '168': 67.4,
    '169': 67.2,
    '170': 67.1,
    '171': 67,
    '172': 66.9,
    '173': 66.7,
    '174': 66.6,
    '175': 66.5,
    '176': 66.4,
    '177': 66.2,
    '178': 66.1,
    '179': 66,
    '180': 65.9,
    '181': 65.7,
    '182': 65.6,
    '183': 65.5,
    '184': 65.4,
    '185': 65.3,
    '186': 65.1,
    '187': 65,
    '188': 64.9,
    '189': 64.8,
    '190': 64.6,
    '191': 64.5,
    '192': 64.4,
    '193': 64.3,
    '194': 64.2,
    '195': 64.1,
    '0.5': 93.7,
    '1.5': 93.5,
    '2.5': 93.3,
    '3.5': 93.1,
    '4.5': 92.9,
    '5.5': 92.7,
    '6.5': 92.5,
    '7.5': 92.3,
    '8.5': 92.2,
    '9.5': 92,
    '10.5': 91.8,
    '11.5': 91.6,
    '12.5': 91.4,
    '13.5': 91.2,
    '14.5': 91,
    '15.5': 90.8,
    '16.5': 90.6,
    '17.5': 90.5,
    '18.5': 90.3,
    '19.5': 90.1,
    '20.5': 89.9,
    '21.5': 89.7,
    '22.5': 89.5,
    '23.5': 89.4,
    '24.5': 89.2,
    '25.5': 89,
    '26.5': 88.8,
    '27.5': 88.6,
    '28.5': 88.4,
    '29.5': 88.3,
    '30.5': 88.1,
    '31.5': 87.9,
    '32.5': 87.7,
    '33.5': 87.6,
    '34.5': 87.4,
    '35.5': 87.2,
    '36.5': 87,
    '37.5': 86.8,
    '38.5': 86.7,
    '39.5': 86.5,
    '40.5': 86.3,
    '41.5': 86.1,
    '42.5': 86,
    '43.5': 85.8,
    '44.5': 85.6,
    '45.5': 85.4,
    '46.5': 85.3,
    '47.5': 85.1,
    '48.5': 84.9,
    '49.5': 84.8,
    '50.5': 84.6,
    '51.5': 84.4,
    '52.5': 84.3,
    '53.5': 84.1,
    '54.5': 83.9,
    '55.5': 83.7,
    '56.5': 83.6,
    '57.5': 83.4,
    '58.5': 83.3,
    '59.5': 83.1,
    '60.5': 82.9,
    '61.5': 82.8,
    '62.5': 82.6,
    '63.5': 82.4,
    '64.5': 82.3,
    '65.5': 82.1,
    '66.5': 81.9,
    '67.5': 81.8,
    '68.5': 81.6,
    '69.5': 81.4,
    '70.5': 81.3,
    '71.5': 81.1,
    '72.5': 81,
    '73.5': 80.8,
    '74.5': 80.6,
    '75.5': 80.5,
    '76.5': 80.3,
    '77.5': 80.2,
    '78.5': 80,
    '79.5': 79.9,
    '80.5': 79.7,
    '81.5': 79.5,
    '82.5': 79.4,
    '83.5': 79.2,
    '84.5': 79.1,
    '85.5': 78.9,
    '86.5': 78.8,
    '87.5': 78.6,
    '88.5': 78.4,
    '89.5': 78.3,
    '90.5': 78.1,
    '91.5': 78,
    '92.5': 77.8,
    '93.5': 77.7,
    '94.5': 77.5,
    '95.5': 77.4,
    '96.5': 77.2,
    '97.5': 77.1,
    '98.5': 76.9,
    '99.5': 76.8,
    '100.5': 76.6,
    '101.5': 76.5,
    '102.5': 76.3,
    '103.5': 76.2,
    '104.5': 76,
    '105.5': 75.9,
    '106.5': 75.7,
    '107.5': 75.6,
    '108.5': 75.5,
    '109.5': 75.3,
    '110.5': 75.2,
    '111.5': 75,
    '112.5': 74.9,
    '113.5': 74.7,
    '114.5': 74.6,
    '115.5': 74.4,
    '116.5': 74.3,
    '117.5': 74.2,
    '118.5': 74,
    '119.5': 73.9,
    '120.5': 73.7,
    '121.5': 73.6,
    '122.5': 73.4,
    '123.5': 73.3,
    '124.5': 73.2,
    '125.5': 73,
    '126.5': 72.9,
    '127.5': 72.7,
    '128.5': 72.6,
    '129.5': 72.5,
    '130.5': 72.3,
    '131.5': 72.2,
    '132.5': 72,
    '133.5': 71.9,
    '134.5': 71.8,
    '135.5': 71.6,
    '136.5': 71.5,
    '137.5': 71.4,
    '138.5': 71.2,
    '139.5': 71.1,
    '140.5': 71,
    '141.5': 70.8,
    '142.5': 70.7,
    '143.5': 70.5,
    '144.5': 70.4,
    '145.5': 70.3,
    '146.5': 70.2,
    '147.5': 70,
    '148.5': 69.9,
    '149.5': 69.8,
    '150.5': 69.6,
    '151.5': 69.5,
    '152.5': 69.4,
    '153.5': 69.2,
    '154.5': 69.1,
    '155.5': 69,
    '156.5': 68.8,
    '157.5': 68.7,
    '158.5': 68.6,
    '159.5': 68.4,
    '160.5': 68.3,
    '161.5': 68.2,
    '162.5': 68.1,
    '163.5': 67.9,
    '164.5': 67.8,
    '165.5': 67.7,
    '166.5': 67.5,
    '167.5': 67.4,
    '168.5': 67.3,
    '169.5': 67.2,
    '170.5': 67,
    '171.5': 66.9,
    '172.5': 66.8,
    '173.5': 66.7,
    '174.5': 66.5,
    '175.5': 66.5,
    '176.5': 66.3,
    '177.5': 66.2,
    '178.5': 66.1,
    '179.5': 65.9,
    '180.5': 65.8,
    '181.5': 65.7,
    '182.5': 65.6,
    '183.5': 65.4,
    '184.5': 65.3,
    '185.5': 65.2,
    '186.5': 65.1,
    '187.5': 65,
    '188.5': 64.8,
    '189.5': 64.7,
    '190.5': 64.6,
    '191.5': 64.5,
    '192.5': 64.3,
    '193.5': 64.2,
    '194.5': 64.1,
  },
  '83.5': {
    '0': 94.4,
    '1': 94.2,
    '2': 94,
    '3': 93.8,
    '4': 93.6,
    '5': 93.4,
    '6': 93.2,
    '7': 93,
    '8': 92.8,
    '9': 92.6,
    '10': 92.4,
    '11': 92.2,
    '12': 92.1,
    '13': 91.9,
    '14': 91.7,
    '15': 91.5,
    '16': 91.3,
    '17': 91.1,
    '18': 90.9,
    '19': 90.7,
    '20': 90.5,
    '21': 90.4,
    '22': 90.2,
    '23': 90,
    '24': 89.8,
    '25': 89.6,
    '26': 89.4,
    '27': 89.3,
    '28': 89.1,
    '29': 88.9,
    '30': 88.7,
    '31': 88.5,
    '32': 88.4,
    '33': 88.2,
    '34': 88,
    '35': 87.8,
    '36': 87.6,
    '37': 87.5,
    '38': 87.3,
    '39': 87.1,
    '40': 86.9,
    '41': 86.8,
    '42': 86.6,
    '43': 86.4,
    '44': 86.2,
    '45': 86.1,
    '46': 85.9,
    '47': 85.7,
    '48': 85.5,
    '49': 85.4,
    '50': 85.2,
    '51': 85,
    '52': 84.8,
    '53': 84.7,
    '54': 84.5,
    '55': 84.3,
    '56': 84.2,
    '57': 84,
    '58': 83.8,
    '59': 83.7,
    '60': 83.5,
    '61': 83.3,
    '62': 83.2,
    '63': 83,
    '64': 82.8,
    '65': 82.7,
    '66': 82.5,
    '67': 82.3,
    '68': 82.2,
    '69': 82,
    '70': 81.9,
    '71': 81.7,
    '72': 81.5,
    '73': 81.4,
    '74': 81.2,
    '75': 81,
    '76': 80.9,
    '77': 80.7,
    '78': 80.6,
    '79': 80.4,
    '80': 80.3,
    '81': 80.1,
    '82': 79.9,
    '83': 79.8,
    '84': 79.6,
    '85': 79.5,
    '86': 79.3,
    '87': 79.1,
    '88': 79,
    '89': 78.8,
    '90': 78.7,
    '91': 78.5,
    '92': 78.4,
    '93': 78.2,
    '94': 78.1,
    '95': 77.9,
    '96': 77.8,
    '97': 77.6,
    '98': 77.5,
    '99': 77.3,
    '100': 77.2,
    '101': 77,
    '102': 76.9,
    '103': 76.7,
    '104': 76.6,
    '105': 76.4,
    '106': 76.3,
    '107': 76.1,
    '108': 76,
    '109': 75.8,
    '110': 75.7,
    '111': 75.5,
    '112': 75.4,
    '113': 75.2,
    '114': 75.1,
    '115': 74.9,
    '116': 74.8,
    '117': 74.7,
    '118': 74.5,
    '119': 74.4,
    '120': 74.2,
    '121': 74.1,
    '122': 74,
    '123': 73.8,
    '124': 73.7,
    '125': 73.5,
    '126': 73.4,
    '127': 73.2,
    '128': 73.1,
    '129': 73,
    '130': 72.8,
    '131': 72.7,
    '132': 72.5,
    '133': 72.4,
    '134': 72.3,
    '135': 72.1,
    '136': 72,
    '137': 71.9,
    '138': 71.7,
    '139': 71.6,
    '140': 71.4,
    '141': 71.3,
    '142': 71.2,
    '143': 71,
    '144': 70.9,
    '145': 70.8,
    '146': 70.6,
    '147': 70.5,
    '148': 70.4,
    '149': 70.2,
    '150': 70.1,
    '151': 70,
    '152': 69.8,
    '153': 69.7,
    '154': 69.6,
    '155': 69.4,
    '156': 69.3,
    '157': 69.2,
    '158': 69,
    '159': 68.9,
    '160': 68.8,
    '161': 68.7,
    '162': 68.5,
    '163': 68.4,
    '164': 68.3,
    '165': 68.1,
    '166': 68,
    '167': 67.9,
    '168': 67.8,
    '169': 67.6,
    '170': 67.5,
    '171': 67.4,
    '172': 67.3,
    '173': 67.1,
    '174': 67,
    '175': 66.9,
    '176': 66.8,
    '177': 66.6,
    '178': 66.5,
    '179': 66.4,
    '180': 66.3,
    '181': 66.1,
    '182': 66,
    '183': 65.9,
    '184': 65.8,
    '185': 65.6,
    '186': 65.5,
    '187': 65.4,
    '188': 65.3,
    '189': 65.2,
    '190': 65,
    '191': 64.9,
    '192': 64.8,
    '193': 64.7,
    '194': 64.6,
    '195': 64.4,
    '0.5': 94.3,
    '1.5': 94.1,
    '2.5': 93.9,
    '3.5': 93.7,
    '4.5': 93.5,
    '5.5': 93.3,
    '6.5': 93.1,
    '7.5': 92.9,
    '8.5': 92.7,
    '9.5': 92.5,
    '10.5': 92.3,
    '11.5': 92.1,
    '12.5': 92,
    '13.5': 91.8,
    '14.5': 91.6,
    '15.5': 91.4,
    '16.5': 91.2,
    '17.5': 91,
    '18.5': 90.8,
    '19.5': 90.6,
    '20.5': 90.5,
    '21.5': 90.3,
    '22.5': 90.1,
    '23.5': 89.9,
    '24.5': 89.7,
    '25.5': 89.5,
    '26.5': 89.3,
    '27.5': 89.2,
    '28.5': 89,
    '29.5': 88.8,
    '30.5': 88.6,
    '31.5': 88.4,
    '32.5': 88.3,
    '33.5': 88.1,
    '34.5': 87.9,
    '35.5': 87.7,
    '36.5': 87.5,
    '37.5': 87.4,
    '38.5': 87.2,
    '39.5': 87,
    '40.5': 86.8,
    '41.5': 86.7,
    '42.5': 86.5,
    '43.5': 86.3,
    '44.5': 86.1,
    '45.5': 86,
    '46.5': 85.8,
    '47.5': 85.6,
    '48.5': 85.4,
    '49.5': 85.3,
    '50.5': 85.1,
    '51.5': 84.9,
    '52.5': 84.8,
    '53.5': 84.6,
    '54.5': 84.4,
    '55.5': 84.3,
    '56.5': 84.1,
    '57.5': 83.9,
    '58.5': 83.8,
    '59.5': 83.6,
    '60.5': 83.4,
    '61.5': 83.2,
    '62.5': 83.1,
    '63.5': 82.9,
    '64.5': 82.8,
    '65.5': 82.6,
    '66.5': 82.4,
    '67.5': 82.3,
    '68.5': 82.1,
    '69.5': 81.9,
    '70.5': 81.8,
    '71.5': 81.6,
    '72.5': 81.4,
    '73.5': 81.3,
    '74.5': 81.1,
    '75.5': 81,
    '76.5': 80.8,
    '77.5': 80.6,
    '78.5': 80.5,
    '79.5': 80.3,
    '80.5': 80.2,
    '81.5': 80,
    '82.5': 79.9,
    '83.5': 79.7,
    '84.5': 79.5,
    '85.5': 79.4,
    '86.5': 79.2,
    '87.5': 79.1,
    '88.5': 78.9,
    '89.5': 78.8,
    '90.5': 78.6,
    '91.5': 78.5,
    '92.5': 78.3,
    '93.5': 78.1,
    '94.5': 78,
    '95.5': 77.8,
    '96.5': 77.7,
    '97.5': 77.5,
    '98.5': 77.4,
    '99.5': 77.2,
    '100.5': 77.1,
    '101.5': 76.9,
    '102.5': 76.8,
    '103.5': 76.6,
    '104.5': 76.5,
    '105.5': 76.3,
    '106.5': 76.2,
    '107.5': 76,
    '108.5': 75.9,
    '109.5': 75.8,
    '110.5': 75.6,
    '111.5': 75.5,
    '112.5': 75.3,
    '113.5': 75.2,
    '114.5': 75,
    '115.5': 74.9,
    '116.5': 74.7,
    '117.5': 74.6,
    '118.5': 74.4,
    '119.5': 74.3,
    '120.5': 74.2,
    '121.5': 74,
    '122.5': 73.9,
    '123.5': 73.7,
    '124.5': 73.6,
    '125.5': 73.5,
    '126.5': 73.3,
    '127.5': 73.2,
    '128.5': 73,
    '129.5': 72.9,
    '130.5': 72.8,
    '131.5': 72.6,
    '132.5': 72.5,
    '133.5': 72.3,
    '134.5': 72.2,
    '135.5': 72.1,
    '136.5': 71.9,
    '137.5': 71.8,
    '138.5': 71.6,
    '139.5': 71.5,
    '140.5': 71.4,
    '141.5': 71.2,
    '142.5': 71.1,
    '143.5': 71,
    '144.5': 70.8,
    '145.5': 70.7,
    '146.5': 70.6,
    '147.5': 70.4,
    '148.5': 70.3,
    '149.5': 70.2,
    '150.5': 70,
    '151.5': 69.9,
    '152.5': 69.8,
    '153.5': 69.6,
    '154.5': 69.5,
    '155.5': 69.4,
    '156.5': 69.2,
    '157.5': 69.1,
    '158.5': 69,
    '159.5': 68.9,
    '160.5': 68.7,
    '161.5': 68.6,
    '162.5': 68.5,
    '163.5': 68.3,
    '164.5': 68.2,
    '165.5': 68.1,
    '166.5': 68,
    '167.5': 67.8,
    '168.5': 67.7,
    '169.5': 67.6,
    '170.5': 67.4,
    '171.5': 67.3,
    '172.5': 67.2,
    '173.5': 67.1,
    '174.5': 66.9,
    '175.5': 66.8,
    '176.5': 66.7,
    '177.5': 66.6,
    '178.5': 66.4,
    '179.5': 66.3,
    '180.5': 66.2,
    '181.5': 66.1,
    '182.5': 65.9,
    '183.5': 65.8,
    '184.5': 65.7,
    '185.5': 65.6,
    '186.5': 65.5,
    '187.5': 65.3,
    '188.5': 65.2,
    '189.5': 65.1,
    '190.5': 65,
    '191.5': 64.8,
    '192.5': 64.7,
    '193.5': 64.6,
    '194.5': 64.5,
  },
  '84': {
    '0': 95,
    '1': 94.8,
    '2': 94.6,
    '3': 94.4,
    '4': 94.2,
    '5': 94,
    '6': 93.8,
    '7': 93.6,
    '8': 93.4,
    '9': 93.2,
    '10': 93,
    '11': 92.8,
    '12': 92.6,
    '13': 92.4,
    '14': 92.2,
    '15': 92,
    '16': 91.9,
    '17': 91.7,
    '18': 91.5,
    '19': 91.3,
    '20': 91.1,
    '21': 90.9,
    '22': 90.7,
    '23': 90.5,
    '24': 90.4,
    '25': 90.2,
    '26': 90,
    '27': 89.8,
    '28': 89.6,
    '29': 89.4,
    '30': 89.3,
    '31': 89.1,
    '32': 88.9,
    '33': 88.7,
    '34': 88.5,
    '35': 88.3,
    '36': 88.2,
    '37': 88,
    '38': 87.8,
    '39': 87.6,
    '40': 87.5,
    '41': 87.3,
    '42': 87.1,
    '43': 86.9,
    '44': 86.7,
    '45': 86.6,
    '46': 86.4,
    '47': 86.2,
    '48': 86.1,
    '49': 85.9,
    '50': 85.7,
    '51': 85.5,
    '52': 85.4,
    '53': 85.2,
    '54': 85,
    '55': 84.8,
    '56': 84.7,
    '57': 84.5,
    '58': 84.3,
    '59': 84.2,
    '60': 84,
    '61': 83.8,
    '62': 83.7,
    '63': 83.5,
    '64': 83.3,
    '65': 83.2,
    '66': 83,
    '67': 82.8,
    '68': 82.7,
    '69': 82.5,
    '70': 82.3,
    '71': 82.2,
    '72': 82,
    '73': 81.9,
    '74': 81.7,
    '75': 81.5,
    '76': 81.4,
    '77': 81.2,
    '78': 81,
    '79': 80.9,
    '80': 80.7,
    '81': 80.6,
    '82': 80.4,
    '83': 80.3,
    '84': 80.1,
    '85': 79.9,
    '86': 79.8,
    '87': 79.6,
    '88': 79.5,
    '89': 79.3,
    '90': 79.2,
    '91': 79,
    '92': 78.8,
    '93': 78.7,
    '94': 78.5,
    '95': 78.4,
    '96': 78.2,
    '97': 78.1,
    '98': 77.9,
    '99': 77.8,
    '100': 77.6,
    '101': 77.5,
    '102': 77.3,
    '103': 77.2,
    '104': 77,
    '105': 76.9,
    '106': 76.7,
    '107': 76.6,
    '108': 76.4,
    '109': 76.3,
    '110': 76.1,
    '111': 76,
    '112': 75.8,
    '113': 75.7,
    '114': 75.5,
    '115': 75.4,
    '116': 75.2,
    '117': 75.1,
    '118': 75,
    '119': 74.8,
    '120': 74.7,
    '121': 74.5,
    '122': 74.4,
    '123': 74.2,
    '124': 74.1,
    '125': 74,
    '126': 73.8,
    '127': 73.7,
    '128': 73.5,
    '129': 73.4,
    '130': 73.3,
    '131': 73.1,
    '132': 73,
    '133': 72.8,
    '134': 72.7,
    '135': 72.6,
    '136': 72.4,
    '137': 72.3,
    '138': 72.1,
    '139': 72,
    '140': 71.9,
    '141': 71.7,
    '142': 71.6,
    '143': 71.5,
    '144': 71.3,
    '145': 71.2,
    '146': 71,
    '147': 70.9,
    '148': 70.8,
    '149': 70.6,
    '150': 70.5,
    '151': 70.4,
    '152': 70.2,
    '153': 70.1,
    '154': 70,
    '155': 69.8,
    '156': 69.7,
    '157': 69.6,
    '158': 69.5,
    '159': 69.3,
    '160': 69.2,
    '161': 69.1,
    '162': 68.9,
    '163': 68.8,
    '164': 68.7,
    '165': 68.5,
    '166': 68.4,
    '167': 68.3,
    '168': 68.2,
    '169': 68,
    '170': 67.9,
    '171': 67.8,
    '172': 67.6,
    '173': 67.5,
    '174': 67.4,
    '175': 67.3,
    '176': 67.1,
    '177': 67,
    '178': 66.9,
    '179': 66.8,
    '180': 66.6,
    '181': 66.5,
    '182': 66.4,
    '183': 66.3,
    '184': 66.1,
    '185': 66,
    '186': 65.9,
    '187': 65.8,
    '188': 65.7,
    '189': 65.5,
    '190': 65.4,
    '191': 65.3,
    '192': 65.2,
    '193': 65.1,
    '194': 64.9,
    '195': 64.8,
    '0.5': 94.9,
    '1.5': 94.7,
    '2.5': 94.5,
    '3.5': 94.3,
    '4.5': 94.1,
    '5.5': 93.9,
    '6.5': 93.7,
    '7.5': 93.5,
    '8.5': 93.3,
    '9.5': 93.1,
    '10.5': 92.9,
    '11.5': 92.7,
    '12.5': 92.5,
    '13.5': 92.3,
    '14.5': 92.1,
    '15.5': 91.9,
    '16.5': 91.8,
    '17.5': 91.6,
    '18.5': 91.4,
    '19.5': 91.2,
    '20.5': 91,
    '21.5': 90.8,
    '22.5': 90.6,
    '23.5': 90.5,
    '24.5': 90.3,
    '25.5': 90.1,
    '26.5': 89.9,
    '27.5': 89.7,
    '28.5': 89.5,
    '29.5': 89.3,
    '30.5': 89.2,
    '31.5': 89,
    '32.5': 88.8,
    '33.5': 88.6,
    '34.5': 88.4,
    '35.5': 88.3,
    '36.5': 88.1,
    '37.5': 87.9,
    '38.5': 87.7,
    '39.5': 87.5,
    '40.5': 87.4,
    '41.5': 87.2,
    '42.5': 87,
    '43.5': 86.8,
    '44.5': 86.7,
    '45.5': 86.5,
    '46.5': 86.3,
    '47.5': 86.1,
    '48.5': 86,
    '49.5': 85.8,
    '50.5': 85.6,
    '51.5': 85.4,
    '52.5': 85.3,
    '53.5': 85.1,
    '54.5': 84.9,
    '55.5': 84.8,
    '56.5': 84.6,
    '57.5': 84.4,
    '58.5': 84.3,
    '59.5': 84.1,
    '60.5': 83.9,
    '61.5': 83.7,
    '62.5': 83.6,
    '63.5': 83.4,
    '64.5': 83.3,
    '65.5': 83.1,
    '66.5': 82.9,
    '67.5': 82.8,
    '68.5': 82.6,
    '69.5': 82.4,
    '70.5': 82.3,
    '71.5': 82.1,
    '72.5': 81.9,
    '73.5': 81.8,
    '74.5': 81.6,
    '75.5': 81.4,
    '76.5': 81.3,
    '77.5': 81.1,
    '78.5': 81,
    '79.5': 80.8,
    '80.5': 80.6,
    '81.5': 80.5,
    '82.5': 80.3,
    '83.5': 80.2,
    '84.5': 80,
    '85.5': 79.9,
    '86.5': 79.7,
    '87.5': 79.5,
    '88.5': 79.4,
    '89.5': 79.2,
    '90.5': 79.1,
    '91.5': 78.9,
    '92.5': 78.8,
    '93.5': 78.6,
    '94.5': 78.5,
    '95.5': 78.3,
    '96.5': 78.2,
    '97.5': 78,
    '98.5': 77.8,
    '99.5': 77.7,
    '100.5': 77.5,
    '101.5': 77.4,
    '102.5': 77.2,
    '103.5': 77.1,
    '104.5': 76.9,
    '105.5': 76.8,
    '106.5': 76.6,
    '107.5': 76.5,
    '108.5': 76.4,
    '109.5': 76.2,
    '110.5': 76.1,
    '111.5': 75.9,
    '112.5': 75.8,
    '113.5': 75.6,
    '114.5': 75.5,
    '115.5': 75.3,
    '116.5': 75.2,
    '117.5': 75,
    '118.5': 74.9,
    '119.5': 74.7,
    '120.5': 74.6,
    '121.5': 74.5,
    '122.5': 74.3,
    '123.5': 74.2,
    '124.5': 74,
    '125.5': 73.9,
    '126.5': 73.7,
    '127.5': 73.6,
    '128.5': 73.5,
    '129.5': 73.3,
    '130.5': 73.2,
    '131.5': 73,
    '132.5': 72.9,
    '133.5': 72.8,
    '134.5': 72.6,
    '135.5': 72.5,
    '136.5': 72.3,
    '137.5': 72.2,
    '138.5': 72.1,
    '139.5': 71.9,
    '140.5': 71.8,
    '141.5': 71.7,
    '142.5': 71.5,
    '143.5': 71.4,
    '144.5': 71.3,
    '145.5': 71.1,
    '146.5': 71,
    '147.5': 70.8,
    '148.5': 70.7,
    '149.5': 70.6,
    '150.5': 70.4,
    '151.5': 70.3,
    '152.5': 70.2,
    '153.5': 70,
    '154.5': 69.9,
    '155.5': 69.8,
    '156.5': 69.7,
    '157.5': 69.5,
    '158.5': 69.4,
    '159.5': 69.3,
    '160.5': 69.1,
    '161.5': 69,
    '162.5': 68.9,
    '163.5': 68.7,
    '164.5': 68.6,
    '165.5': 68.5,
    '166.5': 68.4,
    '167.5': 68.2,
    '168.5': 68.1,
    '169.5': 68,
    '170.5': 67.8,
    '171.5': 67.7,
    '172.5': 67.6,
    '173.5': 67.5,
    '174.5': 67.3,
    '175.5': 67.2,
    '176.5': 67.1,
    '177.5': 67,
    '178.5': 66.8,
    '179.5': 66.7,
    '180.5': 66.6,
    '181.5': 66.5,
    '182.5': 66.3,
    '183.5': 66.2,
    '184.5': 66.1,
    '185.5': 66,
    '186.5': 65.8,
    '187.5': 65.7,
    '188.5': 65.6,
    '189.5': 65.5,
    '190.5': 65.4,
    '191.5': 65.2,
    '192.5': 65.1,
    '193.5': 65,
    '194.5': 64.9,
  },
  '84.5': {
    '0': 95.5,
    '1': 95.3,
    '2': 95.1,
    '3': 94.9,
    '4': 94.7,
    '5': 94.5,
    '6': 94.3,
    '7': 94.1,
    '8': 94,
    '9': 93.8,
    '10': 93.6,
    '11': 93.4,
    '12': 93.2,
    '13': 93,
    '14': 92.8,
    '15': 92.6,
    '16': 92.4,
    '17': 92.2,
    '18': 92,
    '19': 91.8,
    '20': 91.6,
    '21': 91.5,
    '22': 91.3,
    '23': 91.1,
    '24': 90.9,
    '25': 90.7,
    '26': 90.5,
    '27': 90.3,
    '28': 90.2,
    '29': 90,
    '30': 89.8,
    '31': 89.6,
    '32': 89.4,
    '33': 89.2,
    '34': 89.1,
    '35': 88.9,
    '36': 88.7,
    '37': 88.5,
    '38': 88.3,
    '39': 88.2,
    '40': 88,
    '41': 87.8,
    '42': 87.6,
    '43': 87.4,
    '44': 87.3,
    '45': 87.1,
    '46': 86.9,
    '47': 86.7,
    '48': 86.6,
    '49': 86.4,
    '50': 86.2,
    '51': 86,
    '52': 85.9,
    '53': 85.7,
    '54': 85.5,
    '55': 85.4,
    '56': 85.2,
    '57': 85,
    '58': 84.8,
    '59': 84.7,
    '60': 84.5,
    '61': 84.3,
    '62': 84.2,
    '63': 84,
    '64': 83.8,
    '65': 83.7,
    '66': 83.5,
    '67': 83.3,
    '68': 83.2,
    '69': 83,
    '70': 82.8,
    '71': 82.7,
    '72': 82.5,
    '73': 82.3,
    '74': 82.2,
    '75': 82,
    '76': 81.8,
    '77': 81.7,
    '78': 81.5,
    '79': 81.4,
    '80': 81.2,
    '81': 81,
    '82': 80.9,
    '83': 80.7,
    '84': 80.6,
    '85': 80.4,
    '86': 80.2,
    '87': 80.1,
    '88': 79.9,
    '89': 79.8,
    '90': 79.6,
    '91': 79.5,
    '92': 79.3,
    '93': 79.2,
    '94': 79,
    '95': 78.8,
    '96': 78.7,
    '97': 78.5,
    '98': 78.4,
    '99': 78.2,
    '100': 78.1,
    '101': 77.9,
    '102': 77.8,
    '103': 77.6,
    '104': 77.5,
    '105': 77.3,
    '106': 77.2,
    '107': 77,
    '108': 76.9,
    '109': 76.7,
    '110': 76.6,
    '111': 76.4,
    '112': 76.3,
    '113': 76.1,
    '114': 76,
    '115': 75.8,
    '116': 75.7,
    '117': 75.5,
    '118': 75.4,
    '119': 75.3,
    '120': 75.1,
    '121': 75,
    '122': 74.8,
    '123': 74.7,
    '124': 74.5,
    '125': 74.4,
    '126': 74.2,
    '127': 74.1,
    '128': 74,
    '129': 73.8,
    '130': 73.7,
    '131': 73.5,
    '132': 73.4,
    '133': 73.3,
    '134': 73.1,
    '135': 73,
    '136': 72.8,
    '137': 72.7,
    '138': 72.6,
    '139': 72.4,
    '140': 72.3,
    '141': 72.1,
    '142': 72,
    '143': 71.9,
    '144': 71.7,
    '145': 71.6,
    '146': 71.5,
    '147': 71.3,
    '148': 71.2,
    '149': 71.1,
    '150': 70.9,
    '151': 70.8,
    '152': 70.7,
    '153': 70.5,
    '154': 70.4,
    '155': 70.3,
    '156': 70.1,
    '157': 70,
    '158': 69.9,
    '159': 69.7,
    '160': 69.6,
    '161': 69.5,
    '162': 69.3,
    '163': 69.2,
    '164': 69.1,
    '165': 68.9,
    '166': 68.8,
    '167': 68.7,
    '168': 68.6,
    '169': 68.4,
    '170': 68.3,
    '171': 68.2,
    '172': 68,
    '173': 67.9,
    '174': 67.8,
    '175': 67.7,
    '176': 67.5,
    '177': 67.4,
    '178': 67.3,
    '179': 67.2,
    '180': 67,
    '181': 66.9,
    '182': 66.8,
    '183': 66.7,
    '184': 66.5,
    '185': 66.4,
    '186': 66.3,
    '187': 66.2,
    '188': 66,
    '189': 65.9,
    '190': 65.8,
    '191': 65.7,
    '192': 65.6,
    '193': 65.4,
    '194': 65.3,
    '195': 65.2,
    '0.5': 95.4,
    '1.5': 95.2,
    '2.5': 95,
    '3.5': 94.8,
    '4.5': 94.6,
    '5.5': 94.4,
    '6.5': 94.2,
    '7.5': 94,
    '8.5': 93.9,
    '9.5': 93.7,
    '10.5': 93.5,
    '11.5': 93.3,
    '12.5': 93.1,
    '13.5': 92.9,
    '14.5': 92.7,
    '15.5': 92.5,
    '16.5': 92.3,
    '17.5': 92.1,
    '18.5': 91.9,
    '19.5': 91.7,
    '20.5': 91.6,
    '21.5': 91.4,
    '22.5': 91.2,
    '23.5': 91,
    '24.5': 90.8,
    '25.5': 90.6,
    '26.5': 90.4,
    '27.5': 90.2,
    '28.5': 90.1,
    '29.5': 89.9,
    '30.5': 89.7,
    '31.5': 89.5,
    '32.5': 89.3,
    '33.5': 89.1,
    '34.5': 89,
    '35.5': 88.8,
    '36.5': 88.6,
    '37.5': 88.4,
    '38.5': 88.2,
    '39.5': 88.1,
    '40.5': 87.9,
    '41.5': 87.7,
    '42.5': 87.5,
    '43.5': 87.4,
    '44.5': 87.2,
    '45.5': 87,
    '46.5': 86.8,
    '47.5': 86.7,
    '48.5': 86.5,
    '49.5': 86.3,
    '50.5': 86.1,
    '51.5': 86,
    '52.5': 85.8,
    '53.5': 85.6,
    '54.5': 85.4,
    '55.5': 85.3,
    '56.5': 85.1,
    '57.5': 84.9,
    '58.5': 84.8,
    '59.5': 84.6,
    '60.5': 84.4,
    '61.5': 84.2,
    '62.5': 84.1,
    '63.5': 83.9,
    '64.5': 83.7,
    '65.5': 83.6,
    '66.5': 83.4,
    '67.5': 83.2,
    '68.5': 83.1,
    '69.5': 82.9,
    '70.5': 82.7,
    '71.5': 82.6,
    '72.5': 82.4,
    '73.5': 82.3,
    '74.5': 82.1,
    '75.5': 81.9,
    '76.5': 81.8,
    '77.5': 81.6,
    '78.5': 81.4,
    '79.5': 81.3,
    '80.5': 81.1,
    '81.5': 81,
    '82.5': 80.8,
    '83.5': 80.6,
    '84.5': 80.5,
    '85.5': 80.3,
    '86.5': 80.2,
    '87.5': 80,
    '88.5': 79.9,
    '89.5': 79.7,
    '90.5': 79.5,
    '91.5': 79.4,
    '92.5': 79.2,
    '93.5': 79.1,
    '94.5': 78.9,
    '95.5': 78.8,
    '96.5': 78.6,
    '97.5': 78.5,
    '98.5': 78.3,
    '99.5': 78.2,
    '100.5': 78,
    '101.5': 77.8,
    '102.5': 77.7,
    '103.5': 77.5,
    '104.5': 77.4,
    '105.5': 77.2,
    '106.5': 77.1,
    '107.5': 76.9,
    '108.5': 76.8,
    '109.5': 76.6,
    '110.5': 76.5,
    '111.5': 76.3,
    '112.5': 76.2,
    '113.5': 76.1,
    '114.5': 75.9,
    '115.5': 75.8,
    '116.5': 75.6,
    '117.5': 75.5,
    '118.5': 75.3,
    '119.5': 75.2,
    '120.5': 75,
    '121.5': 74.9,
    '122.5': 74.7,
    '123.5': 74.6,
    '124.5': 74.5,
    '125.5': 74.3,
    '126.5': 74.2,
    '127.5': 74,
    '128.5': 73.9,
    '129.5': 73.7,
    '130.5': 73.6,
    '131.5': 73.5,
    '132.5': 73.3,
    '133.5': 73.2,
    '134.5': 73,
    '135.5': 72.9,
    '136.5': 72.8,
    '137.5': 72.6,
    '138.5': 72.5,
    '139.5': 72.4,
    '140.5': 72.2,
    '141.5': 72.1,
    '142.5': 71.9,
    '143.5': 71.8,
    '144.5': 71.7,
    '145.5': 71.5,
    '146.5': 71.4,
    '147.5': 71.3,
    '148.5': 71.1,
    '149.5': 71,
    '150.5': 70.9,
    '151.5': 70.7,
    '152.5': 70.6,
    '153.5': 70.5,
    '154.5': 70.3,
    '155.5': 70.2,
    '156.5': 70.1,
    '157.5': 69.9,
    '158.5': 69.8,
    '159.5': 69.7,
    '160.5': 69.5,
    '161.5': 69.4,
    '162.5': 69.3,
    '163.5': 69.1,
    '164.5': 69,
    '165.5': 68.9,
    '166.5': 68.7,
    '167.5': 68.6,
    '168.5': 68.5,
    '169.5': 68.4,
    '170.5': 68.2,
    '171.5': 68.1,
    '172.5': 68,
    '173.5': 67.9,
    '174.5': 67.7,
    '175.5': 67.6,
    '176.5': 67.5,
    '177.5': 67.3,
    '178.5': 67.2,
    '179.5': 67.1,
    '180.5': 67,
    '181.5': 66.8,
    '182.5': 66.7,
    '183.5': 66.6,
    '184.5': 66.5,
    '185.5': 66.3,
    '186.5': 66.2,
    '187.5': 66.1,
    '188.5': 66,
    '189.5': 65.9,
    '190.5': 65.7,
    '191.5': 65.6,
    '192.5': 65.5,
    '193.5': 65.4,
    '194.5': 65.2,
  },
  '85': {
    '0': 96.1,
    '1': 95.9,
    '2': 95.7,
    '3': 95.5,
    '4': 95.3,
    '5': 95.1,
    '6': 94.9,
    '7': 94.7,
    '8': 94.5,
    '9': 94.3,
    '10': 94.1,
    '11': 93.9,
    '12': 93.7,
    '13': 93.5,
    '14': 93.4,
    '15': 93.2,
    '16': 93,
    '17': 92.8,
    '18': 92.6,
    '19': 92.4,
    '20': 92.2,
    '21': 92,
    '22': 91.8,
    '23': 91.6,
    '24': 91.4,
    '25': 91.3,
    '26': 91.1,
    '27': 90.9,
    '28': 90.7,
    '29': 90.5,
    '30': 90.3,
    '31': 90.1,
    '32': 90,
    '33': 89.8,
    '34': 89.6,
    '35': 89.4,
    '36': 89.2,
    '37': 89,
    '38': 88.9,
    '39': 88.7,
    '40': 88.5,
    '41': 88.3,
    '42': 88.1,
    '43': 88,
    '44': 87.8,
    '45': 87.6,
    '46': 87.4,
    '47': 87.3,
    '48': 87.1,
    '49': 86.9,
    '50': 86.7,
    '51': 86.6,
    '52': 86.4,
    '53': 86.2,
    '54': 86,
    '55': 85.9,
    '56': 85.7,
    '57': 85.5,
    '58': 85.3,
    '59': 85.2,
    '60': 85,
    '61': 84.8,
    '62': 84.7,
    '63': 84.5,
    '64': 84.3,
    '65': 84.2,
    '66': 84,
    '67': 83.8,
    '68': 83.7,
    '69': 83.5,
    '70': 83.3,
    '71': 83.2,
    '72': 83,
    '73': 82.8,
    '74': 82.7,
    '75': 82.5,
    '76': 82.3,
    '77': 82.2,
    '78': 82,
    '79': 81.8,
    '80': 81.7,
    '81': 81.5,
    '82': 81.4,
    '83': 81.2,
    '84': 81,
    '85': 80.9,
    '86': 80.7,
    '87': 80.6,
    '88': 80.4,
    '89': 80.2,
    '90': 80.1,
    '91': 79.9,
    '92': 79.8,
    '93': 79.6,
    '94': 79.5,
    '95': 79.3,
    '96': 79.2,
    '97': 79,
    '98': 78.8,
    '99': 78.7,
    '100': 78.5,
    '101': 78.4,
    '102': 78.2,
    '103': 78.1,
    '104': 77.9,
    '105': 77.8,
    '106': 77.6,
    '107': 77.5,
    '108': 77.3,
    '109': 77.2,
    '110': 77,
    '111': 76.9,
    '112': 76.7,
    '113': 76.6,
    '114': 76.4,
    '115': 76.3,
    '116': 76.1,
    '117': 76,
    '118': 75.8,
    '119': 75.7,
    '120': 75.5,
    '121': 75.4,
    '122': 75.3,
    '123': 75.1,
    '124': 75,
    '125': 74.8,
    '126': 74.7,
    '127': 74.5,
    '128': 74.4,
    '129': 74.2,
    '130': 74.1,
    '131': 74,
    '132': 73.8,
    '133': 73.7,
    '134': 73.5,
    '135': 73.4,
    '136': 73.3,
    '137': 73.1,
    '138': 73,
    '139': 72.8,
    '140': 72.7,
    '141': 72.6,
    '142': 72.4,
    '143': 72.3,
    '144': 72.2,
    '145': 72,
    '146': 71.9,
    '147': 71.7,
    '148': 71.6,
    '149': 71.5,
    '150': 71.3,
    '151': 71.2,
    '152': 71.1,
    '153': 70.9,
    '154': 70.8,
    '155': 70.7,
    '156': 70.5,
    '157': 70.4,
    '158': 70.3,
    '159': 70.1,
    '160': 70,
    '161': 69.9,
    '162': 69.7,
    '163': 69.6,
    '164': 69.5,
    '165': 69.3,
    '166': 69.2,
    '167': 69.1,
    '168': 69,
    '169': 68.8,
    '170': 68.7,
    '171': 68.6,
    '172': 68.4,
    '173': 68.3,
    '174': 68.2,
    '175': 68.1,
    '176': 67.9,
    '177': 67.8,
    '178': 67.7,
    '179': 67.5,
    '180': 67.4,
    '181': 67.3,
    '182': 67.2,
    '183': 67,
    '184': 66.9,
    '185': 66.8,
    '186': 66.7,
    '187': 66.5,
    '188': 66.4,
    '189': 66.3,
    '190': 66.2,
    '191': 66.1,
    '192': 65.9,
    '193': 65.8,
    '194': 65.7,
    '195': 65.6,
    '0.5': 96,
    '1.5': 95.8,
    '2.5': 95.6,
    '3.5': 95.4,
    '4.5': 95.2,
    '5.5': 95,
    '6.5': 94.8,
    '7.5': 94.6,
    '8.5': 94.4,
    '9.5': 94.2,
    '10.5': 94,
    '11.5': 93.8,
    '12.5': 93.6,
    '13.5': 93.5,
    '14.5': 93.3,
    '15.5': 93.1,
    '16.5': 92.9,
    '17.5': 92.7,
    '18.5': 92.5,
    '19.5': 92.3,
    '20.5': 92.1,
    '21.5': 91.9,
    '22.5': 91.7,
    '23.5': 91.5,
    '24.5': 91.4,
    '25.5': 91.2,
    '26.5': 91,
    '27.5': 90.8,
    '28.5': 90.6,
    '29.5': 90.4,
    '30.5': 90.2,
    '31.5': 90.1,
    '32.5': 89.9,
    '33.5': 89.7,
    '34.5': 89.5,
    '35.5': 89.3,
    '36.5': 89.1,
    '37.5': 89,
    '38.5': 88.8,
    '39.5': 88.6,
    '40.5': 88.4,
    '41.5': 88.2,
    '42.5': 88.1,
    '43.5': 87.9,
    '44.5': 87.7,
    '45.5': 87.5,
    '46.5': 87.3,
    '47.5': 87.2,
    '48.5': 87,
    '49.5': 86.8,
    '50.5': 86.6,
    '51.5': 86.5,
    '52.5': 86.3,
    '53.5': 86.1,
    '54.5': 85.9,
    '55.5': 85.8,
    '56.5': 85.6,
    '57.5': 85.4,
    '58.5': 85.3,
    '59.5': 85.1,
    '60.5': 84.9,
    '61.5': 84.7,
    '62.5': 84.6,
    '63.5': 84.4,
    '64.5': 84.2,
    '65.5': 84.1,
    '66.5': 83.9,
    '67.5': 83.7,
    '68.5': 83.6,
    '69.5': 83.4,
    '70.5': 83.2,
    '71.5': 83.1,
    '72.5': 82.9,
    '73.5': 82.7,
    '74.5': 82.6,
    '75.5': 82.4,
    '76.5': 82.3,
    '77.5': 82.1,
    '78.5': 81.9,
    '79.5': 81.8,
    '80.5': 81.6,
    '81.5': 81.4,
    '82.5': 81.3,
    '83.5': 81.1,
    '84.5': 81,
    '85.5': 80.8,
    '86.5': 80.6,
    '87.5': 80.5,
    '88.5': 80.3,
    '89.5': 80.2,
    '90.5': 80,
    '91.5': 79.9,
    '92.5': 79.7,
    '93.5': 79.5,
    '94.5': 79.4,
    '95.5': 79.2,
    '96.5': 79.1,
    '97.5': 78.9,
    '98.5': 78.8,
    '99.5': 78.6,
    '100.5': 78.5,
    '101.5': 78.3,
    '102.5': 78.2,
    '103.5': 78,
    '104.5': 77.8,
    '105.5': 77.7,
    '106.5': 77.5,
    '107.5': 77.4,
    '108.5': 77.2,
    '109.5': 77.1,
    '110.5': 76.9,
    '111.5': 76.8,
    '112.5': 76.6,
    '113.5': 76.5,
    '114.5': 76.4,
    '115.5': 76.2,
    '116.5': 76.1,
    '117.5': 75.9,
    '118.5': 75.8,
    '119.5': 75.6,
    '120.5': 75.5,
    '121.5': 75.3,
    '122.5': 75.2,
    '123.5': 75,
    '124.5': 74.9,
    '125.5': 74.8,
    '126.5': 74.6,
    '127.5': 74.5,
    '128.5': 74.3,
    '129.5': 74.2,
    '130.5': 74,
    '131.5': 73.9,
    '132.5': 73.8,
    '133.5': 73.6,
    '134.5': 73.5,
    '135.5': 73.3,
    '136.5': 73.2,
    '137.5': 73.1,
    '138.5': 72.9,
    '139.5': 72.8,
    '140.5': 72.6,
    '141.5': 72.5,
    '142.5': 72.4,
    '143.5': 72.2,
    '144.5': 72.1,
    '145.5': 71.9,
    '146.5': 71.8,
    '147.5': 71.7,
    '148.5': 71.5,
    '149.5': 71.4,
    '150.5': 71.3,
    '151.5': 71.1,
    '152.5': 71,
    '153.5': 70.9,
    '154.5': 70.7,
    '155.5': 70.6,
    '156.5': 70.5,
    '157.5': 70.3,
    '158.5': 70.2,
    '159.5': 70.1,
    '160.5': 69.9,
    '161.5': 69.8,
    '162.5': 69.7,
    '163.5': 69.5,
    '164.5': 69.4,
    '165.5': 69.3,
    '166.5': 69.1,
    '167.5': 69,
    '168.5': 68.9,
    '169.5': 68.8,
    '170.5': 68.6,
    '171.5': 68.5,
    '172.5': 68.4,
    '173.5': 68.2,
    '174.5': 68.1,
    '175.5': 68,
    '176.5': 67.9,
    '177.5': 67.7,
    '178.5': 67.6,
    '179.5': 67.5,
    '180.5': 67.4,
    '181.5': 67.2,
    '182.5': 67.1,
    '183.5': 67,
    '184.5': 66.9,
    '185.5': 66.7,
    '186.5': 66.6,
    '187.5': 66.5,
    '188.5': 66.4,
    '189.5': 66.2,
    '190.5': 66.1,
    '191.5': 66,
    '192.5': 65.9,
    '193.5': 65.8,
    '194.5': 65.6,
  },
  // 85 - 90
  '85.5': {
    '0': 96.7,
    '1': 96.5,
    '2': 96.3,
    '3': 96.1,
    '4': 95.9,
    '5': 95.7,
    '6': 95.5,
    '7': 95.3,
    '8': 95.1,
    '9': 94.9,
    '10': 94.7,
    '11': 94.5,
    '12': 94.3,
    '13': 94.1,
    '14': 93.9,
    '15': 93.7,
    '16': 93.5,
    '17': 93.3,
    '18': 93.1,
    '19': 92.9,
    '20': 92.8,
    '21': 92.6,
    '22': 92.4,
    '23': 92.2,
    '24': 92,
    '25': 91.8,
    '26': 91.6,
    '27': 91.4,
    '28': 91.2,
    '29': 91.1,
    '30': 90.9,
    '31': 90.7,
    '32': 90.5,
    '33': 90.3,
    '34': 90.1,
    '35': 89.9,
    '36': 89.8,
    '37': 89.6,
    '38': 89.4,
    '39': 89.2,
    '40': 89,
    '41': 88.8,
    '42': 88.7,
    '43': 88.5,
    '44': 88.3,
    '45': 88.1,
    '46': 87.9,
    '47': 87.8,
    '48': 87.6,
    '49': 87.4,
    '50': 87.2,
    '51': 87.1,
    '52': 86.9,
    '53': 86.7,
    '54': 86.5,
    '55': 86.4,
    '56': 86.2,
    '57': 86,
    '58': 85.8,
    '59': 85.7,
    '60': 85.5,
    '61': 85.3,
    '62': 85.2,
    '63': 85,
    '64': 84.8,
    '65': 84.6,
    '66': 84.5,
    '67': 84.3,
    '68': 84.1,
    '69': 84,
    '70': 83.8,
    '71': 83.6,
    '72': 83.5,
    '73': 83.3,
    '74': 83.1,
    '75': 83,
    '76': 82.8,
    '77': 82.6,
    '78': 82.5,
    '79': 82.3,
    '80': 82.2,
    '81': 82,
    '82': 81.8,
    '83': 81.7,
    '84': 81.5,
    '85': 81.4,
    '86': 81.2,
    '87': 81,
    '88': 80.9,
    '89': 80.7,
    '90': 80.6,
    '91': 80.4,
    '92': 80.2,
    '93': 80.1,
    '94': 79.9,
    '95': 79.8,
    '96': 79.6,
    '97': 79.5,
    '98': 79.3,
    '99': 79.1,
    '100': 79,
    '101': 78.8,
    '102': 78.7,
    '103': 78.5,
    '104': 78.4,
    '105': 78.2,
    '106': 78.1,
    '107': 77.9,
    '108': 77.8,
    '109': 77.6,
    '110': 77.5,
    '111': 77.3,
    '112': 77.2,
    '113': 77,
    '114': 76.9,
    '115': 76.7,
    '116': 76.6,
    '117': 76.4,
    '118': 76.3,
    '119': 76.1,
    '120': 76,
    '121': 75.8,
    '122': 75.7,
    '123': 75.5,
    '124': 75.4,
    '125': 75.3,
    '126': 75.1,
    '127': 75,
    '128': 74.8,
    '129': 74.7,
    '130': 74.5,
    '131': 74.4,
    '132': 74.2,
    '133': 74.1,
    '134': 74,
    '135': 73.8,
    '136': 73.7,
    '137': 73.5,
    '138': 73.4,
    '139': 73.3,
    '140': 73.1,
    '141': 73,
    '142': 72.8,
    '143': 72.7,
    '144': 72.6,
    '145': 72.4,
    '146': 72.3,
    '147': 72.2,
    '148': 72,
    '149': 71.9,
    '150': 71.7,
    '151': 71.6,
    '152': 71.5,
    '153': 71.3,
    '154': 71.2,
    '155': 71.1,
    '156': 70.9,
    '157': 70.8,
    '158': 70.7,
    '159': 70.5,
    '160': 70.4,
    '161': 70.3,
    '162': 70.1,
    '163': 70,
    '164': 69.9,
    '165': 69.7,
    '166': 69.6,
    '167': 69.5,
    '168': 69.3,
    '169': 69.2,
    '170': 69.1,
    '171': 69,
    '172': 68.8,
    '173': 68.7,
    '174': 68.6,
    '175': 68.4,
    '176': 68.3,
    '177': 68.2,
    '178': 68.1,
    '179': 67.9,
    '180': 67.8,
    '181': 67.7,
    '182': 67.6,
    '183': 67.4,
    '184': 67.3,
    '185': 67.2,
    '186': 67.1,
    '187': 66.9,
    '188': 66.8,
    '189': 66.7,
    '190': 66.6,
    '191': 66.4,
    '192': 66.3,
    '193': 66.2,
    '194': 66.1,
    '195': 65.9,
    '0.5': 96.6,
    '1.5': 96.4,
    '2.5': 96.2,
    '3.5': 96,
    '4.5': 95.8,
    '5.5': 95.6,
    '6.5': 95.4,
    '7.5': 95.2,
    '8.5': 95,
    '9.5': 94.8,
    '10.5': 94.6,
    '11.5': 94.4,
    '12.5': 94.2,
    '13.5': 94,
    '14.5': 93.8,
    '15.5': 93.6,
    '16.5': 93.4,
    '17.5': 93.2,
    '18.5': 93,
    '19.5': 92.9,
    '20.5': 92.7,
    '21.5': 92.5,
    '22.5': 92.3,
    '23.5': 92.1,
    '24.5': 91.9,
    '25.5': 91.7,
    '26.5': 91.5,
    '27.5': 91.3,
    '28.5': 91.1,
    '29.5': 91,
    '30.5': 90.8,
    '31.5': 90.6,
    '32.5': 90.4,
    '33.5': 90.2,
    '34.5': 90,
    '35.5': 89.8,
    '36.5': 89.7,
    '37.5': 89.5,
    '38.5': 89.3,
    '39.5': 89.1,
    '40.5': 88.9,
    '41.5': 88.8,
    '42.5': 88.6,
    '43.5': 88.4,
    '44.5': 88.2,
    '45.5': 88,
    '46.5': 87.9,
    '47.5': 87.7,
    '48.5': 87.5,
    '49.5': 87.3,
    '50.5': 87.2,
    '51.5': 87,
    '52.5': 86.8,
    '53.5': 86.6,
    '54.5': 86.5,
    '55.5': 86.3,
    '56.5': 86.1,
    '57.5': 85.9,
    '58.5': 85.8,
    '59.5': 85.6,
    '60.5': 85.4,
    '61.5': 85.2,
    '62.5': 85.1,
    '63.5': 84.9,
    '64.5': 84.7,
    '65.5': 84.6,
    '66.5': 84.4,
    '67.5': 84.2,
    '68.5': 84.1,
    '69.5': 83.9,
    '70.5': 83.7,
    '71.5': 83.6,
    '72.5': 83.4,
    '73.5': 83.2,
    '74.5': 83.1,
    '75.5': 82.9,
    '76.5': 82.7,
    '77.5': 82.6,
    '78.5': 82.4,
    '79.5': 82.2,
    '80.5': 82.1,
    '81.5': 81.9,
    '82.5': 81.8,
    '83.5': 81.6,
    '84.5': 81.4,
    '85.5': 81.3,
    '86.5': 81.1,
    '87.5': 81,
    '88.5': 80.8,
    '89.5': 80.6,
    '90.5': 80.5,
    '91.5': 80.3,
    '92.5': 80.2,
    '93.5': 80,
    '94.5': 79.8,
    '95.5': 79.7,
    '96.5': 79.5,
    '97.5': 79.4,
    '98.5': 79.2,
    '99.5': 79.1,
    '100.5': 78.9,
    '101.5': 78.8,
    '102.5': 78.6,
    '103.5': 78.5,
    '104.5': 78.3,
    '105.5': 78.1,
    '106.5': 78,
    '107.5': 77.8,
    '108.5': 77.7,
    '109.5': 77.5,
    '110.5': 77.4,
    '111.5': 77.2,
    '112.5': 77.1,
    '113.5': 76.9,
    '114.5': 76.8,
    '115.5': 76.6,
    '116.5': 76.5,
    '117.5': 76.3,
    '118.5': 76.2,
    '119.5': 76.1,
    '120.5': 75.9,
    '121.5': 75.8,
    '122.5': 75.6,
    '123.5': 75.5,
    '124.5': 75.3,
    '125.5': 75.2,
    '126.5': 75,
    '127.5': 74.9,
    '128.5': 74.8,
    '129.5': 74.6,
    '130.5': 74.5,
    '131.5': 74.3,
    '132.5': 74.2,
    '133.5': 74,
    '134.5': 73.9,
    '135.5': 73.8,
    '136.5': 73.6,
    '137.5': 73.5,
    '138.5': 73.3,
    '139.5': 73.2,
    '140.5': 73.1,
    '141.5': 72.9,
    '142.5': 72.8,
    '143.5': 72.6,
    '144.5': 72.5,
    '145.5': 72.4,
    '146.5': 72.2,
    '147.5': 72.1,
    '148.5': 71.9,
    '149.5': 71.8,
    '150.5': 71.7,
    '151.5': 71.5,
    '152.5': 71.4,
    '153.5': 71.3,
    '154.5': 71.1,
    '155.5': 71,
    '156.5': 70.9,
    '157.5': 70.7,
    '158.5': 70.6,
    '159.5': 70.5,
    '160.5': 70.3,
    '161.5': 70.2,
    '162.5': 70.1,
    '163.5': 69.9,
    '164.5': 69.8,
    '165.5': 69.7,
    '166.5': 69.5,
    '167.5': 69.4,
    '168.5': 69.3,
    '169.5': 69.2,
    '170.5': 69,
    '171.5': 68.9,
    '172.5': 68.8,
    '173.5': 68.6,
    '174.5': 68.5,
    '175.5': 68.4,
    '176.5': 68.3,
    '177.5': 68.1,
    '178.5': 68,
    '179.5': 67.9,
    '180.5': 67.7,
    '181.5': 67.6,
    '182.5': 67.5,
    '183.5': 67.4,
    '184.5': 67.2,
    '185.5': 67.1,
    '186.5': 67,
    '187.5': 66.9,
    '188.5': 66.7,
    '189.5': 66.6,
    '190.5': 66.5,
    '191.5': 66.4,
    '192.5': 66.2,
    '193.5': 66.1,
    '194.5': 66,
  },
  '86': {
    '0': 97.3,
    '1': 97.1,
    '2': 96.9,
    '3': 96.7,
    '4': 96.5,
    '5': 96.3,
    '6': 96.1,
    '7': 95.9,
    '8': 95.7,
    '9': 95.5,
    '10': 95.3,
    '11': 95.1,
    '12': 94.9,
    '13': 94.7,
    '14': 94.5,
    '15': 94.3,
    '16': 94.1,
    '17': 93.9,
    '18': 93.7,
    '19': 93.5,
    '20': 93.3,
    '21': 93.1,
    '22': 92.9,
    '23': 92.7,
    '24': 92.5,
    '25': 92.3,
    '26': 92.2,
    '27': 92,
    '28': 91.8,
    '29': 91.6,
    '30': 91.4,
    '31': 91.2,
    '32': 91,
    '33': 90.8,
    '34': 90.7,
    '35': 90.5,
    '36': 90.3,
    '37': 90.1,
    '38': 89.9,
    '39': 89.7,
    '40': 89.6,
    '41': 89.4,
    '42': 89.2,
    '43': 89,
    '44': 88.8,
    '45': 88.6,
    '46': 88.5,
    '47': 88.3,
    '48': 88.1,
    '49': 87.9,
    '50': 87.8,
    '51': 87.6,
    '52': 87.4,
    '53': 87.2,
    '54': 87,
    '55': 86.9,
    '56': 86.7,
    '57': 86.5,
    '58': 86.3,
    '59': 86.2,
    '60': 86,
    '61': 85.8,
    '62': 85.7,
    '63': 85.5,
    '64': 85.3,
    '65': 85.1,
    '66': 85,
    '67': 84.8,
    '68': 84.6,
    '69': 84.5,
    '70': 84.3,
    '71': 84.1,
    '72': 84,
    '73': 83.8,
    '74': 83.6,
    '75': 83.5,
    '76': 83.3,
    '77': 83.1,
    '78': 83,
    '79': 82.8,
    '80': 82.6,
    '81': 82.5,
    '82': 82.3,
    '83': 82.1,
    '84': 82,
    '85': 81.8,
    '86': 81.7,
    '87': 81.5,
    '88': 81.3,
    '89': 81.2,
    '90': 81,
    '91': 80.9,
    '92': 80.7,
    '93': 80.5,
    '94': 80.4,
    '95': 80.2,
    '96': 80.1,
    '97': 79.9,
    '98': 79.8,
    '99': 79.6,
    '100': 79.4,
    '101': 79.3,
    '102': 79.1,
    '103': 79,
    '104': 78.8,
    '105': 78.7,
    '106': 78.5,
    '107': 78.4,
    '108': 78.2,
    '109': 78.1,
    '110': 77.9,
    '111': 77.8,
    '112': 77.6,
    '113': 77.5,
    '114': 77.3,
    '115': 77.2,
    '116': 77,
    '117': 76.9,
    '118': 76.7,
    '119': 76.6,
    '120': 76.4,
    '121': 76.3,
    '122': 76.1,
    '123': 76,
    '124': 75.8,
    '125': 75.7,
    '126': 75.5,
    '127': 75.4,
    '128': 75.2,
    '129': 75.1,
    '130': 75,
    '131': 74.8,
    '132': 74.7,
    '133': 74.5,
    '134': 74.4,
    '135': 74.2,
    '136': 74.1,
    '137': 74,
    '138': 73.8,
    '139': 73.7,
    '140': 73.5,
    '141': 73.4,
    '142': 73.3,
    '143': 73.1,
    '144': 73,
    '145': 72.8,
    '146': 72.7,
    '147': 72.6,
    '148': 72.4,
    '149': 72.3,
    '150': 72.2,
    '151': 72,
    '152': 71.9,
    '153': 71.7,
    '154': 71.6,
    '155': 71.5,
    '156': 71.3,
    '157': 71.2,
    '158': 71.1,
    '159': 70.9,
    '160': 70.8,
    '161': 70.7,
    '162': 70.5,
    '163': 70.4,
    '164': 70.3,
    '165': 70.1,
    '166': 70,
    '167': 69.9,
    '168': 69.7,
    '169': 69.6,
    '170': 69.5,
    '171': 69.4,
    '172': 69.2,
    '173': 69.1,
    '174': 69,
    '175': 68.8,
    '176': 68.7,
    '177': 68.6,
    '178': 68.5,
    '179': 68.3,
    '180': 68.2,
    '181': 68.1,
    '182': 67.9,
    '183': 67.8,
    '184': 67.7,
    '185': 67.6,
    '186': 67.4,
    '187': 67.3,
    '188': 67.2,
    '189': 67.1,
    '190': 66.9,
    '191': 66.8,
    '192': 66.7,
    '193': 66.6,
    '194': 66.4,
    '195': 66.3,
    '0.5': 97.2,
    '1.5': 97,
    '2.5': 96.8,
    '3.5': 96.6,
    '4.5': 96.4,
    '5.5': 96.2,
    '6.5': 96,
    '7.5': 95.8,
    '8.5': 95.6,
    '9.5': 95.4,
    '10.5': 95.2,
    '11.5': 95,
    '12.5': 94.8,
    '13.5': 94.6,
    '14.5': 94.4,
    '15.5': 94.2,
    '16.5': 94,
    '17.5': 93.8,
    '18.5': 93.6,
    '19.5': 93.4,
    '20.5': 93.2,
    '21.5': 93,
    '22.5': 92.8,
    '23.5': 92.6,
    '24.5': 92.4,
    '25.5': 92.3,
    '26.5': 92.1,
    '27.5': 91.9,
    '28.5': 91.7,
    '29.5': 91.5,
    '30.5': 91.3,
    '31.5': 91.1,
    '32.5': 90.9,
    '33.5': 90.8,
    '34.5': 90.6,
    '35.5': 90.4,
    '36.5': 90.2,
    '37.5': 90,
    '38.5': 89.8,
    '39.5': 89.6,
    '40.5': 89.5,
    '41.5': 89.3,
    '42.5': 89.1,
    '43.5': 88.9,
    '44.5': 88.7,
    '45.5': 88.6,
    '46.5': 88.4,
    '47.5': 88.2,
    '48.5': 88,
    '49.5': 87.8,
    '50.5': 87.7,
    '51.5': 87.5,
    '52.5': 87.3,
    '53.5': 87.1,
    '54.5': 87,
    '55.5': 86.8,
    '56.5': 86.6,
    '57.5': 86.4,
    '58.5': 86.3,
    '59.5': 86.1,
    '60.5': 85.9,
    '61.5': 85.7,
    '62.5': 85.6,
    '63.5': 85.4,
    '64.5': 85.2,
    '65.5': 85.1,
    '66.5': 84.9,
    '67.5': 84.7,
    '68.5': 84.5,
    '69.5': 84.4,
    '70.5': 84.2,
    '71.5': 84,
    '72.5': 83.9,
    '73.5': 83.7,
    '74.5': 83.5,
    '75.5': 83.4,
    '76.5': 83.2,
    '77.5': 83,
    '78.5': 82.9,
    '79.5': 82.7,
    '80.5': 82.6,
    '81.5': 82.4,
    '82.5': 82.2,
    '83.5': 82.1,
    '84.5': 81.9,
    '85.5': 81.7,
    '86.5': 81.6,
    '87.5': 81.4,
    '88.5': 81.3,
    '89.5': 81.1,
    '90.5': 80.9,
    '91.5': 80.8,
    '92.5': 80.6,
    '93.5': 80.5,
    '94.5': 80.3,
    '95.5': 80.1,
    '96.5': 80,
    '97.5': 79.8,
    '98.5': 79.7,
    '99.5': 79.5,
    '100.5': 79.4,
    '101.5': 79.2,
    '102.5': 79.1,
    '103.5': 78.9,
    '104.5': 78.8,
    '105.5': 78.6,
    '106.5': 78.4,
    '107.5': 78.3,
    '108.5': 78.1,
    '109.5': 78,
    '110.5': 77.8,
    '111.5': 77.7,
    '112.5': 77.5,
    '113.5': 77.4,
    '114.5': 77.2,
    '115.5': 77.1,
    '116.5': 76.9,
    '117.5': 76.8,
    '118.5': 76.6,
    '119.5': 76.5,
    '120.5': 76.3,
    '121.5': 76.2,
    '122.5': 76,
    '123.5': 75.9,
    '124.5': 75.8,
    '125.5': 75.6,
    '126.5': 75.5,
    '127.5': 75.3,
    '128.5': 75.2,
    '129.5': 75,
    '130.5': 74.9,
    '131.5': 74.7,
    '132.5': 74.6,
    '133.5': 74.5,
    '134.5': 74.3,
    '135.5': 74.2,
    '136.5': 74,
    '137.5': 73.9,
    '138.5': 73.8,
    '139.5': 73.6,
    '140.5': 73.5,
    '141.5': 73.3,
    '142.5': 73.2,
    '143.5': 73.1,
    '144.5': 72.9,
    '145.5': 72.8,
    '146.5': 72.6,
    '147.5': 72.5,
    '148.5': 72.4,
    '149.5': 72.2,
    '150.5': 72.1,
    '151.5': 71.9,
    '152.5': 71.8,
    '153.5': 71.7,
    '154.5': 71.5,
    '155.5': 71.4,
    '156.5': 71.3,
    '157.5': 71.1,
    '158.5': 71,
    '159.5': 70.9,
    '160.5': 70.7,
    '161.5': 70.6,
    '162.5': 70.5,
    '163.5': 70.3,
    '164.5': 70.2,
    '165.5': 70.1,
    '166.5': 69.9,
    '167.5': 69.8,
    '168.5': 69.7,
    '169.5': 69.5,
    '170.5': 69.4,
    '171.5': 69.3,
    '172.5': 69.2,
    '173.5': 69,
    '174.5': 68.9,
    '175.5': 68.8,
    '176.5': 68.6,
    '177.5': 68.5,
    '178.5': 68.4,
    '179.5': 68.3,
    '180.5': 68.1,
    '181.5': 68,
    '182.5': 67.9,
    '183.5': 67.8,
    '184.5': 67.6,
    '185.5': 67.5,
    '186.5': 67.4,
    '187.5': 67.3,
    '188.5': 67.1,
    '189.5': 67,
    '190.5': 66.9,
    '191.5': 66.8,
    '192.5': 66.6,
    '193.5': 66.5,
    '194.5': 66.4,
  },
  '86.5': {
    '0': 97.9,
    '1': 97.7,
    '2': 97.5,
    '3': 97.2,
    '4': 97,
    '5': 96.8,
    '6': 96.6,
    '7': 96.4,
    '8': 96.2,
    '9': 96,
    '10': 95.8,
    '11': 95.6,
    '12': 95.4,
    '13': 95.2,
    '14': 95,
    '15': 94.8,
    '16': 94.6,
    '17': 94.4,
    '18': 94.3,
    '19': 94.1,
    '20': 93.9,
    '21': 93.7,
    '22': 93.5,
    '23': 93.3,
    '24': 93.1,
    '25': 92.9,
    '26': 92.7,
    '27': 92.5,
    '28': 92.3,
    '29': 92.1,
    '30': 91.9,
    '31': 91.8,
    '32': 91.6,
    '33': 91.4,
    '34': 91.2,
    '35': 91,
    '36': 90.8,
    '37': 90.6,
    '38': 90.4,
    '39': 90.3,
    '40': 90.1,
    '41': 89.9,
    '42': 89.7,
    '43': 89.5,
    '44': 89.3,
    '45': 89.2,
    '46': 89,
    '47': 88.8,
    '48': 88.6,
    '49': 88.4,
    '50': 88.3,
    '51': 88.1,
    '52': 87.9,
    '53': 87.7,
    '54': 87.6,
    '55': 87.4,
    '56': 87.2,
    '57': 87,
    '58': 86.8,
    '59': 86.7,
    '60': 86.5,
    '61': 86.3,
    '62': 86.2,
    '63': 86,
    '64': 85.8,
    '65': 85.6,
    '66': 85.5,
    '67': 85.3,
    '68': 85.1,
    '69': 85,
    '70': 84.8,
    '71': 84.6,
    '72': 84.4,
    '73': 84.3,
    '74': 84.1,
    '75': 83.9,
    '76': 83.8,
    '77': 83.6,
    '78': 83.4,
    '79': 83.3,
    '80': 83.1,
    '81': 82.9,
    '82': 82.8,
    '83': 82.6,
    '84': 82.5,
    '85': 82.3,
    '86': 82.1,
    '87': 82,
    '88': 81.8,
    '89': 81.6,
    '90': 81.5,
    '91': 81.3,
    '92': 81.2,
    '93': 81,
    '94': 80.8,
    '95': 80.7,
    '96': 80.5,
    '97': 80.4,
    '98': 80.2,
    '99': 80.1,
    '100': 79.9,
    '101': 79.7,
    '102': 79.6,
    '103': 79.4,
    '104': 79.3,
    '105': 79.1,
    '106': 79,
    '107': 78.8,
    '108': 78.7,
    '109': 78.5,
    '110': 78.4,
    '111': 78.2,
    '112': 78.1,
    '113': 77.9,
    '114': 77.8,
    '115': 77.6,
    '116': 77.5,
    '117': 77.3,
    '118': 77.2,
    '119': 77,
    '120': 76.9,
    '121': 76.7,
    '122': 76.6,
    '123': 76.4,
    '124': 76.3,
    '125': 76.1,
    '126': 76,
    '127': 75.8,
    '128': 75.7,
    '129': 75.5,
    '130': 75.4,
    '131': 75.2,
    '132': 75.1,
    '133': 75,
    '134': 74.8,
    '135': 74.7,
    '136': 74.5,
    '137': 74.4,
    '138': 74.2,
    '139': 74.1,
    '140': 74,
    '141': 73.8,
    '142': 73.7,
    '143': 73.5,
    '144': 73.4,
    '145': 73.3,
    '146': 73.1,
    '147': 73,
    '148': 72.8,
    '149': 72.7,
    '150': 72.6,
    '151': 72.4,
    '152': 72.3,
    '153': 72.2,
    '154': 72,
    '155': 71.9,
    '156': 71.7,
    '157': 71.6,
    '158': 71.5,
    '159': 71.3,
    '160': 71.2,
    '161': 71.1,
    '162': 70.9,
    '163': 70.8,
    '164': 70.7,
    '165': 70.5,
    '166': 70.4,
    '167': 70.3,
    '168': 70.1,
    '169': 70,
    '170': 69.9,
    '171': 69.8,
    '172': 69.6,
    '173': 69.5,
    '174': 69.4,
    '175': 69.2,
    '176': 69.1,
    '177': 69,
    '178': 68.8,
    '179': 68.7,
    '180': 68.6,
    '181': 68.5,
    '182': 68.3,
    '183': 68.2,
    '184': 68.1,
    '185': 67.9,
    '186': 67.8,
    '187': 67.7,
    '188': 67.6,
    '189': 67.4,
    '190': 67.3,
    '191': 67.2,
    '192': 67.1,
    '193': 66.9,
    '194': 66.8,
    '195': 66.7,
    '0.5': 97.8,
    '1.5': 97.6,
    '2.5': 97.4,
    '3.5': 97.1,
    '4.5': 96.9,
    '5.5': 96.7,
    '6.5': 96.5,
    '7.5': 96.3,
    '8.5': 96.1,
    '9.5': 95.9,
    '10.5': 95.7,
    '11.5': 95.5,
    '12.5': 95.3,
    '13.5': 95.1,
    '14.5': 94.9,
    '15.5': 94.7,
    '16.5': 94.5,
    '17.5': 94.4,
    '18.5': 94.2,
    '19.5': 94,
    '20.5': 93.8,
    '21.5': 93.6,
    '22.5': 93.4,
    '23.5': 93.2,
    '24.5': 93,
    '25.5': 92.8,
    '26.5': 92.6,
    '27.5': 92.4,
    '28.5': 92.2,
    '29.5': 92,
    '30.5': 91.8,
    '31.5': 91.7,
    '32.5': 91.5,
    '33.5': 91.3,
    '34.5': 91.1,
    '35.5': 90.9,
    '36.5': 90.7,
    '37.5': 90.5,
    '38.5': 90.4,
    '39.5': 90.2,
    '40.5': 90,
    '41.5': 89.8,
    '42.5': 89.6,
    '43.5': 89.4,
    '44.5': 89.3,
    '45.5': 89.1,
    '46.5': 88.9,
    '47.5': 88.7,
    '48.5': 88.5,
    '49.5': 88.4,
    '50.5': 88.2,
    '51.5': 88,
    '52.5': 87.8,
    '53.5': 87.6,
    '54.5': 87.5,
    '55.5': 87.3,
    '56.5': 87.1,
    '57.5': 86.9,
    '58.5': 86.8,
    '59.5': 86.6,
    '60.5': 86.4,
    '61.5': 86.2,
    '62.5': 86.1,
    '63.5': 85.9,
    '64.5': 85.7,
    '65.5': 85.6,
    '66.5': 85.4,
    '67.5': 85.2,
    '68.5': 85,
    '69.5': 84.9,
    '70.5': 84.7,
    '71.5': 84.5,
    '72.5': 84.4,
    '73.5': 84.2,
    '74.5': 84,
    '75.5': 83.9,
    '76.5': 83.7,
    '77.5': 83.5,
    '78.5': 83.4,
    '79.5': 83.2,
    '80.5': 83,
    '81.5': 82.9,
    '82.5': 82.7,
    '83.5': 82.5,
    '84.5': 82.4,
    '85.5': 82.2,
    '86.5': 82,
    '87.5': 81.9,
    '88.5': 81.7,
    '89.5': 81.6,
    '90.5': 81.4,
    '91.5': 81.2,
    '92.5': 81.1,
    '93.5': 80.9,
    '94.5': 80.8,
    '95.5': 80.6,
    '96.5': 80.5,
    '97.5': 80.3,
    '98.5': 80.1,
    '99.5': 80,
    '100.5': 79.8,
    '101.5': 79.7,
    '102.5': 79.5,
    '103.5': 79.4,
    '104.5': 79.2,
    '105.5': 79,
    '106.5': 78.9,
    '107.5': 78.7,
    '108.5': 78.6,
    '109.5': 78.4,
    '110.5': 78.3,
    '111.5': 78.1,
    '112.5': 78,
    '113.5': 77.8,
    '114.5': 77.7,
    '115.5': 77.5,
    '116.5': 77.4,
    '117.5': 77.2,
    '118.5': 77.1,
    '119.5': 76.9,
    '120.5': 76.8,
    '121.5': 76.6,
    '122.5': 76.5,
    '123.5': 76.3,
    '124.5': 76.2,
    '125.5': 76,
    '126.5': 75.9,
    '127.5': 75.8,
    '128.5': 75.6,
    '129.5': 75.5,
    '130.5': 75.3,
    '131.5': 75.2,
    '132.5': 75,
    '133.5': 74.9,
    '134.5': 74.7,
    '135.5': 74.6,
    '136.5': 74.5,
    '137.5': 74.3,
    '138.5': 74.2,
    '139.5': 74,
    '140.5': 73.9,
    '141.5': 73.7,
    '142.5': 73.6,
    '143.5': 73.5,
    '144.5': 73.3,
    '145.5': 73.2,
    '146.5': 73.1,
    '147.5': 72.9,
    '148.5': 72.8,
    '149.5': 72.6,
    '150.5': 72.5,
    '151.5': 72.4,
    '152.5': 72.2,
    '153.5': 72.1,
    '154.5': 72,
    '155.5': 71.8,
    '156.5': 71.7,
    '157.5': 71.5,
    '158.5': 71.4,
    '159.5': 71.3,
    '160.5': 71.1,
    '161.5': 71,
    '162.5': 70.9,
    '163.5': 70.7,
    '164.5': 70.6,
    '165.5': 70.5,
    '166.5': 70.3,
    '167.5': 70.2,
    '168.5': 70.1,
    '169.5': 69.9,
    '170.5': 69.8,
    '171.5': 69.7,
    '172.5': 69.6,
    '173.5': 69.4,
    '174.5': 69.3,
    '175.5': 69.2,
    '176.5': 69,
    '177.5': 68.9,
    '178.5': 68.8,
    '179.5': 68.7,
    '180.5': 68.5,
    '181.5': 68.4,
    '182.5': 68.3,
    '183.5': 68.1,
    '184.5': 68,
    '185.5': 67.9,
    '186.5': 67.8,
    '187.5': 67.6,
    '188.5': 67.5,
    '189.5': 67.4,
    '190.5': 67.3,
    '191.5': 67.1,
    '192.5': 67,
    '193.5': 66.9,
    '194.5': 66.8,
  },
  '87': {
    '0': 98.4,
    '1': 98.2,
    '2': 98,
    '3': 97.8,
    '4': 97.6,
    '5': 97.4,
    '6': 97.2,
    '7': 97,
    '8': 96.8,
    '9': 96.6,
    '10': 96.4,
    '11': 96.2,
    '12': 96,
    '13': 95.8,
    '14': 95.6,
    '15': 95.4,
    '16': 95.2,
    '17': 95,
    '18': 94.8,
    '19': 94.6,
    '20': 94.4,
    '21': 94.2,
    '22': 94,
    '23': 93.8,
    '24': 93.6,
    '25': 93.4,
    '26': 93.2,
    '27': 93.1,
    '28': 92.9,
    '29': 92.7,
    '30': 92.5,
    '31': 92.3,
    '32': 92.1,
    '33': 91.9,
    '34': 91.7,
    '35': 91.5,
    '36': 91.3,
    '37': 91.2,
    '38': 91,
    '39': 90.8,
    '40': 90.6,
    '41': 90.4,
    '42': 90.2,
    '43': 90.1,
    '44': 89.9,
    '45': 89.7,
    '46': 89.5,
    '47': 89.3,
    '48': 89.1,
    '49': 89,
    '50': 88.8,
    '51': 88.6,
    '52': 88.4,
    '53': 88.2,
    '54': 88.1,
    '55': 87.9,
    '56': 87.7,
    '57': 87.5,
    '58': 87.4,
    '59': 87.2,
    '60': 87,
    '61': 86.8,
    '62': 86.7,
    '63': 86.5,
    '64': 86.3,
    '65': 86.1,
    '66': 86,
    '67': 85.8,
    '68': 85.6,
    '69': 85.4,
    '70': 85.3,
    '71': 85.1,
    '72': 84.9,
    '73': 84.8,
    '74': 84.6,
    '75': 84.4,
    '76': 84.3,
    '77': 84.1,
    '78': 83.9,
    '79': 83.8,
    '80': 83.6,
    '81': 83.4,
    '82': 83.3,
    '83': 83.1,
    '84': 82.9,
    '85': 82.8,
    '86': 82.6,
    '87': 82.4,
    '88': 82.3,
    '89': 82.1,
    '90': 81.9,
    '91': 81.8,
    '92': 81.6,
    '93': 81.5,
    '94': 81.3,
    '95': 81.1,
    '96': 81,
    '97': 80.8,
    '98': 80.7,
    '99': 80.5,
    '100': 80.4,
    '101': 80.2,
    '102': 80,
    '103': 79.9,
    '104': 79.7,
    '105': 79.6,
    '106': 79.4,
    '107': 79.3,
    '108': 79.1,
    '109': 79,
    '110': 78.8,
    '111': 78.7,
    '112': 78.5,
    '113': 78.3,
    '114': 78.2,
    '115': 78,
    '116': 77.9,
    '117': 77.7,
    '118': 77.6,
    '119': 77.4,
    '120': 77.3,
    '121': 77.1,
    '122': 77,
    '123': 76.8,
    '124': 76.7,
    '125': 76.5,
    '126': 76.4,
    '127': 76.3,
    '128': 76.1,
    '129': 76,
    '130': 75.8,
    '131': 75.7,
    '132': 75.5,
    '133': 75.4,
    '134': 75.2,
    '135': 75.1,
    '136': 74.9,
    '137': 74.8,
    '138': 74.7,
    '139': 74.5,
    '140': 74.4,
    '141': 74.2,
    '142': 74.1,
    '143': 74,
    '144': 73.8,
    '145': 73.7,
    '146': 73.5,
    '147': 73.4,
    '148': 73.3,
    '149': 73.1,
    '150': 73,
    '151': 72.8,
    '152': 72.7,
    '153': 72.6,
    '154': 72.4,
    '155': 72.3,
    '156': 72.2,
    '157': 72,
    '158': 71.9,
    '159': 71.7,
    '160': 71.6,
    '161': 71.5,
    '162': 71.3,
    '163': 71.2,
    '164': 71.1,
    '165': 70.9,
    '166': 70.8,
    '167': 70.7,
    '168': 70.5,
    '169': 70.4,
    '170': 70.3,
    '171': 70.1,
    '172': 70,
    '173': 69.9,
    '174': 69.7,
    '175': 69.6,
    '176': 69.5,
    '177': 69.4,
    '178': 69.2,
    '179': 69.1,
    '180': 69,
    '181': 68.8,
    '182': 68.7,
    '183': 68.6,
    '184': 68.5,
    '185': 68.3,
    '186': 68.2,
    '187': 68.1,
    '188': 68,
    '189': 67.8,
    '190': 67.7,
    '191': 67.6,
    '192': 67.4,
    '193': 67.3,
    '194': 67.2,
    '195': 67.1,
    '0.5': 98.3,
    '1.5': 98.1,
    '2.5': 97.9,
    '3.5': 97.7,
    '4.5': 97.5,
    '5.5': 97.3,
    '6.5': 97.1,
    '7.5': 96.9,
    '8.5': 96.7,
    '9.5': 96.5,
    '10.5': 96.3,
    '11.5': 96.1,
    '12.5': 95.9,
    '13.5': 95.7,
    '14.5': 95.5,
    '15.5': 95.3,
    '16.5': 95.1,
    '17.5': 94.9,
    '18.5': 94.7,
    '19.5': 94.5,
    '20.5': 94.3,
    '21.5': 94.1,
    '22.5': 93.9,
    '23.5': 93.7,
    '24.5': 93.5,
    '25.5': 93.3,
    '26.5': 93.2,
    '27.5': 93,
    '28.5': 92.8,
    '29.5': 92.6,
    '30.5': 92.4,
    '31.5': 92.2,
    '32.5': 92,
    '33.5': 91.8,
    '34.5': 91.6,
    '35.5': 91.4,
    '36.5': 91.3,
    '37.5': 91.1,
    '38.5': 90.9,
    '39.5': 90.7,
    '40.5': 90.5,
    '41.5': 90.3,
    '42.5': 90.1,
    '43.5': 90,
    '44.5': 89.8,
    '45.5': 89.6,
    '46.5': 89.4,
    '47.5': 89.2,
    '48.5': 89,
    '49.5': 88.9,
    '50.5': 88.7,
    '51.5': 88.5,
    '52.5': 88.3,
    '53.5': 88.2,
    '54.5': 88,
    '55.5': 87.8,
    '56.5': 87.6,
    '57.5': 87.4,
    '58.5': 87.3,
    '59.5': 87.1,
    '60.5': 86.9,
    '61.5': 86.7,
    '62.5': 86.6,
    '63.5': 86.4,
    '64.5': 86.2,
    '65.5': 86,
    '66.5': 85.9,
    '67.5': 85.7,
    '68.5': 85.5,
    '69.5': 85.4,
    '70.5': 85.2,
    '71.5': 85,
    '72.5': 84.8,
    '73.5': 84.7,
    '74.5': 84.5,
    '75.5': 84.3,
    '76.5': 84.2,
    '77.5': 84,
    '78.5': 83.8,
    '79.5': 83.7,
    '80.5': 83.5,
    '81.5': 83.3,
    '82.5': 83.2,
    '83.5': 83,
    '84.5': 82.8,
    '85.5': 82.7,
    '86.5': 82.5,
    '87.5': 82.4,
    '88.5': 82.2,
    '89.5': 82,
    '90.5': 81.9,
    '91.5': 81.7,
    '92.5': 81.5,
    '93.5': 81.4,
    '94.5': 81.2,
    '95.5': 81.1,
    '96.5': 80.9,
    '97.5': 80.8,
    '98.5': 80.6,
    '99.5': 80.4,
    '100.5': 80.3,
    '101.5': 80.1,
    '102.5': 80,
    '103.5': 79.8,
    '104.5': 79.7,
    '105.5': 79.5,
    '106.5': 79.3,
    '107.5': 79.2,
    '108.5': 79,
    '109.5': 78.9,
    '110.5': 78.7,
    '111.5': 78.6,
    '112.5': 78.4,
    '113.5': 78.3,
    '114.5': 78.1,
    '115.5': 78,
    '116.5': 77.8,
    '117.5': 77.7,
    '118.5': 77.5,
    '119.5': 77.4,
    '120.5': 77.2,
    '121.5': 77.1,
    '122.5': 76.9,
    '123.5': 76.8,
    '124.5': 76.6,
    '125.5': 76.5,
    '126.5': 76.3,
    '127.5': 76.2,
    '128.5': 76,
    '129.5': 75.9,
    '130.5': 75.7,
    '131.5': 75.6,
    '132.5': 75.5,
    '133.5': 75.3,
    '134.5': 75.2,
    '135.5': 75,
    '136.5': 74.9,
    '137.5': 74.7,
    '138.5': 74.6,
    '139.5': 74.4,
    '140.5': 74.3,
    '141.5': 74.2,
    '142.5': 74,
    '143.5': 73.9,
    '144.5': 73.7,
    '145.5': 73.6,
    '146.5': 73.5,
    '147.5': 73.3,
    '148.5': 73.2,
    '149.5': 73,
    '150.5': 72.9,
    '151.5': 72.8,
    '152.5': 72.6,
    '153.5': 72.5,
    '154.5': 72.4,
    '155.5': 72.2,
    '156.5': 72.1,
    '157.5': 71.9,
    '158.5': 71.8,
    '159.5': 71.7,
    '160.5': 71.5,
    '161.5': 71.4,
    '162.5': 71.3,
    '163.5': 71.1,
    '164.5': 71,
    '165.5': 70.9,
    '166.5': 70.7,
    '167.5': 70.6,
    '168.5': 70.5,
    '169.5': 70.3,
    '170.5': 70.2,
    '171.5': 70.1,
    '172.5': 69.9,
    '173.5': 69.8,
    '174.5': 69.7,
    '175.5': 69.6,
    '176.5': 69.4,
    '177.5': 69.3,
    '178.5': 69.2,
    '179.5': 69,
    '180.5': 68.9,
    '181.5': 68.8,
    '182.5': 68.6,
    '183.5': 68.5,
    '184.5': 68.4,
    '185.5': 68.3,
    '186.5': 68.1,
    '187.5': 68,
    '188.5': 67.9,
    '189.5': 67.8,
    '190.5': 67.6,
    '191.5': 67.5,
    '192.5': 67.4,
    '193.5': 67.3,
    '194.5': 67.1,
  },
  '87.5': {
    '0': 99,
    '1': 98.8,
    '2': 98.6,
    '3': 98.4,
    '4': 98.2,
    '5': 98,
    '6': 97.8,
    '7': 97.6,
    '8': 97.4,
    '9': 97.2,
    '10': 97,
    '11': 96.8,
    '12': 96.6,
    '13': 96.4,
    '14': 96.2,
    '15': 96,
    '16': 95.8,
    '17': 95.6,
    '18': 95.4,
    '19': 95.2,
    '20': 95,
    '21': 94.8,
    '22': 94.6,
    '23': 94.4,
    '24': 94.2,
    '25': 94,
    '26': 93.8,
    '27': 93.6,
    '28': 93.4,
    '29': 93.2,
    '30': 93,
    '31': 92.8,
    '32': 92.6,
    '33': 92.4,
    '34': 92.3,
    '35': 92.1,
    '36': 91.9,
    '37': 91.7,
    '38': 91.5,
    '39': 91.3,
    '40': 91.1,
    '41': 90.9,
    '42': 90.8,
    '43': 90.6,
    '44': 90.4,
    '45': 90.2,
    '46': 90,
    '47': 89.8,
    '48': 89.7,
    '49': 89.5,
    '50': 89.3,
    '51': 89.1,
    '52': 88.9,
    '53': 88.7,
    '54': 88.6,
    '55': 88.4,
    '56': 88.2,
    '57': 88,
    '58': 87.9,
    '59': 87.7,
    '60': 87.5,
    '61': 87.3,
    '62': 87.1,
    '63': 87,
    '64': 86.8,
    '65': 86.6,
    '66': 86.5,
    '67': 86.3,
    '68': 86.1,
    '69': 85.9,
    '70': 85.8,
    '71': 85.6,
    '72': 85.4,
    '73': 85.2,
    '74': 85.1,
    '75': 84.9,
    '76': 84.7,
    '77': 84.6,
    '78': 84.4,
    '79': 84.2,
    '80': 84.1,
    '81': 83.9,
    '82': 83.7,
    '83': 83.6,
    '84': 83.4,
    '85': 83.2,
    '86': 83.1,
    '87': 82.9,
    '88': 82.7,
    '89': 82.6,
    '90': 82.4,
    '91': 82.3,
    '92': 82.1,
    '93': 81.9,
    '94': 81.8,
    '95': 81.6,
    '96': 81.4,
    '97': 81.3,
    '98': 81.1,
    '99': 81,
    '100': 80.8,
    '101': 80.7,
    '102': 80.5,
    '103': 80.3,
    '104': 80.2,
    '105': 80,
    '106': 79.9,
    '107': 79.7,
    '108': 79.6,
    '109': 79.4,
    '110': 79.2,
    '111': 79.1,
    '112': 78.9,
    '113': 78.8,
    '114': 78.6,
    '115': 78.5,
    '116': 78.3,
    '117': 78.2,
    '118': 78,
    '119': 77.9,
    '120': 77.7,
    '121': 77.6,
    '122': 77.4,
    '123': 77.3,
    '124': 77.1,
    '125': 77,
    '126': 76.8,
    '127': 76.7,
    '128': 76.5,
    '129': 76.4,
    '130': 76.2,
    '131': 76.1,
    '132': 75.9,
    '133': 75.8,
    '134': 75.7,
    '135': 75.5,
    '136': 75.4,
    '137': 75.2,
    '138': 75.1,
    '139': 74.9,
    '140': 74.8,
    '141': 74.7,
    '142': 74.5,
    '143': 74.4,
    '144': 74.2,
    '145': 74.1,
    '146': 73.9,
    '147': 73.8,
    '148': 73.7,
    '149': 73.5,
    '150': 73.4,
    '151': 73.2,
    '152': 73.1,
    '153': 73,
    '154': 72.8,
    '155': 72.7,
    '156': 72.6,
    '157': 72.4,
    '158': 72.3,
    '159': 72.1,
    '160': 72,
    '161': 71.9,
    '162': 71.7,
    '163': 71.6,
    '164': 71.5,
    '165': 71.3,
    '166': 71.2,
    '167': 71.1,
    '168': 70.9,
    '169': 70.8,
    '170': 70.7,
    '171': 70.5,
    '172': 70.4,
    '173': 70.3,
    '174': 70.1,
    '175': 70,
    '176': 69.9,
    '177': 69.7,
    '178': 69.6,
    '179': 69.5,
    '180': 69.4,
    '181': 69.2,
    '182': 69.1,
    '183': 69,
    '184': 68.8,
    '185': 68.7,
    '186': 68.6,
    '187': 68.5,
    '188': 68.3,
    '189': 68.2,
    '190': 68.1,
    '191': 68,
    '192': 67.8,
    '193': 67.7,
    '194': 67.6,
    '195': 67.5,
    '0.5': 98.9,
    '1.5': 98.7,
    '2.5': 98.5,
    '3.5': 98.3,
    '4.5': 98.1,
    '5.5': 97.9,
    '6.5': 97.7,
    '7.5': 97.5,
    '8.5': 97.3,
    '9.5': 97.1,
    '10.5': 96.9,
    '11.5': 96.7,
    '12.5': 96.5,
    '13.5': 96.3,
    '14.5': 96.1,
    '15.5': 95.9,
    '16.5': 95.7,
    '17.5': 95.5,
    '18.5': 95.3,
    '19.5': 95.1,
    '20.5': 94.9,
    '21.5': 94.7,
    '22.5': 94.5,
    '23.5': 94.3,
    '24.5': 94.1,
    '25.5': 93.9,
    '26.5': 93.7,
    '27.5': 93.5,
    '28.5': 93.3,
    '29.5': 93.1,
    '30.5': 92.9,
    '31.5': 92.7,
    '32.5': 92.5,
    '33.5': 92.4,
    '34.5': 92.2,
    '35.5': 92,
    '36.5': 91.8,
    '37.5': 91.6,
    '38.5': 91.4,
    '39.5': 91.2,
    '40.5': 91,
    '41.5': 90.9,
    '42.5': 90.7,
    '43.5': 90.5,
    '44.5': 90.3,
    '45.5': 90.1,
    '46.5': 89.9,
    '47.5': 89.7,
    '48.5': 89.6,
    '49.5': 89.4,
    '50.5': 89.2,
    '51.5': 89,
    '52.5': 88.8,
    '53.5': 88.7,
    '54.5': 88.5,
    '55.5': 88.3,
    '56.5': 88.1,
    '57.5': 87.9,
    '58.5': 87.8,
    '59.5': 87.6,
    '60.5': 87.4,
    '61.5': 87.2,
    '62.5': 87.1,
    '63.5': 86.9,
    '64.5': 86.7,
    '65.5': 86.5,
    '66.5': 86.4,
    '67.5': 86.2,
    '68.5': 86,
    '69.5': 85.8,
    '70.5': 85.7,
    '71.5': 85.5,
    '72.5': 85.3,
    '73.5': 85.2,
    '74.5': 85,
    '75.5': 84.8,
    '76.5': 84.7,
    '77.5': 84.5,
    '78.5': 84.3,
    '79.5': 84.1,
    '80.5': 84,
    '81.5': 83.8,
    '82.5': 83.7,
    '83.5': 83.5,
    '84.5': 83.3,
    '85.5': 83.2,
    '86.5': 83,
    '87.5': 82.8,
    '88.5': 82.7,
    '89.5': 82.5,
    '90.5': 82.3,
    '91.5': 82.2,
    '92.5': 82,
    '93.5': 81.9,
    '94.5': 81.7,
    '95.5': 81.5,
    '96.5': 81.4,
    '97.5': 81.2,
    '98.5': 81,
    '99.5': 80.9,
    '100.5': 80.7,
    '101.5': 80.6,
    '102.5': 80.4,
    '103.5': 80.3,
    '104.5': 80.1,
    '105.5': 79.9,
    '106.5': 79.8,
    '107.5': 79.6,
    '108.5': 79.5,
    '109.5': 79.3,
    '110.5': 79.2,
    '111.5': 79,
    '112.5': 78.9,
    '113.5': 78.7,
    '114.5': 78.6,
    '115.5': 78.4,
    '116.5': 78.3,
    '117.5': 78.1,
    '118.5': 78,
    '119.5': 77.8,
    '120.5': 77.7,
    '121.5': 77.5,
    '122.5': 77.4,
    '123.5': 77.2,
    '124.5': 77.1,
    '125.5': 76.9,
    '126.5': 76.8,
    '127.5': 76.6,
    '128.5': 76.5,
    '129.5': 76.3,
    '130.5': 76.2,
    '131.5': 76,
    '132.5': 75.9,
    '133.5': 75.7,
    '134.5': 75.6,
    '135.5': 75.4,
    '136.5': 75.3,
    '137.5': 75.2,
    '138.5': 75,
    '139.5': 74.9,
    '140.5': 74.7,
    '141.5': 74.6,
    '142.5': 74.4,
    '143.5': 74.3,
    '144.5': 74.2,
    '145.5': 74,
    '146.5': 73.9,
    '147.5': 73.7,
    '148.5': 73.6,
    '149.5': 73.5,
    '150.5': 73.3,
    '151.5': 73.2,
    '152.5': 73,
    '153.5': 72.9,
    '154.5': 72.8,
    '155.5': 72.6,
    '156.5': 72.5,
    '157.5': 72.4,
    '158.5': 72.2,
    '159.5': 72.1,
    '160.5': 71.9,
    '161.5': 71.8,
    '162.5': 71.7,
    '163.5': 71.5,
    '164.5': 71.4,
    '165.5': 71.3,
    '166.5': 71.1,
    '167.5': 71,
    '168.5': 70.9,
    '169.5': 70.7,
    '170.5': 70.6,
    '171.5': 70.5,
    '172.5': 70.3,
    '173.5': 70.2,
    '174.5': 70.1,
    '175.5': 69.9,
    '176.5': 69.8,
    '177.5': 69.7,
    '178.5': 69.6,
    '179.5': 69.4,
    '180.5': 69.3,
    '181.5': 69.2,
    '182.5': 69,
    '183.5': 68.9,
    '184.5': 68.8,
    '185.5': 68.7,
    '186.5': 68.5,
    '187.5': 68.4,
    '188.5': 68.3,
    '189.5': 68.1,
    '190.5': 68,
    '191.5': 67.9,
    '192.5': 67.8,
    '193.5': 67.6,
    '194.5': 67.5,
  },
  '88': {
    '0': 99.6,
    '1': 99.4,
    '2': 99.2,
    '3': 99,
    '4': 98.8,
    '5': 98.6,
    '6': 98.4,
    '7': 98.2,
    '8': 97.9,
    '9': 97.7,
    '10': 97.5,
    '11': 97.3,
    '12': 97.1,
    '13': 96.9,
    '14': 96.7,
    '15': 96.5,
    '16': 96.3,
    '17': 96.1,
    '18': 95.9,
    '19': 95.7,
    '20': 95.5,
    '21': 95.3,
    '22': 95.1,
    '23': 94.9,
    '24': 94.7,
    '25': 94.5,
    '26': 94.3,
    '27': 94.1,
    '28': 93.9,
    '29': 93.8,
    '30': 93.6,
    '31': 93.4,
    '32': 93.2,
    '33': 93,
    '34': 92.8,
    '35': 92.6,
    '36': 92.4,
    '37': 92.2,
    '38': 92,
    '39': 91.8,
    '40': 91.7,
    '41': 91.5,
    '42': 91.3,
    '43': 91.1,
    '44': 90.9,
    '45': 90.7,
    '46': 90.5,
    '47': 90.4,
    '48': 90.2,
    '49': 90,
    '50': 89.8,
    '51': 89.6,
    '52': 89.4,
    '53': 89.3,
    '54': 89.1,
    '55': 88.9,
    '56': 88.7,
    '57': 88.5,
    '58': 88.4,
    '59': 88.2,
    '60': 88,
    '61': 87.8,
    '62': 87.6,
    '63': 87.5,
    '64': 87.3,
    '65': 87.1,
    '66': 86.9,
    '67': 86.8,
    '68': 86.6,
    '69': 86.4,
    '70': 86.2,
    '71': 86.1,
    '72': 85.9,
    '73': 85.7,
    '74': 85.6,
    '75': 85.4,
    '76': 85.2,
    '77': 85,
    '78': 84.9,
    '79': 84.7,
    '80': 84.5,
    '81': 84.4,
    '82': 84.2,
    '83': 84,
    '84': 83.9,
    '85': 83.7,
    '86': 83.5,
    '87': 83.4,
    '88': 83.2,
    '89': 83,
    '90': 82.9,
    '91': 82.7,
    '92': 82.6,
    '93': 82.4,
    '94': 82.2,
    '95': 82.1,
    '96': 81.9,
    '97': 81.7,
    '98': 81.6,
    '99': 81.4,
    '100': 81.3,
    '101': 81.1,
    '102': 81,
    '103': 80.8,
    '104': 80.6,
    '105': 80.5,
    '106': 80.3,
    '107': 80.2,
    '108': 80,
    '109': 79.9,
    '110': 79.7,
    '111': 79.5,
    '112': 79.4,
    '113': 79.2,
    '114': 79.1,
    '115': 78.9,
    '116': 78.8,
    '117': 78.6,
    '118': 78.5,
    '119': 78.3,
    '120': 78.2,
    '121': 78,
    '122': 77.9,
    '123': 77.7,
    '124': 77.6,
    '125': 77.4,
    '126': 77.3,
    '127': 77.1,
    '128': 77,
    '129': 76.8,
    '130': 76.7,
    '131': 76.5,
    '132': 76.4,
    '133': 76.2,
    '134': 76.1,
    '135': 75.9,
    '136': 75.8,
    '137': 75.6,
    '138': 75.5,
    '139': 75.4,
    '140': 75.2,
    '141': 75.1,
    '142': 74.9,
    '143': 74.8,
    '144': 74.6,
    '145': 74.5,
    '146': 74.4,
    '147': 74.2,
    '148': 74.1,
    '149': 73.9,
    '150': 73.8,
    '151': 73.7,
    '152': 73.5,
    '153': 73.4,
    '154': 73.2,
    '155': 73.1,
    '156': 73,
    '157': 72.8,
    '158': 72.7,
    '159': 72.5,
    '160': 72.4,
    '161': 72.3,
    '162': 72.1,
    '163': 72,
    '164': 71.9,
    '165': 71.7,
    '166': 71.6,
    '167': 71.5,
    '168': 71.3,
    '169': 71.2,
    '170': 71.1,
    '171': 70.9,
    '172': 70.8,
    '173': 70.7,
    '174': 70.5,
    '175': 70.4,
    '176': 70.3,
    '177': 70.1,
    '178': 70,
    '179': 69.9,
    '180': 69.7,
    '181': 69.6,
    '182': 69.5,
    '183': 69.4,
    '184': 69.2,
    '185': 69.1,
    '186': 69,
    '187': 68.8,
    '188': 68.7,
    '189': 68.6,
    '190': 68.5,
    '191': 68.3,
    '192': 68.2,
    '193': 68.1,
    '194': 68,
    '195': 67.8,
    '0.5': 99.5,
    '1.5': 99.3,
    '2.5': 99.1,
    '3.5': 98.9,
    '4.5': 98.7,
    '5.5': 98.5,
    '6.5': 98.3,
    '7.5': 98.1,
    '8.5': 97.8,
    '9.5': 97.6,
    '10.5': 97.4,
    '11.5': 97.2,
    '12.5': 97,
    '13.5': 96.8,
    '14.5': 96.6,
    '15.5': 96.4,
    '16.5': 96.2,
    '17.5': 96,
    '18.5': 95.8,
    '19.5': 95.6,
    '20.5': 95.4,
    '21.5': 95.2,
    '22.5': 95,
    '23.5': 94.8,
    '24.5': 94.6,
    '25.5': 94.4,
    '26.5': 94.2,
    '27.5': 94,
    '28.5': 93.9,
    '29.5': 93.7,
    '30.5': 93.5,
    '31.5': 93.3,
    '32.5': 93.1,
    '33.5': 92.9,
    '34.5': 92.7,
    '35.5': 92.5,
    '36.5': 92.3,
    '37.5': 92.1,
    '38.5': 91.9,
    '39.5': 91.7,
    '40.5': 91.6,
    '41.5': 91.4,
    '42.5': 91.2,
    '43.5': 91,
    '44.5': 90.8,
    '45.5': 90.6,
    '46.5': 90.4,
    '47.5': 90.3,
    '48.5': 90.1,
    '49.5': 89.9,
    '50.5': 89.7,
    '51.5': 89.5,
    '52.5': 89.3,
    '53.5': 89.2,
    '54.5': 89,
    '55.5': 88.8,
    '56.5': 88.6,
    '57.5': 88.4,
    '58.5': 88.3,
    '59.5': 88.1,
    '60.5': 87.9,
    '61.5': 87.7,
    '62.5': 87.6,
    '63.5': 87.4,
    '64.5': 87.2,
    '65.5': 87,
    '66.5': 86.9,
    '67.5': 86.7,
    '68.5': 86.5,
    '69.5': 86.3,
    '70.5': 86.2,
    '71.5': 86,
    '72.5': 85.8,
    '73.5': 85.6,
    '74.5': 85.5,
    '75.5': 85.3,
    '76.5': 85.1,
    '77.5': 85,
    '78.5': 84.8,
    '79.5': 84.6,
    '80.5': 84.5,
    '81.5': 84.3,
    '82.5': 84.1,
    '83.5': 84,
    '84.5': 83.8,
    '85.5': 83.6,
    '86.5': 83.5,
    '87.5': 83.3,
    '88.5': 83.1,
    '89.5': 83,
    '90.5': 82.8,
    '91.5': 82.6,
    '92.5': 82.5,
    '93.5': 82.3,
    '94.5': 82.2,
    '95.5': 82,
    '96.5': 81.8,
    '97.5': 81.7,
    '98.5': 81.5,
    '99.5': 81.3,
    '100.5': 81.2,
    '101.5': 81,
    '102.5': 80.9,
    '103.5': 80.7,
    '104.5': 80.6,
    '105.5': 80.4,
    '106.5': 80.2,
    '107.5': 80.1,
    '108.5': 79.9,
    '109.5': 79.8,
    '110.5': 79.6,
    '111.5': 79.5,
    '112.5': 79.3,
    '113.5': 79.2,
    '114.5': 79,
    '115.5': 78.8,
    '116.5': 78.7,
    '117.5': 78.5,
    '118.5': 78.4,
    '119.5': 78.2,
    '120.5': 78.1,
    '121.5': 77.9,
    '122.5': 77.8,
    '123.5': 77.6,
    '124.5': 77.5,
    '125.5': 77.3,
    '126.5': 77.2,
    '127.5': 77,
    '128.5': 76.9,
    '129.5': 76.7,
    '130.5': 76.6,
    '131.5': 76.4,
    '132.5': 76.3,
    '133.5': 76.2,
    '134.5': 76,
    '135.5': 75.9,
    '136.5': 75.7,
    '137.5': 75.6,
    '138.5': 75.4,
    '139.5': 75.3,
    '140.5': 75.1,
    '141.5': 75,
    '142.5': 74.9,
    '143.5': 74.7,
    '144.5': 74.6,
    '145.5': 74.4,
    '146.5': 74.3,
    '147.5': 74.1,
    '148.5': 74,
    '149.5': 73.9,
    '150.5': 73.7,
    '151.5': 73.6,
    '152.5': 73.4,
    '153.5': 73.3,
    '154.5': 73.2,
    '155.5': 73,
    '156.5': 72.9,
    '157.5': 72.8,
    '158.5': 72.6,
    '159.5': 72.5,
    '160.5': 72.3,
    '161.5': 72.2,
    '162.5': 72.1,
    '163.5': 71.9,
    '164.5': 71.8,
    '165.5': 71.7,
    '166.5': 71.5,
    '167.5': 71.4,
    '168.5': 71.3,
    '169.5': 71.1,
    '170.5': 71,
    '171.5': 70.9,
    '172.5': 70.7,
    '173.5': 70.6,
    '174.5': 70.5,
    '175.5': 70.3,
    '176.5': 70.2,
    '177.5': 70.1,
    '178.5': 69.9,
    '179.5': 69.8,
    '180.5': 69.7,
    '181.5': 69.5,
    '182.5': 69.4,
    '183.5': 69.3,
    '184.5': 69.2,
    '185.5': 69,
    '186.5': 68.9,
    '187.5': 68.8,
    '188.5': 68.7,
    '189.5': 68.5,
    '190.5': 68.4,
    '191.5': 68.3,
    '192.5': 68.1,
    '193.5': 68,
    '194.5': 67.9,
  },
  '88.5': {
    '1': 100,
    '2': 99.8,
    '3': 99.6,
    '4': 99.4,
    '5': 99.1,
    '6': 98.9,
    '7': 98.7,
    '8': 98.5,
    '9': 98.3,
    '10': 98.1,
    '11': 97.9,
    '12': 97.7,
    '13': 97.5,
    '14': 97.3,
    '15': 97.1,
    '16': 96.9,
    '17': 96.7,
    '18': 96.5,
    '19': 96.3,
    '20': 96.1,
    '21': 95.9,
    '22': 95.7,
    '23': 95.5,
    '24': 95.3,
    '25': 95.1,
    '26': 94.9,
    '27': 94.7,
    '28': 94.5,
    '29': 94.3,
    '30': 94.1,
    '31': 93.9,
    '32': 93.7,
    '33': 93.5,
    '34': 93.3,
    '35': 93.1,
    '36': 92.9,
    '37': 92.7,
    '38': 92.6,
    '39': 92.4,
    '40': 92.2,
    '41': 92,
    '42': 91.8,
    '43': 91.6,
    '44': 91.4,
    '45': 91.2,
    '46': 91.1,
    '47': 90.9,
    '48': 90.7,
    '49': 90.5,
    '50': 90.3,
    '51': 90.1,
    '52': 89.9,
    '53': 89.8,
    '54': 89.6,
    '55': 89.4,
    '56': 89.2,
    '57': 89,
    '58': 88.9,
    '59': 88.7,
    '60': 88.5,
    '61': 88.3,
    '62': 88.1,
    '63': 88,
    '64': 87.8,
    '65': 87.6,
    '66': 87.4,
    '67': 87.3,
    '68': 87.1,
    '69': 86.9,
    '70': 86.7,
    '71': 86.6,
    '72': 86.4,
    '73': 86.2,
    '74': 86,
    '75': 85.9,
    '76': 85.7,
    '77': 85.5,
    '78': 85.4,
    '79': 85.2,
    '80': 85,
    '81': 84.8,
    '82': 84.7,
    '83': 84.5,
    '84': 84.3,
    '85': 84.2,
    '86': 84,
    '87': 83.8,
    '88': 83.7,
    '89': 83.5,
    '90': 83.3,
    '91': 83.2,
    '92': 83,
    '93': 82.9,
    '94': 82.7,
    '95': 82.5,
    '96': 82.4,
    '97': 82.2,
    '98': 82,
    '99': 81.9,
    '100': 81.7,
    '101': 81.6,
    '102': 81.4,
    '103': 81.2,
    '104': 81.1,
    '105': 80.9,
    '106': 80.8,
    '107': 80.6,
    '108': 80.5,
    '109': 80.3,
    '110': 80.1,
    '111': 80,
    '112': 79.8,
    '113': 79.7,
    '114': 79.5,
    '115': 79.4,
    '116': 79.2,
    '117': 79.1,
    '118': 78.9,
    '119': 78.8,
    '120': 78.6,
    '121': 78.4,
    '122': 78.3,
    '123': 78.1,
    '124': 78,
    '125': 77.8,
    '126': 77.7,
    '127': 77.5,
    '128': 77.4,
    '129': 77.2,
    '130': 77.1,
    '131': 76.9,
    '132': 76.8,
    '133': 76.7,
    '134': 76.5,
    '135': 76.4,
    '136': 76.2,
    '137': 76.1,
    '138': 75.9,
    '139': 75.8,
    '140': 75.6,
    '141': 75.5,
    '142': 75.3,
    '143': 75.2,
    '144': 75.1,
    '145': 74.9,
    '146': 74.8,
    '147': 74.6,
    '148': 74.5,
    '149': 74.3,
    '150': 74.2,
    '151': 74.1,
    '152': 73.9,
    '153': 73.8,
    '154': 73.6,
    '155': 73.5,
    '156': 73.4,
    '157': 73.2,
    '158': 73.1,
    '159': 72.9,
    '160': 72.8,
    '161': 72.7,
    '162': 72.5,
    '163': 72.4,
    '164': 72.3,
    '165': 72.1,
    '166': 72,
    '167': 71.9,
    '168': 71.7,
    '169': 71.6,
    '170': 71.5,
    '171': 71.3,
    '172': 71.2,
    '173': 71.1,
    '174': 70.9,
    '175': 70.8,
    '176': 70.7,
    '177': 70.5,
    '178': 70.4,
    '179': 70.3,
    '180': 70.1,
    '181': 70,
    '182': 69.9,
    '183': 69.7,
    '184': 69.6,
    '185': 69.5,
    '186': 69.3,
    '187': 69.2,
    '188': 69.1,
    '189': 69,
    '190': 68.8,
    '191': 68.7,
    '192': 68.6,
    '193': 68.5,
    '194': 68.3,
    '195': 68.2,
    '1.5': 99.9,
    '2.5': 99.7,
    '3.5': 99.5,
    '4.5': 99.3,
    '5.5': 99,
    '6.5': 98.8,
    '7.5': 98.6,
    '8.5': 98.4,
    '9.5': 98.2,
    '10.5': 98,
    '11.5': 97.8,
    '12.5': 97.6,
    '13.5': 97.4,
    '14.5': 97.2,
    '15.5': 97,
    '16.5': 96.8,
    '17.5': 96.6,
    '18.5': 96.4,
    '19.5': 96.2,
    '20.5': 96,
    '21.5': 95.8,
    '22.5': 95.6,
    '23.5': 95.4,
    '24.5': 95.2,
    '25.5': 95,
    '26.5': 94.8,
    '27.5': 94.6,
    '28.5': 94.4,
    '29.5': 94.2,
    '30.5': 94,
    '31.5': 93.8,
    '32.5': 93.6,
    '33.5': 93.4,
    '34.5': 93.2,
    '35.5': 93,
    '36.5': 92.8,
    '37.5': 92.7,
    '38.5': 92.5,
    '39.5': 92.3,
    '40.5': 92.1,
    '41.5': 91.9,
    '42.5': 91.7,
    '43.5': 91.5,
    '44.5': 91.3,
    '45.5': 91.1,
    '46.5': 91,
    '47.5': 90.8,
    '48.5': 90.6,
    '49.5': 90.4,
    '50.5': 90.2,
    '51.5': 90,
    '52.5': 89.9,
    '53.5': 89.7,
    '54.5': 89.5,
    '55.5': 89.3,
    '56.5': 89.1,
    '57.5': 88.9,
    '58.5': 88.8,
    '59.5': 88.6,
    '60.5': 88.4,
    '61.5': 88.2,
    '62.5': 88.1,
    '63.5': 87.9,
    '64.5': 87.7,
    '65.5': 87.5,
    '66.5': 87.3,
    '67.5': 87.2,
    '68.5': 87,
    '69.5': 86.8,
    '70.5': 86.6,
    '71.5': 86.5,
    '72.5': 86.3,
    '73.5': 86.1,
    '74.5': 86,
    '75.5': 85.8,
    '76.5': 85.6,
    '77.5': 85.4,
    '78.5': 85.3,
    '79.5': 85.1,
    '80.5': 84.9,
    '81.5': 84.8,
    '82.5': 84.6,
    '83.5': 84.4,
    '84.5': 84.3,
    '85.5': 84.1,
    '86.5': 83.9,
    '87.5': 83.8,
    '88.5': 83.6,
    '89.5': 83.4,
    '90.5': 83.3,
    '91.5': 83.1,
    '92.5': 82.9,
    '93.5': 82.8,
    '94.5': 82.6,
    '95.5': 82.4,
    '96.5': 82.3,
    '97.5': 82.1,
    '98.5': 82,
    '99.5': 81.8,
    '100.5': 81.6,
    '101.5': 81.5,
    '102.5': 81.3,
    '103.5': 81.2,
    '104.5': 81,
    '105.5': 80.8,
    '106.5': 80.7,
    '107.5': 80.5,
    '108.5': 80.4,
    '109.5': 80.2,
    '110.5': 80.1,
    '111.5': 79.9,
    '112.5': 79.8,
    '113.5': 79.6,
    '114.5': 79.4,
    '115.5': 79.3,
    '116.5': 79.1,
    '117.5': 79,
    '118.5': 78.8,
    '119.5': 78.7,
    '120.5': 78.5,
    '121.5': 78.4,
    '122.5': 78.2,
    '123.5': 78.1,
    '124.5': 77.9,
    '125.5': 77.8,
    '126.5': 77.6,
    '127.5': 77.5,
    '128.5': 77.3,
    '129.5': 77.2,
    '130.5': 77,
    '131.5': 76.9,
    '132.5': 76.7,
    '133.5': 76.6,
    '134.5': 76.4,
    '135.5': 76.3,
    '136.5': 76.1,
    '137.5': 76,
    '138.5': 75.8,
    '139.5': 75.7,
    '140.5': 75.6,
    '141.5': 75.4,
    '142.5': 75.3,
    '143.5': 75.1,
    '144.5': 75,
    '145.5': 74.8,
    '146.5': 74.7,
    '147.5': 74.6,
    '148.5': 74.4,
    '149.5': 74.3,
    '150.5': 74.1,
    '151.5': 74,
    '152.5': 73.8,
    '153.5': 73.7,
    '154.5': 73.6,
    '155.5': 73.4,
    '156.5': 73.3,
    '157.5': 73.2,
    '158.5': 73,
    '159.5': 72.9,
    '160.5': 72.7,
    '161.5': 72.6,
    '162.5': 72.5,
    '163.5': 72.3,
    '164.5': 72.2,
    '165.5': 72.1,
    '166.5': 71.9,
    '167.5': 71.8,
    '168.5': 71.7,
    '169.5': 71.5,
    '170.5': 71.4,
    '171.5': 71.3,
    '172.5': 71.1,
    '173.5': 71,
    '174.5': 70.9,
    '175.5': 70.7,
    '176.5': 70.6,
    '177.5': 70.5,
    '178.5': 70.3,
    '179.5': 70.2,
    '180.5': 70.1,
    '181.5': 69.9,
    '182.5': 69.8,
    '183.5': 69.7,
    '184.5': 69.5,
    '185.5': 69.4,
    '186.5': 69.3,
    '187.5': 69.2,
    '188.5': 69,
    '189.5': 68.9,
    '190.5': 68.8,
    '191.5': 68.6,
    '192.5': 68.5,
    '193.5': 68.4,
    '194.5': 68.3,
  },
  '89': {
    '4': 99.9,
    '5': 99.7,
    '6': 99.5,
    '7': 99.3,
    '8': 99.1,
    '9': 98.9,
    '10': 98.7,
    '11': 98.5,
    '12': 98.3,
    '13': 98.1,
    '14': 97.9,
    '15': 97.6,
    '16': 97.4,
    '17': 97.2,
    '18': 97,
    '19': 96.8,
    '20': 96.6,
    '21': 96.4,
    '22': 96.2,
    '23': 96,
    '24': 95.8,
    '25': 95.6,
    '26': 95.4,
    '27': 95.2,
    '28': 95,
    '29': 94.8,
    '30': 94.6,
    '31': 94.4,
    '32': 94.2,
    '33': 94.1,
    '34': 93.9,
    '35': 93.7,
    '36': 93.5,
    '37': 93.3,
    '38': 93.1,
    '39': 92.9,
    '40': 92.7,
    '41': 92.5,
    '42': 92.3,
    '43': 92.1,
    '44': 91.9,
    '45': 91.8,
    '46': 91.6,
    '47': 91.4,
    '48': 91.2,
    '49': 91,
    '50': 90.8,
    '51': 90.6,
    '52': 90.5,
    '53': 90.3,
    '54': 90.1,
    '55': 89.9,
    '56': 89.7,
    '57': 89.5,
    '58': 89.4,
    '59': 89.2,
    '60': 89,
    '61': 88.8,
    '62': 88.6,
    '63': 88.5,
    '64': 88.3,
    '65': 88.1,
    '66': 87.9,
    '67': 87.8,
    '68': 87.6,
    '69': 87.4,
    '70': 87.2,
    '71': 87,
    '72': 86.9,
    '73': 86.7,
    '74': 86.5,
    '75': 86.4,
    '76': 86.2,
    '77': 86,
    '78': 85.8,
    '79': 85.7,
    '80': 85.5,
    '81': 85.3,
    '82': 85.2,
    '83': 85,
    '84': 84.8,
    '85': 84.6,
    '86': 84.5,
    '87': 84.3,
    '88': 84.1,
    '89': 84,
    '90': 83.8,
    '91': 83.6,
    '92': 83.5,
    '93': 83.3,
    '94': 83.2,
    '95': 83,
    '96': 82.8,
    '97': 82.7,
    '98': 82.5,
    '99': 82.3,
    '100': 82.2,
    '101': 82,
    '102': 81.9,
    '103': 81.7,
    '104': 81.5,
    '105': 81.4,
    '106': 81.2,
    '107': 81.1,
    '108': 80.9,
    '109': 80.7,
    '110': 80.6,
    '111': 80.4,
    '112': 80.3,
    '113': 80.1,
    '114': 80,
    '115': 79.8,
    '116': 79.6,
    '117': 79.5,
    '118': 79.3,
    '119': 79.2,
    '120': 79,
    '121': 78.9,
    '122': 78.7,
    '123': 78.6,
    '124': 78.4,
    '125': 78.3,
    '126': 78.1,
    '127': 78,
    '128': 77.8,
    '129': 77.7,
    '130': 77.5,
    '131': 77.4,
    '132': 77.2,
    '133': 77.1,
    '134': 76.9,
    '135': 76.8,
    '136': 76.6,
    '137': 76.5,
    '138': 76.3,
    '139': 76.2,
    '140': 76,
    '141': 75.9,
    '142': 75.8,
    '143': 75.6,
    '144': 75.5,
    '145': 75.3,
    '146': 75.2,
    '147': 75,
    '148': 74.9,
    '149': 74.8,
    '150': 74.6,
    '151': 74.5,
    '152': 74.3,
    '153': 74.2,
    '154': 74,
    '155': 73.9,
    '156': 73.8,
    '157': 73.6,
    '158': 73.5,
    '159': 73.3,
    '160': 73.2,
    '161': 73.1,
    '162': 72.9,
    '163': 72.8,
    '164': 72.7,
    '165': 72.5,
    '166': 72.4,
    '167': 72.3,
    '168': 72.1,
    '169': 72,
    '170': 71.8,
    '171': 71.7,
    '172': 71.6,
    '173': 71.4,
    '174': 71.3,
    '175': 71.2,
    '176': 71,
    '177': 70.9,
    '178': 70.8,
    '179': 70.6,
    '180': 70.5,
    '181': 70.4,
    '182': 70.3,
    '183': 70.1,
    '184': 70,
    '185': 69.9,
    '186': 69.7,
    '187': 69.6,
    '188': 69.5,
    '189': 69.3,
    '190': 69.2,
    '191': 69.1,
    '192': 69,
    '193': 68.8,
    '194': 68.7,
    '195': 68.6,
    '4.5': 99.8,
    '5.5': 99.6,
    '6.5': 99.4,
    '7.5': 99.2,
    '8.5': 99,
    '9.5': 98.8,
    '10.5': 98.6,
    '11.5': 98.4,
    '12.5': 98.2,
    '13.5': 98,
    '14.5': 97.8,
    '15.5': 97.5,
    '16.5': 97.3,
    '17.5': 97.1,
    '18.5': 96.9,
    '19.5': 96.7,
    '20.5': 96.5,
    '21.5': 96.3,
    '22.5': 96.1,
    '23.5': 95.9,
    '24.5': 95.7,
    '25.5': 95.5,
    '26.5': 95.3,
    '27.5': 95.1,
    '28.5': 94.9,
    '29.5': 94.7,
    '30.5': 94.5,
    '31.5': 94.3,
    '32.5': 94.2,
    '33.5': 94,
    '34.5': 93.8,
    '35.5': 93.6,
    '36.5': 93.4,
    '37.5': 93.2,
    '38.5': 93,
    '39.5': 92.8,
    '40.5': 92.6,
    '41.5': 92.4,
    '42.5': 92.2,
    '43.5': 92,
    '44.5': 91.9,
    '45.5': 91.7,
    '46.5': 91.5,
    '47.5': 91.3,
    '48.5': 91.1,
    '49.5': 90.9,
    '50.5': 90.7,
    '51.5': 90.5,
    '52.5': 90.4,
    '53.5': 90.2,
    '54.5': 90,
    '55.5': 89.8,
    '56.5': 89.6,
    '57.5': 89.5,
    '58.5': 89.3,
    '59.5': 89.1,
    '60.5': 88.9,
    '61.5': 88.7,
    '62.5': 88.6,
    '63.5': 88.4,
    '64.5': 88.2,
    '65.5': 88,
    '66.5': 87.8,
    '67.5': 87.7,
    '68.5': 87.5,
    '69.5': 87.3,
    '70.5': 87.1,
    '71.5': 87,
    '72.5': 86.8,
    '73.5': 86.6,
    '74.5': 86.4,
    '75.5': 86.3,
    '76.5': 86.1,
    '77.5': 85.9,
    '78.5': 85.7,
    '79.5': 85.6,
    '80.5': 85.4,
    '81.5': 85.2,
    '82.5': 85.1,
    '83.5': 84.9,
    '84.5': 84.7,
    '85.5': 84.6,
    '86.5': 84.4,
    '87.5': 84.2,
    '88.5': 84.1,
    '89.5': 83.9,
    '90.5': 83.7,
    '91.5': 83.6,
    '92.5': 83.4,
    '93.5': 83.2,
    '94.5': 83.1,
    '95.5': 82.9,
    '96.5': 82.7,
    '97.5': 82.6,
    '98.5': 82.4,
    '99.5': 82.3,
    '100.5': 82.1,
    '101.5': 81.9,
    '102.5': 81.8,
    '103.5': 81.6,
    '104.5': 81.5,
    '105.5': 81.3,
    '106.5': 81.1,
    '107.5': 81,
    '108.5': 80.8,
    '109.5': 80.7,
    '110.5': 80.5,
    '111.5': 80.3,
    '112.5': 80.2,
    '113.5': 80,
    '114.5': 79.9,
    '115.5': 79.7,
    '116.5': 79.6,
    '117.5': 79.4,
    '118.5': 79.3,
    '119.5': 79.1,
    '120.5': 79,
    '121.5': 78.8,
    '122.5': 78.7,
    '123.5': 78.5,
    '124.5': 78.3,
    '125.5': 78.2,
    '126.5': 78,
    '127.5': 77.9,
    '128.5': 77.7,
    '129.5': 77.6,
    '130.5': 77.4,
    '131.5': 77.3,
    '132.5': 77.1,
    '133.5': 77,
    '134.5': 76.9,
    '135.5': 76.7,
    '136.5': 76.6,
    '137.5': 76.4,
    '138.5': 76.3,
    '139.5': 76.1,
    '140.5': 76,
    '141.5': 75.8,
    '142.5': 75.7,
    '143.5': 75.5,
    '144.5': 75.4,
    '145.5': 75.3,
    '146.5': 75.1,
    '147.5': 75,
    '148.5': 74.8,
    '149.5': 74.7,
    '150.5': 74.5,
    '151.5': 74.4,
    '152.5': 74.3,
    '153.5': 74.1,
    '154.5': 74,
    '155.5': 73.8,
    '156.5': 73.7,
    '157.5': 73.6,
    '158.5': 73.4,
    '159.5': 73.3,
    '160.5': 73.1,
    '161.5': 73,
    '162.5': 72.9,
    '163.5': 72.7,
    '164.5': 72.6,
    '165.5': 72.5,
    '166.5': 72.3,
    '167.5': 72.2,
    '168.5': 72.1,
    '169.5': 71.9,
    '170.5': 71.8,
    '171.5': 71.6,
    '172.5': 71.5,
    '173.5': 71.4,
    '174.5': 71.2,
    '175.5': 71.1,
    '176.5': 71,
    '177.5': 70.8,
    '178.5': 70.7,
    '179.5': 70.6,
    '180.5': 70.4,
    '181.5': 70.3,
    '182.5': 70.2,
    '183.5': 70.1,
    '184.5': 69.9,
    '185.5': 69.8,
    '186.5': 69.7,
    '187.5': 69.5,
    '188.5': 69.4,
    '189.5': 69.3,
    '190.5': 69.2,
    '191.5': 69,
    '192.5': 68.9,
    '193.5': 68.8,
    '194.5': 68.6,
  },
  '89.5': {
    '7': 99.9,
    '8': 99.7,
    '9': 99.5,
    '10': 99.2,
    '11': 99,
    '12': 98.8,
    '13': 98.6,
    '14': 98.4,
    '15': 98.1,
    '16': 98,
    '17': 97.8,
    '18': 97.6,
    '19': 97.4,
    '20': 97.2,
    '21': 97,
    '22': 96.8,
    '23': 96.6,
    '24': 96.4,
    '25': 96.2,
    '26': 96,
    '27': 95.8,
    '28': 95.6,
    '29': 95.4,
    '30': 95.2,
    '31': 95,
    '32': 94.8,
    '33': 94.6,
    '34': 94.4,
    '35': 94.2,
    '36': 94,
    '37': 93.8,
    '38': 93.6,
    '39': 93.4,
    '40': 93.2,
    '41': 93,
    '42': 92.8,
    '43': 92.7,
    '44': 92.5,
    '45': 92.3,
    '46': 92.1,
    '47': 91.9,
    '48': 91.7,
    '49': 91.5,
    '50': 91.3,
    '51': 91.2,
    '52': 91,
    '53': 90.8,
    '54': 90.6,
    '55': 90.4,
    '56': 90.2,
    '57': 90,
    '58': 89.9,
    '59': 89.7,
    '60': 89.5,
    '61': 89.3,
    '62': 89.1,
    '63': 89,
    '64': 88.8,
    '65': 88.6,
    '66': 88.4,
    '67': 88.2,
    '68': 88.1,
    '69': 87.9,
    '70': 87.7,
    '71': 87.5,
    '72': 87.4,
    '73': 87.2,
    '74': 87,
    '75': 86.8,
    '76': 86.7,
    '77': 86.5,
    '78': 86.3,
    '79': 86.1,
    '80': 86,
    '81': 85.8,
    '82': 85.6,
    '83': 85.5,
    '84': 85.3,
    '85': 85.1,
    '86': 85,
    '87': 84.8,
    '88': 84.6,
    '89': 84.4,
    '90': 84.3,
    '91': 84.1,
    '92': 83.9,
    '93': 83.8,
    '94': 83.6,
    '95': 83.4,
    '96': 83.3,
    '97': 83.1,
    '98': 83,
    '99': 82.8,
    '100': 82.6,
    '101': 82.5,
    '102': 82.3,
    '103': 82.1,
    '104': 82,
    '105': 81.8,
    '106': 81.7,
    '107': 81.5,
    '108': 81.3,
    '109': 81.2,
    '110': 81,
    '111': 80.9,
    '112': 80.7,
    '113': 80.6,
    '114': 80.4,
    '115': 80.2,
    '116': 80.1,
    '117': 79.9,
    '118': 79.8,
    '119': 79.6,
    '120': 79.5,
    '121': 79.3,
    '122': 79.2,
    '123': 79,
    '124': 78.9,
    '125': 78.7,
    '126': 78.6,
    '127': 78.4,
    '128': 78.2,
    '129': 78.1,
    '130': 77.9,
    '131': 77.8,
    '132': 77.6,
    '133': 77.5,
    '134': 77.4,
    '135': 77.2,
    '136': 77.1,
    '137': 76.9,
    '138': 76.8,
    '139': 76.6,
    '140': 76.5,
    '141': 76.3,
    '142': 76.2,
    '143': 76,
    '144': 75.9,
    '145': 75.7,
    '146': 75.6,
    '147': 75.5,
    '148': 75.3,
    '149': 75.2,
    '150': 75,
    '151': 74.9,
    '152': 74.7,
    '153': 74.6,
    '154': 74.5,
    '155': 74.3,
    '156': 74.2,
    '157': 74,
    '158': 73.9,
    '159': 73.8,
    '160': 73.6,
    '161': 73.5,
    '162': 73.3,
    '163': 73.2,
    '164': 73.1,
    '165': 72.9,
    '166': 72.8,
    '167': 72.6,
    '168': 72.5,
    '169': 72.4,
    '170': 72.2,
    '171': 72.1,
    '172': 72,
    '173': 71.8,
    '174': 71.7,
    '175': 71.6,
    '176': 71.4,
    '177': 71.3,
    '178': 71.2,
    '179': 71,
    '180': 70.9,
    '181': 70.8,
    '182': 70.6,
    '183': 70.5,
    '184': 70.4,
    '185': 70.2,
    '186': 70.1,
    '187': 70,
    '188': 69.9,
    '189': 69.7,
    '190': 69.6,
    '191': 69.5,
    '192': 69.3,
    '193': 69.2,
    '194': 69.1,
    '195': 69,
    '6.5': 100,
    '7.5': 99.8,
    '8.5': 99.6,
    '9.5': 99.4,
    '10.5': 99.1,
    '11.5': 98.9,
    '12.5': 98.7,
    '13.5': 98.5,
    '14.5': 98.3,
    '15.5': 98.1,
    '16.5': 97.9,
    '17.5': 97.7,
    '18.5': 97.5,
    '19.5': 97.3,
    '20.5': 97.1,
    '21.5': 96.9,
    '22.5': 96.7,
    '23.5': 96.5,
    '24.5': 96.3,
    '25.5': 96.1,
    '26.5': 95.9,
    '27.5': 95.7,
    '28.5': 95.5,
    '29.5': 95.3,
    '30.5': 95.1,
    '31.5': 94.9,
    '32.5': 94.7,
    '33.5': 94.5,
    '34.5': 94.3,
    '35.5': 94.1,
    '36.5': 93.9,
    '37.5': 93.7,
    '38.5': 93.5,
    '39.5': 93.3,
    '40.5': 93.1,
    '41.5': 92.9,
    '42.5': 92.8,
    '43.5': 92.6,
    '44.5': 92.4,
    '45.5': 92.2,
    '46.5': 92,
    '47.5': 91.8,
    '48.5': 91.6,
    '49.5': 91.4,
    '50.5': 91.2,
    '51.5': 91.1,
    '52.5': 90.9,
    '53.5': 90.7,
    '54.5': 90.5,
    '55.5': 90.3,
    '56.5': 90.1,
    '57.5': 90,
    '58.5': 89.8,
    '59.5': 89.6,
    '60.5': 89.4,
    '61.5': 89.2,
    '62.5': 89,
    '63.5': 88.9,
    '64.5': 88.7,
    '65.5': 88.5,
    '66.5': 88.3,
    '67.5': 88.2,
    '68.5': 88,
    '69.5': 87.8,
    '70.5': 87.6,
    '71.5': 87.4,
    '72.5': 87.3,
    '73.5': 87.1,
    '74.5': 86.9,
    '75.5': 86.7,
    '76.5': 86.6,
    '77.5': 86.4,
    '78.5': 86.2,
    '79.5': 86.1,
    '80.5': 85.9,
    '81.5': 85.7,
    '82.5': 85.5,
    '83.5': 85.4,
    '84.5': 85.2,
    '85.5': 85,
    '86.5': 84.9,
    '87.5': 84.7,
    '88.5': 84.5,
    '89.5': 84.4,
    '90.5': 84.2,
    '91.5': 84,
    '92.5': 83.9,
    '93.5': 83.7,
    '94.5': 83.5,
    '95.5': 83.4,
    '96.5': 83.2,
    '97.5': 83,
    '98.5': 82.9,
    '99.5': 82.7,
    '100.5': 82.6,
    '101.5': 82.4,
    '102.5': 82.2,
    '103.5': 82.1,
    '104.5': 81.9,
    '105.5': 81.7,
    '106.5': 81.6,
    '107.5': 81.4,
    '108.5': 81.3,
    '109.5': 81.1,
    '110.5': 81,
    '111.5': 80.8,
    '112.5': 80.6,
    '113.5': 80.5,
    '114.5': 80.3,
    '115.5': 80.2,
    '116.5': 80,
    '117.5': 79.9,
    '118.5': 79.7,
    '119.5': 79.5,
    '120.5': 79.4,
    '121.5': 79.2,
    '122.5': 79.1,
    '123.5': 78.9,
    '124.5': 78.8,
    '125.5': 78.6,
    '126.5': 78.5,
    '127.5': 78.3,
    '128.5': 78.2,
    '129.5': 78,
    '130.5': 77.9,
    '131.5': 77.7,
    '132.5': 77.6,
    '133.5': 77.4,
    '134.5': 77.3,
    '135.5': 77.1,
    '136.5': 77,
    '137.5': 76.8,
    '138.5': 76.7,
    '139.5': 76.5,
    '140.5': 76.4,
    '141.5': 76.2,
    '142.5': 76.1,
    '143.5': 76,
    '144.5': 75.8,
    '145.5': 75.7,
    '146.5': 75.5,
    '147.5': 75.4,
    '148.5': 75.2,
    '149.5': 75.1,
    '150.5': 74.9,
    '151.5': 74.8,
    '152.5': 74.7,
    '153.5': 74.5,
    '154.5': 74.4,
    '155.5': 74.2,
    '156.5': 74.1,
    '157.5': 74,
    '158.5': 73.8,
    '159.5': 73.7,
    '160.5': 73.5,
    '161.5': 73.4,
    '162.5': 73.3,
    '163.5': 73.1,
    '164.5': 73,
    '165.5': 72.9,
    '166.5': 72.7,
    '167.5': 72.6,
    '168.5': 72.4,
    '169.5': 72.3,
    '170.5': 72.2,
    '171.5': 72,
    '172.5': 71.9,
    '173.5': 71.8,
    '174.5': 71.6,
    '175.5': 71.5,
    '176.5': 71.4,
    '177.5': 71.2,
    '178.5': 71.1,
    '179.5': 71,
    '180.5': 70.8,
    '181.5': 70.7,
    '182.5': 70.6,
    '183.5': 70.4,
    '184.5': 70.3,
    '185.5': 70.2,
    '186.5': 70,
    '187.5': 69.9,
    '188.5': 69.8,
    '189.5': 69.7,
    '190.5': 69.5,
    '191.5': 69.4,
    '192.5': 69.3,
    '193.5': 69.1,
    '194.5': 69,
  },
  '90': {
    '10': 99.8,
    '11': 99.6,
    '12': 99.4,
    '13': 99.2,
    '14': 99,
    '15': 98.8,
    '16': 98.6,
    '17': 98.4,
    '18': 98.1,
    '19': 97.9,
    '20': 97.7,
    '21': 97.5,
    '22': 97.3,
    '23': 97.1,
    '24': 96.9,
    '25': 96.7,
    '26': 96.5,
    '27': 96.3,
    '28': 96.1,
    '29': 95.9,
    '30': 95.7,
    '31': 95.5,
    '32': 95.3,
    '33': 95.1,
    '34': 94.9,
    '35': 94.7,
    '36': 94.5,
    '37': 94.3,
    '38': 94.1,
    '39': 94,
    '40': 93.8,
    '41': 93.6,
    '42': 93.4,
    '43': 93.2,
    '44': 93,
    '45': 92.8,
    '46': 92.6,
    '47': 92.4,
    '48': 92.2,
    '49': 92,
    '50': 91.9,
    '51': 91.7,
    '52': 91.5,
    '53': 91.3,
    '54': 91.1,
    '55': 90.9,
    '56': 90.7,
    '57': 90.6,
    '58': 90.4,
    '59': 90.2,
    '60': 90,
    '61': 89.8,
    '62': 89.6,
    '63': 89.5,
    '64': 89.3,
    '65': 89.1,
    '66': 88.9,
    '67': 88.7,
    '68': 88.6,
    '69': 88.4,
    '70': 88.2,
    '71': 88,
    '72': 87.8,
    '73': 87.7,
    '74': 87.5,
    '75': 87.3,
    '76': 87.1,
    '77': 87,
    '78': 86.8,
    '79': 86.6,
    '80': 86.4,
    '81': 86.3,
    '82': 86.1,
    '83': 85.9,
    '84': 85.8,
    '85': 85.6,
    '86': 85.4,
    '87': 85.2,
    '88': 85.1,
    '89': 84.9,
    '90': 84.7,
    '91': 84.6,
    '92': 84.4,
    '93': 84.2,
    '94': 84.1,
    '95': 83.9,
    '96': 83.7,
    '97': 83.6,
    '98': 83.4,
    '99': 83.2,
    '100': 83.1,
    '101': 82.9,
    '102': 82.8,
    '103': 82.6,
    '104': 82.4,
    '105': 82.3,
    '106': 82.1,
    '107': 82,
    '108': 81.8,
    '109': 81.6,
    '110': 81.5,
    '111': 81.3,
    '112': 81.2,
    '113': 81,
    '114': 80.8,
    '115': 80.7,
    '116': 80.5,
    '117': 80.4,
    '118': 80.2,
    '119': 80.1,
    '120': 79.9,
    '121': 79.7,
    '122': 79.6,
    '123': 79.4,
    '124': 79.3,
    '125': 79.1,
    '126': 79,
    '127': 78.8,
    '128': 78.7,
    '129': 78.5,
    '130': 78.4,
    '131': 78.2,
    '132': 78.1,
    '133': 77.9,
    '134': 77.8,
    '135': 77.6,
    '136': 77.5,
    '137': 77.3,
    '138': 77.2,
    '139': 77,
    '140': 76.9,
    '141': 76.7,
    '142': 76.6,
    '143': 76.4,
    '144': 76.3,
    '145': 76.1,
    '146': 76,
    '147': 75.9,
    '148': 75.7,
    '149': 75.6,
    '150': 75.4,
    '151': 75.3,
    '152': 75.1,
    '153': 75,
    '154': 74.9,
    '155': 74.7,
    '156': 74.6,
    '157': 74.4,
    '158': 74.3,
    '159': 74.2,
    '160': 74,
    '161': 73.9,
    '162': 73.7,
    '163': 73.6,
    '164': 73.5,
    '165': 73.3,
    '166': 73.2,
    '167': 73,
    '168': 72.9,
    '169': 72.8,
    '170': 72.6,
    '171': 72.5,
    '172': 72.4,
    '173': 72.2,
    '174': 72.1,
    '175': 72,
    '176': 71.8,
    '177': 71.7,
    '178': 71.6,
    '179': 71.4,
    '180': 71.3,
    '181': 71.1,
    '182': 71,
    '183': 70.9,
    '184': 70.8,
    '185': 70.6,
    '186': 70.5,
    '187': 70.4,
    '188': 70.2,
    '189': 70.1,
    '190': 70,
    '191': 69.8,
    '192': 69.7,
    '193': 69.6,
    '194': 69.4,
    '195': 69.3,
    '9.5': 99.9,
    '10.5': 99.7,
    '11.5': 99.5,
    '12.5': 99.3,
    '13.5': 99.1,
    '14.5': 98.9,
    '15.5': 98.7,
    '16.5': 98.5,
    '17.5': 98.3,
    '18.5': 98,
    '19.5': 97.8,
    '20.5': 97.6,
    '21.5': 97.4,
    '22.5': 97.2,
    '23.5': 97,
    '24.5': 96.8,
    '25.5': 96.6,
    '26.5': 96.4,
    '27.5': 96.2,
    '28.5': 96,
    '29.5': 95.8,
    '30.5': 95.6,
    '31.5': 95.4,
    '32.5': 95.2,
    '33.5': 95,
    '34.5': 94.8,
    '35.5': 94.6,
    '36.5': 94.4,
    '37.5': 94.2,
    '38.5': 94,
    '39.5': 93.9,
    '40.5': 93.7,
    '41.5': 93.5,
    '42.5': 93.3,
    '43.5': 93.1,
    '44.5': 92.9,
    '45.5': 92.7,
    '46.5': 92.5,
    '47.5': 92.3,
    '48.5': 92.1,
    '49.5': 91.9,
    '50.5': 91.8,
    '51.5': 91.6,
    '52.5': 91.4,
    '53.5': 91.2,
    '54.5': 91,
    '55.5': 90.8,
    '56.5': 90.6,
    '57.5': 90.5,
    '58.5': 90.3,
    '59.5': 90.1,
    '60.5': 89.9,
    '61.5': 89.7,
    '62.5': 89.5,
    '63.5': 89.4,
    '64.5': 89.2,
    '65.5': 89,
    '66.5': 88.8,
    '67.5': 88.6,
    '68.5': 88.5,
    '69.5': 88.3,
    '70.5': 88.1,
    '71.5': 87.9,
    '72.5': 87.8,
    '73.5': 87.6,
    '74.5': 87.4,
    '75.5': 87.2,
    '76.5': 87.1,
    '77.5': 86.9,
    '78.5': 86.7,
    '79.5': 86.5,
    '80.5': 86.4,
    '81.5': 86.2,
    '82.5': 86,
    '83.5': 85.8,
    '84.5': 85.7,
    '85.5': 85.5,
    '86.5': 85.3,
    '87.5': 85.2,
    '88.5': 85,
    '89.5': 84.8,
    '90.5': 84.7,
    '91.5': 84.5,
    '92.5': 84.3,
    '93.5': 84.2,
    '94.5': 84,
    '95.5': 83.8,
    '96.5': 83.7,
    '97.5': 83.5,
    '98.5': 83.3,
    '99.5': 83.2,
    '100.5': 83,
    '101.5': 82.8,
    '102.5': 82.7,
    '103.5': 82.5,
    '104.5': 82.4,
    '105.5': 82.2,
    '106.5': 82,
    '107.5': 81.9,
    '108.5': 81.7,
    '109.5': 81.5,
    '110.5': 81.4,
    '111.5': 81.2,
    '112.5': 81.1,
    '113.5': 80.9,
    '114.5': 80.8,
    '115.5': 80.6,
    '116.5': 80.4,
    '117.5': 80.3,
    '118.5': 80.1,
    '119.5': 80,
    '120.5': 79.8,
    '121.5': 79.7,
    '122.5': 79.5,
    '123.5': 79.4,
    '124.5': 79.2,
    '125.5': 79.1,
    '126.5': 78.9,
    '127.5': 78.8,
    '128.5': 78.6,
    '129.5': 78.4,
    '130.5': 78.3,
    '131.5': 78.1,
    '132.5': 78,
    '133.5': 77.8,
    '134.5': 77.7,
    '135.5': 77.5,
    '136.5': 77.4,
    '137.5': 77.3,
    '138.5': 77.1,
    '139.5': 77,
    '140.5': 76.8,
    '141.5': 76.7,
    '142.5': 76.5,
    '143.5': 76.4,
    '144.5': 76.2,
    '145.5': 76.1,
    '146.5': 75.9,
    '148.5': 75.6,
    '147.5': 75.8,
    '149.5': 75.5,
    '150.5': 75.4,
    '151.5': 75.2,
    '152.5': 75.1,
    '153.5': 74.9,
    '154.5': 74.8,
    '155.5': 74.6,
    '156.5': 74.5,
    '157.5': 74.4,
    '158.5': 74.2,
    '159.5': 74.1,
    '160.5': 73.9,
    '161.5': 73.8,
    '162.5': 73.7,
    '163.5': 73.5,
    '164.5': 73.4,
    '165.5': 73.2,
    '166.5': 73.1,
    '167.5': 73,
    '168.5': 72.8,
    '169.5': 72.7,
    '170.5': 72.6,
    '171.5': 72.4,
    '172.5': 72.3,
    '173.5': 72.2,
    '174.5': 72,
    '175.5': 71.9,
    '176.5': 71.7,
    '177.5': 71.6,
    '178.5': 71.5,
    '179.5': 71.4,
    '180.5': 71.2,
    '181.5': 71.1,
    '182.5': 71,
    '183.5': 70.8,
    '184.5': 70.7,
    '185.5': 70.6,
    '186.5': 70.4,
    '187.5': 70.3,
    '188.5': 70.2,
    '189.5': 70,
    '190.5': 69.9,
    '191.5': 69.8,
    '192.5': 69.6,
    '193.5': 69.5,
    '194.5': 69.4,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const returnCorrectedGravity = (gravity: string, temperature: string): null | number => {
  if (gravity != null && temperature != null) {
    let roundedGravity = Math.round(parseFloat(gravity) * 2) / 2
    let roundedTemperature = Math.round(parseFloat(temperature) * 2) / 2
    if (
      correctedGravityLookupTable != null &&
      correctedGravityLookupTable[roundedGravity.toString()] != null &&
      correctedGravityLookupTable[roundedGravity.toString()][roundedTemperature.toString()] != null
    ) {
      return correctedGravityLookupTable[roundedGravity.toString()][roundedTemperature.toString()]
    } else {
      return null
    }
  } else {
    return null
  }
}
