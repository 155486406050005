//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Min and Max on numeric submission
			[ ] QR Code return could direct them to a temp response database location that they can fill out that will be wiped upon formal submission???
				[ ] Generate random key for them?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, CircularProgress, Divider, Typography } from '@mui/material/'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import {
  returnCleanFormDataReadyForSubmission,
  returnFormInputsFromDatabaseDataFormat,
  returnStartingFormDataFromFormattedFormInputs,
} from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { rJSX_DynamicApplicationLogoSvg } from 'rfbp_aux/components/dynamic_app_branding'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Form, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_AN_UNKNOWN_ERROR_HAS_OCCURED: JSX.Element = <Trans>An unknown error has occured</Trans>
const s_APP_REQUIRED: JSX.Element = <Trans>App Required</Trans>
const s_APP_STORE: JSX.Element = <Trans>App Store</Trans>
const s_CONFIRMATION_CODE: JSX.Element = <Trans>Confirmation Code</Trans>
const s_CONTACT_SUPPORT_IF_THIS_ERROR_PERSISTS: JSX.Element = <Trans>Contact support if this error persists</Trans>
const s_DOWNLOAD_THE_SECURE_DELIVERY_APP_AND_SCAN_THE_CODE_AGAIN: JSX.Element = <Trans>Download the secure delivery app and scan the code again</Trans>
const s_ERROR: JSX.Element = <Trans>Error</Trans>
const s_FORM_SUBMITTED: JSX.Element = <Trans>Form Submitted</Trans>
const s_GOOGLE_PLAY: JSX.Element = <Trans>Google Play</Trans>
const s_LOADING_FORM: JSX.Element = <Trans>Loading Form</Trans>
const s_SUBMIT: JSX.Element = <Trans>Submit</Trans>
const s_SUBMITTING_FORM: JSX.Element = <Trans>Submitting Form</Trans>
// { sort-end } - displayed text

// Form Properties

const formSettings_QRCode: TsInterface_FormSettings = {
  submit_button_theme: 'info',
  submit_button_alignment: 'right',
  submit_button_hide: false,
  submit_button_icon: (
    <Icon
      icon="paper-plane"
      sx={{ fontSize: '20px' }}
      className="tw-mr-1"
    />
  ),
  submit_button_saving_icon: true,
  submit_button_text: <Box sx={{ fontSize: '20px' }}>{s_SUBMIT}</Box>,
  highlight_missing: true,
}

let workflowCss: string = `
		@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');
	`

const getDeviceType = () => {
  const userAgent = navigator.userAgent
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'apple'
  } else if (/Android/i.test(userAgent)) {
    return 'android'
  } else if (/Macintosh|Mac OS X/i.test(userAgent)) {
    return 'apple'
  } else if (/Windows/i.test(userAgent)) {
    return 'pc'
  } else {
    return 'other'
  }
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const qrCodeKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_qrCodeData, us_setQrCodeData ] = 								useState<TsInterface_UnspecifiedObject>({})
  const [us_deviceType, us_setDeviceType] = useState<string>(getDeviceType())
  const [us_errorData, us_setErrorData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData, us_setFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formInputs, us_setFormInputs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_qrCopdeDataLoadStatus, us_setQrCopdeDataLoadStatus] = useState<string>('start') // start, error, require_app, form, submitting, success
  const [us_submissionResults, us_setSubmissionResults] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    us_setDeviceType(getDeviceType())
    return () => {}
  }, [])

  useEffect(() => {
    if (qrCodeKey != null && us_qrCopdeDataLoadStatus === 'start') {
      cloudFunctionUnauthenticatedRequests({
        function: 'get_qr_code_data',
        qr_code_key: qrCodeKey,
      })
        .then((res_CFUR) => {
          let qrCodeData = getProp(res_CFUR, 'data', {})

          console.log(qrCodeData)

          // us_setQrCodeData( qrCodeData )
          // Generate Form
          if (qrCodeData != null && qrCodeData['form_fields'] != null) {
            let formFields = returnFormInputsFromDatabaseDataFormat(qrCodeData['form_fields'], qrCodeData['client_key'])
            us_setFormInputs(formFields)
            us_setFormData(returnStartingFormDataFromFormattedFormInputs(formFields))
          }
          if (qrCodeData != null && qrCodeData.qr_code != null && qrCodeData.qr_code.require_app === true) {
            us_setQrCopdeDataLoadStatus('require_app')
          } else {
            us_setQrCopdeDataLoadStatus('form')
          }
        })
        .catch((rej_CFUR) => {
          if (rej_CFUR != null && rej_CFUR.response != null && rej_CFUR.response.data != null && rej_CFUR.response.data.error != null) {
            us_setErrorData(rej_CFUR.response.data.error)
          }
          us_setQrCopdeDataLoadStatus('error')
        })
    }
    return () => {}
  }, [qrCodeKey, us_qrCopdeDataLoadStatus])

  // Functions
  const formOnChange_QRCode: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    // Nothing
  }

  const formSubmission_QRCode: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    return new Promise((resolve, reject) => {
      // Hit endpoint with qrCodeKey and formSubmittedData, other stuff from initial endpoint data load
      us_setQrCopdeDataLoadStatus('submitting')
      cloudFunctionUnauthenticatedRequests({
        function: 'save_qr_code_data',
        qr_code_key: qrCodeKey,
        form_data: returnCleanFormDataReadyForSubmission(us_formData, us_formInputs),
        metadata: {
          // TODO: Add metadata
          // Lat, Lng, IP, etc
        },
      })
        .then((res_CFUR) => {
          us_setSubmissionResults(getProp(res_CFUR, 'data', {}))
          us_setQrCopdeDataLoadStatus('success')
          ur_forceRerender()
          resolve({ success: true })
        })
        .catch((rej_CFUR) => {
          us_setQrCopdeDataLoadStatus('form')
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFUR.error })
          ur_forceRerender()
          reject({ success: false })
        })
    })
  }

  // Call Functions

  // JSX Generation
  const rJSX_StartContent = (): JSX.Element => {
    let contentJSX = (
      <Box className="tw-text-center">
        <Box
          className="tw-mb-6"
          sx={{ paddingTop: '30vh' }}
        >
          {rJSX_DynamicApplicationLogoSvg('80px', {})}
          {/* <LogoSecureDelivery
            height="80px"
            l_color={themeVariables.warning_main}
            s_color={themeVariables.navGradientTopColor}
            d_color={themeVariables.navGradientTopColor}
          /> */}
        </Box>
        <CircularProgress
          color="error"
          size={50}
        />
        <Typography
          variant="h6"
          className="tw-mt-6"
        >
          {s_LOADING_FORM}
        </Typography>
      </Box>
    )
    return contentJSX
  }

  const rJSX_ErrorContent = (): JSX.Element => {
    let contentJSX = (
      <Box
        className="tw-text-center"
        sx={{ marginTop: '30vh' }}
      >
        <Box className="tw-mb-6">
          <Typography
            sx={{
              fontSize: '50px',
              color: themeVariables.error_main,
              fontFamily: "'Roboto Slab', serif",
            }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{
                fontSize: '44px',
              }}
            />
            {s_ERROR}
          </Typography>
          <style>{workflowCss}</style>
          <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
            <Typography variant="h5">{getProp(us_errorData, 'message', s_AN_UNKNOWN_ERROR_HAS_OCCURED)}</Typography>
            <Typography variant="h6">{getProp(us_errorData, 'details', s_CONTACT_SUPPORT_IF_THIS_ERROR_PERSISTS)}</Typography>
            <Typography className="tw-opacity-30 tw-mt-2">{getProp(us_errorData, 'code', 'ER-D-QECF-UKN-01')}</Typography>
          </Box>
        </Box>
      </Box>
    )
    return contentJSX
  }

  // TODO - button links
  const rJSX_RequireAppContent = (): JSX.Element => {
    let appStoreIconJSX = <></>
    let appTextColor = themeVariables.info_main
    let appStoreLinksJSX = <></>
    if (us_deviceType === 'apple') {
      appStoreIconJSX = <i className="fa-brands fa-apple tw-mr-2"></i>
      appStoreLinksJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '24px' }}
            onClick={() => {
              // TODO: Open app store link
            }}
          >
            <i className="fa-brands fa-app-store-ios tw-mr-2"></i>
            {s_APP_STORE}
          </Button>
        </Box>
      )
    } else if (us_deviceType === 'android') {
      appTextColor = themeVariables.success_main
      appStoreIconJSX = <i className="fa-brands fa-android tw-mr-2"></i>
      appStoreLinksJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '24px' }}
            onClick={() => {
              // TODO: Open app store link
            }}
          >
            <i className="fa-brands fa-google-play tw-mr-2"></i>
            {s_GOOGLE_PLAY}
          </Button>
        </Box>
      )
    } else {
      appStoreIconJSX = (
        <Icon
          icon="mobile"
          className="tw-mr-2"
        />
      )
      appStoreLinksJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '18px' }}
            className="tw-mr-2 tw-mb-2"
            onClick={() => {
              // TODO: Open app store link
            }}
            startIcon={<i className="fa-brands fa-app-store-ios"></i>}
          >
            {s_APP_STORE}
          </Button>
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '18px' }}
            className="tw-mb-2"
            onClick={() => {
              // TODO: Open app store link
            }}
            startIcon={<i className="fa-brands fa-google-play"></i>}
          >
            {s_GOOGLE_PLAY}
          </Button>
        </Box>
      )
    }
    // Full JSX
    let contentJSX = (
      <Box
        className="tw-text-center"
        sx={{ marginTop: '30vh' }}
      >
        <Box className="tw-mb-6">
          <Typography
            sx={{
              fontSize: '40px',
              color: appTextColor,
              fontFamily: "'Roboto Slab', serif",
            }}
          >
            {appStoreIconJSX}
            {s_APP_REQUIRED}
          </Typography>
          <style>{workflowCss}</style>
          <Box
            sx={{ maxWidth: '400px', margin: 'auto' }}
            className="tw-mt-2"
          >
            <Typography variant="h5">{s_DOWNLOAD_THE_SECURE_DELIVERY_APP_AND_SCAN_THE_CODE_AGAIN}</Typography>
            {appStoreLinksJSX}
          </Box>
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_FormContent = (): JSX.Element => {
    let contentJSX = <></>
    contentJSX = (
      <Box className="tw-p-4">
        <Card
          className="tw-p-2 tw-px-4 tw-m-auto"
          sx={{ maxWidth: '800px' }}
        >
          <Box>
            {rJSX_DynamicApplicationLogoSvg('48px', {})}
            {/* <LogoSecureDelivery
              height="48px"
              l_color={themeVariables.warning_main}
              s_color={themeVariables.navGradientTopColor}
              d_color={themeVariables.navGradientTopColor}
            /> */}
            <Divider />
          </Box>
          <Form
            formData={us_formData}
            formAdditionalData={{}}
            formInputs={us_formInputs}
            formSettings={formSettings_QRCode}
            formOnChange={formOnChange_QRCode}
            formSubmission={formSubmission_QRCode}
          />
        </Card>
      </Box>
    )
    return contentJSX
  }

  const rJSX_SubmittingContent = (): JSX.Element => {
    let contentJSX = (
      <Box className="tw-text-center">
        <Box sx={{ paddingTop: '30vh' }}>
          {rJSX_DynamicApplicationLogoSvg('80px', {})}
          {/* <LogoSecureDelivery
            height="80px"
            l_color={themeVariables.warning_main}
            s_color={themeVariables.navGradientTopColor}
            d_color={themeVariables.navGradientTopColor}
          /> */}
          <CircularProgress
            color="error"
            size={50}
          />
          <Typography
            variant="h6"
            className="tw-mt-6"
          >
            {s_SUBMITTING_FORM}
          </Typography>
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_SuccessContent = (): JSX.Element => {
    let contentJSX = (
      <Box
        className="tw-text-center"
        sx={{ marginTop: '30vh' }}
      >
        <Box className="tw-mb-6">
          <Typography
            sx={{
              fontSize: '40px',
              color: themeVariables.success_main,
              fontFamily: "'Roboto Slab', serif",
            }}
          >
            <Icon
              icon="paper-plane"
              className="tw-mr-2"
              sx={{
                fontSize: '34px',
              }}
            />
            {s_FORM_SUBMITTED}
          </Typography>
          <style>{workflowCss}</style>
          <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
            <Typography variant="h5">
              {s_CONFIRMATION_CODE}:
              <Box
                className="tw-inline-block tw-ml-1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {getProp(us_submissionResults, 'confirmation_number', s_ERROR)}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <></>
    if (us_qrCopdeDataLoadStatus === 'start') {
      pageJSX = rJSX_StartContent()
    } else if (us_qrCopdeDataLoadStatus === 'error') {
      pageJSX = rJSX_ErrorContent()
    } else if (us_qrCopdeDataLoadStatus === 'require_app') {
      pageJSX = rJSX_RequireAppContent()
    } else if (us_qrCopdeDataLoadStatus === 'form') {
      pageJSX = rJSX_FormContent()
    } else if (us_qrCopdeDataLoadStatus === 'submitting') {
      pageJSX = rJSX_SubmittingContent()
    } else if (us_qrCopdeDataLoadStatus === 'success') {
      pageJSX = rJSX_SuccessContent()
    }
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
