//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			User Authentication contains all functions related to managing user authentication and identification

		TODO:
			[ ] Typescript - 1 instance of any
			[ ] Typescript - 9 instances of unknown

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  ActionCodeSettings,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { DatabaseRef_GlobalUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints' // OUTSIDE BOILERPLATE
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_GlobalUser } from 'rfbp_core/services/context'
import { DatabaseGetDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_GenericPromiseReject, TsInterface_GenericPromiseResolve, TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { cloudFunctionManageRequest } from '../cloud_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_UpdateUserClientKeyProperResult {
  error: object
  success: boolean
}

interface TsInterface_GetClientKeyResult {
  clientKey: string
  error: object
  success: boolean
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const getDomainFromEmail = (email: string): string => {
  // Split the email string at the '@' symbol to separate the local part and domain part
  const parts = email.split('@')
  // If the email format is valid and has two parts (local part and domain part)
  if (parts.length === 2) {
    // The domain part will be the second element in the 'parts' array
    return parts[1]
  } else {
    // Return null or throw an error for invalid email formats
    return ''
  }
}

///////////////////////////////
// Exports
///////////////////////////////

// Authentication
export const checkIfAuthenticated = (): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          resolve({ success: true, authenticated: true }) // User is authenticated
        } else {
          resolve({ success: true, authenticated: false }) // User is not authenticated
        }
      },
      (error) => {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to check authentication'),
            details: error.message,
            code: 'ER-D-SUA-CIA-01',
          },
        })
      },
    )
  })
}

export const authWithPassword = (email: string, password: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then((res_SIWEAP) => {
        const user = res_SIWEAP.user
        resolve({
          success: true,
          user: user,
        })
      })
      .catch((rej_SIWEAP) => {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Log In'),
            details: rej_SIWEAP.message,
            code: 'ER-D-SUA-AWP-01',
          },
        })
      })
  })
}

export const sendAuthLinkToEmail = (email: string, actionCodeSettings: ActionCodeSettings): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then((res_SSILTE) => {
        window.localStorage.setItem('emailForSignIn', email)
        // let email = window.localStorage.getItem('emailForSignIn')
        resolve({ success: true })
      })
      .catch((rej_SSILTE) => {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to send sign in link to email'),
            details: rej_SSILTE.message,
            code: 'ER-D-SUA-SAILTE-01',
          },
        })
      })
  })
}

export const logOut = (): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    signOut(auth)
      .then((res_SO) => {
        resolve({ success: true })
      })
      .catch((rej_SO) => {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Log Out'),
            details: rej_SO.message,
            code: 'ER-D-SUA-AWP-01',
          },
        })
      })
  })
}

export const sendPasswordResetToEmail = (email: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    sendPasswordResetEmail(auth, email)
      .then((res_SPRE) => {
        resolve({ success: true })
      })
      .catch((rej_SPRE) => {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to send password reset email'),
            details: rej_SPRE.message,
            code: 'ER-D-SUA-SPRTE-01',
          },
        })
      })
  })
}

export const waitForAuthenticationVerification = (): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve({ success: true, authenticated: true, userKey: user.uid })
      } else {
        reject({ success: false, authenticated: false, userKey: null })
      }
    })
  })
}

// Google Auth
export const authWithGoogle = (restrictedDomains: null | TsInterface_UnspecifiedObject, restrictedDomainErrorMessage: null | JSX.Element) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((res_SIWP) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential: any = GoogleAuthProvider.credentialFromResult(res_SIWP);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = res_SIWP.user
        // IdP data available using getAdditionalUserInfo(res_SIWP)
        let userEmail = getProp(user, 'email', null)

        if (
          restrictedDomains == null ||
          (userEmail != null &&
            getDomainFromEmail(userEmail) != null &&
            getDomainFromEmail(userEmail) !== '' &&
            restrictedDomains[getDomainFromEmail(userEmail)] === true)
        ) {
          resolve({
            success: true,
            user: user,
          })
        } else {
          let errorMessage = rLIB('Unauthorized Domain')
          if (restrictedDomainErrorMessage != null) {
            errorMessage = restrictedDomainErrorMessage
          }
          reject({
            success: false,
            error: {
              message: rLIB('Failed to Log In'),
              details: errorMessage,
              code: 'ER-D-SUA-AWG-01',
            },
          })
        }
      })
      .catch((rej_SIWP) => {
        // Handle Errors here.
        const errorCode = rej_SIWP.code
        const errorMessage = rej_SIWP.message
        // The email of the user's account used.
        const email = rej_SIWP.customData.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(rej_SIWP)
        // ...
        console.log(errorCode)
        console.log(errorMessage)
        console.log(email)
        console.log(credential)
        reject({
          success: false,
          error: {
            message: rLIB('Failed to Log In'),
            details: errorMessage,
            code: 'ER-D-SUA-AWG-02',
          },
        })
      })
  })
}

// Permissions and IDs
export const getClientKey = (
  clientKeyCachedInContext: string | null,
  callbackToSetContext: (value: string) => void,
): Promise<TsInterface_GetClientKeyResult> => {
  return new Promise((resolve, reject) => {
    if (clientKeyCachedInContext != null) {
      resolve({ success: true, clientKey: clientKeyCachedInContext, error: {} })
    } else {
      const auth = getAuth()
      onAuthStateChanged(auth, (user) => {
        if (user) {
          let databasePromiseArray = []
          let globalUser: TsInterface_RootData_GlobalUser = {
            authorized_clients: {},
            client_key: null,
            key: null,
            super: false,
            user_role: null,
          }
          databasePromiseArray.push(
            DatabaseGetDocument(DatabaseRef_GlobalUser_Document(user.uid))
              .then((res_DGD: any) => {
                globalUser = res_DGD.data
              })
              .catch((rej_DGD) => {
                console.error(rej_DGD)
              }),
          )
          Promise.all(databasePromiseArray).finally(() => {
            if (globalUser.client_key != null && globalUser.client_key !== '') {
              if (callbackToSetContext != null && typeof callbackToSetContext === 'function') {
                callbackToSetContext(globalUser.client_key as string)
              }
              resolve({ success: true, clientKey: globalUser.client_key, error: {} })
            } else {
              reject({
                success: false,
                clientKey: '',
                error: {
                  message: rLIB('Failed to get client key'),
                  details: rLIB('Failed to get user authentication details'),
                  code: 'ER-D-SUA-AWP-01',
                },
              })
            }
          })
        } else {
          reject({
            success: false,
            clientKey: '',
            error: {
              message: rLIB('Failed to get client key'),
              details: rLIB('Failed to get user authentication details'),
              code: 'ER-D-SUA-AWP-02',
            },
          })
        }
      })
    }
  })
}

export const updateUserClientKey = (clientKey: string, rootGlobalUser: TsInterface_RootData_GlobalUser): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    if (rootGlobalUser.super === true) {
      updateUserClientKeyProper(rootGlobalUser.key as string, clientKey)
        .then((res_UUCKP: TsInterface_GenericPromiseResolve) => {
          resolve(res_UUCKP)
        })
        .catch((rej_UUCKP: TsInterface_GenericPromiseReject) => {
          reject(rej_UUCKP)
        })
    } else {
      cloudFunctionManageRequest('manageUser', { function: 'updateUserClientKey', client_key: clientKey })
        .then((res_CFMUR: unknown) => {
          resolve(res_CFMUR as TsInterface_GenericPromiseResolve)
        })
        .catch((rej_CFMUR: unknown) => {
          reject(rej_CFMUR)
        })
    }
  })
}

export const updateUserClientKeyProper = (userKey: string, clientKey: string): Promise<TsInterface_UpdateUserClientKeyProperResult> => {
  // TODO - verify that the user has the rights to access the specified clientKey

  return new Promise((resolve, reject) => {
    if (userKey != null && clientKey != null) {
      let updateObject = {
        client_key: clientKey,
      }
      DatabaseSetMergeDocument(DatabaseRef_GlobalUser_Document(userKey), updateObject)
        .then((res_DSMD: TsInterface_GenericPromiseResolve) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD: TsInterface_GenericPromiseReject) => {
          reject(rej_DSMD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to update user client key'),
          details: rLIB('Missing required parameters'),
          code: 'ER-S-S-UM-UUCK-01',
        },
      })
    }
  })
}
