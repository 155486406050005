///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:
			TIMEZONES

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Trans } from 'react-i18next'
import {
  DatabaseRef_LookupTablesDateRangesData_Document,
  DatabaseRef_LookupTablesSpecificActiveDateRange_Query,
  DatabaseRef_LookupTable_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/lookup_tables'
import { DatabaseGetCollection, DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
const s_IDENTICAL_LOOKUP: JSX.Element = <Trans>Identical Lookup</Trans>
const s_RANGE_LOOKUP: JSX.Element = <Trans>Range Lookup</Trans>
const s_MATRIX_LOOKUP: JSX.Element = <Trans>Matrix Lookup</Trans>
const s_FAILED_TO_RETURN_LOOKUP_VALUE: JSX.Element = <Trans>Failed to return lookup value</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_NO_LOOKUP_TABLE_DATA_FOR_SPECIFIED_DATE: JSX.Element = <Trans>No lookup table data for specified date</Trans>
const s_FAILED_TO_FIND_LOOKUP_VALUE_ON_TABLE: JSX.Element = <Trans>Failed to find lookup value on table</Trans>

///////////////////////////////
// Functions
///////////////////////////////

function sortArrayOfArraysByIndex(arr: any[], index: number) {
  if (!Array.isArray(arr) || !Number.isInteger(index) || arr.some((subArr) => !Array.isArray(subArr))) {
    throw new Error('Invalid input. The first argument must be an array of arrays, and the second argument must be an integer.')
  }
  arr.sort((a, b) => {
    if (a[index] < b[index]) {
      return -1
    }
    if (a[index] > b[index]) {
      return 1
    }
    return 0
  })
}

function createTableData(data: TsInterface_UnspecifiedObject) {
  let rowVariables: any = new Set()
  let colVariables: any = new Set()
  // Populate rowVariables and colVariables with unique values
  for (let key in data) {
    const [rowVar, colVar] = key.split('~')
    rowVariables.add(rowVar)
    colVariables.add(colVar)
  }
  // Sort rowVariables and colVariables alphabetically
  rowVariables = Array.from(rowVariables).sort()
  colVariables = Array.from(colVariables).sort()
  // Create the table data
  const tableData = []
  // Add the header row (first row)
  tableData.push(['', ...colVariables])
  // Add the remaining rows
  for (const rowVar of rowVariables) {
    const row = [rowVar]
    for (const colVar of colVariables) {
      const key = `${rowVar}~${colVar}`
      const value = data[key] ? data[key] : ''
      row.push(value)
    }
    tableData.push(row)
  }
  return tableData
}

export const lookupTableTypeOptions: TsInterface_UnspecifiedObject = {
  identical_lookup: {
    key: 'identical_lookup',
    value: s_IDENTICAL_LOOKUP,
  },
  range_lookup: {
    key: 'range_lookup',
    value: s_RANGE_LOOKUP,
  },
  matrix_lookup: {
    key: 'matrix_lookup',
    value: s_MATRIX_LOOKUP,
  },
}

export const lookupTableExampleDownloads: TsInterface_UnspecifiedObject = {
  identical_lookup: {
    name: 'Identical Lookup - US States',
    example_data: [
      ['AL', 'Alabama'],
      ['AK', 'Alaska'],
      ['AZ', 'Arizona'],
      ['AR', 'Arkansas'],
      ['CA', 'California'],
      ['CO', 'Colorado'],
      ['CT', 'Connecticut'],
      ['DE', 'Delaware'],
      ['FL', 'Florida'],
      ['GA', 'Georgia'],
      ['HI', 'Hawaii'],
      ['ID', 'Idaho'],
      ['IL', 'Illinois'],
      ['IN', 'Indiana'],
      ['IA', 'Iowa'],
      ['KS', 'Kansas'],
      ['KY', 'Kentucky'],
      ['LA', 'Louisiana'],
      ['ME', 'Maine'],
      ['MD', 'Maryland'],
      ['MA', 'Massachusetts'],
      ['MI', 'Michigan'],
      ['MN', 'Minnesota'],
      ['MS', 'Mississippi'],
      ['MO', 'Missouri'],
      ['MT', 'Montana'],
      ['NE', 'Nebraska'],
      ['NV', 'Nevada'],
      ['NH', 'New Hampshire'],
      ['NJ', 'New Jersey'],
      ['NM', 'New Mexico'],
      ['NY', 'New York'],
      ['NC', 'North Carolina'],
      ['ND', 'North Dakota'],
      ['OH', 'Ohio'],
      ['OK', 'Oklahoma'],
      ['OR', 'Oregon'],
      ['PA', 'Pennsylvania'],
      ['RI', 'Rhode Island'],
      ['SC', 'South Carolina'],
      ['SD', 'South Dakota'],
      ['TN', 'Tennessee'],
      ['TX', 'Texas'],
      ['UT', 'Utah'],
      ['VT', 'Vermont'],
      ['VA', 'Virginia'],
      ['WA', 'Washington'],
      ['WV', 'West Virginia'],
      ['WI', 'Wisconsin'],
      ['WY', 'Wyoming'],
    ],
  },
  range_lookup: {
    name: 'Range Lookup - Credit Scores',
    example_data: [
      [300, 580, 'Poor'],
      [580, 670, 'Fair'],
      [670, 740, 'Good'],
      [740, 800, 'Very Good'],
      [800, 850, 'Exceptional'],
    ],
  },
  matrix_lookup: {
    name: 'Matrix Lookup - Eisenhower Matrix',
    example_data: [
      ['important', 'urgent', 'do it'],
      ['important', 'not urgent', 'schedule it'],
      ['not important', 'urgent', 'delegate it'],
      ['not important', 'not urgent', 'delete it'],
    ],
  },
}

export const returnDataInRenderTableFormat = (lookupTableType: string, databaseDataObject: TsInterface_UnspecifiedObject) => {
  let outputArray: TsInterface_UnspecifiedObject[] = []
  switch (lookupTableType) {
    case 'identical_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        outputArray.push({ lookup: loopDataRowKey, result: databaseDataObject[loopDataRowKey] })
      }
      outputArray.sort(dynamicSort('lookup', 'asc'))
      break
    case 'range_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        let splitIndex = loopDataRowKey.indexOf('~')
        let lookupStart = parseFloat(loopDataRowKey.substring(0, splitIndex))
        let lookupEnd = parseFloat(loopDataRowKey.substring(splitIndex + 1))
        outputArray.push({ lookup_start: lookupStart, lookup_end: lookupEnd, result: databaseDataObject[loopDataRowKey] })
      }
      outputArray.sort(dynamicSort('lookup_start', 'asc'))
      break
    case 'matrix_lookup':
      outputArray = createTableData(databaseDataObject)
      break
  }
  return outputArray
}

export const returnDataAsDownloadableArray = (lookupTableType: string, databaseDataObject: TsInterface_UnspecifiedObject) => {
  let outputArray: any = []
  switch (lookupTableType) {
    case 'identical_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        outputArray.push([loopDataRowKey, databaseDataObject[loopDataRowKey]])
      }
      sortArrayOfArraysByIndex(outputArray, 0)
      break
    case 'range_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        let splitIndex = loopDataRowKey.indexOf('~')
        let lookupStart = loopDataRowKey.substring(0, splitIndex)
        let lookupEnd = loopDataRowKey.substring(splitIndex + 1)
        outputArray.push([lookupStart, lookupEnd, databaseDataObject[loopDataRowKey]])
      }
      sortArrayOfArraysByIndex(outputArray, 0)
      break
    case 'matrix_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        let splitIndex = loopDataRowKey.indexOf('~')
        let lookupStart = loopDataRowKey.substring(0, splitIndex)
        let lookupEnd = loopDataRowKey.substring(splitIndex + 1)
        outputArray.push([lookupStart, lookupEnd, databaseDataObject[loopDataRowKey]])
      }
      sortArrayOfArraysByIndex(outputArray, 0)
      break
  }
  return outputArray
}

export const returnSearchValueFromLookupTable = (
  clientKey: string,
  lookupTableKey: string,
  searchDate: Date,
  queryParam1: string | number,
  queryParam2: string | number | null,
) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && lookupTableKey != null) {
      // Get Base Lookup Table
      DatabaseGetDocument(DatabaseRef_LookupTable_Document(clientKey, lookupTableKey))
        .then((getDocumentResult) => {
          let lookupTable = getDocumentResult.data
          let lookupTableType = getProp(lookupTable, 'table_type', null)
          // Query Lookup by provided date
          DatabaseGetCollection(DatabaseRef_LookupTablesSpecificActiveDateRange_Query(clientKey, lookupTableKey, searchDate))
            .then((getCollectionResult) => {
              if (getCollectionResult != null && getCollectionResult.data != null && objectToArray(getCollectionResult.data).length === 1) {
                let queriedDateRange = objectToArray(getCollectionResult.data)[0]
                DatabaseGetDocument(DatabaseRef_LookupTablesDateRangesData_Document(clientKey, lookupTableKey, queriedDateRange.key))
                  .then((getDocumentResult2) => {
                    let lookupData = getDocumentResult2.data
                    let foundLookupValue = false
                    let lookupValue: any = null
                    switch (lookupTableType) {
                      case 'identical_lookup':
                        if (lookupData != null && queryParam1 != null && lookupData[queryParam1] != null) {
                          foundLookupValue = true
                          lookupValue = lookupData[queryParam1]
                        }
                        break
                      case 'range_lookup':
                        // eslint-disable-next-line no-case-declarations
                        let lookupTableData = returnDataInRenderTableFormat(lookupTableType, lookupData)
                        for (let loopIndex in lookupTableData) {
                          let loopRow = lookupTableData[loopIndex]
                          if (
                            loopRow.lookup_start != null &&
                            loopRow.lookup_end != null &&
                            queryParam1 != null &&
                            // @ts-expect-error
                            !isNaN(parseFloat(queryParam1)) &&
                            // @ts-expect-error
                            loopRow.lookup_start <= parseFloat(queryParam1) &&
                            // @ts-expect-error
                            parseFloat(queryParam1) < loopRow.lookup_end
                          ) {
                            foundLookupValue = true
                            lookupValue = loopRow.result
                          }
                        }
                        break
                      case 'matrix_lookup':
                        if (
                          queryParam1 != null &&
                          queryParam2 != null &&
                          lookupData != null &&
                          lookupData[queryParam1.toString() + '~' + queryParam2.toString()] != null
                        ) {
                          foundLookupValue = true
                          lookupValue = lookupData[queryParam1.toString() + '~' + queryParam2.toString()]
                        }
                        break
                    }
                    if (foundLookupValue === true) {
                      resolve({ success: true, value: lookupValue })
                    } else {
                      reject({
                        success: false,
                        error: {
                          message: s_FAILED_TO_RETURN_LOOKUP_VALUE,
                          details: s_FAILED_TO_FIND_LOOKUP_VALUE_ON_TABLE,
                          code: 'ER-D-LTD-RSVFLT-01',
                        },
                      })
                    }
                  })
                  .catch((getDocumentReject2) => {
                    reject(getDocumentReject2)
                  })
              } else {
                reject({
                  success: false,
                  error: {
                    message: s_FAILED_TO_RETURN_LOOKUP_VALUE,
                    details: s_NO_LOOKUP_TABLE_DATA_FOR_SPECIFIED_DATE,
                    code: 'ER-D-LTD-RSVFLT-02',
                  },
                })
              }
            })
            .catch((getCollectionReject) => {
              reject(getCollectionReject)
            })
        })
        .catch((getDocumentReject) => {
          reject(getDocumentReject)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_RETURN_LOOKUP_VALUE,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-LTD-RSVFLT-03',
        },
      })
    }
  })
}
