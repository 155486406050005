///////////////////////////////
// Imports
///////////////////////////////

import { collection, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Scheduled Emails
// export const DatabaseRef_GlobalScheduledEmails_Collection =
// (): CollectionReference< DocumentData > => {
// return collection( getFirestore(), "global_emails", "scheduled", "main" ) }

export const DatabaseRef_GlobalScheduledEmail_Document = (emailKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'global_emails', 'scheduled', 'main', emailKey)
}

export const DatabaseRef_CronJobScheduledEmailQueue_Query = (): Query<unknown> => {
  let currentDate = new Date()
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    // { prop: "schedule_status", comparator: "==", value: "in_queue" },
    { prop: 'timestamp_email_scheduled', comparator: '<=', value: currentDate },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global_emails', 'scheduled', 'main'), queryOperatorsArray, orderByArray, {}, 100)
}

// Recurring Emails
// export const DatabaseRef_GlobalRecurringEmails_Collection =
// (): CollectionReference< DocumentData > => {
// return collection( getFirestore(), "global_emails", "recurring", "main" ) }

export const DatabaseRef_GlobalRecurringEmail_Document = (emailKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'global_emails', 'recurring', 'main', emailKey)
}

export const DatabaseRef_CronJobRecurringEmailQueue_Query = (queryType: string, oneDayAgo: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'schedule_status', comparator: '==', value: 'in_queue' },
    { prop: 'query_date_range_type', comparator: '==', value: queryType },
    { prop: 'timestamp_last_email', comparator: '<=', value: oneDayAgo },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global_emails', 'recurring', 'main'), queryOperatorsArray, orderByArray, {}, 100)
}

// Reconciliation
// export const DatabaseRef_GlobalRecurringReconciliationEmail_Document = (emailKey: string): DocumentReference<DocumentData> => {
//   return doc(getFirestore(), 'global_emails', 'automatic_reconciliations', 'main', emailKey)
// }

// export const DatabaseRef_CronJobRecurringReconciliationEmailQueue_Query = (queryType: string, oneDayAgo: Date): Query<unknown> => {
//   let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
//     { prop: 'schedule_status', comparator: '==', value: 'in_queue' },
//     // { prop: "query_date_range_type", comparator: "==", value: queryType },
//     { prop: 'timestamp_last_email', comparator: '<=', value: oneDayAgo },
//   ]
//   let orderByArray: TsInterface_OrderByArray = []
//   return generateDatabaseQuery(collection(getFirestore(), 'global_emails', 'automatic_reconciliations', 'main'), queryOperatorsArray, orderByArray, {}, 100)
// }
