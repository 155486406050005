///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			QR Code creation
				- create global qr code with server function
				- return key
				- create local qr code with key

			QR Code management
				- associations are like tags, can have multiple of any directory category
					- associated_location_keys: { locationKey: true }
					- associated_location_names: { locationKey: locationName }
					- mostly used for filtering

			Maybe change directory locations to be associations and you can create an association "type" (location, purchaser, producer etc)
			Then on QR code you choose the association type and then the association key from the list?
			Noit sure if there is a need to have different types of associations, maybe just one type of association is fine

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { cloudFunctionManageQRCodeRequest } from 'app/services/external_requests/external_requests'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_DataBuckets_Collection, DatabaseRef_DataBuckets_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_PromptDialog } from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataBucketsListPage']['key']

// Table
const tableSettings_QRCode: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_QRCode: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.DataBucketsViewPage.url(rowData.key as string))
        }
      },
    },
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Rename')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'success',
              confirm_text: rLIB('Save'),
              default_value: null,
              header: rLIB('Rename Data Bucket'),
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: rLIB('Data Bucket Name'),
              input_type: 'text',
              text: <></>,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        name: promptValue,
                      }
                      DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Data Bucket'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this Data Bucket?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  // TEMP_icon: {
  // header: {
  // 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
  // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <></> },
  // 	header_sort_by: null
  // },
  // cell: {
  // 	cell_css: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData
  // 	) => { return "" },
  // 	cell_jsx: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData,
  // 		tableHooks: TsInterface_TableHooks
  // 	) => {
  // 		let cellJSX = <></>
  // 		if( rowData.locked === true ){
  // 			cellJSX =
  // 			<Icon
  // 				icon="lock"
  // 				type="solid"
  // 				sx={{ color: themeVariables.warning_main, fontSize: "18px" }}
  // 				tooltip={ s_LOCKED }
  // 				tooltipPlacement='right'
  // 			/>
  // 		} else {
  // 			cellJSX =
  // 			<Icon
  // 				icon="play"
  // 				type="solid"
  // 				sx={{ color: themeVariables.success_main, fontSize: "18px" }}
  // 				tooltip={ s_ACTIVE }
  // 				tooltipPlacement='right'
  // 			/>
  // 		}
  // 		return cellJSX
  // 	},
  // },
  // },
  name: TableCellBasic('name', rLIB('Data Bucket Name'), 'name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Date Created'), 'timestamp_created', ' D MMM YYYY - h:mm a', true),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Data Buckets', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const create = (): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: rLIB('Create New Data Bucket') as JSX.Element,
        default_value: null,
        header: rLIB('New Data Bucket'),
        icon: (
          <Icon
            icon="pen-to-square"
            type="solid"
          />
        ),
        input_label: rLIB('Data Bucket Name') as JSX.Element,
        input_type: 'text',
        text: <></>,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                cloudFunctionManageQRCodeRequest({
                  function: 'createGlobalQRCode',
                  client_key: res_GCK.clientKey,
                  name: promptValue,
                })
                  .then((res_CFMQRCR: any) => {
                    un_routerNavigation(ApplicationPages.DataBucketsViewPage.url(res_CFMQRCR.key))
                    resolve(res_CFMQRCR)
                  })
                  .catch((rej_CFMQRCR) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMQRCR.error })
                    reject(rej_CFMQRCR)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const tableDatabaseEndpoint_QRCode = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_DataBuckets_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          create()
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Data Bucket')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_QRCodeTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_QRCode}
              tableDatabaseEndpoint={tableDatabaseEndpoint_QRCode}
              tableSettings={tableSettings_QRCode}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  // Call Functions

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Data Buckets')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_NewButton()}</Box>
            <Box className="tw-mt-2">{rJSX_QRCodeTable()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
