///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

const appVersionString: string = '0.0.1'

///////////////////////////////
// Exports
///////////////////////////////

export const returnAppVersionNumber = (): number => {
  return parseInt(appVersionString.replaceAll('.', ''))
}

export const returnAppVersionString = (): string => {
  return appVersionString
}
