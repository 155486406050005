///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_UnauthenticatedQRCodeData_File = (clientKey: string, imageKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/data/unauthenticated_qr_code_data/' + imageKey + '/' + fileName)
}

export const StorageRef_AuthenticatedQRCodeData_File = (clientKey: string, imageKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/data/authenticated_qr_code_data/' + imageKey + '/' + fileName)
}
