//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Main home page for authenticated users

		TODO:
			[ ] Auto navigate to proper page based on user role

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Card, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { getAuth, linkWithCredential, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientUserRolesFormOptions } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UserEmailInvites_Query } from 'rfbp_aux/services/database_endpoints/global_workspace_invites'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnStandardizedUSPhoneNumber } from 'rfbp_core/services/helper_functions'
import { getClientKey, updateUserClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_GenericPromiseReject, TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_WorkspaceInviteDialog {
  workspaceInvitation: TsInterface_UnspecifiedObject
}

declare global {
  interface Window {
    recaptchaVerifier: any
  }
}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['HomePage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_HOME: JSX.Element = <Trans>Home</Trans>
const se_HOME: string = 'Home'
// { sort-end } - displayed text

// Auth
const auth = getAuth()

///////////////////////////////
// Functions
///////////////////////////////

const ScheduledEmailFormDialog: React.FC<TsInterface_WorkspaceInviteDialog> = ({ workspaceInvitation }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_submittingResponse, us_setSubmittingResponse] = useState<boolean>(false)
  const [us_submittingResponseType, us_setSubmittingResponseType] = useState<string | null>(null)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.info_main }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="paper-plane" />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{ flexGrow: 1, color: themeVariables.white }}
              >
                {rLIB('Respond to Workspace Invitation')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography variant="h6">
              {rLIB("You've been invited to join a workspace by")}{' '}
              {getProp(workspaceInvitation, 'associated_owner_name', getProp(workspaceInvitation, 'associated_invitee_email', rLIB('a workspace owner')))}
            </Typography>
            <Box sx={{ marginTop: '16px' }}>
              <Button
                color="success"
                variant="contained"
                startIcon={
                  us_submittingResponse && us_submittingResponseType === 'accept' ? (
                    <Icon
                      icon="arrows-rotate"
                      className="bp_spin"
                    />
                  ) : (
                    <Icon icon="circle-check" />
                  )
                }
                disabled={us_submittingResponse === true}
                sx={{ marginRight: '16px' }}
                onClick={() => {
                  us_setSubmittingResponseType('accept')
                  us_setSubmittingResponse(true)
                  cloudFunctionManageRequest('manageUser', {
                    function: 'acceptWorkspaceInvite',
                    invite_key: workspaceInvitation.key,
                    invitee_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
                  })
                    .then((res_CFMUR) => {
                      us_setSubmittingResponse(false)
                      uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    })
                    .catch((rej_CFMUR) => {
                      us_setSubmittingResponse(false)
                      uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    })
                }}
              >
                {rLIB('Accept Invitation')}
              </Button>
              <Button
                color="error"
                variant="contained"
                startIcon={
                  us_submittingResponse && us_submittingResponseType === 'reject' ? (
                    <Icon
                      icon="arrows-rotate"
                      className="bp_spin"
                    />
                  ) : (
                    <Icon icon="ban" />
                  )
                }
                disabled={us_submittingResponse === true}
                onClick={() => {
                  us_setSubmittingResponseType('reject')
                  us_setSubmittingResponse(true)
                  cloudFunctionManageRequest('manageUser', {
                    function: 'turnDownWorkspaceInvite',
                    invite_key: workspaceInvitation.key,
                    invitee_user_key: getProp(uc_RootData_GlobalUser, 'key', null),
                  })
                    .then((res_CFMUR) => {
                      us_setSubmittingResponse(false)
                      uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    })
                    .catch((rej_CFMUR) => {
                      us_setSubmittingResponse(false)
                      uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    })
                }}
              >
                {rLIB('Turn Down Invitation')}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_loadedInvitationDialog, us_setLoadedInvitationDialog] = useState<boolean>(false)
  const [us_allClients, us_setAllClients] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tileCount, us_setTileCount] = useState<number>(0)
  const [us_loadedClientsList, us_setLoadedClientsList] = useState<boolean>(false)
  const [us_visibleHomeTiles, us_setVisibleHomeTiles] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_HOME
  }, [])

  useEffect(() => {
    if (us_loadedClientsList === false) {
      if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.key != null) {
        cloudFunctionManageRequest('manageUser', {
          function: 'getAvailableClientsList',
          user_key: uc_RootData_GlobalUser.key,
        })
          .then((res_CFMUR: unknown) => {
            us_setAllClients(getProp(res_CFMUR, 'clientToggleOptions', {}))
            us_setLoadedClientsList(true)
          })
          .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
            console.error(rej_CFMUR)
          })
      }
    }
  }, [uc_RootData_GlobalUser, us_loadedClientsList])

  useEffect(() => {
    let tileCount = 0
    let visibleHomeTiles: TsInterface_UnspecifiedObject = {
      // finish_profile: 1,
      // invite_user_to_workspace: 2,
      // request_access_to_workspace: 3,
      // contact_sales: 4
      // toggle_between_clients: 5
    }
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser['key'] != null) {
      // Profile Tile
      if (
        getProp(uc_RootData_GlobalUser, 'name', null) == null ||
        getProp(uc_RootData_GlobalUser, 'phone', null) == null ||
        getProp(uc_RootData_GlobalUser, 'email', null) == null
      ) {
        tileCount++
        visibleHomeTiles['finish_profile'] = tileCount
      }
      // Invite User Tile
      if (getProp(uc_RootData_GlobalUser, 'user_role', null) === 'owner') {
        tileCount++
        visibleHomeTiles['invite_user_to_workspace'] = tileCount
      }
      // Request Access Tile
      tileCount++
      visibleHomeTiles['request_access_to_workspace'] = tileCount
      // Sales Tile / Toggle Workspace Tile
      let totalAvailableWorkspaces = 0
      if (getProp(uc_RootData_GlobalUser, 'client_access', null) != null) {
        for (let loopClientKey in getProp(uc_RootData_GlobalUser, 'client_access', {})) {
          let loopClientUserRole = getProp(uc_RootData_GlobalUser, 'client_access', {})[loopClientKey]
          if (loopClientKey !== 'unaffiliated_users') {
            if (loopClientUserRole != null) {
              totalAvailableWorkspaces++
            }
          }
        }
      }
      if (totalAvailableWorkspaces === 0) {
        // tileCount++
        // visibleHomeTiles['contact_sales'] = tileCount
      } else if (totalAvailableWorkspaces === 1) {
        // TODO: Auto set active workspace to the only one available IF it's not the same as the current active workspace
      } else {
        tileCount++
        visibleHomeTiles['toggle_between_clients'] = tileCount
      }
    }

    us_setVisibleHomeTiles(visibleHomeTiles)
    us_setTileCount(tileCount)
  }, [uc_RootData_GlobalUser])

  useEffect(() => {
    if (uc_RootData_GlobalUser != null && getProp(uc_RootData_GlobalUser, 'email', null) != null) {
      if (us_loadedInvitationDialog == false) {
        DatabaseGetCollection(DatabaseRef_UserEmailInvites_Query(getProp(uc_RootData_GlobalUser, 'email', null)))
          .then((res_DGC) => {
            if (objectToArray(res_DGC.data).length > 0) {
              let workspaceInvitation = objectToArray(res_DGC.data)[0]
              us_setLoadedInvitationDialog(true)
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: <ScheduledEmailFormDialog workspaceInvitation={workspaceInvitation} />,
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      }
    }
  }, [uc_RootData_GlobalUser, uc_setUserInterface_CustomDialogDisplay, us_loadedInvitationDialog])

  useEffect(() => {
    let initializeRecaptcha: any = null
    try {
      initializeRecaptcha = () => {
        if (!window.recaptchaVerifier) {
          try {
            window.recaptchaVerifier = new RecaptchaVerifier(
              'recaptcha-container',
              {
                'size': 'invisible',
                'callback': (response: any) => {
                  // reCAPTCHA solved - will proceed with submit
                },
                'expired-callback': () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                  window.recaptchaVerifier.reset()
                },
              },
              auth,
            )
          } catch (error) {
            console.error('Error initializing reCAPTCHA verifier:', error)
          }
        }
      }
      // Ensure the DOM is fully loaded before initializing reCAPTCHA
      if (document.readyState === 'complete') {
        initializeRecaptcha()
      } else {
        window.addEventListener('load', initializeRecaptcha)
      }
    } catch (err) {
      console.error(err)
    }
    // Remove the event listener when the component is unmounted
    return () => {
      if (initializeRecaptcha != null) {
        window.removeEventListener('load', initializeRecaptcha)
      }
    }
  }, [])

  useEffect(() => {
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser['key'] != null) {
      let cloudFunctionRequestParams: TsInterface_UnspecifiedObject = {
        function: 'checkForUnlinkedDuplicateAccounts',
        email: getProp(uc_RootData_GlobalUser, 'email', null),
        phone: getProp(uc_RootData_GlobalUser, 'phone', null),
      }
      cloudFunctionManageRequest('manageUser', cloudFunctionRequestParams)
        .then((res_CFMUR: any) => {
          if (
            res_CFMUR != null &&
            res_CFMUR['email_account_key'] != null &&
            res_CFMUR['phone_account_key'] != null &&
            res_CFMUR['email_account_key'] !== res_CFMUR['phone_account_key']
          ) {
            console.log('====================================')
            console.log('User has unlinked duplicate accounts')
            // TODO: Show modal to have user link phone and email accounts
            // } else if (
            //   res_CFMUR != null &&
            //   res_CFMUR['email_account_key'] != null &&
            //   res_CFMUR['phone_account_key'] != null &&
            //   res_CFMUR['email_account_key'] === res_CFMUR['phone_account_key']
            // ) {
            //   console.log('====================================')
            //   console.log('User accounts linked')
          }
        })
        .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
          console.error(rej_CFMUR)
        })
    }
    // Remove the event listener when the component is unmounted
    return () => {}
  }, [uc_RootData_GlobalUser])

  // Functions
  const inviteUserToWorkspace = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            header1: {
              data_type: 'string',
              key: 'header1',
              input_type: 'custom_form_input_jsx',
              label: <></>,
              required: false,
              renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                let inputJSX = (
                  <Box className="tw-text-left tw-mt-0">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700 }}
                      className="tw-mt-0"
                    >
                      {rLIB('Enter the email address of the person you want to invite to your workspace.')}
                    </Typography>
                  </Box>
                )
                return inputJSX
              },
            },
            email: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'email',
              label: <>{rLIB('Email')}</>,
              required: true,
            },
            invitee_user_role: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'invitee_user_role',
              label: <>{rLIB('User Role')}</>,
              options: ClientUserRolesFormOptions,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_theme: 'success',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  cloudFunctionManageRequest('manageUser', {
                    function: 'inviteUserToWorkspace',
                    // requestor_uid: uc_RootData_GlobalUser.key,
                    // owner_email: formSubmittedData.email,
                    client_key: res_GCK.clientKey,
                    owner_email: getProp(uc_RootData_GlobalUser, 'email', null),
                    owner_name: getProp(uc_RootData_GlobalUser, 'name', null),
                    invitee_email: formSubmittedData.email,
                    invitee_user_role: formSubmittedData.invitee_user_role,
                    // invitee_access_level: formSubmittedData.invitee_user_role,
                  })
                    .then((res_CFMUR: unknown) => {
                      resolve({ success: true, error: {} })
                      formHooks.uc_setUserInterface_AlertDialogDisplay({
                        display: true,
                        alert: {
                          color: 'success',
                          header: rLIB('Request sent successfully'),
                          icon: <Icon icon="paper-plane" />,
                          text: rLIB('We will let you know when we get a response'),
                        },
                      })
                    })
                    .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
                      reject(rej_CFMUR)
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Invite a user to your workspace')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="user-plus"
            />
          ),
        },
      },
    })
  }

  const changeClientKey = () => {
    let clientKeyOptions: TsInterface_UnspecifiedObject[] = []
    for (let loopClientKey in us_allClients) {
      let loopClient = us_allClients[loopClientKey]
      if (loopClientKey !== 'unaffiliated_users') {
        clientKeyOptions.push({
          key: loopClientKey,
          value: loopClient['name'],
        })
      }
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            client_key: uc_RootData_GlobalUser.client_key,
          },
          formInputs: {
            client_key: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'client_key',
              label: <>{rLIB('Select the workspace you want to view')}</>,
              submit_on_change: true,
              required: true,
              options: clientKeyOptions,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_theme: 'error',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              // console.log(formSubmittedData)
              updateUserClientKey(formSubmittedData.client_key, uc_RootData_GlobalUser)
                .then((res_UUCK) => {
                  resolve(res_UUCK)
                })
                .catch((rej_UUCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UUCK.error })
                  reject(rej_UUCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'error',
          formDialogHeaderText: <>{rLIB('Change your workspace')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="list-radio"
            />
          ),
        },
      },
    })
  }

  function verifyPhoneNumber(phoneNumber: string, recaptchaVerifier: any) {
    return new Promise((resolve1, reject1) => {
      const provider = new PhoneAuthProvider(auth)
      const authUser = auth.currentUser
      provider.verifyPhoneNumber(phoneNumber, recaptchaVerifier).then((verificationId) => {
        uc_setUserInterface_PromptDialogDisplay({
          display: true,
          prompt: {
            color: 'success',
            confirm_text: rLIB('Confirm') as JSX.Element,
            default_value: null,
            header: rLIB('Phone Verification'),
            icon: (
              <Icon
                icon="pen-to-square"
                type="solid"
              />
            ),
            input_label: rLIB('Access Code') as JSX.Element,
            input_type: 'text',
            text: rLIB('Please enter the verification code sent to your phone'),
            submit_callback: (promptValue: string) => {
              return new Promise((resolve, reject) => {
                if (authUser != null) {
                  let verificationCode = promptValue
                  const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode)
                  linkWithCredential(authUser, phoneCredential)
                    .then((res_LWC) => {
                      let cloudFunctionRequestParams: TsInterface_UnspecifiedObject = {
                        function: 'updateUserAccount',
                        user_key: uc_RootData_GlobalUser.key,
                        phone: phoneNumber,
                      }
                      cloudFunctionManageRequest('manageUser', cloudFunctionRequestParams)
                        .then((res_CFMUR: unknown) => {
                          resolve({ success: true, error: {} })
                          resolve1({ success: true, error: {} })
                          uc_setUserInterface_AlertDialogDisplay({
                            display: true,
                            alert: {
                              color: 'warning',
                              header: rLIB('Profile updated successfully') as JSX.Element,
                              icon: <Icon icon="floppy-disk" />,
                              text: rLIB('Phone has been updated') as JSX.Element,
                            },
                          })
                        })
                        .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
                          reject(rej_CFMUR)
                          reject1(rej_CFMUR)
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
                        })
                    })
                    .catch((rej_LWC) => {
                      console.error(rej_LWC)
                      if (rej_LWC.code === 'auth/account-exists-with-different-credential') {
                        // TODO: figure out how to link these up

                        let error = {
                          code: 'ER-D-H-FPP-01',
                          message: rLIB('Failed to link phone number'),
                          details: rLIB('This phone number is linked to a different account. Please contact support to get this resolved'),
                        }
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                        reject({ success: false, error: error })
                        reject1({ success: false, error: error })
                      } else {
                        let error = {
                          code: 'ER-D-H-FPP-01',
                          message: rLIB('Failed to link phone number'),
                          details: rLIB('Please contact support if this issue persists'),
                        }
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                        reject({ success: false, error: error })
                        reject1({ success: false, error: error })
                      }
                    })
                } else {
                  let error = {
                    code: 'ER-D-H-FPP-02',
                    message: rLIB('Expired authentication session'),
                    details: rLIB('Please try again after logging in again'),
                  }
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                  reject({ success: false, error: error })
                  reject1({ success: false, error: error })
                }
              })
            },
          },
        })
      })
    })
  }

  // Works up to 9 tiles
  const returnGridSizes = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl', tileIndex: number) => {
    let returnSize = 4
    switch (size) {
      case 'xs':
        returnSize = 12
        break
      case 'sm':
        if (us_tileCount == 1) {
          returnSize = 12
        } else if (us_tileCount % 2 == 1 && tileIndex == us_tileCount) {
          returnSize = 12
        } else {
          returnSize = 6
        }
        break
      case 'md':
        if (us_tileCount == 1) {
          returnSize = 12
        } else if (us_tileCount % 2 == 1 && tileIndex == us_tileCount) {
          returnSize = 12
        } else {
          returnSize = 6
        }
        break
      case 'lg':
        if (us_tileCount == 1) {
          returnSize = 12
        } else if (us_tileCount == 2) {
          returnSize = 6
        } else if (us_tileCount == 4) {
          returnSize = 6
        } else if (us_tileCount == 5) {
          if (tileIndex > 3) {
            returnSize = 6
          } else {
            returnSize = 4
          }
        } else if (us_tileCount == 7) {
          if (tileIndex > 3) {
            returnSize = 6
          } else {
            returnSize = 4
          }
        } else if (us_tileCount == 8) {
          if (tileIndex > 6) {
            returnSize = 6
          } else {
            returnSize = 4
          }
        } else {
          returnSize = 4
        }
        break
      case 'xl':
        if (us_tileCount == 1) {
          returnSize = 12
        } else if (us_tileCount == 2) {
          returnSize = 6
        } else if (us_tileCount == 4) {
          returnSize = 6
        } else if (us_tileCount == 5) {
          if (tileIndex > 3) {
            returnSize = 6
          } else {
            returnSize = 4
          }
        } else if (us_tileCount == 7) {
          if (tileIndex > 3) {
            returnSize = 6
          } else {
            returnSize = 4
          }
        } else if (us_tileCount == 8) {
          if (tileIndex > 6) {
            returnSize = 6
          } else {
            returnSize = 4
          }
        } else {
          returnSize = 4
        }
        break
      default:
        returnSize = 4
        break
    }
    // us_tileCount
    return returnSize
  }

  const requestWorkspaceAccess = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            header1: {
              data_type: 'string',
              key: 'header1',
              input_type: 'custom_form_input_jsx',
              label: <></>,
              required: false,
              renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                let inputJSX = (
                  <Box className="tw-text-left tw-mt-0">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700 }}
                      className="tw-mt-0"
                    >
                      {rLIB('Enter the email address of the person who manages the workspace that you want access to')}
                    </Typography>
                  </Box>
                )
                return inputJSX
              },
            },
            email: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'email',
              label: <>{rLIB('Email')}</>,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_theme: 'info',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              cloudFunctionManageRequest('manageUser', {
                function: 'requestWorkspaceAccessFromOwner',
                requestor_uid: uc_RootData_GlobalUser.key,
                owner_email: formSubmittedData.email,
              })
                .then((res_CFMUR: unknown) => {
                  resolve({ success: true, error: {} })
                  formHooks.uc_setUserInterface_AlertDialogDisplay({
                    display: true,
                    alert: {
                      color: 'info',
                      header: rLIB('Request sent successfully'),
                      icon: <Icon icon="paper-plane" />,
                      text: rLIB('We will let you know when we get a response'),
                    },
                  })
                })
                .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
                  reject(rej_CFMUR)
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: <>{rLIB('Request access to a workspace')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="paper-plane"
            />
          ),
        },
      },
    })
  }

  const finishProfileName = () => {
    let finishProfileFormInputs: TsInterface_FormInputs = {
      name: {
        data_type: 'string',
        input_type: 'text_basic',
        key: 'name',
        label: <>{rLIB('Your Name')}</>,
        required: true,
      },
    }
    // Open the form dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: { ...uc_RootData_GlobalUser } as any,
          formInputs: finishProfileFormInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_theme: 'warning',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let cloudFunctionRequestParams: TsInterface_UnspecifiedObject = {
                    function: 'updateUserAccount',
                    user_key: uc_RootData_GlobalUser.key,
                    name: formSubmittedData.name,
                  }
                  cloudFunctionManageRequest('manageUser', cloudFunctionRequestParams)
                    .then((res_CFMUR: unknown) => {
                      resolve({ success: true, error: {} })
                      formHooks.uc_setUserInterface_AlertDialogDisplay({
                        display: true,
                        alert: {
                          color: 'warning',
                          header: rLIB('Profile updated successfully'),
                          icon: <Icon icon="floppy-disk" />,
                          text: rLIB('Name has been updated'),
                        },
                      })
                    })
                    .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
                      reject(rej_CFMUR)
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'warning',
          formDialogHeaderText: <>{rLIB('Finish your profile')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="user-plus"
            />
          ),
        },
      },
    })
  }

  const finishProfileEmail = () => {
    // TODO: Add email verification
    // // Open the form dialog
    // uc_setUserInterface_FormDialogDisplay({
    //   display: true,
    //   form: {
    //     form: {
    //       formAdditionalData: {},
    //       formData: { ...uc_RootData_GlobalUser } as any,
    //       formInputs: finishProfileFormInputs,
    //       formOnChange: (
    //         formAdditionalData: TsInterface_FormAdditionalData,
    //         formData: TsInterface_FormData,
    //         formInputs: TsInterface_FormInputs,
    //         formSettings: TsInterface_FormSettings,
    //       ) => {},
    //       formSettings: {
    //         submit_button_theme: 'warning',
    //       },
    //       formSubmission: (
    //         formSubmittedData: TsInterface_FormSubmittedData,
    //         formAdditionalData: TsInterface_FormAdditionalData,
    //         formHooks: TsInterface_FormHooksObject,
    //       ) => {
    //         return new Promise((resolve, reject) => {
    //           cloudFunctionManageRequest('manageUser', {
    //             function: 'addEmailToUserProfile',
    //             user_key: uc_RootData_GlobalUser.key,
    //             email_to_confirm: formSubmittedData.email,
    //           })
    //             .then((res_CFMUR: unknown) => {
    //               resolve({ success: true, error: {} })
    //               formHooks.uc_setUserInterface_AlertDialogDisplay({
    //                 display: true,
    //                 alert: {
    //                   color: 'info',
    //                   header: rLIB('Confirmation email sent successfully'),
    //                   icon: <Icon icon="paper-plane" />,
    //                   text: rLIB('Click the link in the email to confirm your email address'),
    //                 },
    //               })
    //             })
    //             .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
    //               reject(rej_CFMUR)
    //               formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
    //             })
    //         })
    //       },
    //     },
    //     dialog: {
    //       formDialogHeaderColor: 'warning',
    //       formDialogHeaderText: <>{rLIB('Finish your profile')}</>,
    //       formDialogIcon: (
    //         <Icon
    //           type="solid"
    //           icon="user-plus"
    //         />
    //       ),
    //     },
    //   },
    // })
  }

  const finishProfilePhone = () => {
    let finishProfileFormInputs: TsInterface_FormInputs = {
      phone: {
        data_type: 'string',
        input_type: 'text_basic',
        key: 'phone',
        label: <>{rLIB('Your Phone Number')}</>,
        required: true,
      },
    }
    // Open the form dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: { ...uc_RootData_GlobalUser } as any,
          formInputs: finishProfileFormInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_theme: 'warning',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let formattedPhoneNumber = returnStandardizedUSPhoneNumber(formSubmittedData.phone)
              if (formattedPhoneNumber !== 'Invalid phone number') {
                verifyPhoneNumber(formattedPhoneNumber, window.recaptchaVerifier)
                  .then((res_VPN) => {
                    resolve({ success: true, error: {} })
                  })
                  .catch((rej_VPN) => {
                    reject(rej_VPN)
                  })
              } else {
                let error = {
                  code: 'ER-D-H-FPP-01',
                  message: rLIB('Invalid phone number'),
                  details: rLIB('Please try again with a valid phone number'),
                }
                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                reject({ success: false, error: error })
              }
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'warning',
          formDialogHeaderText: <>{rLIB('Finish your profile')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="user-plus"
            />
          ),
        },
      },
    })
  }

  const contactSales = () => {
    // TODO:
  }

  // JSX Generation

  // TODO: MORE TILES

  // MOSTLY COMPLETE

  // [X] FINISH PROFILE
  // [X] REQUEST ACCESS
  // [X] SEND INVITE
  // [X] TOGGLE CLIENT KEY

  // [ ] WELCOME Tile? - Video that shows how secure delivery works (varies based on client type)
  // [ ] STATS Tile - IF Crude Oil client type = Stats Tile
  // [ ] SALES Tile - IF you have no workspaces, show a tile to contact sales
  // [ ] PENDING INVITES - IF you are the owner and have Pending Invitations that you have send and haven't been accepted
  // [ ] PENDING REQUESTS - IF you are the owner and have Pending Requests that you have received and haven't accepted
  // [ ] WAITING ON OWNER - IF you have send a request to join a workspace and it hasn't been accepted

  const rJSX_FinishProfileTile = (): JSX.Element => {
    let tileJSX = <></>
    let tileKey = 'finish_profile'
    let profileAlertJSX = <></>
    if (uc_RootData_GlobalUser != null && getProp(uc_RootData_GlobalUser, 'email', null) == null) {
      profileAlertJSX = (
        <Box className="tw-mt-2">
          <Typography
            variant="body1"
            sx={{ color: themeVariables.gray_700, lineHeight: '1.1' }}
            className="tw-mt-0"
          >
            <Icon
              icon="exclamation-triangle"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            {rLIB("You won't be able to see workspace invites until you finish setting up your profile")}
          </Typography>
        </Box>
      )
    }
    let nameButtonJSX = <></>
    if (uc_RootData_GlobalUser != null && getProp(uc_RootData_GlobalUser, 'name', null) == null) {
      nameButtonJSX = (
        <Button
          variant="contained"
          color="warning"
          sx={{ marginTop: '16px' }}
          className="tw-mx-1"
          onClick={() => {
            finishProfileName()
          }}
        >
          {rLIB('Add Name')}
        </Button>
      )
    }
    let emailButtonJSX = <></>
    if (uc_RootData_GlobalUser != null && getProp(uc_RootData_GlobalUser, 'email', null) == null) {
      emailButtonJSX = (
        <Button
          variant="contained"
          color="warning"
          sx={{ marginTop: '16px' }}
          className="tw-mx-1"
          // TODO: Add email verification
          disabled={true}
          onClick={() => {
            finishProfileEmail()
          }}
        >
          {rLIB('Add Email')}
        </Button>
      )
    }
    let phoneButtonJSX = <></>
    if (uc_RootData_GlobalUser != null && getProp(uc_RootData_GlobalUser, 'phone', null) == null) {
      phoneButtonJSX = (
        <Button
          variant="contained"
          color="warning"
          sx={{ marginTop: '16px' }}
          className="tw-mx-1"
          onClick={() => {
            finishProfilePhone()
          }}
        >
          {rLIB('Add Phone')}
        </Button>
      )
    }
    // Show the tile if the user has not finished setting up their profile
    if (us_visibleHomeTiles[tileKey] != null) {
      tileJSX = (
        <Grid2
          xs={returnGridSizes('xs', us_visibleHomeTiles[tileKey])}
          sm={returnGridSizes('sm', us_visibleHomeTiles[tileKey])}
          md={returnGridSizes('md', us_visibleHomeTiles[tileKey])}
          lg={returnGridSizes('lg', us_visibleHomeTiles[tileKey])}
          xl={returnGridSizes('xl', us_visibleHomeTiles[tileKey])}
        >
          <Card
            className="tw-p-4 tw-inline-block tw-w-full tw-rounded-xl"
            sx={{ minHeight: '280px' }}
          >
            <Icon
              icon="id-card"
              type="thin"
              className="tw-mb-4"
              sx={{ fontSize: '96px', color: themeVariables.warning_main }}
            />
            <Typography variant="h6">{rLIB("Let's finish setting up your profile")}</Typography>
            <Box>
              {nameButtonJSX}
              {emailButtonJSX}
              {phoneButtonJSX}
            </Box>
            <Box>{profileAlertJSX}</Box>
          </Card>
        </Grid2>
      )
    }
    return tileJSX
  }

  const rJSX_InviteUserToWorkspaceTile = (): JSX.Element => {
    let tileJSX = <></>
    let tileKey = 'invite_user_to_workspace'
    if (us_visibleHomeTiles[tileKey] != null) {
      tileJSX = (
        <Grid2
          xs={returnGridSizes('xs', us_visibleHomeTiles[tileKey])}
          sm={returnGridSizes('sm', us_visibleHomeTiles[tileKey])}
          md={returnGridSizes('md', us_visibleHomeTiles[tileKey])}
          lg={returnGridSizes('lg', us_visibleHomeTiles[tileKey])}
          xl={returnGridSizes('xl', us_visibleHomeTiles[tileKey])}
        >
          <Card
            className="tw-p-4 tw-inline-block tw-w-full tw-rounded-xl"
            sx={{ minHeight: '280px' }}
          >
            <Icon
              icon="user-plus"
              type="thin"
              className="tw-mb-4"
              sx={{ fontSize: '96px', color: themeVariables.success_main }}
            />
            <Typography variant="h6">{rLIB('Invite a user to your workspace')}</Typography>
            <Button
              variant="contained"
              color="success"
              sx={{ marginTop: '16px' }}
              onClick={() => {
                inviteUserToWorkspace()
              }}
            >
              {rLIB('Invite User')}
            </Button>
          </Card>
        </Grid2>
      )
    }
    return tileJSX
  }

  const rJSX_AskForAccessTile = (): JSX.Element => {
    let tileJSX = <></>
    let tileKey = 'request_access_to_workspace'
    let profileAlertJSX = <></>
    let disableButton = false
    if (uc_RootData_GlobalUser != null && getProp(uc_RootData_GlobalUser, 'email', null) == null) {
      disableButton = true
      profileAlertJSX = (
        <Box className="tw-mt-2">
          <Typography
            variant="body1"
            sx={{ color: themeVariables.gray_700, lineHeight: '1.1' }}
            className="tw-mt-0"
          >
            <Icon
              icon="exclamation-triangle"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            {rLIB("You can't ask for workspace access until you finish setting up your profile")}
          </Typography>
        </Box>
      )
    }
    if (us_visibleHomeTiles[tileKey] != null) {
      tileJSX = (
        <Grid2
          xs={returnGridSizes('xs', us_visibleHomeTiles[tileKey])}
          sm={returnGridSizes('sm', us_visibleHomeTiles[tileKey])}
          md={returnGridSizes('md', us_visibleHomeTiles[tileKey])}
          lg={returnGridSizes('lg', us_visibleHomeTiles[tileKey])}
          xl={returnGridSizes('xl', us_visibleHomeTiles[tileKey])}
        >
          <Card
            className="tw-p-4 tw-inline-block tw-w-full tw-rounded-xl"
            sx={{ minHeight: '280px' }}
          >
            <Icon
              icon="paper-plane"
              type="thin"
              className="tw-mb-4"
              sx={{ fontSize: '96px', color: themeVariables.info_main }}
            />
            <Typography variant="h6">{rLIB('Ask for access to a workspace')}</Typography>
            <Button
              variant="contained"
              color="info"
              sx={{ marginTop: '16px' }}
              onClick={() => {
                requestWorkspaceAccess()
              }}
              disabled={disableButton}
            >
              {rLIB('Ask for Access')}
            </Button>
            <Box>{profileAlertJSX}</Box>
          </Card>
        </Grid2>
      )
    }
    return tileJSX
  }

  const rJSX_ContactSalesTile = (): JSX.Element => {
    let tileJSX = <></>
    let tileKey = 'contact_sales'
    if (us_visibleHomeTiles[tileKey] != null) {
      tileJSX = (
        <Grid2
          xs={returnGridSizes('xs', us_visibleHomeTiles[tileKey])}
          sm={returnGridSizes('sm', us_visibleHomeTiles[tileKey])}
          md={returnGridSizes('md', us_visibleHomeTiles[tileKey])}
          lg={returnGridSizes('lg', us_visibleHomeTiles[tileKey])}
          xl={returnGridSizes('xl', us_visibleHomeTiles[tileKey])}
        >
          <Card
            className="tw-p-4 tw-inline-block tw-w-full tw-rounded-xl"
            sx={{ minHeight: '280px' }}
          >
            <Icon
              icon="comments"
              type="thin"
              className="tw-mb-4"
              sx={{ fontSize: '96px', color: themeVariables.warning_main }}
            />
            <Typography variant="h6">{rLIB('Contact our sales team to upgrade your account')}</Typography>
            <Button
              variant="contained"
              color="warning"
              sx={{ marginTop: '16px' }}
              onClick={() => {
                contactSales()
              }}
            >
              {rLIB('Contact Sales')}
            </Button>
          </Card>
        </Grid2>
      )
    }
    return tileJSX
  }

  const rJSX_ActiveWorkspaceName = (): JSX.Element => {
    let workspaceNameJSX = <></>
    if (objectToArray(us_allClients).length > 0 && uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.client_key != null) {
      if (us_allClients[uc_RootData_GlobalUser.client_key] != null && us_allClients[uc_RootData_GlobalUser.client_key].name != null) {
        workspaceNameJSX = <>{us_allClients[uc_RootData_GlobalUser.client_key].name}</>
      } else {
        workspaceNameJSX = <>{uc_RootData_GlobalUser.client_key}</>
      }
    } else {
      workspaceNameJSX = <>{rLIB('Loading...')}</>
    }
    return workspaceNameJSX
  }

  const rJSX_ToggleBetweenClientsTile = (): JSX.Element => {
    let tileJSX = <></>
    let tileKey = 'toggle_between_clients'
    if (us_visibleHomeTiles[tileKey] != null) {
      tileJSX = (
        <Grid2
          xs={returnGridSizes('xs', us_visibleHomeTiles[tileKey])}
          sm={returnGridSizes('sm', us_visibleHomeTiles[tileKey])}
          md={returnGridSizes('md', us_visibleHomeTiles[tileKey])}
          lg={returnGridSizes('lg', us_visibleHomeTiles[tileKey])}
          xl={returnGridSizes('xl', us_visibleHomeTiles[tileKey])}
        >
          <Card
            className="tw-p-4 tw-inline-block tw-w-full tw-rounded-xl"
            sx={{ minHeight: '280px' }}
          >
            <Icon
              icon="list-radio"
              type="thin"
              className="tw-mb-4"
              sx={{ fontSize: '96px', color: themeVariables.error_main }}
            />
            <Typography variant="h6">{rLIB('Select your active workspace')}</Typography>
            <Button
              variant="contained"
              color="error"
              sx={{ marginTop: '16px' }}
              onClick={() => {
                changeClientKey()
              }}
              disabled={objectToArray(us_allClients).length === 0}
            >
              {rJSX_ActiveWorkspaceName()}
            </Button>
          </Card>
        </Grid2>
      )
    }
    return tileJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX: JSX.Element = (
      <AuthenticatedContainer
        pageHeader={s_HOME}
        pageKey={pageKey}
        content={
          <Box
            className="tw-text-center tw-m-auto"
            sx={{ maxWidth: '1280px' }}
          >
            <Grid2
              container
              spacing={2}
            >
              {rJSX_FinishProfileTile()}
              {rJSX_InviteUserToWorkspaceTile()}
              {rJSX_AskForAccessTile()}
              {rJSX_ContactSalesTile()}
              {rJSX_ToggleBetweenClientsTile()}
            </Grid2>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
