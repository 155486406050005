///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_LookupTables_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'lookup_tables', 'main')
}

export const DatabaseRef_LookupTable_Document = (clientKey: string, lookupTableKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'lookup_tables', 'main', lookupTableKey)
}

export const DatabaseRef_LookupTablesDateRanges_Collection = (clientKey: string, lookupTableKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'lookup_tables', 'main', lookupTableKey, 'date_range_cutoffs')
}

export const DatabaseRef_LookupTablesDateRangesData_Document = (
  clientKey: string,
  lookupTableKey: string,
  dateKey: string,
): DocumentReference<DocumentData> => {
  return doc(
    getFirestore(),
    'clients',
    clientKey,
    'data_management',
    'lookup_tables',
    'main',
    lookupTableKey,
    'date_range_cutoffs',
    dateKey,
    'data',
    'lookup_table_values',
  )
}

export const DatabaseRef_LookupTablesActiveDateRanges_Query = (
  clientKey: string,
  lookupTableKey: string,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'date', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'data_management', 'lookup_tables', 'main', lookupTableKey, 'date_range_cutoffs'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_LookupTablesDateRanges_Document = (clientKey: string, lookupTableKey: string, dateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'lookup_tables', 'main', lookupTableKey, 'date_range_cutoffs', dateKey)
}

export const DatabaseRef_LookupTablesSpecificActiveDateRange_Query = (clientKey: string, lookupTableKey: string, date: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'date', comparator: '>=', value: date },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'date', desc: false }]
  let queryCursorsObject: TsInterface_QueryCursorsObject = {}
  let limit = 1
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'data_management', 'lookup_tables', 'main', lookupTableKey, 'date_range_cutoffs'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}
