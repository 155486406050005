///////////////////////////////
// Description
///////////////////////////////

import { Box, Button, Chip, FormControl, MenuItem, Select, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { dynamicSort, generateRandomString, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
const s_ADD_CALCULATION_STEP: JSX.Element = <Trans>Add Calculation Step</Trans>
const s_ADD_CONDITION: JSX.Element = <Trans>Add Condition</Trans>
const s_ADD_ELSE_IF: JSX.Element = <Trans>Add Else If</Trans>
const s_ADD_FORMULA_CALCULATION: JSX.Element = <Trans>Add Formula Calculation</Trans>
const s_ADD_IF_ELSE_STATEMENT: JSX.Element = <Trans>Add If/Else Statement</Trans>
const s_ADD_SWITCH_CASE: JSX.Element = <Trans>Add Switch Case</Trans>
const s_ADD_SWITCH_STATEMENT: JSX.Element = <Trans>Add Switch Statement</Trans>
const s_ALL_OF_THE_FOLLOWING_ARE_TRUE: JSX.Element = <Trans>All of the following are true</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_CALCULATION_STEP: JSX.Element = <Trans>Are you sure that you want to delete this calculation step?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_CONDITION: JSX.Element = <Trans>Are you sure that you want to delete this condition?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ELSE_IF: JSX.Element = <Trans>Are you sure that you want to delete this else if?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_SWITCH_CASE: JSX.Element = <Trans>Are you sure that you want to delete this switch case?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_WHOLE_STEP: JSX.Element = <Trans>Are you sure that you want to delete this whole step?</Trans>
const s_DEFAULT: JSX.Element = <Trans>Default</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_CALCULATION_STEP: JSX.Element = <Trans>Delete Calculation Step</Trans>
const s_DELETE_CONDITION: JSX.Element = <Trans>Delete Condition</Trans>
const s_DELETE_ELSE_IF: JSX.Element = <Trans>Delete Else If</Trans>
const s_DELETE_STEP: JSX.Element = <Trans>Delete Step</Trans>
const s_DELETE_SWITCH_CASE: JSX.Element = <Trans>Delete Switch Case</Trans>
const s_ELSE: JSX.Element = <Trans>Else</Trans>
const s_ELSE_IF: JSX.Element = <Trans>Else If</Trans>
const s_FORMULA: JSX.Element = <Trans>Formula</Trans>
const s_IF: JSX.Element = <Trans>If</Trans>
const s_INCOMPLETE_PATH: JSX.Element = <Trans>Incomplete Path</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_RESULT_OF_STEP: JSX.Element = <Trans>Result of Step</Trans>
const s_SWITCH: JSX.Element = <Trans>Switch</Trans>
const s_THIS_WILL_DELETE_EVERYTHING_NESTED_UNDERNEATH_IT: JSX.Element = <Trans>This will delete everything nested underneath it.</Trans>
const s_DIVIDED_BY: JSX.Element = <Trans>Divided by</Trans>
const s_EDIT: JSX.Element = <Trans>Edit</Trans>
const s_EQUAL_TO: JSX.Element = <Trans>Equal to</Trans>
const s_FLAG_AS_ERROR: JSX.Element = <Trans>Flag as Error</Trans>
const s_GREATER_THAN: JSX.Element = <Trans>Greater than</Trans>
const s_GREATER_THAN_OR_EQUAL_TO: JSX.Element = <Trans>Greater than or equal to</Trans>
const s_IS_FALSE: JSX.Element = <Trans>Is False</Trans>
const s_IS_NOT_FALSE: JSX.Element = <Trans>Is Not False</Trans>
const s_IS_NOT_NULL: JSX.Element = <Trans>Is Not Null</Trans>
const s_IS_NOT_TRUE: JSX.Element = <Trans>Is Not True</Trans>
const s_IS_NULL: JSX.Element = <Trans>Is Null</Trans>
const s_IS_TRUE: JSX.Element = <Trans>Is True</Trans>
const s_LESS_THAN: JSX.Element = <Trans>Less than</Trans>
const s_LESS_THAN_OR_EQUAL_TO: JSX.Element = <Trans>Less than or equal to</Trans>
const s_MINUS: JSX.Element = <Trans>Minus</Trans>
const s_MODULUS: JSX.Element = <Trans>Modulus</Trans>
const s_MULTIPLIED_BY: JSX.Element = <Trans>Multiplied by</Trans>
const s_NOTHING: JSX.Element = <Trans>Nothing</Trans>
const s_NOT_EQUAL_TO: JSX.Element = <Trans>Not equal to</Trans>
const s_PLUS: JSX.Element = <Trans>Plus</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const s_TRACE: JSX.Element = <Trans>Trace</Trans>
const s_AT_LEAST_ONE_OF_THE_FOLLOWING_IS_TRUE: JSX.Element = <Trans>At least one of the following is true</Trans>

// Options
export const comparatorOptions = {
  '>': {
    key: '>',
    value: s_GREATER_THAN,
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '>=': {
    key: '>=',
    value: s_GREATER_THAN_OR_EQUAL_TO,
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '<': {
    key: '<',
    value: s_LESS_THAN,
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '<=': {
    key: '<=',
    value: s_LESS_THAN_OR_EQUAL_TO,
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '==': {
    key: '==',
    value: s_EQUAL_TO,
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '!=': {
    key: '!=',
    value: s_NOT_EQUAL_TO,
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '==null': {
    key: '==null',
    value: s_IS_NULL,
    types: { number: true, string: true, boolean: true, timestamp: true },
  },
  '!=null': {
    key: '!=null',
    value: s_IS_NOT_NULL,
    types: { number: true, string: true, boolean: true, timestamp: true },
  },
  '==true': {
    key: '==true',
    value: s_IS_TRUE,
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  '!=true': {
    key: '!=true',
    value: s_IS_NOT_TRUE,
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  '==false': {
    key: '==false',
    value: s_IS_FALSE,
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  '!=false': {
    key: '!=false',
    value: s_IS_NOT_FALSE,
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
}

export const operatorOptions = {
  '+': {
    key: '+',
    value: s_PLUS,
  },
  '-': {
    key: '-',
    value: s_MINUS,
  },
  '*': {
    key: '*',
    value: s_MULTIPLIED_BY,
  },
  '/': {
    key: '/',
    value: s_DIVIDED_BY,
  },
  '%': {
    key: '%',
    value: s_MODULUS,
  },
  'nothing': {
    key: 'nothing',
    value: s_NOTHING,
  },
  'error': {
    key: 'error',
    value: s_FLAG_AS_ERROR,
  },
}

export const logicViewModeOptions = {
  view: {
    key: 'view',
    value: s_VIEW,
  },
  edit: {
    key: 'edit',
    value: s_EDIT,
  },
  trace: {
    key: 'trace',
    value: s_TRACE,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

// If / Else
const addIfElseConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    console.log('<><>')
    console.log(logicItem)
    console.log(calculationData)

    logicItem.logic_step_type = 'condition_if_else'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.condition_ifs = [
      {
        condition_type: 'and',
        conditions: [
          {
            variable_1: null,
            variable_2: null,
            comparator: null,
          },
        ],
        logic_step_type: null,
      },
    ]
    logicItem.condition_else = {
      logic_step_type: null,
    }
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteIfElseConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.condition_ifs = []
    logicItem.condition_else = {}
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const changeIfConditionType = (
  conditionalItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.condition_type = newValue
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const changeConditionVariable = (
  conditionLine: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionLine[variableKey] = newValue
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const addElseIf = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_ifs.push({
      condition_type: 'and',
      conditions: [
        {
          variable_1: null,
          variable_2: null,
          comparator: null,
        },
      ],
      logic_step_type: null,
    })
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteElseIf = (
  logicItem: TsInterface_UnspecifiedObject,
  elseIfIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_ifs.splice(elseIfIndex, 1)
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const addConditionLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.conditions.push({
      variable_1: null,
      variable_2: null,
      comparator: null,
    })
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteConditionLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionalLineIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.conditions.splice(conditionalLineIndex, 1)
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

// Switch
const addSwitchConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'condition_switch'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.condition_cases = [
      {
        logic_step_type: null,
        case_variable: null,
      },
    ]
    logicItem.condition_default = {
      logic_step_type: null,
    }
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteSwitchConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.condition_cases = null
    logicItem.condition_default = null
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const changeSwitchVariable = (
  logicItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.switch_variable = newValue
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const changeSwitchCaseVariable = (
  switchCase: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    switchCase[variableKey] = newValue
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const addSwitchCase = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_cases.push({
      case_variable: null,
      logic_step_type: null,
    })
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteSwitchCase = (
  logicItem: TsInterface_UnspecifiedObject,
  caseIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_cases.splice(caseIndex, 1)
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

// Formula
const addOperationFormulaStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'operation_formula'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.operation_steps = [
      {
        operation_type: null,
        variable_1: null,
        variable_2: null,
      },
    ]
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteOperationFormulaStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.operation_steps = null
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const addCalculationStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.operation_steps.push({
      operation_type: null,
      variable_1: null,
      variable_2: null,
    })
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteCalculationStep = (
  logicItem: TsInterface_UnspecifiedObject,
  stepIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.operation_steps.splice(stepIndex, 1)
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

const changeCalculationStepVariable = (
  calculationStep: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    calculationStep[variableKey] = newValue
    saveFormulaChangeToDatabase({ logic: calculationData.logic })
      .then((res_SFCTD: any) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: any) => {
        reject(rej_SFCTD)
      })
  })
}

// JSX Render
const rJSX_LogicPathNewStepButtons = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let buttonsJSX = <></>
  buttonsJSX = (
    <Box>
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addIfElseConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData)
        }}
      >
        <Icon
          icon="code-branch"
          className="tw-mr-2 fa-flip-vertical"
        />
        {s_ADD_IF_ELSE_STATEMENT}
      </Button>
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addSwitchConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData)
        }}
      >
        <Icon
          icon="layer-group"
          className="tw-mr-2"
        />
        {s_ADD_SWITCH_STATEMENT}
      </Button>
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addOperationFormulaStep(logicItem, saveFormulaChangeToDatabase, calculationData)
        }}
      >
        <Icon
          icon="calculator-simple"
          className="tw-mr-2"
        />
        {s_ADD_FORMULA_CALCULATION}
      </Button>
    </Box>
  )
  if (logicViewMode !== 'edit') {
    buttonsJSX = (
      <Chip
        color="error"
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {s_INCOMPLETE_PATH}
          </Typography>
        }
      />
    )
  }
  return buttonsJSX
}

const rJSX_IfConditionalLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionLine: TsInterface_UnspecifiedObject,
  conditionLineIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  // Instantiate Variables
  let conditionalLineJSX = <></>
  // Trace
  let variableTrace1 = <></>
  let variableTrace2 = <></>
  let variable1Background = 'rgba(0,0,0,0)'
  let conditionBackground = 'rgba(0,0,0,0)'
  let variable2Background = 'rgba(0,0,0,0)'
  if (logicViewMode === 'trace') {
    if (
      conditionLine != null &&
      conditionLine.variable_1 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][conditionLine.variable_1] != null
    ) {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][conditionLine.variable_1]}
        </Box>
      )
    } else {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {s_MISSING}
        </Box>
      )
    }
    if (
      conditionLine != null &&
      conditionLine.variable_2 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][conditionLine.variable_2] != null
    ) {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][conditionLine.variable_2]}
        </Box>
      )
    } else {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {s_MISSING}
        </Box>
      )
    }
  }
  // Input background colors
  if (conditionLine.variable_1 == null) {
    variable1Background = themeVariables.error_wash
  }
  if (conditionLine.comparator == null) {
    conditionBackground = themeVariables.error_wash
  }
  if (conditionLine.variable_2 == null) {
    variable2Background = themeVariables.error_wash
  }
  // Input and Button JSX
  let variableOneJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '30px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_1 }</InputLabel> */}
      <Select
        sx={{ background: variable1Background }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_VARIABLE_1 }
        onChange={(event, value) => {
          changeConditionVariable(conditionLine, 'variable_1', event.target.value, saveFormulaChangeToDatabase, calculationData)
        }}
        value={conditionLine.variable_1 || ''}
      >
        {objectToArray(calculationData.variables)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive'}
            >
              {option['name']}
              {variableTrace1}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let conditionJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '30px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_CONDITION }</InputLabel> */}
      <Select
        sx={{ background: conditionBackground }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_CONDITION }
        onChange={(event, value) => {
          changeConditionVariable(conditionLine, 'comparator', event.target.value, saveFormulaChangeToDatabase, calculationData)
        }}
        value={conditionLine.comparator || ''}
      >
        {objectToArray(comparatorOptions).map((option: TsInterface_UnspecifiedObject) => (
          <MenuItem
            key={option['key']}
            value={option['key']}
            disabled={option['disabled']}
          >
            {option['value']}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  let variableTwoJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '30px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_2 }</InputLabel> */}
      <Select
        sx={{ background: variable2Background }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_VARIABLE_2 }
        onChange={(event, value) => {
          changeConditionVariable(conditionLine, 'variable_2', event.target.value, saveFormulaChangeToDatabase, calculationData)
        }}
        value={conditionLine.variable_2 || ''}
      >
        {objectToArray(calculationData.variables)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive'}
            >
              {option['name']}
              {variableTrace2}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: s_DELETE_CONDITION,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_CONDITION,
            submit_text: s_DELETE,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteConditionLine(conditionalItem, conditionLineIndex, saveFormulaChangeToDatabase, calculationData)
                  .then((deleteResult) => {
                    resolve(deleteResult)
                  })
                  .catch((deleteReject) => {
                    reject(deleteReject)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  // Modifications to JSX
  if (
    conditionLine.comparator === '==null' ||
    conditionLine.comparator === '!=null' ||
    conditionLine.comparator === '==true' ||
    conditionLine.comparator === '!=true' ||
    conditionLine.comparator === '==false' ||
    conditionLine.comparator === '!=false'
  ) {
    variableTwoJSX = <></>
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Full JSX
  conditionalLineJSX = (
    <Box>
      {variableOneJSX}
      {conditionJSX}
      {variableTwoJSX}
      {deleteButtonJSX}
    </Box>
  )
  return conditionalLineJSX
}

const rJSX_IfConditional = (
  logicItem: TsInterface_UnspecifiedObject,
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let conditionalJSX = <></>
  let ifLabelJSX = <></>
  let deleteButtonJSX = <></>
  if (conditionIndex === 0) {
    let chipColor1: any = 'info'
    if (
      logicViewMode === 'trace' &&
      traceData != null &&
      traceData.calculationPath != null &&
      traceData.calculationPath.pathDebug != null &&
      conditionalItem != null &&
      conditionalItem.logic_trace_key != null
    ) {
      chipColor1 = 'default'
      if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === true) {
        chipColor1 = 'success'
      } else if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === false) {
        chipColor1 = 'error'
      }
    }
    ifLabelJSX = (
      <Chip
        color={chipColor1}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {s_IF}
          </Typography>
        }
      />
    )
    deleteButtonJSX = (
      <Button
        variant="outlined"
        color="error"
        className="tw-opacity-30 hover:tw-opacity-100"
        sx={{ minWidth: '20px', fontSize: '24px', marginTop: '0px' }}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: s_DELETE_STEP,
              text: (
                <>
                  {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_WHOLE_STEP} {s_THIS_WILL_DELETE_EVERYTHING_NESTED_UNDERNEATH_IT}
                </>
              ),
              submit_text: s_DELETE,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  deleteIfElseConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData)
                    .then((deleteResult) => {
                      resolve(deleteResult)
                    })
                    .catch((deleteReject) => {
                      reject(deleteReject)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="trash-xmark"
          type="solid"
        />
      </Button>
    )
  } else {
    let chipColor2: any = 'info'
    if (
      logicViewMode === 'trace' &&
      traceData != null &&
      traceData.calculationPath != null &&
      traceData.calculationPath.pathDebug != null &&
      conditionalItem != null &&
      conditionalItem.logic_trace_key != null
    ) {
      chipColor2 = 'default'
      if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === true) {
        chipColor2 = 'success'
      } else if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === false) {
        chipColor2 = 'error'
      }
    }
    ifLabelJSX = (
      <Chip
        color={chipColor2}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {s_ELSE_IF}
          </Typography>
        }
      />
    )
    deleteButtonJSX = (
      <Button
        variant="outlined"
        color="error"
        className="tw-opacity-30 hover:tw-opacity-100"
        sx={{ minWidth: '20px', fontSize: '24px', marginTop: '4px' }}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: s_DELETE_ELSE_IF,
              text: (
                <>
                  {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ELSE_IF} {s_THIS_WILL_DELETE_EVERYTHING_NESTED_UNDERNEATH_IT}
                </>
              ),
              submit_text: s_DELETE,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  deleteElseIf(logicItem, conditionIndex, saveFormulaChangeToDatabase, calculationData)
                    .then((deleteResult) => {
                      resolve(deleteResult)
                    })
                    .catch((deleteReject) => {
                      reject(deleteReject)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon icon="trash-xmark" />
      </Button>
    )
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  let addButtonJSX = (
    <Button
      sx={{ marginBottom: '8px', marginRight: '8px' }}
      variant="outlined"
      color="success"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addConditionLine(conditionalItem, saveFormulaChangeToDatabase, calculationData)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {s_ADD_CONDITION}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
  }
  conditionalJSX = (
    <Box>
      <Box className="">
        {ifLabelJSX}
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '30px', marginLeft: '12px', marginRight: '12px' }}
        >
          <Select
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            onChange={(event, value) => {
              changeIfConditionType(conditionalItem, event.target.value, saveFormulaChangeToDatabase, calculationData)
            }}
            value={conditionalItem.condition_type || ''}
          >
            <MenuItem
              key={'and'}
              value={'and'}
            >
              {s_ALL_OF_THE_FOLLOWING_ARE_TRUE}
            </MenuItem>
            <MenuItem
              key={'or'}
              value={'or'}
            >
              {s_AT_LEAST_ONE_OF_THE_FOLLOWING_IS_TRUE}
            </MenuItem>
          </Select>
        </FormControl>
        {deleteButtonJSX}
      </Box>
      <Box sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px', paddingLeft: '8px' }}>
        <Box sx={{ paddingTop: '8px' }}>
          {conditionalItem.conditions.map((conditionLine: TsInterface_UnspecifiedObject, conditionLineIndex: number) => (
            <Box key={conditionLineIndex}>
              {rJSX_IfConditionalLine(
                conditionalItem,
                conditionLine,
                conditionLineIndex,
                logicViewMode,
                traceData,
                currentLevelIndex,
                saveFormulaChangeToDatabase,
                uc_setUserInterface_ConfirmDialogDisplay,
                calculationData,
              )}
            </Box>
          ))}
          {addButtonJSX}
        </Box>
      </Box>
      <Box
        className=""
        sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
      >
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '16px', width: '100%' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            conditionalItem,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      </Box>
    </Box>
  )
  return conditionalJSX
}

const rJSX_IfConditionalContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let containerJSX = <></>
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      color="info"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addElseIf(logicItem, saveFormulaChangeToDatabase, calculationData)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {s_ADD_ELSE_IF}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
  }
  let chipColor: any = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem['condition_else'] != null &&
    logicItem['condition_else'].logic_trace_key != null
  ) {
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem['condition_else'].logic_trace_key] === true) {
      chipColor = 'success'
    } else if (traceData.calculationPath.pathDebug[logicItem['condition_else'].logic_trace_key] === false) {
      chipColor = 'error'
    }
  }
  containerJSX = (
    <Box>
      {logicItem.condition_ifs.map((conditionalItem: TsInterface_UnspecifiedObject, conditionIndex: number) => (
        <Box key={conditionIndex}>
          {rJSX_IfConditional(
            logicItem,
            conditionalItem,
            conditionIndex,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      ))}
      <Box>
        {addButtonJSX}
        <Box
          className=""
          sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
        ></Box>
      </Box>
      <Box className="">
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {s_ELSE}
            </Typography>
          }
        />
      </Box>
      <Box sx={{ marginLeft: '16px', marginBottom: '8px' }}>
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            logicItem.condition_else,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      </Box>
    </Box>
  )
  return containerJSX
}

const rJSX_SwitchConditionalCase = (
  logicItem: TsInterface_UnspecifiedObject,
  switchCase: TsInterface_UnspecifiedObject,
  switchCaseIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let caseJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginTop: '8px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: s_DELETE_SWITCH_CASE,
            text: (
              <>
                {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_SWITCH_CASE} {s_THIS_WILL_DELETE_EVERYTHING_NESTED_UNDERNEATH_IT}
              </>
            ),
            submit_text: s_DELETE,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteSwitchCase(logicItem, switchCaseIndex, saveFormulaChangeToDatabase, calculationData)
                  .then((deleteResult) => {
                    resolve(deleteResult)
                  })
                  .catch((deleteReject) => {
                    reject(deleteReject)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Input background colors
  let caseVariableBackground = 'rgba(0,0,0,0)'
  if (switchCase.case_variable == null) {
    caseVariableBackground = themeVariables.error_wash
  }
  let variableTrace = <></>
  if (logicViewMode === 'trace') {
    if (
      switchCase != null &&
      switchCase.case_variable != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][switchCase.case_variable] != null
    ) {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][switchCase.case_variable]}
        </Box>
      )
    } else {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {s_MISSING}
        </Box>
      )
    }
  }
  // Full JSX
  caseJSX = (
    <Box
      className=""
      sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
    >
      <Box
        className="tw-inline-block"
        sx={{ width: '32px', display: 'inline-block' }}
      >
        <Box
          className=""
          sx={{ height: '32px', width: '100%' }}
        ></Box>
        <Box
          className=""
          sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
        ></Box>
      </Box>
      <Box
        className="tw-inline-block tw-align-top"
        sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
      >
        <Box>
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '140px', marginRight: '12px', marginTop: '8px' }}
          >
            {/* <InputLabel>{ s_CASE_VARIABLE }</InputLabel> */}
            <Select
              sx={{ background: caseVariableBackground }}
              disabled={logicViewMode !== 'edit'}
              autoWidth={true}
              // label={ s_CASE_VARIABLE }
              onChange={(event, value) => {
                changeSwitchCaseVariable(switchCase, 'case_variable', event.target.value, saveFormulaChangeToDatabase, calculationData)
              }}
              value={switchCase.case_variable || ''}
            >
              {objectToArray(calculationData.variables)
                .sort(dynamicSort('name', null))
                .map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['status'] === 'inactive'}
                  >
                    {option['name']}
                    {variableTrace}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {deleteButtonJSX}
        </Box>
        <Box
          className=""
          sx={{ marginLeft: '16px' }}
        >
          <Box
            className="tw-inline-block"
            sx={{ width: '32px', display: 'inline-block' }}
          >
            <Box
              className=""
              sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
            ></Box>
            <Box
              className=""
              sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
            ></Box>
          </Box>
          <Box
            className="tw-inline-block tw-align-top "
            sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
          >
            {rJSX_LogicPathRender(
              switchCase,
              logicViewMode,
              traceData,
              currentLevelIndex + 1,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              calculationData,
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
  return caseJSX
}

const rJSX_SwitchConditionalContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let containerJSX = <></>
  // Button Components
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: s_DELETE_STEP,
            text: (
              <>
                {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_WHOLE_STEP} {s_THIS_WILL_DELETE_EVERYTHING_NESTED_UNDERNEATH_IT}
              </>
            ),
            submit_text: s_DELETE,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteSwitchConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData)
                  .then((deleteResult) => {
                    resolve(deleteResult)
                  })
                  .catch((deleteReject) => {
                    reject(deleteReject)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      className="tw-opacity-30 hover:tw-opacity-100"
      color="info"
      onClick={() => {
        addSwitchCase(logicItem, saveFormulaChangeToDatabase, calculationData)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {s_ADD_SWITCH_CASE}
    </Button>
  )
  // Preview Mode
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
    addButtonJSX = <></>
  }
  // Input background colors
  let switchVariable = 'rgba(0,0,0,0)'
  if (logicItem.switch_variable == null) {
    switchVariable = themeVariables.error_wash
  }
  let chipColor1: any = 'info'
  let chipColor2: any = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    chipColor1 = 'default'
    let usedCaseStatement = false
    for (let loopConditionCaseIndex in logicItem.condition_cases) {
      let loopCondition = logicItem.condition_cases[loopConditionCaseIndex]
      if (loopCondition != null && loopCondition.logic_trace_key != null && traceData.calculationPath.pathDebug[loopCondition.logic_trace_key] === true) {
        usedCaseStatement = true
      }
      if (usedCaseStatement === true) {
        chipColor1 = 'success'
      }
    }
  }
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem['condition_default'] != null &&
    logicItem['condition_default'].logic_trace_key != null
  ) {
    chipColor2 = 'default'
    if (traceData.calculationPath.pathDebug[logicItem['condition_default'].logic_trace_key] === true) {
      chipColor2 = 'success'
      chipColor1 = 'error'
    }
  }
  let variableTrace = <></>
  if (logicViewMode === 'trace') {
    if (
      logicItem != null &&
      logicItem.switch_variable != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][logicItem.switch_variable] != null
    ) {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][logicItem.switch_variable]}
        </Box>
      )
    } else {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {s_MISSING}
        </Box>
      )
    }
  }
  // Full JSX
  containerJSX = (
    <Box>
      <Box>
        <Chip
          color={chipColor1}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {s_SWITCH}
            </Typography>
          }
        />
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '170px', marginLeft: '12px', marginRight: '12px' }}
        >
          {/* <InputLabel>{ s_VARIABLE_TO_CHECK }</InputLabel> */}
          <Select
            sx={{ background: switchVariable }}
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            // label={ s_VARIABLE_TO_CHECK }
            onChange={(event, value) => {
              changeSwitchVariable(logicItem, event.target.value, saveFormulaChangeToDatabase, calculationData)
            }}
            value={logicItem.switch_variable || ''}
          >
            {objectToArray(calculationData.variables)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                  disabled={option['status'] === 'inactive'}
                >
                  {option['name']}
                  {variableTrace}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {deleteButtonJSX}
      </Box>
      <Box>
        {logicItem.condition_cases.map((switchCase: TsInterface_UnspecifiedObject, switchCaseIndex: number) => (
          <Box key={switchCaseIndex}>
            {rJSX_SwitchConditionalCase(
              logicItem,
              switchCase,
              switchCaseIndex,
              logicViewMode,
              traceData,
              currentLevelIndex,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              calculationData,
            )}
          </Box>
        ))}
      </Box>
      <Box>
        {addButtonJSX}
        <Box
          className=""
          sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
        ></Box>
      </Box>
      <Chip
        color={chipColor2}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {s_DEFAULT}
          </Typography>
        }
      />
      <Box
        className=""
        sx={{ marginLeft: '16px' }}
      >
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            logicItem.condition_default,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      </Box>
    </Box>
  )
  return containerJSX
}

const rJSX_CalculationStep = (
  calculationItem: TsInterface_UnspecifiedObject,
  calculationStep: TsInterface_UnspecifiedObject,
  calculationStepIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let lineJSX = <></>
  let disabledStepDeleteFunction = false
  if (calculationStepIndex < calculationItem.operation_steps.length - 1) {
    disabledStepDeleteFunction = true
  }
  let previousStepOptions: TsInterface_UnspecifiedObject[] = []
  for (let loopStepIndex = 1; loopStepIndex <= calculationStepIndex; loopStepIndex++) {
    previousStepOptions.push({
      key: 'STEP_' + loopStepIndex,
      name: (
        <>
          {s_RESULT_OF_STEP} {loopStepIndex}
        </>
      ),
    })
  }
  // Trace
  let variableTrace1 = <></>
  let variableTrace2 = <></>
  let variable1Background = 'rgba(0,0,0,0)'
  let operatorBackground = 'rgba(0,0,0,0)'
  let variable2Background = 'rgba(0,0,0,0)'
  if (logicViewMode === 'trace') {
    if (
      calculationStep != null &&
      calculationStep.variable_1 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][calculationStep.variable_1] != null
    ) {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][calculationStep.variable_1]}
        </Box>
      )
    } else {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {s_MISSING}
        </Box>
      )
    }
    if (
      calculationStep != null &&
      calculationStep.variable_2 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][calculationStep.variable_2] != null
    ) {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][calculationStep.variable_2]}
        </Box>
      )
    } else {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {s_MISSING}
        </Box>
      )
    }
  }
  // Input background colors
  if (calculationStep.variable_1 == null) {
    variable1Background = themeVariables.error_wash
  }
  if (calculationStep.operation_type == null) {
    operatorBackground = themeVariables.error_wash
  }
  if (calculationStep.variable_2 == null) {
    variable2Background = themeVariables.error_wash
  }
  // Input and Button JSX
  let variableOneJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '30px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_1 }</InputLabel> */}
      <Box
        onClick={() => {
          if (logicViewMode === 'trace') {
            // TODO
            // console.log("TRACE")
            // console.log(calculationStep.variable_1)
            // console.log(traceData.other_trace_data) // Pass in useful stuff here?
            // console.log( "<><><><>" )
            // console.log( calculationStep )
            // console.log( traceData )
            // console.log( calculationData )
            // TODO - show dialog? can't use custom? maybe tooltip...
          }
        }}
      >
        <Select
          sx={{ background: variable1Background }}
          disabled={logicViewMode !== 'edit'}
          autoWidth={true}
          // label={ s_VARIABLE_1 }
          onChange={(event, value) => {
            changeCalculationStepVariable(calculationStep, 'variable_1', event.target.value, saveFormulaChangeToDatabase, calculationData)
          }}
          value={calculationStep.variable_1 || ''}
        >
          {previousStepOptions.map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['disabled']}
            >
              {option['name']}
            </MenuItem>
          ))}
          {objectToArray(calculationData.variables)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['status'] === 'inactive'}
              >
                {option['name']}
                {variableTrace1}
              </MenuItem>
            ))}
        </Select>
      </Box>
    </FormControl>
  )
  let operatorJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '30px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_OPERATOR }</InputLabel> */}
      <Select
        sx={{ background: operatorBackground }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_OPERATOR }
        onChange={(event, value) => {
          changeCalculationStepVariable(calculationStep, 'operation_type', event.target.value, saveFormulaChangeToDatabase, calculationData)
        }}
        value={calculationStep.operation_type || ''}
      >
        {objectToArray(operatorOptions).map((option: TsInterface_UnspecifiedObject) => (
          <MenuItem
            key={option['key']}
            value={option['key']}
            disabled={option['disabled']}
          >
            {option['value']}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  let variableTwoJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '30px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_2 }</InputLabel> */}
      <Select
        sx={{ background: variable2Background }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_VARIABLE_2 }
        onChange={(event, value) => {
          changeCalculationStepVariable(calculationStep, 'variable_2', event.target.value, saveFormulaChangeToDatabase, calculationData)
        }}
        value={calculationStep.variable_2 || ''}
      >
        {previousStepOptions.map((option: TsInterface_UnspecifiedObject) => (
          <MenuItem
            key={option['key']}
            value={option['key']}
            disabled={option['disabled']}
          >
            {option['name']}
          </MenuItem>
        ))}
        {objectToArray(calculationData.variables)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive'}
            >
              {option['name']}
              {variableTrace2}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px' }}
      disabled={disabledStepDeleteFunction}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: s_DELETE_CALCULATION_STEP,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_CALCULATION_STEP,
            submit_text: s_DELETE,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteCalculationStep(calculationItem, calculationStepIndex, saveFormulaChangeToDatabase, calculationData)
                  .then((deleteResult) => {
                    resolve(deleteResult)
                  })
                  .catch((deleteReject) => {
                    reject(deleteReject)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  // Modifications to JSX
  if (calculationStep.operation_type === 'nothing') {
    variableTwoJSX = <></>
  } else if (calculationStep.operation_type === 'error') {
    variableOneJSX = <></>
    variableTwoJSX = <></>
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Full JSX
  lineJSX = (
    <Box>
      <Box
        className="tw-inline-block tw-opacity-20"
        sx={{ display: 'inline-block', marginRight: '4px', marginLeft: '4px' }}
      >
        <Typography variant="h6">{calculationStepIndex + 1}</Typography>
      </Box>
      {variableOneJSX}
      {operatorJSX}
      {variableTwoJSX}
      {deleteButtonJSX}
    </Box>
  )
  return lineJSX
}

const rJSX_OperationFormulaContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let operationJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginLeft: '12px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: s_DELETE_STEP,
            text: (
              <>
                {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_WHOLE_STEP} {s_THIS_WILL_DELETE_EVERYTHING_NESTED_UNDERNEATH_IT}
              </>
            ),
            submit_text: s_DELETE,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteOperationFormulaStep(logicItem, saveFormulaChangeToDatabase, calculationData)
                  .then((deleteResult) => {
                    resolve(deleteResult)
                  })
                  .catch((deleteReject) => {
                    reject(deleteReject)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      color="info"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addCalculationStep(logicItem, saveFormulaChangeToDatabase, calculationData)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {s_ADD_CALCULATION_STEP}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
    deleteButtonJSX = <></>
  }
  let chipColor: any = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem.logic_trace_key] === true) {
      chipColor = 'success'
    }
  }
  operationJSX = (
    <Box>
      <Box>
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {s_FORMULA}
            </Typography>
          }
        />
        {deleteButtonJSX}
      </Box>
      <Box
        className=""
        sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
      ></Box>
      {logicItem.operation_steps.map((step: TsInterface_UnspecifiedObject, stepIndex: number) => (
        <Box
          className=""
          sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
          key={stepIndex}
        >
          <Box
            className="tw-inline-block"
            sx={{ width: '32px', display: 'inline-block' }}
          >
            <Box
              className=""
              sx={{ height: '16px', width: '100%' }}
            ></Box>
            <Box
              className=""
              sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
            ></Box>
          </Box>
          <Box
            className="tw-inline-block tw-align-top"
            sx={{ width: 'calc(100% - 32px)', display: 'inline-block', verticalAlign: 'top' }}
          >
            {rJSX_CalculationStep(
              logicItem,
              step,
              stepIndex,
              logicViewMode,
              traceData,
              currentLevelIndex,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              calculationData,
            )}
          </Box>
        </Box>
      ))}
      <Box sx={{ marginBottom: '8px' }}>{addButtonJSX}</Box>
    </Box>
  )
  return operationJSX
}

export const rJSX_LogicPathRender = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let logicItemJSX = <></>
  if (logicItem != null) {
    if (logicItem['logic_step_type'] == null) {
      logicItemJSX = (
        <Box>{rJSX_LogicPathNewStepButtons(logicItem, logicViewMode, traceData, currentLevelIndex, saveFormulaChangeToDatabase, calculationData)}</Box>
      )
    } else if (logicItem['logic_step_type'] === 'condition_if_else') {
      logicItemJSX = (
        <Box>
          {rJSX_IfConditionalContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'condition_switch') {
      logicItemJSX = (
        <Box>
          {rJSX_SwitchConditionalContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'operation_formula') {
      logicItemJSX = (
        <Box>
          {rJSX_OperationFormulaContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
          )}
        </Box>
      )
    }
  }
  return logicItemJSX
}
