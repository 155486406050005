///////////////////////////////
// Imports
///////////////////////////////

import { collection, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ClientUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'client_key', comparator: '==', value: clientKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'users'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ClientAccessUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: `client_access.${clientKey}`, comparator: '>=', value: 'a' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'users'), queryOperatorsArray, orderByArray, {}, null)
}
