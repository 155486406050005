///////////////////////////////
// Imports
///////////////////////////////

import { collection, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ClientWorkspaceInvites_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'client_key', comparator: '==', value: clientKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global_workspace_invites'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_UserEmailInvites_Query = (userEmail: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_invitee_email', comparator: '==', value: userEmail }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global_workspace_invites'), queryOperatorsArray, orderByArray, {}, null)
}
