//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { initializeApp } from 'firebase/app'

///////////////////////////////
// Variables
///////////////////////////////

const firebaseConfig = {
  apiKey: 'AIzaSyBQB154PZ2buRFORUwh9uk_-WQ2CGIjtbc',
  authDomain: 'data-ore-app.firebaseapp.com',
  projectId: 'data-ore-app',
  storageBucket: 'data-ore-app.appspot.com',
  messagingSenderId: '521475640193',
  appId: '1:521475640193:web:d573a65b16b28770bff680',
  measurementId: 'G-CMJPL11JY3',
}

///////////////////////////////
// Functions
///////////////////////////////

initializeApp(firebaseConfig)

///////////////////////////////
// Exports
///////////////////////////////

export default function FirebaseInit() {
  // Initialization occurs just from loading file BEFORE app.js file is loaded
}
