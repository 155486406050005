///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useCSVReader } from 'react-papaparse'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_LookupTablesActiveDateRanges_Query,
  DatabaseRef_LookupTablesDateRangesData_Document,
  DatabaseRef_LookupTablesDateRanges_Collection,
  DatabaseRef_LookupTablesDateRanges_Document,
  DatabaseRef_LookupTable_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/lookup_tables'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableCellBasic, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseSetReplaceDocument,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, getProp, objectToArray, returnFormattedDate, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import {
  lookupTableExampleDownloads,
  lookupTableTypeOptions,
  returnDataAsDownloadableArray,
  returnDataInRenderTableFormat,
  returnSearchValueFromLookupTable,
} from './data/lookup_tables'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['LookupTablesViewPage']['key']

// Displayed Translatable Strings
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_DATE_RANGE: JSX.Element = <Trans>Are you sure that you want to delete this date range?</Trans>
const s_BEGINNING: JSX.Element = <Trans>Beginning</Trans>
const s_CREATED_BY: JSX.Element = <Trans>Created By</Trans>
const s_CREATE_DATE_CUTOFF: JSX.Element = <Trans>Create Date Cutoff</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DATE_CUTOFFS: JSX.Element = <Trans>Date Cutoffs</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_DATE_RANGE: JSX.Element = <Trans>Delete Date Range</Trans>
const s_DELETE_RANGE: JSX.Element = <Trans>Delete Range</Trans>
const s_DESCRIPTION: JSX.Element = <Trans>Description</Trans>
const s_DOWNLOAD_EXAMPLE: JSX.Element = <Trans>Download Example</Trans>
const s_DOWNLOAD_TABLE: JSX.Element = <Trans>Download Table</Trans>
const s_EDIT_DATE: JSX.Element = <Trans>Edit Date</Trans>
const s_ENTER_A_NEW_VALUE_FOR: JSX.Element = <Trans>Enter a new value for</Trans>
const s_FAILED_TO_DELETE_DATE_RANGE: JSX.Element = <Trans>Failed to delete date range</Trans>
const s_FAILED_TO_UPDATE_DATE_RANGE: JSX.Element = <Trans>Failed to update date range</Trans>
const s_GREATER_THAN_OR_EQUAL_TO: JSX.Element = <Trans>Greater than or equal to</Trans>
const s_INPUT_VALUE: JSX.Element = <Trans>Input Value</Trans>
const s_LESS_THAN: JSX.Element = <Trans>Less than</Trans>
const s_LOOKUP_RANGE_END: JSX.Element = <Trans>Lookup Range End</Trans>
const s_LOOKUP_RANGE_START: JSX.Element = <Trans>Lookup Range Start</Trans>
const s_LOOKUP_TABLE: JSX.Element = <Trans>Lookup Table</Trans>
const s_LOOKUP_TABLE_TYPE: JSX.Element = <Trans>Lookup Table Type</Trans>
const s_LOOKUP_VALUE: JSX.Element = <Trans>Lookup Value</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_NO_DATE_RANGES_ADDED_YET: JSX.Element = <Trans>No Date Ranges Added Yet</Trans>
const s_NO_LOOKUP_DATA_UPLOADED_YET: JSX.Element = <Trans>No lookup data uploaded yet</Trans>
const s_PRIMARY_INPUT_VALUE: JSX.Element = <Trans>Primary Input Value</Trans>
const s_RANGE_END_DATE: JSX.Element = <Trans>Range End Date</Trans>
const s_RESULT: JSX.Element = <Trans>Result</Trans>
const s_SEARCH: JSX.Element = <Trans>Search</Trans>
const s_SEARCH_RESULT: JSX.Element = <Trans>Search Result</Trans>
const s_SECONDARY_INPUT_VALUE: JSX.Element = <Trans>Secondary Input Value</Trans>
const s_SELECT_LOOKUP_TABLE_TYPE: JSX.Element = <Trans>Select Lookup Table Type</Trans>
const s_TEST_LOOKUP: JSX.Element = <Trans>Test Lookup</Trans>
const s_UPDATE: JSX.Element = <Trans>Update</Trans>
const s_UPLOAD_LOOKUP_TABLE: JSX.Element = <Trans>Upload Lookup Table</Trans>

///////////////////////////////
// Functions
///////////////////////////////

// us_lookupTable => us_lookupTable

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_loadedCalculationData, us_setLoadedCalculationData] = useState<boolean>(false)
  const [us_lookupSearchResult, us_setLookupSearchResult] = useState<any>(null)
  const [us_lookupTable, us_setLookupTable] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lookupTableDateRangeCutoffs, us_setLookupTableDateRangeCutoffs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_parsingData, us_setParsingData] = useState<boolean>(false)
  const [us_selectedDateRangeKey, us_setSelectedDateRangeKey] = useState<string | null>(null)
  const [us_selectedDateRangeLookupTableData, us_setSelectedDateRangeLookupTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_testLookupDate, us_setTestLookupDate] = useState<string>(returnFormattedDateKey(new Date()))
  const [us_testLookupInput1, us_setTestLookupInput1] = useState<string>('')
  const [us_testLookupInput2, us_setTestLookupInput2] = useState<string>('')
  const forceRerender = useReducer(() => ({}), {})[1] as () => void
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { CSVReader } = useCSVReader()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Lookup Table', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLookupTable(newData)
      us_setLoadedCalculationData(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_LookupTable_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLookupTableDateRangeCutoffs(newData)
      forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_LookupTablesActiveDateRanges_Query(res_GCK.clientKey, itemKey, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedDateRangeLookupTableData(newData)
      forceRerender()
    }
    if (us_selectedDateRangeKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_LookupTablesDateRangesData_Document(res_GCK.clientKey, itemKey, us_selectedDateRangeKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, us_selectedDateRangeKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, forceRerender])

  // Functions
  const editLookupTableType = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_lookupTable,
          formInputs: {
            table_type: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'table_type',
              label: s_LOOKUP_TABLE_TYPE,
              options: objectToArray(lookupTableTypeOptions),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject = formSubmittedData
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_LookupTable_Document(res_GCK.clientKey, itemKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_SELECT_LOOKUP_TABLE_TYPE,
          formDialogIcon: <Icon icon="pen-to-square" />,
        },
      },
    })
  }

  const addDateRange = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            date: {
              data_type: 'string',
              input_type: 'timestamp_datetime',
              key: 'date',
              label: s_RANGE_END_DATE,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject = {
                // key: formSubmittedData.date,
                date: new Date(formSubmittedData.date),
                status: 'active',
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseAddDocument(DatabaseRef_LookupTablesDateRanges_Collection(res_GCK.clientKey, itemKey), updateObject, true)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_CREATE_DATE_CUTOFF,
          formDialogIcon: <Icon icon="pen-to-square" />,
        },
      },
    })
  }

  const editDateRangeDate = (): void => {
    let formData = getProp(us_lookupTableDateRangeCutoffs, us_selectedDateRangeKey, {})
    if (formData.date != null) {
      formData.date = returnFormattedDate(formData.date, 'YYYY-MM-DD hh:mm')
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: getProp(us_lookupTableDateRangeCutoffs, us_selectedDateRangeKey, {}),
          formInputs: {
            date: {
              data_type: 'string',
              input_type: 'timestamp_datetime',
              key: 'date',
              label: s_RANGE_END_DATE,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject = {
                date: new Date(formSubmittedData.date),
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (us_selectedDateRangeKey != null) {
                    DatabaseSetMergeDocument(DatabaseRef_LookupTablesDateRanges_Document(res_GCK.clientKey, itemKey, us_selectedDateRangeKey), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: s_FAILED_TO_UPDATE_DATE_RANGE,
                        details: s_MISSING_REQUIRED_PARAMETERS,
                        code: 'ER-D-CL-EDRD-01',
                      },
                    })
                  }
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_CREATE_DATE_CUTOFF,
          formDialogIcon: <Icon icon="pen-to-square" />,
        },
      },
    })
  }

  const deleteDateRangeDate = (): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        icon: <Icon icon="trash" />,
        header: s_DELETE_DATE_RANGE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_DATE_RANGE,
        submit_text: s_DELETE,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            let updateObject: TsInterface_UnspecifiedObject = {}
            updateObject = {
              status: 'deleted',
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                if (us_selectedDateRangeKey != null) {
                  DatabaseSetMergeDocument(DatabaseRef_LookupTablesDateRanges_Document(res_GCK.clientKey, itemKey, us_selectedDateRangeKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                      us_setSelectedDateRangeKey(null)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                } else {
                  reject({
                    success: false,
                    error: {
                      message: s_FAILED_TO_DELETE_DATE_RANGE,
                      details: s_MISSING_REQUIRED_PARAMETERS,
                      code: 'ER-D-CL-DDRD-01',
                    },
                  })
                }
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const downloadExampleCSV = (): void => {
    if (
      us_lookupTable != null &&
      us_lookupTable.table_type != null &&
      lookupTableExampleDownloads != null &&
      lookupTableExampleDownloads[us_lookupTable.table_type] != null
    ) {
      downloadCSV(lookupTableExampleDownloads[us_lookupTable.table_type]['name'], lookupTableExampleDownloads[us_lookupTable.table_type]['example_data'])
    }
  }

  const downloadActualCSV = (): void => {
    let lookupTableType = getProp(us_lookupTable, 'table_type', null)
    downloadCSV(us_lookupTable['name'], returnDataAsDownloadableArray(lookupTableType, us_selectedDateRangeLookupTableData))
  }

  const saveLookupDataToLookupTable = (uploadData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          if (itemKey != null && us_selectedDateRangeKey != null) {
            DatabaseSetReplaceDocument(DatabaseRef_LookupTablesDateRangesData_Document(res_GCK.clientKey, itemKey, us_selectedDateRangeKey), uploadData)
              .then((res_DSMD) => {
                resolve(res_DSMD)
              })
              .catch((rej_DSMD) => {
                reject(rej_DSMD)
              })
          } else {
            reject({ success: false })
          }
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  const handleFileSelection = (data: any): void => {
    // Instantiate variables
    let formattedData: TsInterface_UnspecifiedObject = {}
    us_setParsingData(true)
    // Loop through and format data
    let lookupTableType = getProp(us_lookupTable, 'table_type', null)
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      if (data != null && data[rowIndex] != null) {
        let loopRow = data[rowIndex]
        switch (lookupTableType) {
          case 'identical_lookup':
            if (loopRow.length === 2 && loopRow[0] != null && loopRow[1] != null) {
              formattedData[loopRow[0].toString()] = loopRow[1]
            } else {
              console.error(loopRow[0])
              console.error(loopRow[1])
            }
            break
          case 'range_lookup':
            if (
              loopRow.length === 3 &&
              loopRow[0] != null &&
              !isNaN(parseFloat(loopRow[0])) &&
              loopRow[1] != null &&
              !isNaN(parseFloat(loopRow[1])) &&
              loopRow[2] != null
            ) {
              formattedData[loopRow[0].toString() + '~' + loopRow[1].toString()] = loopRow[2]
            }
            break
          case 'matrix_lookup':
            if (loopRow.length === 3 && loopRow[0] != null && loopRow[1] != null && loopRow[2] != null) {
              formattedData[loopRow[0].toString().replace(/~/g, '') + '~' + loopRow[1].toString().replace(/~/g, '')] = loopRow[2]
            }
            break
        }
      }
    }
    saveLookupDataToLookupTable(formattedData)
      .then(() => {
        us_setParsingData(false)
      })
      .catch(() => {
        us_setParsingData(false)
      })
  }

  const performTestLookup = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        returnSearchValueFromLookupTable(res_GCK.clientKey, itemKey, new Date(us_testLookupDate), us_testLookupInput1, us_testLookupInput2)
          .then((searchResult) => {
            us_setLookupSearchResult(getProp(searchResult, 'value', null))
          })
          .catch((searchReject) => {
            us_setLookupSearchResult(null)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: searchReject.error })
          })
      })
      .catch((rej_GCK) => {
        us_setLookupSearchResult(null)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  // Call Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="outlined"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          un_routerNavigation(ApplicationPages.LookupTablesIndexPage.url())
        }}
        startIcon={<Icon icon="angle-left" />}
      >
        {rLIB('Back to all Lookup Tables')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_LineItem = (propName: JSX.Element, data: TsInterface_UnspecifiedObject, propKey: string, editable: boolean): JSX.Element => {
    let lineJSX = <></>
    if (data == null) {
      data = {}
    }
    let propValue = getProp(data, propKey, null)
    let propJSX = <Box className="tw-inline-block tw-italic tw-opacity-30 tw-mr-2">{s_MISSING}</Box>
    if (propValue != null) {
      propJSX = <Box className="tw-inline-block tw-opacity-70 tw-mr-2">{propValue}</Box>
    }
    let editJSX = <></>
    if (editable === true) {
      editJSX = (
        <Box
          className="tw-inline-block tw-cursor-pointer"
          sx={{
            'color': themeVariables.success_light,
            '&:hover': {
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            uc_setUserInterface_PromptDialogDisplay({
              display: true,
              prompt: {
                color: 'success',
                confirm_text: <>{s_UPDATE}</>,
                default_value: propValue,
                header: (
                  <>
                    {s_UPDATE}: {propName}
                  </>
                ),
                icon: (
                  <Icon
                    icon="pen-to-square"
                    type="solid"
                  />
                ),
                input_label: propName,
                input_type: 'text',
                text: (
                  <>
                    {s_ENTER_A_NEW_VALUE_FOR} {propName}
                  </>
                ),
                submit_callback: (promptValue: string) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {}
                    updateObject[propKey] = promptValue
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_LookupTable_Document(res_GCK.clientKey, itemKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
            })
          }}
        >
          <Icon icon="pen-to-square" />
        </Box>
      )
    }
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box className="tw-inline-block tw-mr-2 tw-font-bold">{propName}:</Box>
          {propJSX}
          {editJSX}
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_LookupTableTypeLineItem = (): JSX.Element => {
    let lineItem = <></>
    let propJSX = <Box className="tw-inline-block tw-italic tw-opacity-30 tw-mr-2">{s_MISSING}</Box>
    if (us_lookupTable != null && us_lookupTable.table_type != null) {
      if (lookupTableTypeOptions != null && lookupTableTypeOptions[us_lookupTable.table_type] != null) {
        propJSX = <Box className="tw-inline-block tw-opacity-70 tw-mr-2">{lookupTableTypeOptions[us_lookupTable.table_type].value}</Box>
      } else {
        propJSX = <Box className="tw-inline-block tw-opacity-70 tw-mr-2">{us_lookupTable.table_type}</Box>
      }
    }
    let editIcon = (
      <Box className="tw-inline-block tw-opacity-30">
        <Icon icon="pen-to-square" />
      </Box>
    )
    if (us_lookupTable != null && us_lookupTable.table_type == null) {
      editIcon = (
        <Box
          className="tw-inline-block tw-cursor-pointer"
          sx={{
            'color': themeVariables.success_light,
            '&:hover': {
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            editLookupTableType()
          }}
        >
          <Icon icon="pen-to-square" />
        </Box>
      )
    }
    lineItem = (
      <Box>
        <Box>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-2 tw-font-bold">{s_LOOKUP_TABLE_TYPE}:</Box>
            {propJSX}
            {editIcon}
          </Typography>
        </Box>
      </Box>
    )
    return lineItem
  }

  const rJSX_ContentContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_loadedCalculationData === true) {
      containerJSX = (
        <Box>
          <Box className="tw-mt-4">
            <Typography
              variant="h5"
              className="tw-font-bold tw-inline-block"
            >
              {s_LOOKUP_TABLE}
            </Typography>
          </Box>
          <Card className="tw-p-4 tw-mb-2">
            {rJSX_LineItem(s_LOOKUP_TABLE, us_lookupTable, 'name', false)}
            {rJSX_LineItem(s_DESCRIPTION, us_lookupTable, 'description', true)}
            {rJSX_LineItem(s_CREATED_BY, us_lookupTable, 'associated_creator_name', false)}
            {rJSX_LookupTableTypeLineItem()}
          </Card>
        </Box>
      )
    } else {
      containerJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_lookupTable != null && us_lookupTable.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{s_LOOKUP_TABLE}:</Box>
          <Box
            className="tw-inline-block"
            sx={{ color: themeVariables.error_main }}
          >
            {us_lookupTable.name}
          </Box>
        </Box>
      )
    } else {
      pageHeader = s_LOOKUP_TABLE
    }
    return pageHeader
  }

  const rJSX_SearchResult = (): JSX.Element => {
    let resultJSX = <></>
    if (us_lookupSearchResult != null) {
      resultJSX = (
        <Box className="tw-inline-block">
          <Typography
            variant="h6"
            className="tw-inline-block tw-mr-2 tw-font-bold"
          >
            {s_SEARCH_RESULT}:
          </Typography>
          <Typography
            variant="h6"
            className="tw-inline-block"
          >
            {us_lookupSearchResult}
          </Typography>
        </Box>
      )
    }
    return resultJSX
  }

  const rJSX_TestLookupSection = (): JSX.Element => {
    let sectionJSX = <></>
    let firstVariableLabel = s_INPUT_VALUE
    let secondVariableInput = <></>
    if (us_lookupTable != null && us_lookupTable.table_type === 'matrix_lookup') {
      firstVariableLabel = s_PRIMARY_INPUT_VALUE
      secondVariableInput = (
        <FormControl
          className=""
          sx={{ minWidth: '130px', marginRight: '12px' }}
        >
          <TextField
            className="bp_thin_text_input"
            color="primary"
            label={s_SECONDARY_INPUT_VALUE}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setTestLookupInput2(event.target.value)
              }
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={us_testLookupInput2 || ''}
          />
        </FormControl>
      )
    }
    sectionJSX = (
      <Box>
        <Box className="tw-mt-8">
          <Typography
            variant="h5"
            className="tw-font-bold tw-inline-block"
          >
            {s_TEST_LOOKUP}
          </Typography>
        </Box>
        <Card className="tw-mb-2 tw-p-4 tw-align-top">
          <FormControl
            sx={{ minWidth: '130px' }}
            className="tw-mt-0 tw-mr-2"
          >
            <TextField
              className="bp_thin_text_input"
              sx={{ marginTop: '0px' }}
              color="primary"
              value={us_testLookupDate}
              label={s_DATE}
              margin="normal"
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setTestLookupDate(event.target.value)
                }
              }}
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl
            className=""
            sx={{ minWidth: '130px', marginRight: '12px' }}
          >
            <TextField
              className="bp_thin_text_input"
              color="primary"
              label={firstVariableLabel}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setTestLookupInput1(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={us_testLookupInput1 || ''}
            />
          </FormControl>
          {secondVariableInput}
          <Button
            color="warning"
            variant="contained"
            className="tw-mr-2"
            onClick={() => {
              performTestLookup()
            }}
          >
            <Icon
              icon="magnifying-glass"
              className="tw-mr-2"
            />
            {s_SEARCH}
          </Button>
          {rJSX_SearchResult()}
        </Card>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_DateButton = (allDateData: TsInterface_UnspecifiedObject[], dateData: TsInterface_UnspecifiedObject, index: number): JSX.Element => {
    let buttonJSX = <></>
    let buttonVariant: 'outlined' | 'contained' = 'outlined'
    if (us_selectedDateRangeKey === dateData.key) {
      buttonVariant = 'contained'
    }
    if (index === 0) {
      buttonJSX = (
        <Box>
          <Button
            size="small"
            color="info"
            variant={buttonVariant}
            className="tw-mr-0.25 tw-mb-0.25"
            onClick={() => {
              if (us_selectedDateRangeKey === dateData.key) {
                us_setSelectedDateRangeKey(null)
              } else {
                us_setSelectedDateRangeKey(dateData.key)
              }
            }}
          >
            {s_BEGINNING} - {returnFormattedDate(dateData.date, 'YYYY-MM-DD')}
          </Button>
        </Box>
      )
    } else {
      let previousDate = allDateData[index - 1].date
      buttonJSX = (
        <Box>
          <Button
            size="small"
            color="info"
            variant={buttonVariant}
            className="tw-mr-0.25 tw-mb-0.25"
            onClick={() => {
              if (us_selectedDateRangeKey === dateData.key) {
                us_setSelectedDateRangeKey(null)
              } else {
                us_setSelectedDateRangeKey(dateData.key)
              }
            }}
          >
            {returnFormattedDate(previousDate, 'YYYY-MM-DD')} - {returnFormattedDate(dateData.date, 'YYYY-MM-DD')}
          </Button>
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_DateCutoffsList = (): JSX.Element => {
    let listJSX = <></>
    if (objectToArray(us_lookupTableDateRangeCutoffs).length === 0) {
      listJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">{s_NO_DATE_RANGES_ADDED_YET}</Typography>
        </Box>
      )
    } else {
      let sortedDateData = objectToArray(us_lookupTableDateRangeCutoffs).sort(dynamicSort('date', null))
      listJSX = (
        <Box className="tw-text-left">
          {sortedDateData.map((dateData: TsInterface_UnspecifiedObject, index: number) => (
            <Box
              className="tw-mr-2 tw-inline-block"
              key={index}
            >
              {rJSX_DateButton(sortedDateData, dateData, index)}
            </Box>
          ))}
        </Box>
      )
    }
    return listJSX
  }

  const rJSX_TableDateSection = (): JSX.Element => {
    let sectionJSX = (
      <Box>
        <Box className="tw-mt-8">
          <Typography
            variant="h5"
            className="tw-font-bold tw-inline-block"
          >
            {s_DATE_CUTOFFS}
          </Typography>
        </Box>
        <Card className="tw-mb-2 tw-p-4 tw-align-top">
          <Button
            color="success"
            variant="contained"
            className=""
            onClick={() => {
              addDateRange()
            }}
          >
            <Icon
              icon="circle-plus"
              className="tw-mr-2"
            />
            {s_CREATE_DATE_CUTOFF}
          </Button>
          <Divider className="tw-my-2" />
          <Box className="tw-mt-2">{rJSX_DateCutoffsList()}</Box>
        </Card>
      </Box>
    )
    return sectionJSX
  }

  const rJSX_LookupTableRender = (): JSX.Element => {
    let tableJSX = <></>
    let lookupTableType = getProp(us_lookupTable, 'table_type', null)
    let formattedData = returnDataInRenderTableFormat(lookupTableType, us_selectedDateRangeLookupTableData)
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      show_header: true,
      size: 'small',
      sortable: false,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (objectToArray(us_selectedDateRangeLookupTableData).length > 0) {
      switch (lookupTableType) {
        case 'identical_lookup':
          tableJSX = (
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={{
                lookup: TableCellBasic('lookup', <>{s_LOOKUP_VALUE}</>, 'lookup'),
                result: TableCellBasic('result', <>{s_RESULT}</>, 'result'),
              }}
              tableData={formattedData}
              tableSettings={tableSettings}
            />
          )
          break
        case 'range_lookup':
          tableJSX = (
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={{
                lookup_start: TableCellBasic(
                  'lookup_start',
                  <Box>
                    <Box>{s_LOOKUP_RANGE_START}</Box>
                    <Box className="tw-opacity-30 tw-italic">{s_GREATER_THAN_OR_EQUAL_TO} (&ge;)</Box>
                  </Box>,
                  'lookup_start',
                ),
                lookup_end: TableCellBasic(
                  'lookup_end',
                  <Box>
                    <Box>{s_LOOKUP_RANGE_END}</Box>
                    <Box className="tw-opacity-30 tw-italic">
                      {s_LESS_THAN} ({'<'})
                    </Box>
                  </Box>,
                  'lookup_end',
                ),
                result: TableCellBasic('result', <>{s_RESULT}</>, 'result'),
              }}
              tableData={formattedData}
              tableSettings={tableSettings}
            />
          )
          break
        case 'matrix_lookup':
          // eslint-disable-next-line no-case-declarations
          let formattedMatrixData = returnDataInRenderTableFormat(lookupTableType, us_selectedDateRangeLookupTableData)
          tableJSX = (
            <TableContainer className="bp_show_horizontal_scroll">
              <Table>
                <TableBody>
                  {formattedMatrixData.map((row: any, rowIndex: number) => (
                    <TableRow key={rowIndex}>
                      {objectToArray(row).map((column: any, columnIndex: number) => (
                        <TableCell
                          key={columnIndex}
                          sx={{ fontWeight: rowIndex === 0 || columnIndex === 0 ? '700' : '400' }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
          break
      }
    } else {
      tableJSX = (
        <Box className="tw-text-center tw-pb-4 tw-mx-4">
          <Typography variant="h6">{s_NO_LOOKUP_DATA_UPLOADED_YET}</Typography>
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_LookupTableContainerProper = (): JSX.Element => {
    let containerJSX = <></>
    if (us_selectedDateRangeKey != null) {
      containerJSX = (
        <Box>
          <Box className="tw-mt-8">
            <Typography
              variant="h5"
              className="tw-font-bold tw-inline-block"
            >
              {s_LOOKUP_TABLE}
            </Typography>
          </Box>
          <Card className="tw-mb-2 tw-align-top">
            <Box className="tw-p-4">
              <CSVReader
                // https://react-papaparse.js.org/docs#config
                // https://github.com/Bunlong/react-papaparse/blob/v4.0.0/examples/CSVReaderBasicUpload.tsx
                onUploadAccepted={(results: any) => {
                  handleFileSelection(results.data)
                }}
                noDrag
              >
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
                  <Button
                    color="success"
                    variant="contained"
                    className="tw-mr-2 tw-mb-2"
                    disabled={us_parsingData === true}
                    {...getRootProps()}
                  >
                    <Icon
                      icon="cloud-arrow-up"
                      className="tw-mr-2"
                    />
                    {s_UPLOAD_LOOKUP_TABLE}
                  </Button>
                )}
              </CSVReader>
              <Button
                color="info"
                variant="contained"
                disabled={objectToArray(us_selectedDateRangeLookupTableData).length === 0}
                className="tw-mr-2 tw-mb-2"
                onClick={() => {
                  downloadActualCSV()
                }}
              >
                <Icon
                  icon="cloud-arrow-down"
                  className="tw-mr-2"
                />
                {s_DOWNLOAD_TABLE}
              </Button>
              <Button
                color="info"
                variant="outlined"
                className="tw-mr-2 tw-mb-2"
                onClick={() => {
                  downloadExampleCSV()
                }}
              >
                <Icon
                  icon="cloud-arrow-down"
                  className="tw-mr-2"
                />
                {s_DOWNLOAD_EXAMPLE}
              </Button>
              <Button
                color="success"
                variant="outlined"
                className="tw-mr-2 tw-mb-2"
                onClick={() => {
                  editDateRangeDate()
                }}
              >
                <Icon
                  icon="pen-to-square"
                  className="tw-mr-2"
                />
                {s_EDIT_DATE}
              </Button>
              <Button
                color="error"
                variant="outlined"
                className="tw-mr-2 tw-mb-2"
                onClick={() => {
                  deleteDateRangeDate()
                }}
              >
                <Icon
                  icon="trash"
                  className="tw-mr-2"
                />
                {s_DELETE_RANGE}
              </Button>
            </Box>
            {rJSX_LookupTableRender()}
          </Card>
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_LookupTableContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_lookupTable != null && us_lookupTable.table_type != null) {
      containerJSX = (
        <Box>
          {rJSX_TestLookupSection()}
          {rJSX_TableDateSection()}
          {rJSX_LookupTableContainerProper()}
        </Box>
      )
    } else {
      containerJSX = (
        <Box className="tw-text-center tw-mb-2">
          <Button
            color="success"
            variant="contained"
            className=""
            onClick={() => {
              editLookupTableType()
            }}
          >
            <Icon
              icon="pen-to-square"
              className="tw-mr-2"
            />
            {s_SELECT_LOOKUP_TABLE_TYPE}
          </Button>
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={
          <>
            {rLIB('Lookup Table')}
            {': ' + getProp(us_lookupTable, 'name', '')}
          </>
        }
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_BackButton()}
            <Box
              className="tw-m-auto"
              sx={{ maxWidth: '1000px' }}
            >
              {rJSX_ContentContainer()}
              <Box>{rJSX_LookupTableContainer()}</Box>
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
