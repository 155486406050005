///////////////////////////////
// Description
///////////////////////////////

// TODO:
// Keep nesting as simple as possible
// Only allow for one level of nesting

// V1 Components
// Static Text
// Variable Text
// Static Tables - Only allow text or images in cells - no nested tables
// Static Images

// SKIP - disable all menu items and selection of these components
// Looped Data Tables
// View Containers

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { Document, Image, Page, PDFViewer, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, getProp, mergeTwoObjects, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from '../form'
import { Icon } from '../icons'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_PdfTemplateComponent_StaticTable {
  component_type: 'static_table'
  order: number
  table_style?: TsInterface_UnspecifiedObject
  rows: {
    [key: string]: {
      row_style?: TsInterface_UnspecifiedObject
      order: number
      cells: {
        [key: string]: {
          cell_style?: TsInterface_UnspecifiedObject
          order: number
          contents: {
            [key: string]: TsType_PdfTemplateComponent
          }
        }
      }
    }
  }
}

interface TsInterface_PdfTemplateComponent_LoopedDataTable {
  component_type: 'looped_data_table'
  order: number
  table_style?: TsInterface_UnspecifiedObject
  table_data_variable_mapping_key: string
  row_style?: TsInterface_UnspecifiedObject
  columns: {
    [key: string]: {
      order: number
      column_header_style?: TsInterface_UnspecifiedObject
      column_body_style?: TsInterface_UnspecifiedObject
      header_text: string
      variable_mapping_key: string
      fallback_text?: string
      fallback_text_style?: TsInterface_UnspecifiedObject
    }
  }
  // TODO
}

interface TsInterface_PdfTemplateComponent_StaticImage {
  component_type: 'static_image'
  order: number
  image_style?: TsInterface_UnspecifiedObject
  src: string
}

interface TsInterface_PdfTemplateComponent_StaticText {
  component_type: 'static_text'
  order: number
  text_style?: TsInterface_UnspecifiedObject
  text: string
}

interface TsInterface_PdfTemplateComponent_VariableText {
  component_type: 'variable_text'
  order: number
  text_style?: TsInterface_UnspecifiedObject
  variable_mapping_key: string
  fallback_text?: string
  fallback_text_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_PdfTemplateComponent_View {
  component_type: 'view'
  order: number
  view_style?: TsInterface_UnspecifiedObject
  contents: {
    [key: string]: TsType_PdfTemplateComponent
  }
}

type TsType_PdfTemplateComponent =
  | TsInterface_PdfTemplateComponent_StaticTable
  | TsInterface_PdfTemplateComponent_LoopedDataTable
  | TsInterface_PdfTemplateComponent_StaticImage
  | TsInterface_PdfTemplateComponent_StaticText
  | TsInterface_PdfTemplateComponent_VariableText
  | TsInterface_PdfTemplateComponent_View

export interface TsInterface_PdfTemplate {
  page_settings: {
    flexDirection: string
    paddingTop: string | number
    paddingBottom: string | number
    paddingLeft: string | number
    paddingRight: string | number
  }
  page_contents: {
    [key: string]: TsType_PdfTemplateComponent
  }
}

///////////////////////////////
// Variables
///////////////////////////////

const cssFormProperties: TsInterface_UnspecifiedObject = {
  //-------------------------
  // FLEXBOX
  //-------------------------
  // alignContent
  // alignItems
  // alignSelf
  // flex
  // flexDirection
  // flexWrap
  // flexFlow
  // flexGrow
  // flexShrink
  // flexBasis
  // justifyContent
  //-------------------------
  // LAYOUT
  //-------------------------
  // bottom
  // display
  // left
  // position
  // right
  // top
  // overflow
  // zIndex
  //-------------------------
  // DIMENSION
  //-------------------------
  // height
  // maxHeight
  // maxWidth
  // minHeight
  // minWidth
  // width
  //-------------------------
  // COLOR
  //-------------------------
  // backgroundColor
  // color
  // opacity
  //-------------------------
  // TEXT
  //-------------------------
  // fontSize
  fontSize: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'fontSize',
      label: rLIB('Font Size'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // fontFamily
  // fontStyle
  // fontWeight
  // letterSpacing
  // lineHeight
  // maxLines
  // textAlign
  // textDecoration
  // textDecorationColor
  // textDecorationStyle
  // textIndent
  // textOverflow
  // textTransform
  //-------------------------
  // SIZING/POSITIONING
  //-------------------------
  // object-fit
  // object-position
  //-------------------------
  // MARGIN/PADDING
  //-------------------------
  // margin
  // marginHorizontal
  // marginVertical
  marginTop: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'marginTop',
      label: rLIB('Margin Top'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  marginRight: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'marginRight',
      label: rLIB('Margin Right'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  marginBottom: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'marginBottom',
      label: rLIB('Margin Bottom'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  marginLeft: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'marginLeft',
      label: rLIB('Margin Left'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // padding
  // paddingHorizontal
  // paddingVertical
  paddingTop: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'paddingTop',
      label: rLIB('Padding Top'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // paddingRight
  paddingRight: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'paddingRight',
      label: rLIB('Padding Right'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // paddingBottom
  paddingBottom: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'paddingBottom',
      label: rLIB('Padding Bottom'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  // paddingLeft
  paddingLeft: {
    allowed_components: {
      static_table: false,
      looped_data_table: false,
      static_image: false,
      static_text: true,
      variable_text: true,
      view: false,
    },
    isNumber: true,
    input: {
      key: 'paddingLeft',
      label: rLIB('Padding Left'),
      input_type: 'text_number',
      data_type: 'number',
      required: false,
    },
  },
  //-------------------------
  // TRANSFORMATIONS
  //-------------------------
  // transform:rotate
  // transform:scale
  // transform:scaleX
  // transform:scaleY
  // transform:translate
  // transform:translateX
  // transform:translateY
  // transform:skew
  // transform:skewX
  // transform:skewY
  // transform:matrix
  // transformOrigin
  //-------------------------
  // BORDERS
  //-------------------------
  // border
  // borderColor
  // borderStyle
  // borderWidth
  // borderTop
  // borderTopColor
  // borderTopStyle
  // borderTopWidth
  // borderRight
  // borderRightColor
  // borderRightStyle
  // borderRightWidth
  // borderBottom
  // borderBottomColor
  // borderBottomStyle
  // borderBottomWidth
  // borderLeft
  // borderLeftColor
  // borderLeftStyle
  // borderLeftWidth
  // borderTopLeftRadius
  // borderTopRightRadius
  // borderBottomRightRadius
  // borderBottomLeftRadius
}

///////////////////////////////
// Functions
///////////////////////////////

const generatePdfComponent_StaticTable = (
  component: TsInterface_PdfTemplateComponent_StaticTable,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = (
      <View style={getProp(component, 'table_style', {})}>
        {objectToArray(component.rows)
          .sort(dynamicSort('order', 'asc'))
          .map((row: TsInterface_UnspecifiedObject, rowIndex: number) => (
            <View
              key={rowIndex}
              style={getProp(row, 'row_style', {})}
            >
              {objectToArray(row.cells)
                .sort(dynamicSort('order', 'asc'))
                .map((cell: TsInterface_UnspecifiedObject, cellIndex: number) => (
                  <View
                    key={cellIndex}
                    style={getProp(cell, 'cell_style', {})}
                  >
                    {objectToArray(cell.contents)
                      .sort(dynamicSort('order', 'asc'))
                      .map((component: TsType_PdfTemplateComponent, componentIndex: number) => (
                        <React.Fragment key={componentIndex}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
                      ))}
                  </View>
                ))}
            </View>
          ))}
      </View>
    )
  } else if (mode === 'edit') {
    // TODO: Edit Mode
  }
  return componentJSX
}

const generatePdfComponent_LoopedDataTable = (
  component: TsInterface_PdfTemplateComponent_LoopedDataTable,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let tableData = getProp(pdfData, component.table_data_variable_mapping_key, [])
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = (
      <View style={getProp(component, 'table_style', {})}>
        <View style={getProp(component, 'row_style', {})}>
          {objectToArray(getProp(component, 'columns', {}))
            .sort(dynamicSort('order', 'asc'))
            .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
              <View
                style={getProp(column, 'column_header_style', {})}
                key={columnIndex}
              >
                <Text>{column.header_text}</Text>
              </View>
            ))}
        </View>
        {objectToArray(tableData).map(
          (
            rowData: TsInterface_UnspecifiedObject,
            rowIndex: number, // TODO: Add Sorting?
          ) => (
            <View
              style={getProp(component, 'row_style', {})}
              key={rowIndex}
            >
              {objectToArray(component.columns)
                .sort(dynamicSort('order', 'asc'))
                .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
                  <View
                    style={getProp(column, 'column_body_style', {})}
                    key={columnIndex}
                  >
                    {generatePdfComponent_VariableText(column as TsInterface_PdfTemplateComponent_VariableText, rowData, mode, editHooks)}
                  </View>
                ))}
            </View>
          ),
        )}
      </View>
    )
  } else if (mode === 'edit') {
    // TODO: Edit Mode
  }
  return componentJSX
}

const generatePdfComponent_StaticImage = (
  component: TsInterface_PdfTemplateComponent_StaticImage,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = (
      <Image
        src={component.src}
        style={getProp(component, 'image_style', {})}
      />
    )
  } else if (mode === 'edit') {
    // TODO: Edit Mode
  }
  return componentJSX
}

const generatePdfComponent_StaticText = (
  component: TsInterface_PdfTemplateComponent_StaticText,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = <Text style={getProp(component, 'text_style', {})}>{component.text}</Text>
  } else if (mode === 'edit') {
    componentJSX = (
      <Box>
        <Box
          className="tw-cursor-pointer tw-m-2"
          sx={{
            'border': '1px solid rgba(0,0,0,0)',
            '&:hover': {
              border: '1px solid ' + themeVariables.warning_main,
            },
          }}
          onClick={(event) => {
            event.stopPropagation()
            if (editHooks != null && editHooks.us_setAnchorPosition != null) {
              editHooks.us_setSelectedComponentKey(getProp(component, 'key', null))
              editHooks.us_setSelectedComponentType('static_text')
              editHooks.us_setAnchorPosition({
                top: event.clientY,
                left: event.clientX,
              })
            }
          }}
        >
          <Typography style={getProp(component, 'text_style', {})}>{component.text}</Typography>
        </Box>
      </Box>
    )
  }
  return componentJSX
}

const generatePdfComponent_VariableText = (
  component: TsInterface_PdfTemplateComponent_VariableText,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    if (pdfData != null && component.variable_mapping_key != null && pdfData[component.variable_mapping_key] != null) {
      componentJSX = <Text style={getProp(component, 'text_style', {})}>{pdfData[component.variable_mapping_key]}</Text>
    } else if (component.fallback_text != null) {
      componentJSX = <Text style={getProp(component, 'fallback_text_style', getProp(component, 'text_style', {}))}>{component.fallback_text}</Text>
    }
  } else if (mode === 'edit') {
    // TODO: Edit Mode
  }
  return componentJSX
}

const generatePdfComponent_View = (
  component: TsInterface_PdfTemplateComponent_View,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  if (mode === 'view') {
    componentJSX = (
      <View style={getProp(component, 'view_style', {})}>
        {objectToArray(component.contents)
          .sort(dynamicSort('order', 'asc'))
          .map((component: TsType_PdfTemplateComponent, index: number) => (
            <React.Fragment key={index}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
          ))}
      </View>
    )
  } else if (mode === 'edit') {
    // TODO: Edit Mode
  }
  return componentJSX
}

const generatePdfComponent = (
  component: TsType_PdfTemplateComponent,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let componentJSX = <></>
  switch (component.component_type) {
    case 'view':
      componentJSX = generatePdfComponent_View(component as TsInterface_PdfTemplateComponent_View, pdfData, mode, editHooks)
      break
    case 'static_table':
      componentJSX = generatePdfComponent_StaticTable(component as TsInterface_PdfTemplateComponent_StaticTable, pdfData, mode, editHooks)
      break
    case 'looped_data_table':
      componentJSX = generatePdfComponent_LoopedDataTable(component as TsInterface_PdfTemplateComponent_LoopedDataTable, pdfData, mode, editHooks)
      break
    case 'static_image':
      componentJSX = generatePdfComponent_StaticImage(component as TsInterface_PdfTemplateComponent_StaticImage, pdfData, mode, editHooks)
      break
    case 'static_text':
      componentJSX = generatePdfComponent_StaticText(component as TsInterface_PdfTemplateComponent_StaticText, pdfData, mode, editHooks)
      break
    case 'variable_text':
      componentJSX = generatePdfComponent_VariableText(component as TsInterface_PdfTemplateComponent_VariableText, pdfData, mode, editHooks)
      break
  }
  return componentJSX
}

// Create Elements
const openNewRootPdfElementDialog = (uc_setUserInterface_FormDialogDisplay: any, onChange: any, pdfTemplate: TsInterface_UnspecifiedObject) => {
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: {},
        formInputs: {
          mapped_data_field_key: {
            key: 'mapped_data_field_key',
            label: rLIB('New PDF Element Type'),
            input_type: 'multiple_choice_radio',
            required: true,
            data_type: 'string',
            options: [
              { key: 'static_table', value: rLIB('Static Table'), disabled: true }, // TODO - build
              { key: 'looped_data_table', value: rLIB('Looped Data Table'), disabled: true },
              { key: 'static_image', value: rLIB('Static Image'), disabled: true },
              { key: 'static_text', value: rLIB('Static Text') },
              { key: 'variable_text', value: rLIB('Variable Text'), disabled: true },
              { key: 'view', value: rLIB('Container'), disabled: true },
            ],
          },
          text: {
            key: 'text',
            label: rLIB('Text'),
            input_type: 'text_basic',
            data_type: 'string',
            hidden: true,
            required: false,
            conditional_display: {
              active: true,
              logic: {
                active: true,
                logic_type: 'comparison',
                source: 'formData',
                prop: 'mapped_data_field_key',
                comparator: '==',
                value: 'static_text',
                conditions: [],
              },
            },
            conditional_require: {
              active: true,
              logic: {
                active: true,
                logic_type: 'comparison',
                source: 'formData',
                prop: 'mapped_data_field_key',
                comparator: '==',
                value: 'static_text',
                conditions: [],
              },
            },
          },
          number_of_columns: {
            key: 'number_of_columns',
            label: rLIB('Number of Columns'),
            input_type: 'text_number',
            data_type: 'number',
            hidden: true,
            required: false,
            conditional_display: {
              active: true,
              logic: {
                active: true,
                logic_type: 'comparison',
                source: 'formData',
                prop: 'mapped_data_field_key',
                comparator: '==',
                value: 'static_table',
                conditions: [],
              },
            },
            conditional_require: {
              active: true,
              logic: {
                active: true,
                logic_type: 'comparison',
                source: 'formData',
                prop: 'mapped_data_field_key',
                comparator: '==',
                value: 'static_table',
                conditions: [],
              },
            },
          },
        },
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {
          // hide_submit_button: true,
        },
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let componentKey = new Date().getTime().toString()
            let matchingComponentType = false
            switch (formSubmittedData.mapped_data_field_key) {
              case 'static_table':
                pdfTemplate.page_contents[componentKey] = {
                  component_type: 'static_table',
                  order: new Date().getTime(),
                  key: componentKey,
                  // table_style: {},
                  // rows: {},
                }
                matchingComponentType = true
                break
              case 'looped_data_table':
                // TODO: Implement
                break
              case 'static_image':
                // TODO: Implement
                break
              case 'static_text':
                pdfTemplate.page_contents[componentKey] = {
                  component_type: 'static_text',
                  order: new Date().getTime(),
                  key: componentKey,
                  text: getProp(formSubmittedData, 'text', ''),
                  text_style: {},
                }
                matchingComponentType = true
                break
              case 'variable_text':
                // TODO: Implement
                break
              case 'view':
                // TODO: Implement
                break
            }
            // TODO: Pass through whole PDF Template Document
            if (matchingComponentType === true) {
              onChange(pdfTemplate)
                .then((res_OC: any) => {
                  resolve(res_OC)
                })
                .catch((rej_OC: any) => {
                  reject(rej_OC)
                })
            }
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('New PDF Template Element')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="circle-plus"
          />
        ),
      },
    },
  })
}

// Edit - Shared Elements // TODO: some might only work for text - figure out later
const determineComponentKeyPath = (componentKeyPath: string[], checkComponentKey: string, editedComponentKey: string) => {
  if (checkComponentKey === editedComponentKey) {
    // Matching Component Key - Add to Path and Return
    componentKeyPath.push(checkComponentKey)
    return componentKeyPath
  } else {
    // TODO: Handle Nested Components - just static tables for now...
    return componentKeyPath
  }
}

const generateNestedData = (arr: any[], key: any, value: any) => {
  return arr.reduceRight((acc, current) => ({ [current]: acc }), { [key]: value })
}

const generateDeletedNestedData = (template: TsInterface_PdfTemplate, pathArray: any[]): TsInterface_UnspecifiedObject => {
  // Start with the reference to the original object
  let current: TsInterface_UnspecifiedObject = template
  pathArray.unshift('page_contents')
  // Iterate over the pathArray, except for the last element
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i]
    // If the key doesn't exist, create an empty object at that key
    if (!current[key]) {
      current[key] = {}
    }
    // Move deeper into the object
    current = current[key]
  }
  // Set the value at the last key in the path
  delete current[pathArray[pathArray.length - 1]]
  // Return the modified object
  return template
}

const getNestedData = (arr: any[], obj: any) => {
  return arr.reduce((acc, current) => acc && acc[current], obj)
}

const generatePdfComponentUpdateObject = (
  componentKey: string,
  template: TsInterface_PdfTemplate,
  propKey: string,
  propValue: any,
): TsInterface_UnspecifiedObject => {
  let updateObject: TsInterface_UnspecifiedObject = {}
  if (template != null && template.page_contents != null) {
    for (let loopComponentKey in template.page_contents) {
      // Determine Component Key Path
      let potentialComponentKeyPath = determineComponentKeyPath([], loopComponentKey, componentKey)
      if (potentialComponentKeyPath.length > 0) {
        updateObject = {
          page_contents: generateNestedData(potentialComponentKeyPath, propKey, propValue),
        }
      }
    }
  }
  return updateObject
}

const generatePdfComponentDeleteObject = (componentKey: string, template: TsInterface_PdfTemplate): TsInterface_UnspecifiedObject => {
  let updateObject: TsInterface_UnspecifiedObject = {}
  if (template != null && template.page_contents != null) {
    for (let loopComponentKey in template.page_contents) {
      // Determine Component Key Path
      let potentialComponentKeyPath = determineComponentKeyPath([], loopComponentKey, componentKey)
      if (potentialComponentKeyPath.length > 0) {
        // let parentComponentPath = potentialComponentKeyPath.slice(0, -1)
        updateObject = generateDeletedNestedData({ ...template }, potentialComponentKeyPath)
      }
    }
  }
  return updateObject
}

const generatePdfComponentSwitchUpdateObject = (
  componentKey1: string,
  componentKey2: string,
  template: TsInterface_PdfTemplate,
  propKey: string,
  propValue1: any,
  propValue2: any,
): TsInterface_UnspecifiedObject => {
  let updateObject1: TsInterface_UnspecifiedObject = {}
  let updateObject2: TsInterface_UnspecifiedObject = {}
  if (template != null && template.page_contents != null) {
    for (let loopComponentKey in template.page_contents) {
      // Determine Component Key Path
      let potentialComponentKeyPath1 = determineComponentKeyPath([], loopComponentKey, componentKey1)
      if (potentialComponentKeyPath1.length > 0) {
        updateObject1 = generateNestedData(potentialComponentKeyPath1, propKey, propValue2)
      }
      let potentialComponentKeyPath2 = determineComponentKeyPath([], loopComponentKey, componentKey2)
      if (potentialComponentKeyPath2.length > 0) {
        updateObject2 = generateNestedData(potentialComponentKeyPath2, propKey, propValue1)
      }
    }
  }
  let mergedUpdateObject = {
    page_contents: mergeTwoObjects(updateObject1, updateObject2),
  }
  return mergedUpdateObject
}

const moveElementUp = (editHooks: any, onChange: any) => {
  let pdfTemplate = editHooks.us_pdfTemplate
  // Find Element Path
  for (let loopComponentKey in editHooks.us_pdfTemplate.page_contents) {
    // Determine Component Key Path
    let potentialComponentKeyPath = determineComponentKeyPath([], loopComponentKey, editHooks.us_selectedComponentKey)
    if (potentialComponentKeyPath.length > 0) {
      // Get all components in the same parent
      let parentComponentPath = potentialComponentKeyPath.slice(0, -1)
      let sameLevelComponents = objectToArray(getNestedData(parentComponentPath, editHooks.us_pdfTemplate.page_contents)).sort(dynamicSort('order', 'asc'))
      // Get the index of the selected component
      for (let i = 0; i < sameLevelComponents.length; i++) {
        if (
          sameLevelComponents != null &&
          sameLevelComponents[i] != null &&
          sameLevelComponents[i]['key'] != null &&
          sameLevelComponents[i]['key'] === editHooks.us_selectedComponentKey
        ) {
          // Trade orders with the component above if not the first element
          if (i > 0) {
            let upstreamComponentKey = sameLevelComponents[i - 1]['key']
            let upstreamComponentOrder = sameLevelComponents[i - 1]['order']
            let downstreamComponentKey = sameLevelComponents[i]['key']
            let downstreamComponentOrder = sameLevelComponents[i]['order']
            let updateObject = generatePdfComponentSwitchUpdateObject(
              upstreamComponentKey,
              downstreamComponentKey,
              editHooks.us_pdfTemplate,
              'order',
              upstreamComponentOrder,
              downstreamComponentOrder,
            )
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
          }
        }
      }
    }
  }
}

const moveElementDown = (editHooks: any, onChange: any) => {
  let pdfTemplate = editHooks.us_pdfTemplate
  // Find Element Path
  for (let loopComponentKey in editHooks.us_pdfTemplate.page_contents) {
    // Determine Component Key Path
    let potentialComponentKeyPath = determineComponentKeyPath([], loopComponentKey, editHooks.us_selectedComponentKey)
    if (potentialComponentKeyPath.length > 0) {
      // Get all components in the same parent
      let parentComponentPath = potentialComponentKeyPath.slice(0, -1)
      let sameLevelComponents = objectToArray(getNestedData(parentComponentPath, editHooks.us_pdfTemplate.page_contents)).sort(dynamicSort('order', 'asc'))
      // Get the index of the selected component
      for (let i = 0; i < sameLevelComponents.length; i++) {
        if (
          sameLevelComponents != null &&
          sameLevelComponents[i] != null &&
          sameLevelComponents[i]['key'] != null &&
          sameLevelComponents[i]['key'] === editHooks.us_selectedComponentKey
        ) {
          // Trade orders with the component above if not the first element
          if (i < sameLevelComponents.length - 1) {
            let upstreamComponentKey = sameLevelComponents[i]['key']
            let upstreamComponentOrder = sameLevelComponents[i]['order']
            let downstreamComponentKey = sameLevelComponents[i + 1]['key']
            let downstreamComponentOrder = sameLevelComponents[i + 1]['order']
            let updateObject = generatePdfComponentSwitchUpdateObject(
              upstreamComponentKey,
              downstreamComponentKey,
              editHooks.us_pdfTemplate,
              'order',
              upstreamComponentOrder,
              downstreamComponentOrder,
            )
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
          }
        }
      }
    }
  }
}

const deleteElement = (editHooks: any, onChange: any) => {
  editHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'error',
      icon: <Icon icon="trash" />,
      header: rLIB('Delete Element'),
      text: rLIB('Are you sure that you want to delete this PDF Element? This cannot be undone'),
      submit_text: rLIB('Delete'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          let updateObject = generatePdfComponentDeleteObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate)
          editHooks
            .onChangeWithDelete(updateObject)
            .then((res_OC: any) => {
              resolve(res_OC)
            })
            .catch((rej_OC: any) => {
              reject(rej_OC)
            })
        })
      },
    },
  })
}

// Edit - Page
const openPageSettingsEditDialog = (editHooks: any, onChange: any) => {
  // TODO: Implement
  console.log('Edit Page Margins')
}

// Edit - Text
const openTextContentEditDialog = (editHooks: any, onChange: any) => {
  editHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: {
          text: getProp(editHooks.us_pdfTemplate.page_contents[editHooks.us_selectedComponentKey], 'text', ''),
        },
        formInputs: {
          text: {
            key: 'text',
            label: rLIB('Text'),
            input_type: 'text_basic',
            data_type: 'string',
            required: true,
          },
        },
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let pdfTemplate = editHooks.us_pdfTemplate
            // Generate Update Object
            let updateObject = generatePdfComponentUpdateObject(
              editHooks.us_selectedComponentKey,
              editHooks.us_pdfTemplate,
              'text',
              getProp(formSubmittedData, 'text', ''),
            )
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
              .then((res_OC: any) => {
                resolve(res_OC)
              })
              .catch((rej_OC: any) => {
                reject(rej_OC)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('Edit Text')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const openTextStylesEditDialog = (editHooks: any, onChange: any) => {
  // Generate Form Inputs
  let formInputs: TsInterface_FormInputs = {}
  for (let loopInputKey in cssFormProperties) {
    let loopInput = cssFormProperties[loopInputKey]
    if (loopInput != null && loopInput.allowed_components != null && loopInput.allowed_components.static_text === true) {
      formInputs[loopInputKey] = loopInput.input
    }
  }
  let formData: TsInterface_UnspecifiedObject = { ...getProp(editHooks.us_pdfTemplate.page_contents[editHooks.us_selectedComponentKey], 'text_style', {}) }
  // text_style
  editHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: formData,
        formInputs: formInputs,
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let pdfTemplate = editHooks.us_pdfTemplate
            let initialUpdateObject: TsInterface_UnspecifiedObject = {}
            for (let loopInputKey in formSubmittedData) {
              let loopInputValue = formSubmittedData[loopInputKey]
              if (loopInputValue != null && loopInputValue !== '') {
                if (cssFormProperties[loopInputKey].isNumber === true) {
                  if (!isNaN(loopInputValue)) {
                    initialUpdateObject[loopInputKey] = loopInputValue
                  }
                } else {
                  initialUpdateObject[loopInputKey] = loopInputValue
                }
              }
            }
            // Generate Update Object
            let updateObject = generatePdfComponentUpdateObject(editHooks.us_selectedComponentKey, editHooks.us_pdfTemplate, 'text_style', initialUpdateObject)
            onChange(mergeTwoObjects(updateObject, pdfTemplate))
              .then((res_OC: any) => {
                resolve(res_OC)
              })
              .catch((rej_OC: any) => {
                reject(rej_OC)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: <>{rLIB('Edit Text')}</>,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

// Edit - Menu Generation
const rJSX_editComponentMenu = (editHooks: TsInterface_UnspecifiedObject, onChange: (template: TsInterface_UnspecifiedObject) => Promise<any>) => {
  let editMenuJSX = <></>
  switch (editHooks.us_selectedComponentType) {
    case 'page_settings':
      editMenuJSX = (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            editHooks != null && editHooks.us_anchorPosition !== null
              ? { top: editHooks.us_anchorPosition.top, left: editHooks.us_anchorPosition.left }
              : undefined
          }
          open={Boolean(editHooks.us_anchorPosition)}
          onClose={() => {
            if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
              editHooks.us_setSelectedComponentKey(null)
              editHooks.us_setSelectedComponentType(null)
              editHooks.us_setAnchorPosition(null)
            }
          }}
        >
          <MenuItem
            onClick={() => {
              openPageSettingsEditDialog(editHooks, onChange)
              if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
                editHooks.us_setSelectedComponentKey(null)
                editHooks.us_setSelectedComponentType(null)
                editHooks.us_setAnchorPosition(null)
              }
            }}
          >
            <Icon
              icon="pen-to-square"
              sx={{ marginRight: '8px', width: '24px' }}
            />
            {rLIB('Edit Page Margins')}
          </MenuItem>
        </Menu>
      )
      break
    case 'static_table':
      // TODO: Implement
      break
    case 'looped_data_table':
      // TODO: Implement
      break
    case 'static_image':
      // TODO: Implement
      break
    case 'static_text':
      editMenuJSX = (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            editHooks != null && editHooks.us_anchorPosition !== null
              ? { top: editHooks.us_anchorPosition.top, left: editHooks.us_anchorPosition.left }
              : undefined
          }
          open={Boolean(editHooks.us_anchorPosition)}
          onClose={() => {
            if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
              editHooks.us_setSelectedComponentKey(null)
              editHooks.us_setSelectedComponentType(null)
              editHooks.us_setAnchorPosition(null)
            }
          }}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              openTextContentEditDialog(editHooks, onChange)
              if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
                editHooks.us_setSelectedComponentKey(null)
                editHooks.us_setSelectedComponentType(null)
                editHooks.us_setAnchorPosition(null)
              }
            }}
          >
            <Icon
              icon="pen-to-square"
              sx={{ marginRight: '8px', width: '24px' }}
            />
            {rLIB('Edit Text')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              openTextStylesEditDialog(editHooks, onChange)
              if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
                editHooks.us_setSelectedComponentKey(null)
                editHooks.us_setSelectedComponentType(null)
                editHooks.us_setAnchorPosition(null)
              }
            }}
          >
            <Icon
              icon="brush"
              sx={{ marginRight: '8px', width: '24px' }}
            />
            {rLIB('Edit Styles')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              deleteElement(editHooks, onChange)
              if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
                editHooks.us_setSelectedComponentKey(null)
                editHooks.us_setSelectedComponentType(null)
                editHooks.us_setAnchorPosition(null)
              }
            }}
          >
            <Icon
              icon="trash"
              sx={{ marginRight: '8px', width: '24px' }}
            />
            {rLIB('Delete Text')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              moveElementUp(editHooks, onChange)
              if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
                editHooks.us_setSelectedComponentKey(null)
                editHooks.us_setSelectedComponentType(null)
                editHooks.us_setAnchorPosition(null)
              }
            }}
          >
            <Icon
              icon="arrow-up"
              sx={{ marginRight: '8px', width: '24px' }}
            />
            {rLIB('Move Text Up')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              moveElementDown(editHooks, onChange)
              if (editHooks != null && editHooks.us_setAnchorPosition !== null) {
                editHooks.us_setSelectedComponentKey(null)
                editHooks.us_setSelectedComponentType(null)
                editHooks.us_setAnchorPosition(null)
              }
            }}
          >
            <Icon
              icon="arrow-down"
              sx={{ marginRight: '8px', width: '24px' }}
            />
            {rLIB('Move Text Down')}
          </MenuItem>
        </Menu>
      )
      break
    case 'variable_text':
      // TODO: Implement
      break
    case 'view':
      // TODO: Implement
      break
  }
  return editMenuJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const generatePdfFromData = (
  pdfTemplate: TsInterface_PdfTemplate,
  pdfData: TsInterface_UnspecifiedObject,
  mode: 'view' | 'edit',
  uc_setUserInterface_FormDialogDisplay: any,
  onChange: (template: TsInterface_UnspecifiedObject) => Promise<any>,
  editHooks: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let documentJSX = <></>
  if (mode === 'view') {
    documentJSX = (
      <PDFViewer style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
        <Document>
          <Page
            size="LETTER"
            style={getProp(pdfTemplate, 'page_settings', {})}
          >
            {objectToArray(pdfTemplate.page_contents)
              .sort(dynamicSort('order', 'asc'))
              .map((component: TsType_PdfTemplateComponent, index: number) => (
                <React.Fragment key={index}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
              ))}
          </Page>
        </Document>
      </PDFViewer>
    )
  } else if (mode === 'edit') {
    documentJSX = (
      <Box style={{ width: '100%', minHeight: 'calc(100vh - 300px)' }}>
        <Box className="tw-text-left tw-mt-2">
          <Box
            sx={{
              'border': '1px solid rgba(0,0,0,0)',
              '&:hover': {
                border: '1px solid ' + themeVariables.warning_main,
              },
            }}
            className="tw-cursor-pointer"
            onClick={(event) => {
              event.stopPropagation()
              editHooks.us_setSelectedComponentKey('page_settings')
              editHooks.us_setSelectedComponentType('page_settings')
              editHooks.us_setAnchorPosition({
                top: event.clientY,
                left: event.clientX,
              })
            }}
          >
            {objectToArray(pdfTemplate.page_contents)
              .sort(dynamicSort('order', 'asc'))
              .map((component: TsType_PdfTemplateComponent, index: number) => (
                <React.Fragment key={index}>{generatePdfComponent(component, pdfData, mode, editHooks)}</React.Fragment>
              ))}
          </Box>
          <Box className="tw-text-center tw-mt-2">
            <Button
              variant="contained"
              color="success"
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                openNewRootPdfElementDialog(uc_setUserInterface_FormDialogDisplay, onChange, pdfTemplate)
              }}
            >
              {rLIB('Add New PDF Element')}
            </Button>
          </Box>
          {rJSX_editComponentMenu(editHooks, onChange)}
        </Box>
      </Box>
    )
  }
  return documentJSX
}
