///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - hooks

// { sort-end } - hooks

///////////////////////////////
// Functions
///////////////////////////////

const checkIfInputIsANumber = (input: string) => {
  // Remove commas from the input string
  const inputWithoutCommas = input.toString().replace(/,/g, '')
  // Regular expression pattern to match valid numeric strings
  const pattern = /^[-]?(\d+(\.\d+)?|\.\d+)$/
  // Use the test method to check if the modified input matches the pattern
  return pattern.test(inputWithoutCommas)
}

// const checkIfInputIsABoolean = ( input: string | boolean ) => {
// if(
// 	input === true ||
// 	input === false ||
// 	input.toString().toLowerCase() === "true" ||
// 	input.toString().toLowerCase() === "false"
// ){
// 	return true
// } else {
// 	return false
// }
// }

const checkIfInputIsATime = (input: string) => {
  // Regular expression pattern to match valid time strings
  const pattern = /^[\d:]+(\.\d+)?$/
  // Use the test method to check if the input matches the pattern
  return pattern.test(input)
}

const checkIfInputIsADate = (input: string) => {
  // Regular expression pattern to match valid date strings (YYYY-MM-DD)
  const pattern = /^\d{4}-\d{2}-\d{2}$/
  // Use the test method to check if the input matches the pattern
  return pattern.test(input)
}

const checkIfInputIsADatetime = (input: string) => {
  // Regular expression pattern to match valid datetime strings (YYYY-MM-DDThh:mm)
  const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/
  // Use the test method to check if the input matches the pattern
  return pattern.test(input)
}

const getField1ValueAndFlag = (rowData: TsInterface_UnspecifiedObject, analysisProcedure: TsInterface_UnspecifiedObject) => {
  let field1Value = null
  let foundField1Value = false
  if (
    rowData != null &&
    analysisProcedure != null &&
    analysisProcedure.field1 != null &&
    analysisProcedure.field1_option != null &&
    rowData[analysisProcedure.field1] != null &&
    rowData[analysisProcedure.field1][analysisProcedure.field1_option] != null
  ) {
    field1Value = rowData[analysisProcedure.field1][analysisProcedure.field1_option]
    foundField1Value = true
  } else if (rowData != null && analysisProcedure != null && analysisProcedure.field1 != null && rowData[analysisProcedure.field1] != null) {
    field1Value = rowData[analysisProcedure.field1]
    foundField1Value = true
  }
  return { field1Value, foundField1Value }
}

const getField2ValueAndFlag = (rowData: TsInterface_UnspecifiedObject, analysisProcedure: TsInterface_UnspecifiedObject) => {
  // Get Field 2 Values
  let field2Value = null
  let foundField2Value = false
  if (analysisProcedure != null && analysisProcedure.field2 === 'HARDCODE') {
    if (analysisProcedure.field2_hardcode != null) {
      field2Value = analysisProcedure.field2_hardcode
      foundField2Value = true
    }
  } else if (
    rowData != null &&
    analysisProcedure != null &&
    analysisProcedure.field2 != null &&
    analysisProcedure.field2_option != null &&
    rowData[analysisProcedure.field2] != null &&
    rowData[analysisProcedure.field2][analysisProcedure.field2_option] != null
  ) {
    field2Value = rowData[analysisProcedure.field2][analysisProcedure.field2_option]
    foundField2Value = true
  } else if (rowData != null && analysisProcedure != null && analysisProcedure.field2 != null && rowData[analysisProcedure.field2] != null) {
    field2Value = rowData[analysisProcedure.field2]
    foundField2Value = true
  }
  return { field2Value, foundField2Value }
}

const getField3ValueAndFlag = (rowData: TsInterface_UnspecifiedObject, analysisProcedure: TsInterface_UnspecifiedObject) => {
  // Get Field 3 Values
  let field3Value = null
  let foundField3Value = false
  if (analysisProcedure != null && analysisProcedure.field3 === 'HARDCODE') {
    if (analysisProcedure.field3_hardcode != null) {
      field3Value = analysisProcedure.field3_hardcode
      foundField3Value = true
    }
  } else if (
    rowData != null &&
    analysisProcedure != null &&
    analysisProcedure.field3 != null &&
    analysisProcedure.field3_option != null &&
    rowData[analysisProcedure.field3] != null &&
    rowData[analysisProcedure.field3][analysisProcedure.field3_option] != null
  ) {
    field3Value = rowData[analysisProcedure.field3][analysisProcedure.field3_option]
    foundField3Value = true
  } else if (rowData != null && analysisProcedure != null && analysisProcedure.field3 != null && rowData[analysisProcedure.field3] != null) {
    field3Value = rowData[analysisProcedure.field3]
    foundField3Value = true
  }
  return { field3Value, foundField3Value }
}

// Analysis Procedure Options
export const analysisProcedureOptions: TsInterface_UnspecifiedObject = {
  // Signle Field Comparison
  is_a_number: {
    name: 'Is a Number',
    key: 'is_a_number',
    allowed_data_types: { number: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        return checkIfInputIsANumber(field1Value)
      } else {
        return null
      }
    },
  },
  is_not_a_number: {
    name: 'Is Not a Number',
    key: 'is_not_a_number',
    allowed_data_types: { number: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        return !checkIfInputIsANumber(field1Value)
      } else {
        return null
      }
    },
  },
  // is_a_string: {
  // name: "Is a String",
  // key: "is_a_string",
  // allowed_data_types: { "string": true },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {
  // 	if(
  // 		rowData != null &&
  // 		analysisProcedure != null &&
  // 		analysisProcedure.field1 != null &&
  // 		rowData[ analysisProcedure.field1 ] != null
  // 	){
  // 		return typeof rowData[ analysisProcedure.field1 ] === "string"
  // 	} else {
  // 		return false
  // 	}
  // }
  // },
  // is_not_a_string: {
  // name: "Is Not a String",
  // key: "is_not_a_string",
  // allowed_data_types: { "string": true },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {
  // 	if(
  // 		rowData != null &&
  // 		analysisProcedure != null &&
  // 		analysisProcedure.field1 != null &&
  // 		rowData[ analysisProcedure.field1 ] != null
  // 	){
  // 		return typeof rowData[ analysisProcedure.field1 ] !== "string"
  // 	} else {
  // 		return false
  // 	}
  // }
  // },
  is_a_boolean: {
    name: 'Is a Boolean',
    key: 'is_a_boolean',
    allowed_data_types: { boolean: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        if (
          field1Value === true ||
          field1Value === false ||
          field1Value.toString().toLowerCase() === 'true' ||
          field1Value.toString().toLowerCase() === 'false'
        ) {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    },
  },
  is_not_a_boolean: {
    name: 'Is Not a Boolean',
    key: 'is_not_a_boolean',
    allowed_data_types: { boolean: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        if (
          field1Value === true ||
          field1Value === false ||
          field1Value.toString().toLowerCase() === 'true' ||
          field1Value.toString().toLowerCase() === 'false'
        ) {
          return false
        } else {
          return true
        }
      } else {
        return null
      }
    },
  },
  is_null: {
    name: 'Is Missing',
    key: 'is_null',
    allowed_data_types: { string: true, number: true, boolean: true, date: true, time: true, datetime: true, option_boolean: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        if (field1Value !== '') {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },
  is_not_null: {
    name: 'Is Not Missing',
    key: 'is_not_null',
    allowed_data_types: { string: true, number: true, boolean: true, date: true, time: true, datetime: true, option_boolean: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        if (field1Value !== '') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
  is_true: {
    name: 'Is True',
    key: 'is_true',
    allowed_data_types: { boolean: true, option_boolean: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        if (field1Value === true || field1Value.toString().toLowerCase() === 'true') {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    },
  },
  is_false: {
    name: 'Is False',
    key: 'is_false',
    allowed_data_types: { boolean: true, option_boolean: true },
    field2_visible: false,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true) {
        if (field1Value === false || field1Value.toString().toLowerCase() === 'false') {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    },
  },
  // Field Comparison - Number
  equals: {
    name: 'Equals',
    key: 'equals',
    allowed_data_types: { number: true, string: true, boolean: true, option_boolean: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true && foundField2Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true) {
          // eslint-disable-next-line eqeqeq
          if (parseFloat(field1Value) == parseFloat(field2Value)) {
            return true
          } else {
            return false
          }
        } else {
          // eslint-disable-next-line eqeqeq
          if (field1Value == field2Value) {
            return true
          } else {
            return false
          }
        }
      } else {
        return null
      }
    },
  },
  not_equals: {
    name: 'Does Not Equal',
    key: 'not_equals',
    allowed_data_types: { number: true, string: true, boolean: true, option_boolean: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      // Run Check
      if (foundField1Value === true && foundField2Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true) {
          // eslint-disable-next-line eqeqeq
          if (parseFloat(field1Value) == parseFloat(field2Value)) {
            return false
          } else {
            return true
          }
        } else {
          // eslint-disable-next-line eqeqeq
          if (field1Value == field2Value) {
            return false
          } else {
            return true
          }
        }
      } else {
        return null
      }
    },
  },
  less_than: {
    name: 'Less Than',
    key: 'less_than',
    allowed_data_types: { number: true, date: true, time: true, datetime: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true) {
          return parseFloat(field1Value) < parseFloat(field2Value)
        } else if (checkIfInputIsADate(field1Value) === true && checkIfInputIsADate(field2Value) === true) {
          return new Date(field1Value) < new Date(field2Value)
        } else if (checkIfInputIsATime(field1Value) === true && checkIfInputIsATime(field2Value) === true) {
          return new Date(`1970-01-01T${field1Value}`) < new Date(`1970-01-01T${field2Value}`)
        } else if (checkIfInputIsADatetime(field1Value) === true && checkIfInputIsADatetime(field2Value) === true) {
          return new Date(field1Value) < new Date(field2Value)
        } else {
          return field1Value < field2Value
        }
      } else {
        return null
      }
    },
  },
  less_than_or_equal_to: {
    name: 'Less Than or Equal To',
    key: 'less_than_or_equal_to',
    allowed_data_types: { number: true, date: true, time: true, datetime: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true) {
          return parseFloat(field1Value) <= parseFloat(field2Value)
        } else if (checkIfInputIsADate(field1Value) === true && checkIfInputIsADate(field2Value) === true) {
          return new Date(field1Value) <= new Date(field2Value)
        } else if (checkIfInputIsATime(field1Value) === true && checkIfInputIsATime(field2Value) === true) {
          return new Date(`1970-01-01T${field1Value}`) <= new Date(`1970-01-01T${field2Value}`)
        } else if (checkIfInputIsADatetime(field1Value) === true && checkIfInputIsADatetime(field2Value) === true) {
          return new Date(field1Value) <= new Date(field2Value)
        } else {
          return field1Value <= field2Value
        }
      } else {
        return null
      }
    },
  },
  greater_than: {
    name: 'Greater Than',
    key: 'greater_than',
    allowed_data_types: { number: true, date: true, time: true, datetime: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true) {
          return parseFloat(field1Value) > parseFloat(field2Value)
        } else if (checkIfInputIsADate(field1Value) === true && checkIfInputIsADate(field2Value) === true) {
          return new Date(field1Value) > new Date(field2Value)
        } else if (checkIfInputIsATime(field1Value) === true && checkIfInputIsATime(field2Value) === true) {
          return new Date(`1970-01-01T${field1Value}`) > new Date(`1970-01-01T${field2Value}`)
        } else if (checkIfInputIsADatetime(field1Value) === true && checkIfInputIsADatetime(field2Value) === true) {
          return new Date(field1Value) > new Date(field2Value)
        } else {
          return field1Value > field2Value
        }
      } else {
        return null
      }
    },
  },
  greater_than_or_equal_to: {
    name: 'Greater Than or Equal To',
    key: 'greater_than_or_equal_to',
    allowed_data_types: { number: true, date: true, time: true, datetime: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true) {
          return parseFloat(field1Value) >= parseFloat(field2Value)
        } else if (checkIfInputIsADate(field1Value) === true && checkIfInputIsADate(field2Value) === true) {
          return new Date(field1Value) >= new Date(field2Value)
        } else if (checkIfInputIsATime(field1Value) === true && checkIfInputIsATime(field2Value) === true) {
          return new Date(`1970-01-01T${field1Value}`) >= new Date(`1970-01-01T${field2Value}`)
        } else if (checkIfInputIsADatetime(field1Value) === true && checkIfInputIsADatetime(field2Value) === true) {
          return new Date(field1Value) >= new Date(field2Value)
        } else {
          return field1Value >= field2Value
        }
      } else {
        return null
      }
    },
  },
  // Field Comparison - String
  contains_string: {
    name: 'Contains',
    key: 'contains_string',
    allowed_data_types: { string: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        return field1Value.toString().toLowerCase().includes(field2Value.toString().toLowerCase())
      } else {
        return null
      }
    },
  },
  does_not_contain_string: {
    name: 'Does Not Contain',
    key: 'does_not_contain_string',
    allowed_data_types: { string: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        return !field1Value.toString().toLowerCase().includes(field2Value.toString().toLowerCase())
      } else {
        return null
      }
    },
  },
  starts_with_string: {
    name: 'Starts With',
    key: 'starts_with_string',
    allowed_data_types: { string: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        return field1Value.toString().toLowerCase().startsWith(field2Value.toString().toLowerCase())
      } else {
        return null
      }
    },
  },
  ends_with_string: {
    name: 'Ends With',
    key: 'ends_with_string',
    allowed_data_types: { string: true },
    field2_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true) {
        return field1Value.toString().toLowerCase().endsWith(field2Value.toString().toLowerCase())
      } else {
        return null
      }
    },
  },
  // Field Comparison - Range
  between_two_numbers_inclusive: {
    name: 'Between 2 Numbers (Inclusive)',
    key: 'between_two_numbers_inclusive',
    allowed_data_types: { number: true },
    field2_visible: true,
    field3_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      const { field3Value, foundField3Value } = getField3ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true && foundField3Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true && checkIfInputIsANumber(field3Value) === true) {
          let lowerBound = parseFloat(field2Value)
          let upperBound = parseFloat(field3Value)
          if (lowerBound > upperBound) {
            lowerBound = parseFloat(field3Value)
            upperBound = parseFloat(field2Value)
          }
          return parseFloat(field1Value) >= lowerBound && parseFloat(field1Value) <= upperBound
        } else {
          let lowerBound = field2Value
          let upperBound = field3Value
          if (lowerBound > upperBound) {
            lowerBound = field3Value
            upperBound = field2Value
          }
          return field1Value >= lowerBound && field1Value <= upperBound
        }
      } else {
        return null
      }
    },
  },
  between_two_numbers_exclusive: {
    name: 'Between 2 Numbers (Exclusive)',
    key: 'between_two_numbers_exclusive',
    allowed_data_types: { number: true },
    field2_visible: true,
    field3_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      const { field3Value, foundField3Value } = getField3ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true && foundField3Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true && checkIfInputIsANumber(field3Value) === true) {
          let lowerBound = parseFloat(field2Value)
          let upperBound = parseFloat(field3Value)
          if (lowerBound > upperBound) {
            lowerBound = parseFloat(field3Value)
            upperBound = parseFloat(field2Value)
          }
          return parseFloat(field1Value) > lowerBound && parseFloat(field1Value) < upperBound
        } else {
          let lowerBound = field2Value
          let upperBound = field3Value
          if (lowerBound > upperBound) {
            lowerBound = field3Value
            upperBound = field2Value
          }
          return field1Value > lowerBound && field1Value < upperBound
        }
      } else {
        return null
      }
    },
  },
  not_between_two_numbers_inclusive: {
    name: 'Not Between 2 Numbers (Inclusive)',
    key: 'not_between_two_numbers_inclusive',
    allowed_data_types: { number: true },
    field2_visible: true,
    field3_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      const { field3Value, foundField3Value } = getField3ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true && foundField3Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true && checkIfInputIsANumber(field3Value) === true) {
          let lowerBound = parseFloat(field2Value)
          let upperBound = parseFloat(field3Value)
          if (lowerBound > upperBound) {
            lowerBound = parseFloat(field3Value)
            upperBound = parseFloat(field2Value)
          }
          return parseFloat(field1Value) < lowerBound || parseFloat(field1Value) > upperBound
        } else {
          let lowerBound = field2Value
          let upperBound = field3Value
          if (lowerBound > upperBound) {
            lowerBound = field3Value
            upperBound = field2Value
          }
          return field1Value < lowerBound || field1Value > upperBound
        }
      } else {
        return null
      }
    },
  },
  not_between_two_numbers_exclusive: {
    name: 'Not Between 2 Numbers (Exclusive)',
    key: 'not_between_two_numbers_exclusive',
    allowed_data_types: { number: true },
    field2_visible: true,
    field3_visible: true,
    evaluation: (allData: any, rowData: any, analysisProcedure: any) => {
      // Get Field Values
      const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
      const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)
      const { field3Value, foundField3Value } = getField3ValueAndFlag(rowData, analysisProcedure)
      if (foundField1Value === true && foundField2Value === true && foundField3Value === true) {
        if (checkIfInputIsANumber(field1Value) === true && checkIfInputIsANumber(field2Value) === true && checkIfInputIsANumber(field3Value) === true) {
          let lowerBound = parseFloat(field2Value)
          let upperBound = parseFloat(field3Value)
          if (lowerBound > upperBound) {
            lowerBound = parseFloat(field3Value)
            upperBound = parseFloat(field2Value)
          }
          return parseFloat(field1Value) <= lowerBound || parseFloat(field1Value) >= upperBound
        } else {
          let lowerBound = field2Value
          let upperBound = field3Value
          if (lowerBound > upperBound) {
            lowerBound = field3Value
            upperBound = field2Value
          }
          return field1Value <= lowerBound || field1Value >= upperBound
        }
      } else {
        return null
      }
    },
  },
  // Sequence
  // single_field_gaps: {
  // name: "Single Field Gaps",
  // key: "single_field_gaps",
  // allowed_data_types: { "number": true },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// TODO

  // }
  // },
  // double_field_gaps: {
  // name: "Double Field Gaps",
  // key: "double_field_gaps",
  // allowed_data_types: { "number": true },
  // field2_visible: true,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// TODO

  // }
  // },

  // Date Time

  // within_x_days: {
  // name: "Within X Days",
  // key: "within_x_days",
  // allowed_data_types: { "date": true, "datetime": true, },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// Get Field Values
  // 	const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
  // 	const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)

  // 	// return value1 >= value2
  // 	if(
  // 		foundField1Value === true &&
  // 		foundField2Value === true
  // 	){

  // 	} else {
  // 		return null
  // 	}
  // }
  // },
  // not_within_x_days: {
  // name: "Not Within X Days",
  // key: "not_within_x_days",
  // allowed_data_types: { "date": true, "datetime": true, },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// Get Field Values
  // 	const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
  // 	const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)

  // 	// return value1 >= value2
  // 	if(
  // 		foundField1Value === true &&
  // 		foundField2Value === true
  // 	){

  // 	} else {
  // 		return null
  // 	}
  // }
  // },
  // within_x_hours: {
  // name: "Within X Hours",
  // key: "within_x_hours",
  // allowed_data_types: { "date": true, "datetime": true, },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// Get Field Values
  // 	const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
  // 	const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)

  // 	// return value1 >= value2
  // 	if(
  // 		foundField1Value === true &&
  // 		foundField2Value === true
  // 	){

  // 	} else {
  // 		return null
  // 	}
  // }
  // },
  // not_within_x_hours: {
  // name: "Not Within X Hours",
  // key: "not_within_x_hours",
  // allowed_data_types: { "date": true, "datetime": true, },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// Get Field Values
  // 	const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
  // 	const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)

  // 	// return value1 >= value2
  // 	if(
  // 		foundField1Value === true &&
  // 		foundField2Value === true
  // 	){

  // 	} else {
  // 		return null
  // 	}
  // }
  // },
  // within_x_minutes: {
  // name: "Within X Minutes",
  // key: "within_x_minutes",
  // allowed_data_types: { "date": true, "datetime": true, },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// Get Field Values
  // 	const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
  // 	const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)

  // 	// return value1 >= value2
  // 	if(
  // 		foundField1Value === true &&
  // 		foundField2Value === true
  // 	){

  // 	} else {
  // 		return null
  // 	}
  // }
  // },
  // not_within_x_minutes: {
  // name: "Not Within X Minutes",
  // key: "not_within_x_minutes",
  // allowed_data_types: { "date": true, "datetime": true, },
  // field2_visible: false,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {

  // 	// Get Field Values
  // 	const { field1Value, foundField1Value } = getField1ValueAndFlag(rowData, analysisProcedure)
  // 	const { field2Value, foundField2Value } = getField2ValueAndFlag(rowData, analysisProcedure)

  // 	// return value1 >= value2
  // 	if(
  // 		foundField1Value === true &&
  // 		foundField2Value === true
  // 	){

  // 	} else {
  // 		return null
  // 	}
  // }
  // },

  // Custom
  // custom: {
  // name: "Custom",
  // key: "custom",
  // allowed_data_types: { "string": true, "number": true, "boolean": true, "date": true, "time": true, "datetime": true, "option_boolean": true },
  // field2_visible: true,
  // evaluation: ( allData: any, rowData: any, analysisProcedure: any ) => {
  // 	// TODO
  // }
  // },
}

export const analysisProcedureResultOptions: TsInterface_UnspecifiedObject = {
  highlight_yellow: {
    key: 'highlight_yellow',
    name: 'Highlight Yellow',
    return_sx: () => {
      return { backgroundColor: themeVariables.warning_main }
    },
  },
  // highlight_red: {
  // key: "highlight_red",
  // name: "Highlight Red",
  // return_sx: () => {
  // 	return { backgroundColor: themeVariables.error_main }
  // }
  // },
}
