export const getBaseURL = () => {
  // Get the current URL of the webpage
  const currentURL = window.location.href

  // Find the index of the end of the domain
  const domainEndIndex = currentURL.indexOf('/', 8) // Search after "https://" or "http://"

  // Extract the base URL
  const baseURL = currentURL.substring(0, domainEndIndex)

  return baseURL
}
