///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  TsInterface_DataViewerLines,
  TsInterface_DataViewerSettings,
  ViewerLineBasicData,
  ViewerLineHeader,
  ViewerLinePhone,
} from 'rfbp_core/components/data_viewer'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const viewerSettings_User: TsInterface_DataViewerSettings = {
  // Nothing
}

export const viewerLines_User: TsInterface_DataViewerLines = {
  header_user: ViewerLineHeader(rLIB('User')),
  name: ViewerLineBasicData(rLIB('User Name'), 'name', { editable: false }),
  email: ViewerLineBasicData(rLIB('Email'), 'email', { editable: false }),
  phone: ViewerLinePhone(rLIB('Phone'), 'phone'),
  user_role: ViewerLineBasicData(rLIB('User Type'), 'user_role', { editable: false }),
}
