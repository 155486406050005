//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  height: string
  color: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const LogoDataOre = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let height: TsInterface_ComponentProps['height'] = getProp(props, 'height', '30px')
  let color: TsInterface_ComponentProps['color'] = getProp(props, 'color', '#000000')

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // Call Functions

  // JSX Generation
  const Logo = (
    <Box>
      <svg
        height={height}
        viewBox="0 0 1050 450"
      >
        <g transform="matrix(0.582879,0,0,0.582879,-240.881,-510.745)">
          <path
            fill={color}
            d="M535.185,1212.17L535.185,934.3L659.67,934.3C709.761,934.3 744.143,944.97 762.816,966.31C781.489,987.651 790.825,1023.29 790.825,1073.24C790.825,1123.18 781.489,1158.82 762.816,1180.16C744.143,1201.5 709.761,1212.17 659.67,1212.17L535.185,1212.17ZM661.004,1005.43L624.103,1005.43L624.103,1141.04L661.004,1141.04C673.156,1141.04 681.974,1139.63 687.457,1136.81C692.941,1134 695.682,1127.55 695.682,1117.47L695.682,1029C695.682,1018.92 692.941,1012.47 687.457,1009.66C681.974,1006.84 673.156,1005.43 661.004,1005.43Z"
          />
          <path
            fill={color}
            d="M888.191,1212.17L794.382,1212.17L866.406,934.3L1003.78,934.3L1075.81,1212.17L981.999,1212.17L971.774,1168.15L898.416,1168.15L888.191,1212.17ZM932.65,1019.66L914.421,1097.46L955.324,1097.46L937.54,1019.66L932.65,1019.66Z"
          />
          <path
            fill={color}
            d="M1274.99,1005.43L1210.52,1005.43L1210.52,1212.17L1121.6,1212.17L1121.6,1005.43L1057.13,1005.43L1057.13,934.3L1274.99,934.3L1274.99,1005.43Z"
          />
          <path
            fill={color}
            d="M1350.12,1212.17L1256.31,1212.17L1328.34,934.3L1465.71,934.3L1537.74,1212.17L1443.93,1212.17L1433.7,1168.15L1360.35,1168.15L1350.12,1212.17ZM1394.58,1019.66L1376.35,1097.46L1417.26,1097.46L1399.47,1019.66L1394.58,1019.66Z"
          />
        </g>
        <g transform="matrix(0.582879,0,0,0.582879,-233.107,-294.273)">
          <path
            fill={color}
            d="M521.847,1073.46C521.847,1022.77 531.332,985.798 550.301,962.531C569.27,939.264 603.504,927.631 653.002,927.631C702.499,927.631 736.733,939.264 755.702,962.531C774.671,985.798 784.156,1022.77 784.156,1073.46C784.156,1098.65 782.155,1119.84 778.154,1137.03C774.153,1154.22 767.262,1169.19 757.481,1181.94C747.7,1194.68 734.214,1204.02 717.023,1209.95C699.832,1215.87 678.491,1218.84 653.002,1218.84C627.512,1218.84 606.171,1215.87 588.98,1209.95C571.789,1204.02 558.303,1194.68 548.522,1181.94C538.741,1169.19 531.85,1154.22 527.849,1137.03C523.848,1119.84 521.847,1098.65 521.847,1073.46ZM617.434,1027.22L617.434,1142.81L654.335,1142.81C666.487,1142.81 675.305,1141.4 680.788,1138.59C686.272,1135.77 689.013,1129.33 689.013,1119.25L689.013,1003.66L651.668,1003.66C639.812,1003.66 631.142,1005.06 625.659,1007.88C620.176,1010.7 617.434,1017.14 617.434,1027.22Z"
          />
          <path
            fill={color}
            d="M1057.58,1212.17L959.77,1212.17L923.313,1129.48L904.196,1129.48L904.196,1212.17L815.278,1212.17L815.278,934.3L955.324,934.3C1019.05,934.3 1050.91,966.755 1050.91,1031.66C1050.91,1076.12 1037.13,1105.47 1009.56,1119.69L1057.58,1212.17ZM904.196,1005.43L904.196,1061.01L924.647,1061.01C935.317,1061.01 943.098,1059.9 947.988,1057.67C952.879,1055.45 955.324,1050.34 955.324,1042.34L955.324,1024.11C955.324,1016.11 952.879,1010.99 947.988,1008.77C943.098,1006.55 935.317,1005.43 924.647,1005.43L904.196,1005.43Z"
          />
          <path
            fill={color}
            d="M1266.54,1107.25L1177.62,1107.25L1177.62,1141.04L1286.55,1141.04L1286.55,1212.17L1088.7,1212.17L1088.7,934.3L1284.32,934.3L1273.21,1005.43L1177.62,1005.43L1177.62,1042.78L1266.54,1042.78L1266.54,1107.25Z"
          />
        </g>
        <g transform="matrix(0.747593,0,0,0.747593,530.799,-18.095)">
          <path
            fill={color}
            d="M258.717,70.046C248.34,71.13 225.661,75.596 222.627,77.153C218.585,79.227 214.964,83.418 213.946,87.202C211.408,96.625 218.978,106 229.123,106C230.769,106 236.699,106.95 242.3,108.11C273.431,114.561 316.873,135.717 354.5,162.75C418.05,208.407 483.744,277.03 521.485,337.18C532.287,354.395 547.102,383.555 552.386,398C557.239,411.268 561.818,429.193 562.655,438.201C563.687,449.3 569.186,455.5 578,455.5C587.143,455.5 591.723,449.881 594.859,434.816C598.755,416.095 599.436,408.835 599.436,386C599.436,362.644 598.635,354.134 594.494,333.485C580.38,263.12 537.9,196.197 473,142.082C426.559,103.359 373.132,79.171 316,71.003C305.709,69.532 269.438,68.926 258.717,70.046M517.58,99.147C509.621,100.254 500.14,104.853 494.027,110.572C491.262,113.158 489,115.572 489,115.937C489,116.302 492.262,119.269 496.25,122.53C506.72,131.093 531.802,155.859 541.793,167.5C546.514,173 550.965,178.158 551.685,178.961C552.811,180.218 553.631,179.8 557.556,175.961C570.223,163.574 573.842,141.944 566.037,125.265C560.186,112.761 545.152,101.011 533.068,99.495C530.556,99.18 527.375,98.787 526,98.622C524.625,98.458 520.836,98.694 517.58,99.147M238.34,366.249C139.547,465.11 94.537,510.808 93.089,513.72C84.09,531.813 87.548,552.708 101.92,567.08C116.548,581.708 138.264,585.057 155.978,575.415C159.15,573.689 205.563,527.939 303.489,430.011L446.371,287.126L441.984,282.313C425.913,264.682 412.002,250.493 398.485,237.946C389.925,230.001 382.6,223.387 382.207,223.249C381.814,223.111 317.074,287.461 238.34,366.249"
          />
        </g>
      </svg>
    </Box>
  )

  return Logo
}
