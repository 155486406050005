///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import React, { useEffect } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperTestLabPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // let items2 = 						getProp(props, "items2", {})

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 			useState< TsInterface_UnspecifiedObject >( {} )
  // const ur_forceRerender = 				useReducer( () => ( {} ), {} )[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {}, [])

  // Functions
  const processFileContent = (content: string) => {
    const lines = content.split('\n')
    let isTable = false
    let table: any = []
    let cutoffs: any = []
    let headersSet = false
    let currentState = ''
    lines.forEach((line: any, index: any) => {
      if (line.trim().startsWith('------------')) {
        if (isTable) {
          table = []
        }
        isTable = true
        const headerLineIndex = index - 1
        const headerLine = lines[headerLineIndex]
        cutoffs = getHeaderCutoffs(line)
        if (!headersSet) {
          const headers = extractHeaders(headerLine, cutoffs)
          headers.unshift('State')
          table.push(headers)
          headersSet = true
        }
      } else if (isTable && line.startsWith('Price: NET')) {
        isTable = false
      } else if (isTable) {
        if (isStateLine(line)) {
          currentState = line.trim().slice(0, -1) // Remove the colon
        } else if (isValidLine(line)) {
          const values = extractValues(line, cutoffs)
          values.unshift(currentState || '') // Add the current state to the beginning
          table.push(values)
        }
      }
    })
    if (table.length > 0) {
      console.log('FINAL Table:')
      console.log(table)
    }
  }

  const getHeaderCutoffs = (line: any) => {
    const cutoffs = []
    let isStart = true
    for (let i = 0; i < line.length; i++) {
      if (line[i] === '-' && (isStart || line[i - 1] === ' ')) {
        cutoffs.push(i)
        isStart = false
      }
    }
    return cutoffs
  }

  const extractHeaders = (headerLine: any, cutoffs: any) => {
    const headers = []
    for (let i = 0; i < cutoffs.length; i++) {
      const start = cutoffs[i]
      const end = cutoffs[i + 1] || headerLine.length
      const header = headerLine.slice(start, end).trim()
      headers.push(header)
    }
    return headers
  }

  const isStateLine = (line: any) => {
    const trimmedLine = line.trim()
    return trimmedLine.length < 30 && trimmedLine.endsWith(':')
  }

  const isValidLine = (line: any) => {
    if (line.trim().length <= 1) return false
    return !isStateLine(line)
  }

  const extractValues = (line: any, cutoffs: any) => {
    const values = []
    for (let i = 0; i < cutoffs.length; i++) {
      const start = cutoffs[i]
      const end = cutoffs[i + 1] || line.length
      let value = line.slice(start, end).trim()
      if (/^\d+\.\d+\*$/.test(value)) {
        value = value.slice(0, -1)
      }
      if (value.endsWith(' E') || value.endsWith(' A')) {
        value = value.slice(0, -3).trim()
      }
      values.push(value)
    }
    return values
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        const file = event.target.files[0]
        if (file && file.type === 'text/plain') {
          const reader = new FileReader()
          reader.onload = (e) => {
            let content: string = e.target?.result as string
            processFileContent(content)
            // Process the file content and turn it into CSV
            resolve({ success: true, data: content })
          }
          reader.readAsText(file)
        } else {
          console.error('Please upload a .txt file.')
          reject({
            success: false,
            error: {
              message: 'Failed to upload file',
              details: 'Please upload a .txt file.',
              code: 'ER-D-TFS-FOS-01',
            },
          })
        }
      } else {
        reject({
          success: false,
          error: {
            message: 'Failed to upload file',
            details: 'Invalid file',
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  // Call Functions

  // JSX Generation
  const rJSX_UploadButton = (): JSX.Element => {
    let uploadButtonJSX = (
      <FileUploadButton
        multiple={false}
        button={{
          text: <>{'Upload File'}</>,
          icon: (
            <Icon
              icon="cloud-arrow-up"
              className="tw-mr-2"
              sx={{ fontSize: '20px' }}
            ></Icon>
          ),
          color: 'success',
          className: 'tw-mr-2',
          variant: 'contained',
          disabled: false,
        }}
        accept=".txt"
        onChange={uploadFiles}
        additionalFileUploadParams={{}}
      />
    )
    return uploadButtonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Test Lab')}
        pageKey={pageKey}
        content={<Box>{rJSX_UploadButton()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
