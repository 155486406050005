//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

			TABS
				- Email
				- Schedule (One Time vs Recurring)
					- Active or Inactive
				- Attachments / Filters
				- Logs


			- Global Database Location for querying cronjob
			- Actual Cronjob



			ATTACHMENTS
				- File Name
				- Move Filters to Attachments???
				- Custom Times???

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { AppBar, Box, Button, Card, Checkbox, Chip, Dialog, DialogContent, IconButton, Stack, TextField, Toolbar, Tooltip, Typography } from '@mui/material/'
import { MuiChipsInput } from 'mui-chips-input'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ActivePersistentClasses_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveDataBuckets_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { DatabaseRef_ActiveReportTemplates_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import {
  DatabaseRef_ScheduledEmailLogs_Collection,
  DatabaseRef_ScheduledEmail_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/scheduled_emails'
import { DatabaseRef_ActiveDirectory_Query } from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import { DatabaseRef_GlobalScheduledEmail_Document } from 'rfbp_aux/services/database_endpoints/global_emails'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ScheduledEmailDialog {
  emailKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ADD_ATTACHMENT = <Trans>Add Attachment</Trans>
const s_ADD_FILTER = <Trans>Add Filter</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ATTACHMENT = <Trans>Are you sure that you want to delete this attachment</Trans>
const s_ATTACHMENTS = <Trans>Attachments</Trans>
const s_CENTRAL_TIME = <Trans>Central Time</Trans>
const s_DATA_BUCKETS = <Trans>Data Buckets</Trans>
const s_DATA_FILTERS = <Trans>Data Filters</Trans>
const s_DATA_TO_QUERY = <Trans>Data to Query</Trans>
const s_DATE_RANGE_TYPE = <Trans>Date Range Type</Trans>
const s_DAY_BEFORE_EMAIL = <Trans>Day Before Email</Trans>
const s_DAY_OF_EMAIL = <Trans>Day of Email</Trans>
const s_DELETE = <Trans>Delete</Trans>
const s_DELETE_ATTACHMENT = <Trans>Delete Attachment</Trans>
const s_DYNAMIC_DATES = <Trans>Dynamic Dates</Trans>
const s_EASTERN_TIME = <Trans>Eastern Time</Trans>
const s_EDIT = <Trans>Edit</Trans>
const s_EDIT_SEND_DATE = <Trans>Edit Send Date</Trans>
const s_EMAIL = <Trans>Email</Trans>
const s_EMAIL_FAILED_TO_SEND = <Trans>Email failed to send</Trans>
const s_EMAIL_NOT_SENT_YET = <Trans>Email not sent yet</Trans>
const s_EMAIL_SENT_SUCCESSFULLY = <Trans>Email sent successfully</Trans>
const s_END_DATE = <Trans>End Date</Trans>
const s_END_TIME = <Trans>End Time</Trans>
const s_FILTER_BY = <Trans>Filter By</Trans>
const s_IN_QUEUE = <Trans>In Queue</Trans>
const s_LOGS = <Trans>Logs</Trans>
const s_MISSING_SELECTION = <Trans>Missing Selection</Trans>
const s_MOUNTAIN_TIME = <Trans>Mountain Time</Trans>
const s_NOT_IN_QUEUE = <Trans>Not in Queue</Trans>
const s_NOT_SELECTED = <Trans>Not Selected</Trans>
const s_PACIFIC_TIME = <Trans>Pacific Time</Trans>
const s_RECIPIENTS = <Trans>Recipients</Trans>
const s_REPORT_FILE_NAME_FOR_EMAIL_RECIPIENT = <Trans>Report File Name for Email Recipient</Trans>
const s_REPORT_TEMPLATE = <Trans>Report Template</Trans>
const s_SCHEDULE = <Trans>Schedule</Trans>
const s_SCHEDULED_DATE = <Trans>Scheduled Date</Trans>
const s_SEND_DATE = <Trans>Send Date</Trans>
const s_SEND_TIME = <Trans>Send Time</Trans>
const s_SEND_TO = <Trans>Send To</Trans>
const s_SPECIFIC_DATES = <Trans>Specific Dates</Trans>
const s_START_DATE = <Trans>Start Date</Trans>
const s_START_TIME = <Trans>Start Time</Trans>
const s_SUBJECT = <Trans>Subject</Trans>
const s_TIMEZONE = <Trans>Timezone</Trans>
// { sort-end } - displayed text

// Form Options
const startDateEmailAttachmentTimeframeOptions = {
  // previous_day: { key: "previous_day", value: s_PREVIOUS_DAY },
  // month_to_date: { key: "month_to_date", value: s_MONTH_TO_DATE },
  // rolling_30_days: { key: "rolling_30_days", value: s_ROLLING_30_DAYS },
  first_day_of_month: { key: 'first_day_of_month', value: rLIB('First Day of Month') },
  day_before_email: { key: 'day_before_email', value: s_DAY_BEFORE_EMAIL },
  day_of_email: { key: 'day_of_email', value: s_DAY_OF_EMAIL },
}
const endDateEmailAttachmentTimeframeOptions = {
  // previous_day: { key: "previous_day", value: s_PREVIOUS_DAY },
  // month_to_date: { key: "month_to_date", value: s_MONTH_TO_DATE },
  // rolling_30_days: { key: "rolling_30_days", value: s_ROLLING_30_DAYS },
  day_before_email: { key: 'day_before_email', value: s_DAY_BEFORE_EMAIL },
  day_of_email: { key: 'day_of_email', value: s_DAY_OF_EMAIL },
}

const dateRangeOptions = {
  dynamic: { key: 'dynamic', value: s_DYNAMIC_DATES },
  specific: { key: 'specific', value: s_SPECIFIC_DATES },
}

const timezoneOptions = {
  et: { key: 'et', value: s_EASTERN_TIME },
  ct: { key: 'ct', value: s_CENTRAL_TIME },
  mt: { key: 'mt', value: s_MOUNTAIN_TIME },
  pt: { key: 'pt', value: s_PACIFIC_TIME },
}

// Table
const tableColumns_EmailLogs: TsInterface_TableColumns = {
  TEMP_ICON: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-2'
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-max-w-2'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        switch (getProp(rowData, 'status', null)) {
          case 'success':
            cellJSX = (
              <Tooltip
                placement="right"
                title={s_EMAIL_SENT_SUCCESSFULLY}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="badge-check"
                    sx={{ fontSize: '20px', color: themeVariables.success_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          case 'failure':
            cellJSX = (
              <Tooltip
                placement="right"
                title={s_EMAIL_FAILED_TO_SEND}
              >
                <Box
                  className="tw-inline-block"
                  sx={{ maxWidth: '20px' }}
                >
                  <Icon
                    icon="triangle-exclamation"
                    sx={{ fontSize: '20px', color: themeVariables.error_main }}
                  />
                </Box>
              </Tooltip>
            )
            break
          default:
            cellJSX = <></>
            break
        }
        return (
          <Box
            className="tw-inline-block"
            sx={{ maxWidth: '20px' }}
          >
            {cellJSX}
          </Box>
        )
      },
    },
  },
  timestamp: TableCellTimestamp('timestamp', s_SEND_DATE, 'timestamp', 'YYYY-MM-DD h:mm a', true),
  to_recipients: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_SEND_TO
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            {getProp(getProp(rowData, 'email', {}), 'to_recipients', [])
              .sort()
              .map((email: string, index: number) => (
                <Chip
                  key={index}
                  className="tw-mr-1"
                  color={isValidEmail(email) ? 'default' : 'error'}
                  label={<Box>{email}</Box>}
                />
              ))}
          </Box>
        )
        return cellJSX
      },
    },
  },
  subject: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_SUBJECT
      },
      header_sort_by: null,
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box>{getProp(getProp(rowData, 'email', {}), 'subject', '')}</Box>
        return cellJSX
      },
    },
  },
}

const tableSettings_EmailLogs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

///////////////////////////////
// Functions
///////////////////////////////

export const isValidEmail = (email: string) => {
  // Regular expression for basic email validation
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

///////////////////////////////
// Component
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const ScheduledEmailFormDialog: React.FC<TsInterface_ScheduledEmailDialog> = ({ emailKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activePersistentClasses, us_setActivePersistentClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_email, us_setEmail] = useState<TsInterface_UnspecifiedObject>({})
  const [us_emailBody, us_setEmailBody] = useState<string>('')
  const [us_emailLogs, us_setEmailLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_emailSubject, us_setEmailSubject] = useState<string>('')
  const inputRef = useRef(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setEmail(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, emailKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActivePersistentClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActivePersistentClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // TODO - change to query most recent 100
  // useEffect(() => {
  // let unsubscribeLiveData: TsType_VoidFunction
  // const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //   us_setEmailLogs(newData)
  //   ur_forceRerender()
  // }
  // getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //   .then((res_GCK) => {
  //     unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledEmailLogs_Collection(res_GCK.clientKey, emailKey), updateLiveData)
  //   })
  //   .catch((rej_GCK) => {
  //     console.error(rej_GCK)
  //   })
  // return () => {
  //   if (typeof unsubscribeLiveData === 'function') {
  //     unsubscribeLiveData()
  //   }
  // }
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, emailKey])

  useEffect(() => {
    us_setEmailSubject(getProp(us_email, 'subject', ''))
    us_setEmailBody(getProp(us_email, 'body', ''))
  }, [us_email])

  // Functions
  const updateToRecipients = (chipsArray: string[]) => {
    let cleanEmailObject: TsInterface_UnspecifiedObject = {}
    for (let loopIndex = 0; loopIndex < chipsArray.length; loopIndex++) {
      let loopEmail = chipsArray[loopIndex]
      cleanEmailObject[loopEmail] = loopEmail
    }
    // Update Object
    let toArray: string[] = []
    for (let loopKey in cleanEmailObject) {
      toArray.push(loopKey)
    }
    let updateObject: TsInterface_UnspecifiedObject = {
      to_recipients: toArray,
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
          .then((res_DSMD) => {
            console.log(res_DSMD)
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const getFileExtension = (attachment: TsInterface_UnspecifiedObject) => {
    let extension = ''
    switch (getProp(attachment, 'associated_report_file_type', '')) {
      case 'csv':
        extension = '.csv'
        break
      case 'excel':
        extension = '.xlsx'
        break
      case 'pdf':
        extension = '.pdf'
        break
      default:
        extension = ''
        break
    }
    return extension
  }

  const addOrEditAttachment = (addOrEdit: 'add' | 'edit', attachmentKey: string | null, attachmentData: TsInterface_UnspecifiedObject) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        // Get Report Template Options
        let reportTemplateOptions: TsInterface_UnspecifiedObject[] = []
        let reportTemplateOptionsObject: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey))
            .then((res_DGC) => {
              reportTemplateOptionsObject = res_DGC.data
              for (let loopTemplateKey in res_DGC.data) {
                let loopTemplate = res_DGC.data[loopTemplateKey]
                reportTemplateOptions.push({
                  key: loopTemplate.key,
                  value: loopTemplate.name,
                })
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        // Get Data Bucket Options
        let dataBucketOptions: TsInterface_UnspecifiedObject[] = []
        let dataBucketOptionsObject: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey))
            .then((res_DGC) => {
              dataBucketOptionsObject = res_DGC.data
              for (let loopDataBucketKey in res_DGC.data) {
                let loopDataBucket = res_DGC.data[loopDataBucketKey]
                dataBucketOptions.push({
                  key: loopDataBucket.key,
                  value: loopDataBucket.name,
                })
              }
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        // After Data Loaded
        Promise.all(promiseArray).finally(() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: attachmentData,
                formInputs: {
                  header1: {
                    data_type: 'string',
                    key: 'header1',
                    input_type: 'custom_form_input_jsx',
                    label: <></>,
                    required: false,
                    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                      let inputJSX = (
                        <Box className="tw-text-left tw-mt-0">
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 700 }}
                            className="tw-mt-0"
                          >
                            {s_REPORT_TEMPLATE}
                          </Typography>
                        </Box>
                      )
                      return inputJSX
                    },
                  },
                  associated_report_template_key: {
                    data_type: 'string',
                    input_type: 'multiple_choice_select',
                    key: 'associated_report_template_key',
                    label: s_REPORT_TEMPLATE,
                    options: reportTemplateOptions,
                    required: true,
                  },
                  associated_report_name_override: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'associated_report_name_override',
                    label: s_REPORT_FILE_NAME_FOR_EMAIL_RECIPIENT,
                    required: true,
                  },
                  header2: {
                    data_type: 'string',
                    key: 'header2',
                    input_type: 'custom_form_input_jsx',
                    label: <></>,
                    required: false,
                    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                      let inputJSX = (
                        <Box className="tw-text-left">
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, marginTop: '12px' }}
                          >
                            {s_DATA_TO_QUERY}
                          </Typography>
                        </Box>
                      )
                      return inputJSX
                    },
                  },
                  associated_data_bucket_keys: {
                    data_type: 'string',
                    input_type: 'multiple_select_dropdown',
                    key: 'associated_data_bucket_keys',
                    label: s_DATA_BUCKETS,
                    options: dataBucketOptions,
                    required: true,
                  },
                  query_date_range_type: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'query_date_range_type',
                    label: s_DATE_RANGE_TYPE,
                    options: objectToArray(dateRangeOptions),
                    required: true,
                  },
                  header3: {
                    data_type: 'string',
                    key: 'header3',
                    input_type: 'custom_form_input_jsx',
                    label: <></>,
                    required: false,
                    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                      let inputJSX = (
                        <Box className="tw-text-left">
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, marginTop: '12px' }}
                          >
                            {s_START_DATE}
                          </Typography>
                        </Box>
                      )
                      return inputJSX
                    },
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '!=',
                        value: null,
                        conditions: [],
                      },
                    },
                  },
                  query_dynamic_start_date: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'query_dynamic_start_date',
                    label: s_START_DATE,
                    options: objectToArray(startDateEmailAttachmentTimeframeOptions),
                    required: false,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'dynamic', // dynamic | specific
                        conditions: [],
                      },
                    },
                    conditional_require: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'dynamic', // dynamic | specific
                        conditions: [],
                      },
                    },
                  },
                  query_specific_start_date: {
                    data_type: 'string',
                    input_type: 'timestamp_date',
                    key: 'query_specific_start_date',
                    label: s_START_DATE,
                    required: false,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'specific', // dynamic | specific
                        conditions: [],
                      },
                    },
                    conditional_require: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'specific', // dynamic | specific
                        conditions: [],
                      },
                    },
                  },
                  query_start_time: {
                    data_type: 'string',
                    input_type: 'timestamp_time',
                    key: 'query_start_time',
                    label: s_START_TIME,
                    required: true,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '!=',
                        value: null,
                        conditions: [],
                      },
                    },
                  },
                  header4: {
                    data_type: 'string',
                    key: 'header4',
                    input_type: 'custom_form_input_jsx',
                    label: <></>,
                    required: false,
                    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                      let inputJSX = (
                        <Box className="tw-text-left">
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, marginTop: '12px' }}
                          >
                            {s_END_DATE}
                          </Typography>
                        </Box>
                      )
                      return inputJSX
                    },
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '!=',
                        value: null,
                        conditions: [],
                      },
                    },
                  },
                  query_dynamic_end_date: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'query_dynamic_end_date',
                    label: s_END_DATE,
                    options: objectToArray(endDateEmailAttachmentTimeframeOptions),
                    required: false,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'dynamic', // dynamic | specific
                        conditions: [],
                      },
                    },
                    conditional_require: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'dynamic', // dynamic | specific
                        conditions: [],
                      },
                    },
                  },
                  query_specific_end_date: {
                    data_type: 'string',
                    input_type: 'timestamp_date',
                    key: 'query_specific_end_date',
                    label: s_END_DATE,
                    required: false,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'specific', // dynamic | specific
                        conditions: [],
                      },
                    },
                    conditional_require: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '==',
                        value: 'specific', // dynamic | specific
                        conditions: [],
                      },
                    },
                  },
                  query_end_time: {
                    data_type: 'string',
                    input_type: 'timestamp_time',
                    key: 'query_end_time',
                    label: s_END_TIME,
                    required: true,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '!=',
                        value: null,
                        conditions: [],
                      },
                    },
                  },
                  header5: {
                    data_type: 'string',
                    key: 'header5',
                    input_type: 'custom_form_input_jsx',
                    label: <></>,
                    required: false,
                    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                      let inputJSX = (
                        <Box className="tw-text-left">
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, marginTop: '12px' }}
                          >
                            {s_TIMEZONE}
                          </Typography>
                        </Box>
                      )
                      return inputJSX
                    },
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '!=',
                        value: null,
                        conditions: [],
                      },
                    },
                  },
                  query_timezone: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'query_timezone',
                    label: s_TIMEZONE,
                    options: objectToArray(timezoneOptions),
                    required: true,
                    conditional_display: {
                      active: true,
                      logic: {
                        active: true,
                        logic_type: 'comparison',
                        source: 'formData',
                        prop: 'query_date_range_type',
                        comparator: '!=',
                        value: null,
                        conditions: [],
                      },
                    },
                  },
                  // display_form_data_json: {
                  // data_type: 'string',
                  // input_type: 'display_form_data_json',
                  // key: '',
                  // label: <></>
                  // }
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          attachments: getProp(us_email, 'attachments', {}),
                        }
                        if (addOrEdit === 'add' || attachmentKey == null) {
                          attachmentKey = uuidv4()
                        }
                        updateObject.attachments[attachmentKey] = formSubmittedData
                        updateObject.attachments[attachmentKey]['key'] = attachmentKey
                        updateObject.attachments[attachmentKey]['associated_report_template_name'] = getProp(
                          getProp(reportTemplateOptionsObject, formSubmittedData.associated_report_template_key, {}),
                          'name',
                          null,
                        )
                        updateObject.attachments[attachmentKey]['associated_report_file_type'] = getProp(
                          getProp(reportTemplateOptionsObject, formSubmittedData.associated_report_template_key, {}),
                          'file_type',
                          null,
                        )
                        updateObject.attachments[attachmentKey]['associated_data_bucket_names'] = {}
                        for (let loopDataBucketKey in formSubmittedData.associated_data_bucket_keys) {
                          updateObject.attachments[attachmentKey]['associated_data_bucket_names'][loopDataBucketKey] = getProp(
                            getProp(dataBucketOptionsObject, loopDataBucketKey, {}),
                            'name',
                            null,
                          )
                        }
                        DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{s_ADD_ATTACHMENT}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="circle-plus"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const addEmailToGlobalQueue = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey),
              data: {
                schedule_status: 'in_queue',
              },
            },
            {
              type: 'setMerge',
              ref: DatabaseRef_GlobalScheduledEmail_Document(emailKey),
              data: {
                schedule_status: 'in_queue',
                key: emailKey,
                client_key: res_GCK.clientKey,
                timestamp_email_scheduled: getProp(us_email, 'timestamp_email_scheduled', null),
              },
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    })
  }

  const removeEmailFromGlobalQueue = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey),
              data: {
                schedule_status: 'not_in_queue',
              },
            },
            {
              type: 'delete',
              ref: DatabaseRef_GlobalScheduledEmail_Document(emailKey),
              data: {},
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    })
  }

  const tableDatabaseEndpoint_EmailLogs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_ScheduledEmailLogs_Collection(uc_RootData_ClientKey as string, emailKey),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_EmailTabContent = (): JSX.Element => {
    let disableInputs = true
    if (us_email.schedule_status == null || us_email.schedule_status === 'not_in_queue') {
      disableInputs = false
    }
    let contentJSX = (
      <Card sx={{ padding: '16px' }}>
        <Box>
          <MuiChipsInput
            ref={inputRef}
            label={s_RECIPIENTS}
            placeholder={'Add Emails'}
            fullWidth={true}
            value={getProp(us_email, 'to_recipients', [])}
            onChange={(chipsArray: string[]) => {
              updateToRecipients(chipsArray)
            }}
            disabled={disableInputs}
            disableEdition={true}
            disableDeleteOnBackspace={true}
            onKeyDown={(event: any) => {
              if (event.key === 'Tab' && inputRef.current === document.activeElement) {
                event.preventDefault() // Prevent default tab behavior
                // Add the current input value as a chip
                if (
                  inputRef != null &&
                  inputRef.current != null &&
                  // @ts-expect-error
                  inputRef.current.value != null
                ) {
                  // @ts-expect-error
                  let inputValue = inputRef.current.value.trim()
                  if (inputValue) {
                    updateToRecipients([...getProp(us_email, 'to_recipients', []), inputValue])
                    // @ts-expect-error
                    inputRef.current.value = '' // Clear input value
                  }
                }
              }
            }}
            addOnBlur={true}
            renderChip={(Component, key, props) => {
              // eslint-disable-next-line
              if (isValidEmail(props.label) === true) {
                return (
                  <Component
                    {...props}
                    key={key}
                  />
                )
              } else {
                return (
                  <Component
                    {...props}
                    key={key}
                    color="error"
                  />
                )
              }
            }}
          />
        </Box>
        <Box sx={{ marginTop: '16px' }}>
          <TextField
            id="subject_input"
            label={s_SUBJECT}
            fullWidth={true}
            value={us_emailSubject}
            disabled={disableInputs}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setEmailSubject(event.target.value)
              }
            }}
            onBlur={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  subject: event.target.value,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                      .then((res_DSMD) => {
                        console.log(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          />
        </Box>
        <Box sx={{ marginTop: '12px' }}>
          {disableInputs ? <></> : <style>{'#body_input:hover{ border-color: #212121 !important; }'}</style>}
          <style>{'#body_input:focus{ outline-color: #444971 !important; }'}</style>
          <TextareaAutosize
            id="body_input"
            minRows={8}
            placeholder=""
            style={{
              border: '1px solid #C4C4C4',
              borderRadius: '5px',
              boxSizing: 'border-box',
              fontFamily: "'IBM Plex Sans', sans-serif",
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: 1.5,
              padding: '8px 12px',
              resize: 'none',
              width: '100%',
            }}
            disabled={disableInputs}
            value={us_emailBody}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setEmailBody(event.target.value)
              }
            }}
            onBlur={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  body: event.target.value,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                      .then((res_DSMD) => {
                        console.log(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          />
        </Box>
      </Card>
    )
    return contentJSX
  }

  const rJSX_FilterDropdownSelection = (classData: TsInterface_UnspecifiedObject, disableInputs: boolean): JSX.Element => {
    let selectionJSX = <></>
    if (getProp(getProp(us_email, 'associated_filter_class_keys', {}), classData.key, false) === true) {
      let editIconJSX = (
        <Icon
          icon="pen-to-square"
          sx={{
            'marginLeft': '8px',
            'opacity': 0.3,
            'cursor': 'pointer',
            '&:hover': {
              opacity: 1,
              color: themeVariables.success_main,
            },
          }}
          tooltip={s_EDIT}
          tooltipPlacement="right"
          onClick={() => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let promiseArray: TsType_UnknownPromise[] = []
                let directoryItemOptions: TsInterface_UnspecifiedObject[] = []
                let allDirectoryItems: TsInterface_UnspecifiedObject = {}
                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_ActiveDirectory_Query(res_GCK.clientKey, classData.key))
                    .then((res_DGC) => {
                      allDirectoryItems = res_DGC.data
                      for (let loopDirectoryItemKey in res_DGC.data) {
                        let loopDirectoryItem = res_DGC.data[loopDirectoryItemKey]
                        directoryItemOptions.push({
                          key: loopDirectoryItem.key,
                          value: loopDirectoryItem.name,
                        })
                      }
                    })
                    .catch((rej_DGC) => {
                      console.error(rej_DGC)
                    }),
                )
                // After Data Loaded
                Promise.all(promiseArray).finally(() => {
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: {},
                        formInputs: {
                          associated_report_template_key: {
                            data_type: 'string',
                            input_type: 'multiple_choice_select',
                            key: 'associated_report_template_key',
                            label: s_FILTER_BY,
                            options: directoryItemOptions,
                            required: true,
                          },
                        },
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            let updateObject: TsInterface_UnspecifiedObject = {
                              associated_filter_directory_item_keys: getProp(us_email, 'associated_filter_directory_item_keys', {}),
                              associated_filter_directory_item_names: getProp(us_email, 'associated_filter_directory_item_names', {}),
                            }
                            updateObject['associated_filter_directory_item_keys'][classData.key] = formSubmittedData.associated_report_template_key
                            updateObject['associated_filter_directory_item_names'][classData.key] =
                              allDirectoryItems[formSubmittedData.associated_report_template_key].name
                            DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: <>{s_ADD_FILTER}</>,
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="filter"
                          />
                        ),
                      },
                    },
                  })
                })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }}
        />
      )
      if (disableInputs === true) {
        editIconJSX = <></>
      }
      if (
        classData != null &&
        classData.key != null &&
        us_email != null &&
        us_email['associated_filter_class_keys'] != null &&
        us_email['associated_filter_directory_item_keys'] != null &&
        us_email['associated_filter_directory_item_names'] != null &&
        us_email['associated_filter_directory_item_keys'][classData.key] != null &&
        us_email['associated_filter_directory_item_names'][classData.key] != null
      ) {
        selectionJSX = (
          <Box sx={{ marginLeft: '48px' }}>
            <Typography
              sx={{ display: 'inline-block', opacity: 0.5 }}
              variant="body1"
            >
              {us_email['associated_filter_directory_item_names'][classData.key]}
            </Typography>
            {editIconJSX}
          </Box>
        )
      } else {
        selectionJSX = (
          <Box sx={{ marginLeft: '48px' }}>
            <Typography
              sx={{ display: 'inline-block', opacity: 0.3, fontStyle: 'italic' }}
              variant="body1"
            >
              {s_MISSING_SELECTION}
            </Typography>
            {editIconJSX}
          </Box>
        )
      }
    }
    return selectionJSX
  }

  const rJSX_AttachmentIcon = (attachment: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="file"
        sx={{ fontSize: '40px', color: themeVariables.gray_400 }}
      />
    )
    switch (getProp(attachment, 'associated_report_file_type', '')) {
      case 'pdf':
        iconJSX = (
          <Icon
            icon="file-pdf"
            sx={{ fontSize: '40px', color: themeVariables.error_main }}
          />
        )
        break
      case 'csv':
        iconJSX = (
          <Icon
            icon="file-csv"
            sx={{ fontSize: '40px', color: themeVariables.info_main }}
          />
        )
        break
      case 'excel':
        iconJSX = (
          <Icon
            icon="file-excel"
            sx={{ fontSize: '40px', color: themeVariables.success_main }}
          />
        )
        break
    }
    return iconJSX
  }

  const rJSX_DeleteIcon = (attachment: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (us_email.schedule_status == null || us_email.schedule_status === 'not_in_queue') {
      iconJSX = (
        <Icon
          icon="trash"
          tooltip={s_DELETE}
          tooltipPlacement="right"
          sx={{
            'cursor': 'pointer',
            'fontSize': '22px',
            'color': themeVariables.gray_300,
            '&:hover': {
              color: themeVariables.error_main,
            },
          }}
          onClick={() => {
            uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                icon: <Icon icon="trash" />,
                header: s_DELETE_ATTACHMENT,
                text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ATTACHMENT,
                submit_text: s_DELETE,
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      attachments: getProp(us_email, 'attachments', {}),
                    }
                    delete updateObject.attachments[attachment.key]
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseUpdateDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                          .then((res_DUD) => {
                            console.log(res_DUD)
                            resolve(res_DUD)
                          })
                          .catch((rej_DUD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                            reject(rej_DUD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
            })
          }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_EditIcon = (attachment: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (us_email.schedule_status == null || us_email.schedule_status === 'not_in_queue') {
      iconJSX = (
        <Icon
          icon="pen-to-square"
          tooltip={s_EDIT}
          tooltipPlacement="right"
          sx={{
            'cursor': 'pointer',
            'fontSize': '22px',
            'color': themeVariables.gray_300,
            '&:hover': {
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            addOrEditAttachment('edit', attachment.key, attachment)
          }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_DataBucketFilterList = (attachment: TsInterface_UnspecifiedObject): JSX.Element => {
    let chipsJSX = <></>
    let activeDataBuckets: TsInterface_UnspecifiedObject = []
    for (let loopAttachmentKey in getProp(attachment, 'associated_data_bucket_keys', {})) {
      if (
        attachment['associated_data_bucket_keys'][loopAttachmentKey] === true &&
        attachment['associated_data_bucket_names'] != null &&
        attachment['associated_data_bucket_names'][loopAttachmentKey] != null
      ) {
        activeDataBuckets.push(attachment['associated_data_bucket_names'][loopAttachmentKey])
      }
    }
    chipsJSX = activeDataBuckets.join(', ')
    return chipsJSX
  }

  const rJSX_AttachmentDateRange = (attachment: TsInterface_UnspecifiedObject): JSX.Element => {
    let dateRangeJSX = <></>
    if (attachment != null && attachment.query_date_range_type === 'dynamic') {
      dateRangeJSX = (
        <Box className="tw-inline-block">
          <Icon
            icon="clock"
            sx={{ fontSize: '16px', opacity: 0.4 }}
          />
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>
            {getProp(
              getProp(startDateEmailAttachmentTimeframeOptions, getProp(attachment, 'query_dynamic_start_date', ''), {}),
              'value',
              getProp(attachment, 'query_dynamic_start_date', ''),
            )}
          </Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>{getProp(attachment, 'query_start_time', '')}</Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}> - </Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>
            {getProp(
              getProp(endDateEmailAttachmentTimeframeOptions, getProp(attachment, 'query_dynamic_end_date', ''), {}),
              'value',
              getProp(attachment, 'query_dynamic_end_date', ''),
            )}
          </Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>{getProp(attachment, 'query_end_time', '')}</Box>
          <Box sx={{ opacity: 0.4, display: 'inline-block', marginLeft: '4px' }}>
            ({getProp(getProp(timezoneOptions, getProp(attachment, 'query_timezone', ''), {}), 'value', getProp(attachment, 'query_timezone', ''))})
          </Box>
        </Box>
      )
    } else if (attachment != null && attachment.query_date_range_type === 'specific') {
      dateRangeJSX = (
        <Box className="tw-inline-block">
          <Icon
            icon="clock"
            sx={{ fontSize: '16px', opacity: 0.4 }}
          />
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>{getProp(attachment, 'query_specific_start_date', '')}</Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>{getProp(attachment, 'query_start_time', '')}</Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}> - </Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>{getProp(attachment, 'query_specific_end_date', '')}</Box>
          <Box sx={{ display: 'inline-block', marginLeft: '4px' }}>{getProp(attachment, 'query_end_time', '')}</Box>
          <Box sx={{ opacity: 0.4, display: 'inline-block', marginLeft: '4px' }}>
            ({getProp(getProp(timezoneOptions, getProp(attachment, 'query_timezone', ''), {}), 'value', getProp(attachment, 'query_timezone', ''))})
          </Box>
        </Box>
      )
    }
    return dateRangeJSX
  }

  const rJSX_AttachmentsTabContent = (): JSX.Element => {
    let disableInputs = true
    if (us_email.schedule_status == null || us_email.schedule_status === 'not_in_queue') {
      disableInputs = false
    }
    let contentJSX = (
      <Card sx={{ padding: '16px' }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: '700' }}
        >
          {s_DATA_FILTERS}
        </Typography>
        <Box className="tw-ml-4">
          {objectToArray(us_activePersistentClasses)
            .sort(dynamicSort('name', null))
            .map((loopClass: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                key={index}
                sx={{ opacity: getProp(getProp(us_email, 'associated_filter_class_keys', {}), loopClass.key, false) ? 1 : 0.3 }}
              >
                <Box>
                  <Checkbox
                    checked={getProp(getProp(us_email, 'associated_filter_class_keys', {}), loopClass.key, false)}
                    onChange={(event, value) => {
                      if (value != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          associated_filter_class_keys: {
                            [loopClass.key]: value,
                          },
                        }
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                              .then((res_DSMD) => {
                                console.log(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                console.error(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }}
                    disabled={disableInputs}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {loopClass.name}
                </Box>
                <Box>{rJSX_FilterDropdownSelection(loopClass, disableInputs)}</Box>
              </Box>
            ))}
        </Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: '700', marginTop: '16px' }}
        >
          {s_ATTACHMENTS}
        </Typography>
        <Box sx={{ marginBottom: '12px', marginLeft: '16px' }}>
          {objectToArray(getProp(us_email, 'attachments', {}))
            .sort(dynamicSort('associated_report_name_override', null))
            .map((attachment: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                key={index}
                sx={{ display: 'block', alignItems: 'center', marginTop: '8px' }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ border: '1px solid rgba(0,0,0,0.2)', borderRadius: '6px', paddingLeft: '8px', justifyContent: 'space-between' }}
                >
                  <Box>
                    <Stack
                      direction="row"
                      spacing={2}
                    >
                      <Box sx={{ width: '24px', paddingTop: '6px' }}>{rJSX_AttachmentIcon(attachment)}</Box>
                      <Box>
                        <Box>
                          <Icon
                            icon="paperclip"
                            sx={{ fontSize: '16px', opacity: 0.4, marginRight: '4px' }}
                          />
                          <Box sx={{ fontWeight: 700, display: 'inline-block' }}>
                            {getProp(attachment, 'associated_report_name_override', '')}
                            {getFileExtension(attachment)}
                          </Box>
                          <Box sx={{ opacity: 0.4, fontStyle: 'italic', display: 'inline-block', marginLeft: '4px' }}>
                            ({s_REPORT_TEMPLATE}: {getProp(attachment, 'associated_report_template_name', '')})
                          </Box>
                        </Box>
                        <Box>{rJSX_AttachmentDateRange(attachment)}</Box>
                        <Box>
                          <Icon
                            icon="filter"
                            sx={{ fontSize: '16px', opacity: 0.4, marginRight: '4px' }}
                          />
                          {rJSX_DataBucketFilterList(attachment)}
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                  <Box sx={{ width: '32px', paddingTop: '6px' }}>
                    {rJSX_EditIcon(attachment)}
                    {rJSX_DeleteIcon(attachment)}
                  </Box>
                </Stack>
              </Box>
            ))}
        </Box>
        <Button
          variant="contained"
          color="success"
          startIcon={<Icon icon="circle-plus" />}
          onClick={() => {
            addOrEditAttachment('add', null, {})
          }}
          disabled={disableInputs}
          size="small"
          sx={{ marginLeft: '16px' }}
        >
          {s_ADD_ATTACHMENT}
        </Button>
      </Card>
    )
    return contentJSX
  }

  const rJSX_ScheduledDate = (): JSX.Element => {
    let scheduleDateJSX = <></>
    let editIcon = <></>
    if (us_email.schedule_status == null || us_email.schedule_status === 'not_in_queue') {
      editIcon = (
        <Icon
          icon="pen-to-square"
          sx={{
            'marginLeft': '8px',
            'opacity': 0.3,
            'cursor': 'pointer',
            '&:hover': {
              opacity: 1,
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            let formData: TsInterface_UnspecifiedObject = {}
            if (us_email != null && us_email['timestamp_email_scheduled'] != null) {
              formData['timestamp_email_scheduled'] = returnFormattedDate(us_email['timestamp_email_scheduled'], 'YYYY-MM-DDTHH:mm')
            }
            if (us_email != null && us_email['timestamp_email_scheduled_timezone'] != null) {
              formData['timestamp_email_scheduled_timezone'] = us_email['timestamp_email_scheduled_timezone']
            }
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: formData,
                  formInputs: {
                    timestamp_email_scheduled: {
                      data_type: 'string',
                      input_type: 'timestamp_datetime',
                      key: 'timestamp_email_scheduled',
                      label: s_SEND_TIME,
                      required: true,
                    },
                    timestamp_email_scheduled_timezone: {
                      data_type: 'string',
                      input_type: 'multiple_choice_radio',
                      key: 'timestamp_email_scheduled_timezone',
                      label: s_TIMEZONE,
                      options: objectToArray(timezoneOptions),
                      required: true,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            timestamp_email_scheduled: new Date(formSubmittedData.timestamp_email_scheduled),
                            timestamp_email_scheduled_timezone: formSubmittedData.timestamp_email_scheduled_timezone,
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ScheduledEmail_Document(res_GCK.clientKey, emailKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{s_EDIT_SEND_DATE}</>,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="circle-plus"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    }
    if (us_email != null && us_email.timestamp_email_scheduled != null) {
      scheduleDateJSX = (
        <Box>
          <Typography variant="h6">
            <Box
              component="span"
              sx={{ marginRight: '4px', fontWeight: '700' }}
            >
              {s_SCHEDULED_DATE}:
            </Box>
            <Box
              component="span"
              sx={{ color: themeVariables.info_main }}
            >
              {returnFormattedDate(us_email.timestamp_email_scheduled, 'YYYY-MM-DD HH:mm')} {us_email.timestamp_email_scheduled_timezone.toUpperCase()}
            </Box>
            {editIcon}
          </Typography>
        </Box>
      )
    } else {
      scheduleDateJSX = (
        <Box>
          <Typography variant="h6">
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {s_SCHEDULED_DATE}:
            </Box>
            <Box
              component="span"
              sx={{ opacity: 0.4, fontStyle: 'italic' }}
            >
              {s_NOT_SELECTED}
            </Box>
            {editIcon}
          </Typography>
        </Box>
      )
    }
    return scheduleDateJSX
  }

  const rJSX_SendStatusButtons = (): JSX.Element => {
    // Result Button
    let disableQueueButtons = false
    let resultButtonJSX = <></>
    switch (getProp(us_email, 'schedule_status', null)) {
      case 'success':
        disableQueueButtons = true
        resultButtonJSX = (
          <Button
            variant={getProp(us_email, 'schedule_status', 'not_in_queue') === 'success' ? 'contained' : 'outlined'}
            color={getProp(us_email, 'schedule_status', 'not_in_queue') === 'success' ? 'success' : 'inherit'}
            sx={{ marginRight: '8px' }}
            size="small"
            startIcon={<Icon icon="badge-check" />}
          >
            {s_EMAIL_SENT_SUCCESSFULLY}
          </Button>
        )
        break
      case 'temporary_failure':
        disableQueueButtons = true
        resultButtonJSX = (
          <Button
            variant={getProp(us_email, 'schedule_status', 'not_in_queue') === 'success' ? 'contained' : 'outlined'}
            color={getProp(us_email, 'schedule_status', 'not_in_queue') === 'success' ? 'warning' : 'inherit'}
            sx={{ marginRight: '8px' }}
            size="small"
            startIcon={<Icon icon="triangle-exclamation" />}
          >
            {s_EMAIL_FAILED_TO_SEND}
          </Button>
        )
        break
      case 'permanent_failure':
        disableQueueButtons = true
        resultButtonJSX = (
          <Button
            variant={getProp(us_email, 'schedule_status', 'not_in_queue') === 'success' ? 'contained' : 'outlined'}
            color={getProp(us_email, 'schedule_status', 'not_in_queue') === 'success' ? 'error' : 'inherit'}
            sx={{ marginRight: '8px' }}
            size="small"
            startIcon={<Icon icon="triangle-exclamation" />}
          >
            {s_EMAIL_FAILED_TO_SEND}
          </Button>
        )
        break
      default:
        resultButtonJSX = (
          <Button
            variant={'outlined'}
            color={'inherit'}
            sx={{ marginRight: '8px' }}
            size="small"
            startIcon={<Icon icon="clock" />}
          >
            {s_EMAIL_NOT_SENT_YET}
          </Button>
        )
        break
    }
    // Inactive Button
    let inactiveButtonJSX = (
      <Button
        variant={getProp(us_email, 'schedule_status', 'not_in_queue') === 'not_in_queue' ? 'contained' : 'outlined'}
        color={getProp(us_email, 'schedule_status', 'not_in_queue') === 'not_in_queue' ? 'warning' : 'inherit'}
        sx={{ marginRight: '8px' }}
        size="small"
        disabled={disableQueueButtons}
        onClick={() => {
          removeEmailFromGlobalQueue()
        }}
        startIcon={<Icon icon="circle-pause" />}
      >
        {s_NOT_IN_QUEUE}
      </Button>
    )
    // Active Button
    let activeButtonJSX = (
      <Button
        variant={getProp(us_email, 'schedule_status', 'not_in_queue') === 'in_queue' ? 'contained' : 'outlined'}
        color={getProp(us_email, 'schedule_status', 'not_in_queue') === 'in_queue' ? 'info' : 'inherit'}
        sx={{ marginRight: '8px' }}
        size="small"
        disabled={getProp(us_email, 'timestamp_email_scheduled', null) == null || disableQueueButtons}
        onClick={() => {
          addEmailToGlobalQueue()
        }}
        startIcon={<Icon icon="circle-play" />}
      >
        {s_IN_QUEUE}
      </Button>
    )

    // HASNT BEEN SENT
    // SUCCESS
    // TEMP FAILURE
    // PERMANENT FAILURE

    let buttonsJSX = (
      <Box>
        {inactiveButtonJSX}
        {activeButtonJSX}
        {resultButtonJSX}
      </Box>
    )

    return buttonsJSX
  }

  const rJSX_ScheduleTabContent = (): JSX.Element => {
    let contentJSX = (
      <Card sx={{ padding: '8px' }}>
        {rJSX_ScheduledDate()}
        <Box sx={{ marginLeft: '24px', marginTop: '12px' }}>{rJSX_SendStatusButtons()}</Box>
      </Card>
    )
    return contentJSX
  }

  const rJSX_LogsTabContent = (): JSX.Element => {
    let contentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      contentJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_EmailLogs}
            tableDatabaseEndpoint={tableDatabaseEndpoint_EmailLogs}
            tableSettings={tableSettings_EmailLogs}
          />
          <Box sx={{ textAlign: 'right', opacity: 0.3, padding: '4px' }}>{emailKey}</Box>
        </Card>
      )
    }
    return contentJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.info_main }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="paper-plane" />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{ flexGrow: 1, color: themeVariables.white }}
              >
                {getProp(us_email, 'name', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '8px' }}>
            <TabsBasic
              tabs={[
                {
                  tabHeader: s_EMAIL,
                  tabContent: rJSX_EmailTabContent(),
                },
                {
                  tabHeader: s_ATTACHMENTS,
                  tabContent: rJSX_AttachmentsTabContent(),
                },
                {
                  tabHeader: s_SCHEDULE,
                  tabContent: rJSX_ScheduleTabContent(),
                },
                {
                  tabHeader: s_LOGS,
                  tabContent: rJSX_LogsTabContent(),
                },
              ]}
              tabsSettings={{}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
