///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			External Requests contain all functions that interface with cloud function endpoints or external APIs

		TODO:


	*/

///////////////////////////////
// Imports
///////////////////////////////

import axios from 'axios'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { waitForAuthenticationVerification } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Types
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings

// Cloud Functions
const functions = getFunctions()

///////////////////////////////
// Functions
///////////////////////////////

const parseCloudFunctionError = (cloudFunctionError: any): TsInterface_UnspecifiedObject => {
  if (typeof cloudFunctionError === 'string') {
    return { message: cloudFunctionError }
  } else if (typeof cloudFunctionError === 'object') {
    return cloudFunctionError
  } else if (cloudFunctionError != null && cloudFunctionError.message != null) {
    return JSON.parse(cloudFunctionError.message)
  } else {
    return { message: 'parseCloudFunctionError' }
  }
}

///////////////////////////////
// Exports
///////////////////////////////

export const cloudFunctionUnauthenticatedRequests = (requestParams: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'GET',
      url: 'https://us-central1-data-ore-app.cloudfunctions.net/unauthenticatedRequests',
      params: requestParams,
    }
    axios
      .request(config)
      .then((res_A) => {
        resolve(res_A)
      })
      .catch((rej_A) => {
        reject(rej_A)
      })
  })
}

export const cloudFunctionManageUserRequest = (requestParams: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    waitForAuthenticationVerification().finally(() => {
      const cloudFunction = httpsCallable(functions, 'manageUser')
      cloudFunction(requestParams)
        .then((res_CF) => {
          resolve(res_CF.data)
        })
        .catch((rej_CF) => {
          reject(parseCloudFunctionError(rej_CF))
        })
    })
  })
}

export const cloudFunctionManageQRCodeRequest = (requestParams: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    waitForAuthenticationVerification().finally(() => {
      const cloudFunction = httpsCallable(functions, 'manageQRCode')
      cloudFunction(requestParams)
        .then((res_CF) => {
          resolve(res_CF.data)
        })
        .catch((rej_CF) => {
          reject(parseCloudFunctionError(rej_CF))
        })
    })
  })
}

export const cloudFunctionSendEmailRequest = (requestParams: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    waitForAuthenticationVerification().finally(() => {
      const cloudFunction = httpsCallable(functions, 'sendEmail')
      cloudFunction(requestParams)
        .then((res_CF) => {
          resolve(res_CF.data)
        })
        .catch((rej_CF) => {
          reject(parseCloudFunctionError(rej_CF))
        })
    })
  })
}
