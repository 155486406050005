/*
 FILE COPIED TO SERVER
*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_DataBucketsTiedToReconciliation_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { DatabaseRef_AutomaticReconciliation_Document } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/automatic_reconciliations'
import {
  DatabaseRef_DataForSpecificDataBucket_DateRange_Query,
  DatabaseRef_DataForSpecificReconciliation_DateRange_Query,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetCollection, DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, getProp, objectToArray, returnFormattedDate, returnTimestampFromUnknownDateFormat } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const exampleStationLogs = [
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'SCA',
    data: {
      ticket_number: 101,
      // ticket_number: null,
      delivery_start_meter: 0,
      // delivery_start_meter: null,
      delivery_stop_meter: 175,
      // delivery_stop_meter: null,
      delivered_bbls: 175,
      // delivered_bbls: null,
    },
    timestamp_primary_query: new Date('4/1/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'SCA',
    data: {
      ticket_number: 102,
      delivery_start_meter: 174.3,
      delivery_stop_meter: 355,
      delivered_bbls: 180,
    },
    timestamp_primary_query: new Date('4/2/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'SCA',
    data: {
      ticket_number: 103,
      delivery_start_meter: 355,
      delivery_stop_meter: 530,
      delivered_bbls: 175,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'SCA',
    data: {
      ticket_number: 104,
      delivery_start_meter: 530,
      delivery_stop_meter: 710,
      delivered_bbls: 180,
    },
    timestamp_primary_query: new Date('4/4/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'SCA',
    data: {
      ticket_number: 106,
      delivery_start_meter: 890,
      delivery_stop_meter: 1065,
      delivered_bbls: 175,
    },
    timestamp_primary_query: new Date('4/6/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'SEA',
    data: {
      ticket_number: 201,
      delivery_start_meter: 0,
      delivery_stop_meter: 180,
      delivered_bbls: 180,
    },
    timestamp_primary_query: new Date('4/1/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'SEA',
    data: {
      ticket_number: 202,
      delivery_start_meter: 180,
      delivery_stop_meter: 350,
      delivered_bbls: 170,
    },
    timestamp_primary_query: new Date('4/2/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'SEA',
    data: {
      ticket_number: 203,
      delivery_start_meter: 350,
      delivery_stop_meter: 535,
      delivered_bbls: 185,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'SEA',
    data: {
      ticket_number: 204,
      delivery_start_meter: 535,
      delivery_stop_meter: 705,
      delivered_bbls: 170,
    },
    timestamp_primary_query: new Date('4/4/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'SEA',
    data: {
      ticket_number: 205,
      delivery_start_meter: 705,
      delivery_stop_meter: 895,
      delivered_bbls: 190,
    },
    timestamp_primary_query: new Date('4/5/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'SEA',
    data: {
      ticket_number: 206,
      delivery_start_meter: 895,
      delivery_stop_meter: 1080,
      delivered_bbls: 185,
    },
    timestamp_primary_query: new Date('4/6/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 301,
      delivery_start_meter: 0,
      delivery_stop_meter: 175,
      delivered_bbls: 175,
    },
    timestamp_primary_query: new Date('4/1/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 302,
      delivery_start_meter: 175,
      delivery_stop_meter: 350,
      delivered_bbls: 175,
    },
    timestamp_primary_query: new Date('4/2/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 303,
      delivery_start_meter: 350,
      delivery_stop_meter: 530,
      delivered_bbls: 180,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 304,
      delivery_start_meter: 530,
      delivery_stop_meter: 720,
      delivered_bbls: 190,
    },
    timestamp_primary_query: new Date('4/4/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 305,
      delivery_start_meter: 720,
      delivery_stop_meter: 900,
      delivered_bbls: 180,
    },
    timestamp_primary_query: new Date('4/5/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 305,
      delivery_start_meter: 900,
      delivery_stop_meter: 1090,
      delivered_bbls: 190,
    },
    timestamp_primary_query: new Date('4/5/24'),
    status: 'active',
  },
  {
    associated_class_key: 'crude_oil_metered',
    associated_data_bucket_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'SEB',
    data: {
      ticket_number: 306,
      delivery_start_meter: 1100,
      delivery_stop_meter: 1285,
      delivered_bbls: 185,
    },
    timestamp_primary_query: new Date('4/6/24'),
    status: 'active',
  },
]

export const exampleCarrierTickets = [
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 101,
      // ticket_number: null,
      start_meter: 0,
      // start_meter: null,
      stop_meter: 175,
      // stop_meter: null,
      volume: 175,
      // volume: null,
    },
    timestamp_primary_query: new Date('4/1/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      // ticket_number: 102,
      ticket_number: 104,
      start_meter: 175,
      stop_meter: 355,
      volume: 180,
    },
    timestamp_primary_query: new Date('5/2/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 104,
      start_meter: 530,
      stop_meter: 890,
      volume: 180,
    },
    timestamp_primary_query: new Date('4/4/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 201,
      start_meter: 0,
      stop_meter: 180,
      volume: 180,
    },
    timestamp_primary_query: new Date('4/1/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 204,
      start_meter: 535,
      stop_meter: 705,
      volume: 170,
    },
    timestamp_primary_query: new Date('4/4/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 205,
      start_meter: 705,
      stop_meter: 895,
      volume: 190,
    },
    timestamp_primary_query: new Date('4/5/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 209,
      start_meter: 895,
      stop_meter: 1080,
      volume: 185,
    },
    timestamp_primary_query: new Date('4/6/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 301,
      start_meter: 0,
      stop_meter: 175,
      volume: 175,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 303,
      start_meter: 175,
      stop_meter: 355,
      volume: 180,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier A',
    associated_offload_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 305,
      start_meter: 355,
      stop_meter: 535,
      volume: 180,
    },
    timestamp_primary_query: new Date('4/5/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier B',
    associated_offload_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 103,
      start_meter: 355,
      stop_meter: 530,
      volume: 175,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier B',
    associated_offload_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 106,
      start_meter: 890,
      stop_meter: 1065,
      volume: 175,
    },
    timestamp_primary_query: new Date('4/6/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier B',
    associated_offload_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 202,
      start_meter: 180,
      stop_meter: 350,
      volume: 170,
    },
    timestamp_primary_query: new Date('4/2/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier B',
    associated_offload_name: 'Eastpointe - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 203,
      start_meter: 350,
      stop_meter: 535,
      volume: 195,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier B',
    associated_offload_name: 'Clearfield - Atlas',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 105,
      start_meter: 710,
      stop_meter: 890,
      volume: 180,
    },
    timestamp_primary_query: new Date('4/5/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier B',
    associated_offload_name: 'Eastpointe - Bravo',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 306,
      start_meter: 1090,
      stop_meter: 1270,
      volume: 185,
    },
    timestamp_primary_query: new Date('4/6/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier C',
    associated_offload_name: 'OTHER',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 401,
      start_meter: 0,
      stop_meter: 175,
      volume: 175,
    },
    timestamp_primary_query: new Date('5/1/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier C',
    associated_offload_name: 'OTHER',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 402,
      start_meter: 175,
      stop_meter: 355,
      volume: 180,
    },
    timestamp_primary_query: new Date('4/2/24'),
    status: 'active',
  },
  {
    associated_class_key: 'carrier_ticket',
    associated_carrier_name: 'Carrier C',
    associated_offload_name: 'OTHER',
    associated_data_bucket_key: 'CT',
    data: {
      ticket_number: 403,
      start_meter: 355,
      stop_meter: 530,
      volume: 175,
    },
    timestamp_primary_query: new Date('4/3/24'),
    status: 'active',
  },
]

export const returnCombinedExampleData = (): TsInterface_UnspecifiedObject => {
  let combinedDataItems: TsInterface_UnspecifiedObject = {}
  for (let loopDataItemKey in exampleStationLogs) {
    let loopDataItem = exampleStationLogs[loopDataItemKey]
    combinedDataItems['sl_' + loopDataItemKey] = loopDataItem
  }
  for (let loopDataItemKey in exampleCarrierTickets) {
    let loopDataItem = exampleCarrierTickets[loopDataItemKey]
    combinedDataItems['ct_' + loopDataItemKey] = loopDataItem
  }
  return combinedDataItems
}

export const formatTime = (minutes: number) => {
  const days = Math.floor(minutes / 1440)
  const hours = Math.floor((minutes % 1440) / 60)
  const remainingMinutes = minutes % 60
  let result = []
  if (days > 0) result.push(days + ' day' + (days > 1 ? 's' : ''))
  if (hours > 0) result.push(hours + ' hour' + (hours > 1 ? 's' : ''))
  if (remainingMinutes > 0 || result.length === 0) result.push(remainingMinutes + ' minute' + (remainingMinutes > 1 ? 's' : ''))
  return result.join(', ')
}

export const getDataItemsForAutoReconciliation = (
  clientKey: string,
  reconciliationKey: string,
  reconciliationData: TsInterface_UnspecifiedObject | null,
  startDate: Date,
  endDate: Date,
  method: 'quick' | 'full',
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let promiseArray1: TsType_UnknownPromise[] = []
    let promiseArray2: TsType_UnknownPromise[] = []
    if (reconciliationData == null || objectToArray(reconciliationData).length === 0) {
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_AutomaticReconciliation_Document(clientKey, reconciliationKey))
          .then((res_DGD) => {
            reconciliationData = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
    }
    Promise.all(promiseArray1).finally(() => {
      if (reconciliationData != null) {
        if (method === 'full') {
          // Get Data Buckets tied to reconciliation
          DatabaseGetCollection(DatabaseRef_DataBucketsTiedToReconciliation_Query(clientKey, reconciliationKey))
            .then((res_GDC) => {
              let allDataBuckets: TsInterface_UnspecifiedObject = res_GDC.data
              let activeDataBuckets: TsInterface_UnspecifiedObject = {}
              let combinedDataItems: TsInterface_UnspecifiedObject = {}
              for (let loopDataBucketKey in allDataBuckets) {
                let loopDataBucket = allDataBuckets[loopDataBucketKey]
                if (loopDataBucket['status'] === 'active' && loopDataBucket['associated_reconciliation_active'] === true) {
                  activeDataBuckets[loopDataBucketKey] = loopDataBucket
                }
              }
              for (let loopDataBucketKey in activeDataBuckets) {
                // Get Data Items by data bucket - longer but is sure to grab everything
                promiseArray2.push(
                  DatabaseGetCollection(DatabaseRef_DataForSpecificDataBucket_DateRange_Query(clientKey, loopDataBucketKey, startDate, endDate))
                    .then((res_GDC) => {
                      for (let loopDataItemKey in res_GDC.data) {
                        let loopDataItem = res_GDC.data[loopDataItemKey]
                        combinedDataItems[loopDataItemKey] = loopDataItem
                      }
                    })
                    .catch((rej_GDC) => {
                      console.error(rej_GDC)
                    }),
                )
              }
              Promise.all(promiseArray2).finally(() => {
                resolve({
                  success: true,
                  data_buckets: activeDataBuckets,
                  data_items: combinedDataItems,
                  reconciliation: reconciliationData,
                  method: method,
                })
              })
            })
            .catch((rej_GDC) => {
              reject(rej_GDC)
            })
        } else {
          // Get Data Items by tag - would not grab data items added to reconciliation after creation
          DatabaseGetCollection(DatabaseRef_DataForSpecificReconciliation_DateRange_Query(clientKey, reconciliationKey, startDate, endDate))
            .then((res_GDC) => {
              resolve({
                success: true,
                data_buckets: {},
                data_items: res_GDC.data,
                reconciliation: reconciliationData,
                method: method,
              })
            })
            .catch((rej_GDC) => {
              reject(rej_GDC)
            })
        }
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to run reconciliation'),
            details: rLIB('No reconciliation data found for specified key'),
            code: 'ER-D-RAV-GDIFAR-01',
          },
        })
      }
    })
  })
}

export const runReconciliationOnDataSet = (
  reconciliation: TsInterface_UnspecifiedObject,
  dataBuckets: TsInterface_UnspecifiedObject,
  dataItems: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let reconciliationProcedure = getProp(reconciliation, 'associated_reconciliation_procedure', null)
    if (reconciliationProcedure === 'crude_oil_metered' || reconciliationProcedure === 'crude_oil_volume') {
      // Instantiate Variables
      let totals: TsInterface_UnspecifiedObject = {
        carrier_ticket_data_bucket_breakdown: {},
        station_log_data_bucket_breakdown: {},
        total_carrier_ticket_volume: 0,
        total_carrier_tickets: 0,
        total_linked_carrier_ticket_volume: 0,
        total_linked_station_log_volume: 0,
        total_station_log_volume: 0,
        total_station_logs: 0,
      }
      let errors: TsInterface_UnspecifiedObject = {
        gaps_in_station_log_meter_readings_by_data_bucket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'two_station_logs',
          key: 'gaps_in_station_log_meter_readings_by_data_bucket',
          order: 1,
        },
        overlaps_in_station_log_meter_readings_by_data_bucket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'two_station_logs',
          key: 'overlaps_in_station_log_meter_readings_by_data_bucket',
          order: 2,
        },
        volume_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'volume_discrepancies',
          order: 3,
        },
        start_meter_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'start_meter_discrepancies',
          order: 4,
        },
        stop_meter_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'stop_meter_discrepancies',
          order: 5,
        },
        timestamp_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'timestamp_discrepancies',
          order: 6,
        },
        station_logs_missing_start_meter: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log',
          key: 'station_logs_missing_start_meter',
          order: 7,
        },
        station_logs_missing_stop_meter: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log',
          key: 'station_logs_missing_stop_meter',
          order: 8,
        },
        station_logs_missing_volume: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log',
          key: 'station_logs_missing_volume',
          order: 9,
        },
        station_logs_missing_unique_identifier: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log',
          key: 'station_logs_missing_unique_identifier',
          order: 10,
        },
        station_logs_with_duplicate_unique_identifiers: {
          count: 0,
          station_logs: {},
          display_table_type: 'station_log_direct',
          key: 'station_logs_with_duplicate_unique_identifiers',
          order: 11,
        },
        station_logs_with_no_matching_carrier_ticket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log',
          key: 'station_logs_with_no_matching_carrier_ticket',
          order: 12,
        },
        station_logs_matched_with_multiple_carrier_tickets: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_multiple_carrier_tickets',
          key: 'station_logs_matched_with_multiple_carrier_tickets',
          order: 13,
        },
        carrier_tickets_missing_start_meter: {
          count: 0,
          data_buckets: {},
          display_table_type: 'carrier_ticket',
          key: 'carrier_tickets_missing_start_meter',
          order: 14,
        },
        carrier_tickets_missing_stop_meter: {
          count: 0,
          data_buckets: {},
          display_table_type: 'carrier_ticket',
          key: 'carrier_tickets_missing_stop_meter',
          order: 15,
        },
        carrier_tickets_missing_volume: {
          count: 0,
          data_buckets: {},
          display_table_type: 'carrier_ticket',
          key: 'carrier_tickets_missing_volume',
          order: 16,
        },
        carrier_tickets_missing_unique_identifier: {
          count: 0,
          data_buckets: {},
          display_table_type: 'carrier_ticket',
          key: 'carrier_tickets_missing_unique_identifier',
          order: 17,
        },
        carrier_tickets_with_duplicate_unique_identifiers: {
          count: 0,
          carrier_tickets: {},
          display_table_type: 'carrier_ticket_direct',
          key: 'carrier_tickets_with_duplicate_unique_identifiers',
          order: 18,
        },
        carrier_tickets_with_no_matching_station_log: {
          count: 0,
          data_buckets: {},
          display_table_type: 'carrier_ticket',
          key: 'carrier_tickets_with_no_matching_station_log',
          order: 19,
        },
        carrier_tickets_matched_with_multiple_station_logs: {
          count: 0,
          data_buckets: {},
          display_table_type: 'carrier_ticket_multiple_station_logs',
          key: 'carrier_tickets_matched_with_multiple_station_logs',
          order: 20,
        },
      }
      let warnings: TsInterface_UnspecifiedObject = {
        gaps_in_station_log_meter_readings_by_data_bucket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'two_station_logs',
          key: 'gaps_in_station_log_meter_readings_by_data_bucket',
          order: 1,
        },
        overlaps_in_station_log_meter_readings_by_data_bucket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'two_station_logs',
          key: 'overlaps_in_station_log_meter_readings_by_data_bucket',
          order: 2,
        },
        start_meter_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'start_meter_discrepancies',
          order: 3,
        },
        stop_meter_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'stop_meter_discrepancies',
          order: 4,
        },
        timestamp_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'timestamp_discrepancies',
          order: 5,
        },
        volume_discrepancies: {
          count: 0,
          data_buckets: {},
          display_table_type: 'station_log_and_carrier_ticket',
          key: 'volume_discrepancies',
          order: 6,
        },
      }
      let info: TsInterface_UnspecifiedObject = {
        gaps_in_station_log_meter_readings_by_data_bucket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'two_station_logs',
          key: 'gaps_in_station_log_meter_readings_by_data_bucket',
          order: 1,
        },
        overlaps_in_station_log_meter_readings_by_data_bucket: {
          count: 0,
          data_buckets: {},
          display_table_type: 'two_station_logs',
          key: 'overlaps_in_station_log_meter_readings_by_data_bucket',
          order: 2,
        },
      }
      let volumeWarningTolerance = Math.abs(parseFloat(getProp(reconciliation, 'volume_tolerance_warning', 0)))
      let volumeErrorTolerance = Math.abs(parseFloat(getProp(reconciliation, 'volume_tolerance_error', 0)))
      let meterWarningTolerance = Math.abs(parseFloat(getProp(reconciliation, 'meter_tolerance_warning', 0)))
      let meterErrorTolerance = Math.abs(parseFloat(getProp(reconciliation, 'meter_tolerance_error', 0)))
      let timestampWarningTolerance = Math.abs(parseFloat(getProp(reconciliation, 'timestamp_tolerance_warning', 0)) * (1000 * 60))
      let timestampErrorTolerance = Math.abs(parseFloat(getProp(reconciliation, 'timestamp_tolerance_error', 0)) * (1000 * 60))
      let carrierTicketsMissingUniqueIdentifier: TsInterface_UnspecifiedObject = {}
      let linkedData: TsInterface_UnspecifiedObject = {}
      let multipleCarrierTicketsForStationLog: TsInterface_UnspecifiedObject = {}
      let multipleCarrierTicketsWithSameUniqueIdentifier: TsInterface_UnspecifiedObject = {}
      let multipleStationLogsForCarrierTicket: TsInterface_UnspecifiedObject = {}
      let multipleStationLogsWithSameUniqueIdentifier: TsInterface_UnspecifiedObject = {}
      let stationLogsMissingUniqueIdentifier: TsInterface_UnspecifiedObject = {}
      let unlinkedCarrierTicketData: TsInterface_UnspecifiedObject = {}
      let unlinkedStationLogData: TsInterface_UnspecifiedObject = {}
      // let linkedDataSeparatedByDataBucket: TsInterface_UnspecifiedObject = {}
      let stationLogsSeparatedByDataBucket: TsInterface_UnspecifiedObject = {}
      let mappedGroupedData: TsInterface_UnspecifiedObject = {}
      // Data Bucket Mapping
      let dataBucketNames: TsInterface_UnspecifiedObject = {}
      for (let loopDataBucketKey in dataBuckets) {
        dataBucketNames[loopDataBucketKey] = getProp(dataBuckets[loopDataBucketKey], 'name', loopDataBucketKey)
      }

      // // TODO: TEMP
      // dataBucketNames = {
      //   // TEMP
      //   CT: 'Carrier Tickets',
      //   SCA: 'Clearfield Alpha',
      //   SEA: 'Eastport Alpha',
      //   SEB: 'Eastport Bravo',
      // }
      // // TODO: TEMP

      // Split Data into Station Logs and Carrier Tickets
      for (let loopDataItemKey in dataItems) {
        let loopDataItem = dataItems[loopDataItemKey]
        if (
          loopDataItem != null &&
          loopDataItem['associated_class_key'] != null &&
          loopDataItem['data'] != null &&
          reconciliation != null &&
          reconciliation['associated_reconciliation_class_group_selections'] != null &&
          reconciliation['associated_reconciliation_class_group_selections'][loopDataItem['associated_class_key']] != null &&
          reconciliation['associated_reconciliation_class_mapping'] != null &&
          reconciliation['associated_reconciliation_class_mapping'][loopDataItem['associated_class_key']] != null
        ) {
          let groupKey = reconciliation['associated_reconciliation_class_group_selections'][loopDataItem['associated_class_key']]
          let cleanedDataItem: TsInterface_UnspecifiedObject = {
            associated_data_bucket_key: getProp(loopDataItem, 'associated_data_bucket_key', null),
            associated_data_bucket_name: getProp(loopDataItem, 'associated_data_bucket_name', null),
            // timestamp_primary_query: getProp(loopDataItem, 'timestamp_primary_query', new Date()).getTime(),
          }
          if (loopDataItem != null && loopDataItem['timestamp_primary_query'] != null) {
            cleanedDataItem['timestamp_primary_query'] = returnTimestampFromUnknownDateFormat(loopDataItem['timestamp_primary_query'])
          } else {
            cleanedDataItem['timestamp_primary_query'] = new Date().getTime()
          }
          for (let loopPropKey in reconciliation['associated_reconciliation_class_mapping'][loopDataItem['associated_class_key']]) {
            let loopPropDataMappingKey = reconciliation['associated_reconciliation_class_mapping'][loopDataItem['associated_class_key']][loopPropKey]
            if (loopDataItem['data'][loopPropDataMappingKey] != null) {
              cleanedDataItem[loopPropKey] = loopDataItem['data'][loopPropDataMappingKey]
            } else {
              cleanedDataItem[loopPropKey] = null
            }
          }
          if (mappedGroupedData[groupKey] == null) {
            mappedGroupedData[groupKey] = {}
          }
          mappedGroupedData[groupKey][loopDataItemKey] = cleanedDataItem
        }
      }
      // Totals
      totals['total_carrier_tickets'] = objectToArray(mappedGroupedData['carrier_ticket']).length
      totals['total_station_logs'] = objectToArray(mappedGroupedData['station_log']).length
      // Loop through station logs
      for (let stationLogKey in mappedGroupedData['station_log']) {
        let stationLog = mappedGroupedData['station_log'][stationLogKey]
        for (let loopInnerStationLogKey in mappedGroupedData['station_log']) {
          let loopInnerStationLog = mappedGroupedData['station_log'][loopInnerStationLogKey]
          if (stationLogKey !== loopInnerStationLogKey && stationLog['unique_identifier'] === loopInnerStationLog['unique_identifier']) {
            if (multipleStationLogsWithSameUniqueIdentifier[stationLog['unique_identifier']] == null) {
              multipleStationLogsWithSameUniqueIdentifier[stationLog['unique_identifier']] = {}
            }
            multipleStationLogsWithSameUniqueIdentifier[stationLog['unique_identifier']][stationLogKey] = stationLog
          }
        }
        if (stationLogsSeparatedByDataBucket[getProp(stationLog, 'associated_data_bucket_key', 'UNKNOWN')] == null) {
          stationLogsSeparatedByDataBucket[getProp(stationLog, 'associated_data_bucket_key', 'UNKNOWN')] = {}
        }
        stationLogsSeparatedByDataBucket[getProp(stationLog, 'associated_data_bucket_key', 'UNKNOWN')][stationLog['unique_identifier']] = stationLog
      }
      errors['station_logs_with_duplicate_unique_identifiers']['count'] = objectToArray(multipleStationLogsWithSameUniqueIdentifier).length
      errors['station_logs_with_duplicate_unique_identifiers']['station_logs'] = multipleStationLogsWithSameUniqueIdentifier
      // Loop through carrier tickets
      for (let carrierTicketKey in mappedGroupedData['carrier_ticket']) {
        let carrierTicket = mappedGroupedData['carrier_ticket'][carrierTicketKey]
        for (let loopInnerCarrierTicketKey in mappedGroupedData['carrier_ticket']) {
          let loopInnerCarrierTicket = mappedGroupedData['carrier_ticket'][loopInnerCarrierTicketKey]
          if (carrierTicketKey !== loopInnerCarrierTicketKey && carrierTicket['unique_identifier'] === loopInnerCarrierTicket['unique_identifier']) {
            if (multipleCarrierTicketsWithSameUniqueIdentifier[carrierTicket['unique_identifier']] == null) {
              multipleCarrierTicketsWithSameUniqueIdentifier[carrierTicket['unique_identifier']] = {}
            }
            multipleCarrierTicketsWithSameUniqueIdentifier[carrierTicket['unique_identifier']][carrierTicketKey] = carrierTicket
          }
        }
      }
      errors['carrier_tickets_with_duplicate_unique_identifiers']['count'] = objectToArray(multipleCarrierTicketsWithSameUniqueIdentifier).length
      errors['carrier_tickets_with_duplicate_unique_identifiers']['carrier_tickets'] = multipleCarrierTicketsWithSameUniqueIdentifier
      // Loop through station logs and check for multiple carrier tickets
      for (let stationLogKey in mappedGroupedData['station_log']) {
        let stationLog = mappedGroupedData['station_log'][stationLogKey]
        if (stationLog['volume'] != null && !isNaN(parseFloat(stationLog['volume']))) {
          totals['total_station_log_volume'] += parseFloat(stationLog['volume'])
          if (stationLog['associated_data_bucket_key'] == null) {
            stationLog['associated_data_bucket_key'] = 'UNKNOWN'
          }
          if (totals['station_log_data_bucket_breakdown'][stationLog['associated_data_bucket_key']] == null) {
            totals['station_log_data_bucket_breakdown'][stationLog['associated_data_bucket_key']] = {
              associated_data_bucket_key: stationLog['associated_data_bucket_key'],
              associated_data_bucket_name: getProp(dataBucketNames, stationLog['associated_data_bucket_key'], stationLog['associated_data_bucket_key']),
              total_station_logs: 0,
              total_volume: 0,
            }
          }
          totals['station_log_data_bucket_breakdown'][stationLog['associated_data_bucket_key']]['total_station_logs'] += 1
          totals['station_log_data_bucket_breakdown'][stationLog['associated_data_bucket_key']]['total_volume'] += parseFloat(stationLog['volume'])
        }
        let stationLogUniqueIdentifier = getProp(stationLog, 'unique_identifier', null)
        if (stationLogUniqueIdentifier != null) {
          // Loop through Carrier Tickets and check for matches
          let matchedData: TsInterface_UnspecifiedObject = {}
          for (let carrierTicketKey in mappedGroupedData['carrier_ticket']) {
            let carrierTicket = mappedGroupedData['carrier_ticket'][carrierTicketKey]
            if (carrierTicket['unique_identifier'] === stationLog['unique_identifier']) {
              matchedData[carrierTicketKey] = carrierTicket
            }
          }
          if (objectToArray(matchedData).length > 1) {
            multipleCarrierTicketsForStationLog[stationLogKey] = {
              station_log: stationLog,
              carrier_tickets: matchedData,
            }
          } else if (objectToArray(matchedData).length === 1) {
            linkedData[stationLogUniqueIdentifier] = {
              station_log: stationLog,
              carrier_ticket: objectToArray(matchedData)[0],
            }
          } else {
            unlinkedStationLogData[stationLogKey] = stationLog
          }
        } else {
          stationLogsMissingUniqueIdentifier[stationLogKey] = stationLog
        }
      }
      for (let loopStationLogKey in stationLogsMissingUniqueIdentifier) {
        let loopStationLog = stationLogsMissingUniqueIdentifier[loopStationLogKey]
        if (errors['station_logs_missing_unique_identifier']['data_buckets'][loopStationLog.associated_data_bucket_key] == null) {
          errors['station_logs_missing_unique_identifier']['data_buckets'][loopStationLog.associated_data_bucket_key] = {
            key: loopStationLog.associated_data_bucket_key,
            name: getProp(dataBucketNames, loopStationLog.associated_data_bucket_key, loopStationLog.associated_data_bucket_key),
            station_logs: {},
          }
        }
        errors['station_logs_missing_unique_identifier']['data_buckets'][loopStationLog.associated_data_bucket_key]['station_logs'][loopStationLogKey] =
          loopStationLog
        errors['station_logs_missing_unique_identifier']['count']++
      }
      for (let loopStationLogKey in unlinkedStationLogData) {
        let loopStationLog = unlinkedStationLogData[loopStationLogKey]
        if (errors['station_logs_with_no_matching_carrier_ticket']['data_buckets'][loopStationLog.associated_data_bucket_key] == null) {
          errors['station_logs_with_no_matching_carrier_ticket']['data_buckets'][loopStationLog.associated_data_bucket_key] = {
            key: loopStationLog.associated_data_bucket_key,
            name: getProp(dataBucketNames, loopStationLog.associated_data_bucket_key, loopStationLog.associated_data_bucket_key),
            station_logs: {},
          }
        }
        errors['station_logs_with_no_matching_carrier_ticket']['data_buckets'][loopStationLog.associated_data_bucket_key]['station_logs'][loopStationLogKey] =
          loopStationLog
        errors['station_logs_with_no_matching_carrier_ticket']['count']++
      }
      for (let loopStationLogKey in multipleCarrierTicketsForStationLog) {
        let loopCombinedStationLogCarrierTicketData = multipleCarrierTicketsForStationLog[loopStationLogKey]
        let dataBucketKey = getProp(loopCombinedStationLogCarrierTicketData['station_log'], 'associated_data_bucket_key', 'UNKNOWN')
        if (errors['station_logs_matched_with_multiple_carrier_tickets']['data_buckets'][dataBucketKey] == null) {
          errors['station_logs_matched_with_multiple_carrier_tickets']['data_buckets'][dataBucketKey] = {
            key: dataBucketKey,
            name: getProp(dataBucketNames, dataBucketKey, dataBucketKey),
            station_logs: {},
          }
        }
        errors['station_logs_matched_with_multiple_carrier_tickets']['data_buckets'][dataBucketKey]['station_logs'][loopStationLogKey] =
          loopCombinedStationLogCarrierTicketData
        errors['station_logs_matched_with_multiple_carrier_tickets']['count']++
      }
      // Loop through carrier tickets and check for multiple station logs
      for (let carrierTicketKey in mappedGroupedData['carrier_ticket']) {
        let carrierTicket = mappedGroupedData['carrier_ticket'][carrierTicketKey]
        if (carrierTicket['volume'] != null && !isNaN(parseFloat(carrierTicket['volume']))) {
          totals['total_carrier_ticket_volume'] += parseFloat(carrierTicket['volume'])
          if (carrierTicket['associated_data_bucket_key'] == null) {
            carrierTicket['associated_data_bucket_key'] = 'UNKNOWN'
          }
          if (totals['carrier_ticket_data_bucket_breakdown'][carrierTicket['associated_data_bucket_key']] == null) {
            totals['carrier_ticket_data_bucket_breakdown'][carrierTicket['associated_data_bucket_key']] = {
              associated_data_bucket_key: carrierTicket['associated_data_bucket_key'],
              associated_data_bucket_name: getProp(dataBucketNames, carrierTicket['associated_data_bucket_key'], carrierTicket['associated_data_bucket_key']),
              total_carrier_tickets: 0,
              total_volume: 0,
            }
          }
          totals['carrier_ticket_data_bucket_breakdown'][carrierTicket['associated_data_bucket_key']]['total_carrier_tickets'] += 1
          totals['carrier_ticket_data_bucket_breakdown'][carrierTicket['associated_data_bucket_key']]['total_volume'] += parseFloat(carrierTicket['volume'])
        }
        let carrierTicketUniqueIdentifier = getProp(carrierTicket, 'unique_identifier', null)
        if (carrierTicketUniqueIdentifier != null) {
          // Loop through Station Logs and check for matches
          let matchedData: TsInterface_UnspecifiedObject = {}
          for (let stationLogKey in mappedGroupedData['station_log']) {
            let stationLog = mappedGroupedData['station_log'][stationLogKey]
            if (stationLog['unique_identifier'] === carrierTicket['unique_identifier']) {
              matchedData[stationLogKey] = stationLog
            }
          }
          if (objectToArray(matchedData).length > 1) {
            multipleStationLogsForCarrierTicket[carrierTicketKey] = {
              carrier_ticket: carrierTicket,
              station_logs: matchedData,
            }
          } else if (objectToArray(matchedData).length === 1) {
            // Already handled in previous loop
          } else {
            unlinkedCarrierTicketData[carrierTicketKey] = carrierTicket
          }
        } else {
          carrierTicketsMissingUniqueIdentifier[carrierTicketKey] = carrierTicket
        }
      }
      for (let loopCarrierTicketKey in carrierTicketsMissingUniqueIdentifier) {
        let loopCarrierTicket = carrierTicketsMissingUniqueIdentifier[loopCarrierTicketKey]
        if (errors['carrier_tickets_missing_unique_identifier']['data_buckets'][loopCarrierTicket.associated_data_bucket_key] == null) {
          errors['carrier_tickets_missing_unique_identifier']['data_buckets'][loopCarrierTicket.associated_data_bucket_key] = {
            key: loopCarrierTicket.associated_data_bucket_key,
            name: getProp(dataBucketNames, loopCarrierTicket.associated_data_bucket_key, loopCarrierTicket.associated_data_bucket_key),
            carrier_tickets: {},
          }
        }
        errors['carrier_tickets_missing_unique_identifier']['data_buckets'][loopCarrierTicket.associated_data_bucket_key]['carrier_tickets'][
          loopCarrierTicketKey
        ] = loopCarrierTicket
        errors['carrier_tickets_missing_unique_identifier']['count']++
      }
      for (let loopCarrierTicketKey in unlinkedCarrierTicketData) {
        let loopCarrierTicket = unlinkedCarrierTicketData[loopCarrierTicketKey]
        if (errors['carrier_tickets_with_no_matching_station_log']['data_buckets'][loopCarrierTicket.associated_data_bucket_key] == null) {
          errors['carrier_tickets_with_no_matching_station_log']['data_buckets'][loopCarrierTicket.associated_data_bucket_key] = {
            key: loopCarrierTicket.associated_data_bucket_key,
            name: getProp(dataBucketNames, loopCarrierTicket.associated_data_bucket_key, loopCarrierTicket.associated_data_bucket_key),
            carrier_tickets: {},
          }
        }
        errors['carrier_tickets_with_no_matching_station_log']['data_buckets'][loopCarrierTicket.associated_data_bucket_key]['carrier_tickets'][
          loopCarrierTicketKey
        ] = loopCarrierTicket
        errors['carrier_tickets_with_no_matching_station_log']['count']++
      }
      for (let loopCarrierTicketKey in multipleStationLogsForCarrierTicket) {
        let loopCombinedCarrierTicketCarrierTicketData = multipleStationLogsForCarrierTicket[loopCarrierTicketKey]
        let dataBucketKey = getProp(loopCombinedCarrierTicketCarrierTicketData['carrier_ticket'], 'associated_data_bucket_key', 'UNKNOWN')
        if (errors['carrier_tickets_matched_with_multiple_station_logs']['data_buckets'][dataBucketKey] == null) {
          errors['carrier_tickets_matched_with_multiple_station_logs']['data_buckets'][dataBucketKey] = {
            key: dataBucketKey,
            name: getProp(dataBucketNames, dataBucketKey, dataBucketKey),
            carrier_tickets: {},
          }
        }
        errors['carrier_tickets_matched_with_multiple_station_logs']['data_buckets'][dataBucketKey]['carrier_tickets'][loopCarrierTicketKey] =
          loopCombinedCarrierTicketCarrierTicketData
        errors['carrier_tickets_matched_with_multiple_station_logs']['count']++
      }
      // Errors for Missing Fields
      for (let stationLogKey in mappedGroupedData['station_log']) {
        let stationLog = mappedGroupedData['station_log'][stationLogKey]
        if (stationLog['volume'] == null || isNaN(parseFloat(stationLog['volume'])) === true) {
          if (errors['station_logs_missing_volume']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
            errors['station_logs_missing_volume']['data_buckets'][stationLog.associated_data_bucket_key] = {
              key: stationLog.associated_data_bucket_key,
              name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
              station_logs: {},
            }
          }
          errors['station_logs_missing_volume']['data_buckets'][stationLog.associated_data_bucket_key]['station_logs'][stationLogKey] = stationLog
          errors['station_logs_missing_volume']['count']++
        }
        if (reconciliationProcedure === 'crude_oil_metered') {
          if (stationLog['start_meter'] == null || isNaN(parseFloat(stationLog['start_meter'])) === true) {
            if (errors['station_logs_missing_start_meter']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
              errors['station_logs_missing_start_meter']['data_buckets'][stationLog.associated_data_bucket_key] = {
                key: stationLog.associated_data_bucket_key,
                name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
                station_logs: {},
              }
            }
            errors['station_logs_missing_start_meter']['data_buckets'][stationLog.associated_data_bucket_key]['station_logs'][stationLogKey] = stationLog
            errors['station_logs_missing_start_meter']['count']++
          }
          if (stationLog['stop_meter'] == null || isNaN(parseFloat(stationLog['stop_meter'])) === true) {
            if (errors['station_logs_missing_stop_meter']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
              errors['station_logs_missing_stop_meter']['data_buckets'][stationLog.associated_data_bucket_key] = {
                key: stationLog.associated_data_bucket_key,
                name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
                station_logs: {},
              }
            }
            errors['station_logs_missing_stop_meter']['data_buckets'][stationLog.associated_data_bucket_key]['station_logs'][stationLogKey] = stationLog
            errors['station_logs_missing_stop_meter']['count']++
          }
        }
      }
      for (let carrierTicketKey in mappedGroupedData['carrier_ticket']) {
        let carrierTicket = mappedGroupedData['carrier_ticket'][carrierTicketKey]
        if (carrierTicket['volume'] == null || isNaN(parseFloat(carrierTicket['volume'])) === true) {
          if (errors['carrier_tickets_missing_volume']['data_buckets'][carrierTicket.associated_data_bucket_key] == null) {
            errors['carrier_tickets_missing_volume']['data_buckets'][carrierTicket.associated_data_bucket_key] = {
              key: carrierTicket.associated_data_bucket_key,
              name: getProp(dataBucketNames, carrierTicket.associated_data_bucket_key, carrierTicket.associated_data_bucket_key),
              carrier_tickets: {},
            }
          }
          errors['carrier_tickets_missing_volume']['data_buckets'][carrierTicket.associated_data_bucket_key]['carrier_tickets'][carrierTicketKey] =
            carrierTicket
          errors['carrier_tickets_missing_volume']['count']++
        }
        if (reconciliationProcedure === 'crude_oil_metered') {
          if (carrierTicket['start_meter'] == null || isNaN(parseFloat(carrierTicket['start_meter'])) === true) {
            if (errors['carrier_tickets_missing_start_meter']['data_buckets'][carrierTicket.associated_data_bucket_key] == null) {
              errors['carrier_tickets_missing_start_meter']['data_buckets'][carrierTicket.associated_data_bucket_key] = {
                key: carrierTicket.associated_data_bucket_key,
                name: getProp(dataBucketNames, carrierTicket.associated_data_bucket_key, carrierTicket.associated_data_bucket_key),
                carrier_tickets: {},
              }
            }
            errors['carrier_tickets_missing_start_meter']['data_buckets'][carrierTicket.associated_data_bucket_key]['carrier_tickets'][carrierTicketKey] =
              carrierTicket
            errors['carrier_tickets_missing_start_meter']['count']++
          }
          if (carrierTicket['stop_meter'] == null || isNaN(parseFloat(carrierTicket['stop_meter'])) === true) {
            if (errors['carrier_tickets_missing_stop_meter']['data_buckets'][carrierTicket.associated_data_bucket_key] == null) {
              errors['carrier_tickets_missing_stop_meter']['data_buckets'][carrierTicket.associated_data_bucket_key] = {
                key: carrierTicket.associated_data_bucket_key,
                name: getProp(dataBucketNames, carrierTicket.associated_data_bucket_key, carrierTicket.associated_data_bucket_key),
                carrier_tickets: {},
              }
            }
            errors['carrier_tickets_missing_stop_meter']['data_buckets'][carrierTicket.associated_data_bucket_key]['carrier_tickets'][carrierTicketKey] =
              carrierTicket
            errors['carrier_tickets_missing_stop_meter']['count']++
          }
        }
      }
      // Errors and Warnings for Measurements Out of Tolerance
      for (let loopUniqueIdentifier in linkedData) {
        let loopLinkedData = linkedData[loopUniqueIdentifier]
        let stationLog = loopLinkedData['station_log']
        let carrierTicket = loopLinkedData['carrier_ticket']
        // Separate By Data Bucket
        // if( linkedDataSeparatedByDataBucket[ getProp( stationLog, "associated_data_bucket_key", "UNKNOWN" ) ] == null ){
        // 	linkedDataSeparatedByDataBucket[ getProp( stationLog, "associated_data_bucket_key", "UNKNOWN" ) ] = {}
        // }
        // linkedDataSeparatedByDataBucket[ getProp( stationLog, "associated_data_bucket_key", "UNKNOWN" ) ][ loopUniqueIdentifier ] = loopLinkedData
        // Volume Totals
        if (carrierTicket['volume'] != null && !isNaN(parseFloat(carrierTicket['volume']))) {
          totals['total_linked_carrier_ticket_volume'] += parseFloat(carrierTicket['volume'])
        }
        if (stationLog['volume'] != null && !isNaN(parseFloat(stationLog['volume']))) {
          totals['total_linked_station_log_volume'] += parseFloat(stationLog['volume'])
        }
        // Volume Discrepancies
        let volumeDiscrepancy = Math.abs(parseFloat(stationLog['volume']) - parseFloat(carrierTicket['volume']))

        if (volumeDiscrepancy > volumeErrorTolerance) {
          errors['volume_discrepancies']['count']++
          if (errors['volume_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
            errors['volume_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
              key: stationLog.associated_data_bucket_key,
              name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
              combined_station_logs_and_carrier_tickets: {},
            }
          }
          errors['volume_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
            loopUniqueIdentifier
          ] = {
            unique_identifier: loopUniqueIdentifier,
            carrier_ticket: carrierTicket,
            station_log: stationLog,
            volume_discrepancy: parseFloat((parseFloat(stationLog['volume']) - parseFloat(carrierTicket['volume'])).toFixed(2)),
          }
        } else if (volumeDiscrepancy > volumeWarningTolerance) {
          warnings['volume_discrepancies']['count']++
          if (warnings['volume_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
            warnings['volume_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
              key: stationLog.associated_data_bucket_key,
              name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
              combined_station_logs_and_carrier_tickets: {},
            }
          }
          warnings['volume_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
            loopUniqueIdentifier
          ] = {
            unique_identifier: loopUniqueIdentifier,
            carrier_ticket: carrierTicket,
            station_log: stationLog,
            volume_discrepancy: parseFloat((parseFloat(stationLog['volume']) - parseFloat(carrierTicket['volume'])).toFixed(2)),
          }
        }
        // Timestamp Discrepancies
        let timestampDiscrepancy = Math.abs(parseFloat(stationLog['timestamp_primary_query']) - parseFloat(carrierTicket['timestamp_primary_query']))
        if (timestampDiscrepancy > timestampErrorTolerance) {
          errors['timestamp_discrepancies']['count']++
          if (errors['timestamp_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
            errors['timestamp_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
              key: stationLog.associated_data_bucket_key,
              name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
              combined_station_logs_and_carrier_tickets: {},
            }
          }
          errors['timestamp_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
            loopUniqueIdentifier
          ] = {
            unique_identifier: loopUniqueIdentifier,
            carrier_ticket: carrierTicket,
            station_log: stationLog,
            timestamp_discrepancy: parseFloat(
              ((parseFloat(stationLog['timestamp_primary_query']) - parseFloat(carrierTicket['timestamp_primary_query'])) / (1000 * 60)).toFixed(2),
            ),
          }
        } else if (timestampDiscrepancy > timestampWarningTolerance) {
          warnings['timestamp_discrepancies']['count']++
          if (warnings['timestamp_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
            warnings['timestamp_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
              key: stationLog.associated_data_bucket_key,
              name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
              combined_station_logs_and_carrier_tickets: {},
            }
          }
          warnings['timestamp_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
            loopUniqueIdentifier
          ] = {
            unique_identifier: loopUniqueIdentifier,
            carrier_ticket: carrierTicket,
            station_log: stationLog,
            timestamp_discrepancy: parseFloat(
              ((parseFloat(stationLog['timestamp_primary_query']) - parseFloat(carrierTicket['timestamp_primary_query'])) / (1000 * 60)).toFixed(2),
            ),
          }
        }
        // Meter Discrepancies
        if (reconciliationProcedure === 'crude_oil_metered') {
          let startMeterDiscrepancy = Math.abs(parseFloat(stationLog['start_meter']) - parseFloat(carrierTicket['start_meter']))
          if (startMeterDiscrepancy > meterErrorTolerance) {
            errors['start_meter_discrepancies']['count']++
            if (errors['start_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
              errors['start_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
                key: stationLog.associated_data_bucket_key,
                name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
                combined_station_logs_and_carrier_tickets: {},
              }
            }
            errors['start_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
              loopUniqueIdentifier
            ] = {
              unique_identifier: loopUniqueIdentifier,
              carrier_ticket: carrierTicket,
              station_log: stationLog,
              start_meter_discrepancy: parseFloat(startMeterDiscrepancy.toFixed(2)),
            }
          } else if (startMeterDiscrepancy > meterWarningTolerance) {
            warnings['start_meter_discrepancies']['count']++
            if (warnings['start_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
              warnings['start_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
                key: stationLog.associated_data_bucket_key,
                name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
                combined_station_logs_and_carrier_tickets: {},
              }
            }
            warnings['start_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
              loopUniqueIdentifier
            ] = {
              unique_identifier: loopUniqueIdentifier,
              carrier_ticket: carrierTicket,
              station_log: stationLog,
              start_meter_discrepancy: parseFloat(startMeterDiscrepancy.toFixed(2)),
            }
          }
          let stopMeterDiscrepancy = Math.abs(parseFloat(stationLog['stop_meter']) - parseFloat(carrierTicket['stop_meter']))
          if (stopMeterDiscrepancy > meterErrorTolerance) {
            errors['stop_meter_discrepancies']['count']++
            if (errors['stop_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
              errors['stop_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
                key: stationLog.associated_data_bucket_key,
                name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
                combined_station_logs_and_carrier_tickets: {},
              }
            }
            errors['stop_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
              loopUniqueIdentifier
            ] = {
              unique_identifier: loopUniqueIdentifier,
              carrier_ticket: carrierTicket,
              station_log: stationLog,
              stop_meter_discrepancy: parseFloat(stopMeterDiscrepancy.toFixed(2)),
            }
          } else if (stopMeterDiscrepancy > meterWarningTolerance) {
            warnings['stop_meter_discrepancies']['count']++
            if (warnings['stop_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] == null) {
              warnings['stop_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key] = {
                key: stationLog.associated_data_bucket_key,
                name: getProp(dataBucketNames, stationLog.associated_data_bucket_key, stationLog.associated_data_bucket_key),
                combined_station_logs_and_carrier_tickets: {},
              }
            }
            warnings['stop_meter_discrepancies']['data_buckets'][stationLog.associated_data_bucket_key]['combined_station_logs_and_carrier_tickets'][
              loopUniqueIdentifier
            ] = {
              unique_identifier: loopUniqueIdentifier,
              carrier_ticket: carrierTicket,
              station_log: stationLog,
              stop_meter_discrepancy: parseFloat(stopMeterDiscrepancy.toFixed(2)),
            }
          }
        }
      }
      // Meter Gap Discrepancies
      for (let loopDataBucketKey in stationLogsSeparatedByDataBucket) {
        let loopDataBucketStationLogs = stationLogsSeparatedByDataBucket[loopDataBucketKey]
        // Sort by start meter
        let sortedStationLogsArray = objectToArray(loopDataBucketStationLogs).sort(dynamicSort('start_meter', 'asc'))
        // Loop through sorted station logs and check for gaps or overlaps
        for (let loopIndex = 0; loopIndex < sortedStationLogsArray.length; loopIndex++) {
          let loopStationLog = sortedStationLogsArray[loopIndex]
          if (loopIndex > 0) {
            // Skip the first station log
            let previousStationLog = sortedStationLogsArray[loopIndex - 1]
            let gap = parseFloat(loopStationLog['start_meter']) - parseFloat(previousStationLog['stop_meter'])
            let absGap = Math.abs(gap)
            if (gap > 0) {
              // Gaps
              if (absGap > meterErrorTolerance) {
                // Error
                errors['gaps_in_station_log_meter_readings_by_data_bucket']['count']++
                if (errors['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] == null) {
                  errors['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] = {
                    key: loopDataBucketKey,
                    name: getProp(dataBucketNames, loopDataBucketKey, loopDataBucketKey),
                    station_logs: {},
                  }
                }
                errors['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey]['station_logs'][
                  loopStationLog['unique_identifier']
                ] = {
                  stop_meter_station_log: previousStationLog,
                  start_meter_station_log: loopStationLog,
                  first_station_log_stop_meter: parseFloat(previousStationLog['stop_meter']),
                  second_station_log_start_meter: parseFloat(loopStationLog['start_meter']),
                  gap: gap,
                }
              } else if (absGap > meterWarningTolerance) {
                // Warning
                warnings['gaps_in_station_log_meter_readings_by_data_bucket']['count']++
                if (warnings['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] == null) {
                  warnings['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] = {
                    key: loopDataBucketKey,
                    name: getProp(dataBucketNames, loopDataBucketKey, loopDataBucketKey),
                    station_logs: {},
                  }
                }
                warnings['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey]['station_logs'][
                  loopStationLog['unique_identifier']
                ] = {
                  stop_meter_station_log: previousStationLog,
                  start_meter_station_log: loopStationLog,
                  first_station_log_stop_meter: parseFloat(previousStationLog['stop_meter']),
                  second_station_log_start_meter: parseFloat(loopStationLog['start_meter']),
                  gap: gap,
                }
              } else {
                // Info
                info['gaps_in_station_log_meter_readings_by_data_bucket']['count']++
                if (info['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] == null) {
                  info['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] = {
                    key: loopDataBucketKey,
                    name: getProp(dataBucketNames, loopDataBucketKey, loopDataBucketKey),
                    station_logs: {},
                  }
                }
                info['gaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey]['station_logs'][
                  loopStationLog['unique_identifier']
                ] = {
                  stop_meter_station_log: previousStationLog,
                  start_meter_station_log: loopStationLog,
                  first_station_log_stop_meter: parseFloat(previousStationLog['stop_meter']),
                  second_station_log_start_meter: parseFloat(loopStationLog['start_meter']),
                  gap: gap,
                }
              }
            } else if (gap < 0) {
              // Overlaps
              if (absGap > meterErrorTolerance) {
                // Error
                errors['overlaps_in_station_log_meter_readings_by_data_bucket']['count']++
                if (errors['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] == null) {
                  errors['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] = {
                    key: loopDataBucketKey,
                    name: getProp(dataBucketNames, loopDataBucketKey, loopDataBucketKey),
                    station_logs: {},
                  }
                }
                errors['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey]['station_logs'][
                  loopStationLog['unique_identifier']
                ] = {
                  stop_meter_station_log: previousStationLog,
                  start_meter_station_log: loopStationLog,
                  first_station_log_stop_meter: parseFloat(previousStationLog['stop_meter']),
                  second_station_log_start_meter: parseFloat(loopStationLog['start_meter']),
                  gap: gap,
                }
              } else if (absGap > meterWarningTolerance) {
                // Warning
                warnings['overlaps_in_station_log_meter_readings_by_data_bucket']['count']++
                if (warnings['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] == null) {
                  warnings['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] = {
                    key: loopDataBucketKey,
                    name: getProp(dataBucketNames, loopDataBucketKey, loopDataBucketKey),
                    station_logs: {},
                  }
                }
                warnings['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey]['station_logs'][
                  loopStationLog['unique_identifier']
                ] = {
                  stop_meter_station_log: previousStationLog,
                  start_meter_station_log: loopStationLog,
                  first_station_log_stop_meter: parseFloat(previousStationLog['stop_meter']),
                  second_station_log_start_meter: parseFloat(loopStationLog['start_meter']),
                  gap: gap,
                }
              } else {
                // Info
                info['overlaps_in_station_log_meter_readings_by_data_bucket']['count']++
                if (info['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] == null) {
                  info['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey] = {
                    key: loopDataBucketKey,
                    name: getProp(dataBucketNames, loopDataBucketKey, loopDataBucketKey),
                    station_logs: {},
                  }
                }
                info['overlaps_in_station_log_meter_readings_by_data_bucket']['data_buckets'][loopDataBucketKey]['station_logs'][
                  loopStationLog['unique_identifier']
                ] = {
                  stop_meter_station_log: previousStationLog,
                  start_meter_station_log: loopStationLog,
                  first_station_log_stop_meter: parseFloat(previousStationLog['stop_meter']),
                  second_station_log_start_meter: parseFloat(loopStationLog['start_meter']),
                  gap: gap,
                }
              }
            }
          }
        }
      }
      // Email Formatted Data
      let emailFormattedData: TsInterface_UnspecifiedObject = {
        totals: {
          // total_carrier_ticket_volume: getProp(totals, 'total_carrier_ticket_volume', 0),
          // total_carrier_tickets: getProp(totals, 'total_carrier_tickets', 0),
          // total_linked_carrier_ticket_volume: getProp(totals, 'total_linked_carrier_ticket_volume', 0),
          // total_linked_station_log_volume: getProp(totals, 'total_linked_station_log_volume', 0),
          total_station_log_volume: getProp(totals, 'total_station_log_volume', 0).toFixed(0),
          total_station_logs: getProp(totals, 'total_station_logs', 0),
          total_errors: 0,
          total_warnings: 0,
        },
        DATA_station_log_data_bucket_breakdown: getProp(totals, 'station_log_data_bucket_breakdown', {}),
      }
      for (let loopErrorKey in errors) {
        // Instantiate Error, Increment counts, placeholder for flat logs
        let loopError = errors[loopErrorKey]
        emailFormattedData['totals']['total_errors'] += getProp(loopError, 'count', 0)
        emailFormattedData['totals']['ERROR_' + loopErrorKey] = getProp(loopError, 'count', 0)
        emailFormattedData['ERRORS_' + loopErrorKey] = {}
        // If the error has data to show
        if (
          loopError != null &&
          ((loopError.data_buckets != null && objectToArray(loopError.data_buckets).length > 0) ||
            (loopError.station_logs != null && objectToArray(loopError.station_logs).length > 0) ||
            (loopError.carrier_tickets != null && objectToArray(loopError.carrier_tickets).length > 0))
        ) {
          switch (loopError.display_table_type) {
            case 'two_station_logs':
              for (let loopDataBucketKey in loopError.data_buckets) {
                let loopDataBucket = loopError.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.station_logs != null) {
                  for (let loopStationLogKey in loopDataBucket.station_logs) {
                    let loopStationLog = loopDataBucket.station_logs[loopStationLogKey]
                    if (loopStationLog != null) {
                      emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        first_station_log_id: getProp(getProp(loopStationLog, 'start_meter_station_log', {}), 'unique_identifier', null),
                        first_station_log_stop_meter: getProp(loopStationLog, 'first_station_log_stop_meter', null),
                        gap: getProp(loopStationLog, 'gap', null).toFixed(1),
                        second_station_log_id: getProp(getProp(loopStationLog, 'stop_meter_station_log', {}), 'unique_identifier', null),
                        second_station_log_start_meter: getProp(loopStationLog, 'second_station_log_start_meter', null),
                        timestamp: getProp(loopStationLog, 'timestamp_primary_query', null),
                      }
                    }
                  }
                }
              }
              break
            case 'station_log_and_carrier_ticket':
              for (let loopDataBucketKey in loopError.data_buckets) {
                let loopDataBucket = loopError.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.combined_station_logs_and_carrier_tickets != null) {
                  for (let loopCombinedStationLogCarrierTicketKey in loopDataBucket.combined_station_logs_and_carrier_tickets) {
                    let loopCombinedStationLogCarrierTicket = loopDataBucket.combined_station_logs_and_carrier_tickets[loopCombinedStationLogCarrierTicketKey]
                    if (loopCombinedStationLogCarrierTicket != null) {
                      emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        carrier_ticket_start_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'start_meter', null),
                        carrier_ticket_stop_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'stop_meter', null),
                        carrier_ticket_timestamp: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'timestamp_primary_query', null),
                        carrier_ticket_volume: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'volume', null),
                        start_meter_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'start_meter_discrepancy', null),
                        station_log_start_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'start_meter', null),
                        station_log_stop_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'stop_meter', null),
                        station_log_timestamp: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'timestamp_primary_query', null),
                        station_log_volume: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'volume', null),
                        stop_meter_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'stop_meter_discrepancy', null),
                        timestamp_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'timestamp_discrepancy', null),
                        unique_identifier: getProp(loopCombinedStationLogCarrierTicket, 'unique_identifier', null),
                        volume_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'volume_discrepancy', null),
                      }
                      if (emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['carrier_ticket_timestamp'] != null) {
                        emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['carrier_ticket_timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['carrier_ticket_timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      if (emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['station_log_timestamp'] != null) {
                        emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['station_log_timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['station_log_timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      if (emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['timestamp_discrepancy'] != null) {
                        emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['timestamp_discrepancy'] = formatTime(
                          Math.abs(emailFormattedData['ERRORS_' + loopErrorKey][loopCombinedStationLogCarrierTicketKey]['timestamp_discrepancy']),
                        )
                      }
                    }
                  }
                }
              }
              break
            case 'station_log_multiple_carrier_tickets':
              for (let loopDataBucketKey in loopError.data_buckets) {
                let loopDataBucket = loopError.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.station_logs != null) {
                  for (let loopStationLogKey in loopDataBucket.station_logs) {
                    let loopStationLog = loopDataBucket.station_logs[loopStationLogKey]
                    if (loopStationLog != null && loopStationLog.carrier_tickets != null && loopStationLog.station_log != null) {
                      emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        start_meter: getProp(loopStationLog.station_log, 'start_meter', null),
                        stop_meter: getProp(loopStationLog.station_log, 'stop_meter', null),
                        timestamp: getProp(loopStationLog.station_log, 'timestamp_primary_query', null),
                        type: 'Station Log',
                        unique_identifier: getProp(loopStationLog.station_log, 'unique_identifier', null),
                        volume: getProp(loopStationLog.station_log, 'volume', null),
                      }
                      if (emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] != null) {
                        emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      for (let loopCarrierTicketKey in loopStationLog.carrier_tickets) {
                        let loopCarrierTicket = loopStationLog.carrier_tickets[loopCarrierTicketKey]
                        emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey] = {
                          associated_station_name: getProp(loopDataBucket, 'name', null),
                          stop_meter: getProp(loopCarrierTicket, 'stop_meter', null),
                          timestamp: getProp(loopCarrierTicket, 'timestamp_primary_query', null),
                          type: 'Carrier Ticket',
                          unique_identifier: getProp(loopCarrierTicket, 'unique_identifier', null),
                          volume: getProp(loopCarrierTicket, 'volume', null),
                        }
                        if (emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] != null) {
                          emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                            new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp']),
                            'D MMM YYYY HH:mm',
                          )
                        }
                      }
                    }
                  }
                }
              }
              break
            case 'carrier_ticket_multiple_station_logs':
              for (let loopDataBucketKey in loopError.data_buckets) {
                let loopDataBucket = loopError.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.carrier_tickets != null) {
                  for (let loopCarrierTicketKey in loopDataBucket.carrier_tickets) {
                    let loopCarrierTicket = loopDataBucket.carrier_tickets[loopCarrierTicketKey]
                    if (loopCarrierTicket != null && loopCarrierTicket.station_logs != null && loopCarrierTicket.carrier_ticket != null) {
                      emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        start_meter: getProp(loopCarrierTicket.carrier_ticket, 'start_meter', null),
                        stop_meter: getProp(loopCarrierTicket.carrier_ticket, 'stop_meter', null),
                        timestamp: getProp(loopCarrierTicket.carrier_ticket, 'timestamp_primary_query', null),
                        type: 'Carrier Ticket',
                        unique_identifier: getProp(loopCarrierTicket.carrier_ticket, 'unique_identifier', null),
                        volume: getProp(loopCarrierTicket.carrier_ticket, 'volume', null),
                      }
                      if (emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] != null) {
                        emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      for (let loopStationLogKey in loopCarrierTicket.station_logs) {
                        let loopStationLogTicket = loopCarrierTicket.station_logs[loopStationLogKey]
                        emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey] = {
                          associated_station_name: getProp(loopDataBucket, 'name', null),
                          stop_meter: getProp(loopStationLogTicket, 'stop_meter', null),
                          timestamp: getProp(loopStationLogTicket, 'timestamp_primary_query', null),
                          type: 'Station Log',
                          unique_identifier: getProp(loopStationLogTicket, 'unique_identifier', null),
                          volume: getProp(loopStationLogTicket, 'volume', null),
                        }
                        if (emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] != null) {
                          emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                            new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp']),
                            'D MMM YYYY HH:mm',
                          )
                        }
                      }
                    }
                  }
                }
              }
              break
            case 'station_log':
              for (let loopDataBucketKey in loopError.data_buckets) {
                let loopDataBucket = loopError.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.station_logs != null) {
                  for (let loopStationLogKey in loopDataBucket.station_logs) {
                    let loopStationLog = loopDataBucket.station_logs[loopStationLogKey]
                    emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey] = {
                      associated_station_name: getProp(loopDataBucket, 'name', null),
                      start_meter: getProp(loopStationLog, 'start_meter', null),
                      stop_meter: getProp(loopStationLog, 'stop_meter', null),
                      timestamp: getProp(loopStationLog, 'timestamp_primary_query', null),
                      unique_identifier: getProp(loopStationLog, 'unique_identifier', null),
                      volume: getProp(loopStationLog, 'volume', null),
                    }
                    if (emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] != null) {
                      emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                        new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp']),
                        'D MMM YYYY HH:mm',
                      )
                    }
                  }
                }
              }
              break
            case 'station_log_direct':
              for (let uniqueIdentifier in loopError.station_logs) {
                for (let loopStationLogKey in loopError.station_logs[uniqueIdentifier]) {
                  let loopStationLog = loopError.station_logs[uniqueIdentifier][loopStationLogKey]
                  emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey] = {
                    associated_station_name: getProp(loopStationLog, 'associated_data_bucket_name', null),
                    start_meter: getProp(loopStationLog, 'start_meter', null),
                    stop_meter: getProp(loopStationLog, 'stop_meter', null),
                    timestamp: getProp(loopStationLog, 'timestamp_primary_query', null),
                    unique_identifier: getProp(loopStationLog, 'unique_identifier', null),
                    volume: getProp(loopStationLog, 'volume', null),
                  }
                  if (emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] != null) {
                    emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                      new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopStationLogKey]['timestamp']),
                      'D MMM YYYY HH:mm',
                    )
                  }
                }
              }

              break
            case 'carrier_ticket':
              for (let loopDataBucketKey in loopError.data_buckets) {
                let loopDataBucket = loopError.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.carrier_tickets != null) {
                  for (let loopCarrierTicketKey in loopDataBucket.carrier_tickets) {
                    let loopCarrierTicket = loopDataBucket.carrier_tickets[loopCarrierTicketKey]
                    emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey] = {
                      associated_station_name: getProp(loopDataBucket, 'name', null),
                      start_meter: getProp(loopCarrierTicket, 'start_meter', null),
                      stop_meter: getProp(loopCarrierTicket, 'stop_meter', null),
                      timestamp: getProp(loopCarrierTicket, 'timestamp_primary_query', null),
                      unique_identifier: getProp(loopCarrierTicket, 'unique_identifier', null),
                      volume: getProp(loopCarrierTicket, 'volume', null),
                    }
                    if (emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] != null) {
                      emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                        new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp']),
                        'D MMM YYYY HH:mm',
                      )
                    }
                  }
                }
              }
              break
            case 'carrier_ticket_direct':
              for (let uniqueIdentifier in loopError.carrier_tickets) {
                for (let loopCarrierTicketKey in loopError.carrier_tickets[uniqueIdentifier]) {
                  let loopCarrierTicket = loopError.carrier_tickets[uniqueIdentifier][loopCarrierTicketKey]
                  emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey] = {
                    associated_station_name: getProp(loopCarrierTicket, 'associated_data_bucket_name', null),
                    start_meter: getProp(loopCarrierTicket, 'start_meter', null),
                    stop_meter: getProp(loopCarrierTicket, 'stop_meter', null),
                    timestamp: getProp(loopCarrierTicket, 'timestamp_primary_query', null),
                    unique_identifier: getProp(loopCarrierTicket, 'unique_identifier', null),
                    volume: getProp(loopCarrierTicket, 'volume', null),
                  }
                  if (emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] != null) {
                    emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                      new Date(emailFormattedData['ERRORS_' + loopErrorKey][loopCarrierTicketKey]['timestamp']),
                      'D MMM YYYY HH:mm',
                    )
                  }
                }
              }
              break
          }
        }
      }
      for (let loopWarningKey in warnings) {
        let loopWarning = warnings[loopWarningKey]
        emailFormattedData['totals']['total_warnings'] += getProp(loopWarning, 'count', 0)
        emailFormattedData['totals']['WARNING_' + loopWarningKey] = getProp(loopWarning, 'count', 0)
        emailFormattedData['WARNINGS_' + loopWarningKey] = {}
        // If the error has data to show
        if (
          loopWarning != null &&
          ((loopWarning.data_buckets != null && objectToArray(loopWarning.data_buckets).length > 0) ||
            (loopWarning.station_logs != null && objectToArray(loopWarning.station_logs).length > 0) ||
            (loopWarning.carrier_tickets != null && objectToArray(loopWarning.carrier_tickets).length > 0))
        ) {
          switch (loopWarning.display_table_type) {
            case 'two_station_logs':
              for (let loopDataBucketKey in loopWarning.data_buckets) {
                let loopDataBucket = loopWarning.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.station_logs != null) {
                  for (let loopStationLogKey in loopDataBucket.station_logs) {
                    let loopStationLog = loopDataBucket.station_logs[loopStationLogKey]
                    if (loopStationLog != null) {
                      emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        first_station_log_id: getProp(getProp(loopStationLog, 'start_meter_station_log', {}), 'unique_identifier', null),
                        first_station_log_stop_meter: getProp(loopStationLog, 'first_station_log_stop_meter', null),
                        gap: getProp(loopStationLog, 'gap', null).toFixed(1),
                        second_station_log_id: getProp(getProp(loopStationLog, 'stop_meter_station_log', {}), 'unique_identifier', null),
                        second_station_log_start_meter: getProp(loopStationLog, 'second_station_log_start_meter', null),
                        timestamp: getProp(loopStationLog, 'timestamp_primary_query', null),
                      }
                    }
                  }
                }
              }
              break
            case 'station_log_and_carrier_ticket':
              for (let loopDataBucketKey in loopWarning.data_buckets) {
                let loopDataBucket = loopWarning.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.combined_station_logs_and_carrier_tickets != null) {
                  for (let loopCombinedStationLogCarrierTicketKey in loopDataBucket.combined_station_logs_and_carrier_tickets) {
                    let loopCombinedStationLogCarrierTicket = loopDataBucket.combined_station_logs_and_carrier_tickets[loopCombinedStationLogCarrierTicketKey]
                    if (loopCombinedStationLogCarrierTicket != null) {
                      emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        carrier_ticket_start_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'start_meter', null),
                        carrier_ticket_stop_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'stop_meter', null),
                        carrier_ticket_timestamp: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'timestamp_primary_query', null),
                        carrier_ticket_volume: getProp(getProp(loopCombinedStationLogCarrierTicket, 'carrier_ticket', {}), 'volume', null),
                        start_meter_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'start_meter_discrepancy', null),
                        station_log_start_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'start_meter', null),
                        station_log_stop_meter: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'stop_meter', null),
                        station_log_timestamp: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'timestamp_primary_query', null),
                        station_log_volume: getProp(getProp(loopCombinedStationLogCarrierTicket, 'station_log', {}), 'volume', null),
                        stop_meter_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'stop_meter_discrepancy', null),
                        timestamp_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'timestamp_discrepancy', null),
                        unique_identifier: getProp(loopCombinedStationLogCarrierTicket, 'unique_identifier', null),
                        volume_discrepancy: getProp(loopCombinedStationLogCarrierTicket, 'volume_discrepancy', null),
                      }
                      if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['carrier_ticket_timestamp'] != null) {
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['carrier_ticket_timestamp'] =
                          returnFormattedDate(
                            new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['carrier_ticket_timestamp']),
                            'D MMM YYYY HH:mm',
                          )
                      }
                      if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['station_log_timestamp'] != null) {
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['station_log_timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['station_log_timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['timestamp_discrepancy'] != null) {
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['timestamp_discrepancy'] = formatTime(
                          Math.abs(emailFormattedData['WARNINGS_' + loopWarningKey][loopCombinedStationLogCarrierTicketKey]['timestamp_discrepancy']),
                        )
                      }
                    }
                  }
                }
              }
              break
            case 'station_log_multiple_carrier_tickets':
              for (let loopDataBucketKey in loopWarning.data_buckets) {
                let loopDataBucket = loopWarning.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.station_logs != null) {
                  for (let loopStationLogKey in loopDataBucket.station_logs) {
                    let loopStationLog = loopDataBucket.station_logs[loopStationLogKey]
                    if (loopStationLog != null && loopStationLog.carrier_tickets != null && loopStationLog.station_log != null) {
                      emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        start_meter: getProp(loopStationLog.station_log, 'start_meter', null),
                        stop_meter: getProp(loopStationLog.station_log, 'stop_meter', null),
                        timestamp: getProp(loopStationLog.station_log, 'timestamp_primary_query', null),
                        type: 'Station Log',
                        unique_identifier: getProp(loopStationLog.station_log, 'unique_identifier', null),
                        volume: getProp(loopStationLog.station_log, 'volume', null),
                      }
                      if (emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] != null) {
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      for (let loopCarrierTicketKey in loopStationLog.carrier_tickets) {
                        let loopCarrierTicket = loopStationLog.carrier_tickets[loopCarrierTicketKey]
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey] = {
                          associated_station_name: getProp(loopDataBucket, 'name', null),
                          stop_meter: getProp(loopCarrierTicket, 'stop_meter', null),
                          timestamp: getProp(loopCarrierTicket, 'timestamp_primary_query', null),
                          type: 'Carrier Ticket',
                          unique_identifier: getProp(loopCarrierTicket, 'unique_identifier', null),
                          volume: getProp(loopCarrierTicket, 'volume', null),
                        }
                        if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] != null) {
                          emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                            new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp']),
                            'D MMM YYYY HH:mm',
                          )
                        }
                      }
                    }
                  }
                }
              }
              break
            case 'carrier_ticket_multiple_station_logs':
              for (let loopDataBucketKey in loopWarning.data_buckets) {
                let loopDataBucket = loopWarning.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.carrier_tickets != null) {
                  for (let loopCarrierTicketKey in loopDataBucket.carrier_tickets) {
                    let loopCarrierTicket = loopDataBucket.carrier_tickets[loopCarrierTicketKey]
                    if (loopCarrierTicket != null && loopCarrierTicket.station_logs != null && loopCarrierTicket.carrier_ticket != null) {
                      emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey] = {
                        associated_station_name: getProp(loopDataBucket, 'name', null),
                        start_meter: getProp(loopCarrierTicket.carrier_ticket, 'start_meter', null),
                        stop_meter: getProp(loopCarrierTicket.carrier_ticket, 'stop_meter', null),
                        timestamp: getProp(loopCarrierTicket.carrier_ticket, 'timestamp_primary_query', null),
                        type: 'Carrier Ticket',
                        unique_identifier: getProp(loopCarrierTicket.carrier_ticket, 'unique_identifier', null),
                        volume: getProp(loopCarrierTicket.carrier_ticket, 'volume', null),
                      }
                      if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] != null) {
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                          new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp']),
                          'D MMM YYYY HH:mm',
                        )
                      }
                      for (let loopStationLogKey in loopCarrierTicket.station_logs) {
                        let loopStationLogTicket = loopCarrierTicket.station_logs[loopStationLogKey]
                        emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey] = {
                          associated_station_name: getProp(loopDataBucket, 'name', null),
                          stop_meter: getProp(loopStationLogTicket, 'stop_meter', null),
                          timestamp: getProp(loopStationLogTicket, 'timestamp_primary_query', null),
                          type: 'Station Log',
                          unique_identifier: getProp(loopStationLogTicket, 'unique_identifier', null),
                          volume: getProp(loopStationLogTicket, 'volume', null),
                        }
                        if (emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] != null) {
                          emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                            new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp']),
                            'D MMM YYYY HH:mm',
                          )
                        }
                      }
                    }
                  }
                }
              }
              break
            case 'station_log':
              for (let loopDataBucketKey in loopWarning.data_buckets) {
                let loopDataBucket = loopWarning.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.station_logs != null) {
                  for (let loopStationLogKey in loopDataBucket.station_logs) {
                    let loopStationLog = loopDataBucket.station_logs[loopStationLogKey]
                    emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey] = {
                      associated_station_name: getProp(loopDataBucket, 'name', null),
                      start_meter: getProp(loopStationLog, 'start_meter', null),
                      stop_meter: getProp(loopStationLog, 'stop_meter', null),
                      timestamp: getProp(loopStationLog, 'timestamp_primary_query', null),
                      unique_identifier: getProp(loopStationLog, 'unique_identifier', null),
                      volume: getProp(loopStationLog, 'volume', null),
                    }
                    if (emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] != null) {
                      emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                        new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp']),
                        'D MMM YYYY HH:mm',
                      )
                    }
                  }
                }
              }
              break
            case 'station_log_direct':
              for (let uniqueIdentifier in loopWarning.station_logs) {
                for (let loopStationLogKey in loopWarning.station_logs[uniqueIdentifier]) {
                  let loopStationLog = loopWarning.station_logs[uniqueIdentifier][loopStationLogKey]
                  emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey] = {
                    associated_station_name: getProp(loopStationLog, 'associated_data_bucket_name', null),
                    start_meter: getProp(loopStationLog, 'start_meter', null),
                    stop_meter: getProp(loopStationLog, 'stop_meter', null),
                    timestamp: getProp(loopStationLog, 'timestamp_primary_query', null),
                    unique_identifier: getProp(loopStationLog, 'unique_identifier', null),
                    volume: getProp(loopStationLog, 'volume', null),
                  }
                  if (emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] != null) {
                    emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp'] = returnFormattedDate(
                      new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopStationLogKey]['timestamp']),
                      'D MMM YYYY HH:mm',
                    )
                  }
                }
              }
              break
            case 'carrier_ticket':
              for (let loopDataBucketKey in loopWarning.data_buckets) {
                let loopDataBucket = loopWarning.data_buckets[loopDataBucketKey]
                if (loopDataBucket != null && loopDataBucket.carrier_tickets != null) {
                  for (let loopCarrierTicketKey in loopDataBucket.carrier_tickets) {
                    let loopCarrierTicket = loopDataBucket.carrier_tickets[loopCarrierTicketKey]
                    emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey] = {
                      associated_station_name: getProp(loopDataBucket, 'name', null),
                      start_meter: getProp(loopCarrierTicket, 'start_meter', null),
                      stop_meter: getProp(loopCarrierTicket, 'stop_meter', null),
                      timestamp: getProp(loopCarrierTicket, 'timestamp_primary_query', null),
                      unique_identifier: getProp(loopCarrierTicket, 'unique_identifier', null),
                      volume: getProp(loopCarrierTicket, 'volume', null),
                    }
                    if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] != null) {
                      emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                        new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp']),
                        'D MMM YYYY HH:mm',
                      )
                    }
                  }
                }
              }
              break
            case 'carrier_ticket_direct':
              for (let uniqueIdentifier in loopWarning.carrier_tickets) {
                for (let loopCarrierTicketKey in loopWarning.carrier_tickets[uniqueIdentifier]) {
                  let loopCarrierTicket = loopWarning.carrier_tickets[uniqueIdentifier][loopCarrierTicketKey]
                  emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey] = {
                    associated_station_name: getProp(loopCarrierTicket, 'associated_data_bucket_name', null),
                    start_meter: getProp(loopCarrierTicket, 'start_meter', null),
                    stop_meter: getProp(loopCarrierTicket, 'stop_meter', null),
                    timestamp: getProp(loopCarrierTicket, 'timestamp_primary_query', null),
                    unique_identifier: getProp(loopCarrierTicket, 'unique_identifier', null),
                    volume: getProp(loopCarrierTicket, 'volume', null),
                  }
                  if (emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] != null) {
                    emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp'] = returnFormattedDate(
                      new Date(emailFormattedData['WARNINGS_' + loopWarningKey][loopCarrierTicketKey]['timestamp']),
                      'D MMM YYYY HH:mm',
                    )
                  }
                }
              }
              break
          }
        }
      }
      // Resolve
      resolve({
        dataBuckets: dataBucketNames,
        errors: errors,
        info: info,
        linkedData: linkedData,
        success: true,
        totals: totals,
        warnings: warnings,
        emailFormattedData: emailFormattedData,
      })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to run reconciliation'),
          details: rLIB('Unsupported reconciliation procedure'),
          code: 'ER-D-RAV-GDIFAR-01',
        },
      })
    }
  })
}

const errorAndWarningTableDivStyles = {
  'background': '#FFFFFF',
  'border-radius': '5px',
  'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  'box-sizing': 'border-box',
  'color': 'rgba(0, 0, 0, 0.87)',
  'margin-bottom': '16px',
  'margin-top': '8px',
  'order': 0,
  'padding': '8px',
  'text-align': 'center',
}

const errorChipStyles = {
  'background': '#D41B29',
  'border-radius': '20px',
  'box-sizing': 'border-box',
  'display': 'inline-block',
  'font-size': '20px',
  'margin-bottom': '4px',
  'margin-left': '0px',
  'margin-top': '12px',
  'padding': '4px',
  'padding-left': '8px',
  'padding-right': '8px',
  'text-align': 'left',
  'width': 'auto',
}

const warningChipStyles = {
  'background': '#F7A830',
  'border-radius': '20px',
  'box-sizing': 'border-box',
  'display': 'inline-block',
  'font-size': '20px',
  'margin-bottom': '4px',
  'margin-left': '0px',
  'margin-top': '12px',
  'padding': '4px',
  'padding-left': '8px',
  'padding-right': '8px',
  'text-align': 'left',
  'width': 'auto',
}

const chipErrorTextStyles = {
  'box-sizing': 'border-box',
  'color': '#ffffff',
  'display': 'inline-block',
  'font-size': '16px',
  'font-weight': 'bold',
  'margin-bottom': '0px',
  'margin-top': '0px',
}

const errorTableStyle = {
  'background': '#FFFFFF',
  'border-collapse': 'collapse',
  'border-spacing': 0,
  'box-sizing': 'border-box',
  'color': '#000',
  'font-size': '15px',
  'text-align': 'left',
  'width': '100%',
}

const errorTableThStyle = {
  'box-sizing': 'border-box',
  'padding': '2px',
  'text-align': 'left',
  'vertical-align': 'top',
}

const errorTableTdStyle = {
  'border-color': '#ddd',
  'border-width': '1px',
  'box-sizing': 'border-box',
  'padding': '2px',
  'text-align': 'left',
  'vertical-align': 'top',
}

const errorTableTdHighlightedStyle = {
  'background': '#D41B29',
  'border-color': '#ddd',
  'border-width': '1px',
  'box-sizing': 'border-box',
  'color': '#FFFFFF',
  'padding': '2px',
  'text-align': 'left',
  'vertical-align': 'top',
}

const warningTableTdHighlightedStyle = {
  'background': '#F7A830',
  'border-color': '#ddd',
  'border-width': '1px',
  'box-sizing': 'border-box',
  'color': '#FFFFFF',
  'padding': '2px',
  'text-align': 'left',
  'vertical-align': 'top',
}

const whiteDivStyles = {
  'background-color': '#FFFFFF',
  'border-radius': '5px',
  'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  'color': 'rgba(0, 0, 0, 0.87)',
  'display': 'block',
  'margin-bottom': '16px',
  'padding': '8px',
  'text-align': 'center',
}

const successDivStyles = {
  'background': '#149F57',
  'border-radius': '5px',
  'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  'box-sizing': 'border-box',
  'color': '#FFFFFF',
  'display': 'block',
  'margin-bottom': '16px',
  'padding': '8px',
  'text-align': 'center',
}

const errorDivStyles = {
  'background': '#D41B29',
  'border-radius': '5px',
  'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  'box-sizing': 'border-box',
  'color': '#FFFFFF',
  'display': 'block',
  'margin-bottom': '16px',
  'padding': '8px',
  'text-align': 'center',
}

const warningDivStyles = {
  'background': '#F7A830',
  'border-radius': '5px',
  'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
  'box-sizing': 'border-box',
  'color': '#FFFFFF',
  'display': 'block',
  'margin-bottom': '16px',
  'padding': '8px',
  'text-align': 'center',
}

export const reconciliationEmailTemplateObject: TsInterface_EmailTemplateObject = {
  HEADER_DIV: {
    element_type: 'div',
    style: {
      'background': 'linear-gradient(#D41B29, rgb(198, 1, 28))',
      'font-size': '20px',
      'width': '100%',
      'min-height': '80px',
      'padding-top': '16px',
      'padding-bottom': '8px',
      'text-align': 'center',
      'border-top-left-radius': '8px',
      'border-top-right-radius': '8px',
    },
    contents: {
      LOGO_IMG: {
        element_type: 'img',
        src_type: 'static',
        src: 'https://firebasestorage.googleapis.com/v0/b/data-ore-app.appspot.com/o/global%2Flogo_white.png?alt=media&token=bb29ca40-0da3-46cc-9050-1a6343d317af',
        alt: 'logo.png',
        width: '200px',
        height: 'auto',
      },
    },
  },
  BODY_DIV: {
    element_type: 'div',
    style: {
      'background': '#f1f1f1',
      'padding': '8px',
      'border-bottom-left-radius': '8px',
      'border-bottom-right-radius': '8px',
    },
    contents: {
      SUMMARY_CARDS_ROW_1_DIV: {
        element_type: 'div',
        style: {
          width: '100%',
          display: 'block',
        },
        contents: {
          HALF_DIV_1: {
            element_type: 'div',
            style: {
              'width': '50%',
              'display': 'inline-block',
              'padding-right': '4px',
              'box-sizing': 'border-box',
            },
            contents: {
              TOTAL_STATION_LOGS_DIV_1: {
                element_type: 'div',
                style: whiteDivStyles,
                contents: {
                  NUMBER_TEXT: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'totals',
                    data_object_prop_key: 'total_station_logs',
                    style: {
                      'display': 'block',
                      'font-size': '28px',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                  DESCRIPTION_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Station Logs',
                    style: {
                      'display': 'block',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                },
              },
            },
          },
          HALF_DIV_2: {
            element_type: 'div',
            style: {
              'width': '50%',
              'display': 'inline-block',
              'padding-left': '4px',
              'box-sizing': 'border-box',
            },
            contents: {
              TOTAL_STATION_LOG_VOLUME_DIV_2: {
                element_type: 'div',
                style: whiteDivStyles,
                contents: {
                  NUMBER_TEXT: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'totals',
                    data_object_prop_key: 'total_station_log_volume',
                    style: {
                      'display': 'block',
                      'font-size': '28px',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                  DESCRIPTION_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Total Volume',
                    style: {
                      'display': 'block',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                },
              },
            },
          },
        },
      },
      STATION_LOGS_TABLE: {
        element_type: 'div',
        style: {},
        contents: {
          HEADER_TEXT: {
            element_type: 'p',
            text_type: 'static',
            text: 'Station Logs by Data Buckets',
            style: {
              'display': 'block',
              'font-size': '18px',
              'font-weight': 'bold',
              'margin-top': '12px',
              'margin-bottom': '4px',
            },
          },
          CARD_DIV: {
            element_type: 'div',
            style: {
              'background': '#FFFFFF',
              'border-radius': '5px',
              'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
              'color': 'rgba(0, 0, 0, 0.87)',
              'margin-bottom': '16px',
              'text-align': 'center',
              'padding': '8px',
            },
            contents: {
              TABLE: {
                element_type: 'table',
                table_type: 'looped_data',
                looped_data_object_key: 'DATA_station_log_data_bucket_breakdown',
                table_style: {
                  'background': '#FFFFFF',
                  'width': '100%',
                  'color': '#000',
                  'font-size': '15px',
                  'text-align': 'left',
                  'border-collapse': 'collapse',
                  'border-spacing': 0,
                },
                table_columns: {
                  COL_DATA_BUCKET: {
                    data_object_prop_key: 'associated_data_bucket_name',
                    column_header: 'Station',
                    th_style: {
                      'vertical-align': 'top',
                      'padding': '2px',
                      'text-align': 'left',
                    },
                    td_style: {
                      'vertical-align': 'top',
                      'padding': '2px',
                      'text-align': 'left',
                      'border-color': '#ddd',
                      'border-width': '1px',
                    },
                  },
                  COL_NUMBER_OF_LOGS: {
                    data_object_prop_key: 'total_station_logs',
                    column_header: '# of Logs',
                    th_style: {
                      'vertical-align': 'top',
                      'padding': '2px',
                      'text-align': 'left',
                    },
                    td_style: {
                      'vertical-align': 'top',
                      'padding': '2px',
                      'text-align': 'left',
                      'border-color': '#ddd',
                      'border-width': '1px',
                    },
                  },
                  COL_TOTAL_VOLUME: {
                    data_object_prop_key: 'total_volume',
                    column_header: 'Total Volume',
                    th_style: {
                      'vertical-align': 'top',
                      'padding': '2px',
                      'text-align': 'left',
                    },
                    td_style: {
                      'vertical-align': 'top',
                      'padding': '2px',
                      'text-align': 'left',
                      'border-color': '#ddd',
                      'border-width': '1px',
                    },
                  },
                },
              },
            },
          },
        },
      },
      SUMMARY_CARDS_ROW_2_DIV: {
        element_type: 'div',
        style: {
          'justify-content': 'space-between',
          'align-items': 'stretch',
          'margin-bottom': '12px',
        },
        contents: {
          HALF_DIV_1: {
            element_type: 'div',
            style: {
              'width': '50%',
              'display': 'inline-block',
              'padding-right': '4px',
              'box-sizing': 'border-box',
            },
            contents: {
              RED_ERROR_DIV: {
                element_type: 'div_conditional',
                condition_data_object_key: 'totals',
                condition_data_object_prop_key: 'total_errors',
                condition_operator: '>',
                condition_value: 0,
                style: errorDivStyles,
                contents: {
                  NUMBER_TEXT: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'totals',
                    data_object_prop_key: 'total_errors',
                    style: {
                      'display': 'block',
                      'font-size': '28px',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                  DESCRIPTION_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Errors',
                    style: {
                      'display': 'block',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                },
              },
              GREEN_ERROR_DIV: {
                element_type: 'div_conditional',
                condition_data_object_key: 'totals',
                condition_data_object_prop_key: 'total_errors',
                condition_operator: '==',
                condition_value: 0,
                style: successDivStyles,
                contents: {
                  NUMBER_TEXT: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'totals',
                    data_object_prop_key: 'total_errors',
                    style: {
                      'display': 'block',
                      'font-size': '28px',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                  DESCRIPTION_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Errors',
                    style: {
                      'display': 'block',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                },
              },
            },
          },
          HALF_DIV_2: {
            element_type: 'div',
            style: {
              'width': '50%',
              'display': 'inline-block',
              'padding-left': '4px',
              'box-sizing': 'border-box',
            },
            contents: {
              YELLOW_WARNING_DIV: {
                element_type: 'div_conditional',
                condition_data_object_key: 'totals',
                condition_data_object_prop_key: 'total_warnings',
                condition_operator: '>',
                condition_value: 0,
                style: warningDivStyles,
                contents: {
                  NUMBER_TEXT: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'totals',
                    data_object_prop_key: 'total_warnings',
                    style: {
                      'display': 'block',
                      'font-size': '28px',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                  DESCRIPTION_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Warnings',
                    style: {
                      'display': 'block',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                },
              },
              GREEN_WARNING_DIV: {
                element_type: 'div_conditional',
                condition_data_object_key: 'totals',
                condition_data_object_prop_key: 'total_warnings',
                condition_operator: '==',
                condition_value: 0,
                style: successDivStyles,
                contents: {
                  NUMBER_TEXT: {
                    element_type: 'p',
                    text_type: 'dynamic',
                    data_object_key: 'totals',
                    data_object_prop_key: 'total_warnings',
                    style: {
                      'display': 'block',
                      'font-size': '28px',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                  DESCRIPTION_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'Warnings',
                    style: {
                      'display': 'block',
                      'font-weight': 'bold',
                      'margin': '0px',
                    },
                  },
                },
              },
            },
          },
        },
      },
      ERRORS_DIV: {
        element_type: 'div',
        contents: {
          ERROR_gaps_in_station_log_meter_readings_by_data_bucket_DIV: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_gaps_in_station_log_meter_readings_by_data_bucket',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'GAPS IN STATION LOG METER READINGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_gaps_in_station_log_meter_readings_by_data_bucket',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_id: {
                        data_object_prop_key: 'first_station_log_id',
                        column_header: 'First Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_stop_meter: {
                        data_object_prop_key: 'first_station_log_stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_id: {
                        data_object_prop_key: 'second_station_log_id',
                        column_header: 'Second Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_start_meter: {
                        data_object_prop_key: 'second_station_log_start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      gap: {
                        data_object_prop_key: 'gap',
                        column_header: 'Meter Gap',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_overlaps_in_station_log_meter_readings_by_data_bucket_DIV: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_overlaps_in_station_log_meter_readings_by_data_bucket',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'OVERLAPS IN STATION LOG METER READINGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_overlaps_in_station_log_meter_readings_by_data_bucket',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_id: {
                        data_object_prop_key: 'first_station_log_id',
                        column_header: 'First Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_stop_meter: {
                        data_object_prop_key: 'first_station_log_stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_id: {
                        data_object_prop_key: 'second_station_log_id',
                        column_header: 'Second Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_start_meter: {
                        data_object_prop_key: 'second_station_log_start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      overlap: {
                        data_object_prop_key: 'gap',
                        column_header: 'Meter Overlap',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_volume_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_volume_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'VOLUME DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_volume_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_volume: {
                        data_object_prop_key: 'station_log_volume',
                        column_header: 'Station Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_volume: {
                        data_object_prop_key: 'carrier_ticket_volume',
                        column_header: 'Carrier Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume_discrepancy: {
                        data_object_prop_key: 'volume_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_start_meter_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_start_meter_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'START METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_start_meter_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_start_meter: {
                        data_object_prop_key: 'station_log_start_meter',
                        column_header: 'Station Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_start_meter: {
                        data_object_prop_key: 'carrier_ticket_start_meter',
                        column_header: 'Carrier Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      start_meter_discrepancy: {
                        data_object_prop_key: 'start_meter_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_stop_meter_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_stop_meter_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STOP METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_stop_meter_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_stop_meter: {
                        data_object_prop_key: 'station_log_stop_meter',
                        column_header: 'Station Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_stop_meter: {
                        data_object_prop_key: 'carrier_ticket_stop_meter',
                        column_header: 'Carrier Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter_discrepancy: {
                        data_object_prop_key: 'stop_meter_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_timestamp_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_timestamp_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'TIMESTAMP DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_timestamp_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_timestamp: {
                        data_object_prop_key: 'station_log_timestamp',
                        column_header: 'Station Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_timestamp: {
                        data_object_prop_key: 'carrier_ticket_timestamp',
                        column_header: 'Carrier Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp_discrepancy: {
                        data_object_prop_key: 'timestamp_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_missing_start_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_missing_start_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING START METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_missing_start_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_missing_stop_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_missing_stop_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING STOP METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_missing_stop_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_missing_volume: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_missing_volume',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING VOLUME',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_missing_volume',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_missing_unique_identifier: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_missing_unique_identifier',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING UNIQUE IDENTIFIER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_missing_unique_identifier',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_with_duplicate_unique_identifiers: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_with_duplicate_unique_identifiers',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS WITH DUPLICATE UNIQUE IDENTIFIERS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_with_duplicate_unique_identifiers',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_with_no_matching_carrier_ticket: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_with_no_matching_carrier_ticket',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS WITH NO MATCHING CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_with_no_matching_carrier_ticket',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_station_logs_matched_with_multiple_carrier_tickets: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_station_logs_matched_with_multiple_carrier_tickets',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOG MATCHED WITH MULTIPLE CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_station_logs_matched_with_multiple_carrier_tickets',
                    table_style: errorTableStyle,
                    table_columns: {
                      type: {
                        data_object_prop_key: 'type',
                        column_header: 'Type',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_missing_start_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_missing_start_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING START METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_missing_start_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_missing_stop_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_missing_stop_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING STOP METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_missing_stop_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_missing_volume: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_missing_volume',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING VOLUME',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_missing_volume',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_missing_unique_identifier: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_missing_unique_identifier',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING UNIQUE IDENTIFIER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_missing_unique_identifier',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_with_duplicate_unique_identifiers: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_with_duplicate_unique_identifiers',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKET WITH DUPLICATE UNIQUE IDENTIFIERS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_with_duplicate_unique_identifiers',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_with_no_matching_station_log: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_with_no_matching_station_log',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS WITH NO MATCHING STATION LOGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_with_no_matching_station_log',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          ERROR_carrier_tickets_matched_with_multiple_station_logs: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'ERROR_carrier_tickets_matched_with_multiple_station_logs',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              ERROR_CHIP_DIV: {
                element_type: 'div',
                style: errorChipStyles,
                contents: {
                  ERROR_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKET MATCHED WITH MULTIPLE STATION LOGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  ERROR_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'ERRORS_carrier_tickets_matched_with_multiple_station_logs',
                    table_style: errorTableStyle,
                    table_columns: {
                      type: {
                        data_object_prop_key: 'type',
                        column_header: 'Type',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      WARNINGS_DIV: {
        element_type: 'div',
        contents: {
          WARNING_gaps_in_station_log_meter_readings_by_data_bucket_DIV: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_gaps_in_station_log_meter_readings_by_data_bucket',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'GAPS IN STATION LOG METER READINGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_gaps_in_station_log_meter_readings_by_data_bucket',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_id: {
                        data_object_prop_key: 'first_station_log_id',
                        column_header: 'First Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_stop_meter: {
                        data_object_prop_key: 'first_station_log_stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_id: {
                        data_object_prop_key: 'second_station_log_id',
                        column_header: 'Second Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_start_meter: {
                        data_object_prop_key: 'second_station_log_start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      gap: {
                        data_object_prop_key: 'gap',
                        column_header: 'Meter Gap',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_overlaps_in_station_log_meter_readings_by_data_bucket_DIV: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_overlaps_in_station_log_meter_readings_by_data_bucket',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'OVERLAPS IN STATION LOG METER READINGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_overlaps_in_station_log_meter_readings_by_data_bucket',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_id: {
                        data_object_prop_key: 'first_station_log_id',
                        column_header: 'First Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      first_station_log_stop_meter: {
                        data_object_prop_key: 'first_station_log_stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_id: {
                        data_object_prop_key: 'second_station_log_id',
                        column_header: 'Second Station Log',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      second_station_log_start_meter: {
                        data_object_prop_key: 'second_station_log_start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      overlap: {
                        data_object_prop_key: 'gap',
                        column_header: 'Meter Overlap',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_volume_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_volume_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'VOLUME DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_volume_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_volume: {
                        data_object_prop_key: 'station_log_volume',
                        column_header: 'Station Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_volume: {
                        data_object_prop_key: 'carrier_ticket_volume',
                        column_header: 'Carrier Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume_discrepancy: {
                        data_object_prop_key: 'volume_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_start_meter_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_start_meter_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'START METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_start_meter_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_start_meter: {
                        data_object_prop_key: 'station_log_start_meter',
                        column_header: 'Station Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_start_meter: {
                        data_object_prop_key: 'carrier_ticket_start_meter',
                        column_header: 'Carrier Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      start_meter_discrepancy: {
                        data_object_prop_key: 'start_meter_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_stop_meter_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_stop_meter_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STOP METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_stop_meter_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_stop_meter: {
                        data_object_prop_key: 'station_log_stop_meter',
                        column_header: 'Station Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_stop_meter: {
                        data_object_prop_key: 'carrier_ticket_stop_meter',
                        column_header: 'Carrier Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter_discrepancy: {
                        data_object_prop_key: 'stop_meter_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_timestamp_discrepancies: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_timestamp_discrepancies',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'TIMESTAMP DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_timestamp_discrepancies',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      station_log_timestamp: {
                        data_object_prop_key: 'station_log_timestamp',
                        column_header: 'Station Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      carrier_ticket_timestamp: {
                        data_object_prop_key: 'carrier_ticket_timestamp',
                        column_header: 'Carrier Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp_discrepancy: {
                        data_object_prop_key: 'timestamp_discrepancy',
                        column_header: 'Discrepancy',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_missing_start_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_missing_start_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING START METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_missing_start_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_missing_stop_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_missing_stop_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING STOP METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_missing_stop_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_missing_volume: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_missing_volume',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING VOLUME',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_missing_volume',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_missing_unique_identifier: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_missing_unique_identifier',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS MISSING UNIQUE IDENTIFIER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_missing_unique_identifier',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_with_duplicate_unique_identifiers: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_with_duplicate_unique_identifiers',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS WITH DUPLICATE UNIQUE IDENTIFIERS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_with_duplicate_unique_identifiers',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_with_no_matching_carrier_ticket: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_with_no_matching_carrier_ticket',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOGS WITH NO MATCHING CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_with_no_matching_carrier_ticket',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_station_logs_matched_with_multiple_carrier_tickets: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_station_logs_matched_with_multiple_carrier_tickets',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'STATION LOG MATCHED WITH MULTIPLE CARRIER TICKETS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_station_logs_matched_with_multiple_carrier_tickets',
                    table_style: errorTableStyle,
                    table_columns: {
                      type: {
                        data_object_prop_key: 'type',
                        column_header: 'Type',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Station',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_missing_start_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_missing_start_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING START METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_missing_start_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_missing_stop_meter: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_missing_stop_meter',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING STOP METER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_missing_stop_meter',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_missing_volume: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_missing_volume',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING VOLUME',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_missing_volume',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_missing_unique_identifier: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_missing_unique_identifier',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS MISSING UNIQUE IDENTIFIER',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_missing_unique_identifier',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_with_duplicate_unique_identifiers: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_with_duplicate_unique_identifiers',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKET WITH DUPLICATE UNIQUE IDENTIFIERS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_with_duplicate_unique_identifiers',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_with_no_matching_station_log: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_with_no_matching_station_log',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKETS WITH NO MATCHING STATION LOGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_with_no_matching_station_log',
                    table_style: errorTableStyle,
                    table_columns: {
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
          WARNING_carrier_tickets_matched_with_multiple_station_logs: {
            element_type: 'div_conditional',
            condition_data_object_key: 'totals',
            condition_data_object_prop_key: 'WARNING_carrier_tickets_matched_with_multiple_station_logs',
            condition_operator: '>',
            condition_value: 0,
            contents: {
              WARNING_CHIP_DIV: {
                element_type: 'div',
                style: warningChipStyles,
                contents: {
                  WARNING_TEXT: {
                    element_type: 'p',
                    text_type: 'static',
                    text: 'CARRIER TICKET MATCHED WITH MULTIPLE STATION LOGS',
                    style: chipErrorTextStyles,
                  },
                },
              },
              CARD_DIV: {
                element_type: 'div',
                style: errorAndWarningTableDivStyles,
                contents: {
                  WARNING_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    looped_data_object_key: 'WARNINGS_carrier_tickets_matched_with_multiple_station_logs',
                    table_style: errorTableStyle,
                    table_columns: {
                      type: {
                        data_object_prop_key: 'type',
                        column_header: 'Type',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      associated_station_name: {
                        data_object_prop_key: 'associated_station_name',
                        column_header: 'Carrier',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      unique_identifier: {
                        data_object_prop_key: 'unique_identifier',
                        column_header: 'Unique Identifier',
                        th_style: errorTableThStyle,
                        td_style: warningTableTdHighlightedStyle,
                      },
                      start_meter: {
                        data_object_prop_key: 'start_meter',
                        column_header: 'Start Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      stop_meter: {
                        data_object_prop_key: 'stop_meter',
                        column_header: 'Stop Meter',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      volume: {
                        data_object_prop_key: 'volume',
                        column_header: 'Volume',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                      timestamp: {
                        data_object_prop_key: 'timestamp',
                        column_header: 'Timestamp',
                        th_style: errorTableThStyle,
                        td_style: errorTableTdStyle,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}
