///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card } from '@mui/material/'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { rLIB } from 'rfbp_core/localization/library'
import { rJSX_UnauthenticatedPageFooter } from './components/footer'
import { rJSX_UnauthenticatedPageHeader } from './components/header'
import { returnCurrentLegalVersionNumbers, returnTermsOfServiceText, rJSX_LegalDocument } from './data/agreements'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Terms of Service', false) as string
  }, [])

  // Functions

  // Call Functions

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        {rJSX_UnauthenticatedPageHeader(un_routerNavigation)}
        <Box
          className="tw-p-4 tw-m-auto"
          sx={{ maxWidth: '1200px' }}
        >
          <Card className="tw-p-4">{rJSX_LegalDocument(returnTermsOfServiceText(returnCurrentLegalVersionNumbers()['termsOfService']))}</Card>
        </Box>
        {rJSX_UnauthenticatedPageFooter(un_routerNavigation)}
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
